import _ from 'lodash';
import { getMomentTime } from '@chipcoo/fe-utils';
import { MpwBusPreorderPropertyKeys } from '@chipcoo/constant';

import { optionsGroup } from 'src/config/selectOption';

export const handlePreOrderBusData = (respData: any) => {
  const {
    _id,
    mpwBusId,
    productName,
    remark,
  } = respData;
  let properties: any[] = [];

  MpwBusPreorderPropertyKeys.forEach(key => {
    let value = respData[key];

    if (value) {
      if (_.isArray(value)) {
        value = value.filter(it => it);

        if (!_.isEmpty(value)) {
          properties.push({
            key,
            value: JSON.stringify(value)
          });
        }
      } else {
        properties.push({
          key,
          value
        });
      }
    }
  });

  return {
    _id,
    mpwBus: mpwBusId,
    productName,
    remark,
    properties
  };
};

const getContact = (contact: Obj) => {
  let name = contact?.name;
  let email = contact?.email;

  name = name ? name : '';
  email = email ? ` (${email})` : '';

  return name + email;
};

export const handlePreOrderList = (list: any[]) => {
  const newList = list.map(it => {
    const {
      _id,
      contact,
      tapeOutDate,
      status,
      properties,
      createdAt,
    } = it;

    let curDate = {
      ...it,
      key: _id,
      contact: getContact(contact),
      tapeOutDate: getMomentTime(tapeOutDate),
      statusText: optionsGroup.mpwBusPreOrderStatus.options!?.[status],
      createdAt: createdAt && getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss')
    };

    properties.forEach(p => {
      curDate = {
        ...curDate,
        [`${p?.key}`]: p?.value
      };

      if (p?.value && p?.value.indexOf('[') >= 0) {
        curDate = {
          ...curDate,
          [`${p?.key}`]: JSON.parse(p?.value)
        };
      } else {
        curDate = {
          ...curDate,
          [`${p?.key}`]: p?.value
        };
      }
    });

    return curDate;
  });

  return newList;
};

export const handlePreOrderData = (respData: any) => {
  const {
    contact,
    status,
    tapeOutDate,
    properties,
    contractDate,
    createdAt,
    mpwBus,
    nameChipcoo,
    // factory,
  } = respData;

  // const { data } = await factoryApi.detail(factory);

  const obj = properties.reduce(
    (prev, cur) => {
      if (cur?.value && cur?.value.indexOf('[') >= 0) {
        prev[cur?.key] = JSON.parse(cur?.value);
      } else {
        prev[cur?.key] = cur?.value;
      }

      return prev;
    },
    {} as any
  );

  return {
    ...mpwBus,
    ...respData,
    ...obj,
    mpwBusId: mpwBus?._id,
    statusHidden: status,
    contractDate: getMomentTime(contractDate),
    date: tapeOutDate && getMomentTime(tapeOutDate),
    contact: getContact(contact),
    createdAt: createdAt && getMomentTime(createdAt),
    chipcooName: nameChipcoo
    // factoryName: getFactoryName(data),
  };
};
