import { createSelector } from 'reselect';

import { InitialState, ActivityByDay, WorkspaceMember } from './interface';
import { createUserPicker } from '../user/selectors';
import { getNormalizedData } from 'src/utils';

function mapReduceActivity(workspaceId: string, activity: InitialState['activity'], order: 'asc' | 'desc') {
  const { allIds, byId } = activity[workspaceId];
  
  // 将id按照内容时间从之前到现在排
  const sorted = allIds.slice().sort((a, b) => {
    return byId[a].updateAtTimestamp - byId[b].updateAtTimestamp;
  });

  const result = sorted.reduce((all, curId) => {
    const item = byId[curId];
    const { day } = item;

    const find = all.find(list => list.day === day);

    if (find) {
      find.list.unshift(item);
    } else {
      all.push({
        day,
        list: [item],
      });
    }

    return all;
  }, [] as ActivityByDay[]);

  return order === 'desc' ? result.reverse() : result;
} 

export const activitySelector = createSelector(
  (state: InitialState) => state.workspaceId,
  (state: InitialState) => state.activity,
  (_: InitialState, order: 'asc' | 'desc') => order,
  mapReduceActivity,
);

export const pickWorkspaceUser = createUserPicker<WorkspaceMember>();
export const normalizeWorkspaceUser = createSelector(
  pickWorkspaceUser,
  getNormalizedData
);
