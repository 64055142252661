/**
 * 对antd的Popover组件的增强, 给props.content注入一个可以手动调用去关闭popover的方法closePopover
 */
import * as React from 'react';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

interface P extends PopoverProps {
  content: React.ReactElement<any> | string;
}
interface S {
  visible: boolean;
}

class EnhancePopover extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      visible: false
    };
  }

  closePopover = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  render() {
    const { visible } = this.state;
    const { content: propsContent, ...passThoughProps } = this.props as any;
    const content = typeof propsContent === 'object'
      ? React.cloneElement(propsContent, {
        ...propsContent.props,
        closePopover: this.closePopover
      })
      : propsContent;

    return (
      <Popover
        content={content}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        {...passThoughProps}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export default EnhancePopover;
