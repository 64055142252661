/**
 * 搜索芯片名称，
 */
import React, { PureComponent } from 'react';
// import { Icon, Divider } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { SelectWithDynamicOptionConfig } from '@chipcoo/hanayo';
import { EnumProductModel } from '@chipcoo/constant';

type P = {
  status: FormStatus;
  packageType: string | undefined;
} & Pick<WrappedFormUtils, 'resetFields'>;
interface S {
  /**
   * @mention 该值的目的：
   * 1、每次切换封装类型以后，芯片名的选择范围会跟着变化，比如选择引线框架，那么芯片就只能选择普通芯片，选择倒装，那么芯片只能选择BUMP芯片
   * 2、如果根据每次从props中传入的packageType来进行切换不同的Select组件，但这样会带来一个问题：因为清空芯片名value的操作在componentDieUpdate中，所以每次
   *    切换组件一定在componentDieUpdate之前被执行，而被切换的Select组件在初始化的时候做的第一件事就是获取芯片名的value去请求数据，这样就被打了一个时间差。
   *    本来预期的结果是请求的时候啥都不带，这次带了一个value，会导致请求的结果不对
   * 3、解决办法：每次props.packageType变化以后，不会立刻切换Select组件，而是等到componentDieUpdate执行以后再进行
   */
  packageType: string | undefined;
}

class SelectDie extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      packageType: props.packageType
    };
  }

  async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    const { packageType, resetFields } = this.props;

    if (prevProps.packageType !== packageType) {
      resetFields && resetFields(['dies']);

      setTimeout(() => this.setState({ packageType }));
    }
  }

  getDieSelectorCategory = () => {
    // const { packageType } = this.state;

    // if (packageType === '引线框架' || packageType === '引线基板') return 'originDie';
    //
    // if (packageType === '倒装' || packageType === '晶圆级封装') return 'bump';

    return EnumProductModel.spwDie;
  }

  getSelectProps = (type: string | undefined) => {
    const { status, packageType, resetFields, ...restProps } = this.props;

    return {
      ...restProps,
      status,
      optionKey: 'spwOriginDie',
      menuMaxHeight: 200,
      placeholder: '请新建或选择（可筛选）',
      // beforeSelect: beforeSelectOriginDie(optionKey, packageType),
      // addNew: this.renderAddNewBtn(),
      // onAddNew: this.addNewFn,
    };
  }

  render() {
    const type = this.getDieSelectorCategory();
    const props = this.getSelectProps(type);

    return <SelectWithDynamicOptionConfig {...props} />;
  }
}

export default SelectDie;
