/**
 * 出库申请列表
 */
import React, { ComponentType } from 'react';
import _, { get } from 'lodash';
import log from 'loglevel';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CardTableV2 } from '@chipcoo/hanayo';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { EnumProductModel } from '@chipcoo/constant';

import { getTableColumns } from './components/getTableColumns';
import CardExtra from './components/CardExtra';
import { connectDecorator } from 'src/store';
import { filterFormConfig } from '../components/getFilterFormConfig';
import { getInitialQuery } from 'src/utils';

import ApplicationFormModal from './ApplicationFormModal';
import styles from './index.module.less';

interface OwnProps {
  category: EnumProductModel | 'ALL';
  queryCondition?: Obj;
}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}

interface DispatchProps {
  getTableList: (params: any) => void;
  postCreate: (data: any) => void;
  getTableItemDetail: (id: string) => void;
}

type IProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;
export type ModalType = 'new' | 'reSubmit' | 'preview';
interface IState {
  tableLoading: boolean;
  modalVisible: boolean;
  modalType: ModalType;
  detailId: string;
  tableColumns: ColumnProps<any>[];
  queryFormConfig: FormItemConfig[];
  isResetDefault: boolean;
}

const { EnhancedTable, QueryTermTags } = CardTableV2;

@connectDecorator(
  ({ stockoutRecord: { list, count, detail } }) => ({ list, count, detail }),
  ({ stockoutRecord: { getTableList, postCreate, getTableItemDetail } }) => ({
    getTableList,
    postCreate,
    getTableItemDetail,
  })
)
class StockOutTable extends React.PureComponent<IProps, IState> {
  private cardTableController: ICardTableController;
  private queryParams;

  constructor(props: IProps) {
    super(props);

    this.state = {
      tableLoading: false,
      modalVisible: false,
      modalType: 'new',
      detailId: '',
      tableColumns: getTableColumns({
        openModal: this.openModal,
      }),
      queryFormConfig: filterFormConfig(props.category),
      isResetDefault: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    const { category } = this.props;

    if (category !== prevProps.category) {
      this.setState({
        queryFormConfig: filterFormConfig(category),
      });

      this.reloadTable();
    }
  }

  reloadTable = () => {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  setCardTableController = (controller: ICardTableController) => {
    this.cardTableController = controller;
  }

  getTerms = (query: Obj) => {
    const config = filterFormConfig(this.props.category);

    return config.reduce((result, curCfg) => {
      const dataKey = get(curCfg, 'dataKey');

      if (!dataKey) {
        return result;
      }

      let val = get(query, dataKey);
      if (_.isObject(val)) {
        val = get(val, 'label');
      }

      if (val) {
        const label = get(curCfg, 'label');
        const getDisplay = get(curCfg, 'getDisplay');

        if (getDisplay) {
          val = getDisplay(val);
        }

        result[label] = val;

        if (val && ['string', 'number'].indexOf(typeof val) === -1) {
          log.warn('[getTerms]: 未被正确转换的查询条件展示', label, val, curCfg);
          Reflect.deleteProperty(result, label);
        }
      }

      return result;
    }, {} as any);
  }

  openModal = async (detailId: string, modalType: ModalType) => {
    const { getTableItemDetail } = this.props;
    let state: any = {
      modalVisible: true,
      modalType: modalType,
    };

    if (modalType !== 'new') {
      try {
        await getTableItemDetail(detailId);

        state = {
          ...state,
          detailId
        };
      } catch (e) {
        log.error('[ApplicationDetailTable]: loading preview failed', e, detailId);
        message.error('加载失败');
        return;
      }
    }

    this.setState(state);
  }

  createApplication = async (values) => {
    const { category } = this.props;
    const { postCreate } = this.props;

    try {
      await postCreate({ productModel: category, values });

      this.reloadTable();
      this.closeModal();
    } catch (e) {
      console.log(e);
    }
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  }

  // 获取表格数据
  getTableData = async (params: GetTableDataParams) => {
    const { getTableList, category, queryCondition } = this.props;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          case 'material':
            prev[key] = value ? value.key : undefined;
            break;
          case 'product':
            prev[key] = value ? value.key : undefined;
            break;
          case 'dieNumber':
            prev[key] = value ? value : undefined;
            break;
          case 'stampNumber':
            prev[key] = value ? value : undefined;
            break;
          case 'warehouse':
            prev[key] = value ? value.key : undefined;
            break;
          default:
            prev[key] = value ? value : undefined;

            break;
        }

        return prev;
      },
      {} as any
    );
    this.queryParams = {
      ...queryCondition,
      ...extraParams,
      ..._formData,
    };

    if (category !== 'ALL') {
      this.queryParams.productModel = category;
    }

    this.setState({ tableLoading: true });
    try {
      await getTableList({...this.queryParams});
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  getInitialQuery = () => {
    const initialQuery = getInitialQuery(this.props);

    return _.omit(initialQuery, 'category');
  }

  clearQuery = () => {
    const { history } = this.props;

    history.push(this.props.match.url);
  }

  getScrollY = () => {
    const chipwingModal = document.querySelector('.order-summary-modal .chipwing-modal-body');

    const height = _.get(chipwingModal, 'offsetHeight');

    if (!height) return 700;

    return height - 16 * 2 - 57 - 16 * 3 - 25 - 16 * 2 - 92 + 14;
  }

  render() {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      tableColumns,
      modalVisible,
      modalType,
      queryFormConfig,
    } = this.state;

    return (
      <div className={styles.stockoutList}>
        <CardTableV2
          card={{
            title: '出库申请列表',
            extra: (
              <CardExtra queryParams={this.queryParams} openQuery={this.openQuery} openModal={this.openModal} />
            )
          }}
          queryFormConfig={queryFormConfig}
          getCardTableController={this.setCardTableController}
        >
          <QueryTermTags
            getTerms={this.getTerms}
            openQueryPanel={this.openQuery}
            clearQuery={this.clearQuery}
          />
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={tableColumns}
            scroll={{ x: 2450, y: this.getScrollY() }}
            clickMention={true}
            size="small"
            initialQuery={this.getInitialQuery()}
          />
        </CardTableV2>

        <ApplicationFormModal
          visible={modalVisible}
          mode={modalType}
          onOk={this.createApplication}
          onCancel={this.closeModal}
          formData={modalType === 'new' ? null : detail}
        />
      </div>
    );
  }
}

// tslint:disable-next-line: max-file-line-count
export default withRouter(StockOutTable) as ComponentType<OwnProps>;
