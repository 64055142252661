import React from 'react';
import _ from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import styles from './index.module.less';

import WipInfo from './WipInfo';
import CurrentInfo from './CurrentInfo';
import WipHistory from './WipHistory';
import { EnumProcessClass } from '@chipcoo/constant';

const wipModeFormItem: InputFormItemConfig = {
  label: '信息来源切换',
  type: 'input',
  dataKey: 'wipMode',
  colClassName: 'hidden-form-item'
};

const processClassFormItem: InputFormItemConfig = {
  type: 'input',
  dataKey: 'processClass',
  colClassName: 'hidden-form-item',
};

const productNameFormItem: InputFormItemConfig = {
  type: 'input',
  dataKey: 'productName',
  colClassName: 'hidden-form-item',
};

const packageTypeFormItem: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'packageType',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const processAmountFormItem: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'processAmount',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const info: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'time',
  colSpan: 24,
  render() {
    return <WipInfo wipMode={this.formData?.wipMode} />;
  },
};

const processText: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'processText',
  colSpan: 24,
  colClassName: styles.processText,
  render() {
    const { formData, initialFormData } = this;
    let stockOutAmount = _.sum(initialFormData?.operations?.map(it => it.amount)) || 0;

    return (
      <div>
        <span>加工数量: {formData.processAmount}颗</span>
        <span>投料数量: {stockOutAmount}颗</span>
        <span>未投料数量: {formData.processAmount - stockOutAmount}颗</span>
      </div>
    );
  },
  renderType() {
    const { processClass } = this.formData;
    const bool = processClass === EnumProcessClass.ft || processClass === EnumProcessClass.assembly;
    return bool ? 'normal' : 'none';
  }
};

const currentInfo: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'operations',
  colSpan: 24,
  render() {
    const { processClass, productName, packageType, processAmount } = this.formData;

    return (
      <CurrentInfo
        processClass={processClass}
        productName={productName}
        packageType={packageType}
        processAmount={processAmount}
      />
    );
  }
};

const history: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'history',
  colSpan: 24,
  render() {
    return (
      <WipHistory processClass={this.formData?.processClass} />
    );
  }
};

export const getWipFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      groupItemConfig: [
        wipModeFormItem,
        processClassFormItem,
        productNameFormItem,
        packageTypeFormItem,
        processAmountFormItem,
        info,
      ] as FormItemConfig[]
    },
    {
      card: { title: '当前信息' },
      groupItemConfig: [
        processText,
        currentInfo
      ] as FormItemConfig[]
    },
    {
      card: { title: '历史信息' },
      groupItemConfig: [
        history
      ] as FormItemConfig[]
    },
  ]
});
