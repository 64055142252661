// tslint:disable:max-file-line-count
import { createModel } from '@rematch/core';
import { isEmpty, get } from 'lodash';
import produce from 'immer';
import { EnumMemberStatus } from '@chipcoo/constant';

import {
  handleOrgDetail,
  handleFunctionContact,
  handleFunctionContactInOrg,
} from './handler';
import { orgApi, attachmentApi } from 'src/services/net';
import { initialNormalizedData, logDataCache } from 'src/utils';
import {
  InitialState,
  NewOrg,
  Setting,
  Verify,
  PatchOrgData,
  MemberOperation,
  OrgMember,
  IOrgDetail
} from './interface';
import { RootState } from 'src/store';
import { handleMemberList } from 'src/utils/data';
import { isEqualUserId } from 'src/utils/user';
import { Access } from './enum';

export * from './selectors';

const initialState: InitialState = {
  orgList: initialNormalizedData({ fetched: false }),
  orgMembers: initialNormalizedData(),
  orgVerify: {
    status: '',
    info: {
      name: '',
      legalPerson: '',
      creditCode: '',
      license: ''
    },
    contact: {
      name: '',
      phone: '',
      email: '',
      address: ''
    },
    invoice: {
      name: '',
      type: 'VALUE_ADD_SPECIAL',
      taxRegistration: '',
      bankName: '',
      bankAccount: '',
      address: '',
    }
    // bank: {
    //   bankName: '',
    //   bankAccount: '',
    //   accountCurrency: []
    // }
  },
  orgId: '',
};

type SetOrgDetailPayload = {
  data: IOrgDetail | IOrgDetail[];
  replace?: boolean;
};

export default createModel({
  state: initialState,
  reducers: {
    setOrgId(state: InitialState, orgId: string) {
      if (state.orgId === orgId) {
        return state;
      }

      return {
        ...state,
        orgId,
      };
    },
    setOrgDetail(state: InitialState, payload: SetOrgDetailPayload) {
      const { data, replace } = payload;
      return produce(state, draft => {
        if (replace) {
          draft.orgList = initialNormalizedData({ fetched: false });
        }

        const { orgList: { allIds, byId } } = draft;
        (Array.isArray(data) ? data : [data]).forEach((org) => {
          const _id = org?._id;

          if (byId[_id!]) {
            Object.assign(byId[_id!], org);
          } else {
            allIds.push(_id!);
            byId[_id!] = handleOrgDetail(org);
          }
        });
      });
    },
    setOrgMember(state: InitialState, payload: { replace?: boolean; data: OrgMember[] }) {
      const { data, replace } = payload;
      return produce(state, draft => {
        if (replace) {
          //
          draft.orgMembers = initialNormalizedData({ fetched: false });
        }

        const { orgMembers: { byId, allIds } } = draft;

        data.forEach(member => {
          const userId = member.user;

          if (byId[userId]) {
            Object.assign(byId[userId], member);
          } else {
            byId[userId] = member;
            allIds.push(userId.toString());
          }
        });
      });
    },
    // resetNormalized(state: InitialState, key: 'orgMembers' | 'orgList') {
    //   return {
    //     ...state,
    //     [key]: initialNormalizedData({ fetched: false }),
    //   };
    // }
  },
  effects: (dispatch) => ({
    // 获取所有组织的列表
    async getOrgList({ force }: DispatchPayload = { force: false }, rootState: RootState) {
      if (rootState.org.orgList.fetched && !force) {
        logDataCache('getOrgList: hit from cache');
        return;
      }

      const { data } = await orgApi.getList();

      const handleData = data.map(item => handleFunctionContactInOrg(item));

      this.setOrgDetail({ data: handleData, replace: true });
    },
    addOrgMember(payload: any) {
      const { user, replace } = payload;
      const isArr = Array.isArray(user);
      const { users, members } = handleMemberList(isArr ? user : [user], 'orgUserId');
      dispatch.user.setUser(users);

      this.setOrgMember({ data: members, replace });
    },
    // 获取组织成员列表
    async getOrgMembers({ orgId, config }: DispatchPayload, rootState: RootState) {
      // if (memberCache.check(orgId) && !force) {
      //   console.log('getOrgMembers: hit from cache', orgId, memberCache);
      //   resp = { data: memberCache.get(orgId) };
      // } else {
      const { data } = await orgApi.getMembers(orgId, config);
      this.addOrgMember({ user: data, replace: true });
      return data;
    },

    // 新增加组织
    async postNewOrg(orgInfo: NewOrg) {
      const { data } = await orgApi.create({ info: orgInfo });

      this.setOrgDetail({ data });
      return data._id;
    },

    async getOrgInfo(orgBasicInfo: Verify['info']) {
      return Object.entries(orgBasicInfo).reduce(
        (prev, [key, val]) => {
          if (!val) {
            prev[key] = undefined;
          } else {
            prev[key] = val;
          }

          return prev;
        },
        {}
      );
    },

    async patchOrgData(payload: PatchOrgData, rootState: any) {
      const { _id: orgId, type } = payload;

      if (type === 'avatar') {
        const currentOrg = rootState.org.orgList.byId[orgId];
        const info = await this.getOrgInfo(currentOrg.info);

        const { data } = await orgApi.patchOrgInfo(orgId, {
          info: {
            ...info,
            avatar: get(payload, 'info.avatar')
          }
        });

        this.setOrgDetail({ data: { ...data, _id: orgId } });
      } else {
        // todo 老组织修改信息报错，暂时无法复现
        const { data } = await orgApi.patchOrgInfo(orgId, payload);

        this.setOrgDetail({ data: { ...handleFunctionContact(data), _id: orgId } });
      }
    },
    // 解散组织
    async postDissolveOrg(payload: Setting) {
      const { orgId, code } = payload;
      await orgApi.postDissolve( { orgId, code });

      return () => this.removeNormalizedData({
        removeIds: [orgId],
        path: 'orgList'
      });
    },
    // 离开组织
    async leaveOrg(val: Setting) {
      const { orgId, code } = val;
      await orgApi.postLeave({ orgId, code });

      return () => this.removeNormalizedData({
        removeIds: [orgId],
        path: 'orgList'
      });
    },
    // 转让组织
    async postTransfer(payload: Setting) {
      const { orgId, userId, profileId, code } = payload;
      await orgApi.postTransfer({ orgId, userId: userId!, code });

      this.updateState([
        { path: `orgMembers.byId.${userId}.role`, data: 'OWNER' },
        { path: `orgMembers.byId.${profileId}.role`, data: 'ADMIN' },
        { path: `orgList.byId.${orgId}.role`, data: 'ADMIN' }
      ]);

      // return resp;
    },
    // 获取认证信息
    async getVerify (orgId?: string) {
      const { data } = await orgApi.getOrgVerify();

      this.updateState({
        path: `orgVerify`, data: isEmpty(data) ? {} : data
      });
    },
    // 提交组织认证
    async postVerify(val: Verify) {
      const license = val?.info?.license;
      try {
        let postData = { ...val };

        if (typeof license === 'object') {
          const { data } = await attachmentApi.uploadFile({
            referenceName: 'verify',
            referenceId: 'UNKNOWN',
            referenceRole: 'license',
            file: get(license, '0.originFileObj')
          });

          postData = {
            ...postData,
            info: {
              ...postData?.info,
              license: data?._id,
            }
          };
        }

        await orgApi.postOrgVerify(postData);
      } catch (e) {
        throw new Error();
      }
    },
    // 添加组织成员
    async putOrgMember(payload: MemberOperation, rootState: RootState) {
      const { org: { orgId: defaultOrgId } } = rootState;
      const { orgId = defaultOrgId, role, userId } = payload;
      await orgApi.putMember(orgId, { role: role!, userId: userId! });

      this.setOrgMember({
        data: [{ user: userId, role, status: EnumMemberStatus.normal }]
      });
    },
    // 邀请组织成员
    async postAddOrgMember(val: MemberOperation) {
      const { accounts } = val;
      await orgApi.inviteMembers({ accounts: accounts! });
    },
    // 移除组织成员
    async deleteOrgMember(val: MemberOperation) {
      try {
        const { orgId, userId } = val;
        await orgApi.removeMember(orgId, { userId: userId! } );

        this.setOrgMember({
          data: [{ user: userId, role: null, status: EnumMemberStatus.removed }]
        });
      } catch (e) {
        console.log(e);
      }
    },
    // 设置组织成员角色
    async patchOrgMember(val: MemberOperation) {
      const { orgId, role, userId } = val;
      const resp = await orgApi.patchMember(orgId, { userId: userId!, role: role! });
      this.setOrgMember({
        data: [{ user: userId, role, status: EnumMemberStatus.normal }],
      });

      return resp.status;
    },
    // socket推送
    // 对方同意邀请加入组织、离开组织、解散组织
    // todo 组织xxx已被解散 提示
    async syncOrg(data: ISyncSocketData, rootState: RootState) {
      const { resource, event, payload  } = data;
      const { userId, user } = payload;
      const { session, org: { orgId: curId, orgList: { byId: orgById } } } = rootState;
      const orgId = resource.id;
      const { _id: profileUserId } = session.profile!;
      // https://doc.chipwing.com/pages/viewpage.action?pageId=6750209
      switch (event) {
        case 'update':
          if (resource.role === 'members') {
            // 理论上是用户角色更新
            const member = {
              user: userId,
              role: payload.role,
            };
            this.setOrgMember({ data: [member] });
          } else {
            // 组织详情更新
            this.setOrgDetail({ data: payload.org });
          }
          break;
        case 'transfer':
          // 移交组织
          const { from, to } = payload;
          const members = [
            from,
            to,
          ];

          let orgDetail: PatchOrgData = {
            _id: orgId,
          };

          // 如果涉及到当前用户，需要更新当前用户角色
          if (isEqualUserId(from.user, profileUserId)) {
            orgDetail.role = Access.AllRole.member;
          } else if (isEqualUserId(to.user, profileUserId)) {
            orgDetail.role = Access.AllRole.owner;
          }

          if (orgDetail.role) {
            this.setOrgDetail({ data: orgDetail });
          }

          this.setOrgMember({ data: members });
          // TODO 显示一个提示信息，提示谁谁谁角色变动
          break;
        case 'remove':
          // 移除成员或主动离开
          const { operate } = payload;
          if (isEqualUserId(profileUserId, userId)) {
            this.removeNormalizedData({ removeIds: [orgId], path: 'orgList' });
          } else {
            // TODO 这里不应该移除，而是禁用
            let status = EnumMemberStatus.leaved;
            if (operate === 'remove') {
              status = EnumMemberStatus.removed;
            }
            this.setOrgMember({
              data: [{ user: userId, role: null, status }]
            });
            // this.removeNormalizedData({ removeIds: [parseInt(userId, 10)], path: 'orgMembers' });
          }
          break;
        case 'dissolve':
          // 解散组织
          this.removeNormalizedData({ removeIds: [orgId], path: 'orgList' });
          break;
        case 'add':
          try {
            if (profileUserId === userId) {
              // 本地没该组织才去获取数据
              !orgById[orgId] && await this.getOrgList({ force: true });
            } else if (orgId === curId) {
              this.addOrgMember({
                user: {
                  user,
                  role: 'MEMBER',
                  // 添加的成员，状态必然为 normal
                  status: EnumMemberStatus.normal,
                }
              });
            }
          } catch (e) {
            console.log('Loading org info failed.', e);
          }
          break;
        default:
      }
    },

    // 获取转让验证码
    async postTransferCode(val: any) {
      const { data } = await orgApi.postTransferCode(val);
      return data;
    },
    // // 验证转让验证码
    // async postTransfer1(payload: any) {
    //   console.log(99, payload);
    //   const { orgId, userId, profileId, code } = payload;
    //
    //   try {
    //     await orgApi.postTransfer({ code, userId });
    //
    //     this.updateState([
    //       { path: `orgMembers.byId.${userId}.role`, data: 'OWNER' },
    //       { path: `orgMembers.byId.${profileId}.role`, data: 'ADMIN' },
    //       { path: `orgList.byId.${orgId}.role`, data: 'ADMIN' }
    //     ]);
    //   } catch (e) {
    //     console.error(e);
    //     throw e;
    //   }
    //   // await orgApi.postTransfer(val);
    // }

    // 获取离开验证码
    async postLeaveCode(val: any) {
      const { data } = await orgApi.postLeaveCode(val);
      return data;
    },
    // 验证离开验证码

    // 获取解散验证码
    async postDissolveCode(val: any) {
      const { data } = await orgApi.postDissolveCode(val);
      return data;
    },
    // 验证解散验证码
  })
});
