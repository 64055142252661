/**
 * Created by marvin on 18/8/8.
 */

import * as React from 'react';
import { Form, Button, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { isEmail } from 'class-validator';

import { RootState, RootDispatch } from 'src/store';
import styles from './index.module.less';
import { AccountParams } from 'src/models/session';

import VerifyCode from './VerifyCode/index';

interface OwnProps {
  formItemLayout?: object;
  submitFormLayout?: object;
}
interface StateProps {
  profile: User;
}
interface DispatchProps {
  patchAccount: (val: AccountParams) => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  isEditing: boolean;
}
const FormItem = Form.Item;
class Email extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false
    };
  }

  // 验证输入的邮箱
  checkEmail = (rule, value, callback) => {
    const { profile } = this.props;

    if (value && value === profile.email) {
      callback('请输入新邮箱!');
    } else if (value && !isEmail(value)) {
      callback('请输入正确的邮箱格式!');
    } else {
      callback();
    }
  }

  // 发送验证邮件
  handleSubmitEmail = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { form, patchAccount } = this.props;
        const { account, verifyCode } = values;

        try {
          await patchAccount({ account, code: verifyCode.trim() });

          this.setState({ isEditing: false });
        } catch (e) {
          console.error(e);
          const data = e?.response?.data;

          switch (data?.code) {
            case 'COMMON_CAPTCHA_FAILED':
              form.setFields({ verifyCode: { value: verifyCode, errors: [new Error('验证码错误或已失效，请重新获取')] } });
              break;
            default:
              break;
          }
        }
      }
    });
  }

  render () {
    const { form, profile, formItemLayout, submitFormLayout } = this.props;
    const { isEditing } = this.state;
    const { getFieldDecorator } = form;
    return (
      <div>
        <div>
          <h3 style={{ display: 'inline-block' }}>邮箱账号</h3>
          {profile.email ? <h4 style={{ display: 'inline-block' }}>&nbsp;- {profile.email}</h4> : ''}
        </div>
        <div className={styles.introduction}>
          邮箱可作为账号进行登录、找回密码和收取系统通知。
        </div>
        <div className={styles.operation}>
          {isEditing
            ? (
              <Form className={styles.form} onSubmit={this.handleSubmitEmail}>
                <FormItem label="邮箱" {...formItemLayout}>
                  {getFieldDecorator('account', {
                    rules: [
                      { required: true, message: '请输入邮箱' },
                      { validator: this.checkEmail }
                    ]
                  })(
                    <Input autoFocus={true} placeholder="邮箱" />
                  )}
                </FormItem>

                <VerifyCode
                  form={form}
                  from="email"
                  captchaBtnClassName="email-captcha-btn"
                  formItemLayout={formItemLayout}
                />

                <FormItem {...submitFormLayout}>
                  <Button type="primary" htmlType="submit">确定</Button>
                  <Button style={{marginLeft: 8}} onClick={() => this.setState({isEditing: false})}>
                    取消
                  </Button>
                </FormItem>
              </Form>
            )
            : <Button type="primary" onClick={() => this.setState({isEditing: true})}>
              {Boolean(profile.email) ? '修改邮箱账号' : '添加邮箱账号'}
            </Button>}
        </div>
      </div>
    );
  }
}
const mapState = ({ session: { profile } }: RootState) => ({ profile: profile! });
const mapDispatch = ({ session: { patchAccount } }: RootDispatch) => ({ patchAccount });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(Email));
