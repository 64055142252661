/**
 * 聊天群组的成员card
 */
import * as React from 'react';
import { Icon, Tooltip } from 'antd';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import styles from './index.module.less';

import { EnhanceAvatar } from 'src/components';
import { ConnectUserInfo } from 'src/pages/IM/containers';
import { getEnhancedAvatar, isEqualUserId } from 'src/utils/user';

interface P {
  userId: string;
  isOwner: boolean;
  currentUserId: string;
  userInfo?: User;
  deleteUser: (userId: string) => void;
}
interface S {}

class ChatMember extends React.PureComponent<P, S> {
  handleRemoveMember = () => {
    const { userId, deleteUser } = this.props;

    deleteUser(userId);
  }

  render() {
    const { userInfo, isOwner, currentUserId } = this.props;
    const { _id } = userInfo!;
    const userName = getUserNameEnhance(userInfo!);
    const src = getEnhancedAvatar(userInfo!);

    return (
      <div className={styles.chatMemberWrapper}>
        <Tooltip title={userName} mouseEnterDelay={0.5}>
          <div className={styles.avatar}>
            {isOwner && (!isEqualUserId(_id, currentUserId)) && (
              <div className={styles.avatarMask} onClick={this.handleRemoveMember}>
                <Icon type="close" theme="outlined" />
              </div>
            )}

            <EnhanceAvatar userName={userName} src={src} />
          </div>
        </Tooltip>

        <div className="name ellipsis">
          {userName}
        </div>
      </div>
    );
  }
}

export default ConnectUserInfo(ChatMember) as any;
