import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { IState } from './interface';
import { inventoryApi } from 'src/services/net';
import { TableModelCommonEffects } from 'src/models/type';
import {
  handleProcessList,
  handleProcessDetail,
  handleWipData
} from './handle';

const initialState: IState = {
  list: [],
  count: 0,
  detail: void 0,
  wipDetail: void 0,
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: IState, respData: any) {
      return produce(state, draft => {
        const { data, count } = respData;

        draft.count = count;
        draft.list = data;
      });
    },
    setProcessDetail(state: IState, respData: any) {
      return produce(state, draft => {
        draft.detail = handleProcessDetail(respData);
      });
    },
    setWipDetail(state: IState, payoad: any) {
      return produce(state, draft => {
        draft.wipDetail = payoad;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await inventoryApi.getStockProcessList(params);
      const handleData = await handleProcessList(data.data);

      this.setList({ ...data, data: handleData });
    },
    async postCreate(data: any) {
      //
    },
    async patchModify(data: any) {
      //
    },
    async getTableItemDetail(id: string) {
      const { data } = await inventoryApi.getStockProcessById(id);

      this.setProcessDetail(data);
    },
    async getWipDetail(id: string, rootState: any) {
      const { data } = await inventoryApi.getWip(id);
      const stockProcess = rootState.process?.list?.find(it => it?._id === id);
      const handleData = handleWipData(data);

      this.setWipDetail({
        ...handleData,
        processClass: stockProcess?.processClass,
        packageType: stockProcess?.output?.packageType,
        productName: stockProcess?.output?.productName,
        processAmount: stockProcess?.inputs?.amount,
      });
    },
  })
});
