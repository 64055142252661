import { createModel } from '@rematch/core';
import produce from 'immer';

import { TableModelCommonEffects } from 'src/models/type';
import { State } from './interface';
import {
  handleList,
  handleDetail,
  handleCompareData
} from './handle';
import { qcApi } from 'src/services/net';

const initialState: State = {
  list: [],
  count: 0,

  detail: {},
  compareDetail: {},
  modalInst: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    },
    setCompareData(state: State, payload: any) {
      return produce(state, draft => {
        draft.compareDetail = payload;
      });
    },
    setModalInst(state: State, payload: any) {
      return produce(state, draft => {
        draft.modalInst = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getTestGroupList(params);

      const handleData = handleList(data);
      this.setList({data: handleData, count: data.count});
    },
    async getTableItemDetail(id: string) {
      const { data } = await qcApi.getTestGroupById({id, withTests: true});

      const handleData = handleDetail(data);
      this.setDetail(handleData);
    },
    async getCompareDetail(params: Obj) {
      const { data } = await qcApi.getTestGroupCompare(params);

      const handleData = handleCompareData(data);
      this.setCompareData(handleData);
    },
    async deleteTestItem(id: string) {
      await qcApi.deleteTestGroupById(id);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
  })
});
