import React, { FC, useEffect } from 'react';
import { Radio, Tooltip } from 'antd';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumMarkingContentFrom } from '@chipcoo/constant';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  fetchPolicy: any;
  status: FormStatus;
}

const MarkingContentFrom: FC<Props> = props => {
  const { value, onChange, fetchPolicy, status } = props;
  const newValue = value || EnumMarkingContentFrom.chipcoo;

  const policy = fetchPolicy?.policies?.find(it => it?.name === '下单时客户可自主提供打印内容');
  const disabled = policy?.value && policy?.value === 'true';

  const text = '自主提供';
  const node = !disabled && status !== 'preview' ? (
    <Tooltip
      title="如下单需要支持自主提供打印内容，请联系芯翼客服"
      overlayStyle={{ zIndex: FlexModalManager.getNextZIndex() }}
    >
      {text}
    </Tooltip>
  ) : text;

  useEffect(
    () => {
      onChange && onChange(newValue);
    },
    []
  );

  return (
    <>
      <Radio.Group onChange={onChange} value={newValue} disabled={status === 'preview'}>
        <Radio value={EnumMarkingContentFrom.chipcoo}>平台生成</Radio>
        <Radio value={EnumMarkingContentFrom.user} disabled={!disabled}>{node}</Radio>
      </Radio.Group>
    </>
  );
};

export default MarkingContentFrom;
