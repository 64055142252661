import * as React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'materialName',
      key: 'materialName',
      title: '物料名称',
      width: 200,
    },
    {
      dataIndex: 'attributes',
      key: 'attributes',
      title: '是否为蓝膜',
      width: 150,
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 150
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      width: 200
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 200,
    },
    {
      title: '片号',
      dataIndex: 'waferIds',
      key: 'waferIds',
      width: 150,
      render: text => {
        return <span>{text ? '#' + text : '/'}</span>;
      }
    },
    {
      dataIndex: 'good',
      key: 'good',
      title: '良品数量',
      width: 100
    },
    {
      dataIndex: 'bad',
      key: 'bad',
      title: '不良品数量',
      width: 150
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总数量',
      width: 100
    },
  ];
};
