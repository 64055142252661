import React, { PureComponent } from 'react';
import { Card, Form } from 'antd';
import { CardProps } from 'antd/lib/card';
import { PropsAPIHocFix } from 'gg-editor';
import { FormComponentProps } from 'antd/lib/form';
import { noop } from '@chipcoo/fe-utils';

import { UNSAVED_SIGNAL } from '../RegisterBehaviours';

interface Props extends PropsAPIHocFix, FormComponentProps {

}

export function getPopupContainer(triggerNode: HTMLElement) {
  return triggerNode.parentNode! as HTMLElement;
}

export class DetailEditor<P extends Props, S extends any> extends PureComponent<P, S> {
  static previousId: string;
  public _cardProps: CardProps = {
    type: 'inner',
    size: 'small',
    title: '',
    bordered: false,
  };

  modelId: string;

  componentDidMount() {
    this.modelId = this.item.getModel().id;

    const unsaved = this.page.getSignal(UNSAVED_SIGNAL) || [];

    if (unsaved.includes(this.modelId)) {
      this.props.form.validateFields();
    }
  }

  componentWillUnmount() {
    this.setState = noop;
    DetailEditor.previousId = this.modelId;

    setTimeout(() => {
      DetailEditor.previousId = '';
    }, 300);
  }

  get item() {
    const { propsAPI } = this.props;

    return propsAPI.getSelected!()[0];
  }

  get cardProps(): CardProps {
    return this._cardProps;
  }

  get page() {
    return this.props.propsAPI.editor.getCurrentPage();
  }

  getUpdateValues(values: any) {
    return values;
  }

  setUnSaved = () => {
    let unsaved = this.page.getSignal(UNSAVED_SIGNAL) || [];
    if (!unsaved.includes(this.modelId)) {
      unsaved = [...unsaved, this.modelId];
    }
    this.page.setSignal(UNSAVED_SIGNAL, unsaved);
  }

  clearUnSaved = () => {
    let unsaved = this.page.getSignal(UNSAVED_SIGNAL) || [];
    const index = unsaved.indexOf(this.modelId);

    if (index !== -1) {
      unsaved = unsaved.slice();
      unsaved.splice(index, 1);
    }
    this.page.setSignal(UNSAVED_SIGNAL, unsaved);
  }

  handleSubmit = (e?) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setUnSaved();

    const { form, propsAPI } = this.props;
    const { getSelected, executeCommand, update } = propsAPI;

    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        form.validateFieldsAndScroll((err, values) => {
          const item = getSelected!()[0];

          if (!item) {
            return;
          }

          executeCommand!(() => {
            update!(item, {
              ...this.getUpdateValues(values),
            });
          });

          if (err) {
            reject(err);
            return;
          }

          this.clearUnSaved();
          resolve();
        });
      }, 0);
    });
  }

  renderFormContent(): React.ReactNode {
    return null;
  }

  render() {
    if (!this.item) {
      return null;
    }

    return (
      <Card {...this.cardProps}>
        <Form onSubmit={this.handleSubmit}>
          {this.renderFormContent()}
        </Form>
      </Card>
    );
  }
}

export default DetailEditor;
