/**
 * Created by marvin on 18/8/10.
 */
/**
 * 头像列表配置
 * path: string
 * name: string => 为空时为横向分隔线
 * path: string
 * isLogout: boolean => 针对登出
 */

import urlConfig from 'src/config/url';

const dropdownMenuConfig = [
  {
    icon: 'icon-user-thick',
    name: '个人设置',
    path: '/user'
  },
  {
    icon: 'icon-stop-thick',
    name: '退出登录',
    path: `${urlConfig.authUrl}/logout`,
    isLogout: true
  }
];

export default dropdownMenuConfig;
