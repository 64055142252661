// tslint:disable:max-file-line-count

import * as React from 'react';
import { Select, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table/interface';
import { getUserName } from '@chipcoo/fe-utils';

import styles from './style.module.less';

import createMemberTable, { BasicStateProps } from 'src/containers/MemberTable';

import { RootState, RootDispatch } from 'src/store';
import { OrgUser } from 'src/models/org/interface';
import { i18nMembers } from 'src/models/org/handler';

import { EnhanceAvatar } from 'src/components';
import { WithWorkspaceId, SetMemberParams } from 'src/models/workspace/interface';
import { RouteComponentProps } from 'react-router';
import { pickWorkspaceUser } from 'src/models/workspace/selectors';
import { pickOrgUser } from 'src/models/org';
import { EnumMemberStatus } from '@chipcoo/constant';
import { getEnhancedAvatar } from 'src/utils/user';

interface StateProps extends BasicStateProps {
  workspaceId: string;
}

interface DispatchProps {
  addMember: (arg: WithWorkspaceId<{ members: NormalMember[] }>) => any;
  removeMember: (arg: WithWorkspaceId<{ userId: User['_id'] }>) => any;
  setMemberRole: (arg: WithWorkspaceId<SetMemberParams>) => any;
}

const { Option } = Select;

export const baseColumnsConfig: ColumnProps<OrgUser>[] = [
  {
    title: '头像   昵称',
    key: 'name',
    dataIndex: 'name',
    className: styles.columnAvatarNickname,
    width: '25%',
    render: (_, record) => {
      const avatar = getEnhancedAvatar(record);
      const { status = EnumMemberStatus.normal } = record;
      const userDisabled = !record.role || status !== EnumMemberStatus.normal;

      return (
        <div className="user-list-record-user-wrap">
          <EnhanceAvatar src={avatar} userName={getUserName(record)} userDisabled={userDisabled} />
          <span className="record-user-name">{getUserName(record)}</span>
        </div>
      );
    }
  },
  {
    title: '邮箱或手机号',
    key: 'account',
    dataIndex: 'account',
    width: '50%',
  },
  {
    title: '角色',
    key: 'role',
    dataIndex: 'role',
    width: '10%',
    render: (text) => (i18nMembers[text] || text)
  }
];

export function getColumnsConfig(role: AllRole) {
  const columns = baseColumnsConfig.concat([]);
  if (role === 'MEMBER') {
    return columns;
  }

  if (role === 'OWNER') {
    const setColumn: ColumnProps<OrgUser> = {
      title: '角色',
      key: 'role',
      dataIndex: 'role',
      width: '10%',
      render: (text, record) => {
        const { status = EnumMemberStatus.normal } = record;
        if (text === null || status !== EnumMemberStatus.normal) {
          return '';
        }

        if (text === 'OWNER') {
          return i18nMembers[text] || text;
        }
        const { loadingList } = this.state;
        const contain = loadingList.indexOf(record._id) !== -1;
        let value = contain ? this.roleCache[record._id] : text;

        return (
          <Select
            defaultValue={text}
            value={value}
            loading={contain}
            disabled={contain}
            onChange={(r) => this.setMemberRole(record._id, r)}
          >
            <Option value="ADMIN">{i18nMembers.ADMIN}</Option>
            <Option value="MEMBER">{i18nMembers.MEMBER}</Option>
          </Select>
        );
      }
    };

    columns[2] = setColumn;
  }

  const actionColumn: ColumnProps<OrgUser> = {
    title: '操作',
    width: 200,
    key: 'operation',
    render: (_, record) => {
      return (
        <div>
          {
            ((role === 'OWNER' && record.role !== 'OWNER')
              || (role === 'ADMIN' && record.role === 'MEMBER'))
            && (
              <Popconfirm
                title="是否要移除该成员？"
                onConfirm={() => this.removeMember(record._id)}
              >
                <a>移除</a>
              </Popconfirm>
            )
          }
        </div>
      );
    }
  };

  columns.push(actionColumn);

  return columns;
}

const weight = {
  'MEMBER': 1,
  'ADMIN': 1,
  'OWNER': 3,
};

const mapState = (
  {
    session: { profile },
    org: { orgMembers },
    user,
    workspace: { list, workspaceId }
  }: RootState,
): StateProps => {
  const workspace = list.byId[workspaceId];
  const { role } = workspace.members.find(m => m.user.toString() === profile!._id + '')!;

  const members = pickWorkspaceUser(user, workspace.members).sort((a, b) => {
    return weight[b.role!] - weight[a.role!];
  });

  const users = pickOrgUser(user, orgMembers.byId);

  const disabledKeys = members.map(it => it._id);
  return {
    disabledKeys,
    members,
    users,
    role: role! as AllRole,
    workspaceId,
  };
};

const mapDispatch = ({ workspace: { asyncAddMember, asyncRemoveMember, asyncSetMemberRole } }: RootDispatch) => ({
  removeMember: asyncRemoveMember,
  addMember: asyncAddMember,
  setMemberRole: asyncSetMemberRole,
});

export default createMemberTable<OrgUser, StateProps, DispatchProps, RouteComponentProps>({
  async addMember(members: Member[], reset?: any) {
    const { addMember, workspaceId } = this.props;
    await addMember({ workspaceId, members });
    reset();
  },
  async setMemberRole(userId: string | number, role: AllRole, ctx?: any) {
    const { setMemberRole, workspaceId } = this.props;
    await setMemberRole({ workspaceId, userId, role });
  },
  async removeMember(userId: string | number, ctx?: any) {
    const { removeMember, workspaceId } = this.props;
    await removeMember({ workspaceId, userId });
  },
  rowKey: (record) => record._id,
  getColumnsConfig,
  mapState,
  mapDispatch,
  cardTitle: '协作区成员',
  className: styles.orgMembers,
});
