/**
 * Created by marvin on 2019/1/22.
 */

import React from 'react';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';

import { modalConfig } from './modalConfig';
import { RootState } from 'src/store';
import { AddressParam } from 'src/models/address/interface';

import { AddressSelector } from 'src/containers';

interface OwnProps extends FormComponentProps {
  addressId: string;
}
interface StateProps {
  addressList: AddressParam[];
}
interface DispatchProps {}
type Props = OwnProps & StateProps & DispatchProps;
type State = {
  detail: Object;
};

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

class CreateAddress extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    const { addressId, addressList } = props;

    this.state = {
      detail: addressList.find(addr => addr._id === addressId) || {}
    };
  }

  getSubmitValue = () => {
    const { form, addressId } = this.props;

    let value: Obj | undefined = undefined;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { detail } = this.state;
        const { tag, regionCode } = values;
        let [ province, city, distinct ] = ['', '', ''];

        if (isEqual(regionCode?.regionCode, get(detail, 'regionCode'))) {
          province = get(detail, 'province');
          city = get(detail, 'city');
          distinct = get(detail, 'distinct');
        } else {
          [ province, city, distinct ] = regionCode?.regionCodeText;
        }

        value = {
          ...values,
          _id: addressId,
          province,
          city,
          distinct,
          labels: tag ? [tag] : undefined,
          regionCode: regionCode?.regionCode,
        };
      }
    });

    return value;
  }

  render () {
    const { form } = this.props;
    const { detail } = this.state;
    const { getFieldDecorator } = form;

    return (
      <Form>
        {Object.keys(modalConfig).map(key => {
          const { label, type, required, message, autofocus } = modalConfig[key];

          return (
            <FormItem key={key} label={label} {...formItemLayout}>
              {getFieldDecorator(key, {
                rules: [{ required, message }],
                initialValue: key === 'tag' ? get(detail, `labels.0`) : detail[key]
              })(
                type === 'input'
                  ? <Input placeholder={message} autoFocus={autofocus} autoComplete="off" />
                  : (
                    <AddressSelector />
                  )
              )}
            </FormItem>
          );
        })}
      </Form>
    );
  }
}

export default Form.create()(
  connect<StateProps, DispatchProps, OwnProps>(
    ({ address: { addressList } }: RootState) => ({ addressList }),
    null,
    null,
    { forwardRef: true }
  )(CreateAddress)
) as any;
