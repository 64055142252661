import * as React from 'react';
import { has } from 'lodash';
import { Button, Divider, Popconfirm } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table/interface';
import { getFlexModalBody } from '@chipcoo/fe-utils';

import styles from './index.module.less';

import { TableWithColumnConf } from 'src/components';

type TableDataItem = Object;
interface NewTableDataItem extends TableDataItem {
  key?: string;
  editable?: boolean;
  isNew?: boolean;
}
interface Columns extends ColumnProps<any> {
  title: string;
  editableRender: (props: Object, tableData: any) => any;
  width?: string | number;
  addonBefore?: string;
  defaultValue?: any;
}

interface Props extends TableProps<any> {
  isInFlexModal?: boolean;
  onlyRead?: boolean;
  columnsConfig: {[key: string]: Columns};
  verify?: (rowTableData: any) => boolean;
  value?: NewTableDataItem[];
  onChange?: (newData: any) => void;
  showAddBtn?: boolean;
  isSetDisabledByAdd: boolean; // 在新建时是否禁用新建按钮
  addBtnText?: string;
}
interface State {
  disabled: boolean;
  tableData: NewTableDataItem[];
}

class TableForm extends React.PureComponent<Props, State> {
  static defaultProps = {
    showAddBtn: true,
    isSetDisabledByAdd: true,
    isInFlexModal: false,
  };

  // 用来存储原始的数据，编辑时候需要用到
  private cacheOriginData = {};
  // 负责分辨是否是点击了取消
  private clickedCancel = false;
  // 这个值用来记录下最新的表格行的index
  private lastIndex = 0;
  // 表格行的基础字段，,从props.columnsConfig中获取，主要是用于新建行
  private baseTableRowField: Object = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      disabled: false,
      tableData: props.value as NewTableDataItem[]
    };

    Object.keys(props.columnsConfig).forEach(key => this.baseTableRowField[key] = null);
  }

  componentWillReceiveProps(nextProps: Props) {
    if ('value' in nextProps) {
      this.setState({ tableData: nextProps.value as NewTableDataItem[] });
    }
  }

  // 获取表格的列头
  getColumns() {
    const { columnsConfig, onlyRead, isInFlexModal } = this.props;
    const { tableData } = this.state;
    const dataColumns = Object.keys(columnsConfig).map((key, index) => {
      const {
        editableRender,
        render: propsRender,
        addonBefore,
        defaultValue,
        ...passConfig
      } = columnsConfig[key];
      this.baseTableRowField[key] = defaultValue;
      const render = (text, record) => {
        if (record.editable) {
          const baseProps = {
            value: typeof text !== 'number' && !text ? void 0 : text,
            size: 'small',
            autoFocus: index === 0,
            onChange: e => this.handleFieldChange(e, key, record.key),
            // onPressEnter: e => this.handleKeyPress(e, record.key),
          };

          return editableRender(baseProps, tableData);
        }

        if (typeof text === 'object') return text?.label;

        return addonBefore ? addonBefore + text : text;
      };

      return { ...passConfig, key, dataIndex: key, render: propsRender ? propsRender : render };
    });

    if (!onlyRead) {
      dataColumns.push({
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        width: 90,
        render: (text, record) => {
          const editorOperate = (type: string, action?) => (
            <React.Fragment>
              <a onClick={e => this.saveRow(e, record.key)}>{type}</a>
              <Divider type="vertical" />
              {record.isNew ? <a onClick={() => this.remove(record.key, action)}>取消</a> : (
                <Popconfirm
                  overlayClassName="pop-confirm"
                  title="是否要删除此行？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => this.remove(record.key, action)}
                  getPopupContainer={isInFlexModal ? getFlexModalBody : (t) => t.parentNode as HTMLElement}
                >
                  <a>删除</a>
                </Popconfirm>
              )}
            </React.Fragment>
          );

          if (record.editable) {
            if (record.isNew) {
              return editorOperate('确定', 'cancel');
            }
            return (
              <span>
                <a onClick={e => this.saveRow(e, record.key)}>保存</a>
                <Divider type="vertical" />
                <a onClick={e => this.cancel(e, record)}>取消</a>
              </span>
            );
          }
          return editorOperate('编辑');
        }
      });
    }

    return dataColumns;
  }

  getRowByKey(key: number, newData?: any) {
    return (newData || this.state.tableData).filter(item => item.key === key)[0];
  }

  handleFieldChange = (e, fieldName, key) => {
    const newData = this.state.tableData.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);

    if (!target) return;

    // inputNumber组件onChange回调的第一个参数是value，input组件则是一个event对象
    target[fieldName] = has(e, 'target.value') ? e.target.value : e;

    this.setState({ tableData: newData });
  }

  // 保存这一行
  saveRow = (e, key) => {
    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }

    const target = this.getRowByKey(key) || {};
    const { verify, onChange } = this.props;

    if (verify && !verify(target)) {
      e.target.focus();
      return;
    }

    delete target.isNew;
    const newData = this.toggleEditable(e, key);

    this.setDisabled(newData);
    onChange && onChange(newData);
  }

  // 设置新建按钮的禁用/启用状态
  setDisabled = (data) => {
    const obj = data.find(item => !!item.editable);
    this.setState({ disabled: !!obj });
  }

  // 切换编辑模式
  toggleEditable = (e, key) => {
    e.preventDefault();
    const newData = this.state.tableData.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    this.setDisabled(newData);

    // 进入编辑状态
    if (target) {
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }

      target.editable = !target.editable;

      this.setState({ tableData: newData });
    }

    return newData;
  }

  remove = (key, type?) => {
    const newData = this.state.tableData.filter(item => item.key !== key);
    this.setState({ tableData: newData });
    this.setDisabled(newData);

    if (this.props.onChange) {
      this.props.onChange(newData);
    }
  }

  // 取消编辑状态
  cancel = (e, record) => {
    e.preventDefault();
    const key = record.key;
    const newData = this.state.tableData.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);

    if (this.cacheOriginData[key]) {
      Object.assign(target, this.cacheOriginData[key]);
      target.editable = false;
      delete this.cacheOriginData[key];
    } else {
      // todo 看会引起什么bug
      target.editable = false;
    }
    this.setDisabled(newData);
    this.setState({ tableData: newData });
  }

  // 新建一行数据
  addNew = () => {
    const { isSetDisabledByAdd } = this.props;
    const { tableData } = this.state;
    const newData: NewTableDataItem[] = tableData ? tableData.map(item => ({ ...item })) : [];

    newData.push({
      ...this.baseTableRowField,
      key: 'NEW_TEMP_INDEX_' + this.lastIndex,
      editable: true,
      isNew: true
    });
    this.setState({ tableData: newData, disabled: isSetDisabledByAdd });
    this.lastIndex += 1;
  }

  // 回车保存
  handleKeyPress = (e, key) => {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  render() {
    const { onlyRead, showAddBtn, addBtnText, ...passThoughProps } = this.props as any;
    const { tableData, disabled } = this.state;
    const columns = this.getColumns();

    return (
      <div className={styles.table}>
        {tableData && tableData.length ? (
          <TableWithColumnConf
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="middle"
            {...passThoughProps}
          />
        ) : null}
        {onlyRead ? null : showAddBtn && (
          <Button
            type="dashed"
            style={{ width: '100%', marginBottom: 8, marginTop: 16 }}
            icon="plus"
            disabled={disabled}
            onClick={this.addNew}
          >
            {addBtnText ?? '添加'}
          </Button>
        )}
      </div>
    );
  }
}

export default TableForm;
