import React from 'react';
import { get } from 'lodash';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { delayRemove, createKey } from 'src/containers/delayRemove';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import store from 'src/store';

export function handleRoomsSync(data: ISyncSocketData) {
  const state = store.getState();
  const { event: action, payload: { roomId } } = data;

  return delayRemove({
    key: createKey('ROOM', action, roomId),
    handler: () => store.dispatch.chatRoomList.handleRoomSyncData(data),
    executeDirectly: () => {
      const { location: { pathname } } = getCurrentRoute()!;
      const matchPath = compilePath({ name: ROUTE_NAMES.IM_DETAIL, params: { id: roomId } });
      
      const shouldChangeRoute = action === 'dissolve';

      return !shouldChangeRoute || pathname.indexOf(matchPath) === -1;
    },
    modalContent() {
      const roomInst = state.chatRoomList.roomList.byId[roomId];
      const name = get(roomInst, 'name') || '';

      if (name) {
        return <div>群组『{name}』已被解散。</div>;
      }

      return <div>一个群组已被解散。</div>;
    },
    nextRoute: { 
      name: ROUTE_NAMES.IM,
    }
  });
}
