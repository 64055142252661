/**
 * Created by marvin on 18/8/13.
 */

import * as React from 'react';
import { Button, Popover } from 'antd';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';

import { RootState, RootDispatch } from 'src/store';
import styles from './index.module.less';
import config from 'src/config/url';

interface OwnProps {}
interface StateProps {
  wechat: any;
}
interface DispatchProps {
  getWechat: () => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  visible: boolean;
  qrCode: string;
}
class Wechat extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = { visible: false, qrCode: '' };
  }

  async componentDidMount () {
    await this.props.getWechat();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if ((!prevProps.wechat || !this.props.wechat) && prevProps.wechat !== this.props.wechat) {
      this.setState({ visible: false });
    }
  }

  getWechatQrCode = async () => {
    const { data } = await axios.get(`${config.WECHAT_URL}`, { withCredentials: true });

    this.setState({ qrCode: data.url });
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({ visible });
  }

  renderQrcode = () => {
    const { qrCode } = this.state;

    if (qrCode) {
      return (
        <img
          style={{ width: 256, height: 256 }}
          src={qrCode}
        />
      );
    } else {
      return null;
    }
  }

  render () {
    const { wechat } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <div>
          <h3 style={{ display: 'inline-block' }}>绑定微信号</h3>
          {!isEmpty(wechat) && (<h4 style={{ display: 'inline-block' }}> &nbsp;- 已绑定</h4>)}
        </div>
        <div className={styles.introduction}>
          绑定微信后，可以使用微信扫一扫登录，还可以通过chipcoo微信服务号接收消息通知。
        </div>
        <div className={styles.operation}>
          <Popover
            trigger="click"
            title="绑定微信"
            visible={visible}
            content={this.renderQrcode()}
            overlayClassName="wechatPopover"
            getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button type="primary" onClick={this.getWechatQrCode}>
              {!isEmpty(wechat) ? '重新绑定微信号' : '绑定微信号'}
            </Button>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapState = ({ session: { wechat } }: RootState) => ({ wechat });
const mapDispatch = ({ session: { getWechat } }: RootDispatch) => ({ getWechat });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Wechat);
