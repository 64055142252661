/**
 * 无聊天消息时候显示的占位图片
 */
import React, { memo } from 'react';

import styles from './index.module.less';

export default memo(() => (
  <div className={styles.noChatMessageWrapper}>
    <div className={styles.placeholder} />
  </div>
));
