import { createSelector } from 'reselect';
import { noop } from '@chipcoo/fe-utils';

import { UserModel } from './interface';
import { mapUsers as map, isEqualUserId } from 'src/utils/user';

export interface UserExtend {
  user: UserId;
  _id?: string | undefined;
}

function mapSingleUser<
  T extends UserExtend = UserExtend
>(member: T, byId: { [key: string]: User }, online: UserId[]) {
  const { user, _id, ...extra } = member;

  noop(_id);
  return {
    ...byId[user],
    ...extra,
    isOnline: online.some(id => isEqualUserId(id, _id)),
  } as User<T>;
}

export function createUserPicker<U extends UserExtend = UserExtend>() {
  return createSelector(
    (state: UserModel) => state.list.byId,
    (_: UserModel, list: U[] | { [key: string]: U }) => list,
    (state: UserModel) => state.online,

    (byId, list, online) => {
      if (Array.isArray(list)) {
        return list.map(member => mapSingleUser(member, byId, online));
      }

      return Object.keys(list).map((id) => {
        return mapSingleUser(list[id], byId, online);
      });
    }
  );
}

// See: https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
export function createMapUserSelector() {
  return createSelector(
    (state: UserModel) => state.list.byId,
    (_: UserModel, ids: UserId[]) => ids,
    (state: UserModel) => state.online,
    (byId, ids, online) => map(ids, byId).map(user => {
      return {
        ...user,
        isOnline: online.some(id => isEqualUserId(id, user._id)),
      } as User;
    }),
  );
}

export function createMapMemberSelector<T extends UserExtend = UserExtend>() {
  return createSelector(
    (normalized: NormalizedData<T>) => normalized,
    ({ byId, allIds }) => allIds.map(it => byId[it]),
  );
}

export const mapUserSelector = createMapUserSelector();
export const pickAllUsers = (() => {
  const selector = createMapUserSelector();
  return function(state: UserModel) {
    return selector(state, state.list.allIds);
  };
})();
