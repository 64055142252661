import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';

export interface UniverseComment {
  resource: IResource;
  message?: string;
  attachments?: string[];
}

export type IUComment = UniverseComment;

export interface CommentFetchParams extends IResource {
  limit?: number;
  // For fetch comments
  lastId?: string;
  // For fetch attachments;
  page?: number;
}

export default function(api: AxiosInstance) {
  //
  return {
    createComment(comment: UniverseComment, config?: AxiosRequestConfig) {
      return api.post('im/comment', comment, config);
    },
    deleteComment(commentId: string, config?: AxiosRequestConfig) {
      return api.delete(`im/comment/${commentId}`, config);
    },
    fetchComment(res: CommentFetchParams, config: AxiosRequestConfig = {}) {
      return api.get(
        `im/comment/${res.name}/${res.id}`,
        { ...config, params: omit(res, ['name', 'id']) }
      );
    },
    fetchAttachment(res: CommentFetchParams, config: AxiosRequestConfig = {}) {
      const { name, id, ...params } = res;

      return api.get(
        `im/comment/${name}/${id}/attachments`,
        { ...config, params }
      );
    },
    count(resource: UniverseComment['resource'], config?: AxiosRequestConfig) {
      const { name, id, role } = resource;
      const path = [name, id, role].filter(it => it).join('/');

      return api.get(`im/comment/${path}/count`, config);
    }
  };
}
