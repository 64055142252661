import React, { Component } from 'react';
import { __RouterContext } from 'react-router';
import { CacheRouteProps } from './interface';
import { CacheRouteComponent } from './components';

const { Consumer } = __RouterContext;

export class CacheRoute extends Component<CacheRouteProps> {
  static componentName = 'CacheRoute';
  refComponent: React.RefObject<CacheRouteComponent>;

  render() {
    return (
      <Consumer>
        {(context) => <CacheRouteComponent ref={this.refComponent} {...context} {...this.props}/>}
      </Consumer>
    );
  }
}

export default CacheRoute;
