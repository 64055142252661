import React from 'react';
import { bind } from 'lodash-decorators';
import { Input, Form } from 'antd';
import classnames from 'classnames';

import styles from './group.module.less';
import { validateForm } from 'src/utils/form';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  value?: string;
  onChange?: (val: string) => any;
  className?: string;
  disabledValues?: string[];
}

interface State {
  groupName: string;
  loading: boolean;
}

export class GroupName extends React.PureComponent<Props, State> {
  static defaultProps = {
    onChange(groupName: string) {
      this.setState({ groupName });
    },
    disabledValues: [],
  };

  inputRef = React.createRef<any>();
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const next: Partial<State> = {};

    if (nextProps.hasOwnProperty('value') && nextProps.value !== prevState.groupName) {
      next.groupName = nextProps.value;
    }

    return next;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      groupName: props.value || '',
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.props.form.setFieldsValue({ groupName: this.props.value });
    }
    setTimeout(() => {
      this.tryFocus();
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    if (value && value !== prevProps.value) {
      this.props.form.setFieldsValue({ groupName: value });
    }
  }

  tryGetValues() {
    return validateForm<{ groupName: string }>(this.props.form);
  }

  @bind
  tryFocus() {
    try {
      this.inputRef.current.focus();
    } catch (e) {
      console.error(e);
    }
  }

  @bind
  validateGroupName(_: any, value: any, callback: any) {
    let err;
    const { disabledValues } = this.props;

    if (disabledValues!.indexOf((value || '').trim()) !== -1) {
      err = '分组名重复';
    }

    callback(err);
  }

  @bind
  onChange(e: any) {
    this.props.onChange!.call(this, e.target.value);
  }

  render() {
    const { className, form: { getFieldDecorator } } = this.props;
    return (
      <Form className={classnames(styles.groupName, className)}>
        <Form.Item>
          {getFieldDecorator('groupName', {
            rules: [
              { required: true, message: '分组名不能为空' },
              { validator: this.validateGroupName },
            ]
          })(<Input className="cell" ref={this.inputRef} placeholder="分组名" />)}
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({
  onValuesChange(props: Props, changed: any) {
    if (changed.groupName && props.onChange) {
      props.onChange(changed.groupName);
    }
  }
})(GroupName);
