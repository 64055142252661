/**
 * 根据产品类型的不同选择不同的包装方式
 */
import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { SelectWithOption } from '@chipcoo/hanayo';

interface IProps {
  productInfos: any;
  value?: string;
  onChange?: (val: string) => void;
  status: FormStatus;
}

interface IState {
  options: any[];
}

const PackingMethodByProductModel: FC<IProps> = props => {
  const { productInfos, status, value, ...restProps } = props;

  const [state, setState] = useState<IState> ({
    options: [],
  });
  const { options } = state;

  const getMethods = () => {
    if (!_.isEmpty(productInfos)) {
      setState({
        ...state,
        options: productInfos?.packing?.map(item => ({
          label: item.packingMethod,
          key: item.packingMethod,
        })),
      });
    }
  };

  useEffect(() => {
    getMethods();
  }, [productInfos]);

  const renderComponent = (): any => {
    if (status === 'preview') {
      return <span title={value}>{value}</span>;
    } else {
      return (
        <SelectWithOption
          {...restProps}
          options={options}
          value={value}
        />
      );
    }
  };

  return renderComponent();
};

export default PackingMethodByProductModel;
