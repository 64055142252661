import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { trimString } from 'src/utils';
import { qcApi } from 'src/services/net';
import { AlertContact, AlertContactEdit, AlertContactsListParams } from 'src/services/net/qcDataMonitor';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setContactList(state: State, payload: { count: number, data: AlertContactEdit[] }) {
      return produce(state, draft => {
        draft.count = payload.count;
        draft.list = payload.data;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: AlertContactsListParams) {
      const { data } = await qcApi.getSubscriptionList(params);
      this.setContactList(data);
    },
    async getTableItemDetail() {
      //
    },
    async postCreate(payload: AlertContact) {
      await qcApi.postSubscriptionContact(trimString(payload));
    },
    async patchModify(payload: AlertContactEdit) {
      await qcApi.putSubscriptionContact(trimString(payload));
    },
    async deleteItem(_id: string) {
      await qcApi.deleteSubscriptionContact(_id);
    }
  })
});
