/**
 * Created by marvin on 2019/2/13.
 */
import { ROUTE_NAMES } from 'src/router/normalRouter';
import {
  mpwShuttle,
  preOrder,
  nto,
  inventory,
  shipment,
  queryReport,
  faq,
  // newTest,
} from 'src/assets/images/rdManage';

const appList: any[] = [
  {
    title: '流片',
    // src: inventory,
    children: [
      {
        title: 'MPW班车查询及预订',
        src: mpwShuttle,
        route: ''
      },
      {
        title: 'MPW班车预订记录',
        src: preOrder,
        route: ''
      },
      {
        title: 'NTO项目',
        src: nto,
        route: ''
      },
      {
        title: '库存',
        src: inventory,
        route: ''
      },
      {
        title: '出货记录',
        src: shipment,
        route: ''
      },
      {
        title: '报告查询',
        src: queryReport,
        route: '',
      },
      {
        title: '常见问题',
        src: faq,
        route: ''
      },
    ]
  },
  // {
  //   title: '测试',
  //   children: [
  //     {
  //       title: '新品测试',
  //       src: newTest,
  //       route: '',
  //     }
  //   ]
  // }
];

export function fillAppList() {
  appList[0].children[0].route = ROUTE_NAMES.ORG_RD_MPW_SHUTTLE;
  appList[0].children[1].route = ROUTE_NAMES.ORG_RD_MPW_PRE_ORDER;
  appList[0].children[2].route = ROUTE_NAMES.ORG_RD_NTO;
  appList[0].children[3].route = ROUTE_NAMES.ORG_RD_INVENTORY;
  appList[0].children[4].route = ROUTE_NAMES.ORG_RD_SHIPMENT;
  appList[0].children[5].route = ROUTE_NAMES.ORG_RD_QUERY_REPORT;
  appList[0].children[6].route = ROUTE_NAMES.ORG_RD_FAQ;
  // appList[1].children[0].route = ROUTE_NAMES.ORG_RD_TEST;
}

setTimeout(fillAppList);

export default appList;
