// tslint:disable:max-file-line-count
import React from 'react';
import { isEmpty } from 'lodash';
import {
  FormItemConfig,
  // SearchFormItemConfig,
  CustomFormItemConfig,
  // NumberFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { CardGroupConfig } from '@chipcoo/hanayo/lib/FormCreator/types/formConfig';
import {
  EnumDemandOrderType,
  EnumReceiptsCause,
} from '@chipcoo/constant';
import { getSelectedSimpleInfo } from '@chipcoo/fe-utils';

import styles from '../index.module.less';
import { Option } from '../formConfig';

import { openMaterialModal } from '../../DemandOrderMaterialModal/openMaterialModal';
import {
  CollectEngineeringData,
  ReliabilityTest,
} from './components';
import StockProcess from 'src/pages/Operation/Process';
import {
  files,
  description,
  demandOrderType,
  currentStatus,
  RejectReason,
  getPolicyById,
  hiddenSelectedData,
  hiddenProductModel,
  markingInfo,
  markingContent,
  selectionMode,
  getBlockButton,
  getProduct,
  orderTemplateId,
  getProductInfo,
  getProductHiddenFormItem,
  // quantityInfo,
  demandQuantity,
  demandWaferQuantity,
  expectedDeliveryDate,
  outSourcedOrderSerial,
  remark,
  selectedTableData,
  allowReplace,
  selectedDataInfo,
} from './demandOrderConfig/common';

const isCollectEngineeringData: CustomFormItemConfig = {
  label: '是否收集工程数据',
  type: 'custom',
  dataKey: 'isCollectEngineeringData',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择是否收集工程数据',
  render() {
    return <CollectEngineeringData status={this.formStatus} />;
  },
  renderType() {
    return this.formData?.type === EnumDemandOrderType.engineering ? 'normal' : 'none';
  }
};

const isReliabilityTest: CustomFormItemConfig = {
  label: '是否进行可靠性试验',
  type: 'custom',
  dataKey: 'isReliabilityTest',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择是否进行可靠性试验',
  render() {
    return <ReliabilityTest status={this.formStatus} />;
  },
  renderType() {
    return this.formData?.type === EnumDemandOrderType.engineering ? 'normal' : 'none';
  }
};

export const demandOrderInfo = (options: Option): CardGroupConfig => {
  const { productModel, classes } = options;

  return [
    {
      card: { title: '订单依据' },
      groupItemConfig: [
        files(),
        description(),
      ],
      ifRender() {
        return this.initialFormData?.cause === EnumReceiptsCause.other;
      }
    },
    {
      groupItemConfig: [
        currentStatus,
        RejectReason,
      ] as FormItemConfig[],
      ifRender() {
        const { status } = this.initialFormData;

        return status;
      }
    },
    {
      card: { title: '产品信息' },
      groupItemConfig: [
        demandOrderType,
        getProduct(options),
        orderTemplateId(productModel),
        getProductHiddenFormItem(),
        getPolicyById,
        getProductInfo(productModel),
        hiddenSelectedData(),
        hiddenProductModel(),
        markingInfo,
        markingContent,
        isCollectEngineeringData,
        isReliabilityTest,
        selectionMode,
        getBlockButton(options),
      ] as FormItemConfig[]
    },
    {
      type: 'repeater',
      dataKey: 'selectedMaterialInfo',
      showAddNewBtn: false,
      renderRepeater() {
        const { formStatus, formData, form, initialFormData } = this;
        const product = formData.product?.key;
        const type = formData.type;
        let selectedData = formData?.selectedData || [];

        function openMaterialSectionModal() {
          // 将选料modal传出来的值set到form上
          function onOk(val: Obj) {
            selectedData = val.selectedData;
            const info = getSelectedSimpleInfo(selectedData || []);

            form.setFieldsValue({
              selectedData: val.selectedData,
              quantity: info.quantity || val?.quantity,
              waferQuantity: info.waferQuantity || val?.waferQuantity,
            });
          }

          openMaterialModal({
            autoOpen: true,
            product,
            type,
            productModel,
            localDetail: { selectedData },
            classes: initialFormData?.classes,
            onOk
          });
        }

        return ({
          card: {
            title: '物料信息',
            extra: formStatus === 'preview' ? null : <a onClick={openMaterialSectionModal}>重新选料</a>
          },
          groupItemConfig: [
            selectedTableData(classes),
            allowReplace,
          ]
        });
      },
      ifRender() {
        return (this.formData?.selectionMode === 'auto' || this.formStatus === 'preview') &&
          !isEmpty(this.formData?.selectedData) && this.formData?.product;
      }
    },
    {
      card: {
        title: '订单信息',
      },
      groupItemConfig: [
        selectedDataInfo('terrace'),
        demandWaferQuantity(options, 'terrace'),
        demandQuantity(options, 'terrace'),
        { type: 'divider' },
        expectedDeliveryDate,
        outSourcedOrderSerial,
        remark,
      ] as FormItemConfig[],
      ifRender() {
        const { formData } = this;
        return formData?.selectedData || formData?.product;
      }
    },
    {
      groupItemConfig: [
        {
          type: 'custom',
          dataKey: 'stock-process',
          colSpan: 24,
          colClassName: styles.stockProcess,
          render() {
            return (
              <StockProcess demandOrderSerial={this.initialFormData?.serial} />
            );
          },
        }
      ],
      ifRender() {
        return this.formStatus === 'preview';
      }
    }
  ];
};
