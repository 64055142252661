import React from 'react';
import { Card } from 'antd';
import { 
  withPropsAPI,
  NodePanel,
  EdgePanel,
  GroupPanel,
  MultiPanel,
  CanvasPanel,
  DetailPanel,
  PropsAPIHocFix,
} from 'gg-editor';
import DetailForm from './DetailForm';
import NodeEditForm from './NodeEditor';
import EdgeEditForm from './EdgeEditor';
import styles from './index.module.less';

interface Props extends PropsAPIHocFix {}

class FlowDetailPanelRaw extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    console.log(props.propsAPI);
  }

  render() {
    return (
      <DetailPanel className={styles.detailPanel}>
        <NodePanel>
          <NodeEditForm />
        </NodePanel>
        <EdgePanel>
          <EdgeEditForm />
        </EdgePanel>
        <GroupPanel>
          <DetailForm type="group" />
        </GroupPanel>
        <MultiPanel>
          <Card type="inner" size="small" title="Multi Select" bordered={false} />
        </MultiPanel>
        <CanvasPanel>
          <Card type="inner" size="small" title="选项" bordered={false} />
        </CanvasPanel>
      </DetailPanel>
    );
  }
}

export default withPropsAPI(FlowDetailPanelRaw);
