/**
 * Created by marvin on 18/8/15.
 */

import * as React from 'react';
import { Card, Steps, Button, Checkbox } from 'antd';

import style from './index.module.less';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

interface Props {
  orgId: string;
  status: string | undefined;
}
interface State {
  current: number;
  checked: boolean;
}
const Step = Steps.Step;
class StartCertification extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      current: 0,
      checked: false,
    };
  }

  componentDidMount() {
    const { status } = this.props;
    if (!status) {
      return;
    } else if (status === 'PENDING') {
      this.setState({current: 2});
    } else if (status === 'REJECTED') {
      this.setState({current: 1});
    }
  }

  // 点击下一步
  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  // 点击上一步
  prev = () => {
    const { current } = this.state;
    const _current = current - 1;
    current > 0 && this.setState({ current: _current });
  }

  handleStepTwoNext = () => {
    this.setState({ current: 2 });
  }

  render () {
    const { orgId } = this.props;
    const { current, checked } = this.state;

    return (
      <Card bordered={false} className={style.startCertification}>
        <Steps current={current}>
          <Step title="同意认证协议" />
          <Step title="填写认证信息" />
          <Step title="申请完成" />
        </Steps>

        {current === 0 && (
          <>
            <StepOne/>
            <div className={style.buttons}>
              <p className={style.operationRow}>
                <Checkbox checked={checked} onChange={e => this.setState({checked: e.target.checked})}>
                  我同意并遵守上述的《芯合网组织认证服务协议》
                </Checkbox>

                <Button type="primary" disabled={!checked} onClick={() => this.next()}>下一步</Button>
              </p>
            </div>
          </>
        )}

        {current !== 0 && (
          <div className={`thin-scroll y-scroll ${style.content}`}>
            {current === 1 && (
              <StepTwo orgId={orgId} prev={this.prev} next={this.handleStepTwoNext} />
            )}
            {current === 2 && <StepThree/>}
          </div>
        )}
      </Card>
    );
  }
}

export default StartCertification;
