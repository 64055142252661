import * as React from 'react';
import { List, Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';

import styles from './index.module.less';

import { compilePath } from 'src/components/HoneyRouter';
import { HoverScrollBarContainer } from 'src/components';

interface Props {
  dataSource: any[];
  params: any;
}

class ManageAppList extends React.PureComponent<Props> {
  render () {
    const { dataSource, params } = this.props;

    return (
      <div className={styles.appWrap}>
        <HoverScrollBarContainer className={styles.appList} width={964}>
          <List
            grid={{ gutter: 20, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 6 }}
            dataSource={dataSource}
            renderItem={item => (
              <List.Item>
                <Link to={compilePath({ name: item.route, query: item.query, params })}>
                  <Card bordered={false}>
                    <Avatar src={item.src} shape="square" size={100} />
                    <div className={styles.title}>{item.title}</div>
                  </Card>
                </Link>
              </List.Item>
            )}
          />
        </HoverScrollBarContainer>
      </div>
    );
  }
}

export default ManageAppList;
