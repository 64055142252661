import React, { PureComponent } from 'react';
import { Popconfirm } from 'antd';
import { bind } from 'lodash-decorators';
import styles from './index.module.less';

interface Props {
  user: User;
  deleteFriend: (userId: UserId) => any;
}
interface State {}

export class DeleteFriend extends PureComponent<Props, State> {
  @bind
  async deleteFriend() {
    //
    const { user: { _id }, deleteFriend } = this.props;
    await deleteFriend(_id);
  }
  
  render() {
    return (
      <Popconfirm title="是否确认解除好友" onConfirm={this.deleteFriend}>
        <a className={styles.deleteFriend} href="#">解除好友</a>
      </Popconfirm>
    );
  }
}

export default DeleteFriend;
