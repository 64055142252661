import * as React from 'react';
import { CardTableV2 } from '@chipcoo/hanayo';
import { EnumProcessClass } from '@chipcoo/constant';

import { getTableColumns } from './getTableColumns';

const { EnhancedTable } = CardTableV2;

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  processClass: EnumProcessClass;
  productName: string;
  packageType: Obj;
  processAmount: number;
}

class CurrentInfo extends React.PureComponent<Props> {
  mountRef = React.createRef<HTMLDivElement>();
  private tableColumns;

  constructor(props: Props) {
    super(props);

    this.tableColumns = getTableColumns({
      processClass: props.processClass,
      packageType: props.packageType?.packageType
    });
  }

  render () {
    const { value, productName, packageType } = this.props;
    const dataSource = value?.map(it => ({
      ...it,
      productName,
      packageTypeLW: packageType?.packageTypeLW,
    }));

    return (
      <div ref={this.mountRef}>
        <EnhancedTable
          getTableData={() => {
            //
          }}
          dataSource={dataSource || []}
          showPagination={false}
          columns={this.tableColumns}
        />
      </div>
    );
  }
}

export default CurrentInfo;
