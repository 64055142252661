// 智能选料及订单下单的context
import * as React from 'react';

export type ICheckFrom = 'smart' | 'check';

export interface IOrderContext {
  perWaferDieQty: number;
  submitTableData?: (val: any[]) => void;
}

export const DemandOrderContext = React.createContext<IOrderContext>({
  perWaferDieQty: 0,
});
