/**
 * 运营管理-委外需求订单
 */
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PRODUCT_TYPE } from '@chipcoo/constant';

import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { getRouterProps } from 'src/components/HoneyRouter';

import SiderMenuRoute from '../SiderMenuRoute';
import DemandOrder from './DemandOrder';

interface Props extends RouteComponentProps<any> {}

let menus: RouteMenuItem[] = [];

function fillMenu() {
  menus = [
    {
      title: PRODUCT_TYPE['assembly-product'],
      routeName: ROUTE_NAMES.ORG_OPERATION_ORDER_ASSEMBLY,
      defaultSelected: true,
      routeProps: {
        component: DemandOrder,
        exact: true,
      }
    },
    {
      title: PRODUCT_TYPE['dp-product'],
      routeName: ROUTE_NAMES.ORG_OPERATION_ORDER_DP,
      routeProps: {
        component: DemandOrder,
        exact: true,
      }
    },
    // {
    //   title: EnumOrderTypeText.die,
    //   routeName: ROUTE_NAMES.ORG_OPERATION_ORDER_CHIP,
    //   routeProps: {
    //     component: DemandOrderTable,
    //     exact: true,
    //   }
    // },
    // {
    //   title: EnumOrderTypeText.cpDie,
    //   routeName: ROUTE_NAMES.ORG_OPERATION_ORDER_CP,
    //   routeProps: {
    //     component: DemandOrderTable,
    //     exact: true,
    //   }
    // }
  ];
}

setTimeout(fillMenu);

class Order extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }

  render () {
    const routerProps = getRouterProps(this.props);

    return (
      <SiderMenuRoute menus={menus} routerProps={routerProps} />
    );
  }
}

export default Order;
