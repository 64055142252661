import * as React from 'react';
import { isEmpty } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';

import { TableCheckBoxGroup } from 'src/containers';

type Option = {
  // 表格是否可以编辑
  isEditable?: boolean;
  // 是否需要同时存在片号和bin这两列
  doubleCol?: boolean;
  // 当面可选物料列表面板在何页
  tabActiveKey?: EnumProductModel | undefined;
};

export const getTableColumns = ({ doubleCol, isEditable, tabActiveKey = undefined }: Option) => {

  /**
   * 这里展示的是来源物料列表；
   * 封装的来源物料是片形式
   * 成测ft来源物料是成品颗形式
   */
  const waferId = {
    title: '片号',
    dataIndex: 'waferIds',
    key: 'waferIds',
    width: 150,
    render: text => {
      return text ? '#' + text : '/';
    }
  };
  const bin = {
    title: 'Bin类型',
    dataIndex: 'binType',
    key: 'binType',
    width: 400,
  };

  let column: any[] = [];
  if (doubleCol) {
    column = [waferId, bin];
  } else {
    if (tabActiveKey === EnumProductModel.assemblyProduct) column = [bin];
    else column = [waferId];
  }

  return [
    {
      dataIndex: 'checkbox',
      key: 'checkbox',
      width: 100,
      fixed: 'left' as 'left',
      render: (text, record) => {
        return <TableCheckBoxGroup record={record} />;
      }
    },
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
      fixed: 'left' as 'left',
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: '物料名称',
      width: 200,
    },
    {
      dataIndex: 'attributesText',
      key: 'attributesText',
      title: '是否为蓝膜',
      width: 100,
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 200
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      width: 200,
    },
    {
      title: '芯片批号',
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      width: 200,
    },
    {
      title: '印章批号',
      dataIndex: 'stampNumber',
      key: 'stampNumber',
      width: 200,
    },
    ...column,
    {
      dataIndex: 'good',
      key: 'good',
      title: '良品数量',
      width: 100,
      // editable: (record) => {
      //   return isEditable && record?.productModel === EnumProductModel.dpProduct;
      // },
      render: (text, record) => {
        const { productModel, children } = record;
        return productModel === EnumProductModel.assemblyProduct && isEmpty(children) ? record.amount : text;
      }
    },
    {
      dataIndex: 'bad',
      key: 'bad',
      title: '不良品数量',
      width: 100,
      // editable: (record) => {
      //   return isEditable && record?.productModel === EnumProductModel.dpProduct;
      // },
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总数量',
      width: 150,
      editable: (record) => {
        return isEditable && record?.productModel === EnumProductModel.assemblyProduct;
      },
      render: (text, record) => {
        const { desc, good = 0, bad = 0 } = record;
        // 只有dp的子行需要重新计算amount，有desc就代表是dp的子行
        return desc ? good + bad : text;
      }
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '库存状态',
      width: 100
    },
    // {
    //   dataIndex: 'frozenText',
    //   key: 'frozenText',
    //   title: '是否冻结',
    //   width: 300
    // }
  ];
};
