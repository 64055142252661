import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface.d';
import { TableModelCommonEffects } from 'src/models/type';
import { qcApi } from 'src/services/net';
import { handleFtpList, handleFtp } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getFtpList(params);
      const handleData = handleFtpList(data?.data);

      this.setList({
        count: data?.count,
        data: handleData,
      });
    },
    async getTableItemDetail() {
      //
    },
    async postCreate(payload: any) {
      const handleData = handleFtp(payload);
      await qcApi.postFtp(handleData);
    },
    async patchModify(payload: any) {
      const handleData = handleFtp(payload);
      await qcApi.putFtp(handleData);
    },
    async deleteFtp(id: string) {
      await qcApi.delFtp(id);
    }
  })
});
