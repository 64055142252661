import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { bind } from 'lodash-decorators';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Tooltip } from 'antd';
import { getUserName, fillImgSrc, getWorkspaceName } from '@chipcoo/fe-utils';

import style from './card.module.less';

import url from 'src/config/url';
import { Workspace } from 'src/models/workspace/interface';
import { compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';

interface Props extends RouteComponentProps {
  workspace: Workspace;
  owner: User;
  onToggle: (workspace: Workspace) => any;
  flippedProps: any;
}

interface States {
  loading: boolean;
}

export class WorkspaceCard extends PureComponent<Props, States> {
  state = {
    loading: false,
  };

  @bind
  async handleToggle(e: React.MouseEvent<HTMLElement>) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    const { workspace, onToggle } = this.props;
    try {
      await onToggle(workspace);
    } catch (e) {
      console.error(e);
    }

    this.setState({ loading: false });
  }

  render() {
    const { match: { params }, workspace, owner, flippedProps } = this.props;
    const { isStar } = workspace;
    const { loading } = this.state;
    let tooltipText = isStar ? '取消星标' : '设为星标';

    if (loading) {
      tooltipText = '正在处理...';
    }

    return (
      <li className={style.itemCard} {...flippedProps}>
        <Link
          to={compilePath({ name: ROUTE_NAMES.ORG_COO_DETAIL, params: { ...params, workspaceId: workspace._id } })}
        >
          <div
            className={style.cardContent}
            style={{ backgroundImage: `url('${fillImgSrc(workspace.avatar, url.staticUrl)}')` }}
          >
            <div className={style.mask} />
            <div className={classnames(style.cardHeader, 'flex align-center')}>
              <span className="ellipsis cell owner-name">
                <i className="c-icon icon-account " />{getUserName(owner)}
              </span>

              <Tooltip title={tooltipText}>
                <i
                  onClick={this.handleToggle}
                  className={classnames(
                    'c-icon star-icon',
                    loading ? 'icon-loader-1 loading' : 'icon-star',
                    isStar ? 'stared' : ''
                  )}
                />
              </Tooltip>
            </div>
          </div>
          <div
            className={`${style.cardTitle}${(workspace.isMaster &&
              ' '.concat(style.isMaster)) ||
              ''}`}
          >
            {workspace.isMaster ?
              (<><i className="c-icon icon-crown" /></>)
              : ''
            }
            <span>{getWorkspaceName(workspace)}</span>
          </div>
        </Link>
      </li>
    );
  }
}

export default WorkspaceCard;
