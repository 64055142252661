import { createSelector } from 'reselect';
import { Notify } from './interface';

export function createNotiSelector() {
  return createSelector(
    (byId: { [key: string]: Notify }) => byId,
    (_:  { [key: string]: Notify }, ids: string[]) => ids,
    (byId, ids) => {
      return ids.reduce((all, id) => {
        if (byId[id]) {
          all.push(byId[id]);
        }
        return all;
      }, [] as Notify[]);
    }
  );
}

export const selectCurrentNoti = createNotiSelector();
