import React from 'react';
import { EnumProductModel } from '@chipcoo/constant';
import { IEditableColumnProps } from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';

interface Option {
  productModel: EnumProductModel;
  openModal: (id: string) => void;
}

const getDieName = (title, isFixed = true) => ({
  title,
  dataIndex: 'customerName',
  key: 'customerName',
  width: 242,
  fixed: isFixed ? 'left' as 'left' : false,
});

const principal = {
  title: '负责人',
  key: 'principalName',
  dataIndex: 'principalName',
  width: 150,
};

const maskProperty = {
  title: '制版性质',
  key: 'maskProperty',
  dataIndex: 'maskProperty',
  width: 88,
};

const initialVersionDieName = {
  title: '母版芯片名',
  key: 'initialVersionDieName',
  dataIndex: 'initialVersionDieName',
  width: 172,
};

const reversion = {
  title: '版本',
  key: 'reversion',
  dataIndex: 'reversion',
  width: 88,
};

const maskSetupTime = {
  title: '制版时间',
  key: 'maskSetupTime',
  dataIndex: 'maskSetupTime',
  width: 130,
};

const getFactories = (title) => ({
  title: title ? `${title}/状态` : '状态',
  dataIndex: 'factories',
  key: 'factories',
  width: 284,
});

const waferDiameter = {
  title: '晶圆直径',
  key: 'waferDiameter',
  dataIndex: 'waferDiameter',
  width: 88,
};

const waferTechnology = {
  title: '工艺节点',
  key: 'waferTechnology',
  dataIndex: 'waferTechnology',
  width: 88,
};

const dieSize = {
  title: '芯片尺寸(不含划片道)',
  key: 'dieSize',
  dataIndex: 'dieSize',
  width: 200,
};

const perWaferDieQty = {
  title: '每片有效管芯数',
  key: 'perWaferDieQty',
  dataIndex: 'perWaferDieQty',
  width: 88,
};

/*************************** 封装成品 **********************************/
const packageType = {
  title: '封装类型',
  key: 'packageType',
  dataIndex: 'packageType',
  width: 102,
};

const packageTypeLW = {
  title: '封装型号(引线框架类)',
  key: 'packageTypeLW',
  dataIndex: 'packageTypeLW',
  width: 186,
};

const dieName = {
  title: '芯片名',
  key: 'dieName',
  dataIndex: 'dieName',
  width: 172,
};

const environmentalRequirement = {
  title: '环保要求',
  key: 'environmentalRequirement',
  dataIndex: 'environmentalRequirement',
  width: 88,
};

const MSL = {
  title: '防潮等级',
  key: 'MSL',
  dataIndex: 'MSL',
  width: 88,
};

const markingMethod = {
  title: '打印方式',
  key: 'markingMethod',
  dataIndex: 'markingMethod',
  width: 88,
};

const markingRule = {
  title: '打印规则',
  key: 'markingRule',
  dataIndex: 'markingRule',
  width: 144,
};

const packingMethod = {
  title: '包装方式',
  key: 'packingMethod',
  dataIndex: 'packingMethod',
  width: 130,
};

const getOperation = (option: Option) => {
  return {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    fixed: 'right' as 'right',
    width: 120,
    render: (text, record) => {
      const openModal = (e) => {
        option.openModal(record._id);
      };

      return (
        <a onClick={openModal}>查看</a>
      );
    }
  };
};

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  const { productModel } = option;

  switch (productModel) {
    case EnumProductModel.dpProduct:
      return [
        getDieName('磨划成品名'),
        principal,
        dieName,
        waferDiameter,
        waferTechnology,
        packingMethod,
        getFactories('磨划厂'),
        getOperation(option)
      ];
    case EnumProductModel.assemblyProduct:
      return [
        getDieName('封装成品名'),
        principal,
        packageType,
        packageTypeLW,
        dieName,
        environmentalRequirement,
        MSL,
        markingMethod,
        markingRule,
        packingMethod,
        getFactories('封装厂'),
        getOperation(option)
      ];
    default:
      return [
        getDieName('芯片名'),
        principal,
        maskProperty,
        initialVersionDieName,
        reversion,
        maskSetupTime,
        getFactories('流片厂'),
        waferDiameter,
        waferTechnology,
        dieSize,
        perWaferDieQty,
        getOperation(option),
      ];
  }
};
