import React from 'react';
import { Icon, Divider } from 'antd';
import { inventoryApi } from 'src/services/net';
import { ModalType } from '../StockOutTable';

interface CardExtraProps {
  queryParams: any;
  openQuery: (e: any) => void;
  openModal?: (detailId: string, modalType: ModalType) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery, queryParams } = props;
  // const openAddModal = () => {
  //   openModal!('', 'new');
  // };

  return (
    <div>
      <a href={inventoryApi.exportStockOutApply({...queryParams})}>
        <Icon type="export" />导出
      </a>
      <Divider type="vertical" />
      <a onClick={openQuery}>
        <Icon type="search" />查询
      </a>
      {/* <Divider type="vertical" /> */}
      {/* <Button type="primary" onClick={openAddModal}>添加</Button> */}
    </div>
  );
}

export default React.memo(CardExtra);
