const EventEmitter = require('events');

export const Emitter = new EventEmitter();

// 事件总线中的事件名称
export const NEW_GLOBAL_MODAL = 'NEW_GLOBAL_MODAL';

// 表单中添加Card的时候，广播一个事件，用来动态变化导航
export const ADD_CARD_IN_FORM = 'ADD_NEW_CARD_IN_FORM';

// 表单中删除一个Card的时候，广播一个事件，用来动态变化导航
export const DEL_CARD_IN_FORM = 'DEL_CARD_IN_FORM';

export const NEW_GLOBAL_PORTAL = 'NEW_GLOBAL_PORTAL';

export const DESTROY_GLOBAL_PORTAL = 'DESTROY_GLOBAL_PORTAL';
