/**
 * Created by marvin on 18/3/1.
 */

import * as React from 'react';
import { Card, Button } from 'antd';

interface Props {
  loading?: boolean;
  onStart?: () => void;
}

class ReadyStart extends React.PureComponent<Props> {
  render () {
    const { onStart, loading } = this.props;

    return (
      <Card title="组织认证须知" bordered={false} loading={loading}>
        <p>
          您的组织还未进行组织认证，通过组织认证后您的组织将获得业务办理通道，同时也能更好地保护组织的合法权益。<br/>
          申请组织认证需上传营业执照（若为复印件需加盖企业公章）。
        </p>
        <Button type="primary" onClick={onStart} style={{marginTop: 16}}>
          开始申请认证
        </Button>
      </Card>
    );
  }
}

export default ReadyStart;
