import { EnumRequirementOrderStatus } from '@chipcoo/constant';

export const gridsConfig = (gridInfos) => {
  return [
    {
      title: '全部订单',
      value: gridInfos?.count,
      key: 'ALL',
    },
    {
      title: '待审核',
      value: gridInfos?.status?.CREATED,
      key: EnumRequirementOrderStatus.created,
    },
    {
      title: '待生产',
      value: gridInfos?.status?.ACCEPTED,
      key: EnumRequirementOrderStatus.accepted,
    },
    {
      title: '生产中',
      value: gridInfos?.status?.MANUFACTURING,
      key: EnumRequirementOrderStatus.manufacturing
    },
    {
      title: '已入库',
      value: gridInfos?.status?.STOCK_IN,
      key: EnumRequirementOrderStatus.stockIn
    },
    {
      title: '已完成',
      value: gridInfos?.status?.FINISHED,
      key: EnumRequirementOrderStatus.finished
    },
    {
      title: '已驳回',
      value: gridInfos?.status?.REJECTED,
      key: EnumRequirementOrderStatus.rejected
    }
  ];
};
