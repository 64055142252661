import React from 'react';
import RcUpload from 'rc-upload';
import classNames from 'classnames';
import { Upload } from 'antd';
import LocaleReceiver from 'antd/lib/locale-provider/LocaleReceiver';
import defaultLocale from 'antd/lib/locale-provider/default';
import { UploadFile } from 'antd/lib/upload/interface';
import { ConfigConsumer } from 'antd/lib/config-provider';
import { bind } from 'lodash-decorators';

const Consumer = ConfigConsumer as any;

export class UploadEnhance extends Upload {
  private _upload: typeof RcUpload;

  get uploader() {
    return this._upload && this._upload.uploader;
  }

  @bind
  saveUploadInstance(node: typeof RcUpload) {
    this._upload = node;
  }

  handleManualRemove = (file: UploadFile) => {
    this._upload.abort(file);
    file.status = 'removed'; // eslint-disable-line
    this.handleRemove(file);
  }

  renderUpload = () => {
    const {
      className,
      showUploadList,
      listType,
      type,
      disabled,
      children,
    } = this.props;
    const prefixCls = this.props.prefixCls || 'ant-upload';

    const rcUploadProps = {
      onStart: this.onStart,
      onError: this.onError,
      onProgress: this.onProgress,
      onSuccess: this.onSuccess,
      ...this.props,
      beforeUpload: this.beforeUpload,
    };

    delete rcUploadProps.className;

    const uploadList = showUploadList ? (
      <LocaleReceiver componentName="Upload" defaultLocale={defaultLocale.Upload}>
        {this.renderUploadList}
      </LocaleReceiver>
    ) : null;

    if (type === 'drag') {
      const dragCls = classNames(prefixCls, {
        [`${prefixCls}-drag`]: true,
        [`${prefixCls}-drag-uploading`]: this.state.fileList.some(
          file => file.status === 'uploading',
        ),
        [`${prefixCls}-drag-hover`]: this.state.dragState === 'dragover',
        [`${prefixCls}-disabled`]: disabled,
      });
      return (
        <span className={className}>
          <div
            className={dragCls}
            onDrop={this.onFileDrop}
            onDragOver={this.onFileDrop}
            onDragLeave={this.onFileDrop}
          >
            <RcUpload {...rcUploadProps} ref={this.saveUploadInstance} className={`${prefixCls}-btn`}>
              <div className={`${prefixCls}-drag-container`}>{children}</div>
            </RcUpload>
          </div>
          {uploadList}
        </span>
      );
    }
    const uploadButtonCls = classNames(prefixCls, {
      [`${prefixCls}-select`]: true,
      [`${prefixCls}-select-${listType}`]: true,
      [`${prefixCls}-disabled`]: disabled,
    });
    const uploadButton = (
      <div className={uploadButtonCls} style={{ display: children ? '' : 'none' }}>
        <RcUpload {...rcUploadProps} ref={this.saveUploadInstance} />
      </div>
    );
    if (listType === 'picture-card') {
      return (
        <span className={className}>
          {uploadList}
          {uploadButton}
        </span>
      );
    }
    return (
      <span className={className}>
        {uploadButton}
        {uploadList}
      </span>
    );
  }

  render() {
    return <Consumer>{this.renderUpload}</Consumer>;
  }
}

export default UploadEnhance;
