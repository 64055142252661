import * as React from 'react';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';

import styles from './index.module.less';
import { craftRouteApi } from 'src/services/net';
import { ICraftRouteFullRoute } from 'src/services/net/craftRoute';

import CraftRouteCard from './CraftRouteCard';

interface Props {
  productId: string;
}

interface State {
  craftRoutes: any[];
}

class ProductCraftRoute extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      craftRoutes: []
    };
  }

  async componentDidMount() {
    try {
      const { productId } = this.props;
      const { data } = await craftRouteApi.detail({ productId });

      this.setState({ craftRoutes: data });
    } catch (e) {
      console.error(e);
    }
  }

  renderCraftRouteCard = (craftRoute: ICraftRouteFullRoute, index: number) => {
    // const { modalName } = this.props;
    return (
      <CraftRouteCard
        key={craftRoute._id}
        index={index}
        cardClassName={styles.craftRouteCard}
        // modalName={modalName}
        craftRoute={craftRoute}
      />
    );
  }

  render () {
    const { craftRoutes } = this.state;

    if (isEmpty(craftRoutes)) {
      return <Empty description="暂无工艺路线" />;
    }

    return (
      <>
        {craftRoutes.map(this.renderCraftRouteCard)}
      </>
    );
  }
}

export default ProductCraftRoute;
