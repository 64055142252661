/**
 * 客户物料库存列表
 */
import React, { ComponentType, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { TableCategory } from '@chipcoo/hanayo';
import { EnumProcessClass, EnumProductModel } from '@chipcoo/constant';

import styles from './index.module.less';
import store from 'src/store';

import { categoryConfig } from './components/categoryConfig';
import InventoryTableList from './InventoryTableList';

interface OwnProps {
  processClass?: EnumProcessClass;
}

interface StateProps {
}
interface DispatchProps {
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  category: EnumProductModel | 'ALL';
}

class InventoryTable extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      category: 'ALL',
    };
  }

  setCategory = (category: S['category']) => {
    this.setState({ category }, () => {
      const { history, location } = this.props;

      // 如果是从通知点击过来的，每次点击分类切换后，重置
      if (location.search) {
        history.push(location.pathname);
      }
    });
  }

  render() {
    const { processClass } = this.props;
    const { category } = this.state;
    const statistics = store.getState().inventory.statistics;

    return (
      <div className={classNames(styles.stockList, 'wrap-content')}>
        <TableCategory
          defaultCategory="ALL"
          title="产品类型"
          statistics={statistics}
          setCategory={this.setCategory}
          categoryConfig={categoryConfig}
        />

        <InventoryTableList category={category} processClass={processClass} />
      </div>
    );
  }
}

export default InventoryTable as ComponentType<OwnProps>;
// tslint:disable:max-file-line-count
