import { get } from 'lodash';
import { 
  ICraftRouteItemLocal, 
  ICraftMaterial,
  ICraftRouteItem,
} from 'src/services/net/craftRoute';
import { toTreeGraph } from './to-tree-graph';
import { flatCraftRouteItemLocal } from './flat';
import { craftRouteToGraph } from './craft-route-to-graph';
import { isDev } from 'src/utils';

type TCraftRoute = ICraftRouteItemLocal | ICraftRouteItem;

export class CraftRouteManage {
  static getPartialCraftRouteLocal(craftRoute: TCraftRoute, targetMaterial: string, source: string | string[] = []) {
    const sourceMaterial = Array.isArray(source) ? source : [source];
    const treeCraftRoute = toTreeGraph(craftRoute);

    const flatItems = flatCraftRouteItemLocal(treeCraftRoute);
    const targetItem = flatItems.find(it => {
      if ('type' in it) {
        return it.id === targetMaterial;
      }

      return it.material._id === targetMaterial;
    }) as ICraftRouteItemLocal | undefined;

    if (!targetItem) {
      const error = new Error('targetItem not found'); 
      const errorDetail = {
        argv: [].slice.call(arguments),
        treeCraftRoute,
        flatItems,
        targetItem,
      };
      if (isDev) {
        console.log(errorDetail);
      }
      throw error;
    }

    sourceMaterial.forEach(sourceId => {
      const sourceItem = flatItems.find(it => {
        if ('type' in it) {
          return false;
        }

        return it.material._id === sourceId;
      }) as ICraftRouteItemLocal;

      if (sourceItem) {
        sourceItem.isEnd = true;
      }
    });

    return targetItem;
  }

  craftRoute: TCraftRoute;
  treeCraftRoute: ICraftRouteItemLocal;
  flatItems: (ICraftRouteItemLocal | ICraftMaterial)[];

  constructor(craftRoute: TCraftRoute, target?: string, source?: string | string[]) {
    this.updateCraftRoute(craftRoute, target, source);
  }

  updateCraftRoute(craftRoute: TCraftRoute, target?: string, source?: string | string[]) {
    if (target) {
      this.craftRoute = CraftRouteManage.getPartialCraftRouteLocal(craftRoute, target, source);
      this.treeCraftRoute = this.craftRoute as ICraftRouteItemLocal;
    } else {
      this.craftRoute = craftRoute;
      this.treeCraftRoute = toTreeGraph(this.craftRoute);
    }

    this.flatItems = flatCraftRouteItemLocal(this.treeCraftRoute);
  }

  getDisabledMaterialId(targetMaterial: string): string[] {
    const ids: string[] = [];
    const node = this.flatItems.find(it => {
      return get(it, '_id') === targetMaterial || get(it, 'id') === targetMaterial;
    });

    if (node) {
      if (!('type' in node)) {
        const flatItemsChildren = flatCraftRouteItemLocal(node).map(it => {
          if ('type' in it) {
            return it.id;
          }

          return it.material._id;
        });

        ids.push(...flatItemsChildren);
      }

      let parent = node.parent;

      while (parent) {
        ids.push(parent.material._id);
        parent = node.parent;
      }
    }

    return ids;
  }

  getGraphData(localCraft: ICraftRouteItemLocal = this.treeCraftRoute) {
    return craftRouteToGraph(localCraft);
  }
} 
