// import _ from 'lodash';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';

// 加工单号、客户名称、加工类型、加工依据、加工依据单号、加工前物料名称、加工前产品名称、加工前物料批号、加工操作、加工厂、要求供应商交期、加工状态、芯片批号、印章批号、加工单创建时间、指令下达人
export const getFilterFormConfig = (): FormItemConfig[] => [
  {
    label: '加工单号',
    type: 'input',
    dataKey: 'serial',
  },
  // {
  //   label: '加工操作',
  //   type: 'search',
  //   dataKey: 'process',
  //   optionKey: 'manufactureOperation',
  //   props: { addNew: null }
  // },
  // {
  //   label: '加工依据',
  //   type: 'select',
  //   dataKey: 'reason',
  //   optionKey: 'stockProcessReason',
  // },
  // {
  //   label: '加工依据单号',
  //   type: 'input',
  //   dataKey: 'reasonSerial',
  // },
  // {
  //   label: '加工前物料名称',
  //   type: 'input',
  //   dataKey: 'inputMaterialName',
  // },
  // {
  //   label: '加工前物料批号',
  //   type: 'input',
  //   dataKey: 'inputMaterialNumber',
  // },
  // {
  //   label: '加工厂',
  //   type: 'search',
  //   dataKey: 'factory',
  //   optionKey: 'allFactory',
  //   props: { addNew: null }
  // },
  // {
  //   label: '要求供应商交期',
  //   type: 'rangeDate',
  //   dataKey: 'demandTime',
  // },
  {
    label: '加工状态',
    type: 'select',
    dataKey: 'status',
    optionKey: 'stockProcessStatus',
  },
  // {
  //   label: '加工后物料名称',
  //   type: 'input',
  //   dataKey: 'outputMaterialName',
  // },
  // {
  //   label: '加工后物料批号',
  //   type: 'input',
  //   dataKey: 'outputMaterialNumber',
  // },
  {
    label: '芯片批号',
    type: 'input',
    dataKey: 'outputDieNumber',
  },
  {
    label: '加工后印章批号',
    type: 'input',
    dataKey: 'outputStampNumber',
  },
  {
    label: '加工单创建时间',
    type: 'rangeDate',
    dataKey: 'createdAt',
  },
];
