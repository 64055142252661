import * as React from 'react';
import {
  FormConfig,
  FormItemConfig,
  // InputFormItemConfig,
  CustomFormItemConfig,
  NumberFormItemConfig,
  TextAreaFormItemConfig,
  SearchFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';

import BrekpointTable from './components/BreakpointTable';

const ntoName: SearchFormItemConfig = {
  label: '产品信息',
  type: 'search',
  dataKey: 'ntoName',
  optionKey: 'initialitionNtoListInLot',
  props: { addNew: null }
};

const waferAmount: NumberFormItemConfig = {
  label: 'wafer数量',
  type: 'number',
  dataKey: 'waferAmount',
};

const outputAmount: NumberFormItemConfig = {
  label: '产出数量',
  type: 'number',
  dataKey: 'outputAmount',
};

const stops = (mode: ModalMode): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'stops',
  render: () => {
    return <BrekpointTable status={mode} />;
  },
  colSpan: 24,
});

const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
};

export const getFormConfig = (mode: ModalMode): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '指令信息' },
      groupItemConfig: [
        ntoName,
        waferAmount,
        outputAmount,
        stops(mode),
        remark
      ] as FormItemConfig[],
    }
  ]
});
