import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { getRouterProps } from 'src/components/HoneyRouter';

import { SiderMenuRoute } from 'src/components';
import NtoList from './NtoList';
import Lot from './Lot';

interface Props extends RouteComponentProps<any> {}

let menus: RouteMenuItem[] = [];
function fillMenu() {
  menus = [
    {
      title: 'NTO产品',
      routeName: ROUTE_NAMES.ORG_RD_NTO_PRODUCT,
      routeProps: {
        component: NtoList,
        exact: true,
      }
    },
    {
      title: 'LOT指令',
      routeName: ROUTE_NAMES.ORG_RD_NTO_LOT,
      routeProps: {
        component: Lot,
        exact: true,
      }
    },
  ];
}

setTimeout(fillMenu);

class Nto extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }
  render() {
    const routeProps = getRouterProps(this.props);

    return (
      <SiderMenuRoute menus={menus} routerProps={routeProps} />
    );
  }
}

export default Nto;
