import { getMomentTime } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';

export const handleList = (list: any[]) => {
  return (list || []).map(it => {
    const { _id, type, createdAt, status, dieNumbers, products } = it;
    let productNames;

    if (products) {
      productNames = products?.map(p => `${p.customerName}(${p.chipcooName})`);
    }

    return {
      ...it,
      key: _id,
      typeText: optionsGroup.abnormalityType.options?.[type],
      createdAtText: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      statusText: optionsGroup.abnormalityStatus.options?.[status],
      dieNumbers: dieNumbers?.join(', '),
      productName: productNames?.join(', ')
    };
  });
};

export const handleDetail = (detail) => {
  const { resource } = detail;
  const key = resource?.[0]?.name === 'demand-order' ? 'order' : 'material';

  return {
    ...detail,
    [key]: resource.map(it => ({
      ...it,
      resource: {
        ...it?.resource,
      }
    })),
  };
};
