/**
 * 产品库左侧的列表
 */
import React, { PureComponent, ComponentType } from 'react';
import { get } from 'lodash';

import styles from './index.module.less';
import { Actions, connectDecorator, RootState } from 'src/store';
// import { FOCUS_FACTORY_ID_KEY } from 'src/config/const';

import { ListItem, ThreeBounceLoading } from 'src/components';

interface OwnProps {
  setUrl: (providerId: string) => void;
  focusedFactoryId: string | undefined;
}
interface StateProps {
  factoryList: any[];
}
interface DispatchProps {
  getFactoryList: (params: any) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {
  listLoading: boolean;
}

@connectDecorator<StateProps, DispatchProps, OwnProps>(
  ({ mpwShuttle: { factoryList } }: RootState) => ({
    factoryList
  }),
  ({ mpwShuttle: { getFactoryList } }: Actions) => ({
    getFactoryList,
  })
)
class FactoryList extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = { listLoading: false };
  }

  async componentDidMount() {
    this.setState({ listLoading: true });

    await this.props.getFactoryList({ limit: 100 });

    this.setState({ listLoading: false });
  }

  renderListContent = () => {
    const { focusedFactoryId, factoryList, setUrl } = this.props;

    return (
      <>
        <div className="thin-scroll y-scroll">
          {factoryList.map(item => {
            return (
              <ListItem
                providerInfo={item}
                key={item._id}
                setUrl={setUrl}
                isFocused={item._id === focusedFactoryId}
              />
            );
          })}
        </div>
      </>
    );
  }

  renderEmptyFactoryList = () => {
    return (
      <div className={styles.emptyFactoryList}>暂无有班车的工厂</div>
    );
  }

  render() {
    const { factoryList } = this.props;
    const { listLoading } = this.state;

    return (
      <div className={`${styles.factoryListWrapper} flex-static`}>
        {
          listLoading
            ? <ThreeBounceLoading style={{ margin: '16px 0' }} />
            : (
              !get(factoryList, 'length') ? this.renderEmptyFactoryList() : this.renderListContent()
            )
        }
      </div>
    );
  }
}

export default FactoryList as ComponentType<OwnProps>;
