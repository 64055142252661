/**
 * 一些xxx 删除了消息之类的固定的文字的获取
 * 注意：info === 'DELETED' 目前只出现在src/pages/IM/ChatPanel/MessagePanel/MessageItem/DeletedMessage中
 * roomCard中使用的时候直接传入的是messageData
 * @param userName
 * @param info
 */
type Info = {
  message: string;
  attachments: any[];
  type: 'MESSAGE' | 'DELETED' | 'SYSTEM';
} | 'DELETED';
export const getMessageText = (userName: string, info?: Info) => {
  if (!info) { return ''; }

  if (info === 'DELETED') {
    return `@${userName} 删除了消息`;
  } else {
    const { message, attachments, type } = info;

    if (type === 'DELETED') { return `@${userName} 删除了消息`; }

    if (type === 'SYSTEM') { return message; }

    if (message) { return `${userName}：${message}`; }

    if (attachments.length) { return `@${userName} 上传了附件`; }

    return '';
  }
};
