// /** 数据分析 */
// import * as React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
//
// import { ROUTE_NAMES } from 'src/router/normalRouter';
// import { RouteMenuItem } from 'src/components/RouteMenu/interface';
// import { getRouterProps } from 'src/components/HoneyRouter';
//
// import { SiderMenuRoute } from 'src/components';
// import AutoAnalysis from './AutoAnalysis';
// import TrendAnalysis from './TrendAnalysis';
// import CustomerAnalysis from './CustomerAnalysis';
// import Rule from './Monitor/Rule';
// import Contact from './Monitor/Contact';
// import History from './Monitor/History';
//
// import AnalysisLog from './AnalysisSetting/AnalysisLog';
// import Ftp from './AnalysisSetting/Ftp';
// import TestItemManage from './TestItemManage';
//
// interface Props extends RouteComponentProps<any> {}
//
// let menus: RouteMenuItem[] = [];
//
// function fillMenu() {
//   menus = [
//     {
//       title: '数据分析',
//       defaultUnfold: true,
//       children: [
//         {
//           title: '批次分析报告',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_LOT,
//           defaultSelected: true,
//           routeProps: {
//             component: AutoAnalysis,
//             exact: true,
//           }
//         },
//         {
//           title: '趋势分析报告',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_TREND,
//           routeProps: {
//             component: TrendAnalysis,
//             exact: true,
//           }
//         },
//         {
//           title: '自定义分析报告',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_CUSTOMER,
//           routeProps: {
//             component: CustomerAnalysis,
//             exact: true,
//           }
//         },
//       ]
//     },
//     {
//       title: '数据监测',
//       children: [
//         {
//           title: '监测规则',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_RULE,
//           defaultSelected: true,
//           routeProps: {
//             component: Rule,
//             exact: true,
//           }
//         },
//         {
//           title: '告警通知管理',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_CONTACT,
//           routeProps: {
//             component: Contact,
//             exact: true,
//           }
//         },
//         {
//           title: '告警历史',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_HISTORY,
//           routeProps: {
//             component: History,
//             exact: true,
//           }
//         },
//       ]
//     },
//     {
//       title: '数据解析设置',
//       // routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_SETTING,
//       // routeProps: {
//       //   component: AnalysisSetting,
//       //   exact: true,
//       // },
//       children: [
//         {
//           title: '数据解析日志',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_SETTING_LOG,
//           defaultSelected: true,
//           routeProps: {
//             component: AnalysisLog,
//             exact: true,
//           }
//         },
//         {
//           title: 'FTP站点设置',
//           routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_SETTING_FTP,
//           routeProps: {
//             component: Ftp,
//             exact: true,
//           }
//         },
//       ]
//     },
//     {
//       title: '测试项管理',
//       routeName: ROUTE_NAMES.ORG_QC_ANALYSIS_TEST_MANAGE,
//       routeProps: {
//         component: TestItemManage,
//         exact: true,
//       }
//     }
//   ];
// }
//
// setTimeout(fillMenu);
//
// class Analysis extends React.PureComponent<Props> {
//   constructor(props: Props) {
//     super(props);
//
//     if (!menus.length) {
//       fillMenu();
//     }
//   }
//
//   render () {
//     const routerProps = getRouterProps(this.props);
//
//     return (
//       <SiderMenuRoute menus={menus} routerProps={routerProps} />
//     );
//   }
// }
//
// export default Analysis;
import React, { FC, ComponentType } from 'react';
import { Button, Icon } from 'antd';

import styles from './index.module.less';
import url from 'src/config/url';
import { analysis, arrowRight, rapidTdas } from 'src/assets/images/iso';

interface OwnProps {}
interface StateProps {}
interface DispatchProps {}
interface Props extends OwnProps, StateProps, DispatchProps {}

const Analysis: FC<Props> = props => {
  const {} = props;
  const pathname = window.location.pathname;
  const path = pathname?.split('/qc')?.[0];

  return (
    <div className={styles.qcArea}>
      <h1>
        <span className="app">数据分析</span>
        已升级为
        <span className="app">RapidTDAS<sup>TM</sup></span>
      </h1>

      <div className="text">
        数据分析已升级为RapidTDAS，在保证原有数据完整性和一致性的同时，通过优化界面，增强功能模块，优化系统性能等措施，
        为您提供更为简单易用、功能更为强大、运行速度更快的半导体测试数据分析服务。
      </div>

      <div className="png-area">
        <img src={analysis} alt="" className="analysis" />
        <img src={arrowRight} alt="" className="arrow-right" />
        <img src={rapidTdas} alt="" className="rapid-tdas" />
      </div>

      <Button size="large" block={true} className="btn top" href={url.tdasUrl} target="_blank">
        了解RapidTDAS<Icon type="link" />
      </Button>

      <Button
        size="large"
        block={true}
        type="primary"
        target="_blank"
        className="btn top-16"
        href={`${url.tdasUrl}${path}/lot`}
      >
        立即体验RapidTDAS<Icon type="arrow-right" />
      </Button>
    </div>
  );
};

export default Analysis as ComponentType<OwnProps>;
