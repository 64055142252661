import * as React from 'react';
// import { get } from 'lodash';

import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {
  showYield: boolean;
  editable: boolean;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  const { showYield, editable } = option;
  const yieldColumns = showYield ? [
    {
      key: 'yield',
      dataIndex: 'yield',
      title: '良率',
      width: 300,
      render: (text, record) => {
        let { good, amount } = record;
        good = parseInt(good, 10) || 0;
        amount = parseInt(amount, 10) || 0;
        const yieldText = ((good / amount) * 100).toFixed(2);

        return <span>{(yieldText || 0) + '%'}</span>;      
      }
    }
  ] : [];

  return [
    {
      key: 'waferId',
      dataIndex: 'waferId',
      title: '片号',
      width: 300,
      render: (text, record) => {

        return <span>{text ? '#' + text : '/'}</span>;
      }
    },
    {
      key: 'good',
      dataIndex: 'good',
      title: '良品',
      editable: editable,
      width: 400,
    },
    {
      key: 'bad',
      dataIndex: 'bad',
      title: '不良品',
      width: 300,
      render: (text, record) => {
        let badAmount: number | undefined;
        const { amount, good } = record;
        badAmount = amount - good;

        return <span>{badAmount || 0}</span>;
      }
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: '单片总数量',
      width: 300,
      render: (text, record) => {

        return <span>{text ? text : 0}</span>;
      }
    },
    ...yieldColumns,
  ];
};
