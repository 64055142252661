import * as React from 'react';
import { Icon } from 'antd';

interface CardExtraProps {
  openQuery: (e: any) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery } = props;

  return (
    <a onClick={openQuery}>
      <Icon type="search" />
      查询
    </a>
  );
}

export default React.memo(CardExtra);
