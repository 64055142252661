import React from 'react';
import { isEmpty } from 'lodash';
interface Option {

}

export const getTableColumns = (option: Option) => {
  const render = (text) => (<span>{text ?? '/'}</span>);

  return [
    {
      title: '产品',
      key: 'product',
      dataIndex: 'product',
      children: [
        {
          title: '产品名',
          key: 'customerName',
          dataIndex: 'customerName'
        },
        {
          title: '订购名',
          key: 'orderTemplateNames',
          dataIndex: 'orderTemplateNames',
          render: (text) => (<span>{!isEmpty(text) ? text.join(',') : '/'}</span>)
        },
        {
          title: '芯翼产品名',
          key: 'chipcooName',
          dataIndex: 'chipcooName',
        },
        {
          title: '类型',
          key: 'packageType',
          dataIndex: 'packageType',
          render: (text) => (<span>{text ? text : '/'}</span>)
        },
        {
          title: '芯片名',
          key: 'customerDieName',
          dataIndex: 'customerDieName'
        },
        {
          title: '芯翼芯片名',
          key: 'chipcooDieName',
          dataIndex: 'chipcooDieName'
        },
      ],
    },
    {
      title: '已制未交付',
      key: 'manufacture',
      dataIndex: 'manufacture',
      children: [
        {
          title: '合计',
          key: 'manufacturedUnStockOut',
          dataIndex: 'manufacturedUnStockOut',
        }
      ]
    },
    {
      title: 'FT数量(颗)',
      key: 'ft',
      dataIndex: 'ft',
      render,
    },
    {
      title: '封装成品数量(颗)',
      key: 'as',
      dataIndex: 'as',
      render,
    },
    {
      title: '蓝膜、挑芯数量(颗/片)',
      key: 'dp',
      dataIndex: 'dp',
      render,
    },
    {
      title: 'CP厂晶圆数量(颗/片)',
      key: 'cp',
      dataIndex: 'cp',
      render,
    },
    {
      title: 'Bumping晶圆数量(颗/片)',
      key: 'bp',
      dataIndex: 'bp',
      render,
    }
  ];
};
