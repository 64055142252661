/**
 * 通知左侧列表的滚动加载list
 */
import * as React from 'react';
import { Spin, List } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import styles from './listContent.module.less';
import { RootState, RootDispatch } from 'src/store';

import NotifyCard from './NotifyCard';
import { ThreeBounceLoading } from 'src/components';
import { Filter, NotifyFetchParams } from 'src/models/notification/interface';

interface OwnProps {
  loading: boolean;
  hasMore: boolean;
  loadMore: (arg: any) => any;
  loadNotifyData: (...args: any[]) => any;
  focusNotifyId: string | undefined;
  resetUrl: () => void;
  onSelected: (notifyId: string) => void;
  setSelected: (notifyIds: string[]) => any;
}
interface StateProps {
  notifyIds: string[];
  notifyCount: number;
  notifyFilter: Filter;
  fetched: boolean;
}
interface DispatchProps {
  fetchNotify: (parmas?: NotifyFetchParams) => void;
  resetList: () => any;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {

  notifyFilter: Filter;
}

const isNotifyFilterDataEqual = (props: P, state: S) => isEqual(props.notifyFilter, state.notifyFilter);

class ListContent extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    if (!isNotifyFilterDataEqual(nextProps, prevState)) {
      return { notifyFilter: nextProps.notifyFilter };
    }

    return null;
  }

  constructor(props: P) {
    super(props);

    this.state = { notifyFilter: props.notifyFilter };
  }

  componentDidMount() {
    this.props.loadNotifyData();
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    if (!isEqual(prevProps.notifyFilter, this.props.notifyFilter)) {
      
      this.props.setSelected([]);
      this.props.resetList();
      this.props.loadNotifyData();
    }
  }

  renderListItem = (id: string) => {
    const { resetUrl, focusNotifyId, onSelected } = this.props;

    return (
      <NotifyCard
        isFocus={id === focusNotifyId}
        notifyId={id}
        focusNotify={onSelected}
        resetUrl={resetUrl}
      />
    );
  }

  render() {
    const { notifyIds, notifyCount, fetched, loading, hasMore } = this.props;

    // 正在加载通知
    if (!fetched) {
      return <ThreeBounceLoading style={{ margin: '16px 0' }} />;
    }

    // 如果没有通知
    if (notifyCount === 0) {
      return (
        <div className={styles.emptyTip}>
          <i className="c-icon icon-bell" />
          <p>项目内容更新时</p>
          <p className="small">你就会在这里收到通知提醒</p>
        </div>
      );
    }

    return (
      <div className={`hover-thin-scroll y-scroll cell ${styles.contentWrap}`}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.props.loadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          threshold={200}
        >
          <List
            dataSource={notifyIds}
            renderItem={this.renderListItem}
          >
            {loading && hasMore && (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}

const mapState = ({ notification: { current, filter, fetched } }: RootState) => ({
  notifyFilter: filter,
  notifyIds: current.list.allIds,
  notifyCount: current.count,
  fetched,
});
const mapDispatch = ({ notification }: RootDispatch) => ({
  fetchNotify: notification.fetch,
  resetList: notification.resetList,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(ListContent);
