import * as React from 'react';
import { Icon, Divider, Button } from 'antd';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';

interface CardExtraProps {
  openQuery: (e: any) => void;
  openModal: (id: string, mode: ModalMode) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery, openModal } = props;

  return (
    <div>
      <a onClick={openQuery}>
        <Icon type="search" />
        查询
      </a>

      <Divider type="vertical" />

      <Button type="primary" onClick={() => openModal('', 'add')}>添加</Button>
    </div>
  );
}

export default React.memo(CardExtra);
