import * as React from 'react';
import { isEqual } from 'lodash';
import { CardTableV2 } from '@chipcoo/hanayo';
// import { getSelectedDataByKeys } from '@chipcoo/fe-utils';
import { EnumProductModel } from '@chipcoo/constant';
import { noop } from '@chipcoo/fe-utils';

import { getDataBySaveRow } from 'src/utils';
import { DemandOrderContext } from '../components/context';
import { getTableColumns } from './getTableColumns';

interface Props {
  dataSource: any[];
  smartSelectionData?: any[];
}

interface State {
  dataSource: any[];
}

const { EnhancedTable } = CardTableV2;

class SelectedMaterialTable extends React.PureComponent<Props, State> {
  static contextType = DemandOrderContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataSource: props.dataSource
    };
  }

  componentDidMount() {
    const { dataSource } = this.props;
    this.setState({ dataSource });
    this.context.getSelectedData(dataSource);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { dataSource } = this.props;

    if (!isEqual(dataSource, prevProps.dataSource)) {
      this.setState({ dataSource });
      this.context.getSelectedData(dataSource);
    }
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  getNewDataBySaveRow = (rowData) => {
    const data = {
      ...rowData,
      amount: rowData?.productModel === EnumProductModel.assemblyProduct
        ? rowData.amount
        : rowData?.good + rowData?.bad
    };
    const newData = getDataBySaveRow([...this.state.dataSource], data);

    this.setState({ dataSource: [...newData] });
    this.context.getSelectedData(newData);
  }

  render () {
    const { dataSource } = this.state;

    const columns = getTableColumns({ doubleCol: true, isEditable: true });
    // 这里不需要选择
    columns.shift();

    return (
      <div style={{ marginTop: 16 }}>
        <CardTableV2>
          <EnhancedTable
            getTableData={() => {
              //
            }}
            title={() => '已选物料列表'}
            dataSource={dataSource}
            columns={columns}
            showPagination={false}
            getNewDataBySaveRow={this.getNewDataBySaveRow}
            scroll={{ y: 300 - 57 - 16 * 3 - 38 }}
          />
        </CardTableV2>
      </div>
    );
  }
}

export default SelectedMaterialTable;
