import * as React from 'react';
import { EnumProductModel } from '@chipcoo/constant';

import { productApi } from 'src/services/net';

interface Props {
  value?: string;
  onChange?: (val: string) => void;
  productModel: EnumProductModel;
  product: string;
}

interface State {
  dieName: string;
}

class DieName extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { dieName: '' };
  }

  async componentDidMount() {
    const { value } = this.props;

    if (!value) {
      this.getDieName();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.product !== prevProps.product) {
      this.getDieName();
    }
  }

  fetchProductDieName = async () => {
    const { product, productModel } = this.props;
    const { data } = await productApi.getProductById(product);

    if (productModel === EnumProductModel.assemblyProduct) {
      return data?.product?.dies?.[0]?.die?.customerName;
    } else {
      return data?.product?.die?.customerName;
    }
  }

  getDieName = async () => {
    const { product } = this.props;
    const dieName = await this.fetchProductDieName();

    if (product && dieName) {
      this.setState({ dieName });
    }
  }

  render () {
    const { dieName } = this.state;

    return (
      <div>{dieName}</div>
    );
  }
}

export default DieName;
