// tslint:disable:max-file-line-count
import React from 'react';
import _ from 'lodash';
import { Card } from 'antd';

import { ICraftRouteFullRoute } from 'src/services/net/craftRoute';

import { PreviewCraft } from 'src/containers/PreviewCraft';

interface Props {
  index: number;
  cardClassName?: string;
  modalName?: string;
  craftRoute: ICraftRouteFullRoute;
}

interface State {
}

export class CraftRouteCard extends React.PureComponent<Props, State> {

  render() {
    const { props } = this;
    const { modalName, craftRoute } = props;
    const priority = _.get(craftRoute, 'priority');
    let title = `工艺路线${props.index + 1}`;
    title = title + ` (${priority === 1 ? 'A' : 'B'})`;

    return (
      <Card
        title={title}
        className={props.cardClassName}
        key={props.index}
      >
        <PreviewCraft modalName={modalName} craftRoute={craftRoute} />
      </Card>
    );
  }
}

export default CraftRouteCard;
