/**
 * Created by marvin on 18/3/7.
 */
import { ROUTE_NAMES } from 'src/router/normalRouter';
// import teamwork from 'src/assets/images/application/teamwork.png';
import product from 'src/assets/images/application/product.png';
import development from 'src/assets/images/application/development.png';
import operating from 'src/assets/images/application/operating.png';
import setting from 'src/assets/images/application/setting.png';
import qc from 'src/assets/images/application/qc.png';
import { checkRequireVerify } from 'src/pages/require-verify';

const applicationList = [
  // {
  //   id: '1',
  //   title: '团队协作',
  //   logo: teamwork,
  //   description: '信息同步是团队高效协作的基础。在这里，我们可以快速组建小团队、围绕任务主题展开实时的沟通协作，还可以讨论话题、汇报工作和管理文档。',
  //   target: '',
  //   route: '', // define only.
  // },
  {
    id: '1',
    title: '产品管理',
    logo: product,
    description: 'IC产品有自己的特殊属性和生命周期。在这里，我们可以为自己的每个产品建立档案，了解发生在他们身上的那些事。',
    target: '',
    route: '', // define only.
    onClick: (e) => {
      const modal = checkRequireVerify();
      if (modal) {
        e.preventDefault();
      }
    }
  },
  {
    id: '2',
    title: '研发管理',
    logo: development,
    description: 'IC研发过程中，需要各种外部协作、工具支持和信息查询。来这里找找看，有没有能够帮助到我们的小惊喜。',
    target: '',
    route: '', // define only.
  },
  {
    id: '3',
    title: '运营管理',
    logo: operating,
    description: '日复一日的生产运营看似简单，却无时无刻不在跟“异常”战斗。保证品质、交期，控制成本，提升客户满意度，这些都是运营的基本功。把运营交给他，安心！',
    target: '',
    route: '', // define only.
    onClick: (e) => {
      const modal = checkRequireVerify();
      if (modal) {
        e.preventDefault();
      }
    }
  },
  {
    id: '4',
    title: '质量管理',
    logo: qc,
    description: '质量体系建设，产品FEMA、测试数据分析、生产良率报表等。',
    target: '',
    route: '', // define only.
  },
  {
    id: '5',
    title: '组织设置',
    logo: setting,
    description: '查询和设置组织信息、邀请和管理组织成员、申请组织认证（有些应用模块只对已认证组织开放）、高级设置。',
    target: '',
    route: '', // define only.
  },
];

export function fillAppList() {
  // applicationList[0].route = ROUTE_NAMES.ORG_COO_LIST;
  applicationList[0].route = ROUTE_NAMES.ORG_PROD;
  applicationList[1].route = ROUTE_NAMES.ORG_RD;
  applicationList[2].route = ROUTE_NAMES.ORG_OPERATION;
  applicationList[3].route = ROUTE_NAMES.ORG_QC;
  applicationList[4].route = ROUTE_NAMES.ORG_SETTINGS;
}

setTimeout(fillAppList);

export default applicationList;
