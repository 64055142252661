import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators'; 
import { Card } from 'antd';
import { RouteComponentProps } from 'react-router';

import { Workspace, WithWorkspaceId } from 'src/models/workspace/interface';
import { RootState, RootDispatch } from 'src/store';
import { CreateParams } from 'src/services/net/workspace';
import { workspaceContext } from '../';

import { WorkspaceModalForm, FormValues } from '../components/WorkspaceModal';

import './style.module.less';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface OwnProps extends RouteComponentProps {

}

interface StateProps {
  workspace: Workspace;
}

interface DispatchProps {
  editWorkspace: (arg: WithWorkspaceId<CreateParams>) => any;
}

interface State {
  modalVisible: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

export class BasicInfo extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible : false });
  }

  @bind
  setFormValues(form: WrappedFormUtils) {
    const { workspace: { name, description } } = this.props;

    form.setFieldsValue({
      name,
      description,
    });
  }

  renderExtra() {
    const { Consumer } = workspaceContext;
    return (
      <Consumer>
        {({ role }) => {
          if (role === 'MEMBER') {
            return null;
          } 

          return (<a href="javascript: void(0);" onClick={this.openModal}>编辑信息</a>);
        }}
      </Consumer>
    );
  }

  @bind
  async handleSubmit(value: FormValues) {
    const { editWorkspace, workspace } = this.props;
    await editWorkspace({ ...value, workspaceId: workspace._id });
  }

  render() {
    const { workspace } = this.props;
    const { modalVisible } = this.state;

    return (
      <Card 
        bordered={false}
        className="setting-content" 
        title={workspace.name} 
        extra={this.renderExtra()}
      >
        <p className={classnames({ 'no-desc': !workspace.description })}>{workspace.description || '暂无内容'}</p>
        <WorkspaceModalForm 
          visible={modalVisible}
          afterOpen={this.setFormValues}
          onSubmit={this.handleSubmit}
          autoFocus={true} 
          title="编辑协作区信息"
          renderUserSelect={false}
          onClose={this.closeModal}
        />
      </Card>
    );
  }
}

const mapState = ({ workspace: { workspaceId, list } }: RootState) => ({
  workspace: list.byId[workspaceId],
});

const mapDispatch = ({ workspace: { asyncEdit } }: RootDispatch) => ({
  editWorkspace: asyncEdit,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(BasicInfo);
