import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { ColumnProps, TableProps } from 'antd/lib/table/interface';
import { TableWithColumnConf } from 'src/components';
import { ModalType } from '../index';
type TableDataItem = Object;
interface NewTableDataItem extends TableDataItem {
  key?: string;
  eitable?: boolean;
  isNew?: boolean;
}

interface Columns extends ColumnProps<any> {
  title: string;
  editableRender: (props: Object, TableData: any) => any;
  width?: string | number;
}

interface IProps extends TableProps<any> {
  status: ModalType;
  onlyRead?: boolean;
  columnsConfig: {[key: string]: Columns};
  verify?: (rowTableData: any) => boolean;
  value?: NewTableDataItem[];
  onChange?: (newData: any) => void;
  showAddBtn?: boolean;
}

interface IState {
  disabled: boolean;
  tableData: NewTableDataItem[];
}

const NewTableEditInModal: FC<IProps> = props => {
  const baseTableRowField: Object = {};

  const { value, columnsConfig, onChange, status } = props;
  const [state, setState] = useState<IState>({
    disabled: false,
    tableData: value as NewTableDataItem[]
  });

  const { tableData } = state;
  Object.keys(columnsConfig).forEach(key => baseTableRowField[key] = null);

  useEffect(() => {
    setState({
      ...state,
      tableData: value as NewTableDataItem[],
    });
  }, [value]);

  const getRowKey = (key: number, newData?: any) => {
    return (newData || tableData).filter(item => item.key === key)[0];
  };

  const handleFieldChange = (e, fieldName, key) => {
    const newData = tableData.map(item => ({ ...item }));
    const target = getRowKey(key, newData);

    if (!target) return;

    target[fieldName] = _.has(e, 'target.value') ? e.target.value : e;
    setState({
      ...state,
      tableData: newData,
    });
    onChange && onChange(newData);
  };

  const getColumns = () => {
    const dataColumns = Object.keys(columnsConfig).map((key, index) => {
      const { editableRender, render: propsRender, ...passConfig } = columnsConfig[key];
      const render = (text, record) => {
        if (status !== 'preview') {
          const baseProps = {
            value: typeof text !== 'number' && !text ? void 0 : text,
            size: 'small',
            autoFocus: index === 0,
            onChange: e => handleFieldChange(e, key, record.key),
          };
          return editableRender(baseProps, tableData);
        }
        return text;
      };
      return { ...passConfig, key, dataIndex: key, render: propsRender ? propsRender : render};
    });
    return dataColumns;
  };
  const columns = getColumns();
  const { onlyRead, ...passThoughProps } = props;

  return (
    <div>
      {
        tableData && tableData.length ? (
          <TableWithColumnConf
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="small"
            className="small-table-no-border"
            {...passThoughProps}
          />
        ) : null
      }
    </div>
  );
};

export default NewTableEditInModal;
