import { get } from 'lodash';

type CheckFn = (id?: any, list?: any) => boolean;

export function isEqualUserId(a: UserId = '', b: UserId = '') {
  return a.toString() === b.toString();
}

export function toInt(id: UserId) {
  return parseInt(id as string, 10);
}

/**
 * Map a user array by given user ids.
 * @param ids string[] user Id
 * @param list { [id]: UserLike }
 * @param checkFn (id?: string, list?: { [id]: UserLike }) => boolean
 * @return UserLike[] filtered users.
 */
export function mapUsers<T extends User = User>(
  ids: UserId[], 
  list: { [id: string]: T } | T[], 
  checkFn: CheckFn = () => true
): T[] {
  return ids.reduce(
    function (result: T[], id: UserId) {
      let find;
      if (Array.isArray(list)) {
        find = list.find(it => isEqualUserId(it._id, id));
      } else {
        find = list[id];
      }
      if (find && checkFn(id, list)) {
        result.push(find);
      }

      return result;
    }, 
    []
  );
}

/**
 * Map a member array with userInfo.
 * @param members MemberLike[]
 * @param list { [id]: UserLike }
 * @return (UserLike & Omit<MemberLike, 'user'>)[]
 */
export function mapMembers<T extends Member = Member, U extends User = User>(members: T[], list: { [id: string]: U }) {
  return members.map(m => {
    const { user, ...extra } = m;
    return {
      ...extra,
      ...list[m.user],
      role: m.role
    };
  });
}

/**
 * Find user by user Id from a user array.
 * @param list 
 * @param id 
 */
export const findUserById = function<T extends User>(list: T[], id: UserId) {
  const find = list.find(user => isEqualUserId(user._id, id));

  return find;
};

/**
 * Find user name by id.
 * @param list 
 * @param id 
 */
export const findNameById = function<T extends User>(list: T[], id: UserId): string {
  const find = findUserById(list, id);

  return find ? (find.nickname || find.name || '') : '';
};

/**
 * Find user id by name.
 * @param list UserLike[]
 * @param name string
 * @return user id or ''
 */
export const findIdByName = function<T extends User>(list: T[], name: string) {
  const find = list.find(user => user.nickname === name || user.name === name);

  return find ? find._id : '';
};

export function enhanceUserAvatar(user: User) {
  return {
    ...user,
    avatar: get(user, 'avatar') ? `/api/console/user/avatar/${user._id}` : '',
  };
}

export function getEnhancedAvatar(user: User): string {
  return enhanceUserAvatar(user).avatar!;
}
