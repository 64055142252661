import React from 'react';
import _ from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  RepeaterConfig,
  RepeaterRenderParams,
  InputFormItemConfig,
  CustomFormItemConfig,
  SelectFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import MaterialNumberList from './components/MaterialNumberList';
import { RiskAssessmentHeader } from 'src/pages/RDManage/TrialProduction/components/RiskAssessmentHeader';

// 物料名称
const name: InputFormItemConfig = {
  label: '物料名称',
  type: 'input',
  dataKey: 'name',
};

// 产品名称
const product: InputFormItemConfig = {
  label: '产品名称',
  type: 'input',
  dataKey: 'product',
};

// 产品类型
const productModel: SelectFormItemConfig = {
  label: '产品类型',
  type: 'select',
  dataKey: 'productModel',
  optionKey: 'productModel',
};

// 芯片名称
const dieName: InputFormItemConfig = {
  label: '芯片名称',
  type: 'input',
  dataKey: 'dieName',
  renderType() {
    if (this.formData?.productModel === EnumProductModel.spwDie) {
      return 'none';
    }

    return 'normal';
  }
};

// 良品数量
const available: InputFormItemConfig = {
  label: '良品数量',
  type: 'input',
  dataKey: 'available',
};

// 不可用库存
const unavailable: InputFormItemConfig = {
  label: '不良品数量',
  type: 'input',
  dataKey: 'unavailable',
};

// 冻结库存
// const freeze: InputFormItemConfig = {
//   label: '冻结库存',
//   type: 'input',
//   dataKey: 'freeze',
// };

// 总库存
const total: InputFormItemConfig = {
  label: '总数量',
  type: 'input',
  dataKey: 'total',
};

// 片数
const waferAmount: InputFormItemConfig = {
  label: '片数',
  type: 'input',
  dataKey: 'waferAmount',
  renderType: (formData) => formData.productModel !== EnumProductModel.assemblyProduct ? 'normal' : 'none'
};

// 库房物料仓储信息
// 表格
const materialNumberList: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'materialList',
  colSpan: 24,
  render() {
    return <MaterialNumberList productModel={this.formData.productModel} />;
  }
};

export const materialNumberInfoCard: RepeaterConfig = {
  type: 'repeater',
  repeaterType: 'collapse',
  dataKey: 'materialNumberInfo',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams, initialData: any) {
    const wName = _.get(initialData, `materialNumberInfo.${params.index}.name`);
    const wAvailable = _.get(initialData, `materialNumberInfo.${params.index}.available`);
    const wUnAvailable = _.get(initialData, `materialNumberInfo.${params.index}.unavailable`);
    // const wFreeze = _.get(initialData, `materialNumberInfo.${params.index}.freeze`);
    const wTotal = _.get(initialData, `materialNumberInfo.${params.index}.total`);
    const extraTitleText = `
      (库房: ${wName}; 良品数量: ${wAvailable}; 不良品数量: ${wUnAvailable}; 总数量: ${wTotal})
    `;
    // const extraTitleText = `
    //   (库房: ${wName}; 可用数量: ${wAvailable}; 不可用数量: ${wUnAvailable}; 冻结数量: ${wFreeze}; 总数量: ${wTotal})
    // `;

    return {
      panelProps: {
        header: (
          <RiskAssessmentHeader
            {...params}
            initialData={initialData}
            collapseText="库房"
            extraTitleText={extraTitleText}
            path="materialNumberInfo"
            isPreview={true}
          />
        )
      },
      groupItemConfig: [materialNumberList] as FormItemConfig[]
    };
  }
};

export const getFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '产品信息' },
      groupItemConfig: [
        name,
        product,
        productModel,
        dieName,
        available,
        unavailable,
        // freeze,
        total,
        waferAmount,
      ] as FormItemConfig[]
    },
    {
      card: { title: '库房物料仓储信息' },
      groupItemConfig: [
        materialNumberInfoCard
      ] as FormItemConfig[]
    }
  ]
});
// tslint:disable:max-file-line-count
