import { toNumber } from 'lodash';
import { getMomentTime } from '@chipcoo/fe-utils';
import { EnumAnalysisCustomScheduleRepeatUnit } from '@chipcoo/constant';

import { IList } from './interface';
import { TemplatePlanData } from 'src/services/net/qc';
import { RepeatUnitSelectOptions }
  from 'src/pages/QCManage/Analysis/CustomerAnalysis/components/TemplateSchedule/Setting/components/RepeatUnit';

export const handleTemplateList = (
  payload: {
    count: number;
    data: IList[];
    factories: Obj;
    products: Obj;
  }
) => {
  const { count, data, factories, products } = payload;

  return {
    count,
    list: data?.map(it => {
      const { createdAt, recentlyExecutionAt, schedule, data: templateData } = it;
      const factory = templateData?.primaryStdfFilter?.factory;
      const product = templateData?.primaryStdfFilter?.product;

      const productObj = products?.[product];
      const customerName = productObj?.customerName;
      const chipcooName = productObj?.chipcooName;

      return {
        ...it,
        createdAt: getMomentTime(createdAt),
        recentlyExecutionAt: getMomentTime(recentlyExecutionAt),
        scheduleText: schedule
          ? RepeatUnitSelectOptions?.find(option => option.value === schedule.repeatUnit)?.label
          : schedule,
        type: templateData?.primaryStdfFilter?.type,
        factoryText: factory ? factories?.[factory]?.name : '',
        productName: product ? customerName && chipcooName ? `${customerName}(${chipcooName})` : '' : ''
      };
    })
  };
};

export const handleTemplateDetail = (payload: Obj) => {
  const { data } = payload;

  return {
    ...payload,
    reportContents: data?.reportContents,
    analysisContents: data?.analysisContents,
  };
};

export const handleTemplateScheduleData = (payload: TemplatePlanData): TemplatePlanData => {
  const { _id, repeatUnit, monthlyDay, yearlyDay, time, onceDate, weeklyDay } = payload;
  let formData = {
    _id,
    repeatUnit,
    time: getMomentTime(time, 'HH:mm'),
  };

  if (repeatUnit === EnumAnalysisCustomScheduleRepeatUnit.once) {
    return {
      ...formData,
      onceDate: getMomentTime(onceDate),
    } as TemplatePlanData;
  }

  if (repeatUnit === EnumAnalysisCustomScheduleRepeatUnit.weekly) {
    return {
      ...formData,
      weeklyDay,
    } as TemplatePlanData;
  }

  if (repeatUnit === EnumAnalysisCustomScheduleRepeatUnit.monthly) {
    return {
      ...formData,
      monthlyDay: monthlyDay?.map(it => {
        const momentDay = getMomentTime(it, 'DD');

        return momentDay ? toNumber(momentDay) : momentDay;
      }),
    } as TemplatePlanData;
  }

  if (repeatUnit === EnumAnalysisCustomScheduleRepeatUnit.yearly) {
    return {
      ...formData,
      yearlyDay: yearlyDay?.map(it => {
        const momentDay = getMomentTime(it, 'MM-DD');

        return momentDay;
      }),
    } as TemplatePlanData;
  }

  return {
    ...formData,
  } as TemplatePlanData;
};
