/**
 * 这里默认添加了一个对于特殊的http code的处理
 * 如果ShowErrorMessage没有给予返回值或者返回一个false，那么这里不会显示message提示
 */
import { ServiceErrorMessage } from '@chipcoo/http-error-message';

type ShowErrorMessage = boolean | void | Promise<boolean|void>;
export type ListParams = {
  page?: number;
  limit?: number;
};

// extends axios AxiosRequestConfig
declare module 'axios' {
  interface AxiosRequestConfig {
    silent?: boolean;
    handleSpecialError?: {
      [status: number]: (response?: AxiosResponse, error?: AxiosError) => ShowErrorMessage;
    };
    // 自定义的error message
    customErrorMsg?: Partial<typeof ServiceErrorMessage>;
  }
}

import { api } from './create-api';
import workspace from './workspace';
import selectField from './selectField';
import attachment from './attachment';
import im from './im';
import outsourcingProcess from './outsourcingProcess';
import operationSettings from './operationSettings';
import universeComment from './comment';
import Npi from './npi';
import org from './org';
import task from './task';
import friend from './friend';
import profile from './profile';
import notification from './notification';
import address from './address';
import demandOrder from './demandOrder';
import inventoryManage from './inventoryManage';
import craftRoute from './craftRoute';
import mpw from './mpw';
import product from './product';
import abnormality from './abnormality';
import qc from './qc';
import qcDataMonitor from './qcDataMonitor';

const workspaceApi = workspace(api);
const selectFieldApi = selectField(api);
const attachmentApi = attachment(api);
const outsourcingProcessApi = outsourcingProcess(api);
const operationSettingsApi = operationSettings(api);
const universeCommentApi = universeComment(api);
const NpiApi = Npi(api);
const orgApi = org(api);
const taskApi = task(api);
const friendApi = friend(api);
const profileApi = profile(api);
const notificationApi = notification(api);
const imApi = im(api);
const addressApi = address(api);
const demandOrderApi = demandOrder(api);
const inventoryApi = inventoryManage(api);
const craftRouteApi = craftRoute(api);
const mpwApi = mpw(api);
const productApi = product(api);
const abnormalityApi = abnormality(api);
const qcApi = qc(api);
const qcMonitorApi = qcDataMonitor(api);

export {
  api,
  orgApi,
  workspaceApi,
  selectFieldApi,
  attachmentApi,
  imApi,
  outsourcingProcessApi,
  operationSettingsApi,
  universeCommentApi,
  NpiApi,
  taskApi,
  friendApi,
  profileApi,
  notificationApi,
  addressApi,
  demandOrderApi,
  inventoryApi,
  craftRouteApi,
  mpwApi,
  productApi,
  abnormalityApi,
  qcApi,
  qcMonitorApi,
};

export default api;
