import * as React from 'react';

import RecordTable from './RecordTable';

class Abnormality extends React.PureComponent {
  render () {
    return (
      <RecordTable />
    );
  }
}

export default Abnormality;
