/**
 * Created by marvin on 18/9/28.
 */

import * as React from 'react';

import style from './invitationUserDetail.module.less';

interface Props {
  userInfo: User;
}
interface State {}

const basicInfos = {
  nickname: { label: '昵称' },
  name: { label: '姓名' },
  job: { label: '职位' },
  department: { label: '部门' },
  gender: { label: '性别' },
  bio: { label: '个人签名' },
  phone: { label: '手机账号' },
  email: { label: '邮箱帐号' },
};

class InvitationUserDetail extends React.PureComponent<Props, State> {

  render () {
    const { userInfo } = this.props;

    return (
      <div className={style.userDetail}>
        {
          Object.keys(basicInfos).map((key, index) => {
            return (
              userInfo[key] && <div className={style.infoItem} key={key}>
                <div className="term">{basicInfos[key].label}</div>
                <div className="content ellipsis">{userInfo[key]}</div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default InvitationUserDetail;
