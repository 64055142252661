import { ROUTE_NAMES } from 'src/router/normalRouter';

import { analysis } from 'src/assets/images/iso';
// import { qs, fema, pcn, customerComplaint, analysis } from 'src/assets/images/iso';

const appList: any[] = [
  // {
  //   title: '质量体系',
  //   route: '',
  //   src: qs,
  // },
  // {
  //   title: 'FEMA',
  //   route: '',
  //   src: fema,
  // },
  // {
  //   title: 'PCN管理',
  //   route: '',
  //   src: pcn,
  // },
  // {
  //   title: '客诉管理',
  //   src: customerComplaint,
  //   route: '',
  // },
  {
    title: '数据分析',
    src: analysis,
    route: '',
  }
];

export function refreshAppList() {
  // appList[0].route = ROUTE_NAMES.ORG_QC_QS;
  // appList[1].route = ROUTE_NAMES.ORG_QC_FEMA;
  // appList[2].route = ROUTE_NAMES.ORG_QC_PCN;
  // appList[3].route = ROUTE_NAMES.ORG_QC_CUSTOMER_COMPLAINT;
  appList[0].route = ROUTE_NAMES.ORG_QC_ANALYSIS;
}

setTimeout(refreshAppList);

export default appList;
