import { createModel } from '@rematch/core';
import produce from 'immer';
import _ from 'lodash';

import { State } from './interface.d';
import { RootState } from 'src/store';
import { TableModelCommonEffects } from 'src/models/type';
import { qcApi } from 'src/services/net';
import { handleList, handleDetail, handleTestDetail } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
  testItem: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    },
    setTestItemDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.testItem = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getTendencyList(params);
      const handleData = handleList(data?.data);

      this.setList({
        count: data?.count,
        data: handleData
      });
    },
    async getTableItemDetail(_id: string) {
      const { data } = await qcApi.getTendencyById(_id);

      const handleData = handleDetail(data);

      this.setDetail(handleData);

      this.setTestItemDetail(undefined);
    },
    async filterDetail(params: any, rootState: RootState) {
      const { detail } = rootState.trendAnalysis;
      let summary, topfive, topBin;
      try {
        summary = await qcApi.filterTendencySummary({ _id: detail?._id, ...params});
        topfive = await qcApi.filterTopFive({ _id: detail?._id, ...params});
        topBin = await qcApi.filterTopBin({ _id: detail?._id, ...params});
      } catch (e) {
        console.error(e);
      }

      const handleData = handleDetail({ ...detail, ...summary?.data, ...topfive?.data, ...topBin?.data}, params);
      this.setDetail(handleData);
    },
    async filterOnlySummary(params: any, rootState: RootState) {
      const { detail } = rootState.trendAnalysis;
      let data;
      try {
        data = await qcApi.filterTendencySummary({ _id: detail?._id, ...params});
      } catch (e) {
        console.error(e);
      }

      const handleData = handleDetail({ ...detail, ...data?.data}, params);
      this.setDetail(handleData);
    },
    async getTestTendencyFilter(params: any, rootState: RootState) {
      const { detail } = rootState.trendAnalysis;
      const _id = detail?._id;
      const testId = params?.testId;
      let data;
      if (testId) {
        try {
          const resp = await qcApi.getTendencyTest({ _id, ...params });
          data = resp.data;
        } catch (e) {
          console.error(e);
        }
      }
      const handleTestData = handleTestDetail({ ...detail, ...data }, params);

      this.setTestItemDetail({
        ...handleTestData,
        tendencyCl: data?.cl && _.pick(data?.cl, ['meanCL', 'stdDevCL', 'meanSigma', 'stdDevSigma'])
      });
    },
    async postCreate(payload: any) {
      //
    },
    async patchModify(payload: any) {
      //
    },
  })
});
