/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Modal, SelectWithOption } from '@chipcoo/hanayo';

import styles from './index.module.less';
import { InvoiceParams } from 'src/models/org/interface';

interface Props extends FormComponentProps {
  columns: Object;
  isOpen: boolean;
  modalType: string;
  modalData: InvoiceParams | undefined;
  onSubmit: (val: InvoiceParams) => void;
  onCancel?: () => void;
}
interface State {
  confirmLoading: boolean;
}

const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
const FormItem = Form.Item;
class InvoiceModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
    };
  }

  submit = async () => {
    let value: Obj | undefined = undefined;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        value = values;
      }
    });

    if (!value) {
      return;
    }

    await this.props.onSubmit(value);
  }

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  }

  render () {
    const { form, columns, isOpen, modalType, modalData } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        visible={isOpen}
        width={600}
        title={`${modalType}开票信息`}
        onCancel={this.onCancel}
        onOk={this.submit}
      >
        <Form className={styles.invoice}>
          {Object.keys(columns).map((key) => {
            const item = columns[key];

            return (
              <FormItem key={key} label={item.label} {...formItemLayout}>
                {getFieldDecorator(key as string, {
                  initialValue: modalData && modalData[key],
                  rules: [{ required: item.isRequired, message: item.placeholder }]
                })(
                  item.type === 'select'
                    ? <SelectWithOption options={item.options} placeholder={item.placeholder} />
                    : <Input
                      autoFocus={item.autoFocus}
                      autoComplete="off"
                      placeholder={item.placeholder}
                      maxLength={item.maxLength}
                    />
                )}
              </FormItem>
            );
          })}
        </Form>
      </Modal>
    );

    // return (
    //   <ReactModal
    //     isOpen={isOpen}
    //     title={`${modalType}开票信息`}
    //     width={600}
    //     onCancel={this.onCancel}
    //     onOk={this.submit}
    //     confirmLoading={confirmLoading}
    //     content={(
    //       <Form className={styles.invoice}>
    //         {Object.keys(columns).map((key) => {
    //           const item = columns[key];
    //
    //           return (
    //             <FormItem key={key} label={item.label} {...formItemLayout}>
    //               {getFieldDecorator(key as string, {
    //                 initialValue: modalData[key],
    //                 rules: [{ required: item.isRequired, message: item.placeholder }]
    //               })(
    //                 item.type === 'select'
    //                   ? <SelectWithOption options={item.options} placeholder={item.placeholder} />
    //                   : <Input
    //                     autoFocus={item.autoFocus}
    //                     autoComplete="off"
    //                     placeholder={item.placeholder}
    //                     maxLength={item.maxLength}
    //                   />
    //               )}
    //             </FormItem>
    //           );
    //         })}
    //       </Form>
    //     )}
    //   />
    // );
  }
}

export default (Form as any).create()(InvoiceModal) as React.ComponentType<Omit<Props, 'form'>>;
