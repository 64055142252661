import log from 'loglevel';
import { env } from './env';

// 本地环境下开放所有的log等级
if (env === 'local') {
  log.enableAll();
}

if (env === 'test') {
  log.setLevel('info');
}
