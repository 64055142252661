import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';

export const queryForm: FormItemConfig[] = [
  {
    dataKey: 'name',
    type: 'input',
    label: '昵称'
  },
  {
    label: '邮箱/手机号',
    type: 'input',
    dataKey: 'account'
  },
  {
    label: '角色',
    type: 'select',
    dataKey: 'role',
    optionKey: 'orgMemberRole'
  }
];
