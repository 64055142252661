// tslint:disable:max-file-line-count
/**
 * message显示的组件
 */
import * as React from 'react';
import { Button } from 'antd';
import { trim, filter, remove } from 'lodash';
import { bind, debounce } from 'lodash-decorators';
import classnames from 'classnames';
import { getLocalStorage } from '@chipcoo/fe-utils';

import style from './index.module.less';
import { CommentInfo, ChatInputBoxBasicProps } from '../interface';
import { isProd } from 'src/utils';
import { SaveDraftApi, getKey } from 'src/components/SaveDraft';
import { Tooltip, Checkbox, Icon } from 'antd';
import UploadList from '../Uploader/UploadList';

import Uploader, { CompressStatus } from '../Uploader';
import EmojiPanel from '../EmojiPanel';
import { EmojiSVG, EnhancePopover } from 'src/components';

// import InitAtWho from './InitAtWho';
import { SaveDraft } from 'src/components';
// import { ControlledComponent } from 'src/hoc';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UploadAttachmentProps, UploadFileEnhance } from '../interface';
import UploadEnhance from '../Uploader/Upload';
import TextAreaWithMention from './TextAreaWithMention';

interface P extends ChatInputBoxBasicProps {
}
interface S {
  compressDisabled: boolean;
  compressStatus: CompressStatus | 'initial';
  fileList: UploadFileEnhance[];
  dragState: string;
  value: string;
}

const textAreaAutoSize = { minRows: 2 };

function warn(name: string) {
  console.warn(
    `[FileUpload]: \`fileList\` array provided but without \`${name}\` method prop. It won\'t work properly.`
  );
}

function getDefaultUAP() {
  return {} as UploadAttachmentProps;
}

class MsgBox extends React.PureComponent<P, S> {
  static defaultProps = {
    placeholder: 'Shift + Enter换行，按Enter快速发布',
    saveDraft: false,
  };
  private uploadRef = React.createRef<UploadEnhance>();
  private saveDraftFn: (value: any) => void;
  private _compressStatusTimer: any = null;
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};

    if (nextProps.uploaderOptions && 'compress' in nextProps.uploaderOptions) {
      nextState.compressDisabled = !nextProps.uploaderOptions.compress;
    }

    if ('value' in nextProps) {
      nextState.value = nextProps.value;
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    const { compress } = props.uploaderOptions || getDefaultUAP();

    this.state = {
      value: '',
      // 设置初始的压缩图片选项。
      compressDisabled: !(compress === undefined ? Uploader.defaultProps.compress : compress),
      fileList: [] as UploadFileEnhance[],
      dragState: 'initial',
      compressStatus: 'initial',
    };

  }

  get compress() {
    const { compressDisabled } = this.state;
    if (compressDisabled) {
      return false;
    }

    return this.props.uploaderOptions && this.props.uploaderOptions.compress;
  }

  componentDidMount() {
    const { saveDraft } = this.props;
    if (saveDraft) {
      const autoSaveVal = getLocalStorage(getKey(saveDraft));

      if (autoSaveVal !== null) {
        this.onChange(autoSaveVal);
      }
    }
  }

  componentWillUnmount() {
    this.saveDraft();
  }

  @bind
  onChange(value: string) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    } else {
      this.setState({ value });
    }

    this.saveDraft(value);
  }

  @bind
  @debounce(1000)
  saveDraft(val: string | null = this.state.value) {
    const value = this.getTrimmedValue(val);
    this.saveDraftFn && this.saveDraftFn(value);
  }

  onFileListChange = fileList => this.setState({ fileList });

  getTrimmedValue = (value?: any) => trim(trim(value || this.state.value), '\n');

  focusTextArea = () => {
    const element = document.querySelector(`.${style.textarea} textarea`);

    // @ts-ignore
    element && element.focus();
  }

  handleSendMessage = e => {
    // 对评论框中的数据进行消减，去掉头和尾的空格以及换行，如果为空，就没有content
    const trimmedContent = this.getTrimmedValue();

    const { fileList } = this.state;
    const uploaded = fileList.filter((file) => file.status === 'done');

    // 如果输入的文字和附件都为空，那么不做任何操作
    if (trimmedContent === '' && !uploaded.length) {
      e.stopPropagation();
      return;
    }

    const value: CommentInfo = {};

    trimmedContent && (value.content = trimmedContent);
    uploaded.length && (value.attachments = uploaded);

    this.props.onSubmit(value);
    // 清空缓存的对应的草稿
    this.onChange('');
    this.saveDraft(null);
    const nextFileList = filter(fileList, file => !uploaded.includes(file));

    this.setState({ fileList: nextFileList });
  }

  @bind
  @debounce(1000)
  handleSaveDraftInTextArea() {
    this.saveDraft();
  }

  handleFile(file: UploadFileEnhance, fileList: UploadFileEnhance[]) {
    const { response } = file;
    if (response) {
      const find = fileList.find(it => it.uid === file.uid);
      if (find) {
        ['image-height', 'image-width', 'mimetype', 'url'].forEach(key => {
          if (response[key]) {
            find[key.split('-').pop()!] = response[key];
          }
        });
      }
    }
  }

  setFileList = ({ fileList, file }) => {
    this.handleFile(file, fileList);
    this.setState({ fileList: fileList.slice() });
  }

  removeFile = (file) => {
    const fileList = this.state.fileList;
    remove(fileList, it => it === file || (it.uid && file.uid && it.uid === file.uid));
    this.setState({ fileList: fileList.slice() });
  }

  handleChooseEmoji = content => {
    const textAreaVal = this.state.value;
    const newContent = textAreaVal ? textAreaVal + content : content;

    this.onChange(newContent);
    this.focusTextArea();
  }

  @bind
  handleCompressStatus(_: any, status: CompressStatus) {
    clearTimeout(this._compressStatusTimer);
    this.setState({ compressStatus: status });

    if (status === 'error' || status === 'done') {
      this._compressStatusTimer = setTimeout(() => {
        this.setState({ compressStatus: 'initial' });
      }, 1000);
    }
  }

  @bind
  handleToggleCompress(e: CheckboxChangeEvent) {
    this.setState({ compressDisabled: e.target.checked });
  }

  @bind
  handleDrag(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();

    const isChild = (() => {
      let parent: any = e.target;
      while (parent !== e.currentTarget && parent !== null) {
        parent = parent.parentNode;
      }
      return parent === e.currentTarget;
    })();

    let dragState = e.type;

    if (e.type === 'dragleave' && isChild) {
      dragState = 'dragover';
    }

    switch (dragState) {
      case 'dragenter':
        this.setState({ dragState: 'dragover' });
        break;
      case 'drop':
      case 'dragleave':
        this.setState({ dragState: 'initial' });
        break;
      default:
    }

    if (e.type === 'drop') {
      this.uploadRef.current!.uploader.onFileDrop(e);
    }
  }

  handlePaste = (e) => {
    const { clipboardData } = e;

    if (typeof clipboardData === 'object') {
      const items = clipboardData.items || clipboardData.files;
      const files: File[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }

      if (files.length) {
        this.uploadRef.current!.uploader.uploadFiles(files);
        e.preventDefault();
      }
    }
  }

  render() {
    const { placeholder, saveDraft, uploaderOptions, mentionsProps } = this.props;
    const { showUploadList, fileList, onChange, onRemove, className } = uploaderOptions || getDefaultUAP();
    const { compressDisabled, dragState } = this.state;
    const files = fileList ? fileList : this.state.fileList;
    const onChangeFn = onChange ? onChange : this.setFileList;
    const onRemoveFn = onRemove ? onRemove : this.removeFile;

    if (fileList && !isProd) {
      if (!onChange) {
        warn('onChange');
      }
      if (!onRemove) {
        warn('onRemove');
      }
    }

    return (
      <div
        onDrop={this.handleDrag}
        onDragEnter={this.handleDrag}
        onDragOver={this.handleDrag}
        onDragLeave={this.handleDrag}
        className={classnames(style.msgBox, `on-${dragState}`, 'ctb-dnd-container')}
      >
        <div className={'msg-content'}>

          {/*添加表情和上传附件*/}
          <div className={style.chatHandlerWrapper}>
            <div className={style.chatHandler}>
              <EnhancePopover
                content={<EmojiPanel choiceEmoji={this.handleChooseEmoji} />}
                trigger="click"
                placement="topLeft"
                arrowPointAtCenter={true}
                overlayClassName="ctb-emoji-popover-overlay"
              >
                <Tooltip title="添加表情" mouseEnterDelay={1}>
                  <Icon component={EmojiSVG} />
                </Tooltip>
              </EnhancePopover>

              <Tooltip title="添加附件" mouseEnterDelay={1}>
                <div className="upload-in-chat-input">
                  <Uploader
                    uploadRef={this.uploadRef}
                    {...(uploaderOptions || {})}
                    fileList={files}
                    onCompress={this.handleCompressStatus}
                    compress={this.compress}
                    onChange={onChangeFn}
                    className={classnames('upload-in-chatbox', className)}
                  />
                </div>
              </Tooltip>

              <Checkbox checked={compressDisabled} onChange={this.handleToggleCompress}>发送原图</Checkbox>
            </div>
          </div>

          {
            saveDraft && (
              <SaveDraft autoSaveKey={saveDraft}>
                {(api: SaveDraftApi) => {
                  if (!this.saveDraftFn) {
                    this.saveDraftFn = api.save;
                  }

                  return null;
                }}
              </SaveDraft>
            )
          }

          <div
            onPaste={this.handlePaste}
            className={classnames(style.message, 'thin-scroll y-scroll')}
          >
            <div className={classnames('compress-status', this.state.compressStatus)}>
              <div className="compress-process">正在压缩图片...</div>
              <div className="compress-done">图片压缩完成，即将上传...</div>
              <div className="compress-error">图片压缩失败，上传原图...</div>
            </div>
            <TextAreaWithMention
              {...mentionsProps}
              placeholder={placeholder}
              autosize={textAreaAutoSize}
              value={this.state.value}
              onChange={this.onChange}
              onSubmit={this.handleSendMessage}
            />
            {
              showUploadList && (
                <UploadList
                  fileList={files}
                  onRemove={onRemoveFn}
                />
              )
            }
          </div>

          <Button className={style.senderBtn} onClick={this.handleSendMessage}>发送</Button>
        </div>
        <div className="dnd-tip">
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">拖动文件到此处可直接上传</p>
        </div>
      </div>
    );
  }
}

export default MsgBox;
