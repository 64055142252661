/**
 * 右侧已经选择的成员列表
 */
import * as React from 'react';
import { Icon } from 'antd';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { getClosetAttrVal } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { DataItem } from './index';

interface P {
  dataSource: DataItem[];
  title: string | React.ReactNode;
  selectedKeys: string[];
  render: (record: DataItem) => React.ReactNode;
  onSelectedKeysChange: (selectedKeys: string[]) => void;
}
interface S {
  selectedKeys: string[];
  selectedSourceData: DataItem[];
}

const getSelectedSourceData = (sourceData: DataItem[], selectedKeys: string[]) => {
  return sourceData.filter(item => selectedKeys.includes(item.key));
};

class RightList extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const { selectedKeys, dataSource } = nextProps;

    if (!isEqual(selectedKeys, prevState.selectedKeys)) {
      return {
        selectedKeys,
        selectedSourceData: getSelectedSourceData(dataSource, selectedKeys)
      };
    }

    return null;
  }

  constructor(props: P) {
    super(props);

    const { selectedKeys, dataSource } = props;

    this.state = {
      selectedKeys,
      selectedSourceData: getSelectedSourceData(dataSource, selectedKeys)
    };
  }

  handleDelSelected = (e) => {
    const delKey = getClosetAttrVal(e.target, 'data-id');
    const { selectedKeys, onSelectedKeysChange } = this.props;
    const newSelectedKeys = selectedKeys.filter(key => key !== delKey);

    onSelectedKeysChange(newSelectedKeys);
  }

  renderListContent = (item: DataItem) => {
    const { key } = item;
    const { render } = this.props;

    return (
      <div className={classnames(styles.contentItem, 'flex')} key={key} data-id={key}>
        <div className="cell">
          {render(item)}
        </div>
        <Icon type="close" onClick={this.handleDelSelected} />
      </div>
    );
  }

  render() {
    const { title, selectedKeys } = this.props;
    const { selectedSourceData } = this.state;

    return (
      <div className={styles.list}>
        <div className={styles.listHeader}>
          {title}
          <p style={{ marginLeft: 4 }}>
            {selectedKeys.length} 项
          </p>
        </div>
        <div className={styles.listContentWrapper}>
          <div className={`y-scroll thin-scroll ${styles.rightListContent}`}>
            {selectedSourceData.map(item => this.renderListContent(item))}
          </div>
        </div>
      </div>
    );
  }
}

export default RightList;
