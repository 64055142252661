/**
 * Created by marvin on 18/8/15.
 */

import * as React from 'react';
import { Form, Button, Card, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get, isString } from 'lodash';
import { connect } from 'react-redux';

import style from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { Verify, IOrgDetail, FunctionContactsParams } from 'src/models/org/interface';

import { getFormConfig } from '../formConfig';
import { FormCreator } from '@chipcoo/hanayo';

interface OwnProps {
  orgId: string;
  prev?: () => void;
  next?: () => void;
}
interface StateProps {
  orgInfo: IOrgDetail;
  verifyInfo: any;
}
interface DispatchProps {
  postVerify: (val: Verify) => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  licenseUrl: string;
  file: any;
}

class StepTwo extends React.PureComponent<Props, State> {
  private formController;
  constructor(props: Props) {
    super(props);

    this.state = {
      licenseUrl: '',
      file: {}
    };
  }

  // 提交认证
  submitCertification = (e) => {
    e.preventDefault();

    const { validateFields } = this.formController;
    validateFields(async (err, values) => {
      if (!err) {
        let resContacts: FunctionContactsParams[] = [];
        const { orgInfo, next, postVerify } = this.props;
        const { info, info: { license } } = values;
        let newLicense: string = info?.license;
        if (isString(license)) {
          newLicense = license;
        }

        const _info = {...info, license: newLicense, name: get(orgInfo, 'info.name')};

        try {
          await postVerify({
            ...values,
            info: _info,
            functionContacts: resContacts,
            // license,
          });

          next && next();
        } catch (e) {
          message.error('提交认证失败，请检查修改后重试！');
        }
      }
    });
  }

  // 上一步
  prev = () => {
    const { prev } = this.props;
    prev && prev();
  }

  render () {
    const { orgInfo, verifyInfo } = this.props;

    return (
      <div className={style.stepTwo}>
        {
          verifyInfo && verifyInfo.status === 'REJECTED' && (
            <Card className={style.rejectReason}>
              <p>拒绝理由：{verifyInfo?.rejectReason}</p>
            </Card>
          )
        }
        <FormCreator
          formData={verifyInfo ? verifyInfo : null}
          globalStatus="edit"
          formConfig={getFormConfig(orgInfo)}
          getFormController={controller => this.formController = controller}
        />
        <div className={style.buttons}>
          <Button onClick={this.prev} style={{marginRight: 8}}>上一步</Button>
          <Button type="primary" onClick={this.submitCertification}>提交</Button>
        </div>
      </div>
    );
  }
}

const mapState = ({ org: { orgList: { byId }, orgVerify } }: RootState, props: OwnProps) => ({
  orgInfo: get(byId, props.orgId),
  verifyInfo: orgVerify
});
const mapDispatch = ({ org: { postVerify } }: RootDispatch) => ({ postVerify });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(StepTwo));
