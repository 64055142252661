/**
 * 显示在table上方的查询条件的标签
 */
import React, { PureComponent } from 'react';
import { omit, isEmpty, isPlainObject, get } from 'lodash';
import log from 'loglevel';
import { Button } from 'antd';
import classNames from 'classnames';
import { isDataEmpty } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { QueryFormConfig } from '../Card';
import { optionsGroup } from 'src/config/selectOption';
import { QueryCondition } from '../types';

interface QueryTermTagsProps {
  showQueryPanel: () => void;
  resetQuery: (e: any, closePanel: boolean) => void;
  queryCondition: QueryCondition;
  queryFormConfig: QueryFormConfig | undefined;
}
interface S {}

class QueryTermTags extends PureComponent<QueryTermTagsProps, S> {
  // 拿到有效的查询条件的k-v
  getValidTermKV = (validCondition: Obj) => {
    const { queryFormConfig } = this.props;
    const rowConfig = queryFormConfig!.rowGroupConfig as any;

    return rowConfig.reduce((prev, cur) => {
      const { type, dataKey, label: labelComponent } = cur;
      const val = validCondition[dataKey];
      const label = get(labelComponent, 'props.text');

      if (!label) {
        log.error('查询配置中label应该是一个component');
        return prev;
      }

      if (isDataEmpty(val)) return prev;

      if (type === 'rangeDate') {
        prev[label] = `${val[0]} ~ ${val[1]}`;
        return prev;
      }

      if (type === 'select') {
        const { optionKey } = cur;
        const options = get(optionsGroup, `${optionKey}.options`);

        // 注意从对应的配置里面拿到的options数据有数组和object两种形式
        if (Array.isArray(options)) {
          prev[label] = val;
        }

        if (isPlainObject(options)) {
          prev[label] = options![val];
        }

        if (!options) {
          log.error(`could not find ${optionKey} in optionsGroup`);
        }

        return prev;
      }

      if (isPlainObject(val)) {
        const _val = get(val, 'label');

        if (!_val) {
          log.error('当拿到的列表查询条件的某一项是一个object的时候，应该返回一个label');
          return prev;
        }

        prev[label] = _val;
        return prev;
      }

      prev[label] = val;

      return prev;
    }, {} as {[label: string]: string});
  }

  resetQuery = (e) => {
    const { resetQuery } = this.props;

    resetQuery(e, true);
  }

  renderQueryTerm = (validCondition: Obj) => {
    const validTermKV = this.getValidTermKV(validCondition);

    return Object.entries(validTermKV).map(([label, val], index) => {
      const cls = classNames('flex', {
        [styles.queryItem]: 1,
        [styles.firstQueryItem]: !index
      });

      return (
        <div className={cls} key={label}>
          <div className="term">{label}</div>
          <div>{val as any}</div>
        </div>
      );
    });
  }

  render() {
    const { queryFormConfig, queryCondition, showQueryPanel } = this.props;
    // 筛选出来有效的查询条件，过滤掉通用的page和limit
    const validCondition = omit(queryCondition, ['limit', 'page']);

    if (!queryFormConfig || isEmpty(validCondition)) return <div className={styles.noQueryCondition} />;

    return (
      <div className={styles.queryTermTag}>
        <div className={styles.text}>查询条件为</div>
        "&nbsp;{this.renderQueryTerm(validCondition)}&nbsp;"
        <Button className={styles.editBtn} onClick={showQueryPanel}>编辑</Button>
        <Button onClick={this.resetQuery} type="primary">重置</Button>
      </div>
    );
  }
}

export default QueryTermTags;
