import { createModel } from '@rematch/core';
import produce from 'immer';
import { isEqualUserId } from 'src/utils/user';

import { UserModel } from './interface';
import { RootState } from 'src/store';
import { PatchOrgData } from '../org/interface';

const initialState: UserModel = {
  list: { byId: {}, allIds: [] },
  online: [],
};

export default createModel({
  state: initialState,
  reducers: {
    setUser(state: UserModel, users: User | User[]) {
      return produce(state, draft => {
        let payload = users;

        if (!Array.isArray(payload)) {
          payload = [payload];
        }

        const { list: { byId, allIds } } = draft;

        payload.forEach(user => {
          const account: Partial<User> = {
            // 确保ID转换成字符串存储，适配 antd 部分组件
            _id: user._id + '',
          };

          const exist = byId[user._id];
          if (exist) {
            Object.assign(exist, user, account);
          } else {
            // 设用户 account, 邮箱优先级高于手机号
            account.account = user.email || user.phone,
            allIds.push(user._id.toString());
            byId[user._id] = Object.assign({}, user, account);
          }
        });
      });
    },
    setUserOnline(state: UserModel, userIds: UserId | UserId[]) {
      return produce(state, draft => {
        const { online } = draft;
        const ids = Array.isArray(userIds) ? userIds : [userIds];

        ids.forEach(userId => {
          const find = online.some(id => isEqualUserId(id, userId));

          if (!find) {
            online.push(userId);
          }
        });
      });
    },
    setUserOffline(state: UserModel, userIds: UserId | UserId[]) {
      return produce(state, draft => {
        const { online } = draft;
        const ids = Array.isArray(userIds) ? userIds : [userIds];
        ids.forEach(userId => {
          const index = online.findIndex((id) => isEqualUserId(id, userId));

          if (~index) {
            online.splice(index, 1);
          }
        });
      });
    }
  },
  effects: (dispatch) => ({
    // 修改资料
    async modifyUserProfile(data: ISyncSocketData, rootState: RootState) {
      const { payload: { user } } = data;
      const {
        org: { orgList: { allIds, byId } }
      } = rootState;
      const userId = user._id;

      this.setUser(user);

      // 更新组织列表拥有者信息
      allIds.forEach(id => {
        if (byId![id]!.userId!._id === userId) {
          const orgDetail: PatchOrgData = {
            _id: id,
            userId: {
              ...byId[id].userId,
              ...user
            }
          };

          dispatch.org.setOrgDetail({ data: orgDetail });
        }
      });
    },
  })
});
