import { uniq } from 'lodash';
import { ILayoutData } from './compact-graph';
import { ICraftRouteItemLocal, ICraftMaterial } from 'src/services/net/craftRoute';

export function flatLayoutRoot(root: ILayoutData) {
  const flatted = [root];

  flatted.push(...root.children);
  if (root.children) {
    root.children.forEach((node) => flatted.push(...flatLayoutRoot(node)));
  }

  return flatted;
}

export function flatCraftRouteItemLocal(root: ICraftRouteItemLocal) {
  const flatted: (ICraftRouteItemLocal | ICraftMaterial)[] = [root];

  if (root.children) {
    flatted.push(...root.children);
    root.children.forEach((node) => {
      if ('type' in node || node.isEnd) {
        return;
      }

      flatted.push(...flatCraftRouteItemLocal(node));
    });
  }

  return uniq(flatted);
}
