import React from 'react';
import { isEmpty } from 'lodash';
import { EnumProcessClass } from '@chipcoo/constant';
import { IEditableColumnProps } from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';

interface Option {
  // jump2: (process: EnumProcessClass) => void;
  openModal: (params: unknown) => void;
}

const render = (text) => {
  return <span>{text ?? '/'}</span>;
};

export const getTableColumns = ({ openModal }: Option): IEditableColumnProps<any>[] => {
  return [
    {
      title: '产品',
      width: 900,
      key: 'product',
      children: [
        {
          title: '产品名',
          key: 'customerName',
          dataIndex: 'customerName',
          width: 150,
        },
        {
          title: '订购名',
          key: 'orderTemplateNames',
          dataIndex: 'orderTemplateNames',
          width: 150,
          render: (text) => {
            return !isEmpty(text) ? <span>{text?.join(', ')}</span> : '/';
          }
        },
        {
          title: '芯翼产品名',
          key: 'chipcooName',
          dataIndex: 'chipcooName',
          width: 150,
        },
        {
          title: '类型',
          key: 'packageType',
          dataIndex: 'packageType',
          width: 150,
          render
        },
        {
          title: '芯片名',
          key: 'customerDieName',
          dataIndex: 'customerDieName',
          width: 150,
        },
        {
          title: '芯翼芯片名',
          key: 'chipcooDieName',
          dataIndex: 'chipcooDieName',
          width: 150,
        },
      ]
    },
    {
      title: '未交付总数',
      width: 150,
      key: 'unStockOt',
      children: [{
        title: '合计',
        key: 'unStockOutQuantity',
        dataIndex: 'unStockOutQuantity',
        width: 150,
      }]
    },
    {
      title: (
        <a
          onClick={() => openModal({
            processClass: EnumProcessClass.ft,
            modalTitle: 'FT数量'
          })}
        >
          FT数量(颗)
        </a>
      ),
      width: 450,
      key: 'ft',
      children: [
        {
          title: '已制',
          key: 'ft.normal',
          dataIndex: 'ft.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'ft.manufacture',
          dataIndex: 'ft.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'ft.pending',
          dataIndex: 'ft.pending',
          width: 150,
          render
        },
      ]
    },
    {
      title: (
        <a
          onClick={() => openModal({
            processClass: EnumProcessClass.assembly,
            modalTitle: '封装成品数量'
          })}
        >
          封装成品数量(颗)
        </a>
      ),
      width: 450,
      key: 'as',
      children: [
        {
          title: '已制',
          key: 'as.normal',
          dataIndex: 'as.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'as.manufacture',
          dataIndex: 'as.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'as.pending',
          dataIndex: 'as.pending',
          width: 150,
          render
        },
      ]
    },
    {
      title: (
        <a
          onClick={() => openModal({
            processClass: EnumProcessClass.dp,
            modalTitle: '蓝膜、挑芯数量'
          })}
        >
          蓝膜、挑芯数量(颗)
        </a>
      ),
      width: 450,
      key: 'dp',
      children: [
        {
          title: '已制',
          key: 'dp.normal',
          dataIndex: 'dp.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'dp.manufacture',
          dataIndex: 'dp.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'dp.pending',
          dataIndex: 'dp.pending',
          width: 150,
          render
        },
      ]
    },
    {
      title: (
        <a
          onClick={() => openModal({
            processClass: EnumProcessClass.cp,
            modalTitle: 'CP厂晶圆数量'
          })}
        >
          CP厂晶圆数量(颗)
        </a>
      ),
      width: 450,
      key: 'cp',
      children: [
        {
          title: '已制',
          key: 'cp.normal',
          dataIndex: 'cp.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'cp.manufacture',
          dataIndex: 'cp.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'cp.pending',
          dataIndex: 'cp.pending',
          width: 150,
          render
        },
      ]
    },
    {
      title: 'Bumping晶圆数量(颗)',
      width: 450,
      key: 'bp',
      children: [
        {
          title: '已制',
          key: 'bp.normal',
          dataIndex: 'bp.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'bp.manufacture',
          dataIndex: 'bp.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'bp.pending',
          dataIndex: 'bp.pending',
          width: 150,
          render
        },
      ]
    },
    {
      title: (
        <a
          onClick={() => openModal({
            processClass: EnumProcessClass.rw,
            modalTitle: 'RAW晶圆数量'
          })}
        >
          RAW晶圆数量(颗)
        </a>
      ),
      width: 450,
      key: 'rw',
      children: [
        {
          title: '已制',
          key: 'rw.normal',
          dataIndex: 'rw.normal',
          width: 150,
          render
        },
        {
          title: '在制',
          key: 'rw.manufacture',
          dataIndex: 'rw.manufacture',
          width: 150,
          render
        },
        {
          title: '待制',
          key: 'rw.pending',
          dataIndex: 'rw.pending',
          width: 150,
          render
        },
      ]
    }
  ];
};
