import { isEmpty, sum, get, flattenDeep } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import { getMomentTime, unSortWaferId, sortWaferId } from '@chipcoo/fe-utils';

const baseBins = [
  { bins: '无', amount: 0 },
  { bins: 'bin1', amount: 0 },
  { bins: 'bin2', amount: 0 },
  { bins: 'bin3', amount: 0 },
  { bins: 'bin4', amount: 0 },
  { bins: 'bin5', amount: 0 },
  { bins: 'bin6', amount: 0 },
  { bins: 'bin7', amount: 0 },
  { bins: 'bin8', amount: 0 },
];

export const handleStockList = (data: any) => data.reduce(
  (prev, cur) => {
    let {
      _id,
      createdAt,
      productModel,
      bins,
      slices,
      name,
      attributes,
      warehouse,
      packingMethod,
      product,
      ...restData
    } = cur;
    let children: any[] = [];
    let [good, bad, total, waferIds] = [0, 0, 0, ''];

    if (productModel !== EnumProductModel.assemblyProduct) {
      children = slices.map(it => {
        // tslint:disable-next-line:no-shadowed-variable
        const { desc, waferId, attributes, good, bad } = it;

        return {
          ...it,
          waferIds: desc,
          attributes: (attributes & 1) === 1 ? '是' : '否',
          key: _id + '_' + waferId,
          originAmount: {
            good,
            bad,
          }
        };
      });
      good = sum(slices.map(it => it.good));
      bad = sum(slices.map(it => it.bad));
      total = good + bad;
      const allWaferIds = flattenDeep(slices.map(it => unSortWaferId(it.desc))) as number[];

      waferIds = sortWaferId(allWaferIds);
    } else {
      bins.forEach((bin, index) => {
        const { amount } = bin;
        if (amount !== 0) {
          children.push({
            ...baseBins[index],
            amount,
            originAmount: amount,
            key: _id + '_' + baseBins[index].bins,
          });
        }
      });
      total = sum(children.map(it => it.amount));
      bins = children.map(it => it.bins).join(',');
    }

    const curData = {
      ...restData,
      productModel,
      _id,
      key: _id,
      stockInAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      productName: name?.split('/')[0],
      materialName: name,
      good,
      bad: productModel !== EnumProductModel.assemblyProduct ? bad : undefined,
      amount: total,
      waferIds,
      bins,
      attributes: (attributes & 1) === 1 ? '是' : '否',
      warehouse:  warehouse?.name,
      packingMethod: product?.packing?.find(it => it._id === packingMethod)?.packingMethod || packingMethod,
      children: isEmpty(children) ? undefined : children,
    };

    prev.push(curData);

    return prev;
  },
  [] as any[]
);

export const handleFormData = (data: any) => {
  const { productModel, address, materialDetail, remark } = data;
  let resultValues, stockInfos;

  if (productModel === EnumProductModel.dpProduct) {
    stockInfos = materialDetail.map(item => {
      const { _id, children, packingMethod } = item;

      if (children) {
        return {
          stock: _id,
          packingMethod,
          waferIds: children.map(it => it.waferId),
          dpSlices: children.map(it => {
            const { good, bad } = it;

            return {
              good: good || 0,
              bad: bad || 0,
            };
          })
        };
      } else {
        const { waferId, good, bad } = item;

        return {
          stock: _id,
          packingMethod,
          waferIds: [waferId],
          dpSlices: [{
            good: good || 0,
            bad: bad || 0,
          }]
        };
      }
    });
  }

  if (productModel === EnumProductModel.assemblyProduct) {
    stockInfos = materialDetail.map(item => {
      const { children, _id, stampNumber } = item;

      let binArray: Number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (children) {
        const binstransition = children.map(it => {
          const { bins, amount } = it;
          if (bins === '无') {
            return {bins: 'bin0', amount};
          } else {
            return {bins, amount};
          }
        });

        binstransition.forEach(it => {
          binArray[it.bins.slice(-1)] = it.amount;
        });
      } else {
        const { bins, amount } = item;
        if (bins === '无') {
          binArray[0] = amount;
        } else {
          binArray[bins.slice(-1)] = amount;
        }
      }

      return {
        stock: _id,
        stampNumber,
        bins: binArray,
      };
    });
  }
  resultValues = {
    address: get(address, '_id'),
    remark,
    stockInfos,
  };

  return resultValues;
};
