/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { Card, Divider } from 'antd';
import { connect } from 'react-redux';

import Password from './Password';
import MobilePhone from './MobilePhone';
import Email from './Email';
import Wechat from './Wechat';

import { RootState, RootDispatch } from 'src/store';

interface OwnProps {}
interface StateProps {}
interface DispatchProps {
}
type Props = OwnProps & StateProps & DispatchProps;
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 15,
    offset: 1
  },
};
const submitFormLayout = {wrapperCol: {sm: { span: 15, offset: 9 }}};
class SafetySettingContainer extends React.PureComponent<Props> {

  render () {
    return (
      <Card title="安全设置" bordered={false}>
        <Password
          formItemLayout={formItemLayout}
          submitFormLayout={submitFormLayout}
        />
        <Divider/>
        <MobilePhone
          formItemLayout={formItemLayout}
          submitFormLayout={submitFormLayout}
        />
        <Divider/>
        <Email
          formItemLayout={formItemLayout}
          submitFormLayout={submitFormLayout}
        />
        <Divider/>
        <Wechat/>
      </Card>
    );
  }
}
const mapState = (state: RootState) => ({});
const mapDispatch = (dispatch: RootDispatch) => ({
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(SafetySettingContainer);
