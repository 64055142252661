import { getRegionText } from '@chipcoo/fe-utils';

export const handleAddressList = (formData: any[]) => {
  return (formData || []).map(it => {
    const { _id } = it;

    return {
      ...it,
      key: _id,
      regionText: getRegionText(it),
    };
  });
};
