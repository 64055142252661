import React, { PureComponent } from 'react';
import { NamedRouteComponents, ROUTE_NAMES } from 'src/router/normalRouter';
import { AsyncDataArg, getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import store from 'src/store';

import { RouteComponentProps } from 'react-router';
import { message } from 'antd';
import { AxiosRequestConfig } from 'axios';
import { SubscribeResult } from 'src/services/socket';
import { syncSocket } from 'src/socket';
import { handleSyncOrg } from 'src/layouts/GlobalWebPush/handler/org';

interface Props extends RouteComponentProps<{ id: string }> {}
interface State {}

function goToOrgList() {
  const { history } = getCurrentRoute()!;
  history.push(compilePath({ name: ROUTE_NAMES.ORG_LIST }));
}

const config: AxiosRequestConfig = {
  handleSpecialError: {
    403: () => {
      message.error('抱歉，您无权访问该组织');
      goToOrgList();
    },
    404: () => {
      message.error('您访问了一个不存在的组织');
      goToOrgList();
    }
  }
};

export class DetailEntry extends PureComponent<Props, State> {
  private subscription: SubscribeResult;

  static async asyncData({ store, route }: AsyncDataArg<{ id?: string }>) { // tslint:disable-line
    const { match: { params: { id } } } = route;

    // Fix me: use proper way to set orgId in localStorage.
    store.dispatch({ type: 'org/setOrgId', payload: id });
    const result = await store.dispatch({ type: 'org/getOrgMembers', payload: { orgId: id, config } });

    return result;
  }

  async componentDidUpdate(prevProps: Props) {
    const { params: { id } } = this.props.match;
    if (id !== (prevProps.match).params.id) {
      this.setState({ loading: true });
      store.dispatch({ type: 'org/setOrgId', payload: id });
      await store.dispatch({ type: 'org/getOrgMembers', payload: { orgId: id, config } });
      this.setState({ loading: false });

      if (this.subscription) {
        await this.subscription.unsubscribe();
      }

      this.subscribe();
    }
  }

  async componentDidMount() {
    this.subscribe();
  }

  async subscribe() {
    const { id } = this.props.match.params;
    this.subscription = await syncSocket.subscribe(
      { name: 'organization', id, role: 'members'},
      handleSyncOrg
    );
  }

  componentWillUnmount() {
    this.subscription && this.subscription.unsubscribe();
  }

  render() {
    return (
      <>
      {NamedRouteComponents.ORG_DETAIL()}
      {NamedRouteComponents.ORG_PROD()}
      {NamedRouteComponents.ORG_OPERATION()}
      {NamedRouteComponents.ORG_RD()}
      {NamedRouteComponents.ORG_QC()}
      {NamedRouteComponents.ORG_SETTINGS()}
        {/*组织研发管理*/}
      {NamedRouteComponents.ORG_RD_MPW_SHUTTLE()}
      {NamedRouteComponents.ORG_RD_MPW_PRE_ORDER()}
      {NamedRouteComponents.ORG_RD_NTO()}
      {NamedRouteComponents.ORG_RD_INVENTORY()}
      {NamedRouteComponents.ORG_RD_SHIPMENT()}
      {NamedRouteComponents.ORG_RD_QUERY_REPORT()}
      {NamedRouteComponents.ORG_RD_FAQ()}
      {NamedRouteComponents.ORG_RD_TEST()}
      {/*组织运营管理*/}
      {/*{NamedRouteComponents.ORG_OPERATION_OUTSOURCE()}*/}
      {/*{NamedRouteComponents.ORG_OPERATION_DEMAND_ORDER()}*/}
      {NamedRouteComponents.ORG_OPERATION_ORDER()}
      {NamedRouteComponents.ORG_OPERATION_ORDER_SUMMARY()}
      {NamedRouteComponents.ORG_OPERATION_INVENTORY()}
      {NamedRouteComponents.ORG_OPERATION_PROCESS()}
      {NamedRouteComponents.ORG_OPERATION_ABNORMALITY()}
      {NamedRouteComponents.ORG_OPERATION_STATEMENT()}
      {/*组织质量管理*/}
      {NamedRouteComponents.ORG_QC_QS()}
      {NamedRouteComponents.ORG_QC_FEMA()}
      {NamedRouteComponents.ORG_QC_PCN()}
      {NamedRouteComponents.ORG_QC_CUSTOMER_COMPLAINT()}
      {NamedRouteComponents.ORG_QC_ANALYSIS()}
      {/* {NamedRouteComponents.ORG_OPERATION_LOGISTICS()} */}
      </>
    );
  }
}

export default DetailEntry;
