import { createModel } from '@rematch/core';
import { get, remove, omit, uniq, debounce, has } from 'lodash';
import produce from 'immer';
import { notificationApi as notifyApi } from 'src/services/net';

import { initialNormalizedData, getNormalizedData, notificationPop } from 'src/utils';
import { getLastTime } from './tools';

import {
  MarkTodoParams,
  PatchOrgInvitationApplyParams,
  ReceiveNewMessage,
  NotificationModel,
  NotifyFetchParams,
  Notify,
  Filter
} from './interface';
// import { getResourceKey } from 'src/services/resource';
import { RootState } from 'src/store';
import { isEqualUserId } from 'src/utils/user';

export const getInitialFilter = () => ({
  isRead: undefined,
  isTodo: undefined,
  category: '',
  app: '',
});

const getInitialResult = () => ({
  list: initialNormalizedData(),
  statics: {
    count: 0,
    unread: 0,
    todo: 0,
  },
  count: 0,
});

type NModel = NotificationModel;

const initialState: NModel = {
  oneNotifyDetail: void 0,
  current: getInitialResult(),
  statics: {
    default: {
      count: 0,
      unread: 0,
    },
    todo: {
      count: 0,
      unread: 0,
    }
  },
  filter: getInitialFilter(),
  fetched: false,
};

export * from './selectors';

type SetResultPayload = {
  data: Notify[], count?: number, statics?: NModel['current']['statics'], replace?: boolean;
};

function setTodo(state: NModel, payload: string[], status: boolean) {
  return produce(state, draft => {
    const { byId } = draft.current.list;
    let length = payload.length;
    if (!status) {
      length = -length;
    }

    draft.current.statics.todo += length;

    payload.forEach(id => {
      byId[id] && (byId[id].isTodo = status);
    });
  });
}

export default createModel({
  state: initialState,
  reducers: {
    resetList(state: NModel) {
      return {
        ...state,
        current: getInitialResult(),
        fetched: false,
      };
    },
    resetFilter(state: NModel) {
      return {
        ...state,
        filter: getInitialFilter(),
      };
    },
    setFilter(state: NModel, payload: Partial<NModel['filter']>) {
      return produce(state, draft => {
        Object.assign(draft.filter, payload);
      });
    },
    setCurrentResult(state: NModel, payload: SetResultPayload) {
      return produce(state, draft => {
        const { replace } = payload;
        const normalized = getNormalizedData<Notify>(payload.data);

        if (replace) {          
          draft.current = {
            list: normalized,
            statics: payload.statics || {
              count: 0,
              unread: 0,
              todo: 0,
            },
            count: payload.count || 0,
          };
        } else {
          const { list: { allIds, byId } } = draft.current;
          // let diffed = 0;
          // let unread = 0;

          normalized.allIds.forEach(id => {
            const notify = normalized.byId[id];

            if (notify.time) {
              notify.date = new Date(notify.time);
            }

            if (notify._id === get(draft.oneNotifyDetail, '_id')) {
              draft.oneNotifyDetail = {
                ...draft.oneNotifyDetail,
                ...notify,
              };
            }

            // // 如果更新的数据未读且之前不存在或之前已读， 未读 +1
            // if (!notify.isRead && (get(byId, `${id}.isRead`) || !byId[id])) {
            //   unread += notify.repeat || 1;
            // }

            // // 如果更新的数据已读且之前未读， 未读 -1
            // if (notify.isRead && byId[id] && !byId[id].isRead) {
            //   unread -= (byId[id].repeat || 1);
            // }

            if (byId[id]) {
              Object.assign(byId[id], notify);
            } else {
              // diffed++;
              allIds.unshift(id);
              byId[id] = notify;
            }
          });

          draft.current.list.allIds = allIds.sort((notifyAId, notifyBId) => {
            return byId[notifyBId].date!.getTime() - byId[notifyAId].date!.getTime();
          });

          if (has(payload, 'count')) {
            draft.current.count = payload.count!;
          }

          if (has(payload, 'statics')) {
            draft.current.statics = payload.statics!;
          }

          // draft.current.count = payload.count || (draft.current.count + diffed);
          // draft.current.statics.count = get(payload, 'statics.count') || (
          //   draft.current.statics.count + unread + diffed - normalized.allIds.length
          // );

          // if (has(payload, 'statics.todo')) {
          //   draft.current.statics.todo = payload.statics!.todo;
          // }

          // draft.current.statics.unread = get(payload, 'statics.unread') || (draft.current.statics.unread + unread);
        }
      });
    },
    setRead(state: NModel, payload?: string[]) {
      const list = payload ? payload : state.current.list.allIds;
      
      return produce(state, draft => {
        const { list: { byId } } = draft.current;
        // let read = 0;
        // let diffed = 0;
        list.forEach(id => {
          if (byId[id]) {
            // if (!byId[id].isRead) {
            //   diffed++;            
            //   read += (byId[id].repeat || 1);
            // }
            byId[id].isRead = true;
          }
        });
        // if (payload) {
        //   console.log('current read diff is', read);
        //   draft.current.statics.unread -= read;
        //   draft.current.statics.count -= read - diffed;
        // } else {
        //   // 全部标记已读
        //   draft.current.statics.count -= draft.current.statics.unread - diffed;
        //   draft.current.statics.unread = 0;
        // }
      });
    },
    setTodo(state: NModel, payload: string[]) {
      return setTodo(state, payload, true);
    },
    resetTodo(state: NModel, payload: string[]) {
      return setTodo(state, payload, false);
    },
    setStatics(state: NModel, statics: NModel['statics']) {
      return {
        ...state,
        statics,
      };
    },
    removeNotifications(state: NModel, notifyIds: string[]) {
      return produce(state, draft => {
        const { list: { byId, allIds } } = draft.current;
        // let unreadCount = 0;
        // let removeCount = 0;
        // let repeatCount = 0;
        notifyIds.forEach(id => {
          if (byId[id]) {
            // removeCount++;
            // if (!byId[id].isRead) {
            //   repeatCount += byId[id].repeat || 1;
            //   unreadCount += byId[id].repeat || 1;
            // } else {
            //   repeatCount += 1;
            // }

            Reflect.deleteProperty(byId, id);
          }
        });

        remove(allIds, id => notifyIds.indexOf(id) !== -1);
        // draft.current.count -= removeCount;
        // draft.current.statics.count -= repeatCount;
        // draft.current.statics.unread -= unreadCount;
      });
    },
  },
  effects: (dispatch) => ({
    updateFilter(payload: Partial<Filter>) {
      this.setFilter({ ...payload });
    },

    // 拿一个消息的详情，如果这条消息还是未读的话，顺便给标记成已读
    async getNotifyDetail(payload: WrapAxiosRequest<{notifyId: string}>, rootState: RootState) {
      const { notifyId, config } = payload;
      const { notification: { current: { list: { byId } } } } = rootState;

      this.updateState([
        { path: 'oneNotifyDetail', data: byId[notifyId] },
      ]);

      const { data } = await notifyApi.getNotiDetail(notifyId, config);

      const updateState = [{ path: 'oneNotifyDetail', data }];

      this.updateState(updateState);
    },

    // 组织邀请
    async patchOrgInvitationApply (params: PatchOrgInvitationApplyParams) {
      const { noticeId, result, config } = params;
      const { data } = await notifyApi.patchInvatation({ noticeId, result }, config);
      
      return data;
    },

    /**
     * socket推送
     */
    async receiveNewNotify(receiveNewMessage: ReceiveNewMessage, rootState: RootState) {
      const notifyState = rootState.notification;
      const { session: { profile } } = rootState;
      const { current: { list: notify }, filter } = notifyState;
      const { allIds, byId } = notify;
      const { message, payload } = receiveNewMessage;
      const { notify: pushedMessageData, comment } = payload;
      const { _id: notifyId, state, fromUser, app, category } = pushedMessageData;
      const { result, from } = state ? JSON.parse(state) : '';
      const isSame = Object.entries(filter).every(([key, val]) => {
        return !val || (val === payload[key]);
      });
    
      if (app === 'console' && category === 'verify') {
        // 更新认证情况
        dispatch.org.getOrgList();
      }
      // console.log('payload', payload);
      // // if (comment && orgId && orgId === rootState.org.orgId) {
      // if (comment) {
      //   //
      //   console.log('comment', comment);
      //   const { resource } = comment;
      //   dispatch.universeComment.onNewComment({ key: getResourceKey(resource), data: [comment] });
      //   dispatch.universeComment.fetchCount(resource);
      // }

      // 同意/拒绝操作更新消息内容(只有操作者才更新消息内容，其他的收到push)
      if (result && from === fromUser!._id) {
        const notifyData = {
          _id: notifyId,
          message,
          isRead: false, // 此处需要设置为未读
        };
        this.setCurrentResult({ data: [notifyData] });
      } else if (allIds.includes(notifyId)) {
        const prevNotify = byId[notifyId];

        if (prevNotify && prevNotify._id === notifyId ) { 
          if (prevNotify.time !== pushedMessageData.time) {
            const nextIds = allIds.slice();
            remove(nextIds, id => id === notifyId);
            nextIds.unshift(notifyId);
            const { data } = await notifyApi.getNotiDetail(notifyId);
            this.setCurrentResult({ data: [data] });
          }
        }
      } else {
        if (!comment || !isEqualUserId(comment.user!, profile!._id)) {
          notificationPop({title: '你有新的消息', template: message, publisher: fromUser});
        }

        if (isSame) {
          const { data } = await notifyApi.getNotiDetail(notifyId);
          this.setCurrentResult({ data: [data] });
        }
      }

      // 还是要更新一遍数据
      this.fetchStatics();
    },
    async fetch(payload: NotifyFetchParams = {}, rootState: RootState) {
      const { filter, current: { list } } = rootState.notification;
      const cached = omit(payload, ['loadMore', 'time']);

      if (payload.loadMore) {
        payload.time = getLastTime(list);
      }

      const { data } = await notifyApi.list(payload);
      const equal = Object.entries(cached).every(([key, value]) => value === filter[key]);

      if (equal) {
        this.setCurrentResult({ ...data });
        this.updateState([
          { path: 'fetched', data: true },
        ]);
      }
    },
    fetchStatics: debounce(async function(_: any, rootState: RootState) {
      const { filter } = rootState.notification;
      const { data: curStatistics } = await notifyApi.list(filter);
      this.setCurrentResult({...curStatistics, data: [] });
      const { data } = await notifyApi.fetchStatics();
      this.setStatics(data);
    }, 400),
    async markAsRead(payload?: string[]) {
      if (payload) {
        await notifyApi.markAsRead(payload);
      } else {
        // await notifyApi.markAllAsRead();
      }

      this.fetchStatics();
      this.setRead(payload);
    },
    async markTodo(payload: MarkTodoParams) {
      const { action, notifyId } = payload;
      await notifyApi.markTodo(payload);
      this.fetchStatics();

      let notifyIds = (payload.notifyIds || []).slice();

      if (notifyId) {
        notifyIds.push(notifyId);
      }

      notifyIds = uniq(notifyIds);

      if (action === 'mark') {
        this.setTodo(notifyIds);
      } else {
        this.resetTodo(notifyIds);
      }
    },
    async deleteNotifications(notifyIds: string[]) {
      await notifyApi.remove(notifyIds);
      this.removeNotifications(notifyIds);
      this.fetchStatics();
    }
  })
  // tslint:disable-next-line
});
