import React from 'react';
import {
  FormConfig,
  FormItemConfig,
  CustomFormItemConfig,
  InputFormItemConfig,
  FileFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';
import { get, isEmpty, isEqual } from 'lodash';
import { EnumProductModel, EnumProcessClass } from '@chipcoo/constant';

import { attachmentApi } from 'src/services/net';

const fileDefaultProps = {
  getFileDetail: fileId => fileId && attachmentApi.getDetail(fileId),
  getUrl: fileId => fileId && attachmentApi.download({ attachmentId: fileId })
};

import {
  getDieNumber,
  getStampNumber,
  choiceWaferIds,
  getStockSlice,
  getStockBin,
  getDpDieSlices,
} from '../../ApplicationFormModal/stockInConfig';
import {
  AnalysisDataDirty,
  AlertMessage,
  AnalytiCalSolution,
  GoodBin,
  Mapping,
  SelectParseMap,
  OriginAnalysisData,
} from './components';

const materialItemId: InputFormItemConfig = {
  type: 'input',
  dataKey: '_id',
  colClassName: 'hidden-form-item'
};

export const isParseMap = (status: 'preview' | 'edit'): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'isParseMap',
  label: '是否根据MAP文件来解析物料',
  required: status === 'edit',
  requiredMsg: '请选择是否根据MAP文件来解析物料',
  colSpan: 24,
  colClassName: status === 'preview' ? 'hidden-form-item' : '',
  render: (formData, formStatus, form) => {
    return (
      <SelectParseMap
        formData={formData}
        status={formStatus}
        setFieldsValue={form?.setFieldsValue}
      />
    );
  },
});

export const getMappingInfos = (status: 'edit' | 'preview'): CustomFormItemConfig => ({
  label: 'MAP文件',
  type: 'custom',
  dataKey: 'mapping',
  required() {
    return this.formData?.isParseMap;
  },
  requiredMsg: '请点击上传MAP文件',
  colClassName() {
    const { formData, initialFormData } = this;

    return formData?.isParseMap === false && initialFormData?.mapping ? 'hidden-form-item' : 'preview-file-style';
  },
  render: (formData, globalStatus, form, initialData) => {
    return (
      <Mapping
        setFieldsValue={form?.setFieldsValue}
        status={status}
        isNeedHidden={formData?.isParseMap === false}
      />
    );
  },
  renderType: (formData, initialValue, globalStatus) => {
    // tslint:disable-next-line:no-shadowed-variable
    const isParseMap = get(formData, 'isParseMap');
    if (isParseMap || initialValue?.mapping) {
      return globalStatus === 'edit' ? 'normal' : 'preview';
    }
    return 'none';
  },
  colSpan: status === 'preview' ? 8 : 24,
});

const handleAnalysis: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'handleAnalysisData',
  colClassName: 'hidden-form-item',
  render() {
    return (
      <OriginAnalysisData
        analysisData={this.formData?.analysisData}
        analytiCalSolution={this.formData?.analytiCalSolution}
      />
    );
  },
  renderType() {
    return !isEmpty(this.formData?.analysisData) ? 'normal' : 'none';
  }
};

// 解析结果提示
const alertMessage: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'alertMessage',
  colSpan: 24,
  render: (formData, formStatus, form) => {
    const isAnalysisDataDirty = get(formData, 'isAnalysisDataDirty');
    const handleAnalysisData = get(formData, 'handleAnalysisData');
    const analysisData = get(formData, 'analysisData');

    return (
      <AlertMessage
        isAnalysisDataDirty={isAnalysisDataDirty}
        handleAnalysisData={handleAnalysisData}
        analysisData={analysisData}
        setFieldsValue={form?.setFieldsValue}
      />
    );
  },
  renderType: (formData) => {
    return formData?.analysisData ? 'normal' : 'none';
  }
};

const analysis: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'analysisData',
  colClassName: 'hidden-form-item',
  render: () => <div />,
};

const isAnalysisDataDirtyFormItem: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'isAnalysisDataDirty',
  colClassName: 'hidden-form-item',
  render() {
    const { formData } = this;
    return (
      <AnalysisDataDirty
        formData={formData}
      />
    );
  },
};

const analytiCalSolution = (): CustomFormItemConfig => ({
  label: '解析方案',
  type: 'custom',
  dataKey: 'analytiCalSolution',
  required: true,
  requiredMsg: '请选择解析方案',
  render: (formData, formStatus, form, initialFormData) => {
    const analysisData = get(formData, 'analysisData');
    const initialAnalysisData = get(initialFormData, 'analysisData');

    return (
      <AnalytiCalSolution
        analytiCalData={analysisData}
        setFieldsValue={form?.setFieldsValue}
        reMapping={!isEqual(initialAnalysisData, analysisData)}
      />
    );
  },
  renderType: (formData, initialValue, globalStatus) => {
    // tslint:disable-next-line:no-shadowed-variable
    const isParseMap = get(formData, 'isParseMap');
    const mappingData = get(formData, 'analysisData');

    if (isParseMap && !isEmpty(mappingData)) {
      return 'normal';
    }
    return 'none';
  }
});

export const cpReport = (outerFormData, status: 'edit' | 'preview'): FileFormItemConfig => ({
  type: 'file',
  label: 'CP测试报告',
  dataKey: 'cpReport',
  props: fileDefaultProps,
  colClassName: 'preview-file-style',
  renderType: (formData, initialValue, globalStatus) => {
    if (outerFormData?.classes === EnumProcessClass.cp) return 'normal';
    if (initialValue?.cpReport && status === 'preview') return 'preview';
    return  'none';
  }
});

export const goodBins = (status: 'preview' | 'edit'): CustomFormItemConfig => ({
  label: '良品Bin选择',
  type: 'custom',
  dataKey: 'goodBins',
  colSpan: status === 'edit' ? 16 : 8,
  required: true,
  requiredMsg: '请选择良品Bin',
  render: (formData, formStatus) => {
    const analytiCalData = get(formData, 'analysisData');
    const solution = get(formData, 'analytiCalSolution');

    return (
      <GoodBin
        analytiCalData={analytiCalData}
        analytiCalSolution={solution}
        status={status}
      />
    );
  },
  renderType: (formData, initialValue, globalStatus) => {
    // 判断解析结果里是否有bin信息
   // tslint:disable-next-line:no-shadowed-variable
   const isParseMap = get(formData, 'isParseMap');
   const mappingData = get(formData, 'analysisData');
   const solution = get(formData, 'analytiCalSolution');
   const binsData = mappingData?.[solution]?.data?.map(it => it.bins).filter(it => it);
   const binsArray = get(initialValue, 'goodBins');

   if ((isParseMap && !isEmpty(binsData)) || (status === 'preview' && !isEmpty(binsArray))) {
     return 'normal';
   }
   return 'none';
  }
});

type Params = {
  productModel: EnumProductModel,
  outerFormData: any;
};
export const getStockInConfig = (props: Params): FormConfig => {
  const { outerFormData, productModel } = props;
  return {
    cardGroupConfig: [
      {
        card: { title: '解析信息' },
        groupItemConfig: [
          analysis,
          isParseMap('edit'),
          getMappingInfos('edit'),
          handleAnalysis,
          isAnalysisDataDirtyFormItem,
          alertMessage,
        ] as FormItemConfig[],
        ifRender: () => {
          const classes = get(outerFormData, 'classes');
          return productModel === EnumProductModel.spwDie && classes === EnumProcessClass.cp;
        }
      },
      {
        card: { title: '物料信息' },
        groupItemConfig: [
          materialItemId,
          analytiCalSolution(),
          getDieNumber(productModel),
          choiceWaferIds('edit', productModel, outerFormData),
          getDpDieSlices('edit', productModel, outerFormData),
          getStampNumber(productModel),
          cpReport(outerFormData, 'edit'),
          goodBins('edit'),
          getStockSlice('new', productModel, outerFormData),
          getStockBin('edit', productModel, outerFormData),
        ] as FormItemConfig[],
        renderType() {
          const { formData, initialFormData } = this;
          const classes = get(outerFormData, 'classes');
          if (classes === EnumProcessClass.cp) {
            return (formData?.isParseMap && formData?.analysisData) ||
              formData?.isParseMap === false ||
              initialFormData?.dieNumber ? 'normal' : 'none';
          }
          return 'normal';
        }
      }
    ]
  };
};
