/**
 * 任务看板
 */
import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  DropResult as IDropResult
} from 'react-beautiful-dnd';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { SortStageListsParams, SortTaskParams } from 'src/models/task/interface';

import StageList from './StageList';
import AddNewStageList from './AddNewStageList';
import TaskDetail from './TaskDetail';

interface OwnProps {}
interface StateProps {
  stageListIds: string[];
}
interface DispatchProps {
  getStageList: () => void;
  resetTask: () => void;
  sortTask: (payload: SortTaskParams) => void;
  sortStageLists: (payload: SortStageListsParams) => void;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  columns: any;
  ordered: any;
}

class TaskBoard extends React.PureComponent<P, S> {
  componentDidMount() {
    this.props.getStageList();
  }

  componentWillUnmount() {
    this.props.resetTask();
  }

  onDragStart = (initial) => {
    // publishOnDragStart(initial);
  }

  onDragEnd = (result: IDropResult) => {
    if (!result.destination) { return; }

    const source = result.source;
    const destination = result.destination;

    // 如果移动以后还在原位
    if (isEqual(source, destination)) { return; }

    const { sortStageLists, sortTask } = this.props;

    // 移动stage
    if (result.type === 'COLUMN') {
      sortStageLists({
        startIndex: source.index,
        endIndex: destination.index
      });

      return;
    }

    // 移动task
    sortTask({ taskId: result.draggableId, source, destination });
  }

  render() {
    const { stageListIds } = this.props;

    return (
      <div className={classnames(styles.wrapper, 'horizontal-scroll', 'x-scroll')}>
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <div className={styles.board} ref={provided.innerRef} {...provided.droppableProps}>
                {stageListIds.map((key: string, index: number) => (
                  <StageList
                    key={key}
                    index={index}
                    // title={key}
                    stageId={key}
                  />
                ))}

                {/*新建stage*/}
                <AddNewStageList />
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Switch>
          <Route path="/task/:id" component={TaskDetail} />
        </Switch>
      </div>
    );
  }
}

const mapState = ({ task: { stageLists: { allIds } } }: RootState) => ({
  stageListIds: allIds
});
const mapDispatch = ({ task }: RootDispatch) => ({
  getStageList: task.getStageList,
  sortStageLists: task.sortStageLists,
  sortTask: task.sortTask,
  resetTask: task.reset
});

export default connect(mapState, mapDispatch)(withRouter(TaskBoard));
