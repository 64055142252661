/**
 * 用来处理数据的函数
 */
import { get, sum, isArray, findIndex, isString, toNumber, isNumber } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';

import { env } from 'src/config/env';
import { LOCAL_PREFIX } from 'src/config/const';
import { CraftRouteManage } from 'src/containers/PreviewCraft/utils/craft-route-manage';
import { tagsData, ITag } from
    'src/pages/QCManage/Analysis/AnalysisModal/formConfigs/components/ProjectCategory/tags';
// import { IFTP } from 'src/models/qc/analysisSetting/ftp/interface';

export function isLocalData(id: string) {
  return id.startsWith(LOCAL_PREFIX);
}

type ByIdData<T extends any = {}> = {
  byId: { [key: string]: T },
  allIds: string[]
};
/**
 * fixme: 此处overload有误，需要更正
 * 服务器获取的数据进行简单的范式化
 * @param {any[]} dataSource
 * @returns {ByIdData}
 */
export function getNormalizedData<
  T extends any, S extends Array<T> = Array<T>
>(dataSource: S, key?: keyof T): ByIdData<T>;
export function getNormalizedData<
  T extends any
>(dataSource: T[], key?: keyof T): ByIdData<T>;
export function getNormalizedData(dataSource: any[], key: string = '_id'): ByIdData<any> {
  return dataSource.reduce(
    (pre, cur) => {
      const _id = cur[key];

      if (env === 'local') {
        if (!_id) { console.error('不存在id，无法范式化'); }
      }

      pre.byId[_id] = cur;
      pre.allIds.push(_id);

      return pre;
    },
    { byId: {}, allIds: [] }
  );
}

interface UserWrap {
  user: User;
  _id: string | undefined;
}

function handleMember<T extends UserWrap = UserWrap>(member: T, formatKey: string) {
  const { user, _id, ...extra } = member;

  return {
    member: {
      ...member,
      ...extra,
      ...member?.user,
      user: user._id,
      [formatKey]: _id,
    },
    user,
  };
}

export function handleMemberList<T extends UserWrap = UserWrap>(data: T[], formatKey: string) {
  return data.reduce((all, cur) => {
    try {
      const { user, member } = handleMember(cur, formatKey);
      all.users.push(user);
      all.members.push(member);
    } catch (e) {
      console.error(e);
    }

    return all;
  }, { users: [], members: [] } as { users: User[], members: any });
}

/**
 * model里面初始化的store数据
 */
export const initialNormalizedData = (extra = {}) => {
  return { byId: {}, allIds: [], ...extra };
};

// 获取步骤工艺路线的相关信息
export const getStepCraftRouteData = (params: any) => {
  const { craftRoutes, craftRouteId, targetMaterial, sourceMaterial, } = params;

  const craftRoute = craftRoutes.find(c => c._id === craftRouteId);

  if (craftRoute) {
    const crm = new CraftRouteManage(craftRoute, targetMaterial, sourceMaterial);

    const crmFilterFlatItems = crm.flatItems.filter(it => {
      // 只有一个步骤的工艺路线，只包含isEnd为true及没有children属性的项
      return 'children' in it && !it.isEnd;
    }).map((item: any) => {
      const sources = item.children.map(it => {
        if ('type' in it) {
          return {
            materialName: it.item.materialName,
            productModel: it.item.productModel,
          };
        }

        return {
          materialName: it.material.materialName,
          productModel: it.material.productModel,
        };
      });

      return {
        craftRoute: item._id,
        source: get(sources, '0.materialName'),
        sourceProduct: get(item, 'sources.0.item.product._id') || get(item, 'sources.0.item.product'),
        sourceProductModel: get(sources, '0.productModel'),
        target: get(item, 'material.materialName'),
        targetMaterialId: get(item, 'material._id'),
        process: get(item, 'process._id'),
        name: get(item, 'process.name'),
        code: get(item, 'process.code'),
        targetProductModel: item.productModel,
        targetProduct: get(item, 'product._id'),
      };
    });

    return crmFilterFlatItems;
  }

  return [];
};

// 用来处理更改某一行数据后，重新生成新列表数据的方法
export const getDataBySaveRow = (data, rowData) => {
  let newData: any[] = data;

  if (data && isArray(data)) {
    if (rowData?.key) {
      const _index = rowData.key.indexOf('_');

      if (_index >= 0) {
        // 子行
        const parentKey = rowData.key.split('_')[0];

        const parentIndex = data.findIndex(it => it.key === parentKey);

        if (parentIndex >= 0) {
          const parentRow = data[parentIndex];

          if (parentRow?.children) {
            const dataIndex = parentRow.children.findIndex(it => it.key === rowData.key);

            if (dataIndex >= 0) {
              const item = parentRow?.children[dataIndex];
              let newChildren = parentRow.children;

              newChildren.splice(dataIndex, 1, {
                ...item,
                ...rowData
              });

              let parentitem = {
                ...parentRow,
                children: newChildren
              };

              // 将业务代码剥离出来
              if (parentRow?.productModel === EnumProductModel.assemblyProduct) {
                const amounts = newChildren.map(it => it.amount || 0);
                const amount = sum(amounts);
                const goodBins = newChildren.filter(it => it.binIndex === 0 || it.binIndex === 1);
                const goods = goodBins.map(it => it.amount ?? 0);
                const good = sum(goods);
                const bad = amount - (good ?? 0);

                parentitem = {
                  ...parentitem,
                  amount: amount,
                  available: good,
                  good,
                  bad,
                  unavailable: bad,
                };
              }

              if (parentRow?.productModel === EnumProductModel.dpProduct) {
                const goods = newChildren.map(it => it.good || 0);
                const bads = newChildren.map(it => it.bad || 0);
                const good = sum(goods);
                const bad = sum(bads);

                parentitem = {
                  ...parentitem,
                  good,
                  bad,
                  available: good,
                  unavailable: bad,
                  amount: good + bad
                };
              }

              newData.splice(parentIndex, 1, parentitem);
            }
          }
        }
      } else {
        // 父行
        const dataIndex = data.findIndex(it => it._id === rowData._id);

        if (dataIndex >= 0) {
          const item = data[dataIndex];

          newData.splice(dataIndex, 1, {
            ...item,
            ...rowData,
          });
        }
      }
    } else {
      console.warn('请给列表数据传入键值key');
    }
  }

  return newData;
};

// 用来处理左侧第一列作为标题栏的转置矩阵数据
export const transformDatas = (_columns: any[], tableRows: any[]): any[] => {
  if (tableRows?.length > 0) {
    let transDatas: any[] = [];
    for (let i = 0; i < tableRows.length; i++) {
      let item = tableRows[i];
      if (i === 0) {
        let keys = _columns.map(it => it.title);
        transDatas.push(keys);
      }
      let values: any[] = [];
      Object.entries(item).forEach(([key, val]) => {
        let columnsIdx = findIndex(_columns, it => it.dataIndex === key);
        if (columnsIdx !== -1) values[columnsIdx] = val;
      });
      transDatas.push(values);
    }
    let newArray = transDatas[0].map((col, idx) => transDatas.map((row: any) => row[idx]));
    let rowKey = new Array(tableRows.length + 1).fill(0).map((it, idx) => `dataIndex${idx}`);
    let tansformNewData: any[] = [];

    for (let j = 0; j < newArray.length; j++) {
      let obj = {};
      for (let k = 0; k < rowKey.length; k++) {
        obj[rowKey[k]] = newArray[j][k];
      }
      tansformNewData.push(obj);
    }
    return tansformNewData;
  }
  return [];
};

/**
 * 用来保留有效位数
 * @param formData 需要保留位数的小数
 * @param digits 保留的有效位数位数, 默认保留3位
 */
export const reservingDecimal = (formData, digits = 3) => {
  let data = formData;

  if (!data && !isNumber(data)) return undefined;

  if (isString(data)) {
    data = toNumber(data);
  }

  if (Math.abs(data) >= 1) {
    data = data.toFixed(digits);
  } else {
    data = digits < 1 ? 0 : data.toPrecision(digits);
  }

  return toNumber(data);
};

export const getEchartsSaveTitle = (analysisDetail, content, title?) => {
  const productName = analysisDetail?.productName ?? analysisDetail?.product?.[0]?.customerName;
  const dieNumber = analysisDetail?.dieNumber;
  let msg = title;

  if (content) {
    const tag: ITag | undefined = tagsData?.find(it => it?.key === content);
    msg = tag?.name;
  }

  const pageTitle = `${productName}_${msg}_${dieNumber}`;

  return pageTitle;
};

// 暂时先trim一层
export const trimString = (payload: any) => {
  return Object.entries(payload)?.reduce(
    (prev, [key, val]) => {
      if (typeof val === 'string') {
        prev[key] = val?.trim();
      } else {
        prev[key] = val;
      }

      return prev;
    },
    {} as any
  );
};

/**
 * 用来处理Tooltip里的formatter
 * @param params formatter里的params
 * @param valueIdx echarts数据data里的index
 * @param shouldHandleData 是否需要数据处理
 * @param digits 数据处理保留的有效位数位数
 */
export const formatterTooltip = (
  params: any[],
  valueIdx: number = 1,
  shouldHandleData: boolean = false,
  digits?: number
) => {
  let res = `<div>${params?.[0]?.axisValue}</div>`;

  for (let i = 0; i < params.length; i++) {
    const currentValue = isArray(params[i].value) ? params[i].value?.[valueIdx] : params[i].value;
    if (currentValue) {
      const handleValue = shouldHandleData ?
        reservingDecimal(currentValue, digits ?? 3) :
        currentValue;

      res += `<p>${params[i].marker}${params[i].seriesName}:&nbsp&nbsp
        <b style="float: right">${handleValue}</b></p>`;
    }
  }
  return res;
};

// tslint:disable-next-line:max-file-line-count
