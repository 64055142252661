import React from 'react';
import { CommentBoxContextProps } from './interface';

export const initialCommentBoxCtx: CommentBoxContextProps = {
  suggestionData: [],
  displaySuggests: false,
  uploaderOptions: {},
  // uploaderEventsHandler: {},
};

export const CommentBoxContext = React.createContext<CommentBoxContextProps>(initialCommentBoxCtx);
