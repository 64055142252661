import React from 'react';
import classnames from 'classnames';
import { message } from 'antd';
import { bind } from 'lodash-decorators';
import { Tooltip } from 'antd';
import to from 'await-to-js';

import styles from './index.module.less';
import MemberTree from 'src/containers/MemberTree';
import GroupName, { GroupName as GN } from './components/GroupName';

import AddMember from 'src/containers/MemberTable/AddMember';
import { Workspace, WithWorkspaceId } from 'src/models/workspace/interface';
import { connect, RootDispatch } from 'src/store';
import { toInt, mapUsers } from 'src/utils/user';
import { MemberGroupEditParams } from 'src/services/net/workspace';

interface StateProps {}

interface OwnProps {
  workspace: Workspace;
  workspaceId: string;
  role: AllRole;
  users: RoleUser[];
}

type AddRemoveFn = (arg: WithWorkspaceId<{name: string}>) => any;

interface DispatchProps {
  addMemberGroup: AddRemoveFn;
  removeMemberGroup: AddRemoveFn;
  editMemberGroup: (arg: WithWorkspaceId<MemberGroupEditParams>) => any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  modalVisible: boolean;
  editType: 'create' | 'edit';
  selectedMembers: UserId[];
  memberGroupProps: {
    value: string;
    disabledValues: string[];
  };
}

class CooMemberDrawer extends React.PureComponent<Props, State> {
  $groupNameRef = React.createRef<GN>();
  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false,
      editType: 'create',
      selectedMembers: [],
      memberGroupProps: {
        value: '',
        disabledValues: [],
      }
    };
  }

  get totalGroupNames() {
    const { workspace } = this.props;
    if (!workspace) {
      return [];
    }
    const { memberGroup } = workspace;

    return memberGroup.map(it => it.name);
  }

  formatMembersByGroup() {
    const { workspace, users } = this.props;

    if (!workspace) {
      return [];
    }

    const { memberGroup } = workspace;
    
    return [{
      title: '全部成员',
      members: users,
    }].concat(memberGroup.map(g => ({ title: g.name, members: mapUsers(g.users, users) })));
  }

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind 
  closeModal() {
    this.setState({ modalVisible: false, selectedMembers: [] });
  }

  @bind
  openCreateModal() {
    this.setState({
      memberGroupProps: {
        value: '',
        disabledValues: this.totalGroupNames,
      },
      selectedMembers: [],
      editType: 'create',
    }, this.openModal);
  }

  @bind
  openEditModal(name: string) {
    const { workspace } = this.props;
    const group = workspace.memberGroup.find(it => it.name === name);

    if (!group) {
      message.error('准备编辑分组失败，分组或许已被更改/移除');
      return;
    }

    this.setState({
      editType: 'edit',
      selectedMembers: group.users,
      memberGroupProps: {
        value: name,
        disabledValues: this.totalGroupNames.filter(it => it !== name),
      }
    }, this.openModal);
  }

  @bind 
  syncSelectMembers(selectedMembers: UserId[]) {
    this.setState({ selectedMembers });
  }

  @bind
  async addMemberGroup(name: string) {
    const { workspaceId, addMemberGroup } = this.props;
    await addMemberGroup({ workspaceId, name });
  }

  @bind
  async removeMemberGroup(name: string) {
    const { workspaceId, removeMemberGroup } = this.props;
    await removeMemberGroup({ workspaceId, name });
  }

  @bind
  async editMemberGroup(form: MemberGroupEditParams) {
    const { workspaceId, editMemberGroup } = this.props;
    await editMemberGroup({ workspaceId, ...form });
  }

  @bind
  async handleEditMemberGroup(memberIds: string[]) {
    const { editType, memberGroupProps: { value } } = this.state;
    const groupNameComp = this.$groupNameRef.current;
    const [err, vals] = await to(groupNameComp!.tryGetValues());
 
    if (err) {
      return Promise.reject(err);
    }

    const isCreate = editType === 'create';

    const { groupName } = vals!;
    const form: MemberGroupEditParams = {
      oldName: groupName,
    };

    if (isCreate) {
      await this.addMemberGroup(groupName);
    } else {
      form.oldName = value;
      form.newName = groupName;
    }

    if (memberIds.length === 0 && isCreate) {
      return Promise.resolve();
    }

    form.members = memberIds.map(user => toInt(user));
    return this.editMemberGroup(form);
  }

  render () {
    const { editType, modalVisible, memberGroupProps } = this.state;
    const { role, users } = this.props;
    const isMember = role === 'MEMBER';
    const members = this.formatMembersByGroup();

    return (
      <div className={classnames(styles.cooMemberDrawer, 'flex')}>
        <header className={classnames(styles.header, 'flex')}>
          <h4 className="cell">协作区成员</h4>
          {(role !== 'MEMBER') && 
            <Tooltip title="添加分组">
              <i className="c-icon icon-plus-thick add-group-icon" onClick={this.openCreateModal} />
            </Tooltip>
          }

          <AddMember 
            visible={modalVisible} 
            modalTitle={editType === 'create' ? '新建成员分组' : '编辑成员分组'} 
            onCancel={this.closeModal} 
            onSubmit={this.handleEditMemberGroup}
            users={users}
            value={this.state.selectedMembers}
            onChange={this.syncSelectMembers}
          >
            {<GroupName wrappedComponentRef={this.$groupNameRef} {...memberGroupProps}/>} 
          </AddMember>
        </header>
        <section className={classnames(styles.body, 'flex cell')}> 
          <MemberTree 
            renderEdit={!isMember} 
            onRemove={this.removeMemberGroup} 
            onEdit={this.openEditModal} 
            memberGroups={members}
          />
        </section>
      </div>
    );
  }
}

const mapState = () => ({});

const mapDispatch = ({ workspace }: RootDispatch) => ({
  addMemberGroup: workspace.asyncAddMemberGroup,
  editMemberGroup: workspace.asyncEditMemberGroup,
  removeMemberGroup: workspace.asyncRemoveMemberGroup,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(CooMemberDrawer);
