import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { State } from './interface';
import { qcMonitorApi } from 'src/services/net';
import {
  AlertContactsListParams,
  AlertContact,
  AlertContactEdit,

  AlertContactGroupParams,
  AlertContactGroup,
  AlertContactGroupEdit,
} from 'src/services/net/qcDataMonitor';
import { trimString } from 'src/utils';

const initialState: State = {
  contactList: [],
  contactCount: 0,
  contactDetail: {},

  groupList: [],
  groupCount: 0,
  groupDetail: {},
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setContactList(state: State, payload: { count: number, data: AlertContactEdit[] }) {
      return produce(state, draft => {
        draft.contactCount = payload.count;
        draft.contactList = payload.data;
      });
    },
    setContactDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.contactDetail = payload;
      });
    },
    setContactGroup(state: State, payload: { count: number, data: AlertContactGroupEdit[] }) {
      return produce(state, draft => {
        draft.groupCount = payload.count;
        draft.groupList = payload.data;
      });
    },
    setGroupDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.groupDetail = payload;
      });
    },
  },
  effects: (dispatch) => ({
    async getContactTableList(params: AlertContactsListParams) {
      const { data } = await qcMonitorApi.getAlertContactsList(params);

      this.setContactList(data);
    },
    async getContactDetail(_id: string) {
      const { data } = await qcMonitorApi.getContactById(_id);
      this.setContactDetail(data);
    },
    async postContact(payload: AlertContact) {
      await qcMonitorApi.postAlertContact(trimString(payload));
    },
    async putContact(payload: AlertContactEdit) {
      await qcMonitorApi.putAlertContact(trimString(payload));
    },
    async deleteContact(_id: string) {
      await qcMonitorApi.deleteAlertContact(_id);
    },

    async getGroupTableList(params: AlertContactGroupParams) {
      const { data } = await qcMonitorApi.getAlertGroupList(params);

      this.setContactGroup(data);
    },
    async postGroup(payload: AlertContactGroup) {
      await qcMonitorApi.postAlertGroup(payload);
    },
    async putGroup(payload: AlertContactGroupEdit) {
      await qcMonitorApi.putAlertGroup(payload);
    },
    async deleteGroup(_id: string) {
      await qcMonitorApi.deleteAlertGroup(_id);
    },
    async getGroupDetail(_id: string) {
      const { data } = await qcMonitorApi.getGroupById(_id);
      this.setGroupDetail(data);
    },
  })
});
