import React, { ComponentClass } from 'react';
import { RouteComponentProps } from 'react-router';
import log from 'loglevel';
import { CacheRouteProps, CacheLifecycleObj } from '../interface';
import { CacheComponent, Updatable, HoneyRouteComponent } from '../components';
import { Route } from 'react-router-dom';
import { has, get } from '../utils/lodash';

const isEmptyChildren = children => React.Children.count(children) === 0;

function getComponent(c: any) {
  if (c.WrappedComponent) {
    // Find React-Redux connected Component.
    return c.WrappedComponent;
  }

  return c;
}

function hookCDM(component: ComponentClass, cycles: CacheLifecycleObj) {
  const { prototype } = component as ComponentClass;
  if (get(prototype, 'componentDidMount.__hooked')) {
    //
    return;
  }

  const cmd = get(prototype, 'componentDidMount');
  const didCache = get(prototype, 'componentDidCache');
  const didRecover = get(prototype, 'componentDidRecover');

  function hookedComponentDidMount() {
    cmd && cmd.call(this);
    if (didCache) {
      cycles.didCache(didCache.bind(this));
    }
    if (didRecover) {
      cycles.didRecover(didRecover.bind(this));
    }
  }

  (hookedComponentDidMount as any).__hooked = true;
  prototype.componentDidMount = hookedComponentDidMount;
}

export class CacheRouteComponent extends HoneyRouteComponent<CacheRouteProps> {
  static componentName = 'CacheRouteComponent';

  render() {
    let {
      children,
      render,
      component,
      when,
      behavior,
      cacheKey,
      className,
    } = this.props;

    /**
     * Note:
     * If children prop is a React Element, define the corresponding wrapper component for supporting multiple children
     *
     * 说明：如果 children 属性是 React Element 则定义对应的包裹组件以支持多个子组件
     */
    if (React.isValidElement(children) || !isEmptyChildren(children)) {
      render = () => children;
    }

    return (
      /**
       * Only children prop of Route can help to control rendering behavior
       * 只有 Router 的 children 属性有助于主动控制渲染行为
       */
      <Route
        {...this.routeProps}
        children={props => (
          <CacheComponent
            {...props}
            {...{ when, className, behavior, cacheKey }}
          >
            {cacheLifecycles => (
              <Updatable
                match={props.match}
                render={() => {
                  Object.assign(props, { cacheLifecycles });

                  if (component) {
                    const c = getComponent(component);
                    if (has(c, 'prototype.render')) {
                      //
                      try {
                        hookCDM(c as ComponentClass, cacheLifecycles);
                      } catch (e) {
                        log.error(e);
                      }
                    }

                    return React.createElement(component, props);
                  }

                  if (render) {
                    return render(props as RouteComponentProps);
                  }

                  if (children) {
                    // 非函数的children已经在之前被包裹，所以执行到此处的的 children 一定是 function
                    return (children as Function)(props);
                  }

                  return null;              
                }}
              />
            )}
          </CacheComponent>
        )}
      />
    );
  }
}

export default CacheRouteComponent;
