import * as React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { SiderProps } from 'antd/lib/layout/Sider';
import pathToRegexp from 'path-to-regexp';
import { Link } from 'react-router-dom';
import { urlToList } from '@chipcoo/fe-utils';

import styles from './SiderMenu.module.less';
import { CellMenu } from 'src/common/menu';

interface Props extends SiderProps {
  menuData: CellMenu[];
  location: any;
}

interface State {
  openKeys: string[];
}

const { Sider } = Layout;
const { SubMenu } = Menu;

const getIcon = (icon) => {
  if (typeof icon === 'string' && icon.indexOf('http') === 0) {
    return <img src={icon} alt="icon" />;
  }
  if (typeof icon === 'string') {
    return <Icon type={icon} />;
  }
  return icon;
};
export const getFlatMenuKeys = menu =>
  menu
    .reduce(
      (keys, item) => {
        keys.push(item.path);
        if (item.children) {
          return keys.concat(getFlatMenuKeys(item.children));
        }
        return keys;
      },
      []
    );
export const getMeunMatchKeys = (flatMenuKeys, paths) =>
  paths
    .reduce(
      (matchKeys, path) => (
        matchKeys.concat(
          flatMenuKeys.filter(item => pathToRegexp(item).test(path))
        )),
      []
    );

class SiderMenu extends React.PureComponent<Props, State> {
  static defaultProps = {
    collapsed: false,
    theme: 'dark'
  };

  private menus: CellMenu[];
  private flatMenuKeys;

  constructor(props: Props) {
    super(props);

    this.menus = this.props.menuData;
    this.flatMenuKeys = getFlatMenuKeys(props.menuData);
    this.state = {
      openKeys: this.getDefaultCollapsedSubMenus(props)
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        openKeys: this.getDefaultCollapsedSubMenus(nextProps)
      });
    }
  }

  /**
   * Recursively flatten the data
   * [{path:string},{path:string}] => {path,path2}
   * @param  menus
   */
  getFlatMenuKeys(menus: CellMenu[]) {
    let keys: string[] = [];

    menus.forEach((item) => {
      if (item.children) {
        keys.push(item.path);
        keys = keys.concat(this.getFlatMenuKeys(item.children));
      } else {
        keys.push(item.path);
      }
    });
    return keys;
  }

  /**
   * Get selected child nodes
   * /user/chen => /user/:id
   */
  getSelectedMenuKeys = () => {
    const { location: { pathname } } = this.props;
    return getMeunMatchKeys(this.flatMenuKeys, urlToList(pathname));
  }

  // 将pathname转换为sideMenu被选中的项
  getDefaultCollapsedSubMenus(props: Props) {
    const { location: { pathname } } = props || this.props;
    return getMeunMatchKeys(this.flatMenuKeys, urlToList(pathname));
  }

  // conversion Path
  // 转化路径
  conversionPath = (path: string) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    } else {
      return `/${path || ''}`.replace(/\/+/g, '/');
    }
  }

  /**
   * 判断是否是http链接.返回 Link 或 a
   * Judge whether it is http link.return a or Link
   * @memberof SiderMenu
   */
  getMenuItemPath = (item) => {
    const itemPath = this.conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target, name } = item;

    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}<span>{name}</span>
        </a>
      );
    }

    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === this.props.location.pathname}
      >
        {icon}<span>{name}</span>
      </Link>
    );
  }

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item: any) => {
    if (item.children && item.children.some(child => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children);
      // 当无子菜单时就不展示菜单
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu
            title={
              item.icon ? (
                <span>
                  {getIcon(item.icon)}
                  <span>{item.name}</span>
                </span>
              ) : (
                item.name
              )
            }
            key={item.path}
          >
            {childrenItems}
          </SubMenu>
        );
      }
      return null;
    } else {
      return <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>;
    }
  }

  getNavMenuItems = (menusData: CellMenu[]): any[] => {
    if (!menusData) {
      return [];
    }
    return menusData
      .filter(item => item.name && !item.hideInMenu)
      .map(item => {
        // make dom
        const ItemDom = this.getSubMenuOrItem(item);
        return ItemDom;
      })
      .filter(item => item);
  }

  isMainMenu = key => {
    return this.menus.some(item => key && (item.key === key || item.path === key));
  }

  // 处理左侧sideMenu切换时候的场景
  handleOpenChange = (openKeys) => {
    const lastOpenKey = openKeys[openKeys.length - 1];
    const moreThanOne = openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys],
    });
  }

  render() {
    const { collapsed, theme, ...passThoughProps } = this.props as any;
    const { openKeys } = this.state;

    const menuProps = collapsed ? {} : {
      openKeys,
    };

    let selectedKeys = this.getSelectedMenuKeys();

    if (!selectedKeys.length) {
      selectedKeys = [openKeys[openKeys.length - 1]];
    }

    return (
      <Sider
        {...passThoughProps}
        theme={theme}
        trigger={null}
        className={`${styles.wrap} thin-scroll`}
        width={256}
      >
        {/*<div className={styles.logo}>*/}
          {/*<Link to="/">*/}
            {/*<img src={logo} alt="logo" />*/}
          {/*</Link>*/}
        {/*</div>*/}
        <Menu
          key="Menu"
          theme={theme}
          mode="inline"
          {...menuProps}
          onOpenChange={this.handleOpenChange}
          selectedKeys={selectedKeys}
        >
          {this.getNavMenuItems(this.menus)}
        </Menu>
      </Sider>
    );
  }
}
// tslint:disable-next-line
export default SiderMenu;
