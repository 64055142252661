import { createModel } from '@rematch/core';
import produce from 'immer';
import { EnumCustomNameSuffixType } from '@chipcoo/constant';

// import { RootState } from 'src/store';
import { qcApi } from 'src/services/net';
import { TableModelCommonEffects } from 'src/models/type';
import { State, PutFormData } from './interface';
import {
  handleSelectData,
  handleFormData,
  handleCustomerAnalysisList,
} from './handle';

const originFormData: PutFormData = {
  name: '',
  suffix: EnumCustomNameSuffixType.none,
  template: false,
  type: [],
  primaryData: [],
  secondaryData: [],
  analysisItems: [],
  primaryQueryParams: {},
  secondaryQueryParams: {},
  primaryTestItems: [],
  secondaryTestItems: [],
};

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
  selectData: [],
  selectDataCount: 0,
  putFormData: originFormData,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setAnalysisList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setFormData(state: State, payload: any) {
      return produce(state, draft => {
        const { type, data } = payload;

        if (type === 'basic') {
          draft.putFormData.name = data.name;
          draft.putFormData.type = data.type;
          draft.putFormData.suffix = data.suffix;
        }
        if (type === 'template') {
          draft.putFormData.template = data;
        }
        if (type === 'type') {
          draft.putFormData.type = data;
        }
        if (type === 'primary') {
          draft.putFormData.primaryData = data.selectedData;
          if (data.queryParams) draft.putFormData.primaryQueryParams = data.queryParams;
        }
        if (type === 'secondary') {
          draft.putFormData.secondaryData = data.selectedData;
          if (data.queryParams) draft.putFormData.secondaryQueryParams = data.queryParams;
        }
        if (type === 'primaryTest') {
          draft.putFormData.primaryTestItems = data;
        }
        if (type === 'secondaryTest') {
          draft.putFormData.secondaryTestItems = data;
        }
        if (type === 'item') {
          draft.putFormData.analysisItems = data;
        }
        if (type === 'clear') {
          draft.putFormData = originFormData;
        }
      });
    },
    setSelectData(state: State, payload: any) {
      return produce(state, draft => {
        draft.selectData = payload?.data;
        draft.selectDataCount = payload?.count;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getAnalysisList(params);
      const handleData = handleCustomerAnalysisList(data);

      this.setAnalysisList({
        data: handleData,
        count: data?.count
      });
    },
    async getTableItemDetail() {
      //
    },
    async postCreate(data: PutFormData) {
      const handleData = handleFormData(data);

      const { data: respData } = await qcApi.postCustomAnalysis(handleData);
      return respData;
    },
    async patchModify() {
      //
    },
    async getSelectData(params: any) {
      const { data } = await qcApi.getAnalysisList(params);
      const handleData = handleSelectData(data);
      
      this.setSelectData({data: handleData, count: data?.count});
    }
  })
});
