import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EnumProductModel } from '@chipcoo/constant';
import { messageModal } from '../../utils/messageModal';

export interface IDemandOrderFetchParams {
  limit?: number;
  page?: number;
  ids?: string[];
  productModel?: EnumProductModel;
}

export interface IDemandOrderMaterial {
  name: string;
  amount: string;
  dieName: string;
  dieNumber: string;
  waferIds: number[];
  binType: number[];
  stampNumber: string;
  remark: string;
  testResult: string;
}

export interface IDemandOrderBase {
  product: string;
  productModel: EnumProductModel;
  demandQuantity: number;
  demandWaferQuantity: number;
  expectedDeliveryDate: string;
  packingMethod: string;
  markMode: string;
  isPrintStampNumber: boolean;
  // materialFrom: EnumDemandOrderMaterialFrom;
  address: string;
  materials?: IDemandOrderMaterial[];
  stockInfos?: any[];
}

export interface IDemandOrderDetail extends IDemandOrderBase {
  _id: string;
  /** Date string */
  createdAt: string;
}

export default function(api: AxiosInstance) {
  return {
    list(params: IDemandOrderFetchParams, config: AxiosRequestConfig = {}) {
      return api.get('ops/demand-order/search', { ...config, params });
    },
    // create(data: IDemandOrderBase, config: AxiosRequestConfig = {}) {
    create(data: any, config: AxiosRequestConfig = {}) {
      return api.post('ops/demand-order', data, {
        ...config,
        handleSpecialError: {
          409: (e) => {
            messageModal({
              content: e?.data?.message
            });
          }
        }
      });
    },
    fetch(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`ops/demand-order/${id}`, config);
    },
    cancel(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`ops/demand-order/${id}/cancel`, config);
    },
    // 获取订单下的计划、步骤及指令
    getPlansByOrderId(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`ops/plan/order/${id}`, config);
    },
    // 删除已拒绝订单
    deleteOrder: (orgId: string, config?: AxiosRequestConfig) => {
      return api.delete(`ops/demand-order/${orgId}`, config);
    },
    // 订购名列表
    searchOrderTemplate: (product: string, config?: AxiosRequestConfig) => {
      return api.get('order-template/search');
    },
    // 订单看板各个状态的订单数量
    searchOrderStatus: (params: any, config: AxiosRequestConfig = {}) => {
      return api.get('ops/demand-order/dashboard', { ...config, params});
    },

    // 获取规则/获取策略
    getPolicy(params: any, config: AxiosRequestConfig = {}) {
      return api.get(`ops/policy/resources`, { ...config, params });
    }
  };
}
