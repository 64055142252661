/**
 * @注意：chatRoomList的数据中的sender字段于chatPanel中不同，chatPanel中所有房间中曾经出现过的人都可以统一获取，因此
 * sender只用一个number(默认) -> string(存储)就可以了
 * 但chatRoomList中没有这个数据，因此最新的一条消息里的sender字段统统都是自带User的
 */
/* tslint:disable:max-file-line-count */
import { createModel, ModelConfig } from '@rematch/core';
import { findIndex, get, isNumber, debounce } from 'lodash';
import { fillImgSrc } from '@chipcoo/fe-utils';

import { getNormalizedData, initialNormalizedData, notificationPop } from 'src/utils';
import update from 'immutability-helper-x';
import {
  shouldResortRoomList,
  sortRoomListByNewMessagePush,
  createChatRoom
} from './chatRoomList.tool';
import {
  InitialState,
  RoomLastMessage,
  ReceiveNewMessage,
  SortRoomList,
  RoomTop,
  PrivateRoom
} from './chatRoomList.type';

import { imApi } from 'src/services/net';
import { findNameById, isEqualUserId } from 'src/utils/user';
import { RootState } from 'src/store';

const initialState: InitialState = {
  currentActiveRoom: void 0,
  roomList: { ...initialNormalizedData(), isInitial: false },
  couldSelectMembers: { ...initialNormalizedData(), isInitial: false }
};

const markReadFn: { [key: string]: Function } = {};
function getMarkReadFn(roomId: string, dispatch: any) {
  if (!markReadFn[roomId]) {
    const fn = async () => {
      await imApi.patchRoomRead(roomId);
      dispatch.chatMisc.getUnreadMessageNumber();
    };
    markReadFn[roomId] = debounce(fn, 600);
  }

  return markReadFn[roomId];
}

export default createModel<InitialState, ModelConfig<InitialState, InitialState>>({
  state: initialState,
  reducers: {
    // 存一下当前打开的这个房间的id，用来判断是否要更新chatPanel中的数据
    setCurrentRoomId: (state: InitialState, roomId?: string) => update.$set(state, 'currentActiveRoom', roomId),

    // chatPanel中删除消息或者发送新消息之类的，roomCard这边的最新消息也要更新
    updateLastMessageInRoom: (state: InitialState, lastMessage: RoomLastMessage) => {
      const { roomId } = lastMessage;

      return update.$set(state, `roomList.byId.${roomId}.lastMessage`, lastMessage);
    },

    // 置顶房间，新建房间，或者发送消息以后，该房间被推至置顶的房间的下面第一条
    // @注意：webpush过来的新消息不走这个
    sortRoomList: (state: InitialState, payload: SortRoomList) => {
      const { type, roomId } = payload;
      const { roomList } = state;

      if (type === 'POST_NEW' || type === 'CREATE_ROOM') {
        const newAllIds = sortRoomListByNewMessagePush(roomList, roomId);

        return update.$set(state, 'roomList.allIds', newAllIds);
      }

      // type === 'SET_TOP'，目前还用不着╮(╯_╰)╭
    },
  },
  effects: (dispatch) => ({
    // 新建聊天房间
    async createRoom(userIds: string[], rootState: any) {
      const isPrivate = userIds.length === 1;
      const state = rootState.chatRoomList as InitialState;
      const { isInitial } = state.roomList;

      /**
       * 好友列表可以通过点击聊天跳转至对应的私聊，对应的操作就是直接建房间开搞，这时存在两种情况：
       * 1、好友列表数据已经isInitial掉了，那么这个时候就跟新建私聊时候的操作一样
       * 2、如果好友列表的数据还未isInitial，那么创建房间后返回id就ok，直接跳转过来后让im这边自行操作
       */
      if (!isInitial) {
        const data1 = await createChatRoom(userIds, isPrivate);
        const { _id: roomId1 } = data1;

        return roomId1;
      }

      // 如果是私聊，检查是否已经有这个房间了，如果有，将对应的房间置顶就可以了
      if (isPrivate) {
        const { allIds, byId } = state.roomList;
        const roomIndex = findIndex(allIds, (id: string) => {
          const roomData = byId[id];
          const { type, user } = roomData as PrivateRoom;
          return Boolean(type === 'PRIVATE' && user && `${user._id}` === userIds[0]);
        });

        if (~roomIndex) {
          const roomId = byId[allIds[roomIndex]]._id;
          this.sortRoomList({ type: 'CREATE_ROOM', roomId });

          return roomId;
        }
      }

      const data2 = await createChatRoom(userIds, isPrivate);
      const { _id: roomId2 } = data2;
      const updateStateOperation: UpdateStateOperation[] = [];

      updateStateOperation.push({ method: '$merge', path: 'roomList.byId', data: { [roomId2]: data2 } });

      if (shouldResortRoomList(state.roomList, roomId2, true)) {
        const allIds = sortRoomListByNewMessagePush(state.roomList, roomId2, 'CREATE_ROOM');

        updateStateOperation.push({ path: 'roomList.allIds', data: allIds });
      }

      this.updateState(updateStateOperation);

      return roomId2;
    },

    // 获取所有房间列表
    async getRoomList() {
      const { data } = await imApi.getRoomList();
      const initialData = { ...getNormalizedData(data), isInitial: true };

      // 将所有房间中的用户丢进用户列表
      // TODO: 是否要丢入有待商榷
      data.forEach(room => {
        dispatch.user.setUser(room.users || []);
      });

      this.updateState({ path: 'roomList', data: initialData });
    },

    // state 中删除某个房间
    async removeRoom(roomId: string, rootState: RootState) {
      const state = rootState.chatRoomList;
      const unreadCount = get(state.roomList.byId, `${roomId}.unread`);

      if (isNumber(unreadCount) && unreadCount) {
        await dispatch.chatMisc.changeUnreadCount(-unreadCount);
      }

      this.removeNormalizedData({
        removeIds: [roomId],
        path: 'roomList'
      });
    },

    // 删除某个房间
    async deleteRoom(roomId: string) {
      await imApi.deleteRoom(roomId);

      await this.removeRoom(roomId);

      return true;
    },

    /**
     * 下面都是socket推送
     */
    // 新推送过来的聊天消息
    async receiveNewMessage(receiveNewMessage: ReceiveNewMessage, rootState: any) {
      const state = rootState.chatRoomList as InitialState;
      const { currentActiveRoom, roomList } = state;
      const { allIds: listAllIds, isInitial } = roomList;
      const { payload, isSelf, icon, message, currentUserId } = receiveNewMessage;
      const { message: pushedMessageData } = payload;
      const updateStateOperation: UpdateStateOperation[] = [];
      const { atUsers = [] } = payload;
      // 如果自带完整链接的，则直接显示；否则默认为是发送者头像且为不完成的头像
      const { sender } = pushedMessageData;
      const iconAvatar = fillImgSrc(icon, '/api/console/user/avatar/');
      let updateUnreadCount = true;
      // Fixme:
      // 补全 atUsers
      // 可能是废代码，按照下面逻辑这里补不补都没有任何区别
      // 暂时保留
      if (!payload.message.atUsers) {
        payload.message.atUsers = atUsers;
      }

      const updateUserList = atUsers.slice();
      if (sender) {
        updateUserList.push(sender);
      }
      dispatch.user.setUser(updateUserList);

      // 自己不需要显示桌面提醒
      // todo 区分私聊及群聊，@（有人@你）等
      if (!isSelf) {
        const { attachments } = payload.message;
        const filename = attachments[0] && attachments[0].versions[0].filename;
        let template = message
          ? message : (
            attachments.length ? `上传了附件 ${filename}` : '今天天气不错'
          );
        let isAtMe = false;
        // 解析消息消息正文
        if (atUsers.length) {
          template = template.replace(/{{@(\d+)}}/gm, (_, userId) => {
            isAtMe = isEqualUserId(userId, currentUserId);

            const name = findNameById(atUsers, userId);

            if (name) {
              return `@${name}`;
            }

            return `{{@${userId}}}`;
          });
        }

        notificationPop({
          event: 'im',
          title: isAtMe ? '有人@我' : '收到一条新消息',
          template: template,
          publisher: payload.message.sender,
          icon: iconAvatar
        });
      }

      // 如果数据都没有初始化，推送数据没必要进行更新
      if (!isInitial) return;

      /**
       * 因为自己发送消息以后自己也会收到推送(防止开多个同样的标签页的更新问题)
       * 这样会带来的问题是post一条消息或者删除后数据会更新两遍，这里要排除掉这种情况
       * @注意：考虑到后面需要做上传消息失败后的重新传，socket无法知道失败这个状态，所以不考虑更新数据全部使用socket推送
       */
      const { roomId, _id: messageId } = pushedMessageData;

      if (listAllIds.includes(roomId)) {
        const lastMessage = roomList.byId[roomId].lastMessage;

        if (lastMessage && lastMessage._id === messageId ) { return; }
      }

      // 检查房间列表里面是否有这个房间，如果没有，重新获取整个房间的信息
      const hasReceiveRoom = listAllIds.includes(roomId);

      if (!hasReceiveRoom) {
        const { data: roomInfoData } = await imApi.getRoomInfo(roomId);
        const { unread } = roomInfoData;

        roomInfoData.lastMessage = pushedMessageData;
        // 根据拿到的房间的数据的未读数去进行更新总的未读数
        dispatch.chatMisc.changeUnreadCount(unread);
        updateStateOperation.push(
          { method: '$merge', path: 'roomList.byId', data: { [roomId]: roomInfoData } }
        );
      } else {
        // 如果房间已经存在，那么直接更新对应房间信息的lastMessage数据
        updateStateOperation.push(
          { path: `roomList.byId.${roomId}.lastMessage`, data: pushedMessageData },
        );
      }

      // 如果消息推送过来的时候正好打开了对应消息所在的房间，因此还需要更新聊天信息
      if (roomId === currentActiveRoom) {
        const messageData = { ...pushedMessageData, sender: `${pushedMessageData.sender._id}` };
        getMarkReadFn(roomId, dispatch)();
        updateUnreadCount = false;
        dispatch.chatPanel.addNewMessage(messageData);
      } else {
        // 如果新消息推送过来的时getUnreadMessageNumber候没有代开对应的房间，且消息非本人，那么需要把对应房间的未读数加1
        // 如果一个房间之前被删除了，重新获取的房间信息的时候就已经改过了未读的统计数量
        if (!isSelf && hasReceiveRoom) {
          const path = `roomList.byId.${roomId}.unread`;
          const newUnreadCount = get(state, path);

          dispatch.chatMisc.changeUnreadCount(1);

          updateStateOperation.push({ path, data: isNumber(newUnreadCount) ? newUnreadCount + 1 : 1 });
        }
      }

      // 检查来新消息的房间是否是置顶房间，或者已经是在最上面了，把最新消息过来的房间给置顶到最上面(注意有房间会被置顶，新消息应该在置顶的下面)
      if (shouldResortRoomList(roomList, roomId, true)) {
        const allIds = sortRoomListByNewMessagePush(roomList, roomId);

        updateStateOperation.push(
          { path: 'roomList.allIds', data: allIds },
        );
      }

      updateUnreadCount && dispatch.chatMisc.getUnreadMessageNumber();

      this.updateState(updateStateOperation);
    },

    // 修改房间名称的推送
    setRoomName(receiveData: ISyncSocketData, rootState: any) {
      const { payload, resource: { id: _id }  } = receiveData;
      const { name } = payload;
      const state = rootState.chatRoomList as InitialState;
      const { currentActiveRoom } = state;

      // 如果当前打开的房间名称被修改了，替换掉名称
      if (currentActiveRoom === _id) {
        dispatch.chatPanel.updateState({ path: 'roomInfo.name', data: name });
      }

      this.updateState({ path: `roomList.byId.${_id}.name`, data: name });
    },

    async patchRoomName(payload: { roomId: string, name: string }) {

      await imApi.patchRoomName(payload);
      this.setRoomName({
        resource: { id: payload.roomId },
        payload: { name: payload.name }
      });
    },

    // 置顶/取消置顶房间
    async patchRoomTop (params: RoomTop, rootState: any) {
      const updateStateOperation: UpdateStateOperation[] = [];
      const state = rootState.chatRoomList as InitialState;
      const { roomList } = state;

      const { roomId, isTop } = params;
      await imApi.patchRoomTop(roomId, isTop ? 'untop' : 'top');

      updateStateOperation.push(
        { path: `roomList.byId.${roomId}.isTop`, data: !isTop },
      );

      const allIds = sortRoomListByNewMessagePush(roomList, roomId, 'SET_TOP');

      updateStateOperation.push(
        { path: 'roomList.allIds', data: allIds },
      );

      this.updateState(updateStateOperation);
    },

    async handleRoomSyncData(data: ISyncSocketData) {
      const { event, payload: { roomId } } = data;
      switch (event) {
        case 'create':
          // 虽然有获取单个房间信息的接口
          // 但最简单粗暴快捷的方式还是直接取一次房间列表

          await this.getRoomList();
          break;
        case 'dissolve':
          await this.removeRoom(roomId);
          break;
        default:
      }
    }
  })
});
