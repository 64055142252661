import * as React from 'react';
import { connect } from 'react-redux';
import { List, Tooltip } from 'antd';
import { get } from 'lodash';
import classnames from 'classnames';
import { bind, debounce } from 'lodash-decorators';
import { getUserName, getClosetAttrVal } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import urlConfig from 'src/config/url';
import { RootState, RootDispatch } from 'src/store';
import { Notify, MarkTodoParams } from 'src/models/notification/interface';

import LinkageCheckBox from './LinkageCheckBox';
import { EnhanceAvatar, TimeStamp, LinesEllipsis } from 'src/components/index';
import { enhanceUserAvatar } from 'src/utils/user';
// TransparentEllipsis

interface OwnProps {
  notifyId: string;
  isFocus: boolean;
  focusNotify: (notifyId: string) => void;
  resetUrl: () => void;
}
interface StateProps {
  notifyData: Notify;
}
interface DispatchProps {
  deleteNotifications: (notifyIds: string[]) => any;
  markTodo: (payload: MarkTodoParams) => any;
  markAsRead: (notifyIds: string[]) => any;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

const ListItem = List.Item;
export const mockUser: User = {
  nickname: '芯翼',
  _id: '@none',
  avatarSrc: `${urlConfig.baseUrl}/favicon.png`,
};

class NotifyCard extends React.PureComponent<P, S> {
  componentDidMount() {
    this.tryRead();
  }

  async tryRead() {
    const { isFocus, notifyData: { isRead } } = this.props;
    if (isFocus && !isRead) {
      this.markAsRead();
    }
  }

  handleDelNotify = async (e) => {
    e.stopPropagation();

    const { deleteNotifications, isFocus, resetUrl } = this.props;
    const notifyId = getClosetAttrVal(e.currentTarget, 'data-id');

    if (!notifyId) { return; }

    await deleteNotifications([notifyId]);

    // 如果删除的正好是一条被选中的消息，那么清一下对应的路由
    isFocus && resetUrl();
  }

  handleMarkTodo = async (e, action: 'mark' | 'unmark') => {
    e.stopPropagation();

    const { markTodo } = this.props;
    const notifyId = getClosetAttrVal(e.currentTarget, 'data-id');

    if (!notifyId) { return; }

    await markTodo({ notifyId, action });
  }

  handleViewNotifyDetail = (e) => {
    const notifyId = getClosetAttrVal(e.currentTarget, 'data-id');
    const { focusNotify } = this.props;

    if (!notifyId) { return; }

    focusNotify(notifyId);
  }

  @debounce(200)
  @bind
  markAsRead() {
    const { markAsRead, notifyId } = this.props;
    markAsRead([notifyId]);
  }

  componentDidUpdate() {
    this.tryRead();
  }

  renderMsgText = () => {
    const { notifyData } = this.props;
    const { message } = notifyData;

    return (
      <LinesEllipsis lines={3}>
        {message}
      </LinesEllipsis>
    );
    // const textComp = (
    //   <LinesEllipsis lines={3}>
    //     {message}
    //   </LinesEllipsis>
    // );
    //
    // return (
    //   <TransparentEllipsis
    //     text={textComp}
    //     className={styles.transparentEllipsis}
    //   />
    // );
  }

  render() {
    const { notifyData, notifyId, isFocus } = this.props;
    const { time, isTodo, repeat, isRead, fromUser } = notifyData;
    const userInfo = enhanceUserAvatar(fromUser || mockUser);
    const userName = getUserName(userInfo);
    const avatar = userInfo.avatar;

    return (
      <ListItem>
        <div
          className={classnames(styles.wrapper, { [styles.active]: isFocus })}
          data-id={notifyId}
          onClick={this.handleViewNotifyDetail}
        >
          <LinkageCheckBox notifyId={notifyId} className={styles.checkbox} />

          <div className={styles.notifyBody}>
            <EnhanceAvatar userName={userName} src={userInfo.avatarSrc || avatar} />

            <div className={styles.content}>
              <div className={styles.header}>
                <p className="flex-fill">{userName}</p>
                <div className="extra flex-static">
                  <Tooltip title="删除信息" mouseEnterDelay={0.5}>
                    <i className="hover-visible c-icon icon-trash" onClick={this.handleDelNotify} />
                  </Tooltip>

                  {
                    isTodo
                      ? (
                        <Tooltip title="移除待办" mouseEnterDelay={0.5}>
                          <i className="todo c-icon icon-flag" onClick={e => this.handleMarkTodo(e, 'unmark')} />
                        </Tooltip>
                      )
                      : (
                        <Tooltip title="标记待办" mouseEnterDelay={0.5}>
                          <i
                            className="hover-visible c-icon icon-flag-o"
                            onClick={e => this.handleMarkTodo(e, 'mark')}
                          />
                        </Tooltip>
                      )
                  }

                  <TimeStamp className="time" time={time} />
                </div>

              </div>

              <div className={styles.msgContent}>
                {this.renderMsgText()}

                {!Boolean(isRead) && !isFocus && (
                  <div className="unread-count">{repeat ? repeat : 1}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }
}

const mapState = ({ notification: { current } }: RootState, props) => ({
  notifyData: get(current.list.byId, props.notifyId)
});
const mapDispatch = ({
  notification: {
    deleteNotifications,
    markTodo,
    markAsRead,
  }
}: RootDispatch) => ({
  deleteNotifications, markTodo, markAsRead,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(NotifyCard);
