import React, { FC, useState, useEffect } from 'react';
import { SelectWithOption } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  status: FormStatus;
  analytiCalData: any[];
  analytiCalSolution: number;
}

interface IState {
  options: any[];
}

const GoodBin: FC<Props> = props => {
  const { analytiCalData = [], analytiCalSolution, value, onChange, status } = props;
  const [state, setState] = useState<IState>({
    options: []
  });
  const { options } = state;

  function firstUpperCase(str: string) {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  }

  useEffect(
    () => {
      if (analytiCalSolution >= 0) {
        const solution = analytiCalData?.[analytiCalSolution];
        const binsArray = solution?.data?.map(it => it?.bins);

        // 生成一个长度为1号片(默认所有片的bins长度一样)bins长度的对象
        let binAmountObj = Array.from(new Array(Object.keys(binsArray?.[0])?.length).keys()).reduce(
          (prev, cur) => {
            return {
              ...prev,
              [`bin${cur + 1}`]: 0
            };
          },
          {}
        );

        // 计算各bin的数量和
        binsArray.forEach(it => {
          Object.keys(binAmountObj).forEach(key => {
            binAmountObj = {
              ...binAmountObj,
              [key]: binAmountObj[key] + it?.[key]
            };
          });
        });

        // 先将Obj变成string[],
        // 过滤数量为0的bin,
        // 生成{ label, value }形式的数组
        const binOptions = Object.keys(binAmountObj).filter(key => binAmountObj[key]).map(key => ({
          label: firstUpperCase(key),
          value: key,
        }));

        setState({
          options: binOptions
        });
      }
    },
    [analytiCalData, analytiCalSolution]
  );

  useEffect(
    () => {
      // 这么写的原因是为了防止options里不存在该默认bin，但是却显示在选择框里的情况
      const defaultValue = options?.find(it => it.value === 'bin1')?.value;
      let newValue = value ? value : defaultValue ? [defaultValue] : undefined;

      onChange && onChange(newValue);
    },
    [options]
  );

  if (status === 'preview') {
    const valueTextArr = value?.map(it => it.charAt(0).toUpperCase() + it.slice(1));
    return (
      <span title={valueTextArr}>{valueTextArr && valueTextArr.join(',')}</span>
    );
  }

  return (
    <SelectWithOption
      {...props}
      mode="multiple"
      options={options}
    />
  );
};

export default GoodBin;
