import { get } from 'lodash';
import { compilePath } from '../HoneyRouter';
import { RouteMenuItem, RouteMenuChild, RouteLink } from './interface';

export function filterMenu(menuList: RouteMenuItem<any>[], ctx: any) {
  return menuList.filter(item => {
    return !item.renderCondition || item.renderCondition(ctx);
  });
}

/**
 * 解析每个Menu.item的配置
 * @param item
 * @param pathname
 * @param params
 */
function parseItem(item: RouteMenuChild<any>, pathname: string, params: any) {
  const path = compilePath({ name: item.routeName, params });

  return {
    path,
    title: item.title,
    defaultSelected: item.defaultSelected,
  };
}

/**
 * 解析Menu的配置
 * @param menu
 * @param pathname
 * @param params
 * @param context
 */
export function parseMenu(menu: RouteMenuItem<any>[], pathname: string, params: any, context: any): RouteLink[] {
  return filterMenu(menu, context).map(item => {
    if ('children' in item) {
      return {
        title: item.title,
        defaultUnfold: item.defaultUnfold,
        children: parseMenu(item.children, pathname, params, context),
      };
    }

    return parseItem(item as RouteMenuChild<any>, pathname, params);
  });
}

/**
 * 获取初始化时候根据menu的配置默认需要打开的subMenu
 * @param links
 * @param keys
 * @param parentKey
 */
export function getDefaultOpenKeys(links: RouteLink[], keys: string[] = []) {
  const openKeys: string[] = keys;

  links.forEach(link => {
    const defaultUnfold = get(link, 'defaultUnfold');

    if (defaultUnfold) {
      openKeys.push(link.title.toString());
    }

    if ('children' in link) {
      getDefaultOpenKeys(link.children, openKeys);
    }
  });

  return openKeys;
}

/**
 * 根据当前url获取需要打开的subMenu
 * @param links
 * @param pathname
 * @param keys
 * @param parentKey
 */
export function getOpenKeys(links: RouteLink[], pathname: string, keys: string[] = [], parentKey: string = '') {
  const openKeys: string[] = keys;

  links.forEach(link => {
    if ('children' in link) {
      getOpenKeys(link.children, pathname, openKeys, link.title);
    } else {
      if (link.path === pathname && parentKey && openKeys.indexOf(parentKey.toString()) === -1) {
        openKeys.push(parentKey.toString());
      }
    }
  });

  return openKeys;
}

/**
 * 遍历menu配置，将第一个设置了defaultSelected为true的节点作为默认选中得key，如果没找到，则不设置默认选中
 */
export function getDefaultSelectKey(links: RouteLink[]) {
  let path: string | undefined = undefined;

  const findDefaultKey = (linksToLoop: RouteLink[]) => {
    return linksToLoop.some(link => {
      if ('children' in link) return findDefaultKey(link.children);

      const isDefaultKey = Boolean(link.defaultSelected);

      if (isDefaultKey) {
        path = link.path;
      }

      return isDefaultKey;
    });
  };

  findDefaultKey(links);

  return path;
}

/**
 * 遍历所有的links数据，检查是否有匹配项
 * @param links
 * @param pathname
 */
export function getSelectedKey(links: RouteLink[], pathname: string) {
  let path: string = '';

  const findPath = function(linksToLoop: RouteLink[]) {
    return linksToLoop.some(link => {
      if ('children' in link) return findPath(link.children);

      if (link.path === pathname) {
        path = pathname;

        return true;
      }

      return false;
    });
  };

  findPath(links);

  return path;
}
