import React, { FC, useState } from 'react';
import { Radio, Modal } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

interface Props extends Pick<WrappedFormUtils, 'setFieldsValue'> {
  value?: boolean;
  onChange?: (val: any) => void;
  status: FormStatus;
  formData: any;
}
interface State {
  currentValue: boolean;
}

const SelectParseMap: FC<Props> = props => {
  const { value, onChange, status, setFieldsValue, formData } = props;

  const [state, setState] = useState<State>({
    currentValue: value !== undefined ? value : true,
  });
  const { currentValue } = state;

  const handleChange = (e) => {
    const newValue = e.target.value;

    const changeValue = () => {
      setState(prevState => ({
        ...prevState,
        currentValue: newValue
      }));

      setFieldsValue && setFieldsValue({
        mapping: undefined,
        analysisData: undefined,
        dieNumber: undefined,
        waferIdArray: undefined,
      });

      onChange && onChange(newValue);
    };
    // 有数据时提示一下，没数据直接切换
    if (formData?.dieNumber || formData?.waferIdArray) {
      Modal.confirm({
        title: '切换后将清空下面填写的物料信息，是否继续?',
        okText: '确定',
        cancelText: '取消',
        zIndex: FlexModalManager.getNextZIndex(),
        onOk() {
          changeValue();
        },
        onCancel() {
          //
        },
      });
    } else {
      changeValue();
    }
  };

  return (
    <Radio.Group
      value={currentValue}
      disabled={status === 'preview'}
      onChange={handleChange}
    >
      <Radio value={true}>是</Radio>
      <Radio value={false}>否</Radio>
    </Radio.Group>
  );
};

export default SelectParseMap;
