import axios, { AxiosError, AxiosResponse } from 'axios';
import { isPlainObject } from 'lodash';
import { message } from 'antd';

import { getErrorMessage } from '@chipcoo/http-error-message/lib/client';

import { isProd } from 'src/utils';
import urlConfig from 'src/config/url';

const defaultCodeMessage = {
  200: '服务器成功返回请求的数据',
  201: '新建或修改数据成功',
  202: '一个请求已经进入后台排队（异步任务）',
  204: '删除数据成功',
  400: {
    default: '发出的请求有错误，服务器没有进行新建或修改数据的操作',
  },
  401: {
    default: '抱歉，您未登录或登录态已失效',
  },
  402: '账号已被禁用',
  403: {
    default: '抱歉，您无权进行当前操作',
    'wrong password': '您输入的密码不正确',
  },
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  406: '请求的格式不可得',
  410: '请求的资源被永久删除，且不会再得到的',
  422: '当创建一个对象时，发生一个验证错误',
  500: '服务器发生错误，请重试',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
};

export function getDefaultMessage(response: AxiosResponse) {
  const { status, data } = response;
  let msg = defaultCodeMessage[status];

  if (isPlainObject(msg)) {
    msg = msg[data.message] || msg.default;
  }

  return msg || '';
}

export async function handleError(error: AxiosError): Promise<AxiosError> {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  const { config, response, request } = error;
  const { silent, handleSpecialError, customErrorMsg } = config;

  // 在 config 中添加 silent 参数时，interceptor会直接返回错误，不作任何处理。
  if (silent) {
    return Promise.reject(error);
  }

  let errMessage = '';
  let showErrorMessage = true;

  if (!response) {
    // TODO
    // No response
    // 请求发出的时候直接失败了，或者服务器没有响应，应在此处进行处理
    !isProd && console.log(request);
    errMessage = '请求发送失败';
  } else {
    const { status, statusText } = response;

    errMessage = getErrorMessage(response, customErrorMsg) || getDefaultMessage(response) || statusText;

    if (handleSpecialError && handleSpecialError[status]) {
      showErrorMessage = Boolean(await handleSpecialError[status](response, error));
    } else {
      // TODO: improve default error message

      switch (status) {
        case 400:
          showErrorMessage = true;
          errMessage = response?.data?.message;
          // TODO: 通常是表单验证错误，需要展示一些具体信息
          break;
        case 401:
          if (['127.0.0.1', 'localhost'].indexOf(location.hostname) === -1) {
            window.location.href = `${urlConfig.authUrl}/login?redirect=${window.location.href}`;
            showErrorMessage = false;
          }
          break;
        case 402:
          showErrorMessage = false;
          break;
        default:
          showErrorMessage = true;
          errMessage = response?.data?.message;
      }
    }
  }

  showErrorMessage && message.error(errMessage);
  return Promise.reject(error);
}
