import * as React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { FormCreator, CardTableV2 } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { addTableDataUniqueRowKey, noop } from '@chipcoo/fe-utils';

import './index.less';
import { getTableColumns } from './getTableColumns';
import { getFormConfig } from './formConfig';

interface Props {
  dataKey: string;
  label: string;
  status: any;
  value?: any;
  onChange?: (val: any) => void;
}

interface State {
  visible: boolean;
  record: Obj;
  dataSource: any[];
}

const { EnhancedTable } = CardTableV2;

class ContactTable extends React.PureComponent<Props, State> {

  private formController;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      dataSource: addTableDataUniqueRowKey(props.value) || [],
      record: {}
    };
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  closeModal = () => this.setState({ visible: false, record: {} });

  openModal = (record) => this.setState({ record, visible: true });

  delItem = (key: string) => {
    const { onChange } = this.props;
    const { dataSource } = this.state;
    const data = dataSource.filter(it => it.key !== key);

    this.setState({ dataSource: data });

    onChange && onChange(data);
  }

  handleSubmit = () => {
    return new Promise((resolve) => {
      this.formController?.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const { onChange } = this.props;
          const { dataSource } = this.state;
          const { key } = values;
          let data = _.cloneDeep(dataSource);
          const index = data.findIndex(it => it?.key === key);

          if (index >= 0) {
            data[index] = values;
          } else {
            data.push(values);
          }

          this.setState({
            dataSource: addTableDataUniqueRowKey(data) || []
          });

          this.closeModal();

          onChange && onChange(data);
        }
      });
    });
  }

  render () {
    const { status, dataKey, label } = this.props;
    const { visible, dataSource, record } = this.state;

    return (
      <div className="contact-table-item">
        <EnhancedTable
          getTableData={
            () => {
              //
            }
          }
          dataSource={dataSource}
          showPagination={false}
          columns={getTableColumns({
            status,
            dataKey,
            label,
            openModal: this.openModal,
            delItem: this.delItem
          })}
        />

        {
          status === 'edit' && (
            <>
              <Button
                block={true}
                icon="plus"
                style={{ marginTop: 16 }}
                onClick={() => this.setState({ visible: true })}
              >
                {`添加${label}联系人`}
              </Button>

              <FlexModal
                title={`${_.isEmpty(record) ? '新建' : '编辑'}${label}联系人`}
                width={500}
                visible={visible}
                onCancel={this.closeModal}
                onOk={this.handleSubmit}
              >
                <FormCreator
                  formConfig={getFormConfig(dataKey, label)}
                  formData={_.isEmpty(record) ? undefined : record}
                  getFormController={controller => this.formController = controller}
                />
              </FlexModal>
            </>
          )
        }
      </div>
    );
  }
}

export default ContactTable;
