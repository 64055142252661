/**
 * 根据类型显示不同的产品名称
 */
import React, { FC } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { EnumProductModel } from '@chipcoo/constant';
import { SelectWithDynamicOptionConfig } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

type IProps = {
  value?: any;
  onChange?: (value: any) => void;
  productModel: EnumProductModel | 'ALL';
  status: FormStatus;
  organization: string;
  paths: string[];
} & Pick<WrappedFormUtils, 'resetFields'>;

const SelectProductByModel: FC<IProps> = props => {
  const { productModel, organization, status } = props;

  const getSelectProps = () => {
    return {
      ...props,
      status,
      optionKey: 'getProductByProductModel',
      menuMaxHeight: 200,
      placeholder: '请选择（可筛选）',
      addNew: null,
      extraFetchParams: { 
        organization,
        productModel: productModel === 'ALL' ? undefined : productModel
      }
    };
  };

  const resultSelect = () => {
    const newProps = getSelectProps();

    return <SelectWithDynamicOptionConfig {...newProps} />;
  };

  return resultSelect();

};

export default SelectProductByModel;
