import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import './style.less';

import { HocProfileAvatar } from 'src/hoc';

import { EnhanceAvatar } from '../EnhanceAntd';

interface Props {
  userInfo: User;
  className?: string;
  messageTime?: string | React.ReactNode | JSX.Element;
  userDisabled?: boolean | (() => boolean);
}
interface State {}

class UserMessage extends PureComponent<Props, State> {
  render() {
    const { className, children, userInfo, messageTime, userDisabled } = this.props;
    const { avatar, avatarSrc } = userInfo;
    const userName = getUserNameEnhance(userInfo);

    return (
      <div className={classnames(className, 'user-message flex align-start')} >
        <EnhanceAvatar
          src={avatarSrc || avatar}
          userName={userName}
          className="user-avatar"
          userDisabled={userDisabled}
        />
        <div className="content-wrap cell">
          <div className="content-head">
            <span className="user-name">{userName}</span>
            {messageTime && <span className="time">{messageTime}</span>}
          </div>
          <div className="message-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export const HocUserMessage = HocProfileAvatar(UserMessage);

export default HocUserMessage;
