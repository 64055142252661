import React from 'react';
import { Divider } from 'antd';

const name = (label) => ({
  title: `${label}联系人`,
  dataIndex: 'name',
  key: 'name'
});

const phone = (label) => ({
  title: '联系电话',
  dataIndex: 'phone',
  key: 'phone'
});

const email = (label, dataKey) => ({
  title: `${dataKey === 'STOCK_OUT' ? '出货清单' : label}邮箱地址`,
  dataIndex: 'email',
  key: 'email'
});

const address = {
  title: '联系地址',
  dataIndex: 'address',
  key: 'address'
};

const action = (openModal, delItem) => ({
  title: '操作',
  dataIndex: 'action',
  key: 'action',
  render: (text, record) => {
    const open = (e) => openModal(record);
    const del = (e) => delItem(record?.key);

    return (
      <>
        <a onClick={open}>编辑</a>
        <Divider type="vertical" />
        <a onClick={del}>删除</a>

        {/*<Popconfirm*/}
        {/*  title="是否要删除此行？"*/}
        {/*  okText="确定"*/}
        {/*  cancelText="取消"*/}
        {/*  onConfirm={del}*/}
        {/*  getPopupContainer={triggerNode => triggerNode.parentNode! as HTMLElement}*/}
        {/*>*/}
        {/*  <a>删除</a>*/}
        {/*</Popconfirm>*/}
      </>
    );
  }
});

type IParams = {
  status: string;
  dataKey: string;
  label: string;
  openModal: (record: Obj) => void;
  delItem: (key: string) => void;
};

export const getTableColumns = (params: IParams) => {
  const { status, dataKey, label, openModal, delItem } = params;
  let columns;

  switch (dataKey) {
    case 'ORGANIZATION':
      columns = [
        name(label),
        phone(label),
        email(label, dataKey),
        address,
      ];

      break;
    case 'TAPE_OUT':
      columns = [
        name(label),
        phone(label),
        email(label, dataKey),
      ];

      break;
    case 'ORDER':
      columns = [
        name(label),
        phone(label),
        email(label, dataKey),
      ];

      break;
    case 'STOCK_OUT':
      columns = [
        name(label),
        phone(label),
        email(label, dataKey),
      ];

      break;
    case 'FINANCE':
      columns = [
        name(label),
        phone(label),
        email(label, dataKey),
      ];

      break;
    default:
      columns = [];
      break;
  }

  if (status === 'edit') {
    columns.push(action(openModal, delItem));
  }

  return columns;
};
