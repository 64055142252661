import React, { FC } from 'react';
import _ from 'lodash';
import { Row, Divider } from 'antd';
import { EnumProductModel } from '@chipcoo/constant';
import { unSortWaferId, sortWaferId } from '@chipcoo/fe-utils';

import CustomerFormItem from './CustomerFormItem';

interface IProps {
  productModel: EnumProductModel;
  materialInfo: any[];
}

const MaterialInfos: FC<IProps> = props => {
  const { materialInfo, productModel } = props;

  const handleMaterial = (material: any[]): any[] => {
    const isSpwDie: boolean = productModel === EnumProductModel.spwDie;
    const isDpProduct: boolean = productModel === EnumProductModel.dpProduct;
    const isAssemblyProduct: boolean = productModel === EnumProductModel.assemblyProduct;

    if (isSpwDie || isDpProduct) {
      return material?.map(item => {
        let waferIdArray;
        const { slices, dieNumber, dpDieSlices} = item;
        const good = isSpwDie ? _.sumBy(slices, 'good') : _.sumBy(dpDieSlices, 'good');
        const bad = isSpwDie ? (_.sumBy(slices, 'bad') || 0) : _.sumBy(dpDieSlices, 'bad');

        const dpDieWaferIds = _.flattenDeep(dpDieSlices?.map(it => unSortWaferId(it.waferId)));
        const waferAmount = isSpwDie ? _.get(slices, 'length') : _.get(dpDieWaferIds, 'length');
        const amount = good + bad;
        waferIdArray = isSpwDie ? item.waferIdArray : 
          sortWaferId(_.flattenDeep(dpDieSlices?.map((it => unSortWaferId(it.waferId)))));
        return [
          {
            label: '芯片批号',
            value: dieNumber
          },
          {
            label: '片号',
            value: waferIdArray && '#' + waferIdArray,
          },
          {
            label: '良品',
            value: good
          },
          {
            label: '不良品',
            value: bad,
          },
          {
            label: '总数量',
            value: amount
          },
          {
            label: '片数',
            value: waferAmount,
          }
        ];
      });
    }
    if (isAssemblyProduct) {
      return material?.map(item => {
        const { bins, stampNumber} = item;
        const amount = _.sumBy(bins, 'count');
        const binsType = bins?.map(it => it.name).join(',');
        return [
          {
            label: '印章批号',
            value: stampNumber
          },
          {
            label: 'Bin类型',
            value: binsType,
          },
          {
            label: '总数量',
            value: amount
          },
        ];
      });
    }
    return [];
  };

  const materialArray = handleMaterial(materialInfo);

  return (
    <Row style={{ margin: '0 -12px'}}>
      {materialArray?.map((item, index) => {

        return (
          <div key={Math.random().toString(16)}>
            {item.map(it => (
              <CustomerFormItem key={Math.random().toString(16)} label={it.label} value={it.value} />
            ))}
            {index !== materialArray.length - 1 && <Divider type="horizontal" />}
          </div>
        );
      })}
    </Row>
  );
};

export default MaterialInfos;
