/**
 * Created by marvin on 18/8/7.
 */
import { createModel } from '@rematch/core';
import { omit } from 'lodash';
import { profileApi } from 'src/services/net';
import produce from 'immer';
import { enhanceUserAvatar } from 'src/utils/user';

export type PasswordParams = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};
export type UploadAvatar = {
  url: string;
  param: Object,
  profile: User,
};
export type AccountParams = {
  account: string;
  code: string;
};
export interface State {
  profile: User | undefined;
  orgId: string | undefined;
  wechat: any;
}

const {
  getProfile,
  patchBasicInfo,
  patchPassword,
  sendVerifyCode,
  patchAccount,
  // getOrgInfo,
  getSocial,
  getAvatar
} = profileApi;
export const initialState: State = {
  profile: void 0,
  orgId: void 0,
  wechat: void 0,
};

export default createModel({
  state: initialState,
  reducers: {
    subscribe(state: State) {
      return produce(state, draft => {
        draft.wechat = { a: 1 };
      });
    },
    unsubscribe(state: State) {
      return produce(state, draft => {
        draft.wechat = void 0;
      });
    }
  },
  effects: (dispatch) => ({
    async getProfile () {
      const { data } = await getProfile();
      this.updateState({
        path: 'profile',
        data: enhanceUserAvatar(data),
      });

      return data;
    },
    async patchPassword (val: PasswordParams) {
      const { oldPassword, newPassword } = val;
      await patchPassword({oldPassword, newPassword});
    },
    /**
     * 修改个人资料
     * @param {User & {type?: string}} profile
     * @returns {Promise<void>}
     *
     * 1. 不断更换头像
     * 2. 刚修改头像后，修改个人信息
     * 3. getProfile后profile头像显示
     * 4. getProfile后修改个人信息
     */
    async putBasicInfo(profile: User & { type: string }) {
      if (profile.type === 'avatar') {
        // 修改头像
        const { avatar } = profile;
        await patchBasicInfo({ avatar });
        const avatarResp = await getAvatar(parseInt(profile._id, 10), { params: { noRedirect: true } });
        this.updateState({ path: 'profile', data: { ...profile, avatar: avatarResp.data.url } });
      } else {
        const data = omit(profile, ['avatar']);

        await patchBasicInfo({ ...data });

        this.updateState({
          path: 'profile',
          data: { ...profile, avatar: profile.avatar }
        });
      }
    },
    // 获取修改账号的验证码
    async postVerifyCode(geetResult: GeetestValidate & { account: string; }) {
      await sendVerifyCode(geetResult);
    },
    // 修改手机号或邮箱
    async patchAccount(val: AccountParams) {
      const reg = /(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/;
      const bool = reg.test(val.account);

      await patchAccount(val);

      this.updateState({path: bool ? 'profile.email' : 'profile.phone', data: val.account});
    },
    async getWechat () {
      const { data } = await getSocial();
      this.updateState({path: 'wechat', data: data.wechat});
      // this.updateState({path: 'wechat', data: {a: 1}});
    },
    async setSubscribe() {
      await this.getWechat();
    }
  })
});
