/**
 * 获取产品里的制程名称
 */
import * as React from 'react';
import _ from 'lodash';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectWithOption } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { productApi } from 'src/services/net';

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  path: string[];
  productName: string;
  status: FormStatus;
} & Pick<WrappedFormUtils, 'resetFields' | 'setFieldsValue'>;

type State = {
  options: any;
  materials: any[];
};

class ProcessNameByProduct extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = { options: [], materials: [] };
  }

  async componentDidMount() {
    this.getOptionData();
  }

  async componentDidUpdate(prevProps: Props) {
    const { productName, path, resetFields } = this.props;

    if (
      productName && prevProps.productName !== productName
    ) {
      resetFields && resetFields(path);

      this.getOptionData();
    }
  }

  getOptionData = async () => {
    const { productName } = this.props;

    if (productName) {
      const { data } = await productApi.searchProcessByProduct(productName);

      this.setState({
        materials: data,
        options: data.map(item => ({ key: item._id, label: item.process?.name }))
      });
    }
  }

  handleChange = (value: any) => {
    const { onChange, setFieldsValue } = this.props;
    const { materials } = this.state;

    const classes = materials.find(it => it._id === value?.key)?.process.classes;
    setFieldsValue && setFieldsValue({ classes, detailMaterial: undefined });

    onChange && onChange(value);
  }

  render () {
    const { options } = this.state;
    const { value, status, ...passThoughProps } = this.props;

    if (status === 'preview') return <span title={_.get(value, 'label')}>{_.get(value, 'label')}</span>;

    return (
      <SelectWithOption
        value={value}
        labelInValue={true}
        options={options}
        {...passThoughProps}
        onChange={this.handleChange}
      />
    );
  }
}

export default ProcessNameByProduct;
