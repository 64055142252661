import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'src/utils/detect';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'moment/locale/zh-cn';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { unregister } from './registerServiceWorker';
import urlConfig from './config/url';
import setBrowser from './set-browser';
// export const fundebug = require('fundebug-javascript');
// fundebug.apikey = 'a34060c98babcb35d198ad659dbc20079d411d1ba386b6101fa73237c192314a';

// (window as any).fundebug = fundebug;
import './config/logLevel';
import './styles/global.less';
import './styles/iconfont.less';
import 'github-markdown-css';

/**
 * 这下面这两个import是form-components组件种没有引入对应的antd样式，而chipcoo整个项目中也没有import对应的组件
 * 导致的样式缺失，临时这么引入一下，后期考虑引入全部的antd的样式
 */
import 'antd/dist/antd.min.css';
// import 'antd/lib/date-picker/style/css';
// import 'antd/lib/form/style/css';
// import 'antd/lib/collapse/style/css';

import '@chipcoo/hanayo/dist/style/index.css';

import store from 'src/store';
// 注入APM
// import './apm';

import Layout from './layouts';

// import registerServiceWorker from './registerServiceWorker';

const rootEl = document.getElementById('root') as HTMLElement;
const getRenderApp = (Component: React.ComponentClass) => (
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <Router basename={urlConfig.baseUrl}>
        <Component />
      </Router>
    </ConfigProvider>
  </Provider>
);

ReactDOM.render(getRenderApp(Layout as any), rootEl);

if (module.hot) {
  const AppContainer = require('react-hot-loader').AppContainer;
  module.hot.accept('./layouts', () => {
    const NextLayout = require('./layouts').default;

    ReactDOM.render(
      (
        <AppContainer>
          {getRenderApp(NextLayout)}
        </AppContainer>
      ),
      rootEl
    );
  });
}

setBrowser();
// registerServiceWorker();
unregister();
