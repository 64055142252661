/**
 * 一条消息被删除以后显示的内容
 */
import * as React from 'react';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import styles from './deletedMessage.module.less';
import { getMessageText } from 'src/pages/IM/util';

import { ConnectUserInfo } from 'src/pages/IM/containers';

interface P {
  userId: UserId;
  userInfo?: User;
}
interface S {}

class DeletedMessage extends React.PureComponent<P, S> {
  render() {
    const { userInfo } = this.props;
    const userName = getUserNameEnhance(userInfo!);
    const deletedText = getMessageText(userName, 'DELETED');

    return (
      <div className={styles.deletedMessageWrapper}>
        <span className={styles.text}>
          {deletedText}
        </span>
      </div>
    );
  }
}

export default ConnectUserInfo(DeletedMessage);
