/**
 * 这个组件的使用方式如下：
 * 这个文件会暴露出来一个方法openNewEventBusGlobalModal，调用的时候可以将对应的modal的配置写入并新建一个弹框，看起来非常的不react，但主要是为了解决如下问题：
 *
 * 表单中有这么一个要求：当我选择某个下拉项的时候，会去检查所选择的这一项所对应的表单项是否填写完整，如果不完整，那么会打开一个弹框将这个表单放入让你完善
 *
 * 这里有一个问题，那就是点击后打开弹框的操作，是写在表单配置项中的，你没地方去挂载这个modal的component,
 * 所以这个时候是没有地方去setState({ visible: true })打开一个弹框，包括react的createPortal也有这个问题，那么只能手动创建一个div节点，
 * 再调用ReactDOM.render将一个打开的弹框挂载上去，最后在组件卸载的时候再手动将对应的弹框给全部卸载掉，
 * 如果只是一个无数据交互的弹框，这么做显然并没有什么问题，但是，假如这里需要链接store去获取数据，那么由于这个节点是你重新render的，
 * 并没有挂载到react-redux提供的Provider下面，你根本就获取不到context上下文的数据。。。
 *
 * 这就是这个组件诞生的意义。。。为了能够在表单配置里面打开一个弹框并且拿到对应的store里面的数据
 */
import React, { PureComponent } from 'react';
import { uniqueId, isEmpty, findIndex } from 'lodash';
import { BasicModal } from '@chipcoo/hanayo';
import { BasicModalProps } from '@chipcoo/hanayo/lib/BasicModal';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

import { NEW_GLOBAL_MODAL as eventName, Emitter } from 'src/config/eventBusNameConstant';

type EventBusModalOptions = PartialSelector<BasicModalProps, 'visible'> & {
  // 关闭弹框后是否清除掉对应的弹框的option数据，默认true
  clear?: boolean;
  modalId?: string
};

interface P {}
interface S {
  modalOptions: RequireSelector<EventBusModalOptions, 'modalId'>[];
}

// 打开一个新的弹框
export const openNewGlobalModal = (options: EventBusModalOptions) => {
  Emitter.emit(eventName, options);
};

class EventBusGlobalModal extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      modalOptions: []
    };
  }

  componentDidMount(): void {
    // 开始监听是否有新建全局弹框的事件
    Emitter.on(eventName, this.addNewModal);
  }

  componentWillUnmount(): void {
    Emitter.removeListener(eventName, this.addNewModal);
  }

  handleCloseModal = (modalId: string) => {
    const modalOptions = this.state.modalOptions.slice();
    const index = findIndex(modalOptions, ['modalId', modalId]);

    if (!~index) return;

    modalOptions[index].visible = false;

    this.setState({ modalOptions: modalOptions });
  }

  handleAfterClose = (modalId: string) => {
    // 弹框被整体关闭以后，如果clear为true，那么需要清除对应的弹框option数据
    const modalOptions = this.state.modalOptions.slice();
    const index = findIndex(modalOptions, ['modalId', modalId]);

    if (!~index) return;

    const option = modalOptions[index];
    const { clear } = option;

    if (clear) {
      modalOptions.splice(index, 1);

      this.setState({ modalOptions });
    }
  }

  addNewModal = (options: BasicModalProps) => {
    const modalId = uniqueId();
    const { afterClose, ...restOptions } = options;

    const newOptions = {
      modalId,
      clear: true,
      closeModal: () => this.handleCloseModal(modalId),
      afterClose: () => {
        afterClose && afterClose();
        this.handleAfterClose(modalId);
      },
      ...restOptions,
      visible: true,
    };

    const modalOptions = this.state.modalOptions.slice();

    modalOptions.push(newOptions);

    this.setState({ modalOptions });
  }

  render() {
    const { modalOptions } = this.state;

    if (isEmpty(modalOptions)) return null;

    return modalOptions.map(item => {
      const { modalId, clear, ...rest } = item as any;

      return <BasicModal {...rest} key={modalId} zIndex={FlexModalManager.getNextZIndex()} />;
    });
  }
}

export default EventBusGlobalModal;
