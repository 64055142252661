/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { Form, Input, Button, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { forOwn } from 'lodash';

import styles from './index.module.less';

interface Props extends FormComponentProps {
  profile: User;
  onSubmit?: (val: User) => void;
}
interface State {
  isEditing: boolean;
}
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 9 },
};
const submitFormLayout = {wrapperCol: { sm: { span: 10, offset: 3 } }};
const basicInfos = {
  nickname: { label: '昵称', autoFocus: true, isRequired: true, message: '请输入昵称', placeholder: '请输入昵称',
    pattern: /^[A-Za-z0-9_\-\u4e00-\u9fa5]{2,20}$/, patternMessage: '只可包含字母、数字、汉字、“-”、“_”，2~20字符！' },
  name: { label: '姓名', placeholder: '请输入姓名', pattern: /^[A-Za-z0-9_\-\u4e00-\u9fa5]{2,20}$/,
    patternMessage: '只可包含字母、数字、汉字、“-”、“_”，2~20字符！' },
  job: { label: '职位', placeholder: '请输入职位' },
  department: { label: '部门', placeholder: '请输入部门' },
  gender: { label: '性别', type: 'radio' },
  bio: { label: '个人签名', placeholder: '请输入个人签名', max: 30, extraMessage: '个人签名最长30' },
};
const RadioGroup = Radio.Group;
class BasicInfo extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  // 取消编辑
  cancelBasicInfo = async () => {
    await this.props.form.resetFields();
    this.setState({ isEditing: false });
  }

  // 编辑保存
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { onSubmit } = this.props;
        let value: any = {};

        forOwn(values, (val, key) => {
          value[key] = val && val.trim();
        });

        await (onSubmit && onSubmit(value));
        this.setState({ isEditing: false });
      }
    });
  }

  render () {
    const { form, profile } = this.props;
    const { isEditing } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form className={styles.info} onSubmit={this.handleSubmit}>
        {
          Object.keys(basicInfos).map((key, index) => {
            const { label, isRequired, message, max, extraMessage, autoFocus, type,
              placeholder, pattern, patternMessage } = basicInfos[key];

            return (
              isEditing ? (
                <FormItem key={key} label={label} {...formItemLayout}>
                  {getFieldDecorator(key as string, {
                    initialValue: profile[key],
                    rules: [
                      { required: isRequired, message },
                      { max, message: extraMessage },
                      { pattern, message: patternMessage }
                    ]
                  })(
                    type === 'radio'
                      ? (
                        <RadioGroup>
                          <Radio value="男">男</Radio>
                          <Radio value="女">女</Radio>
                        </RadioGroup>
                      )
                      : (
                        <Input
                          autoComplete="off"
                          autoFocus={autoFocus}
                          placeholder={placeholder}
                        />
                      )
                  )}
              </FormItem>
              ) : (
                profile[key] && <FormItem key={index} label={label} {...formItemLayout}>
                  {getFieldDecorator(key as string)(
                    <span>{profile[key]}</span>
                  )}
                </FormItem>
              )
            );
          })
        }
        {
          isEditing ? (
            <FormItem {...submitFormLayout}>
              <Button type="primary" htmlType="submit">确定</Button>
              <Button style={{marginLeft: 8}} onClick={this.cancelBasicInfo}>
                取消
              </Button>
            </FormItem>
          ) : <FormItem {...submitFormLayout}>
            <a onClick={() => this.setState({ isEditing: true })}>修改个人资料</a>
          </FormItem>
        }
      </Form>
    );
  }
}

export default (Form as any).create()(BasicInfo) as React.ComponentType<Omit<Props, 'form'>>;
