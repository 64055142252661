/**
 * @注意：chatPanel中所有的的messageData中的sender字段都给转换成了string，获取的房间信息中的User._id也全部换成了string，
 * 这么做主要是为了保证所有的id都是string，方便进行统一操作
 */
/* tslint:disable:max-file-line-count */
import { createModel, ModelConfig } from '@rematch/core';
import { produce } from 'immer';
import { uniq, get } from 'lodash';
import { initialNormalizedData, getNormalizedData } from 'src/utils';
import { updateChain } from 'immutability-helper-x';
import { InitialState as ChatRoomListInitialState } from './chatRoomList.type';
import { changeUrlAfterConfirm } from 'src/components';
import {
  updateRoomLastMessage,
  messageSenderNum2Str
} from './chatPanel.tool';
import {
  shouldResortRoomList,
} from './chatRoomList.tool';
import {
  InitialState,
  PostMessageParams,
  ImMessage,
  GetMessageHistoryParams,
  DeleteUserFromRoomParams,
  PutNewMemberToRoomParams,
} from './chatPanel.type';

import { RootState } from 'src/store';
import { LOCAL_PREFIX } from 'src/config/const';
import { isEqualUserId } from 'src/utils/user';
import { imApi } from 'src/services/net';
import { AxiosRequestConfig } from 'axios';

const initialState: InitialState = {
  chatData: { ...initialNormalizedData(), isInitial: false, hasMore: true },
  roomUsers: { allIds: [], existUsers: [] },
  roomInfo: void 0
};

function createLocalMessage(localMsg: PostMessageParams & { sender: string }): ImMessage {
  const { sender, roomId } = localMsg;
  const uniqId = `${LOCAL_PREFIX}${Math.random().toString(16).substr(2, 6)}`;
  const localTime = new Date().toISOString();

  const messageData: ImMessage = {
    createdAt: localTime,
    updatedAt: localTime,
    generatedAt: localTime,
    type: 'MESSAGE',
    _id: uniqId,
    uniqId,
    attachments: [],
    message: '',
    roomId,
    sender,
  };
  
  if (localMsg.content) {
    messageData.message = localMsg.content;
  }

  if (localMsg.attachments) {
    let [ attachment ] = localMsg.attachments;
    attachment = Object.assign({}, attachment);
    const { reference } = attachment;
    Reflect.deleteProperty(attachment, 'reference');
    const extra = { userId: sender, _id: uniqId, createdAt: localTime };
    messageData.attachments = [
      {
        ...extra,
        versions: [ Object.assign(attachment, extra) ],
        reference: reference!,
        updatedAt: localTime,
      }
    ];
  }

  return messageData;
}

export default createModel<InitialState, ModelConfig<InitialState, InitialState>>({
  state: initialState,
  reducers: {
    // 增加新的消息
    addNewMessage(state: InitialState, messageData: ImMessage) {
      const { allIds } = state.chatData;
      const { _id, uniqId } = messageData;

      if (!allIds.includes(_id) && !allIds.includes(uniqId || '')) {
        return updateChain(state)
          .$push('chatData.allIds', [_id])
          .$merge('chatData.byId', { [_id]: messageData })
          .value();
      }

      return state;
    },

    // 修改原有消息
    modifyMessage(state: InitialState, messageData: ImMessage) {
      const { _id, uniqId } = messageData;
      let replaceFlag = uniqId && uniqId.includes(LOCAL_PREFIX);
      if (!(state.chatData.byId[_id] || (replaceFlag && state.chatData.byId[uniqId!]))) return state;
      
      return produce(state, (draft) => {
        draft.chatData.byId[_id] = messageData;

        if (replaceFlag) {
          let { allIds } = draft.chatData;
          
          const index = allIds.findIndex(id => id === uniqId);
          index !== -1 && (allIds[index] = _id);
          messageData.uniqId = _id;

          // 去重
          draft.chatData.allIds = allIds.filter((id, i) => allIds.indexOf(id) === i);

          if (draft.chatData.byId[uniqId!]) {
            Reflect.deleteProperty(draft.chatData.byId, uniqId!);
          }
          
          draft.chatData.byId[_id] = messageData;
        }
      });
    },
  },
  effects: (dispatch) => ({
    // 提交聊天消息
    async postMessage(params: PostMessageParams, rootState: RootState) {
      const { session: { profile }, user: { list: users } } = rootState;
      const sender = profile!._id + '';
      const localMessage = createLocalMessage(Object.assign({ sender }, params));
      
      params.uniqId = localMessage.uniqId;

      // Add to timeline first.
      this.addNewMessage(localMessage);

      const { updateLastMessageInRoom } = dispatch.chatRoomList;

      updateRoomLastMessage(users, localMessage, updateLastMessageInRoom);

      // 检查当前房间是否是置顶，或者已经是最新的一条，如果都不是，那么需要重新排序整个roomList
      const roomListState = rootState.chatRoomList as ChatRoomListInitialState;
      const { roomId } = params;
      const { roomList } = roomListState;

      if (shouldResortRoomList(roomList, roomId)) {
        dispatch.chatRoomList.sortRoomList({ type: 'POST_NEW', roomId });
      }

      const { data } = await imApi.postMessage(params);

      const messageData = messageSenderNum2Str(data);

      this.modifyMessage(Object.assign({}, localMessage, messageData));

      return true;
    },

    // 获取当前房间的总览信息
    async getRoomInfo(payload: { roomId: string, config?: AxiosRequestConfig }) {
      const { roomId, config } = payload;
      const { data } = await imApi.getRoomInfo(roomId, config);
      const { users, ...roomInfo } = data;
      const { ownerId } = roomInfo;
      // users中的id都由number改为string
      const formattedUsers = users.map(item => ({ ...item, _id: `${item._id}` }));
      dispatch.user.setUser(formattedUsers);
      // 检查user，如果status为leaving，表明这个用户被删除了，需要排除，然后把owner放到第一位
      const existUsers = uniq([
        ownerId,
        ...users.reduce((ids, user) => {
          if (user.status !== 'leaving') {
            ids.push(user._id);
          }

          return ids;
        }, [] as number[]),
      ]);
      const allIds = formattedUsers.map(user => user._id);

      this.updateState([
        { path: 'roomInfo', data: roomInfo },
        { path: 'roomUsers', data: { allIds, existUsers } }
      ]);
    },

    // 获取历史聊天记录
    async getMessageHistory(params: GetMessageHistoryParams, rootState: any) {
      const { lastId, roomId } = params;
      const updateStateOperation: UpdateStateOperation[] = [];
      const { data } = await imApi.getMessageHistory(params);

      if (!lastId) {
        updateStateOperation.push({ path: 'chatData.isInitial', data: true });
      }

      if (!data.length || data.length < 20) {
        updateStateOperation.push({ path: 'chatData.hasMore', data: false });
      }

      if (!data.length) {
        this.updateState(updateStateOperation);
        return;
      }

      const handleData = data.map(messageSenderNum2Str);
      const { byId, allIds } = getNormalizedData(handleData.reverse() || handleData);

      // 检查房间上是否有挂着未读，如果有，清空
      const chatRoomListState = rootState.chatRoomList as ChatRoomListInitialState;
      const { byId: roomListById } = chatRoomListState.roomList;
      const roomUnreadCount = roomListById[roomId] ? roomListById[roomId].unread : 0;

      if (roomUnreadCount) {
        dispatch.chatRoomList.updateState({ path: `roomList.byId.${roomId}.unread`, data: 0 });
        dispatch.chatMisc.changeUnreadCount(-roomUnreadCount);
      }

      updateStateOperation.push(
        { method: '$unshift', path: 'chatData.allIds', data: allIds },
        { method: '$merge', path: 'chatData.byId', data: { ...byId } }
      );

      this.updateState(updateStateOperation);
    },

    // 删除某条聊天记录
    async deleteMessage(messageId: string, rootState: RootState) {
      const { data: message } = await imApi.deleteMessage(messageId);
      
      this.syncRoomMessage({
        resource: { id: rootState.chatPanel.roomInfo!._id },
        payload: { message }
      });
    },

    // 离开房间
    async leaveRoom(roomId: string) {
      await imApi.deleteRoomByLeave(roomId);

      dispatch.chatRoomList.removeNormalizedData({
        removeIds: [roomId],
        path: 'roomList'
      });

      return true;
    },

    // 从房间里删除某个用户
    async deleteUserFromRoom(params: DeleteUserFromRoomParams) {
      await imApi.deleteUserFromRoom(params);
      const users = [params.userId];

      this.someoneRemoved({
        data: {
          payload: {
            users,
            roomId: params.roomId,
          },
        },
        history: null, // 自己无法删除自己，所以此处并不需要 history 来更改 URL.
      });
    },

    async addNewUserToRoom(params: PutNewMemberToRoomParams) {
      //
      const result = await imApi.putNewMemberToRoom(params);

      this.syncAddNewMemberToRoom({
        payload: {
          ...result.data,
          roomId: params.roomId,
        }
      });

      return result;
    },

    /**
     * 下面都是socket推送
     */
    // 有人离开房间后的推送，注意：当一个人主动离开房间，包括群主解散群，这个人不会收到这条消息
    someoneRemoved(data: { data: ISyncSocketData, history: any }, rootState: RootState) {
      const chatRoomListState = rootState.chatRoomList;
      const state = rootState.chatPanel;
      const { existUsers } = state.roomUsers;
      const { _id: userId } = rootState.session.profile!;
      const { currentActiveRoom } = chatRoomListState;
      const { data: { payload }, history } = data;
      const { roomId, users } = payload;
      const hasCurrentUser = users.some(id => isEqualUserId(id, userId));
      const newExistUsers = existUsers.filter(id => !users.some(uId => isEqualUserId(uId, id)));
      const removeChatRoom = () => {
        dispatch.chatRoomList.removeNormalizedData({
          removeIds: [roomId],
          path: 'roomList'
        });
      };

      if (currentActiveRoom === roomId) {
        // 打开当前窗口的本人被T掉了的处理
        if (hasCurrentUser) {
          changeUrlAfterConfirm('您已经被群主从该群移出', () => {
            history && history.push('/im');
            removeChatRoom();
          });
        // 当前窗口非本人被T，直接更新下群组管理面板的成员就ok
        } else {
          this.updateState({ path: 'roomUsers.existUsers', data: newExistUsers });
        }
      } else {
        // 非当前窗口，且是本人，那么直接移除对应的房间
        if (hasCurrentUser) {
          removeChatRoom();
        }
      }
    },

    // 房间里面添加成员后的推送
    syncAddNewMemberToRoom(data: ISyncSocketData, rootState: any) {
      const { roomId, users } = data.payload;
      const chatRoomListState = rootState.chatRoomList as ChatRoomListInitialState;
      const { currentActiveRoom } = chatRoomListState;

      const allIds = users.map(item => `${item._id}`);

      if (currentActiveRoom === roomId) {
        this.updateState([
          { method: '$push', path: 'roomUsers.allIds', data: allIds },
          { method: '$push', path: 'roomUsers.existUsers', data: allIds }
        ]);
      }
    },
    syncRoomMessage(data: ISyncSocketData, rootState: RootState) {
      //
      const roomId = get(data.resource, 'id');
      const { currentActiveRoom, roomList: { byId: roomsById } } = rootState.chatRoomList;
      const { message } = data.payload;
      const { _id: messageId } = message;
      // 删除成功后更新数据
      const state = rootState.chatPanel;
      const { chatData } = state;
      const { byId } = chatData;

      const newMessage = {
        ...byId[messageId],
        ...message,
        // http 响应的 sender 是 number，socket响应的 sender 是 User.
        // sender 需要用字符串形式存储
        // TODO: sender 存储方式统一。
        sender: (get(message, 'sender._id') || message.sender || '').toString()
      };

      // 当前房间内的更改
      if (roomId === currentActiveRoom) {
        this.updateState({ path: `chatData.byId.${messageId}`, data: newMessage });
      }

      // 更新房间的lastMessage
      // if (type === 'DELETED' && messageId === get(roomsById[roomId], 'lastMessage._id')) {
      if (messageId === get(roomsById[roomId], 'lastMessage._id')) {
        dispatch.chatRoomList.updateLastMessageInRoom(newMessage);
      }
    }
  })
});
