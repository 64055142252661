import { message } from 'antd';
import { get } from 'lodash';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

type ARC = AxiosRequestConfig;

export default function(api: AxiosInstance) {
  return {
    // 搜索订单的表格
    searchOrder(params: object, config?: ARC) {
      return api.get('ops/order/search', { ...config, params });
    },

    // 创建订单
    createOrder(data: any, config?: ARC) {
      return api.post('ops/order', data, { ...config });
    },

    // 获取订单详情
    getOrderDetail(orderId: string, config?: ARC) {
      return api.get(`ops/order/${orderId}`, { ...config });
    },

    // 更新订单
    updateOrder(data: { orderId: string } & Obj, config?: ARC) {
      const { orderId } = data;

      return api.patch(`ops/order/${orderId}`, data, {...config});
    },

    // 更新订单状态
    updateOrderStatus(data: { orderId: string, status: string, step?: string }, config?: ARC) {
      const { orderId, status, step } = data;

      return api.patch(`ops/order/${orderId}/status`, { status, step }, {...config});
    },

    // 利用服务端解析mapping文件
    analyseMapFile(data: any, config?: ARC) {
      return api.post(`ops/util/analysis-mapper`, data, config);
    },

    // 分析盲封mapping文件
    analyseFullMappingFile(file: any, excludeAmbientCircleCount: number, config?: ARC) {
      return api.post(
        `ops/util/analysis/blind-package-excel?excludeAmbientCircleCount=${excludeAmbientCircleCount}`,
        file,
        {
          ...config,
          customErrorMsg: {
            INVALID_FILE: '芯片Full Mapping文件无法解析，请提供正确格式的文件，重新上传',
          },
          handleSpecialError: {
            400: (response) => {
              const maxExcludeAmbientCircleCount = get(response, 'data.maxExcludeAmbientCircleCount');

              let errText = '去除的圈数过多';

              if (maxExcludeAmbientCircleCount !== undefined) {
                errText = `${errText}，去外围圈数请输入小于${maxExcludeAmbientCircleCount + 1}的数值`;
              }

              message.error(errText);
            }
          }
        }
      );
    },

    /* 订单导出操作 */
    // 导出封装订单列表
    exportAssemblyOrder: (orgId, orderType, filename) => (
      `/api/ops/order/export?orgId=${orgId}&businessType=ASSEMBLY&orderType=${orderType}&filename=${filename}`
    ),
    // 导出晶圆测试订单列表
    exportCpOrder: (orgId, orderType, filename) => (
      `/api/ops/order/export?orgId=${orgId}&businessType=CP&orderType=${orderType}&filename=${filename}`
    ),
    // 导出磨划订单列表
    exportDpOrder: (orgId, orderType, filename) => (
      `/api/ops/order/export?orgId=${orgId}&businessType=DP&orderType=${orderType}&filename=${filename}`
    )
  };
}
