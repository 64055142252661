import React, { FC, useEffect, useState } from 'react';
import { Radio, Tooltip } from 'antd';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

interface Props {
  fetchPolicy: any;
  value?: any;
  onChange?: (value: any) => void;
}

interface State {
  canSmartSelection: boolean;
}

const SelectionMode: FC<Props> = props => {
  const { fetchPolicy, value, onChange } = props;
  const newValue = value || 'terrace';

  const [state, setState] = useState<State>({
    canSmartSelection: false
  });
  const { canSmartSelection } = state;

  useEffect(
    () => {
      onChange && onChange(newValue);
    },
    []
  );

  const getPolicy = async () => {
    const item = fetchPolicy?.policies?.find(it => it?.name === '下单时客户可自主选料');

    setState(prevState => ({
      ...prevState,
      canSmartSelection: item?.value && item?.value === 'true'
    }));
  };

  useEffect(
    () => {
      getPolicy();
    },
    [fetchPolicy]
  );

  const text = '自主选料';
  const node = !canSmartSelection ? (
    <Tooltip
      title="如下单需要支持自主选料，请联系芯翼客服"
      overlayStyle={{ zIndex: FlexModalManager.getNextZIndex() }}
    >
      {text}
    </Tooltip>
  ) : text;

  return (
    <Radio.Group onChange={onChange} value={newValue}>
      <Radio value="terrace">平台选料</Radio>
      <Radio value="auto" disabled={!canSmartSelection}>{node}</Radio>
    </Radio.Group>
  );
};

export default SelectionMode;
