/**
 * 一段文字点击变成可编辑的Input
 */
import React from 'react';
import { Tooltip, Icon, message } from 'antd';

import s from './index.module.less';

import InputWrapper from './InputWrapper';
import { MemberContextParams, MemberOperateContext } from '..';

interface Props { 
  userInfo: User;
  defaultText: string;
  inputPlaceholder?: string;
  onChange?: (value: string) => void;
}

interface S {
  inputVisible: boolean;
}

const MARK_REGEXP = /^[A-Za-z0-9_\-\.\u4e00-\u9fa5]{2,20}$/;

class EditRemark extends React.PureComponent<Props, S> {
  private inputValue: string | undefined = void 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      inputVisible: false,
    };

    this.inputValue = props.userInfo.remarkName;
  }

  handleInputChange = (value) => {
    this.inputValue = value;
  }

  handleSetRemarkName = async (setRemarkName: MemberContextParams['setRemarkName']) => {
    const { remarkName, _id } = this.props.userInfo;
    const val = this.inputValue ? this.inputValue.trim() : '';
    const length = val.length;
    if (length !== 0 && !MARK_REGEXP.test(val)) {
      message.error('备注只可包含字母、数字、汉字、“-”、“_”，2~20字符！');
      return false;
    }
    if (remarkName === val) { return true; }

    return await setRemarkName({ userId: _id, remarkName: val });
  }

  handleVisibleInput = () => {
    this.setState({ inputVisible: true });
  }

  handleInputClose = () => {
    this.setState({ inputVisible: false });
  }

  render() {
    const { inputPlaceholder, defaultText } = this.props;
    const { inputVisible } = this.state;
    const { Consumer } = MemberOperateContext;

    if (inputVisible) {
      return (
        <div className={s.inputWrap}>
          <Consumer>
            {({ setRemarkName }) => (
              <InputWrapper
                placeholder={inputPlaceholder}
                value={this.inputValue}
                onInputChange={this.handleInputChange}
                onInputClose={this.handleInputClose}
                setRemarkName={() => this.handleSetRemarkName(setRemarkName)}
              />
            )}
          </Consumer>
        </div>
      );
    }

    return (
      <Tooltip mouseEnterDelay={0.5} title="编辑备注">
        <div className={s.textWrap} onClick={this.handleVisibleInput}>
          {this.inputValue ? this.inputValue : <i>{defaultText}</i>}
          <Icon type="edit" />
        </div>
      </Tooltip>
    );
  }
}

export default EditRemark;
