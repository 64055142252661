import React, { ComponentType } from 'react';
import classnames from 'classnames';
import { get, isEmpty, uniqueId } from 'lodash';
import { createSelector } from 'reselect';
import { Button, Icon } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { FormCreator } from '@chipcoo/hanayo';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import { FlexModal, IFlexModalProps, FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';
import { EnumProcessClass, EnumProductModel, PRODUCT_TYPE } from '@chipcoo/constant';
import { getSelectedSimpleInfo } from '@chipcoo/fe-utils';

import store, { connectDecorator } from 'src/store';
import { DemandOrderFormModalCtx } from './NewOrderModal/context';
import { CommentResource } from 'src/containers/UniverseComment/interface';
import { IDemandOrderModel } from 'src/models/operation/demandOrder/interface';
import styles from './NewOrderModal/index.module.less';
import { getNewOrderModalConfig, getPreviewConfig } from './NewOrderModal/formConfig';

import UniverseCommentWrap from './NewOrderModal/UniverseCommentWrap';

interface OwnProps {
  visible: boolean;
  formConfigType: string;
  productModel: EnumProductModel;
  classes?: EnumProcessClass;
  id?: string;
  mode: ModalMode;
  onOk?: (val: any) => any;
  onCancel?: IFlexModalProps['onCancel'];
  onAfterClose?: IFlexModalProps['onAfterClose'];
  /** 仅在没有id的时候起作用 */
  localDetail?: Obj;
}
interface StateProps {
  orderDetail: IDemandOrderModel['orderDetail'];
}
interface DispatchProps {}
type Props = OwnProps & StateProps & DispatchProps;
export interface ReqOrderPortalProps extends OwnProps {}

interface State {
  step: number;
  modalFullWindow: boolean;
  stashData: Obj;
  commentVisible: boolean;
  btnLoading: boolean;
}

@connectDecorator(
  ({ demandOrder: { orderDetail } }) => ({ orderDetail }),
)
class ReqOrderPortal extends React.PureComponent<Props, State> {
  formController: FormController;
  orderTypeSelector = createSelector(
    (props: Props) => props.classes,
    (props: Props) => props.productModel,
    (props: Props) => PRODUCT_TYPE[props.productModel],
    (classes, productModel, orderTypeText) => {

      return { classes, productModel, orderTypeText };
    }
  );

  selectCommentResource = createSelector(
    (props: Props) => get(props.orderDetail[props.id!], '_id'),
    (orderId): CommentResource => ({
      role: 'comment',
      id: orderId,
      name: 'demand-order',
    }),
  );

  readonly modalName = uniqueId('DemandOrderModal_');

  constructor(props: Props) {
    super(props);

    this.state = {
      step: props.formConfigType === 'pdf' ? 1 : 0,
      modalFullWindow: true,
      stashData: {},
      commentVisible: false,
      btnLoading: false,
    };

    // const { isCreatedByOtherRecord } = this.props;
    //
    // this.addConfig = getStockInConfig('add', isCreatedByOtherRecord);
  }

  getTitle = () => {
    const options = this.orderTypeSelector(this.props);
    const { id } = this.props;
    const preText = id ? '查看' : '添加';

    return `${preText}${options.orderTypeText}需求订单`;
  }

  getFormController = (controller) => this.formController = controller;

  resetStep = () => {
    // 将state数据重置
    this.setState({ step: 0, stashData: {}, btnLoading: false });
  }

  handleCancel = async (e) => {
    const { onCancel } = this.props;

    if (onCancel) {
      await onCancel(e);
    }

    this.resetStep();
  }

  handleSubmit = () => {
    const { step, stashData } = this.state;
    const { classes, onOk } = this.props;

    this.formController.validateFieldsAndScroll(async (errs, values) => {
      if (!errs) {

        if (!step) {
          const info = getSelectedSimpleInfo(values?.selectedData || []);
          // 表示是填写表单页
          this.setState({
            step: 1,
            stashData: {
              ...values,
              quantity: info.quantity || values?.quantity,
              waferQuantity: info.waferQuantity || values?.waferQuantity,
              productModel: values?.productModel || values?.fetchProduct?.productModel,
            }
          });
        }

        if (step === 1) {
          // 表示预览页提交
          if (onOk) {
            this.setState({ btnLoading: true });
            let selectedData = stashData?.selectedData;

            if (isEmpty(selectedData)) {
              selectedData = get(stashData, 'material.0.selectedTableData');
            }

            try {
              await onOk({
                ...stashData,
                selectedData,
                classes
              });

              this.resetStep();
            } catch (e) {
              this.setState({ btnLoading: false });
              console.error(e);
            }
          }
        }
      }
    });
  }

  renderFooter = () => {
    const { step, btnLoading } = this.state;

    if (!step) return null;

    return (
      <>
        <Button
          className="footer-btn"
          onClick={() => this.setState({ step: 0 })}
        >
          上一步
        </Button>
        <Button
          className="footer-btn"
          type="primary"
          onClick={this.handleSubmit}
          loading={btnLoading}
        >
          确定
        </Button>
      </>
    );
  }

  changeCommentVisible = () => {
    const { commentVisible } = this.state;
    this.setState({
      commentVisible: !commentVisible
    });
  }

  triggerResize = () => {
    setTimeout(() => {
      FlexModalManager.triggerResize(this.modalName);
    }, 300);
  }

  renderComment = () => {
    const { orderDetail, id } = this.props;
    const { step } = this.state;
    const formData = orderDetail[id!];

    if (!formData || step === 1) {
      return null;
    }

    const resource = this.selectCommentResource(this.props);
    const state = store.getState();
    const userId = get(state, 'session.profile._id') || '';
    const { commentVisible } = this.state;
    const className = classnames('icon-position', commentVisible ? '' : 'icon-position-left');
    const wrapClassName = classnames('comment-toggle-btn', commentVisible ? 'comment-visible' : 'comment-invisible');
    return (
      <>
        <div className={wrapClassName} onClick={this.changeCommentVisible} >
          <Icon
            type={commentVisible ? 'right' : 'left'}
            className={className}
          />
        </div>
        <div>
          <CSSTransition
            in={commentVisible}
            appear={commentVisible}
            timeout={300}
            onEnter={this.triggerResize}
            onExited={this.triggerResize}
            classNames="universe-comment"
          >
            <UniverseCommentWrap
              userId={userId}
              resource={resource}
              uploaderOptions={{}}
            />
          </CSSTransition>
        </div>
      </>
    );
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  render () {
    const { id, mode, visible, orderDetail, classes } = this.props;
    const { step, stashData, modalFullWindow, commentVisible } = this.state;
    const okText = step === 1 ? '确定' : '下一步';
    const className = classnames(styles.modal, commentVisible ? '' : 'full-center');
    const options = this.orderTypeSelector(this.props);
    let formData = isEmpty(stashData) && id ? { ...orderDetail[id!], classes } : { ...stashData, classes };
    const formConfig = step === 1 ? getPreviewConfig('preview') : getNewOrderModalConfig(options);

    return (
      <DemandOrderFormModalCtx.Provider value={this.state}>
        <FlexModal
          width={1024}
          animation="fade"
          visible={visible}
          modalName={this.modalName}
          className={className}
          onCancel={this.handleCancel}
          onOk={this.handleSubmit}
          okText={okText}
          onAfterClose={this.props.onAfterClose}
          title={this.getTitle()}
          footer={mode === 'preview' ? null : this.renderFooter}
          resize={{ minWidth: 580, minHeight: 400 }}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.requestFullWindow}
          onRequestNormalWindow={this.requestNormalWindow}
          rightSection={this.renderComment()}
        >
          <FormCreator
            formData={formData}
            formConfig={formConfig}
            getFormController={controller => this.formController = controller!}
            globalStatus={mode === 'preview' ? 'preview' : 'edit'}
          />
        </FlexModal>
      </DemandOrderFormModalCtx.Provider>
    );
  }
}

export default ReqOrderPortal as ComponentType<OwnProps>;
