/**
 * 聊天群组右侧的控制面板
 */
import * as React from 'react';
import { Drawer, Divider } from 'antd';
import { connect } from 'react-redux';
import log from 'loglevel';

import store, { RootState } from 'src/store';
import styles from './index.module.less';
import { Room } from 'src/models/im/chatRoomList.type';
import { ImContext, InitialImCtx } from 'src/pages/IM';

import ChatMemberManage from './ChatMemberManage';
import { EditAfterClick } from 'src/components';
import { isEqualUserId } from 'src/utils/user';

interface OwnProps {
  containerSelector: string;
  getToggleFn: (fn: (toggle: boolean) => void) => void;
}
interface StateProps {
  currentUserId: string;
  roomInfo: Room | undefined;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;
interface S {
  visible: boolean;
}

class ChatGroupDrawer extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      visible: false
    };

    const { getToggleFn } = this.props;

    getToggleFn(this.toggleDrawer);
  }

  toggleDrawer = (visible: boolean) => {
    const { visible: stateVisible } = this.state;

    if (stateVisible !== visible) {
      this.setState({ visible });
    }
  }

  onClose = () => this.toggleDrawer(false);

  handleModifyRoomName = (value) => {
    const { roomInfo } = this.props;
    const { _id } = roomInfo!;

    try {
      store.dispatch.chatRoomList.patchRoomName({ name: value, roomId: _id });
    } catch (e) {
      log.error(e);
    }
  }

  render() {
    const { containerSelector, roomInfo, currentUserId } = this.props;
    const { visible } = this.state;

    if (!roomInfo) { return null; }

    const { name, ownerId, _id } = roomInfo;
    const isOwner = isEqualUserId(ownerId, currentUserId);

    return (
      <Drawer
        destroyOnClose={true}
        placement="right"
        closable={false}
        onClose={this.onClose}
        visible={visible}
        getContainer={containerSelector}
        className={styles['drawer-wrapper']}
        width={248}
      >
        <div className={styles.editGroupName}>
          <div className="title term">群名</div>
          {
            isOwner
              ? <EditAfterClick defaultText="点击修改群名" value={name} onChange={this.handleModifyRoomName} />
              : <div>{name}</div>
          }
        </div>

        <Divider />

        <ChatMemberManage isOwner={isOwner} currentUserId={currentUserId} roomId={_id} />

        <ImContext.Consumer>
          {(ctx: InitialImCtx) => (
            <div
              className={styles['leave-btn']}
              onClick={() => ctx.openLeaveChatRoomModal!(isOwner ? 'owner' : 'normal')}
            >
              {isOwner ? '解散群组' : '退出群聊'}
            </div>
          )}
        </ImContext.Consumer>
      </Drawer>
    );
  }
}

const mapState = ({ chatPanel, session }: RootState) => ({
  roomInfo: chatPanel.roomInfo,
  currentUserId: session.profile!._id + ''
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(ChatGroupDrawer);
