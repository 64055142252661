/**
 * 这个组件的使用方式如下：
 * 这个文件会暴露出来一个方法openNewEventBusGlobalModal，调用的时候可以将对应的modal的配置写入并新建一个弹框，看起来非常的不react，但主要是为了解决如下问题：
 *
 * 表单中有这么一个要求：当我选择某个下拉项的时候，会去检查所选择的这一项所对应的表单项是否填写完整，如果不完整，那么会打开一个弹框将这个表单放入让你完善
 *
 * 这里有一个问题，那就是点击后打开弹框的操作，是写在表单配置项中的，你没地方去挂载这个modal的component,
 * 所以这个时候是没有地方去setState({ visible: true })打开一个弹框，包括react的createPortal也有这个问题，那么只能手动创建一个div节点，
 * 再调用ReactDOM.render将一个打开的弹框挂载上去，最后在组件卸载的时候再手动将对应的弹框给全部卸载掉，
 * 如果只是一个无数据交互的弹框，这么做显然并没有什么问题，但是，假如这里需要链接store去获取数据，那么由于这个节点是你重新render的，
 * 并没有挂载到react-redux提供的Provider下面，你根本就获取不到context上下文的数据。。。
 *
 * 这就是这个组件诞生的意义。。。为了能够在表单配置里面打开一个弹框并且拿到对应的store里面的数据
 */
import React, { PureComponent } from 'react';
import log from 'loglevel';
import { onNextFrame } from '@chipcoo/fe-utils';

import { NEW_GLOBAL_PORTAL, DESTROY_GLOBAL_PORTAL, Emitter } from 'src/config/eventBusNameConstant';

interface IGlobalPortalOptions {
  key: string;
  node: React.ReactNode;
}

interface P {}
interface S {

}

// 打开一个新的弹框
export const openNewGlobalPortal = (options: IGlobalPortalOptions) => {
  Emitter.emit(NEW_GLOBAL_PORTAL, options);
};

export const destroyGlobalPortal = (key: string) => {
  Emitter.emit(DESTROY_GLOBAL_PORTAL, key);
};

class GlobalPortal extends PureComponent<P, S> {
  static nodesCache: [string, React.ReactNode][] = [];
  static cacheNodes(nodes: GlobalPortal['nodes']) {
    GlobalPortal.nodesCache = nodes;
    onNextFrame(() => {
      setTimeout(() => {
        GlobalPortal.nodesCache = [];
      }, 160);
    });
  }

  nodes: [string, React.ReactNode][] = [];

  constructor(props: P) {
    super(props);
    this.nodes = GlobalPortal.nodesCache;
  }

  componentDidMount(): void {
    // 开始监听是否有新建全局弹框的事件
    Emitter.on(NEW_GLOBAL_PORTAL, this.addPortal);
    Emitter.on(DESTROY_GLOBAL_PORTAL, this.removePortal);
  }

  componentWillUnmount(): void {
    GlobalPortal.cacheNodes(this.nodes);
    Emitter.removeListener(NEW_GLOBAL_PORTAL, this.addPortal);
    Emitter.removeListener(DESTROY_GLOBAL_PORTAL, this.removePortal);
  }

  addPortal = (options: IGlobalPortalOptions) => {
    const { nodes } = this;
    if (nodes.some(it => it[0] === options.key)) {
      //
      log.error('[GlobalPortal]: duplicate key', options.key, options, nodes);
    }

    nodes.push([options.key, options.node]);

    this.forceUpdate();
  }

  removePortal = (key: string) => {
    const index = this.nodes.findIndex(it => it[0] === key);
    if (index !== -1) {
      this.nodes.splice(index, 1);
      this.forceUpdate();
    }
  }

  render() {
    return this.nodes.map(([_, node]) => {
      if (React.isValidElement(node)) {
        return node;
      }

      return null;
    });
  }
}

export default GlobalPortal;
