import React from 'react';
import { get } from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  NumberFormItemConfig,
  SelectFormItemConfig,
  SearchFormItemConfig,
  RepeaterConfig,
  RepeaterRenderParams,
  CustomFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import commonStyles from '../../index.module.less';
import {
  principal,
  participants,
  other,
  packingMethod,
  getCustomerName,
} from './common';

import { RepeaterCardTitle } from 'src/components';
import DieInfo from '../components/DieInfo';
import CollapseDieInfo from '../components/CollapseDieInfo';

// 芯片名（作为搜索项了，如果要调整请注意）
export const die: SearchFormItemConfig = {
  type: 'search',
  dataKey: 'die',
  label: '芯片名',
  optionKey: 'spwOriginDie',
  props: {
    placeholder: '请新建或选择（可筛选）',
    menuMaxHeight: 200,
  },
  renderType: 'preview'
};

// const dieInfoFormItem: CustomFormItemConfig = {
//   type: 'custom',
//   colSpan: 24,
//   colClassName: styles.dieInfoFormItem,
//   render: (val: Obj, status: string) => {
//     const originDie = get(val, 'die');
//
//     return (
//       <DieInfoFormItemComponent originDie={originDie} fetchDetailType="die" citedBy="citedByDp" />
//     );
//   },
// };

// 晶圆最终厚度
const getWaferFinalThickness = (): NumberFormItemConfig => ({
  label: '晶圆最终厚度',
  type: 'number',
  dataKey: 'requirement.waferFinalThickness',
  unit: 'µm',
  range: '[20, 800]',
  precision: 0,
  renderType: 'preview'
});

// 研磨方式
const bgMethod: SelectFormItemConfig = {
  label: '研磨方式',
  type: 'select',
  dataKey: 'requirement.bgMethod',
  optionKey: 'bgMethod',
  renderType: 'preview'
};

// 切割方式
const sawMethod: SelectFormItemConfig = {
  label: '切割方式',
  type: 'select',
  dataKey: 'requirement.sawMethod',
  optionKey: 'sawMethod',
  renderType: 'preview'
};

const getCollapseDieInfo = (): CustomFormItemConfig => ({
  type: 'custom',
  colSpan: 24,
  // colClassName: styles.dieInfoFormItem,
  renderType: (value) => get(value, 'die') ? 'preview' : 'none',
  render: (val: Obj) => {
    const originDie = get(val, 'die');

    return (
      <CollapseDieInfo>
        <DieInfo die={originDie} />
      </CollapseDieInfo>
    );
  }
});

export const dieInfoArea = [
  die,
  // divider,
  // dieInfoFormItem,
  getCollapseDieInfo()
];

// 磨划要求
const dpRequirement = (): FormItemConfig[] => [
  getWaferFinalThickness(),
  bgMethod,
  sawMethod,
  other('requirement'),
];

const getPackingRequirement = (productModel: EnumProductModel): RepeaterConfig => ({
  type: 'repeater',
  dataKey: 'packing',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams) {
    return ({
      card: {
        title: RepeaterCardTitle('包装要求', params.index, params.len),
      },
      groupItemConfig: [
        packingMethod(productModel),
        other()
      ]
    });
  },
});

// 前台的dp成品表单配置
export const getDpProductConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: {
        title: '基本信息',
        className: commonStyles.hiddenCardTitle
      },
      groupItemConfig: [
        getCustomerName(),
        principal,
        participants
      ],
    },
    {
      card: {
        title: '芯片信息',
      },
      groupItemConfig: dieInfoArea
    },
    {
      card: {
        title: '磨划要求',
      },
      groupItemConfig: dpRequirement(),
    },
    getPackingRequirement(EnumProductModel.dpProduct),
  ],
});

// tslint:disable-next-line:max-file-line-count
