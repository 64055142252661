import React from 'react';
import { EnumProductModel } from '@chipcoo/constant';

import { TableCheckBoxGroup } from 'src/containers';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {
  productModel: EnumProductModel;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  const { productModel } = option;
  let detailAmount: any[] = [];

  if (productModel === EnumProductModel.assemblyProduct) {
    detailAmount = [
      {
        title: 'Bin类型',
        dataIndex: 'bins',
        key: 'bins',
        width: 250,
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        title: '总数量',
        width: 100,
      }
    ];
  } else {
    detailAmount = [
      {
        title: '片号',
        dataIndex: 'waferIds',
        key: 'waferIds',
        width: 150,
        render: text => {
          return <span>{text ? '#' + text : '/'}</span>;
        }
      },
      {
        dataIndex: 'good',
        key: 'good',
        title: '良品数量',
        width: 100
      },
      {
        dataIndex: 'bad',
        key: 'bad',
        title: '不良品数量',
        width: 150
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        title: '总数量',
        width: 100
      },
    ];
  }

  return [
    {
      dataIndex: 'checkbox',
      key: 'checkbox',
      width: 100,
      render: (text, record) => {
        return <TableCheckBoxGroup record={record} />;
      },
      fixed: 'left',
    },
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'materialName',
      key: 'materialName',
      title: '物料名称',
      width: 200,
    },
    {
      dataIndex: 'attributes',
      key: 'attributes',
      title: '是否为蓝膜',
      width: 150,
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 200
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      width: 200
    },
    {
      dataIndex: 'markMode',
      key: 'markMode',
      title: '打点方式',
      width: 100
    },
    {
      dataIndex: 'packingMethod',
      key: 'packingMethod',
      title: '包装方式',
      width: 100
    },
    {
      title: '芯片批号',
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      width: 200,
    },
    ...detailAmount,
    {
      dataIndex: 'restrictBySerial',
      key: 'restrictBySerial',
      title: '限制单号',
      width: 200,
    }
  ];
};
