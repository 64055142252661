/** 获取仓库中的物料名称 */
import React, { FC, useState, useEffect } from 'react';
import { inventoryApi } from 'src/services/net';
import { SelectWithOption } from '@chipcoo/hanayo';
import { EnumProductModel } from '@chipcoo/constant';

interface IProps {
  value?: any;
  onChange?: (val: string) => void;
  productModel: EnumProductModel | 'ALL';
}

interface IState {
  options: any;
}

const SelectMaterialByProductModel: FC<IProps> = props => {
  const { value, productModel, ...restProps } = props;
  const [state, setState] = useState<IState>({
    options: [],
  });
  const { options } = state;

  const getOptionData = async () => {
    if (productModel) {
      const { data } = await inventoryApi.searchStockGroup({
        productModel: productModel === 'ALL' ? undefined : productModel
      });
      
      setState({
        options: data.data.map(item => ({ key: item._id, label: item.name }))
      });
    }
  };

  useEffect(() => {
    getOptionData();
  }, [productModel]);

  return (
    <SelectWithOption
      value={value}
      options={options}
      labelInValue={true}
      {...restProps}
    />
  );
};

export default SelectMaterialByProductModel;
