import { get, set, flow, cloneDeep, isEmpty, uniq, isString, flattenDeep, isArray, sum } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { unSortWaferId, sortWaferId, getUserName, getHandleDataBySelectedData } from '@chipcoo/fe-utils';
import { EnumProcessClass, EnumProductModel } from '@chipcoo/constant';
import { getMomentTime, getFactoryName, slices2Number, bins2Number } from '@chipcoo/fe-utils';

import {
  booleanStringConvert,
  setValByPath,
  getStepCraftRouteData,
  getOrgIdByUrl,
} from 'src/utils';
import { IDemandOrderDetail } from 'src/services/net/demandOrder';
import { orgApi, productApi } from 'src/services/net';
import { optionsGroup } from 'src/config/selectOption';

// 处理需求单列表数据
export const handleDemandOrderList = async (respData) => {
  const handleData = (respData || []).reduce(
    (prev, cur) => {
      const {
        _id,
        // product,
        createdAt,
        predictDeliveryTime,
        packageType,
        status,
        name,
        productModel,
        waferQuantity,
        ...restData
      } = cur;
      const productInfo = cur?.product;
      let dieName;

      let curData = {
        ...restData,
        _id,
        key: _id,
        // product,
        productModel,
        productModelText: optionsGroup.productModel.options?.[productModel],
        waferQuantity,
        classes: waferQuantity === 0 ? EnumProcessClass.ft : EnumProcessClass.assembly,
        createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
        predictDeliveryTime: getMomentTime(predictDeliveryTime),
        status,
        statusText: optionsGroup.requirementStatus.options![status],
        customerName: productInfo?.customerName,
      };

      if (productModel === EnumProductModel.assemblyProduct) {
        const dies = productInfo?.product?.dies?.map(it => it?.die?.customerName);
        dieName = dies?.join(', ');

        curData = {
          ...curData,
          dieName,
          packageType: optionsGroup.packageType_1.options![packageType[0]],
          packageTypeLW: packageType[0] === '引线框架' ? packageType[1] : '/',
        };
      }

      if (productModel === EnumProductModel.dpProduct) {
        dieName = productInfo?.product?.die?.customerName;

        curData = {
          ...curData,
          dieName,
        };
      }

      prev.push(curData);

      return prev;
    },
    [] as any[]
  );

  return {
    originData: respData,
    handleData,
  };
};

export const getHandleSteps = async (steps: any[], craftRoutes: any[]) => {
  let [
    productIds,
    productArray,
  ]: any[] = [[], [], [], [], [], [], []];

  let newSteps = steps.map(step => {
    const { commands, craftRoute, plan } = step;

    const newCommands = commands.map(item => {
      const { _id, command, stockInfos } = item;
      const outputMaterialName = command?.output?.materialName;

      const newInputs = (command?.inputs || []).map(input => {
        let newInput = input;
        const { slices, bins, stock } = newInput;
        const stockInfo = stockInfos.find(s => s.stock === stock);

        if (stockInfo) {
          const stockSlices = stockInfo.slices;
          const stockBins = stockInfo.bins;
          // 需要塞入stock的部分信息，至于slices，bins，可用不可用等数据下面数据处理后会覆盖
          newInput = { ...newInput, ...stockInfo };

          if (stockSlices) newInput = { ...newInput, stockSlices };
          if (stockBins) newInput = { ...newInput, stockBins };

          if (slices && !isEmpty(slices)) {
            const slices2NumberObj = slices2Number(slices, newInput?.productModel);

            if (newInput?.productModel === EnumProductModel.dpProduct) {
              const newSlices = slices.map(it => {
                const waferItem = stockSlices.find(s => s?.waferId === it?.waferId);

                return {
                  ...waferItem,
                  key: stock + '_' + waferItem?.waferId,
                  waferId: waferItem?.desc,
                };
              });

              newInput = {
                ...newInput,
                ...slices2NumberObj,
                stockNumber: newSlices,
                slices: newSlices,
                waferIds: slices2NumberObj?.waferIds, // 用来让stockNumber显示
              };
            } else {
              newInput = {
                ...newInput,
                ...slices2NumberObj,
                stockNumber: slices,
              };
            }
          }

          if (bins && !isEmpty(bins)) {
            const bins2NumberObj = bins2Number(bins);

            newInput = {
              ...newInput,
              ...bins2NumberObj,
              bins: bins2NumberObj.binType,
              stockNumber: bins2NumberObj.binsData
            };
          }
        }

        return newInput;
      });

      return {
        ...command,
        inputs: newInputs,
        output: {
          ...command?.output,
          factory: command?.factory && (
            isString(command?.factory) ? command?.factory : getFactoryName(command?.factory)
          ),
          product: outputMaterialName ? outputMaterialName.split('/')[0] : undefined
        },
        commandId: _id,
        manufactureOperation: command?.processCode,
        manufactureType: command?.processName,
      };
    });

    // 步骤图-工艺路线的某一段
    const params = {
      craftRoutes,
      craftRouteId: plan?.craftRoute,
      targetMaterial: plan?.target,
      sourceMaterial: plan?.source,
    };

    const crmFilterFlatItems = getStepCraftRouteData(params);

    const crm = crmFilterFlatItems.find(c => c.craftRoute === craftRoute);

    return {
      ...step,
      ...crm,
      commands: newCommands
    };
  });

  // 获取步骤里sourceProduct和targetProduct的产品信息
  newSteps.forEach(step => {
    const { sourceProduct, targetProduct } = step;

    if (sourceProduct) productIds.push(sourceProduct);
    if (targetProduct) productIds.push(targetProduct);
  });

  const allProductIds: any[] = uniq(productIds).filter(it => it);
  if (!isEmpty(allProductIds)) {
    // todo 准备干掉
    const { data: { data } } = await productApi.searchProductInOrg({ ids: allProductIds });
    productArray = data;
  }

  newSteps = newSteps.map(step => {
    let item = step;

    ['sourceProduct', 'targetProduct'].forEach(key => {
      if (step[key]) {
        const data = productArray.find(it => it._id === step[key]);
        item = { ...item, [`${key}`]: data };
      }
    });

    return item;
  });

  return newSteps;
};

const bool2stringPaths = [
  'isPrintStampNumber',
];

// 处理需求单详情数据
export async function handleDetailData(detail: IDemandOrderDetail) {
  const data = flow([
    booleanStringConvert(bool2stringPaths, 'boolean')
  ])(detail);
  let stockArray: any[] = [];

  set(data, 'product', {
    key: get(data, 'product._id'),
    label: get(data, 'product.customerName'),
  });
  setValByPath(data, 'expectedDeliveryDate', val => val && moment(val));

  stockArray = uniq((detail.stockInfos || []).map(it => it.stock).filter(it => it));

  const selectedData = getStockList(stockArray);

  return {
    ...data,
    selectedData,
    selectionMode: isEmpty(selectedData) ? 'terrace' : 'auto',
    orderTemplateName: get(detail, 'name'),
    orderTemplate: get(detail, 'name'),
    selectedMaterialInfo: [{
      allowReplace: data?.allowReplace,
    }],
    // plans,
    // selectedTableData: selectedData
  };
}

// 根据产品id、组织id等获取数据并处理
export async function getPreviewReqOrderData(respData: any) {
  const { formData, orgMembers, stashData } = respData;

  const productId = stashData?.product?.key;
  const orgId = getOrgIdByUrl();
  let [productInfo, state, packageType, productDies, packingMethod]: any[] = [{}, {}, {}, [], ''];

  let {
    createdAt,
    expectedDeliveryDate,
    product,
    productModel,
    material,
    orderTemplate,
  } = stashData;
  const orderTemplateId = orderTemplate?.key;

  if (orgId) {
    const getOrgInfo = orgApi.getOrgInfo(orgId);
    const getOrgVerify = orgApi.getOrgVerify();
    let promiseArray = [getOrgInfo, getOrgVerify];

    // 详情时不需要orderTemplateId关联
    if (orderTemplateId) {
      const getOrderTemplateDetail = productApi.getOrderTemplateById(orderTemplateId);
      promiseArray.push(getOrderTemplateDetail);
    }

    // 根据产品类型走不同的api
    if (productId) {

      if (!productModel) {
        productModel = formData?.fetchProduct?.productModel;
      }

      productInfo = formData?.fetchProduct;

      if (!formData?.fetchProduct) {
        const {data: productDetail} = await productApi.getProductById(productId);
        productInfo = productDetail;
      }

      const [
        { data: orgInfo },
        { data: orgVerify },
        orderTemplateResp
      ] = await Promise.all(promiseArray);
      const org = {
        ...orgVerify,
        ...orgInfo,
      };
      const orderTemplateDetail = orderTemplateResp?.data;

      packingMethod = (productInfo?.packing || [])
        .find(it => it?._id === orderTemplateDetail?.packing)?.packingMethod || formData?.packingMethod;
      createdAt = getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss');
      expectedDeliveryDate = expectedDeliveryDate ? getMomentTime(expectedDeliveryDate) : undefined;

      // 委托方
      const user = get(orgMembers?.byId, formData?.userId);
      const client = get(orgInfo, 'info.name');
      const clientName = getUserName(user);
      const clientPhone = user?.phone;
      const clientContacts = clientName + (clientPhone ? ' - ' + clientPhone : '');

      // 承接方
      const sspName = get(org, 'business.chipcooContactName') || '';
      let sspPhone = get(org, 'business.chipcooContactPhone') || '';
      sspPhone = sspPhone ? ' - ' + sspPhone : '';
      const sspContacts = sspName + sspPhone;

      if (productInfo) {
        packageType = productInfo?.product?.packageType;
      }

      if (productModel === EnumProductModel.assemblyProduct) {
        const dies = productInfo?.product?.dies;

        if (isArray(dies)) {
          productDies = dies.map(it => ({
            customerName: it?.die?.customerName,
            dieSize: it?.die?.product?.info?.dieSize
          }));
        }
      }

      if (productModel === EnumProductModel.dpProduct) {
        const die = productInfo?.product?.die;

        productDies.push({
          customerName: die?.customerName,
          dieSize: die?.product?.info?.dieSize
        });
      }

      state = {
        ...state,
        productModel,
        formData: {
          ...formData,
          createdAt,
          expectedDeliveryDate,
          client,
          clientContacts,
          ssp: '杭州芯翼科技有限公司',
          sspContacts,
          productName: product?.label,
          packageType,
          productDies,
          ...productInfo?.requirement,
          allowReplace: material?.[0]?.allowReplace,
          packingMethod,
          orderTemplateName: orderTemplate?.name || formData?.orderTemplateName
        },
      };
    }

    return state;
  }
}

// 处理需求单提交数据
export async function handleDemandOrderFormData(formData: any, prevFormData?: Obj) {
  let handleData = Object.entries(formData).reduce(
    (prev, [key, value]) => {
      let val: any = cloneDeep(value);
      let resetKey: string = '';

      if (key === 'expectedDeliveryDate' && val) {
        val = getMomentTime(val);
      }

      if (key === 'product') {
        val = val.key;
      }

      // status需要置空
      if (key === 'status' || key === 'auditReason') {
        val = undefined;
      }

      if (key === 'selectedData') {
        resetKey = 'stockInfos';

        if (val) {
          val = getHandleDataBySelectedData(val);
        }
      }

      if (key === 'material') {
        resetKey = 'allowReplace';

        val = val[0]?.allowReplace;
      }

      if (key === 'orderTemplate') {
        resetKey = 'orderTemplateId';

        val = val?.key;
      }

      // resetKey为空时，表示key没有被修改；反之，表示需要修改key
      prev[resetKey ? resetKey : key] = val;

      return prev;
    },
    {} as any
  );

  // 如果自主选料，需要将订单数量同步到需求数量上
  if (handleData?.quantity) {
    handleData = {
      ...handleData,
      demandQuantity: handleData?.quantity,
      demandWaferQuantity: handleData?.waferQuantity ?? 0,
    };
  }

  handleData = {
    ...handleData,
    demandWaferQuantity: handleData?.demandWaferQuantity ?? 0,
  };

  return {
    ...handleData,
    _n: uuidv4()
  };
}

// const getFreezeOnParent = (respData) => {
//   let freezeOnParent;
//   const unFrozenWafers = respData.filter(s => !s.isFrozen);
//   const frozenWafers = respData.filter(s => s.isFrozen);
//
//   if (isEmpty(unFrozenWafers)) {
//     // 如果没有非冻结的片，那么该父行的isFrozen显示冻结
//     freezeOnParent = '冻结';
//   } else {
//     const unFrozenWaferIds = unFrozenWafers.map(it => it.waferId);
//     const frozenWaferIds = frozenWafers.map(it => it.waferId);
//     const unFrozenWaferCount = unFrozenWafers?.length;
//     const frozenWaferCount = frozenWafers?.length;
//     const count = unFrozenWaferCount + frozenWaferCount;
//     const unFrozenWaferIdsText = sortWaferId(unFrozenWaferIds);
//     const frozenWaferIdsText = sortWaferId(frozenWaferIds);
//
//     const text1 = `共${count}片；`;
//     const text2 = `${frozenWaferCount}片冻结${frozenWaferCount > 0 ? (` #${frozenWaferIdsText}`) : ''}；`;
//     const text3 = `${unFrozenWaferCount}片非冻结${unFrozenWaferCount > 0 ? (` #${unFrozenWaferIdsText}`) : ''}`;
//
//     freezeOnParent = text1 + text2 + text3;
//   }
//
//   return freezeOnParent;
// };

// 处理可选物料列表数据
const getStockList = (formData) => {
  const handleSingleData = (formData || []).reduce(
    (prev, cur) => {
      let {
        _id,
        name,
        stockInAt,
        slices,
        bins,
        productModel,
        warehouse,
        attributes,
        available,
        unavailable,
        status,
        // freeze,
      } = cur;

      let [
        waferIds,
        newSlices,
        binType,
        newBins,
        children,
        binIndex
      ]: any[] = [[], [], '', [], undefined, undefined];

      if (productModel === EnumProductModel.assemblyProduct) {
        bins.forEach((it, index) => {
          const { amount } = it;

          // 选料只要Bin1和无
          if (amount && (index === 0 || index === 1)) {
            newBins.push({
              key: _id + '_' + index,
              amount,
              binType: index === 0 ? '无' : `Bin${index}`,
              binIndex: index,
              productModel
            });
          }
        });

        children = newBins.length > 1 && newBins.map(it => ({
          ...it,
        }));

        const binTypeArray = newBins.map(it => it?.binType).filter(it => it);
        binType = binTypeArray.join(', ');

        available = sum(newBins?.map(it => it.amount || 0));
        unavailable = 0;

        // 如果选料只有单条时，在数据根目录里添加一个binIndex
        if (newBins?.length === 1) {
          binIndex = newBins?.[0]?.binIndex;
        }
      } else {
        if (slices) {
          newSlices = slices.map(s => {
            const { waferId, good, bad, desc } = s;
            let common: any = {
              key: _id + '_' + waferId,
              waferIds: waferId,
              good,
              bad,
              amount: good + bad,
              // isFrozen,
              attributes,
              productModel,
              // frozenText: isFrozen ? '冻结' : '否',
            };

            if (productModel === EnumProductModel.spwDie) {
              waferIds.push(waferId);
            } else if (productModel === EnumProductModel.dpProduct) {
              waferIds = waferIds.concat(flattenDeep(unSortWaferId(desc)) as number[]);

              common = {
                ...common,
                originWaferId: waferId,
                waferIds: desc,
                desc,
              };
            }

            return common;
          });

          children = newSlices.length > 1 && newSlices;
        }
      }

      const curData = {
        ...cur,
        _id,
        key: _id,
        name,
        stockInAt: stockInAt && getMomentTime(stockInAt, 'YYYY-MM-DD HH:mm:ss'),
        waferIds: sortWaferId(waferIds),
        slices: newSlices,
        bins,
        binType,
        binIndex,
        children,
        productName: name.split('/')[0],
        warehouse: warehouse?.name,
        attributes,
        attributesText: (attributes & 1) === 1 ? '蓝膜' : '否',
        good: available,
        bad: unavailable,
        // amount: available + unavailable + freeze,
        amount: available + unavailable,
        status,
        statusText: optionsGroup.stockStatus.options![status],
        // frozenText: freezeOnParent,
        // isFrozen: freezeOnParent === '冻结'
      };

      prev.push(curData);

      return prev;
    },
    [] as any[]
  );

  return handleSingleData;
};

export const handleStockSelectionData = (formData) => {
  const handleData = Object.entries(formData).reduce(
    (prev, [key, data]: any) => {
      const handleSingleData = getStockList(data);

      prev[key] = handleSingleData;

      return prev;
    },
    {}
  );

  return {
    originData: formData,
    handleData,
  };
};

// 处理智能选料数据
// stockList为可选物料列表数据
export const handleSmartSelectionData = (data, formData) => {
  let [smartSelectionData, selectedKeys]: any[][] = [[], []];

  Object.keys(formData).forEach(key => {
    data.forEach(m => {
      let { _id, serialArray, children, good, bad, amount } = m;

      if (isString(serialArray)) {
        serialArray = unSortWaferId(serialArray);
      }

      // const singleStockKeys = waferIds?.map(it => _id + '_' + it);
      // selectedKeys = selectedKeys.concat(singleStockKeys);

      // let selectionWaferIds: any[] = [];

      const stock = formData?.[key]?.find(it => it?._id === _id);

      if (stock && !isEmpty(serialArray)) {
        // const stockChildrenLength = stock.slices && stock.slices.filter(c => !c.isFrozen).length;
        const stockChildrenLength = stock.slices && stock.slices.length;

        // // 如果stock非冻结片数 = 智能选料里选中的片数，那么表示全选，需要将该stock id放入selectionWaferIds里
        if (stockChildrenLength === serialArray?.length || stock.slices.length === 1) {
          selectedKeys.push(_id);
        }

        if (stock.slices.length !== 1) {
          if (stock.productModel === EnumProductModel.assemblyProduct) {
            selectedKeys = selectedKeys.concat([_id]);
          } else {
            selectedKeys = selectedKeys.concat(serialArray?.map(w => _id + '_' + w));
          }
        }

        if (isEmpty(children)) {
          smartSelectionData.push({
            ...stock,
            good,
            bad,
            amount,
          });
        } else {
          smartSelectionData.push({
            ...stock,
            children,
          });
        }
      }

      if (isEmpty(formData?.[key]) && !isEmpty(serialArray)) {
        if (serialArray.length !== 1) {
          selectedKeys = selectedKeys.concat(serialArray.map(w => _id + '_' + w));

          selectedKeys.push(_id);
        } else {
          selectedKeys.push(_id);
        }
      }

      // smartSelectionData = smartSelectionData.concat(selectionWaferIds);
    });
  });

  // return smartSelectionData;

  return {
    smartSelectionData,
    selectedKeys
  };
};
// tslint:disable:max-file-line-count
