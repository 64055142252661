import { uniq, sortBy, unionBy } from 'lodash';
import { getMomentTime, sortWaferId } from '@chipcoo/fe-utils';
import { EnumAnalysisFrom } from '@chipcoo/constant';

import { optionsGroup } from 'src/config/selectOption';
import { reservingDecimal } from 'src/utils';

export const handleAnalysisList = (payload: Obj) => {
  const { data, products, factories, dieNames } = payload;
  return data.map(it => {
    const {
      _id,
      testStartedAt,
      testFinishedAt,
      createdAt,
      wafers,
      factory,
      factoryName,
      status,
      product,
      productName,
    } = it;

    const waferIds = wafers?.map(w => w.waferId) || [];
    const currentFactory = factories?.find(f => f._id === factory);
    const currentProduct = products?.find(p => p._id === product);
    const currentDieName = dieNames?.find(d => d._id === product);

    return {
      ...it,
      _id,
      testStartedAt: getMomentTime(testStartedAt, 'YYYY-MM-DD HH:mm:ss'),
      testFinishedAt: getMomentTime(testFinishedAt, 'YYYY-MM-DD HH:mm:ss'),
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      waferIds: waferIds ? '#' + sortWaferId(waferIds) : waferIds,
      productName: product ? currentProduct?.customerName + '(' + currentProduct?.chipcooName + ')' : productName,
      factory: factoryName ?? currentFactory?.shortName,
      dieName: currentDieName ? currentDieName?.customerName + '(' + currentDieName?.chipcooName + ')' : '',
      statusText: optionsGroup.analysisStatus.options![status],
    };
  });
};

export const handleAnalysisDetail = (payload) => {
  const {
    from,
    createdAt,
    testedAt,
    wafers,
    tests,
    testStartedAt,
    testFinishedAt,
    factories,
    product,
    customData,
  } = payload;
  let productName = payload?.productName, factoryName, dieNumber, testProgram;

  if (from === EnumAnalysisFrom.automatic) {
    factoryName = payload?.factoryName ?? factories?.[0]?.shortName;
    dieNumber = payload.dieNumber;
    testProgram = payload.testProgram;
  } else if (from === EnumAnalysisFrom.manually) {
    const primaryRanges: any[] = customData?.primaryRanges;
    const primaryData: any[] = primaryRanges.map(it => {
      return {
        ...it,
        product: product?.find(p => p._id === it.product),
        factory: factories?.find(f => f._id === it.factory),
      };
    });
    let productArr = unionBy(primaryData, it => it.product?._id);
    let factoryArr = unionBy(primaryData, it => it.factory?._id);

    if (productArr?.length > 3) {
      productName = productArr.map(it => it.product.customerName).slice(0, 3).join(', ') + '...';
    } else {
      productName = productArr.map(it => it.product.customerName).join(', ');
    }

    if (factoryArr?.length > 3) {
      factoryName = factoryArr.map(it => it.factory.shortName).slice(0, 3).join(', ') + '...';
    } else {
      factoryName = factoryArr.map(it => it.factory.shortName).join(', ');
    }

    if (primaryData?.length > 3) {
      dieNumber = primaryData.map(it => it.dieNumber).slice(0, 3).join(', ') + '...';
      testProgram = primaryData.map(it => it.testProgram).slice(0, 3).join(', ') + '...';
    } else {
      dieNumber = primaryData.map(it => it.dieNumber).join(', ');
      testProgram = primaryData.map(it => it.testProgram).join(', ');
    }
  }

  const batches = uniq(wafers.map(it => it.dieNumber)).map(d => {
    const filterWafers = wafers.filter(it => it.dieNumber === d);
    let waferIds = uniq(filterWafers.map(it => it.waferId));

    return {
      dieNumber: d,
      waferIds: sortBy(waferIds),
    };
  });
  const newTests = tests?.map(it => {
    const { min, max } = it;

    return {
      ...it,
      min: reservingDecimal(min, 3),
      max: reservingDecimal(max, 3),
    };
  });

  return {
    ...payload,
    batches,
    tests: newTests,
    originTests: tests,
    factoryName,
    productName,
    // product: from === EnumAnalysisFrom.automatic ? product?.[0] : product,
    dieNumber,
    testProgram,
    yield: (payload?.yield || payload.yield === 0) ? reservingDecimal(payload?.yield * 100, 3) + '%' : undefined,
    createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
    testedAt: getMomentTime(testedAt, 'YYYY-MM-DD HH:mm:ss'),
    testStartedAt: getMomentTime(testStartedAt, 'YYYY-MM-DD HH:mm:ss'),
    testFinishedAt: getMomentTime(testFinishedAt, 'YYYY-MM-DD HH:mm:ss'),
  };
};
