// tslint:disable:max-file-line-count

import React, { PureComponent } from 'react';
import produce from 'immer';
import { connect } from 'react-redux';
import { omit, get } from 'lodash';
import { bind } from 'lodash-decorators';
import { message } from 'antd';
import log from 'loglevel';

import store, { RootState } from 'src/store';
import { orgApi } from 'src/services/net';
import { DataItem, MemberSelectGroup } from 'src/components/SelectMemberPanel';
import { isEqualUserId } from 'src/utils/user';

import { createMapUserSelector } from 'src/models/user/selectors';
import { filterAvailableMember } from 'src/models/org';
import AddMember from 'src/containers/MemberTable/AddMember';

interface OwnProps {
  visible: boolean;
  value?: UserId[];
  disabledKeys?: UserId[];
  modalTitle?: string;
  leftTitle?: string;
  rightTitle?: string;
  showFriends?: boolean;
  onCancel?: () => any;
  onChange?: (value: string[]) => any;
  onSubmit?: (value?: UserId[], reset?: () => any) => any;
  onVisibleChange?: (visible: boolean) => any;
}

interface StateProps {
  myUserId: UserId;
  orgList: RootState['org']['orgList'];
  userList: RootState['user']['list'];
  friends: RootState['friend']['friends'];
  users: User[];
}

interface State {
  modalVisible: boolean;
  dataSource: DataItem[];
  memberSelectGroups: MemberSelectGroup[];
}

type Props = OwnProps & StateProps;

function createMemberSelectGroups(props: Props, ctx: SelectMember): MemberSelectGroup[] {
  //
  const { friends } = props;
  const { orgList: { allIds, byId }, showFriends = true } = props;
  return [
    ...(showFriends ? [{
      groupKey: 'friends',
      groupTitle: '全部好友',
      dataKeys: friends.allIds.map(id => id.toString()),
    }] : []),
    ...(allIds.map(id => ({
      groupKey: id,
      groupTitle: byId[id].info.shortName || byId[id].info.name,
      dataKeys: [],
      isAsync: true,
      loading: false,
      fetched: false,
      fetch: (group: MemberSelectGroup) => ctx.fetchOrgMembers(group),
    }))),
  ];
}

function updateDataSource(dataSource: DataItem[], dataKeys: UserId[], props: Props) {
  return produce(dataSource, draft => {
    dataKeys.forEach(key => {
      const find = draft.find(item => isEqualUserId(key, item.key));
      const disabled = (props.disabledKeys || []).some(disabledKey => isEqualUserId(disabledKey, key));

      if (!find) {
        draft.push(({
          key: key.toString(),
          title: key.toString(),
          disabled,
          checked: disabled || (!props.value || (props.value).some(selectedKey => isEqualUserId(selectedKey, key))),
        }));
      }
    });
  });
}

class SelectMember extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { showFriends = true } = props;
    const memberSelectGroups = createMemberSelectGroups(props, this);

    // memberSelectGroups[0].dataKeys will be at least an empty array when showFriends.
    const dataKeys = showFriends ? memberSelectGroups[0].dataKeys : [];

    this.state = {
      modalVisible: props.visible,
      dataSource: updateDataSource([], dataKeys, props),
      memberSelectGroups,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const nextState: Partial<State> = {};
    let fetchAllMembers = false;
    if (this.props.visible && !prevProps.visible) {
      nextState.memberSelectGroups = createMemberSelectGroups(this.props, this);
      const { showFriends = true } = this.props;
      // memberSelectGroups[0].dataKeys will be at least an empty array when showFriends.
      const dataKeys = showFriends ? nextState.memberSelectGroups[0].dataKeys : [];

      nextState.dataSource = updateDataSource([], dataKeys, this.props);
      nextState.modalVisible = true;
      fetchAllMembers = true;
    } else if (!this.props.visible && prevProps.visible) {
      nextState.modalVisible = false;
      nextState.dataSource = [];
      nextState.memberSelectGroups = [];
    }

    if (Object.keys(nextState).length) {
      this.setState({ ...(nextState as any) }, () => {
        fetchAllMembers && this.fetchAllOrgMembers();
      });
    }
  }

  updateDataSource(dataKeys: string[]) {
    const { dataSource } = this.state;
    this.setState({
      dataSource: updateDataSource(dataSource, dataKeys, this.props),
    });
  }

  async fetchAllOrgMembers() {
    const { myUserId } = this.props;
    this.setState({
      memberSelectGroups: produce(this.state.memberSelectGroups, draft => {
        draft.forEach(group => {
          if (group.isAsync) {
            group.loading = true;
          }
        });
      }),
    });

    let memberSelectGroups: State['memberSelectGroups'] = this.state.memberSelectGroups;
    const dataKeys: string[] = [];
    const users: User<any>[] = [];
    try {
      const { data } = await orgApi.getList({ params: { isIncludeMembers: true } });

      memberSelectGroups = produce(memberSelectGroups, draft => {
        draft.forEach(group => {
          if (group.isAsync) {
            group.loading = false;
          }

          if (!Array.isArray(data)) {
            return;
          }
          const find = data.find(org => org._id === group.groupKey);
          if (find) {
            // 取出当前组织的所有用户
            const orgUsers = (get(find, 'members') || []).filter(filterAvailableMember).map(u => u.user);
            // 往数组里先丢，重复也没事
            users.push(...orgUsers);
            // 取出当前组织所有用户的用户id
            const curDataKeys = orgUsers.map(user => user._id.toString()).filter(id => !isEqualUserId(id, myUserId));
            // 往dataKeys里丢，重复也没事
            dataKeys.push(...curDataKeys);
            // 设置当前分组的dataKeys
            group.dataKeys = curDataKeys;
            group.fetched = true;
          }
        });
      });
      await store.dispatch.user.setUser(users);
      this.setState({
        dataSource: updateDataSource(this.state.dataSource, dataKeys, this.props),
        memberSelectGroups,
      });
    } catch (e) {
      log.error('[SelectMember]: append org users fault.', e);
      message.error('加载组织成员失败');
      memberSelectGroups = produce(memberSelectGroups, draft => {
        draft.forEach(group => {
          if (group.isAsync) {
            group.loading = false;
          }
        });
      });
    }
  }

  @bind
  async fetchOrgMembers(group: MemberSelectGroup) {
    const { myUserId } = this.props;
    const { groupKey: orgId, fetched, loading } = group;
    if (fetched || loading) {
      return;
    }

    this.setState({
      memberSelectGroups: produce(this.state.memberSelectGroups, draft => {
        const index = draft.findIndex(grp => grp.groupKey === group.groupKey);
        if ((index)) {
          draft[index] = {
            ...draft[index],
            loading: true,
          };
        }
      })
    });

    try {
      const { data: users } = await orgApi.getMembers(orgId);
      if (Array.isArray(users)) {
        await store.dispatch.user.setUser(users.filter(filterAvailableMember).map(user => user.user));
        const dataKeys = users.map(user => user.user._id.toString()).filter(id => !isEqualUserId(id, myUserId));
        this.updateDataSource(dataKeys);

        this.setState({
          memberSelectGroups: produce(this.state.memberSelectGroups, draft => {
            const index = draft.findIndex(grp => grp.groupKey === group.groupKey);
            if ((index)) {
              draft[index] = {
                ...draft[index],
                loading: false,
                fetched: true,
                dataKeys,
              };
            }
          })
        });
      } else {
        //
      }
    } catch (e) {
      console.error(e);
      message.error('加载组织成员失败');
      this.setState({
        memberSelectGroups: produce(this.state.memberSelectGroups, draft => {
          const index = draft.findIndex(grp => grp.groupKey === group.groupKey);
          if ((index)) {
            draft[index] = {
              ...draft[index],
              loading: false,
            };
          }
        })
      });
    }

    return true;
  }

  render() {
    const { dataSource, memberSelectGroups, modalVisible } = this.state;
    const props = omit(this.props, ['orgList', 'userList', 'friends']);

    return (
      <AddMember
        {...props}
        visible={modalVisible}
        dataSource={dataSource}
        groupMode={true}
        groups={memberSelectGroups}
      />
    );
  }
}

const pickAllUser = createMapUserSelector();

function mapState(state: RootState) {
  return {
    myUserId: state.session.profile!._id,
    users: pickAllUser(state.user, state.user.list.allIds),
    orgList: state.org.orgList,
    userList: state.user.list,
    friends: state.friend.friends,
  };
}

export default connect<StateProps, null, OwnProps>(mapState)(SelectMember);
