/**
 * 委外需求订单-选择晶园物料按钮
 */
import React, { memo, useState } from 'react';
import { Button } from 'antd';
import { EnumProcessClass, EnumProductModel, EnumWarehouseType } from '@chipcoo/constant';

import DemandOrderMaterialModal from '../../../DemandOrderMaterialModal';

interface Props {
  classes?: EnumProcessClass;
  productModel: EnumProductModel;
  product: string;
  type: EnumWarehouseType;
  btnText?: string;
  setFieldsValue: (obj: Object) => void;
}

function BlockButton(props: Props) {
  const { productModel, product, type, classes, btnText, setFieldsValue } = props;
  const [visible, setVisible] = useState(false);

  function handleOk(submitData: Obj) {
    setVisible(false);

    setFieldsValue({
      selectedData: submitData.selectedData,
      productModel,
    });
  }

  return (
    <>
      <Button
        block={true}
        icon="plus"
        type="dashed"
        onClick={() => setVisible(true)}
        disabled={!product}
        className="blue-btn-border"
      >
        {btnText || '选择物料'}
      </Button>

      <DemandOrderMaterialModal
        visible={visible}
        classes={classes || EnumProcessClass.assembly}
        productModel={productModel}
        product={product}
        type={type}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}

export default memo(BlockButton);
