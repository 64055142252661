// tslint:disable:max-file-line-count
import * as React from 'react';
import { uniqueId } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { CardTableV2, TableColumnEditor } from '@chipcoo/hanayo';
import { EnumProductModel } from '@chipcoo/constant';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { getColumnConfigProps, IEditableColumnProps } from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';
import { noop } from '@chipcoo/fe-utils';

import { getTableColumns } from './components/getTableColumns';
import { Actions, connectDecorator, RootState } from 'src/store';

import { openProductModal } from './ProductModal/openProductModal';

interface OwnProps {}
interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: GetTableDataParams) => void;
  getTableItemDetail: (id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

interface State {
  category: EnumProductModel;
  tableLoading: boolean;
  detailId: string;
  tableColumns: IEditableColumnProps<any>[];
}

const { EnhancedTable } = CardTableV2;

@connectDecorator(
  ({ product: { list, count, detail } }: RootState) => ({
    list, count, detail
  }),
  ({ product: { getTableList, getTableItemDetail } }: Actions) => ({
    getTableList, getTableItemDetail
  })
)
class ProductTable extends React.PureComponent<Props, State> {
  public tableColumns: IEditableColumnProps<any>[] = [];
  private cardTableController: ICardTableController;
  private tableName = '';
  private modalName = '';

  constructor(props: Props) {
    super(props);

    const category: EnumProductModel =
      (props?.location?.pathname || '').split('production/')?.[1] as EnumProductModel || EnumProductModel.spwDie;

    this.tableName = `${category}_LIST`;
    this.modalName = uniqueId(`${category}_`);

    // this.tableColumns = getTableColumns({
    //   productModel: category,
    //   openModal: this.openEditModal,
    // });

    this.state = {
      category,
      tableLoading: false,
      detailId: '',
      tableColumns: [],
    };
  }

  componentDidMount() {
    this.setColumns();
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  setColumns = () => {
    const { category } = this.state;
    this.tableName = `${category}_LIST`;

    this.tableColumns = getTableColumns({
      productModel: category,
      openModal: this.openEditModal,
    });
    const tableColumns = getColumnConfigProps(this.tableColumns, this.tableName);

    this.setState({ tableColumns });
  }

  fetchTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { category } = this.state;
    const { formData, ...extraParams }: any = params;

    const queryParams = {
      ...extraParams,
      ...formData,
      productModel: category,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  openEditModal = async (detailId: string) => {
    const { getTableItemDetail } = this.props;

    try {
      this.setState({ tableLoading: true });
      await getTableItemDetail(detailId);

      openProductModal({
        autoOpen: true,
        productId: detailId,
        modalName: this.modalName,
        localDetail: this.props.detail,
        onOk: this.reloadTable
      });
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false, detailId });
  }

  getCategoryConfig = () => {
    const { category } = this.state;

    switch (category) {
      case EnumProductModel.dpProduct:
        return {
          title: '磨划成品',
        };
      case EnumProductModel.assemblyProduct:
        return {
          title: '封装成品',
        };
      default:
        return {
          title: 'SPW芯片',
        };
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  reloadTable = () => {
    if (this.cardTableController) {
      this.cardTableController.reloadTable();
    }
  }

  setColumn = (columns: IEditableColumnProps<any>[]) => {
    console.log('received config', columns);

    this.setState({ tableLoading: true });

    // 制造假加载
    setTimeout(() => {
      this.setState({
        tableLoading: false,
        tableColumns: columns,
      });
    }, Math.floor(Math.random() * 800) + 500);
  }

  render () {
    const { list, count } = this.props;
    const {
      tableLoading,
      tableColumns
    } = this.state;

    return (
      <div className="wrap-content">
        <CardTableV2
          card={{
            title: `${this.getCategoryConfig()?.title}列表`,
            extra: (
              <TableColumnEditor
                onSetColumn={this.setColumn}
                tableName={this.tableName}
                tableColumns={this.tableColumns}
              />
            )
          }}
          getCardTableController={this.getCardTableController}
        >
          <EnhancedTable
            dataSource={list}
            total={count}
            columns={tableColumns}
            getTableData={this.fetchTableData}
            clickMention={true}
            tableLoading={tableLoading}
            tableName={this.tableName}
          />
        </CardTableV2>
      </div>
    );
  }
}

export default ProductTable;
