import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import { getUserName } from '@chipcoo/fe-utils';

import store from 'src/store';

// 锁定编辑参数
export type LockParams = {
  name: string;
  id: string;
  role?: string;
  members?: any;
};

export type ProductSearchParams = {
  page?: number;
  limit?: number;
  chipcooName?: string;
  customerName?: string;
  productModel?: EnumProductModel;
  isHVMReady?: boolean;
};

type ARC = AxiosRequestConfig;

/**
 * 打开一个编辑中的产品的时候，如果该产品已被锁定，那么需要详细的提示告知用户如何解锁
 */
export const getEditLockedText = (e) => {
  let msg = '该产品正在被他人编辑，您现在只能查看。';
  const admin = get(e, 'data.err.admin');
  const userId = get(e, 'data.err.user');
  const getMsg = (name: string) => `该产品正在被他人编辑，您现在只能查看；如需编辑，请联系${name}关闭该产品的编辑页面。`;
  // const lockTime = getMomentTime(get(e, 'data.err.lockTime'), 'YYYY-MM-DD HH:mm');

  if (admin) {
    msg = getMsg('芯翼管理员');
    // msg = `官方于${lockTime}锁定该产品，正在帮忙编辑中`;
  }

  // store.
  if (userId) {
    const state = store.getState();
    const name = getUserName(
      state.user.list.byId[userId]
    );

    if (name) {
      msg = getMsg(name);
    }

    // msg = `该产品已被${name}于${lockTime}锁定，自动进入预览模式。如需继续编辑，请联系${name}退出该产品的编辑状态。`;
  }

  return msg;
};

export default function(api: AxiosInstance) {
  return {
    search(params: ProductSearchParams, config: ARC = {}) {
      return api.get('product/product/search', { ...config, params });
    },
    getProductById(id: string, config: ARC = {}) {
      return api.get(`product/product/${id}`, config);
    },
    patchProduct(data: any, config: ARC = {}) {
      return api.patch(`product/product/${data?._id}`, data, config);
    },

    /*************************** 产品订购相关 **********************************/
    searchOrderTemplate(params: any, config: ARC = {}) {
      return api.get('product/order-template/search', { ...config, params });
    },
    getOrderTemplateById(id: string, config: ARC = {}) {
      return api.get(`product/order-template/${id}`, config);
    },

    // 搜索产品制程
    searchProcessByProduct: (productId: string, config: ARC = {}) => {
      return api.get(`product/material/product/${productId}`, config);
    },

    /*************************** 产品锁定/解锁 **********************************/
    // 锁定编辑
    lock(data: LockParams, config?: ARC) {
      const url = data.role
        ? `ryo/lock/acquire/${data.name}/${data.id}/${data.role}`
        : `ryo/lock/acquire/${data.name}/${data.id}`;

      return api.post(url, '', {
        ...config,
        handleSpecialError: {
          // 之前为423的时候会弹出一条message，改为读取抛出的错误直接显示在界面上，这里不进行任何操作，注：有这个函数的时候不会执行默认谈message的操作，因此不能删除
          423: (e) => {
            // const admin = get(e, 'data.err.admin');
            // const userId = get(e, 'data.err.user');
            // const lockTime = getMomentTime(get(e, 'data.err.lockTime'), 'YYYY-MM-DD hh:mm');
            // let msg = '该产品已有人处于编辑状态';
            //
            // if (admin) {
            //   msg = `官方于${lockTime}锁定该产品, 正在帮忙编辑中`;
            // }
            //
            // if (userId) {
            //   const name = getUserName(data.members[userId]);
            //
            //   msg = '\
            //     该产品已被' + name + '于' + lockTime + '锁定，自动进入预览模式。\
            //     如需继续编辑，请联系' + name + '退出该产品的编辑状态。\
            //   ';
            // }
            //
            // message.info(msg);
          }
        }
      });
    },
    // 解除锁定
    unLock(data: LockParams, config?: ARC) {
      const url = data.role
        ? `ryo/lock/release/${data.name}/${data.id}/${data.role}`
        : `ryo/lock/release/${data.name}/${data.id}`;

      return api.post(url, '', config);
    },
    // 申请解锁
    apply(data: LockParams, config?: ARC) {
      const url = data.role
        ? `ryo/lock/apply/${data.name}/${data.id}/${data.role}`
        : `ryo/lock/apply/${data.name}/${data.id}`;

      return api.post(url, '', config);
    },

    // 获取产品制程
    getProcessById(productId: string, config: ARC = {}) {
      return api.get(`product/product-process/product/${productId}`, config);
    },

    /** 混合搜索组织内所有产品 */
    // todo 准备干掉
    searchProductInOrg(params: any, config: ARC = {}) {
      return api.get(`product/product/search`, { ...config, params });
    },

    // 搜索工厂
    searchFactory(params: any, config: ARC = {}) {
      return api.get(`product/factory/search`, { ...config, params });
    },

    // 获取组织里的工厂
    searchFactoryByUser(params: any, config: ARC = {}) {
      return api.get('product/factory/used', { ...config, params });
    },

    /*************************** 订单汇总相关 **********************************/
    // 订单汇总表列表
    searchOrderSummary(params: unknown, config: ARC = {}) {
      return api.get('product/summary/order', { ...config, params });
    },
    // 未交付列表
    searchUnStockOutSummary(params: unknown, config: ARC = {}) {
      return api.get('product/summary/un-stock-out', { ...config, params });
    },
    // 已制未交付列表
    searchManufacturedUnStockOutSummary(params: unknown, config: ARC = {}) {
      return api.get('product/summary/manufactured-un-stock-out', { ...config, params });
    },
  };
}
