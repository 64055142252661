/**
 * 聊天列表的头部
 */
import * as React from 'react';
import { Icon, message } from 'antd';
import { bind } from 'lodash-decorators';

import styles from './index.module.less';
import { ImContext, InitialImCtx } from 'src/pages/IM';
// import AddMember from 'src/containers/MemberTable/AddMember';
import SelectMember from 'src/containers/SelectMemberByGroup';

interface P {
  createRoom: (userId: string[]) => void;
  resetModel: (path?: any) => void;
}
interface S {
  modalVisible: boolean;
  selectedMembers: UserId[];
}

class ListHeader extends React.PureComponent<P, S> {
  state = {
    modalVisible: false,
    selectedMembers: [],
  };

  handleModalSubmit = async (selectedKeys, ctx) => {
    if (selectedKeys.length === 0) {
      message.info('请选择成员');
      
      return false;
    }

    const { setUrl } = ctx;
    const { createRoom } = this.props;

    const result = await createRoom(selectedKeys);

    if (typeof result === 'string') {
      setUrl(result);
    }

    return Boolean(result);
  }

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible: false, selectedMembers: [] });
  }

  @bind
  syncSelectMembers(selectedMembers: UserId[]) {
    this.setState({ selectedMembers });
  }

  renderModal = (ctx: InitialImCtx) => {
    const { modalVisible } = this.state;
    return (
      <SelectMember
        visible={modalVisible}
        modalTitle={'新建聊天'}
        onCancel={this.closeModal}
        onSubmit={(selectedKeys) => this.handleModalSubmit(selectedKeys, ctx)}
        value={this.state.selectedMembers}
        onChange={this.syncSelectMembers}
      />
    );
  }

  render() {
    return (
      <div className={styles.listHeaderWrapper}>
        <div className={styles.addNew} onClick={this.openModal}>
          <Icon type="plus" />
        </div>

        <ImContext.Consumer>{this.renderModal}</ImContext.Consumer>
      </div>
    );
  }
}

export default ListHeader;
