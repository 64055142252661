import { createMapUserSelector, createUserPicker, createMapMemberSelector } from '../user/selectors';
import { OrgMember } from './interface';
import { getNormalizedData } from 'src/utils';
import { createSelector } from 'reselect';
import { OrgDetail } from 'src/pages/Organization';
import { EnumMemberStatus } from '@chipcoo/constant';

export function filterAvailableMember(memberLike: { role: AllRole | null, status?: EnumMemberStatus }) {
  const { status = EnumMemberStatus.normal, role } = memberLike;

  return !!role && status === EnumMemberStatus.normal;
}

/**
 * 将当前组织的用于按照id取出 User[]
 */
export const mapOrgUser = createMapUserSelector();

/**
 * 将当前组织所有(包含离开/禁用)的Member 整理成 OrgUser[]
 */
export const pickOrgUser = createUserPicker<OrgMember>();

/**
 * 将当前组织可供选择(不包含离开/禁用)的Member 整理成 OrgUser[]
 */
export const pickAvailableOrgUser = createSelector(
  pickOrgUser,
  (users) => users.filter(filterAvailableMember)
);

export const pickNormalizedOrgUser = createSelector(
  pickOrgUser,
  getNormalizedData
);

/**
 * 将当前组织所有（包含离开/禁用）的 Member(not user) 整理成 OrgMember[]
 */
export const mapOrgMember = createMapMemberSelector<OrgMember>();

/**
 * 将当前组织可供选择（不包含离开/禁用）的 Member(not user) 整理成 OrgMember[]
 */
export const mapAvailableOrgMember = createSelector(
  mapOrgMember,
  (orgMembers) => {
    return orgMembers.filter(filterAvailableMember);
  }
);
export const mapOrgList = createSelector(
  (orgList: NormalizedData<OrgDetail>) => orgList.allIds,
  (orgList: NormalizedData<OrgDetail>) => orgList.byId,
  (allIds, byId) => allIds.map(id => byId[id])
);
