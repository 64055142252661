import React, { PureComponent } from 'react';
import { Menu } from 'antd';
import { isEqual, uniq, isEmpty } from 'lodash';
import { bind } from 'lodash-decorators';
import { Link, withRouter } from 'react-router-dom';

import './style.less';
import { RouteMenuProps, RouteLink, RouteSubMenuParent, RouteMenuLink } from './interface';
import { parseMenu, getOpenKeys, getDefaultSelectKey, getSelectedKey, getDefaultOpenKeys } from './parse-link';

interface Props<T> extends RouteMenuProps<T> {
  openOneOnly?: boolean;
}

interface State {
  pathname: string;
  params: any;
  context: any;
  menu: any;
  links: RouteLink[];
  openKeys: string[];
  selectedKeys: string[];
}

const { SubMenu, Item: MenuItem } = Menu;

class RouteMenu<T extends any = any> extends PureComponent<Props<T>, State> {
  static getDerivedStateFromProps(nextProps: Props<any>, prevState: State) {
    const nextState: Partial<State> = {};
    const { menu, match: { params }, location: { pathname }, context } = nextProps;

    if (nextProps.forceUpdate) {
      nextState.links = parseMenu(menu, pathname, params, context);
    }

    if (
      !isEqual(params, prevState.params)
      || !isEqual(menu, prevState.menu)
      || pathname !== prevState.pathname
      || context !== prevState.context
    ) {
      //
      nextState.context = context;
      nextState.pathname = pathname;
      nextState.menu = menu;
      nextState.params = params;
      nextState.links = parseMenu(menu, pathname, params, context);
    }

    if (nextState.links) {
      //
      const openKeys = getOpenKeys(nextState.links, pathname);
      if (nextProps.openOneOnly) {
        nextState.openKeys = openKeys;
      } else {
        nextState.openKeys = uniq(prevState.openKeys.concat(openKeys));
      }
      nextState.selectedKeys = [getSelectedKey(nextState.links, pathname)];
    }

    // if (nextProps.openOneOnly) {
    //   nextState.openKeys = (nextState.openKeys || prevState.openKeys).slice(0, 1);
    // }

    return nextState;
  }
  // 当前选中的路径
  private readonly activePath: string;
  // 默认需要选中的路径
  private readonly defaultActivePath: string | undefined;

  constructor(props: RouteMenuProps<T>) {
    super(props);

    const { menu, match: { params }, location: { pathname }, context } = this.props;
    const links = parseMenu(menu, pathname, params, context);

    // 当前需要选中的Menu.item
    this.activePath = getSelectedKey(links, pathname);
    this.defaultActivePath = getDefaultSelectKey(links);

    const selectedKey = this.activePath || this.defaultActivePath;

    // 当前需要展开的subMenu
    let path = pathname;

    if (!this.activePath) {
      path = this.defaultActivePath || '';
    }

    const openKeys = getOpenKeys(links, path);
    const defaultOpenKeys = getDefaultOpenKeys(links);

    this.state = {
      pathname,
      params,
      links,
      context,
      menu,
      selectedKeys: selectedKey ? [selectedKey] : [],
      openKeys: isEmpty(openKeys) ? defaultOpenKeys : openKeys,
    };
  }

  componentDidMount(): void {
    if (!this.activePath && this.defaultActivePath) {
      const { history } = this.props;

      history.replace(this.defaultActivePath);
    }
  }

  @bind
  handleOpenChange(keys: string[]) {
    if (!this.props.openOneOnly) {
      //
      this.setState({ openKeys: keys });
      return;
    }
    const { openKeys } = this.state;
    this.setState({ openKeys: keys.filter(key => openKeys.indexOf(key) === -1) });
  }

  renderMenuItem(item: RouteMenuLink) {
    const { path, title } = item;

    return (
      <MenuItem key={path}>
        <Link to={path}>
          {title}
        </Link>
      </MenuItem>
    );
  }

  renderSubMenu(subMenu: RouteSubMenuParent) {
    return (
      <SubMenu key={subMenu.title} title={subMenu.title} >
        {this.renderLink(subMenu.children)}
      </SubMenu>
    );
  }

  renderLink(links: RouteLink[]) {
    return links.map(link => 'children' in link ? this.renderSubMenu(link) : this.renderMenuItem(link));
  }

  render() {
    const { className } = this.props;
    const { links, selectedKeys, openKeys } = this.state;

    return (
      <Menu
        className={`${className} route-menu y-scroll`}
        mode="inline"
        onOpenChange={this.handleOpenChange}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
      >
        {this.renderLink(links)}
      </Menu>
    );
  }
}

export default withRouter(RouteMenu);
