/**
 * 一个table展示的组件包
 */
import React, { PureComponent } from 'react';
import produce from 'immer';
import { isObject, cloneDeep, has } from 'lodash';

import {
  StandardTableProps as Props,
  StandardTableState as State,
  SetQueryCondition,
  OpenModalParams,
  ICtx,
} from './types';
import Ctx from './createContext';

import Card from './Card/index';
import Table from './Table';
import OperateModal from './OperateModal';

class StandardTable extends PureComponent<Props, State> {
  static Card: typeof Card;
  static Table: typeof Table;
  static Modal: typeof OperateModal;

  static defaultProps = {
    queryMode: 'primary',
    pageSizeLimit: 10,
    initialLoad: true,
  };

  private initialCtx: ICtx;

  constructor(props: Props) {
    super(props);

    const { pageSizeLimit, fetchData, queryMode, initialLoad } = props;

    this.initialCtx = {
      fetchData,
      queryMode: queryMode!,
      pageSizeLimit: pageSizeLimit!,
      activeModalKey: void 0,
      activeModalPayload: void 0,
      queryCondition: { page: 1, limit: pageSizeLimit },
      setQueryCondition: this.setQueryCondition,
      initialLoad: initialLoad!,
      resetCtx: this.resetCtx
    };

    this.state = {
      ctxVal: cloneDeep(this.initialCtx)
    };
  }

  setQueryCondition = (condition: SetQueryCondition) => {
    const { pageSizeLimit } = this.props;
    const { queryCondition } = this.state.ctxVal;
    const { page, ...passThoughCondition } = condition;

    this.setState(produce(this.state, draftState => {
      draftState.ctxVal.queryCondition = {
        ...passThoughCondition,
        page: page === void 0 ? queryCondition.page : page,
        limit: pageSizeLimit
      };
    }));
  }

  // 重新 reload 整个 table，用来给编辑了某个列表项并关闭弹框后，刷新累彪
  reloadTable = () => {
    const { queryCondition } = this.state.ctxVal;

    this.setQueryCondition({...queryCondition});
  }

  // 根据对应的key值打开对应的弹框，payload的数据会作为modalContent的childProps传入
  openModal = (modalInfo?: OpenModalParams) => {
    let modalKey: string | undefined = void 0;
    let modalPayload: object | undefined = void 0;

    if (typeof modalInfo === 'string') {
      modalKey = modalInfo;
    } else if (isObject(modalInfo)) {
      modalKey = modalInfo!.modalKey;
      modalPayload = modalInfo!.payload;
    }

    this.setState(produce(this.state, draftState => {
      const { ctxVal } = draftState;

      ctxVal.activeModalKey = modalKey;
      ctxVal.activeModalPayload = modalPayload;
    }));
  }

  /**
   * 重置state上的ctxVal上的某个值
   * @param path
   */
  resetCtx = (path?: string | string[]) => {
    const resetKeys = !path ? Object.keys(this.state.ctxVal) : (Array.isArray(path) ? path : [path]);

    this.setState(produce(this.state, draftState => {
      const { ctxVal } = draftState;

      resetKeys.forEach(key => {
        has(ctxVal, key) && (ctxVal[key] = this.initialCtx[key]);
      });
    }));
  }

  render() {
    const { ctxVal } = this.state;
    const { children } = this.props;

    return (
      <Ctx.Provider value={ctxVal}>
        {children({
          openModal: this.openModal,
          reloadTable: this.reloadTable,
        })}
      </Ctx.Provider>
    );
  }
}

export default StandardTable;
