import React, { FC, useState, ComponentType } from 'react';
import _ from 'lodash';
import { CardTableV2 } from '@chipcoo/hanayo';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTable/Addon/EnhancedTable';

import { getTableColumns } from './getTableColumns';
import { RootDispatch, connect, RootState } from 'src/store';

const { EnhancedTable } = CardTableV2;

interface OwnProps {
  queryCondition?: Obj;
}

interface StateProps {
  manuFacturedList: any;
  manuFacturedCount: number;
}

interface DispatchProps {
  getManuFacturedUnStockOutSummaryList: (params: any) => void;
}

interface IState {
  tableLoading: boolean;
}

type IProps = OwnProps & StateProps & DispatchProps;

const Manufacture: FC<IProps> = props => {
  const { manuFacturedCount, manuFacturedList, getManuFacturedUnStockOutSummaryList, queryCondition } = props;

  const [state, setState] = useState<IState>({
    tableLoading: false,
  });
  const { } = state;

  const getTableData = async (params: GetTableDataParams) => {
    const { formData, ...restData } = params;

    const queryParams = {
      ...queryCondition,
      ...restData,
      ...formData,
    };

    try {
      setState(prevState => ({ ...prevState, tableLoading: true }));

      await getManuFacturedUnStockOutSummaryList(queryParams);
    } catch (e) {
      console.error(e);
    }

    setState(prevState => ({ ...prevState, tableLoading: false }));
  };

  const getScrollY = () => {
    const chipwingModal = document.querySelector('.order-summary-modal .chipwing-modal-body');

    const height = _.get(chipwingModal, 'offsetHeight');

    if (!height) return 700;

    return height - 16 * 2 - 57 - 16 * 3 - 25 - 16 * 2 - 92;
  };

  return (
    <CardTableV2
      card={{ title: '产品列表' }}
    >
      <EnhancedTable 
        columns={getTableColumns({})}
        total={manuFacturedCount}
        dataSource={manuFacturedList}
        bordered={true}
        getTableData={getTableData}
        scroll={{ y: getScrollY() }}
      />
    </CardTableV2>
  );
};

const mapState = (
  { orderSummary: {
    manuFacturedList,
    manuFacturedCount
  } }: RootState
) => ({
  manuFacturedList,
  manuFacturedCount,
});

const mapDispatch = (
  {
    orderSummary: {
      getManuFacturedUnStockOutSummaryList
    }
  }: RootDispatch
) => ({
  getManuFacturedUnStockOutSummaryList
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapState,
  mapDispatch,
)(Manufacture) as ComponentType<OwnProps>;
