import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import { QuickSelectUser } from 'src/components';
import { OrgUser } from 'src/models/org/interface';
import { RootState } from 'src/store';
import { pickOrgUser } from 'src/models/org';
import { isEqualUserId } from 'src/utils/user';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumMemberStatus } from '@chipcoo/constant';

export { createRenderParticipants } from './participants';

interface OwnProps {
  status: FormStatus;
  principal: string | undefined;
  value?: any;
  onChange?: any;
  members: Member[];
  fetchMembers?: () => any;
}

interface StateProps {
  userId: string;
  orgUsers: OrgUser[];
}

interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

interface States {}

class Principal extends PureComponent<Props, States> {
  private _principal: string | undefined;

  constructor(props: Props) {
    super(props);

    this._principal = props.principal;
  }

  componentDidMount() {
    // 暂时先每次打开编辑都先获取一次
    const { fetchMembers } = this.props;
    this.isEdit && fetchMembers && fetchMembers();
  }

  get principal() {
    const { userId, principal } = this.props;
    return this._principal || principal || userId;
  }

  get isEdit() {
    return this.props.status === 'edit';
  }

  get canEdit() {
    const { userId, members } = this.props;

    return !this._principal
      || isEqualUserId(this._principal, userId)
      || members.some(it => isEqualUserId(userId, it.user));
  }

  @bind
  canSelect(user: OrgUser) {
    const { role, status } = user;
    return !!role || (status === EnumMemberStatus.normal);
  }

  @bind
  isUserDisabled(user: OrgUser) {
    return !this.canSelect(user);
  }

  render() {
    const { orgUsers, userId, value, onChange } = this.props;
    return (
      <QuickSelectUser
        mode="single"
        value={value}
        onChange={onChange}
        defaultValue={userId}
        users={orgUsers}
        canSelect={this.canSelect}
        showInList={this.canSelect}
        userDisabled={this.isUserDisabled}
        placeholder="选择负责人"
        canEdit={this.isEdit && this.canEdit}
        displayName={getUserNameEnhance}
        canRemove={(id) => !isEqualUserId(id, this.principal)}
      />
    );
  }
}

function mapState(state: RootState) {
  const { org: { orgMembers }, user, session: { profile } } = state;
  const orgUsers = pickOrgUser(user, orgMembers.byId) as OrgUser[];
  return {
    userId: profile!._id.toString(),
    orgUsers,
  };
}

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(Principal);
