function clone(source: Obj) {
  return Object.assign(Object.create(Object.getPrototypeOf(source)), source);
}

/**
 * https://github.com/alibaba/GGEditor/issues/371
 * @override 覆盖当前页面默认的 dragEdgeBeforeShowAnchor, 节点也可以连接组了
 * @param {*} page 当前页面
 */
export function overrideDragEdgeBeforeShowAnchor(page: any) {
  const handler = page.dragEdgeBeforeShowAnchor;
  const nodeHandler = handler.bind(page);

  // 重写 getGraph 方法，重新代理 graph 中的 getNodes 方法直接指向到 getGroups
  const clonePage = clone(page);
  clonePage.getGraph = () => {
    const graph = page.getGraph();
    return {
      getNodes() {
        const groups = graph.getGroups();
        return groups;
      },
    };
  };

  const groupHandler = handler.bind(clonePage);

  page.dragEdgeBeforeShowAnchor = function (...args: any[]) {
    const [sourceNode, ...extra] = args;

    // 如果是 group 的话，先将 group 克隆一份然后将 group.isNode 设为 true
    let source = sourceNode;
    if (source.type === 'group') {
      source = Object.assign(clone(sourceNode), { isNode: true });
    }

    nodeHandler(source, ...extra);
    groupHandler(source, ...extra);
  };
}

export function setOrientedEdge(page: any) {
  // https://github.com/alibaba/GGEditor/issues/159
  page.on('dragedge:beforeshowanchor', (ev) => {
    // // 只允许目标锚点是输入，源锚点是输出，才能连接
    // if (!(ev.targetAnchor.type === INPUT_ANCHOR && ev.sourceAnchor.type === OUTPUT_ANCHOR)) {
    //   ev.cancel = true;
    // }
    // 相同的锚点类型
    const isSameAnchorType = ev.targetAnchor.type === ev.sourceAnchor.type;
    // 相同的节点
    const isSameNode = ev.target === ev.source;

    const isAnchorLinked = page.anchorHasBeenLinked(ev.target, ev.targetAnchor);

    ev.cancel = isSameAnchorType || isSameNode || isAnchorLinked;
  });
}

export function hoverShowAnchor(page: any) {
  // 当 group 鼠标悬浮时 时显示锚点
  page.on('beforeitemactived', (ev) => {
    const node = ev.item;
    if (node.isGroup) {
      page.hoverShowAnchor(node);
    }
  });
}
