// 用以缓存表单数据
import React from 'react';

export interface IDemandOrderFormModalCtx {
  stashData: Obj;
}

export const DemandOrderFormModalCtx = React.createContext<IDemandOrderFormModalCtx>({
  stashData: {},
});
