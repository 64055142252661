/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { Form, Button, Input, message as AntdMessage } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import log from 'loglevel';

import { forOwn } from 'lodash';

import { RootDispatch, RootState } from 'src/store';
import styles from './index.module.less';
import { PasswordParams } from 'src/models/session';

interface OwnProps {
  formItemLayout?: object;
  submitFormLayout?: object;
}
interface StateProps {
  profile: User | undefined;
}
interface DispatchProps {
  modifyPass: (val: PasswordParams) => void;
  getProfile: () => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  isEditing: boolean;
}
const FormItem = Form.Item;
const regRex = /^(?![^a-zA-Z]+$)(?!\D+$).{6,20}$/;

class Password extends React.PureComponent<Props, State> {
  private passwordForm;
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false
    };

    this.passwordForm = [
      {
        label: '旧密码',
        id: 'oldPassword',
        placeholder: '旧密码',
        isRequired: true,
        message: '请输入旧密码',
        min: 6,
        max: 20,
        extraMessage: '请输入正确的旧密码！',
        autoFocus: true
      },
      {
        label: '新密码',
        id: 'newPassword',
        placeholder: '新密码',
        isRequired: true,
        message: '请输入新密码',
        validator: this.checkPassword
      },
      {
        label: '确认新密码',
        id: 'confirmPassword',
        placeholder: '确认密码',
        isRequired: true,
        message: '请再次输入密码',
        validator: this.checkConfirmPassword
      }
    ];
  }

  checkPassword = (rule, value, callback) => {
    if (!value) {
      callback();
    } else if (!value.match(regRex)) {
      callback('必须包含字母和数字，6~20字符！');
    } else {
      callback();
    }
  }

  // 验证确认密码与新密码是否一致
  checkConfirmPassword = (rule, value, callback) => {
    const form = this.props.form;

    if (value && value !== form.getFieldValue('newPassword')) {
      callback('两次密码输入不一致!');
    } else {
      callback();
    }
  }

  // 提交修改密码的表单
  handleSubmitPasswd = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { modifyPass, getProfile } = this.props;
        let value: any = {};

        forOwn(values, (val, key) => {
          value[key] = val && val.trim();
        });

        try {
          await modifyPass(value);
          AntdMessage.success('密码修改成功');
          await getProfile();
          this.setState({isEditing: false});
        } catch (e) {
          log.error(e);
          AntdMessage.error('密码修改失败');
        }
      }
    });
  }

  render () {
    const { form, formItemLayout, submitFormLayout, profile } = this.props;
    const { isEditing } = this.state;
    const { getFieldDecorator } = form;
    const hasPassword = profile?.hasPassword;

    return (
      <div>
        <h3>登录密码</h3>
        <div className={styles.introduction}>
          密码必须包含字母和数字，区分大小写，6~20字符。建议定期更换密码，确保账号安全。
        </div>

        <div className={styles.operation}>
          {
            isEditing
              ? (
                <Form className={styles.form} onSubmit={this.handleSubmitPasswd}>
                  {this.passwordForm.map((item) => {
                    const {
                      label,
                      id,
                      isRequired,
                      placeholder,
                      message,
                      min,
                      max,
                      extraMessage,
                      validator,
                      autoFocus
                    } = item;
                    let required = isRequired;

                    if (id === 'oldPassword') {
                      required = hasPassword;
                    }

                    return (
                      <FormItem key={id} label={label} {...formItemLayout}>
                        {getFieldDecorator(id as string, {
                          rules: [
                            { required: required, message: message },
                            { min, max, message: extraMessage },
                            { validator: validator }
                          ]
                        })(
                          <Input autoFocus={autoFocus} type="password" placeholder={placeholder} />
                        )}
                      </FormItem>
                    );
                  })}
                  <FormItem {...submitFormLayout}>
                    <Button type="primary" htmlType="submit">确定</Button>
                    <Button style={{marginLeft: 8}} onClick={() => this.setState({isEditing: false})}>
                      取消
                    </Button>
                  </FormItem>
                </Form>
              )
              : (
                <Button type="primary" onClick={() => this.setState({isEditing: true})}>
                  {hasPassword ? '修改密码' : '设置密码'}
                </Button>
              )
          }
        </div>
      </div>
    );
  }
}

const mapState = ({ session: { profile } }: RootState) => ({
  profile
});

const mapDispatch = ({ session: { patchPassword, getProfile } }: RootDispatch) => ({
  modifyPass: patchPassword,
  getProfile,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(Password));
