import React, { FC, useState, useEffect } from 'react';
import { get } from 'lodash';
import { SelectWithOption } from '@chipcoo/hanayo';
import { sortWaferId } from '@chipcoo/fe-utils';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  analytiCalData: any[];
  setFieldsValue?: (obj: Obj) => void;
  reMapping: boolean;
}

interface State {
  options: any[];
}

const AnalytiCalSolution: FC<Props> = props => {
  const { value, analytiCalData = [], setFieldsValue, onChange, reMapping } = props;
  const [state, setState] = useState<State>({
    options: []
  });
  const { options } = state;

  useEffect(
    () => {
      let newValue = value ? value : 0;

      onChange && onChange(newValue);
    },
    []
  );

  useEffect(() => {
    setState({
      options: analytiCalData.map((it, index) => ({ label: `解析方案${index + 1}`, value: index }))
    });
  }, [analytiCalData]);

  useEffect(() => {
    const currentAnalyticalData = analytiCalData?.[value];
    if (currentAnalyticalData && reMapping) {
      const dieNumber = get(currentAnalyticalData, 'dieNumber');
      const waferIdArray = currentAnalyticalData?.data?.map(it => it.waferId);

      setFieldsValue && setFieldsValue({dieNumber, waferIdArray: sortWaferId(waferIdArray)});
    }
  }, [value, analytiCalData]);

  return (
    <SelectWithOption
      {...props}
      options={options}
    />
  );
};

export default AnalytiCalSolution;
