import * as React from 'react';
import {
  FormConfig,
  FormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import SelectedTable from './SelectedTable';

export const getFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '已选择需求单' },
      groupItemConfig: [
        {
          type: 'custom',
          dataKey: 'selectTable',
          colSpan: 24,
          render() {
            return <SelectedTable />;
          }
        }
      ] as FormItemConfig[]
    }
  ]
});
