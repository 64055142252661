import { AxiosInstance, AxiosRequestConfig } from 'axios';

export enum NpiStep {
  // 初始化状态
  init = 'INIT',
  // 风险评估
  riskEvaluation = 'RISK_EVALUATION',
  // 风险试封
  engineerEvaluation = 'ENGINEER_EVALUATION',
  // 正式试封
  formalRun = 'FORMAL_RUN',
  // 已通过
  pass = 'PASS',
  // 已停止
  stop = 'STOP',
}

export enum NpiReviewResult {
  approve = 'APPROVE',
  reject = 'REJECT',
  takeRisk = 'TAKE_RISK',
}

export enum NpiProgressStatus {
  onGoing = 'ON_GOING',
  pass = 'PASS',
  fail = 'FAIL',
  riskRun = 'RISK_RUN',
  stop = 'STOP',
}

export enum NpiWaitSide {
  waitCustomer = 'WAIT_CUSTOMER',
  waitAdmin = 'WAIT_ADMIN',
}

export const NpiProgressStatusText = {
  [NpiProgressStatus.onGoing]: 'On Going',
  [NpiProgressStatus.pass]: 'Pass',
  [NpiProgressStatus.fail]: 'Fail',
  [NpiProgressStatus.riskRun]: 'Risk Run',
  [NpiProgressStatus.stop]: 'Stop',
};

export const NpiStepText = {
  [NpiStep.init]: '等待审核',
  [NpiStep.riskEvaluation]: '风险评估',
  [NpiStep.engineerEvaluation]: '风险试生产',
  [NpiStep.formalRun]: '正式试生产',
};

export const NpiStatusText = {
  ...NpiProgressStatusText,
  [NpiStep.init]: '',
  [NpiWaitSide.waitAdmin]: '等待管理员处理',
  [NpiWaitSide.waitCustomer]: '等待用户处理',
};

export interface NpiData {
  _id: string;
}

export interface NpiPatchData extends NpiData {
  step: NpiStep;
  data: object;
  factory?: string;
  isEngineerEvaluationNeeded?: boolean;
}

export interface BasicPagiParams {
  page?: number;
  limit?: number;
}

export type ConfirmDiagramData = {
  diagramName: string;
  isConfirmed: boolean;
  npiId: string;
  rejectReason?: string;
};

export default function(api: AxiosInstance) {
  return {
    search(params: BasicPagiParams, config: AxiosRequestConfig = {}) {
      return api.get('rnd/npi/search', { ...config, params });
    },
    updateNpi(patchData: NpiPatchData, config?: AxiosRequestConfig) {
      const { _id, step, ...extra } = patchData;

      return api.patch(`rnd/npi/${_id}/${step}`, extra, config);
    },
    fetchNpiDetail(id: string, config?: AxiosRequestConfig) {
      return api.get(`rnd/npi/${id}`, config);
    },

    // 图纸确认
    confirmDiagram(data: ConfirmDiagramData, config?: AxiosRequestConfig) {
      const { npiId, ...rest } = data;

      return api.patch(`rnd/npi/${npiId}/diagram-confirm`, rest, {...config});
    },
  };
}
