/**
 * 聊天面板的头部
 */
import * as React from 'react';
import { Icon } from 'antd';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { Room, PrivateRoom } from 'src/models/im/chatRoomList.type';
// import groupAvatar from 'src/assets/images/group-avatar.png';

// import { EnhanceAvatar } from 'src/components';
import ChatGroupDrawer from 'src/pages/IM/ChatGroupDrawer';

interface P {
  roomInfo: Room;
  memberNum: number;
  drawerContainerSelector: string;
  allUsers: { [id: string]: User };
}
interface S {}

class PanelHeader extends React.PureComponent<P, S> {
  private chatGroupControlPanelToggle: (toggle: boolean) => void;

  getToggleFn = (fn) => this.chatGroupControlPanelToggle = fn;

  showDrawer = () => this.chatGroupControlPanelToggle(true);

  renderPrivateHeader = () => {
    const { roomInfo, allUsers } = this.props;
    const { user: { _id } } = roomInfo! as PrivateRoom;
    const displayUserName = getUserNameEnhance(allUsers[_id]);

    return (
      <div className={styles.privateHeaderWrapper}>
        {/*<EnhanceAvatar userName={nickname} src={avatar} size="small" />*/}
        <p className="name">{displayUserName}</p>
      </div>
    );
  }

  renderGroupHeader = () => {
    const { roomInfo, drawerContainerSelector, memberNum } = this.props;
    const { name } = roomInfo!;

    return (
      <div className={styles.groupHeaderWrapper}>
        <div className={styles.infoWrapper}>
          {/*<EnhanceAvatar userName={name} src={groupAvatar} size="small" />*/}
          <div className="info">
            <p className="name" onClick={this.showDrawer}>{name}</p>
            <p className="count">({memberNum})</p>
          </div>
        </div>

        <Icon type="ellipsis" theme="outlined" onClick={this.showDrawer} />

        <ChatGroupDrawer containerSelector={drawerContainerSelector} getToggleFn={this.getToggleFn} />
      </div>
    );
  }

  renderContent = () => {
    const { roomInfo } = this.props;

    if (!roomInfo) { return null; }

    const { type } = roomInfo;

    return type === 'PRIVATE' ? this.renderPrivateHeader() : this.renderGroupHeader();
  }

  render() {
    return (
      <div className={styles.panelHeaderWrapper}>
        {this.renderContent()}
      </div>
    );
  }
}

export default PanelHeader;
