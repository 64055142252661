/**
 * 房间列表卡片
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { getClosetAttrVal, stopPropagation, getUserName } from '@chipcoo/fe-utils';

import styles from './index.module.less';
// import store, { RootState, RootDispatch } from 'src/store';
import { RootState, RootDispatch } from 'src/store';
import { getMessageText } from 'src/pages/IM/util';
import { Room, RoomTop, GroupRoom, PrivateRoom } from 'src/models/im/chatRoomList.type';
import groupAvatar from 'src/assets/images/group-avatar.png';

import { UserName } from 'src/containers';
import RoomCardMessage from './RoomCardMessage';
import { EnhanceAvatar, TransparentEllipsis } from 'src/components';
import { findNameById, getEnhancedAvatar } from 'src/utils/user';
// import { SubscribeResult } from 'src/services/socket';
// import { syncSocket } from 'src/socket';
// import { getCurrentRoute } from 'src/components/HoneyRouter';

interface OwnProps {
  roomId: string;
  isFocus: boolean;
  setUrl: (roomPath?: string) => void;
}
interface StateProps {
  roomData: Room;
}
interface DispatchProps {
  removeRoom: (roomId: string) => Promise<boolean>;
  modifyRoomTop: (val: RoomTop) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

const defaultUser = {
  nickname: 'x',
  _id: 'unique'
};

class RoomCard extends React.PureComponent<P, S> {
  handleOpenChatPanel = (e) => {
    const roomId = getClosetAttrVal(e.currentTarget, 'data-id');
    const { setUrl } = this.props;

    setUrl(roomId);
  }

  handleDelRoom = async (e) => {
    const { removeRoom, setUrl } = this.props;
    const roomId = getClosetAttrVal(e.currentTarget, 'data-id');

    const result = await removeRoom(roomId!);

    result && setUrl();
  }

  // 置顶或取消置顶房间
  handleRoomTop = () => {
    const { roomData, modifyRoomTop } = this.props;

    modifyRoomTop({ roomId: roomData._id, isTop: roomData.isTop });
  }

  render() {
    const { roomData, isFocus } = this.props;
    const { _id, type, lastMessage, unread } = roomData;
    const privateUser = (roomData as PrivateRoom).user! || defaultUser;
    const [ name, avatar ] = type === 'GROUP'
      ? [roomData.name, groupAvatar] : [getUserName(privateUser)!, privateUser.avatar];
    const src = type === 'GROUP' ? avatar : getEnhancedAvatar(privateUser);

    let messageName = '';
    let createdTime: string | undefined = void 0;

    if (lastMessage) {
      // Fixme: 更改掉这蛋疼的找名字方式
      messageName = findNameById(
        (roomData as GroupRoom).users || [], (lastMessage.sender._id || lastMessage.sender) as any
      );

      if (!messageName) {
        messageName = lastMessage.sender.nickname;
      }

      createdTime = moment(lastMessage.createdAt).calendar();
    }

    const message = getMessageText(messageName, lastMessage as any);

    return (
      <div
        className={classnames(styles.roomCardWrapper, {active: isFocus, top: roomData.isTop })}
        data-id={_id}
        onClick={this.handleOpenChatPanel}
      >
        <div className={styles.roomContent}>
          <EnhanceAvatar userName={name} src={src} />

          <div className={styles.cardContentWrap}>
            <div className={styles.cardHeader}>
              <p className="name flex-fill ellipsis">
                {
                  type === 'GROUP'
                    ? name
                    : <UserName userId={privateUser._id} defaultUserName={name} />
                }
              </p>

              <div className="extra flex-static">
                <Tooltip title="删除聊天" mouseEnterDelay={0.5}>
                  <div className={styles.messageHandler} onClick={stopPropagation}>
                    <i className="hover-visible c-icon icon-trash" onClick={this.handleDelRoom} />
                  </div>
                </Tooltip>
                <Tooltip title={roomData.isTop ? '取消置顶' : '置顶'} mouseEnterDelay={0.5}>
                  <div className={styles.messageHandler} onClick={stopPropagation}>
                    <i
                      onClick={this.handleRoomTop}
                      className={`hover-visible c-icon ${roomData.isTop ? 'icon-bottom' : 'icon-stick'}`}
                    />
                  </div>
                </Tooltip>

                <p className="time">{createdTime}</p>
              </div>
            </div>

            <div className={styles.cardContent}>
              <TransparentEllipsis
                text={<RoomCardMessage lastMessage={lastMessage} defaultMessage={message} roomData={roomData} />}
                className={styles.transparentEllipsis}
              />

              {Boolean(unread) && <div className="unread flex-static">{unread}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = ({ chatRoomList: { roomList: { byId } } }: RootState, props: P) => ({
  // Fixme: 不使用roomData中的用户数据
  roomData: byId[props.roomId]
});
const mapDispatch = ({ chatRoomList }: RootDispatch) => ({
  removeRoom: chatRoomList.deleteRoom,
  modifyRoomTop: chatRoomList.patchRoomTop
});

// Fix me: type define
export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch as any)(RoomCard);
