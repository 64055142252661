import React from 'react';
// import { notification } from 'antd';

import { WebPushProps } from '../';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { delayRemove, createKey } from 'src/containers/delayRemove';
import { isEqualUserId } from 'src/utils/user';
import { NAMESPACE } from 'src/config/const';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { Workspace } from 'src/models/workspace/interface';

export function handleWorkspaceMember(props: WebPushProps, data: any) {
  const { curUserId, dispatch } = props;
  const { payload } = data;
  const { event, workspaceId, organization } = payload;

  let members: UserId[] | Member[] = [];

  // 推送数据格式修正
  if (event === 'leave') {
    members = payload.userId;
  } else {
    members = payload.members;
  }

  const { location: { pathname }, match: { params } } = getCurrentRoute()!;

  const nextParams = { id: '', ...params, workspaceId };
  const { workspaceById, orgId } = props;

  const isMe = (members as any).some(user => isEqualUserId(user.user || user, curUserId));
  delayRemove({
    key: createKey(NAMESPACE.WORKSPACE, event, workspaceId),
    handler: () => {
      dispatch.workspace.handleSyncMember({ ...payload, members });
      if (isMe) {
        if (event === 'join' && orgId === organization) {
          dispatch.workspace.fetch({ orgId: organization });
        }
        if (event === 'leave') {
          (dispatch.workspace.removeWorkspace(workspaceId) as any).then(cb => cb());
        }
      }
    },
    executeDirectly: () => {
      const detailPath = compilePath({
        name: ROUTE_NAMES.ORG_COO_DETAIL,
        params: nextParams,
      });

      return !(isMe && event === 'leave') || pathname.indexOf(detailPath) === -1;
    },
    modalContent() {
      const { name } = workspaceById[workspaceId];

      return <div>您已离开工作区『{name}』。</div>;
    },
    nextRoute: {
      name: ROUTE_NAMES.ORG_COO_LIST,
      params: nextParams,
    },
  });
}

export function handleWorkspace(props: WebPushProps, data: any) {
  const { payload } = data;
  const { workspace, event } = payload;
  const { _id: workspaceId } = workspace as Workspace;

  const { workspaceById, dispatch } = props;
  const { location: { pathname }, match: { params } } = getCurrentRoute()!;

  const nextParams = { id: '', ...params, workspaceId };
  const noModal = event !== 'archive' && event !== 'dissolve';

  // if (noModal) {
  //   //
  //   let message = '';
  //   const {  } = workspaceById[workspaceId];
  //   switch (event) {
  //     case 'create':
  //       message = ``;
  //       break;
  //     case 'update':
  //       const { name: newName } = workspace;
  //       const prevWorkspace = workspaceById[workspaceId];
  //       if (prevWorkspace) {

  //       }
  //       break;
  //     case 'transfer':

  //     default:
  //   }
  //   notification.info({
  //    message,
  //   });
  // }

  delayRemove({
    key: createKey(NAMESPACE.WORKSPACE, event, workspaceId),
    handler: () => dispatch.workspace.handleSyncWorkspace(payload),
    executeDirectly: () => {
      // 新建/更新/移交直接执行
      if (noModal) {
        return true;
      }

      const detailPath = compilePath({
        name: ROUTE_NAMES.ORG_COO_DETAIL,
        params: nextParams,
      });

      return pathname.indexOf(detailPath) === -1;
    },
    modalContent() {
      const { name } = workspaceById[workspaceId];
      let content = `您已离开工作区『${name}』。`;
      if (event === 'archive') {
        content = `工作区『${name}』已归档。`;
      }
      return <div>{content}</div>;
    }
  });
}
