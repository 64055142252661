import { getMomentTime } from '@chipcoo/fe-utils';

import { reservingDecimal } from 'src/utils';
import { EnumContentTags } from 'src/pages/QCManage/Analysis/TrendAnalysis/components/TrendAnalysisModal/context';

export const handleList = (payload: any[] = []) => {
  return payload?.map(it => {
    const { updatedAt, dieName, lastTestedAt } = it;

    return {
      ...it,
      updatedAt: getMomentTime(updatedAt, 'YYYY-MM-DD HH:mm:ss'),
      lastTestedAt: getMomentTime(lastTestedAt, 'YYYY-MM-DD HH:mm:ss'),
      yield: it?.yield ? reservingDecimal(it?.yield * 100, 3) + '%' : it?.yield,
      dieName: dieName?.customerName ? dieName?.customerName + '(' + dieName?.chipcooName + ')' : undefined
    };
  });
};

const handleCommonDetail = (payload: Obj, filters?: Obj): Obj => {
  const { startAt, endAt, groupBy } = payload;
  const dates = [startAt, endAt];
  const originFilter = {
    dates,
    groupBy
  };

  return {
    ...payload,
    filters: filters ?? originFilter
  };
};

export const handleDetail = (payload: Obj, filters?: Obj) => {
  const formData = handleCommonDetail(payload, filters);

  return {
    ...formData,
    content: EnumContentTags.overview,
  };
};

// 测试项趋势详情数据
export const handleTestDetail = (payload: Obj, filters?: Obj) => {
  const formData = handleCommonDetail(payload, filters);

  return {
    ...formData,
    content: EnumContentTags.tendency,
  };
};
