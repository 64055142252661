import { get } from 'lodash';
import { createSelector } from 'reselect';
import { isEqualUserId } from 'src/utils/user';

interface Props {
  detail: any;
}

type UserId = string | number;

export const getNextRelatedUserIds = createSelector(
  (props: Props) => get(props.detail, 'participants'),
  (props: Props) => get(props.detail, 'principal'),
  (participants, principal) => {
    const userIds: UserId[] = [];
    if (participants) {
      userIds.push(...participants);
    }

    if (principal) {
      if (!userIds.some(id => isEqualUserId(principal, id))) {
        userIds.push(principal);
      }
    }

    return userIds;
  }
);
