// tslint:disable:max-file-line-count

import React,  { createContext } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { RouteComponentProps } from 'react-router';
// import { NavLink, Switch, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { filterMenu } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import CooSettings from './Settings';

import CooMemberDrawer from './CooMemberDrawer';

import Task from 'src/pages/Cooperation/Task';

import store, { RootState } from 'src/store';
import { ROUTE_NAMES, NamedRouteComponents } from 'src/router/normalRouter';
import HoneyRoute, { compilePath, getRouteByName, getRouterProps, AsyncDataArg } from 'src/components/HoneyRouter';
import { Workspace } from 'src/models/workspace/interface';
import { isEqualUserId } from 'src/utils/user';
import { pickOrgUser, mapOrgMember } from 'src/models/org';

interface WorkspaceContext {
  workspace?: Workspace;
  memberUsers: RoleUser[];
  role: AllRole;
}

interface OwnProps extends RouteComponentProps<any> {

}

interface StateProps {
  totalUsers: RoleUser[];
  workspace: Workspace;
  workspaceId: string;
  role: AllRole;
}

interface DispatchProps {}

interface State {
  showMemberDrawer: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

export const workspaceContext = createContext<WorkspaceContext>({
  memberUsers: [],
  role: 'MEMBER',
});

let menus: any[] = [];

function fillMenu() {
  menus = [
    {
      title: '任务',
      routeName: ROUTE_NAMES.ORG_COO_TASK,
      routeProps: {
        component: Task,
        exact: true,
      }
    },
    {
      title: '设置',
      routeName: NamedRouteComponents.ORG_COO_SETTINGS,
      routeProps: {
        component: CooSettings,
      }
    },
  ];

}

setTimeout(fillMenu);

class CooWrapper extends React.PureComponent<Props, State> {
  $el: any;
  static asyncData({ store, route }: AsyncDataArg) { // tslint:disable-line
    const { match: { params } } = route;

    return store.dispatch({ type: 'workspace/setWorkspaceId', payload: params.workspaceId });
  }

  constructor(props: Props) {
    super(props);

    // if (!menus.length) {
    //   fillMenu();
    // }

    this.state = {
      showMemberDrawer: false,
    };
  }

  componentDidUpdate(props: Props) {
    const { match: { params: { workspaceId } } } = this.props;
    if (props.match.params.workspaceId !== workspaceId) {
      store.dispatch({ type: 'workspace/setWorkspaceId', payload: workspaceId });
    }
  }

  componentWillUnmount() {
    store.dispatch({ type: 'workspace/setWorkspaceId', payload: '' });
  }

  @bind
  toggleMemberDrawer() {
    const { showMemberDrawer } = this.state;
    this.setState({ showMemberDrawer: !showMemberDrawer });

    this.resizeWindow();
  }

  @bind
  closeMemberDrawer() {
    this.setState({ showMemberDrawer: false });
    this.resizeWindow();
  }

  resizeWindow() {
    // TODO: use transitionEnd event, not a setTimeout.
    setTimeout(() => {
      const event = new Event('resize');
      window.dispatchEvent(event);
    }, 200);
  }

  @bind
  setElement(el: any) {
    this.$el = el;
  }

  @bind
  getElement() {
    return this.$el;
  }

  getRouteName(name: string) {
    const { workspace } = this.props;
    if (workspace.isMaster && name === ROUTE_NAMES.ORG_COO_SETTINGS) {
      return ROUTE_NAMES.ORG_COO_MOMENTS;
    }

    return name;
  }

  render() {
    const { match, workspace, role, workspaceId, totalUsers } = this.props;
    const { showMemberDrawer } = this.state;
    const { Provider } = workspaceContext;
    const routerProps = getRouterProps(this.props);
    const showDrawerClass = showMemberDrawer ? styles.showDrawer : '';
    const showMenus = filterMenu(menus, this);

    return (
      <div className={classnames(styles.wrapper, showDrawerClass)} ref={this.setElement}>
        <div className={classnames(styles.content, 'flex')}>
          <header className={classnames(styles.header, 'clear')}>
            <div className="nav">
              {showMenus.map((item, index) => (
                <NavLink
                  key={index}
                  className={classnames('nav-item')}
                  activeClassName="active"
                  exact={!!item.routeProps.exact}
                  to={compilePath({ name: this.getRouteName(item.routeName), params: match.params })}
                >
                  <span>{item.title}</span>
                </NavLink>
              ))}
            </div>
            <div onClick={this.toggleMemberDrawer} className="member-count flex align-center">
              <span>{totalUsers.length}</span><i className="c-icon icon-users-o" />
            </div>
          </header>
          <div className={classnames(styles.workspaceContent, 'cell')}>
            <Provider value={{ workspace, memberUsers: totalUsers, role: role! }}>
              {/* <Switch> */}
                {
                  showMenus.map((item, index) => {
                    const { routeName, routeProps } = item;
                    const route = getRouteByName(routeName)!;

                    return (<HoneyRoute key={index} {...routerProps} {...route} {...routeProps} />);
                  })
                }
                {/* <Redirect to={compilePath({ name: ROUTE_NAMES.ORG_COO_DETAIL, params: match.params })} />
              </Switch> */}
            </Provider>
          </div>
        </div>
        <CooMemberDrawer role={role} workspace={workspace} workspaceId={workspaceId} users={totalUsers}/>
      </div>
    );
  }
}

const mapState = () => {
  return function mapStateToProps({ session: { profile }, org, workspace: { workspaceId, list }, user }: RootState) {
    const workspace = list.byId[workspaceId];
    const { isMaster } = workspace;
    let role, totalMembers;

    // Master workspace is different.
    if (isMaster) {
      role = org.orgMembers.byId[profile!._id].role;
      totalMembers = mapOrgMember(org.orgMembers);
    } else {
      const member = workspace.members.find(m => isEqualUserId(m.user, profile!._id)) || { role: 'MEMBER' };
      role = member.role;
      totalMembers = workspace.members;
    }

    const totalUsers = pickOrgUser(user, totalMembers) as RoleUser[];

    return {
      totalUsers,
      workspace,
      workspaceId,
      role,
    };
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(CooWrapper);
