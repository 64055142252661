import { AxiosInstance, AxiosRequestConfig } from 'axios';

type ConfirmParams<T extends object = {}> = {
  password: string;
} & {
  [P in keyof T]: T[P];
};

export interface CreateParams {
  name: string;
  description?: string;
  members?: Member<AllRole>[];
}

export interface MemberGroupParams {
  name: string;
}

export interface MemberGroupEditParams {
  oldName: string;
  members?: number[];
  newName?: string;
}

export interface ActivityParams {
  lastId?: string;
  limit?: number;
}

type ARC = AxiosRequestConfig;

export default function(api: AxiosInstance) {
  return {
    create(data: CreateParams, config?: ARC) {
      return api.post('cooperation/workspace', data, config);
    },
    edit(worksapceId: string, data: CreateParams, config?: ARC) {
      return api.patch(`cooperation/workspace/${worksapceId}`, data, config);
    },
    list(params: { [key: string]: any } = {}, config: ARC = {}) {
      return api.get('cooperation/workspace', { ...config, params });
    },
    archive(workspaceId: string, config?: ARC) {
      return api.post(`cooperation/workspace/${workspaceId}/archive`, config);
    },
    leave(workspaceId: string, data: ConfirmParams, config?: ARC) {
      return api.post(`cooperation/workspace/${workspaceId}/leave`, data, config);
    },
    dissolve(workspaceId: string, data: ConfirmParams, config?: ARC) {
      return api.post(`cooperation/workspace/${workspaceId}/dissolve`, data, config);
    },
    transfer(workspaceId: string, data: ConfirmParams<{ userId: string | number }>, config?: ARC) {
      return api.post(`cooperation/workspace/${workspaceId}/transfer`, data, config);
    },
    fetchActivity(worksapceId: string, params: ActivityParams = {}, config: ARC = {}) {
      return api.get(`cooperation/workspace/${worksapceId}/activity`, { ...config, params });
    },
    addMember(workspaceId: string, data: { members: Member<NormalRole>[] }, config?: ARC) {
      return api.put(`cooperation/workspace/${workspaceId}/member`, data, config);
    },
    removeMember(workspaceId: string, params: { userId: string | number }, config: ARC = {}) {
      return api.delete(`cooperation/workspace/${workspaceId}/member`, { ...config, params });
    },
    createMemberGroup(workspaceId: string, data: MemberGroupParams, config?: ARC) {
      return api.post(`cooperation/workspace/${workspaceId}/member-group`, data, config);
    },
    editMemberGroup(workspaceId: string, data: MemberGroupEditParams, config?: ARC) {
      return api.patch(`cooperation/workspace/${workspaceId}/member-group`, data, config);
    },
    deleteMemberGroup(workspaceId: string, params: { name: string }, config: ARC = {}) {
      return api.delete(`cooperation/workspace/${workspaceId}/member-group`, { ...config, params });
    },
    setMemberRole(workspaceId: string, userId: string, data: { role: NormalRole }, config?: ARC) {
      return api.patch(`cooperation/workspace/${workspaceId}/member/${userId}`, data, config);
    },
    star(worksapceId: string, config?: ARC) {
      return api.put(`cooperation/workspace/${worksapceId}/star`, config);
    },
    unstar(worksapceId: string, config?: ARC) {
      return api.delete(`cooperation/workspace/${worksapceId}/star`, config);
    },
  };
}
