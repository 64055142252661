import React, { FC, useEffect } from 'react';
import { sortWaferId } from '@chipcoo/fe-utils';

interface Props {
  analysisData: any[];
  analytiCalSolution: number;
  onChange?: (value: any) => void;
}

const OriginAnalysisData: FC<Props> = props => {
  const { analysisData, analytiCalSolution, onChange } = props;
  let data: Obj = {
    // analytiCalSolution: 0,
    // goodBins: ['bin1']
  };

  useEffect(
    () => {
      // 默认的解决方案数据
      // 重选方案时，更新这个data数据
      const currentAnalyticalData = analysisData?.[analytiCalSolution ?? 0];

      if (currentAnalyticalData) {
        const analyticalData = currentAnalyticalData?.data;
        const hasBins = analyticalData?.find(it => it?.bins);

        if (hasBins) {
          data = {
            ...data,
            goodBins: ['bin1']
          };
        }

        const getGoodAmount = (waferId) => {
          const waferInfo = analyticalData?.find(it => it?.waferId === waferId);

          return waferInfo?.good;
        };

        const getNewWaferIdData = (item) => ({
          waferId: item.toString(),
          editable: true,
          key: `NEW_TEMP_INDEX_${item - 1}`,
          good: getGoodAmount(item),
        });

        const dieNumber = currentAnalyticalData?.dieNumber;
        const waferIdArray = analyticalData?.map(it => it.waferId);
        const slices = waferIdArray.map(item => getNewWaferIdData(item));

        data = {
          ...data,
          dieNumber,
          waferIdArray: sortWaferId(waferIdArray),
          slices
        };

        onChange && onChange(data);
      }
    },
    // 每次原数据更新或重选方案时，更新originAnalysisData
    [analysisData, analytiCalSolution]
  );

  return (
    <div />
  );
};

export default OriginAnalysisData;
