import React, { PureComponent } from 'react';
import { Exception } from 'src/components';
// import { fundebug } from '../../';
// import { apmAgent } from 'src/apm';
import urlConfig from 'src/config/url';
import { IExceptionProps } from 'src/components/Exception';
import { ExceptionError } from './interface';

interface Props {}
interface State {
  hasError: boolean;
  error: null | ExceptionError;
}

export function createException(message: string, params: Partial<IExceptionProps> = {}): ExceptionError {
  //
  const err = new Error(message);
  Object.assign(err, { ...params });

  return err;
}

export class ErrorPage extends PureComponent<Props, State> {
  state = {
    hasError: false,
    error: null,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error });
    // fundebug.notifyError(error, {
    //   metaData: {
    //     info: info
    //   }
    // });

    // APM 上报
    // apmAgent.captureError(error);
  }

  renderException() {
    return (
      <Exception
        type="500"
        title="出错了"
        desc="我们已经将此错误信息记录，并将尽快处理，尽请谅解。"
        backText="返回首页"
        redirect={urlConfig.baseUrl || '/'}
        {...this.state.error}
      />
    );
  }

  render() {
    if (this.state.hasError) {
      return this.renderException();
    }

    return this.props.children;
  }
}

export default ErrorPage;
