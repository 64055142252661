import { getMomentTime, sortWaferId } from '@chipcoo/fe-utils';
import { findLastIndex } from 'lodash';
import { EnumImportJobStatus } from '@chipcoo/constant';

export const handleAnalysisLog = (payload: any[]) => {
  return payload.map(it => {
    const { product, analysis, status, factory, info, createdAt } = it;

    let curData = {
      ...it,
      productName: product?.chipcooName && product?.customerName + '(' + product?.chipcooName + ')',
      statusText: status === 'SUCCESS' ? '成功' : '失败',
      factoryName: factory?.shortName,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      waferIds: info?.waferIds ? '#' + sortWaferId(info?.waferIds) : info?.waferIds,
    };

    if (analysis) {
      const { testedAt, dieName, dieNumber, testProgram, type } = analysis;

      // const waferIds = wafers?.map(w => w.waferId);

      curData = {
        ...curData,
        dieNumber,
        testProgram,
        testedAt: getMomentTime(testedAt, 'YYYY-MM-DD HH:mm:ss'),
        // waferIds: waferIds ? '#' + sortWaferId(waferIds) : waferIds,
        dieName: dieName?.customerName + '(' + dieName?.chipcooName + ')',
        testType: type,
      };
    }

    return curData;
  });
};

export const handleDetailLog = (payload: Obj) => {
  const { originStatus, status } = payload;
  const setOriginStep = (): number => {
    let step: number = 0;
    if (status === EnumImportJobStatus.running) {
      step = findLastIndex(originStatus, (it: any) => it?.[1] === EnumImportJobStatus.running);
    }

    if (status === EnumImportJobStatus.errored) {
      step = findLastIndex(originStatus, (it: any) => it?.[1] === EnumImportJobStatus.errored);
    }

    if (status === EnumImportJobStatus.finished) {
      step = findLastIndex(originStatus, (it: any) => it?.[1] === EnumImportJobStatus.finished);
    }

    if (status === EnumImportJobStatus.partialSuccess) {
      step = findLastIndex(originStatus, (it: any) => it?.[1] === EnumImportJobStatus.partialSuccess);
    }

    return step === -1 ? 0 : step;
  };

  return {
    ...payload,
    originStep: setOriginStep()
  };
};
