import * as React from 'react';
import { Modal } from '@chipcoo/hanayo';

import styles from './index.module.less';

interface IrreversibleConfirmProps {
  visible: boolean;
  onCancel: (e: any) => void;
  onOk: (e: any) => void;
  footer: string;
  content: React.ReactNode;
  title: React.ReactNode;
}

function IrreversibleConfirm(props: IrreversibleConfirmProps) {
  const { visible, onCancel, content, onOk, footer, title } = props;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okType="danger"
      width={500}
      onOk={onOk}
      okText={footer}
      title={title}
      animation="slideDown"
      className={styles.modal}
      canCloseOnEsc={true}
      canCloseOnOverlayClick={true}
    >
      {content}
    </Modal>
  );
}

export {
  IrreversibleConfirm
};
