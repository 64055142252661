import { createModel } from '@rematch/core';
import produce from 'immer';
import { EnumProductModel } from '@chipcoo/constant';

import { RootState } from 'src/store';
import { demandOrderApi, inventoryApi } from 'src/services/net';
import {
  handleDemandOrderList,
  handleDetailData,
  handleDemandOrderFormData,
  handleStockSelectionData,
  // handleSmartSelectionData,
} from './handle';
// import { ISmartSelection } from 'src/services/net/inventoryManage';
import { IDemandOrderModel, ISelectionStock } from './interface';

const demandOrderModel: IDemandOrderModel = {
  tableList: [],
  tableCount: 0,
  orderDetail: {},
  stockList: {
    originData: [],
    handleData: []
  },
  stockCount: 0,
  smartSelectionData: [],
  // selectedKeys: [],
};

export default createModel({
  state: demandOrderModel,
  reducers: {
    setList(state: IDemandOrderModel, respData: any) {
      return produce(state, draft => {

        draft.tableCount = respData.count;
        draft.tableList = respData.handleData;
      });
    },
    setDetail(state: IDemandOrderModel, payload: { id: string, data: any }) {
      return produce(state, draft => {
        draft.orderDetail[payload.id] = payload.data;
      });
    },
    setStockList(state: IDemandOrderModel, respData: any) {
      return produce(state, draft => {
        draft.stockCount = respData.length;
        draft.stockList = respData;
      });
    },
  },
  effects: (dispatch) => ({
    async getTableList(val: any, rootState: RootState) {
      const { data } = await demandOrderApi.list(val);
      const handledData = await handleDemandOrderList(data.data);

      this.setList({ ...data, ...handledData });
    },

    async fetchDetail(id: string) {
      return await demandOrderApi.fetch(id);
    },

    async getTableItemDetail(formData: any, rootState: RootState) {
      const { data } = await this.fetchDetail(formData.id) as any;
      const handledData = await handleDetailData(data);

      this.setDetail({ id: formData.id, data: handledData });
    },

    async createDemandOrder(data: any) {
      const handledData = await handleDemandOrderFormData(data);

      await demandOrderApi.create(handledData);
    },

    // 获取产品可选物料
    async getProductSelectionStock(params: ISelectionStock) {
      const { productId, warehouseType } = params;
      const { data } = await inventoryApi.selectionStock({ _id: productId, warehouseType });
      const handleData = handleStockSelectionData(data);

      this.setStockList(handleData);
    },

    // 智能选料
    // async smartSelection(params: ISmartSelection) {
    //   const { data } = await inventoryApi.smartSelection(params);
    //
    //   this.setSmartSelection(data);
    // },

    // 删除已拒绝的订单
    async deleteOrder(id: string) {
      await demandOrderApi.deleteOrder(id);
    },
    // 获取各个订单状态数量
    async searchOrderStatus(productModel: EnumProductModel) {
      const { data } = await demandOrderApi.searchOrderStatus({productModel});
      return data;
    },

    // 获取策略
    async getPolicy(params: any) {
      const { data } = await demandOrderApi.getPolicy(params);
      return data;
    }
  }),
});
