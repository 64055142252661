import React from 'react';
import { bind } from 'lodash-decorators';
import { Card, Table } from 'antd';

import styles from './style.module.less';
import AddMember from './AddMember';
import { HoverScrollBarContainer } from 'src/components';
import { RootState, RootDispatch, connect } from 'src/store';
import { AsyncDataArg } from 'src/components/HoneyRouter';
import { TableProps } from 'antd/lib/table';

interface MemberTableCreatorOptions<StateProps, Disp, U> {
  cardTitle: React.ReactNode;
  setMemberRole: (userId: UserId, role: AllRole, ctx?: any) => any;
  addMember: (members: Member[], reset?: any, ctx?: any) => any;
  removeMember: (userId: UserId, ctx?: any) => any;
  getColumnsConfig: (role: AllRole, ctx?: any) => any;
  mapState: (arg: RootState) => StateProps;
  mapDispatch: (arg: RootDispatch) => Disp;
  cacheColumnConfig?: boolean;
  displayName?: string;
  className?: string;
  rowKey?: (TableProps<U>)['rowKey'];
  renderExtra?: (ctx?: any) => JSX.Element | null;
  asyncData?: (arg: AsyncDataArg) => any;
  orderedAsyncData?: (arg: AsyncDataArg) => any;
}

export interface BasicStateProps<T extends User = User> {
  role: AllRole | null;
  users: T[];
  members: T[];
  disabledKeys: string[];
}

export function createMemberTable<
  U extends User = User,
  S extends BasicStateProps = BasicStateProps<U>,
  Disp extends object = {},
  Own extends object = {},
>(options: MemberTableCreatorOptions<S, Disp, U>) {
  type Props = S & Disp & Own;

  interface State {
    modalVisible: boolean;
    loadingList: string[];
    // queryPanelVisible: boolean;
  }

  const {
    setMemberRole,
    removeMember,
    addMember,
    getColumnsConfig,
    cacheColumnConfig,
    mapState,
    mapDispatch,
    renderExtra,
    className,
    rowKey,
    asyncData,
    orderedAsyncData,
  } = options;

  class MemberTableCard extends React.PureComponent<Props, State> {
    static displayName = options.displayName;
    static asyncData = asyncData;
    static orderedAsyncData = orderedAsyncData;
    private columnsCache: any = {};
    private roleCache: any = {};

    state = {
      // queryPanelVisible: false,
      modalVisible: false,
      loadingList: [] as string[],
    };

    @bind
    openModal() {
      this.setState({ modalVisible: true });
    }

    @bind
    closeModal() {
      this.setState({ modalVisible: false });
    }
    //
    // @bind
    // openQuery() {
    //   console.log('1');
    //   this.setState({ queryPanelVisible: true });
    // }
    //
    // @bind
    // closeQuery() {
    //   this.setState({ queryPanelVisible: false });
    // }

    @bind
    toggleLoadingId(id: User['_id']) {
      return new Promise<void>((resolve) => {
        const loadingList = this.state.loadingList.slice();
        const contain = loadingList.indexOf(id);
        if (contain !== -1) {
          loadingList.splice(contain, 1);
        } else {
          loadingList.push(id);
        }

        this.setState({ loadingList }, resolve);
      });
    }

    @bind
    getColumnsConfig(role: AllRole) {
      if (typeof cacheColumnConfig !== 'boolean' && !cacheColumnConfig) {
        return getColumnsConfig.call(this, role, this);
      }

      if (this.columnsCache[role]) {
        return this.columnsCache[role];
      }

      const columns = getColumnsConfig.call(this, role, this);
      this.columnsCache[role] = columns;

      return columns;
    }

    @bind
    renderExtra() {
      if (renderExtra) {
        return renderExtra.call(this, this);
      }

      const { role } = this.props;
      if (role === 'MEMBER' || role === 'member') {
        return null;
      }

      return (
        <a href="javascript: void(0);" onClick={this.openModal}>添加成员</a>
      );
    }

    @bind
    async handleAddMembers(val: string[], reset: () => any) {
      const members: Member[] = val.map(id => ({
        user: parseInt(id, 10),
      }));

      await addMember.call(this, members, reset, this);
    }

    @bind
    async removeMember(id: string) {
      await removeMember.call(this, id, this);
    }

    @bind
    async setMemberRole(userId: User['_id'], role: NormalRole) {
      this.roleCache[userId] = role;
      await this.toggleLoadingId(userId);
      try {
        await setMemberRole.call(this, userId, role);
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        this.toggleLoadingId(userId);
      }, 200);
    }

    render() {
      const { role, users, members, disabledKeys } = this.props;
      const { modalVisible } = this.state;
      const columns = this.getColumnsConfig(role as any as AllRole);

      return (
        <HoverScrollBarContainer className={className || styles.orgMembers}>
          {/*<QueryPanel*/}
          {/*  visible={queryPanelVisible}*/}
          {/*  onRequestClosePanel={this.closeQuery}*/}
          {/*/>*/}

          <Card
            title={options.cardTitle}
            bordered={false}
            extra={this.renderExtra()}
            className={styles.members}
          >
            <AddMember
              visible={modalVisible}
              users={users}
              disabledKeys={disabledKeys}
              onCancel={this.closeModal}
              onSubmit={this.handleAddMembers}
            />
            <Table
              size="middle"
              pagination={false}
              columns={columns}
              dataSource={members}
              rowKey={rowKey}
            />
          </Card>
        </HoverScrollBarContainer>
      );
    }
  }

  // Fix me: type define.
  const WrappedMemberTable = connect<S, Disp, Own>(mapState, mapDispatch)(MemberTableCard as any);

  return WrappedMemberTable;
}

export default createMemberTable;
