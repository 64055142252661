/**
 * Created by marvin on 18/8/8.
 */
import { createModel } from '@rematch/core';
import { omit } from 'lodash';

import { addressApi } from 'src/services/net';
import { handleAddressList } from './handle';
import { AddressParam } from './interface';

interface State {
  addAddressObj: AddressParam;
  addressList: AddressParam[];
}

export const initialState: State = {
  addressList: [],
  addAddressObj: {
    receiver: '',
    phone: '',
    detail: '',
    editable: false,
    regionCode: [],
    labels: [],
    province: '',
    city: '',
    distinct: '',
  },
};

export default createModel({
  state: initialState,
  effects: (dispatch) => ({
    async getAddressList () {
      try {
        const { data } = await addressApi.getAddress();
        const handleData = handleAddressList(data);
        this.updateState({path: 'addressList', data: handleData});
      } catch (e) {
        console.log(e);
      }
    },
    async postAddress (val: AddressParam) {
      try {
        const value = omit(val, ['_id', 'isNew', 'editable']);
        const resp = await addressApi.postAddress(value);

        this.updateState([
          {method: '$push', path: 'addressList', data: [resp.data]},
          {path: 'addAddressObj', data: resp.data},
        ]);

        return resp.data;
      } catch (e) {
        console.log(e);
      }
    },
    async patchAddress (val: AddressParam, rootState: any) {
      const addressList = rootState.address.addressList;

      try {
        const { data } =
          await addressApi.putAddress(omit(val, ['editable', 'key', 'userId', '__v']));
        const newAddressList = addressList.map(addr => {
          if (addr._id === val._id) {
            return {...data, ...val, key: val._id};
          } else {
            return addr;
          }
        });

        this.updateState({
          method: '$set', path: 'addressList', data: newAddressList
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAddress (val: AddressParam) {
      try {
        await addressApi.deleteAddress(val._id!);
      } catch (e) {
        console.log(e);
      }
    }
  })
});
