/**
 * 出库申请列表
 */
import React from 'react';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TableCategory } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { EnumProductModel } from '@chipcoo/constant';

import { getInitialQuery } from 'src/utils';
import { categoryConfig } from '../formConfig';

import StockOutTable from './StockOutTable';

interface IProps extends RouteComponentProps<any> {

}
interface IState {
  category: EnumProductModel;
  isResetDefault: boolean;
}
class StockOut extends React.PureComponent<IProps, IState> {
  private cardTableController: ICardTableController;

  constructor(props: IProps) {
    super(props);

    const productModel = EnumProductModel.spwDie;

    this.state = {
      category: productModel,
      isResetDefault: false,
    };
  }
  setCategory = (category: IState['category']) => {
    this.setState({
      category,
      isResetDefault: false,
    }, () => {

      if (this.cardTableController) {
        this.cardTableController.setQueryParams!({
          page: 1,
          formData: {}
        });
      }
    });
  }

  render() {
    const {
      category,
      isResetDefault
    } = this.state;

    return (
      <div className="wrap-content">
        <TableCategory
          defaultCategory={getInitialQuery(this.props)?.category || category}
          title="产品类型"
          statistics={{}}
          setCategory={this.setCategory}
          categoryConfig={categoryConfig}
          isResetDefault={isResetDefault}
        />

        <StockOutTable 
          category={category}
        />
      </div>
    );
  }
}

export default withRouter(StockOut);
