// tslint:disable:max-file-line-count
import * as React from 'react';
import classNames from 'classnames';
import { uniqueId, has } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router-dom';
import { EnumNtoType } from '@chipcoo/constant';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';

import { connectDecorator, RootState, Actions } from 'src/store';
// import styles from './index.module.less';
import CardExtra from './components/CardExtra';
import { getTableColumns, getRevTableColumns, NtoStatusData } from './components/getTableColumns';
import { getNtoAddFormConfig, getNtoEditFormConfig } from './formConfig';

const { EnhancedTable } = CardTableV2;

interface OwnProps extends RouteComponentProps {
}
interface StateProps {
  list: any[];
  revList: Obj;
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getRevNtoListById: (id: string) => void;
  getTableItemDetail: (id: string) => void;
  postCreate: (data: Obj) => void;
  patchModify: (data: Obj) => void;
  enableNto: (_id: string) => void;
  disableNto: (_id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  categoryLoading: boolean;
  tableLoading: boolean;
  revTableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  modalFullWindow: boolean;
  mode: ModalMode;
  ntoType: EnumNtoType;
}

@connectDecorator(
  ({ nto: { list, count, detail, revList } }: RootState) => ({
    list, count, detail, revList
  }),
  ({ nto: {
    getTableList,
    getTableItemDetail,
    patchModify,
    postCreate,
    getRevNtoListById,
    enableNto,
    disableNto
  } }: Actions) => ({
    getTableList, getTableItemDetail, patchModify, postCreate, getRevNtoListById, enableNto, disableNto
  })
)
class NtoList extends React.PureComponent<Props, State> {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];
  private readonly revTableColumns: ColumnProps<any>[] = [];
  private formController;
  modalName = uniqueId('NTO_');

  constructor(props: Props) {
    super(props);

    this.state = {
      categoryLoading: true,
      tableLoading: false,
      revTableLoading: false,
      detailModalVisible: false,
      detailId: '',
      modalFullWindow: true,
      mode: 'add',
      ntoType: EnumNtoType.init,
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
      patchNtoStatus: this.patchNtoStatus,
    });

    this.revTableColumns = getRevTableColumns({
      openModal: this.openDetailModal,
      patchNtoStatus: this.patchNtoStatus,
    });
  }

  // async componentDidMount() {
  //   // 通过邮件链接打开预订单详情
  //   const { match }: any = this.props;
  //   const id = match?.params?.preOrderId;
  //
  //   if (id) {
  //     await this.openDetailModal(id);
  //   }
  // }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  patchNtoStatus = async (data: NtoStatusData) => {
    try {
      const { enableNto, disableNto, patchModify } = this.props;

      if (has(data, 'isSetDisabled')) {
        if (data?.isSetDisabled) {
          await disableNto(data?._id);
        } else {
          await enableNto(data?._id);
        }
      } else {
        await patchModify(data);
      }

      this.reloadTable();
    } catch (e) {
      console.error(e);
    }
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;

    const queryParams = {
      ...formData,
      ...extraParams,
      type: EnumNtoType.init
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  openAddModal = (initialVersion) => {
    // 首版mode为add，改版mode置为edit
    this.setState({ detailModalVisible: true, detailId: '', mode: initialVersion ? 'add' : 'edit' });
  }

  openDetailModal = async (detailId: string, ntoType: EnumNtoType) => {
    const { getTableItemDetail } = this.props;

    try {
      await getTableItemDetail(detailId);

      this.setState({ detailModalVisible: true, detailId, mode: 'preview', ntoType });
    } catch (e) {
      console.error(e);
    }
  }

  closeDetailModal = () => this.setState({ detailModalVisible: false, mode: 'add', ntoType: EnumNtoType.init });

  closeModalWithReload = () => {
    this.closeDetailModal();

    if (this.cardTableController) {
      this.cardTableController.reloadTable();
    }

    this.setState({ tableLoading: false });
  }

  reloadTable = () => {
    this.closeModalWithReload();
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  handleSubmit = (e: any) => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      const { validateFieldsAndScroll } = this.formController;

      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            const { postCreate } = this.props;
            const { mode } = this.state;
            this.setState({ tableLoading: true });
            let type;

            if (mode === 'add') {
              type = EnumNtoType.init;
            } else if (mode === 'edit') {
              type = EnumNtoType.rev;
            }

            await postCreate({
              ...values,
              type
            });

            resolve(true);

            this.closeModalWithReload();
          } catch (e) {
            console.error(e);

            reject(e);
          }
        } else {
          reject(err);
        }
      });
    });
  }

  getRevTableData = async (record: any) => {
    const { getRevNtoListById } = this.props;
    this.setState({ revTableLoading: true });

    try {
      record?._id && await getRevNtoListById(record?._id);
    } catch (e) {
      console.error(e);
    }

    this.setState({ revTableLoading: false });
  }

  expandedRowRender = (record: any) => {
    const { revList } = this.props;
    const { revTableLoading } = this.state;

    return (
      <EnhancedTable
        dataSource={revList[record?._id] || []}
        tableLoading={revTableLoading}
        getTableData={() => this.getRevTableData(record)}
        columns={this.revTableColumns}
        clickMention={true}
        showPagination={false}
        size="small"
      />
    );
  }

  render () {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      detailModalVisible,
      modalFullWindow,
      mode,
      ntoType,
    } = this.state;
    let [footer, status, title, formConfig, formData]: any[] =
      [undefined, 'edit', 'NTO预订', getNtoAddFormConfig(this.modalName, ntoType), {}];

    if (mode === 'preview') {
      footer = null;
      status = 'preview';
      title = 'NTO预订详情';
      formData = detail;

      if (ntoType === EnumNtoType.rev) {
        title = 'NTO改版详情';
        formConfig = getNtoEditFormConfig(this.modalName, ntoType);
      }
    }

    if (mode === 'edit') {
      title = 'NTO改版';
      formConfig = getNtoEditFormConfig(this.modalName, ntoType);
      formData = {};
    }

    return (
      <div className={classNames('wrap-content')}>
        <CardTableV2
          card={{
            title: 'NTO产品列表',
            extra: (
              <CardExtra
                openModal={this.openAddModal}
              />
            )
          }}
          getCardTableController={this.getCardTableController}
        >
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            clickMention={true}
            size="small"
            expandedRowRender={this.expandedRowRender}
          />
        </CardTableV2>

        <FlexModal
          modalName={this.modalName}
          title={title}
          visible={detailModalVisible}
          onCancel={this.closeDetailModal}
          onOk={this.handleSubmit}
          // className={styles.modal}
          fullWindow={modalFullWindow}
          className="flow-in-flex-modal"
          onRequestFullWindow={this.requestFullWindow}
          onRequestNormalWindow={this.requestNormalWindow}
          footer={footer}
          width={1440}
          resize={{
            minWidth: 1366,
            minHeight: 768
          }}
        >
          <FormCreator
            formConfig={formConfig}
            formWrapper={false}
            formData={formData}
            globalStatus={status}
            getFormController={controller => this.formController = controller}
          />
        </FlexModal>
      </div>
    );
  }
}

export default NtoList;
