import {
  // parseURL,
  // newObjByValue,
  handleUrlParams,
} from '@chipcoo/fe-utils';
// import _ from 'lodash';

export const getInitialQuery = (props) => {
  const { location } = props;
  const search = location?.search;
  let initialQuery;

  if (search) {
    const urlParams = handleUrlParams(search);
    initialQuery = { ...urlParams };
  }

  return initialQuery;
};

// 通过获取到的query，将其解析并替换原有url
export const setUrlByQuery = (match, query, queryParams) => {
  // const newQuery = Object.entries(query).reduce(
  //   (prev, [key, val]) => {
  //     prev[key] = _.isObject(val) ? _.get(val, 'key') : val;
  //
  //     return prev;
  //   },
  //   {} as any
  // );
  // const newObj = newObjByValue(newQuery) || {};

  // todo query里添加category
  // const newUrl = parseURL(match.url, newObj);
  // window.history.pushState({}, document.title, newUrl);

  return {
    ...queryParams,
    page: 1,
    formData: {
      ...queryParams?.formData,
      ...query
    }
  };
};
