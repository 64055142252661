import React, { FC, useEffect } from 'react';
import { Radio } from 'antd';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  status: FormStatus;
}

const ReliabilityTest: FC<Props> = props => {
  const { value, onChange, status } = props;
  const newValue = value || false;

  useEffect(
    () => {
      onChange && onChange(newValue);
    },
    []
  );

  return (
    <Radio.Group onChange={onChange} value={newValue} disabled={status === 'preview'}>
      <Radio value={false}>否</Radio>
      <Radio value={true}>是</Radio>
    </Radio.Group>
  );
};

export default ReliabilityTest;
