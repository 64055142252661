/**
 * IM聊天
 */
import * as React from 'react';
// import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual, difference } from 'lodash';
import update from 'immutability-helper-x';

// import { CacheRoute } from 'src/local_modules/CacheRoute';
// import { CacheRoute } from 'src/local_modules/react-router-cache-route_test/index.js';

import HoneyRoute, { getRouteByName } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';

import { RootDispatch, RootState } from 'src/store';
import s from './index.module.less';

// import ImSocket from './ImSocket';
import RoomList from './RoomList';
import ChatPanel from './ChatPanel';
import NoMessage from './NoMessage';
import { IrreversibleConfirm } from 'src/components';

import { friendPicker } from 'src/models/friend';
import { SubscribeResult } from 'src/services/socket';
import { subscribe, unsubscribe } from './im-socket';

interface OwnProps {}

interface StateProps {
  friends: User[];
  chatRoomIds: string[];
}

interface DispatchProps {
  leaveRoom: (roomId: string) => Promise<boolean>;
  setCurrentRoomId: (roomId?: string) => any;
}
export type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  leaveChatRoomTextType: 'normal' | 'owner';
  ctx: InitialImCtx;
  modalVisible: boolean;
}

export type InitialImCtx = {
  roomId: string | undefined;
  friends: User[];
  setUrl: ((roomPath?: string) => void) | undefined;
  openLeaveChatRoomModal: ((type: 'normal' | 'owner') => void) | undefined;
};
export const ImContext = React.createContext<InitialImCtx>({
  friends: [],
  setUrl: void 0,
  roomId: void 0,
  openLeaveChatRoomModal: void 0
});
const leaveChatRoomText = {
  normal: {
    title: '退出群组', content: '退出后不会通知群聊中其他成员，且不会再接收此群组消息'
  },
  owner: {
    title: '解散群组', content: '解散群组，所有的聊天记录将会被删除，且无法找回'
  }
};

interface ImSubscriptions {
  [key: string]: SubscribeResult;
}

export class IM extends React.PureComponent<P, S> {
  subscriptions: ImSubscriptions = {};
  msgSubscriptions: ImSubscriptions = {};
  unmounted = false;
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};

    if (!isEqual(nextProps.friends, prevState.ctx.friends)) {
      nextState.ctx = {
        ...prevState.ctx,
        friends: nextProps.friends,
      };
    }

    return nextState;
  }
  constructor(props: P) {
    super(props);

    this.state = {
      ctx: {
        setUrl: this.handleSetUrl,
        friends: props.friends,
        roomId: void 0,
        openLeaveChatRoomModal: this.openLeaveChatRoomModal
      },
      leaveChatRoomTextType: 'normal',
      modalVisible: false
    };
  }

  componentDidMount() {
    subscribe(this, this.props.chatRoomIds);
  }

  componentDidUpdate(prevProps: P) {
    const addon = difference(this.props.chatRoomIds, prevProps.chatRoomIds);
    const removed = difference(prevProps.chatRoomIds, this.props.chatRoomIds);
    subscribe(this, addon);
    unsubscribe(this, removed);
  }

  componentWillUnmount() {
    this.unmounted = true;
    unsubscribe(this);
  }

  setRoomId = (roomId?: string) => {
    this.setState(update.$set(this.state, 'ctx.roomId', roomId));

    this.props.setCurrentRoomId(roomId);
  }

  // 重置路由至/im，或者点击某个房间，如果不传入值，那就是直接重置到/im路径
  handleSetUrl = (roomPath?: string) => {
    const { history, match } = this.props;

    if (!roomPath) {
      history.push('/im');
      return;
    }

    history.push(`${match.path}/${roomPath}`);
  }

  openLeaveChatRoomModal = (type: 'normal' | 'owner') => {
    this.setState({
      modalVisible: true,
      leaveChatRoomTextType: type
    });
  }

  handleLeaveRoom = async () => {
    const { roomId } = this.state.ctx;
    const { leaveRoom } = this.props;

    if (!roomId) {
      return;
    }

    const result = await leaveRoom(roomId);

    if (result) {
      this.handleSetUrl();

      this.setState({ modalVisible: false });
    }
  }

  componentDidCache() {
    console.log('it cached');
  }

  componentDidRecover() {
    console.log('it recovers');
  }

  closeModal = () => this.setState({ modalVisible: false });

  renderChatPanel = (props) => <ChatPanel {...props} setRoomId={this.setRoomId} />;

  render() {
    const { ctx, leaveChatRoomTextType, modalVisible } = this.state;
    const leaveText = leaveChatRoomText[leaveChatRoomTextType];

    const { history, match, staticContext, location } = this.props;
    const rp = { history, match, staticContext, location, className: 'flex-full' };
    const { name, ...honeyRouteProps } = getRouteByName(ROUTE_NAMES.IM_DETAIL)!;

    return (
      <div className={s.imWrapper}>
        {/* <ImSocket /> */}

        <ImContext.Provider value={ctx}>
          <RoomList />

          <HoneyRoute
            {...rp}
            {...honeyRouteProps}
            render={this.renderChatPanel}
          />

          <IrreversibleConfirm
            visible={modalVisible}
            title={leaveText.title}
            content={leaveText.content}
            footer={leaveText.title}
            onCancel={this.closeModal}
            onOk={this.handleLeaveRoom}
          />

        </ImContext.Provider>
        <NoMessage />
      </div>
    );
  }
}

const mapState = ({ user, friend: { friends: { byId } }, chatRoomList }: RootState) => {
  const friends = friendPicker(user, byId);
  return { friends, chatRoomIds: chatRoomList.roomList.allIds };
};

const mapDispatch = ({ chatPanel, chatRoomList }: RootDispatch) => ({
  setCurrentRoomId: chatRoomList.setCurrentRoomId,
  leaveRoom: chatPanel.leaveRoom
});

// Fix me: type define
export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch as any)(IM);
