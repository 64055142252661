import { createModel } from '@rematch/core';
import produce from 'immer';
import { merge, has, get, isEmpty, isArray, cloneDeep } from 'lodash';
import axios from 'axios';

import { NpiApi, attachmentApi } from 'src/services/net';
import { BasicPagiParams, NpiStep, NpiWaitSide } from 'src/services/net/npi';
import { RootState } from 'src/store';
import { handleNeedConfirmAttachment } from './handle';
import { InitialState, ConfirmAttachments, UpdateDrawingConfirmStatusParams } from './interface';

const initialState: InitialState = {
  trial: { list: [], count: 0, detail: void 0, confirmAttachments: void 0 },
  members: [],
};

type InitialDetailData = {
  detail: Obj;
  confirmAttachments: ConfirmAttachments;
};

export * from './selectors';

export default createModel({
  state: initialState,
  reducers: {
    setTrialList(state: InitialState, payload: { data: any[], count: number, replace?: boolean }) {
      return produce(state, draft => {
        const { count, data, replace } = payload;
        if (replace) {
          draft.trial = { list: [], count: 0, detail: void 0, confirmAttachments: void 0 };
        }

        const { list } = draft.trial;

        draft.trial.count = count;

        data.forEach(trial => {
          const find = list.find(it => it._id === trial._id);

          if (find) {
            Object.assign(find, {
              ...trial,
              key: trial._id
            });
          } else {
            list.push({
              ...trial,
              key: trial._id
            });
          }
        });
      });
    },

    // 展开npi流程时候，写入初始化数据
    setTrialDetail(state: InitialState, payload: InitialDetailData) {
      return produce(state, draft => {
        const { detail, confirmAttachments } = payload;
        const trial = draft.trial;

        trial.detail = detail;
        trial.confirmAttachments = confirmAttachments;
      });
    },

    // 图纸确认或拒绝以后更新对应的附件数据
    updateConfirmAttachmentsData(state: InitialState, payload: UpdateDrawingConfirmStatusParams) {
      const { drawingName } = payload;

      return produce(state, draft => {
        const confirmAttachments = draft.trial.confirmAttachments;

        confirmAttachments![drawingName] = {
          ...confirmAttachments![drawingName],
          ...payload
        };
      });
    },
  },
  effects: (dispatch) => ({
    async asyncSearchTrial(payload: BasicPagiParams, rootState: any) {

      const { data } = await NpiApi.search(payload);
      this.setTrialList({ ...data, replace: true });
    },

    async handleTrialDetail(data: any) {
      const detail = cloneDeep(data);
      const getReqs = (idsArr) => idsArr.reduce(
        (prev, cur) => {
          if (cur) {
            prev.push(attachmentApi.getDetail(cur));
          }

          return prev;
        },
        [] as any
      );
      const attachment = (_id, versions) => ([{
        uid: _id,
        name: versions[0].filename,
        url: attachmentApi.download({ attachmentId: _id })
      }]);
      const ees = get(detail, 'engineerEvaluations');
      const frs = get(detail, 'formalRuns');

      if (isArray(ees)) {
        // 风险试生产-试生产报告
        const eesReportIds = ees.map(item => item.engineerEvaluationReport);

        await axios.all(getReqs(eesReportIds)).then(axios.spread(
          (...args) => {
            args.forEach(({data: respData}) => {
              const { _id, versions } = respData;

              eesReportIds.forEach((eesId, index) => {
                if (eesId) {
                  detail.engineerEvaluations[index].engineerEvaluationReport = attachment(_id, versions);
                }
              });
            });
          }
        ));
      }

      if (isArray(frs)) {
        // 正式试生产-试生产报告
        const frsReportIds = frs.map(item => item.engineerEvaluationReport);

        await axios.all(getReqs(frsReportIds)).then(axios.spread(
          (...args) => {
            args.forEach(({data: respData}) => {
              const { _id, versions } = respData;

              frsReportIds.forEach((frsId, index) => {
                if (frsId) {
                  detail.formalRuns[index].engineerEvaluationReport = attachment(_id, versions);
                }
              });
            });
          }
        ));
      }

      // 其余附件
      const riskReportId = get(detail, 'riskEvaluation.riskAssessmentReport');
      const reliabilityReportId = get(frs, `${frs.length - 1}.reliabilityReport`);

      await axios.all(getReqs([riskReportId, reliabilityReportId])).then(axios.spread(
        (...args) => {
          args.forEach(({data: respData}) => {
            const { _id, versions } = respData;

            const resolveFile = (key, resp?, preRole?) => {
              const riskAssessmentReport = get(detail, 'riskEvaluation.riskAssessmentReport');
              const reliabilityReport = resp && get(detail, `${preRole}s.${resp.length - 1}.reliabilityReport`);

              if (key === 'riskAssessmentReport' && riskAssessmentReport) {
                detail.riskEvaluation.riskAssessmentReport = attachment(_id, versions);
              }

              if (key === 'reliabilityReport' && reliabilityReport) {
                detail[`${preRole}s`][resp.length - 1].reliabilityReport = attachment(_id, versions);
              }
            };

            // 风险评估报告
            resolveFile('riskAssessmentReport');
            // 正式试生产可靠性报告
            resolveFile('reliabilityReport', frs, 'formalRun');
          });
        }));

      return {
        detail,
        confirmAttachments: handleNeedConfirmAttachment(data)
      };
    },

    async asyncSetTrialDetail(data: any) {
      this.setTrialList({ data: [data] });

      const formatted = await this.handleTrialDetail(data);

      this.setTrialDetail(formatted);
      console.log('async setTrial resolve');
    },

    // 获取详情
    async asyncGetTrialDetail(id: string) {
      const { data } = await NpiApi.fetchNpiDetail(id);
      await this.asyncSetTrialDetail(data);
    },

    // 新建、编辑
    async asyncSubmitTrialData(value: any, rootState: any) {
      const { status, _id } = value;
      const [ step ] = status;
      const detail = rootState.rdManage.trial.detail;

      let data: any = {};
      let extra: any = {};

      switch (step) {
        case NpiStep.riskEvaluation:
          data = merge({}, value.riskEvaluation);

          ['isAnyHighRiskPoint', 'isApproveToDoRiskRun'].forEach(key => {
            if (has(data, key)) {
              const val = data[key];
              const isTrue = val.toString() === 'true';
              data[key] = isTrue;
            }
          });

          break;
        case NpiStep.engineerEvaluation:
          data = (merge({}, value).engineerEvaluations || []).slice().pop() || {};

          if (has(data, 'isApproveToDoRiskRun')) {
            const val = data.isApproveToDoRiskRun;
            data.isApproveToDoRiskRun = val.toString() === 'true';
          }

          break;
        case NpiStep.formalRun:
          data = (merge({}, value).formalRuns || []).slice().pop() || {};

          if (has(data, 'isApproveToDoRiskRun')) {
            const val = data.isApproveToDoRiskRun;
            data.isApproveToDoRiskRun = val.toString() === 'true';
          }

          break;
        default:
      }

      Reflect.deleteProperty(data, 'attachments');
      Reflect.deleteProperty(data, 'riskAssessmentReport');
      Reflect.deleteProperty(data, 'reliabilityReport');
      Reflect.deleteProperty(data, 'engineerEvaluationReport');

      // 在wait_admin状态或没有风险试生产且没有值状态下不需要发请求
      if (
        status[2] === NpiWaitSide.waitAdmin
        || (status[0] === NpiStep.engineerEvaluation && status[1] === 'PASS' && isEmpty(detail.engineerEvaluations))
      ) {
        //
      } else {
        await NpiApi.updateNpi({ ...extra, step, _id, data });
      }
    },

    // 更新某个图纸的确认状态，只有试封单有这个需求
    async asyncUpdateDrawingConfirmStatus(payload: UpdateDrawingConfirmStatusParams, rootState: RootState) {
      const trialData = rootState.rdManage.trial;
      const { detail, confirmAttachments } = trialData;
      const { _id } = detail;
      const { drawingName, isConfirmed, rejectReason } = payload;

      if (isEmpty(confirmAttachments)) return;

      const attachmentItem = confirmAttachments![drawingName];

      if (isEmpty(attachmentItem)) return;

      // 更新对应图纸的状态
      await NpiApi.confirmDiagram({
        npiId: _id,
        diagramName: drawingName,
        isConfirmed,
        rejectReason,
      });

      // 更新store中的数据
      let updateData: UpdateDrawingConfirmStatusParams = { isConfirmed, drawingName };

      if (!isConfirmed) {
        updateData = { ...updateData, rejectReason: rejectReason! };
      }

      this.updateConfirmAttachmentsData(updateData);
    }
  })
});
// tslint:disable-next-line
