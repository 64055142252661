import store from 'src/store';

export async function changeOrgId(orgId: string) {
  const { org: { orgId: prevOrgId } } = store.getState();

  if (orgId === prevOrgId) {
    return { changed: false };
  }

  await store.dispatch.org.setOrgId(orgId);
  return { changed: true };
}
