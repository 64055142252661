/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { getUserName } from '@chipcoo/fe-utils';

import UserAvatar from './UserAvatar';
import BasicInfo from './BasicInfo';

import { RootState, RootDispatch } from 'src/store';

interface OwnProps {}
interface StateProps {
  profile: User;
}
interface DispatchProps {
  modifyBasicInfo: (val: User & { type: string }) => void;
}
type Props = OwnProps & StateProps & DispatchProps;

class BasicInfoContainer extends React.PureComponent<Props> {

  // 提交基本信息
  submitBasicInfo = async (val) => {
    const { profile, modifyBasicInfo } = this.props;
    await modifyBasicInfo({...profile, ...val, type: ''});
  }

  // 完成修改头像
  modifyAvatar = async (avatar) => {
    const { profile, modifyBasicInfo } = this.props;

    await modifyBasicInfo({ ...profile, avatar, type: 'avatar' });
  }

  render () {
    const { profile } = this.props;

    return (
      <Card title="我的资料" bordered={false}>
        <UserAvatar
          src={profile.avatar!}
          name={getUserName(profile)!}
          userId={profile._id}
          modifyAvatar={this.modifyAvatar}
        />

        <BasicInfo profile={profile} onSubmit={this.submitBasicInfo} />
      </Card>
    );
  }
}

const mapState = ({ session: { profile } }: RootState) => ({ profile: profile! });
const mapDispatch = ({ session: { putBasicInfo } }: RootDispatch) => ({
  modifyBasicInfo: putBasicInfo,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(BasicInfoContainer);
