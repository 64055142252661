/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { Card, Divider } from 'antd';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import style from './index.module.less';
import { RootState } from 'src/store';
import { IOrgDetail } from 'src/models/org/interface';

import { getRouterProps } from 'src/components/HoneyRouter';
import { HoverScrollBarContainer } from 'src/components';
import { Transfer, Dismission, Leave } from 'src/containers/AdvancedSetting';

interface OwnProps {
  orgId: string;
}
interface StateProps {
  orgListById: { [id: string]: IOrgDetail };
}
interface DispatchProps {}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface State {
}

class Advance extends React.PureComponent<Props, State> {
  render () {
    const { orgId, orgListById,  } = this.props;
    const routerProps = getRouterProps(this.props);
    const role = orgListById[orgId] && orgListById[orgId].role ;
    if (!role) { return null; }

    return (
      <HoverScrollBarContainer className={style.advanced}>
        <Card bordered={false}>
          {role === 'OWNER' ? (
            <div>
              <Transfer {...routerProps} type="org" id={orgId} />
              <Divider/>
              <Dismission {...routerProps} type="org" id={orgId} />
            </div>
          ) : <Leave {...routerProps} type="org" id={orgId} />}
        </Card>
      </HoverScrollBarContainer>
    );
  }
}

const mapState = ({ org: { orgList: { byId } } }: RootState) => ({ orgListById: byId });

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(Advance);
