import * as React from 'react';
import { isEqual } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import { SelectWithDynamicOptionConfig } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  productModel: EnumProductModel;
  setFieldsValue?: (obj: Object, callback?: Function) => void;
  formStatus: FormStatus;
}

class Product extends React.PureComponent<Props> {

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { value, setFieldsValue } = this.props;

    if (!isEqual(value, prevProps.value)) {
      setFieldsValue && setFieldsValue({
        quantity: undefined,
        waferQuantity: undefined,
        // 加这一项，会使切换产品后，修改waferQuantity或quantity的值后，导致页面rc-form报错崩溃
        // selectionMode: undefined,
        // markingContentFrom: undefined,
        orderTemplate: undefined,
        // selectedData: undefined,
      });
    }
  }

  onChange = (value) => {
    const { onChange } = this.props;

    onChange && onChange(value);
  }

  render () {
    const { productModel, formStatus, value } = this.props;

    if (formStatus === 'preview') {
      return <span>{value?.label || value}</span>;
    }

    return (
      <SelectWithDynamicOptionConfig
        {...this.props}
        optionKey="getProductByProductModel"
        extraFetchParams={{ productModel }}
        addNew={null}
        onChange={this.onChange}
      />
    );
  }
}

export default Product;
