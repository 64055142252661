import React from 'react';
import { Switch, matchPath } from 'react-router-dom';
import { __RouterContext, RouteComponentProps, RouteProps } from 'react-router';

import { Fragment } from './components';
import { isNull, get } from './utils/lodash';

const { Consumer } = __RouterContext;

export class CacheSwitch extends Switch {
  renderFragment = (context: RouteComponentProps<any>) => {
    //
    const { match: contextMatch, location } = context;
    const { children } = this.props;

    let alreadyMatched = false;

    return (
      <Fragment>
        {React.Children.map(children, element => {
          if (!React.isValidElement(element)) {
            return null;
          }

          const {
            path: pathProp,
            exact,
            strict,
            sensitive,
            from
          } = element.props as RouteProps as any;
          // 不知道这个from哪里来的，先放着。
          const path = pathProp || from;
          const match = alreadyMatched
            ? null
            : matchPath(
                location.pathname,
                { path, exact, strict, sensitive },
                contextMatch
              );

          let child;
          switch (get(element, 'type.componentName')) {
            case 'CacheRoute':
              child = React.cloneElement<any>(element, {
                location,
                /**
                 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Route.js#L57
                 *
                 * Note:
                 * Route would use computedMatch as its next match state ONLY when computedMatch is a true value
                 * So here we have to do some trick to let the unmatch result pass Route's computedMatch check
                 *
                 * 注意：只有当 computedMatch 为真值时，Route 才会使用 computedMatch 作为其下一个匹配状态
                 * 所以这里我们必须做一些手脚，让 unmatch 结果通过 Route 的 computedMatch 检查
                 */
                computedMatch: isNull(match)
                  ? {
                      __CacheRoute__computedMatch__null: true
                    }
                  : match
              });
              break;
            default:
              child =
                match && !alreadyMatched
                  ? React.cloneElement<any>(element, {
                      location,
                      computedMatch: match
                    })
                  : null;
          }

          if (!alreadyMatched) {
            alreadyMatched = !!match;
          }

          return child;
        })}
      </Fragment>
    );
  }

  render() {
    return (
      <Consumer>
        {this.renderFragment}
      </Consumer>
    );
  }
}

export default CacheSwitch;
