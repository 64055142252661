/**
 * 多行文字超出ellipsis，tooltip由样式控制
 */
import * as React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { isNumber, uniqueId } from 'lodash';
import { TooltipProps } from 'antd/lib/tooltip';

import styles from '../index.module.less';

interface Props {
  children: React.ReactNode;
  toolTipTitle?: TooltipProps['title'];
  toolTipProps?: Omit<TooltipProps, 'title'>;
  overflowWidth?: number;
  lines?: number; // 最大行数
  className?: string;
}
interface State {
  hasOverflowingChildren: boolean;
  isNoNeedToolTip: boolean;
}

const isSupportLineClamp = document.body.style as any !== undefined;

const ellipsisDefaultStyle: React.CSSProperties = {
  overflow: 'hidden',
  overflowWrap: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
};

export default class LinesEllipsis extends React.PureComponent<Props, State> {
  private textRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      hasOverflowingChildren: false,
      isNoNeedToolTip: false,
    };
  }

  componentDidMount(): void {
    const el = this.textRef.current;

    if (!el) return;

    const { overflowWidth, lines } = this.props;
    const { hasOverflowingChildren } = this.state;

    if (!hasOverflowingChildren) {
      const { scrollWidth, clientWidth } = el;
      const lineWidth = isNumber(overflowWidth) ? overflowWidth : clientWidth;
      const compareWidth = lines ? lines * lineWidth : lineWidth;

      if (scrollWidth > compareWidth) {
        this.setState({ hasOverflowingChildren: true });
      } else {
        // 多行且scrollWidth小于compareWidth

        this.setState({ isNoNeedToolTip: true });
      }

    } else {
      this.setState({ hasOverflowingChildren: false });
    }

  }

  render () {
    const { hasOverflowingChildren, isNoNeedToolTip } = this.state;
    const { children, toolTipProps, toolTipTitle, lines, className } = this.props;
    const cls = classNames(styles.ellipsis, className, {
      [styles.lines]: lines && !isSupportLineClamp,
      [styles.lineClamp]: lines && isSupportLineClamp,
    });
    // const userAgent = window.navigator.userAgent;

    const id = `antd-pro-ellipsis-${`${uniqueId('ellipsis_id_')}`}`;
    const style = `#${id}{-webkit-line-clamp:${lines};-webkit-box-orient: vertical;}`;

    if (hasOverflowingChildren) {
      // if (userAgent.includes('Firefox')) {
      //   return null;
      // }

      const node = (
        <div id={id} className={cls}>
          <style>{style}</style>
          {children}
        </div>
      );

      if (!toolTipTitle) return node;

      return (
        <Tooltip overlayClassName="overlay-style" title={toolTipTitle} mouseEnterDelay={0.5} {...toolTipProps}>
          {node}
        </Tooltip>
      );
    }

    if (lines && lines > 1 && isNoNeedToolTip) {
      return (
        <div id={id} className={cls}>
          <style>{style}</style>
          {children}
        </div>
      );
    }

    return (
      <div style={ellipsisDefaultStyle} ref={this.textRef}>
        {children}
      </div>
    );
  }
}
