export function toKey(...params: (string | undefined)[]) {
  return params.filter(it => it).join(':');
}

export function toParam(key: string) {
  return key.split(':');
}

export function getResourceKey(res: IResource) {
  const { name, id, role } = res;
  return toKey(name, id, role);
}
