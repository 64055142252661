import React, { PureComponent } from 'react';
import { RegisterBehaviour, RegisterBehaviourProps } from 'gg-editor';

interface Props {}
interface State {}

export const UNSAVED_SIGNAL = 'unSavedChanges';

function enhancedSelect(page: any, t: any) {
  if (page.get('multiSelectable') && !0 === page.getSignal('shiftKeyDown')) {
    page.setSelected(t.item.id, !0);
  } else {
    const selected = page.getSelected();
    const isSelected = selected.some(node => node.id === t.item.id);

    if (!isSelected) {
      page.clearActived();
      page.clearSelected();
      page.setSelected(t.item.id, !0);
    }
  }
}

const behaviors: RegisterBehaviourProps[] = [
  {
    name: 'clickNodeSelectedEnhanced',
    behaviour: (page) => {
      page.getGraph().behaviourOn('node:click', async (t) => {
        enhancedSelect(page, t);
      });
    }
  },
  {
    name: 'clickGroupSelectedEnhanced',
    behaviour: (page) => {
      page.getGraph().behaviourOn('node:click', async (t) => {
        enhancedSelect(page, t);
      });
    },
  },
  {
    name: 'clickEdgeSelectedEnhanced',
    behaviour: (page) => {
      page.getGraph().behaviourOn('node:click', async (t) => {
        enhancedSelect(page, t);
      });
    },
  }
];

export const behaviorList = [
  'panBlank',
  'hoverGroupActived',
  'keydownCmdWheelZoom',
  'clickEdgeSelectedEnhanced',
  'clickNodeSelectedEnhanced',
  'clickCanvasSelected',
  'clickGroupSelectedEnhanced',
  'hoverNodeActived',
  'hoverEdgeActived',
  'hoverButton',
  'clickCollapsedButton',
  'clickExpandedButton',
  'wheelChangeViewport',
  'keydownShiftMultiSelected',
  'dragNodeAddToGroup',
  'dragOutFromGroup',
  'panItem',
  'hoverEdgeControlPoint',
  'dragEdgeControlPoint',
];

export class RegisterBehaviors extends PureComponent<Props, State> {
  render() {
    return behaviors.map(behaviour => {
      return (<RegisterBehaviour key={behaviour.name} {...behaviour} />);
    });
  }
}

export default RegisterBehaviors;
