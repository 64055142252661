/**
 * 将antd的基础组件，比如Input、TextArea之类的给转换成受控组件，方便直接使用
 */
import * as React from 'react';
import { getDisplayName } from '@chipcoo/fe-utils';
import { env } from 'src/config/env';

interface ExternalProps {
  value?: any;
  resetFlag?: boolean;
  onChange?: (value: any) => void;
  // 在父组件调用这个回调以后直接手动改变受控组件的value
  getSetValueFn?: (fn: (value?: any) => void) => void;
  ref?: any;
}
interface State {
  value: any;
  resetFlag: boolean;
}

const ControlledComponent = <T extends {}>(
  Component: (React.ComponentClass<T> | React.SFC<T>)
) => {
  type Props = T & ExternalProps;

  class HOC extends React.PureComponent<Props, State> {
    static displayName = `Hoc(${getDisplayName(Component)})ControlledComponent`;
    static defaultProps = {
      value: void 0,
      resetFlag: false
    };
    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      if (nextProps.resetFlag !== prevState.resetFlag) {
        return {
          value: void 0,
          resetFlag: nextProps.resetFlag
        };
      }

      return null;
    }

    constructor(props: Props) {
      super(props);

      const { value, resetFlag, onChange, getSetValueFn } = props;

      this.state = { value, resetFlag: resetFlag! };

      onChange && onChange(value);
      getSetValueFn && getSetValueFn(this.changeValueByParent);

      if (env === 'local') {
        const name = getDisplayName(Component);
        const checkGroup = ['Input', 'InputNumber', 'TextArea', 'Search'];

        if (checkGroup.indexOf(name) === -1) {
          console.warn(`${Component}组件不能被HocControlledComponent组件包裹`);
        }
      }
    }

    // 将这个方法传给父组件以后，父组件直接调用去设置value
    changeValueByParent = (value) => {
      const { onChange } = this.props;

      onChange && onChange(value);

      this.setState({ value: value });
    }

    handleChange = (x) => {
      // TextArea这里获取到的是一个event，因此要做兼容处理
      const value = x.target ? x.target.value : x;

      this.setState({ value });

      const { onChange } = this.props;

      onChange && onChange(value);
    }

    render() {
      const {
        value: propsVal,
        onChange,
        resetFlag,
        getSetValueFn,
        forwardedRef,
        ...passThroughProps
      } = this.props as any;
      const { value } = this.state;

      return (
        <Component {...passThroughProps} ref={forwardedRef} value={value} onChange={this.handleChange} />
      );
    }
  }

  // @ts-ignore
  return React.forwardRef((props: Props, ref) => <HOC {...props} forwardedRef={ref} />) as React.ComponentClass<Props>;
};

export default ControlledComponent;
