/**
 * 聊天左侧的房间列表
 */
import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { RootDispatch } from 'src/store';
import styles from './index.module.less';

import ListHeader from './ListHeader';
import ListContent from './ListContent';

interface OwnProps {}
interface StateProps {}
interface DispatchProps {
  reset: () => void;
  getRoomList: () => void;
  createRoom: (userId: string[]) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class RoomList extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    props.getRoomList();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { reset, createRoom } = this.props;

    return (
      <div className={classnames(styles.roomListWrapper, 'flex')}>
        <ListHeader resetModel={reset} createRoom={createRoom} />
        <ListContent/>
      </div>
    );
  }
}

const mapDispatch = ({ chatRoomList }: RootDispatch) => ({
  getRoomList: chatRoomList.getRoomList,
  createRoom: chatRoomList.createRoom,
  reset: chatRoomList.reset
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(RoomList);
