/**
 * 很多table列表上固定需要展示当前负责人的名称，因此搞这么个简单组件，方便展示负责人
 */
import React from 'react';
import { get } from 'lodash';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import { RootState, connect } from 'src/store';

interface OwnProps {
  /**
   * 假如靠userId在对应的user model里面没有查到对应的user info(不排除这个可能)，所以这里要求强制性写入一个defaultUserName
   * 用来作为找不到对应的数据后默认显示的内容
   *
   * 主要使用场景：聊天那边是谁都能聊天的，所以不一定手上正好有对应的用户的user数据，因此这里可以手动写入一个默认值
   */
  defaultUserName: string;
  userId: number | string;
}

interface StateProps {
  userName?: string;
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps;

function UserName(props: Props) {
  const { userName, defaultUserName } = props;

  return <span>{userName || defaultUserName}</span>;
}

function mapState({ user: { list: { byId } } }: RootState, props: OwnProps) {
  const { userId } = props;
  const userInfo = get(byId, userId);

  let userName;

  if (userInfo) {
    userName = getUserNameEnhance(userInfo);
  }

  return { userName };
}

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(UserName);
