/**
 * 注入 context，pickCtx可以选择将哪些context 传入被包裹住的子组件
 */
import React, { PureComponent, ComponentType, ComponentClass, StatelessComponent, Context } from 'react';
import { pick, isBoolean } from 'lodash';
import invariant from 'tiny-invariant';

interface ExternalProps {}

/**
 * @param context React.createContext返回的 Context
 * @param pickKeys 需要被挑检的 context 数据，最后数据会被解构，
 * 传入string | string[]时，使用的 lodash pick 方法，具体看 lodash 文档，默认 false，表明一个都不要，传入 true 表明“我全都要！！！”
 */
const withContext = <U extends {}>(
  context: Context<U>,
  pickKeys: string | string[] | boolean = false
) =>
  <T extends {}>(
    WrappedComponent: (ComponentClass<T> | StatelessComponent<T>)
  ) => {
    type Props = T & ExternalProps;

    class HocWithContext extends PureComponent<Props> {
      static contextType = context;

      context!: React.ContextType<typeof context>;

      componentDidMount() {
        invariant(
          this.context !== void 0,
          `${WrappedComponent.displayName}组件必须放入<${context}.Provder>包裹中`
        );
      }

      render() {
        const ctx = isBoolean(pickKeys)
          ? (pickKeys ? this.context : {})
          : pick(this.context, pickKeys);

        return <WrappedComponent {...this.props as any} {...ctx} />;
      }
    }

    return HocWithContext as ComponentType<T>;
  };

export default withContext;
