import * as React from 'react';
import moment from 'moment';
import {
  FormConfig,
  FormItemConfig,
  DateFormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  SearchFormItemConfig,
  NumberGroupFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumNtoType } from '@chipcoo/constant';

import Flow from '../../components/Flow';
import { uniqueProductNameValidator } from './utils/validator';

const preOrderStatus: InputFormItemConfig = {
  type: 'input',
  dataKey: 'status',
  colClassName: 'hidden-form-item',
};

const fabFormItem: SearchFormItemConfig = {
  label: 'FAB',
  type: 'search',
  dataKey: 'factory',
  optionKey: 'mpwFactoryList',
  props: { addNew: null },
  required: true,
};

const productNameFormItem: InputFormItemConfig = {
  label: 'Customer Product name',
  type: 'input',
  dataKey: 'name',
  required: true,
  validate: uniqueProductNameValidator()
};

const ntoOrComboFormItem: InputFormItemConfig = {
  label: 'NTO or COMBO',
  type: 'input',
  dataKey: 'ntoOrCombo',
  required: true,
};

const chipSizeFormItem: NumberGroupFormItemConfig = {
  label: 'CHIP SIZE',
  type: 'numberGroup',
  dataKey: 'chipSize',
  unit: 'µm',
  required: true,
  groupItem: [
    {
      // placeholder: '尺寸: L',
      // range: '(0, 30]',
      precision: 0,
      // requiredMsg: '请填写芯片尺寸: L',
    },
    {
      // placeholder: '尺寸: W',
      // range: '(0, 30]',
      precision: 0,
      // requiredMsg: '请填写芯片尺寸: W',
    }
  ]
};

// const customerNameFormItem: InputFormItemConfig = {
//   label: 'Customer Name',
//   type: 'input',
//   dataKey: 'customerName',
//   required: true,
//   renderType: (formData, initialFormData, globalStatus) => {
//     return globalStatus === 'preview' ? 'normal' : 'none';
//   }
// };

const applicationFormItem: InputFormItemConfig = {
  label: 'Application',
  type: 'input',
  dataKey: 'application',
  required: true,
};

const perMonthForcastFormItem: InputFormItemConfig = {
  label: 'Wafer per month forcast',
  type: 'input',
  dataKey: 'waferPerMonthForcast',
  required: true,
};

const tapeOutDateFormItem: DateFormItemConfig = {
  label: 'Tapeout Date',
  type: 'date',
  dataKey: 'tapeOutDate',
  required: true,
  props: {
    disabledDate: current => {
      // antd升级至^3.12.3时，current类型定义显示该值可能为undefined
      if (!current) return true;
      return current < moment().subtract(1, 'day');
    }
  }
};

const technologyFormItem: InputFormItemConfig = {
  label: 'Technology',
  type: 'input',
  dataKey: 'technology',
  required: true,
};

const ipFormItem: InputFormItemConfig = {
  label: 'IP',
  type: 'input',
  dataKey: 'ip',
  required: true,
};

const flow = (modalName: string, ntoType: EnumNtoType): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'flow',
  colSpan: 24,
  render() {
    const label = ntoType === EnumNtoType.rev ? 'NTO改版' : 'NTO预定';
    const data = {
      nodes: [
        { id: '1', label: label },
        { id: '2', label: '信息审核' },
        { id: '3', label: '核对资料' },
        { id: '4', label: '填写LOT' },
        { id: '5', label: '商务对接' },
      ],
      edges: [
        { source: '1', target: '2' },
        { source: '2', target: '3' },
        { source: '3', target: '4' },
        { source: '4', target: '5' },
      ]
    };

    return (
      <Flow
        data={data}
        modalName={modalName}
      />
    );
  }
});

export const getNtoAddFormConfig = (modalName: string, ntoType: EnumNtoType): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '流程图' },
      groupItemConfig: [
        preOrderStatus,
        flow(modalName, ntoType)
      ] as FormItemConfig[],
      ifRender: (formData, initialFormData, globalStatus) => {
        return globalStatus === 'preview';
      }
    },
    {
      card: { title: '预订信息' },
      groupItemConfig: [
        fabFormItem,
        productNameFormItem,
        ntoOrComboFormItem,
        chipSizeFormItem,
        // customerNameFormItem,
        applicationFormItem,
        perMonthForcastFormItem,
        tapeOutDateFormItem,
        technologyFormItem,
        ipFormItem,
      ] as FormItemConfig[]
    },
  ]
});

const customProductNameFormItem: InputFormItemConfig = {
  label: 'Custom Product Name',
  type: 'input',
  dataKey: 'name',
  renderType() {
    return this.formStatus === 'preview' ? 'preview' : 'none';
  },
};

const formerProductNameFormItem: SearchFormItemConfig = {
  label: 'Former product name',
  type: 'search',
  dataKey: 'rtoFrom',
  required: true,
  optionKey: 'initialitionNtoList',
  props: { addNew: null }
};

const rtoDateFormItem: DateFormItemConfig = {
  label: 'RTO Date',
  type: 'date',
  dataKey: 'rtoDate',
  required: true,
  props: {
    disabledDate: current => {
      // antd升级至^3.12.3时，current类型定义显示该值可能为undefined
      if (!current) return true;
      return current < moment().subtract(1, 'day');
    }
  }
};

const rtoLayerFormItem: InputFormItemConfig = {
  label: 'RTO Layer',
  type: 'input',
  dataKey: 'rtoLayer',
  required: true,
};

const maskVersionFormItem: InputFormItemConfig = {
  label: 'Mask Version',
  type: 'input',
  dataKey: 'maskVersion',
  required: true,
};

export const getNtoEditFormConfig = (modalName: string, ntoType: EnumNtoType): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '流程图' },
      groupItemConfig: [
        preOrderStatus,
        flow(modalName, ntoType)
      ] as FormItemConfig[],
      ifRender: (formData, initialFormData, globalStatus) => {
        return globalStatus === 'preview';
      }
    },
    {
      card: { title: '改版信息' },
      groupItemConfig: [
        customProductNameFormItem,
        formerProductNameFormItem,
        rtoDateFormItem,
        rtoLayerFormItem,
        maskVersionFormItem,
      ] as FormItemConfig[]
    },
  ]
});
