import * as React from 'react';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { getRegionText, noop } from '@chipcoo/fe-utils';

import './index.less';
import { longArea, longerArea } from './longAreas';

interface Props {
  value?: string[];
  regionData: Obj;
  onChange?: (value: any) => void;
}

interface State {
  activeKey: string; // 当前是省市区哪一个panel
  p: string; // 省级编码
  c: string; // 市级编码
  d: string; // 区级编码
}

const { TabPane } = Tabs;
const excludeArea = ['810000', '820000'];

class PickPanel extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    const { value } = props;
    let activeKey = 'province';

    if (value?.[2]) activeKey = 'distinct';
    else if (value?.[1]) activeKey = 'city';

    this.state = {
      activeKey,
      p: value?.[0] || '',
      c: value?.[1] || '',
      d: value?.[2] || '',
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { p, c } = this.state;
    let state = {};

    // 如果修改了省份，那么需要清空市级和区级编码
    if (p !== prevState.p) {
      state = { c: '', d: '' };
    }

    if (c !== prevState.c) {
      state = { d: '' };
    }

    this.setState(state);
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  // 修改tabs的tab页
  handleTabChange = (activeKey) => {
    this.setState({ activeKey });
  }

  // 点击panel面板的逻辑
  handleClickLiNode = (liData) => {
    const { regionData, onChange } = this.props;
    const { activeKey, p, c } = this.state;
    let state: any = {};

    if (activeKey === 'province') {
      state = {
        ...state,
        activeKey: 'city',
        p: liData
      };
    }

    if (activeKey === 'city') {
      state = {
        ...state,
        c: liData
      };

      if (!excludeArea.includes(p)) {
        // 这个地址库 港澳是没有区级的
        state = {
          ...state,
          activeKey: 'distinct',
        };
      }
    }

    if (activeKey === 'distinct') {
      state = {
        ...state,
        // activeKey: 'distinct',
        d: liData
      };
    }

    this.setState(state);

    // 已选区级或省级是港澳且已选市级时触发
    const cCode = state?.c;
    const dCode = state?.d;
    // 这里多一层if是为了不让onchange一直触发
    if (dCode || (excludeArea.includes(p) && cCode)) {
      const regionCode = [p];
      let region = {};

      const province = regionData['86']?.[p];
      const data = [province];
      region = { ...region, province };

      // 港澳逻辑
      if (excludeArea.includes(p) && cCode) {
        const city = regionData[p]?.[cCode];

        data.push(city);
        regionCode.push(state.c);
        region = { ...region, city };
      }

      // 省市区逻辑
      if (dCode) {
        const city = regionData[p]?.[c];
        const distinct = regionData[c]?.[dCode];

        data.push(city);
        data.push(distinct);

        regionCode.push(c);
        regionCode.push(dCode);
        region = { ...region, city, distinct };
      }

      onChange && onChange({
        regionCode,
        regionText: getRegionText(region),
        regionCodeText: data,
      });
    }
  }

  // 这个code是上一级的code；eg. 在市级面板，这个code代表着已选省份的code
  getPanelNode = (panelKey: string, code?) => {
    const { regionData, value } = this.props;
    const { p, c, d } = this.state;
    const newCode = code ? code : '86';
    let activeCode: string | undefined = '';

    switch (panelKey) {
      case 'province':
        activeCode = value?.[0] || p;
        break;
      case 'city':
        activeCode = value?.[1] || c;
        break;
      case 'distinct':
        activeCode = value?.[2] || d;
        break;
      default:
        break;
    }

    const data = regionData[newCode];
    const keys = regionData[newCode] && Object.keys(regionData[newCode]);

    return (
      <ul className="ui-area-content-list">
        {
          keys?.map(it => (
            <li
              key={it}
              onClick={() => this.handleClickLiNode(it)}
              // 是否为长文字地区
              className={
                classnames(
                  longArea.includes(it) ? 'long-area' : undefined,
                  longerArea.includes(it) ? 'longer-area' : undefined,
                  activeCode === it && 'active-code'
                )
              }
            >
              {data[it]}
            </li>
          ))
        }
      </ul>
    );
  }

  render () {
    const { regionData } = this.props;
    const { activeKey, p, c, d } = this.state;

    return (
      <div className="ui-area-content-wrap">
        <div className="ui-area-content">
          <Tabs activeKey={activeKey} type="card" onChange={this.handleTabChange}>
            <TabPane
              key="province"
              tab={regionData?.['86']?.[p] || '请选择'}
            >
              {this.getPanelNode('province')}
            </TabPane>

            <TabPane
              key="city"
              tab={regionData?.[p]?.[c] || '请选择'}
              disabled={!p}
            >
              {this.getPanelNode('city', p)}
            </TabPane>

            <TabPane
              key="distinct"
              tab={regionData?.[c]?.[d] || '请选择'}
              disabled={!c || excludeArea.includes(p)}
            >
              {this.getPanelNode('distinct', c)}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default PickPanel;
