/**
 * 是否允许临时更改
 * @constructor
 */
import React from 'react';
import { Radio } from 'antd';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  text: string;
  formStatus: FormStatus;
  value?: boolean;
  onChange?: (bool: boolean) => void;
}

function AllowReplace(props: Props) {
  const { text, formStatus, value, onChange } = props;

  function handleOnChange(e: any) {
    onChange && onChange(e.target.value);
  }

  return (
    <div>
      <span>{text}?</span>
      <Radio.Group
        onChange={handleOnChange}
        value={value}
        disabled={formStatus === 'preview'}
        style={{ display: 'inline-block', marginLeft: 16 }}
      >
        <Radio value={true}>是</Radio>
        <Radio value={false}>否</Radio>
      </Radio.Group>
    </div>
  );
}

export default AllowReplace;
