import * as React from 'react';
import { CardTableV2 } from '@chipcoo/hanayo';

import { getTableColumns } from './getTableColumns';

interface Props {
  value?: any[];
}

const { EnhancedTable } = CardTableV2;

class SelectedTable extends React.PureComponent<Props> {
  render () {
    const { value } = this.props;

    return (
      <EnhancedTable
        getTableData={() => {
          //
        }}
        dataSource={value || []}
        columns={getTableColumns()}
        showPagination={false}
      />
    );
  }
}

export default SelectedTable;
