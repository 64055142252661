/**
 * 用户的名片，各种需要列表显示某个用户的地方都可以用到这玩意，样子如下
 * -----  | -----
 *        |  名字
 * 头像    |
 *        |   邮箱
 * -----  | -----
 * 目前主要用在我的好友那边的好友列表，各种添加用户的那个列表
 */
import React from 'react';
import classnames from 'classnames';
import { getUserName } from '@chipcoo/fe-utils';

import './index.less';

import { EnhanceAvatar } from 'src/components';
import { HocProfileAvatar } from 'src/hoc';

interface P {
  userInfo: User;
  className?: string;
  otherContent?: string;
  extra?: string | React.ReactNode;
  messageTime?: string | React.ReactNode;
  isMessageCard?: boolean;
}

export class UserCard extends React.PureComponent<P> {
  render() {
    const { userInfo, className, otherContent, extra, messageTime, isMessageCard } = this.props;
    // 显示的名字，备注优先
    const userName = getUserName(userInfo);
    // 渲染头像的名字，昵称优先
    const avatarRenderText = userInfo.nickname || userInfo.name;
    const { avatar, avatarSrc, email, phone } = userInfo;
    const content = otherContent || email || phone;
    const clsNames = classnames(className, 'universe-user-card flex align-center', isMessageCard && 'message-card');

    return (
      <div className={clsNames}>
        <EnhanceAvatar src={avatarSrc || avatar} userName={avatarRenderText} />
        <div className="info cell ellipsis">
          <div className="name">
            <span className="user-name">{userName}</span>
            {messageTime && <span className="time">{messageTime}</span>}
          </div>
          <div className="content">{content}</div>
          {extra && <div className="extra">{extra}</div>}
        </div>
      </div>
    );
  }
}

export const HocUserCard = HocProfileAvatar(UserCard);

export default UserCard;
