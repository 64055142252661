const { isProd, isTest, isDev } = require('./env.js');

function supportWebp(): boolean {
  const inBrowser = typeof window !== 'undefined';
  if (!inBrowser) return false;
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

    // very old browser like IE 8, canvas not supported
  return false;
}

const UA = (function (userAgent: string) {
  const ISOldIOS: any = /OS (\d)_.* like Mac OS X/g.exec(userAgent);
  const isOldAndroid: any = /Android (\d.*?);/g.exec(userAgent) || /Android\/(\d.*?) /g.exec(userAgent);

  return {
      oldIOS    : ISOldIOS ? + ISOldIOS.pop() < 8 : false,
      oldAndroid: isOldAndroid ? + isOldAndroid.pop().substr(0, 3) < 4.5 : false,
      iOS       : /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(userAgent),
      android   : /Android/g.test(userAgent),
      mQQBrowser: /MQQBrowser/g.test(userAgent),
  };
})(navigator.userAgent);

const isSupportWebp = supportWebp();

export {
  isProd,
  isDev,
  isTest,
  UA,
  isSupportWebp,
  supportWebp,
};
