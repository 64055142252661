import * as React from 'react';
import { List, Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';

import styles from './index.module.less';

import { compilePath } from 'src/components/HoneyRouter';
import { HoverScrollBarContainer } from 'src/components';

interface Props {
  dataSource: any[];
  params: any;
}

class RDManageAppList extends React.PureComponent<Props> {
  render () {
    const { dataSource, params } = this.props;
    // const length = dataSource?.length;

    return (
      <div style={{ width: '100%' }}>
        <HoverScrollBarContainer className={styles.appWrap}>
          <div className={styles.appList}>
            {
              dataSource.map((it, index) => {
                return (
                  <Card key={it?.title} title={it?.title} bordered={false}>
                    <div className={styles.typeArea}>
                      {/*<div className={styles.listTitle}>{it?.title}: </div>*/}
                      <List
                        grid={{ gutter: 20, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 6 }}
                        dataSource={it.children}
                        renderItem={(item: any) => (
                          <List.Item>
                            <Link to={compilePath({ name: item.route, query: item.query, params })}>
                              <Card bordered={false}>
                                <Avatar src={item.src} shape="square" size={100} />
                                <div className={styles.title}>{item.title}</div>
                              </Card>
                            </Link>
                          </List.Item>
                        )}
                      />
                    </div>
                    {/*{index < length - 1 ? <Divider type="horizontal" /> : null}*/}
                  </Card>
                );
              })
            }
          </div>
        </HoverScrollBarContainer>
      </div>
    );
  }
}

export default RDManageAppList;
