import {
  connect as originalConnect,
  MapStateToPropsParam,
  MapDispatchToPropsParam,
  DispatchProp,
  InferableComponentEnhancerWithProps
} from 'react-redux';

import { Actions, RootState } from './index';

export type GetDispatchToProps<TDispatchProps, TOwnProps> = (actions: Actions) => TDispatchProps;

interface MyConnect {
  <no_state = {}, TDispatchProps = {}, TOwnProps = {}>(
    mapStateToProps: null | undefined,
    mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
  ): InferableComponentEnhancerWithProps<TDispatchProps, TOwnProps>;

  <TStateProps = {}, no_dispatch = {}, TOwnProps = {}>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, RootState>
  ): InferableComponentEnhancerWithProps<TStateProps & DispatchProp, TOwnProps>;

  <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, RootState>,
    mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
  ): InferableComponentEnhancerWithProps<TStateProps & TDispatchProps, TOwnProps>;
}

// 以上是正常的connect
export const connect = originalConnect as MyConnect;

export type DecoratorInferableComponentEnhancerWithProps<TNeedsProps> =
  <TComponent extends React.ComponentType<TNeedsProps>>(component: TComponent) => TComponent;

interface DecoratorConnect {
  <no_state = {}, TDispatchProps = {}, TOwnProps = {}>(
    mapStateToProps: null | undefined,
    mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
  ): DecoratorInferableComponentEnhancerWithProps<TOwnProps>;

  <TStateProps = {}, no_dispatch = {}, TOwnProps = {}>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, RootState>
  ): DecoratorInferableComponentEnhancerWithProps<TOwnProps>;

  <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, RootState>,
    mapDispatchToProps: GetDispatchToProps<TDispatchProps, TOwnProps>
  ): DecoratorInferableComponentEnhancerWithProps<TOwnProps>;
}

// 下面是装饰器的connect
export const connectDecorator = originalConnect as DecoratorConnect;
