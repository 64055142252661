// tslint:disable:max-file-line-count
import React, { ComponentType } from 'react';
import { EnumProductModel } from '@chipcoo/constant';
import { FormCreator, ModalMode } from '@chipcoo/hanayo/lib/FormCreator';

import './index.less';
import { getFormConfig } from './formConfig';
import { DemandOrderFormModalCtx } from '../../NewOrderModal/context';
import { connectDecorator, RootState } from 'src/store';
import { IOrgDetail } from 'src/models/org/interface';
import { getPreviewReqOrderData } from 'src/models/operation/demandOrder/handle';
import { getOrgIdByUrl } from 'src/utils';

interface OwnProps {
  formData: any;
  mode: ModalMode;
}

interface StateProps {
  orgVerify: IOrgDetail['verify'];
  orgMembers: any;
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  productModel: EnumProductModel | undefined;
  formData: any;
}

@connectDecorator(
  ({
    org: { orgVerify, orgMembers },
   }: RootState) => ({
    orgVerify, orgMembers
  })
)
class PreviewConfig extends React.PureComponent<Props, State> {
  static contextType = DemandOrderFormModalCtx;

  state = {
    productModel: undefined,
    formData: {}
  };

  async componentDidMount() {
    await this.getRestData();
  }

  getRestData = async () => {
    const { formData, orgMembers } = this.props;
    const stashData = formData || this.context.stashData;
    const orgId = getOrgIdByUrl();

    if (orgId) {
      const state = await getPreviewReqOrderData({
        formData,
        orgMembers,
        stashData
      });

      this.setState(state);
    }
  }

  render () {
    const { mode } = this.props;
    const { formData, productModel } = this.state;

    // todo 看看能不能把这层form去掉
    /**
     * 这里第二层Form的formData来源于两层数据源拼接：
     * 1.外面传进来的表单initialFormData
     * 2.自己根据组织id等获取到的数据
     */
    return (
      <FormCreator
        formConfig={getFormConfig(mode, formData)}
        globalStatus="preview"
        formData={{...formData, productModel}}
        className="order-pre-form"
      />
    );
  }
}

export default PreviewConfig as ComponentType<OwnProps>;
