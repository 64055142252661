import * as React from 'react';
import { EnumProductModel } from '@chipcoo/constant';
import { isNumber } from 'lodash';

import { EditableColumnsProps } from './index';
import { numberRangeValidatorByFormData } from './validator';

interface Option {
  productModel: EnumProductModel;
}

export const getTableColumns = (option: Option): EditableColumnsProps[] => {
  const { productModel } = option;
  let detailAmount: any[] = [];

  if (productModel === EnumProductModel.assemblyProduct) {
    detailAmount = [
      {
        dataIndex: 'bins',
        key: 'bins',
        title: 'Bin类型',
        width: 250,
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        title: '出库数量',
        width: 150,
        editable: true,
        rules: (data) => numberRangeValidatorByFormData(data)
      },
      {
        dataIndex: 'restAmount',
        key: 'restAmount',
        title: '剩余数量',
        width: 100,
        render: (text, record) => {
          const { amount, originAmount } = record;
          const count = originAmount - amount;

          return <div>{isNumber(count) ? count : 0}</div>;
        }
      }
    ];
  } else {
    detailAmount = [
      {
        dataIndex: 'waferIds',
        key: 'waferIds',
        title: '片号',
        width: 150,
        render: text => {
          return <span>{text ? '#' + text : '/'}</span>;
        }
      },
      {
        dataIndex: 'good',
        key: 'good',
        title: '出库良品数',
        width: 150,
        editable: true,
        rules: (data) => numberRangeValidatorByFormData(data, 'good')
      },
      {
        dataIndex: 'bad',
        key: 'bad',
        title: '出库不良品数',
        width: 150,
        editable: true,
        rules: (data) => numberRangeValidatorByFormData(data, 'bad')
      },
      {
        dataIndex: 'restGood',
        key: 'restGood',
        title: '剩余良品数',
        width: 100,
        render: (text, record) => {
          const { good, originAmount } = record;
          const count = originAmount?.good - good;

          return <div>{isNumber(count) ? count : 0}</div>;
        }
      },
      {
        dataIndex: 'restBad',
        key: 'restBad',
        title: '剩余不良品数',
        width: 100,
        render: (text, record) => {
          const { bad, originAmount } = record;
          const count = originAmount?.bad - bad;

          return <div>{isNumber(count) ? count : 0}</div>;
        }
      }
    ];
  }

  return [
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: '产品名称',
      width: 150
    },
    {
      dataIndex: 'materialName',
      key: 'materialName',
      title: '物料名称',
      width: 200,
    },
    {
      dataIndex: 'attributes',
      key: 'attributes',
      title: '是否为蓝膜',
      width: 100,
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 150
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      width: 200
    },
    {
      dataIndex: 'packingMethod',
      key: 'packingMethod',
      title: '包装方式',
      width: 100
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 150,
    },
    ...detailAmount,
  ];
};
