/**
 * Created by marvin on 18/8/8.
 */

import * as React from 'react';
import { Table, Button, Input, Tag, Divider, Popconfirm } from 'antd';
import { TableProps } from 'antd/lib/table/interface';

import { AddressParam, Columns } from './interface';

import { AddressSelector } from 'src/containers';

interface Props extends TableProps<any> {
  dataSource: AddressParam[];
  addressConfig: Columns[];
  onAdd?: () => void;
  onSave?: (e: any, val: AddressParam) => void;
  onRemove?: (val: AddressParam, type?: string) => void;
  onCancel?: (e: any, val: AddressParam) => void;
  onChangeCascader?: (e: any, f: string, val: AddressParam) => void;
  getDetailName?: (val: string[]) => void;
  getRegionCode?: (val: string[]) => void;

  handleAddressChange?: (val: any) => void;
}
class Address extends React.PureComponent<Props> {

  // 新建地址
  addAddress = () => {
    const { onAdd } = this.props;
    onAdd && onAdd();
  }

  // 保存地址
  saveRow = (e, val) => {
    const { onSave } = this.props;
    onSave && onSave(e, val);
  }

  // 删除地址行
  removeRow = (val, type?) => {
    const { onRemove } = this.props;
    onRemove && onRemove(val, type);
  }

  // 取消编辑
  cancel = (e, val) => {
    const { onCancel } = this.props;
    onCancel && onCancel(e, val);
  }

  // 修改地址
  handleFieldChange = (e, fieldName, val) => {
    const { onChangeCascader } = this.props;
    onChangeCascader && onChangeCascader(e, fieldName, val);
  }

  // 回车保存
  handleKeyPress = (e, val) => {
    if (e.key === 'Enter') {
      this.saveRow(e, val);
    }
  }

  handleOnChange = (val) => {
    const { handleAddressChange } = this.props;

    handleAddressChange && handleAddressChange(val);
  }

  // 获取表格的Columns
  getColumns () {
    const { addressConfig } = this.props;
    let addressColumns = addressConfig.map((item, index) => {
      const { title, key, render, width } = item;
      let baseCol = {
        title,
        key,
        dataIndex: key,
        width,
        render: (text, record) => {
          if (record.editable) {
            let props = {
              value: text,
              onChange: e => this.handleFieldChange(e, key, record),
              onPressEnter: e => this.handleKeyPress(e, record),
              placeholder: render.placeholder
            };

            if (index === 0) { props = Object.assign(props, {autoFocus: true}); }

            return render.type === 'input'
              ? <Input {...props} />
              : (
                render.type === 'address'
                  ? (
                    <AddressSelector
                      value={text}
                      onChange={this.handleOnChange}
                    />
                  ) : null
              );
          }

          if (render.type === 'address') {
            return record.province + ' / ' + record.city + ' / ' + record.distinct;
          }
          if (key === 'labels' && record.labels.length) {
            return <Tag style={{fontSize: 14}} className="tag" color="#69c0ff">{record.labels[0]}</Tag>;
          }

          return text;
        }
      };
      return baseCol;
    });

    addressColumns.push({
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      render: (text, record) => {
        const editorOperate = (type: string, action?) => {
          return (
            <div>
              <a onClick={e => this.saveRow(e, record)}>{type}</a>
              <Divider type="vertical" />
              {
                record.isNew
                  ? (
                    <a onClick={() => this.removeRow(record, action)}>取消</a>
                  )
                  : (
                    <Popconfirm
                      title="是否要删除此行？"
                      okText="确定"
                      cancelText="取消"
                      onConfirm={() => this.removeRow(record, action)}
                    >
                      <a>删除</a>
                    </Popconfirm>
                  )
              }
            </div>
          );
        };

        if (record.editable) {
          if (record.isNew) {
            return editorOperate('确定', 'cancel');
          }
          return (
            <span>
              <a onClick={e => this.saveRow(e, record)}>确定</a>
              <Divider type="vertical" />
              <a onClick={e => this.cancel(e, record)}>取消</a>
            </span>
          );
        }
        return editorOperate('编辑');
      }
    });

    return addressColumns;
  }

  render () {
    const { dataSource, ...passThroughProps } = this.props as any;
    const columns = this.getColumns();

    return (
      <div>
        {dataSource.length ? (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            locale={{emptyText: '暂无数据'}}
            {...passThroughProps}
          />
        ) : null}
        <Button
          type="dashed"
          style={{ width: '100%', marginBottom: 8, marginTop: 16 }}
          icon="plus"
          onClick={this.addAddress}
        >
          添加地址
        </Button>
      </div>
    );
  }
}

export default Address;
