import * as React from 'react';
import _ from 'lodash';
import { SelectWithOption } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { productApi } from 'src/services/net';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  status: FormStatus;
  product: any;
}

interface State {
  options: any[];
}

class PackingMethodInDemandOrderModal extends React.PureComponent<Props, State> {
  state = { options: [] };

  async componentDidMount() {
    this.getOptions();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { product } = this.props;

    if (!_.isEqual(product, prevProps.product)) {
      this.getOptions();
    }
  }

  getOptions = async () => {
    const { product } = this.props;

    if (product) {
      let packing = product?.packing;
      const productId = product?.key;

      if (!packing && productId) {
        const { data } = await productApi.getProductById(productId);
        packing = data?.packing;
      }

      const options = packing.map(it => ({
        key: it?._id,
        label: it?.packingMethod
      }));

      this.setState({ options });
    }
  }

  render () {
    const { status, value, onChange } = this.props;
    const { options } = this.state;

    if (status === 'preview') {
      const packing: any = options.find(it => _.get(it, 'key') === value);
      return <span>{packing?.label || value || '--'}</span>;
    }

    return (
      <SelectWithOption
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }
}

export default PackingMethodInDemandOrderModal;
