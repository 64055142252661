import React from 'react';
import { IProcessDetail } from './interface';

interface ICraftRouteContext {
  orgId: string;
  mode: ICraftEditMode;
  processes: IProcessDetail[];
}

export type ICraftTypeNew = 'new';
export type ICraftTypeEdit = 'edit';

export type ICraftEditMode = ICraftTypeNew | ICraftTypeEdit;

export const CraftRouteContext = React.createContext<ICraftRouteContext>({
  orgId: '',
  processes: [],
  mode: 'new',
});
