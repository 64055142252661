import React from 'react';
import { Icon, Button, Divider } from 'antd';
import { inventoryApi } from 'src/services/net';
import { ModalType } from '../index';

interface CardExtraProps {
  queryParams: any;
  openQuery: (e: any) => void;
  openModal?: (detailId: string, modalType: ModalType) => void;
  openImportModal: () => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery, queryParams, openModal, openImportModal } = props;
  const openAddModal = () => {
    openModal!('', 'new');
  };

  return (
    <div>
      <a href={inventoryApi.exportStockInApply({...queryParams})}>
        <Icon type="export" />导出
      </a>
      <Divider type="vertical" />
      <a onClick={openQuery}>
        <Icon type="search" />查询
      </a>
      <Divider type="vertical" />
      <Button type="primary" onClick={openImportModal}>批量导入</Button>
      <Divider type="vertical" />
      <Button type="primary" onClick={openAddModal}>添加</Button>
    </div>
  );
}

export default React.memo(CardExtra);
