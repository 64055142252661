import _ from 'lodash';
import { getMomentTime, sortWaferId } from '@chipcoo/fe-utils';

export const handleDataSource = (data) => data.reduce(
  (prev, cur) => {
    const {
      _id,
      forecastWsDate,
      projectOutDate,
      updatedAt,
      waferStartDate,
      waferIds,
      ...restData
    } = cur;

    const curData = {
      key: _id,
      _id,
      forecastWsDate: getMomentTime(forecastWsDate),
      waferStartDate: getMomentTime(waferStartDate),
      projectOutDate: getMomentTime(projectOutDate),
      WIPUpdateDate: getMomentTime(updatedAt),
      waferIds: sortWaferId(waferIds),
      ...restData,
    };
    prev.push(curData);

    return prev;
  },
  [] as any[]
);
