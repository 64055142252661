import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { filterMenu } from '@chipcoo/fe-utils';

import BasicInfo from './BasicInfo';
import AdvSettings from './AdvSettings';
import Members from './Members';
import Moments from './Moments';
// import { TagManage } from './TagManage';

import styles from './style.module.less';
import { workspaceContext } from '../';

import { ROUTE_NAMES } from 'src/router/normalRouter';
import HoneyRoute, { getRouteByName, getRouterProps } from 'src/components/HoneyRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { Workspace } from 'src/models/workspace/interface';
import { RouteMenu } from 'src/components';

interface Props extends RouteComponentProps<any> {}
interface State {}

let menus: RouteMenuItem<Workspace>[] = [];

function fillMenu() {
  menus = [
    {
      title: '协作区信息',
      routeName: ROUTE_NAMES.ORG_COO_SETTINGS_BASIC,
      renderCondition: (ctx) => !ctx.isMaster,
      routeProps: {
        exact: true,
        component: BasicInfo,
      }
    },
    {
      title: '协作区成员',
      renderCondition: (ctx) => !ctx.isMaster,
      routeName: ROUTE_NAMES.ORG_COO_SETTINGS_MEMBERS,
      routeProps: {
        exact: true,
        component: Members,
      }
    },
    {
      title: '高级设置',
      renderCondition: (ctx) => !ctx.isMaster,
      routeName: ROUTE_NAMES.ORG_COO_SETTINGS_ADV,
      routeProps: {
        exact: true,
        component: AdvSettings,
      }
    },
    {
      title: '动态',
      routeName: ROUTE_NAMES.ORG_COO_MOMENTS,
      routeProps: {
        exact: true,
        component: Moments,
      }
    },
    // {
    //   title: '标签',
    //   routeName: ROUTE_NAMES.ORG_COO_TAGS,
    //   routeProps: {
    //     exact: true,
    //     component: TagManage,
    //   }
    // },
  ];
}

setTimeout(fillMenu);

class Production extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }

  renderSwitchContent = (item) => {
    const routerProps = getRouterProps(this.props);
    const { title, routeName, routeProps } = item;
    const route = getRouteByName(routeName)!;
    return (<HoneyRoute key={title} {...routerProps} {...route} {...routeProps} />);
  }

  render () {
    const { Consumer } = workspaceContext;
    const routerProps = getRouterProps(this.props);

    return (
      <div className={styles.productWrapper}>
        {/*todo 结合SiderMenu*/}
        <Consumer>
          {({ workspace }) => {
            const filtered = filterMenu(menus, workspace);
            return (<>
              <RouteMenu {...routerProps} context={workspace} menu={menus} />
              <div className={classnames(styles.productContent, 'y-scroll thin-scroll')}>
                {
                  filtered.map((item) => {
                    const { children } = item;

                    if (children) {
                      const childrenFiltered = filterMenu(children, workspace);
                      return childrenFiltered.map(i => this.renderSwitchContent(i));
                    } else {
                      return this.renderSwitchContent(item);
                    }
                  })
                }
              </div>
            </>);
          }}
        </Consumer>
      </div>
    );
  }
}

export default Production;
