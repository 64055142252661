import React, { PureComponent } from 'react';
import { get, isArray, flatten } from 'lodash';
import { Icon, Modal } from 'antd';
import classnames from 'classnames';
import { FormCreator } from '@chipcoo/hanayo';
import { FlexModal, FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumProductModel, EnumProcessClass } from '@chipcoo/constant';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';

import { getStockInConfig } from './stockInConfig';
import { createSelector } from 'reselect';

import { CSSTransition } from 'react-transition-group';
import UniverseCommentWrap from '../../components/UniverseCommentWrap';
import { ModalType } from '../index';
import store from 'src/store';
import { CommentResource } from 'src/containers/UniverseComment/interface';
import styles from './style.module.less';

interface IProps {
  visible: boolean;
  mode: ModalType;
  onOk?: (values: any) => any;
  onCancel?: () => any;
  formData?: any;
  productModel: EnumProductModel;
}
const modeTitle = {
  new: '添加',
  edit: '编辑',
  preview: '预览',
  reSubmit: '重新提交',
};
interface IState {
  commentVisible: boolean;
  modalFullWindow: boolean;
}

class ApplicationFormModal extends PureComponent<IProps, IState> {
  private formController: FormController | null;
  constructor (props: IProps) {
    super(props);

    this.state = {
      commentVisible: false,
      modalFullWindow: true,
    };
  }

  selectCommentResource = createSelector(
    (newProps: IProps) => get(newProps.formData, '_id'),
    (id): CommentResource => ({
      role: 'SYSTEM',
      id,
      name: 'stockInApply',
    }),
  );

  changeCommentVisible = () => {
    const { commentVisible } = this.state;
    this.setState({
      commentVisible: !commentVisible,
    });
  }

  onRequestFullWindow = () => {
    this.setState({
      modalFullWindow: true,
    });
  }

  onRequestNormalWindow = () => {
    this.setState({
      modalFullWindow: false,
    });
  }

  getFormConfig = () => {
    const { mode, productModel } = this.props;
    return getStockInConfig(mode, productModel);
  }

  renderComment = () => {
    const { formData, } = this.props;
    const { commentVisible } = this.state;
    if (!formData) {
      return null;
    }

    const resource = this.selectCommentResource(this.props);
    const commentState = store.getState();
    const userId = get(commentState, 'session.profile._id') || '';
    const wrapClassName = classnames('comment-toggle-btn', commentVisible ? 'comment-visible' : 'comment-invisible');
    return (
      <>
        <div className={wrapClassName} onClick={this.changeCommentVisible} >
          <Icon
            type={commentVisible ? 'right' : 'left'}
          />
        </div>
        <div>
          <CSSTransition
            in={commentVisible}
            appear={commentVisible}
            timeout={300}
            classNames="universe-comment"
          >
            <UniverseCommentWrap
              userId={userId}
              resource={resource}
              uploaderOptions={{}}
            />
          </CSSTransition>
        </div>
      </>
    );
  }

  getTitle = () => {
    const { mode } = this.props;
    return `${modeTitle[mode]}入库申请`;
  }

  handleSubmit = () => {
    const { onOk } = this.props;
    if (!this.formController) return;
    const { validateFieldsAndScroll } = this.formController;
    return new Promise((resolve) => {
      validateFieldsAndScroll(async (err, values) => {
        if (err) {
          return resolve(false);
        }
        if (onOk) {
          const uniwaferAmount = get(values, 'productInfos.product.perWaferDieQty');
          const classes = get(values, 'classes');

          const slices = get(values, 'slices') || flatten(get(values, 'detailMaterial')?.map(it => it.slices));

          // 入库单里用户输入的初始有效管芯数不能更改
          if (slices && isArray(slices) && classes === EnumProcessClass.rw) {
            const slicesItem = slices.find(it => {
              const { good } = it;

              return good !== uniwaferAmount;
            });

            if (slicesItem) {
              resolve(true);
              return new Promise((res) => {
                Modal.error({
                  content: `未测芯片初始有效管芯数为${uniwaferAmount},请修改后重新提交`,
                  zIndex: FlexModalManager.getNextZIndex()
                });
              });
            }
          }

          resolve(onOk(values));
        } else {
          resolve(true);
        }
      });
    });
  }

  render() {
    const { mode, formData, visible, onCancel } = this.props;
    const { commentVisible, modalFullWindow } = this.state;
    const className = classnames('common-flex-modal', styles.modal, commentVisible ? '' : 'full-center');
    const globalStatus = (mode === 'new' || mode === 'reSubmit') ? 'edit' : 'preview';

    return (
      <FlexModal
        width={1200}
        animation="fade"
        visible={visible}
        title={this.getTitle()}
        onOk={this.handleSubmit}
        onCancel={onCancel}
        className={className}
        fullWindow={modalFullWindow}
        onRequestFullWindow={this.onRequestFullWindow}
        onRequestNormalWindow={this.onRequestNormalWindow}
        rightSection={this.renderComment()}
        footer={mode === 'preview' && null}
      >
        <FormCreator
          getFormController={controller => this.formController = controller}
          formConfig={this.getFormConfig()}
          globalStatus={globalStatus}
          formData={formData ? formData : undefined}
        />
      </FlexModal>
    );
  }
}

export default ApplicationFormModal;
