import { EnumAnalysisContent } from '@chipcoo/constant';
import { getMomentTime, sortWaferId } from '@chipcoo/fe-utils';
import { isEqual, isEmpty, uniq, get, toNumber } from 'lodash';

import { optionsGroup } from 'src/config/selectOption';
import { PutFormData } from './interface';

export const handleCustomerAnalysisList = (payload: Obj) => {
  const { data, products, factories, dieNames } = payload;
  return data.map(it => {
    const { 
      _id,
      customData,
      createdAt,
      status,
      testStartedAt,
    } = it;
    
    const children = customData?.primaryRanges?.map(i => {
      const { product, factory } = i;
      const currentFactory = factories?.find(f => f._id === factory);
      const currentProduct = products?.find(p => p._id === product);
      const currentDieName = dieNames?.find(d => d._id === product);

      return {
        ...i,
        key: Math.random().toString(16),
        testStartedAt: getMomentTime(i?.testStartedAt, 'YYYY-MM-DD HH:mm:ss'),
        factory: currentFactory.shortName,
        dieName: `${currentDieName?.customerName}(${currentDieName?.chipcooName})`,
        productName: `${currentProduct?.customerName}(${currentProduct?.chipcooName})`,
      };
    });
    const dieNumber = children?.map(i => i.dieNumber)?.join(', ');
    const testProgram = children?.length === 1 ? children?.map(t => t.testProgram)?.join(', ') : undefined;

    return {
      ...it,
      key: _id,
      name: customData?.name,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      productName: uniq(children?.map(p => p.productName))?.join(','),
      dieName: uniq(children?.map(p => p.dieName))?.join(','),
      dieNumber,
      factory: uniq(children?.map(p => p.factory))?.join(','),
      statusText: optionsGroup.analysisStatus.options![status],
      testStartedAt: getMomentTime(testStartedAt, 'YYYY-MM-DD HH:mm:ss'),
      testProgram,
      children: children?.length > 1 ? children : undefined,
    };
  });
};

export const handleCustomerAnalysisDetail = (payload) => {

  return {
    ...payload,
  };
};

// 处理数据筛选搜索条件
export const handleQueryParams = (queryParams: Obj, isCreate?: boolean) => {
  const testAt = get(queryParams, 'testAt');
  const dieNumber = get(queryParams, 'dieNumber');

  let resultData = {
    ...queryParams,
    product: queryParams?.product?.key,
    factory: queryParams?.factory?.key,
    dieNumber: dieNumber?.key === 'input' ? dieNumber?.value : undefined,
    dieNumberRecent: ((dieNumber?.key === 'number') && (toNumber(dieNumber?.value) > 0)) ? 
      toNumber(dieNumber?.value) : undefined,
    testAtRecent: ((testAt?.key === 'number') && (toNumber(testAt?.value) > 0)) ? toNumber(testAt?.value) : undefined,
    testAt: testAt?.key === 'input' ? testAt?.value?.dateString : undefined,
  };

  // 创建时提交后台存储
  if (isCreate) {
    return {
      ...resultData,
      testAt: testAt?.key === 'input' ? testAt?.value?.date : undefined,
    };
  }

  // 表格搜索用的
  return resultData;
};

export const handleFormData = (data: PutFormData) => {
  const {
    name,
    type,
    primaryData,
    secondaryData,
    analysisItems,
    suffix,
    template,
    primaryQueryParams,
    secondaryQueryParams,
    primaryTestItems,
    secondaryTestItems,
  } = data;
  const isMerge = isEqual(type, [EnumAnalysisContent.merge]);

  const primaryRange = primaryData.map(it => {
    return {
      autoAnalysis: it._id,
      waferIds: it.type === 'CP' ? it.children.map(i => i.waferId) : undefined,
    };
  });
  const secondaryRanges = isMerge ? undefined : secondaryData.map(it => {
    return {
      autoAnalysis: it._id,
      waferIds: it.type === 'CP' ? it.children?.map(i => i.waferId) : undefined,
    };
  });
  const primaryTestIds = primaryTestItems.map(it => it.testId);
  const secondaryTestIds = isMerge ? undefined : secondaryTestItems.map(it => it.testId);

  return {
    name,
    primaryRange,
    secondaryRanges,
    analysisContents: type,
    reportContents: analysisItems,
    suffixType: suffix,
    isCreateTemplate: template,
    primaryTestIds,
    secondaryTestIds,
    primaryStdfFilter: template ? handleQueryParams(primaryQueryParams, true) : undefined,
    secondaryStdfFilter: (template && !isMerge) ? handleQueryParams(secondaryQueryParams, true) : undefined,
  };
};

export const handleSelectData = (payload: Obj) => {
  const { data, products, factories } = payload;
  return data.map(it => {
    const { _id, testedAt, wafers, factory, product, productName, factoryName } = it;

    const waferId = wafers?.map(w => w.waferId) || [];
    const currentFactory = factories?.find(f => f._id === factory);
    const currentProduct = products?.find(p => p._id === product);
    const children = !isEmpty(wafers) && wafers.map((item => ({...item, key: _id + '_' + item.waferId})));

    return {
      ...it,
      key: _id,
      testedAt: getMomentTime(testedAt, 'YYYY-MM-DD HH:mm:ss'),
      waferId: waferId ? sortWaferId(waferId) : waferId,
      factory: factoryName ?? currentFactory?.shortName,
      productName: productName ?? currentProduct?.chipcooName + '(' + currentProduct?.customerName + ')',
      children,
    };
  });
};
