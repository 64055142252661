/**
 * 时间戳组件，主要用来显示那种需要实时变化的时间的，例如聊天里面消息产生的时间啊之类的，每隔一分钟时间重新计算一次
 */
import * as React from 'react';
import EventBus from 'eventbusjs';
import * as moment from 'moment';
import log from 'loglevel';

interface P {
  time: string;
  tag?: string;
  format?: string | ((date: any) => string);
  className?: string;
  styleName?: string;
}
interface S {
  stamp: string;
  time: string;
}

let timer: null | any = null;
const eventName = 'message-update-time-stamp';
const formatDate = (date: string, format?: P['format']) => {
  if (!format) {
    return moment(date).toNow();
  }

  if (typeof format === 'function') {
    return format(date);
  }

  return moment(date).format(format);
};

class TimeStamp extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const { time, format } = nextProps;

    if (time !== prevState.time) {
      return { time, stamp: formatDate(time, format) };
    }

    return null;
  }

  constructor(props: P) {
    super(props);

    if (timer === null) {
      // 每隔一分钟升级一下时间戳
      timer = setInterval(
        () => {
          EventBus.dispatch(eventName);
          log.info(eventName);
        },
        60000
      );
    }

    const { time, format } = props;

    this.state = {
      time,
      stamp: formatDate(time, format)
    };
  }

  componentDidMount() {
    // TODO 注意其实有一些时间比较久远的是不会再变动了，因此是不需要进行事件订阅的，后面这块需要进行处理
    EventBus.addEventListener(eventName, this.updateTimeStamp);
  }

  componentWillUnmount() {
    EventBus.removeEventListener(eventName, this.updateTimeStamp);

    // 如果没有订阅的，清掉定时器
    if (!EventBus.hasEventListener(eventName)) {
      clearInterval(timer);
      timer = null;
    }
  }

  updateTimeStamp = () => {
    const { stamp } = this.state;
    const { time, format } = this.props;
    const newStamp = formatDate(time, format);

    if (stamp !== newStamp) {
      this.setState({ stamp: newStamp });
    }
  }
  render() {
    const { tag = 'div' } = this.props;
    const Component = tag as any;
    return <Component className={this.props.className}>{this.state.stamp}</Component>;
  }
}

export default TimeStamp;
