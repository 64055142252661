import { AxiosRequestConfig } from 'axios';

import { getOrgIdByUrl } from 'src/utils';

export function handleRequest(config: AxiosRequestConfig) {
  const orgId = getOrgIdByUrl();

  if (orgId) {
    Object.assign(config.headers, {
      'x-chipcoo-org-id': orgId,
    });
  }

  return config;
}
