import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { abnormalityApi } from 'src/services/net';
import { handleList, handleDetail } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: {}
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await abnormalityApi.getAbnormalityList(params);
      const handleData = handleList(data?.data);

      this.setList({
        count: data?.count,
        data: handleData
      });
    },
    async getTableItemDetail(_id: string) {
      const { data } = await abnormalityApi.getAbnormalityById(_id);
      const handleData = handleDetail(data);

      this.setDetail(handleData);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    }
  })
});
