import React, { FC, useState } from 'react';
import { Card, Form } from 'antd';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import log from 'loglevel';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { optionContent, invoiceConfig } from './config';
import { InvoiceParams, PatchOrgData } from 'src/models/org/interface';
import { FormComponentProps } from 'antd/lib/form';
import InvoiceModal from './InvoiceModal';

interface OwnProps {
  orgId: string;
}

interface StateProps {
  orgInvoiceInfo: InvoiceParams[] | undefined;
  currentOrgRole: string;
}

interface DispatchProps {
  patchOrgData: (payload: PatchOrgData) => void;
}

type IProps = OwnProps & StateProps & DispatchProps & FormComponentProps;

interface IState {
  isOpen: boolean;
  modalData: InvoiceParams | undefined;
}

const columns = {
  name: { label: '发票抬头', isRequired: true, placeholder: '请输入发票抬头', maxLength: 50, autoFocus: true },
  type: { label: '发票类型', isRequired: true, placeholder: '请选择发票类型', type: 'select', options: invoiceConfig.options },
  taxRegistration: { label: '税务登记号', isRequired: true, placeholder: '请输入税务登记号', maxLength: 18 },
  bankName: { label: '基本开户银行名称', isRequired: true, placeholder: '请输入基本开户银行名称', maxLength: 50 },
  bankAccount: { label: '基本开户帐号', isRequired: true, placeholder: '请输入基本开户帐号', maxLength: 19 },
  address: { label: '注册场所地址', isRequired: true, placeholder: '请输入注册场所地址', maxLength: 100 }
};
const FormItem = Form.Item;
const formItemLayout = { labelCol: { span: 3 }, wrapperCol: { span: 9 } };

const InvoiceDetail: FC<IProps> = props => {
  const { orgId, orgInvoiceInfo, currentOrgRole, patchOrgData } = props;

  const [state, setState] = useState<IState>({
    isOpen: false,
    modalData: undefined,
  });
  const { isOpen, modalData } = state;

  const handleOpenInvoiceModal = async (data?) => {
    setState({
      isOpen: true,
      modalData: data,
    });
  };

  const submit = async(val) => {
    try {
      await patchOrgData({
        _id: orgId,
        invoice: val
      });

      setState({
        ...state,
        isOpen: false,
      });
    } catch (e) {
      log.error(e);
    }
  };

  // 显示invoice列表详情
  const renderLabelRows = (val) => {
    return Object.keys(columns).map(key => {
      const item = columns[key];

      return (
        <FormItem key={key} label={item.label} {...formItemLayout}>
          {item.type === 'select' ? <span>{optionContent![val[key]]}</span> : <span>{val[key]}</span>}
        </FormItem>
      );
    });
  };

  const extra = currentOrgRole !== 'MEMBER' && !isEmpty(orgInvoiceInfo) && (
    <i
      onClick={() => handleOpenInvoiceModal(orgInvoiceInfo)}
      className="c-icon icon-edit"
    />
  );

  const renderNode = () => {
    if (!isEmpty(orgInvoiceInfo)) {
      return (
        <Card className={styles.card} title="开票信息" bordered={false} extra={extra}>
          <Form>
            {
              isEmpty(orgInvoiceInfo) ? 
                <span className={styles.emptyText}>这家伙很懒，什么都没有留下！</span> : 
                renderLabelRows(orgInvoiceInfo)
            }
          </Form>
    
          <InvoiceModal
            isOpen={isOpen}
            columns={columns}
            modalType="编辑"
            modalData={modalData}
            onSubmit={submit}
            onCancel={() => setState({ ...state, isOpen: false })}
          />
        </Card>
      );
    } else {
      return <div />;
    }
  };

  return renderNode();
};

const mapState = ({ org: { orgId, orgList: { byId } } }: RootState) => ({
  orgInvoiceInfo: get(byId[orgId], 'invoice'),
  currentOrgRole: get(byId[orgId], 'role')!,
});
const mapDispatch = ({ org: { patchOrgData } }: RootDispatch) => ({ patchOrgData });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(InvoiceDetail));
