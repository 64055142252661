import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { handleDataList, handleDataDetail, handleFormData } from './handle';
import { mpwApi } from 'src/services/net';

const initialState: State = {
  list: [],
  count: 0,
  detail: {}
};

export default createModel({
  state: initialState,
  reducers: {
    setState(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList (params: any) {
      const { data } = await mpwApi.getLotList(params);

      const handleList = handleDataList(data?.data);
      this.setState({
        data: handleList,
        count: data.count,
      });
    },
    async getTableItemDetail(id: string) {
      const { data } = await mpwApi.getLotById(id);

      const handleData = handleDataDetail(data);
      this.setDetail(handleData);
    },
    async postCreate(values: any) {
      const formData = handleFormData(values);

      await mpwApi.createLotCommand(formData);
    },
    async patchModify() {
      //
    },
  })
});
