// import G6 from '@antv/g6/lib/index';
import Hierarchy from '@antv/hierarchy';
import { ICraftRouteNode, ICraftRouteEdge, ICraftRouteGroup } from 'src/components/CraftRoute/interface';
import {
  ICraftMaterial,
  ICraftRouteItem,
  ICraftRoute,
  ICraftRouteItemLocal
} from 'src/services/net/craftRoute';
import { flatLayoutRoot } from './flat';

export interface ILayoutOptions {
  /** layout 方向, 可选  H / V / LR / RL / TB / BT */
  direction?: 'H' | 'V' | 'LR' | 'RL' | 'TB' | 'BT';
  /** 节点默认大小 */
  nodeSize?: [number, number] | number;
  /** 节点水平间距(px) */
  nodeHGap?: number;
  /** 每一层节点之间间距(px) */
  nodeVGap?: number;
}

type TNodeType = ICraftMaterial | ICraftRouteItemLocal | ICraftRoute;

export interface ILayoutData {
  id: string;
  depth: number;
  height: number;
  width: number;
  hgap: number;
  vgap: number;
  x: number;
  y: number;
  data: ICraftRouteItemLocal | ICraftMaterial;
  children: ILayoutData[];
}

const PADDING = 16;
// apply layout
const NODE_HEIGHT = 60;
// const NODE_SIZE = 16;

export default class CraftRouteLayout {
  static _defaultOptions: Omit<Required<ILayoutOptions>, 'craftRoute'> = {
    direction: 'LR',
    // align: undefined,
    nodeSize: [160, 100],
    nodeHGap: 60,
    nodeVGap: 7.5,
  };
  nodes: ICraftRouteNode[] = [];
  edges: ICraftRouteEdge[] = [];
  groups: ICraftRouteGroup[] = [];
  readonly graph: any;
  private options: Required<ILayoutOptions>;
  craftRouteLocal: ICraftRouteItemLocal | null;

  constructor(options: ILayoutOptions, craftRoute: ICraftRouteItemLocal | null) {
    // super();
    this.craftRouteLocal = craftRoute;
    this.options = { ...CraftRouteLayout._defaultOptions, ...options };
  }

  getTreeLayout() {
    if (!this.craftRouteLocal) {
      return null;
    }
    const { nodes } = this;
    const { nodeHGap, nodeVGap } = this.options;
    const ctx = document.createElement('canvas')!.getContext('2d')!;
    const rootNode: ILayoutData = Hierarchy.compactBox(this.craftRouteLocal, {
      direction: 'LR', // H / V / LR / RL / TB / BT
      getId(d: TNodeType) {
        return (d as ICraftRouteItem)._id || (d as ICraftMaterial).id;
      },
      getHeight(d: TNodeType) {
        return NODE_HEIGHT;
      },
      getWidth(d: TNodeType) {
        let text = '';
        if ('type' in d) {
          //
          if (d.type === 'material') {
            text = d.item?.materialName;
          } else {
            text = d.item?.material.materialName;
          }
        } else {
          text = d?.material.materialName;
        }

        return Math.ceil(Math.max(ctx.measureText(text).width + PADDING * 2, 100));
      },
      getHGap(d: TNodeType) {
        if (d.siblings && d.siblings.length > 1) {
          return nodeHGap + 40;
        }

        return nodeHGap;
      },
      getVGap(d: TNodeType) {
        if (d.siblings && d.siblings.length > 1) {
          const { itemIndex = 0 } = d;
          if (itemIndex === 0) {
            return nodeVGap + 27;
          }
        }

        return nodeVGap;
      },
      getSubTreeSep(d: TNodeType) {
        return 0;
      }
    });

    const flatten = flatLayoutRoot(rootNode);
    const filtered = flatten.filter(it => nodes.some(node => node.id === it.id));
    const xMin = Math.min.apply(null, filtered.map(it => it.x));
    const xMax = Math.max.apply(null, filtered.map(it => it.x));
    const yMin = Math.min.apply(null, filtered.map(it => it.y));
    const yMax = Math.max.apply(null, filtered.map(it => it.y));

    const graphRect = {
      width: xMax - xMin,
      height: yMax - yMin,
      offsetX: 0 - xMin,
      offsetY: 0 - yMin,
      xMin,
      xMax,
      yMin,
      yMax,
    };

    return {
      rect: graphRect,
      nodePosition: flatten,
    };
  }

  execute() {
    const layoutData = this.getTreeLayout();
    if (!layoutData) {
      return;
    }

    const { rect, nodePosition } = layoutData;
    const { offsetX, offsetY } = rect;

    this.nodes.forEach(node => {
      const position = nodePosition.find(it => it.id === node.id);
      if (position) {
        // const { data } = position;
        // const { itemIndex = 0, siblings } = data;
        let x = position.x + position.hgap + offsetX;
        let y = position.y + position.vgap + offsetY;
        // if (siblings && siblings.length > 1 && itemIndex === 0) {
        //   y -= 24;
        // }

        node.x = x;
        node.y = y;
      }
    });

    // this.groups.forEach(group => {
    //   const node = this.nodes.find(n => n.parent === group.id);
    //   if (node) {
    //     group.x = (node.x || 0) - PADDING;
    //     group.y = (node.y || 0) - PADDING;
    //   }

    //   group.label = '合封';
    // });
  }
}
