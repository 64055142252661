import * as React from 'react';
import { Row, Col } from 'antd';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  label: string;
  customValue?: any;
  preText?: string;
  unit?: string;
}

const colNumber = {
  left: { span: 12 },
  right: { span: 12 }
};

class CustomItem extends React.PureComponent<Props> {
  render () {
    const { label, value, customValue, unit, preText } = this.props;
    let valueText = `${preText || ''} ${customValue || value} ${unit || ''}`;
    valueText = valueText.trim();

    if (valueText.indexOf('undefined') >= 0) valueText = '';

    return (
      <Row>
        <Col
          span={colNumber.left.span}
          className="cw-left-col"
        >
          {label}:
        </Col>
        <Col
          span={colNumber.left.span}
          className="cw-right-col"
        >
          {valueText}
        </Col>
      </Row>
    );
  }
}

export default CustomItem;
