/**
 * Created by marvin on 2019/1/13.
 */

import { order, inventory, process, abnormality, summary } from 'src/assets/images/operation';
import { ROUTE_NAMES } from 'src/router/normalRouter';

const appList = [
  // {
  //   title: '外协加工',
  //   src: outsource,
  //   route: ''
  // },
  // {
  //   title: '需求下单',
  //   src: outsource,
  //   route: 'demand-order',
  //   query: {
  //     autoOpenCreate: true,
  //   },
  // },
  // {
  //   title: '需求下单列表',
  //   src: demandList,
  //   route: 'demand-order-list'
  // },
  {
    title: '委外需求订单',
    src: order,
    route: ''
  },
  {
    title: '库存管理',
    src: inventory,
    route: 'inventory'
  },
  {
    title: '加工单查询',
    src: process,
    route: ''
  },
  {
    title: '生产异常管理',
    src: abnormality,
    route: ''
  },
  {
    title: '订单汇总表',
    src: summary,
    route: ''
  },
  // {
  //   title: '运营报表',
  //   src: outsource,
  //   route: ''
  // },
  // {
  //   title: '物流管理',
  //   src: outsource,
  //   route: 'logistics'
  // }
  // {
  //   title: '质量管理',
  //   src: quality,
  //   route: ''
  // },
  // {
  //   title: '设置',
  //   src: setting,
  //   route: ''
  // },
];

export function refreshAppList() {
  // appList[0].route = ROUTE_NAMES.ORG_OPERATION_OUTSOURCE;
  // appList[1].route = ROUTE_NAMES.ORG_OPERATION_DEMAND_ORDER;
  // appList[2].route = ROUTE_NAMES.ORG_OPERATION_DEMAND_ORDER;
  appList[0].route = ROUTE_NAMES.ORG_OPERATION_ORDER;
  appList[1].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY;
  appList[2].route = ROUTE_NAMES.ORG_OPERATION_PROCESS;
  appList[3].route = ROUTE_NAMES.ORG_OPERATION_ABNORMALITY;
  appList[4].route = ROUTE_NAMES.ORG_OPERATION_ORDER_SUMMARY;
  // appList[3].route = ROUTE_NAMES.ORG_OPERATION_LOGISTICS;
  // appList[2].route = ROUTE_NAMES.ORG_OPERATION;
  // appList[1].route = ROUTE_NAMES.ORG_OPERATION_SETTINGS;
}

setTimeout(refreshAppList);

export default appList;
