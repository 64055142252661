/**
 * 这个里面丢的是一堆不知道丢哪里的函数
 */
import { getUserName } from '@chipcoo/fe-utils';

import urlConfig from 'src/config/url';
import logoSquare from 'src/assets/images/logo-square.png';
import { getEnhancedAvatar } from './user';

// 桌面提醒
interface NotifyPopParams {
  event?: string;
  title: string;
  template: string;
  publisher: any;
  icon?: string;
  notifyKey?: string;
  forceNotify?: boolean;
}

function setUpNotify (title: string, options: any) {
  let n: any = new Notification(title, options);
  const audio = new Audio(`${location.origin}${urlConfig.baseUrl}/noti.mp3`);
  audio.play();
  setTimeout(
    () => {
      n.close();
      n = null;
    },
    3000
  ); // 3 seconds
  return n;
}

export const notificationPop = (params: NotifyPopParams) => {
  const { title, template, publisher, icon, event } = params;
  if (!title && !template || !window.hasOwnProperty('Notification')) {
    return;
  }

  let options = publisher
    ? {
      icon: icon || getEnhancedAvatar(publisher) || logoSquare,
      body: template.includes(publisher.nickname) ? template
        : (event === 'im' ? `${getUserName(publisher)}: ` : `${getUserName(publisher)} `) + template
    }
    : {icon: logoSquare, body: template};

  // 判断是否有权限
  if (Notification.permission === 'granted') {
    setUpNotify(title, options);
  } else if (Notification.permission === 'default') {
    // 如果没权限，则请求权限
    Notification.requestPermission((permission) => {
      // 如果接受请求
      if (permission === 'granted') {
        setUpNotify(title, options);
      }
    });
  }
};
