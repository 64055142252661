import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import { QuickSelectUser } from 'src/components';
import { isEqualUserId } from 'src/utils/user';
import store from 'src/store';
import { pickOrgUser } from 'src/models/org';
import { OrgUser } from 'src/models/org/interface';
import { EnumMemberStatus } from '@chipcoo/constant';

function createHandler() {
  const showInList = (orgUser: OrgUser) => {
    const { role, status: userStatus = EnumMemberStatus.normal } = orgUser;
    return !!role && userStatus === EnumMemberStatus.normal;
  };

  const userDisabled = (orgUser: OrgUser) => !showInList(orgUser);

  return {
    showInList,
    userDisabled,
  };
}

export function createRenderParticipants() {
  let lastPrincipalVal: any = null;

  let handler = createHandler();

  return function renderParticipants({ principal, participants }: any, status: FormStatus, form: WrappedFormUtils) {
    const state = store.getState();
    const { org: { orgMembers }, user, session: { profile } } = state;

    const isEdit = status === 'edit';

    const userId = profile!._id;
    const principalVal = principal || userId;
    const participantsVal = participants || [userId];

    // 如果之前的负责人和当前负责人不一致，重新生成handler，触发 QuickSelectUser 更新。
    if (principalVal !== lastPrincipalVal) {
      handler = createHandler();
      lastPrincipalVal = principalVal;
    }

    // 如果当前负责人不在参与者中，则需要将负责人添加入参与者
    if (!participantsVal.find(it => isEqualUserId(principalVal, it))) {
      setTimeout(() => {
        form!.setFieldsValue({
          participants: participantsVal.concat([principalVal])
        });
      });
    }

    const { showInList, userDisabled } = handler;
    const members = pickOrgUser(user, orgMembers.byId);
    return (
      <QuickSelectUser
        showInList={showInList}
        canSelect={showInList}
        userDisabled={userDisabled}
        defaultValue={participantsVal}
        users={members}
        placeholder="选择参与者"
        canEdit={isEdit}
        displayName={getUserNameEnhance}
        canRemove={(id) => !isEqualUserId(id, principalVal)}
      />
    );
  };
}
