import React from 'react';
import { Modal } from 'antd';
import { HoneyRoute, compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { Verify } from 'src/models/org/enum';
import store from 'src/store';

const { getCurrentRoute } = HoneyRoute;

function createHandler(routeName: string, replace?: boolean) {
  return function handler() {
    const { history, match: { params } } = getCurrentRoute()!;
    const path = compilePath({ name: routeName, params });

    if (replace) {
      history.replace(path);
    } else {
      history.push(path);
    }
  };
}

export function showGoToVerify(replace: boolean, isOwner: boolean) {
  //
  let content: JSX.Element[] = [<p key="1">只有认证通过的组织才可进行运营管理。</p>];

  if (!isOwner) {
    content.push(<p key="2">请联系组织拥有者进行认证操作。</p>);
  }

  return Modal.confirm({
    title: '请先进行组织认证',
    content,
    okText: '去认证',
    cancelText: '我知道了',
    onCancel: createHandler(ROUTE_NAMES.ORG_DETAIL, replace),
    onOk: createHandler(ROUTE_NAMES.ORG_SETTINGS_VERIFY, replace),
    okButtonProps: {
      className: isOwner ? '' : 'hidden',
    }
  });
}

export function checkRequireVerify(replace?: boolean) {
  const { org: { orgId, orgList: { byId } } } = store.getState();
  const org = byId[orgId];

  if (org && org.verify !== Verify.EnumStatus.accepted) {
    return showGoToVerify(!!replace, org.role === 'OWNER');
  }

  return;
}
