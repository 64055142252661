import React from 'react';
import _ from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumStockInApplyStatus } from '@chipcoo/constant';

import { ModalType } from '../StockOutTable';
import { optionsGroup } from 'src/config/selectOption';
import { AddressList, UploadByPaste } from 'src/containers';
import PreviewTable from '../components/PreviewTable';

const causeFile = (): CustomFormItemConfig => ({
  label: '附件',
  type: 'custom',
  dataKey: 'causeData.files',
  colClassName: 'files-style',
  render: () => {
    return <UploadByPaste renderType="preview" />;
  }
});

// 内容
const description: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'causeData.description',
  colSpan: 24
};

// 来料检验
const checkFile = (): CustomFormItemConfig => ({
  label: '附件',
  type: 'custom',
  dataKey: 'check.files',
  colClassName: 'files-style',
  render: () => {
    return <UploadByPaste renderType="preview" />;
  }
});

// 内容
const checkDescription: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'check.description',
  colSpan: 24
};

// 出库申请单号
const getSerial: InputFormItemConfig = {
  label: '出库申请单号',
  type: 'input',
  dataKey: 'serial',
};

// 产品名称
const productName: InputFormItemConfig = {
  label: '产品名称',
  type: 'input',
  dataKey: 'productName',
};

// 芯片名称
const dieName: InputFormItemConfig = {
  label: '芯片名称',
  type: 'input',
  dataKey: 'dieName',
  renderType: 'preview',
};

// 备注
const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24
};

const materialInfos: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'materialInfos',
  colSpan: 24,
  render() {
    const productModel = _.get(this, 'initialFormData.productModel');
    return (
      <PreviewTable productModel={productModel} />
    );
  }
};

// 包装方式
const packingMethod: InputFormItemConfig = {
  label: '包装方式',
  type: 'input',
  dataKey: 'packingMethod',
  required: true,
  requiredMsg: '请选择包装方式',
};

// 交货地址
const address: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'address',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择交货地址',
  render: (formData, globalStatus) => {
    return <AddressList globalStatus={globalStatus} />;
  }
};

// 当前申请状态及拒绝理由
const statusText: CustomFormItemConfig = {
  label: '当前状态',
  type: 'custom',
  dataKey: 'status',
  render: (formData) => {
    const { status } = formData;
    const statusMessage = optionsGroup.stockOutApplyStatus.options![status];
    return <div>{statusMessage}</div>;
  }
};

const rejectReason: TextAreaFormItemConfig = {
  label: '拒绝理由',
  type: 'textArea',
  dataKey: 'reason',
  colSpan: 24,
  renderType: (formData) => {
    const { status } = formData;
    if (status === EnumStockInApplyStatus.rejected) return 'normal';
    return 'none';
  }
};

const logisticsNumber: InputFormItemConfig = {
  label: '物流单号',
  type: 'input',
  dataKey: 'logisticsNumber',
  renderType: (formData) => {
    const { status } = formData;
    if (status === EnumStockInApplyStatus.approved) return 'normal';
    return 'none';
  }
};

export const getStockOutConfig = (status: ModalType): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '出库申请依据' },
      groupItemConfig: [
        causeFile(),
        description,
      ] as FormItemConfig[],
    },
    {
      groupItemConfig: [
        statusText,
        rejectReason,
      ] as FormItemConfig[],
      ifRender: () => status === 'preview',
    },
    {
      card: { title: '出库信息' },
      groupItemConfig: [
        getSerial,
        productName,
        packingMethod,
        dieName,
        logisticsNumber,
        remark,
      ] as FormItemConfig[]
    },
    {
      card: { title: '物料信息' },
      groupItemConfig: [
        materialInfos,
      ] as FormItemConfig[],
    },
    {
      card: { title: '出货检验' },
      groupItemConfig: [
        checkFile(),
        checkDescription,
      ] as FormItemConfig[]
    },
    {
      card: { title: '交货地址' },
      groupItemConfig: [
        address,
      ] as FormItemConfig[]
    }
  ]
});
