import React from 'react';
import ReactDOM from 'react-dom';
import { isEqual } from 'lodash';
import { bind } from 'lodash-decorators';
import { connect } from 'react-redux';
import { EnumMemberStatus } from '@chipcoo/constant';
import { getFlexModalLayout } from '@chipcoo/fe-utils';

import UniverseComment, {
  AdminUser,
  UniverseCommentProps,
} from 'src/containers/UniverseComment';
import store, { RootState } from 'src/store';
import { mapUsers, isEqualUserId } from 'src/utils/user';
import { OrgMember } from 'src/models/org/interface';
import { pickAvailableOrgUser, pickOrgUser } from 'src/models/org';

interface OwnProps {
  orderId?: string;
}

interface StateProps {
  availableUsers: User<OrgMember>[];
  orgUsers: User<OrgMember>[];
}

interface State {
  userIds: UserId[];
  suggestionData: User[];
}

type Props = OwnProps & UniverseCommentProps & StateProps;

function getUserExcludeMyself(
  userIds: State['userIds'] = [], byIdUsers: StateProps['availableUsers'], myUserId: UserId
) {
  return [AdminUser].concat(mapUsers(userIds, byIdUsers, id => !isEqualUserId(myUserId, id)));
}

let timer;

class UniverseCommentWrap extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextState: Partial<State> = {};
    const nextUserIds = nextProps.availableUsers.map(it => it._id);

    if (!isEqual(nextUserIds, prevState.userIds)) {
      nextState.userIds = nextUserIds;
      nextState.suggestionData = getUserExcludeMyself(nextUserIds, nextProps.availableUsers, nextProps.userId);
    }

    return nextState;
  }

  constructor(props: Props) {
    super(props);

    const userIds = props.availableUsers.map(it => it._id);
    this.state = {
      userIds,
      suggestionData: getUserExcludeMyself(userIds, props.availableUsers, props.userId),
    };
  }

  componentDidMount() {
    clearTimeout(timer);
  }

  componentWillUnmount() {
    const { resource } = this.props;
    timer = setTimeout(() => {
      store.dispatch({ type: 'universeComment/resetComment', payload: resource });
      // store.dispatch.universeComment.resetComment(resource);
    }, 150);
  }

  @bind
  getFullUser(user: User) {
    const users = this.props.orgUsers;
    const find = users.find(u => isEqualUserId(user._id, u._id));

    return find || user;
  }

  @bind
  isUserDisabled(user: User<OrgMember>) {
    const { _id, role, status = EnumMemberStatus.normal } = user;
    if (_id === AdminUser._id) {
      return false;
    }
    return !role || status !== EnumMemberStatus.normal;
  }

  // Fixme: it doesn't work.
  // Seems something wrong
  @bind
  getPopupContainer() {
    const result = getFlexModalLayout(ReactDOM.findDOMNode(this) as HTMLElement);

    return result;
  }

  render() {
    const { orderId, orgUsers, availableUsers, ...props } = this.props;
    const { suggestionData, } = this.state;
    return (
      <UniverseComment
        suggestionData={suggestionData}
        {...props}
        mentionProps={{ getPopupContainer: this.getPopupContainer }}
        getFullUser={this.getFullUser}
        userDisabled={this.isUserDisabled}
      />
    );
  }
}

function mapState(state: RootState, props: Props) {
  const { user , org: { orgMembers: { byId } } } = state;
  const orgAvailableUsers = pickAvailableOrgUser(user, byId);
  const orgUsers = pickOrgUser(user, byId);
  return {
    orgUsers,
    availableUsers: orgAvailableUsers,
  };
}

export default connect<StateProps>(mapState)(UniverseCommentWrap);
