 // import * as React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {

}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'ChipwingProductName',
      width: 200,
    },
    {
      dataIndex: 'nameCustomer',
      key: 'nameCustomer',
      title: 'CustomerProductName',
      width: 200,
    },
    {
      dataIndex: 'nameFab',
      key: 'nameFab',
      title: 'FAB Product Name',
      width: 200,
    },
    {
      dataIndex: 'lotId',
      key: 'lotId',
      title: 'Lot ID',
      width: 200
    },
    {
      dataIndex: 'process',
      key: 'process',
      title: 'Process',
      width: 100
    },
    {
      dataIndex: 'waferStartQty',
      key: 'waferStartQty',
      title: 'Wafer Start Qty',
      width: 150,
    },
    {
      dataIndex: 'waferQty',
      key: 'waferQty',
      title: 'Wafer Qty',
      width: 150,
    },
    {
      dataIndex: 'forecastWsDate',
      key: 'forecastWsDate',
      title: 'Forecast WS Date',
      width: 200,
    },
    {
      dataIndex: 'waferStartDate',
      key: 'waferStartDate',
      title: 'Wafer Start Date',
      width: 200
    },
    {
      dataIndex: 'currentStage',
      key: 'currentStage',
      title: 'Current Stage',
      width: 200
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'STATUS',
      width: 150
    },
    {
      dataIndex: 'waferIds',
      key: 'waferIds',
      title: 'Wafer ID',
      width: 200
    },
    {
      dataIndex: 'projectOutDate',
      key: 'projectOutDate',
      title: 'Project Out Date',
      width: 200
    },
    {
      dataIndex: 'WIPUpdateDate',
      key: 'WIPUpdateDate',
      title: 'WIP Update Date',
      width: 200
    },
  ];
};
