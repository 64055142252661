/**
 * Created by marvin on 2019-06-21.
 */

import * as React from 'react';
// import { Icon } from 'antd';
import classnames from 'classnames';

import styles from './index.module.less';

interface Props {

}

interface State {
  isShowDieInfo: boolean;
}

class CollapseDieInfo extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isShowDieInfo: false
    };
  }

  handleClickCollapse = (visible: boolean) => {
    this.setState({ isShowDieInfo: !visible });
  }

  render () {
    const { children } = this.props;
    const { isShowDieInfo } = this.state;

    return (
      <div className={styles.collapse}>
        <i
          className={classnames(styles.collapseIconArea, isShowDieInfo ? styles.iconUp : styles.iconDown)}
          onClick={() => this.handleClickCollapse(isShowDieInfo)}
        />
          {/*<Icon type="down" className={classnames(styles.icon, !isShowDieInfo && styles.iconDown)} />*/}
          {/*<Icon type="up" className={classnames(styles.icon, isShowDieInfo && styles.iconUp)} />*/}
        {/*</i>*/}

        <div className={classnames(styles.dieInfoAreaTopBorder, isShowDieInfo && styles.dieInfoArea)}>
          {isShowDieInfo && children}
        </div>
      </div>
    );
  }
}

export default CollapseDieInfo;
