/**
 * 用来处理表单的弹框的内容
 */
import * as React from 'react';
import { isPlainObject, isString } from 'lodash';
import { FormCreator } from '@chipcoo/hanayo';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';
import { FormConfig, FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

export interface FormModalContentProps extends BasicModalChildrenProps {
  formConfig: FormConfig;
  // 默认是edit
  formStatus?: FormStatus;
  // 提交表单
  onSubmitForm: (payload: object) => Promise<any>;
  // 这里提交表单默认是传入一个formData，但有的时候还需要其他的数据，因此这里丢一个处理函数进来
  handleSubmitFormParams?: (formData: Obj, detailId?: string) => object;
  // 提交表单数据成功以后执行的内容
  onAfterSubmit?: (submitRespData: Obj) => void;
  // 弹框详情的内容的索引id7
  detailId?: string;
  // 获取表单详情
  getFormDetail?: Function;
  /**
   * 默认getFormDetail的参数直接传入detailId，但之前由于部分代码是传入一个object，
   * 因此这里给一个函数，用来处理传入的params
   */
  handleGetFormDetailParams?: (detailId: string) => void;
  // 表单详情数据
  formDetailData?: Obj;
  // 给表单上锁
  lockForm?: (payload: any) => void;
  // 给表单解锁
  unlockForm?: (payload: any) => void;
  // 自定义表单上锁和解锁的参数，如果没有，参数默认为detailId
  handleLockParams?: (detailId: string) => object;
  // 表单如果是预览状态，那么表单title的显示
  previewModalTitle?: string;
}

interface State {
  formStatus: FormStatus;
}

class FormModalContent extends React.PureComponent<FormModalContentProps, State> {
  static defaultProps = {
    formStatus: 'edit'
  };

  private formController: any;

  constructor(props: FormModalContentProps) {
    super(props);

    const { formStatus, registerSubmitHandler } = this.props;

    this.state = {
      formStatus: formStatus!,
    };

    registerSubmitHandler && registerSubmitHandler(this.handleSubmit);
  }

  async componentDidMount() {
    const state = this.state;
    const {
      detailId,
      lockForm,
      unlockForm,
      getFormDetail,
      showModalContent,
      previewModalTitle,
      __dangerousSetState,
      handleGetFormDetailParams,
    } = this.props;

    let { formStatus } = state;

    if (process.env.NODE_ENV === 'development') {
      // 锁表单和解锁表单必须同时传入或者不传
      if (Boolean(lockForm) !== Boolean(unlockForm)) {
        console.error('锁表单和解锁表单必须同时传入或者不传');
      }
    }

    // 如果传入detailId，默认就是编辑状态
    if (detailId) {
      // 给表单上锁，如果报错，表明已经被锁住了，此时需要将表单的状态重置为preview
      if (lockForm) {
        try {
          await lockForm(this.lockFormParams);
        } catch (e) {
          console.error(e);
          formStatus = 'preview';
        }
      }

      // 如果表单是预览状态，那么将对应的弹框的头和脚都重新设置一遍
      if (formStatus === 'preview' && formStatus !== state.formStatus) {
        __dangerousSetState({ footer: null, title: previewModalTitle  });

        this.setState({ formStatus });
      }

      // 获取表单数据详情
      if (getFormDetail) {
        const detailParams: any = handleGetFormDetailParams?.(detailId);

        const _payload = handleGetFormDetailParams
          ?
            isString(detailParams)
              ? detailParams
              : detailParams?.id
          : detailId;

        await getFormDetail(_payload);
      }

      showModalContent();
    }
  }

  componentWillUnmount(): void {
    const { formStatus } = this.state;
    const { detailId, unlockForm, __dangerousSetState } = this.props;

    // 解锁
    if (detailId && formStatus === 'edit' && unlockForm) {
      unlockForm(this.lockFormParams);
    }

    /**
     * 这个组件会被卸载，但作为父组件的弹框组件并不会
     * 这里主要作用是假如因为锁的原因改变了formStatus，那么下次进来的候title和footer还是上一次的，因此这里需要重置下
     */
    if (formStatus === 'edit') {
      __dangerousSetState({ footer: void 0, title: void 0  });
    }
  }

  get lockFormParams() {
    const { handleLockParams, detailId } = this.props;

    return handleLockParams ? handleLockParams(detailId!) : detailId;
  }

  handleSubmit = () => {
    if (!this.formController) return;

    const { formConfig, onSubmitForm, onAfterSubmit, handleSubmitFormParams, detailId } = this.props;
    const { formKey } = formConfig;
    const { validateFieldsAndScroll } = this.formController;

    return new Promise((resolve, reject) => {
      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const formData = formKey ? values[formKey] : values;
          const _payload = handleSubmitFormParams ? handleSubmitFormParams(formData, detailId) : formData;

          try {
            const result = await onSubmitForm(_payload);

            if (process.env.NODE_ENV === 'development') {
              if (!isPlainObject(result)) {
                console.error('传入FormModalContent组件中的onSubmitForm，返回值应该是一个object');
              }
            }
            onAfterSubmit && onAfterSubmit(result);
            resolve(result);
          } catch (e) {
            console.error(e);
            resolve(false);
          }
        }

        // 迷惑，这里写reject(err)，外面await拿到的到底是什么
        resolve(false);
      });
    });
  }

  getFormController = controller => this.formController = controller;

  render(): React.ReactNode {
    const { formStatus } = this.state;
    const { formConfig, detailId, formDetailData } = this.props;

    // 如果传入detailId，那么在没拿到详情信息之前，不显示表单内容
    if (detailId && !formDetailData) {
      return null;
    }

    const baseFormCreatorProps: any = {
      formConfig,
      globalStatus: formStatus,
      getFormController: this.getFormController
    };

    if (formDetailData) {
      baseFormCreatorProps.formData = formDetailData;
    }

    return (
      <FormCreator {...baseFormCreatorProps} />
    );
  }
}

export default FormModalContent;
