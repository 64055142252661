/**
 * Created by marvin on 18/8/17.
 */

import * as React from 'react';
import { Card, Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { forOwn, get } from 'lodash';
import log from 'loglevel';
import { connect } from 'react-redux';

import styles from './index.module.less';
import urlConfig from 'src/config/url';
import { RootState, RootDispatch } from 'src/store';
import { PatchOrgData, BasicInfo, IOrgDetail } from 'src/models/org/interface';

import OrgImage from './OrgImage';

const { TextArea } = Input;

interface OwnProps {
  orgId: string;
}
interface StateProps {
  currentOrgRole: AllRole;
  orgBasicInfo: BasicInfo | undefined;
  orgVerify: IOrgDetail['verify'];
}
interface DispatchProps {
  patchOrgData: (payload: PatchOrgData) => any;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  isEditing: boolean;
  basicInfo: any[];
}
const config = {
  name: { label: '组织全称', isRequired: true, pattern: /^.{0,50}$/, message: '最多50个字符' },
  shortName: { label: '组织简称', pattern: /^.{0,10}$/, message: '最多10个字符' },
  location: { label: '所在地', pattern: /^.{0,100}$/, message: '最多100个字符' },
  webPage: { label: 'web主页', pattern: /^.{0,50}$/, message: '最多50个字符' },
  wechat: { label: '微信公众号', pattern: /^.{0,30}$/, message: '最多30个字符' },
  description: { label: '组织简介', pattern: /^[\d\D]{0,200}$/, message: '最多200字符' },
};
const getBasicInfo = (props) => {
  let newConfig: any = {};
  const orgBasicInfo = props.orgBasicInfo ? props.orgBasicInfo : {};

  forOwn(config, (val, key) => {
    newConfig[key] = typeof val === 'string'
      ? { label: val, placeholder: `请输入${val}` }
      : { ...val, placeholder: `请输入${val.label}` };
  });

  return Object.keys(newConfig).map(key => ({
    id: key, value: orgBasicInfo[key], ...newConfig[key]
  }));
};
const FormItem = Form.Item;
const formItemLayout = { labelCol: { span: 3 }, wrapperCol: { span: 9 } };
const submitFormLayout = {wrapperCol: { sm: { span: 10, offset: 3 } }};
const defaultCover = `${urlConfig.staticUrl}/default/bg-group/bg-group-01.jpg`;

class OrgBasicInfo extends React.PureComponent<Props, State> {
  static getDerivedStateFromProps (nextProps: Props, prevState: State) {

    return {
      basicInfo: getBasicInfo(nextProps),
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      basicInfo: []
    };
  }

  // 提交组织基本信息
  submitBasicInfo = (e) => {
    e.preventDefault();

    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        const { patchOrgData, orgId, orgBasicInfo } = this.props;

        Object.keys(values).forEach(key => {
          values[key] = values[key] && values[key].trim();
        });

        try {
          await patchOrgData({
            _id: orgId,
            info: { ...orgBasicInfo, ...values },
          });

          this.setState({ isEditing: false });
        } catch (e) {
          log.error(e);
        }
      }
    });
  }

  // 取消编辑
  cancelBasicInfo = () => {
    this.props.form.resetFields();
    this.setState({ isEditing: false });
  }

  renderDescription = (item, isEditing: boolean, isOrgPending: boolean, showVerifiedIcon?: boolean) => {
    if (item.id === 'name' && isOrgPending) {
      return <span>{item.value}</span>;
    }

    if (isEditing && !showVerifiedIcon) {
      if (item.id === 'description') {
        return (
          <TextArea
            autoComplete="off"
            placeholder={item.placeholder}
            maxLength={item.maxLength}
            autosize={{minRows: 4, maxRows: 4}}
          />
        );
      } else {
        return <Input autoComplete="off" placeholder={item.placeholder} maxLength={item.maxLength} />;
      }
    }

    return (
      <span>
        {item.value}
        {showVerifiedIcon && <i className="icon-qi c-icon" style={{marginLeft: 8}} />}
      </span>
    );
  }

  render () {
    const { orgId, form, orgBasicInfo, currentOrgRole, orgVerify } = this.props;
    const isOrgVerified = Boolean(orgVerify) && orgVerify === 'ACCEPTED';
    const isOrgPending = Boolean(orgVerify) && orgVerify === 'PENDING';
    const { isEditing, basicInfo } = this.state;
    const { getFieldDecorator } = form;

    if (!orgBasicInfo) { return null; }

    const { avatar, name } = orgBasicInfo;
    const src = avatar ? `${urlConfig.staticUrl}${avatar}` : defaultCover;

    return (
      <Card title="基本信息" bordered={false}>
        <OrgImage src={src} orgName={name} orgId={orgId} orgRole={currentOrgRole} />

        <Form className={styles.info} onSubmit={this.submitBasicInfo}>
          {basicInfo.map((item, index) => (
            isEditing ? (
              <FormItem key={index} label={item.label} {...formItemLayout}>
                {getFieldDecorator(item.id as string, {
                  initialValue: item.value,
                  rules: [
                    { required: item.isRequired, message: item.placeholder },
                    { pattern: item.pattern, message: item.message }
                  ]
                })(
                  this.renderDescription(item, isEditing, isOrgPending, item.id === 'name' && isOrgVerified)
                )}
              </FormItem>
            ) : (
              item.value && <FormItem key={index} label={item.label} {...formItemLayout}>
                {getFieldDecorator(item.id as string)(
                  this.renderDescription(item, isEditing, isOrgPending, item.id === 'name' && isOrgVerified)
                )}
              </FormItem>
            )
          ))}

          {
            isEditing
              ? (
                <FormItem {...submitFormLayout}>
                  <Button type="primary" htmlType="submit">确定</Button>
                  <Button style={{marginLeft: 8}} onClick={this.cancelBasicInfo}>取消</Button>
                </FormItem>
              )
              : currentOrgRole !== 'MEMBER' && (
                <FormItem {...submitFormLayout}>
                  <a onClick={() => this.setState({ isEditing: true })}>修改基本信息</a>
                </FormItem>
              )
          }
        </Form>
      </Card>
    );
  }
}

const mapState = ({ org: { orgId, orgList: { byId } } }: RootState) => ({
  currentOrgRole: get(byId[orgId], 'role')!,
  orgBasicInfo: get(byId[orgId], 'info'),
  orgVerify: get(byId[orgId], 'verify'),
});

const mapDispatch = ({ org: { patchOrgData } }: RootDispatch) => ({ patchOrgData });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(OrgBasicInfo));
