/**
 * Created by marvin on 18/8/28.
 */

import * as React from 'react';
import { Dropdown, Menu, notification, Button } from 'antd';
import { connect } from 'react-redux';
import log from 'loglevel';
// import { Modal } from '@chipcoo/hanayo';
import { BasicModal } from '@chipcoo/hanayo';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';

import { RootDispatch } from 'src/store';
import { MemberOperation } from 'src/models/org/interface';

import { AddByAccount } from 'src/components';

interface OwnProps {
  orgId: string;
}
interface StateProps {}
interface DispatchProps {
  postAddOrgMember: (val: MemberOperation) => void;
}
type Props = OwnProps & StateProps & DispatchProps;
type State = {
  addNewModalVisible: boolean;
};

class AddNewMembers extends React.PureComponent<Props, State> {
  private readonly menu;
  private addByAccountRef: typeof AddByAccount;

  constructor(props: Props) {
    super(props);

    this.menu = (
      <Menu>
        <Menu.Item>
          <div
            style={{ margin: '-5px -12px', padding: '5px 12px' }}
            onClick={this.openAddMemberModal}
          >
            <i className="c-icon icon-account" />
            <span style={{ marginLeft: 8 }}>通过账号</span>
          </div>
        </Menu.Item>
      </Menu>
    );

    this.state = {
      addNewModalVisible: false
    };
  }

  // 关闭邀请模态窗
  // handleCloseModal = () => {
  //   this.setState({
  //     isAddNewModalOpen: false,
  //   });
  // }

  // 获取邀请列表
  handleGetAccountList = async (value) => {
    try {
      const { orgId, postAddOrgMember } = this.props;
      await postAddOrgMember({accounts: value, orgId: orgId});
      this.openNotification('success');
    } catch (e) {
      log.error(e);
    }
  }

  // 邀请提示
  openNotification = (type: string) => {
    notification[type]({
      message: '提示',
      description: '邀请已发送，请等待对方接受。'
    });
  }

  openAddMemberModal = () => this.setState({ addNewModalVisible: true });

  closeAddMemberModal = () => this.setState({ addNewModalVisible: false });

  getAddByAccountRef = ref => this.addByAccountRef = ref;

  handleOk = async () => {
    console.log(this.addByAccountRef, 'mmmm');
  }
  //
  // addMember = () => {
  //   this.addNewModalVisible(true);
  // }
  //
  // handleAddNewModalVisibleChange = (visibleFn) => {
  //   this.addNewModalVisible = visibleFn;
  // }

  render () {
    const { addNewModalVisible } = this.state;

    return (
      <>
        <Dropdown
          trigger={['click']}
          overlay={this.menu}
          placement="bottomRight"
        >
          <Button type="primary">邀请</Button>
          {/*<div className={styles.dropdown}>*/}
            {/*<i className="c-icon icon-user-add" />*/}
            {/*<span style={{ marginLeft: 8 }}>邀请组织成员</span>*/}
          {/*</div>*/}
        </Dropdown>

        {/*<Modal*/}
        {/*  visible={addNewModalVisible}*/}
        {/*  onRequestClose={this.closeAddMemberModal}*/}
        {/*  onOk={this.handleOk}*/}
        {/*>*/}
        {/*  <AddByAccount submitAccount={this.handleGetAccountList} wrappedComponentRef={this.getAddByAccountRef} />*/}
        {/*</Modal>*/}
        <BasicModal
          visible={addNewModalVisible}
          width={360}
          title="通过账号邀请组织成员"
          closeModal={this.closeAddMemberModal}
        >
          {(childProps: BasicModalChildrenProps) => (
            <AddByAccount
              submitAccount={this.handleGetAccountList}
              registerSubmitHandler={childProps.registerSubmitHandler}
            />
          )}
        </BasicModal>
      </>
    );
  }
}

const mapDispatch = ({ org: { postAddOrgMember } }: RootDispatch) => ({
  postAddOrgMember
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(AddNewMembers);
