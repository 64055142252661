/**
 * 消息筛选面板的过滤条件选择，只能是单选
 */
import * as React from 'react';
import { Icon } from 'antd';
import classnames from 'classnames';
import { has } from 'lodash';
import { bind } from 'lodash-decorators';

interface FilterOption {
  value: any;
  text?: string;
}

interface P {
  type: string;
  value?: any;
  defaultValue?: any;
  onChange?: (type: string, value: any) => any;
  options: FilterOption[];
  count?: string | number | { [key: string]: number } | ((value: any, option: any) => string | number | undefined);
}

interface S {
  curValue: any;
}

class FilterCategory extends React.PureComponent<P, S> {
  static defaultProps = {
    onChange(_: any, value: any) {
      this.setState({ curValue: value });
    }
  };

  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};
    
    if (has(nextProps, 'value')) {
      nextState.curValue = nextProps.value;
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      curValue: props.value,
    };
  }

  getCount(option: any) {
    const { count } = this.props;
    if (count === undefined) {
      return;
    }

    if (/^string|number$/.test(typeof count)) {
      return count;
    }

    if (typeof count === 'function') {
      return count(option.value, option);
    }

    return count && count[option.value];
  }

  getCountNode(option: any) {
    const count = this.getCount(option);
    if (count !== undefined) {
      return `(${count})`;
    }

    return '';
  }

  @bind
  setFilterValue(e: any, nextValue: any) {
    e.stopPropagation();
    const { curValue } = this.state;
    const { type, onChange, defaultValue } = this.props;
    let value = defaultValue;

    if (curValue !== nextValue) {
      value = nextValue;
    }
    
    onChange && onChange.call(this, type, value);
  }

  render() {
    const props = this.props;
    const { curValue } = this.state;
    const { options } = props;

    return options.map((option) => (
      <div
        className={classnames('item', { active: curValue === option.value })}
        key={option.value}
        onClick={(e) => this.setFilterValue(e, option.value)}
      >
        <p>{option.text} {this.getCountNode(option)}</p>
        <Icon type="check" theme="outlined" />
      </div>
    ));
  }
}

export default FilterCategory;
