/**
 * 通知中心
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { RootDispatch } from 'src/store';

import styles from './index.module.less';

import MsgList from './MsgList';
import MsgContent from './MsgContent';
import FilterPanel from './FilterPanel';
// import { CacheRoute } from 'src/local_modules/react-router-cache-route_test/index.js';
import HoneyRoute, { getRouteByName, getRouterProps } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';

interface OwnProps {}
interface StateProps {}
interface DispatchProps {
  resetFilter: () => any;
  resetList: () => any;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {}

class Notification extends React.PureComponent<P, S> {
  componentWillUnmount() {
    this.props.resetFilter();
    this.props.resetList();
  }

  render() {
    const route = getRouteByName(ROUTE_NAMES.NOTIFICATION_DETAIL)!;
    const routerProps = getRouterProps(this.props);
    return (
      <div className={classnames(styles.notificationWrapper, 'flex')}>
        <FilterPanel />
        <MsgList />
        <HoneyRoute {...routerProps} {...route} component={MsgContent}/>
        {/* <CacheRoute
          className="flex-full"
          cacheRoot="notification"
          path={`${this.props.match.path}/:id`}
          component={MsgContent}
        /> */}
      </div>
    );
  }
}

const mapDispatch = ({ notification: { resetFilter, resetList } }: RootDispatch) => ({
  resetList,
  resetFilter,
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(Notification);
