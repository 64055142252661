import _ from 'lodash';
import { EnumProductModel, PRODUCT_TYPE } from '@chipcoo/constant';
import { getMomentTime, sliceArray2Slices, bins2Number } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';
import { baseBins } from '../inventoryManage/stockIn/handle';
import { productApi } from 'src/services/net';

// 物料库存列表
export const handleStockList = (data) => data.reduce(
  (prev, cur) => {
    // let count;
    const {
      _id,
      productModel,
      product,
      warehouse,
      slices,
      name,
      ...restData
    } = cur;

    // if (productModel === EnumProductModel.assemblyProduct) {
    //   //
    // } else {
    //   count = slices2Number(slices);
    // }

    const curData = {
      ...restData,
      // ...count,
      _id,
      key: name,
      name,
      productModelText: PRODUCT_TYPE[productModel],
      product: product.customerName || name.split('/')[0],
      warehouse: warehouse.map(item => item.name).join(', ')
    };

    prev.push(curData);

    return prev;
  },
  [] as any[]
);

// 物料库存详情
export const handleStockDetail = async (data) => {
  // let [allAvailable, allUnAvailable, allFreeze, allTotal, allWaferAmount] = [0, 0, 0, 0, 0];
  let [allAvailable, allUnAvailable, allTotal, allWaferAmount] = [0, 0, 0, 0, 0];
  const warehouseNames: string[] = [];
  const {
    name,
    product,
    productModel,
    // dieName,
  } = data[0];

  const { data: productInfo } = await productApi.getProductById(product);

  data.map(item => {
    // const { available, unavailable, freeze, amount, warehouse, slices } = item;
    const { available, unavailable, amount, warehouse, slices } = item;

    allAvailable += available;
    allUnAvailable += unavailable;
    // allFreeze += freeze;
    allTotal += amount;
    allWaferAmount += _.get(slices, 'length') || 0;

    warehouseNames.push(warehouse.name);
  });

  const warehouseList = _.uniq(warehouseNames).map(warehouseName => {
    let key = '';
    let materialInWarehouse: any[] = [];
    // let [wAvailable, wUnavailable, wFreeze, wTotal, waferAmount] = [0, 0, 0, 0, 0];
    let [wAvailable, wUnavailable, wTotal, waferAmount] = [0, 0, 0, 0, 0];

    data.forEach(item => {
      if (_.get(item, 'warehouse.name') === warehouseName) {
        // const { available, unavailable, freeze, amount, slices } = item;
        const { available, unavailable, amount, slices } = item;

        wAvailable += available;
        wUnavailable += unavailable;
        // wFreeze += freeze;
        wTotal += amount;
        waferAmount += _.get(slices, 'length') || 0;
        key = _.get(item, 'warehouse._id');

        // 库房下的库房物料仓储信息
        materialInWarehouse.push({ ...item, key: item._id });
      }
    });

    // 库房库存信息
    return {
      key,
      name: warehouseName,
      available: wAvailable,
      unavailable: wUnavailable,
      // freeze: wFreeze,
      total: wTotal,
      waferAmount,
      materialList: materialInWarehouse
    };
  });

  let dieName = '';

  if (productModel === EnumProductModel.assemblyProduct) {
    const dies = productInfo?.product?.dies;

    if (_.isArray(dies)) {
      const _dieNames = dies.map(it => it?.die?.customerName).filter(it => it);

      dieName = _dieNames.join(', ');
    }
  }

  if (
      // productModel === EnumProductModel.bumpDie ||
      // productModel === EnumProductModel.cpDie ||
      productModel === EnumProductModel.dpProduct
  ) {
    dieName = productInfo?.product?.die?.customerName;
  }

  // 整个库存信息
  return {
    name,
    productModel,
    product: name.split('/')[0],
    dieName,
    available: allAvailable,
    unavailable: allUnAvailable,
    // freeze: allFreeze,
    total: allTotal,
    waferAmount: allWaferAmount,
    warehouseList,
    materialNumberInfo: warehouseList
  };
};

// 处理库房物料仓储信息数据
export const handleMaterialList = (data: any[]) => (data || []).reduce(
  (prev, cur) => {
    let waferIds = '';
    let bin: string = '';
    const {
      _id,
      productModel,
      slices,
      bins,
      status,
      restrictBy,
      stockInAt,
      ...restData
    } = cur;

    if (productModel === EnumProductModel.assemblyProduct) {
      if (bins) {
        bin = bins2Number(bins).binType;
      }
    } else {
      if (slices) {
        waferIds = sliceArray2Slices(slices).waferIds;
      }
    }

    const curData = {
      ...restData,
      _id,
      key: _id,
      waferIds,
      bin,
      slices,
      bins,
      status,
      stockInAt: stockInAt && getMomentTime(stockInAt, 'YYYY-MM-DD HH:mm:ss'),
      stockStatus: optionsGroup.stockStatus.options![status],
      restrictBy: optionsGroup.stockRestrictBy.options![restrictBy]
    };

    prev.push(curData);

    return prev;
  },
  [] as any
);

// 处理库房物料仓储信息里的物料片号或bin
export const handleWaferIdsOrBins = (data: any) => Object.entries(data).reduce(
  (prev, [key, val]) => {
    const value: any = _.cloneDeep(val);

    if (key === 'bins' && value) {
      value.forEach((item, index) => {
        const { amount } = item;
        // const { amount, freeze } = item;

        prev.push({
          key: baseBins[index].name,
          bin: baseBins[index].name,
          amount: amount,
          // isFrozen: amount && freeze
        });
      });
    }

    if (key === 'slices' && value) {
      value.forEach(item => prev.push({ ...item, key: item.waferId }));
    }

    return prev;
  },
  [] as any
);
