import { AxiosInstance, AxiosRequestConfig } from 'axios';

export default function(api: AxiosInstance) {
  return {
    getList(config?: AxiosRequestConfig) {
      return api.get('console/org', config);
    },
    create(data: any, config?: AxiosRequestConfig) {
      return api.post('console/org', data, config);
    },
    getOrgInfo(orgId: string, config?: AxiosRequestConfig) {
      return api.get(`console/org/${orgId}`, config);
    },
    patchOrgInfo(orgId: string, data: any, config?: AxiosRequestConfig) {
      return api.patch(`console/org/${orgId}`, data, config);
    },
    getLicenseToken(orgId: string, config?: AxiosRequestConfig) {
      return api.get(`console/org/${orgId}/upload/license`, config);
    },
    getOrgVerify(config?: AxiosRequestConfig) {
      return api.get('console/verify', config);
    },
    postOrgVerify(data: any, config?: AxiosRequestConfig) {
      return api.post('console/verify', data, config);
    },
    // dissolve(orgId: string, data: { password: string }, config?: AxiosRequestConfig) {
    //   return api.post(`console/org/${orgId}/dissolve`, data, config);
    // },
    // transfer(data: { orgId: string; userId: UserId; code: string; }, config?: AxiosRequestConfig) {
    //   return api.post(`console/org/${data?.orgId}/transfer`, data, config);
    // },
    // leave(orgId: string, data: { password: string }, config?: AxiosRequestConfig) {
    //   return api.post(`console/org/${orgId}/leave`, data, config);
    // },
    // 获取转让验证码
    postTransferCode(data: GeetestValidate & { orgId: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/transfer/code`, data, config);
    },
    // 验证转让验证码
    postTransfer(data: { orgId: string; userId: UserId; code: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/transfer`, data, config);
    },
    // 获取离开验证码
    postLeaveCode(data: GeetestValidate & { orgId: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/leave/code`, data, config);
    },
    // 验证离开验证码
    postLeave(data: { orgId: string; code: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/leave`, data, config);
    },
    // 获取解散验证码
    postDissolveCode(data: GeetestValidate & { orgId: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/dissolve/code`, data, config);
    },
    // 验证解散验证码
    postDissolve(data: { orgId: string; code: string; }, config?: AxiosRequestConfig) {
      return api.post(`console/org/${data?.orgId}/dissolve`, data, config);
    },
    getMembers(orgId: string, config?: AxiosRequestConfig) {
      return api.get(`console/org/${orgId}/members`, config);
    },
    inviteMembers(data: { accounts: string[] }, config?: AxiosRequestConfig) {
      return api.post('console/invitation', data, config);
    },
    removeMember(orgId: string, params: { userId: UserId }, config?: AxiosRequestConfig) {
      return api.delete(`console/org/${orgId}/members`, { ...config, params });
    },
    patchMember(orgId: string, data: { userId: UserId, role: AllRole }, config?: AxiosRequestConfig) {
      return api.patch(`console/org/${orgId}/members`, data, config);
    },
    putMember(orgId: string, data: { userId: UserId, role: AllRole }, config?: AxiosRequestConfig) {
      return api.put(`console/org/${orgId}/members`, data, config);
    }
  };
}
