import * as React from 'react';
import log from 'loglevel';
import { createSelector } from 'reselect';
import { EnumProductModel } from '@chipcoo/constant';
import { FormCreator } from '@chipcoo/hanayo';
import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';
import { Modal, FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

import { getFormConfig } from './form-config';
import { isAssemblyModel } from './index';

interface Props {
  productModel: EnumProductModel;
  mode: 'new' | 'edit';
  visible: boolean;
  formData: any;
  tableData: any[];
  onSave: (formData: any) => any;
  onCancel: () => any;
}

interface State {
  visible: boolean;
  zIndex: number;
}

class StockNumberForm extends React.PureComponent<Props, State> {

  formConfig: FormConfig;
  selectColumnProps = createSelector(
    (props: Props) => isAssemblyModel(props.productModel),
    (isAssembly) => {
      return getFormConfig(isAssembly);
    }
  );
  formController: FormController;
  getFormController = (controller) => this.formController = controller;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: props.visible,
      zIndex: FlexModalManager.getNextZIndex(),
    };
  }

  getTitle = () => {
    const { mode, productModel } = this.props;
    let text = '编辑数量';

    if (isAssemblyModel(productModel)) {
      return mode === 'new' ? '新建Bin类型' : text;
    } else {
      return mode === 'new' ? '新建片号' : text;
    }
  }

  onCancel = () => {
    const hasChanged = this.formController.isFieldsChanged();

    if (hasChanged) {
      return new Promise<void>((resolve) => {
        Modal.confirm({
          title: '提示',
          content: '是否保存更改？',
          buttonOrder: ['ok', 'reject', 'cancel'],
          onOk: () => {
            resolve();
            this.handleSubmit();
          },
          onReject: () => {
            resolve();
            this.props.onCancel();
          },
          onCancel: () => {
            resolve();
          },
          okText: '保存',
          rejectText: '不保存',
        });
      });
    } else {
      return this.props.onCancel();
    }
  }

  handleSubmit = () => {
    if (!this.formController) {
      return false;
    }

    return new Promise((resolve) => {
      this.formController.validateFieldsAndScroll((errs, values) => {
        if (errs) {
          log.error('[MaterialForm]: validate failed', errs);
          return resolve(false);
        }

        resolve(true);
        this.props.onSave(values);
      });
    });
  }

  render () {
    this.formConfig = this.selectColumnProps(this.props);
    const { visible, formData } = this.props;
    const { zIndex } = this.state;

    return (
      <Modal
        width={1024}
        zIndex={zIndex}
        title={this.getTitle()}
        visible={visible}
        onOk={this.handleSubmit}
        onCancel={this.onCancel}
      >
        {visible && <FormCreator
          getFormController={this.getFormController}
          formData={formData}
          formConfig={this.formConfig}
        />}
      </Modal>
    );
  }
}

export default StockNumberForm;
