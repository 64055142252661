import React, { ComponentType } from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTable/Addon/EnhancedTable';
import {
  getColumnConfigProps,
  IEditableColumnProps,
} from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';

import { Actions, connectDecorator, RootState } from 'src/store';
import { getTableColumns } from './getTableColumns';
import { getFormConfig } from './formConfig';
import styles from './index.module.less';

interface OwnProps {
  params?: Obj;
}
interface StateProps {
  unStockOutCount: number;
  unStockOutList: unknown[];
}
interface DispatchProps {
  getUnStockOutSummaryList: (params: unknown) => void;
}
interface Props extends OwnProps, StateProps, DispatchProps, RouteComponentProps {}
interface State {
  tableLoading: boolean;
  tableColumns: IEditableColumnProps<any>[];
  visible: boolean;
  fullWindow: boolean;
  modalTitle: string;
  formData: Obj;
}

const { EnhancedTable } = CardTableV2;

@connectDecorator(
  ({ orderSummary: { unStockOutCount, unStockOutList } }: RootState) => ({
    unStockOutCount, unStockOutList
  }),
  ({ orderSummary: { getUnStockOutSummaryList } }: Actions) => ({
    getUnStockOutSummaryList
  })
)
class UnStockOut extends React.PureComponent<Props, State> {
  private tableName = 'UN_STOCK_OUT_LIST';

  constructor(props: Props) {
    super(props);

    const columns = getTableColumns({
      openModal: this.openModal
    });
    const tableColumns = getColumnConfigProps(columns, this.tableName);

    this.state = {
      tableLoading: false,
      tableColumns,
      visible: false,
      fullWindow: true,
      modalTitle: '',
      formData: {}
    };
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getUnStockOutSummaryList } = this.props;
    const { formData, ...restData } = params;

    const queryParams = {
      ...restData,
      ...formData,
      productId: get(this.props.params, 'productId'),
    };

    try {
      this.setState({ tableLoading: true });

      await getUnStockOutSummaryList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  getScrollY = () => {
    const chipwingModal = document.querySelector('.order-summary-modal .chipwing-modal-body');

    const height = get(chipwingModal, 'offsetHeight');

    if (!height) return 700;

    // 这个14不知道从哪里缺失的，需要补上
    return height - 16 * 2 - 57 - 16 * 3 - 25 - 16 * 2 - 92 + 14;
  }

  openModal = (params) => {
    const { params: propsParams } = this.props;

    this.setState({
      visible: true,
      modalTitle: params?.modalTitle,
      formData: {
        processClass: params.processClass,
        productId: propsParams?.productId,
      }
    });
  }

  handleCancel = () => this.setState({ visible: false, modalTitle: '', formData: {} });
  onRequestFullWindow = () => this.setState({ fullWindow: true });
  onRequestNormalWindow = () => this.setState({ fullWindow: false });

  render () {
    const { unStockOutCount, unStockOutList } = this.props;
    const { tableLoading, tableColumns, visible, modalTitle, formData, fullWindow } = this.state;

    return (
      <>
        <CardTableV2
          card={{ title: '产品列表' }}
        >
          <EnhancedTable
            total={unStockOutCount}
            dataSource={unStockOutList}
            getTableData={this.getTableData}
            columns={tableColumns}
            resizable={true}
            scroll={{ y: this.getScrollY() }}
            tableLoading={tableLoading}
            clickMention={true}
            tableName={this.tableName}
          />
        </CardTableV2>

        <FlexModal
          title={`${modalTitle}详情`}
          visible={visible}
          width={1024}
          onCancel={this.handleCancel}
          footer={null}
          className={classnames(styles.unStockOutModal, 'un-stock-out-modal')}
          fullWindow={fullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
        >
          <FormCreator
            formData={formData}
            formConfig={getFormConfig()}
          />
        </FlexModal>
      </>
    );
  }
}

export default withRouter(UnStockOut) as ComponentType<OwnProps>;
