import * as React from 'react';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  SelectFormItemConfig,
  TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumMpwBusPreorderStatus } from '@chipcoo/constant';

import { getGroupItemConfig } from '../MPWShuttle/components/BusList/formConfig';
import MpwFlow from '../components/Flow';

const preOrderStatus: SelectFormItemConfig = {
  label: '预订单状态',
  type: 'select',
  dataKey: 'status',
  optionKey: 'mpwBusPreOrderStatus',
};

const rejectReason: TextAreaFormItemConfig = {
  label: '拒绝理由',
  type: 'textArea',
  dataKey: 'reason',
  colSpan: 24,
  renderType() {
    return this.formData?.status === EnumMpwBusPreorderStatus.rejected ? 'preview' : 'none';
  }
};

const flow = (modalName: string): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'flow',
  colSpan: 24,
  render() {
    const { formData } = this;
    const data = {
      nodes: [
        { id: 'CREATED', label: '预定' },
        { id: 'DESIGN', label: '审核' },
        { id: 'UNPAID', label: '设计' },
        { id: 'SUBMITTED', label: '提交数据' },
        { id: 'DEPARTED', label: '产出' },
      ],
      edges: [
        { source: 'CREATED', target: 'DESIGN' },
        { source: 'DESIGN', target: 'UNPAID' },
        { source: 'UNPAID', target: 'SUBMITTED' },
        { source: 'SUBMITTED', target: 'DEPARTED' },
      ]
    };

    return (
      <MpwFlow
        data={data}
        modalName={modalName}
        status={formData?.status}
      />
    );
  }
});

// 确认缴费
const contractNumber: InputFormItemConfig = {
  label: '合同编号',
  type: 'input',
  dataKey: 'contractNumber',
};

const contractDate: InputFormItemConfig = {
  label: '合同日期',
  type: 'input',
  dataKey: 'contractDate',
};

const logisticNumber: InputFormItemConfig = {
  label: '物流信息',
  type: 'input',
  dataKey: 'logisticNumber',
};

// 确认数据：GDS name、topcell name、GDS size、chip window、checksum
const gdsName: InputFormItemConfig = {
  label: 'GDS name',
  type: 'input',
  dataKey: 'gdsName',
};

const topCellName: InputFormItemConfig = {
  label: 'Topcell name',
  type: 'input',
  dataKey: 'topCellName',
};

const gdsSize: InputFormItemConfig = {
  label: 'GDS size',
  type: 'input',
  dataKey: 'gdsSize',
};

const chipWindow: InputFormItemConfig = {
  label: 'Chip window',
  type: 'input',
  dataKey: 'chipWindow',
};

const checksum: InputFormItemConfig = {
  label: 'Checksum',
  type: 'input',
  dataKey: 'checksum',
};

const nameFab: InputFormItemConfig = {
  label: 'Fab产品名',
  type: 'input',
  dataKey: 'nameFab',
};

export const getFormConfig = (modalName: string, name?: string): FormConfig => ({
  cardGroupConfig: [
    {
      groupItemConfig: [
        preOrderStatus,
        rejectReason,
      ] as FormItemConfig[]
    },
    {
      card: { title: '流程图' },
      groupItemConfig: [
        flow(modalName)
      ] as FormItemConfig[]
    },
    {
      card: { title: '预订信息' },
      groupItemConfig: [
        ...getGroupItemConfig('preview')
      ] as FormItemConfig[]
    },
    {
      card: { title: '合同信息' },
      groupItemConfig: [
        contractNumber,
        contractDate,
        logisticNumber,
      ] as FormItemConfig[],
      ifRender: (formData, initialData) => {
        // tslint:disable-next-line:no-shadowed-variable
        const { contractNumber } = initialData;

        return contractNumber;
      },
    },
    {
      card: { title: '数据信息' },
      groupItemConfig: [
        gdsName,
        topCellName,
        gdsSize,
        chipWindow,
        checksum,
        nameFab
      ] as FormItemConfig[],
      ifRender: (formData, initialData) => {
        // tslint:disable-next-line:no-shadowed-variable
        const { gdsName } = initialData;

        return gdsName;
      },
    }
  ]
});
