import React from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';

export interface RouteItem extends RouteProps {
  use?: React.ComponentClass;
  name?: string;
  breadcrumbName?: string;
  children?: RouteItem[];
  cache?: boolean;
  [key: string]: any;
}

function getPath(prefix: string = '', path: string = '') {
  if (path.indexOf('/') === 0) return path;

  return `${prefix}/${path}`.replace(/[\/]{2,}/g, '/').replace(/(.*)\/$/, '$1');
}

/**
 * 将嵌套路由转换成平铺路由。
 * 嵌套路由格式参考Vue, https://router.vuejs.org/guide/essentials/nested-routes.html
 * 只有 path 会被自动拼接，其它属性都是独立的。
 * @param routes 嵌套路由
 * @param pathPrefix
 * @return 平铺路由
 */
export function formatRoutes(routes: RouteItem[], pathPrefix: string = ''): RouteItem[] {
  return routes.reduce((allRoutes, cur) => {
    let { children, path, ...routeProps } = cur;

    // If 'path' prop is missing at current route. skip it.
    if (typeof path !== 'string') {
      return allRoutes;
    }

    // Combine route path by it's parents.
    const route = { ...routeProps, path: getPath(pathPrefix, path) };

    if (children) {
      // Copy children routes.
      children = children.slice();

      // Join parent route to children, make nested as normal.
      children.unshift(route);
      return allRoutes.concat(formatRoutes(children, route.path));
    }

    // If route only has one property 'path', skip it too.
    return allRoutes.concat(Object.keys(route).length !== 1 ? route : [] );
  }, []);
}

export function createRoutes(routes: RouteItem[], DefaultRoute: React.ComponentClass = Route): React.ReactNode[] {
  // First, format routes
  
  return formatRoutes(routes).map((props, index) => {
    const { use, ...extraProps } = props;

    if (use) {
      const Use = use;
      return <Use key={index} {...extraProps} />;
    }

    return (<DefaultRoute key={index} {...extraProps} />);
  });
}
