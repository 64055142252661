/**
 * 用来控制浏览标签页上的favicon上的徽标数
 */
import { PureComponent, ComponentType } from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'lodash';
import Favico from 'src/local_modules/favico.js';

import { RootState } from 'src/store';
import { env } from 'src/config/env';

interface OwnProps {}
interface StateProps {
  chatUnreadCount: number;
  notifyUnreadCount: number | undefined;
}
interface DispatchProps {

}
type P = OwnProps & StateProps & DispatchProps;
interface S {
}

// @ts-ignore
const favicon = new Favico({
  animation: 'none',
  // 非生产环境下显示为数字，主要是为了方便对未读消息的条数进行测试，但生产环境下直接给显示成数字
  noNum: env === 'prod'
});

class FaviconBadge extends PureComponent<P, S> {
  componentDidMount() {
    this.handleFavicon();
  }

  componentDidUpdate() {
    this.handleFavicon();
  }

  handleFavicon = () => {
    const { notifyUnreadCount, chatUnreadCount } = this.props;

    let notifyCount = 0;

    if (isNumber(notifyUnreadCount)) {
      notifyCount = notifyUnreadCount;
    }

    favicon.badge(notifyCount + chatUnreadCount);
  }

  render() {
    return null;
  }
}

const mapState = ({ notification, chatMisc }: RootState) => ({
  notifyUnreadCount: notification.statics.default.unread,
  chatUnreadCount: chatMisc.unread
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(FaviconBadge) as ComponentType<OwnProps>;
