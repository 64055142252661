import { createModel } from '@rematch/core';
import produce from 'immer';

import { TableModelCommonEffects } from 'src/models/type';
import { qcMonitorApi } from 'src/services/net';
import { State } from './interface';
import {
  handleMonitorHistoryList,
  handleMonitorHistoryDetail
} from './handle';

const initialState: State = {
  list: [],
  count: 0,

  detail: {},
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcMonitorApi.getHistoryList(params);
      const handleList = handleMonitorHistoryList(data?.data);

      this.setList({ data: handleList, count: data?.count });
    },
    async getTableItemDetail(params: any) {
      const { data } = await qcMonitorApi.getHistoryById(params);
      const handleData = handleMonitorHistoryDetail(data);

      this.setDetail(handleData);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
  })
});
