/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message, Popconfirm } from 'antd';
import { bind } from 'lodash-decorators';
import { RouteComponentProps } from 'react-router-dom';

import style from './index.module.less';
import { typeMap } from './';
import { SettingType } from './interface';

// import { PasswordAffirm } from 'src/containers';

// import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RootDispatch } from 'src/store';
import { WithWorkspaceId } from 'src/models/workspace/interface';
// import { compilePath } from 'src/components/HoneyRouter';

interface OwnProps {
  type: SettingType;
  id: string;
}

interface StateProps {}
interface DispatchProps {
  archiveWorkspace: (arg: WithWorkspaceId) => any;
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

interface State {
  modalVisible: boolean;
}

class Archive extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  // @bind
  // openModal() {
  //   this.setState({ modalVisible: true });
  // }

  // @bind
  // closeModal() {
  //   this.setState({ modalVisible: false });
  // }

  @bind
  async handleSubmit() {
    const { type, id, archiveWorkspace } = this.props;
    await archiveWorkspace({ workspaceId: id });

    message.success('已成功归档' + typeMap[type]);
  }

  render () {
    const { type } = this.props;

    return (
      <div>
        <h3>归档{typeMap[type]}</h3>
        <p className={style.warningRed}>如归档{typeMap[type]}，{typeMap[type]}内所有数据将变为只读。</p>
        <div style={{ display: 'flex' }}>
          <div className={style.content}>
            <p>确定归档本{typeMap[type]}？</p>
            <p>如已确定，请点击“归档”按钮。</p>
          </div>

          <Popconfirm title="确认归档" onConfirm={this.handleSubmit}>
            <Button type="danger">归档</Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ workspace: { archive } }: RootDispatch) => ({
  archiveWorkspace: archive,
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(Archive);
