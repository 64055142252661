import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { bind } from 'lodash-decorators';
import { Flow, withPropsAPI, PropsAPIHocFix, FlowProps, GGEditorEvent } from 'gg-editor';

import EditorMinimap from './EditorMinimap';
import FlowContextMenu from './ContextMenu';
import FlowToolbar from './Toolbar';
import FlowItemPanel from './EditorItemPanel';
import FlowDetailPanel from './EditorDetailPanel';
import { UNSAVED_SIGNAL, behaviorList } from './RegisterBehaviours';

import { 
  exchangeEdgeArrow,
  preventDeleteDest,
  storeDestNodeEdges,
  hoverShowAnchor,
  overrideDragEdgeBeforeShowAnchor,
  setOrientedEdge
} from './core';

import styles from './index.module.less';

interface Props extends PropsAPIHocFix, FlowProps {
}
interface State {}

// const toastDebounce = debounce(() => {
//   message.info('请先完成或取消当前节点编辑');
// }, 200);

class CraftRouteEditor extends PureComponent<Props, State> {
  graphConfig = {
    edgeDefaultShape: 'flow-polyline-round',
    mode: 'enhanced',
    modes: {
      enhanced: behaviorList,
    }
  };
  toastTimer: NodeJS.Timer;

  componentDidMount() {
    this.enhancePage();
  }

  enhancePage() {
    setTimeout(() => {
      const page = this.props.propsAPI.editor!.getCurrentPage();

      if (page) {
        hoverShowAnchor(page);
        overrideDragEdgeBeforeShowAnchor(page);
        setOrientedEdge(page);
        page.setSignal(UNSAVED_SIGNAL, []);
        // const { setItemSelected, clearItemSelected } = page;

        // page.clearItemSelected = async function(...args: any[]) {
        //   console.log('is item destroyed', args[0].destroyed, args[0]);
        //   if (page.getSignal(UNSAVED_SIGNAL) === args[0].id && !args[0].destroyed) {
        //     this.toastTimer = setTimeout(() => {
        //       if (args[0].destroyed) {
        //         clearItemSelected.call(page, ...args);
        //         return;     
        //       }
        //       message.info('请先完成或取消当前节点编辑');
        //     }, 100);
        //     return;
        //   }

        //   clearItemSelected.call(page, ...args);
        // };

        // page.setItemSelected = function(...args: any[]) {
        //   const unsavedSignal = page.getSignal(UNSAVED_SIGNAL);
        //   if (unsavedSignal) {
        //     if (unsavedSignal !== args[0].id) {
        //       // toastDebounce();
        //       return;
        //     } else {
        //       clearTimeout(this.toastTimer);
        //     }
        //   }

        //   setItemSelected.call(page, ...args);
        // };
      } else {
        console.error('Editor page now is undefined');
      }
    });
  }

  @bind
  beforeChange(ev: GGEditorEvent) {
    storeDestNodeEdges(ev);
  }

  @bind
  afterChange(ev: GGEditorEvent) {
    const { propsAPI } = this.props;
    const { action, item, originModel, updateModel } = ev;
    exchangeEdgeArrow(ev, propsAPI);
    preventDeleteDest(ev, propsAPI);

    if (action === 'remove') {
      setTimeout(() => {
        //
        const page = this.props.propsAPI.editor!.getCurrentPage();
        page.updateStatus();
      });
    }

    // 如果线条要连自身了，直接ban掉
    if ((action === 'add' || action === 'update') && item.type === 'edge') {
      const model = ev.model || { ...originModel, ...updateModel };

      if (model.source === model.target) {
        propsAPI.remove!(item);
      } else if (action === 'add' && !model.label) {
        propsAPI.update!(item, {
          label: '请选择制程',
        });
      }
    }
  }

  render() {
    return (
      <>
        <Row type="flex" className={styles.editorHd}>
          <Col span={24}>
            <FlowToolbar />
          </Col>
        </Row>
        <Row type="flex" className={styles.editorBd}>
          <Col span={4} className={styles.editorSidebar}>
            <FlowItemPanel />
          </Col>
          <Col span={15} className={styles.editorContent}>
            <Flow
              data={this.props.data}
              align={{
                grid: true,
              }}
              noEndEdge={false} 
              className={styles.flow}
              onBeforeChange={this.beforeChange}
              onAfterChange={this.afterChange}
              graph={this.graphConfig}
            />
          </Col>
          <Col span={5} className={styles.editorSidebar}>
            <FlowDetailPanel />
            <EditorMinimap />
          </Col>
        </Row>
        <FlowContextMenu />
      </>
    );
  }
}

export default withPropsAPI(CraftRouteEditor);
