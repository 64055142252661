// import * as React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'ChipwingProductName',
      width: 200,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'CustomerProductName',
      width: 200,
    },
    {
      dataIndex: 'lotId',
      key: 'lotId',
      title: 'Lot ID',
      width: 200
    },
    {
      dataIndex: 'process',
      key: 'process',
      title: 'Process',
      width: 200
    },
    {
      dataIndex: 'shipDate',
      key: 'shipDate',
      title: 'Ship Date',
      width: 150,
    },
    {
      dataIndex: 'poNumber',
      key: 'poNumber',
      title: 'Customer PO NO',
      width: 200,
    },
    {
      dataIndex: 'poDate',
      key: 'poDate',
      title: 'PO Date',
      width: 150,
    },
    {
      dataIndex: 'waferIds',
      key: 'waferIds',
      title: 'Wafer ID',
      width: 200
    },
    {
      dataIndex: 'shipQty',
      key: 'shipQty',
      title: 'Ship Qty',
      width: 200
    },
    {
      dataIndex: 'logisticsNumber',
      key: 'logisticsNumber',
      title: 'Forwarder',
      width: 200,
    },
    {
      dataIndex: 'address',
      key: 'address',
      title: 'ShipTo',
      width: 300,
    },
  ];
};
