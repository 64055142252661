import * as React from 'react';
import { Divider, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EnumMpwBusPreorderStatus } from '@chipcoo/constant';

interface Option {
  openModal: (orderId: string) => void;
  cancelOrder: (orderId: string) => void;
}

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'serial',
      key: 'serial',
      title: '预定单单号',
    },
    {
      dataIndex: 'contact',
      key: 'contact',
      title: 'Contact window',
    },
    {
      dataIndex: 'factoryName',
      key: 'factoryName',
      title: '加工厂',
    },
    {
      dataIndex: 'chipcooProductName',
      key: 'chipcooProductName',
      title: '芯翼产品名称',
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Customer Product name',
    },
    {
      dataIndex: 'node',
      key: 'node',
      title: 'Geometry',
    },
    {
      dataIndex: 'tapeOutDate',
      key: 'tapeOutDate',
      title: 'Tape-in-date',
    },
    {
      dataIndex: 'process',
      key: 'process',
      title: 'Technology Type',
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '预定记录状态',
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record.key);
        };
        const cancelOrder = () => {
          option.cancelOrder(record.key);
        };

        const status = record?.status;
        const bool = status === EnumMpwBusPreorderStatus.created ||
          status === EnumMpwBusPreorderStatus.design;

        return (
          <>
            <a onClick={openModal}>
              查看
            </a>

            {
              bool ? (
                <>
                  <Divider type="vertical" />

                  <Popconfirm
                    title="是否要取消该条预订记录？"
                    onConfirm={() => cancelOrder()}
                  >
                    <a>取消</a>
                  </Popconfirm>
                </>
              ) : null
            }
          </>
        );
      }
    },
  ];
};
