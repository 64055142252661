import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  value?: string;
  label: string | ReactNode;
  // 有些时候传入的value并不是最终需要展示的数据，需要转一道
  transformVal?: (value: string) => string;
  className?: string
};
export default memo((props: Props) => {
  const { value, label, transformVal, className } = props;

  if (!value) return null;

  let newVal = transformVal ? transformVal(value) : value;

  return (
    <div className={classNames('flex', className)}>
      <p className={classNames('term flex-static flex align-center')}>
        {label}
      </p>
      <p className={classNames('flex-fill', 'content')}>
        {newVal}
      </p>
    </div>
  );
});
