import * as React from 'react';
import _ from 'lodash';

type Props = {
  formData: Obj;
  value?: any;
  onChange?: (value: any) => void;
};

interface State {
}

class AnalysisDataDirty extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    const { value, formData, onChange } = this.props;

    if (!_.isEqual(prevProps.formData, formData) && formData?.analytiCalSolution >= 0) {
      const obj = this.getNewObj(formData);

      if (!_.isEqual(obj, formData?.handleAnalysisData) && !value) {
        onChange && onChange(true);
      }

      if (value && _.isEqual(obj, formData?.handleAnalysisData)) {
        onChange && onChange(false);
      }
    }
  }

  getNewObj = (formData) => {
    return _.pick(formData, ['dieNumber', 'waferIdArray', 'goodBins', 'slices']);
  }

  render () {
    return (
      <div />
    );
  }
}

export default AnalysisDataDirty;
