import React, { PureComponent, ReactNode } from 'react';
import { bind } from 'lodash-decorators';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import { FormComponentProps } from 'antd/lib/form';
import to from 'await-to-js';
import { Modal } from '@chipcoo/hanayo';
import log from 'loglevel';

import { validateForm } from 'src/utils/form';
import { QuickSelectProps, QuickSelectUser } from 'src/components/QuickSelectUser';
import formModalStyles from 'src/styles/form-modal.module.less';
import styles from './modal.module.less';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const FormItem = Form.Item;
const { TextArea } = Input;

export type FormValues = {
  name: string;
  description: string;
};

interface Props extends FormComponentProps {
  visible: boolean;
  renderUserSelect?: boolean;
  value?: FormValues;
  title?: string;
  autoFocus?: boolean;
  onClose?: (...args: any[]) => any;
  afterOpen?: (form?: WrappedFormUtils) => any;
  onSubmit?: (value: FormValues) => any;
  quickSelectProps?: QuickSelectProps;
}

interface States {
  curVisible: boolean;
}

class WorkspaceModal extends PureComponent<Props, States> {
  private inputRef: ReactNode = null;
  state = {
    curVisible: false
  };

  static defaultProps = {
    title: '',
    visible: false,
    autoFocus: false,
    renderUserSelect: true,
    values: {
      name: '',
      description: '',
    },
    onClose() {
      this.setState({ curVisible: false });
    },
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: States) {
    const next: any = {};

    if (nextProps.visible !== prevState.curVisible) {
      next.curVisible = nextProps.visible;
    }

    return next;
  }

  componentDidUpdate(_: Props, prevState: States) {
    const { curVisible } = prevState;

    if (!curVisible && this.state.curVisible && this.props.afterOpen) {
      this.props.afterOpen(this.props.form);
    }
  }

  @bind
  bindRef(ref: ReactNode) {
    this.inputRef = ref;
  }

  @bind
  tryFocus() {
    if (this.props.autoFocus && this.inputRef) {
      try {
        (this.inputRef as Input).focus();
      } catch (e) {
        log.error(e);
      }
    }
  }

  @bind
  onClose() {
    this.props.onClose && this.props.onClose.call(this);
  }

  @bind
  async handleSubmit(e?: any) {
    const { onSubmit } = this.props;
    if (e) {
      e.preventDefault();
    }

    const [errs, values] = await to(validateForm<FormValues>(this.props.form));

    if (errs) {
      log.error(errs);
      return Promise.reject(errs);
    }

    if (onSubmit) {
      return onSubmit(values!);
    }

    return Promise.resolve(true);
  }

  renderSelect() {
    const { quickSelectProps } = this.props;

    return (<QuickSelectUser {...quickSelectProps} />);
  }

  renderForm() {
    const { renderUserSelect } = this.props;
    const { getFieldDecorator } = this.props.form;
    // const { members } = this.props.value!;

    return (
      <Form className={classNames(formModalStyles.modalContent, styles.workspaceModal)} onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: '必填项' }]
          })(<Input ref={this.bindRef} autoComplete="off" placeholder="协作区名称 ( 必填 )" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('description')(
            <TextArea placeholder="协作区简介" autosize={{ minRows: 6, maxRows: 6 }} />
          )}
        </FormItem>
        {
          renderUserSelect && (
            <div className="workspace-members">
              <header>协作区成员</header>
              {this.renderSelect()}
            </div>
          )
        }
      </Form>
    );
  }

  render() {
    const { curVisible } = this.state;
    const { title } = this.props;

    return (
      <Modal
        visible={curVisible}
        title={title}
        onCancel={this.onClose}
        onOk={this.handleSubmit}
        onAfterOpen={this.tryFocus}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

export const WorkspaceModalForm = (Form as any).create({})(WorkspaceModal) as React.ComponentType<Omit<Props, 'form'>>;

export default WorkspaceModalForm;
