import React, { PureComponent } from 'react';
import { ceil, cloneDeep, find, floor, isEqual, isInteger, isEmpty } from 'lodash';
import { InputNumberWithUnit, selectOriginOptionData } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import { calcCount, getDieQty } from 'src/pages/Operation/Order/DemandOrderTable/util';
import { productApi } from 'src/services/net';
import styles from './index.module.less';

interface Props {
  unit: '颗' | '片';
  product: string | undefined;
  productModel: EnumProductModel;
  formStatus: FormStatus;
  demandWaferQuantity?: number;
  demandQuantity?: number;
  onChange?: (val: any) => void;
  value?: any;
  selectedData: any[];
  needFormatter?: boolean;
}

interface State {
  dieQtyInPackage: number;
  perWaferDieQty: number;
}

class Quantity extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dieQtyInPackage: 0,
      perWaferDieQty: 0
    };
  }

  async componentDidMount() {
    await this.getCalcCountQty();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    let { dieQtyInPackage } = this.state;

    if (!dieQtyInPackage) {
      await this.getCalcCountQty();

      this.caculateQuantity(prevProps);
    } else {
      this.caculateQuantity(prevProps);
    }
  }

  // 根据dieQtyInPackage, perWaferDieQty计算demandQuantity和demandWaferQuantity
  caculateQuantity = (prevProps) => {
    const { productModel, demandQuantity, demandWaferQuantity, onChange, selectedData } = this.props;
    let { dieQtyInPackage, perWaferDieQty } = this.state;
    let [count, waferCount] = [0, 0];

    if (isEmpty(selectedData)) {
      if (!isEqual(demandQuantity, prevProps.demandQuantity)) {
        if (demandQuantity) {
          waferCount = calcCount({
            demandQuantity,
            dieQtyInPackage,
            perWaferDieQty,
            productModel
          });

          if (waferCount?.toString().split('.')[1]?.length > 2) {
            waferCount = parseFloat(waferCount.toFixed(2));
          }

          onChange && onChange(waferCount);
        }
      }

      if (!isEqual(demandWaferQuantity, prevProps.demandWaferQuantity)) {
        if (demandWaferQuantity) {
          count = calcCount({
            waferCount: demandWaferQuantity,
            dieQtyInPackage,
            perWaferDieQty,
            productModel,
          });

          onChange && onChange(count);
        }
      }
    }
  }

  // 获取产品里的dieQtyInPackage和perWaferDieQty
  getCalcCountQty = async () => {
    const { productModel, product } = this.props;
    const cachedData = selectOriginOptionData.getCachedData();
    const optionsData = cachedData.getProductByProductModel;
    let productInfo = find(optionsData, it => it._id === product);

    if (!productInfo && product) {
      const { data } = await productApi.getProductById(product!);
      productInfo = data;
    }

    const dieQty = getDieQty({
      productInfo,
      productModel,
    });

    this.setState({
      dieQtyInPackage: dieQty?.dieQtyInPackage,
      perWaferDieQty: dieQty?.perWaferDieQty
    });
  }

  diffOnChange = (value, prevValue) => {
    let deepValue = cloneDeep(value);

    if (!isInteger(value)) {
      if (value - prevValue >= 0) {
        // 表示向上步进，对于value来说，向下取整
        deepValue = floor(value);
      } else {
        // 表示向下步进，对于value来说，向上取整
        deepValue = ceil(value);
      }
    }

    return deepValue;
  }

  handleOnChange = (e) => {
    const { value, onChange } = this.props;

    const newValue = this.diffOnChange(e, value);

    onChange && onChange(newValue);
  }

  render() {
    const { value, unit, formStatus, needFormatter } = this.props;
    // let restProps;

    if (formStatus === 'preview') {
      return <div>{value}{unit}</div>;
    }

    if (needFormatter) {
      return (
        <div className={styles.quantity}>
          <span className="vertical-middle">约</span>

          <InputNumberWithUnit
            unit={unit}
            // precision={0}
            onChange={this.handleOnChange}
            value={value}
          />
        </div>
      );
    }

    return (
      <InputNumberWithUnit
        unit={unit}
        // precision={0}
        onChange={this.handleOnChange}
        value={value}
      />
    );
  }
}

export default Quantity;
