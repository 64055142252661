import { EnumProductModel } from '@chipcoo/constant';

import { ROUTE_NAMES } from 'src/router/normalRouter';

const stockOutCardGrids = [
  {
    key: '0',
    title: 'SPW芯片',
    description: '申请SPW芯片出货',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.spwDie },
  },
  {
    key: '1',
    title: '封装成品',
    description: '申请封装成品出货',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.assemblyProduct },
  },
  {
    key: '2',
    title: '磨划成品',
    description: '申请磨划成品出货',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.dpProduct },
  },
];

export function fillAppList() {
  stockOutCardGrids[0].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUT;
  stockOutCardGrids[1].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUT;
  stockOutCardGrids[2].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUT;
}

setTimeout(fillAppList);

export default stockOutCardGrids;
