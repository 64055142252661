import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { isEmpty, isArray } from 'lodash';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { noop } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { getAddressByCode } from './utils';

import PickPanel from './PickPanel';

interface Props extends Omit<DropDownProps, 'overlay'> {
  size?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  value?: Obj | string[];
  onChange?: (val: any) => void;
}

interface State {
  isDropDownOpen: boolean;
  regionCode: string[];
  regionText: string;
  regionCodeText: string[];
  regionData: any;
}

const baseCls = 'ant-select';
const selectionCls = 'ant-select-selection';

class AddressSelector extends React.PureComponent<Props, State> {
  static defaultProps = {
    allowClear: true,
    disabled: false,
    placeholder: '请选择省/市/区',
    selectMode: 'single',
    size: 'large',
  };

  constructor(props: Props) {
    super(props);
    const { value } = props;
    const regionCode = isArray(value) ? value : value?.regionCode;

    this.state = {
      isDropDownOpen: false,
      regionCode: regionCode || [],
      regionText: '',
      regionCodeText: [],
      regionData: {},
    };
  }

  async componentDidMount() {
    const { value } = this.props;
    const { regionData, regionCode } = this.state;

    if (isEmpty(regionData)) {
      try {
        const { data } = await axios.get('https://static.chipcoo.com/china-area-data.K8v9v7C4zCAWuj6N.json');

        this.setState({ regionData: data });
      } catch (e) {
        console.error(e);
      }
    }

    if (value && !isEmpty(value)) {
      const regionText = getAddressByCode(this.state.regionData, regionCode);

      this.setState({ regionText });
    }
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({ isDropDownOpen: visible });
  }

  renderInputVal = () => {
    const { placeholder } = this.props;
    const { regionText } = this.state;
    const [cls, val] = regionText ? ['-selected-value', regionText] : ['__placeholder', placeholder];

    return (
      <div className={`${selectionCls}__rendered`}>
        <div className={`${selectionCls}${cls}`}>{val}</div>
      </div>
    );
  }

  // 地址面板change Func
  handleChange = (formData) => {
    const { onChange } = this.props;

    this.setState({
      isDropDownOpen: false,
      ...formData,
    });

    onChange && onChange(formData);
  }

  render () {
    const { props } = this;
    const {
      className,
      disabled,
      size,
    } = props;
    const { isDropDownOpen, regionData, regionCode } = this.state;
    const rootCls = {
      [className!]: !!className,
      [baseCls]: 1,
      [`${baseCls}-sm`]: size === 'small',
      [`${baseCls}-disabled`]: disabled,
      [`${baseCls}-enabled`]: !disabled,
      [`${baseCls}-open`]: isDropDownOpen,
    };

    return (
      <Dropdown
        {...props}
        trigger={['click']}
        visible={isDropDownOpen}
        onVisibleChange={this.handleVisibleChange}
        overlay={(
          <PickPanel
            value={regionCode}
            regionData={regionData}
            onChange={this.handleChange}
          />
        )}
        getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
      >
        <div className={classNames(rootCls, styles.address)} style={props.style}>
          <div className={classNames(`${selectionCls} ${selectionCls}--single`)}>
            {this.renderInputVal()}
            <span className="ant-select-arrow" />
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default AddressSelector;
