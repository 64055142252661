import * as React from 'react';
import { Divider } from 'antd';
import { EnumStockProcessStatus } from '@chipcoo/constant';

import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {
  openModal: (_id: string) => void;
  openWip: (_id: string) => void;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      title: '加工单号',
      dataIndex: 'serial',
      key: 'serial',
      fixed: 'left',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'manufactureType',
      key: 'manufactureType',
      title: '加工类型',
      width: 120
    },
    {
      dataIndex: 'reason',
      key: 'reason',
      title: '加工依据',
      width: 100
    },
    {
      dataIndex: 'reasonSerial',
      key: 'reasonSerial',
      title: '加工依据单号',
      width: 250,
      canCopy: true,
    },
    {
      dataIndex: 'inputs.productName',
      key: 'inputs.productName',
      title: '加工前产品名称',
      width: 200,
    },
    {
      dataIndex: 'inputs.materialName',
      key: 'inputs.materialName',
      title: '加工前物料名称',
      width: 200
    },
    {
      dataIndex: 'inputs.dieNumber',
      key: 'inputs.dieNumber',
      title: '芯片批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'inputs.amount',
      key: 'inputs.amount',
      title: '加工数量',
      width: 200
    },
    {
      dataIndex: 'inputs.waferAmount',
      key: 'inputs.waferAmount',
      title: '片数',
      width: 100
    },
    {
      dataIndex: 'manufactureOperation',
      key: 'manufactureOperation',
      title: '加工操作',
      width: 100
    },
    {
      dataIndex: 'factory',
      key: 'factory',
      title: '加工厂',
      width: 200
    },
    {
      dataIndex: 'demandTime',
      key: 'demandTime',
      title: '要求交期',
      width: 150
    },
    {
      dataIndex: 'output.productName',
      key: 'output.productName',
      title: '加工后产品名称',
      width: 150
    },
    {
      dataIndex: 'output.materialName',
      key: 'output.materialName',
      title: '加工后物料名称',
      width: 150
    },
    {
      dataIndex: 'output.stampNumber',
      key: 'output.stampNumber',
      title: '印章批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'output.amount',
      key: 'output.amount',
      title: '已加工完成数量',
      width: 150
    },
    {
      dataIndex: 'output.waferAmount',
      key: 'output.waferAmount',
      title: '已加工完成片数',
      width: 150
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '加工状态',
      width: 150
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: '加工单创建时间',
      width: 200
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      width: 110,
      fixed: 'right',
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record._id);
        };
        const openWip = () => {
          option.openWip(record?._id);
        };

        return (
          <>
            <a onClick={openModal}>
              查看
            </a>

            {
              record?.status === EnumStockProcessStatus.processing && (
                <>
                  <Divider type="vertical" />
                  <a onClick={openWip}>WIP</a>
                </>
              )
            }
          </>
        );
      }
    },
  ];
};
