/**
 * Created by marvin on 2019/1/13.
 */

import * as React from 'react';

import appList, { refreshAppList } from './appList';
import { getCurrentRoute } from 'src/components/HoneyRouter';
import { checkRequireVerify } from '../require-verify';

import { ManageAppList } from 'src/components';

export default () => {
  refreshAppList();

  checkRequireVerify();

  const { match: { params } } = getCurrentRoute()!;

  return <ManageAppList dataSource={appList} params={params} />;
};

export { default as Order } from './Order';
// export { default as InventoryTable } from './InventoryTable';
export { default as Process } from './Process';
export { default as Abnormality } from './Abnormality';
export { default as Statement } from './Statement';
export { default as InventoryManage } from './InventoryManage';
export { default as OrderSummary } from './OrderSummary';
