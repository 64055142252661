import { createSelector } from 'reselect';
import { get, has, cloneDeep } from 'lodash';
import { InitialState } from './interface';

export const selectTrialDetail = createSelector(
  (state: InitialState) => get(state, 'trial.detail'),
  (result) => {
    const detail = cloneDeep(result);
    if (has(detail, 'riskEvaluation')) {
      const data = detail.riskEvaluation;

      ['isAnyHighRiskPoint', 'isApproveToDoRiskRun'].forEach(key => {
        if (has(data, key)) {
          const val = data[key];
          const isTrue = val.toString() === 'true';
          data[key] = isTrue.toString();
        }
      });
    }

    ['engineerEvaluations', 'formalRuns'].forEach(step => {
      if (has(detail, step)) {
        const data = detail[step];
        data.forEach(evaluation => {
          if (has(evaluation, 'isApproveToDoRiskRun')) {
            const isTrue = evaluation.isApproveToDoRiskRun.toString() === 'true';
            evaluation.isApproveToDoRiskRun = isTrue.toString();
          }
        });
      }
    });

    return detail;
  }
);
