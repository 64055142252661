import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';
import { uniqueId, findIndex } from 'lodash';
import { Button, Icon } from 'antd';
import { FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumProductModel } from '@chipcoo/constant';

import MaterialCollapse from './MaterialCollapse';
import { getStockInConfig } from './formConfig';
import styles from './index.module.less';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  globalState: string;
  outerFormData: any;
  productModel: EnumProductModel;
}

interface State {
  visible: boolean;
  materialItemId: string | undefined;
  fullWindow: boolean;
  dataSource: any[];
}

const EditableMaterial: FC<Props> = props => {
  let formController;
  const localMaterial = 'LOCAL_MATERIAL_';

  const { globalState, outerFormData, value, onChange, productModel } = props;
  const [state, setState] = useState<State>({
    visible: false,
    materialItemId: undefined,
    fullWindow: true,
    dataSource: [],
  });
  const { visible, fullWindow, materialItemId, dataSource } = state;
  
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      dataSource: value ?? []
    }));
  }, [value]);

  const getFormController = (controller) => {
    formController = controller;
  };

  const openModal = (activeItemId: string | undefined) => {
    setState(prevState => ({ ...prevState, materialItemId: activeItemId, visible: true }));
  };

  const onCloseMdoal = () => {
    setState(prevState => ({ ...prevState, visible: false, materialItemId: undefined }));
  };

  const renderAppendButton = () => {
    if (globalState === 'preview') {
      return null;
    }

    const newItemId = uniqueId(localMaterial);

    return (
      <Button
        className={classnames(styles.addMaterialBtn, 'blue-btn-border')}
        block={true}
        type={'dashed'}
        onClick={() => openModal(newItemId)}
      >
        <Icon type="plus" />添加一批
      </Button>
    );
  };

  const deleteMaterialItem = (id) => {
    const newDataSource = dataSource.filter(it => it?._id !== id);

    setState(prevState => ({ ...prevState, dataSource: newDataSource }));
    onChange && onChange(newDataSource);
  };

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
      const { validateFieldsAndScroll } = formController;

      validateFieldsAndScroll(async (err, values) => {
        if (err) {
          reject(err);
          return;
        }
        try {
          const { _id } = values;
          let newDataSource = dataSource;
          const index = findIndex(dataSource, it => it._id === _id);
          if (index < 0) {
            newDataSource.push(values);
          } else {
            newDataSource.splice(index, 1, values);
          }

          setState(prevState => ({
            ...prevState,
            dataSource: newDataSource,
          }));

          onChange && onChange(newDataSource);

          onCloseMdoal();
          resolve(true);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    });
  };

  const onRequestFullWindow = () => {
    setState({ ...state, fullWindow: true });
  };

  const onRequestNormalWindow = () => {
    setState({ ...state, fullWindow: false });
  };
  const getFormData = () => {
    if (materialItemId) {
      const currentFormData = dataSource.find(it => it?._id === materialItemId);

      if (currentFormData) {
        return {
          ...currentFormData,
          productModel,
        };
      }

      return {
        _id: materialItemId,
        ...currentFormData,
        productModel,
        isParseMap: true,
      };
    }
  };

  return (
    <div>
      <MaterialCollapse
        materialDetail={value}
        globalState={globalState}
        onEdit={openModal}
        onDelete={deleteMaterialItem}
        outerFormData={{...outerFormData, productModel}}
      />
      {renderAppendButton()}

      <FlexModal
        width={1024}
        visible={visible}
        title="添加一批物料"
        onOk={() => onSubmit()}
        onCancel={() => onCloseMdoal()}
        fullWindow={fullWindow}
        onRequestNormalWindow={onRequestNormalWindow}
        onRequestFullWindow={onRequestFullWindow}
      >
        <FormCreator
          formConfig={getStockInConfig({productModel, outerFormData})}
          formData={getFormData()}
          getFormController={getFormController}
        />
      </FlexModal>
    </div>
  );
};

export default EditableMaterial;
