import React, { FC, useState, useEffect } from 'react';

import { productApi } from 'src/services/net';

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
  productId: string;
}

interface IState {
  productInfo: any;
}

const UniwaferAmount: FC<IProps> = props => {
  const { productId, onChange } = props;

  const [state, setState] = useState<IState>({
    productInfo: {},
  });
  const { productInfo } = state;

  const getProductInfo = () => {
    productApi.getProductById(productId!).then(res => {
      setState({
        ...state,
        productInfo: res.data
      });
    });
  };

  useEffect(() => {
    onChange && onChange(productInfo);
  }, [productInfo]);

  useEffect(() => {
    if (productId) {

      getProductInfo();
    }
  }, [productId]);

  return (
    <div />
  );
};

export default UniwaferAmount;
