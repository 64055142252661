import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { mpwApi } from 'src/services/net';
import {
  handlePreOrderBusData,
  handlePreOrderList,
  handlePreOrderData,
} from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: {}
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.list;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await mpwApi.listBusPreOrder(params);
      const list = handlePreOrderList(data.data);

      this.setList({ count: data?.count, list });
    },
    async postCreate(data: any) {
      const handleData = handlePreOrderBusData(data);

      await mpwApi.createMpwBus(handleData);
    },
    async patchModify(data: any) {
      const handleData = handlePreOrderBusData(data);

      await mpwApi.patchMpwBus(handleData);
    },
    async getTableItemDetail(orderId: string) {
      const { data } = await mpwApi.getMpwBusPreOrder(orderId);
      const handleData = handlePreOrderData(data);

      this.setDetail(handleData);
    },
    async cancelPreOrder(orderId: string) {
      await mpwApi.cancelBusOrder(orderId);
    }
  })
});
