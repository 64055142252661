// tslint:disable:max-file-line-count
import React, { memo } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import {
  // SearchFormItemConfig,
  CustomFormItemConfig,
  InputFormItemConfig,
  DateFormItemConfig,
  TextAreaFormItemConfig, SelectFormItemConfig,
  // NumberFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProcessClass, EnumProductModel, EnumRequirementOrderStatus } from '@chipcoo/constant';

import styles from '../index.module.less';
import { optionsGroup } from 'src/config/selectOption';
import { Option } from '../../formConfig';

import { CollapseArea } from 'src/components';
import ProductInfo from '../../../components/ProductInfo';
import {
  BlockButton,
  OrderTemplateList,
  Quantity,
  SelectionMode,
  SelectedTableData,
  AllowReplace,
  // QuantityText,
  FetchProduct,
  Product,
  FetchPolicy,
  MarkingContentFrom,
  MarkingContent, SelectedDataInfo
} from '../components';
import { UploadByPaste } from 'src/containers';

type SimpleTextProps = {
  value?: string;
  label?: string;
  nameKey?: string;
  isError?: boolean;
};

// 获取产品是否可以智能选料信息
// const getProductCanSmartSelection = (fetchProduct) => {
//   // 为true代表可以智能选料，否则不能
//   return fetchProduct?.canSmartSelection;
// };

/*************************** hidden form item start **********************************/
// 通过productId获取具体的产品信息的隐藏表单项
export const getProductHiddenFormItem = (): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'fetchProduct',
  colClassName: 'hidden-form-item',
  render() {
    const { formData } = this;
    return (
      <FetchProduct productId={formData?.product?.key} />
    );
  }
});

// 通过productId获取policy的隐藏表单项
export const getPolicyById: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'fetchPolicy',
  colClassName: 'hidden-form-item',
  render() {
    const { product } = this.formData;
    return (
      <FetchPolicy productId={product?.key || product} />
    );
  }
};

// 这一项作为缓存，用以判断及取值
export const hiddenSelectedData = (): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'selectedData',
  colClassName: 'hidden-form-item',
  render() {
    return <div />;
  }
});

export const hiddenProductModel = (): InputFormItemConfig => ({
  type: 'input',
  dataKey: 'productModel',
  colClassName: 'hidden-form-item',
});

// 需求数量
export const quantityHiddenFormItem: InputFormItemConfig = {
  type: 'input',
  dataKey: 'quantity',
  colClassName: 'hidden-form-item',
};

// 投料片数
export const waferQuantityHiddenFormItem: InputFormItemConfig = {
  type: 'input',
  dataKey: 'waferQuantity',
  colClassName: 'hidden-form-item',
};
/*************************** hidden form item end **********************************/

export const SimpleText = memo((props: SimpleTextProps) => {
  const { value, label, nameKey, isError } = props;

  if (!value) return null;

  let newVal = value;

  if (nameKey === 'status') {
    newVal = value ? optionsGroup.requirementStatus.options![value] : '--';
  }

  return (
    <div className="flex">
      {
        label && (
          <p className="term flex-static">
            {label}
          </p>
        )
      }
      <p className={classNames('flex-fill', { 'error-text': isError })}>
        {newVal}
      </p>
    </div>
  );
});

export const files = (): CustomFormItemConfig => ({
  label: '附件',
  type: 'custom',
  dataKey: 'causeData.files',
  renderType: 'preview',
  colClassName: 'files-style',
  colSpan: 24,
  render() {
    return <UploadByPaste renderType="preview" />;
  }
});

export const description = (): TextAreaFormItemConfig => ({
  label: '备注',
  type: 'textArea',
  dataKey: 'causeData.description',
  colSpan: 24,
  renderType: 'preview',
});

export const demandOrderType: SelectFormItemConfig = {
  label: '订单类型',
  type: 'select',
  dataKey: 'type',
  optionKey: 'demandOrderType',
  required: true
};

// 当前状态
export const currentStatus: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'status',
  colClassName: styles.simpleText,
  render: () => <SimpleText label="当前状态" nameKey="status"/>
};

// 拒绝理由-展示文本
export const RejectReason: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'auditReason',
  colSpan: 24,
  colClassName: styles.simpleText,
  render: () => <SimpleText label="拒绝理由" />,
  renderType: (formData) => formData.status === EnumRequirementOrderStatus.rejected ? 'normal' : 'none'
};

export const getProduct = (options: Option): CustomFormItemConfig => {
  const { productModel } = options;

  // return {
  //   label: '产品名称',
  //   type: 'search',
  //   dataKey: 'product',
  //   optionKey: 'getProductByProductModel',
  //   required: true,
  //   props: {
  //     addNew: null,
  //     extraFetchParams: { productModel }
  //   },
  // };
  return {
    label: '产品名称',
    type: 'custom',
    dataKey: 'product',
    required: true,
    requiredMsg: '请选择产品名称',
    render() {
      const { form, formStatus } = this;

      return (
        <Product
          productModel={productModel}
          setFieldsValue={form.setFieldsValue}
          formStatus={formStatus}
        />
      );
    }
  };
};

export const orderTemplateId = (productModel: EnumProductModel): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'orderTemplate',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择订购名',
  render() {
    const { formData, formStatus, initialFormData } = this;
    const productId = formData?.product?.key || formData?.product;

    return (
      <OrderTemplateList
        productId={productId}
        productModel={productModel}
        status={formStatus}
        initialFormData={initialFormData}
        fetchProduct={initialFormData?.fetchProduct || formData?.fetchProduct}
      />
    );
  },
  renderType() {
    return this.formData?.product ? 'normal' : 'none';
  }
});

export const getProductInfo = (productModel: EnumProductModel): CustomFormItemConfig => ({
  type: 'custom',
  colSpan: 24,
  colClassName: 'collapse-area',
  render() {
    return (
      <CollapseArea>
        <ProductInfo product={get(this, 'formData.product.key')} productModel={productModel} />
      </CollapseArea>
    );
  },
  renderType: (formData) => formData.product ? 'normal' : 'none',
});

export const markingInfo: CustomFormItemConfig = {
  label: '打印内容',
  type: 'custom',
  dataKey: 'markingContentFrom',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择打印内容',
  render() {
    const { fetchPolicy } = this.formData;
    return (
      <MarkingContentFrom fetchPolicy={fetchPolicy} status={this.formStatus} />
    );
  },
  renderType() {
    const { formData, initialFormData } = this;
    return (
      initialFormData?.classes === EnumProcessClass.assembly ||
      initialFormData?.classes === EnumProcessClass.ft
    ) && formData?.product ? 'normal' : 'none';
  }
};

export const markingContent: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'markingContent',
  colSpan: 24,
  required: true,
  requiredMsg: '请填写打印规则',
  render() {
    const { fetchProduct } = this.formData;
    return <MarkingContent fetchProduct={fetchProduct} status={this.formStatus} />;
  },
  renderType() {
    return this.formData.markingContentFrom === 'USER' ? 'normal' : 'none';
  }
};

export const selectionMode: CustomFormItemConfig = {
  label: '选料方式',
  type: 'custom',
  dataKey: 'selectionMode',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择选料方式',
  render() {
    const { fetchPolicy } = this.formData;

    return (
      <SelectionMode
        fetchPolicy={fetchPolicy}
      />
    );
  },
  renderType() {
    const { formData, formStatus } = this;
    return formData?.type && formData?.product && formStatus !== 'preview' ? 'normal' : 'none';
  }
};

export const getBlockButton = (options: Option): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'blockButton',
  colSpan: 24,
  colClassName: 'no-label-form-item',
  render() {
    const { productModel } = options;
    const { formData, form, initialFormData } = this;

    return (
      <BlockButton
        productModel={productModel}
        type={formData?.type}
        product={get(formData, 'product.key')}
        setFieldsValue={form.setFieldsValue}
        classes={initialFormData.classes}
      />
    );
  },
  required() {
    return !this.formData?.quantity;
  },
  requiredMsg: '请选择物料',
  renderType() {
    const { formData, formStatus } = this;

    if (formData?.selectionMode === 'auto' && formStatus !== 'preview' && !formData?.selectedData) {
      return 'normal';
    }

    return 'none';
  }
});

export const selectedDataInfo = (mode): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'selectedDataInfo',
  renderType() {
    const { formData } = this;

    return formData?.selectionMode === mode ? 'none' : 'normal';
  },
  render() {
    const { initialFormData } = this;

    return (
      <SelectedDataInfo
        isNeedDetail={false}
        classes={this.initialFormData?.classes}
        selectedData={this.formData.selectedData}
        demandQuantity={initialFormData?.demandQuantity}
        demandWaferQuantity={initialFormData?.demandWaferQuantity}
        selectionMode={this.formData.selectionMode}
      />
    );
  }
});

export const demandQuantity = (options, mode): CustomFormItemConfig => ({
  label: '需求数量',
  type: 'custom',
  dataKey: 'demandQuantity',
  // unit: '颗',
  // precision: 0,
  required() {
    return this.formData?.selectionMode === mode;
  },
  requiredMsg: '请输入需求数量',
  validate: [{ pattern: /^[1-9]\d*$/, message: '需求数量必须为正整数颗' }],
  renderType() {
    const { formData } = this;
    const classes = formData?.orderTemplate?.material?.process?.classes;

    return formData?.selectionMode === mode && classes ? 'normal' : 'none';
  },
  render(__: any, globalStatus: any) {
    const { formData } = this;
    return (
      <Quantity
        demandWaferQuantity={formData?.demandWaferQuantity}
        product={formData?.product?.key}
        productModel={options.productModel}
        unit="颗"
        formStatus={globalStatus}
        selectedData={formData?.selectedData}
        needFormatter={true}
      />
    );
  },
});

export const demandWaferQuantity = (options, mode): CustomFormItemConfig => ({
  label: '投料数量',
  type: 'custom',
  dataKey: 'demandWaferQuantity',
  // unit: '片',
  // precision: 0,
  required() {
    return this.formData?.selectionMode === mode;
  },
  requiredMsg: '请输入投料数量',
  validate: [{ pattern: /^[1-9]\d*$/, message: '投料数量必须为正整数片' }],
  renderType() {
    const { formData } = this;
    const classes = formData?.orderTemplate?.material?.process?.classes;

    return formData?.selectionMode === mode && classes && classes !== EnumProcessClass.ft ? 'normal' : 'none';
  },
  render(__: any, globalStatus: any) {
    const { formData } = this;
    return (
      <Quantity
        demandQuantity={formData?.demandQuantity}
        product={formData?.product?.key}
        productModel={options.productModel}
        unit="片"
        formStatus={globalStatus}
        selectedData={formData?.selectedData}
      />
    );
  },
});

// export const quantityInfo = (options): CustomFormItemConfig => ({
//   type: 'custom',
//   dataKey: 'quantityInfo',
//   colSpan: 24,
//   render() {
//     const { formData } = this;
//
//     return <QuantityText quantity={formData.quantity} waferCount={formData.waferQuantity} />;
//   },
//   renderType() {
//     const { formData } = this;
//     return getProductCanSmartSelection(formData?.fetchProduct) ? 'normal' : 'none';
//   }
// });

export const expectedDeliveryDate: DateFormItemConfig = {
  label: '期望交货日期',
  type: 'date',
  dataKey: 'expectedDeliveryDate',
  props: {
    disabledDate: current => {
      // antd升级至^3.12.3时，current类型定义显示该值可能为undefined
      if (!current) return true;

      return current < moment().subtract(1, 'day');
    }
  }
};

export const outSourcedOrderSerial: InputFormItemConfig = {
  label: '私有订单编号',
  type: 'input',
  dataKey: 'outSourcedOrderSerial',
};

export const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
};

export const selectedTableData = (classes?: EnumProcessClass): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'selectedTableData',
  colSpan: 24,
  render() {
    const { form } = this;
    const dataSource = this.formData?.selectedData;

    return (
      <SelectedTableData
        classes={classes}
        dataSource={dataSource}
        setFieldsValue={form.setFieldsValue}
      />
    );
  }
});

// 是否可更改
export const allowReplace: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'allowReplace',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择',
  colClassName: 'no-label-form-item',
  render() {
    return (
      <AllowReplace
        formStatus={this.formStatus}
        text="以上物料是否允许临时更换"
      />
    );
  }
};
