import React, { FC } from 'react';
import { FormCreator } from '@chipcoo/hanayo';
import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  formConfig: FormConfig;
  detail: Obj;
  productId: string;
  getFormCtrl: (ctrl: any) => void;
}

const ProductForm: FC<Props> = props => {
  const { formConfig, detail, productId, getFormCtrl } = props;

  return (
    <FormCreator
      formConfig={formConfig}
      formWrapper={false}
      formData={detail?.[productId]}
      getFormController={controller => getFormCtrl(controller)}
    />
  );
};

export default ProductForm;
