import * as React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { Icon } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

import store from 'src/store';

import UniverseCommentWrap from 'src/containers/UniverseCommentWrap';

interface Props {
  modalName: string;
  detailId: string;
  changeCommentVisible: (bool: boolean) => void;
}

interface State {
  commentVisible: boolean;
}

class RightComment extends React.PureComponent<Props, State> {

  state = { commentVisible: false };

  changeCommentVisible = () => {
    const { commentVisible } = this.state;
    this.setState({ commentVisible: !commentVisible });

    this.props.changeCommentVisible(!commentVisible);
  }

  triggerResize = () => {
    const { modalName } = this.props;
    setTimeout(() => {
      FlexModalManager.triggerResize(modalName);
    }, 300);
  }

  render () {
    const { detailId } = this.props;
    const { commentVisible } = this.state;
    const resource = {
      role: 'comment',
      id: detailId,
      name: 'product'
    };
    const rootState = store.getState();
    const userId = get(rootState, 'session.profile._id') || '';
    const className = classnames('icon-position', commentVisible ? '' : 'icon-position-left');
    const wrapClassName = classnames('comment-toggle-btn', commentVisible ? 'comment-visible' : 'comment-invisible');

    return (
      <>
        <div className={wrapClassName} onClick={this.changeCommentVisible} >
          <Icon
            type={commentVisible ? 'right' : 'left'}
            className={className}
          />
        </div>
        <div>
          <CSSTransition
            in={commentVisible}
            appear={commentVisible}
            timeout={300}
            onEnter={this.triggerResize}
            onExited={this.triggerResize}
            classNames="universe-comment"
          >
            <UniverseCommentWrap
              userId={userId}
              resource={resource}
              uploaderOptions={{}}
            />
          </CSSTransition>
        </div>
      </>
    );
  }
}

export default RightComment;
