/**
 * 消息列表的内容
 */
import * as React from 'react';
import { Spin } from 'antd';
import { isBoolean, isEqual, remove } from 'lodash';
import { bind } from 'lodash-decorators';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { sleep, getUrlId } from '@chipcoo/fe-utils';

import { RootState, RootDispatch } from 'src/store';
import styles from './index.module.less';

import OperateBar from './OperateBar';
import ListContent from './ListContent';
import { compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { Filter, NotifyFetchParams } from 'src/models/notification/interface';

interface OwnProps {}
interface StateProps {
  fetched: boolean;
  notifyIds: string[];
  notifyCount: number;
  notifyFilter: Filter;
}
interface DispatchProps {
  fetchNotify: (params?: NotifyFetchParams) => void;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  focusNotifyId: string | undefined;
  delBatchLoading: boolean;
  ctx: InitialMsgListCtx;
  listLoading: boolean;
  hasMore: boolean;
}

export type InitialMsgListCtx = {
  selected: string[];
  allSelected: boolean;
  indeterminate: boolean;
  onSelected: ((e: any, notifyId: string) => void) | undefined;
};
const initialCheckAll = {
  selected: [],
  allSelected: false,
  indeterminate: false,
  onSelected: void 0,
};

export const MsgListCtx = React.createContext<InitialMsgListCtx>(initialCheckAll);

function computeCtx(notifyIds: string[], selected: string[]) {
  const allSelected = !!selected.length && isEqual(notifyIds, selected);
  const indeterminate = !!selected.length && !allSelected;

  return {
    allSelected,
    indeterminate,
  };
}

class MsgList extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};

    return nextState;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      focusNotifyId: void 0,
      delBatchLoading: false,
      listLoading: false,
      hasMore: true,
      ctx: {
        ...initialCheckAll,
        onSelected: this.handleSelectNotify
      }
    };
  }

  componentDidMount() {
    // 检查是否路径上带着一个点开的消息，如果是，对应的消息要选中
    const notifyId = getUrlId(this.props.location.pathname);

    notifyId && this.setState({ focusNotifyId: notifyId });
  }

  componentDidUpdate(prevProps: P) {
    if (!isEqual(prevProps.notifyIds, this.props.notifyIds)) {
      const { selected } = this.state.ctx;

      this.setState({
        ctx: {
          ...this.state.ctx,
          ...computeCtx(this.props.notifyIds, selected),
        }
      });
    }
  }

  // 从服务器获取消息的数据
  loadNotifyData = async (payload: NotifyFetchParams = {}) => {
    this.setState({ listLoading: true });
    try {
      const { fetchNotify, notifyFilter } = this.props;

      const params = { ...notifyFilter, ...payload };

      await fetchNotify(params);
      await sleep(200);
    } catch (e) {
      console.error(e);
    }
    this.setState({ listLoading: false });
  }

  // 向下开始加载数据
  loadMore = async (payload: NotifyFetchParams = {}) => {
    const { fetched } = this.props;
    const { listLoading } = this.state;

    if (listLoading) { return; }

    const { notifyIds, notifyCount } = this.props;

    // 所有的通知全部加载出来了
    if (fetched && notifyIds.length >= notifyCount) {
      console.log('wtf hasMore is to be false', notifyIds, notifyCount);
      this.setState({ hasMore: false });
      return;
    }

    this.loadNotifyData({ loadMore: true, ...payload });
  }

  // 选中某个通知，打开详情
  handleNotifySelected = (notifyId: string) => {
    if (!notifyId) { return; }

    const { history, match } = this.props;

    history.push(`${match.path}/${notifyId}`);

    this.setState({ focusNotifyId: notifyId });
  }

  handleToggleDelBatchLoading = (delBatchLoading: boolean) => {
    this.setState({ delBatchLoading });
  }

  handleResetUrl = () => {
    this.props.history.push(compilePath({ name: ROUTE_NAMES.NOTIFICATION }));
  }

  @bind
  setSelected(selected: string[]) {
    this.setState({
      ctx: {
        ...this.state.ctx,
        selected,
        ...computeCtx(this.props.notifyIds, selected),
      }
    });
  }

  // 处理选中了消息列表
  handleSelectNotify = (e, notifyId: string) => {
    const isChecked = e.target.checked;
    const { notifyIds } = this.props;
    const { ctx } = this.state;
    const { selected } = ctx;
    let newCheckedList = selected.slice();

    if (isChecked) {
      newCheckedList.push(notifyId);
    } else {
      remove(newCheckedList, id => id === notifyId);
    }

    const newCtx = {
      selected: newCheckedList,
      ...computeCtx(notifyIds, newCheckedList),
    };

    this.setState({ ctx: { ...ctx, ...newCtx } });
  }

  // 可以传入一个event或者一个false
  handleCheckAll = (e: any | false) => {
    const { notifyIds } = this.props;
    const isChecked = isBoolean(e) ? e : e.target.checked;
    const selected = isChecked ? notifyIds.slice() : [];
    const ctx = { ...this.state.ctx, indeterminate: false, allSelected: isChecked, selected };

    this.setState({ ctx });
  }

  render() {
    const { notifyIds } = this.props;
    const { focusNotifyId, delBatchLoading, ctx } = this.state;

    return (
      <MsgListCtx.Provider value={ctx}>
        <div className={`flex ${styles.msgListWrapper}`}>
          {delBatchLoading && <div className={styles.mask}><Spin /></div>}

          <OperateBar
            delBatchLoading={this.handleToggleDelBatchLoading}
            focusNotifyId={focusNotifyId}
            resetUrl={this.handleResetUrl}
            loadMore={this.loadMore}
            notifyIds={notifyIds}
            onCheckAll={this.handleCheckAll}
            checkBoxStatus={ctx}
          />

          <ListContent
            loadNotifyData={this.loadNotifyData}
            loadMore={this.loadMore}
            hasMore={this.state.hasMore}
            loading={this.state.listLoading}
            focusNotifyId={focusNotifyId}
            resetUrl={this.handleResetUrl}
            onSelected={this.handleNotifySelected}
            setSelected={this.setSelected}
          />
        </div>
      </MsgListCtx.Provider>
    );
  }
}

const mapState = ({ notification: { current, filter, fetched } }: RootState) => ({
  notifyIds: current.list.allIds,
  notifyFilter: filter,
  notifyCount: current.count,
  fetched,
});
const mapDispatch = ({ notification }: RootDispatch) => ({
  fetchNotify: notification.fetch,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(
  withRouter(MsgList)
);
