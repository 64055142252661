/**
 * 出库申请bin列表
 */
import * as React from 'react';
import _, { get } from 'lodash';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ColumnProps } from 'antd/lib/table';
import { EnumProductModel } from '@chipcoo/constant';
import { getTableColumns } from './getTableColumns';

interface IProps {
  productModel: EnumProductModel;
  value?: any;
  onChange?: (values: any[]) => void;
}

interface IState {
  modalVisible: boolean;
  dataList: any[];
  tableColumns: ColumnProps<any>[];
}

const { EnhancedTable } = CardTableV2;

class SlicesTable extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modalVisible: false,
      dataList: [],
      tableColumns: [],
    };
  }

  componentDidMount(): void {
    const { productModel } = this.props;
    this.setState({
      tableColumns: getTableColumns({
        productModel,
      }),
    });
  }

  componentDidUpdate(prevProps: IProps) {
    const { productModel } = this.props;
    if (prevProps.productModel !== productModel) {
      this.setState({ 
        tableColumns: getTableColumns({
          productModel,
        }),
      });
    }
  }

  render () {
    const { value, productModel } = this.props;
    const { tableColumns } = this.state;

    return (
      <>
        <EnhancedTable
          getTableData={() => {
            //
          }}
          total={get(value, 'length')}
          dataSource={value}
          columns={tableColumns}
          scroll={productModel === EnumProductModel.assemblyProduct ? { x: 1500 } : { x: 1950}}
          size="small"
          className="small-table-no-border"
        />
      </>
    );
  }
}

export default SlicesTable;
