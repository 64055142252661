import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface';
import { handleNtoList, handleRevNtoList, handleNtoDetail, handlePostData } from './handle';
import { TableModelCommonEffects } from 'src/models/type';
import { mpwApi } from 'src/services/net';

const initialState: State = {
  list: [],
  revList: {},
  count: 0,
  detail: {}
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload?.data;
        draft.count = payload?.count;
      });
    },
    setRevList(state: State, payload: any) {
      return produce(state, draft => {
        const { _id, data } = payload;

        draft.revList = {
          ...state.revList,
          [_id]: data,
        };
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList (params: any) {
      const { data } = await mpwApi.listNto(params);
      const handleList = handleNtoList(data?.data || []);

      this.setList({
        data: handleList,
        count: data?.count
      });
    },
    async getRevNtoListById(_id: string) {
      const { data } = await mpwApi.listRevNtoListById(_id);
      const handleList = handleRevNtoList(data || []);

      this.setRevList({ _id, data: handleList });
    },
    async getTableItemDetail(id: string) {
      const { data } = await mpwApi.getNtoById(id);
      const handleData = handleNtoDetail(data);

      this.setDetail(handleData);
    },
    async postCreate(data: any) {
      const handleData = handlePostData(data);

      await mpwApi.createNto(handleData);
    },
    async patchModify(data: any) {
      await mpwApi.patchNtoStatus(data);
    },
    async enableNto(_id: string) {
      await mpwApi.enableNto(_id);
    },
    async disableNto(_id: string) {
      await mpwApi.disableNto(_id);
    }

  })
});
