/**
 * 通过账号进行添加
 */
import * as React from 'react';
import { Form, Icon, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ValidationRule } from 'antd/lib/form/Form';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';

import style from './index.module.less';

export interface P extends FormComponentProps {
  submitAccount: (accountList: string[]) => void;
  // 举例：添加好友时需要过滤掉自己，因此这里给一个额外的添加校验规则的接口
  extraAccountValidationRule?: ValidationRule[];
  registerSubmitHandler?: BasicModalChildrenProps['registerSubmitHandler'];
}
interface S {
  accountList: string[];
}

const FormItem = Form.Item;
const prefixIcon = <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />;
const accountReg = /(^1[0-9]{10}$)|(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/;

class AddByAccount extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      accountList: []
    };

    const { registerSubmitHandler } = props;
    registerSubmitHandler && registerSubmitHandler(this.handleSubmit);
  }

  // 检查输入的账号是否正确
  checkAccountHasErr = () => {
    const { getFieldError } = this.props.form;

    return Boolean(getFieldError('account'));
  }

  handleSubmit = async () => {
    const accountList = Array.from(this.state.accountList);
    const { form, submitAccount } = this.props;
    const { getFieldValue, setFields } = form;
    const accountVal = getFieldValue('account');

    // 如果输入的账号格式不对，返回false
    if (this.checkAccountHasErr()) return false;

    // 如果没有输入过账号，那么给个报错
    if (!accountList.length && !accountVal) {
      setFields({
        account: { value: accountVal, errors: [new Error('请填写账号')] }
      });

      return false;
    }

    // 如果有输入值且输入值正确，那么添加到上传列表里
    if (accountVal) accountList.unshift(accountVal);

    await submitAccount(accountList);

    return true;
  }

  handleConfirmAccount = (e) => {
    e.stopPropagation();

    // 如果输入的账号格式不对，返回false
    if (this.checkAccountHasErr()) { return; }

    const account = e.target.value;
    const { accountList } = this.state;

    if (!account || !account.trim()) return;

    if (accountList.some(item => item === account)) {
      message.warn('请勿重复添加');
      return;
    }

    accountList.unshift(account);

    this.setState({ accountList: accountList });
    this.props.form.resetFields();
  }

  handleDeleteAccount = (e, account: string) => {
    e.stopPropagation();

    const { accountList } = this.state;

    this.setState({ accountList: accountList.filter(item => item !== account) });
  }

  render() {
    const { accountList } = this.state;
    const { form, extraAccountValidationRule } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form className={style.wrapper}>
        <FormItem>
          {getFieldDecorator('account', {
            rules: [
              { pattern: accountReg, message: '请输入正确的邮箱或手机号' },
              ...(extraAccountValidationRule || [])
            ]
          })(
            <Input
              prefix={prefixIcon}
              autoComplete="off"
              autoFocus={true}
              onPressEnter={this.handleConfirmAccount}
              placeholder="输入账号后按回车可添加多个"
            />
          )}
        </FormItem>
        <ul className={style.accountList}>
          {accountList.map(account => (
            <li key={account} className="item">
              <div className="text">
                {account}
              </div>
              <Icon type="close" onClick={ev => this.handleDeleteAccount(ev, account)} />
            </li>
          ))}
        </ul>
      </Form>
    );
  }
}

export default Form.create<P>()(AddByAccount);
