import { get } from 'lodash';

import { trimString } from 'src/utils';
import { IFTP } from './interface';

export const handleFtpList = (payload: IFTP[] = []) => {
  return payload?.map(it => {
    const { paths } = it;
    const factoryName = paths.map(item => get(item?.factory, 'shortName')).join(',');
    const newPaths = paths.map(item => {
      return {
        key: Math.random(),
        path: item?.path,
        factory: {
          key: get(item?.factory, '_id'),
          label: get(item?.factory, 'name'),
        },
        fileType: { 
          key: item?.fileType,
          label: item?.fileType?.toUpperCase()
        }
      };
    });
    return {
      ...it,
      paths: newPaths,
      factoryName,
    };
  });
};

export const handleFtp = (payload: IFTP) => {
  const formData = trimString(payload);
  const { paths } = formData;
  const newPaths = paths.map(it => ({
    path: it?.path,
    factory: get(it?.factory, 'key'),
    fileType: get(it?.fileType, 'key')
  }));

  return {
    ...formData,
    paths: newPaths,
  };
};
