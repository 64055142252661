import React, { FC } from 'react';
import { Modal, Upload, Button, message } from 'antd';
import _ from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';

import urlConfig from 'src/config/url';
import style from './index.module.less';
import { getOrgIdByUrl } from 'src/utils';

interface IProps {
  importModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  openPreviewModal: (val: any) => void;
}

const ImportModal: FC<IProps> = props => {
  const { importModalVisible, handleOk, handleCancel, openPreviewModal } = props;

  function handleChange(info: any) {
    const status = _.get(info, 'file.status');
    let messageText;

    if (status === 'done') {

      openPreviewModal(info);
    } else if (status === 'error') {
      messageText = _.get(info, 'file.response.message');

      if (messageText) {
        //
      } else {
        messageText = `文件${info.file.name} 上传失败,请检查输入的数据是否有错误!`;
      }

      message.error(messageText);
    }
  }

  const orgId = getOrgIdByUrl();

  const getUploadProps = (productModel: EnumProductModel) => {
    const uploadProps = {
      action: `/api/product/stock-in-apply/import?orgId=${orgId}`,
      onChange: (info) => handleChange(info),
      accept: '.xlsx',
      showUploadList: false,
      data: { productModel }
    };

    return uploadProps;
  };

  const tableRowConfig: any[] = [
    {
      title: 'SPW芯片',
      uploadProps: getUploadProps(EnumProductModel.spwDie),
      href: `${urlConfig.staticUrl}/template/product/die-stock-in-apply.xlsx`,
    },
    // {
    //   title: '已测芯片',
    //   uploadProps: getUploadProps(EnumProductModel.cpDie),
    //   href: `${urlConfig.staticUrl}/template/product/cpdie-stock-in-apply.xlsx`
    // }
  ];

  return (
    <Modal
      width={650}
      title="来料批量导入"
      visible={importModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className={style.importModal}
    >
      <table className="import-table">
        <tbody>
          <tr>
            <th>产品类型</th>
            <th>来料文档上传</th>
            <th>模板下载</th>
          </tr>
          {
            tableRowConfig.map(item => (
              <tr key={item.title}>
                <td>{item.title}</td>
                <td>
                  <Upload {...item.uploadProps}>
                    <Button>点击上传</Button>
                  </Upload>
                </td>
                <td>
                  <a href={item.href}>{item.title + '模板'}</a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </Modal>
  );
};

export default ImportModal;
