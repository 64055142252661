/**
 * @author chenchongjian95@163.com
 * @date 2019/1/15 14:45
 */

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';

type ARC = AxiosRequestConfig;

export default function(api: AxiosInstance) {
  return {
    /**
     * 成员管理
     */
    getMembers(config?: ARC) {
      return api.get('ops/member', config);
    },
    addMember(data: Member[], config?: ARC) {
      return api.post('ops/member', { members: data }, {
        ...config,
        handleSpecialError: {
          403: () => {message.error('添加权限不满足'); },
          401: () => { message.error('当前组织未添加该成员'); },
          409: () => { message.error('该成员已添加'); },
        }
      });
    },
    setMemberRole(data: NormalMember, config?: ARC) {
      return api.patch('ops/member', data, config);
    },
    removeMember(memberId: string | number, config?: ARC) {
      return api.delete(`ops/member/${memberId}`, {
        ...config,
        handleSpecialError: {
          403: () => {message.error('删除权限不满足'); }
        }
      });
    },
  };
}
