/**
 * 出货记录列表
 */
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import classNames from 'classnames';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';

import styles from './index.module.less';
import { connectDecorator, RootState, Actions } from 'src/store';

import CardExtra from './components/CardExtra';
import { getTableColumns } from './components/getTableColumns';
import { filterFormConfig } from './components/filterFormConfig';

const { EnhancedTable, QueryTermTags } = CardTableV2;

interface OwnProps {}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: any) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {
  tableLoading: boolean;
}

@connectDecorator(
  ({ shipment: { list, count, detail } }: RootState) => ({ list, count, detail }),
  ({ shipment: { getTableList }}: Actions) => ({ getTableList })
)
class InventoryTable extends PureComponent<P, S> {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];

  constructor(props: P) {
    super(props);

    this.state = {
      tableLoading: false,
    };

    this.tableColumns = getTableColumns({});
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          case 'warehouse':
            prev[key] = value ? value.key : undefined;
            break;
          default:
            prev[key] = value;
            break;
        }

        return prev;
      },
      {} as any
    );

    const queryParams: any = {
      ..._formData,
      ...extraParams,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  getTerms = (query: Obj) => {
    return Object.entries(query).reduce((prev, [key, val]) => {
      if (_.isEmpty(val)) {
        return prev;
      }

      let value = val;

      const queryObj = _.find(filterFormConfig, ['dataKey', key]) as any;
      const { label } = queryObj;

      switch (key) {
        case 'warehouse':
          prev[label] = value.label;

          break;
        default:
          prev[label] = value;
          break;
      }

      return prev;
    }, {} as any);
  }

  reloadTable() {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  render() {
    const { list, count } = this.props;
    const {
      tableLoading,
    } = this.state;

    return (
      <div className={classNames(styles.stockList, 'wrap-content')}>
        <CardTableV2
          card={{
            title: '出货记录列表',
            extra: (
              <CardExtra
                openQuery={this.openQuery}
              />
            )
          }}
          queryFormConfig={filterFormConfig}
          getCardTableController={this.getCardTableController}
        >
          <QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />

          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            scroll={{ x: 2200 }}
            clickMention={true}
            size="small"
          />
        </CardTableV2>
      </div>
    );
  }
}

export default InventoryTable;
// tslint:disable:max-file-line-count
