import * as React from 'react';
import { Button } from 'antd';
import _ from 'lodash';
import { FormCreator } from '@chipcoo/hanayo';
import { EnhancedWrappedFormUtils, FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { noop } from '@chipcoo/fe-utils';

import { getFormConfig } from './formConfig';
import styles from './index.module.less';
import { handleStockList } from 'src/models/operation/inventoryManage/stockIn/handle';

interface Props {
  status: FormStatus;
  value?: any;
  onChange?: (value: any) => void;
}

interface State {
  visible: boolean;
  fullWindow: boolean;
  selectors: any;
}

class MaterialSelect extends React.PureComponent<Props, State> {
  private formController: EnhancedWrappedFormUtils | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      fullWindow: true,
      selectors: {
        selectedRowKeys: props.value?.map(it => it.id).filter(it => it),
        selectedRows: handleStockList(props.value?.map(it => it?.resource).filter(it => it))
      }
    };
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    return new Promise((res, rej) => {
      this.formController?.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            const { onChange } = this.props;
            const selectors = values?.selectors?.selectedRows;

            if (!_.isEmpty(selectors)) {
              onChange && onChange(selectors);

              this.onCloseModal();

              this.setState({
                selectors: values?.selectors
              });
            }

            res(true);
          } catch (e) {
            console.error(e);
            rej(e);
          }
        } else {
          rej(err);
        }
      });
    });
  }

  onCloseModal = () => this.setState({ visible: false });
  onRequestNormalWindow = () => this.setState({ fullWindow: false });
  onRequestFullWindow = () => this.setState({ fullWindow: true });

  render () {
    const { status } = this.props;
    const { visible, fullWindow, selectors } = this.state;
    const formData = { selectors, status, selectTable: selectors?.selectedRows };
    const count = _.get(selectors, 'selectedRowKeys.length');

    return (
      <>
        {
          _.isEmpty(selectors)
            ? (
              <Button
                block={true}
                onClick={() => this.setState({ visible: true })}
              >
                来料选择器
              </Button>
            )
            : (
              <a onClick={() => this.setState({ visible: true })}>
                共关联{count}条来料
              </a>
            )
        }

        <FlexModal
          title="来料选择器"
          visible={visible}
          fullWindow={fullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
          onCancel={this.onCloseModal}
          onOk={this.handleSubmit}
          className={styles.selectorModal}
          footer={status === 'preview' && null}
        >
          <FormCreator
            formWrapper={false}
            formConfig={getFormConfig()}
            formData={formData}
            getFormController={controller => this.formController = controller}
          />
        </FlexModal>
      </>
    );
  }
}

export default MaterialSelect;
