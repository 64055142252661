// 因canSmartSelection在各产品详情里才会出现，后端不好处理，于是在前端选择产品后发一次详情请求来获取产品详情
import React, { ComponentType } from 'react';
import { isEqual } from 'lodash';

import { productApi } from 'src/services/net';

interface OwnProps {
  productId: string;
  onChange?: (val: any) => void;
}

interface StateProps {
}

interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

class FetchProduct extends React.PureComponent<Props> {

  async componentDidMount() {
    this.getProductInfo();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { productId } = this.props;

    if (!isEqual(productId, prevProps.productId)) {
      this.getProductInfo();
    }
  }

  getProductInfo = async () => {
    const { productId, onChange } = this.props;

    if (productId) {
      try {
        const { data } = await productApi.getProductById(productId);

        onChange && onChange(data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  render () {
    return (
      <div>2222</div>
    );
  }
}

export default FetchProduct as ComponentType<OwnProps>;
