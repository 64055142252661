import { createSelector } from 'reselect';
import { CommentStore } from './interface';

export type Key = 'comments' | 'attachments';

export const createUCommentSelector = (KEY: Key) => {
  return createSelector(
    (state: CommentStore, key: string) => state[key],
    (data) => {
      if (!data) {
        return {
          hasMore: true,
          [KEY]: [],
          count: 0,
        };
      }
      const { [KEY]: { allIds, byId, ...extra },  } = data;
      return {
        ...extra,
        [KEY]: allIds.map(id => byId[id]), 
      };    
    }
  );
};
