/**
 * 全局消息推送
 */
import React from 'react';
import { connect } from 'react-redux';

import { RootState, RootDispatch } from 'src/store';
import { pushSocket, syncSocket, registerSocket } from 'src/socket';
import { toInt } from 'src/utils/user';
import { IOrgDetail } from 'src/models/org/interface';
import { Workspace } from 'src/models/workspace/interface';
import { handleWorkspaceMember, handleWorkspace } from './handler/workspace';
// import { handleOrganization } from './handler/org';
import { SubscribeResult } from 'src/services/socket';
import { handleRoomsSync } from './handler/rooms';

interface OwnProps {}
interface StateProps {
  orgId: string;
  curUserId: number;
  orgById: NormalizedData<IOrgDetail>['byId'];
  workspaceById: NormalizedData<Workspace>['byId'];
}

interface DispatchProps {
  receiveNewMessage: (data: any) => any;
  receiveNewNotify: (data: any) => any;
  onRemoveFriend: (data: any) => any;
  onCreateFriend: (data: any) => any;
  // modifyFriendProfile: (data: any) => any;
  syncOrg: (data: any) => any;
  dispatch: RootDispatch;
}
type Props = OwnProps & StateProps & DispatchProps;
interface S {}

export type WebPushProps = Props;

class GlobalWebPush extends React.PureComponent<Props, S> {
  private roomSubscription: SubscribeResult;
  private friendSubscription: SubscribeResult;
  private userSubscription: SubscribeResult;
  onSubscribe = false;

  constructor(props: Props) {
    super(props);

    registerSocket();
  }

  componentDidMount() {
    this.trySubscribe();

    const {
      curUserId,
      receiveNewMessage,
      receiveNewNotify,
      // onRemoveFriend,
      // onCreateFriend,
      // modifyFriendProfile,
      dispatch,
    } = this.props;

    // 新的聊天消息推送
    pushSocket.on('im', (data) => {
      /**
       * TODO 考虑到同一个聊天界面开多个标签页，因此每次发送新消息，socket也会给自己推送一份
       * 但本地Post一条新消息，await还未将本地数据更新掉，socket就响应了
       * 这时候检查数据就会发现推送过来的没有重复，造成发一条消息显示两条一模一样消息的问题
       * 这里先用50ms讲究下，后面改
       */
      // 检查这条消息是否是自己发的
      const { _id } = data.payload.message.sender;
      const isSelf = _id === curUserId;

      // 显示将 curUserId 转成字符串
      setTimeout(() => receiveNewMessage({ ...data, isSelf, curUserId: curUserId.toString() }), 50);
    });

    // 消息推送
    pushSocket.on('notify', data => {
      setTimeout(() => receiveNewNotify(data), 50);
    });

    pushSocket.on('comment', (data) => {
      dispatch.universeComment.onReceiveComment(data);
    });

    // 微信绑定解绑
    pushSocket.on('wechat.status.update', data => {
      // 解绑微信
      if (data.message === 'unsubscribe') {
        dispatch.session.unsubscribe();
      }

      // 绑定微信
      if (data.message === 'subscribe') {
        dispatch.session.setSubscribe();
      }
    });

    // 删除消息是sync推送过来的
    // syncSocket.on('im', (data) => {
    //   setTimeout(() => receiveDeleteMessage(data), 50);
    // });

    // // 添加好友、删除好友
    // syncSocket.on('friend:create', onCreateFriend);
    // syncSocket.on('friend:remove', onRemoveFriend);

    // // 用户上线/下线
    // syncSocket.on('user:online', dispatch.user.setUserOnline);
    // syncSocket.on('user:offline', dispatch.user.setUserOffline);

    // 用户资料推送
    // syncSocket.on('user:profile:change', modifyFriendProfile);

    // // 加入、离开、解散组织推送
    // syncSocket.on('organization', (data) => {
    //   handleOrganization(this.props, data);
    // });

    // 进入、离开协作区
    syncSocket.on('workspace:member', (data) => {
      handleWorkspaceMember(this.props, data);
    });

    // 协作区创建、转交、解散、归档
    syncSocket.on('workspace:info', data => {
      handleWorkspace(this.props, data);
    });

    // syncSocket.on('deleteComment', (data) => {
    //   dispatch.universeComment.syncRemoveComment(data.payload.comment);
    // });
  }

  trySubscribe() {
    if (
      this.props.curUserId &&
      (!this.userSubscription || !this.roomSubscription || !this.roomSubscription)
      && !this.onSubscribe) {
      this.subscribe();
    }
  }

  async subscribe() {
    this.onSubscribe = true;
    const { curUserId, dispatch, onCreateFriend, onRemoveFriend } = this.props;
    const id = curUserId + '';
    try {
      if (!this.userSubscription) {
        this.userSubscription = await syncSocket.subscribe(
          { name: 'user', id, role: 'users' },
          (data) => {
            //
            const { payload, event } = data;
            switch (event) {
              case 'online':
                dispatch.user.setUserOnline(payload.userId);
                break;
              case 'offline':
                dispatch.user.setUserOffline(payload.userId);
                break;
              // case 'profile':
              //   dispatch.user.modifyUserProfile(data);
              //   break;
              default:
            }
          },
        );
      }

      if (!this.friendSubscription) {
        this.friendSubscription = await syncSocket.subscribe(
          { name: 'user', id, role: 'friends' },
          (data) => {
            //
            const { event } = data;
            switch (event) {
              case 'create':
                onCreateFriend(data);
                break;
              case 'remove':
                onRemoveFriend(data);
                break;
              case 'profile':
                dispatch.user.modifyUserProfile(data);
                break;
              default:
            }
          },
        );
      }

      if (!this.roomSubscription) {
        this.roomSubscription = await syncSocket.subscribe(
          { name: 'user', id, role: 'rooms' },
          handleRoomsSync,
        );
      }
    } catch (e) {
      console.error(e);
    }

    this.onSubscribe = false;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.curUserId !== prevProps.curUserId) {
      this.trySubscribe();
    }
  }

  render() { return null; }
}

const mapState = ({ session, org, workspace }: RootState) => ({
  curUserId: toInt(session.profile!._id),
  workspaceById: workspace.list.byId,
  orgById: org.orgList.byId,
  orgId: org.orgId,
});

const mapDispatch = (dispatch: RootDispatch) => {
  const { chatRoomList, notification, friend, org } = dispatch;
  return {
    receiveNewMessage: chatRoomList.receiveNewMessage,
    receiveNewNotify: notification.receiveNewNotify,
    onRemoveFriend: friend.onRemoveFriend,
    onCreateFriend: friend.onCreateFriend,
    // modifyFriendProfile: friend.modifyFriendProfile,
    syncOrg: org.syncOrg,
    dispatch,
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapState, mapDispatch
)(GlobalWebPush);
