/**
 * Created by marvin on 18/8/17.
 */

import * as React from 'react';
import classnames from 'classnames';

import styles from './index.module.less';

import OrgBasicInfo from './OrgBasicInfo';
import InvoiceDetail from './InvoiceDetail';
import FunctionContact from './FunctionContact';

interface Props {
  orgId: string;
}

class OrgInfo extends React.PureComponent<Props> {

  render () {
    const { orgId } = this.props;

    return (
      <div className={classnames('thin-scroll', 'y-scroll', styles.orgInfo)}>
        <OrgBasicInfo orgId={orgId} />
        <InvoiceDetail orgId={orgId} />
        <FunctionContact orgId={orgId} />
      </div>
    );
  }
}

export default OrgInfo;
