import * as React from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router-dom';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';

import { connectDecorator, RootState, Actions } from 'src/store';
import styles from './index.module.less';
import { getTableColumns } from './components/getTableColumns';
import { getFormConfig } from './formConfig';

const { EnhancedTable } = CardTableV2;

interface OwnProps extends RouteComponentProps {
}
interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (orderId: string) => void;
  cancelPreOrder: (orderId: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  categoryLoading: boolean;
  tableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  modalFullWindow: boolean;
}

@connectDecorator(
  ({ mpwPreOrder: { list, count, detail } }: RootState) => ({
    list, count, detail
  }),
  ({ mpwPreOrder: { getTableList, getTableItemDetail, cancelPreOrder } }: Actions) => ({
    getTableList, getTableItemDetail, cancelPreOrder
  })
)
class MPWPreOrder extends React.PureComponent<Props, State> {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];
  modalName = uniqueId('MpwPreOrder_');

  constructor(props: Props) {
    super(props);

    this.state = {
      categoryLoading: true,
      tableLoading: false,
      detailModalVisible: false,
      detailId: '',
      modalFullWindow: true,
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
      cancelOrder: this.cancelOrder
    });
  }

  async componentDidMount() {
    // 通过邮件链接打开预订单详情
    const { match }: any = this.props;
    const id = match?.params?.preOrderId;

    if (id) {
      await this.openDetailModal(id);
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;

    const queryParams = {
      ...formData,
      ...extraParams,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  cancelOrder = async (orderId: string) => {
    await this.props.cancelPreOrder(orderId);

    this.reloadTable();
  }

  openDetailModal = async (detailId: string) => {
    const { getTableItemDetail } = this.props;

    try {
      await getTableItemDetail(detailId);

      this.setState({ detailModalVisible: true, detailId });
    } catch (e) {
      console.error(e);
    }
  }

  closeDetailModal = () => this.setState({ detailModalVisible: false });

  closeModalWithReload = () => {
    this.closeDetailModal();

    if (this.cardTableController) {
      this.cardTableController.reloadTable();
    }

    this.setState({ tableLoading: false });
  }

  reloadTable = () => {
    this.closeModalWithReload();
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  render () {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      detailModalVisible,
      // detailId,
      modalFullWindow,
    } = this.state;

    return (
      <div className={classNames('wrap-content', styles.preOrderList)}>
        <CardTableV2
          card={{
            title: '预订单审核列表',
          }}
          getCardTableController={this.getCardTableController}
        >
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            clickMention={true}
            size="small"
          />
        </CardTableV2>

        <FlexModal
          modalName={this.modalName}
          title="查看预订单"
          visible={detailModalVisible}
          onCancel={this.closeDetailModal}
          // onOk={this.handleOk}
          // className={styles.modal}
          fullWindow={modalFullWindow}
          className="flow-in-flex-modal"
          onRequestFullWindow={this.requestFullWindow}
          onRequestNormalWindow={this.requestNormalWindow}
          footer={null}
          width={1440}
          resize={{
            minWidth: 1366,
            minHeight: 768
          }}
        >
          <FormCreator
            formConfig={getFormConfig(this.modalName, detail?.factoryName)}
            formWrapper={false}
            formData={detail}
            globalStatus="preview"
          />
        </FlexModal>
      </div>
    );
  }
}

export default MPWPreOrder;
