/**
 * 好友侧边栏的头部
 */
import * as React from 'react';
import { Dropdown, Icon, Menu } from 'antd';

interface P {
  addFriend: (e?: any) => void;
  openGroupModal: (e?: any) => void;
  openRelationModal: (e?: any) => any;
}
interface S {
  visible: boolean;
}

class SiderHeader extends React.PureComponent<P, S> {
  private addMenu: React.ReactNode;

  constructor(props: P) {
    super(props);

    this.state = {
      visible: false
    };

    this.addMenu = (
      <Menu selectable={false}>
        <Menu.Item><a onClick={this.addFriend}>通过账号</a></Menu.Item>
        <Menu.Item><a onClick={this.openRelationModal}>人脉邀请</a></Menu.Item>
        <Menu.Item><a onClick={this.openGroupModal}>新建分组</a></Menu.Item>
      </Menu>
    );
  }

  addFriend = (e) => {
    this.props.addFriend(e);
    this.handleVisibleChange(false);
  }

  openGroupModal = (e) => {
    this.props.openGroupModal(e);
    this.handleVisibleChange(false);
  }

  openRelationModal = (e) => {
    this.props.openRelationModal(e);
    this.handleVisibleChange(false);
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible: visible });
  }

  render() {
    const { visible } = this.state;

    return (
      <Dropdown
        overlay={this.addMenu}
        trigger={['click']}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Icon type="plus" />
      </Dropdown>
    );
  }
}

export default SiderHeader;
