/**
 * 消息附件
 */
import * as React from 'react';
import { Icon } from 'antd';
import { bind } from 'lodash-decorators';
import classnames from 'classnames';
import { FilePreview } from '@chipcoo/hanayo';
import { isFileSupportPreview, IMG, parseURL } from '@chipcoo/fe-utils';

import styles from './style.module.less';

import { AttachmentIcon } from 'src/components';
import { LOCAL_PREFIX } from 'src/config/const';
import { isSupportWebp } from 'src/utils';
import { attachmentApi } from 'src/services/net';
import { DownloadAttachmentParams } from 'src/services/net/attachment';

interface P {
  attachments: ImAttachment[];
  title?: string;
  className?: string;
  imgPreview?: boolean;
  maxImageWidth?: number;
}
interface S {
  loading: boolean;
}

const imgFormat = isSupportWebp ? '/format/webp' : '';

export function getAttachment(attachments: ImAttachment[] = []) {
  const attachment = attachments[attachments.length - 1];

  return attachment || {};
}

export class MessageAttachment extends React.PureComponent<P, S> {
  static defaultProps = {
    imgPreview: true,
  };

  state = {
    loading: true,
  };

  private get attachment() {
    return getAttachment(this.props.attachments);
  }

  private get fileObj(): Attachment {
    const { versions } = this.attachment;

    return (versions && versions[0]) || null;
  }

  private get attachmentId() {
    return this.attachment._id;
  }

  private get isImg() {
    const { ext } = this.fileObj!;

    return IMG.test(ext || '');
  }

  private get isLocal() {
    return this.attachmentId && this.attachmentId.includes(LOCAL_PREFIX);
  }

  @bind
  handleImageLoad() {
    this.setState({ loading: false });
  }

  handleViewFile = e => {
    const { filename, ext, preview, blob } = this.fileObj!;
    const { isLocal, isImg } = this;
    if (isLocal) {
      e && e.preventDefault();
      if (!isImg) {
        FilePreview({
          src: (blob || preview)!,
          filename,
          ext
        });
      }
    }

    if (isFileSupportPreview(ext!)) {
      e && e.preventDefault();
      const { attachmentId } = this;
      let qs: DownloadAttachmentParams = {
        attachmentId,
      };

      let previewUrl = attachmentApi.download(qs);
      let downloadUrl = previewUrl;

      if (isImg) {
        previewUrl = parseURL(previewUrl, { thumb: `${imgFormat}/quality/85` });
      }

      FilePreview({
        src: previewUrl,
        downloadSrc: downloadUrl,
        filename,
      });
    }
  }

  renderImg() {
    const { isLocal } = this;
    const { maxImageWidth } = this.props;
    const { loading } = this.state;
    const { width = maxImageWidth || 300, height = maxImageWidth || 300, size } = this.fileObj;
    const imgStyle: any = { objectFit: 'cover' };

    const actualWidth = Math.min(maxImageWidth || 300, width);
    const actualHeight = (height * actualWidth) / width;
    imgStyle.width = actualWidth + 'px';
    imgStyle.height = actualHeight + 'px';

    const qs: DownloadAttachmentParams = {
      attachmentId: this.attachmentId,
    };

    if (size > 1024 * 80 || width > 600) {
      qs.params = { thumb: `${imgFormat}/fw/300` };
    }

    let actualUrl = attachmentApi.download(qs);

    return (
      <div style={imgStyle} className={styles.img}>
        <div
          style={imgStyle}
          className={
            classnames(
              `${styles.imgLoading} flex align-center`,
              loading ? '' : styles.loaded)
          }
        >
          <Icon
            className="cell"
            type="picture"
            style={{ fontSize: '64px', color: 'gray' }}
          />
        </div>
        {!isLocal && (
          <img
            className={classnames(styles.imgPreview, loading ? '' : 'fadeIn delay150')}
            onLoad={this.handleImageLoad}
            style={imgStyle}
            src={actualUrl}
          />
        )}
      </div>
    );
  }

  render() {
    const { fileObj, attachmentId } = this;
    const { className, imgPreview, title } = this.props;
    if (!fileObj) {
      return null;
    }

    const { filename, ext, mimetype, blob } = fileObj!;
    const { isLocal, isImg } = this;
    const actualUrl = isLocal
      ? blob
      : attachmentApi.download({ attachmentId, download: true });

    return (
      <a
        href={actualUrl}
        title={title}
        download={filename}
        className={classnames(className, styles.messageAttachmentWrapper)}
        onClick={this.handleViewFile}
      >
        {(isImg && imgPreview) ? (
          this.renderImg()
        ) : (
          <div className="flex">
            <AttachmentIcon ext={ext!} mimetype={mimetype!} />
            <div className="info cell">
              <div className="name">{filename}</div>
            </div>
          </div>
        )}
      </a>
    );
  }
}

export default MessageAttachment;
