import React, { FC, ComponentType, useState, useEffect } from 'react';
import _ from 'lodash';
import { Card, Statistic, Row, Col } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { EnumRequirementOrderStatus } from '@chipcoo/constant';

import { getCurrentRoute } from 'src/components/HoneyRouter';
import { gridsConfig } from './categoryConfig';
import { RootDispatch, connect } from 'src/store';
import style from './index.module.less';
import DemandOrderTable from '../DemandOrderTable';

const { Grid } = Card;

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  searchOrderStatus: (params: any) => any;
}

type IProps = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

interface IState {
  category: EnumRequirementOrderStatus | 'ALL';
  statusInfos: any;
}

const DemandOrder: FC<IProps> = props => {
  const { searchOrderStatus } = props;
  const { location: { pathname } } = getCurrentRoute()!;
  const productModel = pathname.split('/order/')?.[1];

  const [state, setState] = useState<IState>({
    category: 'ALL',
    statusInfos: {},
  });
  const { category, statusInfos } = state;

  const getRequirementStatus = () => {
    searchOrderStatus(productModel).then((response) => {
      setState({
        ...state,
        statusInfos: response
      });
    });
  };

  const reloadBoardInfos = () => {
    getRequirementStatus();
  };

  useEffect(() => {
    getRequirementStatus();
  }, [productModel]);

  // tslint:disable-next-line:no-shadowed-variable
  const setCategory = (category: any) => {
    setState({
      ...state,
      category
    });
  };

  const colSpan = Math.floor(24 / gridsConfig(statusInfos).length);

  return (
    <div className={classnames(style.boardStyle, 'wrap-content')}>
      <Row gutter={[16, 16]} className="row-style" type="flex" justify="space-between">
        {
          gridsConfig(statusInfos).map(grid => {
            return (
              <Col key={grid.key} span={colSpan} onClick={() => setCategory(grid.key)} className="grid-item">
                <Grid className={classnames('grid-style', category === grid.key ? 'active' : '')}>
                  <Statistic
                    title={grid.title}
                    value={grid.value}
                    suffix={'个'}
                  />
                </Grid>
              </Col>
            );
          })
        }
      </Row>
      <DemandOrderTable
        reloadBoardInfos={reloadBoardInfos}
        status={category}
      />
    </div>
  );
};

const mapState = (() => ({}));

const mapDispatch = ({
  demandOrder: { searchOrderStatus },
}: RootDispatch) => ({
  searchOrderStatus
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapState, mapDispatch
)(DemandOrder) as ComponentType<OwnProps>;
