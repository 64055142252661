import React, { PureComponent } from 'react';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { bind } from 'lodash-decorators';
import urlConfig from 'src/config/url';
import './style.less';

import Comments from './Comments';
import Attachments from './Attachments';
import { UniverseCommentCls, UCBasicProps } from './interface';
import { getResourceKey } from 'src/services/resource';

const { TabPane } = Tabs;

interface State {}

// export function createCommentPage<
//   StateProps extends UCStateProps = UCStateProps,
//   DispatchProps extends UCDispatchProps = UCDispatchProps,
// >(options: CreatorOptions<StateProps, DispatchProps>) {
interface BasicProps {
  tag?: string;
  className?: string;
}

type Props = BasicProps & UCBasicProps;

export type UniverseCommentProps = Props;

export const AdminUser: FrontUser = {
  _id: '0',
  name: '芯翼管理员',
  nickname: '芯翼管理员',
  avatarSrc: `${urlConfig.baseUrl}/favicon.png`,
};

export { getNextRelatedUserIds } from './utils';

export class UniverseComment extends PureComponent<Props, State> implements UniverseCommentCls<Props, State> {
  static defaultProps = {
    tag: 'div',
  };

  elRef = React.createRef<HTMLElement>();
  mentonProps = {
    getPopupContainer: this.getPopupContainer,
  };

  @bind
  getAutoSaveKey() {
    return getResourceKey(this.props.resource);
  }

  @bind
  getPopupContainer() {
    return this.elRef.current!;
  }

  renderTabs() {
    const {
      resource,
      userId,
      suggestionData,
      atUserId,
      userDisabled,
      getFullUser,
      hideInput,
    } = this.props;
    return (
      <Tabs defaultActiveKey="comment" className="ant-tabs flex vertical">
        <TabPane className="comment-tab" tab="评论和动态" key="comment" forceRender={true}>
          <Comments
            mentionProps={this.mentonProps}
            userId={userId}
            atUserId={atUserId}
            resource={resource}
            getAutoSaveKey={this.getAutoSaveKey}
            suggestionData={suggestionData}
            userDisabled={userDisabled}
            getFullUser={getFullUser}
            hideInput={hideInput}
          />
        </TabPane>
        <TabPane
          className="cell thin-scroll y-scroll"
          tab="附件"
          key="attachment"
          forceRender={true}
        >
          <Attachments resource={resource} />
        </TabPane>
      </Tabs>
    );
  }

  render() {
    const { tag, className } = this.props;
    const Component = tag! as any;

    return (
      <Component ref={this.elRef} className={classnames(className, 'universe-comment')} >
        {this.renderTabs()}
      </Component>
    );
  }
}
// }

export default UniverseComment;
