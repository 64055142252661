/**
 * Created by marvin on 18/8/28.
 */
import { isEmpty, omit } from 'lodash';
import { IOrgDetail } from './interface';
import { EnumContactFunction } from '@chipcoo/constant';

export const i18nMembers = {
  OWNER: '拥有者',
  ADMIN: '管理员',
  MEMBER: '普通成员',
  SUPER_ADMIN: '超级管理员',
  owner: '拥有者',
  admin: '管理员',
  member: '普通成员',
  superAdmin: '超级管理员',
};

export const i18nProductMembers = {
  ...i18nMembers,
  member: '产品经理',
  MEMBER: '产品经理',
};

export const i18nOperationMembers = {
  ...i18nMembers,
  member: '下单员',
  MEMBER: '下单员',
};

// 处理组织列表数据
export function handleOrgDetail(org: IOrgDetail) {
  const { members, member } = org;
  const role = isEmpty(members) ? member!.role : members![0].role;

  return { ...org, role };
}

// 处理组织成员数据
// export const handlerOrgMembersData = (data) => (
//   data.reduce((all, item) => { // Pick valid user only.
//     try {
//       const { user, role } = item;
//       const { avatar, phone, email } = user;

//       const _id = user._id.toString();
//       all.push({
//         ...user,
//         account: email || phone,
//         avatar,
//         role,
//         _id,
//         key: user._id,
//         userId: user._id,
//       });
//     } catch (e) {
//       //
//     }

//     return all;
//   }, [] as any)
// );

// 处理联系信息数据
export function handleFunctionContact (data: any) {
  const { functionContacts } = data;
  let newFuncContact = {
    organization: {},
    order: {},
    stockOut: {},
    finance: {},
    tapeOut: {}
  };

  functionContacts && functionContacts.forEach(item => {
    Object.entries(newFuncContact).reduce(
      (prev, [key]) => {
        if (item.function === EnumContactFunction?.[key]) {
          newFuncContact = {
            ...newFuncContact,
            [key]: omit(item, 'function')
          };
        }

        return prev;
      },
      {}
    );
  });

  return { ...data, functionContacts: newFuncContact! };
}

// 处理组织信息里的functionContacts
export const handleFunctionContactInOrg = (data: any) => {
  const { functionContacts } = data;
  let newFuncContact = {
    [EnumContactFunction.organization]: [],
    [EnumContactFunction.order]: [],
    [EnumContactFunction.stockOut]: [],
    [EnumContactFunction.finance]: [],
    [EnumContactFunction.tapeOut]: [],
  };

  functionContacts?.forEach(it => {
    const item = newFuncContact?.[it?.function];

    if (item) {
      item.push(it);
    }
  });

  return { ...data, functionContacts: newFuncContact! };
};
