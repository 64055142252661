/**
 * Created by marvin on 18/9/4.
 */

import * as React from 'react';
import { Form, Input, Button, Col } from 'antd';
import { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { RootDispatch } from 'src/store';
import style from './index.module.less';
import { profileApi } from 'src/services/net';

interface OwnProps {
  form: WrappedFormUtils;
  from: 'phone' | 'email';
  formItemLayout: any;
  captchaBtnClassName?: string;
}
interface StateProps {
}
interface DispatchProps {
  postVerifyCode: (val: GeetestValidate & { account: string; }) => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  count: number;
  emailTextVisible: boolean;
  emailHref: string;
  isMail: boolean;
}

const FormItem = Form.Item;
const mailReg = /@([a-z0-9_-])+(.([a-z0-9_-]+))+$/i;
class VerifyCode extends React.PureComponent<Props, State> {
  static defaultProps = {
    captchaBtnClassName: 'captcha-btn'
  };
  private interval;
  constructor(props: Props) {
    super(props);

    this.state = {
      count: 0,
      emailTextVisible: false,
      emailHref: '#',
      isMail: false,
    };
  }

  async componentDidMount () {
    try {
      console.log(333);
      await profileApi.getGeetest().then(resp => {
        const { captchaBtnClassName } = this.props;
        const { challenge, gt, new_captcha, success } = resp.data;

        window.initGeetest(
          { challenge, gt, new_captcha, offline: !success, product: 'popup' },
          captchaObj => {
            console.log(11);
            captchaObj.appendTo(`.${captchaBtnClassName}`);
            captchaObj.bindOn(`.${captchaBtnClassName}`);
            captchaObj.onSuccess(() => {
              const result = captchaObj.getValidate();
              console.log(22, result);
              this.forbidGetCaptchaRepeat(result);
            });
          }
        );
      });
    } catch (e) { console.log(e); }
  }

  // 清除定时器
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // 禁止短时间多次点击发送验证
  forbidGetCaptchaRepeat = (result: GeetestValidate) => {
    const { form, from } = this.props;

    const account = form.getFieldValue('account');

    if (account) {
      const isMail = account && account.match(mailReg);

      if (isMail) {
        const mail = isMail ? 'http://mail.' + isMail[0].substr(1) : '#';
        this.setState({
          // emailTextVisible: true,
          emailHref: mail,
          isMail: true,
        });
      }

      // 没有account，不发请求
      this.sendCaptcha(result);
    } else {
      form.setFields({ account: { errors: [new Error(from === 'phone' ? '请输入手机号' : '请输入邮箱')] } });
    }
  }

  // 启动定时器
  startInterval = () => {
    let count = 60;

    this.setState({ count });

    this.interval = setInterval(
      () => {
        count -= 1;
        this.setState({ count });
        if (count === 0) {
          const eles = document.querySelectorAll('.captcha-btn');
          eles[1].innerHTML = '重新获取验证码';
          clearInterval(this.interval);
        }
      },
      1000
    );
  }

  // 获取验证码
  sendCaptcha = async (result: GeetestValidate) => {
    const { form, postVerifyCode } = this.props;
    const account = form.getFieldValue('account');
    const verifyCode = form.getFieldValue('verifyCode');
    console.log(71, result, account);

    try {
      await postVerifyCode({
        ...result,
        account,
      });

      this.startInterval();
      this.setState({
        emailTextVisible: true,
      });
    } catch (e) {
      const data = e?.response?.data;

      switch (data?.code) {
        case 'CONSOLE_USER_DUPLICATE':
          form.setFields({account: {value: account, errors: [new Error('该账号已经被使用')]}});
          break;
        case 'COMMON_CAPTCHA_COOLING':
          form.setFields({ verifyCode: { value: verifyCode, errors: [new Error('获取验证码操作太频繁，请稍后再试')] } });
          break;
        default:
          break;
      }
    }
  }

  render () {
    const { count, emailTextVisible, emailHref, isMail } = this.state;
    const { form, formItemLayout, captchaBtnClassName } = this.props;
    const { getFieldDecorator } = form;
    const showOriginCaptchaClass = classNames(style.button, { 'show-origin-btn': Boolean(count) });

    return (
      <>
        <FormItem label="验证码" {...formItemLayout}>
          {getFieldDecorator('verifyCode', {
            rules: [{ required: true, message: '验证码不能为空！' }]
          })(
            <div className={style.code}>
              <Input placeholder="验证码(必填)" autoComplete="off" />
              <div className={showOriginCaptchaClass}>
                <Button className={classNames(captchaBtnClassName, 'captcha-btn')} disabled={Boolean(count)}>
                  {count ? `${count} s后可重发` : '获取验证码'}
                </Button>
              </div>
            </div>
          )}
        </FormItem>

        {
          emailTextVisible
            ? (
              <Col offset={9}>
                {
                  isMail
                    ? (
                      <>
                        <p className={style.mailText}>
                          验证码已发送至邮箱，<a href={emailHref} target="_blank">登录邮箱 </a>查看。
                        </p>
                        <p className={style.mailText}>若未收到？请查看垃圾邮件或重新发送。</p>
                      </>
                    )
                    : (
                      <p className="ant-form-explain">验证码已发送，可能会有延后，请耐心等待</p>
                    )
                }
              </Col>
            )
            : null
        }
      </>
    );
  }
}

const mapDispatch = ({ session: { postVerifyCode } }: RootDispatch) => ({ postVerifyCode });

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(VerifyCode);
