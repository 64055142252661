import React, { FC } from 'react';
import { CardTableV2 } from '@chipcoo/hanayo';
import { EnumProcessClass } from '@chipcoo/constant';

import { getTableColumns } from './getTableColumns';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  processClass: EnumProcessClass;
}

const { EnhancedTable } = CardTableV2;

const WipHistory: FC<Props> = props => {
  const { value, } = props;
  let dataSource = value || [];

  return (
    <EnhancedTable
      getTableData={() => {
        //
      }}
      showPagination={false}
      dataSource={dataSource}
      columns={getTableColumns({
        processClass: props.processClass
      }, 'history')}
    />
  );
};

export default WipHistory;
