import * as React from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { CardTableV2 } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumProductModel } from '@chipcoo/constant';

import { getTableColumns, getMaterialColumns } from './getTableColumns';
import { handleMaterialList, handleWaferIdsOrBins } from 'src/models/operation/inventory/handle';

const { EnhancedTable } = CardTableV2;

interface Props {
  productModel: EnumProductModel;
  value?: any;
  onChange?: (value: any) => void;
}

interface State {
  detailModalVisible: boolean;
  dataSource: any[];
  materialId: string;
}

class MaterialNumberList extends React.PureComponent<Props, State> {
  private readonly tableColumns: ColumnProps<any>[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      detailModalVisible: false,
      dataSource: [],
      materialId: ''
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
      productModel: props.productModel,
    });
  }

  openDetailModal = async (data) => {
    const dataSource = handleWaferIdsOrBins(data);

    this.setState({
      detailModalVisible: true,
      materialId: data._id,
      dataSource
    });
  }

  closeDetailModal = () => this.setState({ detailModalVisible: false, materialId: '' });

  render () {
    const { value, productModel } = this.props;
    const { detailModalVisible, dataSource } = this.state;

    return (
      <div>
        <EnhancedTable
          getTableData={() => {
            //
          }}
          columns={this.tableColumns}
          dataSource={handleMaterialList(value)}
          showPagination={false}
          clickMention={true}
          size="small"
          className="small-table-no-border"
        />

        <FlexModal
          width={950}
          animation="fade"
          visible={detailModalVisible}
          title="查看物料"
          footer={null}
          onCancel={this.closeDetailModal}
          resize={{
            minHeight: 350,
            minWidth: 500,
          }}
        >
          <EnhancedTable
            getTableData={() => {
              //
            }}
            columns={getMaterialColumns(productModel)}
            showPagination={false}
            dataSource={dataSource}
          />
        </FlexModal>
      </div>
    );
  }
}

export default MaterialNumberList;
