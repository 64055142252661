import * as React from 'react';
import { get } from 'lodash';
import {
  FormConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  SelectFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

const getProductModelRenderType = (_this, key) => {
  const { formData } = _this;

  return formData?.productModel === EnumProductModel[key] ? 'normal' : 'none';
};

const productModel: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productModel',
  colClassName: 'hidden-form-item',
  render() {
    return <div />;
  }
};

const dieName: InputFormItemConfig = {
  label: '芯片名',
  type: 'input',
  dataKey: 'dieName',
};

const packageType: SelectFormItemConfig = {
  label: '封装类型',
  type: 'select',
  dataKey: 'packageType.packageType',
  optionKey: 'packageType_1',
  renderType() {
    return getProductModelRenderType(this, 'assemblyProduct');
  }
};

const packageTypeLW: InputFormItemConfig = {
  label: '封装型号',
  type: 'input',
  dataKey: 'packageType.packageTypeLW',
  renderType() {
    return getProductModelRenderType(this, 'assemblyProduct');
  }
};

const waferDiameter: CustomFormItemConfig = {
  label: '晶圆直径',
  type: 'custom',
  dataKey: 'waferDiameter',
  render() {
    // 单封
    const diameter = get(this, 'initialFormData.dies.0.die.info.waferDiameter');

    return <div>{diameter}</div>;
  },
  renderType() {
    return getProductModelRenderType(this, 'assemblyProduct');
  }
};

export const getProductInfo = (): FormConfig => ({
  formRow: { gutter: 24 },
  rowGroupConfig: [
    productModel,
    dieName,
    packageType,
    packageTypeLW,
    waferDiameter,
  ]
});
