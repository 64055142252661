/**
 * 弹框组件，该组件是对BasicModal的一层封装，方便在StandardTable里使用
 */
import React, { PureComponent, ComponentType, isValidElement, createRef } from 'react';
import { compose } from 'recompose';
import { isFunction } from 'lodash';
import { BasicModal } from '@chipcoo/hanayo';

import { withVisible, withContext } from 'src/hoc';
import { ModalComponentProps, ModalOriginProps } from './types';

import Ctx from './createContext';

export interface S {
  didMount: boolean;
}

const Enhance = compose(
  withVisible,
  withContext(Ctx, ['resetCtx', 'activeModalKey', 'activeModalPayload'])
);

class OperateModal extends PureComponent<ModalComponentProps, S> {
  private modalRef: any = createRef();
  private closeModalInterceptorFn: (() => (Promise<boolean> | boolean)) | undefined;

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    const { visible, modalKey, show, activeModalKey } = this.props;

    if (!visible && activeModalKey === modalKey) show();
  }

  handleModalClose = () => {
    const { hide, resetCtx } = this.props;

    resetCtx('activeModalKey');
    hide();
  }

  afterModalClose = () => {
    this.props.resetCtx('activeModalPayload');
  }

  closeModalInterceptor = async () => {
    if (!this.closeModalInterceptorFn) return true;

    return await this.closeModalInterceptorFn();
  }

  registerCloseModalInterceptor = fn => this.closeModalInterceptorFn = fn;

  renderModalContent = () => {
    if (!this.modalRef.current) return null;

    const { children, activeModalPayload } = this.props;

    if (isValidElement(children)) return children;

    if (isFunction(children)) {
      const {
        showModalContent,
        registerSubmitHandler,
        __dangerousSetState
      } = this.modalRef.current;

      return (children as any)({
        showModalContent,
        registerSubmitHandler,
        __dangerousSetState,
        registerCloseModalInterceptor: this.registerCloseModalInterceptor,
        payload: activeModalPayload
      });
    }

    return null;
  }

  render() {
    const {
      children,
      hide,
      show,
      modalKey,
      toggle,
      resetCtx,
      activeModalKey,
      activeModalPayload,
      afterClose,
      ...passThoughProps
    } = this.props;

    return (
      <BasicModal
        {...passThoughProps}
        afterClose={this.afterModalClose}
        ref={this.modalRef}
        closeModal={this.handleModalClose}
        closeModalInterceptor={this.closeModalInterceptor}
      >
        {this.renderModalContent()}
      </BasicModal>
    );
  }
}

export default Enhance(OperateModal) as ComponentType<ModalOriginProps>;
