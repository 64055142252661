import * as React from 'react';
import moment from 'moment';
import { Icon, Tooltip } from 'antd';
import classnames from 'classnames';
import { uniqBy, remove, unionWith, cloneDeep, isEmpty } from 'lodash';
import { getMomentTime } from '@chipcoo/fe-utils';
import { EnumMpwBusStatus } from '@chipcoo/constant';

import store from 'src/store';

interface Option {
  myArray: any;
  handleVisible: (val: boolean) => void;
  years: number[];
  months: Obj;
}

const renderContent = (record, handleVisible) => {
  let [text, mpwDateValue, tooltipText, childNode]: any[] = ['', '', '', null];

  if (record) {
    // mpw班车时间
    if (record?.date) {
      mpwDateValue = moment(record?.date).valueOf();
    }

    const { fab, busCode, mpwDate } = record;
    let textArr: any[] = [mpwDate, fab, busCode];

    textArr = textArr.filter(it => it);

    text = textArr.map((it, index) => {
      if (index === textArr.length - 1) {
        return <span key={index}>{it}</span>;
      }

      return <div key={index}>{it}</div>;
    });
  }

  const preorderStop = record?.dates?.preorderStop;
  const preorderCancel = record?.dates?.preorderCancel;
  const data = record?.dates?.data;
  const pay = record?.dates?.pay;
  const tapeOut = record?.dates?.tapeOut;
  // 可以预订班车的最近时间
  const canOrderDate = moment().endOf('day').valueOf();

  switch (record?.status) {
    case EnumMpwBusStatus.canceled:
      tooltipText = '已取消：班车被取消';
      break;
    case EnumMpwBusStatus.paused:
      tooltipText = '已暂停：班车已满';
      break;
    case EnumMpwBusStatus.expired:
      tooltipText = '已过期：已过预定截止时间';
      break;
    default:
      tooltipText = (
        <div>
          <p>预定截止时间: {preorderStop && getMomentTime(preorderStop)}</p>
          <p>取消预定截止时间: {preorderCancel && getMomentTime(preorderCancel)}</p>
          <p>数据提交截止时间: {data && getMomentTime(data)}</p>
          <p>付款截止时间: {pay && getMomentTime(pay)}</p>
          <p>产出时间: {tapeOut && getMomentTime(tapeOut)}</p>
        </div>
      );
  }

  childNode = (
    <Tooltip
      title={tooltipText}
    >
      {
        mpwDateValue >= canOrderDate && record?.status === EnumMpwBusStatus.created ? (
          <div
            className={classnames('order-mpw-cell-wrap', record?.className)}
            // style={{ paddingRight: 24 }}
            onClick={() => handleVisible({ visible: true, cellRecord: record })}
          >
            {text}
            <Icon type={record?.iconType} />
          </div>
        ) : (
          <div className="disabled-mpv-date">
            {record ? (
              <>
                {text}
                <Icon type="lock" />
              </>
            ) : null}
          </div>
        )
      }
    </Tooltip>
  );

  return <div>{childNode}</div>;
};

let date = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const dateColumns = (years, handleVisible, months) => {
  let newDate: any[] = date.map((it, index) => {
    return {
      title: it,
      dataIndex: index,
      key: it,
      width: 112,
      render: (record) => renderContent(record, handleVisible),
    };
  });

  if (years.length === 1) {
    const year = years[0];

    newDate = newDate.map(it => ({
      ...it,
      title: it?.title + ' - ' + year,
    }));
  }

  if (years.length === 2) {
    // 今年还剩几个月，这个数字可后端接口获取后传入
    // let restCol = 6;
    let restCol = months?.[years[0]]?.length;
    // 截取数组并记录原先的dataIndex，再将两个数组拼成一个数组
    const cloneDate = cloneDeep(date).map((it, index) => ({ title: it, index }));
    const removeDate = remove(cloneDate, (it, index) => index < (12 - restCol));
    let withDate = unionWith(cloneDate, removeDate);

    // 给新合成的数组的每一项，拼正确的title
    withDate = withDate.map((it, index) => {
      return {
        ...it,
        title: index < restCol
          ? it?.title + ' - ' + years[0]
          : it?.title + ' - ' + years[1]
      };
    });

    // 生成新的列表配置
    newDate = newDate.map((it, index) => ({
      ...it,
      title: withDate?.[index]?.title,
      dataIndex: withDate?.[index]?.index
    }));
  }

  return newDate;
};

export const getTableColumns = (option: Option): any[] => {
  const { myArray, handleVisible, years, months } = option;
  const { allList, filters } = store.getState().mpwShuttle;

  const columns = [
    {
      title: 'Node',
      dataIndex: 'node',
      fixed: 'left',
      width: 120,
      enableAdvanceFilter: true,
      enableSearch: true,
      searchPlaceholder: '输入以筛选',
      // onFilter: (value, record) => record.node.includes(value),
      fetchFilters: async () => {
        const filterList = allList.filter(it => isEmpty(filters?.process) || filters?.process?.includes(it?.process));
        const data = filterList.map(it => ({ value: it?.node, text: it?.node }));

        return {
          filters: uniqBy(data, it => it?.text),
        };
      },
      render: (value, row, index) => {
        const obj: any = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = myArray?.node?.[index];
        return obj;
      },
    },
    {
      title: 'Process',
      dataIndex: 'process',
      fixed: 'left',
      width: 300,
      enableAdvanceFilter: true,
      enableSearch: true,
      searchPlaceholder: '输入以筛选',
      // onFilter: (value, record) => record.process.includes(value),
      fetchFilters: async () => {
        const filterList = allList.filter(it => isEmpty(filters?.node) || filters?.node?.includes(it?.node));
        const data = filterList.map(it => ({ value: it.process, text: it?.process }));

        return {
          filters: uniqBy(data, it => it?.text),
        };
      },
      render: (value, row, index) => {
        const obj: any = {
          children: value,
          props: {},
        };
        obj.props.rowSpan = myArray?.process?.[index];
        return obj;
      },
    },
    ...dateColumns(years, handleVisible, months)
  ];

  return columns;
};
