import React from 'react';
import log from 'loglevel';
import { uniqueId } from 'lodash';
import { noop, onNextFrame } from '@chipcoo/fe-utils';

import ReqOrderPortal , { ReqOrderPortalProps } from './ReqOrderPortal';
import { openNewGlobalPortal, destroyGlobalPortal } from 'src/layouts/GlobalPortal';

interface OpenConfig extends Omit<ReqOrderPortalProps, 'visible'> {
  autoOpen?: boolean;
}

export function openReqOrderModal(config: OpenConfig) {
  let retValue = {
    closeModal: noop,
    openModal: noop,
  };

  const key = uniqueId('StockInModalStandalone_');
  // 默认的手动调用的modal的配置
  let currentConfig: ReqOrderPortalProps = {
    ...config,
    visible: true
  };

  // 摧毁当前的节点
  function destroy() {
    destroyGlobalPortal(key);
  }

  const { onAfterClose } = config;

  currentConfig.onAfterClose = () => {
    // console.log('after close');
    onAfterClose && onAfterClose();
    destroy();
  };

  function wrapFn(fn?: Function) {
    return async function(...args: any[]) {
      let result;
      try {
        if (fn) {
          await fn.apply(null, args);

          result = true;
        }
      } catch (e) {
        log.error('[StockInModalPortal]: caught an unexpected error', e);
        log.warn(
          '[StockInModalPortal]: return false recommended to prevent close confirm Modal, not an error.'
        );
        result = false;
        throw e;
        return;
      }

      if (result) retValue.closeModal();
    };
  }

  currentConfig.onOk = wrapFn(currentConfig.onOk);
  currentConfig.onCancel = wrapFn(currentConfig.onCancel);

  function Ele() {
    const [visible, setVisible] = React.useState(false);
    retValue.openModal = () => { setVisible(true); };
    retValue.closeModal = () => setVisible(false);

    return (
      <ReqOrderPortal {...currentConfig} visible={visible} />
    );
  }

  openNewGlobalPortal({ key, node: <Ele key={key}/> });

  if (config.autoOpen) {
    onNextFrame(() => {
      retValue.openModal();
    });
  }

  return retValue;
}
