/**
 * 左侧快速切换栏
 */
import React, { ComponentType, PureComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Layout, Badge } from 'antd';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { getUserName, urlToList } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { RootState } from 'src/store';

import AvatarPopoverMenu from './AvatarPopoverMenu/AvatarPopoverMenu';
import { EnhancePopover, EnhanceAvatar } from 'src/components/EnhanceAntd';
import { fastOrgPath, ROUTE_NAMES } from 'src/router/normalRouter';
import { compilePath } from 'src/components/HoneyRouter';

interface OwnProps {}
interface StateProps {
  profile: undefined | User;
  chatUnreadCount: number;
  notifyUnreadCount: number | undefined;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {}

const { Sider } = Layout;
let menuData: any = [];

function fillMenuData() {
  menuData = [
    {
      name: '工作',
      icon: 'icon-coo',
      path: fastOrgPath()
    },
    {
      name: '通知',
      icon: 'icon-bell',
      path: compilePath({ name: ROUTE_NAMES.NOTIFICATION })
    },
    {
      name: '芯聊',
      icon: 'icon-info',
      path: compilePath({ name: ROUTE_NAMES.IM })
    },
  ];
}

setTimeout(fillMenuData);

class QuickSideMenu extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    if (menuData.length === 0) {
      fillMenuData();
    }
  }

  getSelectedMenuKeys = () => {
    const { location: { pathname } } = this.props;
    const urlList = urlToList(pathname);

    return urlList[0];
  }

  shouldShowDot = (itemPath: string) => {
    const { notifyUnreadCount, chatUnreadCount } = this.props;

    if (itemPath === '/notification') {
      return Boolean(notifyUnreadCount);
    }

    if (itemPath === '/im') {
      return Boolean(chatUnreadCount);
    }

    return false;
  }

  renderLink = (item, selectKey: string) => {
    const { location: { pathname } } = this.props;
    const { name, path, icon } = item;
    const isActive = path === selectKey;

    return (
      <NavLink
        key={path}
        to={path}
        replace={path === pathname}
        className={styles.menuItem}
        activeClassName={styles.active}
      >
        <Badge dot={this.shouldShowDot(path)}>
          <i className={classnames('c-icon', styles.icon, isActive ? `${item.icon}-thick` : icon)} />
        </Badge>
        <span className="name">{name}</span>
      </NavLink>
    );
  }

  render() {
    const { profile } = this.props;
    const { avatar } = profile!;
    const name = getUserName(profile!);
    const selectKey = this.getSelectedMenuKeys();

    return (
      <Sider
        trigger={null}
        collapsed={true}
        width={64}
        className={styles.wrapper}
        collapsedWidth={64}
      >
        {menuData.map(menuItem => this.renderLink(menuItem, selectKey))}

        <EnhancePopover
          trigger="click"
          placement="rightBottom"
          overlayClassName={styles.popover}
          content={<AvatarPopoverMenu userName={name} />}
        >
          <div className={styles.avatar}>
            <EnhanceAvatar size="large" src={avatar} userName={name} />
          </div>
        </EnhancePopover>
      </Sider>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  ({ session: { profile }, notification, chatMisc }: RootState) => ({
    profile,
    chatUnreadCount: chatMisc.unread,
    notifyUnreadCount: notification.statics.default.unread
  })
)(withRouter(QuickSideMenu))  as ComponentType<OwnProps>;
