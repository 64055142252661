/**
 * 滚动条鼠标移入后才显示
 */
import * as React from 'react';
import classnames from 'classnames';
import { bind, debounce } from 'lodash-decorators';
// import domUtils from 'oui-dom-utils';

interface P {
  width?: number;
  className?: string;
  styleName?: string;
}
interface S {
  style: any;
}

class HoverScrollBarContainer extends React.PureComponent<P, S> {

  private $wrapper = React.createRef<HTMLDivElement>();

  constructor(props: P) {
    super(props);

    const { width } = props;

    this.state = {
      style: width ? { width } : {}
    };
  }

  componentDidMount() {
    setTimeout(this.setWidth, 0);

    window.addEventListener('resize', this.resetScrollContentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetScrollContentWidth);
  }

  @bind
  @debounce(200)
  resetScrollContentWidth() {
    this.setWidth();
  }

  setWidth = () => {
    if (!this.$wrapper.current) { return; }

    const rect = this.$wrapper.current.getBoundingClientRect();
    const style = this.$wrapper.current.ownerDocument!.defaultView!.getComputedStyle(this.$wrapper.current);
    // const style = domUtils.getComputedStyles(this.wrapperDom);
    // const width = parseFloat(style.width.indexOf('px') !== -1 ? style.width : 0);
    const width = rect.right - rect.left;
    const paddingLeft = parseFloat(style.paddingLeft! || '0');
    const paddingRight = parseFloat(style.paddingRight! || '0');
    const borderLeftWidth = parseFloat(style.borderLeftWidth || '0');
    const borderRightWidth = parseFloat(style.borderRightWidth || '0');
    this.setState({
      style: { width: width - borderRightWidth - borderLeftWidth - paddingLeft - paddingRight }
    });
  }

  render() {
    const { style } = this.state;
    const { className } = this.props;

    return (
      <div className={classnames('y-scroll', 'hover-thin-scroll', className)} ref={this.$wrapper}>
        <div className="scroll-content" style={style}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default HoverScrollBarContainer;
