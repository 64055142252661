/**
 * 聊天里一些杂七杂八的数据都丢这儿
 */
import { createModel } from '@rematch/core';
import update from 'immutability-helper-x';
import { isNumber } from 'lodash';

import { InitialState } from './chatMisc.type';
import { initialNormalizedData, getNormalizedData } from 'src/utils';
import { GetCouldSelectMembersParmas } from './chatMisc.type';
import { RootState } from 'src/store';
import { friendPicker } from '../friend';
import { imApi } from 'src/services/net';

const initialState: InitialState = {
  unread: 0,
  couldSelectMembers: { ...initialNormalizedData(), isInitial: false }
};

export default createModel({
  state: initialState,
  reducers: {
    // 改变总的未读消息数
    changeUnreadCount(state: InitialState, count: number) {
      if (count !== 0) {
        const { unread } = state;
        const _ = unread + count;
        const newCount = isNumber(_) && (_ >= 0) ? _ : 0;

        return update.$set(state, 'unread', newCount);
      }
    },
  },
  effects: (dispatch) => ({
    // 获取全部未读的聊天消息数量
    async getUnreadMessageNumber() {
      const { data } = await imApi.getUnreadMessageNumber();
      const { unread } = data;

      this.updateState({ path: 'unread', data: unread });
    },

    // TODO 需要去根据组织去获取组织成员，目前先只拿全部好友
    // 新建聊天的时候，获取全部可以被选择的成员的列表数据，后面渲染列表
    // 因为新建聊天每次切换可选列表的时候，右侧已选择的成员还在，所以couldSelectMembers的byId只能$merge，不能$set
    async getCouldSelectMembers(parmas: GetCouldSelectMembersParmas, rootState: RootState) {
      const { type } = parmas;

      if (type === 'friend') {
        const { friend: { friends: { byId } }, user } = rootState;
        const friends = friendPicker(user, byId);

        // 好友的数据类型为： { user: User, remarkName: string, _id: friendId }，需要将user信息提取出来
        const newByIdData = getNormalizedData(friends);

        const newData = { ...newByIdData, isInitial: true };

        this.updateState({ path: 'couldSelectMembers', data: newData });
      }
    },
  })
});
