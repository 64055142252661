import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';

interface Option {
  openModal: (key: string) => void;
}

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 200,
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record);
        };

        return (
          <a onClick={openModal}>{text}</a>
        );
      }
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 200
    },
    {
      dataIndex: 'waferIds',
      key: 'waferIds',
      title: '片号',
      width: 200
    },
    {
      dataIndex: 'available',
      key: 'available',
      title: '良品数量',
      width: 100
    },
    {
      dataIndex: 'unavailable',
      key: 'unavailable',
      title: '不良品数量',
      width: 100
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总数量',
      width: 100,
    },
    {
      dataIndex: 'stockInSerial',
      key: 'stockInSerial',
      title: '入库单号',
      width: 200
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 150
    },
    {
      dataIndex: 'stockStatus',
      key: 'stockStatus',
      title: '库存状态',
      width: 100,
    },
    {
      dataIndex: 'restrictBy',
      key: 'restrictBy',
      title: '限制类型',
      width: 100,
    },
    {
      dataIndex: 'remark',
      key: 'remark',
      title: '备注',
      width: 300
    }
  ];
};

export const getMaterialColumns = (): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'waferId',
      key: 'waferId',
      title: '片号',
    },
    {
      dataIndex: 'good',
      key: 'good',
      title: '良品',
    },
    {
      dataIndex: 'bad',
      key: 'bad',
      title: '不良品',
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '数量',
    },
  ];
};
