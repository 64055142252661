/**
 * 消息列表的消息card里面那个需要跟下面的OperateBar进行联动的Checkbox
 */
import * as React from 'react';
import { Checkbox } from 'antd';
import { stopPropagation } from '@chipcoo/fe-utils';

import { InitialMsgListCtx, MsgListCtx } from '../index';

interface P {
  notifyId: string;
  styleName?: string;
  className?: string;
}
interface S {
  checked: boolean;
}

class LinkageCheckBox extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      checked: false
    };
  }

  handleChange = (e, ctx: InitialMsgListCtx) => {
    e.stopPropagation();

    const { notifyId } = this.props;

    ctx.onSelected!(e, notifyId);
  }

  renderCheckbox = (ctx: InitialMsgListCtx) => {
    const { className } = this.props;
    const { selected } = ctx;
    const { notifyId } = this.props;
    const checked = selected.includes(notifyId);

    return (
      <div onClick={stopPropagation}>
        <Checkbox checked={checked} className={className} onChange={e => this.handleChange(e, ctx)} />
      </div>
    );
  }

  render() {
    return (
      <MsgListCtx.Consumer>
        {this.renderCheckbox}
      </MsgListCtx.Consumer>
    );
  }
}

export default LinkageCheckBox;
