// tslint:disable:max-file-line-count
import React from 'react';
import _, { get } from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  TextAreaFormItemConfig,
  DateFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel, EnumStockInApplyStatus, EnumProcessClass, EnumReceiptsCause } from '@chipcoo/constant';
import { unSortWaferId } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';
import { ModalType } from '../index';
import PackingMethodByProductModel from '../../components/PackingMethodByproductModel';
import SelectProductByModel from '../../components/SelectProductByModel';
import {
  EditableMaterial,
  ProcessNameByProduct,
  ChoiceStockInWaferIds,
  Slices,
  BinTable,
  DpDieSlices,
  UniwaferAmount,
  MaterialInfos,
} from '../components';
import './style.module.less';
import { UploadByPaste } from 'src/containers';
import { isParseMap, getMappingInfos, cpReport, goodBins } from '../components/EditableMaterial/formConfig';

// 用于存储入库时所选产品信息
const getProductInfos = (): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'productInfos',
  colClassName: 'hidden-form-item',
  render: (formData, globalStatus, form) => {
    const productId = get(formData, 'productName.key');

    return (
      <UniwaferAmount
        productId={productId}
      />
    );
  }
});

// 用于判断spw芯片是否为已测芯片
const isCpDie: InputFormItemConfig = {
  type: 'input',
  dataKey: 'classes',
  colClassName: 'hidden-form-item',
};

const causeFile = (): CustomFormItemConfig => ({
  label: '附件',
  type: 'custom',
  dataKey: 'causeData.files',
  colClassName: 'files-style',
  render: () => {
    return <UploadByPaste renderType="preview" />;
  }
});

// 内容
const description: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'causeData.description',
  colSpan: 24
};

// 来料检验
const checkFile = (): CustomFormItemConfig => ({
  label: '附件',
  type: 'custom',
  dataKey: 'check.files',
  colClassName: 'files-style',
  render: () => {
    return <UploadByPaste renderType="preview" />;
  }
});

const checkDescription: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'check.description',
  colSpan: 24
};

// 产品名称 需使用productModel判断
const productName = (productModel: EnumProductModel): CustomFormItemConfig => ({
  label: '产品名称',
  type: 'custom',
  required: true,
  requiredMsg: '请选择产品名称',
  dataKey: 'productName',
  renderType: (formData, initialValue, globalStatus) => {
    if (globalStatus === 'preview') {
      return 'preview';
    }
    return 'normal';
  },
  render: (formData, globalStatus, form) => (
    <SelectProductByModel
      status={globalStatus}
      paths={['productName', 'fileType']}
      resetFields={form.resetFields}
      productModel={productModel}
      organization={get(formData, 'customerName.key')}
    />
  )
});

// 制程名称
const getProcessName = (): CustomFormItemConfig => ({
  label: '来料类型',
  type: 'custom',
  dataKey: 'material',
  required: true,
  requiredMsg: '请选择来料类型',
  renderType: (formData, initialValue, globalStatus) => {
    if (globalStatus === 'preview') {
      return 'preview';
    }
    return 'normal';
  },
  render: (formData, globalStatus, form) => {
    const productId = get(formData, 'productName.key');

    return (
      <ProcessNameByProduct
        path={['material', 'attributes', 'dieName', 'markMode', 'packingMethod']}
        resetFields={form.resetFields}
        setFieldsValue={form.setFieldsValue}
        status={globalStatus}
        productName={productId}
      />
    );
  }
});

// 包装方式
const packingMethod = (productModel: EnumProductModel): CustomFormItemConfig => ({
  label: '包装方式',
  type: 'custom',
  dataKey: 'packingMethod',
  required: true,
  requiredMsg: '请选择包装方式',
  renderType: (formData, initialValue, globalStatus) => {
    if (productModel === EnumProductModel.dpProduct ||
       productModel === EnumProductModel.assemblyProduct) {
      if (globalStatus === 'preview') {
        return 'preview';
      }
      return 'normal';
    }
    return 'none';
  },
  render: (formData, globalStatus, form) => {
    // tslint:disable-next-line: no-shadowed-variable
    const productInfos = get(formData, 'productInfos');
    return (
      <PackingMethodByProductModel
        productInfos={productInfos}
        status={globalStatus}
      />
    );
  }
});

// 入库物流单号
const logisticsNumber: InputFormItemConfig = {
  label: '入库物流单号',
  type: 'input',
  dataKey: 'logisticsNumber',
  renderType: (formData, initialValue, globalStatus) => {
    if (globalStatus === 'preview') {
      return 'preview';
    }
    return 'normal';
  }
};

// 来料时间
const createTime: DateFormItemConfig = {
  label: '来料发货时间',
  type: 'date',
  dataKey: 'deliveryAt',
  renderType: (formData, initialValue, globalStatus) => {
    if (globalStatus === 'preview') {
      return 'preview';
    }
    return 'normal';
  }
};

// 来料备注
const remark: TextAreaFormItemConfig = {
  label: '来料备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24
};

// 芯片批号
export const getDieNumber = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '芯片批号',
  type: 'input',
  dataKey: 'dieNumber',
  colClassName: 'die-number',
  required: true,
  requiredMsg: '请输入芯片批号!',
  renderType: (formData, initialValue, globalStatus) => {
    return globalStatus === 'preview' ? 'preview' : 'normal';
  }
});

// 印章批号
export const getStampNumber = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '印章批号',
  type: 'input',
  dataKey: 'stampNumber',
  required: true,
  requiredMsg: '请输入印章批号!',
  renderType: (formData, initialValue, globalStatus) => {
    if (productModel === EnumProductModel.assemblyProduct) {
      if (globalStatus === 'preview') {
        return 'preview';
      }
      return 'normal';
    }
    return 'none';
  }
});

// 片号
const waferIdsText = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '片号',
  type: 'input',
  dataKey: 'waferIds',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'preview';
    }
    return 'none';
  },
  render() {
    const { formData } = this;
    return <div>{formData?.waferIds ? '#' + formData?.waferIds : '--'}</div>;
  }
});

// Bin类型
const binType = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: 'Bin类型',
  type: 'input',
  dataKey: 'binType',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'none';
    }
    return 'preview';
  }
});

// 良品数量,选择物料批号后，自动填充
const good = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '良品数量',
  type: 'input',
  dataKey: 'available',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'preview';
    }
    return 'none';
  }
});

// 不良品数量,选择物料批号后，自动填充
const bad = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '不良品数量',
  type: 'input',
  dataKey: 'unavailable',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'preview';
    }
    return 'none';
  }
});

// 总数量,选择物料批号后，自动填充
const amount: InputFormItemConfig = {
  label: '总数量',
  type: 'input',
  dataKey: 'amount',
  renderType: 'preview',
};

// 片数,选择物料批号后，自动填充
const waferAmount = (productModel: EnumProductModel): InputFormItemConfig => ({
  label: '片数',
  type: 'input',
  dataKey: 'waferAmount',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'preview';
    }
    return 'none';
  },
});

// 片号选择
export const choiceWaferIds = (
  status: ModalType,
  productModel: EnumProductModel,
  outerFormData?: any
): CustomFormItemConfig => ({
  label: '片号选择',
  type: 'custom',
  dataKey: 'waferIdArray',
  required: true,
  requiredMsg: '请选择片号',
  render() {
    const { formData } = this;
    // slices包含加工入库和调拨入库
    let dieNumber: string;
    dieNumber = get(outerFormData, 'dieNumber') || get(formData, 'dieNumber');
    return (
      <ChoiceStockInWaferIds
        dieNumber={dieNumber}
        status={status}
        // resetFields={form.resetFields}
      />
    );
  },
  renderType: (formData, initialData, globalStatus) => {
    if (status === 'preview' ||
      (productModel === EnumProductModel.assemblyProduct || productModel === EnumProductModel.dpProduct)
    ) return 'none';

    return 'normal';
  }
});

// 片号
export const getStockSlice = (
  status: ModalType,
  productModel: EnumProductModel,
  outerFormData?: any
): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'slices',
  colSpan: 24,
  render() {
    const { formData, form } = this;
    let [slices, waferIds]: any[] = [[], []];

    const classes = get(formData, 'classes') || get(outerFormData, 'classes');
    const productInfos = get(formData, 'productInfos') || get(outerFormData, 'productInfos');
    const waferIdArray = get(formData, 'waferIdArray');
    const analysisData = get(formData, `analysisData`);
    const analytiCalIndex = get(formData, 'analytiCalSolution');
    const mappingData = analysisData?.[analytiCalIndex ? analytiCalIndex : 0]?.data;
    const bins = get(formData, 'goodBins');

    const initialAnalysisData = get(this.initialFormData, `analysisData`);

    if (waferIdArray) {
      waferIds = unSortWaferId(waferIdArray);
    }
    // tslint:disable-next-line:no-shadowed-variable
    const isCpDie = classes === EnumProcessClass.cp ? true : false;

    return (
      <Slices
        status={status}
        slices={slices}
        shouldSetField={outerFormData ? false : true}
        waferIds={waferIds}
        isCpDie={isCpDie}
        productInfos={productInfos}
        mappingData={mappingData}
        bins={bins}
        setFieldsValue={form.setFieldsValue}
        reMapping={!_.isEqual(initialAnalysisData, analysisData)}
      />
    );
  },
  renderType: (formData, initialValue, globalStatus) => {
    if (productModel !== EnumProductModel.assemblyProduct && productModel !== EnumProductModel.dpProduct) {
      if (globalStatus === 'preview') {
        return 'preview';
      }
      return 'normal';
    }
    return 'none';
  },
});

// Bin类型
export const getStockBin = (
  status: ModalType,
  productModel: EnumProductModel,
  outerFormData?: any
): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'bins',
  colSpan: 24,
  required: true,
  requiredMsg: '请填写Bin类型及数量',
  render: (formData, formStatus, form) => (
    <BinTable
      status={status}
      shouldSetFields={outerFormData ? false : true}
      setFieldsValue={form.setFieldsValue}
    />
  ),
  renderType: (formData, initialValue, globalStatus) => {
    if (productModel === EnumProductModel.assemblyProduct) {
      if (globalStatus === 'preview') {
        return 'preview';
      }
      return 'normal';
    }
    return 'none';
  },
});

// 磨划芯片
export const getDpDieSlices = (
  status: ModalType,
  productModel: EnumProductModel,
  outerFormData?: any
): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'dpDieSlices',
  colSpan: 24,
  required: true,
  requiredMsg: '请填写磨划成品片号及数量',
  render() {
    const { formData, form } = this;
    // 根据dieNumber判断是否含有已入库片号
    let dieNumber: string;
    dieNumber = get(formData, 'dieNumber');
    return (
      <DpDieSlices
        status={status}
        setFieldsValue={form.setFieldsValue}
        dieNumber={dieNumber}
        shouldSetFields={outerFormData ? false : true}
        resetFields={form.resetFields}
      />
    );
  },
  renderType: (formData, initialValue, globalStatus) => {
    if (productModel === EnumProductModel.dpProduct) {
      if (globalStatus === 'preview') {
        return 'preview';
      }
      return 'normal';
    }
    return 'none';
  },
});

// 当前申请状态及拒绝理由
const statusText: CustomFormItemConfig = {
  label: '当前状态',
  type: 'custom',
  dataKey: 'status',
  render: (formData) => {
    const { status } = formData;
    const statusMessage = optionsGroup.stockInApplyStatus.options![status];
    return <div>{statusMessage}</div>;
  }
};

const rejectReason: TextAreaFormItemConfig = {
  label: '拒绝理由',
  type: 'textArea',
  dataKey: 'reason',
  colSpan: 24,
  renderType: (formData) => {
    const { status } = formData;
    if (status === EnumStockInApplyStatus.rejected) return 'normal';
    return 'none';
  }
};

const materialInfo = (productModel: EnumProductModel): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'materialInfo',
  colSpan: 24,
  render: (formData, formStatus, form) => {
    // tslint:disable-next-line:no-shadowed-variable
    const { detailMaterial } = formData;

    return (
      <MaterialInfos materialInfo={detailMaterial} productModel={productModel} />
    );
  }
});

export const getStockInConfig = (status: ModalType, productModel: EnumProductModel): FormConfig => {
  if (status === 'new') {
    return ({
      cardGroupConfig: [
        {
          card: { title: '入库信息' },
          groupItemConfig: [
            isCpDie,
            getProductInfos(),
            productName(productModel),
            getProcessName(),
            logisticsNumber,
            createTime,
            packingMethod(productModel),
            remark,
          ] as FormItemConfig[]
        },
        {
          card: { title: '物料信息' },
          groupItemConfig: [
            materialInfo(productModel),
          ] as FormItemConfig[],
          renderType() {
            return !_.isEmpty(this.formData?.detailMaterial) ? 'normal' : 'none';
          }
        },
        {
          card: { title: '入库详细数量' },
          groupItemConfig: [
            {
              type: 'custom',
              dataKey: 'detailMaterial',
              colSpan: 24,
              required: true,
              requiredMsg: '请点击添加一批填写物料信息及数量!',
              render: (formData, formStatus, form) => {
                return (
                  <EditableMaterial
                    globalState={formStatus}
                    outerFormData={formData}
                    productModel={productModel}
                  />
                );
              }
            }
          ] as FormItemConfig[],
          ifRender: (formData) => {
            return get(formData, 'material');
          }
        },
      ]
    });
  }
  return ({
    cardGroupConfig: [
      {
        card: { title: '入库申请依据' },
        groupItemConfig: [
          causeFile(),
          description,
        ].map(it => ({...it, renderType: 'preview'})) as FormItemConfig[],
        ifRender: (formData, initialData, globalStatus) => {
          return initialData.cause === EnumReceiptsCause.other;
        }
      },
      {
        groupItemConfig: [
          statusText,
          rejectReason,
        ] as FormItemConfig[],
        ifRender: () => status === 'preview' || status ===  'edit',
      },
      {
        card: { title: '入库信息' },
        groupItemConfig: [
          isCpDie,
          getProductInfos(),
          productName(productModel),
          getProcessName(),
          logisticsNumber,
          createTime,
          packingMethod(productModel),
          remark,
        ] as FormItemConfig[]
      },
      {
        card: { title: '物料信息' },
        groupItemConfig: [
          waferIdsText(productModel),
          binType(productModel),
          good(productModel),
          bad(productModel),
          amount,
          waferAmount(productModel),
        ] as FormItemConfig[],
      },
      {
        card: { title: '入库详细数量' },
        groupItemConfig: [
          isParseMap('preview'),
          getMappingInfos('preview'),
          cpReport({}, 'preview'),
          goodBins('preview'),
          getDieNumber(productModel),
          choiceWaferIds(status, productModel),
          getStockSlice(status, productModel),
          getStampNumber(productModel),
          getStockBin(status, productModel),
          getDpDieSlices(status, productModel),
        ] as FormItemConfig[],
        ifRender: (formData) => {
          return get(formData, 'material');
        }
      },
      {
        card: { title: '来料检验' },
        groupItemConfig: [
          checkFile(),
          checkDescription,
        ] as FormItemConfig[]
      }
    ]
  });
};
