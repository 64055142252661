import React from 'react';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';

const key: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'key',
  colClassName: 'hidden-form-item',
  render() {
    return <div />;
  }
};

const name = (label): InputFormItemConfig => ({
  label: `${label}联系人`,
  type: 'input',
  dataKey: 'name',
  colSpan: 24,
  validate: [{
    pattern: /^.{0,20}$/,
    message: '最长20个字符'
  }],
  required() {
    const bool = (
      this.formData.phone ||
      this.formData.email ||
      this.formData.address
    );

    return bool ? true : false;
  }
});

const phone = (): InputFormItemConfig => ({
  label: '联系电话',
  type: 'input',
  dataKey: 'phone',
  colSpan: 24,
  validate: [{
    pattern: /^[\d-()（）]{1,20}$/,
    message: '请输入正确格式的联系电话，只能包含数字、-、()，最长20个字符',
  }]
});

const email = (label, dataKey): InputFormItemConfig => ({
  label: `${dataKey === 'STOCK_OUT' ? '出货清单' : label}邮箱地址`,
  type: 'input',
  dataKey: 'email',
  colSpan: 24,
  validate: [{
    pattern: /^(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*){1,100}$/,
    message: '请输入正确的邮箱格式!',
  }]
});

const address: InputFormItemConfig = {
  label: '联系地址',
  type: 'input',
  dataKey: 'address',
  colSpan: 24,
  validate: [{
    pattern: /^.{0,100}$/,
    message: '最长100个字符'
  }],
};

const getContact = (dataKey, label): FormItemConfig[] => {
  switch (dataKey) {
    case 'ORGANIZATION':
      return [
        key,
        name(label),
        phone(),
        email(label, dataKey),
        address,
      ];
    case 'TAPE_OUT':
      return [
        key,
        name(label),
        phone(),
        email(label, dataKey),
      ];
    case 'ORDER':
      return [
        key,
        name(label),
        phone(),
        email(label, dataKey),
      ];
    case 'STOCK_OUT':
      return [
        key,
        name(label),
        phone(),
        email(label, dataKey),
      ];
    case 'FINANCE':
      return [
        key,
        name(label),
        phone(),
        email(label, dataKey),
      ];
    default:
      return [];
  }
};

export const getFormConfig = (dataKey, label): FormConfig => {
  return {
    cardGroupConfig: [
      {
        groupItemConfig: getContact(dataKey, label)
      }
    ]
  };
};
