import { AxiosResponse } from 'axios';
import { remove } from 'lodash';

const messageIds: string[] = [];

function clearMessageId(id: string) {
  remove(messageIds, it => it === id);
}

export function axiosResponseInterceptor(resp: AxiosResponse) {
  const { headers } = resp;
  const messageId = headers['x-request-id'];
  if (messageId) {
    messageIds.push(messageId);

    setTimeout(() => {
      clearMessageId(messageId);
    }, 1000 * 5);
  }

  return resp;
} //

export function isLocalRequest(messageId: string) {
  return messageIds.indexOf(messageId) !== -1;
}
