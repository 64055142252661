// tslint:disable:max-file-line-count
/**
 * 智能选料-可选物料列表
 */
import React, { ComponentType } from 'react';
import _ from 'lodash';
import { Tabs } from 'antd';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { noop, getSelectedRows } from '@chipcoo/fe-utils';
import { EnumProcessClass, EnumProductModel, EnumWarehouseType } from '@chipcoo/constant';

import './index.less';
import { getTableColumns } from './getTableColumns';
import { Actions, connectDecorator, RootState } from 'src/store';
import { TableCheckboxCtx } from 'src/containers/TableCheckBoxGroup/context';
import { DemandOrderContext } from '../components/context';
import { ISelectionStock } from 'src/models/operation/demandOrder/interface';

interface OwnProps {
  product: string;
  type: EnumWarehouseType;
  classes?: EnumProcessClass;
  productModel: EnumProductModel;
  height?: number;
  selectedData?: any[];
}

interface StateProps {
  stockCount: number;
  stockList: {
    originData: any[];
    handleData: any[];
  };
}

interface DispatchProps {
  getProductSelectionStock: (params: ISelectionStock) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  changeCheck: (val: any) => void;
  tableLoading: boolean;
  tabActiveKey: EnumProductModel;
  stockListObj: Obj;
}

const { EnhancedTable } = CardTableV2;
const TabPane = Tabs.TabPane;

@connectDecorator(
  ({
     demandOrder: { stockCount, stockList }
   }: RootState) => ({ stockCount, stockList }),
  ({
     demandOrder: { getProductSelectionStock }
   }: Actions) => ({
    getProductSelectionStock
  })
)
class MaterialTable extends React.PureComponent<Props, State> {
  static contextType = DemandOrderContext;
  private cardTableController: ICardTableController;

  constructor(props: Props) {
    super(props);

    this.state = {
      changeCheck: this.changeCheck,
      tableLoading: false,
      tabActiveKey: EnumProductModel.spwDie,
      stockListObj: {
        [EnumProductModel.spwDie]: [],
        [EnumProductModel.dpProduct]: [],
        [EnumProductModel.assemblyProduct]: [],
      }
    };
  }

  componentDidMount(): void {
    this.setState({
      stockListObj: this.initCheckedList(),
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { stockList } = this.props;

    if (!_.isEqual(stockList, prevProps.stockList)) {
      this.setState({ stockListObj: stockList.handleData });
    }
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  // 根据已选的物料列表，点击重新选料，需要选中之前选择的物料
  initCheckedList = () => {
    const { stockList, selectedData } = this.props;

    const newStockList = Object.entries(stockList?.handleData).reduce(
      (prev, [model, data]) => {
        const newData = data.map(it => {
          const selectedItem = selectedData?.find(s => s._id === it._id);

          if (selectedItem) {
            if (selectedItem?.children) {
              const childrenKeys = selectedItem?.children?.map(c => c.key);

              const newChildren = it.children.map(c => {
                if (childrenKeys.includes(c.key)) {
                  return {
                    ...c,
                    checked: true,
                    // ...selectedItem?.children.find(sc => sc.key === c.key),
                  };
                }

                return c;
              });

              return {
                ...selectedItem,
                children: newChildren,
              };
            } else {
              return selectedItem;
            }
          }

          return it;
        });

        return {
          ...prev,
          [model]: newData,
        };
      },
      {}
    );

    return newStockList;
  }

  // 手动选择物料或取消勾选
  changeCheck = (val) => {
    const { tabActiveKey, stockListObj } = this.state;
    const data = [...this.state.stockListObj?.[tabActiveKey]];
    const { itemType, key } = val;
    const [_id] = key?.split('_');

    const newDataSource = data?.map(d => {
      if (itemType === 'sub' && d.key === _id) {
        const children = d?.children.map(sub => ({
          ...sub,
          checked: sub.key === key ? !sub.checked : sub.checked,
        }));
        // 是否存在没有选中的item
        const noCheckedArray = children.filter(sub => !sub.checked);

        return {
          ...d,
          checked: _.isEmpty(noCheckedArray),
          children
        };
      }

      if (itemType === 'group' && d.key === key) {
        // group的checked判断逻辑
        const dChecked = _.isEmpty(d?.children)
          ? !Boolean(d.checked)
          : (
            _.has(d, 'checked') ? !d.checked : true
          );

        return {
          ...d,
          checked: dChecked,
          children: d?.children
            ? d.children?.map(c => ({
              ...c,
              checked: dChecked
            }))
            : false
        };
      }

      return d;
    });

    const newStockListObj = { ...stockListObj, [tabActiveKey]: newDataSource };

    this.setState({ stockListObj: newStockListObj });

    // 选中数据的处理逻辑
    const selectedRowsObj = Object.entries(newStockListObj).reduce(
      (prev, [productModel, dataList]) => {
        const newData = getSelectedRows(dataList, []);

        return {
          ...prev,
          [productModel]: newData,
        };
      },
      {}
    );
    const selectedRows = selectedRowsObj[EnumProductModel.spwDie].concat(
      selectedRowsObj[EnumProductModel.dpProduct] ?? [],
      selectedRowsObj[EnumProductModel.assemblyProduct] ?? []
    );

    this.context.submitTableData(selectedRows);
  }

  // 可选物料footer
  getTableFooter = (currentPageData) => {
    return null;
  }

  getTableData = async (params: GetTableDataParams) => {
    const { product, type, getProductSelectionStock } = this.props;
    const { formData , ...extraParams } = params;

    const queryParams: any = {
      ...formData,
      ...extraParams,
      productId: product,
      productModel: formData?.productModel || EnumProductModel.spwDie,
      warehouseType: type,
    };

    this.setState({ tableLoading: true });

    try {
      await getProductSelectionStock(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  getScrollY = () => {
    let { height } = this.props;

    if (!height) {
      const chipwingModal = document.querySelector('.order-material-form-modal .chipwing-modal');

      height = _.get(chipwingModal, 'offsetHeight');
    }

    return height! - 49 - 64 - 16 * 7 - 25 - 55 - 38 - 37 - 45 - 16 - 270;
  }

  handleTabClick = (tabKey) => {
    this.cardTableController?.setQueryParams!({
      page: 1,
      formData: { productModel: tabKey },
    });

    this.setState({
      tabActiveKey: tabKey,
    });
  }

  getTableDataRender = () => {
    const { stockCount } = this.props;
    const { tableLoading, tabActiveKey, stockListObj } = this.state;

    return (
      <EnhancedTable
        className="order-material-form-table small-table-no-border"
        getTableData={this.getTableData}
        total={stockCount}
        dataSource={stockListObj?.[tabActiveKey]}
        title={() => '可选物料'}
        // footer={this.getTableFooter}
        columns={getTableColumns({ tabActiveKey })}
        scroll={{ y: this.getScrollY() }}
        tableLoading={tableLoading}
        size="small"
      />
    );
  }

  render () {
    const { classes } = this.props;

    return (
      <TableCheckboxCtx.Provider value={this.state}>
        <CardTableV2
          getCardTableController={ctrl => this.cardTableController = ctrl}
        >
          <Tabs onTabClick={this.handleTabClick}>
            <TabPane tab="SPW芯片" key={EnumProductModel.spwDie}>
              {this.getTableDataRender()}
            </TabPane>

            {
              classes === EnumProcessClass.assembly && (
                <TabPane tab="磨划芯片" key={EnumProductModel.dpProduct}>
                  {this.getTableDataRender()}
                </TabPane>
              )
            }

            {
              classes === EnumProcessClass.assembly && (
                <TabPane tab="封装成品" key={EnumProductModel.assemblyProduct}>
                  {this.getTableDataRender()}
                </TabPane>
              )
            }
          </Tabs>
        </CardTableV2>
      </TableCheckboxCtx.Provider>
    );
  }
}

export default MaterialTable as ComponentType<OwnProps>;
