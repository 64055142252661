/**
 * 处理聊天和评论中的content，比如转换emoji、换行符和@人颜色加深之类的
 */
import * as React from 'react';
import { findIndex, isObject } from 'lodash';
import reactStringReplace from 'react-string-replace';

import styles from './index.module.less';
import emojiDictionary from 'src/common/emojiDictionary';

interface P {
  content: string;
  // 是否显示第一个换行符后面的内容
  showTextAfterBr: boolean;
  myUserId?: number | string | (string | number)[];
  findNameFn?: (id: string) => string;
}
interface S {}

function isContainId(list: any, id: any) {
  if (!list) {
    return false;
  }

  let arr = list;
  if (!Array.isArray(list)) {
    arr = [list];
  }

  return arr.some(item => {
    try {
      return item.toString() === id.toString();
    } catch (e) {
      console.error(e);
      return false;
    }
  });
}

class HandledMessageContent extends React.PureComponent<P, S> {
  static defaultProps = {
    showTextAfterBr: true
  };

  render() {
    const { content, showTextAfterBr } = this.props;
    const { myUserId = [], findNameFn = () => '' } = this.props;
    let replacedText;

    // 将表情的标志文字替换成对应的图片
    replacedText = reactStringReplace(content, /\[em:([\u4E00-\u9FA5|a-z]+)\]/ig, (match, i) => {
      const emoji = emojiDictionary[match];

      if (!emoji) { return `[em:${match}]`; }

      return <img key={i} className={styles.emoji} src={emoji.src} alt={`[em:${match}]`} />;
    });

    // 替换\n换行符
    replacedText = reactStringReplace(replacedText, '\n', (match, i) => <br key={match + i} />);

    // 将 @id 替换成 @昵称
    replacedText = reactStringReplace(replacedText, /{{@(\d+)}}/gm, (userId, i) => {
      const isAtMe = isContainId(myUserId, userId);
      const name = findNameFn(userId) || userId;
      const classList = ['handled-message', userId === name ? '' : 'at'];

      if (isAtMe) {
        classList.push('me');
      }

      return (<span className={classList.join(' ')} key={userId + i}>@{name}</span>);
    });

    // 第一个换行符后面的所有内容全部删除
    if (!showTextAfterBr && replacedText.length) {
      const index = findIndex(replacedText, (item: any) => isObject(item) && item.type === 'br');

      if (~index) {
        replacedText = replacedText.slice(0, index);
      }
    }

    return replacedText;
  }
}

export default HandledMessageContent;
