// import * as React from 'react';

const serial = {
  title: '需求订单号',
  key: 'serial',
  dataIndex: 'serial',
};

const productName = {
  title: '客户产品名称',
  key: 'customerName',
  dataIndex: 'customerName',
};

const productModelText = {
  title: '产品类型',
  key: 'productModelText',
  dataIndex: 'productModelText',
};

export const getTableColumns = () => [
  serial,
  productName,
  productModelText
];
