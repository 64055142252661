/**
 * 让Table组件在出现横向滚动条的时候，可以直接拖着表格进行滚动
 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Table } from 'antd';
import classNames from 'classnames';
import { TableProps } from 'antd/lib/table/interface';

import styles from './index.module.less';

const resetMouse = () => ({
  startX: 0,
  originScrollLeft: 0
});

class TableWidthHorizontalDrag<T> extends React.PureComponent<TableProps<T>> {
  private scrollContainer: HTMLElement;
  private scrollElement: HTMLElement;
  private tableDom: HTMLElement;
  private maxWidth: boolean | number | string;
  private mouse = resetMouse();

  componentDidMount() {
    this.getScrollTableDom();
    this.maxWidth = this.props.scroll!.x!;
    this.scrollElement.addEventListener('mousedown', this.handleMouseDown);
  }

  componentWillUnmount() {
    this.handleDocumentEventListener(false);
    this.scrollElement.removeEventListener('mousedown', this.handleMouseDown);
  }

  handleMouseDown = (event: MouseEvent) => {
    event.stopPropagation();

    // 先检查table得宽度是否已经超过了props传入得scroll得宽度，如果超过了就说明没有滚动条了，直接返回
    const tableWidth = this.tableDom.offsetWidth;

    if (tableWidth >= this.maxWidth) { return; }

    const mouse = this.mouse;

    mouse.startX = event.pageX;
    mouse.originScrollLeft = this.scrollContainer.scrollLeft;

    this.handleDocumentEventListener(true);
  }

  handleMouseMove = (event: MouseEvent) => {
    const mouse = this.mouse;

    const offsetX = mouse.startX - event.pageX;
    let scrollLeft = Math.round(mouse.originScrollLeft + offsetX);

    if (scrollLeft < 0 || scrollLeft > this.maxWidth) { return; }

    // 强制滚动到0，触发table left的阴影改变
    if (scrollLeft < 5) { scrollLeft = 0; }

    this.scrollContainer.scrollLeft = scrollLeft;
  }

  handleMouseUp = (event: MouseEvent) => {
    this.mouse = resetMouse();
    this.handleDocumentEventListener(false);
  }

  // 批量对绑定document上得两个事件进行删除和添加操作
  handleDocumentEventListener = (isAdd?: boolean) => {
    ['MouseUp', 'MouseMove'].forEach(i => {
      const [event, handler] = [i.toLowerCase(), this[`handle${i}`]];

      isAdd ? document.addEventListener(event, handler) : document.removeEventListener(event, handler);
    });
  }

  getScrollTableDom = () => {
    this.scrollContainer = this.tableDom.querySelector('.ant-table-scroll .ant-table-body') as HTMLElement;
    this.scrollElement = this.tableDom.querySelector('.ant-table-scroll .ant-table-fixed') as HTMLElement;

    if (process.env.NODE_ENV === 'development') {
      if (!this.scrollElement || !this.scrollContainer) {
        console.error('检查下antd是不是改了table组件的dom结构');
      }
    }
  }

  render() {
    const { className, ...passThoughProps } = this.props as any;

    return (
      <Table
        {...passThoughProps}
        ref={child => this.tableDom = ReactDOM.findDOMNode(child!) as HTMLElement}
        className={classNames(className, styles.tableCss)}
      />
    );
  }
}

export default TableWidthHorizontalDrag;
