import { EnumProcessStatus } from '@chipcoo/constant';
import { EnumProductModel } from '@chipcoo/constant/lib/product';
import { IBaseCraftRouteProductInfo, ICraftRouteFullRoute } from 'src/services/net/craftRoute';
import { IMaterialDetail } from 'src/services/net/craftRoute';

export enum ProductNodeType {
  /** 基本的供应路线节点 */
  BASE_CRAFT_ROUTE_NODE = 'base-route',
  /** 最终产品的供应路线节点 */
  DEST_CRAFT_ROUTE_NODE = 'dest-route',
  /** 起始产品的供应路线节点 */
  SOURCE_CRAFT_ROUTE_NODE = 'source-route',
}

export enum AnchorType {
  INPUT_ANCHOR = 'input-anchor',
  OUTPUT_ANCHOR = 'output-anchor'
}

export interface ICraftRouteLabel {
  text?: string;
  fill?: string;
  fontSize?: number;
}

export interface IBaseGraphItem {
  label?: string | ICraftRouteLabel;
  id: string;
  x?: number;
  y?: number;
  isRef?: boolean;
  [key: string]: any;
}

interface IBaseGraphNode extends IBaseGraphItem {
  parent?: string;
}

export interface ICraftRouteNode extends IBaseGraphNode {
  shape?: ProductNodeType;
  material?: IMaterialDetail;
  materials?: IMaterialDetail[] | null;
  craftRouteId?: string | null;
  /** 存储当前产品所有的工艺路线 */
  craftRoutes?: ICraftRouteFullRoute[] | null;
  amount: number;
  depthArr?: number[];
  product: null | IBaseCraftRouteProductInfo;
  productModel: null | EnumProductModel;
}

export interface ICraftRouteGroup extends IBaseGraphItem {
  shape?: string;
}

export interface ICraftRouteEdge extends IBaseGraphItem {
  source: string;
  target: string;
  sourceAnchor: number;
  targetAnchor: number;
  process: IProcessDetail | null;
}

export interface IProcessDetail {
  code: string;
  description: string;
  name: string;
  productModel: EnumProductModel;
  serial: number;
  status: EnumProcessStatus;
  _id: string;
}
