/**
 * 点击后可编辑的那个输入框
 */
import * as React from 'react';
import { Input, Spin } from 'antd';
import onClickOutside from 'react-onclickoutside';
import { noop } from '@chipcoo/fe-utils';

import { ControlledComponent } from 'src/hoc';

interface P {
  placeholder: string | undefined;
  value: string | undefined;
  onInputChange: (value: string | undefined) => void;
  onInputClose: () => void;
  setRemarkName: () => Promise<void | boolean> | boolean | undefined;
}
interface S {
  loading: boolean;
}

const ControlledInput = ControlledComponent(Input as any) as any;

@onClickOutside
class InputWrapper extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      loading: false
    };
  }

  submitRemarkName = async () => {
    const { loading } = this.state;
    const { setRemarkName, onInputClose } = this.props;

    // 防止用户重复提交
    if (loading) { return; }

    this.setState({ loading: true });

    let result;

    try {
      result = await setRemarkName();
    } catch (e) {
      console.error(e);
    }

    setTimeout(() => {
      result ? onInputClose() : this.setState({ loading: false });
    });
  }

  handleClickOutside = async (ev) => {
    this.submitRemarkName();
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  render() {
    const { loading } = this.state;
    const { placeholder, value, onInputChange } = this.props;

    return (
      <Spin spinning={loading} delay={50}>
        <ControlledInput
          size="small"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          autoFocus={true}
          onChange={onInputChange}
          onPressEnter={this.submitRemarkName}
        />
      </Spin>
    );
  }
}

export default InputWrapper;
