// import * as React from 'react';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';

export const filterFormConfig: FormItemConfig[] = [
  {
    label: '物料名称',
    type: 'input',
    dataKey: 'name',
  },
  {
    label: '库房名称',
    type: 'select',
    dataKey: 'warehouse',
    optionKey: 'warehouse',
    props: { labelInValue: true }
  },
];
