import pathToRegexp, { compile } from 'path-to-regexp';
import { matchPath, RouteComponentProps } from 'react-router';
import { omit, pick } from '../utils/lodash';
import { CompiledRouteProps, CompilePathOptions, HoneyRouteProps, MatchFilterFn } from '../interface';
import { isProd } from 'src/utils';

function defaultQueryEncoder(val: any) {
  return encodeURIComponent(val);
}

export function getDefaultMatch<T extends object = {}>(params: T = ({} as T)): RouteComponentProps<T>['match'] {
  return {
    isExact: false,
    params,
    path: '/',
    url: '/'
  };
}

function duplicatedName(name: string, rs: CompiledRouteProps[]) {
  return rs.filter(r => r.name === name).length > 1;
}

export function createStatic(routes: CompiledRouteProps[]) {
  function compilePath(options: CompilePathOptions) {
    const { name, params = {}, queryEncoder = defaultQueryEncoder, query = {} } = options;
    let { path, hash = '' } = options;
    let compiler;

    if (!path && !name) {
      return '/';
    }

    if (name) {
      const route = routes.find(r => r.name === name);

      if (route) {
        compiler = route.compiler;
      }
    } else if (path) {
      compiler = compile(path);
    }

    if (!compiler) {
      !isProd && console.log('No path compiler found');
      return '/';
    }

    let querystring = Object.entries(query).map(([key, val]) => `${key}=${queryEncoder(val)}`).join('&');
    if (querystring) {
      querystring = `?${querystring}`;
    }

    if (hash) {
      hash = `#${hash}`.replace(/^#+/, '#');
    }

    let compiledPath;

    try {
      compiledPath = compiler(params);
    } catch (e) {
      console.error(e);
      compiledPath = '/';
    }

    return `${compiledPath}${querystring}${hash}`;
  }

  function registerRoute(props: Partial<HoneyRouteProps>, rs: CompiledRouteProps[] = routes) {
    const { path, name } = props;
    const route = omit(props, ['match', 'staticContext', 'history', 'location']);

    if (!path) {
      !isProd && console.log('[HoneyRoute]: Wrong route props, path is required', path);
      return route;
    }

    if (name && duplicatedName(name, rs)) {
      return console.error(`[HoneyRoute]: Route name '${name}' is duplicated.`);
    }

    // Find exist by path and name
    const exist = rs.find(r => r.path === path && r.name === name);
    if (exist) {
      Object.assign(exist, route);

      return exist;
    }

    const newRoute: any = Object.assign({
      compiler: compile(path as string),
    }, route);
    const length = rs.push(newRoute);

    return rs[length - 1];
  }

  function getRoute(arg?: { name?: string, path?: string }) {
    if (!arg) {
      return null;
    }

    const { name, path } = arg;
    const notEmptyArg = !!name || !!path;

    if (!notEmptyArg) {
      return null;
    }

    return routes.find(
      r =>
        (!name || r.name === name)
        && (!path || (r.path === path))
    ) || null;
  }

  function getRouteByName(name?: string) {
    return getRoute({ name });
  }

  function getRouteByPath(path?: string) {
    return getRoute({ path });
  }

  function getMatchedRoute(
    pathname: string,
    matchFilterFn: MatchFilterFn = () => true,
    rs: CompiledRouteProps[] = routes
  ): CompiledRouteProps[] {
    return rs.reduce((all, route) => {
      const { path, sensitive, strict } = route;
      const match = matchPath(pathname, { path, sensitive, strict, exact: false });

      if (match && matchFilterFn(route, match)) {
        all.push({
          ...route,
          match,
        });
      }

      return all;
    }, [] as CompiledRouteProps[]).sort((a, b) => {
      const result = ((a.path || '') as string).split('/').length - ((b.path || '') as string).split('/').length;
      if (result) {
        return result;
      }

      return (a.path || '').length - (b.path || '').length;
    });
  }

  function getRouterProps(props: any): RouteComponentProps {
    return pick(props, ['match', 'history', 'location', 'staticContext']);
  }

  function getMatchInfoByPathname<T extends object = {}>(pathname: string, rs: CompiledRouteProps[] = routes) {
    let match: RouteComponentProps<T>['match'] | null = null;

    rs.some(r => {
      const result = matchPath<T>(pathname, r);

      if (result && result.url === pathname) {
        match = result;
      }

      return !!match;
    });

    return match || getDefaultMatch();
  }

  return {
    compilePath,
    registerRoute,
    getMatchedRoute,
    pathToRegexp,
    getRoute,
    getRouteByName,
    getRouteByPath,
    getRouterProps,
    getMatchInfoByPathname,
  };
}

export default createStatic;
