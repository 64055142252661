import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface.d';
import { TableModelCommonEffects } from 'src/models/type';
import { qcApi } from 'src/services/net';
import { handleAnalysisLog, handleDetailLog } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: {
    originStatus: [],
    stages: [],
  },
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setLogList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getAnalysisLog(params);
      const handleData = handleAnalysisLog(data?.data);

      this.setLogList({
        data: handleData,
        count: data?.count,
      });
    },
    async getImportLog(params: any) {
      const { data } = await qcApi.getImportLog(params);
      const handleData = handleAnalysisLog(data?.data);

      this.setLogList({
        data: handleData,
        count: data?.count,
      });
    },
    async getTableItemDetail(_id: string) {
      const { data: detail } = await qcApi.getImportDetail({_id});
      const { data: originStatus } = await qcApi.getImportStatus({_id});

      const handleDetail = handleDetailLog({originStatus, ...detail});

      this.setDetail(handleDetail);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    }
  })
});
