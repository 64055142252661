import { createModel } from '@rematch/core';
import update from 'immutability-helper-x';

export interface InitialState {
  imCacheRoute: string;
  notificationCacheRoute: string;
  appCacheRoute: string;
}

export type CacheRouteParams = {
  cacheRoot: 'im' | 'notification' | 'app';
  url: string;
};

const initialState: InitialState = {
  imCacheRoute: '/im',
  notificationCacheRoute: '/notification',
  appCacheRoute: '/app'
};

export default createModel({
  state: initialState,
  reducers: {
    setCacheRoute(state: InitialState, payload: CacheRouteParams) {
      const { cacheRoot, url } = payload;
      const key = `${cacheRoot}CacheRoute`;
      const cachedRoute = state[key];

      if (cachedRoute !== url) {
        return update.$set(state, key, url);
      }

      return state;
    }
  }
});
