/**
 * 聊天列表的头部
 */
import * as React from 'react';
import { bind } from 'lodash-decorators';
import { isEqual } from 'lodash';
import { BasicModal } from '@chipcoo/hanayo';
import { filterUser } from '@chipcoo/fe-utils';

import styles from './style.module.less';

import { SelectMemberPanel, HocUserCard } from 'src/components';
import { DataItem, MemberSelectGroup } from 'src/components/SelectMemberPanel';
import { findUserById } from 'src/utils/user';

interface P {
  users: User[];
  visible: boolean;
  value?: UserId[];
  disabledKeys?: UserId[];
  modalTitle?: string;
  leftTitle?: string;
  rightTitle?: string;
  dataSource?: DataItem[];
  groups?: MemberSelectGroup[];
  groupMode?: boolean;
  onCancel?: () => any;
  onChange?: (value: string[]) => any;
  onSubmit?: (value?: UserId[], reset?: () => any) => any;
  onVisibleChange?: (visible: boolean) => any;
}
interface S {
  modalVisible: boolean;
  selectedKeys: string[];
  dataSource: DataItem[];
}

function mapDataSource(user: User, disabledKeys: UserId[], value: UserId[]) {
  const disabled = disabledKeys!.indexOf(user._id) !== -1;

  return {
    disabled,
    key: user._id,
    title: user._id,
    checked: disabled || value!.indexOf(user._id) !== -1,
  };
}

class AddMember extends React.PureComponent<P, S> {
  static defaultProps = {
    visible: false,
    disabledKeys: [],
    modalTitle: '添加成员',
    leftTitle: '请选择成员',
    rightTitle: '已选列表',
    onCancel() {
      this.setState({ modalVisible: false });
    },
    onChange(selectedKeys: string[]) {
      this.setState({ selectedKeys });
    },
    onVisibleChange(visible: boolean) {
      this.setState({ modalVisible: visible });
    }
  };

  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {
      modalVisible: nextProps.visible,
    };

    const { value, users, disabledKeys } = nextProps;

    if (value && !isEqual(value, prevState.selectedKeys)) {
      nextState.selectedKeys = value.map(it => it + '');
    }

    if (!nextProps.visible) {
      nextState.selectedKeys = [];
    }

    nextState.dataSource = users.map(user => mapDataSource(user, disabledKeys!, nextProps.value || []));

    return nextState;
  }

  constructor(props: P) {
    super(props);
    const { value, users, disabledKeys } = props;

    this.state = {
      modalVisible: props.visible,
      selectedKeys: (value || [])!.map(it => it + ''),
      dataSource: users.map(user => mapDataSource(user, disabledKeys!, value || [])),
    };
  }

  @bind
  closeModal() {
    this.props.onCancel && this.props.onCancel.call(this);
  }

  @bind
  onChange(selectedKeys: string[]) {
    this.props.onChange && this.props.onChange.call(this, selectedKeys);
  }

  @bind
  renderMember(record: DataItem) {
    const { users } = this.props;

    return <HocUserCard userInfo={findUserById(users, record.key)!} className={styles['user-card']} />;
  }

  @bind
  reset() {
    this.setState({ selectedKeys: [] });
  }

  @bind
  async handleSubmit() {
    const { onSubmit } = this.props;
    let result;
    if (onSubmit) {
      result = await onSubmit(this.state.selectedKeys, this.reset);
    }

    return result;
  }

  @bind
  filterOption(filterValue: any, record: DataItem) {
    const { key } = record;
    const { users } = this.props;
    const userInfo = findUserById(users, key);

    return filterUser(filterValue, userInfo);
  }

  render() {
    const { modalVisible, dataSource, selectedKeys } = this.state;
    const { leftTitle, rightTitle, modalTitle, groupMode, groups } = this.props;

    return (
      <BasicModal
        width={648}
        title={modalTitle}
        visible={modalVisible}
        closeModal={this.closeModal}
        onOk={this.handleSubmit}
      >
        {
          <>
            {this.props.children || null}
            <SelectMemberPanel
              groups={groups}
              groupMode={groupMode}
              leftTitle={leftTitle}
              rightTitle={rightTitle}
              dataSource={this.props.dataSource || dataSource}
              render={this.renderMember}
              filterOption={this.filterOption}
              selectedKeys={selectedKeys}
              onSelectedKeysChange={this.onChange}
            />
          </>
        }
      </BasicModal>
    );
  }
}

export default AddMember;
