import * as React from 'react';
import { get } from 'lodash';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getOrgName, getUserName, getClosetAttrVal } from '@chipcoo/fe-utils';

import styles from './index.module.less';
// import store, { RootState } from 'src/store';
import { RootState } from 'src/store';
import urlConfig from 'src/config/url';
import { IOrgDetail } from 'src/models/org/interface';
import { compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';
// import { syncSocket } from 'src/socket';
// import { SubscribeResult } from 'src/services/socket';

interface OwnProps {
  orgId: string;
  enterOrg: (orgId: string) => void;
}
interface StateProps {
  orgData: IOrgDetail;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {}

const { staticUrl } = urlConfig;
const defaultCover = `${staticUrl}/default/bg-group/bg-group-01.jpg`;

class OrgCard extends React.PureComponent<P, S> {
  // private subscription: SubscribeResult;

  // async componentDidMount() {
  //   const { orgId } = this.props;
  //   this.subscription = await syncSocket.subscribe(
  //     { name: 'organization', id: orgId },
  //     this.handleSyncOrg
  //   );
  // }

  // componentWillUnmount() {
  //   this.subscription && this.subscription.unsubscribe();
  // }

  // handleSyncOrg(data: ISyncSocketData) {
  //   store.dispatch.org.syncOrg(data);
  // }

  handleEnterOrg = (e) => {
    const orgId = getClosetAttrVal(e.currentTarget, 'data-id');

    if (!orgId) { return; }

    this.props.enterOrg(orgId);
  }

  handleGoToSetting = (e) => {
    e.stopPropagation();

    const orgId = getClosetAttrVal(e.currentTarget, 'data-id');

    if (!orgId) { return; }

    const { history } = this.props;
    const path = compilePath({ name: ROUTE_NAMES.ORG_SETTINGS, params: { id: orgId } });
    history.push(path);
  }

  render() {
    const { orgData, orgId } = this.props;
    const { info, verify } = orgData;
    const { avatar } = info!;
    const orgName = getOrgName(orgData);
    const ownerName = getUserName(orgData.userId!);
    const src = avatar ? `${staticUrl}${avatar}` : defaultCover;

    return (
      <div
        className={styles.orgCardWrap}
        data-id={orgId}
        onClick={this.handleEnterOrg}
      >
        <img className={styles.cover} src={src}/>
        <div className={styles.mask} />
        <header className={styles.header}>
          <div className="info">
            {verify === 'ACCEPTED' && <i className="c-icon icon-qi" />}
            <p className="name ellipsis">{orgName}</p>
          </div>
          <Tooltip title="组织设置" mouseEnterDelay={0.3}>
            <i className="c-icon icon-setting" onClick={this.handleGoToSetting} />
          </Tooltip>
        </header>
        <div className={styles.content}>
          <i className="c-icon icon-account" />
          <p className="owner">{ownerName}</p>
        </div>
      </div>
    );
  }
}

const mapState = ({ org: { orgList: { byId } } }: RootState, props: P) => ({
  orgData: get(byId, `${props.orgId}`)
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(
  withRouter(OrgCard)
);
