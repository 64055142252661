import * as React from 'react';
import classnames from 'classnames';
import { Tooltip, Drawer, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';

import style from './index.module.less';

import SiderFriend from '../SiderFriend';
import OrderModal from './OrderModal';
import BreadcrumbNav from 'src/layouts/BreadcrumbNav';

interface Props extends RouteComponentProps {
}
interface State {
  siderFriendVisible: boolean;
  visible: boolean; // 我要下单modal visible
  fullWindow: boolean;
}

class GlobalHeader extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      siderFriendVisible: false,
      visible: false,
      fullWindow: false,
    };
  }

  showSiderFriendDrawer = () => this.setState({ siderFriendVisible: true });

  closeSiderFriendDrawer = () => this.setState({ siderFriendVisible: false });

  onClick = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => this.setState({ visible: false });

  onRequestNormalWindow = () => this.setState({ fullWindow: false });

  onRequestFullWindow = () => this.setState({ fullWindow: true });

  render() {
    const {
      siderFriendVisible,
      visible,
      fullWindow,
    } = this.state;
    const { location } = this.props;
    const orgId = location?.pathname?.split('/org/')?.[1];

    return (
      <div className={classnames(style.header, 'flex')}>
        <BreadcrumbNav />

        <div className={classnames(style.right, 'cell fixed')}>
          {
            orgId && (
              <Button type="primary" onClick={this.onClick}>我要下单</Button>
            )
          }

          <Tooltip title="好友" mouseEnterDelay={0.5}>
            <span className={style.rightItem} onClick={this.showSiderFriendDrawer}>
              <i className="icon-left c-icon" />
            </span>
          </Tooltip>
        </div>

        <FlexModal
          title="我要下单"
          width={1024}
          visible={visible}
          onCancel={this.handleCancel}
          fullWindow={fullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
        >
          <OrderModal closeModal={this.handleCancel} />
        </FlexModal>

        <Drawer
          placement="right"
          visible={siderFriendVisible}
          onClose={this.closeSiderFriendDrawer}
          destroyOnClose={true}
          className={style.friendDrawer}
        >
          <SiderFriend onClose={this.closeSiderFriendDrawer} />
        </Drawer>
      </div>
    );
  }
}

export default withRouter(GlobalHeader);
