export function setBrowser() {
  if (!document) return;
  const ua = navigator.userAgent;
  const tridentVer = /Trident\/([\d\.]+)/i.exec(ua);
  const isIE11 = (document as any).documentMode && tridentVer && parseInt(tridentVer[1], 10) === 7;
  const classList: string[] = [];
  if (isIE11) {
    classList.push('ie', 'ie11');
  }

  const isWechat = /MicroMessenger/i.test(ua);

  if (isWechat) {
    classList.push('wechat');
    if (/WindowsWechat/i.test(ua)) {
      classList.push('windows-wechat');
    }
  }

  classList.forEach(clazz => document.documentElement.classList.add(clazz));
}

export default setBrowser;
