import { createModel } from '@rematch/core';
import { produce } from 'immer';
import { getFactoryName } from '@chipcoo/fe-utils';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { handleDataSource } from './handle';
import { mpwApi } from 'src/services/net';

const initialState: State = {
  list: [],
  allList: [],
  filters: {},
  factoryList: [],
  factoryCount: 0,
  yearsByFactorys: [],
  years: [],
  months: {}
};

export default createModel({
  state: initialState,
  // selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        const { list, allList, years, months } = payload;

        draft.list = list;
        draft.allList = allList;
        draft.years = years;
        draft.months = months;
      });
    },
    setFactoryList(state: State, payload: any) {
      return produce(state, draft => {
        const { factoryList, years } = payload;

        draft.factoryList = factoryList;
        draft.yearsByFactorys = years;
      });
    },
    setFilters(state: State, payload: any) {
      return produce(state, draft => {
        draft.filters = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList (params: any) {
      const { data } = await mpwApi.listBus(params);
      const handleData = handleDataSource(data, params?.filters);

      this.setList(handleData);
      this.setFilters(params?.filters);
    },

    async getFactoryList(params: any) {
      const { data } = await mpwApi.listAllFactory({ limit: 200 });

      const newData = data.map(it => ({ ...it, name: getFactoryName(it) }));

      this.setFactoryList({ factoryList: newData });
    },

    async postCreate(data: any) {
      //
    },

    async patchModify() {
      //
    },

    async getTableItemDetail(id: string) {
      //
    },
  })
});
