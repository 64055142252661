/**
 * 消息删除按钮，一条消息发出超过3分钟以后就无法删除了
 */
import * as React from 'react';
import ms from 'ms';
import EventBus from 'eventbusjs';
import * as moment from 'moment';

import styles from './index.module.less';

interface P {
  createdAt: string;
  deleteMessage: (e: any) => void;
}
interface S {
  showButton: boolean;
}

let timer: null | any = null;

const limit = ms('3m');
const eventName = 'chat-update-delete-btn-status';
// 与当前本机时间进行比较，看是否超过了3分钟
const checkExceedLimitTime = (createdAt: string) => {
  const messageTime = moment(createdAt);
  const curTime = moment();

  return curTime.diff(messageTime) > limit;
};

class DeletedButton extends React.PureComponent<P, S> {
  static clear() {
    // 如果没有订阅的，清掉定时器
    if (!EventBus.hasEventListener(eventName)) {
      clearInterval(timer);
      timer = null;
    }
  }

  constructor(props: P) {
    super(props);

    const { createdAt } = props;

    this.state = {
      showButton: !checkExceedLimitTime(createdAt)
    };
  }

  componentDidMount() {
    const { showButton } = this.state;

    if (!showButton) { return; }

    // 如果该条消息没有超过限定时间，且无定时器，那么开启定时器
    if (timer === null) {
      // 每隔5s升级一下时间戳
      timer = setInterval(
        () => {
          EventBus.dispatch(eventName);
          console.log(eventName);
        },
        5000
      );
    }

    // 开始监听事件
    EventBus.addEventListener(eventName, this.updateBtnStatus);
  }

  componentWillUnmount() {
    EventBus.removeEventListener(eventName, this.updateBtnStatus);

    DeletedButton.clear();
  }

  updateBtnStatus = () => {
    const { showButton } = this.state;

    // 如果已经不显示删除按钮了，那么直接清掉事件
    if (!showButton) {
      EventBus.removeEventListener(eventName, this.updateBtnStatus);

      DeletedButton.clear();

      return;
    }

    const { createdAt } = this.props;
    const newStatus = !checkExceedLimitTime(createdAt);

    if (newStatus !== showButton) {
      this.setState({ showButton: false });
    }
  }

  render() {
    const { showButton } = this.state;

    if (!showButton) { return null; }

    const { deleteMessage } = this.props;

    return (
      <div className={styles.actionItem} onClick={deleteMessage}>
        <i className="c-icon icon-trash" />
      </div>
    );
  }
}

export default DeletedButton;
