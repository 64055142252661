/**
 * 通知中心左侧的筛选面板
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { bind } from 'lodash-decorators';
import classnames from 'classnames';

import styles from './index.module.less';

import FilterCategory from './FilterCategory';
import { Filter, NotificationModel } from 'src/models/notification/interface';
import { getInitialFilter } from 'src/models/notification';
import { RootState, RootDispatch } from 'src/store';

interface OwnProps {}
interface StateProps {
  filter: Filter;
  statics: NotificationModel['statics'];
  current: NotificationModel['current'];
  fetched: boolean;
}
interface DispatchProps {
  updateFilter: (filter: Partial<Filter>) => any;
}
type P = OwnProps & StateProps & DispatchProps;

interface S {
  slowFetched: boolean;
}

const readFilter = [
  // { value: true, text: '已读' },
  { value: false, text: '未读' },
];

const todoFilter = [
  { value: true, text: '待办' },
];

const categoryFilter = [
  { value: 'cooperation', text: '协作' },
  { value: 'console', text: '组织管理' },
  { value: 'ops', text: '运营管理' },
  { value: 'rnd', text: '研发管理' },
  { value: 'product', text: '产品管理' }
];

const initialFilterObj = getInitialFilter();

class FilterPanel extends React.PureComponent<P, S> {
  private timer;
  private stall;
  constructor(props: P) {
    super(props);
    this.state = { slowFetched: props.fetched };
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.fetched && !prevProps.fetched && !this.stall) {
      this.stall = true;
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.stall = false;
        this.setState({ slowFetched: true });
      }, 200 + Math.floor(Math.random() * 100));
    }

    if (!this.props.fetched && prevProps.fetched) {
      clearTimeout(this.timer);
      this.stall = false;
      this.setState({ slowFetched: false });
    }
  }

  @bind
  resetFilter() {
    this.props.updateFilter({ ...getInitialFilter() });
  }

  @bind
  updateFilter(type: string, value: any) {
    const { updateFilter } = this.props;
    updateFilter({
      [type]: value
    });
  }

  @bind
  renderCurrentCount() {
    const { current: { statics: { count } } } = this.props;
    const { slowFetched } = this.state;
    let text = '';

    if (!slowFetched) {
      text = '...';
    } else {
      text = `${count === void 0 ? 0 : count}`;
    }

    return text;
  }

  get isFilterEnabled() {
    const { filter } = this.props;

    return !isEqual(filter, initialFilterObj);
  }

  render() {
    const { filter, current: { statics: { todo, unread } } } = this.props;
    const { slowFetched } = this.state;

    // const read = count - unread;

    return (
      <div className={`${styles.filterPanelWrapper}`}>
        <div className={styles.header}>
          <p>{this.isFilterEnabled ? '筛选结果' : '所有消息'}</p>
          <p className={styles.currentCount}>{this.renderCurrentCount()}</p>
        </div>

        <div className={styles.filterContent}>
          <div className={styles.filterCategory}>
            <FilterCategory
              count={slowFetched ? unread : '...'}
              type="isRead"
              value={filter.isRead}
              defaultValue={initialFilterObj.isRead}
              options={readFilter}
              onChange={this.updateFilter}
            />
          </div>

          <div className={styles.filterCategory}>
            <FilterCategory
              count={slowFetched ? todo : '...'}
              type="isTodo"
              value={filter.isTodo}
              defaultValue={initialFilterObj.isTodo}
              options={todoFilter}
              onChange={this.updateFilter}
            />
          </div>

          <div className={styles.filterCategory}>
            <FilterCategory
              type="app"
              value={filter.app}
              defaultValue={initialFilterObj.app}
              options={categoryFilter}
              onChange={this.updateFilter}
            />
          </div>
        </div>

        <div
          className={classnames(styles.clearFilter, !this.isFilterEnabled && 'hidden')}
          onClick={this.resetFilter}
        >
          清空筛选条件
        </div>
      </div>
    );
  }
}

function mapState({ notification: { filter, statics, current, fetched } }: RootState) {
  return {
    filter,
    statics,
    current,
    fetched,
  };
}

const mapDispatch = ({ notification }: RootDispatch) => {
  return {
    updateFilter: notification.updateFilter as any,
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(FilterPanel);
