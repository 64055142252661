/**
 * 表情组件，所有的图片都取自teambition评论框
 */
import * as React from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';

import styles from './index.module.less';

import emojiDictionary from 'src/common/emojiDictionary';

interface P {
  closePopover?: () => void;
  choiceEmoji: (content: string) => void;
}
interface S {
  page: number;
}

type EmojiItem = { name: string; src: string }[];
const emojiArr: EmojiItem[] = [[], [], []];

// 把所有的字典换成对应的路径
Object.entries(emojiDictionary).forEach(([key, val], index) => {
  const _i = Math.floor(index / 32);

  emojiArr[_i].push({ name: key, src: val.src});
});

class EmojiPanel extends React.PureComponent<P, S> {
  private emojiContent: React.ReactNodeArray;

  constructor(props: P) {
    super(props);

    this.state = {
      page: 0
    };

    this.emojiContent = emojiArr.map((ele, index) => (
      <div className={styles.page} key={uniqueId('EMOJI_')}>
        {ele.map(item => {
          const text = `[em:${item.name}]`;

          return (
            <div className={styles.cell} data-content={text} key={text} onClick={this.choiceEmoji}>
              <img src={item.src} alt={text}/>
            </div>
          );
        })}
      </div>
    ));
  }

  choiceEmoji = (e) => {
    const content = e.target.getAttribute('data-content');
    const { choiceEmoji, closePopover } = this.props;

    choiceEmoji(content);
    closePopover!();
  }

  changePage = (page) => {
    this.setState({ page });
  }

  renderFooter = () => {
    const { page } = this.state;

    return new Array(3).fill(null).map((_, index) => (
      <div
        key={index}
        className={styles.switcher}
        onMouseEnter={() => this.changePage(index)}
      >
        <div className={classnames('circle', { active: page === index })} />
      </div>
    ));
  }

  render() {
    const { page } = this.state;

    return (
      <div className={styles.emojiPanelWrapper}>
        <div className={styles.body}>
          {this.emojiContent[page]}
        </div>

        <div className={styles.footer}>
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default EmojiPanel;
