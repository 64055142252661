// 新品FT测试
import * as React from 'react';

class FT extends React.PureComponent {
  render () {
    return (
      <div>FT</div>
    );
  }
}

export default FT;
