import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { IState } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { mpwApi } from 'src/services/net';
import { handleDataList } from './handle';

const initailState: IState = {
  list: [],
  count: 0,
  detail: {},
};

export default createModel({
  state: initailState,
  reducers: {
    setList(state: IState, payload: any) {
      return produce(state, draft => {
        const { data, count } = payload;
        draft.list = handleDataList(data);
        draft.count = count;
      });
    },
    setDetail(data: any) {
      return data;
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList (params: any) {
      const { data } = await mpwApi.getShipmentList(params);

      this.setList(data);
    },
    async postCreate() {
      //
    },

    async patchModify() {
      //
    },

    async getTableItemDetail(id: string) {
      //
    },
  })
});
