import _, { get, flattenDeep } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import { getMomentTime, sortWaferId, unSortWaferId } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';

const baseBins = [
  { binType: '无', amount: 0 },
  { binType: 'bin1', amount: 0 },
  { binType: 'bin2', amount: 0 },
  { binType: 'bin3', amount: 0 },
  { binType: 'bin4', amount: 0 },
  { binType: 'bin5', amount: 0 },
  { binType: 'bin6', amount: 0 },
  { binType: 'bin7', amount: 0 },
  { binType: 'bin8', amount: 0 },
];

// 出库申请列表
export const handleStockList = (data) => data.reduce(
  (prev, cur) => {
    let dieNumber, stampNumber, amount, materialNumber, waferQuantity;
    const {
      _id,
      createdAt,
      productModel,
      product,
      warehouse,
      stockInfos,
      status,
      productName,
      ...restData
    } = cur;
    materialNumber = (stockInfos.map(it => it.materialNumber)).join(', ');
    if (productModel === EnumProductModel.assemblyProduct) {
      const binArray = _.flatten(stockInfos.map(it => it.bins)) as number[];
      amount = _.sum(binArray);
      stampNumber = stockInfos.map(it => it.stampNumber).join(', ');
      waferQuantity = binArray.filter(it => it !== 0).length;
      dieNumber = stockInfos.map(it => it.dieNumber).join(', ');
    } else {
      const slicesArray: any[] = _.flatten(stockInfos.map(it => it.slices)) || [] as any[];
      const flattenDpSlices: number[] = _.flattenDeep(slicesArray.map(it => it.desc && unSortWaferId(it.desc)));

      waferQuantity = productModel === EnumProductModel.dpProduct ? 
        get(flattenDpSlices, 'length') : _.get(slicesArray, 'length');
      const amountArray = stockInfos.map(it => it.amount);
      amount = _.sum(amountArray);

      dieNumber = stockInfos.map(it => it.dieNumber).join(', ');
    }

    const curData = {
      ...restData,
      amount,
      _id,
      key: _id,
      stampNumber,
      dieNumber,
      materialNumber,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      productModel: optionsGroup.productModel.options![productModel],
      status,
      statusText: optionsGroup.stockOutApplyStatus.options![status],
      product: productName,
      warehouse: warehouse?.name,
      waferQuantity,
    };
    prev.push(curData);
    return prev;
  },
  [] as any[]
);

// 出库申请详情
export const handleStockDetail = async (data) => {

  const {
    product,
    productModel,
    material,
    stockInfos,
    warehouse,
    materialName,
    ...restData
  } = data;
  // let stockSlice: any[] = [];
  let materialInfos: any[] = [], packingMethod;
  // 包装方式有些直接给中文有些给id，后面全部换成中文文本
  packingMethod = product?.packing?.find(it => it._id === stockInfos[0]?.packingMethod)?.packingMethod ||
    stockInfos[0]?.packingMethod;
  if (productModel === EnumProductModel.assemblyProduct) {
    materialInfos = stockInfos.map(item => {
      let children: any[] = [];
      const { bins, stock } = item;
      bins.forEach((val, index) => {
        if (val !== 0) {
          children.push({
            ...baseBins[index],
            amount: val,
            key: stock + '_' + baseBins[index].binType,
          });
        }
      });
      const binType = children.map(it => it.binType).join(',');
      return {
        ...item,
        key: stock,
        warehouse: get(warehouse, 'name'),
        materialName,
        binType,
        packingMethod,
        children,
      };
    });
  } else {
    stockInfos.forEach(item => {
      const { stock, slices } = item;
      const stockInAt = get(stock, 'stockInAt');
      let waferIds;
      if (productModel === EnumProductModel.dpProduct) {
        waferIds = flattenDeep(slices.map(it => unSortWaferId(it.desc)));
      } else {
        waferIds = slices.map(it => it .waferId);
      }
      const good = _.sum(slices.map(it => it.good));
      const bad = _.sum(slices.map(it => it.bad));
      const amount = good + bad;
      materialInfos.push({
        ...item,
        key: stock?._id,
        good,
        bad,
        amount,
        packingMethod,
        materialName,
        stockInAt: getMomentTime(stockInAt, 'YYYY-MM-DD HH:mm:ss'),
        warehouse: get(warehouse, 'name'),
        attributes: (get(slices[0], 'attributes') & 1) === 1 ? '蓝膜' : '否',
        waferIds: sortWaferId(waferIds),
        children: slices.map(it => {
          // tslint:disable-next-line: no-shadowed-variable
          const { waferId, good, bad, amount, desc } = it;
          return {
            key: stock + '_' + waferId,
            waferIds: productModel === EnumProductModel.dpProduct ? desc : waferId,
            good,
            bad,
            amount,
          };
        }),
      });
    });
  }
  // 出库申请详细信息
  return {
    ...restData,
    productModel,
    materialInfos,
    packingMethod,
  };
};

// 用于批量出库-片号选择列表数据处理
export const handleStockOutSelection = (data) => {
  const handleData = (data || []).reduce(
    (prev, cur) => {
      const {
        _id,
        attributes,
        stockInAt,
        name,
        slices,
        warehouse,
        materialName,
        ...restData
      } = cur;

      let [
        waferIds,
        newSlices,
        warehouseName,
        available,
        unavailable,
        amount,
      ]: any[] = [
        [],
        [],
        undefined,
        undefined,
        0,
        0,
        0
      ];

      if (slices) {
        newSlices = slices.map(s => {
          const { waferId, good, bad } = s;

          waferIds.push(waferId);

          return {
            key: _id + '_' + waferId,
            waferIds: waferId,
            good,
            bad,
            amount: good + bad,
          };
        });
      }
      warehouseName = _.get(warehouse, 'name');

      if (slices?.length === 1) {
        // 单片
        available = slices[0].good;
        unavailable = slices[0].bad;
        amount = slices[0].amount;
      } else {
        // 多片
        available = _.sum(slices.map(it => it.good));
        unavailable = _.sum(slices.map(it => it.bad));
        amount = _.sum(slices.map(it => it.amount));
      }

      const curData = {
        ...restData,
        _id,
        key: _id,
        good: available,
        bad: unavailable,
        amount,
        attributes: (attributes & 1) === 1 ? '蓝膜' : '否',
        stockInAt: getMomentTime(stockInAt, 'YYYY-MM-DD HH:mm:ss'),
        product: (name.split('/'))[0],
        materialName: materialName ? materialName : name,
        children: newSlices.length > 1 && newSlices,
        waferIds: sortWaferId(waferIds),
        slices: newSlices,
        warehouse: warehouseName,
      };

      prev.push(curData);

      return prev;
    },
    [] as any[]
  );

  // todo 需不需要保留原始数据
  // return {
  //   handleData,
  //   originData: data,
  // };
  return handleData;
};
