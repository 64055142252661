import React, {  PureComponent } from 'react';
import { Collapse } from 'antd';
import _, { isEmpty, get, cloneDeep } from 'lodash';
import { EnumProductModel, EnumStockAttributes } from '@chipcoo/constant';
import { CardTableV2 } from '@chipcoo/hanayo';
import { sortWaferId, slices2Number } from '@chipcoo/fe-utils';

import { getTableColumns } from './getTableColumns';
import { ModalType } from '../../index';

const { EnhancedTable } = CardTableV2;
const { Panel } = Collapse;

interface IProps {
  status: ModalType;
  waferIds: number[];
  value?: any;
  onChange?: (value: any) => void;
  setFieldsValue?: (obj: Obj) => void;
  productModel: EnumProductModel;
  isAttributes?: EnumStockAttributes;
  uniwaferAmount?: number;
  isCpDie?: boolean;
}

interface IState {
  productInfo: any;
  dataSource: any[];
}

class Slices extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      productInfo: {},
      dataSource: [],
    };
  }

  componentDidMount() {

    this.handleDataSource(this.props.waferIds);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { waferIds } = this.props;

    if (!_.isEqual(waferIds, prevProps.waferIds)) {
      this.handleDataSource(waferIds);
    }
  }

  handleSetFields = (val) => {
    const { setFieldsValue, onChange, waferIds } = this.props;
    const newWaferIds = val?.map(it => parseInt(it.waferId, 10)) || waferIds;
    const { available, unavailable, amount, waferAmount } = slices2Number(val);
    setFieldsValue && setFieldsValue({
      available,
      unavailable,
      amount,
      waferAmount,
      waferIds: sortWaferId(newWaferIds)
    });

    this.setState({
      dataSource: val
    }, () => {
      onChange && onChange(val);
    });
  }

  getNewDataBySaveRow = (rowData: any) => {
    const { dataSource } = this.state;
    const { amount, good } = rowData;
    const newRowData = { ...rowData, bad: parseInt(amount, 10) - parseInt(good, 10) };

    let newDataSource = cloneDeep(dataSource);
    const index = newDataSource.findIndex(it => it.waferId === rowData.waferId);
    newDataSource.splice(index, 1, newRowData);

    this.handleSetFields(newDataSource);
  }

  getAmount = () => {
    const { value, uniwaferAmount } = this.props;
    let amount: number | undefined;
    if (uniwaferAmount) {
      amount = uniwaferAmount;
    } else if (!isEmpty(value)) {
      amount = get(value, '[0].amount');
    }

    return amount;
  }

  handleDataSource = (propsWaferIds: number[]) => {
    const { value, status } = this.props;
    const { dataSource } = this.state;
    const maxAmount = this.getAmount();

    let newData: any[] = [];
    const getNewWaferIdData = (item) => {
      const good = value?.find(it => it?.waferId === item)?.good || maxAmount;
      return {
        good,
        waferId: item.toString(),
        key: `NEW_TEMP_INDEX_${item - 1}`,
        amount: maxAmount,
      };
    };
    // 编辑、重新提交
    if (!isEmpty(propsWaferIds)) {
      // 新增片号
      if (!isEmpty(dataSource)) {
        const getIntWaferId = (item) => parseInt(item.waferId, 10);
        const sliceWaferIds = value.map(item => getIntWaferId(item));
        const delWaferIds = _.difference(sliceWaferIds, propsWaferIds);

        const _value = _.filter(value, item => !delWaferIds.includes(getIntWaferId(item)));

        newData = propsWaferIds.map(waferId => {
          const waferIdData = _value.find(item => getIntWaferId(item) === waferId);

          if (waferIdData) {
            // 已存在的片号
            return waferIdData;
          } else {
            // 新建的片号
            return getNewWaferIdData(waferId);
          }
        });
      } else if (!isEmpty(value)) {
        if (!get(value, '[0].amount')) {
          newData = value.map(it => ({ ...it, amount: maxAmount}));
        } else {
          newData = value;
        }
      } else {
        newData = propsWaferIds.map(item => getNewWaferIdData(item));
      }
    } else if (status === 'preview') {
      newData = value;
    }

    this.handleSetFields(newData);
  }

  render() {
    const { status, isCpDie, isAttributes } = this.props;
    const showYield = (isCpDie && isAttributes !== EnumStockAttributes.blueTape) ? true : false;
    const editable = status !== 'preview' && showYield;
    const { dataSource } = this.state;
    const tableColumns = getTableColumns({showYield, editable});

    return (
      <Collapse defaultActiveKey={['1']}>
        <Panel header="片号数量详情" key="1">
          <EnhancedTable
            getTableData={() => {
              //
            }}
            dataSource={dataSource}
            columns={tableColumns}
            size="small"
            getNewDataBySaveRow={this.getNewDataBySaveRow}
            showPagination={false}
            tableName="SLICES_TABLE"
          />
        </Panel>
      </Collapse>
    );
  }
}

// tslint:disable-next-line:max-file-line-count
export default Slices;
