/**
 * 房间列表的消息，当有草稿的时候，这里显示草稿，当没有草稿的时候，这里显示该房间的最后一条消息
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { getLocalStorage } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { RootState } from 'src/store';
import { getKey } from 'src/components/SaveDraft';
import { Room, RoomLastMessage } from 'src/models/im/chatRoomList.type';
import { CHAT_INPUT_AUTO_SAVE_NAMESPACE } from 'src/pages/IM/ChatPanel';

import { HandledMessageContent } from 'src/components';
import { findNameById } from 'src/utils/user';
import { State } from 'src/models/session';
import { pickAllUsers } from 'src/models/user/selectors';

interface OwnProps {
  roomData: Room;
  defaultMessage: string;
  lastMessage?: RoomLastMessage;
}
interface StateProps {
  session: State;
  users: User[];
  currentActiveRoom: string | undefined;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;
interface S {
  draft: any;
  currentActiveRoom: string | undefined;
}

const getChatInputDraft = (roomId: string) => {
  const key = getKey(`${CHAT_INPUT_AUTO_SAVE_NAMESPACE}${roomId}`);
  const result = getLocalStorage(key);

  try {
    return result && JSON.parse(result).value;
  } catch (e) {
    console.error(e);
    return result;
  }
};

class RoomCardMessage extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const { currentActiveRoom, roomData: { _id } } = nextProps;

    if (currentActiveRoom !== prevState.currentActiveRoom) {
      return {
        currentActiveRoom,
        draft: getChatInputDraft(_id),
      };
    }

    return null;
  }

  constructor(props: P) {
    super(props);

    const { currentActiveRoom, roomData } = props;
    const { _id } = roomData;

    this.state = {
      draft: getChatInputDraft(_id),
      currentActiveRoom: currentActiveRoom
    };
  }

  // 从lastMessage里取，或者从房间用户里取
  get users(): User[] {
    const { lastMessage } = this.props;
    const { users: roomUsers } = this.props;
    let users = lastMessage && lastMessage.atUsers || [];
    users = uniqBy(users.concat(roomUsers), '_id');
    return users;
  }

  findUserName = (id: string): string => findNameById(this.users, id);

  render() {
    const { draft } = this.state;
    const { defaultMessage, roomData, currentActiveRoom, session } = this.props;
    const { _id: roomId } = roomData;
    const myUserId = session.profile!._id;

    if (!draft || roomId === currentActiveRoom) {
      return (
        <HandledMessageContent
          myUserId={myUserId}
          findNameFn={this.findUserName}
          content={defaultMessage}
          showTextAfterBr={false}
        />
      );
    }

    return (
      <div className={styles.draft}>
        <p className="tip">[草稿]</p>
        <HandledMessageContent
          myUserId={myUserId}
          findNameFn={this.findUserName}
          content={draft}
          showTextAfterBr={false}
        />
      </div>
    );
  }
}

const mapState = ({ chatRoomList, session, user }: RootState) => ({
  currentActiveRoom: chatRoomList.currentActiveRoom,
  // Fixme
  users: pickAllUsers(user),
  // users: (chatRoomList.roomList.byId[props.roomData._id] as GroupRoom).users || [],
  session,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(RoomCardMessage);
