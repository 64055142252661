/**
 * 委外需求订单-包装方式
 */
import React, { memo } from 'react';
import { SelectWithOption } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  formStatus: FormStatus;
  productInfo: any;
}

function PackingMethodInDemandOrderModal(props: Props) {
  const { formStatus, value, productInfo, onChange } = props;
  let stateValue = value;
  let options = [];

  if (productInfo) {
    const { packing } = productInfo;

    const pmArray = (packing || []).map(p => ({
      key: p.packingMethod || p.markMode, label: p.packingMethod || p.markMode
    })).filter(p => p.key);

    options = options.concat(pmArray);

    // 以偷懒方式解决更改产品名称清空包装方式
    const option = options.find((o: any) => o.key === stateValue);

    if (option) {
      //
    } else {
      stateValue = undefined;
    }
  }

  if (formStatus === 'preview') {
    return <span>{stateValue}</span>;
  }

  return (
    <SelectWithOption
      options={options}
      value={stateValue}
      onChange={onChange}
    />
  );
}

export default memo(PackingMethodInDemandOrderModal);
