import React from 'react';
import GGEditor, { FlowProps } from 'gg-editor';
import styles from './index.module.less';
import CraftRouteEditor from './CraftRouteEditor';
import ContextProvider from './ContextProvider';
import RegisterShapes from './RegisterShapes';
import RegisterBehaviors from './RegisterBehaviours';

// Disable track
GGEditor.setTrackable(false);

interface Props {
  mode: 'new' | 'edit';
  orgId: string;
  ref?: React.RefObject<any>;
  data?: FlowProps['data'];
  children?: React.ReactNode;
}

class CraftRoute extends React.Component<Props> {
  render() {
    const { data, children, mode, orgId } = this.props;
    return (
      <ContextProvider orgId={orgId} mode={mode}>
        <GGEditor className={styles.editor}>
          <RegisterShapes />
          <RegisterBehaviors />
          <CraftRouteEditor data={data}/>
          {children}
        </GGEditor>
      </ContextProvider>
    );
  }
}

export {
  RegisterShapes,
};

export default CraftRoute;
