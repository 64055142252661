import React, { PureComponent } from 'react';
import { Popover } from 'antd';
import classnames from 'classnames';
import { bind } from 'lodash-decorators';
import { noop } from '@chipcoo/fe-utils';

import MemberDetailPopover from './MemberDetail';

import { HocUserCard } from 'src/components';

interface Props {
  userInfo: User;
  popoverCardClassName?: string;
}
interface States {
  visible: boolean;
}

export class MemberCard extends PureComponent<Props, States> {
  state = {
    visible: false,
  };

  componentWillUnmount() {
    this.setState = noop;
  }

  @bind
  handleVisibleChange(visible: boolean) {
    this.setState({ visible });
  }

  @bind
  close() {
    this.handleVisibleChange(false);
  }

  @bind
  renderPopoverContent() {
    const { userInfo } = this.props;
    return <MemberDetailPopover userInfo={userInfo} closePopover={this.close} />;
  }

  render() {
    const { userInfo } = this.props;
    const { visible } = this.state;
    return (
      <Popover
        trigger="click"
        visible={visible}
        placement="leftTop"
        content={this.renderPopoverContent()}
        onVisibleChange={this.handleVisibleChange}
        overlayClassName={classnames('tree-member-popover-card', this.props.popoverCardClassName)}
      >
        <div >
          <HocUserCard className="user-card " userInfo={userInfo} />
        </div>
      </Popover>
    );
  }
}

export default MemberCard;
