import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface';
import { TableModelCommonEffects } from 'src/models/type';
import { qcApi } from 'src/services/net';
import { handleAnalysisList, handleAnalysisDetail } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setAnalysisList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setAnalysisDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcApi.getAnalysisList(params);
      const handleData = handleAnalysisList(data);

      this.setAnalysisList({
        data: handleData,
        count: data?.count
      });
    },
    async getTableItemDetail(_id: string) {
      const { data } = await qcApi.getAnalysisById(_id);
      const handleData = handleAnalysisDetail(data);

      this.setAnalysisDetail(handleData);
    },
    async getAnalysisDetail(payload: any) {
      const { data } = await qcApi.getAnalysisDetail(payload);
      const handleData = handleAnalysisDetail(data);

      this.setAnalysisDetail(handleData);

      return handleData;
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
    async deleteAnalysis(_id: string) {
      await qcApi.deleteAnalysis(_id);
    }
  })
});
