import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { get } from 'lodash';
import { connect } from 'react-redux';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { StageList as StageListData } from 'src/models/task/interface';

import StageHeader from './StageHeader';
import StageContent from './StageContent';
import { ThreeBounceLoading } from 'src/components';

interface OwnProps {
  index: number;
  stageId: string;
}
interface StateProps {
  stageListData: StageListData;
}
interface DispatchProps {
  getTasks: (stageId: string) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class StageList extends React.PureComponent<P, S> {
  private initialStage = false;

  componentDidMount() {
    const { getTasks, stageId, index } = this.props;

    setTimeout(() => getTasks(stageId), index * 50);
  }

  render() {
    const { stageListData, index, stageId } = this.props;
    const { name, totalCount, taskIds } = stageListData;

    if (!this.initialStage && taskIds !== void 0) {
      this.initialStage = true;
    }

    if (!this.initialStage) {
      return (
        <div className={styles.container}>
          <div className={styles.spin}>
            <ThreeBounceLoading style={{ margin: '8px 0' }} />
          </div>
        </div>
      );
    }

    return (
      <Draggable draggableId={stageId} index={index}>
        {(provided, snapshot) => (
          <div
            className={styles.container}
            data-id={stageId}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div {...provided.dragHandleProps}>
              <StageHeader totalCount={totalCount} name={name} />
            </div>
            <StageContent
              listId={stageId}
              listType="QUOTE"
              taskIds={taskIds!}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

const mapState = ({ task: { stageLists: { byId } } }: RootState, props: P) => ({
  stageListData: get(byId, `${props.stageId}`)
});
const mapDispatch = ({ task }: RootDispatch) => ({
  getTasks: task.getTaskGroupUnderStage
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(StageList);
