import * as React from 'react';
import { CardTableV2 } from '@chipcoo/hanayo';
import { EnumProcessClass } from '@chipcoo/constant';

import { getTableColumns } from '../../../DemandOrderMaterialModal/getTableColumns';

interface Props {
  dataSource: any[];
  classes?: EnumProcessClass;
  setFieldsValue?: (obj: Object, callback?: Function) => void;
}

const { EnhancedTable } = CardTableV2;

class SelectedTableData extends React.PureComponent<Props> {

  componentWillUnmount(): void {
    const { setFieldsValue } = this.props;

    setFieldsValue && setFieldsValue({
      selectedData: undefined,
      waferQuantity: undefined,
      quantity: undefined,
    });
  }

  render () {
    const { dataSource } = this.props;
    const columns = getTableColumns({ doubleCol: true });
    // 选择的物料显示页面不需要显示checkbox
    columns.shift();

    return (
      <EnhancedTable
        getTableData={() => {
          //
        }}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 2250 }}
        showPagination={false}
        size="small"
        className="small-table-no-border"
      />
    );
  }
}

export default SelectedTableData;
