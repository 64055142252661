import React, { FC } from 'react';
import { Col } from 'antd';
import style from './index.module.less';

interface IProps {
  label: string;
  value: string;
}

const MaterialInfos: FC<IProps> = props => {
  const { label, value} = props;

  return (
    <Col span={8} style={{ padding: '0 12px'}}>
      <div className={style.formItem}>
        <span className="form-label">{label} : </span>
        <span className="form-content">{value ? value : '--'}</span>
      </div>
    </Col>
  );
};

export default MaterialInfos;
