import { isEqual } from 'lodash';

import { ICraftRouteFullRoute, ICraftRouteSourceItem, IProductProcessDetail } from 'src/services/net/craftRoute';

function handleSources(sources: ICraftRouteSourceItem[]) {
  return sources.map(it => ({ type: it.type, id: it.id }))
    .sort((prev, cur) => prev.id.localeCompare(cur.id));
}

export function getRawCraftRouteData(craftRoute: ICraftRouteFullRoute): Required<any> {
  const { _id, material: { _id: material }, sources, priority } = craftRoute;

  return {
    _id,
    material,
    sources: sources.map(source => {
      return {
        type: source.type,
        id: source.id,
        amount: source.amount,
      };
    }),
    priority: priority || 0
  };
}

/**
 * 判断是否是同样的对象产品（移除amount）
 * @param sources1
 * @param sources2
 */
export function isSameSource(sources1: ICraftRouteSourceItem[], sources2: ICraftRouteSourceItem[]) {
  return isEqual(handleSources(sources1), handleSources(sources2));
}

export function isValidProductProcess(
  productProcess: IProductProcessDetail, craftRoute: ICraftRouteFullRoute
) {
  const { sources: rawSources } = getRawCraftRouteData(craftRoute);

  return isSameSource(rawSources, productProcess.craftRoute.sources);
}
