import React from 'react';
import moment from 'moment';

interface Option {
  openModal: (params: unknown) => void;
}

export const getTableColumns = (option: Option) => {
  const { openModal } = option;

  return [
    {
      title: '产品名',
      key: 'customerName',
      dataIndex: 'customerName',
    },
    {
      title: '芯翼产品名',
      key: 'chipcooName',
      dataIndex: 'chipcooName',
    },
    {
      title: '封装形式',
      key: 'packageType',
      dataIndex: 'packageType',
    },
    {
      title: '芯片名',
      key: 'customerDieName',
      dataIndex: 'customerDieName',
    },
    {
      title: '芯翼芯片名',
      key: 'chipcooDieName',
      dataIndex: 'chipcooDieName',
    },
    {
      title: (<a onClick={() => openModal({ type: 'order', modalTitle: '订单总数' })}>订单总数</a>),
      key: 'orderQuantity',
      dataIndex: 'orderQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'order',
          productId: record.key,
          productModel: record.productModel,
          modalTitle: `${customerName}(${chipcooName})订单总数`
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (<a onClick={() => openModal({ type: 'unStockOut', modalTitle: '未交付总数' })}>未交付总数</a>),
      key: 'unStockOutQuantity',
      dataIndex: 'unStockOutQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'unStockOut',
          productId: record.key,
          modalTitle: `${customerName}(${chipcooName})未交付总数`
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (<a onClick={() => openModal({ type: 'manufacture', modalTitle: '已制未交付' })}>已制未交付</a>),
      key: 'manufactureQuantity',
      dataIndex: 'manufactureQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'manufacture',
          modalTitle: `${customerName}(${chipcooName})已制未交付订单`,
          productId: record?.productId,
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (
        <a
          onClick={() => openModal({
            type: 'stockOutApply',
            modalTitle: '已申请未交付',
            status: ['CREATED', 'APPROVED']
          })}
        >
          已申请未交付
        </a>
      ),
      key: 'stockOutApplyQuantity',
      dataIndex: 'stockOutApplyQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'stockOutApply',
          modalTitle: `${customerName}(${chipcooName})已申请未交付订单`,
          product: record?.productId,
          status: ['CREATED', 'APPROVED']
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (
        <a
          onClick={() => openModal({
            type: 'stockOutApply',
            modalTitle: '近7天已交付',
            status: ['DELIVERY'],
            createdAtAfter: moment().add(-7, 'day').format()
          })}
        >
          近7天已交付
        </a>
      ),
      key: 'stockOutIn7DQuantity',
      dataIndex: 'stockOutIn7DQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'stockOutApply',
          modalTitle: `${customerName}(${chipcooName})近7天已交付订单`,
          product: record?.productId,
          status: ['DELIVERY'],
          createdAtAfter: moment().add(-7, 'day').format()
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (
        <a
          onClick={() => openModal({
            type: 'stockOutApply',
            modalTitle: '近30天已交付',
            status: ['DELIVERY'],
            createdAtAfter: moment().add(-30, 'day').format()
          })}
        >
          近30天已交付
        </a>
      ),
      key: 'stockOutIn30DQuantity',
      dataIndex: 'stockOutIn30DQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'stockOutApply',
          modalTitle: `${customerName}(${chipcooName})近30天已交付订单`,
          product: record?.productId,
          status: ['DELIVERY'],
          createdAtAfter: moment().add(-30, 'day').format()
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
    {
      title: (
        <a
          onClick={() => openModal({
            type: 'stockOutApply',
            modalTitle: '所有已交付',
            status: ['DELIVERY']
          })}
        >
          所有已交付
        </a>
      ),
      key: 'stockOutQuantity',
      dataIndex: 'stockOutQuantity',
      render: (text, record) => {
        if (!text) return text;

        const { customerName, chipcooName } = record;

        const params = {
          type: 'stockOutApply',
          modalTitle: `${customerName}(${chipcooName})所有已交付订单`,
          product: record?.productId,
          status: ['DELIVERY'],
        };

        return <a onClick={() => openModal(params)}>{text}</a>;
      }
    },
  ];
};
