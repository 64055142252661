import React, { PureComponent } from 'react';
import { RegisterCommand } from 'gg-editor';
import { get } from 'lodash';
import { DEST_CRAFT_ROUTE_NODE, BASE_CRAFT_ROUTE_GROUP } from './constant';

const noDest = item => get(item, 'model.shape') !== DEST_CRAFT_ROUTE_NODE;

type GGEditorToFix = any;

function isCommandEnable(editor: GGEditorToFix, minSelectedNum: number = 1) {
  const selected = editor.getCurrentPage().getSelected();
  const noDestNode = selected.every(noDest);

  const noRefNode = true;

  return selected.length >= minSelectedNum && noDestNode && noRefNode;
}

const safeDeleteConfig = {
  enable(editor: GGEditorToFix) {
    const items = this.getDeleteItems(editor);
    return items.length > 0 && items.every(noDest);
  },
  shortcutCodes: ['Delete', 'Backspace'],
};

const safeCopyConfig = {
  enable(editor: GGEditorToFix) {
    return isCommandEnable(editor);
  },
};

const safeAddGroupConfig = {
  enable(editor: GGEditorToFix) {
    return isCommandEnable(editor, 2);
  },
  method(editor: GGEditorToFix) {
    editor.getCurrentPage().addGroup({ id: this.addGroupId, shape: BASE_CRAFT_ROUTE_GROUP, label: '合封' });
  },
  shortcutCodes: [['ctrlKey', 'g']],
};

export class RegisterCraftRouteCommands extends PureComponent {
  render() {
    return (
      <>
        <RegisterCommand name="safe-delete" config={safeDeleteConfig} extend="delete" />
        <RegisterCommand name="safe-copy" config={safeCopyConfig} extend="copy" />
        <RegisterCommand name="safe-add-group" config={safeAddGroupConfig} extend="addGroup" />
      </>
    );
  }
}

export default RegisterCraftRouteCommands;
