import React, { ComponentType, PureComponent } from 'react';
import { Radio, Tag, Icon } from 'antd';
import { get, isEmpty, findIndex } from 'lodash';
import { Modal, FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
import log from 'loglevel';

import { connectDecorator, Actions, RootState } from 'src/store';
import { AddressParam } from 'src/models/address/interface';
import styles from './index.module.less';

import CreateAddress from './CreateAddress';

interface OwnProps {
  globalStatus: string;
  value?: AddressParam;
  onChange?: (data: AddressParam) => void;
}
interface StateProps {
  addressList: AddressParam[];
}
interface DispatchProps {
  getAddressList: () => void;
  postAddress: (data: AddressParam) => void;
  patchAddress: (data: AddressParam) => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  isShowMore: boolean; // 控制更多地址显示
  shortData: AddressParam[]; // 显示在订单里的5条地址, 实际是从addressList里slice
  checkedData: AddressParam | undefined; // 当前选中的地址
  addressId: string;
  modalVisible: boolean;
  zIndex: number;
}

const RadioGroup = Radio.Group;

@connectDecorator<StateProps, DispatchProps, OwnProps>(
  ({ address: { addressList } }: RootState) => ({
    addressList
  }),
  ({ address: { getAddressList, postAddress, patchAddress } }: Actions) => ({
    getAddressList, postAddress, patchAddress
  })
)
class AddressList extends PureComponent<Props, State> {
  private unmounted = false;
  private createAddressRef: typeof CreateAddress;

  constructor(props: Props) {
    super(props);

    this.state = {
      isShowMore: false,
      shortData: [],
      checkedData: void 0,
      addressId: '',
      modalVisible: false,
      zIndex: 0,
    };
  }

  async componentDidMount() {
    const { globalStatus, getAddressList } = this.props;
    setTimeout(async () => {
      globalStatus !== 'preview' && await getAddressList();
      const { addressList, value } = this.props;

      if (this.unmounted) {
        return;
      }
      if (value) {
        this.setCheckedDefaultAddress(value);
      } else {
        this.setDefaultAddressList(addressList);
      }
    }, 22);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  openModal = () => this.setState({ modalVisible: true, zIndex: FlexModalManager.getNextZIndex() });

  closeModal = () => this.setState({ modalVisible: false });

  // 设置初始默认的5条地址数据
  setDefaultAddressList = (data) => {
    this.setState({ shortData: data.slice(0, 5) });
  }

  // 用来将更多地址里选中项提到第一行
  calculateAddressIndex = (dataList, changeVal) => {
    const idx = findIndex(dataList, changeVal);
    dataList.splice(idx, 1);
    dataList.unshift(changeVal);

    const checkedData = dataList.find(item => item._id === changeVal._id);
    const shortData = dataList.slice(0, 5);

    return { shortData, checkedData };
  }

  // 获取地址信息
  renderAddress = (val, text?) => (
    <>
      {text && <span className={styles.addressText}>{text}</span>}
      {['province', 'city', 'distinct', 'detail'].map(name => (
        get(val, name) && <span key={name} className={styles[name]}>{val[name]}</span>
      ))}
    </>
  )

  // 获取收件人
  renderReceiver = (val, text?) => (
    <>
      {text && <span className={styles.addressText}>{text}</span>}
      <span className={styles.receiver}>({val.receiver}{!text && ' 收'})</span>
      <span>{val.phone}</span>
    </>
  )

  // 获取选择的地址文本
  getAddressText = (val: AddressParam) => (
    <>
      {this.renderAddress(val, '寄送至: ')}
      {this.renderReceiver(val)}
    </>
  )

  // 设置有默认地址的默认地址列表
  setCheckedDefaultAddress = (e) => {
    const { addressList, onChange } = this.props;
    const val = get(e, 'target.value') || e;
    const { shortData, checkedData } = this.calculateAddressIndex(addressList, val);

    this.setState({
      checkedData,
      shortData
    });
    onChange && onChange(val);
  }

  // 编辑地址
  handleEditAddress = (e, _id) => {
    e.stopPropagation();

    this.setState({ addressId: _id, modalVisible: true });
  }

  renderRadioList = (data: AddressParam[]) => (
    data.map((item, index) => (
      <RadioGroup
        key={index}
        className={styles.radioGroup}
        onChange={this.setCheckedDefaultAddress}
        value={this.state.checkedData}
      >
        <Radio value={item}>
          {
            !isEmpty(item?.labels) && (
              <Tag className={styles.tag} color="#69c0ff">
                {item.labels[0]}
              </Tag>
            )
          }
          {this.renderAddress(item)}
          {this.renderReceiver(item)}
        </Radio>
        <a className="edit-btn" onClick={(e) => this.handleEditAddress(e, item._id)}>编辑</a>
      </RadioGroup>
    ))
  )

  handleAfterClose = () => this.setState({ addressId: '' });

  getCreateAddressRef = ref => this.createAddressRef = ref;

  handleSubmitAddress = async () => {
    const ref = this.createAddressRef;

    if (!ref) {
      return;
    }

    const value = ref.getSubmitValue();

    if (!value) {
      return;
    }

    try {
      const { patchAddress, postAddress } = this.props;
      const { addressId, shortData, checkedData } = this.state;
      let checked = checkedData;

      if (addressId) {
        await patchAddress(value);

        const newShortData = shortData.map(d => {
          if (d._id === addressId) {
            // 如果修改的地址已被选中，那么需要更新checkedData
            if (addressId === get(checkedData, '_id')) {
              checked = value;
            }

            return { ...value, key: addressId };
          } else {
            return d;
          }
        });

        this.setState({
          shortData: newShortData,
          addressId: '',
          checkedData: get(newShortData, '0._id') === get(checked, '_id')
            ? get(newShortData, '0') : checked
        });
      } else {
        const addr = await postAddress(value);

        this.setCheckedDefaultAddress(addr);
      }

      this.setState({ modalVisible: false });
    } catch (e) {
      log.error(e);
    }
  }

  render () {
    const { globalStatus, value, addressList } = this.props;
    const { isShowMore, shortData, checkedData, addressId, modalVisible } = this.state;

    if (globalStatus === 'preview') {
      const reactNode = get(value, 'province')
        ? (
          <React.Fragment>
            {this.getAddressText(value!)}
          </React.Fragment>
        )
        : <div className="text-center">暂未选择任何地址</div>;

      return reactNode;
    }

    return (
      <div className={styles.addressList}>
        <div className="address-list-error-border">
          {
            get(checkedData, 'province') ? (
              <div className={styles.addressResult}>
                {this.getAddressText(checkedData!)}
              </div>
            ) : null
          }

          {!get(addressList, 'length') && <div className="text-center">暂无地址</div>}

          {isShowMore ? this.renderRadioList(addressList) : this.renderRadioList(shortData)}

          <div className={styles.lastLine}>
            {
              addressList.length > 5 && (
                <div
                  className="toggle"
                  onClick={() => this.setState({ isShowMore: !isShowMore })}
                >
                  <a>{isShowMore ? '收起更多地址' : '更多地址'}</a>
                </div>
              )
            }

            {
              (addressList.length <= 5 || isShowMore) && (
                <div className="toggle" onClick={this.openModal}>
                  <a><Icon type="plus" />添加新地址</a>
                </div>
              )}
          </div>
        </div>

        <Modal
          width={600}
          zIndex={FlexModalManager.getNextZIndex()}
          visible={modalVisible}
          onCancel={this.closeModal}
          title={addressId ? '编辑地址' : '添加新地址'}
          onAfterClose={this.handleAfterClose}
          onOk={this.handleSubmitAddress}
        >
          <CreateAddress
            addressId={addressId}
            wrappedComponentRef={this.getCreateAddressRef}
          />
        </Modal>

      </div>
    );
  }
}

export default AddressList as ComponentType<OwnProps>;
// tslint:disable:max-file-line-count
