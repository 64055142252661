import React, { ComponentType } from 'react';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { IEditableColumnProps } from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';

import { getTableColumns } from './getTableColumns';
import { getPreviewFormConfig } from './formConfig';
import { Actions, connectDecorator, RootState } from 'src/store';

interface OwnProps {}
interface StateProps {
  list: any[];
  count: number;
  detail: {};
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (_id: string) => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  tableLoading: boolean;
  visible: boolean;
  fullWindow: boolean;
}

const { EnhancedTable } = CardTableV2;

@connectDecorator(
  ({ abnormality: { list, count, detail } }: RootState) => ({ list, count, detail }),
  ({ abnormality: { getTableList, getTableItemDetail } }: Actions) => ({
    getTableList, getTableItemDetail
  })
)
class RecordTable extends React.PureComponent<Props, State> {
  private tableColumns: IEditableColumnProps<any>[] = [];
  private tableName = 'ABNORMAL_RECORD_LIST';

  constructor(props: Props) {
    super(props);

    this.state = {
      tableLoading: false,
      visible: false,
      fullWindow: true,
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
    });
  }

  openDetailModal = async (_id: string) => {
    await this.props.getTableItemDetail(_id);

    this.setState({ visible: true });
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;
    const queryParams: any = {
      ...formData,
      ...extraParams,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  onRequestNormalWindow = () => this.setState({ fullWindow: false });
  onRequestFullWindow = () => this.setState({ fullWindow: true });
  onCloseModal = () => this.setState({ visible: false });

  render () {
    const { list, count, detail } = this.props;
    const { tableLoading, visible, fullWindow } = this.state;

    return (
      <div className="wrap-content">
        <CardTableV2 card={{ title: '异常记录列表' }}>
          <EnhancedTable
            getTableData={this.getTableData}
            columns={this.tableColumns}
            total={count}
            dataSource={list}
            clickMention={true}
            resizable={true}
            tableName={this.tableName}
            tableLoading={tableLoading}
          />
        </CardTableV2>

        <FlexModal
          title="异常记录详情"
          visible={visible}
          fullWindow={fullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
          onCancel={this.onCloseModal}
          footer={null}
        >
          <FormCreator
            formWrapper={false}
            formConfig={getPreviewFormConfig()}
            formData={detail}
            globalStatus="preview"
          />
        </FlexModal>
      </div>
    );
  }
}

export default RecordTable as ComponentType<OwnProps>;
