import { compose, withHandlers, withState } from 'recompose';

type WithLoading = {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

const withLoading = (initialLoading: boolean = true) => compose(
  withState('loading', 'setLoading', initialLoading),
  withHandlers({
    startLoading: ({ setLoading }) => () => setLoading(true),
    stopLoading: ({ setLoading }) => () => setLoading(false),
  })
);

export { WithLoading };
export default withLoading;
