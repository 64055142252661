/**
 * Created by marvin on 18/8/15.
 */

import * as React from 'react';

import style from './index.module.less';

export default () => (
  <div className={style.stepOne}>
    <iframe
      frameBorder="0"
      scrolling="auto"
      src="//www.chipcoo.com/pages_org_certificate_protocol.html"
    />
  </div>
);
