import axios from 'axios';
import { handleError } from './interceptors/error';
import { handleRequest } from './interceptors/request';

export const api = axios.create({
  baseURL: '/api',
  timeout: 20 * 1000
});

export const requestInterceptor = api.interceptors.request.use(handleRequest, handleError);
export const responseInterceptor = api.interceptors.response.use(resp => resp, handleError);

export default api;
