import { createModel } from '@rematch/core';
import { produce } from 'immer';
import { TableModelCommonEffects } from 'src/models/type';
import { inventoryApi } from 'src/services/net';
import { IState } from './interface';
import {
  handleStockList,
  handleFormData,
} from './handle';
// import { EnumProductModel } from '@chipcoo/constant';

const initialState: IState = {
  list: [],
  count: 0,
  detail: {},
  statistics: {},
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: IState, respData: any) {
      return produce(state, draft => {
        const { data } = respData;

        draft.count = data.count;
        draft.list = handleStockList(data.data);
      });
    },
    setDetail(state: IState, respData: any) {
      return produce(state, draft => {
        // draft.detail = respData;
      });
    },
  },
  effects: (): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await inventoryApi.getCanStockOutList(params);

      this.setList({ data });
    },
    async postCreate(data: any) {
      const formData = handleFormData(data);

      await inventoryApi.postStockOutApply(formData);
    },
    async getTableItemDetail(id: string) {
      //
    },
    async patchModify(data: any) {
      //
    },
  })
});
