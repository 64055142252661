import * as React from 'react';
import { Row, Col, Card, Icon, Divider } from 'antd';
import { noop } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import cardGrids from './cardGrid';
import orderCardGrids from './CardConfig/order';
import { compilePath, getCurrentRoute } from 'src/components/HoneyRouter';

import { Ellipsis } from 'src/components';
import stockInCardGrids from './CardConfig/stock-in-apply';
import stockOutCardGrids from './CardConfig/stock-out-apply';

interface Props {
  closeModal?: () => void;
}

interface State {
  step: number;
  type: string;
  title: string;
}

class OrderModal extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      step: 0,
      type: '',
      title: '',
    };
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  handleClickCard = (e, it) => {
    const { closeModal } = this.props;
    const { step } = this.state;

    if (step === 0) {
      this.setState({ step: 1, type: it?.type, title: it?.title });

      e.preventDefault();
    }

    if (step === 1) {
      const { history, match: { params } } = getCurrentRoute()!;

      history.push(
        compilePath({ name: it.route, params }),
        { ...it?.state, autoOpenModal: true }
        );

      this.resetState();
      closeModal && closeModal();
    }
  }

  resetState = () => {
    this.setState({ step: 0, type: '', title: '' });
  }

  render () {
    const { step, title, type } = this.state;
    let cardConfig: any[] = cardGrids;

    if (step === 1) {
      if (type === 'order') cardConfig = orderCardGrids;

      if (type === 'stock-in-apply') cardConfig = stockInCardGrids;

      if (type === 'stock-out-apply') cardConfig = stockOutCardGrids;
    }

    return (
      <div className={styles.cardGridList}>
        {
          step === 1 && (
            <div>
              <span
                className={styles.goBack}
                onClick={() => this.setState({ step: 0, type: '', title: '' })}
              >
                <Icon type="left" />
                {title}
              </span>
              <Divider style={{ margin: '8px 0 16px' }} />
            </div>
          )
        }

        <Row gutter={24}>
          {
            cardConfig?.map(it => (
              <Col
                key={it?.key}
                span={8}
                onClick={(e) => this.handleClickCard(e, it)}
              >
                <Card bordered={false}>
                  <Card.Meta
                    title={it?.title}
                    description={(
                      <Ellipsis lines={3} >
                        {it?.description}
                      </Ellipsis>
                    )}
                  />
                </Card>
              </Col>
            ))
          }
        </Row>
      </div>
    );
  }
}

export default OrderModal;
