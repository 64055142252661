/**
 * 聊天的消息面板
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import classnames from 'classnames';

import InfiniteScroll from 'react-infinite-scroller';

import { RootState, RootDispatch } from 'src/store';
import styles from './index.module.less';
import { GetMessageHistoryParams } from 'src/models/im/chatPanel.type';

import MessageItem from './MessageItem';
import { ThreeBounceLoading } from 'src/components';

interface OwnProps {
  roomId: string;
}
interface StateProps {
  messageIds: string[];
  hasMoreChatData: boolean;
  hasInitMessageData: boolean;
}
interface DispatchProps {
  getMessageHistory: (params: GetMessageHistoryParams) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {
  loadingMore: boolean;
}

class MessagePanel extends React.PureComponent<P, S> {
  private endPlaceholder: HTMLElement | null;

  constructor(props: P) {
    super(props);

    this.state = {
      loadingMore: false
    };
  }

  componentDidMount() {
    this.handleGetChatData();
    this.endPlaceholder = document.querySelector(`.${styles['message-panel-wrapper']} .end`);
  }

  componentDidUpdate(prevProps: P) {
    const { messageIds: prevMessageIds } = prevProps;
    const { messageIds, hasInitMessageData } = this.props;

    // 首次加载时候的第一次推送一定会滚动到最下面
    if (!prevProps.hasInitMessageData && hasInitMessageData) {
      this.scrollToBottom();
      return;
    }

    // 检查update后的最后一条id，如果相同，表明是向上加载更多数据，此时不需要向下滚动
    if (messageIds[messageIds.length - 1] === prevMessageIds[prevMessageIds.length - 1]) {
      return;
    }

    // TODO 非首次进行加载的时候，检查当前window是否具有焦点，如果失去焦点，就不滚动
    // TODO 还需要检查当前是否有向上滚动一点，如果有滚动，也是显示有新消息而不是直接滚下去
    if (
      messageIds.length !== prevMessageIds.length
    ) {
      this.scrollToBottom();
    }
  }

  handleGetChatData = async (lastMessageId?: string) => {
    const { roomId, getMessageHistory } = this.props;
    const params = { roomId, ...(lastMessageId ? {lastId: lastMessageId} : {}) };

    await getMessageHistory(params);
  }

  scrollToBottom = (isSmooth?: boolean) => {
    if (!this.endPlaceholder) { return; }

    const option: { [key: string]: string } = { block: 'end' };

    isSmooth && (option.behavior = 'smooth');

    this.endPlaceholder.scrollIntoView(option);
  }

  handleInfiniteOnLoad = async () => {
    const { loadingMore } = this.state;

    if (loadingMore) { return; }

    const { messageIds } = this.props;
    const lastMessageId = messageIds[0];

    this.setState({ loadingMore: true });

    await this.handleGetChatData(lastMessageId);

    this.setState({ loadingMore: false });
  }

  renderContent = () => {
    const { hasInitMessageData, messageIds } = this.props;

    // 首次进入该房间后获取数据的加载动画
    if (!hasInitMessageData) { return <ThreeBounceLoading />; }

    return messageIds.map((id, index) => {
      const prevMessageId = messageIds[index - 1];

      return <MessageItem messageId={id} prevMessageId={prevMessageId} key={id} />;
    });
  }

  render() {
    const { hasMoreChatData } = this.props;
    const { loadingMore } = this.state;

    return (
      <div className={classnames('y-scroll', 'thin-scroll', styles.messagePanelWrapper)}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!loadingMore && hasMoreChatData}
          useWindow={false}
          threshold={200}
          isReverse={true}
        >
          {loadingMore && hasMoreChatData && (<div className={styles['message-spin-container']}><Spin /></div>)}

          {this.renderContent()}
        </InfiniteScroll>

        <div className="end" />
      </div>
    );
  }
}

const mapState = ({ chatPanel: { chatData } }: RootState) => ({
  hasInitMessageData: chatData.isInitial,
  messageIds: chatData.allIds,
  hasMoreChatData: chatData.hasMore
});
const mapDispatch = ({ chatPanel }: RootDispatch) => ({
  getMessageHistory: chatPanel.getMessageHistory,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(MessagePanel);
