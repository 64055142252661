// import React from 'react';
import {
  FormConfig,
  FormItemConfig,
  NumberFormItemConfig,
  InputFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import { numberRangeValidatorByFormData } from './validator';

const binType = (): InputFormItemConfig => ({
  label: 'Bin类型',
  type: 'input',
  dataKey: 'binType',
  renderType: 'preview'
});

const amount: NumberFormItemConfig = {
  label: 'Bin数量',
  type: 'number',
  dataKey: 'amount',
  precision: 0,
  required: true,
  validate: numberRangeValidatorByFormData()
};

const waferId = (): InputFormItemConfig => ({
  label: '片号',
  type: 'input',
  dataKey: 'waferId',
  renderType: 'preview'
});

const good: NumberFormItemConfig = {
  label: '良品',
  type: 'number',
  dataKey: 'good',
  precision: 0,
  required: true,
  validate: numberRangeValidatorByFormData()
};

const bad: NumberFormItemConfig = {
  label: '不良品',
  type: 'number',
  dataKey: 'bad',
  precision: 0,
  positive: null,
  required: true,
  validate: numberRangeValidatorByFormData()
};

// const isFrozen: InputFormItemConfig = {
//   type: 'input',
//   dataKey: 'isFrozen',
//   colClassName: 'hidden-form-item'
// };

export function getFormConfig(isAssembly: boolean): FormConfig {
  let config: FormItemConfig[] = [];

  if (isAssembly) {
    config = config.concat([binType(), amount]);
  } else {
    config = config.concat([waferId(), good, bad]);
  }

  return {
    rowGroupConfig: [
      ...config
    ].filter(it => typeof it !== 'boolean') as FormItemConfig[],
  };
}
