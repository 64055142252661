import { isProd } from './';

const logNamespace = {
  all: !isProd,
  Default: !isProd,
};

type LogArgs = string | [any, ...any[]];

const log: any = function(logArgs: LogArgs, namespace: string = 'Default') {
  if (!Array.isArray(logArgs)) {
    logArgs = [logArgs]; // tslint:disable-line
  }

  if (typeof logArgs[0] === 'string') {
    logArgs[0] = `${namespace}: ${logArgs[0]}`;
  }
  
  logNamespace.all && logNamespace[namespace] && console.log.apply(null, logArgs);
};

log.disable = (namespace) => logNamespace[namespace] = false;
log.enable = (namespace) => logNamespace[namespace] = true;

log.disableAll = () => log.disable('all');
log.enableAll = () => log.enable('all');

function createLogFn(namespace: string) {
  const fn: any = function(logArgs: LogArgs) {
    return log(logArgs, namespace);
  };

  logNamespace[namespace] = true;
  fn.disable = () => logNamespace[namespace] = false;
  fn.enable = () => logNamespace[namespace] = true;

  return fn;
}

const logDataCache = createLogFn('DataLoad');

export {
  log,
  createLogFn,
  logDataCache,
};
