import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { SelectProps } from 'antd/lib/select';
import { SelectWithOption } from '@chipcoo/hanayo';

import styles from './index.module.less';

interface IProps extends Omit<SelectProps, 'onChange' | 'value'> {
  tableData: any[];
  type: 'bins' | 'waferIds';
  slices: any[];
  value?: any;
  onChange?: (value: any) => void;
}

interface IState {
  options: any[];
}

// mock数据
const optionsBins = [
  { key: '无', label: '无' },
  { key: 'Bin1', label: 'Bin1' },
  { key: 'Bin2', label: 'Bin2' },
  { key: 'Bin3', label: 'Bin3' },
  { key: 'Bin4', label: 'Bin4' },
  { key: 'Bin5', label: 'Bin5' },
  { key: 'Bin6', label: 'Bin6' },
  { key: 'Bin7', label: 'Bin7' },
  { key: 'Bin8', label: 'Bin8' },
];

const optionsWaferIds = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
];

const SelectTableBinsOrWaferIds: FC<IProps> = props => {
  // const defaultProps = {
  //   placehoolder: '请选择',
  // };
  const { type, tableData, slices, value, size, onChange } = props;

  const [state, setState] = useState<IState> ({
    options: []
  });
  const { options } = state;

  const getOptions = () => {
    let newOptions: any[] = [];

    if (_.isEmpty(tableData) && _.isEmpty(slices)) {
      newOptions = type === 'bins' ? optionsBins : optionsWaferIds;
    } else {
      const forEachOptions = type === 'bins' ? optionsBins : optionsWaferIds;

      forEachOptions.forEach(item => {
        const isTrue = tableData.find(tableItem => {
          return type === 'bins'
            ? tableItem.name === item.key
            : tableItem.waferId.toString() === item.key;
        });

        if (isTrue) {
          //
        } else {
          if (_.isEmpty(slices)) {
            newOptions.push(item);
          } else {
            if (slices.find(s => s.waferId.toString() === item.key)) {
              newOptions.push(item);
            }
          }
        }
      });
    }

    setState({
      options: newOptions,
    });
  };

  useEffect(() => {
    getOptions();
  }, [slices]);

  return (
    <SelectWithOption
      value={value}
      options={options}
      onChange={onChange}
      size={size}
      dropdownClassName={styles.binTypeDropdown}
    />
  );
};

export default SelectTableBinsOrWaferIds;
