import React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';
import { Divider } from 'antd';
import { ModalType } from '../index';
import { EnumStockInApplyStatus, EnumProductModel } from '@chipcoo/constant';

interface Option {
  openModal: (detailId: string, modalType: ModalType) => void;
  productModel: EnumProductModel;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  const { productModel } = option;
  let waferDetail: any[] = [];
  if (productModel !== EnumProductModel.assemblyProduct) {
    waferDetail = [
      {
        dataIndex: 'waferIds',
        key: 'waferIds',
        title: '片号',
        width: 200,
      },
      {
        dataIndex: 'waferAmount',
        key: 'waferAmount',
        title: '片数',
        width: 100,
      },
      {
        dataIndex: 'good',
        key: 'good',
        title: '良品数量',
        width: 150
      },
      {
        dataIndex: 'bad',
        key: 'bad',
        title: '不良品数量',
        width: 150
      },
    ];
  }
  return [
    {
      dataIndex: 'serial',
      key: 'serial',
      title: '入库申请单号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: '申请时间',
      width: 200,
    },
    {
      dataIndex: 'material',
      key: 'material',
      title: '物料名称',
      width: 200
    },
    {
      dataIndex: 'product',
      key: 'product',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'productModel',
      key: 'productModel',
      title: '产品类型',
      width: 150
    },
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
      canCopy: true,
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'stampNumber',
      key: 'stampNumber',
      title: '印章批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'dieName',
      key: 'dieName',
      title: '芯片名称',
      width: 200
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '入库库房',
      width: 200
    },
    ...waferDetail,
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '入库总数量',
      width: 150
    },
    {
      dataIndex: 'logisticsNumber',
      key: 'logisticsNumber',
      title: '物流单号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'remark',
      key: 'remark',
      title: '备注',
      width: 200
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '状态',
      width: 100
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      fixed: 'right',
      width: 150,
      render: (text, record) => {
        const { _id, status } = record;
        const { openModal } = option;
        const Tag: any = 'a';
        let reactNode: any = '';
        if (status === EnumStockInApplyStatus.created) {
          reactNode = (
            <>
              <Divider type="vertical" />
              <Tag onClick={(e) => openModal(_id,  'edit')}>编辑</Tag>
            </>
          );
        } else if (status === EnumStockInApplyStatus.rejected) {
          reactNode = (
            <>
              <Divider type="vertical" />
              <Tag onClick={(e) => openModal(_id,  'reSubmit')}>重新提交</Tag>
            </>
          );
        }

        return (
          <span>
            <Tag onClick={(e) => openModal(_id, 'preview')}>查看</Tag>
            {reactNode}
          </span>
        );
      }
    }
  ];
};
