/**
 * 运营管理-物流管理
 */
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import SiderMenuRoute from '../SiderMenuRoute';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import StockInTable from './StockInTable';
import StockOutTable from './StockOutTable';
import InventoryTable from '../InventoryTable';
import StockOutApplication from './StockOutApplication';
import { getRouterProps } from 'src/components/HoneyRouter';

interface IProps extends RouteComponentProps<any> {}

let menus: RouteMenuItem[] = [];
function fillMenu() {
  menus = [
    {
      title: '物料库存',
      routeName: ROUTE_NAMES.ORG_OPERATION_INVENTORY_MATERIAL,
      routeProps: {
        component: InventoryTable,
        exact: true,
      }
    },
    {
      title: '入库申请',
      routeName: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKIN,
      routeProps: {
        component: StockInTable,
        exact: true,
      }
    },
    {
      title: '出库申请',
      routeName: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUT,
      routeProps: {
        component: StockOutApplication,
        exact: true,
      }
    },
    {
      title: '出库记录',
      routeName: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUTRECORD,
      routeProps: {
        component: StockOutTable,
        exact: true,
      }
    },
  ];
}

setTimeout(fillMenu);

class Logistics extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }
  render() {
    const routeProps = getRouterProps(this.props);

    return (
      <SiderMenuRoute menus={menus} routerProps={routeProps} />
    );
  }
}

export default Logistics;
