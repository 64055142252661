/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { Card, Divider } from 'antd';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import style from './style.module.less';
import { RootState } from 'src/store';
import { Workspace } from 'src/models/workspace/interface';

import { getRouterProps } from 'src/components/HoneyRouter';
import { HoverScrollBarContainer } from 'src/components';
import { Transfer, Dismission, Leave, Archive } from 'src/containers/AdvancedSetting';
import { isEqualUserId } from 'src/utils/user';

interface StateProps {
  profile: User;
  workspaceId: string;
  workspace: Workspace;
}
interface DispatchProps {}
type Props = StateProps & DispatchProps & RouteComponentProps<any>;

class Advance extends React.PureComponent<Props> {
  render () {
    const { workspaceId, workspace, profile } = this.props;
    const routerProps = getRouterProps(this.props);
    const { role } = workspace.members.find(m => isEqualUserId(profile._id, m.user))!;
    if (!role) { return null; }

    return (
      <HoverScrollBarContainer className={style.advanced}>
        <Card bordered={false}>
          {role === 'OWNER' ? (
            <div>
              <Transfer {...routerProps} members={workspace.members} type="workspace" id={workspaceId} />
              <Divider/>
              <Dismission {...routerProps} type="workspace" id={workspaceId} />
              <Divider />
              <Archive {...routerProps} type="workspace" id={workspaceId} />
            </div>
          ) : <Leave {...routerProps} type="workspace" id={workspaceId} />}
        </Card>
      </HoverScrollBarContainer>
    );
  }
}

const mapState = ({ workspace: { workspaceId, list }, session: { profile } }: RootState) => ({ 
  workspace: list.byId[workspaceId],
  workspaceId,
  profile: profile!,
});

export default connect<StateProps, DispatchProps>(mapState)(Advance);
