/**
 * Created by marvin on 18/8/3.
 */

import * as React from 'react';

import styles from './index.module.less';

import Address from './Address';
import BasicInfo from './BasicInfo/index';
import SafetySetting from './SafetySetting';
import { HoverScrollBarContainer } from 'src/components';

interface Props {}
interface State {}

class User extends React.PureComponent<Props, State> {

  render () {
    return (
      <HoverScrollBarContainer className={styles.user}>
        <div>
          <BasicInfo />
          <SafetySetting />
          <Address/>
        </div>
      </HoverScrollBarContainer>
    );
  }
}

export default User;
