import React, { FC, useState, useEffect } from 'react';
import { Alert } from 'antd';
import { isEmpty } from 'lodash';

interface Props {
  value?: any;
  onChange?: (val: any) => void;
  isAnalysisDataDirty: boolean;
  handleAnalysisData: any;
  analysisData?: any[];
  setFieldsValue?: (obj: Obj) => void;
}
interface State {
  alertType: 'success' | 'warning' | 'error'| undefined;
}

const AlertMessage: FC<Props> = props => {
  const {
    value,
    onChange,
    isAnalysisDataDirty,
    handleAnalysisData,
    analysisData,
    setFieldsValue
  } = props;
  const [state, setState] = useState<State>({
    alertType: value || undefined
  });
  const { alertType } = state;

  const setInitialValue = () => {
    setFieldsValue && setFieldsValue(handleAnalysisData);
  };

  const setMessage = () => {
    switch (alertType) {
      case 'success':
        return (
          <span>
            MAP文件解析成功！以下是根据MAP文件解析到的数据，请核对：
          </span>
        );
      case 'error':
        return (
          <span>
            MAP文件解析失败，平台暂未收录该MAP的解析规则。相关文件已记录，我们会尽快实现该文件的自动解析，目前您可以手动填入物料信息，敬请谅解！
          </span>
        );
      case 'warning':
        return (
          <span>
            解析结果已被手动修改，如需返回原先MAP文件解析结果，请点击“<a onClick={() => setInitialValue()}>使用MAP文件解析结果</a>”。
          </span>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    let currentType: any;
    if (analysisData) {
      if (!isEmpty(analysisData)) {
        currentType = isAnalysisDataDirty ? 'warning' : 'success';
        setState(prevState => ({
          ...prevState,
          alertType: currentType
        }));
      } else {
        currentType = 'error';
        setState(prevState => ({
          ...prevState,
          alertType: currentType
        }));
      }
    }

    onChange && onChange(currentType);
  }, [isAnalysisDataDirty, analysisData]);

  useEffect(() => {
    setMessage();
  }, [alertType]);

  if (!alertType) return null;

  return (
    <Alert message={setMessage()} type={alertType} showIcon={true} />
  );
};

export default AlertMessage;
