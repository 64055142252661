import React, { FC, useState, useEffect } from 'react';
import _ from 'lodash';
import { SelectProps } from 'antd/lib/select';
import { SelectWithOption } from '@chipcoo/hanayo';

interface IProps extends Omit<SelectProps, 'onChange' | 'value'> {
  tableData: any[];
  value?: any;
  onChange?: (value: any) => void;
  size: 'default' | 'large' | 'small';
}

interface IState {
  options: any[];
}

const pointOptions = [
  { key: 'NBL', label: 'NBL' },
  { key: 'OD', label: 'OD' },
  { key: 'POLY', label: 'POLY' },
  { key: 'CNT', label: 'CNT' },
  { key: 'OTHER', label: '其他' },
];

const SelectBreakpoint: FC<IProps> = props => {
  const { tableData, value, onChange, size } = props;

  const [state, setState] = useState<IState> ({
    options: []
  });
  const { options } = state;

  const getOptions = () => {
    let newOptions: any[] = [];

    if (_.isEmpty(tableData)) {
      newOptions = pointOptions;
    } else {
      const forEachOptions = pointOptions;

      forEachOptions.forEach(item => {
        const isTrue = tableData.find(tableItem => {
          return tableItem.site === item.key;
        });

        if (isTrue) {
          //
        } else {
          newOptions.push(item);
        }
      });
    }

    setState({
      options: newOptions,
    });
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <SelectWithOption
      value={value}
      options={options}
      onChange={onChange}
      dropdownClassName="breakpoint-type-dropdown"
      size={size}
    />
  );
};

export default SelectBreakpoint;
