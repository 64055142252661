import React from 'react';
import {
  FormConfig,
  CustomFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import InventoryTableList from 'src/pages/Operation/InventoryTable/InventoryTableList';

const processClassFormItem: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'processClass',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const productIdFormItem: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productId',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const materialList: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'materialList',
  colSpan: 24,
  render() {
    const { processClass, productId } = this.formData;

    return (
      <InventoryTableList
        processClass={processClass}
        productId={productId}
      />
    );
  }
};

export const getFormConfig = (): FormConfig => {
  return {
    cardGroupConfig: [
      {
        groupItemConfig: [
          processClassFormItem,
          productIdFormItem,
          materialList
        ]
      }
    ]
  };
};
