import { createModel } from '@rematch/core';
import produce from 'immer';
import { remove } from 'lodash';

import { CommentStore, EnhancedMessage } from './interface';
import { CommentFetchParams, IUComment } from 'src/services/net/comment';
import { universeCommentApi, attachmentApi } from 'src/services/net';
import { getResourceKey } from 'src/services/resource';
import { getNormalizedData } from 'src/utils';
import { RootState } from 'src/store';
import { Key } from './selectors';
import { CommentMessage } from '../message.model';

const initial: CommentStore = {

};

interface CommentWebPushPayload {
  message: string;
  payload: {
    comment: CommentMessage;
    orgId: string;
  };
}

export * from './selectors';

function initialComment() {
  return {
    comments: {
      allIds: [],
      byId: {},
      count: 0,
      hasMore: true,
    },
    attachments: {
      byId: {},
      allIds: [],
      hasMore: true,
      count: 0,
    },
  };
}

function checkInitKey(draft: CommentStore, key: string) {
  if (!draft[key]) {
    draft[key] = initialComment();
  }
}

function update<T extends any = any>(state: CommentStore, payload: { key: string; data: T[] }, KEY: Key) {
  return produce(state, draft => {
    const { key, data } = payload;
    checkInitKey(draft, key);
    const { allIds, byId } = getNormalizedData<T>(data);
    const current = draft[key][KEY];

    allIds.forEach(id => {
      const item = byId[id];
      // @ts-ignore
      item.createdTime = new Date(item.createdAt);

      if (current.byId[id]) {
        Object.assign(current.byId[id], item);
        return;
      } else {
        current.allIds.push(id);
        current.byId[id] = item as any;
      }
    });

    current.allIds.sort((a, b) => current.byId[a].createdTime!.getTime() - current.byId[b].createdTime!.getTime());
  });
}

export default createModel({
  state: initial,
  reducers: {
    updateComment(state: CommentStore, payload: { key: string, data: EnhancedMessage[] }) {
      return update(state, payload, 'comments');
    },
    removeComment(state: CommentStore, payload: { key: string, commentId: string }) {
      return produce(state, draft => {
        const { key, commentId } = payload;
        if (!draft[key]) {
          return;
        }

        const {
          comments: { byId },
          attachments: { allIds: attachmentIds, byId: attachments }
        } = draft[key];
        byId[commentId].message = null;
        byId[commentId].type = 'DELETED';
        const { attachments: removeIds } = byId[commentId];
        byId[commentId].attachments = null;

        if (removeIds && removeIds.length) {
          remove(attachmentIds, id => removeIds.indexOf(id) !== -1);
          removeIds.forEach(attachmentId => {
            Reflect.deleteProperty(attachments, attachmentId);
          });
        }
      });
    },
    updateAttachments(state: CommentStore, payload: { key: string; data: ImAttachment[]; count?: number }) {
      const nextState = update(state, payload, 'attachments');

      return nextState;
    },
    resetComment(state: CommentStore, resource: CommentMessage['resource']) {
      const key = getResourceKey(resource);
      return produce(state, draft => {
        if (draft[key]) {
          draft[key] = initialComment();
        }
      });
    }
  },
  effects: (dispatch) => ({
    async onNewComment(payload: { key: string, data: EnhancedMessage[] }) {
      const { key, data } = payload;
      const attachmentIds = data.reduce((ids, cur) => {
        ids.push(...(cur.attachments || []));
        return ids;
      }, [] as string[]);

      this.updateComment(payload);
      const processing: string[] = [];

      attachmentIds.forEach(id => {
        if (processing.indexOf(id) === -1) {
          processing.push(id);
          attachmentApi.getDetail(id).then(resp => {
            const { data: attachment } = resp;
            if (attachment) {
              this.updateAttachments({ key, data: [attachment] });
            }
          });
        }
      });
    },
    async fetchComments(payload: CommentFetchParams) {
      const { data } = await universeCommentApi.fetchComment(payload);
      this.onNewComment({ key: getResourceKey(payload), data });
      this.fetchCount(payload);
    },
    async fetchAttachments(payload: CommentFetchParams) {
      //
      const { data } = await universeCommentApi.fetchAttachment(payload);
      const key = getResourceKey(payload);
      this.updateAttachments({ key, data: data.data });
    },
    async deleteComment(payload: { commentId: string, resource: IUComment['resource'] }) {
      const { commentId, resource } = payload;
      await universeCommentApi.deleteComment(commentId);
      this.fetchCount(resource);
      this.removeComment({ key: getResourceKey(resource), commentId });
    },
    async createComment(comment: IUComment) {
      const { data } = await universeCommentApi.createComment(comment);

      this.onNewComment({ key: getResourceKey(comment.resource), data: [data] });
      this.fetchCount(comment.resource);
    },
    async fetchCount(resource: IUComment['resource'], rootState: RootState) {
      const { data } = await(universeCommentApi.count(resource));
      const key = getResourceKey(resource);
      const { universeComment } = rootState;
      const comment = universeComment[key];

      if (comment) {
        this.updateState([
          { path: key.concat('.comments.count'), data },
          { path: key.concat('.comments.hasMore'), data: comment.comments.allIds.length < data },
        ]);
      }
    },
    async syncRemoveComment(payload: CommentMessage) {
      this.removeComment({ key: getResourceKey(payload.resource), commentId: payload._id });
    },
    async onReceiveComment(data: CommentWebPushPayload, rootState: RootState) {
      const { payload: { comment, orgId } } = data;
      const { resource } = comment;
      this.onNewComment({ key: getResourceKey(resource), data: [comment] });

      if (orgId === rootState.org.orgId) {
        this.fetchCount(resource);
      }
    },
  })
});
