import moment from 'moment';
import { getMomentTime } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';

export const handleNtoList = (formData: any[]) => {
  const data = formData.map(it => {
    const { _id, tapeOutDate, status } = it;

    return {
      ...it,
      key: _id,
      tapeOutDateText: tapeOutDate && getMomentTime(tapeOutDate),
      statusText: optionsGroup.ntoStatus.options![status]
    };
  });

  return data;
};

export const handleRevNtoList = (formData: any[]) => {
  const data = formData.map(it => {
    const { _id, rtoDate, status } = it;

    return {
      ...it,
      key: _id,
      rtoDateText: rtoDate && getMomentTime(rtoDate),
      statusText: optionsGroup.ntoStatus.options![status]
    };
  });

  return data;
};

export const handleNtoDetail = (detail: any) => {
  return {
    ...detail,
    factory: detail?.factory && { key: detail?.factory, label: detail?.factoryName },
    rtoFrom: detail?.rtoFrom && { key: detail?.rtoFrom?._id, label: detail?.rtoFrom?.name },
    tapeOutDate: detail?.tapeOutDate && moment(detail?.tapeOutDate),
    rtoDate: detail?.rtoDate && moment(detail?.rtoDate),
  };
};

export const handlePostData = (formData: any) => {
  const { factory, rtoFrom } = formData;

  return {
    ...formData,
    factory: factory?.key || factory,
    rtoFrom: rtoFrom?.key || rtoFrom,
  };
};
