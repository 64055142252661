import _ from 'lodash';
import { getMomentTime, sortWaferId } from '@chipcoo/fe-utils';

export const handleDataList = (data) => data.reduce(
  (prev, cur) => {
    const {
      _id,
      createdAt,
      poDate,
      waferIds,
      address,
      ...restData
    } = cur;
    const { province, city, distinct, detail } = address;

    const detailAddress = province + city + distinct + detail;

    const curData = {
      key: _id,
      _id,
      shipDate: getMomentTime(createdAt),
      poDate: getMomentTime(poDate),
      waferIds: sortWaferId(waferIds),
      address: detailAddress,
      ...restData,
    };
    prev.push(curData);

    return prev;
  },
  [] as any[]
);
