/**
 * 每个stage的头
 */
import * as React from 'react';
import { Icon, Tooltip, Dropdown, Menu } from 'antd';

import styles from './index.module.less';

interface P {
  name: string;
  totalCount: number;
}
interface S {}

const menu = (
  <Menu className={styles.dropdown}>
    <Menu.Item>
      <Icon type="plus" />
      在此后添加新列表
    </Menu.Item>
    <Menu.Item>
      <Icon type="delete" />
      本列表所有任务移动到回收站
    </Menu.Item>
  </Menu>
);

class StageHeader extends React.PureComponent<P, S> {
  render() {
    const { name, totalCount } = this.props;

    return (
      <div className={styles.header}>
        <div className="name">
          {name}
          <span>{` · ${totalCount}`}</span>
        </div>
        <div className={styles.menu}>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <Tooltip title="添加或编辑任务列表" mouseEnterDelay={1}>
              <a>
                <Icon type="down-circle-o" />
              </a>
            </Tooltip>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default StageHeader;
