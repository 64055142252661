import React, { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { UploadFileBtn } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { uploadFormAttachment } from 'src/utils';
import { attachmentApi, inventoryApi } from 'src/services/net';

import style from './index.module.less';

interface IProps {
  value?: any;
  status?: FormStatus;
  isNeedHidden?: boolean; // 是否根据MAP文件来解析物料切换到否
  onChange?: (val: any) => void;
  setFieldsValue?: (obj: Obj) => void;
}

interface IState {
  fileList: any[];
  loading: boolean;
}

const CpDieMapping: FC<IProps> = props => {
  const { value, status, onChange, setFieldsValue, isNeedHidden } = props;

  const [state, setState] = useState<IState>({
    fileList: [],
    loading: false,
  });
  const { fileList, loading } = state;

  // 用于删除解析文件和解析文件解析失败时的情况
  const commonObj = {
    dieNumber: undefined,
    waferIdArray: undefined,
  };

  const handleChange = ({ file, fileList: fileListInOnChange }) => {
    onChange && onChange(fileListInOnChange);

    setState(prevState => ({
      ...prevState,
      fileList: fileListInOnChange,
    }));

    if (isEmpty(fileListInOnChange)) { // 删除解析文件的onChange
      setFieldsValue && setFieldsValue({
        ...commonObj,
        analysisData: undefined
      });
    }
  };

  const beforeUpload = async (file) => {
    const data = {
      files: [{
        ...file,
        originFileObj: file
      }],
    };

    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    const handleData = await uploadFormAttachment({
      formData: data,
      attachmentUploadInfo: [{
        path: 'files',
        referenceName: 'stock-in-apply',
        referenceRole: 'cp-map'
      }],
      formDetail: {}
    });

    try {
      const attachmentId = handleData?.files;
      const result = await inventoryApi.postReadArchice({ attachment: attachmentId });
      const mappingData = result?.data;

      let fieldsData = {

      };

      if (mappingData) {
        fieldsData = {
          ...fieldsData,
          analysisData: mappingData
        };
      }

      setFieldsValue && setFieldsValue(fieldsData);

      onChange && onChange(attachmentId);
    } catch (e) {
      console.error(e);

      setFieldsValue && setFieldsValue({analysisData: []});

      setFieldsValue && setFieldsValue(commonObj);
    }

    setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  };

  useEffect(
    () => {
      if (isNeedHidden) {
        setState(prevState => ({
          ...prevState,
          fileList: []
        }));
      }
    },
    [isNeedHidden]
  );

  const uploadProps: any = {
    onChange: handleChange,
    accept: '.zip, .rar',
    beforeUpload,
  };

  return (
    <div className={style.uploadStyle}>
      <Spin spinning={loading} tip="文件解析中...">
        <UploadFileBtn
          {...uploadProps}
          fileList={value || fileList}
          status={status}
          getUrl={fileId => attachmentApi.download({ attachmentId: fileId })}
          getFileDetail={fileId => attachmentApi.getDetail(fileId)}
        />
      </Spin>
    </div>
  );
};

export default CpDieMapping;
