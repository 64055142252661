// tslint:disable:max-file-line-count
import React from 'react';
import {
  FormConfig,
  CustomFormItemConfig,
  ModalMode,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProcessClass, EnumProductModel } from '@chipcoo/constant';

import './index.module.less';
import { demandOrderInfo } from './CardGroupConfigs/demandOrder';
// import { planRepeater } from './CardGroupConfigs/plan';

import PreviewConfig from '../components/PreviewConfig';

export interface Option {
  productModel: EnumProductModel;
  classes?: EnumProcessClass;
}

export const getNewOrderModalConfig = (options: Option): FormConfig => ({
  cardGroupConfig: [
    ...demandOrderInfo(options),
    // planRepeater,
  ]
});

/*************************** 预览表单项 **********************************/
const previewConfig = (mode: ModalMode): CustomFormItemConfig => ({
  type: 'custom',
  colSpan: 24,
  render() {
    return <PreviewConfig mode={mode} formData={this.initialFormData} />;
  }
});

// 预览表单配置
export const getPreviewConfig = (mode: ModalMode) => ({
  cardGroupConfig: [
    {
      groupItemConfig: [
        previewConfig(mode)
      ]
    }
  ]
});
