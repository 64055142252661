import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { uniq } from 'lodash';

import { NotifyFetchParams, MarkTodoParams } from 'src/models/notification/interface';
import { isDev } from 'src/utils';

export interface InvitationPatchData {
  noticeId: string;
  result: 'accept' | 'reject';
}

export default function(api: AxiosInstance) {
  return {
    // 获取通知的统计，包括未读和待办的总数
    fetchStatics(config?: AxiosRequestConfig) {
      return api.get('console/notify/statics', config);
    },
    // 获取通知列表
    list(params: NotifyFetchParams | null, config?: AxiosRequestConfig) {
      const reqParams = Object.assign({}, { limit: 20 }, params);

      return api.get('console/notify/by-time', { params: reqParams, ...config, });
    },
    // 获取单条通知
    getNotiDetail(notifyId: string, config?: AxiosRequestConfig) {
      return api.get(`console/notify/${notifyId}`, config);
    },
    // 删除单条通知
    deleteSingleNoti(notifyId: string, config?: AxiosRequestConfig) {
      return api.delete(`console/notify/${notifyId}`, config);
    },
    // 批量删除通知
    remove(notifyIds: string[] | null, config?: AxiosRequestConfig) {
      return api.delete(`console/notify/batch`, { params: { notifyIds }, ...config });
    },
    // 标记通知已读
    patchAsRead(notifyId: string, config?: AxiosRequestConfig) {
      return api.patch(`console/notify/${notifyId}/read`, config);
    },
    // 批量标记通知已读
    markAsRead(notifyIds: string[], config?: AxiosRequestConfig) {
      return api.patch(`console/notify/batch/read`, { notifyIds }, config);
    },
    // 批量标记/移除待办
    markTodo(data: MarkTodoParams, config?: AxiosRequestConfig) {
      const { notifyId, notifyIds, action } = data;
      const reqData = {
        action,
        notifyIds: (notifyIds || []) as string[],
      };

      if (!notifyId && !notifyIds && isDev) {
        throw new Error('Invalid request data, `notifyId` or `notifyIds` must provided.');
      }

      if (notifyId) {
        reqData.notifyIds.push(notifyId);
      }

      reqData.notifyIds = uniq(reqData.notifyIds);

      return api.patch(`console/notify/batch/todo`, reqData, config);
    },
    // 通过/拒绝组织邀请
    patchInvatation(data: InvitationPatchData, config?: AxiosRequestConfig) {
      return api.patch(`console/invitation`, data, config);
    },
  };
}
