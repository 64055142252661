import { EnumProductModel } from '@chipcoo/constant';

import { ROUTE_NAMES } from 'src/router/normalRouter';

const stockInCardGrids = [
  {
    key: '0',
    title: 'SPW芯片',
    description: 'SPW芯片来料',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.spwDie },
  },
  {
    key: '1',
    title: '封装成品',
    description: '封装成品来料',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.assemblyProduct },
  },
  {
    key: '2',
    title: '磨划成品',
    description: '磨划成品来料',
    target: '',
    route: '', // define only.
    state: { productModel: EnumProductModel.dpProduct },
  },
];

export function fillAppList() {
  stockInCardGrids[0].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKIN;
  stockInCardGrids[1].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKIN;
  stockInCardGrids[2].route = ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKIN;
}

setTimeout(fillAppList);

export default stockInCardGrids;
