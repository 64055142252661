/**
 * 入库申请列表
 */
import React, { ComponentType } from 'react';
import _, { get, omit } from 'lodash';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router-dom';
import log from 'loglevel';
import { noop } from '@chipcoo/fe-utils';

import { CardTableV2, TableCategory } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { EnumProductModel } from '@chipcoo/constant';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { connectDecorator } from 'src/store';
import { getTableColumns } from './components/getTableColumns';
import CardExtra from './components/CardExtra';
import ApplicationFormModal from './ApplicationFormModal';
import { filterFormConfig } from '../components/getFilterFormConfig';
import ImportModal from './components/ImportModal';
import ImportPreviewModal from './components/ImportPreviewModal';
import { inventoryApi } from 'src/services/net';
import { getInitialQuery } from 'src/utils';
import { categoryConfig } from '../formConfig';

interface OwnProps {
  // productModel: EnumProductModel;
}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}

interface DispatchProps {
  getTableItemDetail: (id: string) => void;
  getTableList: (params: any) => void;
  postCreate: (data: any) => void;
  patchModify: (data: any) => void;
  bulkCreate: (data: any) => void;
}

type IProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;
export type ModalType = 'new' | 'edit' | 'preview' | 'reSubmit';
interface IState {
  tableLoading: boolean;
  modalVisible: boolean;
  modalType: ModalType;
  detailId: string;
  tableColumns: ColumnProps<any>[];
  queryFormConfig: FormItemConfig[];
  importModalVisible: boolean;
  importPreviewModalVisible: boolean;
  previewModalInfos: any;
  category: EnumProductModel;
  isResetDefault: boolean;
}

const { EnhancedTable, QueryTermTags } = CardTableV2;

@connectDecorator(
  ({ stockIn: { list, count, detail } }) => ({ list, count, detail }),
  ({ stockIn: { getTableList, postCreate, getTableItemDetail, patchModify, bulkCreate } }) => ({
    bulkCreate,
    getTableList,
    postCreate,
    getTableItemDetail,
    patchModify,
  })
)
class StockInTable extends React.PureComponent<IProps, IState> {
  private cardTableController: ICardTableController;
  private queryParams;

  constructor(props: IProps) {
    super(props);

    const { location } = props;
    const productModel = get(location?.state, 'productModel') || EnumProductModel.spwDie;

    this.state = {
      tableLoading: false,
      modalVisible: false,
      modalType: 'new',
      detailId: '',
      tableColumns: getTableColumns({
        openModal: this.openModal,
        productModel: productModel,
      }),
      queryFormConfig: filterFormConfig(productModel),
      importModalVisible: false,
      importPreviewModalVisible: false,
      previewModalInfos: {},
      category: productModel,
      isResetDefault: false,
    };
  }

  componentDidMount(): void {
    this.getCategoryByLocation();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { location } = this.props;

    if (!_.isEqual(location.state, prevProps.location?.state)) {
      this.getCategoryByLocation();
    }
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  getCategoryByLocation = () => {
    const { location, history } = this.props;
    const state = location?.state;
    const productModel = get(state, 'productModel');
    let newState = omit(state, 'productModel');

    if (get(state, 'autoOpenModal')) {
      this.openModal('', 'new');
      newState = omit(newState, 'autoOpenModal');
    }

    history.replace({ ...history.location, state: newState });

    if (productModel) {
      this.setCategory(productModel);
    }
  }

  setCategory = (category: IState['category']) => {
    const tableColumns = getTableColumns({
      openModal: this.openModal,
      productModel: category,
    });

    this.clearQuery();

    this.setState({
      category,
      isResetDefault: true,
      tableColumns,
      queryFormConfig: filterFormConfig(category)
    }, () => {

      if (this.cardTableController) {
        this.cardTableController.setQueryParams!({
          page: 1,
          formData: {}
        });
      }
    });
  }

  reloadTable = () => {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  setCardTableController = (controller: ICardTableController) => {
    this.cardTableController = controller;
  }

  getTerms = (query: Obj) => {
    const config = filterFormConfig(this.state.category);
    return config.reduce((result, curCfg) => {
      const dataKey = get(curCfg, 'dataKey');

      if (!dataKey) {
        return result;
      }

      let val = get(query, dataKey);
      if (_.isObject(val)) {
        val = get(val, 'label');
      }

      if (val) {
        const label = get(curCfg, 'label');
        const getDisplay = get(curCfg, 'getDisplay');

        if (getDisplay) {
          val = getDisplay(val);
        }

        result[label] = val;

        if (val && ['string', 'number'].indexOf(typeof val) === -1) {
          log.warn('[getTerms]: 未被正确转换的查询条件展示', label, val, curCfg);
          Reflect.deleteProperty(result, label);
        }
      }

      return result;
    }, {} as any);
  }

  openModal = async (detailId: string, modalType: ModalType) => {
    const { getTableItemDetail } = this.props;
    let state: any = {
      modalVisible: true,
      modalType: modalType,
    };

    if (modalType !== 'new') {
      try {
        await getTableItemDetail(detailId);

        state = {
          ...state,
          detailId
        };
      } catch (e) {
        log.error('[DemandOrderTable]: loading preview failed', e, detailId);
        message.error('加载申请订单详情失败');
        return;
      }
    }

    this.setState(state);
  }

  createApplication = async (values) => {
    const { postCreate, patchModify, bulkCreate } = this.props;
    const { modalType, detailId, category } = this.state;

    try {
      if (modalType === 'new') {
        await bulkCreate({productModel: category, values});
      } else if (modalType === 'reSubmit') {
        await postCreate({ productModel: category, values });
      } else {
        await patchModify({ id: detailId, productModel: category, values });
      }

      this.reloadTable();
      this.closeModal();
    } catch (e) {
      console.log(e);
    }
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  }

  // 获取表格数据
  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { category } = this.state;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          case 'material':
            prev[key] = value ? value.key : undefined;
            break;
          case 'product':
            prev[key] = value ? value.key : undefined;
            break;
          case 'dieNumber':
            prev[key] = value ? value : undefined;
            break;
          case 'stampNumber':
            prev[key] = value ? value : undefined;
            break;
          case 'warehouse':
            prev[key] = value ? value.key : undefined;
            break;
          default:
            prev[key] = value ? value : undefined;

            break;
        }

        return prev;
      },
      {} as any
    );
    this.queryParams = {
      productModel: category,
      ...extraParams,
      ..._formData,
    };

    this.setState({ tableLoading: true });
    try {
      await getTableList({...this.queryParams});
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  // 批量导入弹窗操作
  openImportModal = () => {
    this.setState({
      importModalVisible: true,
    });
  }

  closeImportModal = () => {
    this.setState({
      importModalVisible: false,
    });
  }

  handleOk = () => {
    this.reloadTable();
    this.closeImportModal();
  }

  openPreviewModal = (val) => {

    this.setState({
      importModalVisible: false,
      importPreviewModalVisible: true,
      previewModalInfos: val,
    });
  }

  closeImportPreviewModal = () => this.setState({ importPreviewModalVisible: false });

  handleBulk = async (values) => {
    try {
      await inventoryApi.postStockInApplyBulk(values);
      message.success('入库申请批量上传成功!');

      this.reloadTable();
      this.closeImportPreviewModal();
    } catch (e) {
      console.log(e);
    }
  }

  getInitialQuery = () => {
    const initialQuery = getInitialQuery(this.props);
    const category = initialQuery?.category;

    if (category) {
      this.setState({ category });
    }

    return initialQuery;
  }

  clearQuery = () => {
    const { history } = this.props;

    history.push(this.props.match.url);
  }

  render() {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      tableColumns,
      modalVisible,
      modalType,
      queryFormConfig,
      importModalVisible,
      importPreviewModalVisible,
      previewModalInfos,
      category,
      isResetDefault
    } = this.state;

    return (
      <div className="wrap-content">
        <TableCategory
          defaultCategory={getInitialQuery(this.props)?.category || category}
          title="产品类型"
          statistics={{}}
          setCategory={this.setCategory}
          categoryConfig={categoryConfig}
          isResetDefault={isResetDefault}
        />

        <CardTableV2
          card={{
            title: '入库申请列表',
            extra: (
              <CardExtra
                queryParams={this.queryParams}
                openQuery={this.openQuery}
                openModal={this.openModal}
                openImportModal={this.openImportModal}
              />
            )
          }}
          queryFormConfig={queryFormConfig}
          getCardTableController={this.setCardTableController}
        >
          <QueryTermTags
            getTerms={this.getTerms}
            openQueryPanel={this.openQuery}
            clearQuery={this.clearQuery}
          />
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={tableColumns}
            // scroll={category === EnumProductModel.assemblyProduct ? { x: 2550 } : { x: 3150 }}
            clickMention={true}
            size="small"
            initialQuery={this.getInitialQuery()}
          />
        </CardTableV2>

        <ApplicationFormModal
          productModel={category}
          visible={modalVisible}
          mode={modalType}
          onOk={this.createApplication}
          onCancel={this.closeModal}
          formData={modalType === 'new' ? null : detail}
        />

        <ImportModal
          importModalVisible={importModalVisible}
          handleOk={this.handleOk}
          handleCancel={this.closeImportModal}
          openPreviewModal={this.openPreviewModal}
        />

        <ImportPreviewModal
          visible={importPreviewModalVisible}
          previewInfos={previewModalInfos}
          onOk={this.handleBulk}
          onCancel={this.closeImportPreviewModal}
        />
      </div>
    );
  }
}

// tslint:disable-next-line: max-file-line-count
export default StockInTable as ComponentType<OwnProps>;
