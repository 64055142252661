import React from 'react';

import { get, omit, pick, isEqual } from '../utils/lodash';
import to from 'await-to-js';
import { Route, RouteComponentProps } from 'react-router-dom';
import { matchPath, RouteProps } from 'react-router';
import store from 'src/store';
import { RematchStore } from '@rematch/core';

import { isProd } from 'src/utils';
import { getPreviousRoute } from '../HookRoute';
import { createStatic, getDefaultMatch } from '../core/createStatic';
import { HoneyRouteProps, State, CompiledRouteProps, HoneyRouteComponentProps, HoneyRouteOwnProps } from '../interface';

export interface AsyncDataArg<Params extends any = { [key: string]: any }> {
  store: RematchStore;
  // @ts-ignore
  route: RouteComponentProps<Params> & {
    path: string;
  };
  [key: string]: any;
}

export const routes: CompiledRouteProps[] = [];

export const {
  compilePath,
  getMatchedRoute,
  registerRoute,
  getRoute,
  getRouteByName,
  getRouteByPath,
  getRouterProps,
  getMatchInfoByPathname,
} = createStatic(routes);

const routeHooks = ['asyncData', 'orderedAsyncData'];

const uselessProps: (keyof HoneyRouteComponentProps)[] = ['name', 'breadcrumbName', 'match'];

export default class HoneyRouteComponent<
  P extends HoneyRouteOwnProps = HoneyRouteComponentProps,
> extends React.Component<P, State> {
  static routes = routes;
  private loading = false;
  static _lastFullMatch: CompiledRouteProps<any>[] | null = null;

  static getMatchedRoute = getMatchedRoute;

  static getMatchRoutesWithHooks(pathname: string): CompiledRouteProps[] {
    return this.getMatchedRoute(
      pathname,
      r => !!r.component && routeHooks.some(hookName => !!r.component![hookName])
    );
  }

  static getMatchedWithDiff(to: string, from: string | CompiledRouteProps<any>[]) { // tslint:disable-line
    let matched = this.getMatchedRoute(to);
    let prevMatched = from;

    if (!prevMatched) {
      return matched;
    }

    if (typeof from === 'string') {
      prevMatched = this.getMatchedRoute(from);
    }

    // 我们只关心之前没有渲染的组件
    // 所以我们对比它们，找出两个匹配列表的差异组件
    let diffed = false;

    return matched.filter((route, i) => {
      if (!route.component) {
        return false;
      }

      if (diffed || !prevMatched[i]) {
        diffed = true;
        return true;
      }

      const { match, ...other } = route;
      const { match: prevMatch, ...prevOther } = (prevMatched as CompiledRouteProps<any>[])[i];

      diffed = !isEqual(other, prevOther) || !isEqual(match!.params, prevMatch!.params);

      return diffed;
    });
  }

  static isCurrentActive(pathname: string, props: HoneyRouteProps) {
    const rp = pick(props, ['sensitive', 'strict', 'path', 'exact']);
    return !!matchPath(pathname, rp);
  }

  static getDerivedStateFromProps(nextProps: HoneyRouteProps, prevState: State) {
    const { pathname } = nextProps.location!;
    const currentActive = HoneyRouteComponent.isCurrentActive(pathname, nextProps);
    const from = getPreviousRoute();
    const matches = HoneyRouteComponent.getMatchedWithDiff(pathname, from && from.location.pathname || '');

    const nextState: Partial<State> = {
      currentActive
    };

    if (currentActive) {
      if (!prevState.currentActive) {
        nextState.resolve = !matches.length ? prevState.resolve++ : 0;
      }
    } else {
      nextState.resolve = 0;
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    registerRoute(props);
    const currentActive = HoneyRouteComponent.isCurrentActive(props.location!.pathname, props);

    this.state = {
      resolve: 0,
      currentActive,
    };
  }

  componentDidMount() {
    this.doCheck();
  }

  componentDidUpdate() {
    this.doCheck();
  }

  async doCheck() {
    const { resolve, currentActive } = this.state;
    const { pathname } = this.props.location!;
    const fullPathMatch = getMatchInfoByPathname(pathname);

    if (!currentActive || resolve || fullPathMatch.url === '/') {
      return;
    }

    this.loading = true;

    const { path } = this.props;

    // 我们只关心之前没有渲染的组件
    // 所以我们对比它们，找出两个匹配列表的差异组件

    let matched = HoneyRouteComponent.getMatchedWithDiff(pathname, HoneyRouteComponent._lastFullMatch || '');
    HoneyRouteComponent._lastFullMatch = null;
    HoneyRouteComponent._lastFullMatch = HoneyRouteComponent.getMatchedRoute(pathname);

    const asyncDatas = matched.filter(r => !!get(r, 'component.asyncData'));
    const orderedAsyncDatas = matched.filter(r => !!get(r, 'component.orderedAsyncData'));

    const routeProps = pick(this.props, ['location', 'history']);

    // Build match object manually.
    const match = matchPath(pathname, { path }) || getDefaultMatch();
    const callbackArg = {
      store,
      route: { ...routeProps, path: path as string, match: match! }
    };

    // Check if asyncData function exists and then exec it/them.
    const allAsyncDatasPromise = Promise.all(
      asyncDatas.reduce((allPromise, r) => {
        if (isEqual(path, r.path)) {
          allPromise.push(r.component!.asyncData!(callbackArg));
        }

        return allPromise;
      }, [] as Promise<any>[])
    );

    const allOrderedPromise = (async () => {
      for await (const r of orderedAsyncDatas) {
        //
        try {
          if (isEqual(path, r.path)) {
            await r.component!.orderedAsyncData!(callbackArg);
          }
        } catch (err) {
          throw err;
        }
      }
    })();

    const [err] = await to(Promise.all([allAsyncDatasPromise, allOrderedPromise]));
    this.loading = false;

    if (err) {
      !isProd && console.log(err);
      throw err;
    }

    this.setState({ resolve: this.state.resolve + 1 });
  }

  // shouldComponentUpdate(nextProps: HoneyRouteProps, nextState: State) {
  shouldComponentUpdate() {
    return !this.loading;
    // if (this.loading) return false;
    // const { resolve, currentActive} = this.state;
    // const { match } = this.props;
    //
    // return nextState.resolve !== resolve
    //       || nextState.currentActive !== currentActive
    //       || !isEqual(match, nextProps.match);
  }

  get routeProps() {
    return omit(this.props, uselessProps) as RouteProps;
  }

  get history() {
    return get(this.props, 'history');
  }

  render() {
    const { resolve } = this.state;

    if (resolve) {
      return (<Route {...this.routeProps}/>);
    }

    return null;
  }
}
