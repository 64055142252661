// tslint:disable:max-file-line-count
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';

import { CommentMessage } from 'src/models/message.model';
import { attachmentApi } from 'src/services/net';
import upyun from 'src/config/upyun';
import { CommentFetchParams } from 'src/services/net/comment';
import store, { RootState, RootDispatch } from 'src/store';
import { getResourceKey } from 'src/services/resource';
import { createUCommentSelector } from 'src/models/universeComment';
import { CommentDispatchProps, UCBasicProps } from './interface';
// import { formatDate } from 'src/utils';
// import { isEqualUserId } from 'src/utils/user';
// import { mockUser } from 'src/pages/Notification/MsgList/NotifyCard';
import CommentDetail from './CommentDetail';
import { CommentResourceInst } from 'src/models/universeComment/interface';
import { SubscribeResult } from 'src/services/socket';
import { syncSocket } from 'src/socket';
import ChatInput, { EnhancedCommentInfo } from '../ChatInput';
import { ChatInputBoxProps } from 'src/components/ChatInputBox';

interface OwnProps extends UCBasicProps {
  getAutoSaveKey: () => string;
  mentionProps?: ChatInputBoxProps['mentionsProps'];
}

type CommentStateProps = {
  comments: CommentMessage[];
} & Omit<CommentResourceInst['comments'], 'byId' | 'allIds'>;

type Props = CommentStateProps & CommentDispatchProps & OwnProps;

interface State {
  loading: boolean;
  inited: boolean;
}

class Comments extends PureComponent<Props, State> {
  static defaultProps = {
    suggestionData: []
  };

  private subscription: SubscribeResult;
  private _uploadAction: any;
  private _uploadData: any;
  private _autoSaveKey: string;
  private _policyCacheDuration = 30 * 60 * 1000;
  private _clearPolicyTimer: NodeJS.Timer;
  private _endElRef = React.createRef<HTMLDivElement>();

  private uploaderOptions = {
    data: this.getData,
    action: this.getAction,
    showUploadList: true,
    beforeUpload: this.preparePolicy,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      inited: false,
    };

    this._autoSaveKey = props.getAutoSaveKey();
  }

  async componentDidMount() {
    await this.fetchComment(true);
    setTimeout(this.scrollBottom, 1000);
    this.scrollBottom();
    this.setState({ inited: true });
    this.subscribe();
  }

  async subscribe() {
    this.subscription = await syncSocket.subscribe({
      ...this.props.resource,
      // 订阅评论变更的 role 是comments, 发送评论的 role 是 comment
      role: 'comments'
    }, (data) => {
      switch (data.event) {
        case 'delete':
          const { _id } = data.payload.comment;
          const key = getResourceKey(this.props.resource);
          store.dispatch.universeComment.removeComment({ commentId: _id, key });
          break;
        default:
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this._clearPolicyTimer);
    this.subscription && this.subscription.unsubscribe();
  }

  // componentDidUpdate(prevProps: Props) {
  //   const { comments: { length } } = prevProps;
  //   if (this.props.comments.length !== length && this._endElRef.current) {
  //     this._endElRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //   }
  // }

  @bind
  scrollBottom() {
    if (this._endElRef.current) {
      this._endElRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  @bind
  async fetchComment(forceFetch?: boolean) {
    if (this.state.loading || (!forceFetch && !this.props.hasMore)) {
      return;
    }

    const { resource } = this.props;
    this.setState({ loading: true });

    try {
      const { fetchComments, comments } = this.props;
      const params: CommentFetchParams = {
        ...resource,
        limit: 10,
      };
      if (this.state.inited) {
        const _id = (comments[0] && comments[0]._id || '');
        if (_id) {
          params.lastId = _id;
        }
      }
      await fetchComments(params);
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  }

  @bind
  async preparePolicy() {
    if (this._uploadData) { return true; }

    const { data } = await attachmentApi.getPolicy({
      referenceName: 'comment',
      referenceId: 'UNKNOWN',
      referenceRole: 'attachment',
    });

    const { authorization, bucket, policy } = data;
    this._uploadData = {
      policy,
      authorization,
    };
    this._uploadAction = `${upyun.UPURL}/${bucket}`;

    this._clearPolicyTimer = setTimeout(this.clearPolicy, this._policyCacheDuration);

    return true;
  }

  @bind
  async handleSubmitComment(cmt: EnhancedCommentInfo) {
    //
    const { createComment, resource } = this.props;
    let { content: message, mentions } = cmt;
    let attachments: string[] = [];

    if (cmt.attachments) {
      const ref = {
        id: 'UNKNOWN',
        name: 'comment',
        role: 'attachment',
      };
      const promisePipe = cmt.attachments.map(async (attachment) => {
        const { name: filename, mimetype, size, height, width, url } = attachment;
        try {
          const { data } = await attachmentApi.add({
            ...ref, filename, mimetype, size, height, width, url
          });

          return data._id;
        } catch (e) {
          console.error(e);
          return null;
        }
      });
      const ids = await Promise.all(promisePipe);

      ids.forEach(id => {
        if (!id) {
          // 出错了
          return;
        }
        attachments.push(id);
      });
    }

    if (message) {
      const mentionData = mentions.slice();
      const usedMentionData: { [key: string]: FrontUser<any> } = {};
      message = ` ${message} `.replace(/(^| )@([^\s]+)(?= |$)/gm, (_, prefix, name) => {
        // 永远只使用昵称寻找
        let find = mentionData.find(mention => mention.nickname === name);

        if (find) {
          // 移到使用过的提及数据中，适配同名多次艾特
          usedMentionData[name] = find;
          mentionData.splice(mentionData.indexOf(find), 1);
        } else {
          find = usedMentionData[name];
        }

        if (find) {
          return `${prefix}{{@${find._id}}}`;
        }

        return `${prefix}@${name}`;
      }).trim();
    }

    await createComment({ resource, message, attachments });
    setTimeout(this.scrollBottom, 150);
  }

  @bind
  getData() {
    return this._uploadData;
  }

  @bind
  getAction() {
    return this._uploadAction;
  }

  @bind
  getFullUser(user: User<any>) {
    if (!this.props.getFullUser) {
      return user;
    }

    // 防止污染拿来换完整用户的部分用户数据
    const result = this.props.getFullUser({ ...user });
    return { ...result, ...user };
  }

  clearPolicy() {
    this._uploadData = null;
    this._uploadAction = null;
  }

  renderHeader() {
    const { count, comments, hasMore } = this.props;
    const { loading } = this.state;
    return (
      <p className="comment-statistics">
        <span>{hasMore ? '已加载' : '已全部加载'} {comments.length}/{count}</span>
        {hasMore && (
          <span className="comment-load-more" onClick={() => this.fetchComment()}>
            {loading ? '正在加载' : '加载更多'}
          </span>
        )}
      </p>
    );
  }

  renderComments() {
    const { comments, deleteComment, resource, userId, atUserId, userDisabled } = this.props;
    const commentsEl: JSX.Element[] = comments.map((comment) => {
      return (
        <CommentDetail
          deleteComment={deleteComment}
          resource={resource}
          comment={comment}
          key={comment._id}
          userId={userId}
          atUserId={atUserId}
          userDisabled={userDisabled}
          getFullUser={this.getFullUser}
        />
      );
    });

    return (
      <div className="comment-list-wrap y-scroll thin-scroll">
        {commentsEl}
        <div ref={this._endElRef} />
      </div>
    );
  }

  renderInput() {
    return (
      <ChatInput
        displaySuggests={true}
        suggestionData={this.props.suggestionData || []}
        saveDraft={this._autoSaveKey}
        onSubmit={this.handleSubmitComment}
        mentionsProps={this.props.mentionProps}
        uploaderOptions={this.uploaderOptions}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderComments()}
        {this.renderInput()}
      </>
    );
  }
}

function createMapStateToProps() {
  const commentSelector = createUCommentSelector('comments');
  return function(arg: RootState, props: Props) {
    const { resource } = props;
    const key = getResourceKey(resource);
    const commentsData = commentSelector(arg.universeComment, key);

    return commentsData as any as CommentStateProps;
  };
}

function mapDispatchToProps(arg: RootDispatch) {
  const { universeComment: { fetchComments, deleteComment, createComment } } = arg;

  return {
    fetchComments,
    deleteComment,
    createComment,
  };
}

export default connect<
  CommentStateProps,
  CommentDispatchProps,
  OwnProps
>(createMapStateToProps, mapDispatchToProps)(Comments);
