import * as React from 'react';
import { Divider, Button } from 'antd';

interface CardExtraProps {
  openModal: (initialVersion?: boolean) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openModal } = props;

  return (
    <div>
      <Button type="primary" onClick={() => openModal(true)}>添加</Button>

      <Divider type="vertical" />

      <Button type="primary" onClick={() => openModal(false)!}>改版</Button>
    </div>
  );
}

export default React.memo(CardExtra);
