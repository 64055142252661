/**
 * 通知中心右侧的内容
 */
import * as React from 'react';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';

import ContentWrapper from './ContentWrapper';

interface P extends RouteComponentProps<any> {}
interface S {
  notifyId: null | string;
}

const idReg = /\w{24}/;
export const getNotifyId = (str: string, showMsg?: boolean) => {
  const temp = str.match(idReg);

  if (!temp) {
    showMsg && message.error('找不到对应的消息');
    return null;
  }

  return temp[0];
};

class MsgContent extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const notifyId = getNotifyId(nextProps.location.pathname);

    if (notifyId !== prevState.notifyId) {
      return { notifyId };
    }

    return null;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      notifyId: getNotifyId(props.location.pathname)
    };
  }

  render() {
    const { notifyId } = this.state;

    if (!notifyId) { return null; }

    return <ContentWrapper notifyId={notifyId} />;
  }
}

export default MsgContent;
