import * as React from 'react';
import classnames from 'classnames';
import { pick } from 'lodash';
import { noop } from '@chipcoo/fe-utils';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTable/Addon/EnhancedTable';

import { Actions, connectDecorator, RootState } from 'src/store';
import { getTableColumns } from './getTableColumns';
import { getFormConfig } from './formConfig';
import styles from './index.module.less';

interface OwnProps {}
interface StateProps {
  summaryCount: number;
  summaryList: unknown[];
}
interface DispatchProps {
  getSummaryList: (params: unknown) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {}
interface State {
  tableLoading: boolean;
  visible: boolean;
  fullWindow: boolean;
  modalTitle: string;
  formData: Obj;
}

const { EnhancedTable } = CardTableV2;

@connectDecorator(
  ({ orderSummary: {
    summaryCount,
    summaryList,
  } }: RootState) => ({
    summaryCount,
    summaryList,
  }),
  ({ orderSummary: {
    getSummaryList
  } }: Actions) => ({
    getSummaryList
  })
)
class OrderSummary extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      tableLoading: false,
      visible: false,
      fullWindow: true,
      modalTitle: '',
      formData: {},
    };
  }

  componentDidMount() {
    //
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  openModal = (_params) => {
    const params = { ...pick(_params, ['productId', 'productModel', 'status', 'createdAtAfter', 'product']) };

    this.setState({
      visible: true,
      modalTitle: _params?.modalTitle,
      formData: { params, type: _params?.type }
    });
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getSummaryList } = this.props;
    const { formData, ...restData } = params;

    const queryParams = {
      ...restData,
      ...formData,
    };

    try {
      this.setState({ tableLoading: true });

      await getSummaryList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  handleCancel = () => this.setState({ visible: false, modalTitle: '', formData: {} });

  onRequestFullWindow = () => this.setState({ fullWindow: true });
  onRequestNormalWindow = () => this.setState({ fullWindow: false });

  render () {
    const { summaryCount, summaryList } = this.props;
    const { visible, tableLoading, fullWindow, modalTitle, formData } = this.state;

    return (
      <div className="wrap-content">
        <CardTableV2
          card={{ title: '订单汇总表' }}
        >
          <EnhancedTable
            total={summaryCount}
            dataSource={summaryList}
            getTableData={this.getTableData}
            columns={getTableColumns({
              openModal: this.openModal
            })}
            tableLoading={tableLoading}
            clickMention={true}
          />
        </CardTableV2>

        <FlexModal
          title={`${modalTitle}详情`}
          visible={visible}
          width={1024}
          className={classnames(styles.orderSummaryModal, 'order-summary-modal')}
          onCancel={this.handleCancel}
          // resize={}
          footer={null}
          fullWindow={fullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
        >
          <FormCreator
            formConfig={getFormConfig()}
            formData={formData}
          />
        </FlexModal>
      </div>
    );
  }
}

export default OrderSummary;
