import { ROUTE_NAMES } from 'src/router/normalRouter';
// import applicationList from "../../../pages/Organization/OrgDetail/cardGrid";

const orderCardGrids = [
  {
    key: '0',
    title: '封装成品',
    description: '封装成品委托加工单',
    target: '',
    route: '', // define only.
  },
  {
    key: '1',
    title: '磨划成品',
    description: '磨划成品委托加工单',
    target: '',
    route: '', // define only.
  },
];

export function fillAppList() {
  orderCardGrids[0].route = ROUTE_NAMES.ORG_OPERATION_ORDER_ASSEMBLY;
  orderCardGrids[1].route = ROUTE_NAMES.ORG_OPERATION_ORDER_DP;
  // cardGrids[2].route = ROUTE_NAMES.ORG_OPERATION;
}

setTimeout(fillAppList);

export default orderCardGrids;
