/**
 * Created by marvin on 18/9/4.
 */

import * as React from 'react';
import { Form, Input, Button } from 'antd';
import { get } from 'lodash';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './index.module.less';
import { getOrgIdByUrl } from 'src/utils';
import { profileApi } from 'src/services/net';
// import handleRespError from 'src/utils/handleRespError';
// import { handleUrlParams } from '../../utils/utils';

interface Props extends RouteComponentProps<any> {
  label?: string;
  formItemLayout?: any;
  form: WrappedFormUtils;
  postRequest: (val: any) => void;
  autoStart?: boolean; // 自动启动定时器
  from?: 'orgSetting' | 'userSetting';
}
interface State {
  count: number;
  emailTextVisible: boolean;
  emailHref: string;
  isMail: boolean;
}

const FormItem = Form.Item;
// const mailReg = /@([a-z0-9_-])+(.([a-z0-9_-]+))+$/i;
const accountReg = /(^1[0-9]{10}$)|(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/;

class VerifyCode extends React.PureComponent<Props, State> {
  private interval;
  constructor(props: Props) {
    super(props);

    this.state = {
      count: 0,
      emailTextVisible: false,
      emailHref: '#',
      isMail: false,
    };
  }

  async componentDidMount () {
    try {
      await profileApi.getGeetest().then(resp => {
        const { challenge, gt, new_captcha, success } = resp.data;

        window.initGeetest(
          { challenge, gt, new_captcha, offline: !success, product: 'popup' },
          captchaObj => {
            captchaObj.appendTo('.captcha-btn');
            captchaObj.bindOn('.captcha-btn');
            captchaObj.onSuccess(() => {
              const result = captchaObj.getValidate();
              this.forbidGetCaptchaRepeat(result);
            });
          }
        );
      });

      // 如果自动启动标志位为true，那就自动启动定时器
      if (this.props.autoStart) this.startInterval();
    } catch (e) { console.log(e); }
  }

  // 清除定时器
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // 禁止短时间多次点击发送验证
  forbidGetCaptchaRepeat(result: GeetestValidate) {
    const { form, from } = this.props;

    if (from === 'orgSetting') {
      this.sendCaptcha(result);
    } else {
      form.validateFields(['account'], (err, value) => {
        if (!err) {
          const { account } = value;
          const isMail = account && account.match(accountReg);

          if (isMail) {
            const mail = isMail ? 'http://mail.' + isMail[0].substr(1) : '#';
            this.setState({
              emailHref: mail,
              isMail: true
            });
          }

          // 没有account，不发请求
          this.sendCaptcha(result);
        }
      });
    }
  }

  // 启动定时器
  startInterval = () => {
    let count = 60;

    this.setState({ count });

    this.interval = setInterval(
      () => {
        count -= 1;
        this.setState({ count });
        if (count === 0) {
          const eles = document.querySelectorAll('.captcha-btn');
          eles[1].innerHTML = '重新获取验证码';
          clearInterval(this.interval);
        }
      },
      1000
    );
  }

  // 获取验证码
  sendCaptcha = async (result: GeetestValidate) => {
    const { postRequest } = this.props;
    // const phone = form.getFieldValue('phone');
    // const account = form.getFieldValue('account');
    // const nickname = form.getFieldValue('nickname');
    const orgId = getOrgIdByUrl();

    try {
      const res = await postRequest({ ...result, orgId });

      this.startInterval();
      this.setState({
        emailTextVisible: true,
        isMail: get(res, 'captchaMethod') === 'EMAIL'
      });
      // }
    } catch (e) {
      console.error(e);
    }
  }

  render () {
    const { count } = this.state;
    const { form, label, formItemLayout } = this.props;
    const { getFieldDecorator } = form;
    const showOriginCaptchaClass = classNames(styles.button, { 'show-origin-btn': Boolean(count) });

    return (
      <div style={{ paddingTop: 1 }}>
        <FormItem label={label} {...formItemLayout}>
          {getFieldDecorator('verifyCode', {
            rules: [{ required: true, message: '请输入验证码' }]
          })(
            <div className={styles.verifyCode}>
              <Input placeholder="验证码(必填)" autoComplete="off" />
              <div className={showOriginCaptchaClass}>
                <Button className="captcha-btn" disabled={Boolean(count)}>
                  {count ? `${count} s后可重发` : '获取验证码'}
                </Button>
              </div>
            </div>
          )}
        </FormItem>

        {/*{*/}
        {/*  emailTextVisible*/}
        {/*    ? isMail*/}
        {/*      ? (*/}
        {/*        <>*/}
        {/*          <p className={styles.mailText}>*/}
        {/*            验证码已发送至邮箱，<a href={emailHref} target="_blank">登录邮箱 </a>查看。*/}
        {/*          </p>*/}
        {/*          <p className={styles.mailText}>若未收到？请查看垃圾邮件或重新发送。</p>*/}
        {/*        </>*/}
        {/*      )*/}
        {/*      : (*/}
        {/*        <p className="ant-form-explain">验证码已发送，可能会有延后，请耐心等待</p>*/}
        {/*      )*/}
        {/*    : null*/}
        {/*}*/}
      </div>
    );
  }
}

export default withRouter(VerifyCode);
