/**
 * 整个应用最外面的结构，用来判断用户是否登录，获取初始数据等等事情
 */
import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { noop } from '@chipcoo/fe-utils';

import { RootState, RootDispatch } from 'src/store';

import BasicComponent from './BasicComponent';
import ErrorPage from './ErrorPage';

interface OwnProps {}
interface StateProps {
  profile?: User;
}
interface DispatchProps {
  getProfile: () => void;
  getOrgList: (arg?: DispatchPayload) => void;
  fetchStatics: any;
  gainAllFriends: any;
  getUnreadMessageNumber: () => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  isShowPage: boolean;
}

// 这个里面用来存放权限树之类的不常变的数据
export type InitialLayoutCxt = {
  permissionsTree: any;
};
export const LayoutContext = React.createContext<InitialLayoutCxt>({
  permissionsTree: null
});

class BasicLayout extends React.PureComponent<Props, State> {
  state = {
    isShowPage: false,
  };

  // 但确定登录以后，正式开始渲染页面
  static showPage() {
    // 去掉遮罩
    (document.querySelector('#root-spin-mask') as HTMLElement).style.display = 'none';
  }

  async componentDidMount() {
    const { getOrgList, getProfile, fetchStatics, gainAllFriends, getUnreadMessageNumber } = this.props;

    await Promise.all([getProfile(), getOrgList()]);
    this.setState({ isShowPage: true });

    BasicLayout.showPage();

    axios.all([
      // 获取通知的消息统计
      fetchStatics(),
      // 获取聊天的消息统计
      getUnreadMessageNumber(),
      // 好友列表虽然一开始不会被加载，但聊天那边可能需要这个数据，因此一开始的时候就拿下来算了
      gainAllFriends(),
    ]);
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  render() {
    const { profile } = this.props;

    if (!profile || !this.state.isShowPage) { return null; }

    return <ErrorPage><BasicComponent /></ErrorPage>;
  }
}

const mapState = ({ session }: RootState) => ({
  profile: session.profile,
});
const mapDispatch = ({ session, org, notification, friend, chatMisc }: RootDispatch): DispatchProps => ({
  getProfile: session.getProfile,
  getOrgList: org.getOrgList,
  gainAllFriends: friend.getAllFriends,
  fetchStatics: notification.fetchStatics,
  getUnreadMessageNumber: chatMisc.getUnreadMessageNumber
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(BasicLayout);
