import { init, RematchDispatch, RematchRootState } from '@rematch/core';
// import immerPlugin from '@rematch/immer';
import * as models from '../models';
import {
  defaultReducersPlugin,
  // collectErrorPlugin,
} from './plugin';

const store = init({
  models,
  plugins: [
    // 加入一些默认的reducers，省得一个个写
    defaultReducersPlugin,
    // 收集effects中的response error
    // collectErrorPlugin,
  ]
});

export type Models = typeof models;

export type RootDispatch = typeof store.dispatch;
export type RootState = RematchRootState<Models>;
export type OriginActions = RematchDispatch<Models>;

type EnhanceActions<T> = {
  [P in keyof T]: T[P] & {
    reset: (paths?: string[]) => void;
  }
};

export type Actions = EnhanceActions<OriginActions>;

export { connect, connectDecorator } from './connect';

export default store;
