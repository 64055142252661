import * as React from 'react';
import { Icon } from 'antd';

import { mpwApi } from 'src/services/net';

interface CardExtraProps {
  openQuery: (e: any) => void;
}

function CardExtra(props: CardExtraProps) {
  // const { openQuery } = props;

  return (
    <div>
      <a href={mpwApi.exportWip()}>
        <Icon type="export" />导出
      </a>
      {/* <Divider type="vertical" />
      <a onClick={openQuery}>
        <Icon type="search" />
        查询
      </a> */}
    </div>
  );
}

export default React.memo(CardExtra);
