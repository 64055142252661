// 新品测试
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { getRouterProps } from 'src/components/HoneyRouter';

import { SiderMenuRoute } from 'src/components';
import CP from './CP';
import FT from './FT';

interface Props extends RouteComponentProps<any> {}

let menus: RouteMenuItem[] = [];
function fillMenu() {
  menus = [
    {
      title: '新品CP测试',
      routeName: ROUTE_NAMES.ORG_RD_TEST_CP,
      routeProps: {
        component: CP,
        exact: true,
      }
    },
    {
      title: '新品FT测试',
      routeName: ROUTE_NAMES.ORG_RD_TEST_FT,
      routeProps: {
        component: FT,
        exact: true,
      }
    },
  ];
}

setTimeout(fillMenu);

class NewTest extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }
  render() {
    const routeProps = getRouterProps(this.props);

    return (
      <SiderMenuRoute menus={menus} routerProps={routeProps} />
    );
  }
}

export default NewTest;
