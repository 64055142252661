/**
 * 客户物料库存列表
 */
import React, { ComponentType, PureComponent } from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumProcessClass, EnumProductModel } from '@chipcoo/constant';

import styles from './index.module.less';
import { connectDecorator, RootState, Actions } from 'src/store';
import { getFormConfig } from './formConfig';

import CardExtra from './components/CardExtra';
import { getTableColumns } from './components/getTableColumns';
import { filterFormConfig } from './components/filterFormConfig';

const { EnhancedTable, QueryTermTags } = CardTableV2;

interface OwnProps {
  category?: EnumProductModel | 'ALL';
  processClass?: EnumProcessClass;
  productId?: string;
}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (_id: string) => void;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  tableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  modalFullWindow: boolean;
}

@connectDecorator(
  ({ inventory: { list, count, detail } }: RootState) => ({ list, count, detail }),
  ({
     inventory: { getTableList, getTableItemDetail }
   }: Actions) => ({
    getTableList, getTableItemDetail
  })
)
class InventoryTableList extends PureComponent<P, S> {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];

  constructor(props: P) {
    super(props);

    this.state = {
      tableLoading: false,
      detailModalVisible: false,
      detailId: '',
      modalFullWindow: true,
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
    });
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
    const { category, match } = this.props;

    if (category !== prevProps.category || match?.params.id !== prevProps.match?.params.id) {
      this.reloadTable();
    }
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList, processClass, productId, category } = this.props;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          case 'warehouse':
            prev[key] = value ? value.key : undefined;
            break;
          default:
            prev[key] = value;
            break;
        }

        return prev;
      },
      {} as any
    );

    const queryParams: any = {
      ..._formData,
      ...extraParams,
      processClass,
      product: productId,
    };

    if (category !== 'ALL') {
      queryParams.productModel = category;
    }

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  getTerms = (query: Obj) => {
    return Object.entries(query).reduce((prev, [key, val]) => {
      if (_.isEmpty(val)) {
        return prev;
      }

      let value = val;

      const queryObj = _.find(filterFormConfig, ['dataKey', key]) as any;
      const { label } = queryObj;

      switch (key) {
        case 'warehouse':
          prev[label] = value.label;

          break;
        default:
          prev[label] = value;
          break;
      }

      return prev;
    }, {} as any);
  }

  openAddModal = () => this.setState({ detailModalVisible: true, detailId: '' });

  openDetailModal = async (params: any) => {
    const { getTableItemDetail } = this.props;

    try {
      await getTableItemDetail(params);
    } catch (e) {
      console.error(e);
    }

    this.setState({ detailModalVisible: true });
  }

  reloadTable() {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  closeDetailModal = () => this.setState({ detailModalVisible: false });

  closeModalWithReload = () => {
    this.closeDetailModal();

    this.reloadTable();

    this.setState({ tableLoading: false });
  }

  render() {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      detailModalVisible,
      modalFullWindow,
    } = this.state;

    return (
      <>
        <CardTableV2
          card={{
            title: '物料库存列表',
            extra: (
              <CardExtra
                openQuery={this.openQuery}
                openModal={this.openAddModal}
              />
            )
          }}
          queryFormConfig={filterFormConfig}
          getCardTableController={this.getCardTableController}
        >
          <QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />

          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            scroll={{ x: 1110 }}
            clickMention={true}
            size="small"
          />
        </CardTableV2>

        <FlexModal
          width={950}
          animation="fade"
          className={styles.modal}
          visible={detailModalVisible}
          onCancel={this.closeDetailModal}
          footer={null}
          title="查看物料库存"
          fullWindow={modalFullWindow}
          onRequestFullWindow={() => this.setState({ modalFullWindow: true })}
          onRequestNormalWindow={() => this.setState({ modalFullWindow: false })}
          resize={{
            minHeight: 600,
            minWidth: 1024,
          }}
        >
          <FormCreator
            globalStatus="preview"
            formConfig={getFormConfig()}
            formWrapper={false}
            formData={detail ? detail : {}}
          />
        </FlexModal>
      </>
    );
  }
}

export default withRouter(InventoryTableList) as ComponentType<OwnProps>;
// tslint:disable:max-file-line-count
