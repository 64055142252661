/**
 * Created by marvin on 18/8/31.
 */

import React, { ComponentType } from 'react';
import { List } from 'antd';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { getUserName } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { connectDecorator, RootState } from 'src/store';
import { getNormalizedData } from 'src/utils';
import { isEqualUserId, getEnhancedAvatar } from 'src/utils/user';
import { pickOrgUser } from 'src/models/org';

import { EnhanceAvatar } from 'src/components';

interface OwnProps {
  memberIds: Member[];
  value?: number;
  onChange?: (userId: number) => any;
  ref?: any;
}
interface StateProps {
  memberById: { [id: string]: RoleUser };
}
interface DispatchProps {}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  userId: number;
  memberDataSource: Member[];
}

@connectDecorator(
  ({ user, org: { orgMembers: { byId } } }: RootState) => {
    const select = createSelector(
      pickOrgUser,
      getNormalizedData
    );

    return ({
      memberById: select(user, byId).byId,
    });
  }
)
class ChoiceMember extends React.PureComponent<Props, State> {
  static defaultProps = {
    onChange(userId: number) {
      this.setState({ userId });
    }
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const next: Partial<State> = {};
    const { memberIds } = nextProps;

    if (!isEqual(memberIds, prevState.memberDataSource)) {
      next.memberDataSource = memberIds.filter(m => m.role !== 'OWNER');
    }

    if (nextProps.value !== prevState.userId) {
      next.userId = nextProps.value;
    }

    return next;
  }

  constructor(props: Props) {
    super(props);

    const { memberIds, value } = props;

    const memberDataSource = memberIds.filter(m => m.role !== 'OWNER');

    this.state = {
      userId: value || -1,
      memberDataSource,
    };
  }

  // 选择受让成员
  selectListItem = (member: Member) => {
    this.props.onChange && this.props.onChange.call(this, member.user);
  }

  // 受让成员列表Item
  renderListItem = (item: Member) => {
    const { userId } = this.state;
    const listItemData = this.props.memberById[item.user];
    const avatar = getEnhancedAvatar(listItemData);

    return (
      <List.Item className={`thin-scroll ${isEqualUserId(userId, item.user) ? 'selected' : ''}`}>
        <div
          style={{ width: '100%', margin: '-4px 0', padding: '4px 0' }}
          onClick={() => this.selectListItem(item)}
        >
          <EnhanceAvatar
            size="small"
            src={avatar}
            userName={getUserName(listItemData)}
          />

          <span style={{ margin: '0 8px' }}>{getUserName(listItemData)}</span>
        </div>
      </List.Item>
    );
  }

  render () {
    const { memberDataSource } = this.state;
    return (
      <div className={styles.transfereeList}>
        <List
          bordered={false}
          locale={{emptyText: '暂无受让人'}}
          dataSource={memberDataSource}
          renderItem={item => this.renderListItem(item)}
        />
      </div>
    );
  }
}

export default ChoiceMember as ComponentType<OwnProps>;
