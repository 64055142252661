import React, { ComponentType } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { VTComponents } from 'virtualizedtableforantd';
import { CardTableV2 } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';

import { connectDecorator, RootState, Actions } from 'src/store';
import { getTableColumns } from './components/getTableColumns';
import styles from './index.module.less';
import { calculateResizableTableScroll } from 'src/utils';

import BusList from './components/BusList';

const { EnhancedTable } = CardTableV2;

interface OwnProps {
  factoryId: string;
  setUrl: () => void;
  querystring: string;
}

interface StateProps {
  list: any[];
  factoryList: any[];
  orgVerify: any;
  years: number[];
  count: number;
  months: Obj;
}

interface DispatchProps {
  getTableList: (params: any) => void;
  getVerify: () => void;
  postCreate: (data: any) => void;
}

type P = OwnProps & StateProps & DispatchProps & RouteComponentProps;

interface S {
  tableLoading: boolean;
  dataSource: any[]; // 列表数据
  myArray: any; // 用来记录需要合并的单元格 格数
  visible: boolean;
  modalFullWindow: boolean;
  cellRecord: any; // cell数据
  factoryName: string;
}

@connectDecorator(
  ({
     mpwShuttle: { list, factoryList, years, months },
     org: { orgVerify },
     mpwPreOrder: { count }
   }: RootState) => ({ list, orgVerify, factoryList, years, count, months }),
  ({
     mpwShuttle: { getTableList },
     mpwPreOrder: { postCreate },
     org: { getVerify }
   }: Actions) => ({ getTableList, getVerify, postCreate })
)
class MPWShuttleList extends React.PureComponent<P, S> {
  private cardTableController: ICardTableController;

  constructor(props: P) {
    super(props);

    this.state = {
      dataSource: [],
      myArray: {},
      tableLoading: false,
      visible: false,
      modalFullWindow: true,
      cellRecord: {},
      factoryName: '',
    };
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
    const { factoryId } = this.props;

    if (factoryId !== prevProps.factoryId) {
      if (this.cardTableController) {
        const { reloadTable } = this.cardTableController;

        reloadTable();
      }
    }
  }

  getCardTableController = (controller) => {
    this.cardTableController = controller;
  }

  getTableData = async (params: GetTableDataParams) => {
    const { factoryId, getTableList } = this.props;

    const queryParams = {
      ...params,
      factory: factoryId,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    const data = this.props.list;

    this.setState({ dataSource: data, tableLoading: false });

    // let myArray = new Array(data.length);
    //
    // ['node', 'process'].forEach(key => {
    //   myArray = this.getMyArray(data, myArray, key);
    // });
    //
    // this.setState({ myArray });
  }

  getMyArray = (data, myArray, key: string) => {
    /**
     * x: 保存上一个name
     * count: 相同name出现的次数
     * startindex: 该name第一次出现的位置
     */
    let [x, count, startindex, arr]: any[] = ['', 0, 0, []];

    for (let i = 0; i < data.length; i++) {
      // 这里是合并name列，根据各自情况大家可以自己完善
      let val = data[i]?.[key];

      if (i === 0) {
        x = val;
        count = 1;
        arr[0] = 1;
      } else {
        if (val === x) {
          count++;
          arr[startindex] = count;
          arr[i] = 0;
        } else {
          count = 1;
          x = val;
          startindex = i;
          arr[i] = 1;
        }
      }
    }

    return {
      ...myArray,
      [key]: arr,
    };
  }

  handleVisible = async (val) => {
    const { factoryList, factoryId } = this.props;
    const factory = (factoryList || []).find(it => it._id === factoryId);

    await this.props.getVerify();

    this.setState({
      visible: val?.visible,
      cellRecord: val?.cellRecord,
      factoryName: factory?.name
    });
  }

  reloadTable = () => {
    if (this.cardTableController) {
      this.cardTableController.reloadTable();
    }
  }

  closeModal = () => {
    const { count } = this.props;
    const { dataSource, cellRecord } = this.state;

    if (count) {
      const month = cellRecord?.month;
      const index = dataSource.findIndex(it => {
        return _.get(it, `${month - 1}._id`) === cellRecord?._id;
      });

      dataSource.splice(index, 1, {
        ...dataSource[index],
        [`${month - 1}`]: {
          ...dataSource[index][`${month - 1}`],
          iconType: 'form',
          className: 'blue-bus'
        }
      });
    }

    this.setState({
      visible: false,
      cellRecord: {},
      dataSource
    });
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  getScrollY = () => {
    const mpwListDom = document.querySelector('.mpw-shuttle-list');
    let y = 0;

    if (mpwListDom) {
      const height = _.get(mpwListDom, 'offsetHeight');
      y = height - 16 * 2 - 24 * 2 - 63 + 16;
    }

    return y;
  }

  // 在关闭无预订记录的预订班车modal的同时，关闭班车列表modal
  closeMpwListModal = () => {
    this.closeModal();
  }

  render () {
    const { years, orgVerify, months } = this.props;
    const {
      dataSource,
      myArray,
      tableLoading,
      visible,
      cellRecord,
      modalFullWindow,
      factoryName
    } = this.state;
    const tapeOut = orgVerify?.functionContacts?.tapeOut;

    const columns = getTableColumns({
      myArray,
      handleVisible: this.handleVisible,
      years,
      months,
    });

    const formRecord = {
      ...cellRecord,
      mpwBusId: cellRecord?._id,
      factoryName,
      tapeOut
    };

    return (
      <div className={classnames('wrap-content', 'mpw-shuttle-list', styles.mpwShuttleList)}>
        <CardTableV2
          // card={{
          //   title: '班车列表',
          // }}
          getCardTableController={this.getCardTableController}
        >
          {/*<QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />*/}

          <EnhancedTable
            tableLoading={tableLoading}
            columns={columns}
            dataSource={dataSource}
            getTableData={this.getTableData}
            size="middle"
            bordered={true}
            showPagination={false}
            scroll={{
              x: calculateResizableTableScroll(columns),
              y: this.getScrollY()
            }}
            components={VTComponents({ id: 1000 })}
          />
        </CardTableV2>

        <FlexModal
          title="已预订班车列表"
          width={800}
          visible={visible}
          onCancel={this.closeModal}
          // onOk={this.handleOk}
          className={classnames('common-flex-modal', styles.modal)}
          footer={null}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.requestFullWindow}
          onRequestNormalWindow={this.requestNormalWindow}
        >
          <BusList
            formRecord={formRecord}
            closeMpwListModal={this.closeMpwListModal}
          />
        </FlexModal>
      </div>
    );
  }
}

export default MPWShuttleList as ComponentType<OwnProps>;
// tslint:disable:max-file-line-count
