import React, { FC } from 'react';
import { Card } from 'antd';

import { IOrgDetail } from 'src/models/org/interface';
import { getFormConfig } from './formConfig';
import { FormCreator } from '@chipcoo/hanayo';
import styles from './index.module.less';

interface IProps {
  verifyInfo: any;
  orgInfo: IOrgDetail;
}

const VerifyDetail: FC<IProps> = props => {
  const { verifyInfo, orgInfo } = props;

  return (
    <Card title={verifyInfo!.info.name} bordered={false} className={styles.detail}>
      <FormCreator
        formData={verifyInfo ? verifyInfo : null}
        globalStatus="preview"
        formConfig={getFormConfig(orgInfo)}
      />
    </Card>
  );
};

export default VerifyDetail;
