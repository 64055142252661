/**
 * 新建组织的弹框
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal } from '@chipcoo/hanayo';
import { Input, Form, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import formModalStyles from 'src/styles/form-modal.module.less';
import './index.module.less';
import { NewOrg } from 'src/models/org/interface';

interface OwnProps {
  visible: boolean;
  goSetting: (orgId: string | undefined) => void;
  onCloseModal: () => void;
}
interface StateProps {}
interface DispatchProps {
  addNewOrg: (data: NewOrg) => string | undefined;
}
type P = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface S {}

const { TextArea } = Input;
const FormItem = Form.Item;

class AddNewOrg extends React.PureComponent<P, S> {
  private orgNameRef = React.createRef<Input>();
  private newOrgId: string | undefined = undefined;

  focusInput = () => {
    const ref = this.orgNameRef.current;

    ref && ref.focus();
  }

  submit = async () => {
    this.newOrgId = undefined;

    let value: any = {};

    this.props.form.validateFields((err, values) => {
      if (err) {
        this.focusInput();
      } else {
        value = values;
      }
    });

    const { goSetting, ...restVal } = value;

    if (isEmpty(restVal)) {
      return;
    }

    const { addNewOrg, onCloseModal } = this.props;

    const newOrgId = await addNewOrg(restVal);

    onCloseModal();

    if (goSetting) {
      this.newOrgId = newOrgId;
    }
  }

  handleAfterClose = () => {
    this.props.goSetting(this.newOrgId);
  }

  render() {
    const { form, visible, onCloseModal } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        visible={visible}
        width={450}
        title="新建组织"
        animation="slideDown"
        onAfterClose={this.handleAfterClose}
        onCancel={onCloseModal}
        onOk={this.submit}
      >
        <Form className={formModalStyles.modalContent}>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: '必填项' },
                { pattern: /^.{0,50}$/, message: '最多50个字符' }
              ]
            })(
              <Input
                autoFocus={true}
                autoComplete="off"
                placeholder="组织名称 ( 必填 )"
                ref={this.orgNameRef}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('description', {
              rules: [{pattern: /^.{0,200}$/, message: '最多200个字符'}]
            })(
              <TextArea placeholder="组织简介" autosize={{ minRows: 6, maxRows: 6 }} />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('goSetting', {
              valuePropName: 'checked',
              initialValue: true
            })(<Checkbox>直接去设置</Checkbox>)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapDispatch = (dispatch: any) => ({
  addNewOrg: dispatch.org.postNewOrg
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(Form.create()(AddNewOrg));
