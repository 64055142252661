/**
 * layout下面基础的组件
 */
import React from 'react';
import { Layout } from 'antd';
import { SelectOptionsConfigProvider } from '@chipcoo/hanayo';

import s from './index.module.less';

import EventBusGlobalModal from './EventBusGlobalModal';
import GlobalWebPush from './GlobalWebPush';
import AppRouter from 'src/router/normalRouter';
import GlobalHeader from './GlobalHeader';
import FaviconBadge from './FaviconBadge';
import QuickSideMenu from './QuickSideMenu';
import { optionsGroup } from 'src/config/selectOption';
import { HookRoute } from 'src/components/HoneyRouter';
import GlobalPortal from './GlobalPortal';

const { Header, Content } = Layout;

export default () => (
  <SelectOptionsConfigProvider optionsConf={optionsGroup}>
    <Layout className={s.layout}>
      <HookRoute />
      <FaviconBadge />

      <GlobalWebPush />

      <EventBusGlobalModal />
      <GlobalPortal key="global_portal"/>

      <QuickSideMenu />

      <Layout className="thin-scroll">
        <Header className={s.header}>
          <GlobalHeader />
        </Header>
        <Content className={s.content}>
          {AppRouter}
        </Content>
      </Layout>
    </Layout>
  </SelectOptionsConfigProvider>
);
