import { createModel } from '@rematch/core';
import update, { updateChain } from 'immutability-helper-x';

import { initialNormalizedData, getNormalizedData } from 'src/utils';
import { taskApi } from 'src/services/net';
import { InitialState, SortStageListsParams, SortTaskParams } from './interface';

const initialState: InitialState = {
  stageLists: initialNormalizedData(),
  tasks: {}
};

export default createModel({
  state: initialState,
  reducers: {
    // TODO sortStageLists和sortTask有重复代码，后面抽象出来
    // 重新排序stageList
    sortStageLists(state: InitialState, payload: SortStageListsParams) {
      const allIds = Array.from(state.stageLists.allIds);
      const { startIndex, endIndex } = payload;
      const [removed] = allIds.splice(startIndex, 1);

      allIds.splice(endIndex, 0, removed);

      return update.$set(state, 'stageLists.allIds', allIds);
    },

    // 拖动每个任务后的重新排序
    sortTask(state: InitialState, payload: SortTaskParams) {
      const { stageLists } = state;
      const { taskId, destination, source } = payload;
      const { droppableId: startStageId, index: startIndex } = source;
      const { droppableId: endStageId, index: endIndex } = destination;

      // 如果在同一个stage里面移动
      if (startStageId === endStageId) {
        const taskIdsArr = Array.from(stageLists.byId[startStageId].taskIds!);
        const [removed] = taskIdsArr.splice(startIndex, 1);

        taskIdsArr.splice(endIndex, 0, removed);

        return update.$set(state, `stageLists.byId.${startStageId}.taskIds`, taskIdsArr);
      }

      // 在不同的stage里面移动
      return updateChain(state)
        .$splice(`stageLists.byId.${startStageId}.taskIds`, [[startIndex, 1]])
        .$splice(`stageLists.byId.${endStageId}.taskIds`, [[endIndex, 0, taskId]])
        .value();
    }
  },
  effects: (dispatch) => ({
    // 获取任务列表stage的数据
    async getStageList() {
      const respData = await taskApi.getStageList();
      const { allIds, byId } = getNormalizedData(respData.data.data);

      this.updateState([
        { path: 'stageLists.allIds', data: allIds },
        { path: 'stageLists.byId', data: byId }
      ]);
    },

    // 根据任务列表id，或者该列表所展示的任务集合的数据
    async getTaskGroupUnderStage(stageId: string, rootState: any) {
      const stageLists = rootState.task.stageLists;

      if (!stageId || !stageLists.byId[stageId]) { return; }

      const { data } = await taskApi.getTasksUnderStage(stageId);

      if (!stageLists.byId[stageId]) { return; }

      const { allIds, byId } = getNormalizedData(data.data);

      this.updateState([
        { path: `stageLists.byId.${stageId}.taskIds`, data: allIds },
        { path: 'tasks', data: (state) => ({ ...state.tasks, ...byId }) }
      ]);
    }
  })
});
