/**
 * socket分为推动和同步，虽然是建立两个链接，但是后端那边还是一个，主要是为了对推送和同步做区分，两者数据格式有区别
 */
import * as io from 'socket.io-client';
import log from 'loglevel';

import url from 'src/config/url';
import { EnhancedSocked } from './services/socket';
import { api } from 'src/services/net';
import { wrapSocket, axiosResponseInterceptor } from 'src/services/socket';

const socketOptions: SocketIOClient.ConnectOpts = {
  reconnectionDelay: 3000,
  reconnectionDelayMax: 15000,
  // reconnectionAttempts: 15, 
};

const pushSocket = io(url.pushSocket, { ...socketOptions });
const syncSocket = wrapSocket(io(url.syncSocket, { ...socketOptions }) as any as EnhancedSocked);

api.interceptors.response.use(axiosResponseInterceptor);

export const registerSocket = () => {
  pushSocket.on('connect', () => {
    console.log('push-socket连接上了');
  });

  pushSocket.on('disconnect', () => {
    console.log('push-socket掉线');
  });

  syncSocket.on('connect', () => {
    console.log('sync-socket连接上了');
  });

  syncSocket.on('disconnect', () => {
    console.log('sync-socket掉线');
  });

  pushSocket.on('reconnect_failed', () => {
    log.error(`[PushSocket]: pushSocket is reconnected after many attempts.`);
  });
  syncSocket.on('reconnect_failed', () => {
    log.error(`[SyncSocket]: syncSocket is reconnected after many attempts.`);
  });
};

console.log('syncSocket', syncSocket);

export {
  pushSocket,
  syncSocket
};
