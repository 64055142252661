import React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';
// import { Divider } from 'antd';
// import { EnumStockInApplyStatus } from '@chipcoo/constant';
import { ModalType } from '../StockOutTable';

interface Option {
  openModal: (detailId: string, modalType: ModalType) => void;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      dataIndex: 'serial',
      key: 'serial',
      title: '出库申请单号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: '申请时间',
      width: 200,
    },
    {
      dataIndex: 'materialName',
      key: 'materialName',
      title: '物料名称',
      width: 200
    },
    {
      dataIndex: 'product',
      key: 'product',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'productModel',
      key: 'productModel',
      title: '产品类型',
      width: 150
    },
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 250,
      canCopy: true,
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'stampNumber',
      key: 'stampNumber',
      title: '印章批号',
      width: 200,
      canCopy: true,
    },
    {
      dataIndex: 'dieName',
      key: 'dieName',
      title: '芯片名称',
      width: 200
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '出库库房',
      width: 200
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '出库总数量',
      width: 150
    },
    {
      dataIndex: 'waferQuantity',
      key: 'waferQuantity',
      title: '片数',
      width: 100
    },
    {
      dataIndex: 'remark',
      key: 'remark',
      title: '备注',
      width: 200
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '状态',
      width: 100
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      fixed: 'right',
      width: 80,
      render: (text, record) => {
        const { openModal } = option;
        const Tag: any = 'a';
        const { _id } = record;

        return (
          <span>
            <Tag onClick={() => openModal(_id, 'preview')}>查看</Tag>
            {/* {
              status === EnumStockInApplyStatus.rejected && (
                <>
                  <Divider type="vertical" />
                  <Tag onClick={(e) => openModal(_id,  'reSubmit')}>重新提交</Tag>
                </>
              )
            } */}
          </span>
        );
      }
    }
  ];
};
