import * as React from 'react';
import { UploadFileBtn } from '@chipcoo/hanayo';
import { RenderType } from '@chipcoo/hanayo/lib/FormCreator';

import { attachmentApi } from 'src/services/net';
import UploadEnhance from 'src/components/ChatInputBox/Uploader/Upload';

interface Props {
  value?: any[];
  onChange?: (val: any) => void;
  renderType?: RenderType;
}
interface State {
  fileList: any[];
}

class UploadByPaste extends React.PureComponent<Props, State> {
  private uploadRef = React.createRef<UploadEnhance>();

  state = {
    fileList: [],
  };

  async componentDidMount() {
    const { value, renderType } = this.props;

    if (renderType !== 'preview') {
      window.addEventListener('paste', this.handlePaste);
    }

    this.setState({ fileList: value || [] });
  }

  handlePaste = (e) => {
    const files: File[] = [];

    if ( !(e.clipboardData && e.clipboardData.items) ) {
      return ;
    }

    for (let i = 0, len = e.clipboardData.items.length; i < len; i++) {
      let item = e.clipboardData.items[i];

      if (item.kind === 'file') {
        let pasteFile: any = item.getAsFile();

        files.push(pasteFile);

        if (files.length) {
          this.uploadRef.current?.upload?.uploader.uploadFiles(files);
          e.preventDefault();
        }
      }
    }
  }

  handleChange = ({ fileList }) => {
    const { onChange } = this.props;

    this.setState({ fileList });

    onChange && onChange(fileList);
  }

  render () {
    const { renderType, value } = this.props;
    const { fileList } = this.state;

    return (
      <UploadFileBtn
        uploadRef={this.uploadRef}
        multiple={true}
        fileList={value || fileList}
        listType="picture-card"
        onChange={this.handleChange}
        getUrl={fileId => attachmentApi.download({ attachmentId: fileId })}
        getFileDetail={fileId => attachmentApi.getDetail(fileId)}
        status={renderType}
      />
    );
  }
}

export default UploadByPaste;
