import * as React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';

import styles from './index.module.less';
import { ROUTE_NAMES } from 'src/router/normalRouter';

import ProductTable from './ProductTable';

import HoneyRoute, { getRouteByName, getRouterProps } from 'src/components/HoneyRouter';
import { RouteMenu } from 'src/components';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { checkRequireVerify } from '../require-verify';

interface Props extends RouteComponentProps<any> {}
interface State {}

let menus: RouteMenuItem[] = [];

function fillMenu() {
  menus = [
    {
      title: '产品信息',
      defaultUnfold: true,
      children: [
        {
          title: 'SPW芯片',
          defaultSelected: true,
          routeName: ROUTE_NAMES.ORG_PROD_SPW_DIE,
          routeProps: {
            component: ProductTable,
            exact: true,
          }
        },
        {
          title: '封装成品',
          routeName: ROUTE_NAMES.ORG_PROD_ASSEMBLY_PRODUCT,
          routeProps: {
            component: ProductTable,
            exact: true,
          }
        },
        {
          title: '磨划成品',
          routeName: ROUTE_NAMES.ORG_PROD_DP_PRODUCT,
          routeProps: {
            component: ProductTable,
            exact: true,
          }
        },
      ],
    },
  ];
}

setTimeout(fillMenu);

class Production extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }

  componentDidMount(): void {
    checkRequireVerify();
  }

  renderSwitchContent = (item) => {
    const routerProps = getRouterProps(this.props);
    const { title, routeName, routeProps } = item;
    const route = getRouteByName(routeName)!;
    return (<HoneyRoute key={title} {...routerProps} {...route} {...routeProps} />);
  }

  render () {
    const routerProps = getRouterProps(this.props);
    return (
      <div className={styles.productWrapper}>
        {/*todo 结合SiderMenu*/}
        <RouteMenu menu={menus} {...routerProps} />

        <div className={classnames(styles.productContent, 'thin-scroll', 'y-scroll')}>
          <Switch>
            {
              menus.map((item, index) => {
                if ('children' in item) {
                  return item.children.map(i => this.renderSwitchContent(i));
                } else {
                  return this.renderSwitchContent(item);
                }
              })
            }
          </Switch>
        </div>
      </div>
    );
  }
}

export default Production;
