import React from 'react';

import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { delayRemove, createKey } from 'src/containers/delayRemove';
import { isEqualUserId } from 'src/utils/user';
import { NAMESPACE } from 'src/config/const';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import store from 'src/store';
import { IOrgDetail } from 'src/models/org/interface';

export interface OrgDissolveSocketData extends ISyncSocketData<{
  orgId: string;
}> {
  event: 'dissolve';
}

export interface OrgUpdateSocketData extends ISyncSocketData<{
  org: IOrgDetail;
}> {
  event: 'update';
}

export interface OrgTransferSocketData extends ISyncSocketData<{
  form: UserId;
  to: UserId;
}> {
  event: 'transfer';
}

export interface OrgMemberAddSocketData extends ISyncSocketData<{
  orgId: string;
  userId: UserId;
  user: User;
  role: NormalRole
}> {
  event: 'add';
}

export interface OrgMemberRemoveSocketData extends ISyncSocketData<{
  orgId: string;
  userId: UserId;
  operate: 'remove' | 'leave';
}> {
  event: 'remove';
}

export interface OrgMemberUpdateSocketData extends ISyncSocketData<{
  orgId: string;
  userId: UserId;
  role: NormalRole;
}> {
  event: 'update';
}

export type IOrgSyncSocketData =
  OrgDissolveSocketData
  | OrgUpdateSocketData
  | OrgTransferSocketData
  | OrgMemberAddSocketData
  | OrgMemberRemoveSocketData
  | OrgMemberUpdateSocketData;

export function handleSyncOrg(data: IOrgSyncSocketData) {
  const state = store.getState();
  const curUserId = state.session.profile!._id;
  const orgById = state.org.orgList.byId;
  const { event: action, resource: { id: orgId }, payload } = data;

  return delayRemove({
    key: createKey(NAMESPACE.ORG, action, orgId),
    handler: () => store.dispatch.org.syncOrg(data),
    executeDirectly: () => {
      const { location: { pathname } } = getCurrentRoute()!;
      const matchPath = compilePath({ name: ROUTE_NAMES.ORG_DETAIL, params: { id: orgId } });

      const shouldChangeRoute = action === 'dissolve' ||
        (action === 'remove' && isEqualUserId(curUserId, (payload as OrgMemberRemoveSocketData['payload']).userId));

      return !shouldChangeRoute || pathname.indexOf(matchPath) === -1;
    },
    modalContent() {
      const { name, shortName } = orgById[orgId].info!;
      const orgName = shortName || name;

      if (action === 'dissolve') {
        return <div>组织『{orgName}』已被解散。</div>;
      }

      const { operate } = payload as OrgMemberRemoveSocketData['payload'];
      let text = `您已离开组织『${orgName}』。`;

      if (operate === 'remove') {
        text = `您已被组织『${orgName}』禁用。`;
      }

      return <div>{text}</div>;
    },
    nextRoute: {
      name: ROUTE_NAMES.ORG_LIST,
    }
  });
}
