/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from '@chipcoo/hanayo';
import log from 'loglevel';

import style from './index.module.less';
import { typeMap } from './';

import ChoiceMember from './ChoiceMember';
import { PasswordAffirm } from 'src/containers';

import { RootState, RootDispatch } from 'src/store';
import { Setting, OrgMember } from 'src/models/org/interface';
import { mapAvailableOrgMember } from 'src/models/org';
import { SettingType } from './interface';
import { NAMESPACE } from 'src/config/const';

interface OwnProps extends RouteComponentProps {
  type: SettingType;
  id: string;
  members?: Member[];
}
interface StateProps {
  orgMembers: NormalizedData<OrgMember>;
  profile: User;
}
interface DispatchProps {
  transferOrg: (payload: Setting) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  userId: number;
  name: string;
  modalVisible: boolean;
  isStartPasswordCheck: boolean;
}

class Transfer extends React.PureComponent<Props, State> {
  private isUnmount: boolean = false;
  private passwordAffirmRef = React.createRef<typeof PasswordAffirm>();

  constructor(props: Props) {
    super(props);

    this.state = {
      userId: -1,
      name: '',
      modalVisible: false,
      isStartPasswordCheck: false,
    };
  }

  componentWillUnmount(): void {
    this.isUnmount = true;
  }

  get userId() {
    let { userId } = this.state;

    if (typeof userId === 'number') {
      return userId;
    }

    userId = parseInt(userId, 10);

    return isNaN(userId) ? 0 : userId;
  }

  openModal = () => this.setState({ modalVisible: true, userId: 0 });

  closeModal = () => this.setState({ modalVisible: false, isStartPasswordCheck: false });

  onUserIdChange = (userId: number) => this.setState({ userId });

  handleSubmit = async (values) => {

    try {
      const { type, profile, transferOrg, id } = this.props;
      const { userId } = this;
      const profileId = parseInt(profile._id, 10);

      await transferOrg({
        userId,
        profileId,
        type: NAMESPACE.ORG,
        orgId: id,
        code: values?.verifyCode
      });

      setTimeout(() => {
        message.success('已成功移交' + typeMap[type]);
      });

      !this.isUnmount && this.closeModal();
    } catch (e) {
      console.error(e);
    }
  }

  // 当前展示的弹框内容的类型
  getModalContentType = (userId: number, memberIds: Member[]): 'passwordAffirm' | 'choiceMember' => {
    // 这边写成===2是因为memberIds里包含了owner
    if (userId > 0 && (memberIds && get(memberIds, 'length') === 2 || this.state.isStartPasswordCheck)) {
      return 'passwordAffirm';
    }

    return 'choiceMember';
  }

  handleOk = async (memberIds: Member[]) => {
    const modalContent = this.getModalContentType(this.state.userId, memberIds);

    if (modalContent === 'passwordAffirm') {
      // 这里直接调用的子组件的方法提交内容
      const submitFn = get(this.passwordAffirmRef.current, 'submitForm');

      try {
        if (submitFn) {
          await submitFn();

          // 转让成功后需要重置isStartPasswordCheck的值，不然会影响到下一次转让的ui展示
          this.setState({ isStartPasswordCheck: false });
        }
      } catch (e) {
        log.error(e);
      }
    }

    if (modalContent === 'choiceMember') {
      this.setState({ isStartPasswordCheck: true });
    }
  }

  renderModalContent = (memberIds: Member[]) => {
    const { userId } = this.state;
    const contentType = this.getModalContentType(userId, memberIds);

    if (contentType === 'passwordAffirm') {
      return (
        <PasswordAffirm
          from="transfer"
          modal={false}
          onSubmit={this.handleSubmit}
          wrappedComponentRef={this.passwordAffirmRef}
        />
      );
    }

    return (
      <ChoiceMember
        value={userId}
        onChange={this.onUserIdChange}
        memberIds={memberIds}
      />
    );
  }

  render () {
    const { type, orgMembers, members  } = this.props;
    const { userId, modalVisible } = this.state;

    let memberIds;

    if (type === NAMESPACE.ORG) {
      memberIds = mapAvailableOrgMember(orgMembers);
    } else {
      memberIds = members || [];
    }

    const modalContent = this.getModalContentType(userId, memberIds);

    return (
      <>
        <h3>转让所有权</h3>
        <p className={style.warningRed}>{typeMap[type]}转让后，您将转为管理员角色，并失去“拥有者”特有的管理权限。</p>
        <div style={{ display: 'flex' }}>
          <div className={style.content}>
            <p>确定转让本{typeMap[type]}?</p>
            <p>如已确认，通过身份确认后选择受让成员，并点击“确认”按钮。</p>
          </div>
          <Button type="danger" onClick={this.openModal}>转让</Button>
        </div>

        <Modal
          visible={modalVisible}
          width={300}
          onCancel={this.closeModal}
          title={modalContent === 'passwordAffirm' ? '安全验证' : '转让所有权'}
          // 组织成员长度等于2时(包含拥有者)不需要显示footer，否则显示modal的footer
          // 只有长度为2且modalContent为choiceMember时不显示footer
          footer={memberIds.length === 2 && modalContent !== 'passwordAffirm' ? null : void 0}
          animation="slideDown"
          onOk={() => this.handleOk(memberIds)}
        >
          {this.renderModalContent(memberIds)}
        </Modal>
      </>
    );
  }
}

const mapState = ({ org: { orgMembers }, session: { profile } }: RootState) => ({
  orgMembers,
  profile: profile!,
});

const mapDispatch = ({ org: { postTransfer } }: RootDispatch) => ({
  transferOrg: postTransfer,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Transfer);
