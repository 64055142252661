import _ from 'lodash';

import { optionsGroup } from 'src/config/selectOption';

export const handleDataList = (data) => data.reduce(
  (prev, cur) => {
    const {
      _id,
      nto,
      stops,
      status,
      ...restData
    } = cur;

    const curData = {
      key: _id,
      _id,
      status,
      ntoName: nto?.name,
      stops: stops?.map(it => it.site).join('，'),
      stopsAmount: stops?.map(it => it.amount).join('，'),
      statusText: optionsGroup.lotStatus.options![status],
      ...restData,
    };
    prev.push(curData);

    return prev;
  },
  [] as any[]
);

export const handleDataDetail = (data) => {
  const {
    _id,
    stops,
    nto,
    ...restData
  } = data;
  const stopsData = stops?.map(it => {
    return {
      ...it,
      key: _id + it.site,
    };
  });

  return {
    ntoName: {key: nto?._id, label: nto?.name},
    stops: stopsData,
    ...restData,
  };
};

export const handleFormData = (values) => {
  const {
    ntoName,
    stops,
    ...restData
  } = values;

  const resultData = {
    nto: ntoName?.key,
    stops: stops ? stops.map(it => {
      const { site, amount } = it;
      return { site, amount };
    }) : [],
    ...restData
  };

  return resultData;
};
