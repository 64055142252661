import { createModel } from '@rematch/core';
import { produce } from 'immer';
import { TableModelCommonEffects } from 'src/models/type';
import { inventoryApi } from 'src/services/net';
import { IState } from './interface';
import {
  handleStockList,
  handleStockDetail,
} from './handle';

const initialState: IState = {
  list: [],
  count: 0,
  detail: {},
  materialNumberList: [],
  materialNumberListCount: 0,
  handleMaterialNumber: {
    list: [],
    count: 0
  }
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: IState, respData: any) {
      return produce(state, draft => {
        const { data } = respData;

        draft.count = data.count;
        draft.list = handleStockList(data.data);
      });
    },
    setDetail(state: IState, respData: any) {
      return produce(state, draft => {
        draft.detail = respData;
      });
    },
    setMaterialNumbers(state: IState, respData: any) {
      return produce(state, draft => {
        draft.materialNumberList = respData.data;
        draft.materialNumberListCount = respData.count;
      });
    },
    setHandlerMaterialNumbers(state: IState, respData: any) {
      return produce(state, draft => {
        draft.handleMaterialNumber.list = respData;
        draft.handleMaterialNumber.count = respData.length;
      });
    }
  },
  effects: (): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await inventoryApi.getStockOutApply(params);

      this.setList({ data});
    },
    async postCreate(data: any) {
      //
    },
    async getTableItemDetail(id: string) {
      const { data } = await inventoryApi.getStockOutApplyById(id);
      const handleData = await handleStockDetail(data);
      this.setDetail(handleData);
    },
    async patchModify(data: any) {
      //
    },
  })
});
