import { WrappedFormUtils, ValidateCallback } from 'antd/lib/form/Form';
import { isArray } from 'lodash';
import loglevel from 'loglevel';
import axios from 'axios';
import { isDataEmpty } from '@chipcoo/fe-utils';

import { attachmentApi } from 'src/services/net';
import { UploadFileToServiceProviderParams } from '../services/net/attachment';

import {
  isBoolean,
  isEmpty,
  isString,
  cloneDeep,
  map,
  get,
  set,
  has,
  findIndex,
  isPlainObject
} from 'lodash';

/*------------------------------------------ 只跟当前文件有关的一些工具函数 ------------------------------------------------*/
function pathPlaceholderKeyParser(path: string, data: Obj): string[] {
  const reg = /\.{{(.+?)}}\./g;

  if (reg.test(path)) {
    const splitArr = path.split(reg);
    const placeholderKey = splitArr[1];

    /**
     * 展示先只处理为index的情况，后面有需要了再继续增加
     *
     * index处理逻辑为：如果一个path为a.b.{{index}}.c，那么匹配到index后，会默认认为a.b是一个数组，循环该数组长度后，将对应的path替换为
     * a.b.0.c、a.b.1.c等等路径，后面拿到这个path后自行发挥
     */
    if (placeholderKey === 'index') {
      // 默认占位符前面应该是一个数组
      const arrVal = get(data, splitArr[0]);

      if (!Array.isArray(arrVal)) return[];

      return arrVal.map((_, index) => set(splitArr, 1, index).join('.'));
    }
  }

  return [path];
}

/*------------------------------------------ 只跟当前文件有关的一些工具函数 ------------------------------------------------*/

export function validateForm<T extends object = {}>(
  form: WrappedFormUtils,
  fields?: string[],
  options?: any
): Promise<T> {
  return new Promise((resolve, reject) => {
    const cb: ValidateCallback<any> = (errors, values) => {
      if (errors) reject(errors);
      else resolve(values);
    };

    const args: any[] = [];
    fields && args.push(fields);
    options && args.push(options);
    args.push(cb);

    form.validateFields.call(form, ...args);
  });
}

/**
 * select部分表单字段获取到的是true或者false的字符串，但后端校验需要的是布尔值
 * 无法判断未来是否要扩展或者判断其他的种类，暂时按照最简单的办法处理
 * @param value
 */
export const trueOrFalseToBoolean = (value: any) => {
  if (value === 'true') return true;

  if (value === 'false') return false;

  return value;
};

/**
 * 递归对null和undefined进行互相转换，注意：[null] [undefined]这种数组包裹的是不会被转换的
 * @param value
 * @param convertValue
 * @param hasParentAndParentIsArray
 */
function nullUndefinedConvert(
  value: any,
  convertValue: 'null' | 'undefined',
  hasParentAndParentIsArray: boolean = false
) {
  const isArr = Array.isArray(value);

  if (isArr || isPlainObject(value)) {
    const clone = isArr ? [...value] : { ...value };

    Object.keys(clone).forEach(key => {
      const val = clone[key];

      clone[key] = nullUndefinedConvert(val, convertValue, isArr);
    });

    return clone;
  }

  const convertField = convertValue === 'null' ? null : undefined;

  if (value === convertField && !hasParentAndParentIsArray) return;

  return value;
}

export const null2undefined = <T>(value: any): T => nullUndefinedConvert(value, 'null');
export const undefined2null = <T>(value: any): T => nullUndefinedConvert(value, 'undefined');

/**
 * boolean和string相互转换，类似于 true -> 'true'，'true' -> true'
 * 这个用在表单中，之所以不用递归直接替换掉，而要使用path，主要是因为有些能转有些不能转，，，索性直接指定，用起来麻烦，但不出错
 *
 * 注意: 如果一个key在一个数组中，那么写成a.b.{{index}}.c，代码会认为a.b是一个数组，然后去遍历替换
 *
 * 写成这样方便Lodash.flow去组合
 *
 * @param paths
 * @param convertValue 是转换boolean还是string
 */
export const booleanStringConvert = (paths: string[], convertValue: 'boolean' | 'string') => (obj: any) => {
  if (isEmpty(obj)) return obj;

  const clone = cloneDeep(obj);
  const setValue = (path: string) => {
    const val = get(clone, path);

    let setVal;

    if (convertValue === 'boolean') {
      if (isBoolean(val)) {
        setVal = `${val}`;
      }
    } else {
      if (!isString(val)) return;

      if (val !== 'false' && val !== 'true') {
        loglevel.info(`使用booleanStringConvert函数的时候，${path}中找到的路径获取到的值不为'true'或者'false'`);
      }

      if (val === 'false') setVal = false;

      if (val === 'true') setVal = true;
    }

    if (setVal !== undefined) set(clone, path, setVal);
  };

  paths.forEach(path => {
    // 解析path中的占位符，并将解析出来的路径(一个数组)丢给修改函数
    pathPlaceholderKeyParser(path, obj).forEach(setValue);
  });

  return clone;
};

/**
 * 递归检查并排除数据里面是否有数组是形如：[null, null]、[undefined, undefined]之类的
 * @param obj
 */
export function excludeEmptyArray(obj: any) {
  function recursive(value: any) {
    const isArr = Array.isArray(value);

    if (isArr || isPlainObject(value)) {
      if (isArr && value.every(isDataEmpty)) return;

      const clone = isArr ? [...value] : { ...value };

      Object.keys(clone).forEach(key => {
        const val = clone[key];

        clone[key] = recursive(val);
      });

      return clone;
    }

    return value;
  }

  return recursive(obj);
}

export type AttachmentUploadInfo = {
  path: string;
  referenceName: string;
  referenceRole: string;
};
/**
 * 根据对应的路径，如果拿到的是一个id，那么根据这个id去拿附件详情
 * @param formData
 * @param attachmentUploadInfo
 */
export async function getAttachmentDetailByIds(formData: Obj, attachmentUploadInfo: AttachmentUploadInfo[]) {
  const idReg = /\w{24}/;
  const hasAttachmentIdArr: {_id: string, path: string}[] = [];

  attachmentUploadInfo.forEach(item => {
    // 解析path中的占位符，并将解析出来的路径(一个数组)丢给修改函数
    const parserPaths = pathPlaceholderKeyParser(item.path, formData);

    parserPaths.forEach(path => {
      const attachmentId = get(formData, path);

      if (idReg.test(attachmentId)) {
        hasAttachmentIdArr.push({ _id: attachmentId, path });
      }
    });
  });

  if (!hasAttachmentIdArr.length) return formData;

  // 批量获取全部的附件
  const { data } = await attachmentApi.getBatchDetail(map(hasAttachmentIdArr, '_id'));

  if (!data.length) return formData;

  const clone = cloneDeep(formData);

  data.forEach(item => {
    const { _id, versions } = item;
    const index = findIndex(hasAttachmentIdArr, ['_id', _id]);

    if (~index) {
      const { path } = hasAttachmentIdArr[index];

      // 循环把找到的附件详情塞回到数据里面去
      set(clone, path, [{
        uid: _id,
        name: versions[0].filename,
        url: attachmentApi.download({ attachmentId: _id })
      }]);

      delete hasAttachmentIdArr[index];
    }
  });

  // 如果hasAttachmentIdArr到跑完循环后不为空，表明对应的id后端没有找到该附件，为避免程序崩溃，直接删除对应的clone.path上的数据，
  // 当然这个只是小众情况，主要是预防万一
  if (!isEmpty(hasAttachmentIdArr)) {
    hasAttachmentIdArr.forEach(item => {
      loglevel.error(`无法找到${item._id}对应的附件`);
      delete clone[item.path];
    });
  }

  return clone;
}

/**
 * 表单的附件上传(包括首次上传和新建)统一进行处理
 * @param formData
 * @param attachmentUploadInfo
 * @param formDetail 从服务器获取到的详情数据，需要与这个数据进行很多比较
 * @param isNeedOriginFileUid 代表附件提交后，是否需要保留原uid来进行排序
 */
interface IUploadFormAttachment {
  formData: Obj;
  attachmentUploadInfo: AttachmentUploadInfo[];
  formDetail: Obj | undefined;
  respType?: 'array' | 'string';
  isNeedOriginFileUid?: boolean;
  getProgress?: (count: number, idx: number) => void;
  // 是否开始串行式断点续传
  isSlice?: boolean;
}
export async function uploadFormAttachment(option: IUploadFormAttachment) {
  const {
    formData,
    attachmentUploadInfo,
    formDetail,
    respType = 'string',
    isNeedOriginFileUid = false,
    getProgress,
    isSlice,
  } = option;
  type FileInfo = UploadFileToServiceProviderParams & { path: string };
  let newFiles: FileInfo[] = [];
  let data: any[] = []; // 附件attachmentId string[]
  let clonePath = '';
  let changedFiles: (FileInfo & { attachmentId: string })[] = [];

  const clone = cloneDeep(formData);
  const idReg = /\w{24}/;

  /**
   * 判断文件是新建还是更改，并将其丢到对应的：newFiles | changedFiles数组里去
   * @param path
   * @param info
   */
  const setFilesArr = (path: string, info: AttachmentUploadInfo) => {
    const { referenceName, referenceRole } = info;
    const attachmentInfo = get(formData, path);

    // 附件被提交后是一个数组，循环遍历附件数组，File对象被挂在originFileObj这个key下面
    if (isArray(attachmentInfo)) {
      attachmentInfo?.forEach(it => {
        const fileObj = it?.originFileObj;

        // 找不到文件对象，且未传入从服务器获取到的详情数据，那么表明用户是新建且未上传附件，直接return掉
        if (!fileObj) {
          if (it?.size > 0) {
            data.push(it?.uid);
            clonePath = path;
            return;
          }

          if (typeof it === 'string') {
            data.push(it);
            clonePath = path;
            return;
          }

          if (!formDetail) return;

          const formDetailFileObj = get(formDetail, path);

          // 如果拿到的表单项的值是一个空数组且原始数据有值，那么表明上传了一个附件后被删除了，这里直接将对应的数据置为undefined
          if (Array.isArray(attachmentInfo) && !attachmentInfo.length && !isEmpty(formDetailFileObj)) {
            clone[path] = undefined;

            return;
          }

          // 没有上传一个新的文件，由于此时拿到的数据是根据formDetail中的id展开的详情，因此将formDetail中的数据赋值回去
          if (formDetailFileObj) {
            clone[path] = formDetailFileObj;
          }

          return;
        }

        const uploadParams = {
          path,
          referenceName,
          referenceRole,
          file: fileObj
        };

        // 如果没有传originFormData那么全部都是新上传的附件
        if (!formDetail) {
          newFiles.push(uploadParams);
          return;
        }

        // 根据对应的path看对应的对象是否存在，判断是否是对象且对象具有一个_id
        const originFileInfo = get(formData, path);

        // 后端有时候给过来的数据attachment会直接是个id，虽然一般都会转一道，但还是防着点
        if (typeof originFileInfo === 'string' && idReg.test(originFileInfo)) {
          changedFiles.push({ ...uploadParams, attachmentId: originFileInfo });
          return;
        }

        const attachmentId = get(originFileInfo, '_id');

        // 如果是一个已经展开的文件对象，那么能在这个对象上找一个_id，就是attachmentId
        if (idReg.test(attachmentId)) {
          changedFiles.push({ ...uploadParams, attachmentId });
          return;
        }

        newFiles.push(uploadParams);
      });
    }
  };

  attachmentUploadInfo.forEach(item => {
    const { path } = item;

    // 解析path中的占位符，并将解析出来的路径(一个数组)丢给修改函数
    const parserPaths = pathPlaceholderKeyParser(path, formData);

    parserPaths.forEach(_path => setFilesArr(_path, item));
  });

  const files = newFiles.concat(changedFiles);

  if (isEmpty(files) && isEmpty(data)) return formData;

  if (!isEmpty(files)) {
    // 把新上传和改变的附件一次性全部上传到服务器
    await axios.all(
      files.map(
        // 检查是否传了attachmentId来判断是新上传附件还是更新附件
        (item: any, idx: number) => item.attachmentId ? attachmentApi.updateFile(item) :
        attachmentApi.uploadFile({...item, index: idx, getProgress, isSlice})
      )
    ).then(axios.spread((...args) => {
      // 把新上传的附件给挑出来
      const _args = args.slice(0, newFiles.length);

      _args.forEach(({ data: respData }, index) => {
        const { path } = files[index];

        // 新上传的附件直接将传到服务器上返回的id给塞到表单数据里面
        // set(clone, path, respData._id);

        const attachmentInfo = get(formData, path);

        // 判断传进来的formData里某个dataKey的附件组件是否是多附件，是就返回string[]，否则就返回string
        if (attachmentInfo?.length > 1) {
          // 当前附件路径是否有值，没有就用空数组
          if (isNeedOriginFileUid) {
            data.push({
              uid: respData._id,
              originFileUid: get(files?.[index], 'file.uid'),
            });
          } else {
            data.push(respData._id);
          }

          set(clone, path, data);
        } else {
          if (respType === 'array') {
            set(clone, path, [respData._id]);
          } else {
            set(clone, path, respData._id);
          }
        }
      });
    }));
  } else if (!isEmpty(data)) {
    // 在一堆附件列表里删除几个附件时，逻辑走这里
    set(clone, clonePath, data);
  }

  return clone;
}

/**
 * 有使用限制的一个函数，主要是处理数据中可能出现的[{}, {}]这种情况，直接返回一个[]
 * @param formData
 * @param paths
 */
export function excludeEmptyObjectArray(formData: Obj, paths: string[]) {
  const toJsonObj = JSON.parse(JSON.stringify(formData));
  const isNotEmptyObj = (obj: Obj) => {
    if (!obj) return false;

    let i;

    for (i in obj) return true;

    return false;
  };

  paths.forEach(path => {
    const data = get(toJsonObj, path);

    if (Array.isArray(data) && data.length) {
      formData[path] = data.filter(isNotEmptyObj);
    }
  });

  return formData;
}

/**
 * 用来手动改变formData获取的detail的值的，注意如果path无数据，会直接返回
 * @param value
 * @param path
 * @param handler
 */
export function setValByPath(value: any, path: string, handler: (val: any) => any) {
  if (!has(value, path)) {
    loglevel.info(`${path} could not find in data`);
    return;
  }

  const val = get(value, path);

  set(value, path, handler(val));
}
// tslint:disable-next-line:max-file-line-count
