import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';

export default function(api: AxiosInstance) {
  return {
    // 获取所有的好友列表
    getFriends(config?: AxiosRequestConfig) {
      return api.get('cooperation/friend', config);
    },

    // 获取所有的分组信息
    getFriendCategory(config?: AxiosRequestConfig) {
      return api.get('cooperation/friend/group', config);
    },

    // 创建好友分组
    postFriendCategory(data: Obj, config?: AxiosRequestConfig) {
      return api.post('cooperation/friend/group', data, {
        ...config,
        handleSpecialError: {
          409: () => { message.error('分组名已存在'); },
          412: () => { message.error('无法添加非好友'); }
        }
      });
    },

    // 修改好友分组
    patchFriendCategory(categoryId: string, data: Obj, config?: AxiosRequestConfig) {
      return api.patch(`cooperation/friend/group/${categoryId}`, data, {
        ...config,
        handleSpecialError: {
          409: () => { message.error('分组名已存在'); },
          412: () => { message.error('无法添加非好友'); }
        }
      });
    },

    // 删除好友分组
    deleteFriendCategory(categoryId: string, config?: AxiosRequestConfig) {
      return api.delete(`cooperation/friend/group/${categoryId}`, config);
    },

    // 根据账号申请好友
    putApplyFriendByAccount(accounts: string[], config?: AxiosRequestConfig) {
      return api.put('cooperation/friend/apply', { accounts }, {
        ...config,
        handleSpecialError: {
          409: () => { message.error('不能添加自己为好友'); }
        }
      });
    },

    // 设置好友备注名称
    patchFriendRemarkName(friendId: string, remarkName?: string | undefined, config?: AxiosRequestConfig) {
      return api.patch(`cooperation/friend/${friendId}/remarkName`, remarkName ? { remarkName } : {}, config);
    },

    // 删除好友
    deleteFriend(friendId: string, config?: AxiosRequestConfig) {
      return api.delete(`cooperation/friend/${friendId}`, config);
    },

    // 处理好友申请
    patchFriendApply(id: string, action: 'accept' | 'reject', config?: AxiosRequestConfig) {
      return api.patch(`cooperation/friend/${id}/resolve/${action}`, config);
    },
  };
}
