// 新品CP测试
import * as React from 'react';

class CP extends React.PureComponent {
  render () {
    return (
      <div>5555</div>
    );
  }
}

export default CP;
