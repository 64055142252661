import * as React from 'react';

import DemandOrderTable from 'src/pages/Operation/Order/DemandOrderTable';
import _ from 'lodash';

interface Props {
  params?: Obj;
}
interface State {
  scrollY: number;
}

class Order extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = { scrollY: 700 };
  }

  componentDidMount() {
    const chipwingModal = document.querySelector('.order-summary-modal .chipwing-modal-body');
    let height = _.get(chipwingModal, 'offsetHeight');

    if (height) {
      const scrollY = height - 16 - 16 * 2 - 57 - 16 * 2 - 24 - 25 - 16 * 2 - 54 + 14;
      this.setState({ scrollY });
    }
  }

  render () {
    const { params } = this.props;
    const { scrollY } = this.state;

    return (
      <DemandOrderTable
        product={params?.productId}
        productModel={params?.productModel}
        isNeedCreateBtn={false}
        scrollY={scrollY}
      />
    );
  }
}

export default Order;
