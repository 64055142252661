import React from 'react';
import { get } from 'lodash';
import {
  FormStatus,
  FormConfig,
  FormItemConfig,
  RepeaterConfig,
  InputFormItemConfig,
  TextAreaFormItemConfig,
  NumberFormItemConfig,
  NumberGroupFormItemConfig,
  SelectFormItemConfig,
  FileFormItemConfig,
  RepeaterRenderParams,
  CustomFormItemConfig,
  // DividerFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import commonStyles from '../../index.module.less';
import { attachmentApi } from 'src/services/net';
import {
  divider,
  principal,
  participants,
  other,
  packingMethod,
  getCustomerName,
} from './common';
import ProdTips040101 from 'src/assets/images/prompt/ProdTips-0401-01.png';
import ProdTips040102 from 'src/assets/images/prompt/ProdTips-0401-02.png';
import ProdTips040103 from 'src/assets/images/prompt/ProdTips-0401-03.png';
import ProdTips040104 from 'src/assets/images/prompt/ProdTips-0401-04.png';

import { RepeaterCardTitle } from 'src/components';
import SelectDie from '../components/SelectDie';
import CarrierType from '../components/CarrierType';
import CollapseDieInfo from '../components/CollapseDieInfo';
import DieInfo from '../components/DieInfo';

// 检查是否是引线类芯片
const checkWB = (values) => {
  const packageType = get(values, 'packageType.packageType');

  return packageType === '引线框架' || packageType === '引线基板';
};
// 检查是否是倒装
const checkFlipChip = (values) => {
  const packageType = get(values, 'packageType.packageType');

  return packageType === '倒装';
};
// 检查是否是系统级封装
const checkSysPackage = (values) => {
  const packageType = get(values, 'packageType.packageType');

  return packageType === '系统级封装';
};
// 检查是否是非系统级封装
const checkNoSysPackage = (values) => {
  const packageType = get(values, 'packageType.packageType') as string | undefined;

  return (typeof packageType === 'string') && (packageType.length > 0) && (packageType !== '系统级封装');
};

const fileDefaultProps = {
  getFileDetail: fileId => attachmentApi.getDetail(fileId),
  getUrl: fileId => attachmentApi.download({ attachmentId: fileId })
};

// 传入判断条件，有none的情况下走这个函数
const renderTypeIncludesNone = (values, initialValues, codition) => {
  if (codition) {
    const schemes = get(initialValues, 'schemes');

    if (schemes && schemes.find(item => item.state === 'MASS')) {
      return 'preview';
    }

    return 'preview';
  }

  return 'none';
};

const SimpleText = (props) => <span>{get(props, 'value.label') || get(props, 'value.key')}</span>;

// 封装类型（作为搜索项了，如果要调整请注意）
export const packageTypeInfo = (): SelectFormItemConfig => ({
  label: '封装类型',
  type: 'select',
  dataKey: 'packageType.packageType',
  optionKey: 'packageType_1',
  renderType: 'preview'
});

// 封装型号(引线框架类)（作为搜索项了，如果要调整请注意）
export const packageTypeLW = (): InputFormItemConfig => ({
  label: '封装型号(引线框架类)',
  type: 'input',
  dataKey: 'packageType.packageTypeLW',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    get(values, 'packageType.packageType') === '引线框架'
  ),
  hintType: 'modal',
  hint: (
    <div>
      <p>CHIPCOO的产品封装型号命名规则分三类：</p>
      <p>第一类：双侧引脚的封装（包括 DIP、HDIP、SDIP、SOP、TSOP、SSOP、TSSOP、HTSSOP、HTSOP、HSOP、HDIP、eTSSOP、eSOP等）</p>
      <p>例：eSOP16-225-1.27，"eSOP"代表封装型号，"16"代表引脚数量，</p>
      <p>"225"代表左右两排焊盘中心点之间的距离（单位mil，如下图的eA），"1.27"代表管脚间距, 单位mm。</p>
      <img src={ProdTips040103} />
      <img src={ProdTips040104} />
      <p>第二类：无引脚或四面引脚的封装（DFN、QFN、QFP、LCC、LGA）及MSOP和eMSOP：</p>
      <p>例：LQFP32-7×7-0.8， "LQFP"代表封装类型，"32"代表引脚数量，"7×7"代表塑封体尺寸（不含引脚，单位mm，无引脚产品为D×E，有引脚产
        品为D1×E1，如下图），"0.8"代表管脚间距，单位mm。</p>
      <img src={ProdTips040101} />
      <img src={ProdTips040102} />
      <p>第三类：器件封装（如SOT、TO）：</p>
      <p>例：TSOT23-6，"TSOT23"代表封装型号，"6"代表引脚数量。</p>
    </div>
  )
});

// 其他封装类型描述
const otherPackageType = (): InputFormItemConfig => ({
  label: '其他封装类型描述',
  type: 'input',
  dataKey: 'packageType.otherPackageType',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    get(values, 'packageType.packageType') === 'other'
  ),
  validate: [{ pattern: /^.{0,30}$/, message: '请输入最大长度为30的其他封装类型描述' }]
});

// 载板类型
const carrierType = (): CustomFormItemConfig => ({
  label: '载板类型',
  type: 'custom',
  dataKey: 'packageType.carrierType',
  renderType: (values, initialValues) => {
    if (
      !get(values, 'packageType.packageType')
      || get(values, 'packageType.packageType') === '引线框架'
      || get(values, 'packageType.packageType') === '晶圆级封装'
    ) {
      return 'none';
    }

    const schemes = get(initialValues, 'schemes');

    if (schemes && schemes.find(item => item.state === 'MASS')) {
      return 'preview';
    }

    return 'normal';
  },
  render: (value, globalStatus, form) => {
    const packageType = get(value, 'packageType.packageType');

    return (
      <CarrierType
        status={globalStatus}
        packageType={packageType}
        setFieldsValue={get(form, 'setFieldsValue')!}
      />
    );
  }
});

// 锡球成分
const solderBallComposition = (): InputFormItemConfig => ({
  label: '锡球成分',
  type: 'input',
  dataKey: 'packageType.solderBallComposition',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    get(values, 'packageType.carrierType') === '基板(BGA)'
  ),
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的锡球成分' }]
});

// 锡球直径
const solderBallDiameter = (): NumberFormItemConfig => ({
  label: '锡球直径',
  type: 'number',
  dataKey: 'packageType.solderBallDiameter',
  unit: 'µm',
  precision: 0,
  range: '(0, 1000]',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    get(values, 'packageType.carrierType') === '基板(BGA)'
  ),
});

// 芯片(或Bump芯片)名（作为搜索项了，如果要调整请注意）
export const dieOrBumpName = (index: number): CustomFormItemConfig => ({
  label: '芯片名',
  type: 'custom',
  dataKey: 'die',
  // required: true,
  renderType: 'preview',
  render: ({ packageType, dies }, globalStatus: FormStatus, form) => {
    if (globalStatus === 'preview') return <SimpleText />;

    return (
      <SelectDie
        packageType={get(packageType, 'packageType')}
        status={globalStatus}
        resetFields={get(form, 'resetFields')!}
      />
    );
  }
});

// 芯片信息
const getCollapseDieInfo = (index: number): CustomFormItemConfig => ({
  type: 'custom',
  colSpan: 24,
  // colClassName: classnames(styles.dieInfoFormItem, style.assemblyCollapseDieInfo),
  renderType: (value) => get(value, `dies.${index}.die`) ? 'normal' : 'none',
  render: ({ dies }: Obj) => {
    return (
      <CollapseDieInfo>
        <DieInfo
          die={get(dies, `${index}.die`)}
        />
      </CollapseDieInfo>
    );
  }
});

// 芯片数量
const dieQtyInPackage: NumberFormItemConfig = {
  label: '芯片数量',
  type: 'number',
  dataKey: 'dieQtyInPackage',
  renderType: 'preview',
  precision: 0,
  range: '(0, 1000]',
  hint: '仅指该芯片的数量，而非所有合封芯片的数量。'
};

// 最终芯片厚度
const finalDieThickness = (): NumberFormItemConfig => ({
  label: '最终芯片厚度',
  type: 'number',
  dataKey: 'finalDieThickness',
  unit: 'µm',
  precision: 0,
  range: '(0, 1000]',
  hint: '研磨后的芯片厚度',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    checkNoSysPackage(values)
  ),
});

// 切割方案
const sawScheme: FileFormItemConfig = {
  label: '切割方案',
  type: 'file',
  dataKey: 'sawScheme',
  props: fileDefaultProps,
  // renderType: (values, initialValues) => renderTypeIncludesNone(
  //   values,
  //   initialValues,
  //   get(values, 'die.mask.waferType') === '多项目晶圆(MPW)'
  // ),
  renderType: 'preview',
};

// 焊丝材料
const wireType = (): SelectFormItemConfig => ({
  label: '焊丝材料',
  type: 'select',
  dataKey: 'wireType',
  optionKey: 'wireType',
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkWB(values)),
  // hintType: 'modal',
  // hint: '图文描述'
});

// 焊丝直径
const wireDiameter = (): NumberFormItemConfig => ({
  label: '焊丝直径',
  type: 'number',
  dataKey: 'wireDiameter',
  unit: 'mil',
  precision: 1,
  step: 0.1,
  // ifRender: checkWB,
  range: '[0.1, 3]',
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkWB(values)),
});

// 装片胶类型
const dieAttachMaterial = (): SelectFormItemConfig => ({
  label: '装片胶类型',
  type: 'select',
  dataKey: 'dieAttachMaterial',
  optionKey: 'dieAttachMaterial',
  // ifRender: checkWB,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkWB(values)),
});

// 装片胶电性
const electronConductivity = (): SelectFormItemConfig => ({
  label: '装片胶电性',
  type: 'select',
  dataKey: 'electronConductivity',
  optionKey: 'electronConductivity',
  hint: (
    <div>
      <p>• 绝缘胶成分一般为环氧树脂，无导电性。</p>
      <p>• 导电胶在环氧树脂中加入一定量的金属粉末，有良好的导电、导热性，粘结力强，价格比绝缘胶高。</p>
    </div>
  ),
  // ifRender: checkWB,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkWB(values)),
});

// 回流焊最高温度
const reflowPeakTemp = (): NumberGroupFormItemConfig => ({
  label: '回流焊最高温度',
  type: 'numberGroup',
  dataKey: 'reflowPeakTemp',
  hint: '回流焊过程中焊接区域最高温（Peak Temp）范围',
  unit: '℃',
  precision: 0,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkFlipChip(values)),
  groupItem: [
    {
      placeholder: '最高温(L)',
    },
    {
      placeholder: '最高温(H)',
    }
  ]
});

// 高温时间
const dwellTime = (): NumberGroupFormItemConfig => ({
  label: '高温时间',
  type: 'numberGroup',
  dataKey: 'dwellTime',
  hint: '回流焊过程中Bump焊接部分（一般为锡）熔融状态的时间',
  // ifRender: checkFlipChip,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkFlipChip(values)),
  unit: 's',
  precision: 0,
  groupItem: [
    {
      placeholder: '高温时间(L)',
    },
    {
      placeholder: '高温时间(H)',
    }
  ]
});

// 助焊剂类型
const fluxType = (): SelectFormItemConfig => ({
  label: '助焊剂类型',
  type: 'select',
  dataKey: 'fluxType',
  optionKey: 'fluxType',
  // ifRender: checkFlipChip,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkFlipChip(values)),
});

// 助焊剂清洗
const fluxClean: SelectFormItemConfig = {
  label: '助焊剂清洗',
  type: 'select',
  dataKey: 'fluxClean',
  optionKey: 'fluxClean',
  // ifRender: checkFlipChip,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkFlipChip(values)),
};

// 倒装芯片包封方法
const fcPackageMethod = (): SelectFormItemConfig => ({
  label: '倒装芯片包封方法',
  type: 'select',
  dataKey: 'fcPackageMethod',
  optionKey: 'fcPackageMethod',
  // ifRender: checkFlipChip,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkFlipChip(values)),
});

// 系统级封装产品信息
const sipProductInfo = (): FileFormItemConfig => ({
  label: '系统级封装产品信息',
  type: 'file',
  dataKey: 'sipProductInfo',
  // ifRender: checkSysPackage,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkSysPackage(values)),
  props: fileDefaultProps
});

// 线路层厚度
const metalLayerThickness: NumberFormItemConfig = {
  label: '线路层厚度',
  type: 'number',
  dataKey: 'metalLayerThickness',
  unit: 'µm',
  precision: 0,
  range: '(0, 30]',
  hint: '芯片线路层（从第1层到顶层）的总厚度',
  // ifRender: checkNoSysPackage,
  renderType: (values, initialValues) => renderTypeIncludesNone(values, initialValues, checkNoSysPackage(values)),
};

// 环保要求
const environmentalRequirement = (): SelectFormItemConfig => ({
  label: '环保要求',
  type: 'select',
  dataKey: 'requirement.environmentalRequirement',
  optionKey: 'environmentalRequirement',
  hint: '芯片应用领域所要求的环保强制性标准以及对封装材料选用的要求',
  renderType: 'preview'
});

// 防潮等级
const msl = (): SelectFormItemConfig => ({
  label: '防潮等级',
  type: 'select',
  dataKey: 'requirement.MSL',
  optionKey: 'MSL',
  placeholder: '建议MSL3',
  renderType: 'preview',
  hint: (
    <div>
      <p>防潮等级，即潮湿敏感度等级（Moisture Sensitivity Level）。</p>
      <p>MSL1，对应 ≤ 30℃/85%RH 无限车间寿命</p>
      <p>MSL2，对应 ≤ 30℃/60%RH 1年车间寿命</p>
      <p>MSL3，对应 ≤ 30℃/60%RH 168小时车间寿命</p>
      <p>MSL4，对应 ≤ 30℃/60%RH 72小时车间寿命</p>
      <p>MSL5，对应 ≤ 30℃/60%RH 48小时车间寿命</p>
      <p>(RH：Relative Humidity，相对湿度)</p>
    </div>
  )
});

// 温度循环实验标准
const tc = (): SelectFormItemConfig => ({
  label: '温度循环实验标准',
  type: 'select',
  dataKey: 'requirement.TC',
  optionKey: 'TC',
  hint: '判断产品在极高、极低温度下抗变力及在极高、极低温度中交替之效应，TCB条件为-55℃~125℃，TCC条件为-65℃~150℃',
  placeholder: '建议TCB500x',
  renderType: 'preview'
});

// 高加速温湿度实验标准
const hast = (): SelectFormItemConfig => ({
  label: '高加速温湿度实验标准',
  type: 'select',
  dataKey: 'requirement.HAST',
  optionKey: 'HAST',
  placeholder: '建议96h',
  hint: '利用高温、高湿、高压环境加速水汽渗透来评估非密闭性状态产品对水汽潮湿渗透的抵抗力',
  renderType: 'preview'
});

// 高温存储实验标准
const hts = (): SelectFormItemConfig => ({
  label: '高温存储实验标准',
  type: 'select',
  dataKey: 'requirement.HTS',
  optionKey: 'HTS',
  hint: '判断高温条件对产品的影响',
  placeholder: '建议150℃+1000h',
  renderType: 'preview'
});

// 导热要求
const thermalConductivityRequirement = (): SelectFormItemConfig => ({
  label: '导热要求',
  type: 'select',
  dataKey: 'requirement.thermalConductivityRequirement',
  optionKey: 'thermalConductivityRequirement',
  hint: '芯片是否需要特殊强化导热性能，如需要，可在“其他”栏里详细说明',
  renderType: 'preview'
});

// // 打印方式
// const markingMethod: SelectFormItemConfig = {
//   label: '打印方式',
//   type: 'select',
//   dataKey: 'marking.markingMethod',
//   optionKey: 'markingMethod',
//   renderType: 'preview'
// };

// 打印规则
const markingRule = (): InputFormItemConfig => ({
  label: '打印规则',
  type: 'input',
  dataKey: 'marking.markingRule',
  // hintType: 'modal',
  // hint: '图文描述',
  colSpan: 16,
  // required: checkIsCitedByMass(type),
  // renderType: (value) => get(value, 'marking.markingMethod') !== '不打印' ? 'normal' : 'none',
  renderType: (values, initialValues) => renderTypeIncludesNone(
    values,
    initialValues,
    get(values, 'marking.markingMethod') !== '不打印'
  ),
  validate: [{ pattern: /^.{0,1500}$/, message: '请输入最大长度为150的打印规则' }]
});

// 每管成品数量
const tubeQty = (index): NumberFormItemConfig => ({
  label: '每管成品数量',
  type: 'number',
  dataKey: 'tube.perPackQty',
  unit: '颗',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '管装'
  && get(initialValues, `packing.${index}.tube.perPackQty`) ? 'preview' : 'none'
});

// 每盒料管数量
const innerBoxTube = (index): NumberFormItemConfig => ({
  label: '每盒料管数量',
  type: 'number',
  dataKey: 'tube.perBoxQty',
  unit: '管',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '管装'
  && get(initialValues, `packing.${index}.tube.perBoxQty`) ? 'preview' : 'none'
});

// 每盘成品数量
const trayQty = (index): NumberFormItemConfig => ({
  label: '每盘成品数量',
  type: 'number',
  dataKey: 'tray.perPackQty',
  unit: '颗',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '盘装'
  && get(initialValues, `packing.${index}.tray.perPackQty`) ? 'preview' : 'none'
});

// 每盒托盘数量
const innerBoxTray = (index): NumberFormItemConfig => ({
  label: '每盒托盘数量',
  type: 'number',
  dataKey: 'tray.perBoxQty',
  unit: '盘',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '盘装'
  && get(initialValues, `packing.${index}.tray.perBoxQty`) ? 'preview' : 'none'
});

// 每袋成品数量
const bagQty = (index): NumberFormItemConfig => ({
  label: '每袋成品数量',
  type: 'number',
  dataKey: 'bag.perPackQty',
  unit: '颗',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '袋装'
  && get(initialValues, `packing.${index}.bag.perPackQty`) ? 'preview' : 'none'
});

// 每盒包装袋数量
const innerBoxBag = (index): NumberFormItemConfig => ({
  label: '每盒包装袋数量',
  type: 'number',
  dataKey: 'bag.perBoxQty',
  unit: '袋',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '袋装'
  && get(initialValues, `packing.${index}.bag.perBoxQty`) ? 'preview' : 'none'
});

// 每箱内盒数量
const innerBoxCarton = (index): NumberFormItemConfig => ({
  label: '每箱内盒数量',
  type: 'number',
  dataKey: 'perCartonQty',
  unit: '盒',
  renderType: (value, initialValues) => {
    const _packingMethod = get(value, `packing.${index}.packingMethod`);

    if (
      (_packingMethod === '管装' || _packingMethod === '盘装' || _packingMethod === '袋装')
      && get(initialValues, `packing.${index}.perCartonQty`)
    ) {
      return 'preview';
    }

    return 'none';
  }
});

// 载带宽度
const tapeWidth = (index): NumberFormItemConfig => ({
  label: '载带宽度',
  type: 'number',
  dataKey: 'tapeWidth',
  unit: 'mm',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '编带'
  && get(initialValues, `packing.${index}.tapeWidth`) ? 'preview' : 'none'
});

// 卷盘直径
const reelDiameter = (index): NumberFormItemConfig => ({
  label: '卷盘直径',
  type: 'number',
  dataKey: 'reelDiameter',
  unit: '吋',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '编带'
  && get(initialValues, `packing.${index}.reelDiameter`) ? 'preview' : 'none'
});

// 每卷成品数量
const reelQty = (index): NumberFormItemConfig => ({
  label: '每卷成品数量',
  type: 'number',
  dataKey: 'qtyReel',
  unit: '颗',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '编带'
  && get(initialValues, `packing.${index}.qtyReel`) ? 'preview' : 'none'
});

// 每箱卷带数量
const reelCarton = (index): NumberFormItemConfig => ({
  label: '每箱卷带数量',
  type: 'number',
  dataKey: 'reelCarton',
  unit: '卷',
  renderType: (value, initialValues) => get(value, `packing.${index}.packingMethod`) === '编带'
  && get(initialValues, `packing.${index}.reelCarton`) ? 'preview' : 'none'
});

// 包装方式-其他
const packingRequirementOther: TextAreaFormItemConfig = {
  label: '其他',
  type: 'textArea',
  dataKey: 'other',
  colSpan: 24,
  renderType: 'preview',
  validate: [{ pattern: /^[\d\D]{0,100}$/, message: '请输入最大长度为100的其他描述' }]
};

// 芯片封装要求
export const getPackageInfoByDie = (): RepeaterConfig => ({
  type: 'repeater',
  dataKey: 'dies',
  addNewBtnText: '添加芯片封装要求',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams) {
    return ({
      card: {
        title: RepeaterCardTitle('芯片封装要求', params.index, params.len),
      },
      groupItemConfig: [
        dieOrBumpName(params.index),
        // assemblyDieInfoDivider(params.index),
        // dieInfo(params.index),
        getCollapseDieInfo(params.index),
        // divider,
        dieQtyInPackage,
        finalDieThickness(),
        sawScheme,
        wireType(),
        wireDiameter(),
        dieAttachMaterial(),
        electronConductivity(),
        reflowPeakTemp(),
        dwellTime(),
        fluxType(),
        fluxClean,
        fcPackageMethod(),
        sipProductInfo(),
        metalLayerThickness,
        other(),
      ]
    });
  },
});

// 封装类型
const getPackageType = (): FormItemConfig[] => [
  packageTypeInfo(),
  packageTypeLW(),
  otherPackageType(),
  carrierType(),
  solderBallComposition(),
  solderBallDiameter()
];

// 环保、可靠性及导热要求
export const getEnvironmentalThermal = (): FormItemConfig[] => [
  environmentalRequirement(),
  msl(),
  tc(),
  hast(),
  hts(),
  thermalConductivityRequirement(),
  other('requirement'),
];

// 打印要求
export const getMarkingRequirement = (): FormItemConfig[] => [
  // markingMethod,
  markingRule()
];

// 包装要求
const getPackingRequirement = (): RepeaterConfig => ({
  type: 'repeater',
  dataKey: 'packing',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams) {
    return ({
      card: {
        title: RepeaterCardTitle('包装要求', params.index, params.len),
      },
      groupItemConfig: [
        packingMethod(EnumProductModel.assemblyProduct),
        divider,
        tubeQty(params.index),
        innerBoxTube(params.index),
        trayQty(params.index),
        innerBoxTray(params.index),
        bagQty(params.index),
        innerBoxBag(params.index),
        innerBoxCarton(params.index),
        tapeWidth(params.index),
        reelDiameter(params.index),
        reelQty(params.index),
        reelCarton(params.index),
        packingRequirementOther
      ]
    });
  },
});

export const getAssemblyProductFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: {
        title: '基本信息',
        className: commonStyles.hiddenCardTitle
      },
      groupItemConfig: [
        getCustomerName('封装成品名'),
        principal,
        participants
      ]
    },
    {
      card: {
        title: '封装类型',
      },
      groupItemConfig: getPackageType()
    },
    getPackageInfoByDie(),
    {
      card: {
        title: '环保、可靠性及导热要求',
      },
      groupItemConfig: getEnvironmentalThermal()
    },
    {
      card: {
        title: '打印要求',
      },
      groupItemConfig: getMarkingRequirement()
    },
    getPackingRequirement(),
  ],
});
// tslint:disable:max-file-line-count
