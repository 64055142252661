/**
 * 切换用户角色
 */
import * as React from 'react';
import { Spin } from 'antd';
import log from 'loglevel';
import { connect } from 'react-redux';
import { SelectWithOption } from '@chipcoo/hanayo';

import { RootDispatch } from 'src/store';
import styles from './index.module.less';
import { i18nMembers } from 'src/models/org/handler';
import { MemberOperation } from 'src/models/org/interface';

interface OwnProps {
  value: string;
  userId: number;
  orgId: string;
}
interface StateProps {
}
interface DispatchProps {
  setMemberRole: (arg: MemberOperation) => any;
}
interface ChangeRoleSelectProps extends OwnProps, StateProps, DispatchProps {}
interface ChangeRoleSelectState {
  loading: boolean;
}

const options = [
  { value: 'ADMIN', label: i18nMembers.ADMIN },
  { value: 'MEMBER', label: i18nMembers.MEMBER }
];

class ChangeRoleSelect extends React.PureComponent<ChangeRoleSelectProps, ChangeRoleSelectState> {
  private isUnmount: boolean = false;

  constructor(props: ChangeRoleSelectProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentWillUnmount(): void {
    this.isUnmount = true;
  }

  handleChangeRole = async (value) => {
    const { setMemberRole, orgId, userId } = this.props;

    this.setState({ loading: true });

    try {
      await setMemberRole({ orgId, userId, role: value });
    } catch (e) {
      log.error(e);
    }

    !this.isUnmount && this.setState({ loading: false});
  }

  render() {
    const { value } = this.props;
    const { loading } = this.state;

    return (
      <Spin delay={100} spinning={loading}>
        <SelectWithOption
          className={styles.changeRole}
          value={value}
          options={options}
          allowClear={false}
          onChange={this.handleChangeRole}
        />
      </Spin>
    );
  }
}

const mapDispatch = ({ org: { patchOrgMember } }: RootDispatch) => ({
  setMemberRole: patchOrgMember,
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(ChangeRoleSelect);
