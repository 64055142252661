/**
 * 带@人的输入框
 */
import * as React from 'react';
import { bind } from 'lodash-decorators';

import { HoneyMentions, HoneyMentionsProps } from '@chipcoo/hanayo';
import { CommentBoxContext } from '../../context';

import styles from './index.module.less';
import { ExtraMentionProps, SuggestObject } from '../../interface';

interface Props extends HoneyMentionsProps, ExtraMentionProps {
  value: string;
  onChange: HoneyMentionsProps['onChange'];
  onSubmit: (e: any) => any;
  onSelectMention?: (mentionData: any) => any;
}

interface S {
  value: any;
}

class TextAreaWithMention extends React.PureComponent<Props, S> {
  private isEnterPressed = false;
  static contextType = CommentBoxContext;

  public context!: Required<React.ContextType<typeof CommentBoxContext>>;

  constructor(props: Props) {
    super(props);

    this.state = {
      value: void 0
    };
  }

  // 当输入框激活的情况下，处理键盘事件, 13是enter，16是shift
  handleKeyDown: HoneyMentionsProps['onKeyDown'] = (e, measuring) => {
    if (measuring) {
      return;
    }

    const keyCode = e.keyCode;

    if (keyCode === 13) {
      // 如果按键被按下的时候isEnterPressed为真，表明用户一直没有松开，排除
      if (this.isEnterPressed) {
        e.preventDefault();
        return;
      }

      // 此时回车第一次被按下，检查shift是否有被按下，如果被按下了不做任何操作，否则发送消息
      this.isEnterPressed = true;

      if (!e.shiftKey) {
        e.preventDefault();
        this.props.onSubmit(e);
      }
    }
  }

  handleKeyUp = e => {
    const keyCode = e.keyCode;

    if (keyCode === 13) {
      this.isEnterPressed = false;
    }
  }

  renderSuggest(suggest: string): JSX.Element;
  renderSuggest(suggest: SuggestObject): JSX.Element;
  @bind
  renderSuggest() {
    const suggest = arguments[0];
    const { renderSuggestItem } = this.props;
    let suggestObj: SuggestObject = suggest;

    if (typeof suggest === 'string') {
      suggestObj = {
        value: suggest,
        label: suggest,
      };
    }

    if (renderSuggestItem) {
      return renderSuggestItem(suggestObj);
    }

    const { value, label } = suggestObj;

    return (
      <HoneyMentions.Option key={value} optionKey={value} value={value}>
        <span>{label}</span>
      </HoneyMentions.Option>
    );
  }

  render() {
    const { onSubmit, onSelectMention, renderSuggestItem, ...props } = this.props;
    const { suggestionData = [], displaySuggests } = this.context;
    return (
      <div className={styles.textarea}>
        <HoneyMentions
          {...props}
          mentionEnabled={displaySuggests}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          value={this.props.value}
          onChange={this.props.onChange}
        >
          {(displaySuggests ? (suggestionData as any[]).map(this.renderSuggest).filter(it => it !== null) : null)}
        </HoneyMentions>
      </div>
    );
  }
}

export default TextAreaWithMention;
