import * as React from 'react';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  NumberGroupFormItemConfig,
  SelectFormItemConfig,
  TextAreaFormItemConfig,
  ModalMode,
} from '@chipcoo/hanayo/lib/FormCreator';
import { getMomentTime } from '@chipcoo/fe-utils';

const getTSMCRenderType = (name: string, renderType?) => {
  if (name) {
    const index1 = name.indexOf('台积电');
    const index2 = name.indexOf('TSMC');

    if (index1 >= 0 || index2 >= 0) {
      if (renderType) return renderType;

      return 'normal';
    }

    return 'none';
  }

  return 'none';
};

const busId: InputFormItemConfig = {
  label: '班车id',
  type: 'input',
  dataKey: 'mpwBusId',
  colClassName: 'hidden-form-item',
};

const factory: InputFormItemConfig = {
  label: '工厂名',
  type: 'input',
  dataKey: 'factoryName',
  colClassName: 'hidden-form-item',
};

const shuttleCode: InputFormItemConfig = {
  label: 'shuttle code',
  type: 'input',
  dataKey: 'busCode',
  renderType: 'preview'
};

const blockSize: InputFormItemConfig = {
  label: 'block size',
  type: 'input',
  dataKey: 'blockSize',
  renderType: 'preview'
};

const dieNum: InputFormItemConfig = {
  label: 'die num',
  type: 'input',
  dataKey: 'dieNum',
  renderType: 'preview'
};

const contactWindow = (mode: ModalMode): InputFormItemConfig => ({
  label: 'Contact window',
  type: 'input',
  dataKey: 'contact',
  renderType() {
    return mode === 'add' ? 'none' : 'preview';
  },
});

const geometry: InputFormItemConfig = {
  label: 'Geometry',
  type: 'input',
  dataKey: 'node',
  renderType: 'preview',
};

const tapeOutDate: InputFormItemConfig = {
  label: 'Tape-out-date',
  type: 'input',
  dataKey: 'date',
  renderType: 'preview',
  render() {
    const dateText = getMomentTime(this.formData?.date);

    return <div>{dateText}</div>;
  }
};

const technologyType: InputFormItemConfig = {
  label: 'Technology type',
  type: 'input',
  dataKey: 'process',
  renderType: 'preview'
};

const fab: InputFormItemConfig = {
  label: 'FAB',
  type: 'input',
  dataKey: 'fab',
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName, 'preview');
  },
};

const orderDate = (mode: ModalMode): InputFormItemConfig => ({
  label: '预订时间',
  type: 'input',
  dataKey: 'createdAt',
  renderType() {
    return mode === 'add' ? 'none' : 'preview';
  },
});

const chipcooName: InputFormItemConfig = {
  label: '芯翼产品名',
  type: 'input',
  dataKey: 'chipcooName',
  renderType: 'preview',
};

const customerProduct: InputFormItemConfig = {
  label: 'Customer Product name',
  type: 'input',
  dataKey: 'productName',
  // required: true,
};

const voltage: InputFormItemConfig = {
  label: 'Voltage usage list',
  type: 'input',
  dataKey: 'voltage',
  required: true,
};

const polyMetal: NumberGroupFormItemConfig = {
  label: 'poly layer * metal layer',
  type: 'numberGroup',
  dataKey: 'polyMetal',
  required: true,
  separator: '*',
  groupItem: [
    {
      placeholder: 'poly层数',
      range: '[1, 5]',
      requiredMsg: '请输入poly层数',
    },
    {
      placeholder: 'metal层数',
      range: '[2, 11]',
      requiredMsg: '请输入metal层数',
    }
  ]
};

const chipSize: NumberGroupFormItemConfig = {
  label: 'Chip Size',
  type: 'numberGroup',
  dataKey: 'chipSize',
  unit: 'µm',
  precision: 0,
  groupItem: [
    {},
    {}
  ]
};

const extraWafer: SelectFormItemConfig = {
  label: 'Extra laser wafer',
  type: 'select',
  dataKey: 'extraLaserWafer',
  optionKey: 'yesNo',
  required: true,
  hint: 'MPW 给到客户手中的一般是FAB 划片划好的固定数量的die。假如客户还需要额外的晶圆，FAB也可以提供激光烧蚀过其他客户电路图形的晶圆',
  customHintZIndex: 2500
};

const extraLaserWaferNum: InputFormItemConfig = {
  label: 'extra laser wafer num',
  type: 'input',
  dataKey: 'extraLaserWaferNum',
  renderType() {
    return this.formData?.extraLaserWafer === 'true' ? 'normal' : 'none';
  }
};

// const application: TextAreaFormItemConfig = {
//   label: 'Application',
//   type: 'textArea',
//   dataKey: 'application',
//   colSpan: 24,
// };

const extraDieSaw = (): SelectFormItemConfig => ({
  label: 'Extra Die saw',
  type: 'select',
  dataKey: 'extraDieSaw',
  optionKey: 'yesNo',
  required: true,
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName);
  }
});

const totalDieNum: SelectFormItemConfig = {
  label: 'total die num',
  type: 'select',
  dataKey: 'totalDieNum',
  optionKey: 'totalDieNum',
  renderType() {
    return this.formData?.extraDieSaw === 'true' ? 'normal' : 'none';
  }
};

const splitCondition = (): SelectFormItemConfig => ({
  label: 'Split condition（Corner）',
  type: 'select',
  dataKey: 'splitCondition',
  optionKey: 'yesNo',
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName);
  }
});

const ipMerge = (): SelectFormItemConfig => ({
  label: 'IP merge',
  type: 'select',
  dataKey: 'ipMerge',
  optionKey: 'yesNo',
  required: true,
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName);
  }
});

const tsmcBump = (): SelectFormItemConfig => ({
  label: 'TSMC bump',
  type: 'select',
  dataKey: 'tsmcBump',
  optionKey: 'tsmcBump',
  required: true,
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName);
  }
});

const backgrind = (): InputFormItemConfig => ({
  label: 'Backgrind',
  type: 'input',
  dataKey: 'backgrind',
  hint: '1mil=25.4um',
  customHintZIndex: 2500,
  renderType() {
    return getTSMCRenderType(this.formData?.factoryName);
  }
});

const application = (): TextAreaFormItemConfig => ({
  label: 'Application',
  type: 'textArea',
  dataKey: 'application',
  required: true,
  colSpan: 24,
  customHintZIndex: 2500,
  placeholder: '请准确填写芯片的功能是什么，使用场景和使用设备是什么'
  // renderType() {
  //   return getTSMCRenderType(this.formData?.factoryName);
  // }
});

const remark: TextAreaFormItemConfig = {
  label: 'Remark',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
};

export const getGroupItemConfig = (mode: ModalMode) => [
  busId,
  factory,
  shuttleCode,
  blockSize,
  dieNum,
  contactWindow(mode),
  geometry,
  tapeOutDate,
  technologyType,
  fab,
  orderDate(mode),
  chipcooName,
  { type: 'divider' },
  customerProduct,
  voltage,
  polyMetal,
  chipSize,
  extraWafer,
  extraLaserWaferNum,
  extraDieSaw(),
  totalDieNum,
  splitCondition(),
  ipMerge(),
  tsmcBump(),
  backgrind(),
  application(),
  remark,
];

export const getFormConfig = (mode: ModalMode): FormConfig => ({
  cardGroupConfig: [
    {
      groupItemConfig: [
        ...getGroupItemConfig(mode)
      ] as FormItemConfig[]
    },
  ]
});
// tslint:disable:max-file-line-count
