/**
 * 处理邀请
 */
import * as React from 'react';
import log from 'loglevel';
import { connect } from 'react-redux';

import store, { RootDispatch } from 'src/store';
import styles from './index.module.less';
import { PatchFriendApplyParams } from 'src/models/friend/interface';
import { PatchOrgInvitationApplyParams } from 'src/models/notification/interface';

import InvitationUserDetail from './InvitationUserDetail';
import { HocUserCard, OkCancelBtnGroup } from 'src/components';
import { message as antdMessage } from 'antd';

interface OwnProps {
  detailData: any;
}
interface StateProps {}
interface DispatchProps {
  patchFriendApply: (params: PatchFriendApplyParams) => void;
  updateNotifyState: (parmas: { [key: string]: any }) => void;
  patchOrgInvitationApply: (params: PatchOrgInvitationApplyParams) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class HandleInvitation extends React.PureComponent<P, S> {
  handleInvitation = async (e, type: string, action: 'reject' | 'accept') => {
    e.stopPropagation();
    let result;
    const { detailData, updateNotifyState, patchFriendApply, patchOrgInvitationApply } = this.props;

    switch (type) {
      // 好友邀请
      case 'friend':
        const { friendId } = JSON.parse(detailData.state);
        result = await patchFriendApply({ friendId, action });
        break;
      // 组织邀请
      case 'org':
        try {
          result = await patchOrgInvitationApply({
            noticeId: detailData._id,
            result: action,
            config: {
              handleSpecialError: {
                404: () => {
                  //
                  result = 'FAIL';
                  antdMessage.error('加入组织失败，该组织可能已被解散');
                  return false;
                }
              }
            },
          });

          if (action === 'accept') {
            // 手动获取一次组织列表
            store.dispatch.org.getOrgList();
          }
        } catch (e) {
          log.error(e);
        }
        break;
      default:
        break;
    }

    if (result) {
      const state = JSON.parse(detailData.state);
      const newState = JSON.stringify({
        ...state,
        result: result === 'FAIL' ? 'FAIL' : action
      });

      updateNotifyState({ path: 'oneNotifyDetail.state', data: newState });
    }
  }

  render() {
    const { detailData } = this.props;
    const { message, state, category } = detailData;
    const user = detailData.fromUser as User;
    const { result = '', friendId, orgId } = JSON.parse(state);
    const type = (orgId && 'org') || (friendId && 'friend');
    const accepted = /^accept$/i.test(result);
    const rejected = /^reject$/i.test(result);
    const fail = /^fail$/i.test(result);

    return (
      <div className={styles.handleInvitationWrapper}>
        <header className={styles.header}>
          <HocUserCard userInfo={user} otherContent={message} className="flex-fill" />

          {(() => {
            if (!result) {
              return (
                <OkCancelBtnGroup
                  onOk={(e) => this.handleInvitation(e, type, 'accept')}
                  onCancel={(e) => this.handleInvitation(e, type, 'reject')}
                  okText="同意"
                  cancelText="拒绝"
                />
              );
            }
            let text = accepted ? '已接受' : (rejected ? '已拒绝' : '');
            text = fail ? '已失效' : text;
            return (
              <p className="flex-static">{text}</p>
            );
          })()}
        </header>
        <div className={styles.content}>
          {category === 'invitation' && <InvitationUserDetail userInfo={user} />}
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ friend, notification }: RootDispatch) => ({
  patchFriendApply: friend.patchFriendApply,
  updateNotifyState: (notification as any).updateState,
  patchOrgInvitationApply: notification.patchOrgInvitationApply
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch as any)(HandleInvitation);
