/**
 * 对于antd头像的增强，默认头像显示为名字
 */
import * as React from 'react';
import { Avatar, Tooltip } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import md5 from 'blueimp-md5';
import { toUpper } from 'lodash';
import classnames from 'classnames';
import { isChinese } from '@chipcoo/fe-utils';

import './style.less';

interface P extends AvatarProps {
  userName: string | undefined;
  hasToolTip?: boolean;
  styleName?: string;
  userDisabled?: boolean | (() => boolean);
}
interface S {
  bgColor: string;
  name: string | undefined;
  shortName: string;
  userDisabled: boolean;
}

const bgColorList = [
  '#C48ACF', '#F3A182', '#CDD181', '#F4CF5A', '#5BC3EF'
];
const temp = [
  ...new Array(10).fill(null).map((m, i) => `${i}`),
  'a', 'b', 'c', 'd', 'e', 'f'
];
export const getColor = (name: string) => {
  if (!name) { return bgColorList[0]; }

  const index = temp.indexOf(md5(name)[0]) % 5;

  return bgColorList[index];
};
export const getShortName = (name: string) => {
  if (!name) { return 'X'; }

  if (name.length <= 1) { return toUpper(name); }

  return isChinese(name) ? name.slice(-2) : toUpper(name.slice(0, 2));
};

// 缓存所有曾经计算过的头像，对于一个name如果计算过了就不需要重复计算
type CachedAvatarInfo = {[name: string]: { bgColor: string; shortName: string }};
const cachedAvatarInfo: CachedAvatarInfo = {};

// 计算并缓存头像信息
export const computeAvatarInfo = (name: string) => {
  if (!name) { return { bgColor: bgColorList[0], shortName: 'X' }; }

  if (cachedAvatarInfo[name]) { return cachedAvatarInfo[name]; }

  const avatarInfo = { bgColor: getColor(name!), shortName: getShortName(name!) };

  cachedAvatarInfo[name] = avatarInfo;

  return avatarInfo;
};

function getUserDisabled(props: P) {
  let disabled = !!props.userDisabled;

  if (typeof props.userDisabled === 'function') {
    disabled = props.userDisabled();
  }

  return disabled;
}

class EnhanceAvatar extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};
    const { userName } = nextProps;

    if (userName !== prevState.name) {
      Object.assign(nextState, { name: userName, ...computeAvatarInfo(userName!) });
    }

    nextState.userDisabled = getUserDisabled(nextProps);

    return nextState;
  }

  constructor(props: P) {
    super(props);

    const { userName } = this.props;

    this.state = { name: userName, ...computeAvatarInfo(userName!), userDisabled: getUserDisabled(props) };
  }

  renderContent() {
    const { userDisabled } = this.state;
    const className = classnames(
      'enhanced-avatar',
      userDisabled && 'disabled-user'
    );

    return (
      <span className={className}>
        {this.renderAvatar()}
        {<i key="user-disabled-icon" className="icon-reduce-circle-o c-icon user-disabled-icon" />}
      </span>
    );
  }

  renderAvatar = () => {
    const { bgColor, shortName } = this.state;
    const { userName, hasToolTip, src, userDisabled, ...passThoughProps } = this.props as any;

    if (src) return <Avatar src={src} {...passThoughProps} />;

    let tempProps: any = {};

    if (bgColor) tempProps.style = { backgroundColor: bgColor };

    if (!shortName) tempProps.icon = 'user';

    return (
      <Avatar {...tempProps} {...passThoughProps}>
        {shortName}
      </Avatar>
    );
  }

  render() {
    const { hasToolTip, userName } = this.props;

    if (hasToolTip && userName) {
      return (
        <Tooltip title={userName} mouseEnterDelay={0.5}>
          {this.renderContent()}
        </Tooltip>
      );
    }

    return this.renderContent();
  }
}

export default EnhanceAvatar;
