
const serial = {
  title: '入库申请单号',
  key: 'serial',
  dataIndex: 'serial',
};

const createdAt = {
  title: '申请时间',
  key: 'createdAt',
  dataIndex: 'createdAt',
};

const materialName = {
  title: '物料名称',
  key: 'material',
  dataIndex: 'material',
};

const productName = {
  title: '客户产品名称',
  key: 'productName',
  dataIndex: 'productName',
};

const productModel = {
  title: '产品类型',
  key: 'productModel',
  dataIndex: 'productModel',
};

export const getTableColumns = () => [
  serial,
  createdAt,
  materialName,
  productName,
  productModel
];
