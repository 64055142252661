/**
 * 报告查询 - WIP列表
 */
import React, { PureComponent } from 'react';
import _, { get } from 'lodash';
import classnames from 'classnames';
import { ColumnProps } from 'antd/lib/table';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import log from 'loglevel';

import { connectDecorator, RootState, Actions } from 'src/store';
import CardExtra from './components/CardExtra';
import { filterFormConfig } from './filterFormConfig';
import { getTableColumns } from './components/getTableColumns';
import styles from './index.module.less';

const { EnhancedTable, QueryTermTags } = CardTableV2;

// tslint:disable-next-line:class-name
interface ownProps {}

interface StateProps {
  list: any[];
  count: number;
  detail: Object;
}

interface DispatchProps {
  getTableList: (params: any) => void;
}

type IProps = ownProps & StateProps & DispatchProps;

interface IState {
  tableLoading: boolean;
  tableColumns: ColumnProps<any>[];
}

@connectDecorator(
  ({ wip: { list, count } }: RootState) => ({ list, count }),
  ({ wip: { getTableList } }: Actions) => ({ getTableList })
)

class WIPTable extends PureComponent<IProps, IState> {
  private cardTableController: ICardTableController;
  queryFormConfig: FormItemConfig[] = filterFormConfig();

  constructor(props: IProps) {
    super(props);

    this.state = {
      tableLoading: false,
      tableColumns: getTableColumns({}),
    };
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getTerms = (query: Obj) => {
    const config = filterFormConfig();
    return config.reduce((result, curCfg) => {
      const dataKey = get(curCfg, 'dataKey');

      if (!dataKey) {
        return result;
      }

      let val = get(query, dataKey);
      if (_.isObject(val)) {
        val = get(val, 'label');
      }

      if (val) {
        const label = get(curCfg, 'label');
        const getDisplay = get(curCfg, 'getDisplay');

        if (getDisplay) {
          val = getDisplay(val);
        }

        result[label] = val;

        if (val && ['string', 'number'].indexOf(typeof val) === -1) {
          log.warn('[getTerms]: 未被正确转换的查询条件展示', label, val, curCfg);
          Reflect.deleteProperty(result, label);
        }
      }

      return result;
    }, {} as any);
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          default:
            prev[key] = value ? value : undefined;
            break;
        }

        return prev;
      },
      {} as any
    );

    const queryParams: any = {
      ..._formData,
      ...extraParams,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }
    this.setState({ tableLoading: false });
  }

  render() {
    const { list, count } = this.props;
    const { tableColumns, tableLoading } = this.state;

    return (
      <div className={classnames('wrap-content', styles.wipList)}>
        <CardTableV2
          card={{
            title: 'WIP列表',
            extra: (
              <CardExtra openQuery={this.openQuery} />
            )
          }}
          queryFormConfig={this.queryFormConfig}
          getCardTableController={this.getCardTableController}
        >
          <QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={tableColumns}
            scroll={{ x: 2350 }}
            clickMention={true}
          />
        </CardTableV2>
      </div>
    );
  }
}

export default WIPTable;
