/**
 * 聊天输入框组件，可用在im，评论等地方
 */
import * as React from 'react';
import { isEqual } from 'lodash';
import classnames from 'classnames';

import styles from './index.module.less';

import MsgBox from './MsgBox';
import { 
  ChatInputBoxProps, 
  CommentBoxContextProps, 
} from './interface';
import { CommentBoxContext, initialCommentBoxCtx } from './context';

export * from './interface';
export * from './context';

interface State {
  ctx: CommentBoxContextProps;
}

const watchKeys = Object.keys(initialCommentBoxCtx);

const { Provider } = CommentBoxContext;

class CommentBox extends React.PureComponent<ChatInputBoxProps, State> {
  static defaultProps = {
    suggestionData: [],
    uploaderOptions: {},
    // uploaderEventsHandler: {},
  };

  // private commentBoxDom: null | HTMLElement = null;

  static getDerivedStateFromProps(nextProps: ChatInputBoxProps, prevState: State) {
    const { ctx } = prevState;

    const next: any = { ctx: {} };

    watchKeys.forEach(function(key: string) {
      if (!isEqual(ctx[key], nextProps[key])) {
        next.ctx[key] = nextProps[key];
      }
    });

    next.ctx = { ...ctx, ...next.ctx };

    return next;
  }

  constructor(props: ChatInputBoxProps) {
    super(props);

    const { suggestionData, displaySuggests, uploaderOptions, prepareUpload } = props;

    this.state = {
      ctx: {
        suggestionData: suggestionData!,
        displaySuggests,
        uploaderOptions: uploaderOptions!,
        prepareUpload,
      }
    };
  }

  render() {
    const { ctx } = this.state;
    const { className, prepareUpload, suggestionData, ...props } = this.props;

    return (
      <div
        className={classnames(className, styles.inputBoxWrapper)}
      >
        <Provider value={ctx}>
          <MsgBox {...props} />
        </Provider>
      </div>
    );
  }
}

export default CommentBox;
