/**
 * 报告查询
 */
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import SiderMenuRoute from '../SiderMenuRoute';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';
import { getRouterProps } from 'src/components/HoneyRouter';
import WIP from './WIP';

interface IProps extends RouteComponentProps<any> {}

let menus: RouteMenuItem[] = [];
function fillMenu() {
  menus = [
    {
      title: 'WIP',
      routeName: ROUTE_NAMES.ORG_RD_QUERY_REPORT_WIP,
      routeProps: {
        component: WIP,
        exact: true,
      }
    },
  ];
}

setTimeout(fillMenu);

class QueryReport extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);

    if (!menus.length) {
      fillMenu();
    }
  }
  render() {
    const routeProps = getRouterProps(this.props);

    return (
      <SiderMenuRoute menus={menus} routerProps={routeProps} />
    );
  }
}

export default QueryReport;
