/**
 * Created by marvin on 2019/2/13.
 */
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { isArray } from 'lodash';

import appList, { fillAppList } from './appList';
import { getCurrentRoute } from 'src/components/HoneyRouter';

import RDManageAppList from './RDManageAppList';

interface Props extends RouteComponentProps<any> {}

class Operation extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    const a = appList.some(it => {
      if (it?.children && isArray(it?.children)) {
        return it?.children.some(c => !c?.route);
      }

      return !it?.route;
    });

    if (a) {
      fillAppList();
    }
  }

  render () {
    const { match: { params } } = getCurrentRoute()!;

    return (
      <RDManageAppList dataSource={appList} params={params} />
    );
  }
}

export default Operation;

export { default as MPWShuttle } from './MPWShuttle';
export { default as MPWPreOrder } from './MPWPreOrder';
export { default as NTO } from './NTO';
export { default as RDInventory } from './InventoryManage';
export { default as Shipment } from './Shipment';
export { default as QueryReport } from './QueryReport';
export { default as FAQ } from './FAQ';
export { default as NewTest } from './NewTest';
