/**
 * 封装产品库左侧的列表
 */
import React, { PureComponent } from 'react';
import classNames from 'classnames';

import styles from './index.module.less';

interface P {
  type?: string;
  isFocused: boolean;
  setUrl: (id: string) => void;
  providerInfo: any;
  infoText?: string;
}
interface S {}

class ListItem extends PureComponent<P, S> {
  handleClick = (e) => {
    e.stopPropagation();

    const { setUrl, providerInfo, type } = this.props;
    const providerId = type === 'customer' && providerInfo.organization
      ? providerInfo.organization : providerInfo._id;

    setUrl(providerId);
  }

  render() {
    const { providerInfo, isFocused, infoText } = this.props;
    const { shortName, name } = providerInfo;

    return (
      <div
        className={classNames(styles.listItemWrapper, { 'list-active': isFocused })}
        onClick={this.handleClick}
      >
        <div className={styles.listItem}>
          <p>{shortName || name}</p>
          <p className="info">{infoText}</p>
        </div>
      </div>
    );
  }
}

export default ListItem;
