import { createModel } from '@rematch/core';
import produce from 'immer';

import { TableModelCommonEffects } from 'src/models/type';
import { qcMonitorApi } from 'src/services/net';
import { State } from './interface';
import {
  handleMonitorRuleGroupList,
  handleMonitorRuleGroupDetail
} from './handle';

const initialState: State = {
  list: [],
  count: 0,

  monitorRuleGroup: {},
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload.data;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.monitorRuleGroup = payload;
      });
    },
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await qcMonitorApi.getMonitorRuleGroup(params);
      const handleList = handleMonitorRuleGroupList(data?.data);

      this.setList({ data: handleList, count: data?.count });
    },
    async getTableItemDetail(_id: string) {
      const { data } = await qcMonitorApi.getMonitorRuleGroupById(_id);
      const handleData = handleMonitorRuleGroupDetail(data);

      this.setDetail(handleData);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
  })
});
