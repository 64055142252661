import React, { ComponentType } from 'react';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { FlexModal, IFlexModalProps } from '@chipcoo/hanayo/lib/flex-modal';
import { noop } from '@chipcoo/fe-utils';
import { EnumProductModel } from '@chipcoo/constant';
import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';

import style from './index.module.less';
import { Actions, connectDecorator, RootState } from 'src/store';
import { ProductInfoCxt } from './context';

import RightComment from '../components/RightComment';
import ProductForm from '../ProductForm';
import ProductCraftRoute from '../ProductCraftRoute';
import ProductProcess from '../ProductProcess';
import { getAssemblyProductFormConfig, getDpProductConfig, getSpwDieFormConfig } from '../formConfig';

export enum EnumTabKey {
  // 产品信息
  productTab = 'PRODUCT_TAB',
  // 订购信息
  // orderTemplateTab = 'ORDER_TEMPLATE_TAB',
  // 工艺路线
  craftTab = 'CRAFT_TAB',
  // 制程
  processTab = 'PROCESS_TAB',
}

interface OwnProps {
  visible: boolean;
  productId: string;
  // detail: Obj;
  modalName: string;
  onOk?: IFlexModalProps['onOk'];
  onCancel?: IFlexModalProps['onCancel'];
  onAfterClose?: IFlexModalProps['onAfterClose'];

  /** 仅在没有id的时候起作用 */
  localDetail?: Obj;
}
interface StateProps {
  detail: Obj;
}
interface DispatchProps {
  patchModify: (formData: any) => void;
  getTableItemDetail: (_id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;
export interface IProductModalProps extends OwnProps {}

interface State {
  modalFullWindow: boolean;
  commentVisible: boolean;
  tabActiveKey: EnumTabKey;
}

const { TabPane } = Tabs;

@connectDecorator(
  ({ product: { detail } }: RootState) => ({
    detail
  }),
  ({ product: { getTableItemDetail, patchModify } }: Actions) => ({ getTableItemDetail, patchModify })
)
class ProductModal extends React.PureComponent<Props, State> {
  private formController;

  constructor(props: Props) {
    super(props);

    this.state = {
      modalFullWindow: true,
      commentVisible: false,
      tabActiveKey: EnumTabKey.productTab,
    };
  }

  async componentDidMount() {
    const { productId, localDetail, getTableItemDetail } = this.props;

    if (productId && !localDetail) {
      await getTableItemDetail(productId);
    }
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  onRequestFullWindow = () => {
    this.setState({ modalFullWindow: true });
  }

  onRequestNormalWindow = () => {
    this.setState({ modalFullWindow: false });
  }

  onCancel = (e) => {
    return this.props.onCancel && this.props.onCancel(e);
  }

  handleSubmit = (e: any) => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      const { validateFieldsAndScroll } = this.formController;

      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            const { patchModify, productId, onOk } = this.props;

            if (productId) {
              await patchModify({ ...values, _id: productId });
            } else {
              // await postCreate(values);
            }
            resolve(true);

            onOk && onOk(err);
          } catch (e) {
            console.error(e);
            reject(e);
          }
        }
      });
    });
  }

  changeCommentVisible = (bool) => this.setState({ commentVisible: bool });

  renderComment = () => {
    const { detail, productId, modalName } = this.props;

    if (!detail) {
      return null;
    }

    return (
      <RightComment
        detailId={productId}
        modalName={modalName}
        changeCommentVisible={this.changeCommentVisible}
      />
    );
  }

  onTabClick = (tabKey: EnumTabKey) => {
    if (tabKey === this.state.tabActiveKey) return;

    this.setState({ tabActiveKey: tabKey });
  }

  getCategoryConfig = () => {
    const { localDetail, productId, detail } = this.props;
    const productModel = (localDetail ?? detail)?.[productId]?.productModel;

    switch (productModel) {
      case EnumProductModel.dpProduct:
        return {
          title: '磨划成品',
          formConfig: getDpProductConfig() as FormConfig,
        };
      case EnumProductModel.assemblyProduct:
        return {
          title: '封装成品',
          formConfig: getAssemblyProductFormConfig()  as FormConfig,
        };
      default:
        return {
          title: 'SPW芯片',
          formConfig: getSpwDieFormConfig(),
        };
    }
  }

  renderProductForm() {
    const { detail, productId } = this.props;

    return (
      <ProductForm
        detail={detail}
        productId={productId}
        formConfig={this.getCategoryConfig()?.formConfig}
        getFormCtrl={ctrl => this.formController = ctrl}
      />
    );
  }

  renderCraftRouteEdit = () => {
    const { productId } = this.props;

    if (!productId) {
      return null;
    }

    return (
      <ProductCraftRoute
        // ref={this.tabCompRefs[EnumTabKey.craftTab]}
        // modalName={this.modalName}
        productId={productId}
      />
    );
  }

  renderProductProcessEdit = () => {
    const { productId } = this.props;
    if (!productId) {
      return null;
    }

    return (
      <ProductProcess
        productId={productId}
      />
    );
  }

  render () {
    const { visible, modalName, detail, productId } = this.props;
    const { commentVisible, modalFullWindow, tabActiveKey } = this.state;

    return (
      <ProductInfoCxt.Provider value={{ productInfo: detail?.[productId] }}>
        <FlexModal
          width={950}
          animation="fade"
          visible={visible}
          modalName={modalName}
          className={classnames(style.modal, commentVisible ? '' : 'full-center')}
          onCancel={this.onCancel}
          onOk={this.handleSubmit}
          title={`${this.getCategoryConfig()?.title}详情`}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
          rightSection={this.renderComment()}
          footer={tabActiveKey === EnumTabKey.productTab ? undefined : null}
        >
          <Tabs
            // ref={this.tabRef}
            className={style.productModalTabs}
            activeKey={tabActiveKey}
            onTabClick={this.onTabClick}
            tabBarGutter={0}
          >
            <TabPane tab="基本信息" key={EnumTabKey.productTab}>
              {this.renderProductForm()}
            </TabPane>

            <TabPane
              tab="工艺路线"
              className={style.craftRouteTab}
              key={EnumTabKey.craftTab}
            >
              {this.renderCraftRouteEdit()}
            </TabPane>

            <TabPane
              tab="产品制程"
              key={EnumTabKey.processTab}
            >
              {this.renderProductProcessEdit()}
            </TabPane>
          </Tabs>
        </FlexModal>
      </ProductInfoCxt.Provider>
    );
  }
}

export default ProductModal as ComponentType<OwnProps>;
