import * as React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectWithOption } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { optionsGroup } from 'src/config/selectOption';

type Props = {
  status: FormStatus;
  packageType: string | undefined;
  value?: any;
  onChange?: (val: any) => void;
} & Pick<WrappedFormUtils, 'setFieldsValue'>;
interface State {

}

class CarrierType extends React.PureComponent<Props, State> {

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { packageType } = this.props;

    if (prevProps.packageType !== packageType) {
      this.resetInfo();
    }
  }

  componentWillUnmount(): void {
    this.resetInfo();
  }

  resetInfo = () => {
    const { setFieldsValue } = this.props;

    setFieldsValue({
      'packageType.carrierType': undefined,
      'packageType.solderBallComposition': undefined,
      'packageType.solderBallDiameter': undefined,
    });
  }

  render () {
    const { status, packageType, setFieldsValue, ...passThroughProps } = this.props;

    if (status === 'preview') return <span>{this.props.value}</span>;

    let options = optionsGroup.carrierType.options;
    let _options: any = Object.keys(options!).map(key => ({ key, label: options![key] }));

    if (packageType === '引线框架') {
      options = optionsGroup.carrierTypeFramework.options;
      _options = Object.keys(options!).map(key => ({ key, label: options![key] }));
    }

    if (packageType === '晶圆级封装') {
      options = optionsGroup.carrierTypeWaferLevel.options;
      _options = Object.keys(options!).map(key => ({ key, label: options![key] }));
    }

    return (
      <SelectWithOption
        options={_options}
        {...passThroughProps}
      />
    );
  }
}

export default CarrierType;
