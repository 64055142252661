/**
 * NOTE: 尽量不要使用本文件的导出判断环境。 请使用 src/config/env 内的导出判断环境
 */
const { NODE_ENV, REACT_APP_RUN_ENV } = process.env;

const isProd = NODE_ENV === 'production' && REACT_APP_RUN_ENV === 'production';
const isDev = NODE_ENV === 'development';
const isTest = REACT_APP_RUN_ENV === 'test' || NODE_ENV === 'test';

module.exports = {
  isProd,
  isDev,
  isTest,
};
