/**
 * 委外需求订单列表
 *
 * todo 需要梳理、重构一下
 */
// tslint:disable:max-file-line-count
import React, { ComponentType } from 'react';
import { find, isEmpty, get, omit, isEqual } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { EnumProcessClass, EnumProductModel, PRODUCT_TYPE } from '@chipcoo/constant';
import { noop } from '@chipcoo/fe-utils';
import { throttle, bind } from 'lodash-decorators';
import { EnumRequirementOrderStatus } from '@chipcoo/constant';

import { getCurrentRoute } from 'src/components/HoneyRouter';
import { getTableColumns } from './components/getTableColumns';
import { filterFormConfig } from './components/filterFormConfig';
import { Actions, connectDecorator, RootState } from 'src/store';
import { getInitialQuery } from 'src/utils';

import CardExtra from './components/CardExtra';
import { openReqOrderModal } from './openReqOrderModal';

interface OwnProps {
  scrollY?: number; // 列表scrollY
  product?: string; // 搜索该产品的订单
  productModel?: EnumProductModel; // 当前列表的产品类型
  isNeedCreateBtn?: boolean; // 是否需要新建按钮
  status?: EnumRequirementOrderStatus | 'ALL'; // 订单状态搜索条件
  reloadBoardInfos?: () => void;
}

interface StateProps {
  tableList: any[];
  tableCount: number;
  orderDetail: Obj;
}

interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (formData: any) => void;
  createDemandOrder: (data: any) => void;
  deleteOrder: (id: string) => void;
}

type P = OwnProps & StateProps & DispatchProps & RouteComponentProps;

interface State {
  // orderTypeByPM: EnumOrderTypeByProductModel;
  cardText: string;
  tableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  mode: ModalMode;
  formConfigType: string;
  tableColumns: ColumnProps<any>[];
  productModel: EnumProductModel;
}

const { EnhancedTable, QueryTermTags } = CardTableV2;

@connectDecorator(
  ({ demandOrder: {
    tableList, tableCount, orderDetail
  } }: RootState) => ({
    tableList, tableCount, orderDetail
  }),
  ({
     demandOrder: { getTableList, getTableItemDetail, createDemandOrder, deleteOrder }
   }: Actions) => ({
    getTableList, getTableItemDetail, createDemandOrder, deleteOrder
  })
)
class AssemblyProductOrder extends React.PureComponent<P, State> {
  static defaultProps = {
    isNeedCreateBtn: true,
  };
  private cardTableController: ICardTableController;
  private unmounted = false;
  private addModal: ReturnType<typeof openReqOrderModal> | null = null;
  private editModal: ReturnType<typeof openReqOrderModal> | null = null;

  constructor(props: P) {
    super(props);

    const { location: { pathname } } = getCurrentRoute()!;
    const productModel = pathname.split('/order/')?.[1] ?? props.productModel;

    let state: any = {
      tableLoading: false,
      detailModalVisible: false,
      detailId: '',
      mode: 'new',
      formConfigType: ''
    };

    state = {
      ...state,
      productModel,
      cardText: productModel ? PRODUCT_TYPE[productModel] : '订单',
      tableColumns: getTableColumns({
        openModal: this.openDetailModal,
        productModel: productModel as EnumProductModel,
        // orderTypeByPM: EnumOrderTypeByProductModel[orderTypeByRoute],
        deleteLine: this.deleteLine
      }),
    };

    this.state = state;
  }

  componentDidMount(): void {
    this.openModalByLocation();
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<State>, snapshot?: any): void {
    const { location, status } = this.props;

    if (!isEqual(location.state, prevProps.location?.state)) {
      this.openModalByLocation();
    }
    if (status !== prevProps.status) {
      this.reloadTable();
    }
  }

  componentWillUnmount() {
    this.setState = noop;
    this.unmounted = true;
    this.addModal && this.addModal.closeModal();
    this.editModal && this.editModal.closeModal();
  }

  openModalByLocation = () => {
    const { location, history } = this.props;
    const state = location.state;

    if (get(state, 'autoOpenModal')) {

      // todo 下成测单
      this.openAddModal();

      // 清除location的state
      const newState = omit(state, 'autoOpenModal');
      history.replace({ ...history.location, state: newState });
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  getTerms = (query: Obj) => {
    return Object.entries(query).reduce((prev, [key, val]) => {
      if (isEmpty(val)) {
        return prev;
      }

      let value = val;

      const queryObj = find(filterFormConfig, ['dataKey', key]) as any;
      const { label } = queryObj;

      switch (key) {
        default:
          prev[label] = value;
          break;
      }

      return prev;
    }, {} as any);
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList, status, product } = this.props;
    const { productModel } = this.state;
    const { formData, ...extraParams } = params;

    const queryParams: any = {
      ...formData,
      ...extraParams,
      productModel,
      product,
    };

    if (status !== 'ALL') {
      queryParams.status = status;
    }

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  // 删除已拒绝的订单
  deleteLine = async (_id: string) => {
    await this.props.deleteOrder(_id);

    this.reloadTable();
  }

  openAddModal = (val?: EnumProcessClass) => {
    this.setState({ detailId: '' });

    const { formConfigType, productModel } = this.state;

    const modalInst = openReqOrderModal({
      // id: detailId,
      mode: 'add',
      autoOpen: true,
      productModel,
      classes: val || EnumProcessClass.assembly,
      formConfigType,
      onOk: this.handleOk,
      onCancel: this.closeDetailModal,
      onAfterClose: () => {
        // EditManage.stopEdit(detailId);
        if (this.addModal === modalInst && !this.unmounted) {
          this.forceUpdate();
          this.addModal = null;
        }
      }
    });

    this.addModal = modalInst;
  }

  openDetailModal = async (params: any) => {
    const { id, mode, type, classes, productModel } = params;
    const { getTableItemDetail } = this.props;

    this.setState({ tableLoading: true });

    try {
      await getTableItemDetail({ id });

      this.setState({
        tableLoading: true,
        detailModalVisible: true,
        detailId: id,
        mode,
        formConfigType: type
      });

      const modalInst = openReqOrderModal({
        id,
        mode,
        classes,
        autoOpen: true,
        productModel: this.state.productModel ?? productModel,
        formConfigType: type,
        onOk: this.handleOk,
        onCancel: this.closeDetailModal
      });

      this.editModal = modalInst;
    } catch (e) {
      console.error(e);
    }
  }

  @throttle(300)
  @bind
  async handleOk(values: any) {
    try {
      await this.props.createDemandOrder(values);

      this.closeModalWithReload();
    } catch (e) {
      throw e;
    }
  }

  reloadTable() {
    this.setState({ tableLoading: true });

    this.cardTableController && this.cardTableController.reloadTable();
    this.props.reloadBoardInfos && this.props.reloadBoardInfos();
    this.setState({ tableLoading: false });
  }

  closeDetailModal = () => {
    this.setState({
      detailId: '',
      detailModalVisible: false,
      formConfigType: '',
      tableLoading: false,
    });
  }

  closeModalWithReload = () => {
    this.closeDetailModal();

    this.reloadTable();

    this.setState({ tableLoading: false });
  }

  getInitialQuery = () => {
    const initialQuery = getInitialQuery(this.props);

    return initialQuery;
  }

  clearQuery = () => {
    const { history } = this.props;

    history.push(this.props.match.url);
  }

  render () {
    const { tableCount, tableList, isNeedCreateBtn, scrollY } = this.props;
    const {
      tableLoading,
      productModel,
      cardText,
      tableColumns,
    } = this.state;

    return (
      <CardTableV2
        card={{
          title: `${cardText}列表`,
          extra: (
            <CardExtra
              openQuery={this.openQuery}
              openModal={this.openAddModal}
              productModel={productModel}
              isNeedCreateBtn={isNeedCreateBtn!}
            />
          )
        }}
        queryFormConfig={filterFormConfig}
        getCardTableController={this.getCardTableController}
      >
        <QueryTermTags
          getTerms={this.getTerms}
          openQueryPanel={this.openQuery}
          clearQuery={this.clearQuery}
        />

        <EnhancedTable
          total={tableCount}
          dataSource={tableList}
          tableLoading={tableLoading}
          getTableData={this.getTableData}
          columns={tableColumns}
          // 分产品类型
          clickMention={true}
          tableName={`${productModel}_ORDER_LIST`}
          initialQuery={this.getInitialQuery()}
          scroll={{ y: scrollY }}
        />
      </CardTableV2>
    );
  }
}

export default withRouter(AssemblyProductOrder) as ComponentType<OwnProps>;
