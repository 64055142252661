/**
 * 加工单查询
 */
import React, { ComponentType } from 'react';
import classNames from 'classnames';
import { isEmpty, find, cloneDeep } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { getRangeDate } from '@chipcoo/fe-utils';

import { Actions, connectDecorator, RootState } from 'src/store';
import { optionsGroup } from 'src/config/selectOption';
// import styles from './index.module.less';
import CardExtra from './components/CardExtra';
import { getTableColumns } from './components/getTableColumns';
import { getFilterFormConfig } from './components/filterFormConfig';
import { getStockProcessConfig } from './formConfig';
import { getWipFormConfig } from './wip/wipFormConfig';

interface OwnProps {
  demandOrderSerial?: string;
}
interface StateProps {
  list: any[];
  count: number;
  detail: Obj | undefined;
  wipDetail: Obj | undefined;
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (_id: string) => void;
  getWipDetail: (_id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  tableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  modalFullWindow: boolean;
  modalMode: 'detail' | 'wip';
}

const { EnhancedTable, QueryTermTags } = CardTableV2;

@connectDecorator(
  ({
     process: { list, count, detail, wipDetail }
   }: RootState) => ({
    list, count, detail, wipDetail
  }),
  ({
     process: { getTableList, getTableItemDetail, getWipDetail }
   }: Actions) => ({
    getTableList, getTableItemDetail, getWipDetail
  })
)
class Process extends React.PureComponent<Props, State> {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      tableLoading: false,
      detailModalVisible: false,
      detailId: '',
      modalFullWindow: true,
      modalMode: 'detail'
    };

    this.tableColumns = getTableColumns({
      openModal: this.openDetailModal,
      openWip: this.openWip,
    });
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getTerms = (query: Obj) => {
    return Object.entries(query).reduce((prev, [key, val]) => {
      if (isEmpty(val)) {
        return prev;
      }

      let value = val;

      const queryObj = find(getFilterFormConfig(), ['dataKey', key]) as any;
      const { label } = queryObj;

      switch (key) {
        case 'createdAt':
          const createdAtText = getRangeDate(value);

          prev[label] = createdAtText.join(' ~ ');
          break;
        // case 'demandTime':
        //   const demandTimeText = getRangeDate(value);
        //
        //   prev[label] = demandTimeText.join(' ~ ');
        //   break;
        case 'status':
          prev[label] = optionsGroup.stockProcessStatus.options![value];
          break;
        default:
          prev[label] = value;
          break;
      }

      return prev;
    }, {} as any);
  }

  getCardTableController = controller => this.cardTableController = controller;

  reloadTable() {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  getTableData = async (params: GetTableDataParams) => {
    const { demandOrderSerial, getTableList } = this.props;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = cloneDeep(val);

        switch (key) {
          case 'createdAt':
            prev[key] = value ? getRangeDate(value) : undefined;
            break;
          default:
            prev[key] = value ? value : undefined;

            break;
        }

        return prev;
      },
      {} as any
    );

    const queryParams: any = {
      ..._formData,
      ...extraParams,
      demandOrderSerial,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);

      this.setState({ tableLoading: false });
    } catch (e) {
      console.error(e);
    }
  }

  openDetailModal = async (detailId: string) => {
    const { getTableItemDetail } = this.props;

    try {
      await getTableItemDetail(detailId);

      this.setState({ detailModalVisible: true, detailId, modalMode: 'detail' });
    } catch (e) {
      console.error(e);
    }
  }

  openWip = async (detailId: string) => {
    try {
      this.props.getWipDetail(detailId);

      this.setState({ detailModalVisible: true, detailId, modalMode: 'wip' });
    } catch (e) {
      console.error(e);
    }
  }

  closeDetailModal = () => {
    this.setState({
      detailId: '',
      detailModalVisible: false,
      tableLoading: false,
    });
  }

  closeModalWithReload = () => {
    this.closeDetailModal();

    this.reloadTable();

    this.setState({ tableLoading: false });
  }

  onRequestFullWindow = () => this.setState({ modalFullWindow: true });

  onRequestNormalWindow = () => this.setState({ modalFullWindow: false });

  getModalModeParams = () => {
    const { detail, wipDetail } = this.props;
    const { modalMode, detailId } = this.state;
    let title = '加工记录详情';
    let formConfig = getStockProcessConfig();
    let modalDetail: any = detailId && detail ? detail[detailId] : {};

    if (modalMode === 'wip') {
      title = 'WIP信息';
      formConfig = getWipFormConfig();
      modalDetail = wipDetail;
    }

    return {
      title,
      formConfig,
      detail: modalDetail
    };
  }

  render () {
    const { count, list } = this.props;
    const {
      detailId,
      tableLoading,
      detailModalVisible,
      modalFullWindow
    } = this.state;

    return (
      <div className={classNames('wrap-content')} style={{ width: '100%' }}>
        <CardTableV2
          card={{
            title: '加工单列表',
            extra: (
              <CardExtra
                openQuery={this.openQuery}
              />
            )
          }}
          queryFormConfig={getFilterFormConfig()}
          getCardTableController={this.getCardTableController}
        >
          <QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />

          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            clickMention={true}
            size="small"
          />
        </CardTableV2>

        <FlexModal
          width={1024}
          title={this.getModalModeParams()?.title}
          footer={null}
          visible={detailModalVisible}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
          onCancel={this.closeDetailModal}
        >
          <FormCreator
            formConfig={this.getModalModeParams()?.formConfig}
            formWrapper={false}
            formData={this.getModalModeParams()?.detail}
            globalStatus={detailId ? 'preview' : 'edit'}
            // getFormController={controller => this.formController = controller}
          />
        </FlexModal>
      </div>
    );
  }
}

export default Process as ComponentType<OwnProps>;
