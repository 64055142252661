/**
 * 组织列表页面
 */
import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, notification } from 'antd';

import { RootState } from 'src/store';
import style from './index.module.less';
import { compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES, fastOrgPath } from 'src/router/normalRouter';

import AddNewOrg from './AddNewOrg';
import OrgCard from './OrgCard';
import { HoverScrollBarContainer } from 'src/components';

interface OwnProps {}
interface StateProps {
  orgIds: string[];
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  visible: boolean;
}

class Organization extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      visible: false
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const state = location.state;
    const description = get(location, 'state.description');

    if (state && description) {
      await this.openNotification('success');
      this.props.history.push(fastOrgPath(), '');
    }
  }

  openModal = () => this.setState({ visible: true });

  closeModal = () => this.setState({ visible: false });

  openNotification = (type: string) => {
    const { location } = this.props;
    const description = get(location, 'state.description');

    notification[type]({
      message: '提示',
      description: `${description}`
    });
  }

  handleEnterOrg = (orgId: string) => {
    const { history } = this.props;
    const path = compilePath({ name: ROUTE_NAMES.ORG_DETAIL, params: { id: orgId } });
    history.push(path);
  }

  goOrgSetting = (orgId: string | undefined) => {
    if (!orgId) return;

    const { history } = this.props;

    history.push(
      compilePath({
        name: ROUTE_NAMES.ORG_SETTINGS, params: { id: orgId }
      })
    );
  }

  handleModalClose = (modalResult) => {
    if (!modalResult) { return; }

    const { goSetting, orgId } = modalResult;

    if (goSetting === true && orgId) {
      const { history } = this.props;

      history.push(compilePath({ name: ROUTE_NAMES.ORG_SETTINGS, params: { id: orgId } }));
    }
  }

  renderContent = () => {
    const { orgIds } = this.props;

    if (!orgIds.length) {
      return (
        <div className={style.noOrg}>
          <div className={style.bg}>
            <div className="img" />
          </div>
          <Button type="primary" onClick={this.openModal}>新建组织</Button>
        </div>
      );
    }

    return (
      <div className={style.listSection}>
        <div className={style.header}>
          <div onClick={this.openModal} className={style.addNew}>
            <i className="c-icon icon-plus-thick" />
            新建组织
          </div>
        </div>

        <div className={`clear ${style.list}`}>
          {orgIds.map(id => (
            <OrgCard key={id} orgId={id} enterOrg={this.handleEnterOrg} />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { visible } = this.state;

    return (
      <HoverScrollBarContainer className={style.wrap}>
        {this.renderContent()}

        <AddNewOrg
          visible={visible}
          goSetting={this.goOrgSetting}
          onCloseModal={this.closeModal}
        />
      </HoverScrollBarContainer>
    );
  }
}

const mapState = ({ org }: RootState) => ({
  orgIds: org.orgList.allIds,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(Organization);
