/**
 * 保存草稿
 */
import * as React from 'react';
import { isFunction } from 'lodash';
import { setLocalStorage, getLocalStorage } from '@chipcoo/fe-utils';

import { isProd } from 'src/utils';

export type SaveDraftApi = {
  // SaveDraft组件被卸载时候默认不会再进行保存，如果外部需要在组件卸载的时候最后保存一道，isFocus设置为true
  save: (value: any, isFocus?: boolean) => void;
  initialValue: any;
};

interface P {
  autoSaveKey: string;
  children: (api: SaveDraftApi) => React.ReactNode;
}
interface S {}

const NAMESPACE = 'cw-auto-save';
export const getKey = (key: string) => `${NAMESPACE}:${key}`;
// const EXPIRE_KEY = `${NAMESPACE}:expires`;

export class SaveDraft extends React.PureComponent<P, S> {
  private couldSaveFlag = true;
  private initialValue;

  constructor(props: P) {
    super(props);
    try {
      const initial = getLocalStorage(getKey(props.autoSaveKey));

      if (initial) {
        this.initialValue = initial;
      }
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount() {
    this.couldSaveFlag = false;
  }

  handleSave = (value: any, isFocus?: boolean) => {
    const { autoSaveKey } = this.props;

    this.couldSaveFlag && setLocalStorage(getKey(autoSaveKey), value);
  }

  render() {
    const { children } = this.props;
    const { initialValue } = this;

    if (!isFunction(children)) {
      if (!isProd) {
        throw new Error('AutoSave组件下的children必须是一个function');
      }

      return null;
    }

    return children({
      save: this.handleSave,
      initialValue,
    });
  }
}

export default SaveDraft;
