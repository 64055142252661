import React, { FC, ComponentType } from 'react';
import { Select } from 'antd';
import { EnumAnalysisCustomScheduleRepeatUnit } from '@chipcoo/constant';

interface OwnProps {
  value?: string;
  onChange?: (value: string) => void;
}
interface StateProps {}
interface DispatchProps {}
interface Props extends OwnProps, StateProps, DispatchProps {}

const { Option } = Select;
export const RepeatUnitSelectOptions = [
  { value: EnumAnalysisCustomScheduleRepeatUnit.once, label: '仅一次' },
  { value: EnumAnalysisCustomScheduleRepeatUnit.daily, label: '每天' },
  { value: EnumAnalysisCustomScheduleRepeatUnit.weekly, label: '每周' },
  { value: EnumAnalysisCustomScheduleRepeatUnit.monthly, label: '每月' },
  { value: EnumAnalysisCustomScheduleRepeatUnit.yearly, label: '每年' },
];

const RepeatUnit: FC<Props> = props => {
  const { value, onChange } = props;

  return (
    <Select
      placeholder="请选择"
      value={value}
      onChange={onChange}
      getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
    >
      {
        RepeatUnitSelectOptions.map(it => (
          <Option key={it.value} value={it.value}>{it.label}</Option>
        ))
      }
    </Select>
  );
};

export default RepeatUnit as ComponentType<OwnProps>;
