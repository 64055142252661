import * as React from 'react';
import { Divider, Icon } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { CardTable } from '@chipcoo/hanayo';
import { trim, isEmpty, get, find } from 'lodash';
import { EnumMemberStatus } from '@chipcoo/constant';
import { ColumnProps } from 'antd/lib/table';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTable';
import { getUserName } from '@chipcoo/fe-utils';

import { queryForm } from './queryForm';
import styles from './index.module.less';
import { OrgUser } from 'src/models/org/interface';
import { RootDispatch, RootState } from 'src/store';
import { getTableColumns } from './tableColumnConfig';
import { optionsGroup } from 'src/config/selectOption';
import { filterAvailableMember, pickOrgUser } from 'src/models/org';

import { HoverScrollBarContainer } from 'src/components';
import AddNewMembers from './AddNewMembers';

const { EnhancedTable, QueryTermTags } = CardTable;

interface OwnProps {
}
interface StateProps<T extends User = User> {
  role: AllRole | null;
  users: T[];
  members: T[];
  disabledKeys: string[];
  orgId: string;
}
export interface DispatchProps {
  deleteOrgMember: (payload: { orgId: string, userId: string }) => void;
  putOrgMember: (payload: { userId: number, role: string }) => void;
  postAddOrgMember: (payload: { accounts: string[] }) => void;
}
type MembersProps = OwnProps & StateProps & DispatchProps;
type MembersState = {
  columns: ColumnProps<any>[]
  filteredMembers: User[];
};

class Members extends React.PureComponent<MembersProps, MembersState> {
  private cardTableController: ICardTableController;
  /**
   * 每次重新获取数据的时候都记录下查询条件，因为角色会被改变，每次改变后都要重新计算被过滤的数据
   */
  private queryParams: Obj;

  constructor(props: MembersProps) {
    super(props);

    const { role, orgId, members } = props;

    this.state = {
      columns: getTableColumns({
        role,
        orgId,
        dispatch: this.getDispatchFn()
      }),
      filteredMembers: members
    };
  }

  componentDidUpdate(prevProps: Readonly<MembersProps>, prevState: Readonly<MembersState>) {
    const { orgId, role, members } = this.props;

    if (prevProps.role !== role) {
      this.setState({
        columns: getTableColumns({
          role,
          orgId,
          dispatch: this.getDispatchFn()
        })
      });
    }

    if (prevProps.members !== members) {
      this.setState({
        filteredMembers: this.filterMembers(this.queryParams)
      });
    }
  }

  getDispatchFn = () => {
    const { putOrgMember, postAddOrgMember, deleteOrgMember } = this.props;

    return {
      putOrgMember,
      postAddOrgMember,
      deleteOrgMember
    };
  }

  filterMembers = (queryParams: Obj) => {
    const { members } = this.props;

    if (isEmpty(queryParams)) {
      return members;
    }

    const validData = Object.entries(queryParams).reduce((prev, [key, val]) => {
      if (typeof val === 'string' && trim(val)) {
        prev[key] = trim(val);
      }

      return prev;
    }, {} as Obj);

    if (isEmpty(validData)) {
      return members;
    } else {
      const validKey = Object.keys(validData);

      return members.filter(member => {
        return validKey.every(key => {
          // name需要进行特殊处理
          if (key === 'name') {
            const userName = getUserName(member);

            return Boolean(userName && userName.toLowerCase().includes(validData[key].toLowerCase()));
          }

          // role和account的处理
          // 支持正则
          const regExp = new RegExp(`${validData[key]}`, 'i');

          return regExp.test(member[key] as string || '');
        });
      });
    }
  }

  getTableData = (params) => {
    const { formData } = params;

    this.queryParams = formData;

    this.setState({
      filteredMembers: this.filterMembers(formData)
    });
  }

  getRowKey = record => record._id;

  getCardTableController = controller => this.cardTableController = controller;

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getTerms = (query: Obj) => {
    return Object.entries(query).reduce((prev, [key, val]) => {
      if (isEmpty(val)) {
        return prev;
      }

      let value = val;

      const queryObj = find(queryForm, ['dataKey', key]);
      const label = get(queryObj, 'label') || key;

      if (key === 'role') {
        const options = get(optionsGroup, 'orgMemberRole.options');

        prev[label] = options![val];

        return prev;
      }

      prev[label] = value;

      return prev;
    }, {} as any);
  }

  renderExtra = () => {
    const { role, orgId } = this.props;

    return (
      <>
        <a onClick={this.openQuery}>
          <Icon type="search" />查询
        </a>
        <Divider style={{ visibility: 'hidden' }} type="vertical" />
        {role && role !== 'MEMBER' && (
          <AddNewMembers orgId={orgId}/>
        )}
      </>
    );
  }

  render() {
    const { columns, filteredMembers } = this.state;
    const len = filteredMembers.length;

    return (
      <HoverScrollBarContainer className={styles.orgMembers}>
        <CardTable
          card={{
            title: `组织成员(${len})`,
            extra: this.renderExtra()
          }}
          queryFormConfig={queryForm}
          getCardTableController={this.getCardTableController}
        >
          <QueryTermTags getTerms={this.getTerms} openQueryPanel={this.openQuery} />
          <EnhancedTable
            columns={columns}
            dataSource={filteredMembers}
            hasInitialTableData={true}
            getTableData={this.getTableData}
            rowKey={this.getRowKey}
            showPagination={false}
            size="small"
            className="small-table-no-border"
          />
        </CardTable>
      </HoverScrollBarContainer>
    );
  }
}

const weight = {
  'MEMBER': 1,
  'ADMIN': 1,
  'OWNER': 3,
};
const orderedPicker = createSelector(
  pickOrgUser,
  (users) => users.sort((a, b) => {

    // 总之将当前由于各种原因被禁用的用户优先级调为-3 (越高越前)
    return (weight[b.role!] || -3) - (weight[a.role!] || -3);
  }),
);

interface PickPayload {
  role: AllRole | null;
  status?: EnumMemberStatus;
}
const pickMembersByRole = createSelector(
  (users: OrgUser[], payload: PickPayload) => users,
  (_: OrgUser[], payload: PickPayload) => payload,
  (users, payload) => {
    const { role, status = EnumMemberStatus.normal } = payload;

    if (role === null || status > EnumMemberStatus.normal) {
      return [];
    }

    if (role === 'member' || role === 'MEMBER') {
      return users.filter(filterAvailableMember);
    }

    return users;
  }
);

const mapState = ({ session, user, org: { orgMembers: { byId }, orgId } }: RootState) => {
  const users = orderedPicker(user, byId);
  const { role, status } = byId[session.profile!._id];

  const members = pickMembersByRole(users, { role, status });

  return {
    role,
    orgId,
    users,
    members,
    disabledKeys: [],
  };
};
const mapDispatch = ({ org: { postAddOrgMember, putOrgMember, deleteOrgMember } }: RootDispatch) => ({
  deleteOrgMember,
  postAddOrgMember,
  putOrgMember
});
export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Members);
