/**
 * Created by marvin on 2018/11/20.
 */

import * as React from 'react';
import { isEqual, omit, get } from 'lodash';
import { parseURL } from '@chipcoo/fe-utils';

import { enhanceUserAvatar } from 'src/utils/user';

interface BasicProps {
  userInfo: User<any>;
}

interface State {
  user: User<any>;
  avatar?: string;
}

export default <T extends BasicProps>(
  WrappedComponent: (React.ComponentClass<T>)
) => {
  type Props = T;

  const { displayName, name } = WrappedComponent;

  return class HocProfileAvatar extends React.PureComponent<Props, State> {
    static displayName = `HocProfileAvatar(${ displayName || name || 'Component'})`;

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
      const nextState: Partial<State> = {};
      const omitProps = ['avatar'];

      // 如果 userInfo 除了 avatar 和 _avatar 之外发生了变动
      if (!isEqual(omit(nextProps.userInfo, omitProps), omit(prevState.user, omitProps))) {
        nextState.user = enhanceUserAvatar(nextProps.userInfo);
      }

      // 如果头像也更新了，那么需要对头像图片进行下一步更新
      if (get(nextProps, 'userInfo.avatar') !== prevState.avatar) {
        const user = nextState.user || prevState.user;
        if (user.avatar) {
          Object.assign(user, {
            avatar: parseURL(user.avatar, { t: Math.random() })
          });
        }

        nextState.user = { ...user };
        nextState.avatar = nextProps.userInfo.avatar;
      }

      return nextState;
    }

    constructor(props: Props) {
      super(props);
      const { userInfo } = props;
      this.state = {
        avatar: get(userInfo, 'avatar'),
        user: enhanceUserAvatar(userInfo),
      };
    }

    render() {
      return <WrappedComponent {...this.props} userInfo={this.state.user} />;
    }
  };
};
