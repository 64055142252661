/**
 * 点击后可编辑的那个输入框
 */
import * as React from 'react';
import { Input, Spin } from 'antd';
import onClickOutside from 'react-onclickoutside';

import { ControlledComponent } from 'src/hoc';

interface P {
  confirmSelector: string;
  placeholder: string | undefined;
  value: string | undefined;
  onInputChange: (value: string | undefined) => void;
  onConfirmInputVal: () => void;
  onInputClose: () => void;
}
interface S {
  loading: boolean;
}

const ControlledInput = ControlledComponent(Input as any) as any;

@onClickOutside
class InputWrapper extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleClickOutside = (ev) => {
    const { onInputClose, confirmSelector, onConfirmInputVal } = this.props;
    const element = document.querySelector(`.${confirmSelector}`);

    if (Boolean(element) && (element as HTMLElement).contains(ev.target)) { return; }

    onInputClose();
    onConfirmInputVal();
  }

  render() {
    const { loading } = this.state;
    const {
      placeholder, value, onInputChange, onConfirmInputVal
    } = this.props;

    return (
      <Spin spinning={loading}>
        <ControlledInput
          size="small"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          autoFocus={true}
          onChange={onInputChange}
          onPressEnter={onConfirmInputVal}
        />
      </Spin>
    );
  }
}

export default InputWrapper;
