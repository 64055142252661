// import { uniq, sortBy } from 'lodash';
import { optionsGroup } from 'src/config/selectOption';

export const handleMonitorRuleGroupList = (payload: any[] = []) => {
  return payload.map(it => {
    const {
      enabledMonitors,
      product,
    } = it;
    const enabledMonitorsText = enabledMonitors.map(type => optionsGroup.monitorType.options![type])?.join(',');

    return {
      ...it,
      enabledMonitorsText,
      productName: `${product?.customerName}(${product?.chipcooName})`,
    };
  });
};

export const handleMonitorRuleGroupDetail = (payload) => {

  return {
    ...payload,
  };
};
