/**
 * models里面默认的一些reducers
 * path的语法请参考immutability-helper-x这个库：https://github.com/ProtoTeam/immutability-helper-x
 * 这个库说白了就是对immutability-helper的一层封装
 * 毕竟immutability-helper原生的写法实在太恶心了
 */
import { isEqual, get } from 'lodash';
import update, { updateChain } from 'immutability-helper-x';

import { removeNormalizedDataById } from './tools';
import { env } from 'src/config/env';

// 用来处理每个操作
const handleOperation = (state, operation) => {
  const { path, data: originData } = operation;
  const method = operation.method || '$set';
  const data = typeof originData === 'function' ? originData(state) : originData;

  return { path, data, method };
};
/**
 * 用来设置store的
 */
const updateState = (state: any, operations: UpdateStateOperation | UpdateStateOperation[]) => {
  // 检查传入的格式是否正确
  if (env === 'local') {
    (Array.isArray(operations) ? operations : [operations]).forEach(item => {
      const keys = Object.keys(item);

      if (!isEqual(keys, ['path', 'data']) && !isEqual(keys, ['method', 'path', 'data'])) {
        console.error('updateState传入值的类型要传对，请看Operation的类型定义');
      }
    });
  }

  if (!Array.isArray(operations)) {
    const { path, data, method } = handleOperation(state, operations);

    return update[method](state, path, data);
  }

  return operations.reduce(
    (pre, cur) => {
      const { path, data, method } = handleOperation(state, cur);

      return pre[method](path, data);
    },
    updateChain(state)
  ).value();
};

/**
 * 移除已经被范式化的数据
 * @param state
 * @param payload
 */
export type RemoveById = {
  removeIds: string[];
  path: string;
};
const removeNormalizedData = (state: any, payload: RemoveById) => {
  const { path, removeIds } = payload;

  if (env === 'local') {
    if (!Array.isArray(removeIds)) {
      console.error('removeNormalizedData传入的removeIds是一个数组');
    }
  }

  const normalizedData = get(state, path);

  const { allIds, byId } = removeNormalizedDataById(normalizedData, removeIds);

  return updateChain(state)
    .$set(`${path}.allIds`, allIds)
    .$set(`${path}.byId`, byId)
    .value();
};

export default {
  updateState,
  removeNormalizedData
};
