import * as React from 'react';
import _ from 'lodash';
import { message, Modal } from 'antd';
import classNames from 'classnames';
import { ColumnProps } from 'antd/lib/table';
import { CardTableV2, FormCreator } from '@chipcoo/hanayo';
import { FlexModal, FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import log from 'loglevel';

import CardExtra from './components/CardExtra';
import { getFormConfig } from './formConfig';
import { getTableColumns } from './components/getTableColumns';
import { connectDecorator, Actions, RootState } from 'src/store';
import styles from './index.module.less';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTable/Addon/EnhancedTable';

const { EnhancedTable } = CardTableV2;

interface OwnProps {

}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}

interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (id: string) => void;
  patchModify: (id: string) => void;
  postCreate: (params: any) => void;
}

type IProps = OwnProps & StateProps & DispatchProps;

interface IState {
  tableLoading: boolean;
  modalVisible: boolean;
  modalFullWindow: boolean;
  mode: ModalMode;
}

@connectDecorator(
  ({ lot: { list, count, detail }}: RootState) => ({ list, count, detail}),
  ({ lot: { getTableList, getTableItemDetail, patchModify, postCreate }}: Actions) => ({
    getTableList, getTableItemDetail, patchModify, postCreate
  })
)

class Lot extends React.PureComponent<IProps, IState > {
  private cardTableController: ICardTableController;
  private readonly tableColumns: ColumnProps<any>[] = [];
  private formController;

  constructor(props: IProps) {
    super(props);

    this.state = {
      tableLoading: false,
      modalVisible: false,
      modalFullWindow: true,
      mode: 'add',
    };

    this.tableColumns = getTableColumns({
      openModal: this.openModal,
    });
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  reloadTable = () => {
    if (this.cardTableController) {
      this.cardTableController.reloadTable();
    }
  }

  getCardTableController = (controller: ICardTableController) => {
    this.cardTableController = controller;
  }

  openModal = async (detailId: string, mode: ModalMode) => {
    const { getTableItemDetail } = this.props;
    let state: any = {
      modalVisible: true,
      mode,
    };

    if (mode !== 'add') {
      try {
        await getTableItemDetail(detailId);

        state = {
          ...state,
          detailId
        };
      } catch (e) {
        log.error('[DemandOrderTable]: loading preview failed', e, detailId);
        message.error('加载申请订单详情失败');
        return;
      }
    }

    this.setState(state);
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { formData, ...extraParams } = params;

    const queryParams = {
      ...formData,
      ...extraParams,
    };

    this.setState({ tableLoading: true });

    try {
      await getTableList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  closeModal = () => this.setState({ modalVisible: false });

  closeModalWithReload = () => {
    this.closeModal();

    this.reloadTable();
  }

  handleSubmit = (e: any) => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      const { validateFieldsAndScroll } = this.formController;

      validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const { waferAmount, stops, outputAmount} = values;
          const passed = waferAmount === outputAmount + _.sum(stops?.map(it => it.amount));
          if (!passed) {
            resolve(true);
            return new Promise((res) => {
              Modal.error({
                content: '产出片数与暂停片数之和不等于wafer数量,请修改后重新提交!',
                zIndex: FlexModalManager.getNextZIndex()
              });
            });
          }
          try {
            const { postCreate } = this.props;
            await postCreate(values);
            
            resolve(true);
            this.closeModalWithReload();
          } catch (e) {
            console.error(e);

            reject(e);
          }
        } else {
          reject(err);
        }
      });
    });
  }

  render () {
    const { list, count, detail } = this.props;
    const {
      tableLoading,
      modalVisible,
      modalFullWindow,
      mode,
    } = this.state;

    return (
      <div className={classNames('wrap-content', styles.lotList)}>
        <CardTableV2
          card={{
            title: 'LOT指令列表',
            extra: (
              <CardExtra 
                openQuery={this.openQuery}
                openModal={this.openModal}
              />
            )
          }}
          getCardTableController={this.getCardTableController}
        >
          <EnhancedTable
            total={count}
            dataSource={list}
            tableLoading={tableLoading}
            getTableData={this.getTableData}
            columns={this.tableColumns}
            size="small"
          />
        </CardTableV2>
        <FlexModal
          title="LOT指令"
          visible={modalVisible}
          onCancel={this.closeModal}
          onOk={this.handleSubmit}
          fullWindow={modalFullWindow}
          className={classNames('flow-in-flex-modal', styles.modal)}
          onRequestFullWindow={this.requestFullWindow}
          onRequestNormalWindow={this.requestNormalWindow}
          footer={mode === 'preview' && null}
          width={1440}
          resize={{
            minWidth: 1366,
            minHeight: 768
          }}
        >
          <FormCreator
            formConfig={getFormConfig(mode)}
            formWrapper={false}
            formData={mode === 'add' ? undefined : detail}
            globalStatus={mode === 'preview' ? 'preview' : 'edit'}
            getFormController={controller => this.formController = controller}
          />
        </FlexModal>
      </div>
    );
  }
}

export default Lot;
