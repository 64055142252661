import * as React from 'react';

import appList, { refreshAppList } from './appList';
import { getCurrentRoute } from 'src/components/HoneyRouter';

import { ManageAppList } from 'src/components';

export default () => {
  refreshAppList();

  const { match: { params } } = getCurrentRoute()!;

  return <ManageAppList dataSource={appList} params={params} />;
};

export { default as QS } from './QS';
export { default as FEMA } from './FEMA';
export { default as PCN } from './PCN';
export { default as CustomerComplaint } from './CustomerComplaint';
export { default as Analysis } from './Analysis';
