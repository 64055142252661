import React from 'react';
// import { Table } from 'antd';
// import classNames from 'classnames';
import { ColumnProps } from 'antd/lib/table';
import { EnumProductModel } from '@chipcoo/constant';
import { CardTableV2 } from '@chipcoo/hanayo';

import { IEditCellProps } from './tableComponents';
import { getTableColumns } from './getTableColumns';
import { getDataBySaveRow } from 'src/utils';
// import styles from './index.module.less';

interface IProps {
  productModel: EnumProductModel;
  value?: any;
  onChange?: (values: any[]) => void;
}

interface IState {
  dataList: any[];
}

const { EnhancedTable } = CardTableV2;

export interface EditableColumnsProps extends ColumnProps<any>, IEditCellProps {
  /** 是否可编辑，默认不可编辑 */
  editable?: boolean;
}

export const isAssemblyModel = (model: EnumProductModel) => model === EnumProductModel.assemblyProduct;

class EditableTable extends React.Component<IProps, IState> {
  private readonly tableColumns: EditableColumnsProps[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      dataList: []
    };

    this.tableColumns = getTableColumns({
      productModel: props.productModel
    });
  }

  getNewDataBySaveRow = (rowData: any) => {

    const newData = getDataBySaveRow(this.props.value, rowData);

    this.props.onChange && this.props.onChange(newData);
  }

  render() {
    const { value, productModel } = this.props;

    return (
        <EnhancedTable
          getTableData={() => {
            //
          }}
          dataSource={value}
          columns={this.tableColumns}
          scroll={productModel === EnumProductModel.assemblyProduct ? {x: 1800} : {x: 1950}}
          size="small"
          getNewDataBySaveRow={this.getNewDataBySaveRow}
          className="small-table-no-border"
        />
    );
  }

}

export default EditableTable; 
