import Card from './Card/index';
import Table from './Table';
import Modal from './OperateModal';
import StandardTable from './StandardTable';

StandardTable.Card = Card;
StandardTable.Modal = Modal;
StandardTable.Table = Table;

export default StandardTable;
export { QueryLabel } from './QueryPanel/QueryLabel';
