import { EnumProductModel } from '@chipcoo/constant';

interface CategoryConfig {
  label: string;
  path: string;
  key: EnumProductModel | 'ALL';
}

export const categoryConfig: CategoryConfig[] = [
  {
    label: '全部',
    path: 'all',
    key: 'ALL'
  },
  {
    label: 'SPW芯片',
    path: 'spw-die',
    key: EnumProductModel.spwDie
  },
  {
    label: '磨划成品',
    path: 'dp-die',
    key: EnumProductModel.dpProduct
  },
  {
    label: '封装成品',
    path: 'assembly-product',
    key: EnumProductModel.assemblyProduct
  },
];
