import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { EnumProductModel } from '@chipcoo/constant';

interface Option {
  openModal: (key: string) => void;
  productModel: EnumProductModel;
}

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  const column = option.productModel === EnumProductModel.assemblyProduct ? {
      dataIndex: 'bin',
      key: 'bin',
      title: 'Bin类型',
      width: 200
    } : {
      dataIndex: 'waferIds',
      key: 'waferIds',
      title: '片号',
      width: 200,
      render: text => {
        return <span>{text ? '#' + text : '/'}</span>;
      }
    };

  return [
    {
      dataIndex: 'materialNumber',
      key: 'materialNumber',
      title: '物料批号',
      width: 200,
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record);
        };

        return (
          <a onClick={openModal}>{text}</a>
        );
      }
    },
    {
      dataIndex: 'dieNumber',
      key: 'dieNumber',
      title: '芯片批号',
      width: 200
    },
    {
      dataIndex: 'stampNumber',
      key: 'stampNumber',
      title: '印章批号',
      width: 200
    },
    column,
    {
      dataIndex: 'available',
      key: 'available',
      title: '良品数量',
      width: 100
    },
    {
      dataIndex: 'unavailable',
      key: 'unavailable',
      title: '不良品数量',
      width: 100
    },
    // {
    //   dataIndex: 'freeze',
    //   key: 'freeze',
    //   title: '冻结数量',
    //   width: 100,
    // },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总数量',
      width: 100,
    },
    {
      dataIndex: 'stockInSerial',
      key: 'stockInSerial',
      title: '入库单号',
      width: 200
    },
    {
      dataIndex: 'stockInAt',
      key: 'stockInAt',
      title: '入库时间',
      width: 200
    },
    {
      dataIndex: 'stockStatus',
      key: 'stockStatus',
      title: '库存状态',
      width: 100,
    },
    {
      dataIndex: 'restrictBy',
      key: 'restrictBy',
      title: '限制类型',
      width: 100,
    },
    {
      dataIndex: 'remark',
      key: 'remark',
      title: '备注',
      width: 300
    }
  ];
};

export const getMaterialColumns = (productModel): ColumnProps<any>[] => {
  let prevCol: any[] = [];

  if (productModel === EnumProductModel.assemblyProduct) {
    prevCol = [
      ...prevCol,
      {
        title: 'Bin类型',
        dataIndex: 'bin',
        key: 'bin'
      }
    ];
  } else {
    prevCol = [
      ...prevCol,
      {
        dataIndex: 'waferId',
        key: 'waferId',
        title: '片号',
        render: text => {
          return <span>{text ? '#' + text : '/'}</span>;
        }
      },
      {
        dataIndex: 'good',
        key: 'good',
        title: '良品',
      },
      {
        dataIndex: 'bad',
        key: 'bad',
        title: '不良品',
      },
    ];
  }

  return [
    ...prevCol,
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '数量',
    },
  ];
};
