/**
 * // todo 准备重构
 */
import * as React from 'react';
import { Card, message } from 'antd';
import { connect } from 'react-redux';

import { RootState, RootDispatch } from 'src/store';
import { AddressParam } from 'src/models/address/interface';

import { Address } from 'src/pages/User/components';

interface OwnProps {}
interface StateProps {
  addAddressObj: AddressParam;
  addressList: AddressParam[];
}
interface DispatchProps {
  getAddressList: () => void;
  addAddress: (val: AddressParam) => void;
  editAddress: (val: AddressParam) => void;
  deleteAddress: (val: AddressParam) => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  loading: boolean;
  addressList: AddressParam[];
  region: Obj;
}
const addressConfig = [
  { title: '收件人', key: 'receiver', width: '12%', render: {type: 'input', placeholder: '请输入收件人'} },
  { title: '地址', key: 'regionCode', width: '20%', render: {type: 'address'} },
  { title: '', key: 'detail', width: '30%', render: {type: 'input', placeholder: '请输入详细地址'} },
  { title: '联系电话', key: 'phone', width: '14%', render: {type: 'input', placeholder: '请输入联系电话'} },
  { title: '标签', key: 'labels', width: '12%', render: {type: 'input', placeholder: '请输入地址标签'} }
];
class AddressContainer extends React.PureComponent<Props, State> {
  private cacheOriginData = {};
  private clickedCancel = false;
  // 这个值用来记录下最新的表格行的index
  private lastIndex = 0;
  private _isModify: boolean; // 用来记录地址级联是否修改过

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      addressList: props.addressList,
      region: {}
    };
  }

  async componentDidMount () {
    const { getAddressList } = this.props;
    await getAddressList();
    this.setState({
      loading: false,
      addressList: this.props.addressList
    });
  }

  getRowByKey = (id, newData?) => {
    return (newData || this.state.addressList).filter(item => item._id === id)[0];
  }

  // 切换编辑状态
  toggleEditable = (e, val) => {
    e.preventDefault();
    const { addressList } = this.state;
    const newData = addressList.map(item => ({ ...item }));
    const target = this.getRowByKey(val._id, newData);

    if (target) {
      // 进入编辑状态时保存原始数据
      if (!target.editable) {
        this.cacheOriginData[val._id] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ addressList: newData });
    }

    return newData;
  }

  // 对addressList中的某一行做检验
  verifySave = (val) => {
    switch (true) {
      case !val.receiver: message.error('请填写收件人信息'); return true;
      case !val.province: message.error('请选择省市区'); return true;
      case !val.detail: message.error('请填写详细地址'); return true;
      case !val.phone: message.error('请填写联系电话'); return true;
      default: return false;
    }
  }

  // 新建 、修改地址 请求成功后的操作
  success = (e, val) => {
    const newData = this.toggleEditable(e, val);
    this.setState({ addressList: newData });
    this._isModify = false;
  }

  // 新建地址
  addAddress = () => {
    const newData = this.state.addressList.map(item => ({ ...item }));
    newData.push({ _id: 'NEW_TEMP_INDEX_' + this.lastIndex, key: 'NEW_TEMP_INDEX_' + this.lastIndex,
      phone: '', receiver: '', province: '', city: '', distinct: '', detail: '', editable: true,
      isNew: true, regionCode: [], labels: [] });

    this.setState({addressList: newData});
    this.lastIndex += 1;
  }

  saveRow = async (e, val) => {
    e.persist();
    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }

    const target: AddressParam = this.getRowByKey(val._id) || {};
    const { region } = this.state;

    if (target.editable && this._isModify) {
      const [p, c, d] = region?.regionCodeText;
      target.province = p;
      target.city = c;
      target.distinct = d;
      target.regionCode = region?.regionCode;
    }

    if (this.verifySave(target)) {
      e.target.focus();
      return;
    }
    // 新建
    if (target.isNew) {
      const { addAddress } = this.props;
      await addAddress({...target});
      delete target.isNew;
      target._id = this.props.addAddressObj._id;
      target.key = this.props.addAddressObj._id;
      this.success(e, target);
    } else {
      // 编辑
      if (target.editable) {
        await this.props.editAddress({...target});
        this.success(e, target);
      } else {
        this._isModify = false;
        this.toggleEditable(e, target);
      }
    }
  }

  remove = async (val, type?) => {
    const newData = this.state.addressList.filter(item => item.key !== val.key);

    if (type !== 'cancel') {
      await this.props.deleteAddress(val);
    }
    this.setState({ addressList: newData });
  }

  cancel = (e, val) => {
    e.preventDefault();
    const key = val.key;
    const newData = this.state.addressList.map(item => ({ ...item }));
    const target = this.getRowByKey(key, newData);

    if (this.cacheOriginData[key]) {
      Object.assign(target, this.cacheOriginData[key]);
      target.editable = false;
      delete this.cacheOriginData[key];
    }
    this._isModify = false;
    this.setState({ addressList: newData });
  }

  // 修改地址
  handleFieldChange = (e, fieldName, val) => {
    const { addressList } = this.state;
    const { _id } = val;
    const newData = addressList.map(item => ({ ...item }));
    const target = this.getRowByKey(_id, newData);

    if (target) {
      target[fieldName] = e.target.value;
      // 对地址标签labels进行处理
      if (fieldName === 'labels') {
        target[fieldName] = e.target.value === '' ? [] : [e.target.value];
      }
      this.setState({ addressList: newData });
    }
  }

  handleAddressChange = (val) => {
    this._isModify = true;

    this.setState({ region: val });
  }

  render () {
    const { loading, addressList } = this.state;
    return (
      <Card title="地址管理" bordered={false} loading={loading}>
        <Address
          size="middle"
          dataSource={addressList}
          addressConfig={addressConfig}
          onAdd={this.addAddress}
          onSave={this.saveRow}
          onRemove={this.remove}
          onCancel={this.cancel}
          onChangeCascader={this.handleFieldChange}

          handleAddressChange={this.handleAddressChange}
        />
      </Card>
    );
  }
}

const mapState = ({ address }: RootState) => ({
  addAddressObj: address.addAddressObj,
  addressList: address.addressList
});
const mapDispatch = ({ address }: RootDispatch) => ({
  getAddressList: address.getAddressList,
  addAddress: address.postAddress,
  editAddress: address.patchAddress,
  deleteAddress: address.deleteAddress,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(AddressContainer);
