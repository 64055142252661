import { isObject, isArray } from 'lodash';
import { isEqualUserId } from 'src/utils/user';
import { isProd } from 'src/utils';

type ID = string | number;

/**
 * model里，filter掉某个id对应的byId数据
 * @param obj
 * @param f
 */
// const filterObjectByKey = (obj, f) => {
//   return Object.entries(obj)
//     .filter(([key, value]) => f(key))
//     .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
// };

/**
 * 根据某个id对byId进行remove
 * @param byIdData
 * @param removeId
 */
const removeById = (byIdData, removeIds: ID[]) => {
  const next = { ...byIdData };
  
  removeIds.forEach(id => {
    Reflect.deleteProperty(next, id);
  });
  
  return next;
};

/**
 * 根据id对allIds进行remove
 * @param allIdsData
 * @param removeId
 */
const removeAllIds = (allIdsData, removeIds: ID[]) => {
  return allIdsData.filter(id => !removeIds.some(_id => isEqualUserId(_id, id)));
};

/**
 * 根据id移除对应的范式化的数据
 * @param normalizedData
 * @param ids
 */
function removeNormalizedDataById<T extends object = {}>(normalizedData: NormalizedData<T>, ids: ID[]) {
  const { byId, allIds } = normalizedData;

  if (!isProd) {
    if (!isObject(byId)) {
      console.error('请检查removeNormalizedData调用时候传入的path，目前传入的path找到的byId数据不是一个对象');
    }

    if (!isArray(allIds)) {
      console.error('请检查removeNormalizedData调用时候传入的path，目前传入的path找到的allIds数据不是一个数组');
    }
  }

  return {
    byId: removeById(byId, ids),
    allIds: removeAllIds(allIds, ids)
  };
}

export {
  // filterObjectByKey,
  removeById,
  removeAllIds,
  removeNormalizedDataById
};
