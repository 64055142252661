import React, { ComponentType } from 'react';
import { Card, Button, Empty, Spin, Divider, Popconfirm, message } from 'antd';
import { FormCreator } from '@chipcoo/hanayo';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { EnumMpwBusPreorderStatus, EnumMpwBusStatus } from '@chipcoo/constant';

import styles from './index.module.less';
import { getFormConfig } from './formConfig';
import { Actions, connectDecorator, RootState } from 'src/store';

interface OwnProps {
  formRecord: Obj;
  closeMpwListModal?: () => void;
}
interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
}
interface DispatchProps {
  getTableList: (params: any) => void;
  getTableItemDetail: (orderId: string) => void;
  patchModify: (data: any) => void;
  postCreate: (data: any) => void;
  cancelPreOrder: (orderId: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;
interface State {
  visible: boolean;
  modalFullWindow: boolean;
  detailId: string;
  // 控制页面loading
  loading: boolean;
}

@connectDecorator(
  ({ mpwPreOrder: { list, count, detail } }: RootState) => ({
    list, count, detail
  }),
  ({ mpwPreOrder: { postCreate, getTableList, getTableItemDetail, patchModify, cancelPreOrder } }: Actions) => ({
    postCreate, getTableList, getTableItemDetail, patchModify, cancelPreOrder
  })
)
class BusList extends React.PureComponent<Props, State> {
  private formController;

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      modalFullWindow: true,
      loading: true,
      detailId: '',
    };
  }

  async componentDidMount() {
    await this.reloadTable();

    const { count } = this.props;

    if (!count) {
      this.setState({ visible: true });
    }

    this.setState({ loading: false });
  }

  // 取消班车记录
  deleteMpwBusPreOrder = async (item: Obj) => {
    await this.props.cancelPreOrder(item?._id);

    this.reloadAndLoading();
  }

  openEditModal = async (item: Obj) => {
    await this.props.getTableItemDetail(item?._id);

    this.setState({ detailId: item?._id, visible: true });
  }

  getCardExtra = (item: Obj) => {
    // 只有班车未发车，班车状态为未过期时才可以进行编辑或取消操作
    const { formRecord } = this.props;

    if (
      formRecord?.status === EnumMpwBusStatus.expired ||
      item?.status === EnumMpwBusPreorderStatus.departed ||
      item?.status === EnumMpwBusPreorderStatus.canceled
    ) return null;

    return (
      <>
        {
          new Date(formRecord?.dates?.preorderStop).getTime() - new Date().getTime() > 0 && (
            <a onClick={() => this.openEditModal(item)}>编辑</a>
          )
        }

        {
          new Date(formRecord?.dates?.preorderCancel).getTime() - new Date().getTime() > 0 && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title="是否取消该条班车记录？"
                onConfirm={() => this.deleteMpwBusPreOrder(item)}
                getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              >
                <a>取消</a>
              </Popconfirm>
            </>
          )
        }
      </>
    );
  }

  renderMpwBusOrder = (item, _index) => {
    const { count, formRecord } = this.props;
    let index = _index + 1;

    if (count === 1) {
      index = '';
    }

    return (
      <Card
        key={item?._id}
        title={`预订信息${index ? `(${index}/${count})` : index}`}
        extra={this.getCardExtra(item)}
        className={item?.status === EnumMpwBusPreorderStatus.canceled ? 'canceled-card' : 'normal-card'}
      >
        <FormCreator
          formConfig={getFormConfig('preview')}
          formWrapper={false}
          formData={{
            ...formRecord,
            ...item
          }}
          globalStatus="preview"
          // getFormController={controller => this.formController = controller}
        />
      </Card>
    );
  }

  renderFormModal = () => {
    const { formRecord, detail } = this.props;
    const { visible, modalFullWindow, detailId } = this.state;
    const mode: ModalMode = detailId ? 'edit' : 'add';
    const title = detailId ? '编辑班车预订单' : '预订班车';

    return (
      <FlexModal
        visible={visible}
        title={title}
        width={700}
        fullWindow={modalFullWindow}
        onRequestFullWindow={this.requestFullWindow}
        onRequestNormalWindow={this.requestNormalWindow}
        onCancel={this.closeBusOrderModal}
        onOk={this.handleOrderBus}
      >
        <FormCreator
          formConfig={getFormConfig(mode)}
          formWrapper={false}
          formData={detailId ? detail : formRecord}
          getFormController={controller => this.formController = controller}
        />
      </FlexModal>
    );
  }

  handleOrderBus = () => {
    return new Promise((res, rej) => {
      const { validateFieldsAndScroll } = this.formController;

      if (validateFieldsAndScroll) {
        validateFieldsAndScroll(async (err, values) => {
          if (!err) {
            try {
              const { postCreate, patchModify } = this.props;
              const { detailId } = this.state;
              let msg = '';

              if (detailId) {
                await patchModify({ ...values, _id: detailId });
                msg = '班车预订单编辑成功';
              } else {
                await postCreate(values);
                msg = '班车预订成功';
              }

              // 预订班车
              res(true);

              message.success(msg);

              this.closeModalWithReload();
            } catch (e) {
              rej(e);
            }
          } else {
            rej(err);
          }
        });
      } else {
        rej('validateFieldsAndScroll不存在，请检查formController是否已被正确赋值');
      }
    });
  }

  closeBusOrderModal = () => {
    const { count, closeMpwListModal } = this.props;

    if (!count) {
      closeMpwListModal && closeMpwListModal();
    }

    this.closeDetailModal();
  }

  closeDetailModal = () => {
    this.setState({ visible: false, detailId: '' });
  }

  // 重载列表
  reloadTable = async () => {
    const { formRecord, getTableList } = this.props;

    this.setState({ loading: true });

    await getTableList({ mpwBus: formRecord?._id });
  }

  // 重载列表并将loading置为false
  reloadAndLoading = () => {
    this.reloadTable();

    this.setState({ loading: false });
  }

  closeModalWithReload = () => {
    this.closeDetailModal();

    this.reloadAndLoading();
  }

  requestFullWindow = () => this.setState({ modalFullWindow: true });

  requestNormalWindow = () => this.setState({ modalFullWindow: false });

  render () {
    const { list } = this.props;
    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <div className={styles.busList}>
          {this.renderFormModal()}

          {list.length ? list.map(this.renderMpwBusOrder) : <Empty />}

          <Button
            block={true}
            icon="plus"
            style={{ marginTop: 16 }}
            onClick={() => this.setState({ visible: true })}
          >
            预订班车
          </Button>
        </div>
      </Spin>
    );
  }
}

export default BusList as ComponentType<OwnProps>;
