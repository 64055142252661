import React from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';

import styles from './index.module.less';
import { RootState } from 'src/store';
import { Task } from 'src/models/task/interface';

import { EnhanceAvatar } from 'src/components';

interface OwnProps {
  taskId: string;
  provided: any;
}
interface StateProps {
  taskCardInfo: Task;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class TaskCard extends React.PureComponent<P, S> {
  render() {
    const { taskCardInfo, provided, taskId } = this.props;

    if (!taskCardInfo) { return null; }

    const { content, executor } = taskCardInfo;
    const { name: executorName } = executor;

    return (
      <div
        className={styles.container}
        data-id={taskId}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="priority-0 task-priority" />
        <Checkbox />
        <div className={styles.contentWrap}>
          <header>
            <div className="content">{content}</div>
            <EnhanceAvatar userName={executorName} hasToolTip={true} />
          </header>
        </div>
      </div>
    );
  }
}

const mapState = ({ task: { tasks } }: RootState, props: OwnProps) => ({
  taskCardInfo: tasks[props.taskId]
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(TaskCard);
