/**
 * Created by marvin on 18/8/30.
 */

import * as React from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { bind } from 'lodash-decorators';
import { connect } from 'react-redux';
import to from 'await-to-js';
import { Modal } from '@chipcoo/hanayo';

import style from './index.module.less';
import { RootDispatch, RootState } from 'src/store';
import { validateForm } from 'src/utils/form';

import { EnhanceAvatar } from 'src/components';
import { VerifyCode } from '../index';

// const { Item: FormItem } = Form;

interface OwnProps {
  modal?: boolean;
  value?: string;
  visible?: boolean;
  onCloseModal?: () => any;
  autoFocus?: boolean;
  onSubmit?: (value: any) => any;
  from?: 'transfer' | 'leave' | 'dissolve';
}

interface StateProps {
  profile: User;
}
interface DispatchProps {
  postTransferCode: (val: any) => void;
  postDissolveCode: (val: any) => void;
  postLeaveCode: (val: any) => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;

interface State {
  curVisible: boolean;
}

class PasswordAffirmContainer extends React.PureComponent<Props, State> {
  static defaultProps = {
    modal: true,
    visible: false,
    onCloseModal() {
      this.setState({ curVisible: false });
    },
    autoFocus: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const next: Partial<State> = {};

    if (nextProps.visible !== prevState.curVisible) {
      next.curVisible = nextProps.visible;
    }

    return next;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      curVisible: !!props.visible,
    };
  }

  @bind
  onClose() {
    this.props.onCloseModal && this.props.onCloseModal.call(this);
  }

  @bind
  async submitForm(e?: any) {
    if (e) {
      e.preventDefault();
    }

    const { form, onSubmit } = this.props;
    const [errs, values] = await to(validateForm(form));

    if (errs) {
      return;
    }

    if (onSubmit) {
      await onSubmit(values);
    }
  }

  renderForm() {
    const { form, profile, from, postTransferCode, postDissolveCode, postLeaveCode } = this.props;
    const account = profile?.phone ?? profile?.email;
    let postRequest = postTransferCode;

    if (from === 'leave') postRequest = postLeaveCode;
    if (from === 'dissolve') postRequest = postDissolveCode;

    return (
      <Form onSubmit={this.submitForm} className={style.passwordAffirm}>
        <div style={{ textAlign: 'center' }}>
          <EnhanceAvatar src={profile.avatar} userName={profile.nickname} />
        </div>

        <p className={style.warningRed}>为确保是您本人操作，请输入发送至{account}的6位验证码</p>

        <VerifyCode
          from="orgSetting"
          form={form}
          postRequest={postRequest}
        />
      </Form>
    );
  }

  renderModal() {
    const { curVisible } = this.state;

    return (
      <Modal
        width={300}
        visible={curVisible}
        onCancel={this.onClose}
        title="安全验证"
        onOk={this.submitForm}
      >
        {this.renderForm()}
      </Modal>
    );
  }

  render () {
    const { modal } = this.props;
    if (!modal) {
      return this.renderForm();
    }

    return this.renderModal();
  }
}

const mapState = ({ session: { profile } }: RootState) => ({
  profile: profile!,
});
const mapDispatch = ({ org: { postTransferCode, postDissolveCode, postLeaveCode } }: RootDispatch): DispatchProps => ({
  postTransferCode, postDissolveCode, postLeaveCode
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapState,
  mapDispatch,
  null,
  { forwardRef: true }
)(Form.create<Props>()(PasswordAffirmContainer));
