/**
 * 子附件
 */
import React from 'react';
import { Icon, Progress } from 'antd';
import prettyBytes from 'src/local_modules/prettyBytes';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { IMG, createObjectURL } from '@chipcoo/fe-utils';

import styles from './list.module.less';

import AttachmentIcon from 'src/components/AttachmentIcon';
import { UploadFileEnhance, UploadAttachmentProps } from '../interface';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { ProgressProps } from 'antd/lib/progress';

interface P {
  file: UploadFileEnhance;
  removeFile: UploadAttachmentProps['onRemove'];
}
interface S {
  previewSrc?: string;
  fileStatistics: UploadFileEnhance;
}

function getPreviewSrc(file: UploadFileEnhance) {
  const { thumbUrl, ext, originFileObj } = file;
  if (thumbUrl) {
    return thumbUrl;
  }

  if (IMG.test(ext || '')) {
    try {
      return createObjectURL(originFileObj);
    } catch (e) {
      console.error(e);
      return;
    }
  }
}

const fileStatusToProgressStatusMap: { [x in UploadFileStatus]: string } = {
  success: 'success',
  error: 'exception',
  done: 'success',
  uploading: 'active',
  removed: 'success',
};

class UploadItem extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};
    const { thumbUrl } = nextProps.file;

    if (!isEqual(prevState.fileStatistics, nextProps.file)) {
      nextState.fileStatistics = { ...nextProps.file };
    }

    if (thumbUrl && thumbUrl !== prevState.fileStatistics.thumbUrl) {
      nextState.previewSrc = getPreviewSrc(nextProps.file);
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      previewSrc: getPreviewSrc(props.file),
      fileStatistics: { ...props.file },
    };
  }

  get progressStatus() {
    const { status } = this.props.file;
    if (status) {
      return fileStatusToProgressStatusMap[status];
    }

    return;
  }

  handleRemoveFile = () => {
    const { file } = this.props;
    this.props.removeFile && this.props.removeFile(file);
  }

  render() {
    const { file } = this.props;
    const { name, size, ext, percent } = file;
    const { previewSrc } = this.state;

    return (
      <li className={`${styles.attachmentItemWrapper} upload-box-attachment`}>
        <div className={`${styles.itemBody}`}>

          <div className={classnames('flex align-center', styles.uploaderContent)}>
            <header className="icon">
              {
                previewSrc
                  ? <img className="image-icon" src={previewSrc} />
                  : <AttachmentIcon ext={ext!} />
              }
            </header>

            <div className="content cell">
              <div className="file-info flex align-center">
                <div className="name cell">{name}</div>
                <div className="size">{prettyBytes(size)}</div>
              </div>
              {(<Progress
                status={this.progressStatus as ProgressProps['status']}
                percent={(percent || 0)}
                format={(p) => (p!).toFixed(2) + '%'}
                size="small"
              />)}
            </div>

            <div className={styles.uploaderHandler}>
              <Icon type="close" onClick={this.handleRemoveFile} />
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default UploadItem;
