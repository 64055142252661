/**
 * 根据产品类型的type显示不同的search
 */
import * as React from 'react';
import { EnumProductModel } from '@chipcoo/constant';
import { SelectWithDynamicOptionConfig } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

type Props = {
  value?: any;
  onChange?: (value: any) => void;
  productModel: EnumProductModel;
};

type State = {};

class SelectProductByModel extends React.PureComponent<Props, State> {
  getSelectProps = () => {
    const { productModel } = this.props;

    return {
      ...this.props,
      status: 'preview' as FormStatus,
      optionKey: 'getProductByProductModel',
      menuMaxHeight: 200,
      placeholder: '请选择（可筛选）',
      addNew: null,
      extraFetchParams: { productModel }
    };
  }

  render () {
    return (
      <SelectWithDynamicOptionConfig {...this.getSelectProps()} />
    );
  }
}

export default SelectProductByModel;
