import { findIndex } from 'lodash';
import { Room } from './chatRoomList.type';
import { imApi } from 'src/services/net';

/**
 * 是否应该重新排序整个房间列表
 * @param roomList
 * @param roomId
 * @param ignoreRoomExist 是否忽略去检查room数据是否存在，chatRoomList中的webpush新消息receiveNewMessage的时候，
 * 房间可能会不存在，这个时候会先去从服务器请求新的数据，这个时候并没有这个房间对应的数据，但更新的时候就有了
 * 因此这里把检查房间数据是否存在这个判断给忽略掉
 */
export const shouldResortRoomList = (roomList: NormalizedData<Room>, roomId: string, ignoreRoomExist?: boolean) => {
  const { byId, allIds } = roomList;

  if (!ignoreRoomExist && (!byId[roomId] || byId[roomId].isTop)) { return false; }

  // 第一个非置顶的房间的索引
  const firstNotTopRoomIndex = findIndex(allIds, (id: string) => !byId[id].isTop);

  return allIds[firstNotTopRoomIndex] !== roomId;
};

/**
 *
 * 来了新消息以后重新调整整个roomList的顺序
 * @param {NormalizedData<Room>} roomList
 * @param {string} roomId
 * @param {boolean} isTop 是否为置顶操作
 * @returns {any}
 */
export const sortRoomListByNewMessagePush = (roomList: NormalizedData<Room>, roomId: string, type?: string) => {
  const { allIds, byId } = roomList;

  // 不管列表中有没有这个房间，都尝试将来新消息的房间给过滤掉，后面在重新塞进列表
  const newAllIds = allIds.filter(id => id !== roomId);

  // 第一个非置顶的房间的索引
  const firstNotTopRoomIndex = findIndex(newAllIds, (id: string) => !byId[id].isTop);

  if (!~firstNotTopRoomIndex) {
    if (type === 'SET_TOP' && byId[roomId].isTop || type === 'CREATE_ROOM') {
      // 如果消息全部都是置顶，取消置顶，该房间直接push
      newAllIds.push(roomId);
    } else {
      // 如果消息全部都是置顶，新消息放在最上面
      newAllIds.unshift(roomId);
    }

  // 如果没有消息被置顶，直接插到最前面
  } else if (!firstNotTopRoomIndex) {
    newAllIds.unshift(roomId);

  // 剩下的直接splice进入列表就可以了
  } else {
    if (byId[roomId] && byId[roomId].isTop && type !== 'SET_TOP') {
      // 该房间为置顶时, 来新消息放在置顶列表最上面
      newAllIds.unshift(roomId);
    } else if (type === 'SET_TOP' && !byId[roomId].isTop) {
      newAllIds.unshift(roomId);
    } else {
      newAllIds.splice(firstNotTopRoomIndex, 0, roomId);
    }
  }

  return newAllIds;
};

export const createChatRoom = async (userIds: string[], isPrivate: boolean) => {
  const createRoomRequest = () => isPrivate ? imApi.putPrivateRoom(userIds[0]) : imApi.postCreateRoom(userIds);

  const { data } = await createRoomRequest();

  return data;
};
