import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { State } from './interface.d';
import { productApi } from 'src/services/net';
import { handleSummaryList, handleUnStockOutList, handleManuFacturedList } from './handler';

const initialState: State = {
  summaryList: [],
  summaryCount: 0,

  unStockOutList: [],
  unStockOutCount: 0,

  manuFacturedList: [],
  manuFacturedCount: 0,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setSummaryList(state: State, payload: { data: unknown[], count: number }) {
      return produce(state, draft => {
        draft.summaryCount = payload.count;
        draft.summaryList = handleSummaryList(payload.data);
      });
    },
    setUnStockOutSummaryList(state: State, payload: { data: unknown[], count: number }) {
      return produce(state, draft => {
        draft.unStockOutCount = payload.count;
        draft.unStockOutList = handleUnStockOutList(payload.data);
      });
    },
    setManufacturedUnStockOutSummary(state: State, payload: { data: unknown[], count: number }) {
      return produce(state, draft => {
        draft.manuFacturedCount = payload.count;
        draft.manuFacturedList = handleManuFacturedList(payload.data);
      });
    },
  },
  effects: (dispatch) => ({
    async getSummaryList(params: unknown) {
      const { data } = await productApi.searchOrderSummary(params);

      this.setSummaryList(data);
    },
    async getUnStockOutSummaryList(params: unknown) {
      const { data } = await productApi.searchUnStockOutSummary(params);

      this.setUnStockOutSummaryList(data);
    },
    async getManuFacturedUnStockOutSummaryList(params: unknown) {
      const { data } = await productApi.searchManufacturedUnStockOutSummary(params);

      this.setManufacturedUnStockOutSummary(data);
    },
  })
});
