import * as React from 'react';
import { Icon, Button, Divider } from 'antd';
import { EnumProductModel, EnumProcessClass } from '@chipcoo/constant';

interface CardExtraProps {
  productModel: EnumProductModel;
  isNeedCreateBtn: boolean;
  openQuery: (e: any) => void;
  openModal: (val?: EnumProcessClass) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery, openModal, productModel, isNeedCreateBtn } = props;

  return (
    <div>
      <a onClick={openQuery}>
        <Icon type="search" />
        查询
      </a>

      {
        isNeedCreateBtn && (
          <>
            <Divider type="vertical" />

            {
              productModel === EnumProductModel.assemblyProduct
                ? <Button type="primary" onClick={() => openModal(EnumProcessClass.assembly)}>添加</Button>
                : <Button type="primary" onClick={() => openModal(EnumProcessClass.dp)}>添加</Button>
            }
          </>
        )
      }
    </div>
  );
}

export default React.memo(CardExtra);
