import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { numberRangeVerify } from '@chipcoo/hanayo';

export function numberRangeValidatorByFormData() {
  return function(initialValue: number, curStatus: FormStatus, formData: any) {
    return [
      {
        validator: numberRangeVerify(`[0, ${initialValue}]`)
      }
    ];
  };
}
