import { EnumReportContent } from '@chipcoo/constant';
import { findIndex } from 'lodash';

import { CategoryConfig } from '../../../../AutoAnalysis/categoryConfig';

export interface ITag {
  key: EnumReportContent | 'PCM';
  name: string;
}

export const tagsData: ITag[] = [
  { name: 'BIN 详情', key: EnumReportContent.bins },
  { name: '失效柏拉图', key: EnumReportContent.topN },
  { name: 'CPK & PPK', key: EnumReportContent.topCPK },
  // { name: '参数正态分布', key: EnumReportContent.stdDist },
  { name: '正态分布', key: EnumReportContent.stdDist },
  { name: '散点图', key: EnumReportContent.scatter },
  { name: '箱型图', key: EnumReportContent.boxpolt },
  { name: '均值-标准差', key: EnumReportContent.meanStddev },
  { name: 'XY 散点图', key: EnumReportContent.xyScatter },
  { name: 'PCM 相关性', key: EnumReportContent.pcmCorrelation },
  { name: 'P/F MAP', key: EnumReportContent.pfMap },
  { name: 'BIN MAP', key: EnumReportContent.binMap },
  { name: '参数型 MAP', key: EnumReportContent.paramMap },
  { name: 'MAP 叠片', key: EnumReportContent.stackMap },
  { name: '相关性分析', key: EnumReportContent.correlation },
  { name: '对比分析', key: EnumReportContent.compare },
];

export const tagsDataInPCM: ITag[] = [
  // todo 将key改为具体的值
  { name: 'PCM 相关性', key: EnumReportContent.pcmCorrelation },
];

export const filterTags = (contents?: string[], type?: CategoryConfig['key']) => {
  if (type === 'PCM') {
    if (!contents) return tagsDataInPCM.filter((it, idx) => idx < 1);

    return tagsDataInPCM.filter(item => findIndex(contents, it => it === item.key) > -1);
  } else {
    if (type === 'FT') {
      if (!contents) return tagsData.filter((it, idx) => idx < 5);
    } else {
      if (!contents) return tagsData;
    }

    return tagsData.filter(item => findIndex(contents, it => it === item.key) > -1);
  }
};
