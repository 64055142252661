// tslint:disable:max-file-line-count

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { bind } from 'lodash-decorators';
import log from 'loglevel';
import { filterUser } from '@chipcoo/fe-utils';

import './style.less';
import { HocSingleUserItem } from './SingleUserItem';
import { UserPickPopover, QuickSelectCommonProps } from './UserPickPopover';
import { isEqualUserId } from 'src/utils/user';

export type CanUserRemove = boolean | ((memberId: string) => boolean);
type Size = 'small' | 'mini';
type Mode = 'single' | 'multiple';

export interface RemovableProps {
  canRemove?: CanUserRemove;
  showRemove?: boolean;
}

export interface QuickSelectProps extends RemovableProps, QuickSelectCommonProps {
  users: User[];
  className?: string;
  value?: string[] | string;
  size?: Size;
  maxShowNum?: number;
  mode?: Mode;
  onChange?: (memberIds: string[] | string) => any;
  onChangeLazy?: (memberIds: string[] | string) => any;
  filterOption?: (filterValue: any, record?: User) => boolean;
  defaultValue?: string[] | string;
  displayName?: string | string[] | ((member: User) => string);
  canSelect?: boolean | ((user: User<any>) => boolean);
  showInList?: boolean | ((user: User<any>) => boolean);
}

interface States {
  selected: string[] | string;
  prevValue: string[] | string;
  popoverVisible: boolean;
}

function onChangeFn(selected: string[] | string) {
  this.setState({ selected });
}

export class QuickSelectUser extends PureComponent<QuickSelectProps, States> {
  static defaultProps: Partial<QuickSelectProps> = {
    users: [],
    className: '',
    size: 'small',
    mode: 'multiple',
    autoFocus: true,
    maxShowNum: Infinity, // Unlimited show nums.
    onChange: onChangeFn,
    filterOption(val: string, record: User) {
      if (!val) return true;
      return filterUser(val, record);
    },
    displayName: ['remarkName', 'nickname', 'name'],
    canEdit: true,
    canRemove: true,
    showRemove: false,
    canSelect: true,
    userDisabled: false,
    showInList: true,
    placeholder: '添加协作区成员'
  };

  private $el;

  static getDerivedStateFromProps(nextProps: QuickSelectProps, prevState: States) {
    const nextState: Partial<States> = {};

    // support
    if (nextProps.value !== undefined && !isEqual(prevState.prevValue, nextProps.value)) {
      nextState.prevValue = nextProps.value;
      if (!isEqual(nextProps.value, prevState.selected)) {
        nextState.selected = nextProps.value;
      }
    }

    return nextState;
  }

  constructor(props: QuickSelectProps) {
    super(props);
    const { defaultValue } = props;
    const isMultiple = props.mode === 'multiple';
    const defaultVal = defaultValue || (isMultiple ? [] : '');
    let prevValue = props.value || defaultVal;

    if (isMultiple) {
      prevValue = prevValue.slice();
    }

    this.state = {
      selected: prevValue,
      prevValue,
      popoverVisible: false,
    };

    this.$el = React.createRef();
  }

  componentDidMount() {
    const { defaultValue, value, onChange } = this.props;

    if (!value && defaultValue) {
      onChange!(defaultValue);
    }
  }

  get isMultiple() {
    return this.props.mode === 'multiple';
  }

  @bind
  popoverVisibleChange(popoverVisible: boolean) {
    this.setState({ popoverVisible });

    if (!this.state.popoverVisible) {
      // Handle close modal.
      const { onChangeLazy } = this.props;
      const { selected } = this.state;
      onChangeLazy && onChangeLazy(selected);
    }
  }

  @bind
  toggleSelect(id: string) {
    const { selected } = this.state;
    const { onChange } = this.props;
    const find = this.props.users.find(user => isEqualUserId(user._id, id));

    if (!find) {
      const err = new Error(`[QuickSelectUser]: user#${id} is not found in users. print error.users see user in props`);
      (err as any).users = this.props.users;
      throw err;
    }

    if (this.isMultiple) {
      const val = selected as string[];
      const exists = val.some(it => isEqualUserId(it, id));

      if (exists) {
        if (this.canUserRemove(id)) {
          onChange!.call(this, val.filter(it => !isEqualUserId(id, it)));
        }
      } else {
        this.canUserSelect(find) && onChange!.call(this, val.concat(id));
      }
    } else {
      this.canUserSelect(find) && onChange!.call(this, id);
    }
  }

  // 移除用户，只在多选模式调用。
  @bind
  removeUser(id: string) {
    const { onChange } = this.props;
    const val = this.state.selected as string[];
    if (!Array.isArray(val)) {
      //
      log.error('[QuickSelectUser]: remove user only works in multiple mode, current mode is' + this.props.mode);
    }
    if (this.canUserRemove(id)) {
      onChange && onChange.call(this, val.filter(it => !isEqualUserId(id, it)));
    }
  }

  @bind
  getEl() {
    return this.$el.current;
  }

  @bind
  canUserRemove(memberId: string) {
    const { canRemove } = this.props;
    if (typeof canRemove === 'function') {
      return canRemove(memberId.toString());
    }

    return !!canRemove;
  }

  @bind
  canUserSelect(user: User<any>) {
    const { canSelect } = this.props;
    if (typeof canSelect === 'function') {
      return canSelect(user);
    }

    return !!canSelect;
  }

  @bind
  showInList(user: User<any>) {
    const { showInList } = this.props;
    if (typeof showInList === 'function') {
      return showInList(user);
    }

    return !!showInList;
  }

  @bind
  getUserDisplayName(user: User) {
    let { displayName } = this.props;

    if (typeof displayName === 'string') {
      displayName = [displayName];
    }

    if (Array.isArray(displayName)) {
      let name;
      displayName.some((key) => {
        if (user[key]) {
          name = user[key];

          return true;
        }

        return false;
      });

      return name || user.nickname;
    }

    return displayName!(user);
  }

  renderStyle() {
    return null;
  }

  @bind
  renderSingleUser(member: User, avatarOnly: boolean = true) {
    const { canEdit } = this.props;
    return (
      <HocSingleUserItem
        userDisabled={this.props.userDisabled}
        avatarOnly={avatarOnly}
        userInfo={member}
        displayName={this.getUserDisplayName(member)}
        key={member._id}
        canEdit={canEdit}
        removeUser={this.removeUser}
        canUserRemove={this.canUserRemove}
      />
    );
  }

  renderSelectedList() {
    const { users } = this.props;
    const { selected } = this.state;
    if (Array.isArray(selected)) {
      return selected.reduce((nodes, memberId) => {
        const member = users.find(m => isEqualUserId(m._id, memberId));
        if (member) {
          nodes.push(this.renderSingleUser(member));
        }

        return nodes;
      }, [] as JSX.Element[]);
    }

    if (!selected) {
      return [];
    }

    const mem = users.find(m => isEqualUserId(m._id, selected));
    return [this.renderSingleUser(mem!)];
  }

  render() {
    const { className, placeholder, size, autoFocus, canEdit } = this.props;
    const { popoverVisible, selected } = this.state;
    const { isMultiple } = this;
    return (
      <div className={classnames(className, size, 'quick-select-member')} ref={this.$el}>
        {this.renderStyle()}
        {isMultiple &&
          <div className={classnames('selected-members')}>
            {this.renderSelectedList()}
          </div>
        }
        <UserPickPopover
          getPopupContainer={this.getEl}
          getDisplayName={this.getUserDisplayName}
          users={this.props.users}
          selected={selected}
          placeholder={placeholder}
          value={popoverVisible}
          canEdit={canEdit}
          userDisabled={this.props.userDisabled}
          filterOption={this.props.filterOption!}
          onVisibleChange={this.popoverVisibleChange}
          onToggleSelect={this.toggleSelect}
          autoFocus={!!autoFocus}
          showInList={this.showInList}
        />
      </div>
    );
  }
}

export default QuickSelectUser;
