import _ from 'lodash';
import moment from 'moment';

const findAll = (data, node, process) => {
  let indices: number[] = [];

  for (let i = 0; i < data.length; i++) {
    const item = data?.[i];
    if (item?.node === node && item.process === process) {
      indices.push(i);
    }
  }

  return indices;
};

export const handleDataSource = (respData: any[], filters?: Obj) => {
  let [data, allList, childColumnKey, mpwDate, years, months]: any[] = [[], [], '', '', [], {}];

  respData.forEach(it => {
    if (_.isArray(it?.processes)) {
      it?.processes!.forEach(p => {

        if (_.isArray(p?.buses)) {
          p?.buses!.forEach(b => {
            const { _id, date, process, node, month, preorders } = b;
            // 由于moment月份是从0开始，而后端给的是从1开始，所以这边减1
            const currentMonth = month - 1;

            if (date) {
              childColumnKey = moment(date).locale('en').format('MMM');

              const day = moment(date).format('DD');
              const year = moment(date).format('YYYY');

              mpwDate = childColumnKey + '-' + day;

              // 用来记录每个年份里有多少月份数
              const monthArr = months[year] || [];

              months = {
                ...months,
                [year]: monthArr.concat(month)
              };

              if (years.indexOf(year) < 0) {
                years.push(year);
              }
            }

            const indices = findAll(data, node, process);
            const currentData = {
              ...b,
              mpwDate,
              iconType: preorders ? 'form' : 'plus',
              className: preorders ? 'blue-bus' : 'green-bus'
            };
            const setData = index => {
              const item = data?.[index]?.[currentMonth];

              if (!item) {
                data[index] = {
                  ...data[index],
                  [currentMonth]: currentData
                };
              }
            };

            // indices不为空的情况
            if (!_.isEmpty(indices)) {
              // indices只有一行的情况
              if (indices?.length === 1) {
                const index = indices[0];
                setData(index);
              } else {
                // indices多行的情况
                indices.forEach(index => {
                  setData(index);
                });
              }
            } else {
              const bus = {
                node,
                process,
                key: _id,
                [currentMonth]: currentData
              };

              data.push(bus);
            }
          });
        }
      });
    }
  });

  allList = data;

  if (!_.isEmpty(filters) || (!_.isEmpty(filters?.node) && !_.isEmpty(filters?.process))) {
    data = data.filter(it => {
      if (!filters?.node || _.isEmpty(filters?.node)) return true;

      return filters?.node.includes(it?.node);
    }).filter(it => {
      if (!filters?.process || _.isEmpty(filters?.process)) return true;

      return filters?.process.includes(it?.process);
    });
  }

  let monthLength = 0;

  Object.keys(months).forEach(it => {
    months[it] = _.uniq(months[it]);
    monthLength += months?.[it]?.length;
  });

  if (monthLength > 12) {
    console.warn(`后端返回的月份数大于12个月，询问后端具体情况；返回的月份为`, months);
  }

  return {
    list: data,
    allList,
    years,
    months
  };
};
