import * as React from 'react';
import { isEmpty } from 'lodash';
import { Collapse, Empty } from 'antd';
import { FormCreator } from '@chipcoo/hanayo';
import { EnumProductModel } from '@chipcoo/constant';
import { getFactoryName, noop } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { getFormConfig } from './formConfigs/index';
import { ProductInfoCxt } from '../ProductModal/context';

interface Props {
  processCode: string;
  header: string;
  productProcess: any[];
  productModel: EnumProductModel;
}

interface State {
  activePanelKeys: string[];
}

class ProcessItemCollapse extends React.PureComponent<Props, State> {
  static contextType = ProductInfoCxt;

  constructor(props: Props) {
    super(props);
    const { productProcess } = props;
    const keys = productProcess.map(it => it?._id);

    this.state = {
      activePanelKeys: keys
    };
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  getHeader = (processItem) => {
    const { factory, priority } = processItem;
    const name = getFactoryName(factory);

    return name + `(${ priority === 1 ? 'A' : 'B' })`;
  }

  render () {
    const { productProcess, productModel, header, processCode } = this.props;
    const { activePanelKeys } = this.state;
    const productInfo = this.context?.productInfo;

    if (isEmpty(productProcess)) {
      return <Empty description={`暂无${header ? header + '的' : ''}产品制程`} />;
    }

    return (
      <>
        {
          productProcess.map(it => {
            const processClass = it?.processClass;

            return (
              <Collapse
                key={it?._id}
                className={styles.processEditCollapse}
                activeKey={activePanelKeys}
              >
                <Collapse.Panel
                  key={it?._id}
                  header={this.getHeader(it)}
                >
                  <FormCreator
                    globalStatus="preview"
                    formConfig={getFormConfig({ productModel, processClass })}
                    formData={{ ...it, processClass, productInfo, processCode }}
                    className={styles.processFormCreator}
                  />
                </Collapse.Panel>
              </Collapse>
            );
          })
        }
      </>
    );
  }
}

export default ProcessItemCollapse;
