import { EnumProcessClass } from '@chipcoo/constant';
import {
  FileFormItemConfig,
  InputFormItemConfig,
  NumberFormItemConfig,
  TextAreaFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';

import { attachmentApi } from 'src/services/net';

const fileDefaultProps = {
  getFileDetail: fileId => fileId && attachmentApi.getDetail(fileId),
  getUrl: fileId => fileId && attachmentApi.download({ attachmentId: fileId })
};

/*************************** cp芯片 **********************************/
const getCpRenderType = (processClass: EnumProcessClass) => {
  return processClass === EnumProcessClass.cp ? 'normal' : 'none';
};
// 测试机型号
const testerModel = (): InputFormItemConfig => ({
  label: '测试机型号',
  type: 'input',
  dataKey: 'scheme.testerModel',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的测试机型号' }]
});

// 测试机配置
const testerOption = (): InputFormItemConfig => ({
  label: '测试机配置',
  type: 'input',
  dataKey: 'scheme.testerOption',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的测试机配置' }]
});

// 探针台型号
const probeModel = (): InputFormItemConfig => ({
  label: '探针台型号',
  type: 'input',
  dataKey: 'scheme.probeModel',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的探针台型号' }]
});

// 同测数
const simultaneousSites = (): NumberFormItemConfig => ({
  label: '同测数',
  type: 'number',
  dataKey: 'scheme.simultaneousSites',
  precision: 0,
  range: '(0, 1024]',
});

// 针卡名称
const probeCardName = (): InputFormItemConfig => ({
  label: '针卡名称',
  type: 'input',
  dataKey: 'scheme.probeCardName',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的针卡名称' }]
});

// 针卡PCB文件
const probeCardPcb = (): FileFormItemConfig => ({
  label: '针卡PCB文件',
  type: 'file',
  dataKey: 'scheme.probeCardPCB',
  props: fileDefaultProps
});

// 针卡照片
const photoOfProbeCard = (): FileFormItemConfig => ({
  label: '针卡照片',
  type: 'file',
  dataKey: 'scheme.photoOfProbeCard',
  props: fileDefaultProps
});

// 测试程序名称
const testCodeName = (): InputFormItemConfig => ({
  label: '测试程序名称',
  type: 'input',
  dataKey: 'scheme.testCodeName',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的测试程序名称' }]
});

// 测试程序文件
const testCodeFile = (): FileFormItemConfig => ({
  label: '测试程序文件',
  type: 'file',
  dataKey: 'scheme.testCodeFile',
  props: fileDefaultProps
});

// 测试程序CheckSum
const testCodeCheckSum = (): InputFormItemConfig => ({
  label: '测试程序CheckSum',
  type: 'input',
  dataKey: 'scheme.testCodeCheckSum',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的测试程序CheckSum' }]
});

// 测试项与失效BIN的对应关系
const failRelation = (): FileFormItemConfig => ({
  label: '测试项与失效BIN的对应关系',
  type: 'file',
  dataKey: 'scheme.failRelation',
  props: fileDefaultProps
});

// 测试项时间分布
const timeDistributionOfTestItems = (): FileFormItemConfig => ({
  label: '测试项时间分布',
  type: 'file',
  dataKey: 'scheme.timeDistributionOfTestItems',
  props: fileDefaultProps
});

// 每片晶圆测试时间
const waferTestTime = (): NumberFormItemConfig => ({
  label: '每片晶圆测试时间',
  type: 'number',
  dataKey: 'scheme.waferTestTime',
  unit: 'h',
  range: '(0, 100]',
});

// 平均每颗测试时间
const dieTestTime = (): NumberFormItemConfig => ({
  label: '平均每颗测试时间',
  type: 'number',
  dataKey: 'scheme.dieTestTime',
  unit: 's',
  range: '(0, 1000]',
  hint: '(测试项时间+晶圆移动时间)/同测数',
});

// 中测方案-备注
const cpRemark = (): TextAreaFormItemConfig => ({
  label: '备注',
  type: 'textArea',
  dataKey: 'scheme.remark',
  colSpan: 24,
  validate: [{ pattern: /^[\d\D]{0,300}$/, message: '最多300个字符' }]
});

const cp = [
  testerModel(),
  testerOption(),
  probeModel(),
  simultaneousSites(),
  probeCardName(),
  probeCardPcb(),
  photoOfProbeCard(),
  testCodeName(),
  testCodeFile(),
  testCodeCheckSum(),
  failRelation(),
  timeDistributionOfTestItems(),
  waferTestTime(),
  dieTestTime(),
  cpRemark(),
];

const cpFormConfig = cp.map(it => ({
  ...it,
  renderType() {
    return getCpRenderType(this.formData.processClass);
  },
}));

export { cpFormConfig };
