// tslint:disable:max-file-line-count
/**
 * 左侧选择面板
 */
import * as React from 'react';
import { Checkbox, Input, Collapse, Icon } from 'antd';
import { isEqual, difference } from 'lodash';
import { bind, debounce } from 'lodash-decorators';
import classnames from 'classnames';
import log from 'loglevel';
import { stopPropagation, getClosetAttrVal, noop } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { DataItem, MemberSelectGroup } from './index';
import { GroupPanel } from './GroupPanel';
// import { PanelTitle } from './PanelTitle';

import { ControlledComponent } from 'src/hoc';
import { isEqualUserId } from 'src/utils/user';

interface P {
  groups?: MemberSelectGroup[];
  groupMode?: boolean;
  dataSource: DataItem[];
  selectedKeys: string[];
  title: string | React.ReactNode;
  render: (record: DataItem) => React.ReactNode;
  onSelectedKeysChange: (selectedKeys: string[]) => void;
  filterOption?: (filterValue: any, record: DataItem) => boolean;
}
interface S {
  indeterminate: boolean;
  checkAll: boolean;
  selectedKeys: string[];
  dataSource: DataItem[];
  // dataSourceKeys: string[];
  activePanelKeys: string[];
  // 筛选的value
  searchValue: any;
}

const ControlledInput = ControlledComponent(Input);
const blankArr = [];
// 根据原数据和被选中的数据来判断全选的checkbox的状态
const getCheckAllStatus = (nextProps: P) => {
  const { selectedKeys, dataSource } = nextProps;
  const available = dataSource.filter(it => !Boolean(it.disabled)).length;
  const selectedKeysLen = selectedKeys.length;

  return {
    indeterminate: !!selectedKeysLen && (selectedKeysLen < available),
    checkAll: !!available && selectedKeysLen === available
  };
};

const { Panel } = Collapse;

class LeftList extends React.PureComponent<P, S> {
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};

    const { selectedKeys } = nextProps;

    if (!isEqual(selectedKeys, prevState.selectedKeys)) {
      nextState.selectedKeys = nextProps.selectedKeys;
    }

    if (!isEqual(nextProps.dataSource, prevState.dataSource)) {
      nextState.dataSource = nextProps.dataSource;
    }

    if (nextState.dataSource || nextState.selectedKeys) {
      Object.assign(nextState, getCheckAllStatus(nextProps));
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    const { dataSource, selectedKeys } = props;

    this.state = {
      ...getCheckAllStatus(props),
      searchValue: void 0,
      dataSource,
      selectedKeys,
      activePanelKeys: [],
      // dataSourceKeys: dataSource.map(item => item.key)
    };
  }

  @bind
  onPanelActiveKeysChange(keys: string[]) {
    //
    const { activePanelKeys } = this.state;
    // const closed = difference(activePanelKeys, keys);
    const opened = difference(keys, activePanelKeys);

    this.checkLoadingMember(opened);
    this.setState({ activePanelKeys: keys });
  }

  @bind
  checkLoadingMember(keys: string[]) {
    keys.forEach(key => {
      const find = this.props.groups!.find(group => group.groupKey === key);
      if (find && find.isAsync) {
        this.loadingMembers(find);
      }
    });
  }

  @bind
  async loadingMembers(group: MemberSelectGroup) {
    if (group.loading || group.fetched) {
      return;
    }
    if (!group.fetch) {
      log.error('[MemberSelectGroup]: In async group, `fetch` prop must be provided.');
      return;
    }

    await group.fetch!(group);
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  filterCouldSelectMembers = () => {
    const { dataSource } = this.props;

    return dataSource
      .filter(item => !item.disabled)
      .map(item => item.key);
  }

  onCheckAllChange = (e) => {
    const isChecked = e.target.checked;
    const selectedKeys = isChecked ? this.filterCouldSelectMembers() : blankArr;

    this.setState({
      indeterminate: false,
      checkAll: isChecked
    });

    this.props.onSelectedKeysChange(selectedKeys);
  }

  onCheckCardChange = (e) => {
    /**
     * 2018.9.20
     * Checkbox组件中onChange事件拿到的这个e并不是一个event对象，具体可以看：
     * https://github.com/react-component/checkbox/blob/master/src/Checkbox.jsx#L87，onChange的回调中
     * 有一个nativeEvent: e.nativeEvent，想要拿dom得从nativeEvent中才能拿到
     */
    const clickedCardId = getClosetAttrVal(e.nativeEvent.target, 'data-id') || '';
    const { selectedKeys, dataSource } = this.props;
    // const { dataSourceKeys } = this.state;
    // 单个的checkbox的选中和取消
    const contain = selectedKeys.some(id => isEqualUserId(clickedCardId, id));
    const newSelectedKeys = contain
      ? selectedKeys.filter(key => !isEqualUserId(key, clickedCardId))
      : dataSource.map(source => source.key).filter(
        key => isEqualUserId(key, clickedCardId) || selectedKeys.some(_key => isEqualUserId(key, _key))
      );
    const checkboxStatus = getCheckAllStatus({ ...this.props, selectedKeys: newSelectedKeys });

    this.setState({ ...checkboxStatus });
    this.props.onSelectedKeysChange(newSelectedKeys);
  }

  @bind
  @debounce(200)
  onSearchChange(value: any) {
    this.setState({ searchValue: (value || '').trim() });
  }

  // 点击每个card后的checkbox的改变
  handleClickItemCard = (e) => {
    const target = e.currentTarget;
    const checkboxDom = target.querySelector('.ant-checkbox-wrapper');

    if (checkboxDom) checkboxDom.click();
  }

  get filteredList() {
    return this.props.dataSource.reduce((all, item) => {
      const { searchValue } = this.state;
      const { filterOption } = this.props;

      let filterResult = true;
      if (searchValue !== void 0 && filterOption) {
        filterResult = filterOption(searchValue, item);
      }

      if (filterResult) {
        all.push(item);
      }

      return all;
    }, [] as DataItem[]);
  }

  renderCardItem = (item: DataItem) => {
    const { key, checked, disabled } = item;
    const { render, selectedKeys } = this.props;
    const isChecked = Boolean(checked || selectedKeys.includes(key));

    const isDisabled = Boolean(disabled);

    return (
      <div
        key={key}
        data-id={key}
        onClick={this.handleClickItemCard}
        className={classnames({ disabled: isDisabled }, styles.contentItem, 'flex')}
      >
        <div onClick={stopPropagation}>
          <Checkbox checked={isChecked} disabled={isDisabled} onChange={this.onCheckCardChange} />
        </div>
        <div className="cell">
          {render(item)}
        </div>
      </div>
    );
  }

  @bind
  renderGroupPanel(group: MemberSelectGroup) {
    //
    const { selectedKeys, render, dataSource } = this.props;
    const { groupKey, groupTitle } = group;
    return (
      <Panel key={groupKey} header={groupTitle} className={''}>
        <GroupPanel
          handleClickItemCard={this.handleClickItemCard}
          onCheckBoxChange={this.onCheckCardChange}
          render={render}
          dataSource={dataSource}
          selectedKeys={selectedKeys}
          group={group}
        />
      </Panel>
    );
  }

  renderCollapse() {
    //
    const { activePanelKeys } = this.state;
    const { groups } = this.props;
    return (
      <Collapse
        onChange={this.onPanelActiveKeysChange}
        activeKey={activePanelKeys}
        className={classnames('select-member-panel-collapse')}
        bordered={false}
      >
        {groups!.map(this.renderGroupPanel)}
      </Collapse>
    );
  }

  renderContent() {
    if (this.state.searchValue || !this.props.groupMode) {
      return this.filteredList.map(this.renderCardItem);
    }

    return this.renderCollapse();
  }

  render() {
    const { indeterminate, checkAll } = this.state;
    const { title, selectedKeys } = this.props;

    const available = this.props.dataSource.filter(it => !Boolean(it.disabled)).length;

    return (
      <div className={styles.list}>
        <div className={styles.listHeader}>
          <Checkbox
            checked={checkAll}
            onChange={this.onCheckAllChange}
            indeterminate={indeterminate}
          />
          {title}
          <p style={{ marginLeft: 4 }}>
            {selectedKeys.length ? `${selectedKeys.length}/` : ''}
            {available} 项
          </p>
        </div>
        <div className={styles.listContentWrapper}>
          <div className="filter-input-wrapper">
            <ControlledInput
              prefix={<Icon type="search" />}
              className="member-select-panel-filter-input"
              placeholder="筛选"
              allowClear={true}
              onChange={this.onSearchChange}
            />
          </div>

          <div className={`y-scroll thin-scroll ${styles.leftListContent}`}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default LeftList;
