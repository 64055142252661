import React from 'react';

export enum EnumContentTags {
  overview = 'OVERVIEW',
  tendency = 'TENDENCY',
}

export interface IAnalysisReport {
  loading?: boolean; // 页面loading
  content: EnumContentTags;
  reportFilter?: Obj;

  setLoading?: (bool: boolean) => void;
  setContent?: (content: EnumContentTags) => void;
  setFilters?: (queryParams: Obj) => void;
}

export const TrendAnalysisReportCtx = React.createContext<IAnalysisReport>({
  loading: false,
  content: EnumContentTags.overview,
  reportFilter: {}
});
