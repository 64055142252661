import { IM } from './';
import { syncSocket } from 'src/socket';
import store from 'src/store';
import log from 'loglevel';
import { getCurrentRoute } from 'src/components/HoneyRouter';

const LOG_NS = '[ImSyncSocket]';

export async function subscribe(ctx: IM, roomIds: string[]) {
  //
  roomIds.forEach(async (id) => {
    //
    try {
      const subscription = await syncSocket.subscribe({
        name: 'room', id,
      }, handleRoomSocket);
  
      if (ctx.unmounted) {
        subscription.unsubscribe();
      } else {
        ctx.subscriptions[id] = subscription;
      }
    } catch (e) {
      log.debug(LOG_NS, `room(roomId: ${id}) subscribe failed`, e);
    }
    try {
      const msgSubscription = await syncSocket.subscribe({
        name: 'room', id, role: 'messages'
      }, store.dispatch.chatPanel.syncRoomMessage);
  
      if (ctx.unmounted) {
        msgSubscription.unsubscribe();
      } else {
        ctx.msgSubscriptions[id] = msgSubscription;
      }
    } catch (e) {
      //
      log.debug(LOG_NS, `room message(roomId: ${id}) subscribe failed`, e);
    }
  });
}

export function unsubscribe(ctx: IM, roomIds?: string[]) {
  let ids = roomIds;
  if (!ids) {
    ids = ctx.props.chatRoomIds;
  }

  ids.forEach(roomId => {
    ctx.subscriptions[roomId] && ctx.subscriptions[roomId].unsubscribe();
    ctx.msgSubscriptions[roomId] && ctx.msgSubscriptions[roomId].unsubscribe();
  });
}

export function handleRoomSocket(data: ISyncSocketData) {
  const { event } = data;
  switch (event) {
    case 'update': 
      store.dispatch.chatRoomList.setRoomName(data);
      break;
    case 'add-user':
      store.dispatch.chatPanel.syncAddNewMemberToRoom(data);
      break;
    case 'remove-user':
      const { history } = getCurrentRoute()!;
      store.dispatch.chatPanel.someoneRemoved({ data, history });
      break;
    default: 
  } 
}
