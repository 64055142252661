// tslint:disable:max-file-line-count

import React from 'react';
import { get, has } from 'lodash';
import { PropsAPIHocFix, withPropsAPI } from 'gg-editor';
import { Form, Select, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { debounce, bind } from 'lodash-decorators';

import log from 'loglevel';

import DetailEditor, { getPopupContainer } from './DetailEditor';
import {
  DEST_CRAFT_ROUTE_NODE,
  SOURCE_CRAFT_ROUTE_NODE,
  // updateLabel,
} from '../core';
import { CraftRouteContext } from '../context';
import { productApi, craftRouteApi } from 'src/services/net';
import { EnumProductModel } from '@chipcoo/constant';
import {
  ICraftRouteNode,
  // ICraftRouteEdge,
} from '../interface';
import { ICraftRouteFullRoute, IMaterialDetail } from 'src/services/net/craftRoute';

const inlineFormItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

export const END_CRAFT_ROUTE = {
  _id: 'AS_SOURCE_PRODUCT_MATERIAL',
  label: '直接使用物料',
};

export interface IProductSearchItem {
  product: {
    dieName?: string;
    deviceNo?: string;
    _id: string;
  };
  materials: IMaterialDetail[];
  productModel: EnumProductModel;
  /** 产品名 */
  name: string;
  /** 所属组织 */
  organization: string;
  /** 产品ID */
  _id: string;
}

interface Props extends PropsAPIHocFix, FormComponentProps {

}

interface State {
  craftRoutes: ICraftRouteFullRoute[];
  searchResult: IProductSearchItem[];
  fetchingProducts: boolean;
  fetchingCraftRoutes: boolean;
}

export class NodeEditor extends DetailEditor<Props, State> {
  static previousCraftRoutes: ICraftRouteFullRoute[] = [];
  static previousSearchResult: IProductSearchItem[] = [];
  static previousFetchedCraftRoutes: boolean = false;
  static previousSearchValue: string = '';
  static contextType = CraftRouteContext;

  context!: React.ContextType<typeof CraftRouteContext>;

  public lastFetchId = 0;
  /** 标记是否已经取过工艺路线 */
  private fetchedCraftRoutes = false;
  private searchValue: string = '';

  constructor(props: Props) {
    super(props);
    this.state = {
      craftRoutes: [],
      searchResult: [],
      fetchingProducts: false,
      fetchingCraftRoutes: false,
    };
  }

  componentDidMount() {
    super.componentDidMount && super.componentDidMount();

    if (DetailEditor.previousId !== this.modelId) {
      this.fetchProduct(this.searchValue);
      this.fetchCraftRoutes();
    } else {
      this.setState({
        craftRoutes: NodeEditor.previousCraftRoutes,
        searchResult: NodeEditor.previousSearchResult,
      });
      this.searchValue = NodeEditor.previousSearchValue;
      this.fetchedCraftRoutes = NodeEditor.previousFetchedCraftRoutes;
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { state: { craftRoutes, searchResult }, searchValue, fetchedCraftRoutes } = this;
    NodeEditor.previousSearchValue = searchValue;
    NodeEditor.previousSearchResult = searchResult;
    NodeEditor.previousCraftRoutes = craftRoutes;
    NodeEditor.previousFetchedCraftRoutes = fetchedCraftRoutes;

    setTimeout(() => {
      NodeEditor.previousSearchValue = '';
      NodeEditor.previousSearchResult = [];
      NodeEditor.previousCraftRoutes = [];
      NodeEditor.previousFetchedCraftRoutes = false;
    });
  }

  get cardProps() {
    return {
      ...this._cardProps,
      title: '注解',
    };
  }

  get model() {
    if (this.item) {
      return this.item.getModel() as ICraftRouteNode;
    }

    return {} as any as ICraftRouteNode;
  }

  get isDest() {
    const { shape } = this.model;
    return shape === DEST_CRAFT_ROUTE_NODE;
  }

  get destProductId() {
    const graph = this.item.getGraph();
    if (graph) {
      const nodes = graph.getNodes();
      const destNode = nodes.find(node => node.model.shape === DEST_CRAFT_ROUTE_NODE);

      return get(destNode, 'model.product._id');
    }

    return;
  }

  getProduct(productId: string) {
    return this.state.searchResult.find(it => it._id === productId);
  }

  getUpdateValues(values: any) {
    const { amount, productId, craftRouteId, material } = values;
    const updateValues: Partial<ICraftRouteNode> = {
      amount,
    };

    const productChange = productId !== get(this.model.product, '_id');
    const materialChange = material !== get(this.model.material, '_id');

    if (productChange) {
      const product = this.getProduct(productId);

      if (product) {
        updateValues.product = product.product;
        updateValues.productModel = product.productModel;
        updateValues.materials = product.materials;
      } else {
        updateValues.product = null;
        updateValues.productModel = null;
        updateValues.materials = null;
      }

      updateValues.material = undefined;
    }

    if (materialChange) {
      const materials = this.model.materials || [];
      const materialObj = materials.find(m => m._id === material);
      if (materialObj) {
        updateValues.material = materialObj;
      }
    }

    if (materialChange || productChange) {
      // clear craft routes
      updateValues.craftRouteId = undefined;
      updateValues.craftRoutes = null;
    }

    // craft route id change
    if (craftRouteId !== this.model.craftRouteId) {
      updateValues.craftRouteId = craftRouteId;
      updateValues.craftRoutes = this.state.craftRoutes;
    }

    return updateValues;
  }

  @bind
  async handleProductChange(productId: string) {
    this.props.form.setFieldsValue({
      craftRouteId: undefined,
      material: undefined,
    });

    this.setState({ craftRoutes: [] });
    this.fetchCraftRoutes();
    this.setUnSaved();
    this.handleSubmit();
  }

  @bind
  @debounce(200)
  async fetchProduct(val: string) {
    //
    const value = val.trim();
    this.searchValue = value;

    this.setState({ fetchingProducts: true });
    const params: any = {};

    if (value) {
      params.chipcooName = value;
    }

    const resp = await productApi.search(params);
    const searchResult = resp.data.data as IProductSearchItem[];

    const nextState = {
      ...this.state,
      fetchingProducts: false,
    };
    if (value === this.searchValue) {
      nextState.searchResult = searchResult;
    }

    this.setState(nextState);
  }

  @bind
  @debounce(150)
  async fetchCraftRoutes() {
    const productId = this.props.form.getFieldValue('productId');

    if (!productId) {
      return;
    }

    this.setState({ fetchingCraftRoutes: true });
    let type = this.model.productModel || undefined;
    const resp = await craftRouteApi.detail({ productId, type });

    const result: ICraftRouteFullRoute[] = [];

    if (has(resp.data, 'length')) {
      result.push(...resp.data);
    } else {
      log.warn(`[CraftRouteNodeEditor]: fetchCraftRoutes failed, 取产品ID为(${productId})的工艺路线失败。`, resp);
    }

    // 标记已经取过工艺路线
    this.fetchedCraftRoutes = true;

    if (this.props.form.getFieldValue('productId') === productId) {
      this.setState({ craftRoutes: result, fetchingCraftRoutes: false });
    }
  }

  @bind
  onSelectMaterial() {
    this.props.form.setFieldsValue({ craftRouteId: undefined });
    this.setUnSaved();

    this.handleSubmit();
  }

  renderOptions() {
    const options: IProductSearchItem[] = [];
    const model = this.item.getModel() as ICraftRouteNode;

    try {
      if (model.product) {
        const { product, productModel, materials } = model;
        const { deviceNo, dieName, _id } = model.product;
        const find = this.state.searchResult.find(it => it._id === _id);
        if (!find) {
          options.push({
            product,
            _id,
            materials: materials!,
            name: deviceNo || dieName || '',
            productModel: productModel!,
            // mock
            organization: '',
          });
        }
      }
    } catch (e) {
      //
      log.warn('[CraftRouteNodeEditor]: 未找到初始的产品信息', e);
    }

    options.push(...this.state.searchResult.filter((product) => (
      product._id !== this.destProductId
    )));

    return options.map((item) => {
      const { name, _id } = item;

      return <Select.Option key={_id} value={_id}>{name}</Select.Option>;
    });
  }

  renderCraftRouteOptions() {
    let { craftRoutes, fetchingCraftRoutes } = this.state;

    if (fetchingCraftRoutes) {
      return [];
    }

    // 如果没取过工艺路线且 model 中有备份数据，使用备份数据
    if (!this.fetchedCraftRoutes && this.model.craftRoutes) {
      craftRoutes = this.model.craftRoutes;
    }

    craftRoutes = craftRoutes.filter(craftRoute => {
      const { material } = craftRoute;

      return material && material._id === this.props.form.getFieldValue('material');
    });

    if (craftRoutes.length === 0) {
      const { _id, label } = END_CRAFT_ROUTE;
      return (
        <Select.Option key={_id} value={_id}>{label}</Select.Option>
      );
    }

    return craftRoutes.map((craftRoute, i) => (
      <Select.Option key={craftRoute._id} value={craftRoute._id}>工艺路线{i + 1}</Select.Option>
    ));
  }

  renderMaterialOptions() {
    const materials = get(this.model, 'materials') || [];

    return materials.map((material) => {
      return <Select.Option key={material._id} value={material._id}>{material.materialName}</Select.Option>;
    });
  }

  renderProduct() {
    const { form } = this.props;
    const model = this.model;
    const { product } = model;

    const disabled = this.isDest;

    return (
      <Form.Item label="产品" {...inlineFormItemLayout}>
        {form.getFieldDecorator('productId', {
          initialValue: get(product, '_id'),
          rules: [
            {
              required: true,
              message: '请选择产品',
            },
          ]
        })(
          <Select
            showSearch={true}
            disabled={disabled}
            placeholder="输入产品名搜索"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMenuStyle={{
              zIndex: 1,
            }}
            onSearch={this.fetchProduct}
            onChange={this.handleProductChange}
            getPopupContainer={getPopupContainer}
          >
            {this.renderOptions()}
          </Select>
        )}
      </Form.Item>
    );
  }

  renderAmount() {
    const { form } = this.props;
    const { amount = 1 } = this.model;

    if (this.isDest) {
      return null;
    }

    return (
      <Form.Item label="配比" {...inlineFormItemLayout}>
        {form.getFieldDecorator('amount', {
          initialValue: amount,
        })(
          <InputNumber
            min={1}
            precision={0}
            placeholder="请输入配比"
            onChange={this.handleSubmit}
            className="amount-input"
          />
        )}
      </Form.Item>
    );
  }
  renderMaterial() {
    const { form } = this.props;
    const { material } = this.model;
    const { productId } = this.props.form.getFieldsValue();
    const disabled = !productId || (this.isDest && this.context.mode === 'edit');

    let placeholder = '请选择物料';
    if (!productId) {
      placeholder = '请先选择产品';
    }

    return (
      <Form.Item label="物料" {...inlineFormItemLayout}>
        {form.getFieldDecorator('material', {
          initialValue: get(material, '_id'),
          rules: [
            {
              required: true,
              message: '请选择物料',
            },
          ]
        })(
          <Select
            disabled={disabled}
            getPopupContainer={getPopupContainer}
            placeholder={placeholder}
            defaultActiveFirstOption={true}
            showArrow={false}
            onChange={this.onSelectMaterial}
            dropdownMenuStyle={{
              zIndex: 1,
            }}
          >
            {this.renderMaterialOptions()}
          </Select>
        )}
      </Form.Item>
    );
  }

  renderCraftRoute() {
    const productId = this.props.form.getFieldValue('productId');
    if (this.model.shape !== SOURCE_CRAFT_ROUTE_NODE) {
      return null;
    }
    const { craftRouteId } = this.model;
    const { form } = this.props;
    const { material } = this.props.form.getFieldsValue();
    let disabled = !material || !productId;

    let placeholder = disabled ? '请先选择物料' : '请选择工艺路线';

    if (!productId) {
      placeholder = '请先选择产品';
    }

    return (
      <Form.Item label="工艺路线" {...inlineFormItemLayout}>
        {form.getFieldDecorator('craftRouteId', {
          initialValue: craftRouteId || undefined,
          rules: [
            {
              required: true,
              message: '请选择工艺路线',
            }
          ]
        })(
          <Select
            disabled={disabled}
            getPopupContainer={getPopupContainer}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            showArrow={false}
            dropdownMenuStyle={{
              zIndex: 1,
            }}
            onChange={this.handleSubmit}
          >
            {this.renderCraftRouteOptions()}
          </Select>
        )}
      </Form.Item>
    );
  }

  renderFormContent() {
    if (!this.item) {
      return null;
    }

    return (
      <>
        {this.renderProduct()}
        {this.renderAmount()}
        {this.renderMaterial()}
        {this.renderCraftRoute()}
      </>
    );
  }
}

export default Form.create()(withPropsAPI(NodeEditor));
