/**
 * 聊天面板
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { bind } from 'lodash-decorators';
import { message } from 'antd';
import log from 'loglevel';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { Room } from 'src/models/im/chatRoomList.type';
// import { getPolicy } from 'src/services/api/attachment';
import { PostMessageParams, RoomUsers } from 'src/models/im/chatPanel.type';
import upyun from 'src/config/upyun';

import PanelHeader from './PanelHeader';
import MessagePanel from './MessagePanel';
import { ThreeBounceLoading } from 'src/components';
import { mapUsers, isEqualUserId } from 'src/utils/user';
import { State } from 'src/models/session';
import { UploadFileEnhance } from 'src/components/ChatInputBox/interface';
import { attachmentApi } from 'src/services/net';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { AxiosRequestConfig } from 'axios';
import { UserModel } from 'src/models/user/interface';
import ChatInput, { EnhancedCommentInfo } from 'src/containers/ChatInput';

interface OwnProps {
  setRoomId: (roomId?: string) => void;
}
interface StateProps {
  roomInfo: Room | undefined;
  session: State;
  roomUsers: RoomUsers;
  allUsers: UserModel['list']['byId'];
  displaySuggests: boolean;
}
interface DispatchProps {
  reset: (paths?: string[]) => any;
  sendMessage: (params: PostMessageParams) => any;
  getRoomInfo: (payload: { roomId: string, config?: AxiosRequestConfig }) => any;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  roomId: string;
}

export const CHAT_INPUT_AUTO_SAVE_NAMESPACE = 'chat-input.';

function goToIM() {
  const { history } = getCurrentRoute()!;
  history.push(compilePath({ name: ROUTE_NAMES.IM }));
}

const config: AxiosRequestConfig = {
  handleSpecialError: {
    403: () => {
      message.error('抱歉，您无权访问该群组');
      goToIM();
    },
    404: () => {
      message.error('该群组不存在或已被解散');
      goToIM();
    }
  }
};

class ChatPanel extends React.PureComponent<P, S> {
  private uploadFormData: any = null;
  private uploadAction: any = null;
  private policyTimer: NodeJS.Timer;
  constructor(props: P) {
    super(props);

    const roomId = props.match.params.id;

    this.state = { roomId };
  }

  componentDidMount() {
    this.handleGetRoomInfo(this.state.roomId);
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    const { match, reset } = this.props;
    const roomId = match.params.id;

    if (prevProps.match.params.id !== roomId) {
      this.setState({ ...this.state, roomId });

      reset();
      setTimeout(() => this.handleGetRoomInfo(roomId), 0);
    }
  }

  componentWillUnmount() {
    const { reset, setRoomId } = this.props;
    clearTimeout(this.policyTimer);
    reset();
    setRoomId();
  }

  handleGetRoomInfo = async (roomId: string) => {
    const { getRoomInfo, setRoomId } = this.props;

    setRoomId(roomId);

    await getRoomInfo({ roomId, config });
  } 

  @bind
  async prepareUpload() {
    if (this.uploadFormData) return true;

    const { roomId } = this.state;
    const { data } = await attachmentApi.getPolicy({
      referenceName: 'room',
      referenceId: roomId,
      referenceRole: 1
    });

    this.policyTimer = setTimeout(this.clearPolicy, 10 * 60 * 1000);

    const { authorization, bucket, policy } = data;
    this.uploadFormData = {
      policy,
      authorization,
    };
    this.uploadAction = `${upyun.UPURL}/${bucket}`;

    return {
      data: this.uploadFormData,
      action: this.uploadAction,
    };
  }

  @bind
  getForm() {
    return this.uploadFormData;
  }

  @bind
  getAction() {
    return this.uploadAction;
  }

  clearPolicy() {
    this.uploadFormData = null;
    this.uploadAction = null;
  }

  handleSendMessage = async (value: EnhancedCommentInfo) => {
    const { roomId } = this.state;
    const { sendMessage } = this.props;

    if (isEmpty(value)) { return; }

    let { content, attachments, mentions } = value;
    console.log('CommentInfo', value);
    const params: PostMessageParams[] = [];

    if ('content' in value) {
      // 将正文中的 @昵称 替换成 @userId

      const mentionData = mentions.slice();
      const usedMentionData: { [key: string]: any } = {};

      content = ` ${content} `.replace(/(^| )@([^\s]+)(?= |$)/gm, (_, prefix, name) => {
        // 永远只使用昵称寻找
        let find = mentionData.find(mention => mention.nickname === name);

        if (find) {
          // 移到使用过的提及数据中，适配同名多次艾特
          usedMentionData[name] = find;
          mentionData.splice(mentionData.indexOf(find), 1);
        } else {
          find = usedMentionData[name];
        }

        if (find) {
          return `${prefix}{{@${find._id}}}`;
        }

        return `${prefix}@${name}`;
      }).trim();
  
      params.push({ roomId, content });
    }

    if (attachments && Array.isArray(attachments)) {
      log.info(attachments);
      attachments!.forEach( (file: UploadFileEnhance) => {
        const obj: any = pick(file, ['name', 'size', 'url', 'mimetype', 'width', 'height', 'ext']);
        obj.filename = obj.name;

        params.push({ roomId, attachments: [obj] });
      });
    }

    return Promise.all(params.map(param => sendMessage(param)));
  }

  render() {
    const { roomId } = this.state;
    const { roomInfo, roomUsers, displaySuggests, allUsers } = this.props;

    if (!roomInfo || !roomId) {
      return <div className={styles.chatPanelWrapper}><ThreeBounceLoading /></div>;
    }

    return (
      <div className={styles.chatPanelWrapper}>
        <PanelHeader
          allUsers={allUsers}
          roomInfo={roomInfo}
          memberNum={roomUsers.existUsers.length}
          drawerContainerSelector={`.${styles['chat-content']}`}
        />

        <div className={styles.chatContent}>
          <MessagePanel roomId={roomId} />

          <ChatInput
            className={styles.chatInputBox}
            suggestionData={this.userExcludeMyself}
            displaySuggests={displaySuggests}
            saveDraft={`${CHAT_INPUT_AUTO_SAVE_NAMESPACE}${roomId}`}
            onSubmit={this.handleSendMessage}
            // uploaderOptions={this.state.uploaderOptions}
            uploaderOptions={{
              data: this.getForm,
              action: this.getAction,
              multiple: true,
              beforeUpload: this.prepareUpload,
              showUploadList: true,
            }}
            prepareUpload={this.prepareUpload}
          />
        </div>
      </div>
    );
  }

  get userExcludeMyself() {
    const { roomUsers, session, allUsers } = this.props;

    return mapUsers<User>(roomUsers.existUsers, allUsers , id => !isEqualUserId(id, session.profile!._id));
  }
}

const mapState = ({ 
  chatPanel: { roomUsers, roomInfo }, 
  user: { list: { byId } },
  session, 
}: RootState): StateProps => ({
  roomInfo,
  displaySuggests: !!roomInfo && roomInfo.type === 'GROUP',
  roomUsers,
  allUsers: byId,
  session
});

const mapDispatch = ({ chatPanel }: RootDispatch) => ({
  reset: chatPanel.reset,
  sendMessage: chatPanel.postMessage,
  getRoomInfo: chatPanel.getRoomInfo
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(ChatPanel);
