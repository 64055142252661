import { PureComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { getMatchInfoByPathname, getMatchedRoute } from './';
import { CompiledRouteProps } from './interface';

interface Props extends RouteComponentProps {}
interface States {
  route: RouteComponentProps;
}

let registeredId = 0;
let previousRoute: RouteComponentProps<any> | null = null;
let currentRoute: RouteComponentProps<any>;
let currentMatchList: CompiledRouteProps[] = [];

export const isHooked = () => !!registeredId;

function checkHooked() {
  if (!isHooked()) {
    console.error('HoneyRoute.HookRoute doesn\'t work properly, please install it first.');

    return false;
  }

  return true;
}

function ensureMatch(previous: RouteComponentProps) {
  const { location: { pathname }, match: { url } } = previous;
  if (url === pathname) {
    return previous;
  }

  return {
    ...previous,
    match: getMatchInfoByPathname(pathname),
  };
}

class HookRoute extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);

    registeredId = 1;

    currentRoute = { ...props };
    currentMatchList = getMatchedRoute(props.location.pathname);
    this.state = {
      route: currentRoute,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: States) {
    if (nextProps.location.pathname === prevState.route.location.pathname) {
      return null;
    }
    
    previousRoute = prevState.route;
    currentRoute = ensureMatch(nextProps);
    currentMatchList = getMatchedRoute(nextProps.location.pathname);
    return {
      route: { ...currentRoute },
    };
  }

  render() {
    return this.props.children || null;
  }
}

export const getPreviousRoute = () => {
  if (!checkHooked() || !previousRoute) {
    return null;
  }
  
  return {
    ...ensureMatch(previousRoute),
  };
};

export const getCurrentRoute = () => {
  if (!checkHooked()) {
    return null;
  }

  return {
    ...ensureMatch(currentRoute),
  };
};

export const getCurrentMatched = () => {
  if (!checkHooked) {
    return [];
  }

  return currentMatchList;
};

export default withRouter(HookRoute);
