import React from 'react';

import {
  RegisterCraftRouteGroups,
  RegisterCraftRouteCommands,
  RegisterCraftRouteNodes,
} from './core';

export default function RegisterShapes() {
  return (
    <>
      <RegisterCraftRouteGroups />
      <RegisterCraftRouteNodes />
      <RegisterCraftRouteCommands />
    </>
  );
}
