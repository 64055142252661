/**
 * 单独的每一条消息
 */
import * as React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import ms from 'ms';

import styles from './index.module.less';
import { RootState } from 'src/store';
import { ImMessage } from 'src/models/im/chatPanel.type';

import SystemMessage from './SystemMessage';
import DeletedMessage from './DeletedMessage';
import NormalUserMessage from './NormalUserMessage';

interface OwnProps {
  prevMessageId: string | undefined;
  messageId: string;
}
interface StateProps {
  messageData: ImMessage;
  prevMessageData: ImMessage | undefined;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

const M3 = ms('3m');
const D1 = ms('1d');
// 同一个人发的两条消息之间，时间相差3分钟以内，不显示头像
const showAvatar = (prevData: ImMessage | undefined, curData: ImMessage) => {
  if (!prevData) { return true; }

  const { type } = prevData;

  if (type !== 'MESSAGE') { return true; }

  const { createdAt: prevTime, sender: prevSender } = prevData;
  const { createdAt: curTime, sender: curSender } = curData;

  if (prevSender !== curSender) { return true; }

  const prevDate = moment(prevTime);
  const curDate = moment(curTime);

  return curDate.diff(prevDate) > M3;
};
// 显示日期分割线
const getFormattedDate = (date) => moment(date).format('YYYY-MM-DD');
const showDateDivider = (prevData: ImMessage | undefined, curData: ImMessage) => {
  const curTime = moment(
    getFormattedDate(curData.createdAt)
  );
  const time = curTime.format('YYYY-MM-DD ddd');

  if (!prevData) { return time; }

  const prevTime = moment(
    getFormattedDate(prevData.createdAt)
  );

  return curTime.diff(prevTime) >= D1 ? time : null;
};

class MessageItem extends React.PureComponent<P, S> {
  renderDateDivider = () => {
    const { messageData, prevMessageData } = this.props;
    const time = showDateDivider(prevMessageData, messageData);

    if (!time) { return null; }

    return (
      <div className={styles.dateDivider}>
        <span className={styles.time}>{time}</span>
      </div>
    );
  }

  renderContent = () => {
    const { messageData, prevMessageData } = this.props;
    const { type, sender, message } = messageData;

    // 被删除的消息
    if (type === 'DELETED') {
      return <DeletedMessage userId={sender} />;
    }

    // 系统消息
    if (type === 'SYSTEM') {
      return <SystemMessage message={message!} />;
    }

    const isShowAvatar = showAvatar(prevMessageData, messageData);

    // 普通用户发出来的消息
    return <NormalUserMessage messageData={messageData} isShowAvatar={isShowAvatar} />;
  }

  render() {
    return (
      <>
        {this.renderDateDivider()}
        {this.renderContent()}
      </>
    );
  }
}

const mapState = ({ chatPanel: { chatData: { byId } } }: RootState, props: P) => {
  const { messageId, prevMessageId } = props;
  const prevMessageData = prevMessageId ? byId[prevMessageId] : void 0;

  return {
    prevMessageData,
    messageData: byId[messageId]
  };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(MessageItem);
