import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { FormCreator } from '@chipcoo/hanayo';

import { getFormConfig } from './getFormConfig';
import { handlePreviewFormData } from 'src/models/operation/inventoryManage/stockIn/handle';

interface IProps {
  visible: boolean;
  previewInfos: any;
  onOk?: (values: any) => void;
  onCancel: () => void;
}

interface IState {
  formData: any;
  modalFullWindow: boolean;
}

const ImportPreviewModal: FC<IProps> = props => {
  const { visible, onOk, previewInfos, onCancel } = props;
  const [state, setState] = useState<IState>({
    formData: [],
    modalFullWindow: true,
  });
  const { formData, modalFullWindow } = state;

  const handleFormData = () => {

    const response = get(previewInfos, 'file.response');
    const handleData = response && handlePreviewFormData(response);

    setState({
      ...state,
      formData: { formData: handleData || {} }
    });
  };

  useEffect(() => {
    handleFormData();
  }, [previewInfos]);

  const requestFullWindow = () => setState({ ...state, modalFullWindow: true });

  const requestNormalWindow = () => setState({ ...state, modalFullWindow: false });

  const handleSubmit = () => {
    const response = get(previewInfos, 'file.response');
    return new Promise((resolve) => {
      if (onOk) {
        resolve(onOk(response));
      }
    });
  };

  return (
    <FlexModal
      width={1200}
      visible={visible}
      fullWindow={modalFullWindow}
      onOk={handleSubmit}
      onCancel={onCancel}
      onRequestFullWindow={requestFullWindow}
      onRequestNormalWindow={requestNormalWindow}
    >
      <FormCreator 
        formConfig={getFormConfig()}
        formData={formData}
        globalStatus={'preview'}
      />
    </FlexModal>
  );
};

export default ImportPreviewModal;
