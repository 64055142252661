import _, { get } from 'lodash';
import moment from 'moment';
import { EnumProductModel,  } from '@chipcoo/constant';
import { getMomentTime, sliceArray2Slices, bins2Number, sortWaferId, unSortWaferId } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';
import { uploadFormAttachment } from 'src/utils';

export const baseBins = [
  { name: '无', count: 0 },
  { name: 'Bin1', count: 0 },
  { name: 'Bin2', count: 0 },
  { name: 'Bin3', count: 0 },
  { name: 'Bin4', count: 0 },
  { name: 'Bin5', count: 0 },
  { name: 'Bin6', count: 0 },
  { name: 'Bin7', count: 0 },
  { name: 'Bin8', count: 0 },
];
// 物料库存列表
export const handleStockList = (data) => data.reduce(
  (prev, cur) => {
    let good, bad, amount, dieName, waferIds, waferAmount;
    const {
      _id,
      createdAt,
      productModel,
      product,
      warehouse,
      stockInfo,
      status,
      material,
      ...restData
    } = cur;
    if (productModel === EnumProductModel.assemblyProduct) {
      const binArray = get(stockInfo, 'bins');
      amount = _.sum(binArray);

      dieName = get(product, 'product.dies')?.map(it => get(it, 'die.customerName')).join(',');
    } else {
      const slicesArray = get(stockInfo, 'slices');
      const flattenDpSlices: number[] = _.flattenDeep(slicesArray?.map(it => it.desc && unSortWaferId(it.desc)));

      waferIds = productModel === EnumProductModel.dpProduct ?
      sortWaferId(flattenDpSlices) :
      sortWaferId(slicesArray?.map(it => it.waferId));

      waferAmount = productModel === EnumProductModel.dpProduct ?
        get(flattenDpSlices, 'length') : get(slicesArray, 'length');
      good = _.sum(slicesArray?.map(it => it.good));
      bad = _.sum(slicesArray?.map(it => it.bad));
      amount = (good + bad);
      dieName = get(product, 'product.die.customerName') || get(product, 'customerName');
    }

    const curData = {
      ...restData,
      waferIds: waferIds && '#' + waferIds,
      waferAmount,
      good,
      bad,
      amount,
      _id,
      key: _id,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      material: get(material, 'materialName'),
      materialNumber: get(stockInfo, 'materialNumber'),
      stampNumber: get(stockInfo, 'stampNumber'),
      dieNumber: get(stockInfo, 'dieNumber'),
      dieName,
      productModel: optionsGroup.productModel.options![productModel],
      status,
      statusText: optionsGroup.stockInApplyStatus.options![status],
      product: get(material, 'productName'),
      productName: product?.customerName,
      warehouse: warehouse?.name,
    };
    prev.push(curData);

    return prev;
  },
  [] as any[]
);

// 物料库存详情
export const handleStockDetail = (data) => {
  const {
    productModel,
    logisticsNumber,
    material,
    stockInfo,
    product,
    amount,
    process,
    deliveryAt,
    ...restData
  } = data;
  let attributes, waferIdArray, slices, dpDieSlices;

  let stockBinsList: any[] = [];
  if (productModel === EnumProductModel.assemblyProduct) {
    const bins = get(stockInfo, 'bins');
    bins.forEach((bin, index) => {
      if (bin) {
        stockBinsList.push({
          ...baseBins[index],
          count: bin,
          key: baseBins[index].name
        });
      }
    });
  } else {
    attributes = get(stockInfo, 'attributes');
    slices = get(stockInfo, 'slices').map(it => ({ ...it, key: it.waferId }));
    waferIdArray = (slices.map(it => it.waferId)).join(', ');
    if (productModel === EnumProductModel.dpProduct) {
      dpDieSlices = slices.map(it => {
        const { good, bad, desc } = it;
        return {
          ...it,
          waferId: desc,
          good,
          bad,
        };
      });
    }
  }

  // 入库申请详细信息
  return {
    ...restData,
    productName: {key: product._id, label: product.customerName},
    material: {key: material, label: process.name},
    attributes: optionsGroup.attributes.options![attributes],
    markMode: get(stockInfo, 'markMode'),
    packingMethod: get(stockInfo, 'packingMethod'),
    logisticsNumber,
    stampNumber: get(stockInfo, 'stampNumber'),
    dieNumber: get(stockInfo, 'dieNumber'),
    deliveryAt: deliveryAt && moment(deliveryAt),
    waferIdArray,
    slices,
    dpDieSlices,
    bins: stockBinsList,
    classes: get(process, 'classes'),
    isParseMap: stockInfo?.isParseMap,
    cpReport: stockInfo?.cpReport,
    mapping: stockInfo?.cpMap,
    goodBins: stockInfo?.goodBins
  };
};

// 处理库房物料仓储信息数据
export const handleMaterialList = (data: any[]) => (data || []).reduce(
  (prev, cur) => {
    let waferIds = '';
    let bin = '';
    const {
      _id,
      productModel,
      slices,
      bins,
      createdAt,
      ...restData
    } = cur;

    if (productModel === EnumProductModel.assemblyProduct) {
      if (bins) {
        bin = bins2Number(bins).binType;
      }
    } else {
      if (slices) {
        waferIds = sliceArray2Slices(slices).waferIds;
      }
    }

    const curData = {
      ...restData,
      _id,
      key: _id,
      waferIds,
      bin,
      slices,
      bins,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss')
    };

    prev.push(curData);

    return prev;
  },
  [] as any
);

// 处理库房物料仓储信息里的物料片号或bin
export const handleWaferIdsOrBins = (data: any) => Object.entries(data).reduce(
  (prev, [key, val]) => {
    const value: any = _.cloneDeep(val);

    if (key === 'bins' && value) {
      //
    }

    if (key === 'slices' && value) {
      value.forEach(item => prev.push({ ...item, key: item.waferId }));
    }

    return prev;
  },
  [] as any
);

// 处理批量入库二次确认数据预览信息
export const handlePreviewFormData = (data: any) => data.reduce((prev, cur) => {
  const {
    materialName,
    stockInfo,
    deliveryAt
  } = cur;

  const { slices } = stockInfo;
  const newSlices = slices.map(item => {
    const { good, bad, waferId } = item;

    return {
      ...item,
      key: waferId,
      amount: parseInt(good, 10) + parseInt(bad, 10),
    };
  });
  const waferIds = sortWaferId(slices.map(it => it.waferId));

  const currentData = {
    key: Math.random().toString(16),
    materialName,
    productName: materialName.split('/')[0],
    deliveryAt: getMomentTime(deliveryAt),
    stockInfo: { ...stockInfo, slices: newSlices, waferIds }
  };

  prev.push(currentData);

  return prev;
}, []);

// 处理入库申请表单重新提交时的数据
export const handleFormData = async (data: any) => {
  const { productModel, values } = data;
  let resultValues, stockInfo;
  const {
    logisticsNumber,
    material,
    deliveryAt,
    remark,
    goodBins,
    cpReport,
    mapping,
    isParseMap,
    dieNumber
  } = values;

  if (productModel !== EnumProductModel.assemblyProduct) {
    let { markMode, slices, packingMethod, dpDieSlices } = values;
    if (productModel === EnumProductModel.dpProduct) {
      stockInfo = {
        dieNumber,
        packingMethod,
        attributes: 0,
        slices: dpDieSlices.map((it, index) => {
          const { waferId, good, bad } = it;
          return {
            waferId: 10000 + index,
            desc: waferId,
            good,
            bad: bad ? bad : 0,
          };
        })
      };
    } else {
      stockInfo = {
        dieNumber,
        markMode,
        attributes: 0,
        goodBins,
        isParseMap,
        cpReport,
        cpMap: mapping,
        slices: slices.map(it => {
          const { waferId, good, bad } = it;
          return {
            waferId: parseInt(waferId, 10),
            good,
            bad: bad ? bad : 0,
          };
        })
      };

      if (stockInfo?.cpReport) {
        stockInfo = await uploadFormAttachment({
          formData: stockInfo,
          attachmentUploadInfo: [{
            path: 'cpReport',
            referenceName: 'stock-in-apply',
            referenceRole: 'cp-report'
          }],
          formDetail: {},
        });
      }
    }
  } else {
    const { packingMethod, stampNumber, bins} = values;
    const binstransition = bins.map(it => {
      const { name, count } = it;
      if (name === '无') {
        return {name: 'Bin0', count};
      } else {
        return {name, count};
      }
    });
    const binArray: Number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    binstransition.forEach(it => {
      binArray[it.name.slice(-1)] = it.count;
    });
    stockInfo = {
      dieNumber,
      stampNumber,
      packingMethod,
      bins: binArray,
    };
  }

  resultValues = {
    logisticsNumber,
    material: material.key,
    deliveryAt,
    remark,
    stockInfo,
  };

  return resultValues;
};

export const handleBulkFormData = async (data: any) => {
  const { values, productModel } = data;
  const { detailMaterial } = values;
  const handleDataArray = await Promise.all(
    detailMaterial.map(item => handleFormData({productModel, values: { ...values, ...item }}))
  );
  return handleDataArray;
};
// tslint:disable-next-line:max-file-line-count
