import * as React from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';
import { unSortWaferId, slice2NumberByWaferIds, getBinsStockByBinType } from '@chipcoo/fe-utils';

import { getColumnProps } from './column-config';
import styles from './index.module.less';

import StockNumberForm from './StockNumberForm';

interface Props {
  formStatus: FormStatus;
  productModel: EnumProductModel;
  originMaterialNumber: any;
  // form: EnhancedWrappedFormUtils;
  value?: any[];
  onChange?: (val: any) => void;
  waferIds?: string;
  bins?: string[];
  isCanEdit: boolean;
}

interface State {
  modalVisible: boolean;
  dataList: any[];
}

export const isAssemblyModel = (model: EnumProductModel) => model === EnumProductModel.assemblyProduct;
const getRowKey = (r, index: number) => _.get(r, '_id') || `stock_number_table_row_${index}`;

class StockNumberTable extends React.PureComponent<Props, State> {
  selectColumnProps = createSelector(
    (props: Props) => isAssemblyModel(props.productModel),
    (props: Props) => props.formStatus === 'preview',
    (isAssembly, isPreview) => {
      const { isCanEdit } = this.props;
      const columnProps: any = getColumnProps(isAssembly);

      if (!isPreview && isCanEdit) {
        return columnProps.concat(this.actionColumn);
      }

      return columnProps;
    }
  );

  readonly actionColumn: ColumnProps<any> = {
    key: 'action',
    fixed: 'right',
    width: 120,
    title: '操作',
    render: (__: any, ___: any, index: number) => (
      <a onClick={() => this.editStock(index)}>编辑</a>
    ),
  };

  columnProps: ColumnProps<any>[];
  editIndex: number = -1;
  formData: any | undefined;

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.value !== prevState.dataList) {
      return {
        dataList: nextProps.value,
      };
    }

    return null;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false,
      dataList: props.value || [],
    };
  }

  componentDidMount(): void {
    this.getTableData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { waferIds, bins } = this.props;

    if (prevProps.waferIds !== waferIds || prevProps.bins !== bins) {
      this.getTableData();
    }
  }

  getTableData = () => {
    const { productModel, originMaterialNumber, waferIds, bins: propsBins, value, formStatus, onChange } = this.props;
    let tableList: any[] = value || [];

    if (formStatus !== 'preview') {
      const slices = _.get(originMaterialNumber, 'slices');
      const bins = _.get(originMaterialNumber, 'bins');

      if (!isAssemblyModel(productModel) && waferIds && slices) {
        const newWaferIds = unSortWaferId(waferIds);

        const { tableData } = slice2NumberByWaferIds(slices, newWaferIds);

        tableList = tableData;
      }

      if (isAssemblyModel(productModel) && propsBins && bins) {
        const { tableData } = getBinsStockByBinType(bins, propsBins);

        tableList = tableData;
      }
    }

    this.setState({ dataList: tableList });

    onChange && onChange(tableList);
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  }

  editStock = (index: number) => {
    this.editIndex = index;
    this.formData = _.get(this.state.dataList, index);

    this.setState({ modalVisible: true });
  }

  saveStock = (stock: any) => {
    const { value = [] } = this.props;
    const val = value.slice();

    if (this.editIndex === -1) {
      val.push(stock);
    } else {
      val[this.editIndex] = stock;
    }

    this.props.onChange && this.props.onChange(val);

    this.closeModal();
  }

  renderModal = () => {
    const { modalVisible, dataList } = this.state;
    const { productModel } = this.props;

    return (
      <StockNumberForm
        visible={modalVisible}
        productModel={productModel}
        formData={this.formData}
        tableData={dataList}
        mode={this.editIndex === -1 ? 'new' : 'edit'}
        onSave={this.saveStock}
        onCancel={this.closeModal}
      />
    );
  }

  render () {
    const { dataList } = this.state;
    this.columnProps = this.selectColumnProps(this.props);

    return (
      <div className={styles.stockNumberTableWrap}>
        <Table
          style={{ width: '100%' }}
          key="stock_number_table"
          dataSource={dataList}
          columns={this.columnProps}
          pagination={false}
          rowKey={getRowKey}
          size="small"
          className="small-table-no-border"
        />
        {this.renderModal()}
      </div>
    );
  }
}

export default StockNumberTable;
