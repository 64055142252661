/**
 * 列表等的加载的loading动画
 */
import React, { memo } from 'react';

import './index.css';

interface P {
  style?: React.CSSProperties;
}

export default memo((props: P) => (
  <div className="three-bounce-spinner" style={props.style}>
    <div className="bounce bounce1"/>
    <div className="bounce bounce2"/>
    <div className="bounce bounce3"/>
  </div>
));
