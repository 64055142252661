import React, { FC, useEffect, useState } from 'react';
import { isEmpty, flattenDeep, get } from 'lodash';
import { Collapse, Divider, Popconfirm } from 'antd';
import { EnumProductModel } from '@chipcoo/constant';
import { unSortWaferId, sortWaferId } from '@chipcoo/fe-utils';

import PreviewMaterial from './components/PreviewMaterial';
import styles from './index.module.less';

interface Props {
  materialDetail: any[];
  globalState: string;
  outerFormData: any;
  onEdit: (_id: string) => void;
  onDelete: (_id: string) => void;
}

interface State {
  activePanelKeys: string[];
}

const MaterialCollapse: FC<Props> = props => {
  const { materialDetail, onEdit, onDelete, globalState, outerFormData } = props;

  const [state, setState] = useState<State>({
    activePanelKeys: [],
  });
  const { activePanelKeys } = state;
  const productModel = get(outerFormData, 'productModel');

  const getHeader = (materialItem) => {
    let waferIds: string = '';
    if (productModel === EnumProductModel.dpProduct) {
      const waferIdArray: number[] = flattenDeep(materialItem?.dpDieSlices?.map(it => unSortWaferId(it.waferId)));
      waferIds = sortWaferId(waferIdArray);
    } else if (productModel === EnumProductModel.spwDie) {
      waferIds = materialItem?.waferIdArray;
    }
    
    const waferIdsOrStampNumber = productModel === EnumProductModel.assemblyProduct ?
      `印章批号： ${materialItem?.stampNumber}` : `片号： #${waferIds}`;

    return (
      <>
        <span>芯片批号： {materialItem?.dieNumber}</span>
        <span style={{marginLeft: 30}}>{waferIdsOrStampNumber}</span>
      </>
    );
  };

  const getExtra = (materialItem) => {
    if (globalState === 'preview') {
      return null;
    }

    return (
      <span onClick={(e) => e.stopPropagation()}>
        <a onClick={() => onEdit(materialItem?._id)}>编辑</a>
        <Divider type="vertical" />
        <Popconfirm
          title="确定删除?"
          onConfirm={() => onDelete(materialItem?._id)}
          okText="确定"
          cancelText="取消"
          overlayStyle={{ zIndex: 2000 }}
          getPopupContainer={(t) => t.parentNode as HTMLElement}
        >
          <a href="javascript: void 0;" style={{color: 'red'}}>删除</a>
        </Popconfirm>
      </span>
    );
  };

  const renderPanel = () => {
    return materialDetail  && materialDetail.map(it => {
      return (
        <Collapse.Panel
          key={it?._id}
          header={getHeader(it)}
          extra={getExtra(it)}
        >
          <PreviewMaterial
            materialInfos={it}
            outerFormData={outerFormData}
          />
        </Collapse.Panel>
      );
    });
  };

  useEffect(() => {
    renderPanel();

    setState({
      ...state,
      activePanelKeys: materialDetail?.map(it => it?._id)
    });
  }, [materialDetail]);

  if (isEmpty(materialDetail)) return null;

  return (
    <Collapse
      className={styles.previewCollapse}
      defaultActiveKey={activePanelKeys}
    >
      {renderPanel()}
    </Collapse>
  );
};

export default MaterialCollapse;
