/**
 * 左上角的面包屑导航
 */
import * as React from 'react';
import { bind } from 'lodash-decorators';
import { Breadcrumb } from 'antd';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { getOrgName, getWorkspaceName } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import DropDownArrow, { DropdownItem } from './DropDownArrow';

import HoneyRoute, { compilePath, getCurrentRoute } from 'src/components/HoneyRouter';
import { HoneyRouteComponentProps } from 'src/components/HoneyRouter/interface';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import store, { RootState } from 'src/store';
import { Workspace } from 'src/models/workspace/interface';
import { IOrgDetail } from 'src/models/org/interface';
import applicationList, { fillAppList as fillApplication } from 'src/pages/Organization/OrgDetail/cardGrid';
import operationMenu, { refreshAppList as fillOperation } from 'src/pages/Operation/appList';
import qcMenu, { refreshAppList as fillQC } from 'src/pages/QCManage/appList';
import rdMenu, { fillAppList as fillRd } from 'src/pages/RDManage/appList';

interface P extends RouteComponentProps {}

interface StateProps {
  workspaceId: string;
  workspaceList: NormalizedData<Workspace>;
  orgId: string;
  orgList: NormalizedData<IOrgDetail>;
}

interface S {}

type Props = StateProps & P;

let customText: any = {};
let dropdownItems: any = {};

function fillCustomCallback() {
  customText = {
    [ROUTE_NAMES.ORG_DETAIL]() {
      const { org: { orgId, orgList } } = store.getState();
      try {
        return getOrgName(orgList.byId[orgId]);
      } catch (e) {
        console.error(e);
        return '';
      }
    },
    [ROUTE_NAMES.ORG_COO_DETAIL]() {
      const { workspace: { workspaceId, list } } = store.getState();
      try {
        return getWorkspaceName(list.byId[workspaceId]);
      } catch (e) {
        console.error(e);
        return '';
      }
    },
  };

  dropdownItems = {
    [ROUTE_NAMES.ORG_DETAIL]() {
      const { org: { orgId } } = store.getState();
      const { match: { params } } = getCurrentRoute()!;
      return applicationList.map(card => ({
        name: card.title,
        url: compilePath({ name: card.route, params: { id: orgId || (params as any).id } }),
      }));
    },
    [ROUTE_NAMES.ORG_LIST]() {
      const { org: { orgList } } = store.getState();

      return orgList.allIds.reduce((all, id) => {
        if (orgList.byId[id]) {
          all.push({
            name: getOrgName(orgList.byId[id]),
            url: compilePath({ name: ROUTE_NAMES.ORG_DETAIL, params: { id } }),
          });
        }

        return all;
      }, [] as DropdownItem[]);
    },
    [ROUTE_NAMES.ORG_COO_LIST](_: any, params: any) {
      const { workspace: { list } } = store.getState();

      return list.allIds.reduce((all, id) => {
        if (list.byId[id]) {
          all.push({
            name: getWorkspaceName(list.byId[id]),
            url: compilePath({ name: ROUTE_NAMES.ORG_COO_DETAIL, params: { ...params, workspaceId: id } }),
          });
        }

        return all;
      }, [] as DropdownItem[]);
    },
    [ROUTE_NAMES.ORG_OPERATION](_: any, params: any) {
      return operationMenu.map(menu => ({
        name: menu.title,
        url: compilePath({ name: menu.route, params })
      }));
    },
    [ROUTE_NAMES.ORG_QC](_: any, params: any) {
      return qcMenu.map(menu => ({
        name: menu.title,
        url: compilePath({ name: menu.route, params })
      }));
    },
    [ROUTE_NAMES.ORG_RD](_: any, params: any) {
      const newRdMenu: any[] = rdMenu.map(menu => {
        const newMenu = menu.children.map(it => {
          return {
            name: it.title,
            url: compilePath({ name: it.route, params }),
          };
        });

        return {
          name: menu.title,
          children: newMenu,
        };
      });

      // newRdMenu.splice(1, 0, {
      //   isDivider: true
      // });

      return newRdMenu;
    }
  };
}

setTimeout(fillCustomCallback);

class BreadcrumbNav extends React.Component<Props, S> {
  // 渲染工作区域内的面包屑
  constructor(props: Props) {
    super(props);

    if (Object.keys(customText).length === 0) {
      fillCustomCallback();
    }

    fillOperation();
    fillApplication();
    fillRd();
    fillQC();
  }
  @bind
  renderItem(route: HoneyRouteComponentProps, params: any, routes: HoneyRouteComponentProps[], paths: string[]) {
    const index = routes.indexOf(route);
    const last = index === routes.length - 1;

    let text: string | JSX.Element = route.breadcrumbName || '';
    const { match: { url } } = route;

    // TODO: use proper way to override organazation list breadbrumb.
    if (route.name === ROUTE_NAMES.ORG_LIST) {
      text = <i className="c-icon icon-level-next" />;
    }

    if (customText[route.name || '']) {
      text = customText[route.name!].call(this, route, params, routes, paths) || text;
    }

    let textContent = <div className="bread-text ellipsis">{text}</div>;

    if (!last) {
      textContent = <Link to={url}>{textContent}</Link>;
    }

    let dropItems;

    if (dropdownItems[route.name || '']) {
      dropItems = dropdownItems[route.name || ''].call(this, route, params);
    }

    const hasDropItems = dropItems && dropItems.length > 0;

    return (
      <div className={styles.breadcrumbItem} key={url}>
        {textContent}
        {(!last || hasDropItems) ? <div className="sperator" /> : null}
        {dropItems && <DropDownArrow dropdownItems={dropItems} />}
      </div>
    );
  }

  render() {
    const { match } = HoneyRoute.getCurrentRoute()!;
    const routes: any = HoneyRoute.getCurrentMatched().filter(it => it.breadcrumbName);

    return (
      <Breadcrumb
        itemRender={this.renderItem}
        params={match.params}
        className={classnames(styles.breadcrumb, '')}
        routes={routes}
      />
    );
  }
}

const mapState = ({ org: { orgId, orgList }, workspace: { workspaceId, list } }: RootState) => ({
  orgId,
  workspaceId,
  orgList,
  workspaceList: list,
});

// Note: We don't use any props in RouteComponentProps, just triggers render lifecycle.
export default withRouter(connect<StateProps, {}, P>(mapState)(BreadcrumbNav));
