/**
 * Created by marvin on 18/8/30.
 */

import * as React from 'react';
import { Form, Input } from 'antd';

import style from './index.module.less';

import { HocProfileAvatar } from 'src/hoc';
import { EnhanceAvatar } from 'src/components';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface Props {
  form: WrappedFormUtils;
  userInfo: User;
}
const FormItem = Form.Item;

export class PasswordAffirm extends React.PureComponent<Props> {
  render () {
    const { form, userInfo } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className={style.passwordAffirm}>
        <div style={{ textAlign: 'center' }}>
          <EnhanceAvatar src={userInfo.avatar} userName={userInfo.nickname} />
        </div>

        <p className={style.warningRed}>确认是您本人操作，请输入当前用户密码。</p>

        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: '请输入当前用户密码' }]
          })(
            <Input type="password" autoComplete="off" autoFocus={true} placeholder="登录密码" />
          )}
        </FormItem>
      </div>
    );
  }
}

export const HocPasswordAffirm = HocProfileAvatar(PasswordAffirm);

export default PasswordAffirm;
