import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EnumMonitorType, EnumMonitorMethod } from '@chipcoo/constant';

import { ListParams } from './index';

type ARC = AxiosRequestConfig;

export type AlertContact = { email: string; personName: string };
export type AlertContactEdit = AlertContact & { _id: string; };
export type AlertContactsListParams = ListParams & { email?: string; personName?: string };

export type AlertContactGroup = { groupName: string; remark: string; contactsIds: string[] };
export type AlertContactGroupEdit = AlertContactGroup & { _id: string };
export type AlertContactGroupParams = ListParams & { groupName?: string };

export type MonitorRule = {
  group: string;
  type: EnumMonitorType;
  method: EnumMonitorMethod;
  target: string[];
  thresholdRule?: {
    // 'lt' | 'lte' | 'gt' | 'gte' | 'eq' | 'ne' | 'near' | 'away'
    cond: string;
    value: number;
  };
  tendencyRule?: {
    // 'up' | 'down' | 'both'
    direction: string;
    count: number;
  };
  spcRule?: {
    rules: number[];
    meanMu: number;
    meanSigma: number;
    stddevMu: number;
    stddevSigma: number;
  };
  compareRule?: {
    items: string[];
    ordered: boolean;
  };
};

export default function(api: AxiosInstance) {
  return {
    /*************************** 数据监测 **********************************/
    // 搜索测试项
    getTestItems: (params: any, config: ARC = {}) => {
      return api.get('qc/stdf/search-tsrs', { ...config, params });
    },
    // 搜索测试项带min/max
    getTestItemsWithMinMax: (params: any, config: ARC = {}) => {
      return api.get('qc/stdf/search-tests', { ...config, params });
    },
    // 监控规则组
    getMonitorRuleGroup: (params: any, config: ARC = {}) => {
      return api.get('qc/monitor-rule-group/search', { ...config, params });
    },
    // 监控规则组详情
    getMonitorRuleGroupById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/monitor-rule-group/${_id}`, { ...config });
    },
    // 修改监控规则组详情
    putMonitorRuleGroupById: (data: any, config: ARC = {}) => {
      return api.put(`qc/monitor-rule-group/${data._id}`, data, config);
    },
    // 新建监控规则
    postMonitorRule: (data: MonitorRule, config: ARC = {}) => {
      return api.post('qc/monitor-rule', data, config);
    },
    // 监控规则详情
    getMonitorRuleByType: (params: any, config: ARC = {}) => {
      return api.get('qc/monitor-rule/list', { ...config, params });
    },
    // 修改监控规则详情
    putMonitorRuleByType: (data: any, config: ARC = {}) => {
      return api.put(`qc/monitor-rule/${data._id}`, data, config);
    },
    // 删除监控规则条目
    deleteMonitorRuleById: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/monitor-rule/${_id}`, config);
    },
    // 根据testId自动计算控制线
    getAutoCl: (params: { productId: string; testId: string; }, config: ARC = {}) => {
      return api.get('qc/monitor-rule/auto-cl', {
        ...config,
        params,
        handleSpecialError: {
          406: e => {
            //
          },
        }
      });
    },

    /*************************** 告警联系人 **********************************/
    // 告警联系人列表
    getAlertContactsList: (params: AlertContactsListParams, config: ARC = {}) => {
      return api.get('qc/alert-contacts/search', { ...config, params });
    },
    // 新建告警联系人
    postAlertContact: (data: AlertContact, config: ARC = {}) => {
      return api.post('qc/alert-contacts/add', data, config);
    },
    // 修改告警联系人
    putAlertContact: (data: AlertContactEdit, config: ARC = {}) => {
      return api.put(`qc/alert-contacts/${data._id}`, data, config);
    },
    // 删除告警联系人
    deleteAlertContact: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/alert-contacts/${_id}`, config);
    },
    // 获取告警联系人详情
    getContactById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/alert-contacts/${_id}`, config);
    },
    /*************************** 告警联系组 **********************************/
    // 告警联系组列表
    getAlertGroupList: (params: AlertContactGroupParams, config: ARC = {}) => {
      return api.get('qc/alert-contacts-groups/search', { ...config, params });
    },
    // 新建告警联系组
    postAlertGroup: (data: AlertContactGroup, config: ARC = {}) => {
      return api.post('qc/alert-contacts-groups/add', data, config);
    },
    // 修改告警联系组
    putAlertGroup: (data: AlertContactGroupEdit, config: ARC = {}) => {
      return api.put(`qc/alert-contacts-groups/${data._id}`, data, config);
    },
    // 删除告警联系组
    deleteAlertGroup: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/alert-contacts-groups/${_id}`, config);
    },
    // 获取告警联系组详情
    getGroupById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/alert-contacts-groups/${_id}`, config);
    },
    /*************************** 告警历史 **********************************/
    // 告警历史列表
    getHistoryList: (params: any, config: ARC = {}) => {
      return api.get('qc/monitor-alert-history/group-by-product', { ...config, params });
    },
    // 告警历史详情
    getHistoryById: (params: any, config: ARC = {}) => {
      return api.get('qc/monitor-alert-history/list-by-product', { ...config, params });
    },
  };
}
