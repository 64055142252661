import { BasicModal } from '@chipcoo/hanayo';
import { sleep } from '@chipcoo/fe-utils';

import { CompilePathOptions } from 'src/components/HoneyRouter/interface';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';

const { confirm } = BasicModal;

type BoolOrBoolFn = boolean | (() => boolean);

interface DelayRemoveOptions {
  handler: () => any;
  key?: string;
  delay?: number;
  force?: boolean;
  replace?: boolean;
  nextRoute?: string | CompilePathOptions;
  executeDirectly?: BoolOrBoolFn;
  modalContent?: React.ReactNode | (() => React.ReactNode);
}

const inProcess: any = {};

export function lockKey(key: string) {
  inProcess[key] = true;
}

export function unlockKey(key: string) {
  Reflect.deleteProperty(inProcess, key);
}

export function createKey(namespace: UserId, event: UserId, id: UserId) {
  return [namespace, event, id].join('-');
}

/**
 *
 * @param options.handler 最终的回调
 * @param options.key 唯一key，用于标记任务，当该任务已经在进行时，不再进行后续操作
 * @param options.force 默认 false, 当为true时，会无视key直接强制进行后续操作
 * @param options.delay 最终回调延迟执行时间，默认 200ms
 * @param options.executeDirectly 是否直接执行回调，false则弹窗。
 * @param options.modalContent 弹窗内容
 * @param options.nextRoute 接下来要跳转到的路由
 * @param options.replace 默认true， 为true时使用replace的方式替换路由
 */
export async function delayRemove(options: DelayRemoveOptions) {
  options = Object.assign({ // tslint:disable-line
    replace: true,
    delay: 200,
    executeDirectly: false,
  }, options);

  const { key, replace, nextRoute, delay, handler, modalContent, force } = options;

  if (key && inProcess[key] && !force) {
    return;
  }

  key && lockKey(key);

  let direct = options.executeDirectly;

  if (typeof direct === 'function') {
    direct = direct();
  }

  if (!direct) {
    await new Promise<void>(resolve => {
      confirm({
        title: '提示',
        onCancel: resolve,
        afterClose: resolve,
        onOk: resolve,
        children: modalContent,
        // See: src/styles/global.less
        className: 'delay-remove-modal',
      });
    });

    const { history } = getCurrentRoute()!;

    if (nextRoute) {
      const path = typeof nextRoute === 'string' ? nextRoute : compilePath(nextRoute);
      if (replace) {
        history.replace(path);
      } else {
        history.push(path);
      }
    }
  }

  await sleep(delay);

  key && unlockKey(key);
  handler();
}
