import React, { PureComponent } from 'react';
import { RegisterNode } from 'gg-editor';
import { get } from 'lodash';

import {
  INPUT_ANCHOR,
  OUTPUT_ANCHOR,
  BASE_CRAFT_ROUTE_NODE,
  DEST_CRAFT_ROUTE_NODE,
  SOURCE_CRAFT_ROUTE_NODE,
} from './constant';
import { ICraftRouteNode } from '../interface';

const baseRouteConfig = {
  anchor(item: any) {
    const parent = item.getParent();
    const anchorPoints = [
      [1, 0.5, { type: INPUT_ANCHOR }],
    ];

    if (!parent) {
      anchorPoints.unshift([0, 0.5, { type: OUTPUT_ANCHOR }]);
    }

    return anchorPoints;
  },
  getSize() {
    return [100, 60];
  },
  getLabel(item: any) {
    const model = item.getModel() as ICraftRouteNode;

    let materialName: string | undefined;

    if (model.label) {
      materialName = get(model.label, 'text');

      if (typeof model.label === 'string') {
        materialName = model.label;
      }
    }

    if (model.materialName) {
      materialName = model.materialName;
    }

    const { amount } = model;

    if (materialName) {
      let text = materialName;

      if (amount > 1) {
        text += `\n配比：${amount}`;
      }
      return text;
    }

    return '请选择物料';
  },
};

const destRouteConfig = {
  anchor: [
    [1, 0.5, { type: INPUT_ANCHOR }],
  ],
};

const sourceRouteConfig = {
  anchor(item: any) {
    const parent = item.getParent();
    const anchorPoints: any[] = [
    ];

    if (!parent) {
      anchorPoints.push([0, 0.5, { type: OUTPUT_ANCHOR }]);
    }

    return anchorPoints;
  },
};

export class RegisterCraftRouteNodes extends PureComponent {
  render() {
    return (
      <>
        <RegisterNode name={BASE_CRAFT_ROUTE_NODE} config={baseRouteConfig} extend="flow-rect" />
        <RegisterNode
          name={DEST_CRAFT_ROUTE_NODE}
          config={destRouteConfig}
          extend={BASE_CRAFT_ROUTE_NODE}
        />
        <RegisterNode
          name={SOURCE_CRAFT_ROUTE_NODE}
          config={sourceRouteConfig}
          extend={BASE_CRAFT_ROUTE_NODE}
        />
      </>
    );
  }
}

export default RegisterCraftRouteNodes;
