import React from 'react';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  TextAreaFormItemConfig,
  RepeaterConfig,
  RepeaterRenderParams,
  CustomFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';

import SlicesTable from '../SlicesTable';
import { EnumProductModel } from '@chipcoo/constant';

const productName: InputFormItemConfig = {
  label: '产品名称',
  type: 'input',
  dataKey: 'productName',
};

const materialName: InputFormItemConfig = {
  label: '物料名称',
  type: 'input',
  dataKey: 'materialName',
};

const deliveryAt: InputFormItemConfig = {
  label: '来料发货时间',
  type: 'input',
  dataKey: 'deliveryAt'
};

const logisticsNumber: InputFormItemConfig = {
  label: '入库物流单号',
  type: 'input',
  dataKey: 'logisticsNumber',
};

const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
};

const dieNumber: InputFormItemConfig = {
  label: '芯片批号',
  type: 'input',
  dataKey: 'stockInfo.dieNumber',
};

const waferIds: InputFormItemConfig = {
  label: '片号',
  type: 'input',
  dataKey: 'stockInfo.waferIds',
};

const slices: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'stockInfo.slices',
  colSpan: 24,
  render: () => {

    return (
      <SlicesTable
        status={'preview'}
        waferIds={[]}
        productModel={EnumProductModel.spwDie}
      />
    );
  }
};

const formData: RepeaterConfig = {
  type: 'repeater',
  dataKey: 'formData',
  repeaterType: 'collapse',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams, initialData: any) {
    const {  } = initialData;

    return {
      panelProps: {
        header: '详细信息'
      },
      groupItemConfig: [
        productName,
        materialName,
        deliveryAt,
        logisticsNumber,
        remark,
        dieNumber,
        waferIds,
        slices,
      ] as FormItemConfig[]
    };
  }
};

export const getFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '入库信息' },
      groupItemConfig: [
        formData,
      ] as FormItemConfig[]
    }
  ]
});
