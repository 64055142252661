import { ProductNodeType, AnchorType } from '../interface';

export const BASE_CRAFT_ROUTE_GROUP = 'base-group';

/** 基本的工艺路线节点 */
export const BASE_CRAFT_ROUTE_NODE = ProductNodeType.BASE_CRAFT_ROUTE_NODE;

/** 最终产品的工艺路线节点 */
export const DEST_CRAFT_ROUTE_NODE = ProductNodeType.DEST_CRAFT_ROUTE_NODE;

/** 起始产品的工艺路线节点 */
export const SOURCE_CRAFT_ROUTE_NODE = ProductNodeType.SOURCE_CRAFT_ROUTE_NODE;

export const INPUT_ANCHOR = AnchorType.INPUT_ANCHOR;

export const OUTPUT_ANCHOR = AnchorType.OUTPUT_ANCHOR;
