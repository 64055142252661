// import React from 'react';
import { EnumProcessClass } from '@chipcoo/constant';

const time = {
  title: '时间',
  dataIndex: 'time',
  key: 'time',
};

const productName = {
  title: '产品名',
  dataIndex: 'productName',
  key: 'productName',
};

const packageTypeLW = {
  title: '封裝型号',
  dataIndex: 'packageTypeLW',
  key: 'packageTypeLW',
};

const name = {
  title: '状态',
  dataIndex: 'name',
  key: 'name',
};

const waferIds = {
  title: '片号',
  dataIndex: 'waferIds',
  key: 'waferIds',
};

const amount = {
  title: '数量',
  dataIndex: 'amount',
  key: 'amount',
};

interface Option {
  processClass?: EnumProcessClass;
  packageType?: string;
}

export const getTableColumns = (option: Option, tableMode?) => {
  const { processClass, packageType } = option;

  let columns = [
    name,
  ];

  if (processClass === EnumProcessClass.assembly || processClass === EnumProcessClass.ft) {
    columns.push(amount);

    if (tableMode !== 'history') {
      if (packageType === '引线框架') {
        columns.unshift(packageTypeLW);
      }

      columns.unshift(productName);
    }
  }

  if (
    processClass === EnumProcessClass.rw ||
    processClass === EnumProcessClass.cp ||
    processClass === EnumProcessClass.dp
  ) {
    columns.push(waferIds);
  }

  if (tableMode === 'history') {
    columns.unshift(time);
  }

  return columns;
};
