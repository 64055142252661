import { get, has } from '../utils/lodash';
const components: { [key: string]: React.Component } = {};

export const register = (key, instance: React.Component) => {
  components[key] = instance;
};

export const dropByCacheKey = key => {
  if (components[key]) {
    components[key].setState({ cached: false });
  }
};

export const clearCache = () => {
  Object.entries(components)
  .forEach(([, component]) => {
    if (component && has(component, 'state.cached') && !!get(component, 'state.cached')) {
      component.setState({ cached: false });
    }
  });
};

export const getCachingKeys = () =>
  Object.entries(components)
  .reduce((keys, [key, component]) => {
    if (get(component, 'state.cached')) {
      keys.push(key);
    }

    return keys;
  }, [] as string[]);
