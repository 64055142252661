import { compose, withHandlers, withState } from 'recompose';

type WithVisible = {
  visible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
};

const withVisible = compose(
  withState('visible', 'setVisible', false),
  withHandlers({
    show: ({ setVisible }) => () => setVisible(true),
    hide: ({ setVisible }) => () => setVisible(false),
    toggle: ({ setVisible }) => () => setVisible(visible => !visible)
  })
);

export { WithVisible };
export default withVisible;
