export namespace Verify {
  export enum EnumStatus {
    pending = 'PENDING',
    rejected = 'REJECTED',
    accepted = 'ACCEPTED',
  }
}

export namespace Access {
  export enum AllRole {
    member = 'MEMBER',
    owner = 'OWNER',
    admin = 'ADMIN',
    superAdmin = 'SUPER_ADMIN',
  }
}
