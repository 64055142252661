/**
 * Created by marvin on 2019/1/22.
 */

export const modalConfig = {
  receiver: { label: '收件人', type: 'input', required: true, message: '请输入收件人名字', autofocus: true },
  regionCode: { label: '省/市/区', type: 'cascader', required: true, message: '请选择省/市/区' },
  detail: { label: '详细地址', type: 'input', required: true, message: '请输入详细地址' },
  phone: { label: '收件电话', type: 'input', required: true, message: '请输入收件电话' },
  tag: { label: '标签', type: 'input', message: '请输入地址标签' },
};
