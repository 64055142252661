import { createModel } from '@rematch/core';
import { produce } from 'immer';
import { omit } from 'lodash';
import { TableModelCommonEffects } from 'src/models/type';
import { inventoryApi } from 'src/services/net';
import { IState } from './interface';
import { handleStockList, handleStockDetail, handleFormData, handleBulkFormData } from './handle';
// import { EnumProductModel } from '@chipcoo/constant';

const initialState: IState = {
  list: [],
  count: 0,
  detail: void 0,
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: IState, respData: any) {
      return produce(state, draft => {
        const { data } = respData;

        draft.count = data.count;
        draft.list = handleStockList(data.data);
      });
    },
    setDetail(state: IState, respData: any) {
      return produce(state, draft => {
        draft.detail = handleStockDetail(respData);
      });
    },
  },
  effects: (): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await inventoryApi.getStockInApply(params);

      this.setList({ data});
    },
    // 用于重新提交单个入库申请
    async postCreate(data: any) {
      const formData = await handleFormData(data);
      await inventoryApi.postStockInApply(formData);
    },
    // 批量添加入库申请
    async bulkCreate(data: any) {
      const handleData = await handleBulkFormData(data);
      
      await inventoryApi.postStockInApplyBulk(handleData);
    },
    async getTableItemDetail(id: string) {
      const { data } = await inventoryApi.getStockInApplyById(id);

      this.setDetail(data);
    },
    async patchModify(data: any) {
      const { id } = data;
      let { stockInfo } = await handleFormData(data);
      stockInfo = omit(stockInfo, 'attributes');
      await inventoryApi.editStockInApply({ id, stockInfo });
    },
  })
});
