/**
 * 入库申请-bin列表
 */
import React, { FC, useEffect } from 'react';
import _ from 'lodash';
import { InputNumber } from 'antd';
import { object2Bins } from '@chipcoo/fe-utils';

import { TableForm } from 'src/components';
import { ModalType } from '../index';

import SelectTableBinsOrWaferIds from './SelectTableBinsOrWaferIds';

const binTableFormConfig = {
  name: {
    title: 'bin类型',
    width: 400,
    editableRender: (props, tableData) => {
      const newTableData = tableData.filter(item => item.name);

      return (
        <SelectTableBinsOrWaferIds
          {...props}
          size="small"
          type="bins"
          tableData={newTableData}
          getPopupContainer={(t) => t.parentNode as HTMLElement}
        />
      );
    }
  },
  count: {
    title: '数量',
    width: 300,
    editableRender: (props) => <InputNumber autoComplete="off" placeholder="数量" {...props} />
  },
};

interface IProps {
  status: ModalType;
  value?: any;
  onChange?: (value: any) => void;
  setFieldsValue?: (obj: Obj) => void;
  shouldSetFields: boolean;
}

const BinTable: FC<IProps> = props => {
  const { status, value, onChange, setFieldsValue, shouldSetFields } = props;

  const handleSetFields = (values) => {
    const { binType, amount } = object2Bins(values);

    if (status !== 'new' && shouldSetFields) {
      setFieldsValue && setFieldsValue({
        amount,
        binType: binType.join(', '),
      });
    }
  };

  useEffect(() => {
    handleSetFields(value);
  }, [value]);

  const handleChange = (val) => {
    onChange && onChange(val);
    handleSetFields(val);
  };

  const getRowKey = (record: any) => {
    return record.name || (Math.random().toString(16));
  };

  const readOnly = status === 'preview';

  return (
    <TableForm
      isInFlexModal={true}
      rowKey={getRowKey}
      onlyRead={readOnly}
      columnsConfig={binTableFormConfig}
      value={value}
      onChange={handleChange}
      showAddBtn={_.get(value, 'length') !== 9}
      isSetDisabledByAdd={false}
      size="small"
    />
  );
};

export default BinTable;
