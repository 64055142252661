/**
 * 选择成员的面板，类似于antd中的穿梭框，但穿梭框无法改造成契合我们业务的形势
 * @注意：所有的dataSource数据必须在组件初始化的时候就传进来，如果数据有改变，不会动态反应到组件中
 */
import * as React from 'react';
import classnames from 'classnames';

import styles from './index.module.less';

import LeftList from './LeftList';
import RightList from './RightList';

export interface DataItem {
  key: string;
  title: string;
  disabled?: boolean;
  checked?: boolean;
}

export interface MemberSelectGroup {
  dataKeys: string[];
  groupTitle: string;
  // groupType: 'user' | 'org';
  groupKey: string;
  isAsync?: boolean;
  fetched?: boolean;
  loading?: boolean;
  fetch?: (...args: any[]) => any;
}

export interface SelectMemberPanelProps {
  leftTitle: string | React.ReactNode;
  rightTitle: string | React.ReactNode;
  dataSource: DataItem[];
  // 做筛选过滤的判断函数
  filterOption?: (filterValue: any, record: DataItem) => boolean;
  // 显示在右侧框数据的key集合
  selectedKeys?: string[];
  // 是否是按组展示模式
  groupMode?: boolean;
  // 分组
  groups?: MemberSelectGroup[];
  render: (record: DataItem) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onSelectedKeysChange?: (selectedKeys: string[]) => void;
}
interface S {
  selectedKeys: string[];
  activePanelKeys: string[];
}

type SMPP = SelectMemberPanelProps;

class SelectMemberPanel<P extends SMPP = SMPP> extends React.PureComponent<P, S> {
  static defaultProps = {
    selectedKeys: [],
  };

  constructor(props: P) {
    super(props);

    const { selectedKeys } = props;

    this.state = {
      selectedKeys: selectedKeys!,
      activePanelKeys: [] as string[],
    };
  }

  handleSelectedKeysChange = (selectedKeys: string[]) => {
    const { onSelectedKeysChange } = this.props;

    onSelectedKeysChange && onSelectedKeysChange(selectedKeys);

    this.setState({ selectedKeys });
  }

  render() {
    const { selectedKeys } = this.state;
    const {
      rightTitle,
      leftTitle,
      className,
      style,
      dataSource,
      render,
      filterOption,
      groupMode,
      groups,
    } = this.props;

    return (
      <div className={classnames(className, styles.selectMemberPanelWrapper)} style={style}>
        <LeftList
          title={leftTitle}
          dataSource={dataSource}
          selectedKeys={selectedKeys}
          render={render}
          filterOption={filterOption}
          groups={groups}
          groupMode={groupMode}
          onSelectedKeysChange={this.handleSelectedKeysChange}
        />

        <RightList
          title={rightTitle}
          dataSource={dataSource}
          selectedKeys={selectedKeys}
          render={render}
          onSelectedKeysChange={this.handleSelectedKeysChange}
        />
      </div>
    );
  }
}

export default SelectMemberPanel;
