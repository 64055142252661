/**
 * 物流管理筛选
 */
import React from 'react';
import _ from 'lodash';
import {
  FormItemConfig,
  InputFormItemConfig,
  CustomFormItemConfig,
  SelectFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';
import SelectProductByModel from './SelectProductByModel';
import SelectMaterialByProductModel from './selectMaterialinByProductModel';

type CategoryType = EnumProductModel | 'ALL';

const serial: InputFormItemConfig = {
  label: '申请单号',
  type: 'input',
  dataKey: 'serial',
};
const materialName = (productModel: CategoryType): CustomFormItemConfig => ({
  label: '物料名称',
  type: 'custom',
  dataKey: 'material',
  render: () => <SelectMaterialByProductModel productModel={productModel} />
});
const productName = (productModel: CategoryType): CustomFormItemConfig => ({
  label: '产品名称',
  type: 'custom',
  dataKey: 'product',
  render: (formData, globalStatus, form) => (
    <SelectProductByModel
      status={globalStatus}
      paths={['productName', 'fileType']}
      resetFields={form.resetFields}
      productModel={productModel}
      organization={_.get(formData, 'customerName.key')}
    />
  )
});
const dieNumber = (productModel: CategoryType): InputFormItemConfig => ({
  label: '芯片批号',
  type: 'input',
  dataKey: 'dieNumber',
  renderType: () => {
    if (productModel !== EnumProductModel.assemblyProduct) {
      return 'normal';
    }
    return 'none';
  }
});
const stampNumber = (productModel: CategoryType): InputFormItemConfig => ({
  label: '印章批号',
  type: 'input',
  dataKey: 'stampNumber',
  renderType: () => {
    if (productModel === EnumProductModel.assemblyProduct) {
      return 'normal';
    }
    return 'none';
  }
});
const warehouse: SelectFormItemConfig = {
  label: '库房名称',
  type: 'select',
  dataKey: 'warehouse',
  optionKey: 'warehouse',
  props: { labelInValue: true }
};

const restrictBySerial: InputFormItemConfig = {
  label: '限制单号',
  type: 'input',
  dataKey: 'restrictBySerial',
};

export const filterFormConfig = (productModel: CategoryType, type?): FormItemConfig[] => {
  const common: FormItemConfig[] = [
    materialName(productModel),
    productName(productModel),
    dieNumber(productModel),
    stampNumber(productModel),
    warehouse,
  ];

  if (type === 'stockOutApplication') {
    common.push(restrictBySerial);
  } else {
    common.unshift(serial);
  }

  return common;
};
