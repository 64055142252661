const { REACT_APP_RUN_ENV, NODE_ENV } = process.env;

type Env = 'local' | 'test' | 'prod' | 'demo';

// 默认生产环境
let env: Env = 'prod';

// 是否是开发环境
let isDev = false;

// 本地开发环境
if (NODE_ENV === 'development' || REACT_APP_RUN_ENV === 'develop') {
  env = 'local';
  isDev = REACT_APP_RUN_ENV !== 'develop';
}

// 线上测试环境
if (REACT_APP_RUN_ENV === 'test') {
  env = 'test';
}

// demo环境
if (REACT_APP_RUN_ENV === 'demo') {
  env = 'demo';
}

export { env, isDev };
