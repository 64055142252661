import { EnumProductModel } from '@chipcoo/constant';

export interface CategoryConfig {
  label: string;
  path: string;
  key: EnumProductModel | 'ALL';
}

export enum EnumStockMethods {
  stockIn = 'stock-in',
  stockOut = 'stockout-record',
}

export const categoryConfig: CategoryConfig[] = [
  {
    label: 'SPW芯片',
    path: EnumProductModel.spwDie,
    key: EnumProductModel.spwDie
  },
  {
    label: '磨划成品',
    path: EnumProductModel.dpProduct,
    key: EnumProductModel.dpProduct
  },
  {
    label: '封装成品',
    path: EnumProductModel.assemblyProduct,
    key: EnumProductModel.assemblyProduct
  },
];
