// tslint:disable:max-file-line-count
/**
 * 协作区列表页面
 */
import React from 'react';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { notification } from 'antd';
import { bind } from 'lodash-decorators';
import { Flipper, Flipped } from 'react-flip-toolkit';

import { RootState, connectDecorator, Actions } from 'src/store';

import style from './list.module.less';
import WorkspaceModal, { FormValues } from './components/WorkspaceModal';
import { WorkspaceCard } from './components/WorkspaceCard';

import { HoverScrollBarContainer } from 'src/components';
import { Workspace, WithWorkspaceId } from 'src/models/workspace/interface';

import { AsyncDataArg, getRouterProps } from 'src/components/HoneyRouter';
import { CreateParams } from 'src/services/net/workspace';
import { QuickSelectProps } from 'src/components/QuickSelectUser';
import { isEqualUserId } from 'src/utils/user';
import { pickOrgUser, pickNormalizedOrgUser } from 'src/models/org';
import { fastOrgPath } from 'src/router/normalRouter';

interface OwnProps {}
interface StateProps {
  ids: string[];
  profile: User;
  byId: { [key: string]: Workspace };
  orgUsers: RoleUser[];
  normalizedOrgUsers: NormalizedData<RoleUser>;
}

interface DispatchProps {
  createWorkspace: (val: CreateParams) => any;
  starWorkspace: (arg: WithWorkspaceId) => any;
  unstarWorkspace: (arg: WithWorkspaceId) => any;
}

type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  modalVisible: boolean;
  selectProps: Partial<QuickSelectProps>;
}

function getWorkSpaceWeight(workspace: Workspace) {
  if (workspace.isMaster) {
    return 2;
  }

  if (workspace.isStar) {
    return 1;
  }

  return 0;
}

@connectDecorator(
  ({ workspace, org, session, user }: RootState) => ({
    ids: workspace.list.allIds,
    byId: workspace.list.byId,
    orgUsers: pickOrgUser(user, org.orgMembers.byId) as RoleUser[],
    profile: session.profile!,
    normalizedOrgUsers: pickNormalizedOrgUser(user, org.orgMembers.byId)
  }),
  ({ workspace }: Actions) => ({
    createWorkspace: workspace.create,
    starWorkspace: workspace.starWorkspace,
    unstarWorkspace: workspace.unstarWorkspace,
  })
)
class WorkspaceList extends React.PureComponent<P, S> {
  static async asyncData(arg: AsyncDataArg) {
    const { store } = arg;
    return store.dispatch({ type: 'workspace/fetch' });
  }

  constructor(props: P) {
    super(props);

    this.state = {
      modalVisible: false,
      selectProps: {
        value: [ props.profile._id ],
      }
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const state = location.state;

    if (state && get(state, 'description')) {
      await this.openNotification('success');
      this.props.history.push(fastOrgPath(), '');
    }
  }

  @bind
  onWorkspaceMemberChange(value: string[]) {
    this.setState({
      selectProps: {
        value
      }
    });
  }

  @bind
  canMemberRemove(member: string) {
    const { profile } = this.props;

    return profile._id.toString() !== member.toString();
  }

  get quickSelectProps() {
    const { orgUsers } = this.props;

    return {
      ...this.state.selectProps,
      showRemove: true,
      canRemove: this.canMemberRemove,
      onChange: this.onWorkspaceMemberChange,
      users: orgUsers,
    };
  }

  openNotification = (type: string) => {
    const { location } = this.props;
    const description = get(location, 'state.description');

    notification[type]({
      message: '提示',
      description: `${description}`
    });
  }

  @bind
  openModal() {
    const { profile } = this.props;

    this.setState({
      modalVisible: true,
      selectProps: {
        value: [ profile!._id ],
      }
    });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible : false });
  }

  @bind
  async handleSubmit(val: FormValues) {
    const { profile } = this.props;
    const value = this.state.selectProps.value || [];
    await this.props.createWorkspace({
      ...val,
      members: (value as string[]).map((id) => ({
        user: id,
        role: isEqualUserId(profile._id, id) ? 'OWNER' : 'MEMBER',
      })),
    } as any);
  }

  handleEnterOrg = (orgId: string) => {
    const { history, match } = this.props;

    history.push(`${match.url}/org/${orgId}`);
  }

  getOwner(workspace: Workspace) {
    const { orgUsers, normalizedOrgUsers } = this.props;
    const flatMembers = orgUsers;

    if (workspace.isMaster) {
      return flatMembers.find(member => member.role === 'OWNER');
    }

    const owner = workspace.members.find(member => member.role === 'OWNER');
    const { byId } = normalizedOrgUsers;
    return byId[owner!.user];
  }

  @bind
  async handleToggleStar(workspace: Workspace) {
    const { starWorkspace, unstarWorkspace } = this.props;
    const { _id: workspaceId, isStar } = workspace;

    if (!isStar) {
      await starWorkspace({ workspaceId });
    } else {
      await unstarWorkspace({ workspaceId });
    }
  }

  renderItemCard() {
    const { ids, byId } = this.props;

    const cards = ids.reduce(
      (_cards, id) => {
        if (byId[id]) {
          _cards.push(byId[id]);
        }

        return _cards;
      },
      [] as Workspace[]
    ).sort((a, b) => {
      return getWorkSpaceWeight(b) - getWorkSpaceWeight(a);
    });

    const routerProps = getRouterProps(this.props);
    // const { match: { params } } = this.props;
    const nextFlipKey = cards.map(it => it._id).join('');
    return (
      <Flipper flipKey={nextFlipKey}>
        {
          cards.map((card, i) => (
            <Flipped key={card._id} flipId={card._id}>
              {(flippedProps) => (
                <WorkspaceCard
                  workspace={card}
                  onToggle={this.handleToggleStar}
                  owner={this.getOwner(card)!}
                  {...routerProps}
                  flippedProps={flippedProps}
                />
              )}
            </Flipped>
          ))
        }
      </Flipper>
    );
  }

  renderContent = () => {
    return (
      <div className={style.section}>
        <div className={style.sectionHeader}>
          <div onClick={this.openModal} className={style.addNew}>
            <i className="c-icon icon-plus-thick" />
            新建协作区
          </div>
        </div>

        <div className={`clear ${style.workspaceList}`}>
          {this.renderItemCard()}
        </div>
      </div>
    );
  }

  render() {
    const { modalVisible } = this.state;
    return (
      <HoverScrollBarContainer className={style.workspaceListWrapper}>
        {this.renderContent()}

        <WorkspaceModal
          visible={modalVisible}
          autoFocus={true}
          onClose={this.closeModal}
          onSubmit={this.handleSubmit}
          quickSelectProps={this.quickSelectProps}
          title="新建协作区"
        />
      </HoverScrollBarContainer>
    );
  }
}

export default WorkspaceList;
