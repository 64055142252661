import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  EnumProductModel,
  EnumProcessStatus,
  EnumMaterialStatus,
  EnumProcessClass,
} from '@chipcoo/constant';

export interface IProcessData {
  _id?: string;
  name: string;
  code: string;
  productModel: EnumProductModel;
  description: string;
  status?: EnumProcessStatus;
}

export interface IProcessDetail extends Required<IProcessData> {
  serial: number;
}

export interface IProductProcessBase {
  /** 工艺路线id */
  craftRoute:	Required<ICraftRouteCreateData>;
  /** 产品id */
  product: string;
  /** 产品类型 */
  productModel: EnumProductModel;
  /** 工艺id */
  process: string;
  /** 制程类型 */
  processClass?: EnumProcessClass;
  /** 状态 */
  status: EnumProcessStatus;
  /** 工厂id */
  factory?: any;
  /** 生产周期 */
  cycle?:	number;
  /** 良率 */
  yield?:	number;
  /** bin类型占比 */
  binRatio?: number;
  /** 封回率 */
  returnRatio?:	number;
  /** 单位价格 */
  unitPrice?: number;
  /** 备注 */
  remark?: string;

  /** 组织ID */
  organization?: string;
}

export interface IProductProcessDetail extends IProductProcessBase {
  /** 产品制程ID */
  _id: string;
}

export interface IMaterialDetail {
  materialName: string;
  organization: string;
  process: string;
  product: string;
  productModel: EnumProductModel;
  status: EnumMaterialStatus;
  _id: string;
}

/** 工艺路线中的产品信息 */
export interface IBaseCraftRouteProductInfo {
  _id: string;
  deviceNo?: string;
  dieName?: string;
}

interface IBase {
  amount: number;
  id: string;
  isDest?: boolean;
  parent?: ICraftRouteItemLocal | ICraftRouteItem;
  siblings?: ICraftRouteItemLocal[];
  itemIndex?: number;
}

export interface ICraftRouteItem  {
  organization: string;
  process: IProcessDetail;
  product: IBaseCraftRouteProductInfo;
  productModel: EnumProductModel;
  status: EnumProcessStatus;
  _id: string;
  sources: ISourceItem[];
  material: IMaterialDetail;
  isActive?: boolean;
  priority?: number;
}

export interface ICraftRouteItemLocal extends ICraftRouteItem {
  children: (ICraftMaterial | ICraftRouteItemLocal)[];
  amount: number;
  /** 用于标记是否继续往下取 */
  isEnd?: boolean;
  /** 是否是目标 */
  isDest?: boolean;
  parent?: ICraftRouteItemLocal | ICraftRouteItem;
  siblings?: ICraftRouteItemLocal['children'];
  itemIndex?: number;
}

export interface ICraftRoute extends IBase {
  type: 'craft-route';
  item: ICraftRouteItem;
}

export interface ICraftMaterial extends IBase {
  item: IMaterialDetail;
  type: 'material';
}

export type ISourceItem = ICraftRoute | ICraftMaterial;

export interface ICraftRouteFullRoute extends Omit<ICraftRouteItem, 'isActive'> {
  isActive: boolean;
}

export interface IFetchCraftDetailParams {
  productId: string;
  type?: string;
}

export interface ICraftRouteSourceItem {
  type:	ISourceItem['type'];
  id: string;
  amount?: number;
}

export interface ICraftRouteCreateData {
  _id?: string;
  material: string;
  sources: ICraftRouteSourceItem[];
  priority?: number;
}

export interface ICraftRouteUpdateData extends ICraftRouteCreateData {
  isForce?: boolean;
}

export default function(api: AxiosInstance) {
  return {
    create(data: ICraftRouteCreateData, config: AxiosRequestConfig = {}) {
      const { _id, ...createData } = data;
      return api.post('product/craft-route', createData, config);
    },
    update(data: ICraftRouteUpdateData, isForce: boolean = false, config: AxiosRequestConfig = {}) {
      const { _id, material, ...updates } = data;
      return api.patch(`product/craft-route/${_id}`, updates, { ...config, params: { ...config.params, isForce } });
    },
    detail(data: IFetchCraftDetailParams, config: AxiosRequestConfig = {}) {
      const { productId, ...params } = data;
      return api.get<ICraftRouteFullRoute[]>(`product/craft-route/product/${data.productId}`, {
        ...config,
        params,
      });
    },
    togglePause(data: { craftRouteId: string; isPaused: boolean }, config: AxiosRequestConfig = {}) {
      const { craftRouteId, isPaused } = data;
      return api.patch(`product/craft-route/${craftRouteId}/paused`, { isPaused }, config);
    },
    preview(data: ICraftRouteCreateData, config: AxiosRequestConfig = {}) {
      const { _id, ...postData } = data;

      return api.post<Omit<ICraftRouteFullRoute, '_id'>>('product/craft-route/preview', postData, config);
    },
    singleDetail(craftRouteId: string, config?: AxiosRequestConfig) {
      return api.get<ICraftRouteFullRoute>(`product/craft-route/${craftRouteId}`, config);
    },
  };
}
