import { Modal } from 'antd';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';
interface IProps {
  modalType?: 'info' | 'error' | 'success' | 'warning';
  title?: React.ReactNode; 
  content?: React.ReactNode;
}

export const messageModal = (props: IProps) => {
  const { modalType, ...restProps } = props;

  switch (modalType) {
    case 'info':
      return Modal.info({ ...restProps, zIndex: FlexModalManager.getNextZIndex() });
    case 'success':
      return Modal.success({ ...restProps, zIndex: FlexModalManager.getNextZIndex() });
    case 'warning':
      return Modal.warning({ ...restProps, zIndex: FlexModalManager.getNextZIndex() });
    default:
      return Modal.error({ ...restProps, zIndex: FlexModalManager.getNextZIndex() });
  }
};
