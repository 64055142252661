import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Resource } from './interface';
import store, { RootState } from 'src/store';
import { createUCommentSelector } from 'src/models/universeComment';
import { CommentResourceInst } from 'src/models/universeComment/interface';
import { getResourceKey } from 'src/services/resource';
import { MessageAttachment } from 'src/components';

interface OwnProps {
  resource: Resource;
}

type AttachmentsProps = {
  attachments: ImAttachment[];
} & Omit<CommentResourceInst['attachments'], 'byId' | 'allIds'>;

interface DispatchProps {

}

type Props = OwnProps & DispatchProps & AttachmentsProps;

interface State {}

class Attachments extends PureComponent<Props, State> {
  componentDidMount() {
    //
    store.dispatch({
      type: 'universeComment/fetchAttachments',
      payload: {
        ...this.props.resource,
        page: 1,
        // TODO: 最好分页
        limit: 900,
      }
    });
  }

  render() {
    const { attachments } = this.props;

    if (attachments.length === 0) {
      return (
        <div className="empty-attachment text-center">
          暂无附件
        </div>
      );
    }

    return (
      <div className="universe-attachments">

        {attachments.map(attachment => (
          <MessageAttachment
            key={attachment._id}
            title="查看/下载附件"
            className="comment-attachment"
            attachments={[attachment]}
            maxImageWidth={240}
            imgPreview={false}
          />
        ))}
      </div>
    );
  }
}

function createMapState() {
  const attachmentSelector = createUCommentSelector('attachments');
  return function(arg: RootState, props: Props) {
    const { resource } = props;
    const key = getResourceKey(resource);
    const commentsData = attachmentSelector(arg.universeComment, key);

    return commentsData as any as AttachmentsProps;
  };
}

export default connect<AttachmentsProps, DispatchProps, OwnProps>(createMapState)(Attachments);
