import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { bind } from 'lodash-decorators';
import { getUserName } from '@chipcoo/fe-utils';

import { EnhanceAvatar } from '../EnhanceAntd';
import { HocProfileAvatar } from 'src/hoc';
import { QuickSelectProps } from '.';
import { TooltipProps } from 'antd/lib/tooltip';

interface Props {
  userInfo: User;
  userDisabled: QuickSelectProps['userDisabled'];
  classNames?: string;
  displayName?: string;
  avatarOnly?: boolean;
  isSelected?: boolean;
  canEdit?: boolean;
  canUserRemove?: boolean | ((id: string) => boolean);
  handleClick?: (user: User) => any;
  removeUser?: (id: string) => any;
  tooltipPlacement?: TooltipProps['placement'];
}

interface States {}

export class SingleUserItem extends PureComponent<Props, States> {
  static defaultProps = {
    avatarOnly: false,
  };

  @bind
  handleClick() {
    const { handleClick, userInfo } = this.props;
    handleClick && handleClick(userInfo);
  }

  @bind
  isUserDisabled() {
    const { userDisabled = false, userInfo } = this.props;
    if (typeof userDisabled === 'function') {
      return userDisabled(userInfo);
    }

    return userDisabled;
  }

  @bind
  removeUser(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    const { removeUser, userInfo } = this.props;
    removeUser && removeUser(userInfo._id);
  }

  @bind
  renderRemove() {
    let { canUserRemove = true, userInfo, canEdit = true } = this.props;
    if (typeof canUserRemove === 'function') {
      canUserRemove = canUserRemove(userInfo._id);
    }

    if (!canUserRemove || !canEdit) return null;

    return <i title="移除成员" onClick={this.removeUser} className="icon-close-circle c-icon remove-icon" />;
  }

  @bind
  renderAvatarImg(src: string, user: User) {
    const { avatarOnly } = this.props;
    return (
      <div className="user-avatar-wrapper">
        {!!avatarOnly && this.renderRemove()}
        <EnhanceAvatar
          src={src}
          key="avatar"
          userName={getUserName(user)}
          userDisabled={this.isUserDisabled}
          className="member-avatar circled-size"
        />
      </div>
    );
  }

  renderAvatar() {
    const { userInfo, displayName, tooltipPlacement } = this.props;
    const { avatar } = userInfo;

    return (
      <Tooltip title={displayName} placement={tooltipPlacement || 'top'}>
        <span className="single-user-avatar-wrapper">
          {this.renderAvatarImg(avatar || '', userInfo)}
        </span>
      </Tooltip>
    );
  }

  renderFull() {
    const { userInfo, displayName } = this.props;
    const { avatar } = userInfo;

    return (
      <>
        {this.renderAvatarImg(avatar || '', userInfo)}
        {<span className="cell">{displayName}</span>}
        {<span className="select-icon-wrapper"><i className="c-icon icon-affirm-thick" /></span>}
      </>
    );
  }

  render() {
    const { classNames, avatarOnly, isSelected } = this.props;
    const userDisabled = this.isUserDisabled();

    const clsName = classnames(
      'user-detail',
      isSelected ? 'selected' : '',
      avatarOnly ? 'avatar-only single-item' : 'user-full flex align-center',
      userDisabled ? 'user-disabled' : '',
      classNames
    );

    return (
      <div className={clsName} onClick={this.handleClick}>{
        avatarOnly
          ? this.renderAvatar()
          : this.renderFull()
      }</div>
    );
  }
}

export const HocSingleUserItem = HocProfileAvatar(SingleUserItem);

export default SingleUserItem;
