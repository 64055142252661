import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { parseURL } from '@chipcoo/fe-utils';
import { get } from 'lodash';

import upyun from 'src/config/upyun';
import { attachmentApi } from 'src/services/net';
import { handleRequest } from './interceptors/request';

export type GetAttachmentDetailParams = {
  versionId?: string;
};

export type DownloadAttachmentParams = {
  attachmentId: string;
  // 版本id，留空最新版
  versionId?: string;
  // 是否是下载地址
  download?: boolean;
  params?: Obj;
  noRedirect?: boolean;
};

// 上传规则参数
export type PolicyParams = {
  referenceName: string;
  referenceId: string;
  referenceRole: string | number;
};

// 附件参数
export interface AttachmentParams {
  name: string;
  id: string;
  role: string;
  filename: string;
  url: string;
  size: number;
  mimetype: string;
  width?: number;
  height?: number;
}

// 上传附件参数
export interface UploadFileParams {
  name: string;
  id: string;
  role: string;
  file: File;
}

// 上传附件至服务商需要的参数
export interface UploadFileToServiceProviderParams {
  // 附件关联资源名
  referenceName: string;
  // 附件关联资源id
  referenceId?: string;
  // 附件关联资源类型
  referenceRole: string;
  // 文件对象
  file: File;
  getProgress?: (progress: number, idx?: number) => void;
  index?: number;
  // 是否需要串行式断点续传
  isSlice?: boolean;
}

// 更新附件参数
export type UpdateFileParams = {
  attachmentId: string;
} & UploadFileToServiceProviderParams;

// 用于删除附件的参数
export type DeleteFileParams = {
  attachmentId: string; // 附件id
  id: string; // 当前list item id
};

const addImgWidthHeightParams = (uploadFileInfo: Obj, respData: Obj) => {
  const clone = {...uploadFileInfo};

  if ('image-width' in respData) {
    clone.width = respData['image-width'];
  }

  if ('image-height' in respData) {
    clone.height = respData['image-height'];
  }

  return clone;
};

export default function(api: AxiosInstance) {
  return {
    // 获取附件信息
    getDetail: (attachmentId, params: GetAttachmentDetailParams = {}, config: AxiosRequestConfig = {}) => {
      return api.get(`attachment/chipcoo/${attachmentId}`, {
        ...config, params
      });
    },

    // 批量获取附件信息
    getBatchDetail: (attachmentIds: string[], config: AxiosRequestConfig = {}) => {
      return api.get(`attachment/chipcoo/batch`, {
        ...config, params: { attachmentIds }
      });
    },

    // 预览或者下载附件
    download: (params: DownloadAttachmentParams) => {
      const { attachmentId, versionId, download, params: param } = params;
      const url = `/api/attachment/chipcoo/${attachmentId}/download`;
      const orgId = get(handleRequest({ headers: {} }), 'headers.x-chipcoo-org-id');
      let qs: any = {};

      if (versionId) {
        qs.versionId = versionId;
      }

      if (download) {
        qs.isForceDownload = 1;
      }

      return parseURL(url, { ...qs, ...param, orgId });
    },

    // 获取附件上传规则
    getPolicy: (params: PolicyParams, config: AxiosRequestConfig = {}) => {
      return api.get(
        `attachment/chipcoo/policy`,
        {
          ...config,
          params,
        }
      );
    },

    // 添加附件
    add: (data: AttachmentParams, config: AxiosRequestConfig = {}) => {
      const { name, id, role, ...file } = data;

      return api.put(`attachment/chipcoo/reference/${name}/${id}/${role}`, file, config);
    },

    // 上传文件至对应的服务商
    async uploadFileToServiceProvider(params: UploadFileToServiceProviderParams, config: AxiosRequestConfig = {}) {
      const {
        file,
        referenceName,
        referenceId = 'UNKNOWN',
        referenceRole,
        getProgress,
        index,
        isSlice = false
      } = params;

      if (isSlice) {
        // 使用串行式断点续传
        /**
         * response：
         * 1.Authorization
         * 2.X-Date
         * 3.bucket
         * 4.key
         */
        const { data } = await attachmentApi.getSlicePolicy({
          referenceName, referenceId, referenceRole, filename: file?.name
        });
        // 文件url
        const url = data?.key;
        // idx表示切块file的第几块
        let idx = 0;
        // 设置分块大小
        const size = 1048576 * 10;

        const getUpload = async (headers, body?) => {
          const response = await axios.put(
            `${upyun.UPURL}/${data?.bucket}${url}`,
            body,
            {
              ...config,
              headers,
              timeout: 60000,
              onUploadProgress: (progressEvent) => {
                const currentLoaded = idx * size + progressEvent.loaded;
                const progress = Math.floor(currentLoaded / file.size * 100);
                getProgress && getProgress(progress, index);
              }
            }
          );
          return response;
        };

        // 设置共用的headers
        const commonHeaders = {
          'Authorization': data?.Authorization,
          'X-Date': get(data, 'X-Date'),
        };
        // 初始化串行式断点续传任务
        let res = await getUpload({
          ...commonHeaders,
          'X-Upyun-Multi-Stage': 'initiate',
          'X-Upyun-Multi-Length': file?.size as any,
          'X-Upyun-Multi-Part-Size': '10485760',
        });

        let [nextPartId, upyunUuid] = [
          get(res, 'headers.x-upyun-next-part-id'),
          get(res, 'headers.x-upyun-multi-uuid')
        ];

        while (true) {
          if (nextPartId < 0) {
            // 结束串行式断点续传任务
            await getUpload({
              ...commonHeaders,
              'X-Upyun-Multi-Stage': 'complete',
              'X-Upyun-Multi-Uuid': upyunUuid,
            });
            return { data: { url } };
          } else {
            // 串行式断点续传任务-切块上传
            res = await getUpload(
              {
                ...commonHeaders,
                'Content-Length': size,
                'X-Upyun-Multi-Stage': 'upload',
                'X-Upyun-Multi-Uuid': upyunUuid,
                'X-Upyun-Part-Id': nextPartId,
              },
              file.slice(idx * size, size * (idx + 1))
            );

            idx++;
            // 每次更新part-id
            nextPartId = get(res, 'headers.x-upyun-next-part-id');
          }
        }
      } else {
        // 普通上传
        const { data: { policy, authorization, bucket } } = await attachmentApi.getPolicy({
          referenceName, referenceId, referenceRole
        });
        const fd = new FormData();

        fd.append('authorization', authorization);
        fd.append('policy', policy);
        fd.append('file', file, file.name);

        return await axios.post(
          `${upyun.UPURL}/${bucket}`,
          fd,
          {
            ...config,
            onUploadProgress: (progressEvent) => {
              const progress = Math.floor(progressEvent.loaded / progressEvent.total * 100);
              getProgress && getProgress(progress, index);
            }
          }
        );
      }
    },

    // 更新附件
    async updateFile(params: UpdateFileParams) {
      const { attachmentId, file } = params;
      const { data: respData } = await attachmentApi.uploadFileToServiceProvider(params);
      const { name, size, type } = file;

      const uploadFileInfo: Obj = addImgWidthHeightParams(
        { size, filename: name, mimetype: type, url: respData.url },
        addImgWidthHeightParams
      );

      const result = await api.post(`attachment/chipcoo/${attachmentId}/version`, uploadFileInfo);

      return result;
    },

    // 上传附件
    async uploadFile(params: UploadFileToServiceProviderParams) {
      const { file, referenceName, referenceId = 'UNKNOWN', referenceRole } = params;
      const { data: respData } = await attachmentApi.uploadFileToServiceProvider(params);
      const { size, name, type } = file;
      const uploadFileInfo: Obj = addImgWidthHeightParams(
        { size, filename: name, mimetype: type, url: respData.url },
        addImgWidthHeightParams
      );

      const result = await api.put(
        `attachment/chipcoo/reference/${referenceName}/${referenceId}/${referenceRole}`,
        uploadFileInfo,
        { timeout: 60000 }
      );

      return result;
    },

    // 删除file
    async delete(id: string, config: AxiosRequestConfig = {}) {
      return api.delete(`attachment/chipcoo/${id}`, config);
    },

    // 获取分片上传策略
    async getSlicePolicy(params: ReferenceParams & { filename: string }, config: AxiosRequestConfig = {}) {
      return api.get('attachment/chipcoo/slice-policy', { ...config, params });
    }
  };
}
// tslint:disable:max-file-line-count
