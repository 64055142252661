import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Collapse } from 'antd';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { noop } from '@chipcoo/fe-utils';

import styles from './style.module.less';
import { GroupTitle } from './GroupTitle';
import MemberCard from './MemberCard';

import { SetRemarkNameParams } from 'src/models/friend/interface';
import { RootDispatch, RootState } from 'src/store';

interface IMemberGroup {
  title: string;
  members: User[];
  identifier?: string; // 唯一标示符
}

type Fn = (identifier: string) => any;

export interface OperateProps {
  onEdit: Fn;
  onRemove: Fn;
  renderEdit?: boolean;
}

interface OwnProps extends OperateProps {
  memberGroups: IMemberGroup[];
  emptyGroupNode?: React.ReactNode;
  cardExtra?: (user: User) => React.ReactNode;
  afterCreateChatRoom?: (roomId: string) => any;
  popoverCardClassName?: string;
}

interface StateProps {
  profile?: User;
}

interface DispatchProps {
  createChatRoom: (userId: UserId) => any;
  setRemarkName: (arg: SetRemarkNameParams) => any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface States {}

const { Panel } = Collapse;

export type MemberContextParams = DispatchProps & StateProps & {
  cardExtra?: (user: User) => React.ReactNode;
  afterCreateChatRoom?: (roomId: string) => any;
};

export const MemberOperateContext = React.createContext<MemberContextParams>({
  createChatRoom: noop,
  afterCreateChatRoom: noop,
  setRemarkName: noop,
});

class MemberTree extends PureComponent<Props, States> {

  @bind
  renderMemberCard(member: User) {
    const { popoverCardClassName } = this.props;

    return (<MemberCard key={member._id} popoverCardClassName={popoverCardClassName} userInfo={member} />);
  }

  @bind
  renderMembers(memberGroup: IMemberGroup, index: number) {
    const { title, members, identifier } = memberGroup;
    const { onEdit, onRemove, renderEdit } = this.props;
    const { length } = members;
    return (
      <Panel
        key={title}
        className={classnames('member-group')}
        header={<GroupTitle
          onEdit={onEdit}
          onRemove={onRemove}
          renderEdit={renderEdit}
          count={length}
          index={index}
          title={title}
          identifier={identifier || title}
        />}
      >
        {members.length ? members.map(this.renderMemberCard) : this.renderEmpty()}
      </Panel>
    );
  }

  renderEmpty() {
    const { emptyGroupNode = '暂无成员' } = this.props;
    return <p className="member-empty">{emptyGroupNode}</p>;
  }

  render() {
    const { memberGroups, createChatRoom, afterCreateChatRoom, setRemarkName, profile, cardExtra } = this.props;
    const { Provider } = MemberOperateContext;
    return (
      <Provider value={{ createChatRoom, afterCreateChatRoom, setRemarkName, profile, cardExtra }}>
        <Collapse
          className={classnames(styles.memberTree, 'member-tree-collapse cell thin-scroll y-scroll')}
          bordered={false}
        >
          {memberGroups.map(this.renderMembers)}
        </Collapse>
      </Provider>
    );
  }
}

const mapState = ({ session: { profile } }: RootState) => ({
  profile,
});

const mapDispatch = ({ friend: { patchFriendRemarkName, goChat } }: RootDispatch) => ({
  createChatRoom: goChat,
  setRemarkName: patchFriendRemarkName,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch as any)(MemberTree);
