// import { uniq, sortBy } from 'lodash';
import { getMomentTime } from '@chipcoo/fe-utils';

export const handleMonitorHistoryList = (payload: any[] = []) => {
  return payload.map(it => {
    const {
      lastAlert,
      product
    } = it;

    return {
      ...it,
      lastAlertTime: getMomentTime(lastAlert?.createdAt, 'YYYY-MM-DD HH:mm:ss'),
      productName: `${product?.customerName}(${product?.chipcooName})`,
    };
  });
};

export const handleMonitorHistoryDetail = (payload) => {

  return {
    ...payload,
  };
};
