import * as React from 'react';
import { Divider, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EnumNtoStatus, EnumNtoType } from '@chipcoo/constant';

export type NtoStatusData = {
  _id: string;
  status?: EnumNtoStatus;
  isSetDisabled?: boolean;
};

interface Option {
  openModal: (id: string, ntoType: EnumNtoType) => void;
  patchNtoStatus: (data: NtoStatusData) => void;
}

const operation = (option: Option) => ({
  dataIndex: 'operate',
  key: 'operate',
  title: '操作',
  render: (text, record) => {
    const { _id, type, status, tapeOutDate, rtoDate } = record;

    const openModal = (e) => {
      option.openModal(_id, type);
    };

    const renderDisabledEnter = () => {
      if (status !== EnumNtoStatus.canceled && status !== EnumNtoStatus.audit) {
        let [title, label, isSetDisabled] = [
          '是否禁用该产品?',
          '禁用',
          true,
        ];

        if (status === EnumNtoStatus.disabled) {
          title = '是否取消禁用该产品?';
          label = '取消禁用';
          isSetDisabled = false;
        }

        return (
          <>
            <Divider type="vertical" />

            <Popconfirm
              title={title}
              onConfirm={() => option.patchNtoStatus({
                _id,
                isSetDisabled
              })}
            >
              <a>{label}</a>
            </Popconfirm>
          </>
        );
      }

      return null;
    };

    const renderCancelEnter = () => {
      const currentDate: any = new Date();
      const tod: any = new Date(tapeOutDate);
      const rtod: any = new Date(rtoDate);
      const init = tod - currentDate > 0 && type === EnumNtoType.init;
      const rev = rtod - currentDate > 0 && type === EnumNtoType.rev;

      if ((init || rev) && status !== EnumNtoStatus.canceled) {
        return (
          <>
            <Divider type="vertical" />

            <Popconfirm
              title="是否要取消该产品?"
              onConfirm={() => option.patchNtoStatus({
                _id,
                status: EnumNtoStatus.canceled
              })}
            >
              <a>取消</a>
            </Popconfirm>
          </>
        );
      }

      return null;
    };

    return (
      <>
        <a onClick={openModal}>
          查看
        </a>

        {renderDisabledEnter()}

        {renderCancelEnter()}
      </>
    );
  }
});

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Customer Product Name',
    },
    {
      dataIndex: 'chipcooName',
      key: 'chipcooName',
      title: '芯翼产品名',
    },
    {
      dataIndex: 'factoryName',
      key: 'factoryName',
      title: 'Fab',
    },
    {
      dataIndex: 'technology',
      key: 'technology',
      title: 'Technology',
    },
    {
      dataIndex: 'tapeOutDateText',
      key: 'tapeOutDateText',
      title: 'Tapeout Date',
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '状态',
    },
    operation(option)
  ];
};

export const getRevTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Customer Product Name',
    },
    {
      dataIndex: 'rtoDateText',
      key: 'rtoDateText',
      title: 'RTO Date',
    },
    {
      dataIndex: 'rtoLayer',
      key: 'rtoLayer',
      title: 'RTO layer',
    },
    {
      dataIndex: 'maskVersion',
      key: 'maskVersion',
      title: 'Mask Version',
    },
    {
      dataIndex: 'statusText',
      key: 'statusText',
      title: '状态',
    },
    operation(option)
  ];
};
