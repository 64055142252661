import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

import { mpwApi } from 'src/services/net';

// const regRex = /^[a-zA-Z0-9]{0,30}$/;

export function uniqueProductNameValidator(text?: string) {
  let _initialValue: string | undefined;
  let status: FormStatus;
  let timeoutId: any = null;
  let prevCallback: any = null;

  const validator = [
    {
      transform(val: string) { return (val || '').trim(); },
      validator: (_, value, callback) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          prevCallback && prevCallback();
        }
        prevCallback = callback;

        // 格式校验
        // if (!regRex.test(value)) {
        //   return callback(`请输入最大长度为30的${text || '产品名'}，只包含字母或数字`);
        // }

        // value等于初始值(即编辑的时候需要排除掉自身的值)
        if (!value || value.trim() === _initialValue || status === 'preview') {
          return callback();
        }

        timeoutId = setTimeout(async () => {
          try {
            const { data } = await mpwApi.checkIsUsedName({ name: value.trim() });

            if (data?.isNameUsed) return callback('该产品名已存在！');
          } catch (e) {
            console.log(e);
          }
          callback();
          timeoutId = null;
        }, 400);
      },
    }
  ];

  return function(initialValue: string | undefined, curStatus: FormStatus) {
    status = curStatus;
    _initialValue = initialValue;

    return validator;
  };
}
