import { ImMessage } from './chatPanel.type';
import { UserModel } from '../user/interface';

/**
 * 从服务器获取到的聊天记录，不管是history还是新发送或者删除消息后的返回值，sender都是一个number，这里改为string
 * @param message
 * @constructor
 */
export const messageSenderNum2Str = (message) => ({ ...message, sender: `${message.sender}` });

/**
 * 主要是chatPanel使用，将message中的sender给展开成一个User对象，此时会将之前被转换成string的sender._id给还原成number
 * @param senderId
 * @param roomUsers
 */
export const spreadSender = (senderId: UserId, users: UserModel['list']) => {
  const sender = users.byId[senderId];

  if (!sender) { return senderId; }

  return { ...sender, _id: parseInt(sender._id, 10) };
};

export const updateRoomLastMessage = (users: UserModel['list'], messageData: ImMessage, updateFn) => {
  // 注意这里的sender是一个string
  const { sender } = messageData;
  const lastMessageData = {
    ...messageData,
    sender: spreadSender(sender, users)
  };

  updateFn(lastMessageData);
};
