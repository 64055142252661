/**
 * Created by marvin on 18/9/12.
 */

import * as React from 'react';
import { Card, Row, Col, Avatar, Spin } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';

import style from './index.module.less';
import applicationList, { fillAppList } from './cardGrid';

import { Ellipsis } from 'src/components';
import { HoverScrollBarContainer } from 'src/components';
import { compilePath, getCurrentRoute } from 'src/components/HoneyRouter';

interface Props extends RouteComponentProps<any> {
  loading: boolean;
}

class OrgDetail extends React.PureComponent<Props> {
  state = {
    loading: false,
  };

  constructor(props: Props) {
    super(props);

    if (applicationList.some(it => !it.route)) {
      fillAppList();
    }
  }

  // TODO: Fix this component and then add componentWillUnmount hook.
  // componentWillUnmount() {
  //   console.log('Org wrapper: will unmount');
  //   store.dispatch({ type: 'org/setOrgId', payload: '' });
  //   sessionStorage.setItem(LOCAL_ORG_ID_KEY, '');
  // }

  render () {
    const { match: { params } } = getCurrentRoute()!;
    return (
      <HoverScrollBarContainer className={style.appList}>
        <Spin spinning={this.state.loading}>
          <Row gutter={24}>
            {
              applicationList.map((item) => (
                <Col key={item.id} span={8} className={style.app}>
                  <Link to={compilePath({ name: item.route, params })} onClick={item.onClick}>
                    <Card bordered={false}>
                      <Card.Meta
                        avatar={<Avatar size={48} src={item.logo} />}
                        title={item.title}
                        description={(
                          <Ellipsis lines={3} className={style.description}>
                            {item.description}
                          </Ellipsis>
                        )}
                      />
                    </Card>
                  </Link>
                </Col>
              ))
            }
          </Row>
        </Spin>
      </HoverScrollBarContainer>
    );
  }
}

export default OrgDetail;
