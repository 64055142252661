/**
 * 用来根据userId从chatPanel的model中获取对应的用户信息
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'src/store';

interface OwnProps {
  userId: UserId;
}
interface StateProps {
  userInfo: User;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;

export default <T extends OwnProps = OwnProps>(
  Component: (React.ComponentClass<T> | React.SFC<T>)
) => {
  type Props = T & P;

  class HandleStatelessComponent extends React.PureComponent<Props> {
    render() {
      return <Component {...this.props} />;
    }
  }

  const mapState = ({ user: { list: { byId } } }: RootState, props: P): StateProps => ({
    userInfo: byId[props.userId]
  });

  // Fix me: type define
  return connect<StateProps, DispatchProps, OwnProps>(mapState)(
    HandleStatelessComponent as any
  );
};
