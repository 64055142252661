import React, { PureComponent } from 'react';
import { Icon, message } from 'antd';
import { bind } from 'lodash-decorators';
import lrz from 'lrz';
import to from 'await-to-js';
import Upload from './Upload';
import log from 'loglevel';
import { IMG, IMG_CAN_COMPRESS, createObjectURL, getFileExtension } from '@chipcoo/fe-utils';

import { UploadAttachmentProps, RcFileEnhance, UploadFileEnhance } from '../interface';

import { UA, isProd } from 'src/utils';
import { UploadFileSVG } from 'src/components';
import prettyBytes from 'src/local_modules/prettyBytes';

export type CompressStatus = 'start' | 'done' | 'error';

interface Props extends UploadAttachmentProps {
  uploadRef?: React.RefObject<Upload>;
  onCompress?: (rawFile: UploadFileEnhance, status: CompressStatus) => any;
}

interface State {}

const lrzOptions: any = {
  quality: 0.95,
  compressSize: 0,
};

if (!(UA.iOS || UA.oldIOS)) {
  lrzOptions.width = Infinity;
}

function promisify(result: any) {
  const promise = new Promise((resolve) => resolve(result));
  try {
    if (result && result.then) {
      return result;
    }

    return promise;
  } catch (e) {
    log.error('promisify failed', e);
    return promise;
  }
}

export class Uploader extends PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    multiple: true,
    showUploadList: false,
    compress: true,
    onCompress: () => '',
  };

  @bind
  async onBeforeUpload(file: RcFileEnhance, fileList: UploadFileEnhance[]) {
    const { compress, beforeUpload, limit, sizeLimit } = this.props;
    const { size } = file;

    if (limit && fileList.length >= limit) {
      message.error(`文件数量不能超过${limit}个`);
      return false;
    }

    if (sizeLimit && size > sizeLimit) {
      message.error(`单个文件大小不能超过${prettyBytes(sizeLimit)}`);
      return false;
    }

    const continueUpload = beforeUpload ? beforeUpload(file, fileList) : true;

    file.ext = getFileExtension(file.name);
    if (!compress || !IMG_CAN_COMPRESS.test(file.ext || '')) {
      if (IMG.test(file.ext || '')) {
        file.thumbUrl = createObjectURL(file);
      }

      return continueUpload;
    }

    const { onCompress } = this.props;

    onCompress!(file, 'start');
    const lrzRst = to<any>(lrz(file, Object.assign({}, lrzOptions, compress)));
    const [ctnResult, [err, rst]] = await Promise.all([promisify(continueUpload), lrzRst]);

    let nextFile = file;

    if (!err) {
      nextFile = rst.file;
      ['lastModified', 'lastModifiedDate', 'name', 'uid', 'ext'].forEach(key => {
        try {
          nextFile[key] = file[key];
        } catch (e) {
          console.error(e);
          !isProd && console.log(key, nextFile, e);
        }
      });
      nextFile.thumbUrl = createObjectURL(rst.file);
      onCompress!(file, 'done');
    } else {
      onCompress!(file, 'error');
    }

    return ctnResult && nextFile;
  }

  get headers(): any {
    const { headers } = this.props;
    return Object.assign({
      'X-Requested-With': null,
    }, headers || {});
  }

  render() {
    const { uploadRef, ...uploadProps } = this.props;
    return (
      <Upload
        multiple={true}
        {...uploadProps}
        showUploadList={false}
        headers={this.headers}
        beforeUpload={this.onBeforeUpload}
        ref={uploadRef}
      >
        <div
          className="upload-icon-ref"
        >
          <Icon component={UploadFileSVG} className="uploader" />
        </div>
      </Upload>
    );
  }
}

export default Uploader;
