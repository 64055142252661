import React, { ComponentType } from 'react';
import { isEqual } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import { CardTableV2, selectOriginOptionData } from '@chipcoo/hanayo';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTable/Addon/EnhancedTable';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';

import { Actions, connectDecorator, RootState } from 'src/store';

const { EnhancedTable } = CardTableV2;

interface OwnProps {
  productId: string;
  productModel: EnumProductModel;
  status: FormStatus;
  initialFormData: any;
  fetchProduct: any;
  value?: any;
  onChange?: (value: any) => void;
}

interface StateProps {
  orderTemplate: any;
}
interface DispatchProps {
  getOrderTemplateList: (params: any) => void;
}
type Props = OwnProps & StateProps & DispatchProps;

interface State {
  tableLoading: boolean;
}

const columns = [
  {
    title: '订购名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '包装方式',
    dataIndex: 'packingMethod',
    key: 'packingMethod',
  }
];

@connectDecorator(
  ({ product: { orderTemplate } }: RootState) => ({ orderTemplate }),
  ({ product: { getOrderTemplateList } }: Actions) => ({ getOrderTemplateList })
)
class OrderTemplateList extends React.PureComponent<Props, State> {
  private cardTableController: ICardTableController;

  constructor(props: Props) {
    super(props);

    this.state = {
      tableLoading: false,
    };
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { productId } = this.props;

    if (productId && !isEqual(productId, prevProps.productId)) {
      this.reloadTable();
    }
  }

  getTableData = async (params: GetTableDataParams) => {
    const { productId, productModel, getOrderTemplateList } = this.props;
    const { formData, ...restData } = params;
    let optionsInfo = selectOriginOptionData.getCachedData().getProductByProductModel;
    const info = (optionsInfo || []).find(o => o?._id === productId);

    const queryParams = {
      ...restData,
      ...formData,
      product: productId,
      productModel,
      packing: info?.packing
    };

    try {
      this.setState({ tableLoading: true });

      await getOrderTemplateList(queryParams);
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false });
  }

  reloadTable = () => {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  rowDataOnChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    const { onChange } = this.props;

    onChange && onChange(selectedRows?.[0]);
  }

  render () {
    const { orderTemplate, initialFormData, status, value } = this.props;
    const { tableLoading } = this.state;
    let rowSelection: any = {
      type: 'radio',
      onChange: this.rowDataOnChange,
      selectedRowKeys: value?.key ? [value?.key] : [],
    };
    let [dataSource, total, showPagination]: any[] = [orderTemplate?.list, orderTemplate?.count, undefined];

    if (status === 'preview') {
      rowSelection = undefined;

      dataSource = [{ name: initialFormData?.name, packingMethod: initialFormData?.packingMethod, key: 1 }];

      showPagination = false;
    }

    return (
      <div className="wrap-content">
        <CardTableV2
          getCardTableController={controller => this.cardTableController = controller}
        >
          <EnhancedTable
            getTableData={this.getTableData}
            columns={columns}
            dataSource={dataSource}
            total={total}
            clickMention={true}
            tableLoading={tableLoading}
            rowSelection={rowSelection}
            showPagination={showPagination}
          />
        </CardTableV2>
      </div>
    );
  }
}

export default OrderTemplateList as ComponentType<OwnProps>;
