import _ from 'lodash';
import { EnumProcessClass } from '@chipcoo/constant';
import {
  DividerFormItemConfig,
  FileFormItemConfig,
  FormItemConfig,
  InputFormItemConfig,
  NumberFormItemConfig,
  SelectFormItemConfig
} from '@chipcoo/hanayo/lib/FormCreator';

import { attachmentApi } from 'src/services/net';

const fileDefaultProps = {
  getFileDetail: fileId => fileId && attachmentApi.getDetail(fileId),
  getUrl: fileId => fileId && attachmentApi.download({ attachmentId: fileId })
};

/*************************** hidden-form-item start **********************************/
const processCodeFormItem: InputFormItemConfig = {
  label: 'processCode',
  type: 'input',
  dataKey: 'processCode',
  colClassName: 'hidden-form-item',
};
/*************************** hidden-form-item end **********************************/

/*************************** 封装成品 **********************************/
const getAssemblyRenderType = (processClass: EnumProcessClass) => {
  return processClass === EnumProcessClass.assembly ? 'normal' : 'none';
};

const assemblyDivider = (): DividerFormItemConfig => ({
  type: 'divider',
});

const getRequired = (_this, key) => {
  const { formData } = _this;
  return !_.isEmpty(_.get(formData, `scheme.${key}.attachmentId`));
};

// Bonding图
const bondingDiagramFile = (): FileFormItemConfig => ({
  label: 'Bonding图',
  type: 'file',
  dataKey: 'scheme.bondingDiagram.attachmentId',
  fileType: ['image', 'pdf', 'msword'],
  typeErrMsg: '请上传图片、pdf或word文件',
  props: fileDefaultProps,
});

// Bonding图编号
const bondingDiagramSerial = (): InputFormItemConfig => ({
  label: 'Bonding图编号',
  type: 'input',
  dataKey: 'scheme.bondingDiagram.serial',
  required() {
    return getRequired(this, 'bondingDiagram');
  },
});

// Bonding图版本
const bondingDiagramRev = (): NumberFormItemConfig => ({
  label: 'Bonding图版本',
  type: 'number',
  dataKey: 'scheme.bondingDiagram.rev',
  precision: 0,
  required() {
    return getRequired(this, 'bondingDiagram');
  },
});

// 打印图
const markingDrawingFile = (): FormItemConfig => ({
  label: '打印图',
  type: 'file',
  dataKey: 'scheme.markingDrawing.attachmentId',
  fileType: ['image', 'pdf', 'msword'],
  typeErrMsg: '请上传图片、pdf或word文件',
  props: fileDefaultProps,
});

// 打印图编号
const markingDrawingSerial = (): InputFormItemConfig => ({
  label: '打印图编号',
  type: 'input',
  dataKey: 'scheme.markingDrawing.serial',
  required() {
    return getRequired(this, 'markingDrawing');
  },
});

// 打印图版本
const markingDrawingRev = (): NumberFormItemConfig => ({
  label: '打印图版本',
  type: 'number',
  dataKey: 'scheme.markingDrawing.rev',
  precision: 0,
  required() {
    return getRequired(this, 'markingDrawing');
  },
});

// 外形图
const packageOutlineDrawingFile = (): FileFormItemConfig => ({
  label: '外形图',
  type: 'file',
  dataKey: 'scheme.packageOutlineDrawing.attachmentId',
  fileType: ['image', 'pdf', 'msword'],
  typeErrMsg: '请上传图片、pdf或word文件',
  props: fileDefaultProps,
});

// 外形图编号
const packageOutlineDrawingSerial = (): InputFormItemConfig => ({
  label: '外形图编号',
  type: 'input',
  dataKey: 'scheme.packageOutlineDrawing.serial',
  required() {
    return getRequired(this, 'packageOutlineDrawing');
  },
});

// 外形图版本
const packageOutlineDrawingRev = (): NumberFormItemConfig => ({
  label: '外形图版本',
  type: 'number',
  dataKey: 'scheme.packageOutlineDrawing.rev',
  precision: 0,
  required() {
    return getRequired(this, 'packageOutlineDrawing');
  },
});

// 环保报告
const environmentalReport = (): FileFormItemConfig => ({
  label: '环保报告',
  type: 'file',
  dataKey: 'scheme.environmentalReport',
  props: fileDefaultProps,
});

const ftTestRequirement: SelectFormItemConfig = {
  label: '测试需求',
  type: 'select',
  dataKey: 'scheme.ft.testRequirement',
  optionKey: 'ftTestRequirement',
  props: {
    mode: 'multiple'
  },
  renderType() {
    const { processCode } = this.formData;
    const bool = processCode === 'AT' || processCode === 'ATB' ||
      processCode === 'BR' || processCode === 'FT' || processCode === 'FTB';

    return bool ? 'normal' : 'none';
  }
};

const dieAttachMethods: SelectFormItemConfig = {
  label: '装片方式',
  type: 'select',
  dataKey: 'scheme.dieAttachMethods',
  optionKey: 'dieAttachMethod',
  props: { mode: 'multiple' },
  renderType() {
    const { processCode } = this.formData;
    const bool = processCode === 'AS' || processCode === 'AT' || processCode === 'ATB';

    return bool ? 'normal' : 'none';
  }
};

const getBomRenderType = (_this) => {
  const packageType = _.get(_this.formData, 'productInfo.packageType.packageType');
  return packageType === '引线框架' || packageType === '引线基板' ? 'normal' : 'none';
};

const dieAttachMaterial: SelectFormItemConfig = {
  label: '装片胶',
  type: 'select',
  dataKey: 'scheme.bom.dieAttachMaterial',
  optionKey: 'bomDieAttachMaterial',
  renderType() {
    return getBomRenderType(this);
  }
};

const getDieAttachMaterial = (_this, key: string) => {
  return _this.formData?.scheme?.bom?.dieAttachMaterial === key ? 'normal' : 'none';
};

const dieAttachMaterialProvider = (key: string): InputFormItemConfig => ({
  label: `${key}供应商`,
  type: 'input',
  dataKey: `scheme.bom.dieAttachMaterialProvider_${key}`,
  renderType() {
    return getDieAttachMaterial(this, key);
  }
});

const dieAttachMaterialModel = (key: string): InputFormItemConfig => ({
  label: `${key}型号`,
  type: 'input',
  dataKey: `scheme.bom.dieAttachMaterialModel_${key}`,
  renderType() {
    return getDieAttachMaterial(this, key);
  }
});

const frameProvider: InputFormItemConfig = {
  label: '框架供应商',
  type: 'input',
  dataKey: 'scheme.bom.frameProvider',
  renderType() {
    return getBomRenderType(this);
  }
};

const frameModel: InputFormItemConfig = {
  label: '框架型号',
  type: 'input',
  dataKey: 'scheme.bom.frameModel',
  renderType() {
    return getBomRenderType(this);
  }
};

const wireProvider: InputFormItemConfig = {
  label: '线材供应商',
  type: 'input',
  dataKey: 'scheme.bom.wireProvider',
  renderType() {
    return getBomRenderType(this);
  }
};

const wireModel: InputFormItemConfig = {
  label: '线材型号',
  type: 'input',
  dataKey: 'scheme.bom.wireModel',
  renderType() {
    return getBomRenderType(this);
  }
};

const plasticPackageProvider: InputFormItemConfig = {
  label: '塑封料供应商',
  type: 'input',
  dataKey: 'scheme.bom.plasticPackageProvider',
  renderType() {
    return getBomRenderType(this);
  }
};

const plasticPackageModel: InputFormItemConfig = {
  label: '塑封料型号',
  type: 'input',
  dataKey: 'scheme.bom.plasticPackageModel',
  renderType() {
    return getBomRenderType(this);
  }
};

const assembly = [
  processCodeFormItem,
  assemblyDivider(),
  bondingDiagramFile(),
  bondingDiagramSerial(),
  bondingDiagramRev(),
  markingDrawingFile(),
  markingDrawingSerial(),
  markingDrawingRev(),
  packageOutlineDrawingFile(),
  packageOutlineDrawingSerial(),
  packageOutlineDrawingRev(),
  assemblyDivider(),
  environmentalReport(),
  {
    type: 'divider',
    renderType() {
      const { processCode } = this.formData;
      const bool = processCode === 'AS' || processCode === 'AT' || processCode === 'ATB' ||
        processCode === 'BR' || processCode === 'FT' || processCode === 'FTB';

      return bool ? 'normal' : 'none';
    }
  },
  ftTestRequirement,
  dieAttachMethods,
  {
    type: 'divider',
    renderType() {
      return getBomRenderType(this);
    }
  },
  dieAttachMaterial,
  dieAttachMaterialProvider('DAF膜'),
  dieAttachMaterialModel('DAF膜'),
  dieAttachMaterialProvider('导电胶'),
  dieAttachMaterialModel('导电胶'),
  dieAttachMaterialProvider('不导电胶'),
  dieAttachMaterialModel('不导电胶'),
  dieAttachMaterialProvider('半烧结银'),
  dieAttachMaterialModel('半烧结银'),
  frameProvider,
  frameModel,
  wireProvider,
  wireModel,
  plasticPackageProvider,
  plasticPackageModel,
];

const assemblyFormConfig = assembly.map(it => {

  if (it?.renderType) return it;

  return {
    ...it,
    renderType() {
      return getAssemblyRenderType(this.formData.processClass);
    }
  };
});

export { assemblyFormConfig };
// tslint:disable-next-line: max-file-line-count
