import { ColumnProps } from 'antd/lib/table';

function defaultRender(val: any, key: string) {
  if (!val) {
    return '/';
  }

  if (key === 'waferId') {
    return '#' + val;
  }

  return val;
}

export function getColumnProps(isAssembly: boolean) {
  if (isAssembly) {
    return [
      {
        title: 'Bin类型',
        dataIndex: 'binType',
        render: defaultRender,
      },
      {
        title: 'Bin数量',
        dataIndex: 'amount',
        render: defaultRender
      }
    ];
  }

  return [
    {
      title: '片号',
      dataIndex: 'waferId',
      render: text => defaultRender(text, 'waferId'),
    },
    {
      title: '良品',
      dataIndex: 'good',
      render: defaultRender,
    },
    {
      title: '不良品',
      dataIndex: 'bad',
      render: defaultRender,
    },
  ].filter(it => typeof it !== 'boolean') as ColumnProps<any>[];
}
