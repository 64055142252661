import * as React from 'react';
import _ from 'lodash';
import { Checkbox } from 'antd';

import { TableCheckboxCtx } from './context';

interface Props {
  record: any;
}

interface State {
}

class TableCheckBoxGroup extends React.PureComponent<Props, State> {
  static contextType = TableCheckboxCtx;

  constructor(props: Props) {
    super(props);

    this.state = {
    };
  }

  onChange = () => {
    const { record } = this.props;
    const key = record?.key;
    const { changeCheck } = this.context;

    changeCheck && changeCheck({
      itemType: key?.indexOf('_') >= 0 ? 'sub' : 'group',
      key
    });
  }

  renderCell = () => {
    const { record } = this.props;
    // 有children就只有在children里item的checked都为true时，group的checked才为true，否则直接看checked

    return (
      <Checkbox
        checked={record?.children ? _.isEmpty(record.children?.filter(sub => !sub.checked)) : record?.checked}
        indeterminate={
          record?.children
            // group的children只要至少有一个checked为true且至少有一个checked为false，则indeterminate就为true
            ? record.children.find(sub => sub.checked) && record.children.find(sub => !sub.checked)
            : false
        }
        onChange={() => this.onChange()}
        disabled={record.isFrozen}
      />
    );
  }

  render () {
    return (
      <TableCheckboxCtx.Consumer>
        {this.renderCell}
      </TableCheckboxCtx.Consumer>
    );
  }
}

export default TableCheckBoxGroup;
