/**
 * 消息列表下面的批量处理操作条
 */
import * as React from 'react';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';

import styles from './operateBar.module.less';
import { RootDispatch } from 'src/store';
import { InitialMsgListCtx } from './index';
import { NotifyFetchParams } from 'src/models/notification/interface';

interface OwnProps {
  checkBoxStatus: InitialMsgListCtx;
  focusNotifyId: string | undefined;
  delBatchLoading: (loading: boolean) => void;
  loadMore: (arg: NotifyFetchParams) => any;
  resetUrl: () => void;
  notifyIds: string[];
  onCheckAll: (e: any | false) => void;
}
interface StateProps {}
interface DispatchProps {
  markAsRead: (notifyIds: string[]) => void;
  deleteNotifications: (notifyIds: string[]) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class OperateBar extends React.PureComponent<P, S> {
  // 批量删除
  handleDelBatch = async (e) => {
    e.stopPropagation();

    const { onCheckAll, checkBoxStatus, loadMore } = this.props;
    const { selected } = checkBoxStatus;

    if (!selected || !selected.length) { return; }

    const { delBatchLoading, deleteNotifications, resetUrl, focusNotifyId } = this.props;

    delBatchLoading(true);

    await deleteNotifications(selected);

    // 批量删除消息的时候，如果正好删除了一条正在查看的消息，那么清掉路由
    focusNotifyId && selected.includes(focusNotifyId) && resetUrl();
    console.log('try load more');
    loadMore({ limit: Math.min(20, selected.length) });
    onCheckAll(false);

    delBatchLoading(false);
  }

  // 批量标记已读
  handleMarkReadBatch = async (e) => {
    e.stopPropagation();

    const { onCheckAll, checkBoxStatus } = this.props;
    const { selected } = checkBoxStatus;

    if (!selected || !selected.length) { return; }

    await this.props.markAsRead(selected);

    onCheckAll(false);
  }

  render() {
    const { onCheckAll, checkBoxStatus } = this.props;
    const { allSelected, indeterminate } = checkBoxStatus;

    return (
      <div className={styles.operateBarWrapper}>
        <Checkbox
          checked={allSelected}
          indeterminate={indeterminate}
          onChange={onCheckAll}
          className={styles.checkbox}
        >
          全选
        </Checkbox>

        <div className={styles['del-batch']} onClick={this.handleDelBatch}>
          <i className="c-icon icon-trash" />
          <p>删除</p>
        </div>

        <div className={styles.unread} onClick={this.handleMarkReadBatch}>
          <i className="c-icon icon-eye" />
          <p>标记已读</p>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({ notification: { markAsRead, deleteNotifications } }: RootDispatch) => ({
  markAsRead, deleteNotifications
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(OperateBar);
