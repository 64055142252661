/**
 * 全局的右侧好友栏
 */
// tslint:disable:max-file-line-count
import * as React from 'react';
import { bind } from 'lodash-decorators';
import { message } from 'antd';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import to from 'await-to-js';
import { trim, isEqual } from 'lodash';
import { BasicModal } from '@chipcoo/hanayo';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';
import log from 'loglevel';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './index.module.less';
import SiderHeader from './SiderHeader';

import { P as AddByAccountProps } from 'src/components/AddByAccount';
import { mapUsers } from 'src/utils/user';
import { friendPicker } from 'src/models/friend';
import store, { RootState, connect } from 'src/store';

import GroupName, { GroupName as GN } from 'src/pages/Cooperation/components/GroupName';

import DeleteFriend from './DeleteFriend';
import { AddByAccount } from 'src/components';
import MemberTree from 'src/containers/MemberTree';
import AddMember from 'src/containers/MemberTable/AddMember';
import SelectMember from 'src/containers/SelectMemberByGroup';
import { FriendUser, FriendCategory, ModifyFriendCategory } from 'src/models/friend/interface';

interface OwnProps {
  onClose: () => void;
}
interface StateProps {
  profile?: User;
  friends: FriendUser[];
  friendCategory: NormalizedData<FriendCategory>;
}

interface DispatchProps {
  delCategory: (categoryId: string) => void;
  applyFriend: (accounts: string[]) => void;
  deleteFriend: (userId: UserId) => any;
  addNewCategory: (categoryInfo: Partial<FriendCategory>) => void;
  editCategory: (modifyInfo: ModifyFriendCategory) => void;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  addFriendModalVisible: boolean;
  modalVisible: boolean;
  relationModalVisible: boolean;
  editType: 'create' | 'edit';
  selectedMembers: UserId[];
  categoryId: string;
  friendUserIds: UserId[];
  memberGroupProps: {
    value: string;
    disabledValues: string[];
  };
}

const friendsGroupSelector = createSelector(
  (props: P) => props.friends,
  (props: P) => props.friendCategory,
  (friends, category) => {
    const { allIds, byId } = category;

    const friendsGroup = [
      {
        title: '全部好友',
        members: friends,
        identifier: '',
      }
    ];

    allIds.forEach(id => {
      const group = byId[id];
      friendsGroup.push({
        title: group.name,
        members: mapUsers(group.friends, friends),
        identifier: group._id,
      });
    });

    return friendsGroup;
  }
);

type AddByAccountWithoutSelfProps = Omit<AddByAccountProps, 'form'> & {
  profile?: User;
};

const AddByAccountWithoutSelf = (props: AddByAccountWithoutSelfProps) => {
  const { profile, ...resetProps } = props;
  const { email, phone } = profile!;
  const validationRule = [{
    validator: (rule, value, callback) => {
      const val = trim(value);

      if (val === email || val === phone) {
        callback('不能添加自己为好友');
        return;
      }
      callback();
    }
  }];

  return <AddByAccount {...resetProps} extraAccountValidationRule={validationRule} />;
};

function getFriendUserIds(friends: FriendUser[]) {
  return friends.map(f => f._id);
}

class SiderFriend extends React.PureComponent<P, S> {
  $groupNameRef = React.createRef<GN>();
  static getDerivedStateFromProps(nextProps: P, prevState: S) {
    const nextState: Partial<S> = {};
    const friendIds = getFriendUserIds(nextProps.friends);

    if (!isEqual(friendIds, prevState.friendUserIds)) {
      nextState.friendUserIds = friendIds;
    }

    return nextState;
  }

  constructor(props: P) {
    super(props);

    this.state = {
      modalVisible: false,
      relationModalVisible: false,
      addFriendModalVisible: false,
      categoryId: '',
      editType: 'create',
      selectedMembers: [],
      friendUserIds: getFriendUserIds(props.friends),
      memberGroupProps: {
        value: '',
        disabledValues: [],
      }
    };
  }

  openAddNewModal = () => this.setState({ addFriendModalVisible: true });

  closeAddNewModal = () => this.setState({ addFriendModalVisible: false });

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind
  openRelationModal() {
    this.setState({ relationModalVisible: true });
  }

  @bind
  closeModal() {
    this.setState({
      selectedMembers: [],
      modalVisible: false,
      relationModalVisible: false,
    });
  }

  @bind
  syncSelectMembers(selectedMembers: UserId[]) {
    this.setState({ selectedMembers });
  }

  @bind
  async removeFriendGroup(groupId: string) {
    const { delCategory } = this.props;
    await delCategory(groupId);
  }

  @bind
  async applyFriend(...args: any[]) {
    try {
      await this.props.applyFriend.apply(null, args);
      message.success('好友请求已发送');
      return true;
    } catch (e) {
      message.error('好友请求发送失败');
      log.error('[applyFriend]: apply failed, accounts are', args[0], 'error is', e);
      return false;
    }
  }

  @bind
  renderRemoveFriend(user: User) {
    const { deleteFriend } = this.props;
    return <DeleteFriend user={user} deleteFriend={deleteFriend} />;
  }

  @bind
  openCreateModal() {
    this.setState({
      memberGroupProps: {
        value: '',
        disabledValues: this.totalGroupNames,
      },
      selectedMembers: [],
      editType: 'create',
      categoryId: '',
    }, this.openModal);
  }

  @bind
  openEditModal(id: string) {
    const { friendCategory: { byId } } = this.props;

    const group = byId[id];

    if (!group) {
      message.error('准备编辑分组失败，分组或许已被更改/移除');
      return;
    }

    const { friends, name } = group;

    this.setState({
      editType: 'edit',
      selectedMembers: friends,
      categoryId: id,
      memberGroupProps: {
        value: name,
        disabledValues: this.totalGroupNames.filter(it => it !== name),
      }
    }, this.openModal);
  }

  @bind
  async handleApplyByRelations(userIds: UserId[]) {
    const allUsers = store.getState().user.list.byId;
    const users = mapUsers(userIds, allUsers);
    const accounts = users.map(user => user.phone || user.email);

    return this.applyFriend(accounts);
  }

  @bind
  async handleEditMemberGroup(memberIds: string[]) {
    const { editType, categoryId } = this.state;
    const { editCategory, addNewCategory } = this.props;
    const groupNameComp = this.$groupNameRef.current;

    const [err, vals] = await to(groupNameComp!.tryGetValues());

    if (err) {
      return Promise.reject(err);
    }

    const isCreate = editType === 'create';

    const { groupName } = vals!;
    const form: Partial<FriendCategory> = {
      name: groupName,
      friends: memberIds,
    };

    if (isCreate) {
      return addNewCategory(form);
    }

    form._id = categoryId;

    return editCategory(form as ModifyFriendCategory);
  }

  get friendsByGroup() {
    return friendsGroupSelector(this.props);
  }

  get totalGroupNames() {
    const { friendCategory } = this.props;
    const { allIds, byId } = friendCategory;
    if (!friendCategory) {
      return [];
    }

    return allIds.map(it => byId[it].name);
  }

  render() {
    const {
      editType,
      modalVisible,
      friendUserIds,
      memberGroupProps,
      relationModalVisible,
      addFriendModalVisible,
    } = this.state;
    const { friends, profile } = this.props;

    return (
      <div className={classNames(styles.body, 'flex')}>

        <div className={styles.header}>
          <div className="title">
            我的好友
          </div>
          <SiderHeader
            addFriend={this.openAddNewModal}
            openGroupModal={this.openCreateModal}
            openRelationModal={this.openRelationModal}
          />
        </div>

        {/*全部的好友树*/}
        <MemberTree
          renderEdit={true}
          onRemove={this.removeFriendGroup}
          onEdit={this.openEditModal}
          memberGroups={this.friendsByGroup}
          cardExtra={this.renderRemoveFriend}
          emptyGroupNode="暂无好友"
          afterCreateChatRoom={this.props.onClose}
          popoverCardClassName={styles.friendMemberCard}
          // cardExtra={<RemoveFriend name={name!} userId={userId} closePopover={closePopover!} />}
        />

        {/*通过账号添加好友的弹框*/}
        <BasicModal
          visible={addFriendModalVisible}
          width={360}
          title="添加好友"
          closeModal={this.closeAddNewModal}
        >
          {(childProps: BasicModalChildrenProps) => (
            <AddByAccountWithoutSelf
              profile={profile}
              submitAccount={this.applyFriend}
              registerSubmitHandler={childProps.registerSubmitHandler}
            />
          )}
        </BasicModal>

        <SelectMember
          showFriends={false}
          visible={relationModalVisible}
          modalTitle={'人脉邀请'}
          onCancel={this.closeModal}
          disabledKeys={friendUserIds}
          onSubmit={this.handleApplyByRelations}
          value={this.state.selectedMembers}
          onChange={this.syncSelectMembers}
        />

        <AddMember
          visible={modalVisible}
          modalTitle={editType === 'create' ? '新建成员分组' : '编辑成员分组'}
          onCancel={this.closeModal}
          onSubmit={this.handleEditMemberGroup}
          users={friends}
          value={this.state.selectedMembers}
          onChange={this.syncSelectMembers}
        >
          {<GroupName wrappedComponentRef={this.$groupNameRef} {...memberGroupProps}/>}
        </AddMember>
      </div>
    );
  }
}

const mapState = ({ session: { profile }, user, friend: { friends: { byId }, friendCategory } }: RootState) => {
  const friends = friendPicker(user, byId);

  return { profile, friends, friendCategory };
};

// mapDispatch的类型莫名崩溃，急着修复bug，因此这里直接any掉了，理论上不会影响任何东西
const mapDispatch = ({ friend }: any) => ({
  delCategory: friend.deleteFriendCategory,
  applyFriend: friend.putApplyFriendByAccount,
  addNewCategory: friend.postNewFriendCategory,
  editCategory: friend.patchEditFriendCategory,
  deleteFriend: friend.deleteFriend,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(
  withRouter(SiderFriend)
);
