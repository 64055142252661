import HoneyRoute from './HoneyRoute';
export { default as HookRoute } from './HookRoute';
export * from './HoneyRoute';
export * from './CacheRoute';
export * from './CacheSwitch';

export * from './core/manager';

export {
  HoneyRoute,
};

export default HoneyRoute;
