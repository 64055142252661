import React, { PureComponent } from 'react';
import { Skeleton } from 'antd';
import { connect } from 'react-redux';

import { MessageAttachment } from 'src/components';
import { RootState } from 'src/store';
import { getResourceKey } from 'src/services/resource';

interface OwnProps {
  attachmentId: string;
  imgPreview: boolean;
  resource: IResource;
}

interface StateProps {
  attachment: ImAttachment | null;
}

type Props = OwnProps & StateProps;

interface State {
  attachment: ImAttachment | null;
}

class CommentAttachment extends PureComponent<Props, State> {
  state = {
    attachment: null,
  };

  render() {
    const { attachment } = this.props;
    const clsName = 'comment-attachment';

    return (
      <Skeleton loading={attachment === null} active={true} >
        {attachment && 
          <MessageAttachment 
            title="查看/下载附件"
            className={clsName} 
            attachments={[attachment]} 
            maxImageWidth={240}
            imgPreview={this.props.imgPreview}
          />
        }
      </Skeleton>
    );
  }
}

function mapState(state: RootState, props: Props) {
  const key = getResourceKey(props.resource);
  const comment = state.universeComment[key];
  if (!comment || !comment.attachments.byId[props.attachmentId]) {
    return {
      attachment: null,
    };
  }

  return {
    attachment: comment.attachments.byId[props.attachmentId], 
  };
}

export default connect<StateProps, {}, OwnProps>(mapState)(CommentAttachment);
