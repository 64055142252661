/**
 * Created by marvin on 18/8/20.
 */

import React from 'react';
import { Button, message } from 'antd';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { RouteComponentProps } from 'react-router-dom';
import { sleep } from '@chipcoo/fe-utils';

import style from './index.module.less';
import { SettingType } from './interface';
import { typeMap } from './';

import { PasswordAffirm } from 'src/containers';

import { RootDispatch } from 'src/store';
import { Setting } from 'src/models/org/interface';
import { ROUTE_NAMES, fastOrgPath } from 'src/router/normalRouter';
import { lockKey, unlockKey, createKey } from '../delayRemove';
import { compilePath } from 'src/components/HoneyRouter';
import { NAMESPACE } from 'src/config/const';

interface OwnProps {
  type: SettingType;
  id: string;
}

interface StateProps {}
interface DispatchProps {
  leaveOrg: (val: Setting) => void;
}

interface State {
  modalVisible: boolean;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

class Leave extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  handleModalClose = (modalResult) => {
    if (!modalResult) { return; }

    const { orgName } = modalResult;
    if (orgName) {
      this.props.history.push(fastOrgPath(), { description: `已离开组织 ${orgName}` });
    }
  }

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible: false });
  }

  handleSubmit = async ({ verifyCode }) => {
    const { type, id, leaveOrg, match: { params }, history } = this.props;
    const isOrg = type === NAMESPACE.ORG;
    const key = createKey(type, 'leave', id);
    let handler;
    lockKey(key);

    try {
      if (isOrg) {
        handler = await leaveOrg({ code: verifyCode, orgId: id, type: NAMESPACE.ORG });
      } else {
        // handler = await leaveWorkspace({ password, workspaceId: id });
      }
    } catch (e) {
      console.error(e);
      unlockKey(key);
      return;
    }

    const nextRoute = {
      name: ROUTE_NAMES.ORG_LIST,
      params,
    };

    history.replace(compilePath(nextRoute));
    unlockKey(key);
    message.success('已离开' + typeMap[type]);

    await sleep(200);
    handler();
  }

  render () {
    const { type } = this.props;
    const { modalVisible } = this.state;

    return (
      <div>
        <h3>离开{typeMap[type]}</h3>
        <p className={style.warningRed}>如离开{typeMap[type]}，将不能再访问{typeMap[type]}内容。</p>
        <div style={{ display: 'flex' }}>
          <div className={style.content}>
            <p>确定离开{typeMap[type]}？</p>
            <p>如已确定，点击“离开”按钮后输入6位验证码，并点击“确定”按钮。</p>
          </div>
          <Button type="danger" onClick={this.openModal}>离开</Button>
        </div>

        <PasswordAffirm
          from="leave"
          modal={true}
          visible={modalVisible}
          onSubmit={this.handleSubmit}
          onCloseModal={this.closeModal}
        />
      </div>
    );
  }
}

const mapDispatch = ({ org: { leaveOrg } }: RootDispatch) => ({
  leaveOrg,
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(Leave);
