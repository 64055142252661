import React from 'react';
import _ from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  // InputFormItemConfig,
  CustomFormItemConfig,
  TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import { AddressList } from 'src/containers';
import PreviewTable from './components/PreviewTable';
import EditableTable from './components/EditableTable';

const productModel: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productModel',
  colClassName: 'hidden-form-item',
  render() {
    return <div />;
  }
};

// 可编辑物料库存详情列表
const editMaterialDetail = (): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'materialDetail',
  colSpan: 24,
  render: (formData, initialValue, global) => {
    return <EditableTable productModel={formData?.productModel} />;
  },
  renderType: (formData) => {
    if (
      formData?.productModel === EnumProductModel.assemblyProduct ||
      formData?.productModel === EnumProductModel.dpProduct
    ) {
      return 'normal';
    }
    return 'none';
  }
});

// 不可编辑物料库存详情列表
const previewMaterialDetail = (): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'previewMaterialDetail',
  colSpan: 24,
  render: (formData, initialValue, global) => {
    return <PreviewTable />;
  },
  renderType: (formData) => {
    if (
      formData?.productModel !== EnumProductModel.assemblyProduct &&
      formData?.productModel !== EnumProductModel.dpProduct
    ) {
      return 'normal';
    }
    return 'none';
  }
});

// 备注
const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24
};

// 交货地址
const address: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'address',
  colSpan: 24,
  required: true,
  requiredMsg: '请选择交货地址',
  render: (formData, globalStatus) => {
    return <AddressList globalStatus={globalStatus} />;
  }
};

export const getFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '物料信息' },
      groupItemConfig: [
        productModel,
        editMaterialDetail(),
        previewMaterialDetail(),
        remark,
      ] as FormItemConfig[],
    },
    {
      card: { title: '交货地址' },
      groupItemConfig: [
        address,
      ] as FormItemConfig[]
    }
  ]
});
