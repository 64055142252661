/**
 * 入库记录-片号列表
 */
import * as React from 'react';
import _, { isEmpty } from 'lodash';
import { InputNumber } from 'antd';
import { createSelector } from 'reselect';
import { sortWaferId, slices2Number } from '@chipcoo/fe-utils';

import { ModalType } from '../index';

import NewTableEditInModal from './NewTableEditInModal';
import SelectTableBinsOrWaferIds from '../components/SelectTableBinsOrWaferIds';

const pieceTableFormConfig = (slices: any[], isCpDie: boolean) => ({
  waferId: {
    title: '入库片号',
    editableRender: (props, tableData) => {
      // name为bin，waferId为片号
      const newTableData = tableData.filter(item => item.waferId);

      return (
        <SelectTableBinsOrWaferIds
          {...props}
          size="small"
          type="waferIds"
          tableData={newTableData}
          slices={slices}
        />
      );
    },
    render: (text, record) => {
      return <span>{text ? '#' + text : '/'}</span>;
    },
    with: 500,
  },
  good: {
    title: isCpDie ? '良品' : '初始有效管芯数',
    editableRender: (props) => (
      <InputNumber style={{ width: '100%' }} min={0} autoComplete="off" placeholder="良品" {...props}/>
    )
  },
});

interface Props {
  status: ModalType;
  slices: any[];
  waferIds: number[];
  shouldSetField: boolean;
  value?: any;
  onChange?: (value: any) => void;
  setFieldsValue?: (obj: Obj) => void;
  isCpDie: boolean;
  productInfos?: any;
  mappingData?: any[];
  bins?: string[];
  reMapping?: boolean;
}

interface State {
  slices: any[];
  dataSource: any[];
}

class Slices extends React.PureComponent<Props, State> {
  tableFormConfig: any;
  selectColumnProps = createSelector(
    (props: Props, state: State) => state.slices,
    (props: Props, state: State) => props.isCpDie,
    (slices: any[], isCpDie) => {
      return pieceTableFormConfig(slices, isCpDie);
    }
  );

  constructor(props: Props) {
    super(props);

    this.state = { slices: [], dataSource: props.value };
  }

  componentDidMount(): void {
    const { value, reMapping } = this.props;

    value && !reMapping ? this.handleSetFields(value) : this.handleSetFields(value, true);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { value, waferIds, bins, mappingData } = this.props;

    // 需要区分修改的是哪个值
    if (
      !_.isEqual(value, prevProps.value) ||
      !_.isEqual(waferIds, prevProps.waferIds)
    ) {
      this.handleSetFields(value);
    }
    if (
      !_.isEqual(bins, prevProps.bins) ||
      !_.isEqual(mappingData, prevProps.mappingData)
    ) {
      this.handleSetFields(value, true);
    }

    if (!_.isEqual(value, prevProps.value)) {
      this.setState({ dataSource: value });
    }
  }

  handleChange = (val) => {
    const { setFieldsValue, onChange, waferIds, shouldSetField } = this.props;
    const newWaferIds = val?.map(it => parseInt(it.waferId, 10)) || waferIds;
    const { available, unavailable, amount, waferAmount } = slices2Number(val);

    if (shouldSetField) {
      setFieldsValue && setFieldsValue({
        available,
        unavailable,
        amount,
        waferAmount,
        waferIds: sortWaferId(newWaferIds)
      });
    }

    onChange && onChange(val);
  }

  getGoodAmount = (waferId, goodAmount?) => {
    const { mappingData, bins, productInfos, isCpDie, status } = this.props;

    if (mappingData && status !== 'preview') {
      const waferInfo = mappingData.find(it => it?.waferId === waferId);
      if (!isEmpty(bins)) {
        const allBinsAmount = bins?.map(it => waferInfo?.bins[it]);

        return _.sum(allBinsAmount);
      } else if (!bins) {
        return waferInfo?.good;
      }
    }

    if (goodAmount) return goodAmount;
    if (productInfos && !isCpDie) return _.get(productInfos, 'product.perWaferDieQty');
    return undefined;
  }

  handleSetFields = (value, getAmount?: boolean) => {
    const { slices, waferIds: propsWaferIds, status } = this.props;

    let [data]: any[] = [[]];
    const getNewWaferIdData = (item) => ({
      waferId: item.toString(),
      editable: true,
      key: `NEW_TEMP_INDEX_${item - 1}`,
      good: this.getGoodAmount(item),
    });

    if (!_.isEmpty(slices)) {
      this.setState({ slices });
    }

    if (!_.isEmpty(propsWaferIds)) {
      // 比较slices的值与片号选择器里的片号Array
      if (value) {
        const getIntWaferId = (item) => parseInt(item.waferId, 10);
        const sliceWaferIds = value.map(item => getIntWaferId(item));
        const delWaferIds = _.difference(sliceWaferIds, propsWaferIds);

        // 保留未删除的slices
        const _value = _.filter(value, item => !delWaferIds.includes(getIntWaferId(item)));

        data = propsWaferIds.map(waferId => {
          const waferIdData = _value.find(item => getIntWaferId(item) === waferId);

          if (waferIdData) {
            // 已存在的片号
            return {
              ...waferIdData,
              good: getAmount ? this.getGoodAmount(waferId, waferIdData?.good) : waferIdData?.good
            };
          } else {
            // 新建的片号
            return getNewWaferIdData(waferId);
          }
        });
      } else {
        data = propsWaferIds.map(item => getNewWaferIdData(item));
      }
    } else if (this.props.value && status !== 'new') {
      data = this.props.value;
    }

    this.handleChange(data);
  }

  render () {
    const { status } = this.props;
    const { dataSource } = this.state;
    const readOnly = status === 'preview';
    this.tableFormConfig = this.selectColumnProps(this.props, this.state);

    return (
      <NewTableEditInModal
        status={status}
        columnsConfig={this.tableFormConfig}
        onChange={this.handleChange}
        value={dataSource}
        onlyRead={readOnly}
      />
    );
  }
}

export default Slices;
