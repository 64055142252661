import React from 'react';
import { get } from 'lodash';
import { PropsAPIHocFix, withPropsAPI } from 'gg-editor';
import { Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { bind } from 'lodash-decorators';

import DetailEditor, { getPopupContainer } from './DetailEditor';
import { CraftRouteContext } from '../context';
import { ICraftRouteEdge, ICraftRouteNode, IProcessDetail } from '../interface';
import { updateLabel } from '../core';

interface Props extends PropsAPIHocFix, FormComponentProps {

}
interface State {}

const inlineFormItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

export class EdgeEditor extends DetailEditor<Props, State> {
  static contextType = CraftRouteContext;

  context!: React.ContextType<typeof CraftRouteContext>;

  get model() {
    return this.item.getModel() as ICraftRouteEdge;
  }

  get cardProps() {
    return {
      ...this._cardProps,
      title: '制程',
    };
  }

  getUpdateValues(values: any) {
    const { process: processId } = values;
    const process = this.context.processes.find(p => p._id === processId) || null;

    let { label } = this.model;

    if (get(process, '_id') !== get(this.model.process, '_id')) {
      label = updateLabel({ text: get(process, 'code') }, label);
    }

    return {
      ...values,
      process,
      label,
    };
  }

  getOptions(): IProcessDetail[] {
    const { find } = this.props.propsAPI;
    const { target, process } = this.model;
    const targetItem = find!(target);
    const options: IProcessDetail[] = [process].filter(it => !!it) as any;

    if (targetItem) {
      if (targetItem.type === 'node') {
        const model = targetItem.model as ICraftRouteNode;
        const availableProcesses = this.context.processes.filter(
          (p) => p.productModel === model.productModel && get(process, '_id') !== p._id
        );
        options.push(...availableProcesses);
      }
    }

    return options;
  }

  @bind
  filterOption(input: string, option: any) {
    const text = input.trim().toLowerCase();

    if (!text) {
      return true;
    }

    return option.props.children.toLowerCase().indexOf(text) > -1;
  }

  renderNotFound() {
    return '暂无可选制程';
  }

  renderOptions() {
    return this.getOptions().map((process) => (
      <Select.Option key={process._id} value={process._id}>{process.code}</Select.Option>
    ));
  }

  renderProcess() {
    if (!this.item) {
      return null;
    }
    const { form } = this.props;
    const { process } = this.model;

    return (
      <Form.Item label="制程" {...inlineFormItemLayout}>
        {form.getFieldDecorator('process', {
          initialValue: get(process, '_id'),
        })(
          <Select
            filterOption={this.filterOption}
            showSearch={true}
            notFoundContent={this.renderNotFound()}
            onChange={this.handleSubmit}
            placeholder="请选择制程"
            getPopupContainer={getPopupContainer}
          >
            {this.renderOptions()}
          </Select>
        )}
      </Form.Item>
    );
  }

  renderFormContent() {
    return (
      <>
        {this.renderProcess()}
      </>
    );
  }
}

export default withPropsAPI(Form.create()(EdgeEditor as any) as any) as any;
