import * as React from 'react';
import { Icon, Card, Layout } from 'antd';
import styles from './result.module.less';

type ItemProp = string | JSX.Element | JSX.Element[];
interface Props {
  type: string;
  title: ItemProp;
  description?: ItemProp;
  extra?: ItemProp;
  actions?: ItemProp;
}

const { Content } = Layout;

class Result extends React.PureComponent<Props> {
  render () {
    const { type, title, description, extra, actions } = this.props;
    const iconMap = {
      error: <Icon className={styles.error} type="close-circle" />,
      success: <Icon className={styles.success} type="check-circle" />,
    };

    return (
      <Content style={{ padding: 24, display: 'flex' }}>
        <Card bordered={false} className={styles.card}>
          <div className={styles.page}>
            <div className={styles.icon}>{iconMap[type]}</div>
            <div className={styles.title}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
            {extra && <div className="extra">{extra}</div>}
            {actions && <div className="actions">{actions}</div>}
          </div>
        </Card>
      </Content>
    );
  }
}

export default Result;
