import { Notify } from './interface';
/**
 * 获取最后一条消息的时间
 * @param notify
 */
export const getLastTime = (notify: NormalizedData<Notify>) => {
  const { byId, allIds } = notify;
  const lastId = allIds[allIds.length - 1];

  return lastId ? byId[lastId].time : '';
};
