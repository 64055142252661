/**
 * LOT-暂停站点
 */
import React, { FC } from 'react';
import { get } from 'lodash';
import { Collapse, InputNumber } from 'antd';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';

import { TableForm } from 'src/components';
import SelectBreakpoint from './SelectBreakpoint';

const { Panel } = Collapse;

const tableFormConfig = {
  site: {
    title: '暂停站点',
    width: 400,
    editableRender: (props, tableData) => {
      const newTableData = tableData.filter(item => item.site);

      return (
        <SelectBreakpoint
          {...props}
          size="small"
          tableData={newTableData}
          getPopupContainer={(t) => t.parentNode as HTMLElement}
        />
      );
    }
  },
  amount: {
    title: '暂停数量(片)',
    width: 300,
    editableRender: (props) => <InputNumber autoComplete="off" placeholder="数量" {...props} />
  },
};

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
  status: ModalMode;
}

const BreakpointTable: FC<IProps> = props => {
  const { value, onChange, status } = props;

  const handleChange = (val) => {
    onChange && onChange(val);
  };

  const readOnly = status === 'preview';
  const showAddBtn = (get(value, 'length') || 0) < 5;

  return (
    <Collapse defaultActiveKey={['1']} >
      <Panel header="暂停站点" key="1">
        <TableForm
          isInFlexModal={true}
          onlyRead={readOnly}
          columnsConfig={tableFormConfig}
          value={value}
          onChange={handleChange}
          showAddBtn={showAddBtn}
          isSetDisabledByAdd={false}
          size="small"
          className="small-table-no-border"
        />
      </Panel>
    </Collapse>
  );
};

export default BreakpointTable;
