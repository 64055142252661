import * as React from 'react';
import {
  FormConfig,
  InputFormItemConfig,
  NumberFormItemConfig,
  DividerFormItemConfig,
  SelectFormItemConfig,
  CustomFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProcessClass, EnumProductModel } from '@chipcoo/constant';
import { getFactoryName } from '@chipcoo/fe-utils';

import { cpFormConfig } from './cp';
import { dp } from './dp';
import { assemblyFormConfig } from './assembly';
import { ftFormConfig } from './ft';
import { messageModal } from 'src/utils/messageModal';

const organization: InputFormItemConfig = {
  type: 'input',
  dataKey: 'organization',
  colClassName: 'hidden-form-item'
};

const priority: InputFormItemConfig = {
  type: 'input',
  dataKey: 'priority',
  colClassName: 'hidden-form-item'
};

const craftRouteId: InputFormItemConfig = {
  type: 'input',
  dataKey: 'craftRoute',
  colClassName: 'hidden-form-item',
};

const processItemId: InputFormItemConfig = {
  type: 'input',
  dataKey: '_id',
  colClassName: 'hidden-form-item'
};

const processId: InputFormItemConfig = {
  type: 'input',
  dataKey: 'process',
  colClassName: 'hidden-form-item'
};

const product: InputFormItemConfig = {
  type: 'input',
  dataKey: 'product',
  colClassName: 'hidden-form-item'
};

const productModel: InputFormItemConfig = {
  type: 'input',
  dataKey: 'productModel',
  colClassName: 'hidden-form-item'
};

const classes: InputFormItemConfig = {
  type: 'input',
  dataKey: 'processClass',
  colClassName: 'hidden-form-item'
};

const productInfo: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productInfo',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

// 生产周期
const cycle: NumberFormItemConfig = {
  label: '生产周期（天）',
  type: 'number',
  dataKey: 'cycle',
  unit: '天',
  precision: 2
};

// 预测良率
const _yield: NumberFormItemConfig = {
  label: '预测良率',
  type: 'number',
  dataKey: 'yield',
  unit: '%',
  precision: 2,
  range: '[0, 100]',
  positive: null
};

const divider: DividerFormItemConfig = {
  type: 'divider'
};

// 加工厂
const getLabel = (processClass: EnumProcessClass) => {
  switch (processClass) {
    case EnumProcessClass.rw:
      return '流片厂';
    case EnumProcessClass.cp:
      return '中测厂';
    case EnumProcessClass.dp:
      return '磨划厂';
    case EnumProcessClass.ft:
      return '成测厂';
    case EnumProcessClass.assembly:
      return '封装厂';
    case EnumProcessClass.normal:
      return '';
    default:
      messageModal({ content: '未找到匹配的加工厂label文本，请联系管理员处理' });
      return;
  }
};
const factory = (processClass: EnumProcessClass, dataSource?: any[]): CustomFormItemConfig => ({
  label: getLabel(processClass),
  dataKey: 'factory',
  type: 'custom',
  render() {
    const { formData } = this;

    return <span>{getFactoryName(formData?.factory)}</span>;
  }
});

const passDiePerWafer: NumberFormItemConfig = {
  label: '测试有效管芯数',
  type: 'number',
  dataKey: 'scheme.passDiePerWafer',
  precision: 0,
  unit: '颗',
  renderType() {
    return this.formData.processClass === EnumProcessClass.cp ? 'normal' : 'none';
  },
};

// 状态
export const state = (): SelectFormItemConfig => ({
  label: '状态',
  type: 'select',
  dataKey: 'state',
  optionKey: 'productState',
  // renderType: (value) => {
  //   const factory = get(value, `schemes.${index}.factoryName`) || get(value, `schemes.${index}.factory`);
  //
  //   return factory ? 'normal' : 'none';
  // }
});

// 状态说明
export const stateDescription = (): InputFormItemConfig => ({
  label: '状态说明',
  type: 'input',
  dataKey: 'stateDescription',
  colSpan: 24,
  // renderType: (value) => {
  //   const factory = get(value, `schemes.${index}.factoryName`) || get(value, `schemes.${index}.factory`);
  //
  //   return factory ? 'normal' : 'none';
  // },
  validate: [{ pattern: /^.{0,100}$/, message: '请输入最大长度为100的状态说明' }]
});

type Options = {
  productModel: EnumProductModel;
  processClass: EnumProcessClass;
  dataSource?: any[];
};

export const getFormConfig = (options: Options): FormConfig => {
  const { dataSource, processClass } = options;
  let config: any[] = [];

  switch (processClass) {
    case EnumProcessClass.dp:
      config = dp;
      break;
    case EnumProcessClass.cp:
      config = cpFormConfig;
      break;
    case EnumProcessClass.assembly:
      config = assemblyFormConfig;
      break;
    case EnumProcessClass.ft:
      config = ftFormConfig;
      break;
    default:
      break;
  }

  return ({
    cardGroupConfig: [
      {
        groupItemConfig: [
          organization,
          priority,
          craftRouteId,
          processItemId,
          processId,
          product,
          productModel,
          classes,
          productInfo,
          factory(processClass, dataSource),
          cycle,
          _yield,

          ...config,

          divider,
          state(),
          passDiePerWafer,
          stateDescription(),
        ]
      }
    ]
  });
};
