/**
 * 面包屑上那个可以点击的小三角
 */
import * as React from 'react';
import { Menu, Dropdown } from 'antd';
import classnames from 'classnames';
import { bind } from 'lodash-decorators';
import { Link } from 'react-router-dom';

import styles from './index.module.less';
import { getCurrentRoute } from 'src/components/HoneyRouter';

export interface DropdownItem {
  url?: string;
  name?: string;
  isDivider?: boolean;
  children?: DropdownItem[];
}

interface P {
  dropdownItems: DropdownItem[];
}
interface S {
  visible: boolean;
}

class DropDownArrow extends React.PureComponent<P, S> {
  state = {
    visible: false,
  };

  @bind
  close() {
    this.setState({ visible: false });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  @bind
  renderMenu() {
    const { dropdownItems } = this.props;
    const { location: { pathname } } = getCurrentRoute()!;
    return (
      <Menu className={styles.menu} onClick={this.close}>
        {dropdownItems.map(item => {
          if (!item) {
            return;
          }

          if (item?.isDivider) {
            return (<Menu.Divider key={Math.random()} />);
          }

          if (item?.children) {
            const areaName = item?.name;
            const areaMenuList = item?.children.map(it => {
              return (
                <Menu.Item
                  key={it.url}
                  className={classnames(styles.areaMenuListItem, pathname.indexOf(it.url!) !== -1 ? 'active' : '')}
                >
                  <Link to={it.url!} className="ellipsis">
                    {it.name}
                  </Link>
                </Menu.Item>
              );
            });

            // todo 添加样式
            const areaNameMenu = (
              <Menu.Item key={areaName} disabled={true} className={styles.areaName}>
                {areaName}:
              </Menu.Item>
            );

            // 将大类名插入到index=0的位置
            areaMenuList.unshift(areaNameMenu);

            return areaMenuList;
          }

          return (
            <Menu.Item key={item.url} className={pathname.indexOf(item.url!) !== -1 ? 'active' : ''}>
              <Link to={item.url!} className="ellipsis">
                {item.name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  render() {
    const { visible } = this.state;

    return (
      <Dropdown
        overlay={this.renderMenu()}
        trigger={['click']}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        overlayClassName={styles.breadcrumbDropDownPanel}
      >
        <a className="ant-dropdown-link">
          <i className={classnames('c-icon', 'icon-arrow-right-o', { expand: visible })}/>
        </a>
      </Dropdown>
    );
  }
}

export default DropDownArrow;
