/**
 * WIP筛选查询
 */
// import React from 'react';
import _ from 'lodash';
import {
  FormItemConfig,
  InputFormItemConfig,
  // CustomFormItemConfig,
  // SelectFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

const serial: InputFormItemConfig = {
  label: '申请单号',
  type: 'input',
  dataKey: 'serial',
};

export const filterFormConfig = (): FormItemConfig[] => [
  serial,
];
