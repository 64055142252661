import React from 'react';
import {
  FormConfig,
  // FormItemConfig,
  // CollapseFormItemProps,
} from '@chipcoo/hanayo/lib/FormCreator';

import ContactTableList from './ContactTableList';

// const contacts = [
//   { dataKey: 'ORGANIZATION', label: '组织' },
//   { dataKey: 'TAPE_OUT', label: 'tapeout' },
//   { dataKey: 'ORDER', label: '订单' },
//   { dataKey: 'STOCK_OUT', label: '出货' },
//   { dataKey: 'FINANCE', label: '对账' },
// ];

export const getFormConfig = (status, extra?): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '联系信息', bordered: true, extra },
      groupItemConfig: [
        {
          type: 'custom',
          dataKey: 'functionContacts',
          colSpan: 24,
          render() {
            return (
              <ContactTableList
                status={status}
              />
            );
          }
        }
      ],
    }
  ]
});
