/**
 * 聊天群组成员管理
 */
import * as React from 'react';
import { Icon, Input } from 'antd';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bind, debounce } from 'lodash-decorators';
import { filterUser } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { DeleteUserFromRoomParams, PutNewMemberToRoomParams } from 'src/models/im/chatPanel.type';

import ChatMember from './ChatMember';
import { ControlledComponent } from 'src/hoc';
// import SelectMemberContent from 'src/pages/IM/SelectMemberContent';
import SelectMember from 'src/containers/SelectMemberByGroup';
import { UserModel } from 'src/models/user/interface';

interface OwnProps {
  isOwner: boolean;
  currentUserId: string;
  roomId: string;
}
interface StateProps {
  memberIds: string[];
  userSourceData: UserModel['list']['byId'];
}
interface DispatchProps {
  chatRoomListReset: (path: any) => void;
  deleteUserFromRoom: (params: DeleteUserFromRoomParams) => void;
  addUserToRoom: (params: PutNewMemberToRoomParams) => any;
}

type P = OwnProps & StateProps & DispatchProps;
interface S {
  searchValue: any;
  modalVisible: boolean;
  selectedMembers: UserId[];
}

const ControlledSearch = ControlledComponent(Input.Search);

class ChatMemberManage extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      searchValue: void 0,
      modalVisible: false,
      selectedMembers: [],
    };
  }

  @bind
  @debounce(200)
  onSearchChange(value: any) {
    this.setState({ searchValue: value });
  }

  handleDeleteUser = (userId: string) => {
    const { deleteUserFromRoom, roomId } = this.props;

    deleteUserFromRoom({ userId, roomId });
  }

  @bind
  openModal() {
    this.setState({ modalVisible: true, selectedMembers: [] });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible: false, selectedMembers: [] });
  }

  @bind
  syncSelectMembers(selectedMembers: UserId[]) {
    this.setState({ selectedMembers });
  }

  handleModalSubmit = async (selectedKeys: string[]) => {
    const { roomId } = this.props;
    const result = await this.props.addUserToRoom({ roomId, userIds: selectedKeys });
    return Boolean(result);
  }

  renderMember = (id) => {
    const { searchValue } = this.state;
    const { userSourceData, isOwner, currentUserId } = this.props;
    const user = userSourceData[id];

    let filterResult = true;

    if (searchValue !== void 0) {
      filterResult = filterUser(searchValue, user);
    }

    if (!filterResult) { return null; }

    return (
      <ChatMember
        isOwner={isOwner}
        currentUserId={currentUserId}
        key={id}
        userId={id}
        deleteUser={this.handleDeleteUser}
      />
    );
  }

  render() {
    const { memberIds } = this.props;
    // const dataSourceOption = getMemberOption(memberIds);
    const list = memberIds.map(id => this.renderMember(id));

    return (
      <div className={classnames(styles.chatMemberManageWrapper, 'y-scroll thin-scroll')}>
        <ControlledSearch placeholder="搜索群成员" onChange={this.onSearchChange} />

        <div className={`flex-fill ${styles.memberList}`}>
          <div className={styles.chatMemberWrapper}>
            <button className={styles.plusBtn} onClick={this.openModal}>
              <Icon type="plus" theme="outlined" />
            </button>

            <div className="name ellipsis">添加</div>
          </div>

          {list}
        </div>

        <SelectMember
          visible={this.state.modalVisible}
          modalTitle={'添加群成员'}
          onCancel={this.closeModal}
          onSubmit={this.handleModalSubmit}
          value={this.state.selectedMembers}
          onChange={this.syncSelectMembers}
          disabledKeys={memberIds}
        />
      </div>
    );
  }
}

const mapState = ({ chatPanel: { roomUsers: { existUsers } }, user: { list: { byId } } }: RootState) => ({
  memberIds: existUsers,
  userSourceData: byId,
});
const mapDispatch = ({ chatPanel, chatRoomList }: RootDispatch): DispatchProps => ({
  deleteUserFromRoom: chatPanel.deleteUserFromRoom,
  chatRoomListReset: chatRoomList.reset,
  addUserToRoom: chatPanel.addNewUserToRoom,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(ChatMemberManage);
