import { AxiosInstance, AxiosRequestConfig } from 'axios';

type ARC = AxiosRequestConfig;

export default function(api: AxiosInstance) {
  return {
    getAbnormalityList: (params: any, config: ARC = {}) => {
      return api.get('ops/abnormality/search', { ...config, params });
    },

    getAbnormalityById: (_id: string, config: ARC = {}) => {
      return api.get(`ops/abnormality/${_id}`, config);
    },
  };
}
