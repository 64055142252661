import { createModel } from '@rematch/core';
import produce from 'immer';
import { isEqual, isEmpty } from 'lodash';
import { EnumReportContent } from '@chipcoo/constant';

import { RootState } from 'src/store';
import { qcApi } from 'src/services/net';
import { TableModelCommonEffects } from 'src/models/type';
import { State } from './interface';
import {
  handleReportDetail,
  handlePutReportFilterData,
  getDataKey,
  handlePcmData,
  handleCorrData,
  handleCompareData,
  handleBinAxis,
} from './handle';
import {
  isMap,
  handleFilterData,
  handlePreviewList,
  handleMapData,
  handleCompareFormData,
} from './utils';
import { IMapParams, PcmParams } from 'src/services/net/qc';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
  mapData: {},
  arrayBufferData: {
    stdDist: undefined,
    scatter: undefined,
    xyScatter: undefined,
    comparePrimary: undefined,
    compareSecondary: undefined,
    boxpolt: undefined,
    meanStddev: undefined,
  },
  previewList: {},
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setReportDetail(state: State, payload: any) {
      return produce(state, draft => {
        const { detail, content, arrayBufferData, secondaryArrayBuffer } = payload;
        draft.detail = detail;

        if ((
            content === EnumReportContent.stdDist ||
            content === EnumReportContent.scatter ||
            content === EnumReportContent.boxpolt ||
            content === EnumReportContent.meanStddev ||
            content === EnumReportContent.xyScatter
          ) && arrayBufferData
        ) {
          draft.arrayBufferData[getDataKey(content)] = arrayBufferData;
        } else if (content === EnumReportContent.compare && (arrayBufferData || secondaryArrayBuffer)) {
          if (arrayBufferData) draft.arrayBufferData.comparePrimary = arrayBufferData;
          if (secondaryArrayBuffer) draft.arrayBufferData.compareSecondary = secondaryArrayBuffer;
        }
      });
    },
    setMapData(state: State, payload: any) {
      return produce(state, draft => {
        const { content, data } = payload;
        let dataKey = getDataKey(content);

        draft.detail = {
          ...state.detail,
          [dataKey]: {
            ...state.detail?.[dataKey],
            mapData: content === EnumReportContent.stackMap ? data?.[0] : data,
          }
        };
      });
    },
    setPreviewList(state: State, payload: any) {
      return produce(state, draft => {
        const data = isEmpty(payload) ? {} : {
          ...state.previewList,
          [payload.content]: payload.formData,
        };
        draft.previewList = data;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any) {
      await qcApi.getReportList(params);
    },
    async getTableItemDetail() {
      //
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
    async getReportByAnalysisId(_id: string, rootState: any) {
      const { detail: analysisDetail } = rootState.analysis;
      const { data } = await qcApi.getReportByAnalysisId(_id);
      const handleData = handleReportDetail({
        ...data,
        type: rootState.analysis.detail?.type,
        tests: analysisDetail?.originTests
      }, true);

      this.setReportDetail({detail: handleData});
      this.setPreviewList({});
    },
    async putReportFilter(payload: any, rootState: RootState) {
      const { detail, arrayBufferData } = rootState.report;
      const { content } = payload;
      const handleFilter = handleFilterData(payload);
      let resp, handleData, arrayBuffer;

      const rawParams = {
        _id: handleFilter?._id,
        testId: handleFilter?.setting?.testId,
        filters: handleFilter?.filters,
        groupBy: handleFilter?.groupBy,
        secondary: false,
      };
      let shouldGetArrayBuffer: boolean = !isEqual(rawParams, detail?.[getDataKey(content)]?.queryParams);

      // 正太分布、散点图、箱型图、均值标准差
      if (
        content === EnumReportContent.stdDist ||
        content === EnumReportContent.scatter ||
        content === EnumReportContent.boxpolt ||
        content === EnumReportContent.meanStddev
      ) {
        if (shouldGetArrayBuffer) {
          arrayBuffer = await qcApi.postWhatIf(rawParams);
        }

        handleData  = await handlePutReportFilterData(
          {...handleFilter, queryParams: rawParams},
          detail,
          arrayBuffer?.data ?? arrayBufferData?.[getDataKey(content)]
        );
      } else if (content === EnumReportContent.xyScatter) {
        // XY散点图
        const params = {
          _id: handleFilter?._id,
          testIds: handleFilter?.setting?.testIds,
          filters: handleFilter?.filters,
          groupBy: handleFilter?.groupBy
        };

        shouldGetArrayBuffer = !isEqual(params, detail?.[getDataKey(content)]?.queryParams);
        if (shouldGetArrayBuffer) {
          arrayBuffer = await qcApi.postMultiRawData(params);
        }
        handleData  = await handlePutReportFilterData(
          {...handleFilter, queryParams: params},
          detail,
          arrayBuffer?.data ?? arrayBufferData.xyScatter
        );
      } else if (
        content === EnumReportContent.bins ||
        content === EnumReportContent.topN ||
        content === EnumReportContent.topCPK
      ) {
        // 存一下搜索条件减少发两次请求的次数
        const params = {...handleFilter, setting: {}};
        const hasChange: boolean = !isEqual(params, detail?.[getDataKey(content)]?.queryParams) || params?.isGroupBy;

        if (hasChange) {
          // 点击绘制groupBy不为空时先发个请求拿一下x轴数据
          let noGroupAxis;
          if (handleFilter?.isRedraw && !isEmpty(handleFilter?.groupBy)) {
            const { data } = await qcApi.putReportFilter({ ...params, groupBy: undefined });
            
            noGroupAxis = content === EnumReportContent.bins ? {
              hardBins: handleBinAxis(data?.hardBins),
              softBins: handleBinAxis(data?.softBins),
            } : 
            data?.map(it => it.testId);
          }

          resp = await qcApi.putReportFilter(params);
          handleData  = await handlePutReportFilterData({...params, noGroupAxis}, detail, resp?.data);
        } else {
          handleData = detail;
        }
      } else {
        resp = await qcApi.putReportFilter(handleFilter);
        handleData  = await handlePutReportFilterData(handleFilter, detail, resp?.data);
      }

      if (!isEmpty(resp?.data) && payload.isRedraw && isMap(content)) {
        const handlePreviewListData = handlePreviewList(resp?.data);
        this.setPreviewList({
          content: content,
          formData: handlePreviewListData,
        });
      }

      this.setReportDetail({
        detail: handleData,
        content,
        arrayBufferData: arrayBuffer?.data,
      });

      return resp?.data;
    },
    // 获取map 预览
    async getMapPreview(params: IMapParams) {
      const { data } = await qcApi.getMapPreview(params);
      const handleData = handlePreviewList(data);

      this.setPreviewList({
        content: params.content,
        formData: handleData,
      });
    },
    // 获取map数据
    async getMapByMapId(_id: string, rootState: RootState) {
      const { detail } = rootState.report;
      const { data } = await qcApi.getMapByMapId(_id);
      const handleData = handleMapData(data, detail);

      this.setReportDetail({detail: handleData});
    },

    // 计算报告pcm相关性
    async getPcmCorrCalc(params: PcmParams, rootState: RootState) {
      const { data } = await qcApi.getPcmCorrCalc(params);
      const { detail } = rootState.report;

      const handleData = handlePcmData(data, detail);
      this.setReportDetail({detail: handleData});
    },
    // 计算相关性分析数据
    async getCorrelationData(params: any, rootState: RootState) {
      const { detail } = rootState.report;
      const corrParams = params?.setting?.correlationParams;

      const { data } = await qcApi.postReportCorrCalc({
        ...corrParams,
        _id: detail?._id,
        primaryFilters: corrParams?.primaryFilters?.map(it => ({
          autoAnalysis: it?.autoAnalysis,
          product: it?.product,
          dieNumber: it?.dieNumber,
        })),
        secondaryFilters: corrParams?.secondaryFilters?.map(it => ({
          autoAnalysis: it?.autoAnalysis,
          product: it?.product,
          dieNumber: it?.dieNumber,
        })),
        primaryTestIds: corrParams?.primaryTestIds,
        secondaryTestIds: corrParams?.secondaryTestIds,
        primaryRangeMin: corrParams?.primaryRange?.rangeMin,
        primaryRangeMax: corrParams?.primaryRange?.rangeMax,
        secondaryRangeMin: corrParams?.secondaryRange?.rangeMin,
        secondaryRangeMax: corrParams?.secondaryRange?.rangeMax,
      });
      const handleData = handleCorrData(data, detail);
      this.setReportDetail({ detail: handleData });
    },
    // 计算对比分析数据
    async getCompareData(params: any, rootState: RootState) {
      const { detail, arrayBufferData } = rootState.report;
      const queryParams = handleCompareFormData(params);
      const { primary, secondary } = queryParams;

      // 存一下搜索条件，搜索条件不变时不获取数据
      const prevQueryParams = detail?.[params?.content]?.queryParams;
      let primaryArrayBuffer, secondaryArrayBuffer;

      if (!isEqual(prevQueryParams?.primary, primary) || !prevQueryParams) {
        const { data } = await qcApi.postWhatIf(primary);
        primaryArrayBuffer = data;
      } else {
        primaryArrayBuffer = arrayBufferData.comparePrimary;
      }

      if (!isEqual(prevQueryParams?.secondary, secondary) || !prevQueryParams) {
        const { data } = await qcApi.postWhatIf(secondary);
        secondaryArrayBuffer = data;
      } else {
        secondaryArrayBuffer = arrayBufferData.compareSecondary;
      }

      const handleData = await handleCompareData(
        primaryArrayBuffer,
        secondaryArrayBuffer,
        params,
        detail,
        queryParams
      );

      this.setReportDetail({
        detail: handleData,
        content: params?.content,
        arrayBufferData: primaryArrayBuffer,
        secondaryArrayBuffer: secondaryArrayBuffer,
      });
    }
  })
});
// tslint:disable-next-line:max-file-line-count
