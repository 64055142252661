import { createModel } from '@rematch/core';
import produce from 'immer';

import { State } from './interface';
import { inventoryApi } from 'src/services/net';
import { TableModelCommonEffects } from 'src/models/type';
import { handleStockList, handleStockDetail } from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: void 0,
  statistics: {},
};

export default createModel({
  state: initialState,
  reducers: {
    setList(state: State, respData: any) {
      return produce(state, draft => {
        draft.count = respData.count;
        draft.list = handleStockList(respData.data);
      });
    },
    setDetail(state: State, respData: any) {
      return produce(state, draft => {
        draft.detail = respData;
      });
    }
  },
  effects: (): TableModelCommonEffects => ({
    async getTableList(params: any) {
      const { data } = await inventoryApi.searchStockGroup(params);

      this.setList(data);
    },
    async postCreate(data: any) {
      //
    },
    async getTableItemDetail(id: any) {
      const { data } = await inventoryApi.getStockById(id);
      const handleData = await handleStockDetail(data);

      this.setDetail(handleData);
    },
    async patchModify(data: any) {
      //
    }
  })
});
