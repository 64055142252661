import * as React from 'react';
import { isArray, isEmpty, sum, get } from 'lodash';
import { Col } from 'antd';
import classnames from 'classnames';
import { EnumProductModel, EnumRequirementOrderStatus } from '@chipcoo/constant';
import {
  ModalMode,
  FormConfig,
  FormItemConfig,
  CustomFormItemConfig,
  TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { unSortWaferId } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';

import CustomItem from './CustomItem';

const getProductModelRenderType = (_this, key) => {
  const { formData } = _this;

  return formData?.productModel === EnumProductModel[key] ? 'normal' : 'none';
};

const productModel: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productModel',
  colClassName: 'hidden-form-item',
  render() { return <div />; }
};

const packageType: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'packageType',
  colClassName: 'hidden-form-item',
  render() { return <div />; }
};

const checkInfo = (mode: ModalMode): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'checkInfo',
  colSpan: 24,
  render() {
    return <div>请核对订单信息:</div>;
  },
  renderType() {
    return mode === 'preview' ? 'none' : 'normal';
  }
});

const serial: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'serial',
  colSpan: 12,
  colClassName: 'serial',
  render() {
    return (
      <CustomItem label="系统订单编号" />
    );
  }
};

const outSourcedOrderSerial: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'outSourcedOrderSerial',
  colSpan: 12,
  colClassName: 'serial',
  render() {
    return (
      <CustomItem label="私有订单编号" />
    );
  }
};

const createdAt: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'createdAt',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="下单日期" />
    );
  }
};

const expectedDeliveryDate: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'expectedDeliveryDate',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="期望交货日期" />
    );
  }
};

const client: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'client',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="委托方" />
    );
  }
};

const clientContacts: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'clientContacts',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="联系方式" />
    );
  }
};

const ssp: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'ssp',
  colSpan: 12,
  colClassName: 'underline',
  render() {
    return (
      <CustomItem label="承接方" value="杭州芯翼科技有限公司" />
    );
  }
};

const sspContacts: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'sspContacts',
  colSpan: 12,
  colClassName: 'underline',
  render() {
    return (
      <CustomItem label="联系方式" />
    );
  }
};

const productName: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'productName',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="产品名称" />
    );
  }
};

const orderTemplateName: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'orderTemplateName',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="订购名" />
    );
  }
};

const packageTypeValue: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'packageTypeValue',
  colSpan: 12,
  render() {
    const { formData } = this;
    const _packageType = formData?.packageType;
    const p1 = optionsGroup.packageType_1.options![_packageType?.packageType];
    const packageTypeLW = _packageType?.packageTypeLW;
    const carrierType = _packageType?.carrierType;

    let label = '封装类型';
    let value = p1;

    if (packageTypeLW) {
      label = '封装类型/封装型号';
      value = value + '/' + packageTypeLW;
    }

    if (carrierType) {
      label = '封装类型/载板类型';
      value = value + '/' + carrierType;
    }

    return (
      <CustomItem label={label} customValue={value} />
    );
  },
  renderType() {
    return getProductModelRenderType(this, 'assemblyProduct');
  }
};

const waferFinalThickness: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'waferFinalThickness',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="晶圆最终厚度" unit="µm" />
    );
  },
  renderType() {
    return getProductModelRenderType(this, EnumProductModel.dpProduct);
  }
};

const bgMethod: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'bgMethod',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="研磨方式" />
    );
  },
  renderType() {
    return getProductModelRenderType(this, EnumProductModel.dpProduct);
  }
};

const sawMethod: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'sawMethod',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="切割方式" />
    );
  },
  renderType() {
    return getProductModelRenderType(this, EnumProductModel.dpProduct);
  }
};

// 订单数量
const requirementRenderType = (_this, _productModel?) => {
  const { formData, initialFormData } = _this;
  const { status } = initialFormData;

  if (
    initialFormData.quantity && (
      status !== EnumRequirementOrderStatus.created ||
      status !== EnumRequirementOrderStatus.rejected ||
      status !== EnumRequirementOrderStatus.deleted ||
      status !== EnumRequirementOrderStatus.canceled
    )
  ) {
    return formData?.productModel === _productModel || !_productModel ? 'normal' : 'none';
  }

  return 'none';
};

// 需求数量
const demandRenderType = (_this, _productModel?) => {
  const { formData, initialFormData } = _this;
  const { status } = initialFormData;

  if (
    initialFormData.demandQuantity && (
      !status ||
      status === EnumRequirementOrderStatus.created ||
      status === EnumRequirementOrderStatus.rejected ||
      status === EnumRequirementOrderStatus.deleted ||
      status === EnumRequirementOrderStatus.canceled
    )
  ) {
    return formData?.productModel === _productModel || !_productModel ? 'normal' : 'none';
  }

  return 'none';
};

const quantity: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'quantity',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="订单数量" unit="颗" />
    );
  },
  renderType() {
    return requirementRenderType(this);
  }
};

const waferQuantity: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'waferQuantity',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="订单总片数" unit="片" />
    );
  },
  renderType() {
    return requirementRenderType(this, EnumProductModel.assemblyProduct);
  }
};

const demandQuantity: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'demandQuantity',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="需求数量" unit="颗" preText="约" />
    );
  },
  renderType() {
    return demandRenderType(this);
  }
};

const demandWaferQuantity: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'demandWaferQuantity',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="晶圆总片数" unit="片" />
    );
  },
  renderType() {
    return demandRenderType(this, EnumProductModel.assemblyProduct);
  }
};

const packingMethod: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'packingMethod',
  colSpan: 12,
  render() {
    return (
      <CustomItem label="包装方式" />
    );
  }
};

// const markingMethod: CustomFormItemConfig = {
//   type: 'custom',
//   dataKey: 'markingMethod',
//   colSpan: 12,
//   render() {
//     return (
//       <CustomItem label="打印方式" />
//     );
//   },
//   renderType() {
//     return getProductModelRenderType(this, 'assemblyProduct');
//   }
// };
//
// const testRequirement: CustomFormItemConfig = {
//   type: 'custom',
//   dataKey: 'testRequirement',
//   colSpan: 12,
//   render() {
//     return (
//       <CustomItem label="测试需求" />
//     );
//   },
//   renderType() {
//     return getProductModelRenderType(this, 'assemblyProduct');
//   }
// };

// const blankLines: CustomFormItemConfig = {
//   type: 'custom',
//   dataKey: 'blankLines',
//   colSpan: 12,
//   colClassName: 'blankLines',
//   render() {
//     return <div />;
//   },
//   // renderType() {
//   //   return getProductModelRenderType(this, 'assemblyProduct');
//   // }
// };

const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
  colClassName: classnames('underline', 'remark'),
};

const getDieArray = (data: any) => {
  const productDies = data?.productDies;
  let newConfigs: any[] = [];

  if (isArray(productDies)) {
    newConfigs = productDies.map((it, index) => {
      const { customerName, dieSize } = it;
      const dieIndex = productDies.length > 1 ? (index + 1) : '';

      return [
        {
          type: 'custom',
          dataKey: `dieName_${dieIndex}`,
          colSpan: 12,
          render() {
            return (
              <CustomItem
                label={`芯片名称${dieIndex}`}
                customValue={customerName}
              />
            );
          }
        },
        {
          type: 'custom',
          dataKey: `dieSize_${dieIndex}`,
          colSpan: 12,
          render() {
            return (
              <CustomItem
                label={`芯片尺寸${dieIndex}`}
                customValue={dieSize?.join(' x ')}
              />
            );
          }
        },
      ];
    });
  }

  return newConfigs;
};

const getDieNumber = (data: any) => {
  const selectedData = data?.selectedData;
  const stockInfos = data?.stockInfos;

  const formData = get(selectedData, '0.dieNumber') ? selectedData : stockInfos;
  let newConfigs = [];

  if (formData) {
    // 排除掉productModel为封装的选料
    newConfigs = formData.filter(it => it?.productModel !== EnumProductModel.assemblyProduct).map((it, index) => {
      const { dieNumber, waferIds } = it;
      const dieNumberIndex = formData.length > 1 ? (index + 1) : '';
      const waferLength = isArray(waferIds) ? waferIds?.length : unSortWaferId(waferIds)?.length;

      return [
        {
          type: 'custom',
          dataKey: `dieNumber_${dieNumberIndex}`,
          colSpan: 12,
          render() {
            return (
              <CustomItem
                label={`芯片批号${dieNumberIndex}`}
                customValue={`${dieNumber}/#${waferIds}`}
              />
            );
          }
        },
        {
          type: 'custom',
          dataKey: `waferQuantity_${dieNumberIndex}`,
          colSpan: 12,
          render() {
            return (
              <CustomItem
                label={`封装片数${dieNumberIndex}`}
                customValue={waferLength}
                unit="片"
              />
            );
          },
          renderType() {
            return getProductModelRenderType(this, 'assemblyProduct');
          }
        },
        {
          type: 'custom',
          dataKey: `quantity_${dieNumberIndex}`,
          colSpan: 12,
          render() {
            const children = it?.children;
            let good = it?.good;

            if (!isEmpty(children) && !good) {
              const goods = children.map(item => item?.good || 0);

              good = sum(goods);
            }

            return (
              <CustomItem
                label={`磨划颗数${dieNumberIndex}`}
                customValue={good}
              />
            );
          },
          renderType() {
            return getProductModelRenderType(this, 'dpProduct');
          }
        }
      ];
    });
  }

  return newConfigs;
};

const text = (data: any): CustomFormItemConfig => ({
  type: 'custom',
  dataKey: 'text',
  colSpan: 24,
  colClassName: classnames('underline', 'text'),
  render() {
    const allowReplace = data?.selectedMaterialInfo?.[0]?.allowReplace;

    return (
      <Col span={12} offset={12}>
        以上物料<b>{allowReplace ? '允许' : '不许'}</b>临时更换
      </Col>
    );
  },
  renderType() {
    return !isEmpty(this.initialFormData?.selectedData) ? 'preview' : 'none';
  }
});

export const getFormConfig = (mode: ModalMode, data: any): FormConfig => ({
  cardGroupConfig: [
    {
      groupItemConfig: [
        [
          checkInfo(mode),
          productModel,
        ],
        [
          serial,
          outSourcedOrderSerial,
          createdAt,
          expectedDeliveryDate,
          client,
          clientContacts,
          ssp,
          sspContacts
        ],
        [
          productName,
          orderTemplateName,
          packageType,
          packageTypeValue,
          waferFinalThickness,
          ...getDieArray(data),
          bgMethod,
          sawMethod,
          quantity,
          waferQuantity,
          demandQuantity,
          demandWaferQuantity,
          packingMethod,
          // markingMethod,
          // testRequirement,
          remark,
        ],
        [...getDieNumber(data)],
        text(data),
      ] as FormItemConfig[][]
    }
  ]
});
// tslint:disable:max-file-line-count
