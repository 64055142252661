/** 客诉管理 */
import * as React from 'react';
import { noop } from '@chipcoo/fe-utils';

interface Props {}
interface State {}

class CustomerComplaint extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  // componentDidMount() {}

  componentWillUnmount() {
    this.setState = noop;
  }

  render () {
    return (
      <div>customer complaint</div>
    );
  }
}

export default CustomerComplaint;
