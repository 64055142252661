import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';

// 计算resizable table 的scroll X
export function calculateResizableTableScroll<T>(columns: ColumnProps<T>[]) {
  const scroll = _.sumBy(columns, (it) => {
    let width = it?.width;

    if (typeof width === 'string') {
      width = parseInt(width, 10);
    }

    return width || 0;
  });

  return scroll;
}
