// tslint:disable:max-file-line-count
import React, { PureComponent, ComponentType } from 'react';
import { isEmpty, find } from 'lodash';
import { FlexModal, IFlexModalProps } from '@chipcoo/hanayo/lib/flex-modal';
// import { InputNumberWithUnit } from '@chipcoo/hanayo';
import { selectOriginOptionData } from '@chipcoo/hanayo';
import { throttle } from 'lodash-decorators';
import { EnumProcessClass, EnumProductModel, EnumWarehouseType } from '@chipcoo/constant';

import './index.less';
import { connectDecorator, RootState, Actions } from 'src/store';
import { ISmartSelection } from 'src/services/net/inventoryManage';
import { DemandOrderContext } from '../components/context';
// import { getQuantityByKeys } from './util';
import { calcCount, getDieQty } from '../util';

import MaterialTable from './MaterialTable';
import SelectedMaterialTable from './SelectedMaterialTable';
import { SelectedDataInfo } from '../NewOrderModal/CardGroupConfigs/components';
// import { getFtQuantity } from './util';

interface OwnProps {
  id?: string;
  visible: boolean;
  onOk?: (values: Obj) => void;
  onCancel?: IFlexModalProps['onCancel'];
  onAfterClose?: IFlexModalProps['onAfterClose'];
  product: string;
  type: EnumWarehouseType;
  classes?: EnumProcessClass;
  productModel: EnumProductModel;
  /** 仅在没有id的时候起作用 */
  localDetail?: any;
}

interface StateProps {
  stockList: {
    originData: any[];
    handleData: any[];
  };
}

interface DispatchProps {
  smartSelection: (params: ISmartSelection) => void;
}

interface State {
  visible: boolean;
  dieQtyInPackage: number;
  perWaferDieQty: number;
  submitTableData?: (val: any[]) => void;
  modalFullWindow: boolean;
  height: number;
  getSelectedData: Function;
  // 用于数据外传及左侧section的预览
  selectedData: any[];
  // 用于已选物料列表的展示
  dataSource: any[];
}

interface Props extends OwnProps, StateProps, DispatchProps {}
export interface MaterialModalPortalProps extends OwnProps {}

@connectDecorator(
  ({
     demandOrder: { stockList }
   }: RootState) => ({ stockList }),
  ({
     demandOrder: { smartSelection }
   }: Actions) => ({
    smartSelection
  })
)
export class MaterialModalPortal extends PureComponent<Props, State> {
  static contextType = DemandOrderContext;

  private formController; // 右侧物料列表form实例
  private leftSectionFormController; // 左侧选料form实例

  constructor(props: Props) {
    super(props);
    const { localDetail } = props;

    this.state = {
      visible: false,
      dieQtyInPackage: 0,
      perWaferDieQty: 0,
      submitTableData: this.submitTableData,
      modalFullWindow: true,
      height: 0,
      selectedData: [],
      getSelectedData: this.getSelectedData,

      dataSource: localDetail?.selectedData ?? [],
    };
  }

  componentDidMount(): void {
    this.getNumberOfGrossDiePerWafer();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { product } = this.props;

    if (product && product !== prevProps.product) {
      this.getNumberOfGrossDiePerWafer();
    }
  }

  getNumberOfGrossDiePerWafer = () => {
    const { productModel, product } = this.props;
    const cachedData = selectOriginOptionData.getCachedData();
    let optionKey: string = 'getProductByProductModel';

    const optionsData = cachedData[optionKey];
    const productInfo = find(optionsData, it => it._id === product);
    const dieQty = getDieQty({
      productInfo,
      productModel,
    });

    this.setState({
      dieQtyInPackage: dieQty?.dieQtyInPackage,
      perWaferDieQty: dieQty?.perWaferDieQty
    });
  }

  getFormController = (controller) => this.formController = controller;

  // 智能选料-物料选择完成后提交数据
  submitTableData = (payload) => {
    this.setState({ dataSource: payload });
  }

  // 获取已选物料列表的数据，并设置到state，这个数据在modal submit时会用到
  getSelectedData = (selectedData = []) => {
    this.setState({ selectedData: [...selectedData] });
  }

  @throttle(300)
  setFieldValue(key: string, val: number) {
    this.leftSectionFormController.setFieldValue(key, val);
  }

  handleWaferAmountChange = (val, onChange) => {
    const { productModel } = this.props;
    const { perWaferDieQty, dieQtyInPackage } = this.state;

    onChange(val);

    let amount = calcCount({
      waferCount: val,
      dieQtyInPackage,
      perWaferDieQty,
      productModel,
    });

    this.setFieldValue('amount', amount);
  }

  renderLeftSection = () => {
    const { classes } = this.props;
    const { selectedData } = this.state;

    return (
      <SelectedDataInfo
        classes={classes}
        selectedData={selectedData}
      />
    );
  }

  getTableFormRender = () => {
    const { product, type, productModel, classes, localDetail } = this.props;
    const { height } = this.state;

    return (
      <MaterialTable
        product={product}
        type={type}
        productModel={productModel}
        classes={classes}
        height={height}
        selectedData={localDetail?.selectedData}
      />
    );
  }

  handleSubmit = () => {
    const { onOk } = this.props;
    const { selectedData } = this.state;

    this.formController.submit(values => {
      if (!isEmpty(selectedData) && onOk) {
        onOk({
          selectedData,
        });
      }
    });
  }

  getSelectedList = () => {
    const { dataSource } = this.state;

    return (
      <SelectedMaterialTable
        dataSource={dataSource}
      />
    );
  }

  onRequestFullWindow = () => this.setState({ modalFullWindow: true });

  onRequestNormalWindow = () => this.setState({ modalFullWindow: false });

  render () {
    const { visible, onCancel } = this.props;
    const { modalFullWindow, dataSource } = this.state;

    return (
      <DemandOrderContext.Provider value={this.state}>
        <FlexModal
          className="order-material-form-modal"
          width={1024}
          visible={visible}
          title="选择物料"
          onOk={this.handleSubmit}
          okButtonProps={{ disabled: isEmpty(dataSource) }}
          onCancel={onCancel}
          leftSection={this.renderLeftSection()}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
        >
          {this.getTableFormRender()}

          {this.getSelectedList()}
        </FlexModal>
      </DemandOrderContext.Provider>
    );
  }
}

export default MaterialModalPortal as ComponentType<OwnProps>;
