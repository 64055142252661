/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { Form, Button, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { isMobileValid } from '@chipcoo/fe-utils';

import { RootState, RootDispatch } from 'src/store';
import style from './index.module.less';
import { AccountParams } from 'src/models/session';

import VerifyCode from './VerifyCode/index';

interface OwnProps {
  formItemLayout?: object;
  submitFormLayout?: object;
}
interface StateProps {
  profile: User;
}
interface DispatchProps {
  patchAccount: (val: AccountParams) => void;
}
type Props = OwnProps & StateProps & DispatchProps & FormComponentProps;
interface State {
  isEditing: boolean;
}
const FormItem = Form.Item;

class MobilePhone extends React.PureComponent<Props, State> {
  private interval;
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  // 清除定时器
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // 验证输入的是否是新手机号
  checkPhone = (rule, value, callback) => {
    const { profile } = this.props;

    if (value) {
      if (value === profile.phone) callback('请输入新手机号');
      else if (!isMobileValid(value)) callback('请输入11位正确的手机号');
      else callback();
    } else {
      callback();
    }
  }

  // 绑定手机号
  handleSubmitMobile = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { form, patchAccount } = this.props;
        const { account, verifyCode } = values;

        try {
          await patchAccount({ account: account.trim(), code: verifyCode.trim() });

          this.setState({ isEditing: false });
        } catch (e) {
          console.error(e);
          const data = e?.response?.data;

          switch (data?.code) {
            case 'COMMON_CAPTCHA_FAILED':
              form.setFields({ verifyCode: { value: verifyCode, errors: [new Error('验证码错误或已失效，请重新获取')] } });
              break;
            default:
              break;
          }
        }
      }
    });
  }

  render () {
    const { form, profile, formItemLayout, submitFormLayout } = this.props;
    const { isEditing } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <div>
          <h3 style={{ display: 'inline-block' }}>手机账号</h3>
          {profile.phone ? <h4 style={{ display: 'inline-block' }}>&nbsp;- {profile.phone}</h4> : ''}
        </div>
        <div className={style.introduction}>
          手机账号可用于登录、找回密码。
        </div>

        <div className={style.operation}>
          {isEditing
            ? (
              <Form className={style.form} onSubmit={this.handleSubmitMobile}>
                <FormItem label="新手机号" {...formItemLayout}>
                  {getFieldDecorator('account', {
                    rules: [
                      { required: true, message: '请输入手机号' },
                      { validator: this.checkPhone }
                    ]
                  })(
                    <Input autoFocus={true} placeholder="请输入手机号" />
                  )}
                </FormItem>

                <VerifyCode
                  form={form}
                  from="phone"
                  captchaBtnClassName="phone-captcha-btn"
                  formItemLayout={formItemLayout}
                />

                <FormItem {...submitFormLayout}>
                  <Button type="primary" htmlType="submit">确定</Button>
                  <Button style={{marginLeft: 8}} onClick={() => this.setState({isEditing: false})}>
                    取消
                  </Button>
                </FormItem>
              </Form>
            )
            : <Button type="primary" onClick={() => this.setState({isEditing: true})}>
              {Boolean(profile.phone) ? '修改手机账号' : '添加手机账号'}
            </Button>}
        </div>
      </div>
    );
  }
}

const mapState = ({ session: { profile } }: RootState) => ({ profile: profile! });
const mapDispatch = ({ session: { patchAccount } }: RootDispatch) => ({ patchAccount });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Form.create()(MobilePhone));
