import React from 'react';

interface Props {
  demandQuantity: number;
  waferCount: number;
  selectionMode?: string;
}

function QuantityText(props: Props) {
  const { demandQuantity, waferCount, selectionMode } = props;
  let quantityText = '需求数量';

  if (selectionMode === 'auto') quantityText = '订单数量';

  return (
    <div>{quantityText}: {selectionMode === 'auto' ? '' : '约'}{demandQuantity}颗(投料数量: {waferCount}片)</div>
  );
}

export default QuantityText;
