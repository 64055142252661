/**
 * 用户发送的普通消息
 */
import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Spin, Icon, Tooltip } from 'antd';
import { get } from 'lodash';
import { getClosetAttrVal } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { LOCAL_PREFIX } from 'src/config/const';
import { ImMessage, RoomUsers } from 'src/models/im/chatPanel.type';

import MessageHeader from './MessageHeader';
import DeletedButton from './DeletedButton';
import MessageAttachment, { getAttachment } from 'src/components/MessageAttachment';
import { HandledMessageContent } from 'src/components';
import { mapUsers, findNameById } from 'src/utils/user';
import { attachmentApi } from 'src/services/net';
import { UserModel } from 'src/models/user/interface';

interface OwnProps {
  isShowAvatar: boolean;
  messageData: ImMessage;
}
interface StateProps {
  currentUserId: string;
  roomUsers: RoomUsers;
  users: UserModel['list']['byId'];
}
interface DispatchProps {
  deleteMessage: (messageId: string) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class NormalUserMessage extends React.PureComponent<P, S> {
  handleDelMessage = (e) => {
    const messageId = getClosetAttrVal(e.currentTarget, 'data-id');

    messageId && this.props.deleteMessage(messageId);
  }

  getUserName = (id) => {
    const { users } = this;

    return findNameById(users, id);
  }

  get users() {
    const { roomUsers, users } = this.props;

    return mapUsers<User>(roomUsers.allIds, users);
  }

  render() {
    const { messageData, isShowAvatar, currentUserId } = this.props;
    const { message, sender, createdAt, _id, attachments } = messageData;
    const isCurrentUser = currentUserId === sender;
    const hasAttachments = !!attachments.length;
    const showLoading = _id.includes(LOCAL_PREFIX);
    let download = '';
    let url = '';

    if (hasAttachments) {
      const attachment = getAttachment(attachments);
      url = attachmentApi.download({ attachmentId: attachment._id, download: true });
      download = get(attachment, 'versions.0.filename') || '';
    }

    return (
      <div className={styles.messageItemWrapper} data-id={_id} >
        {isShowAvatar && <MessageHeader userId={sender} createdAt={createdAt} />}

        <div className={styles.messageContent}>
          <div className={classnames('bubble', { [styles.currentUser]: isCurrentUser})}>
            {
              hasAttachments
                ? <MessageAttachment attachments={attachments} />
                : <HandledMessageContent
                    myUserId={currentUserId}
                    findNameFn={this.getUserName}
                    content={message!}
                />
            }
          </div>
          {!showLoading && <div className={styles.actions}>
            {isCurrentUser && (
              <Tooltip title="删除消息">
                <DeletedButton createdAt={createdAt} deleteMessage={this.handleDelMessage} />
              </Tooltip>
            )}
            {hasAttachments && (
              <Tooltip title="下载附件">
                <a className={styles.actionItem} href={url} download={download}>
                  <Icon style={{ fontSize: '16px' }} className="c-icon" type="cloud-download" />
                </a>
              </Tooltip>
            )}
          </div>}
          <div className={`${styles.status} flex align-center`}>
            <Spin delay={80} size="small" indicator={<Icon type="loading" />} spinning={showLoading}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = ({ chatPanel: { roomUsers }, session, user: { list: { byId } } }: RootState) => ({
  currentUserId: session.profile!._id + '',
  roomUsers,
  users: byId,
});
const mapDispatch = ({ chatPanel: { deleteMessage } }: RootDispatch) => ({
  deleteMessage
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(NormalUserMessage);
