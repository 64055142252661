/**
 *
 */
import * as React from 'react';

interface P {}
interface S {}

class TaskDetail extends React.PureComponent<P, S> {
  render() {
    return (
      <div style={{ width: 100, height: 100, backgroundColor: 'black' }}>
        123
      </div>
    );
  }
}

export default TaskDetail;
