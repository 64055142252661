import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { createSelector } from 'reselect';
import { Checkbox, Spin } from 'antd';
import { stopPropagation } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { DataItem, SelectMemberPanelProps, MemberSelectGroup } from './index';
import { isEqualUserId } from 'src/utils/user';

interface Props {
  render: SelectMemberPanelProps['render'];
  group: MemberSelectGroup;
  dataSource: DataItem[];
  selectedKeys: SelectMemberPanelProps['selectedKeys'];
  handleClickItemCard: any;
  onCheckBoxChange: any;
}

interface State {
  dataSource: DataItem[];
}

const selectDataSource = createSelector(
  (keys: string[]) => keys,
  (_: any, source: DataItem[]) => source,
  (keys, source) => {
    return keys.map(key => {
      return source.find(dataItem => isEqualUserId(dataItem.key, key));
    }).filter(it => !!it) as DataItem[];
  }
);

export class GroupPanel extends PureComponent<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextState: Partial<State> = {};
    nextState.dataSource = selectDataSource(nextProps.group.dataKeys, nextProps.dataSource);

    if (nextState.dataSource === prevState.dataSource) {
      Reflect.deleteProperty(nextState, 'dataSource');
    }

    return nextState;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      dataSource: selectDataSource(props.group.dataKeys, props.dataSource),
    };
  }

  renderCardItem = (item: DataItem) => {
    const { key, checked, disabled } = item;
    const { render, selectedKeys } = this.props;
    const isChecked = Boolean(checked || (selectedKeys || []).includes(key));

    const isDisabled = Boolean(disabled);

    return (
      <div
        key={key}
        data-id={key}
        onClick={this.props.handleClickItemCard}
        className={classnames({ disabled: isDisabled }, styles.contentItem, 'flex card-content-item')}
      >
        <div onClick={stopPropagation}>
          <Checkbox checked={isChecked} disabled={isDisabled} onChange={this.props.onCheckBoxChange} />
        </div>
        <div className="cell">
          {render(item)}
        </div>
      </div>
    );
  }

  renderDataSource() {
    const { dataSource } = this.state;
    return dataSource.map(this.renderCardItem);
  }

  render() {
    const { group } = this.props;
    if (group.loading) {
      return (
        <div className="spin-center">
          <Spin />
        </div>
      );
    }

    if (group.dataKeys.length === 0) {
      return (<p className="member-empty">暂无成员</p>);
    }

    return this.renderDataSource();
  }
}

export default GroupPanel;
