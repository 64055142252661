import { PropsAPI, GGEditorEvent } from 'gg-editor';
import { INPUT_ANCHOR, OUTPUT_ANCHOR } from './constant';

function getAnchorObject(nodeId: string, anchorIndex: number, propsAPI: PropsAPI) {
  const node = propsAPI.find!(nodeId);

  if (node) {
    return node.getAnchorPoints(anchorIndex);
  }

  return null;
}

export function exchangeEdgeArrow(ev: GGEditorEvent, propsAPI: PropsAPI) {
  //
  const { item, originModel, updateModel } = ev;
  if (!item || item.type !== 'edge') return;
  const model = ev.model || { ...originModel, ...updateModel };

  // 如果没有源头或者没有目标节点，不做任何更改
  if (!model.source || !model.target) return;

  const sourceAnchor = getAnchorObject(model.source, model.sourceAnchor, propsAPI);
  const targetAnchor = getAnchorObject(model.target, model.targetAnchor, propsAPI);

  if (sourceAnchor && targetAnchor) {
    // 如果从输入锚点连到输出锚点，箭头方向换向
    if (sourceAnchor.type === INPUT_ANCHOR && targetAnchor.type === OUTPUT_ANCHOR) {
      propsAPI.update!(item, {
        source: model.target,
        sourceAnchor: model.targetAnchor,
        target: model.source,
        targetAnchor: model.sourceAnchor,
      });
    }
  } else {
    console.warn('Not all anchor object found, can\'t exchange edge arrow automatically');
  }
}
