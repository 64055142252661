import React, { FC, useState } from 'react';
import { get } from 'lodash';
import { Icon } from 'antd';
import classnames from 'classnames';
import { FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';

import { createSelector } from 'reselect';
import { getStockOutConfig } from './stockOutConfig';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';
import { CSSTransition } from 'react-transition-group';
import UniverseCommentWrap from '../../components/UniverseCommentWrap';
import { ModalType } from '../StockOutTable';
import store from 'src/store';
import { CommentResource } from 'src/containers/UniverseComment/interface';
import styles from './style.module.less';

interface IProps {
  visible: boolean;
  mode: ModalType;
  onOk?: (values: any) => any;
  onCancel?: () => any;
  formData?: any;
}
const modeTitle = {
  new: '添加',
  reSubmit: '重新提交',
  preview: '预览',
};
interface IState {
  commentVisible: boolean;
  modalFullWindow: boolean;
}

const ApplicationFormModal: FC<IProps> = props => {
  const { visible, mode, onOk, onCancel, formData } = props;
  const [state, setState] = useState<IState>({
    commentVisible: false,
    modalFullWindow: true,
  });
  const {commentVisible, modalFullWindow} = state;

  const selectCommentResource = createSelector(
    (newProps: IProps) => get(newProps.formData, '_id'),
    (id): CommentResource => ({
      role: 'comment',
      id,
      name: 'stockOutApply',
    }),
  );

  const changeCommentVisible = () => {
    setState({
      ...state,
      commentVisible: !commentVisible,
    });
  };

  const onRequestFullWindow = () => {
    setState({
      ...state,
      modalFullWindow: true,
    });
  };

  const onRequestNormalWindow = () => {
    setState({
      ...state,
      modalFullWindow: false,
    });
  };

  let formController: FormController;
  const getFormController = (controller) => formController = controller;
  const globalStatus = mode === 'preview' ? 'preview' : 'edit';

  const getFormConfig = () => {
    return getStockOutConfig(mode);
  };

  const renderComment = () => {
    if (!formData) {
      return null;
    }

    const resource = selectCommentResource(props);
    const commentState = store.getState();
    const userId = get(commentState, 'session.profile._id') || '';
    const wrapClassName = classnames('comment-toggle-btn', commentVisible ? 'comment-visible' : 'comment-invisible');
    return (
      <>
        <div className={wrapClassName} onClick={changeCommentVisible} >
          <Icon
            type={commentVisible ? 'right' : 'left'}
          />
        </div>
        <div>
          <CSSTransition
            in={commentVisible}
            appear={commentVisible}
            timeout={300}
            classNames="universe-comment"
          >
            <UniverseCommentWrap
              userId={userId}
              resource={resource}
              uploaderOptions={{}}
            />
          </CSSTransition>
        </div>
      </>
    );
  };

  const getTitle = () => {
    return `${modeTitle[mode]}出库申请`;
  };

  const handleSubmit = () => {
    return new Promise((resolve) => {
      formController.validateFieldsAndScroll((err, values) => {
        if (err) {
          return resolve(false);
        }
        if (onOk) {
          resolve(onOk(values));
        } else {
          resolve(true);
        }
      });
    });
  };
  const className = classnames('common-flex-modal', styles.modal, commentVisible ? '' : 'full-center');

  return (
    <FlexModal
      width={1200}
      animation="fade"
      visible={visible}
      title={getTitle()}
      onOk={handleSubmit}
      onCancel={onCancel}
      className={className}
      fullWindow={modalFullWindow}
      onRequestFullWindow={onRequestFullWindow}
      onRequestNormalWindow={onRequestNormalWindow}
      rightSection={renderComment()}
      footer={mode === 'preview' && null}
    >
      {visible && (
        <FormCreator
          getFormController={getFormController}
          formConfig={getFormConfig()}
          globalStatus={globalStatus}
          formData={formData ? formData : undefined}
        />
      )}
    </FlexModal>
  );
};

export default ApplicationFormModal;
