/**
 * 运营报表
 */
import * as React from 'react';

class Statement extends React.PureComponent {
  render () {
    return (
      <div>运营报表</div>
    );
  }
}

export default Statement;
