import * as React from 'react';
import { Route } from 'react-router';
import { noop } from '@chipcoo/fe-utils';
import { EnumProductModel } from '@chipcoo/constant';

const ROUTE_NAMES = {
  ORG_ENTRY: 'org_entry',
  ORG_LIST: 'org_list', // 组织列表
  ORG_DETAIL_ENTRY: 'org_detail_entry',
  ORG_DETAIL: 'org_detail', // 组织导航页
  // 组织设置
  ORG_SETTINGS: 'org_settings', // 组织设置
  ORG_SETTINGS_BASIC: 'org_setting_basic', // 组织设置 - 组织信息
  ORG_SETTINGS_MEMBER: 'org_setting_member', // 组织设置 - 组织成员
  ORG_SETTINGS_VERIFY: 'org_setting_verify', // 组织设置 - 组织认证
  ORG_SETTINGS_ADV: 'org_setting_adv', // 组织设置 - 高级设置
  // 协作区
  ORG_COO_LIST: 'org_cooperation_list', // 协作区列表
  ORG_COO_DETAIL: 'org_cooperation_detail', // 协作区 - 主页
  ORG_COO_TAGS: 'org_cooperation_tags', // 协作区 - 标签页
  ORG_COO_TASK: 'org_cooperation_task', // 协作区 - 任务页
  ORG_COO_MOMENTS: 'org_cooperation_moments', // 协作区 - 动态
  ORG_COO_SETTINGS: 'org_cooperation_settings', // 协作区设置
  ORG_COO_SETTINGS_BASIC: 'org_cooperation_settings_basic', // 协作区设置 - 基础设置
  ORG_COO_SETTINGS_ADV: 'org_cooperation_settings_adv', // 协作区设置 - 高级设置
  ORG_COO_SETTINGS_MEMBERS: 'org_cooperation_settings_members', // 协作区设置 - 成员管理
  // 产品管理
  ORG_PROD: 'org_production', // 产品管理
  ORG_PROD_SPW_DIE: 'org_production_spw_die', // 产品管理 - spw芯片
  ORG_PROD_BUMP: 'org_production_bump', // 产品管理 - Bump芯片
  ORG_PROD_ASSEMBLY_PRODUCT: 'org_production_assembly_product', // 产品管理 - 封装产品
  ORG_PROD_CP_DIE: 'org_production_cp_die', // 产品管理 - cp产品
  ORG_PROD_DP_PRODUCT: 'org_production_dp_product', // 产品管理 - dp产品
  ORG_PROD_MEMBER: 'org_production_member', // 产品管理 - 成员管理
  // 运营管理
  ORG_OPERATION: 'org_operation', // 运营管理
  ORG_OPERATION_OUTSOURCE: 'org_operation_outsource_process', // 运营管理 - 外协加工
  ORG_OPERATION_OUTSOURCE_ASSEMBLY_SAMPLE: 'org_operation_outsource_process_assembly_sample', // 运营管理 - 外协加工 - 封装试生产
  ORG_OPERATION_OUTSOURCE_ASSEMBLY_MASS: 'org_operation_outsource_process_assembly_mass', // 运营管理 - 外协加工 - 封装量产
  ORG_OPERATION_OUTSOURCE_CP_SAMPLE: 'org_operation_outsource_process_cp_sample', // 运营管理 - 外协加工 - cp试生产
  ORG_OPERATION_OUTSOURCE_CP_MASS: 'org_operation_outsource_process_cp_mass', // 运营管理 - 外协加工 - cp量产
  ORG_OPERATION_OUTSOURCE_DP_MPW: 'org_operation_outsource_process_dp_mpw', // 运营管理 - 外协加工 - 磨划 - MPW
  ORG_OPERATION_OUTSOURCE_DP_SAMPLE: 'org_operation_outsource_process_dp_sample', // 运营管理 - 外协加工 - 磨划 - 量产试制
  ORG_OPERATION_OUTSOURCE_DP_MASS: 'org_operation_outsource_process_dp_mass', // 运营管理 - 外协加工 - 磨划 - 量产
  ORG_OPERATION_SETTINGS: 'org_operation_outsource_settings', // 运营管理 - 设置
  ORG_OPERATION_DEMAND_ORDER: 'org_operation_demand_order', // 运营管理 - 需求订单列表
  ORG_OPERATION_SETTINGS_MEMBER: 'org_operation_outsource_settings_member', // 运营管理 - 设置 - 成员管理
  ORG_OPERATION_ORDER: 'org_operation_order', // 运营管理 - 委外需求订单
  ORG_OPERATION_ORDER_ASSEMBLY: 'org_operation_order_assembly', // 运营管理 - 委外需求订单 - 封装成品
  ORG_OPERATION_ORDER_DP: 'org_operation_order_dp', // 运营管理 - 委外需求订单 - 磨划成品
  ORG_OPERATION_ORDER_CHIP: 'org_operation_order_chip', // 运营管理 - 委外需求订单 - 晶圆
  ORG_OPERATION_ORDER_CP: 'org_operation_order_cp', // 运营管理 - 委外需求订单 - 中测/已测
  ORG_OPERATION_ORDER_SUMMARY: 'org_operation_order_summary', // 运营管理-订单汇总表
  ORG_OPERATION_INVENTORY: 'org_operation_inventory', // 运营管理 - 库存管理
  ORG_OPERATION_INVENTORY_MATERIAL: 'org_operation_inventory_material', // 运营管理 - 库存管理 - 物料库存
  ORG_OPERATION_INVENTORY_STOCKIN: 'org_operation_inventory_stock_in', // 运营管理 - 库存管理 - 入库申请
  ORG_OPERATION_INVENTORY_STOCKOUT: 'org_operation_inventory_stock_out', // 运营管理 - 库存管理 - 出库申请
  ORG_OPERATION_INVENTORY_STOCKOUTRECORD: 'org_operation_inventory_stock_out_record', // 运营管理 - 库存管理 - 出库记录
  ORG_OPERATION_PROCESS: 'org_operation_process', // 运营管理 - 加工单查询
  ORG_OPERATION_ABNORMALITY: 'org_operation_abnormality', // 运营管理 - 加工单查询
  ORG_OPERATION_STATEMENT: 'org_operation_statement', // 运营管理 - 运营报表
  // 研发管理
  ORG_RD: 'org_rd', // 研发管理
  ORG_RD_MPW_SHUTTLE: 'org_rd_mpw_shuttle', // 研发管理 - mpw班车查询
  ORG_RD_MPW_PRE_ORDER: 'org_rd_mpw_pre_order', // 研发管理 - mpw班车预订记录
  ORG_RD_NTO: 'org_rd_nto', // 研发管理 - NTO项目
  ORG_RD_NTO_PRODUCT: 'org_rd_nto_product', // 研发管理 - NTO产品
  ORG_RD_NTO_LOT: 'org_rd_nto_lot', // 研发管理 - LOT指令
  ORG_RD_QUERY_REPORT: 'org_rd_query_report', // 研发管理 - 报告查询
  ORG_RD_QUERY_REPORT_WIP: 'org_rd_query_report_wip', // 研发管理 - 报告查询 - wip
  ORG_RD_INVENTORY: 'org_rd_inventory', // 研发管理 - 库存
  ORG_RD_SHIPMENT: 'org_rd_shipment', // 研发管理 - 出货记录
  ORG_RD_FAQ: 'org_rd_faq', // 研发管理 - 常见问题
  ORG_RD_TEST: 'org_rd_test', // 研发管理 - 新品测试
  ORG_RD_TEST_CP: 'org_rd_test_cp', // 研发管理 - 新品测试 - 新品CP测试
  ORG_RD_TEST_FT: 'org_rd_test_ft', // 研发管理 - 新品测试 - 新品FT测试
  ORG_RD_TRIAL_PRODUCTION: 'org_rd_trial_production', // 研发管理 - 新品试封
  ORG_RD_CP_NPI: 'org_rd_cp_npi',
  ORG_RD_DP_NPI: 'org_rd_dp_npi',
  ORG_RD_ASSEMBLY_NPI: 'org_rd_assembly_npi',
  ORG_RD_SETTINGS: 'org_rd_settings', // 研发管理 - 设置
  // 质量管理
  ORG_QC: 'org_qc', // 质量管理
  ORG_QC_QS: 'org_qc_qs', // 质量体系
  ORG_QC_FEMA: 'org_qc_fema', // FEMA
  ORG_QC_PCN: 'org_qc_pcn', // PCN
  ORG_QC_CUSTOMER_COMPLAINT: 'org_qc_customer_complaint', // 客诉管理
  ORG_QC_ANALYSIS: 'org_qc_analysis', // 数据分析
  ORG_QC_ANALYSIS_LOT: 'org_qc_analysis_lot', // 批次分析报告
  ORG_QC_ANALYSIS_TREND: 'org_qc_analysis_trend', // 趋势分析报告
  ORG_QC_ANALYSIS_CUSTOMER: 'org_qc_analysis_customer', // 自定义分析报告
  ORG_QC_ANALYSIS_SETTING: 'org_qc_analysis_setting', // 数据解析设置
  ORG_QC_ANALYSIS_SETTING_LOG: 'org_qc_analysis_setting_log', // 数据解析设置-数据解析日志
  ORG_QC_ANALYSIS_SETTING_FTP: 'org_qc_analysis_setting_ftp', // 数据解析设置-FTP
  ORG_QC_ANALYSIS_MONITOR_RULE: 'org_qc_analysis_monitor_rule', // 数据监测设置-监测规则
  ORG_QC_ANALYSIS_MONITOR_CONTACT: 'org_qc_analysis_monitor_contact', // 数据监测设置-告警联系人
  ORG_QC_ANALYSIS_MONITOR_HISTORY: 'org_qc_analysis_monitor_history', // 数据监测设置-告警历史
  ORG_QC_ANALYSIS_TEST_MANAGE: 'org_qc_analysis_test_manage', // 测试项管理
  // 其他
  IM: 'im' + '', // 芯聊
  IM_DETAIL: 'im_detail', // 芯聊 - 聊天页
  USER: 'user', // 个人设置
  NOTIFICATION: 'notification', // 通知
  NOTIFICATION_DETAIL: 'notification_detail', // 通知详情
};

const routes: RouteItem[] = [];

export {
  ROUTE_NAMES,
  routes,
};

import { HoneyRoute, compilePath, CacheSwitch } from 'src/components/HoneyRouter';
import { formatRoutes, RouteItem } from './createRoute';

import {
  OrgEntry,
  OrgDetail,
  OrgDetailEntry,
  OrgSetting,
  OrgList,
} from 'src/pages/Organization';

import { CooList, CooDetail } from 'src/pages/Cooperation';

import User from 'src/pages/User';
import IM from 'src/pages/IM';
import Notification from 'src/pages/Notification';
import Product from 'src/pages/Product';
import Operation from 'src/pages/Operation';
// import OutsourcingProcess from 'src/pages/Operation/OutsourcingProcess';
// import DemandOrder from 'src/pages/Operation/DemandOrder';
// import OperationSettings from 'src/pages/Operation/Settings';
import
  RDManage, {
  MPWShuttle,
  MPWPreOrder,
  NTO,
  RDInventory,
  Shipment,
  QueryReport,
  FAQ,
  NewTest,
} from 'src/pages/RDManage';
// import TrialProduction from 'src/pages/RDManage/TrialProduction';
import Fallback from './components/Fallback';
import { HoneyRouteProps } from 'src/components/HoneyRouter/interface';
import {
  Order,
  InventoryManage,
  Process,
  Abnormality,
  Statement,
  OrderSummary,
} from 'src/pages/Operation';
import QCManage, {
  QS,
  FEMA,
  PCN,
  CustomerComplaint,
  Analysis
} from 'src/pages/QCManage';
// import RedirectToOrg from './components/RedirectToOrg';
// import RDSetting from 'src/pages/ResearchAndDevelop/Setting';

const routeMap: RouteItem[] = [
  {
    path: '/org',
    component: OrgEntry,
    name: ROUTE_NAMES.ORG_ENTRY,
    children: [
      {
        path: '',
        component: OrgList,
        cache: true,
        name: ROUTE_NAMES.ORG_LIST,
        breadcrumbName: '组织',
        exact: true,
      },
      {
        path: ':id',
        name: ROUTE_NAMES.ORG_DETAIL_ENTRY,
        component: OrgDetailEntry,
        children: [
          {
            path: '',
            name: ROUTE_NAMES.ORG_DETAIL,
            component: OrgDetail,
            breadcrumbName: '-', // Anyway, it will be replaced in breadcrumbName layout.
            exact: true,
          },
          {
            path: 'cooperation',
            component: CooList,
            name: ROUTE_NAMES.ORG_COO_LIST,
            breadcrumbName: '团队协作',
            exact: true,
          },
          {
            path: 'cooperation/:workspaceId',
            component: CooDetail,
            name: ROUTE_NAMES.ORG_COO_DETAIL,
            breadcrumbName: '--',  // Anyway, it will be replaced in breadcrumbName layout.
            children: [
              {
                path: '',
                exact: true,
                name: ROUTE_NAMES.ORG_COO_TASK,
              },
              // register first.
              {
                path: 'setting',
                name: ROUTE_NAMES.ORG_COO_SETTINGS,
                children: [
                  {
                    path: '',
                    name: ROUTE_NAMES.ORG_COO_SETTINGS_BASIC,
                    // breadcrumbName: '基础设置',
                  },
                  {
                    path: 'advance',
                    name: ROUTE_NAMES.ORG_COO_SETTINGS_ADV,
                    // breadcrumbName: '高级设置',
                  },
                  {
                    path: 'members',
                    name: ROUTE_NAMES.ORG_COO_SETTINGS_MEMBERS,
                    // breadcrumbName: '成员管理'
                  },
                  {
                    path: 'tags',
                    name: ROUTE_NAMES.ORG_COO_TAGS,
                    // breadcrumbName: '标签',
                  },
                  {
                    path: 'moments',
                    name: ROUTE_NAMES.ORG_COO_MOMENTS,
                    // breadcrumbName: '动态',
                  },
                ]
              }
            ],
          },
          {
            path: 'production',
            name: ROUTE_NAMES.ORG_PROD,
            component: Product,
            breadcrumbName: '产品管理',
            children: [
              {
                path: '',
                children: [
                  {
                    path: 'spw-die',
                    name: ROUTE_NAMES.ORG_PROD_SPW_DIE,
                    // breadcrumbName: '芯片管理',
                    exact: true,
                  },
                  {
                    path: 'assembly-product',
                    name: ROUTE_NAMES.ORG_PROD_ASSEMBLY_PRODUCT,
                    // breadcrumbName: '封装成品',
                    exact: true,
                  },
                  {
                    path: 'dp-product',
                    name: ROUTE_NAMES.ORG_PROD_DP_PRODUCT,
                    exact: true
                  }
                ]
              },
              {
                path: 'member',
                name: ROUTE_NAMES.ORG_PROD_MEMBER,
                // breadcrumbName: '成员管理',
                exact: true,
              },
            ]
          },
          {
            path: 'operation',
            name: ROUTE_NAMES.ORG_OPERATION,
            component: Operation,
            breadcrumbName: '运营管理',
            exact: true,
            children: [
              {
                path: 'order',
                name: ROUTE_NAMES.ORG_OPERATION_ORDER,
                component: Order,
                breadcrumbName: '委外需求订单',
                children: [
                  {
                    path: EnumProductModel.assemblyProduct,
                    name: ROUTE_NAMES.ORG_OPERATION_ORDER_ASSEMBLY,
                    exact: true,
                  },
                  {
                    path: EnumProductModel.dpProduct,
                    name: ROUTE_NAMES.ORG_OPERATION_ORDER_DP,
                    exact: true,
                  },
                ]
              },
              {
                path: 'summary',
                name: ROUTE_NAMES.ORG_OPERATION_ORDER_SUMMARY,
                component: OrderSummary,
                breadcrumbName: '订单汇总表',
              },
              {
                path: 'inventory',
                name: ROUTE_NAMES.ORG_OPERATION_INVENTORY,
                breadcrumbName: '库存管理',
                component: InventoryManage,
                children: [
                  {
                    path: 'material',
                    name: ROUTE_NAMES.ORG_OPERATION_INVENTORY_MATERIAL,
                    exact: true
                  },
                  {
                    path: 'stock-in',
                    name: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKIN,
                    exact: true,
                  },
                  {
                    path: 'stock-out',
                    name: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUT,
                    exact: true,
                  },
                  {
                    path: 'stockout-record',
                    name: ROUTE_NAMES.ORG_OPERATION_INVENTORY_STOCKOUTRECORD,
                    exact: true,
                  }
                ]
              },
              {
                path: 'process',
                name: ROUTE_NAMES.ORG_OPERATION_PROCESS,
                breadcrumbName: '加工单查询',
                component: Process,
              },
              {
                path: 'abnormality',
                name: ROUTE_NAMES.ORG_OPERATION_ABNORMALITY,
                breadcrumbName: '生产异常管理',
                component: Abnormality,
              },
              {
                path: 'statement',
                name: ROUTE_NAMES.ORG_OPERATION_STATEMENT,
                breadcrumbName: '运营报表',
                component: Statement,
              },
              // {
              //   path: 'settings',
              //   name: ROUTE_NAMES.ORG_OPERATION_SETTINGS,
              //   component: OperationSettings,
              //   breadcrumbName: '设置',
              //   children: [
              //     {
              //       path: 'member',
              //       children: [
              //         {
              //           path: 'member',
              //           name: ROUTE_NAMES.ORG_OPERATION_SETTINGS_MEMBER,
              //           exact: true,
              //         },
              //       ]
              //     },
              //   ]
              // },
            ]
          },
          {
            path: 'rd',
            name: ROUTE_NAMES.ORG_RD,
            component: RDManage,
            breadcrumbName: '研发管理',
            exact: true,
            children: [
              {
                path: 'mpw-shuttle/:factoryId?',
                name: ROUTE_NAMES.ORG_RD_MPW_SHUTTLE,
                component: MPWShuttle,
                breadcrumbName: 'MPW班车查询及预订',
                exact: true,
              },
              {
                path: 'mpw-pre-order/:preOrderId?',
                name: ROUTE_NAMES.ORG_RD_MPW_PRE_ORDER,
                component: MPWPreOrder,
                breadcrumbName: 'MPW班车预订记录',
              },
              {
                path: 'nto',
                name: ROUTE_NAMES.ORG_RD_NTO,
                component: NTO,
                breadcrumbName: 'NTO项目',
                children: [
                  {
                    path: 'product',
                    name: ROUTE_NAMES.ORG_RD_NTO_PRODUCT,
                    // exact: true
                  },
                  {
                    path: 'lot',
                    name: ROUTE_NAMES.ORG_RD_NTO_LOT,
                    // exact: true,
                  }
                ]
              },
              {
                path: 'inventory',
                name: ROUTE_NAMES.ORG_RD_INVENTORY,
                component: RDInventory,
                breadcrumbName: '库存',
              },
              {
                path: 'shipment',
                name: ROUTE_NAMES.ORG_RD_SHIPMENT,
                component: Shipment,
                breadcrumbName: '出货记录',
              },
              {
                path: 'query-report',
                name: ROUTE_NAMES.ORG_RD_QUERY_REPORT,
                component: QueryReport,
                breadcrumbName: '报告查询',
                children: [
                  {
                    path: 'wip',
                    name: ROUTE_NAMES.ORG_RD_QUERY_REPORT_WIP,
                    exact: true,
                  }
                ]
              },
              {
                path: 'faq',
                name: ROUTE_NAMES.ORG_RD_FAQ,
                component: FAQ,
                breadcrumbName: '常见问题',
              },
              {
                path: 'new-test',
                name: ROUTE_NAMES.ORG_RD_TEST,
                component: NewTest,
                breadcrumbName: '新品测试',
                children: [
                  {
                    path: 'cp',
                    name: ROUTE_NAMES.ORG_RD_TEST_CP,
                    // exact: true
                    // breadcrumbName: '新品CP测试'
                  },
                  {
                    path: 'ft',
                    name: ROUTE_NAMES.ORG_RD_TEST_FT,
                    // exact: true,
                  }
                ]
              }
            ]
          },
          {
            path: 'qc',
            name: ROUTE_NAMES.ORG_QC,
            component: QCManage,
            breadcrumbName: '质量管理',
            exact: true,
            children: [
              {
                path: 'qs',
                name: ROUTE_NAMES.ORG_QC_QS,
                component: QS,
                breadcrumbName: '质量体系',
              },
              {
                path: 'fema',
                name: ROUTE_NAMES.ORG_QC_FEMA,
                component: FEMA,
                breadcrumbName: 'FEMA',
              },
              {
                path: 'pcn',
                name: ROUTE_NAMES.ORG_QC_PCN,
                component: PCN,
                breadcrumbName: 'PCN管理',
              },
              {
                path: 'customer-complaint',
                name: ROUTE_NAMES.ORG_QC_CUSTOMER_COMPLAINT,
                component: CustomerComplaint,
                breadcrumbName: '客诉管理',
              },
              {
                path: 'analysis',
                name: ROUTE_NAMES.ORG_QC_ANALYSIS,
                component: Analysis,
                breadcrumbName: '数据分析',
                children: [
                  {
                    path: 'lot',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_LOT,
                  },
                  {
                    path: 'trend',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_TREND,
                  },
                  {
                    path: 'customer',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_CUSTOMER
                  },
                  {
                    path: 'log',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_SETTING_LOG,
                  },
                  {
                    path: 'ftp',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_SETTING_FTP,
                  },
                  {
                    path: 'rule',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_RULE,
                  },
                  {
                    path: 'contact',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_CONTACT,
                  },
                  {
                    path: 'history',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_MONITOR_HISTORY,
                  },
                  {
                    path: 'test-item',
                    name: ROUTE_NAMES.ORG_QC_ANALYSIS_TEST_MANAGE,
                  }
                ]
              }
            ]
          },
          {
            path: 'setting',
            component: OrgSetting,
            name: ROUTE_NAMES.ORG_SETTINGS,
            breadcrumbName: '组织设置',
            children: [
              {
                path: '',
                name: ROUTE_NAMES.ORG_SETTINGS_BASIC,
              },
              {
                path: 'member',
                name: ROUTE_NAMES.ORG_SETTINGS_MEMBER,
              },
              {
                path: 'advance',
                name: ROUTE_NAMES.ORG_SETTINGS_ADV,
              },
              {
                path: 'verify',
                name: ROUTE_NAMES.ORG_SETTINGS_VERIFY,
              }
            ]
          }
        ]
      },
    ],
  },
  {
    path: '/user',
    component: User,
    name: ROUTE_NAMES.USER,
    breadcrumbName: '用户设置',
  },
  {
    path: '/notification',
    breadcrumbName: '通知',
    children: [
      {
        path: '',
        cache: true,
        component: Notification,
        name: ROUTE_NAMES.NOTIFICATION,
      },
      {
        path: ':id',
        name: ROUTE_NAMES.NOTIFICATION_DETAIL,
      }
    ],
  },
  {
    path: '/im',
    children: [
      {
        path: '',
        cache: true,
        component: IM,
        name: ROUTE_NAMES.IM,
        breadcrumbName: '芯聊',
      },
      // register first.
      {
        path: ':id',
        name: ROUTE_NAMES.IM_DETAIL,
        // breadcrumbName: '',
      }
    ]
  },
  // {
  //   path: '*',
  // }
];

routes.push(...formatRoutes(routeMap));

routes.forEach(function(route: RouteItem) {
  const { use, children, ...props } = route;
  noop(use, children);
  HoneyRoute.registerRoute(props as any);
});

export const NamedRoutes: { [code in keyof typeof ROUTE_NAMES]: RouteItem } = (() =>
  Object.entries(ROUTE_NAMES).reduce((all, [key, val]) => {
    const find = routes.find(route => route.name === val);

    if (find) {
      all[key] = find;
    } else {
      //
    }

    return all;
  }, {})
)() as any;

export const NamedRouteComponents: {
  [code in keyof typeof ROUTE_NAMES]: (props?: Partial<HoneyRouteProps>) => React.ReactNode
} = (() =>
  Object.entries(NamedRoutes).reduce((all, [key, providedProps]) => {
    all[key] = (props: Partial<HoneyRouteProps> = {}) => {
      const newProps: any = {
        ...providedProps,
        ...props,
      };

      return providedProps.cache
        // ? <CacheRoute {...providedProps} {...props} />
        ? <HoneyRoute {...newProps} />
        : <HoneyRoute {...newProps} />;
    };

    return all;
  }, {})
)() as any;

export function createFastRoute(name: string) {
  return function() {
    return compilePath({ name });
  };
}

export const fastOrgPath = createFastRoute(ROUTE_NAMES.ORG_LIST);

const appRoute = (
  <>
    <CacheSwitch>
      {/* <Route path="/" exact={true} component={RedirectToOrg}/> */}
      {NamedRouteComponents.USER()}
      {NamedRouteComponents.ORG_ENTRY()}
      {NamedRouteComponents.NOTIFICATION()}
      {NamedRouteComponents.IM()}
    </CacheSwitch>
    <Route component={Fallback} />
  </>
);

// const AppRoute = (
//   <>
//     {routes.filter(r => !!r.component).map((props, i) => {
//       if (props.cache) {
//         return <CacheRoute key={i} {...props}/>;
//       }
//       return (<HoneyRoute key={i} {...props} />);
//     })}
//     <Route component={Fallback} />
//   </>
// );
// noop(AppRoute);
// tslint:disable:max-file-line-count
export default appRoute;
