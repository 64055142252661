// tslint:disable:max-file-line-count
import { get, flow, omit, isEmpty } from 'lodash';
import moment from 'moment';
import { EnumProductModel, EnumProcessClass } from '@chipcoo/constant';
import { getMomentTime, getUserName, isDataEmpty } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';
import {
  booleanStringConvert,
  // excludeEmptyArray,
  // excludeEmptyObjectArray,
  setValByPath,
  // uploadFormAttachment
} from 'src/utils';
import { DpDieDetail, OriginDieDetail } from './interface';

export const handleProductList = (respData: any[], orgMembers?: Obj) => (respData || []).map(it => {
  const { _id, product, principal } = it;
  let formData = {};
  const member = orgMembers?.[principal];

  if (it?.productModel === EnumProductModel.spwDie) {
    const maskProperty = product?.mask?.maskProperty;
    const maskSetupTime = product?.mask?.maskSetupTime;
    const dieNameInFoundry = get(product, 'schemes.0.dieNameInFoundry');
    let dieSize = get(product, 'info.dieSize');
    dieSize = Array.isArray(dieSize) && !dieSize.every(isDataEmpty) ? dieSize.join('µm x ') + 'µm' : void 0;

    formData = {
      dieNameInFoundry,
      dieSize,
      maskProperty: maskProperty && optionsGroup.maskProperty.options![maskProperty],
      initialVersionDieName: product?.mask?.initialVersionDieName,
      reversion: product?.mask?.reversion,
      maskSetupTime: maskSetupTime && getMomentTime(maskSetupTime),
      waferDiameter: product?.info?.waferDiameter,
      waferTechnology: product?.info?.waferTechnology,
      perWaferDieQty: product?.perWaferDieQty ? product?.perWaferDieQty + '颗' : product?.perWaferDieQty,
      // factories: product?.schemes
    };
  }

  if (it?.productModel === EnumProductModel.dpProduct) {
    const packing = it?.packing;
    const schemes = it?.schemes;

    formData = {
      dieName: get(product, 'die.customerName'),
      waferDiameter: get(product, 'die.product.info.waferDiameter'),
      waferTechnology: get(product, 'die.product.info.waferTechnology'),
      packingMethod: (packing || []).map(p => p.packingMethod).join(', '),
      factories: schemes
    };
  }

  if (it?.productModel === EnumProductModel.assemblyProduct) {
    const packageType = product?.packageType?.packageType;
    const packageTypeLW = product?.packageType?.packageTypeLW;
    const environmentalRequirement = product?.requirement?.environmentalRequirement;
    const MSL = product?.requirement?.MSL;
    const markingMethod = product?.marking?.markingMethod;
    const markingRule = product?.marking.markingRule;
    const dies = product?.dies;

    formData = {
      packageType: packageType && optionsGroup.packageType_1.options![packageType],
      packageTypeLW,
      environmentalRequirement,
      MSL,
      markingMethod,
      markingRule,
      packingMethod: (it?.packing || []).map(p => p.packingMethod).join(', '),
      factories: it?.schemes,
      dieName: (dies || []).map(die => get(die, 'die.customerName')).join(', '),
    };
  }

  return {
    ...it,
    key: _id,
    ...formData,
    principalName: member && getUserName(member),
  };
});

/*************************** spw-die **********************************/
const bool2stringPathsBySpw = (prevText?: string) => {
  const prev = prevText ? `${prevText}.` : '';

  return [
    `${prev}info.isCircuitUnderPad`,
    `${prev}info.isDeviceUnderPad`,
    `${prev}info.isLowKProcess`,
    `${prev}info.isPassivationInSawStreet`,
    'isHVMReady',
    'canSmartSelection',
  ];
};

export const handleNewSpwDieData = async (formData: Obj) => {
  // let data = flow([
  //   booleanStringConvert(bool2stringPathsBySpw(), 'string'),
  //   excludeEmptyArray
  // ])(formData);
  //
  // data = await uploadFormAttachment(data, spwAttachmentInfo, {});
  //
  // setValByPath(
  //   data,
  //   'mask.initialVersionDieName',
  //   val => val.key
  // );
  //
  // data.product = {
  //   mask: data?.mask,
  //   schemes: data?.schemes,
  //   info: data?.info,
  //   perWaferDieQty: data?.perWaferDieQty
  // };
  //
  // return omit(data, ['mask', 'schemes', 'info', 'perWaferDieQty']);
};

export async function handleSpwDieDetailData(spwDieDetail: OriginDieDetail) {
  const data = flow([
    booleanStringConvert(bool2stringPathsBySpw('product'), 'boolean')
  ])(spwDieDetail);

  setValByPath(
    data,
    'product.mask.maskSetupTime',
    val => moment(val)
  );

  setValByPath(
    data,
    'product.mask.initialVersionDieName',
    val => ({ key: val })
  );

  return {
    ...data,
    ...data?.product,
  };
}

/*************************** dp-product **********************************/
const bool2stringPathsBydp = [
  'packing.{{index}}.isDefault',
  'isHVMReady',
  'canSmartSelection',
];

export const handleDpData = async (formData: Obj) => {
  // let data = flow([
  //   booleanStringConvert(bool2stringPathsBydp, 'string'),
  //   excludeEmptyArray
  // ])(formData);
  //
  // const schemes = get(data, 'schemes');
  //
  // data.schemes = (schemes || []).map(item => ({
  //   ...item,
  //   factory: get(item, 'factory.key') || get(item, 'factory')
  // }));
  //
  // setValByPath(
  //   data,
  //   'die',
  //   val => val.key
  // );
  //
  // data = excludeEmptyObjectArray(data, ['schemes']);
  //
  // data.product = {
  //   die: data?.die?.key || data?.die,
  //   requirement: data?.requirement
  // };
  //
  // return omit(data, ['requirement', 'die']);
};

export const handleDppDetail = (dppDetail: DpDieDetail) => {
  const data = flow([
    booleanStringConvert(bool2stringPathsBydp, 'boolean')
  ])(dppDetail);

  const schemes = get(data, 'schemes');

  data.schemes = (schemes || []).map(item => ({
    ...item,
    factory: { key: get(item, 'factory'), label: get(item, 'factoryName') }
  }));

  setValByPath(
    data,
    'product.die',
    val => ({ key: get(val, '_id'), label: get(val, 'customerName') })
  );

  return {
    ...data,
    ...data?.product,
  };
};

/*************************** assembly-product **********************************/
const bool2stringPathsByAssembly = (prevText?: string) => {
  const prev = prevText ? `${prevText}.` : '';

  return [
    `${prev}requirement.thermalConductivityRequirement`,
    'packing.{{index}}.isDefault',
    // 'ft.isNeedFt',
    'isHVMReady',
    'canSmartSelection'
  ];
};

export const handleAssemblyProductDetail = async (detail: any) => {
  const data = flow([
    booleanStringConvert(bool2stringPathsByAssembly('product'), 'boolean')
  ])(detail);

  // data = await getAttachmentDetailByIds(data, attachmentInfo);

  const dies = get(data, 'product.dies');

  data.dies = dies.map(item => ({
    ...item,
    die: { key: item?.die?._id, label: item?.die?.customerName }
  }));

  /* 包装方式 */
  const packing = get(data, 'packing');

  data.packing = (packing || []).map(item => {
    const packingMethod = get(item, 'packingMethod');

    if (packingMethod === '管装') {
      return {
        ...item,
        tube: {
          perPackQty: item.perPackQty,
          perBoxQty: item.perBoxQty,
        }
      };
    }

    if (packingMethod === '盘装') {
      return {
        ...item,
        tray: {
          perPackQty: item.perPackQty,
          perBoxQty: item.perBoxQty,
        }
      };
    }

    if (packingMethod === '袋装') {
      return {
        ...item,
        bag: {
          perPackQty: item.perPackQty,
          perBoxQty: item.perBoxQty,
        }
      };
    }

    return item;
  });

  /* 封装方案 */
  const schemes = get(data, 'schemes');

  data.ftSchemes = (data.ftSchemes || []).map((item) => {
    return {
      ...item,
      factory: {
        key: get(item, 'factory'),
        label: get(item, 'factoryName'),
      },
    };
  });

  data.schemes = (schemes || []).map(item => {
    let defaultData = {
      ...item,
      factory: { key: get(item, 'factory'), label: get(item, 'factoryName') }
    };

    const attachments = item.attachments;

    if (attachments) {
      attachments.forEach(attachment => {
        defaultData = Object.assign({
          ...defaultData,
          [attachment.name]: {
            attachmentId: attachment.attachmentId,
            serial: attachment.serial,
            rev: attachment.rev
          }
        });
      });
    }

    return defaultData;
  });

  return {
    ...data,
    ...omit(data?.product, 'dies'),
  };
};

export const handleOrderTemplateList = (respData, productPacking) => {
  return (respData || []).map(it => {
    const { _id, packing } = it;

    const packingInfo = (productPacking || []).find(i => i?._id === packing);

    return {
      ...it,
      key: _id,
      packingMethod: packingInfo?.packingMethod,
    };
  });
};

// 处理产品制程详情数据
export const handleProductProcess = (respData: any) => {
  const data = respData.map(it => {
    let { _id, scheme, processClass, craftRoute } = it;
    let newScheme;

    if (processClass === EnumProcessClass.assembly) {
      if (scheme) {
        let newAttachments;

        if (!isEmpty(scheme?.attachments)) {
          newAttachments = scheme?.attachments.reduce(
            (prev, cur) => {

              prev[cur.name] = {
                attachmentId: cur.attachmentId,
                serial: cur.serial,
                rev: cur.rev,
              };

              return prev;
            },
            {}
          );
        }

        if (scheme?.bom) {
          const dieAttachMaterial = scheme?.bom?.dieAttachMaterial;
          const dieAttachMaterialProvider = scheme?.bom?.dieAttachMaterialProvider;
          const dieAttachMaterialModel = scheme?.bom?.dieAttachMaterialModel;

          scheme = {
            ...scheme,
            bom: {
              ...scheme?.bom,
              [`dieAttachMaterialProvider_${dieAttachMaterial}`]: dieAttachMaterialProvider,
              [`dieAttachMaterialModel_${dieAttachMaterial}`]: dieAttachMaterialModel,
            }
          };
        }

        newScheme = {
          ...scheme,
          ...newAttachments,
        };
      }
    }

    return {
      ...it,
      key: _id,
      scheme: {
        ...it?.scheme,
        ...newScheme
      },
      craftRoute: craftRoute?._id || craftRoute
    };
  });

  return data;
};
