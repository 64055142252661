import * as React from 'react';

const typeText = {
  title: '异常类型',
  key: 'typeText',
  dataIndex: 'typeText',
};

const productName = {
  title: '产品名称',
  key: 'productName',
  dataIndex: 'productName',
};

const dieNumbers = {
  title: '批号',
  key: 'dieNumbers',
  dataIndex: 'dieNumbers',
};

const createdAtText = {
  title: '创建时间',
  key: 'createdAtText',
  dataIndex: 'createdAtText',
};

const result = {
  title: '处理结果',
  key: 'result',
  dataIndex: 'result',
};

const statusText = {
  title: '状态',
  key: 'statusText',
  dataIndex: 'statusText',
};

const getOperation = (option: Option) => {
  const { openModal } = option;

  return ({
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    render: (text, record) => {
      const _id = record?._id;

      return (
        <a onClick={() => openModal(_id)}>查看</a>
      );
    }
  });
};

interface Option {
  openModal: (_id: string) => void;
}

export const getTableColumns = (option: Option) => [
  typeText,
  productName,
  dieNumbers,
  createdAtText,
  result,
  statusText,
  getOperation(option),
];
