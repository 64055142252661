import React, { FC, useEffect, useRef } from 'react';
import G6 from '@antv/g6';
import { EnumMpwBusPreorderStatus } from '@chipcoo/constant';
import { FlexModalEvents, FlexModalManager, IFlexModalEventsPayload } from '@chipcoo/hanayo/lib/flex-modal';
import { GraphData, TreeGraphData } from '@antv/g6/lib/types';

interface Props {
  data: GraphData | TreeGraphData;
  modalName: string;
  status?: EnumMpwBusPreorderStatus;
}

G6.registerNode(
  'background-animate',
  {
    afterDraw(cfg: any, group: any) {
      const { style } = cfg;
      const width = style?.width;
      const height = style?.height;
      const color = '#40a9ff';

      const attrs = {
        // 减去node宽高的一半
        x: 0 - width / 2,
        y: 0 - height / 2,
        width,
        height,
        stroke: 'black',
        radius: 2,
        fill: color,
        opacity: 0.6
      };
      const addPixel = 20;
      const backAttrs = {
        // 减去node(宽高 + 增大的值) / 2
        x: 0 - (width + addPixel) / 2,
        y: 0 - (height + addPixel) / 2,
        width: width + addPixel,
        height: height + addPixel,
        opacity: 0
      };

      const back1 = group.addShape('rect', {
        zIndex: -3,
        attrs,
        // must be assigned in G6 3.3 and later versions. it can be any value you want
        name: 'rect-shape'
      });
      const back2 = group.addShape('rect', {
        zIndex: -2,
        attrs,
        name: 'rect-shape'
      });
      const back3 = group.addShape('rect', {
        zIndex: -1,
        attrs,
        name: 'rect-shape'
      });
      group.sort(); // Sort according to the zIndex
      back1.animate(
        backAttrs,
        {
          duration: 3000,
          easing: 'easeCubic',
          delay: 0,
          repeat: true // repeat
        }
      ); // no delay
      back2.animate(
        backAttrs,
        {
          duration: 3000,
          easing: 'easeCubic',
          delay: 1000,
          repeat: true // repeat
        }
      ); // 1s delay
      back3.animate(
        backAttrs,
        {
          duration: 3000,
          easing: 'easeCubic',
          delay: 2000,
          repeat: true // repeat
        }
      ); // 2s delay
    }
  },
  'rect'
);

G6.registerEdge(
  'circle-running',
  {
    afterDraw(cfg: any, group: any) {
      const shape = group.get('children')[0];
      const startPoint = shape.getPoint(0);

      const circle = group.addShape('circle', {
        attrs: {
          // 这里为什么补70和50，这两个数值还不知道；但是不补的话会发生横向和纵向的偏移
          x: startPoint.x + 70,
          y: startPoint.y + 50,
          fill: '#1890ff',
          r: 3,
        },
        name: 'circle-shape',
      });

      circle.animate(
        (ratio) => {
          const tmpPoint = shape.getPoint(ratio);
          return {
            x: tmpPoint.x,
            y: tmpPoint.y,
          };
        },
        {
          repeat: true, // Whether executes the animation repeatly
          duration: 2000, // the duration for executing once
        },
      );
    },
  },
  'line', // extend the built-in edge 'cubic'
);

const MpwFlow: FC<Props> = props => {
  let { data, modalName, status } = props;
  const ref = useRef(null);
  let graph: any = null;

  const getWidth = () => {
    let width = 0;
    const chipwingModal = document.querySelector('.flow-in-flex-modal .chipwing-modal');

    if (chipwingModal) {
      // 减去padding
      width = chipwingModal?.clientWidth - 16 * 2 - 24 * 2;
    }

    return width;
  };

  useEffect(() => {
    if (!graph) {
      const container: any = ref.current!;
      let width = getWidth();
      let height = 100;

      if (modalName) {
        FlexModalManager.on(FlexModalEvents.onResize, (payload?: IFlexModalEventsPayload) => {
          if (payload && (payload.modalName !== modalName)) {
            return;
          }

          if (container) {
            width = getWidth();

            graph.changeSize(width, height);
            graph.render();
          }
        });
      }

      graph = new G6.Graph({
        container,
        width,
        height,
        fitView: true,
        fitViewPadding: 10,
        maxZoom: 1,
        modes: {
          default: ['drag-canvas'],
        },
        layout: {
          type: 'dagre',
          rankdir: 'LR',
          // align: 'UL',
          // controlPoints: true,
          // nodesepFunc: () => 1,
          // ranksepFunc: () => 1,
        },
        defaultNode: {
          // size: [30, 20],
          type: 'rect',
          // style: {
          //   stroke: '#000000A6',
          //   fill: '#ffffff',
          //   width: 100,
          // },
          labelCfg: {
            style: {
              fontSize: 20,
            },
          },
        },
        defaultEdge: {
          // type: 'line-dash',
          style: {
            // endArrow: true,
            // lineWidth: 5
          },
          // size: 1,
          // color: '#e2e2e2',
          // style: {
          //   endArrow: {
          //     path: 'M 0,0 L 8,4 L 8,-4 Z',
          //     fill: '#ff4d11',
          //   },
          //   radius: 20,
          // },
        },
      });
    }

    graph.node((node) => {
      let nodeItem = {
        ...node,
        style: {
          stroke: '#000000A6',
          fill: '#ffffff',
          width: 100,
          height: 32,
          radius: 2
        },
        type: node?.id === status ? 'background-animate' : 'rect'
      };

      return nodeItem;
    });

    graph.edge((edge) => {
      let edgeItem = {
        ...edge,
        style: {
          stroke: 'grey',
        },
        type: edge?.source === status ? 'circle-running' : 'line',
      };

      return edgeItem;
    });

    graph.data(data);
    graph.render();
  }, []);

  return (
    <div ref={ref} />
  );
};

export default MpwFlow;
// tslint:disable:max-file-line-count
