import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { InitialState, OperatonMember } from './interface';
import { operationSettingsApi } from 'src/services/net';
import { isEqualUserId } from 'src/utils/user';

const initialState: InitialState = {
  members: [],
};

export default createModel({
  state: initialState,
  reducers: {
    setMembers(state: InitialState, members: OperatonMember[]) {
      return {
        ...state,
        members,
      };
    },
    addMembers(state: InitialState, payload: OperatonMember[] ) {
      return produce(state, draft => {
        const { members } = draft;

        payload.forEach(member => {
          const { role, user } = member;
          const find = members.find(it => isEqualUserId(member.user, it.user));
          if (!find) {
            members.push({
              user,
              role: role || 'MEMBER',
            });
          }
        });
      });
    },
    setMemberRole(state: InitialState, payload: NormalMember) {
      return produce(state, draft => {
        const find = draft.members.find(it => isEqualUserId(it.user, payload.user));

        if (find) {
          find.role = payload.role;
        }
      });
    },
    removeMember(state: InitialState, userId: string | number) {
      return produce(state, draft => {
        const index = draft.members.findIndex(it => isEqualUserId(userId, it.user));
        if (index !== -1) {
          draft.members.splice(index, 1);
        }
      });
    },

  },
  effects: (dispatch) => ({
    async fetchMembers() {
      const { data } = await operationSettingsApi.getMembers();
      data.forEach((item) => {
        item.user = item._id;
      });
      this.setMembers(data);
    },
    // 添加成员
    async asyncAddMember(members: Member[]) {
      await operationSettingsApi.addMember(members);
      this.addMembers(members);
    },
    async asyncSetMemberRole(member: NormalMember) {
      await operationSettingsApi.setMemberRole(member);
      this.setMemberRole(member);
    },
    async asyncRemoveMember(memberId: string | number) {
      await operationSettingsApi.removeMember(memberId);
      this.removeMember(memberId);
    },
  })
});
