// import * as React from 'react';
// import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';

export const filterFormConfig: FormItemConfig[] = [
  {
    label: '物料名称',
    type: 'input',
    dataKey: 'name',
  },
];
