/**
 * Created by marvin on 18/8/15.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import classnames from 'classnames';

import style from './index.module.less';
import { RootState, RootDispatch } from 'src/store';
import { Verify, IOrgDetail } from 'src/models/org/interface';

import ReadyStart from './ReadyStart';
import StartCertification from './StartCertification/StartCertification';
import VerifyDetail from './VerifyDetail';

interface OwnProps {
  orgId: string;
}
interface StateProps {
  orgVerify: Verify;
  orgInfo: IOrgDetail;
}
interface DispatchProps {
  getVerify: (orgId: string) => void;
}
type Props = OwnProps & StateProps & DispatchProps;
interface State {
  isStart: boolean;
  loading: boolean;
}

class Certification extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStart: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const { orgId, getVerify } = this.props;
    await getVerify(orgId);
    const { orgVerify } = this.props;

    if (!orgVerify.status) {
      this.setState({ loading: false });
    } else if (orgVerify.status !== 'ACCEPTED') {
      this.setState({
        isStart: true,
        loading: false
      });
    }
  }

  render () {
    const { isStart, loading } = this.state;
    const { orgVerify, orgId, orgInfo } = this.props;

    return (
      <div 
        className={
          classnames(
            isStart ? style.isStart : '',
            style.verify,
            'y-scroll thin-scroll'
          )
        }
      >
        {
          orgVerify.status === 'ACCEPTED'
            ? <VerifyDetail verifyInfo={orgVerify} orgInfo={orgInfo} />
            : (
              isStart
                ? <StartCertification orgId={orgId} status={orgVerify.status} />
                : <ReadyStart loading={loading} onStart={() => this.setState({isStart: true})} />
            )
        }
      </div>
    );
  }
}

const mapState = ({ org: { orgList: { byId }, orgVerify } }: RootState, props: OwnProps) => ({
  orgInfo: get(byId, props.orgId),
  orgVerify: orgVerify
});
const mapDispatch = ({ org: { getVerify } }: RootDispatch) => ({ getVerify });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(Certification);
