import * as React from 'react';
import { Icon } from 'antd';
// import classnames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import styles from './index.module.less';

import TaskCard from '../TaskCard';
import { HoverScrollBarContainer } from 'src/components';

interface InnerListProps {
  taskIds: string[];
}

class InnerList extends React.PureComponent<InnerListProps> {
  render() {
    return this.props.taskIds.map((taskId, index: number) => (
      <Draggable key={taskId} draggableId={taskId} index={index}>
        {(
          dragProvided,
          dragSnapshot,
        ) => (
          <TaskCard
            key={taskId}
            taskId={taskId}
            provided={dragProvided}
          />
        )}
      </Draggable>
    ));
  }
}

interface StageContentProps {
  listId: string;
  listType: string;
  taskIds: string[];
  isDropDisabled?: boolean;
}

class StageContent extends React.PureComponent<StageContentProps> {
  render() {
    const {
      isDropDisabled,
      listId,
      listType,
      taskIds,
    } = this.props;

    return (
      <Droppable
        droppableId={listId}
        type={listType}
        isDropDisabled={isDropDisabled}
      >
        {(dropProvided, dropSnapshot) => (
          <div
            className={styles.contentWrapper}
            {...dropProvided.droppableProps}
          >

            <HoverScrollBarContainer width={264} className={styles.content}>
              <div ref={dropProvided.innerRef}>
                <InnerList taskIds={taskIds} />

                {dropProvided.placeholder}
              </div>
            </HoverScrollBarContainer>

            <div className={styles.addNew}>
              <a>
                <Icon type="plus-circle-o" />
                添加任务
              </a>
            </div>
          </div>
        )}
      </Droppable>
    );
  }
}

export default StageContent;
