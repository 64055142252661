/**
 * MPW预定说明
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import { CardTableV2 } from '@chipcoo/hanayo';

import { MPWFlowChart } from 'src/assets/images/rdManage';
import './index.less';

const MPWPreOrderState: FC<{}> = () => {
  return (
    <div className={classNames('wrap-content', 'mpw-preorder-state')}>
      <CardTableV2
        card={{
          title: 'MPW预定说明',
        }}
      >
        <h4>1.MPW班车说明</h4>
        <p>1.1 在非量产阶段多家客户选择同一家FAB的同一个工艺，共用一套 mask 来生产样品，降低成本。</p>
        <p>1.2 MPW 最终得到的是 FAB 切割好的固定样品数量，而不是整张的晶圆。假如客户有整张晶圆需求，FAB 会将其他客户的电路图形用激光蚀，避免知识产权方面的泄露。</p>
        <h4>2.MPW流程图</h4>
        <img src={MPWFlowChart} />
        <h4>3.各时间节点</h4>
        <p>3.1 预订截止时间：发车前 50 天。</p>
        <p>3.2 取消预定截止时间：发车前 30 天。</p>
        <p>3.3 付款截止时间（不可退款时间）：发车前 30 天。</p>
        <p>3.4 数据提交截止时间：发车前 14 天。</p>
        <h4>4. 各流片厂 MPW Block 大小和 Die 数量</h4>
        <table className="table-style">
          <thead>
            <tr>
              <td>FAB</td>
              <td>Node(nm)</td>
              <td>Block Size(mm2)</td>
              <td>Die Num(颗)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={6}>TSMC</td>
              <td>16</td>
              <td>4</td>
              <td rowSpan={5}>100</td>
            </tr>
            <tr>
              <td>28</td>
              <td>6</td>
            </tr>
            <tr>
              <td>40</td>
              <td>9</td>
            </tr>
            <tr>
              <td>55,65</td>
              <td>12</td>
            </tr>
            <tr>
              <td>90</td>
              <td>16</td>
            </tr>
            <tr>
              <td>≥130</td>
              <td>25</td>
              <td>40</td>
            </tr>
            <tr>
              <td>GSMC</td>
              <td>ALL</td>
              <td>25</td>
              <td>50</td>
            </tr>
            <tr>
              <td>GF</td>
              <td>ALL</td>
              <td>9</td>
              <td>50</td>
            </tr>
            <tr>
              <td>CSMC</td>
              <td>ALL</td>
              <td>25</td>
              <td>50</td>
            </tr>
          </tbody>
        </table>
        <h4>5.注意事项</h4>
        <p>5.1 除却 TSMC MPW 班车之外，其余 FAB 可能会根据班车预定情况调整班车开车时间，提前、延后都有可能，有时甚至发生班车取消的情况。当然，有些工艺凑够客户数量之后也有加开班车的可能。</p>
        <p>5.2 MPW 班车一般开的都是标准工艺，假如有特殊需求，例如bumping，CornerSplit，额外 wafer 以及额外划片需求等，请参加 MPW 之前提前确定好。</p>
        <p>5.3 同一家客户预定的 block 个数不得超过 4 个。</p>
        <p>5.4 参加 MPW 班车的数据要保证 DRC clean。</p>
      </CardTableV2>
    </div>
  );
};

export default MPWPreOrderState;
