import { Component } from 'react';

import { get } from '../utils/lodash';
import { RouteComponentProps } from 'react-router';

interface UpdatableProps {
  render: Component['render'];
  match: RouteComponentProps<any>['match'] | null;
}

export default class Updatable extends Component<UpdatableProps> {
  render = () => this.props.render();

  shouldComponentUpdate(nextProps: UpdatableProps) {
    return (
      get(nextProps, 'match.__CacheRoute__computedMatch__null') !== true
    );
  }
}
