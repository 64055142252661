/**
 * 新增加一个svg的icon
 */
/* tslint:disable */
import * as React from 'react';

// 上传文件的svg
export const UploadFileSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="none" d="M0 0h16v16H0z"/>
    <path d="M10 1.701v2.301a1 1 0 0 0 .998.998h2.28L10 1.701zM13.8 6h-2.802A2 2 0 0 1 9 4.002V1.2H3.997C3.01 1.2 2.2 2.011 2.2 3.01v9.98a1.8 1.8 0 0 0 1.803 1.81h7.994a1.8 1.8 0 0 0 1.803-1.798V6zM1 3.01A3.007 3.007 0 0 1 3.997 0h6.004L15 5.03v7.972A3 3 0 0 1 11.997 16H4.003A3 3 0 0 1 1 12.99V3.01zm7.6 6.079v3.437a.6.6 0 0 1-1.2 0V9.104L6 10.579a.6.6 0 1 1-.87-.826l2.435-2.566a.6.6 0 0 1 .887.018l2.416 2.509a.6.6 0 1 1-.865.832L8.6 9.09z"/>
  </svg>
);

// 表情的svg
export const EmojiSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="none" d="M0 0h16v16H0z"/>
    <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-1.2A6.8 6.8 0 1 0 8 1.2a6.8 6.8 0 0 0 0 13.6zM4.571 5.257c0-.379.305-.686.686-.686a.68.68 0 0 1 .686.686v.914a.684.684 0 0 1-.686.686.68.68 0 0 1-.686-.686v-.914zm5.715 0c0-.379.304-.686.685-.686a.68.68 0 0 1 .686.686v.914a.684.684 0 0 1-.686.686.68.68 0 0 1-.685-.686v-.914zm-5.293 5.429a.6.6 0 0 1 .894-.8 2.819 2.819 0 0 0 2.108.943c.815 0 1.573-.346 2.106-.94a.6.6 0 1 1 .893.8 4.019 4.019 0 0 1-2.999 1.34 4.019 4.019 0 0 1-3.002-1.343z"/>
  </svg>
);

// @ts-ignore
export const WordSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
    <defs>
      <path id="365-word_a" d="M.027 13.706V.384h8.609v13.322H.027z"/>
    </defs>
    <path fill="#9E9E9A" d="M17.727 20H2.273V0h10.682l4.772 4.773V20"/>
    <path fill="#FFFFFF" d="M2.727 19.545V.455h10L17.273 5v14.545H2.727"/>
    <path fill="#91928E" d="M4.091 6.364h11.818v-.909H4.091zM4.091 4.091h9.545v-.909H4.091zM4.091 8.636h11.818v-.909H4.091zM4.091 13.182h11.818v-.909H4.091zM4.091 10.909h11.818V10H4.091zM4.091 17.727h11.818v-.909H4.091zM4.091 15.455h11.818v-.91H4.091z"/>
    <path fill="#FFFFFF" d="M13.182 4.545V.909l3.636 3.636h-3.636"/>
    <path fill="#9E9D9A" d="M12.727 5V.455h.455v4.09h3.636l.455.455z"/>
    <g transform="translate(0 5)">
      <path fill="#FFFFFF" d="M2.727.455v12.398l5.909.853V.384L2.727.455"/>
      <path fill="#2C4E86" d="M8.182.909v12.273L0 12V2.091L8.182.909"/>
    </g>
    <path fill="#FFFFFF" d="M7.188 9.6l-.968.004-.653 3.653h-.011L4.795 9.61l-1.016.004-.849 3.615h-.014L2.269 9.62l-1.032.003 1.099 4.912 1.089.006.794-3.477h.01l.77 3.486 1.061.005L7.188 9.6"/>
  </svg>
);

export const PPT_SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 18 20">
    <defs>
      <path id="365-powerpoint_a" d="M.027 13.706V.385h8.609v13.321H.027z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#9E9E9A" d="M17.727 20H2.273V0h10.682l4.772 4.773V20M2.727.455v19.09h14.546V5l-.341-.455h-.114L13.182.91V.795l-.455-.34h-10"/>
      <path fill="#FFFFFF" d="M2.727 19.545V.455h10L17.273 5v14.545H2.727"/>
      <path fill="#EFB696" d="M4.091 13.636h11.818v-.909H4.091zM4.091 15.455h11.818v-.91H4.091zM4.091 17.273h11.818v-.909H4.091z"/>
      <path fill="#FFFFFF" d="M5 17.273h.909v-4.546H5z"/>
      <path fill="#8C8D89" d="M10 3.182a4.09 4.09 0 0 0-4.09 4.09A4.09 4.09 0 0 0 10 11.365a4.09 4.09 0 0 0 4.09-4.091H10V3.182"/>
      <path fill="#EFB696" d="M10.91 2.273v4.09H15a4.09 4.09 0 0 0-4.09-4.09"/>
      <path fill="#FFFFFF" d="M13.182 4.545V.909l3.636 3.636h-3.636"/>
      <path fill="#967564" d="M14.767 5h-2.04V2.698c.158.078.31.166.455.263v1.584h1.393c.073.147.137.3.192.455"/>
      <g transform="translate(0 5)">
        <path fill="#FFFFFF" d="M2.727 1.238v11.615l5.909.853V.385l-5.909.853"/>
        <path fill="#BF442B" d="M8.182.909v12.273L0 12V2.091L8.182.909"/>
      </g>
      <path fill="#FFFFFF" d="M4.255 9.612l-1.48.006-.004 4.92.945.004.001-1.696.45.001a2.183 2.183 0 0 0 .468-.05 1.722 1.722 0 0 0 .411-.157 1.58 1.58 0 0 0 .344-.251 1.427 1.427 0 0 0 .252-.338 1.51 1.51 0 0 0 .16-.406 1.873 1.873 0 0 0 .051-.464c0-.262-.033-.492-.099-.689a1.225 1.225 0 0 0-.3-.491c-.133-.131-.3-.229-.5-.293a2.242 2.242 0 0 0-.699-.096m-.537 2.393v-1.537h.373c.127-.001.238.015.333.046a.596.596 0 0 1 .238.142c.063.063.11.142.142.236.031.095.047.205.047.331 0 .13-.016.244-.047.342a.615.615 0 0 1-.143.244.592.592 0 0 1-.238.147c-.095.033-.206.049-.333.049h-.372"/>
    </g>
  </svg>
);

export const ExcelSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 18 20">
    <defs>
      <path id="365-excel_a" d="M.027 13.706V.385h8.609v13.321z"/>
      <path id="365-excel_c" d="M.027 13.706V.385h8.609v13.321z"/>
      <path id="365-excel_e" d="M.027 13.706V.385h8.609v13.321z"/>
    </defs>
    <path fill="#9E9E9A" fillRule="nonzero" d="M17.727 20H2.273V0h10.682l4.772 4.773V20M2.727.455v19.09h14.546V5l-.341-.455h-.114L13.182.91V.795l-.455-.34h-10"/>
    <path fill="#FFFFFF" fillRule="nonzero" d="M2.727 19.545V.455h10L17.273 5v14.545H2.727"/>
    <path fill="#80B896" fillRule="nonzero" d="M8.182 18.182h3.636v-2.273H8.182zM12.273 18.182h3.636v-2.273h-3.636zM8.182 15.455h3.636v-2.273H8.182zM12.273 15.455h3.636v-2.273h-3.636zM8.182 12.727h3.636v-2.272H8.182zM12.273 12.727h3.636v-2.272h-3.636zM8.182 10h3.636V7.727H8.182zM12.273 10h3.636V7.727h-3.636zM8.182 7.273h3.636V5H8.182zM12.273 7.273h3.636V5h-3.636z"/>
    <path fill="#91928E" fillRule="nonzero" d="M4.091 18.182h3.636v-2.273H4.091zM4.091 15.455h3.636v-2.273H4.091zM4.091 12.727h3.636v-2.272H4.091zM4.091 10h3.636V7.727H4.091zM4.091 7.273h3.636V5H4.091zM8.182 4.545h3.636V2.273H8.182zM12.273 4.545h1.818V2.273h-1.818zM4.091 4.545h3.636V2.273H4.091z"/>
    <path fill="#FFFFFF" fillRule="nonzero" d="M13.182 4.545V.909l3.636 3.636h-3.636"/>
    <g transform="translate(0 5)">
      <path fill="#FFFFFF" fillRule="nonzero" d="M2.727 1.238v11.615l5.909.853V.385l-5.909.853"/>
    </g>
    <g transform="translate(0 5)">
      <path fill="#246A43" fillRule="nonzero" d="M8.182.909v12.273L0 12V2.091L8.182.909"/>
    </g>
    <g transform="translate(0 5)">
      <path fill="#FFFFFF" fillRule="nonzero" d="M5.952 4.605l-1.064.005-.817 1.774h-.01l-.719-1.769-1.149.005 1.222 2.459-1.338 2.457 1.123.006.825-1.796h.014l.814 1.805 1.132.006L4.68 7.06l1.272-2.455"/>
    </g>
  </svg>
);

export const PdfSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32">
    <path d="M3.269 0c-.456 0-.874.075-1.254.225A3.04 3.04 0 0 0 .988.901c-.304.3-.545.651-.722 1.052C.09 2.353 0 2.754 0 3.155v25.69c0 .4.089.801.266 1.202.177.4.418.751.722 1.052.304.3.647.525 1.027.676.38.15.798.225 1.254.225h19.462c.456 0 .874-.075 1.254-.225a3.04 3.04 0 0 0 1.027-.676c.304-.3.545-.651.722-1.052.177-.4.266-.801.266-1.202V9l-9-9H3.269z"/>
    <path fill="#000000" fillOpacity=".24" d="M18.997 9A1.998 1.998 0 0 1 17 7.003V0l9 9h-7.003z"/>
    <path fill="#FFFFFF" d="M6.914 15H4.5v7h1.54v-2.486h.582c2.063 0 2.757-1.129 2.757-2.437C9.379 15.529 8.343 15 6.914 15zm-.251 1.288c.784 0 1.126.31 1.126.999 0 .539-.221.978-1.247.978h-.503v-1.977h.624zM10.525 22h2.213c2.596 0 3.31-1.867 3.31-3.655 0-2.237-1.036-3.345-3.33-3.345h-2.193v7zm1.53-5.742h.553c1.116 0 1.86.54 1.86 2.227 0 1.348-.412 2.257-1.85 2.257h-.564v-4.484zm6.8 5.742v-2.626h2.273v-1.258h-2.274v-1.858H21.5V15h-4.175v7h1.53z" opacity=".8"/>
  </svg>
);

export const ZipSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32">
    <path d="M3.436 0C2.98 0 2.56.075 2.18.225a3.04 3.04 0 0 0-1.026.676c-.304.3-.545.651-.722 1.052-.178.4-.266.801-.266 1.202v25.69c0 .4.088.801.266 1.202.177.4.418.751.722 1.052.304.3.646.525 1.026.676.38.15.799.225 1.255.225h19.462c.456 0 .874-.075 1.254-.225a3.04 3.04 0 0 0 1.026-.676c.304-.3.545-.651.723-1.052.177-.4.266-.801.266-1.202V9l-9-9H3.436z"/>
    <g fill="#FFFFFF" opacity=".8">
      <path d="M11.167 23h2v2h-2zM13.167 21h2v2h-2zM11.167 19h2v2h-2zM13.167 17h2v2h-2zM11.167 15h2v2h-2zM13.167 13h2v2h-2z"/>
    </g>
    <path fill="#FFFFFF" d="M11.167 26h4v4h-4z" opacity=".8"/>
    <path fill="#000000" fillOpacity=".24" d="M19.164 9a1.998 1.998 0 0 1-1.997-1.997V0l9 9h-7.003z"/>
  </svg>
);

export const PhotoSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32">
    <path d="M3.269 0c-.456 0-.874.075-1.254.225A3.04 3.04 0 0 0 .988.901c-.304.3-.545.651-.722 1.052C.09 2.353 0 2.754 0 3.155v25.69c0 .4.089.801.266 1.202.177.4.418.751.722 1.052.304.3.647.525 1.027.676.38.15.798.225 1.254.225h19.462c.456 0 .874-.075 1.254-.225a3.04 3.04 0 0 0 1.027-.676c.304-.3.545-.651.722-1.052.177-.4.266-.801.266-1.202V9l-9-9H3.269z"/>
    <path fill="#000000" fillOpacity=".24" d="M18.997 9A1.998 1.998 0 0 1 17 7.003V0l9 9h-7.003z"/>
    <path fill="#FFFFFF" d="M6.5 12.991c0-.823.663-1.491 1.491-1.491H18.01c.823 0 1.491.663 1.491 1.491V23.01c0 .823-.663 1.491-1.491 1.491H7.99A1.488 1.488 0 0 1 6.5 23.009V12.99zm5.796 8.181l-1.813-2.46v.037L8 22.346l10 .038-3.221-4.884-2.483 3.672zM9.6 16.6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" opacity=".8"/>
  </svg>
);

export const OtherSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32">
    <path d="M3.269 0c-.456 0-.874.075-1.254.225A3.04 3.04 0 0 0 .988.901c-.304.3-.545.651-.722 1.052C.09 2.353 0 2.754 0 3.155v25.69c0 .4.089.801.266 1.202.177.4.418.751.722 1.052.304.3.647.525 1.027.676.38.15.798.225 1.254.225h19.462c.456 0 .874-.075 1.254-.225a3.04 3.04 0 0 0 1.027-.676c.304-.3.545-.651.722-1.052.177-.4.266-.801.266-1.202V9l-9-9H3.269z"/>
    <path fill="#000000" fillOpacity=".24" d="M19.164 9a1.998 1.998 0 0 1-1.997-1.997V0l9 9h-7.003z"/>
    <path fill="#FFFFFF" stroke="#FFFFFF" strokeWidth=".5" d="M16.504 15.115a2.158 2.158 0 0 0-2.149-2.149 2.126 2.126 0 0 0-2.135 2.136l.013 4.284-1.223-.004-.013-4.284a3.34 3.34 0 0 1 3.355-3.355 3.39 3.39 0 0 1 3.375 3.375l.016 4.896-1.224-.003-.015-4.896zm-2.75 1.522a.607.607 0 0 1 .611-.61c.338 0 .613.275.614.613l.008 2.754a1.974 1.974 0 0 1-1.983 1.983 2.004 2.004 0 0 1-1.994-1.995l1.223.004a.771.771 0 0 0 .768.767c.423.001.764-.34.762-.762l-.008-2.754zm-4.267 1.065l-.006 2.287a3.546 3.546 0 0 0 3.53 3.53 3.494 3.494 0 0 0 3.508-3.508l1.224.003a4.707 4.707 0 0 1-4.729 4.729 4.779 4.779 0 0 1-4.757-4.757l.006-2.287c0-.338.272-.611.61-.61.34 0 .614.275.614.613z" opacity=".8"/>
  </svg>
);
