import React, { FC } from 'react';
import { Alert } from 'antd';
import { getMomentTime } from '@chipcoo/fe-utils';

import { EnumWipMode } from './interface';

interface Props {
  value?: any;
  wipMode: EnumWipMode;
}

const WipInfo: FC<Props> = props => {
  const { value, wipMode } = props;
  const time = getMomentTime(value || new Date(), 'YYYY-MM-DD HH:mm:ss');

  const message = wipMode === EnumWipMode.auto
    ? `以下信息由工厂提供，每隔一小时自动同步一次，上一次同步时间：${value ? time : '暂未同步到关联信息'}`
    : `以下信息由运营录入，上一次录入时间：${value ? time : '暂未录入'}`;

  return (
    <Alert type="info" message={message} showIcon={true} />
  );
};

export default WipInfo;
