import React from 'react';
import { Popconfirm } from 'antd';
import { RepeaterRenderParams } from '@chipcoo/hanayo/lib/FormCreator';

interface P {
  headerText?: string;
  formStatus: string;
  repeaterRenderParams: RepeaterRenderParams;
  initialFormData?: any;
  path?: string;
}

export const RepeaterHeader = (props: P) => {
  const { repeaterRenderParams, formStatus, headerText, path } = props;
  const { index, len, del } = repeaterRenderParams;
  const _headerText = headerText ? headerText : '测试程序信息';
  const renderText = len <= 1 ? _headerText : `${_headerText} #${index + 1}`;
  let isCanDelete = true;

  // 步骤不能删除
  if (path === 'steps') {
    isCanDelete = false;
  }

  return (
    <div className="flex space-between">
      {renderText}
      {/*指令只有一个时也可以删除*/}
      {formStatus !== 'preview' && isCanDelete && len > 0 && len === index + 1 && (
        <span onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="确定删除?"
            onConfirm={() => del(index)}
            okText="确定"
            cancelText="取消"
            overlayStyle={{ zIndex: 2000 }}
            getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
          >
            <a href="javascript: void 0;" style={{ display: 'block', marginRight: 12 }}>删除</a>
          </Popconfirm>
        </span>
      )}
    </div>
  );
};

export const RepeaterCardTitle = (text: string, index: number, len: number) => {
  if (len <= 1) return text;

  return `${text}(${index + 1}/${len})`;
};

export const RepeaterDelete = (params: RepeaterRenderParams) => params.len > 0 && (
  <Popconfirm
    title="确定删除?"
    onConfirm={() => params.del(params.index)}
    okText="确定"
    cancelText="取消"
    overlayStyle={{ zIndex: 2000 }}
    getPopupContainer={(t) => t.parentNode as HTMLElement}
  >
    <a href="javascript: void 0;">删除</a>
  </Popconfirm>
);

export const RepeaterCollapseHeader = (props: P) => {
  const { repeaterRenderParams, formStatus, headerText } = props;
  const { index, len, del } = repeaterRenderParams;
  const _headerText = headerText ? headerText : '批号';
  const renderText = len <= 1 ? _headerText : `${_headerText} #${index + 1}`;
  let isCanDelete = true;

  return (
    <div className="flex space-between">
      {renderText}
      {formStatus !== 'preview' && isCanDelete && len > 0 && (
        <span onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="确定删除?"
            onConfirm={() => del(index)}
            okText="确定"
            cancelText="取消"
            overlayStyle={{ zIndex: 2000 }}
            getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
          >
            <a href="javascript: void 0;" style={{ display: 'block', marginRight: 12 }}>删除</a>
          </Popconfirm>
        </span>
      )}
    </div>
  );
};
