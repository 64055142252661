import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';

import styles from './index.module.less';
import { getRouterProps, compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';

import FactoryList from './FactoryList';
import MPWShuttleList from './MPWShuttleList';
import MPWPreOrderState from './components/MPWPreOrderState';

interface Props extends RouteComponentProps<{ id?: string, factoryId?: string }> {}
interface State {}

class MPWShuttle extends PureComponent<Props, State> {
  setUrl = (factoryId?: string) => {
    const { history, match } = this.props;

    const orgId = get(match, 'params.id');

    const newUrl = compilePath({
      name: ROUTE_NAMES.ORG_RD_MPW_SHUTTLE,
      params: {
        id: orgId,
        factoryId
      }
    });

    history.push(newUrl);
  }

  renderMPWContent = () => {
    const { location, match } = this.props;
    const factoryId = match.params?.factoryId;

    if (!factoryId) {
      return <MPWPreOrderState />;
    }

    return (
      <MPWShuttleList
        {...getRouterProps(this.props)}
        querystring={location.search}
        factoryId={factoryId}
        setUrl={this.setUrl}
      />
    );
  }

  render() {
    const { match } = this.props;
    const factoryId = match.params?.factoryId;

    return (
      <div className={styles.wrapper}>
        <FactoryList focusedFactoryId={factoryId} setUrl={this.setUrl} />

        {this.renderMPWContent()}
      </div>
    );
  }
}

export default MPWShuttle;
