import * as React from 'react';
import log from 'loglevel';
import { get } from 'lodash';
import { message, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { EnumMemberStatus } from '@chipcoo/constant';
import { getUserName } from '@chipcoo/fe-utils';

import { DispatchProps } from '.';
import styles from './index.module.less';
import { OrgUser } from 'src/models/org/interface';
import { getEnhancedAvatar } from 'src/utils/user';
import { i18nMembers } from 'src/models/org/handler';

import ChangeRoleSelect from './ChangeRoleSelect';
import { EnhanceAvatar } from 'src/components/EnhanceAntd';

type GetTableColumnsOption = {
  role: AllRole | null;
  orgId: string;
  dispatch: DispatchProps;
};
export function getTableColumns(option: GetTableColumnsOption): ColumnProps<OrgUser>[] {
  const { role, orgId, dispatch } = option;
  const { postAddOrgMember, putOrgMember, deleteOrgMember } = dispatch;

  const baseColumn: ColumnProps<any>[] = [
    {
      title: '头像   昵称',
      key: 'name',
      dataIndex: 'name',
      className: styles.columnAvatarNickname,
      width: '25%',
      render: (_, record) => {
        const avatar = getEnhancedAvatar(record);
        const { status = EnumMemberStatus.normal } = record;
        const userDisabled = !record.role || status !== EnumMemberStatus.normal;

        return (
          <div className="user-list-record-user-wrap">
            <EnhanceAvatar src={avatar} userName={getUserName(record)} userDisabled={userDisabled} />
            <span className="record-user-name">{getUserName(record)}</span>
          </div>
        );
      }
    },
    {
      title: '邮箱或手机号',
      key: 'account',
      dataIndex: 'account',
      width: '50%',
    },
    {
      title: '角色',
      key: 'role',
      dataIndex: 'role',
      width: '10%',
      render: (text, record) => {
        // 非拥有者不出现下拉选项
        if (role !== 'OWNER') {
          return i18nMembers[text] || text;
        }

        const { status = EnumMemberStatus.normal } = record;

        if (text === null || status !== EnumMemberStatus.normal) {
          return '';
        }

        if (text === 'OWNER') {
          return i18nMembers[text] || text;
        }

        return (
          <ChangeRoleSelect value={text} userId={record._id} orgId={orgId}/>
        );
      }
    }
  ];

  // 普通用户没有操作列表
  if (!role || role === 'MEMBER' || role === 'member') return baseColumn;

  baseColumn.push({
    title: '操作',
    width: 200,
    key: 'operation',
    render: (_, record) => {
      const showActionButton = (role === 'OWNER' && record.role !== 'OWNER')
        || (role === 'ADMIN' && (record.role === 'MEMBER' || record.role === null));
      if (!showActionButton) {
        return null;
      }

      let text = '禁用';
      let title = '是否要禁用该成员？';
      let onConfirm = () => deleteOrgMember({ orgId, userId: record._id });
      let textClassName = '';

      if (record.status === EnumMemberStatus.leaved) {
        //
        text = '重新邀请';
        title = '是否要重新邀请该成员？';
        onConfirm = async () => {
          const account = get(record, 'email') || get(record, 'phone');

          if (account) {
            try {
              await postAddOrgMember({ accounts: [account] });

              message.success('邀请已发送，请等待对方接受。');
            } catch (e) {
              message.error('邀请组织成员失败');
              log.error('[Invite Org Members]: invite baned members failed, member:', record, '. error detail: ', e);
            }
          } else {
            message.error('邀请组织成员失败');
            log.error('[Invite Org Members]: could not find email or phone in record');
          }
        };
      }
      if (record.status === EnumMemberStatus.removed) {
        //
        text = '重新启用';
        title = '是否要启用该成员？';

        onConfirm = async () => {
          try {
            await putOrgMember({ userId: record._id, role: 'MEMBER' });
            message.success('账号状态已恢复');
          } catch (e) {
            message.error('恢复账号角色失败');
            log.error('[Set Org Member Role]: set member role failed, member:', record, '. error detail: ', e);
          }
        };
      }

      if (record.status) {
        textClassName = styles.warnOperates;
      }

      return (
        <div>
          <Popconfirm
            title={title}
            onConfirm={onConfirm}
          >
            <a className={textClassName}>{text}</a>
          </Popconfirm>
        </div>
      );
    }
  });

  return baseColumn;
}
