import * as React from 'react';
import { Collapse } from 'antd';
import { bind } from 'lodash-decorators';
import { noop } from '@chipcoo/fe-utils';
import { EnumProcessClass } from '@chipcoo/constant';

import styles from './index.module.less';
import { ICraftRouteWithProductProcess } from './interface';
import { createProductProcessDataSelector, IFinalDataSourceState } from './core/create-selector';

import ProcessItemCollapse from './ProcessItemCollapse';

interface Props {
  productProcess: any[];
  craftRouteWithProductProcess: ICraftRouteWithProductProcess;
  craftRouteId: string;
  processCode: string;
}

interface State extends IFinalDataSourceState {
  activePanelKeys: string[];
  editingKey: string;
  selectDataFn: ReturnType<typeof createProductProcessDataSelector>;
  processItemId: string | undefined;
  processClass: EnumProcessClass;
}

class ProcessCraftCollapse extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { craftRouteWithProductProcess, productProcess, craftRouteId } = props;
    const selectDataFn = createProductProcessDataSelector();
    const dataSource = productProcess.filter(it => it.craftRoute === craftRouteId);

    this.state = {
      editingKey: '',
      localData: [],
      activePanelKeys: [craftRouteWithProductProcess._id],
      selectDataFn,

      dataSource,
      processItemId: undefined,
      processClass: EnumProcessClass.normal,
    };
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  @bind
  setPanelKeys(keys: string[]) {
    this.setState({ activePanelKeys: keys });
  }

  getHeader() {
    const { craftRouteWithProductProcess } = this.props;
    const { sources, material: { materialName } } = craftRouteWithProductProcess;

    const names = sources.map(source => {
      if (source.type === 'craft-route') {
        return source.item.material.materialName;
      } else {
        return source.item.materialName;
      }
    });

    const destName = materialName;
    return `${destName} ← ${names.join('+')}`;
  }

  render () {
    const { activePanelKeys, dataSource } = this.state;
    const { craftRouteWithProductProcess, processCode } = this.props;
    const { _id, productModel } = craftRouteWithProductProcess;

    return (
      <Collapse
        key={_id}
        className={styles.processEditCollapse}
        activeKey={activePanelKeys}
        onChange={this.setPanelKeys}
      >
        <Collapse.Panel
          key={_id}
          header={this.getHeader()}
        >
          <ProcessItemCollapse
            processCode={processCode}
            productProcess={dataSource}
            productModel={productModel}
            header={this.getHeader()}
          />
        </Collapse.Panel>
      </Collapse>
    );
  }
}

export default ProcessCraftCollapse;
// tslint:disable:max-file-line-count
