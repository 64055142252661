/**
 * 附件缩略图
 */
import * as React from 'react';
import { Icon } from 'antd';
import classnames from 'classnames';
import { IMG } from '@chipcoo/fe-utils';

import styles from './index.module.less';

import {
  WordSVG,
  PPT_SVG,
  ExcelSVG,
  PdfSVG,
  ZipSVG,
  OtherSVG,
  PhotoSVG
} from 'src/components';

interface P {
  url?: string;
  ext: string;
  mimetype?: string;
}
interface S {}

class AttachmentIcon extends React.PureComponent<P, S> {
  render() {
    const svgComponentGroup = {
      pdf: PdfSVG,
      zip: ZipSVG,
      word: WordSVG,
      ppt: PPT_SVG,
      excel: ExcelSVG,
      photo: PhotoSVG,
      other: OtherSVG
    };
    const { url, ext: propsExt } = this.props;
    const ext = propsExt;
    const isImg = IMG.test(ext);

    let svgId = isImg ? 'photo' : '';

    if (!isImg) {
      switch (true) {
        case ext === 'pdf': svgId = 'pdf'; break;
        case ext === 'zip' || ext === 'rar': svgId = 'zip'; break;
        case Boolean(~ext.indexOf('doc')): svgId = 'word'; break;
        case Boolean(~ext.indexOf('ppt')): svgId = 'ppt'; break;
        case Boolean(~ext.indexOf('xls')): svgId = 'excel'; break;
        default: svgId = 'other'; break;
      }
    }

    const hasClassnames = svgId === 'other' || svgId === 'pdf' || svgId === 'photo' || svgId === 'zip';
    const classname = classnames(styles.svg, hasClassnames && styles[`on-${svgId}`]);

    return isImg && url
      ? <span className={styles.thumbImg} style={{backgroundImage: `url(${url})`}} />
      : <Icon component={svgComponentGroup[svgId]} className={classname} />;
  }
}

export default AttachmentIcon;
