import { ICraftRouteLabel } from '../interface';

export function updateLabel(updateAttrs: ICraftRouteLabel, label: ICraftRouteLabel | string = '') {
  let newLabel: ICraftRouteLabel = {};
  if (typeof label === 'string') {
    newLabel.text = label;
  } else {
    Object.assign(newLabel, { ...label });
  }

  return Object.assign(newLabel, updateAttrs);
}
