/**
 * 聊天房间列表
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { HoverScrollBarContainer } from 'src/components';

import styles from './index.module.less';
import { RootState } from 'src/store';
import { ImContext, InitialImCtx } from 'src/pages/IM';

import RoomCard from './RoomCard';
import { ThreeBounceLoading } from 'src/components';

interface OwnProps {}
interface StateProps {
  roomListIds: string[];
  isRoomListInitial: boolean;
}
interface DispatchProps {}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class ListContent extends React.PureComponent<P, S> {
  renderListContent = (ctx: InitialImCtx) => {
    const { roomListIds, isRoomListInitial } = this.props;
    const { setUrl, roomId } = ctx;

    return (
      <HoverScrollBarContainer
        width={359}
        className={`${styles['list-content-wrapper']} cell`}
      >
        {
          isRoomListInitial
            ? roomListIds.map(id => (
              <RoomCard
                key={id}
                roomId={id}
                isFocus={id === roomId}
                setUrl={setUrl!}
              />
            ))
            : <ThreeBounceLoading />
        }
      </HoverScrollBarContainer>
    );
  }

  render() {
    return <ImContext.Consumer>{this.renderListContent}</ImContext.Consumer>;
  }
}

const mapState = ({ chatRoomList: { roomList } }: RootState) => ({
  roomListIds: roomList.allIds,
  isRoomListInitial: roomList.isInitial
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(ListContent);
