/**
 * 对table组件的封装
 */
import React, { PureComponent, ComponentType } from 'react';
import { compose } from 'recompose';
import { PaginationConfig } from 'antd/lib/pagination/Pagination';
import { EnhancedAntTable } from '@chipcoo/hanayo';

import { TableOriginProps, TableProps } from './types';
import { withLoading, withContext } from 'src/hoc';

import Ctx from './createContext';

type S = {};

const Enhance = compose(
  withLoading(false),
  withContext(Ctx, ['fetchData', 'queryCondition', 'pageSizeLimit', 'setQueryCondition', 'initialLoad'])
);

class Table extends PureComponent<TableProps, S> {
  private pagination: PaginationConfig;

  constructor(props: TableProps) {
    super(props);

    const { pagination, pageSizeLimit } = props;

    // 重新写入分页的配置
    const basePagination = {
      showQuickJumper: true,
      defaultCurrent: 1,
      current: 1,
      total: props.dataCount,
      showTotal: this.getPaginationInfo,
      onChange: this.handlePaginationChange,
      pageSize: pageSizeLimit
    };

    this.pagination = pagination ? { ...pagination, ...basePagination } : basePagination;
  }

  componentDidMount() {
    this.props.initialLoad && this.fetchSourceData();
  }

  componentDidUpdate(prevProps: TableProps) {
    // 每次更新查询条件，queryCondition整个对象都会重新设置，所以通过直接比对地址来判断是否更新了查询条件
    if (prevProps.queryCondition !== this.props.queryCondition) {
      this.fetchSourceData();
    }
  }

  fetchSourceData = async () => {
    const { loading, startLoading, stopLoading, fetchData, queryCondition } = this.props;

    !loading && startLoading();

    /**
     * @mention 这里这么转一道的原因：queryCondition需要被用来显示成查询条件在显示在table上面
     * 但是，假如是一个搜索的查询项，拿到的数据为{ key: string(id), label: string(text) }，可能后端只需要那个key(也就是id)，但是
     * 展示的时候是需要展示label的，因此，丢过来的queryCondition就直接做了处理，有的object上直接添加一个toJson返回对应的key，因此这里
     * 需要这么转两道以便拿到正确的数据
     */
    const formData = JSON.parse(JSON.stringify(queryCondition));

    await fetchData(formData);

    stopLoading();
  }

  getPaginationInfo = (num, range) =>
    num <= 10 ? `共有${num}条` : `共有${num}条, 当前是${range[0]}-${range[1]}`

  handlePaginationChange = (pageNumber: number) => {
    const { setQueryCondition, queryCondition } = this.props;

    setQueryCondition({
      ...queryCondition,
      page: pageNumber
    });
  }

  render() {
    const {
      dataCount,
      startLoading,
      stopLoading,
      fetchData,
      pagination,
      initialLoad,
      queryCondition,
      pageSizeLimit,
      setQueryCondition,
      ...passThoughProps
    } = this.props;

    this.pagination.total = dataCount;
    this.pagination.current = queryCondition.page;

    return (
      <EnhancedAntTable
        {...passThoughProps}
        pagination={this.pagination}
      />
    );
  }
}

export default Enhance(Table) as ComponentType<TableOriginProps>;
