import React, { ComponentType } from 'react';
import { isEmpty } from 'lodash';
import { Card, Empty } from 'antd';

import styles from './index.module.less';
import { craftRouteApi } from 'src/services/net';
import { createEditGroupSelector } from './core/create-selector';
import { IProductProcessByProcess } from './interface';

import ProcessCraftCollapse from './ProcessCraftCollapse';
import { connectDecorator, RootState, Actions } from 'src/store';

interface OwnProps {
  productId: string;
}
interface StateProps {
  productProcess: any[];
}
interface DispatchProps {
  getProductProcess: (id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  craftRoutes: any[];
}

@connectDecorator(
  ({ product: { productProcess } }: RootState) => ({ productProcess }),
  ({ product: { getProductProcess } }: Actions) => ({ getProductProcess })
)
class ProductProcess extends React.PureComponent<Props, State> {

  selectProductProcessGroup = createEditGroupSelector();
  groupByProcess: IProductProcessByProcess[];

  constructor(props: Props) {
    super(props);

    this.state = {
      craftRoutes: [],
    };
  }

  async componentDidMount() {
    try {
      const { productId, getProductProcess } = this.props;
      const { data: craftRoutes } = await craftRouteApi.detail({ productId });
      await getProductProcess(productId);

      this.setState({ craftRoutes });
    } catch (e) {
      console.error(e);
    }
  }

  renderFormCreator = (withProcess: any, processCode: string) => {
    const { productProcess } = this.props;
    const craftRouteId = withProcess._id;

    return (
      <ProcessCraftCollapse
        craftRouteWithProductProcess={withProcess}
        key={craftRouteId}
        productProcess={productProcess || []}
        craftRouteId={craftRouteId}
        processCode={processCode}
      />
    );
  }

  renderCard = (byProcess) => {
    const { process, craftRoutes } = byProcess;
    const processId = process?._id;
    const processCode = process?.code;
    const title = `制程：${processCode}`;

    return (
      <Card
        key={processId + Math.random()}
        className={styles.processEditCard}
        title={title}
      >
        {craftRoutes.map((withProcess) => this.renderFormCreator(withProcess, processCode))}
      </Card>
    );
  }

  render () {
    const { productProcess } = this.props;
    const { craftRoutes } = this.state;

    if (isEmpty(productProcess)) {
      return <Empty description="暂无产品制程" />;
    }

    this.groupByProcess = this.selectProductProcessGroup(craftRoutes, productProcess || []);

    return (
      <>
        {this.groupByProcess.map(this.renderCard)}
      </>
    );
  }
}

export default ProductProcess as ComponentType<OwnProps>;
