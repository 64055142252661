import React, { FC, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { unSortWaferId, getFlexModalBody } from '@chipcoo/fe-utils';
import { ChoiceWaferId } from '@chipcoo/hanayo';

import { inventoryApi } from 'src/services/net';
import { ModalType } from '../index';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  dieNumber: string;
  status: ModalType;
  currentDisabledOptions?: number[];
  organization?: string;
}

interface State {
  disabledOptions: number[];
}

const ChoiceWaferId1 = props => <ChoiceWaferId {...props} />;

const ChoiceStockInWaferIds: FC<Props> = props => {
  const isMountedRef = useRef<any>(null);

  const { dieNumber, status, value, currentDisabledOptions, organization } = props;

  const [state, setState] = useState<State>({
    disabledOptions: [],
  });
  const { disabledOptions } = state;

  const getDisabledOption = async () => {
    setState({
      disabledOptions: currentDisabledOptions || [],
    });

    if (dieNumber) {
      if (organization) {
        let { data } = await inventoryApi.getExistWaferIds({ dieNumber, organization });

        if (status === 'edit') {
          const valueArray = value ? unSortWaferId(value) : [];
          data = _.difference(data, valueArray);

          setState({
            disabledOptions: data.concat(_.difference(currentDisabledOptions, valueArray) || []),
          });
          return;
        }
        setState({
          disabledOptions: data.concat(currentDisabledOptions || []),
        });
      } else {
        console.warn('没有organization传入');
      }
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    if (isMountedRef.current) {
      getDisabledOption();
    }
    return () => { isMountedRef.current = false; };
  }, [currentDisabledOptions]);

  const commonProps = {
    ...props,
    placeholder: '请选择片号',
    selectMode: 'multiple',
    getPopupContainer: getFlexModalBody,
  };

  return <ChoiceWaferId1 {...commonProps} disabledOptions={disabledOptions} />;

};

export default ChoiceStockInWaferIds;
