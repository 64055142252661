import produce from 'immer';
import { createModel } from '@rematch/core';

import { State } from './interface';
import {
  handleTemplateList,
  handleTemplateDetail,
  handleTemplateScheduleData,
} from './handle';
import { TableModelCommonEffects } from 'src/models/type';
import { qcApi } from 'src/services/net';
import { TemplateParams, TemplatePlanData } from 'src/services/net/qc';

const initialState: State = {
  list: [],
  count: 0,
  detail: undefined,
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: {
      list: State['list'];
      count: number;
    }) {
      return produce(state, draft => {
        draft.list = payload.list;
        draft.count = payload.count;
      });
    },
    setDetail(state: State, payload: Obj | undefined) {
      return produce(state, draft => {
        draft.detail = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: TemplateParams) {
      const { data } = await qcApi.getTemplateList(params);
      const handleData = handleTemplateList(data);

      this.setList(handleData);
    },
    async getTableItemDetail(_id: string) {
      const { data } = await qcApi.getTemplateById(_id);
      const handleData = handleTemplateDetail(data);

      this.setDetail(handleData);
    },
    async postCreate() {
      //
    },
    async patchModify() {
      //
    },
    async deleteTemplate(_id: string) {
      await qcApi.deleteTemplateById(_id);
    },
    // 设置计划
    async putTemplatePlan(payload: TemplatePlanData) {
      const handleData = handleTemplateScheduleData(payload);
      await qcApi.putTemplatePlan(handleData);
    },
  })
});
