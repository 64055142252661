import { AxiosInstance, AxiosRequestConfig } from 'axios';

const url = 'https://easy-mock.com/mock/5b498974e820d85ac9497c08/task-demo';

export default function(api: AxiosInstance) {
  return {
    getStageList(config?: AxiosRequestConfig) {
      return api.get(`${url}/api/stages`, config);
    },

    getTasksUnderStage(stageId: string, config?: AxiosRequestConfig) {
      return api.get(`${url}/api/stages/tasks`, { params: stageId });
    },
  };
}
