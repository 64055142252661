/**
 * 用户详细的popover的content
 */
import React from 'react';
import { Icon, Button } from 'antd';
import { bind } from 'lodash-decorators';
import classnames from 'classnames';

import styles from './style.module.less';

import { MemberOperateContext, MemberContextParams } from './';
import EditRemark from './EditRemark';
import { ErrorBoundary, HocUserCard } from 'src/components';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';
import { isEqualUserId } from 'src/utils/user';

interface Props {
  closePopover: () => void;
  userInfo: User;
}

interface S {
  onCreateRoom: boolean;
}

const infoList = {
  nickname: '昵称', name: '姓名', phone: '手机', email: '邮箱', job: '职位', department: '部门', gender: '性别'
};

type MCP = MemberContextParams;

interface GoChatParams {
  createChatRoom: MCP['createChatRoom'];
  afterCreateChatRoom: MCP['afterCreateChatRoom'];
}

export class MemberDetailPopoverContent extends React.PureComponent<Props, S> {
  state = {
    onCreateRoom: false,
  };

  toChat(chatId: string) {
    const { history } = getCurrentRoute()!;
    history.push(compilePath({ name: ROUTE_NAMES.IM_DETAIL, params: { id: chatId } }));
  }

  @bind
  async handleGoChat(payload: GoChatParams) {
    const { createChatRoom, afterCreateChatRoom } = payload;
    const { closePopover, userInfo } = this.props;
    const { _id } = userInfo;
    this.setState({ onCreateRoom: true });
    let chatId;

    try {
      chatId = await createChatRoom(_id);
    } catch (e) {
      console.error(e);
    }

    this.setState({ onCreateRoom: false });

    if (typeof chatId === 'string') {
      this.toChat(chatId);
      closePopover();
      afterCreateChatRoom && afterCreateChatRoom(chatId);
    }
  }

  render() {
    const { closePopover, userInfo } = this.props;
    const { Consumer } = MemberOperateContext;
    return (
      <ErrorBoundary>
        <div className={styles.popoverContent}>
          <Consumer>
             {({ profile, createChatRoom, afterCreateChatRoom, cardExtra }) => {
              const isMe = isEqualUserId(profile!._id, userInfo._id);

              return (
                <>
                  <header className={classnames(styles.popoverContentHeader, 'flex')}>
                    <HocUserCard
                      extra={cardExtra && cardExtra(userInfo)}
                      userInfo={userInfo}
                      className={classnames(styles.userCard, 'cell')}
                    />
                    <Icon type="close" onClick={closePopover} />
                  </header>
                  <section className={styles.popoverContentBody}>
                    {!isMe && userInfo.isFriend && (
                      <div className="info-item">
                        <div className="term">备注</div>
                        <EditRemark userInfo={userInfo} defaultText="填写备注" />
                      </div>
                    )}

                    {Object.keys(infoList).map(key => {
                      if (!userInfo[key]) { return null; }

                      return (
                        <div className="info-item" key={key}>
                          <div className="term">{infoList[key]}</div>
                          <div className="content ellipsis">{userInfo[key]}</div>
                        </div>
                      );
                    })}
                  </section>

                  {!isMe && (
                    <div className={styles.chart}>
                      <Button
                        type="primary"
                        onClick={() => this.handleGoChat({ createChatRoom, afterCreateChatRoom })}
                      >
                        {'聊天'}
                      </Button>
                    </div>
                  )}
                </>
              );
            }}
          </Consumer>
        </div>
      </ErrorBoundary>
    );
  }
}

export default MemberDetailPopoverContent;
