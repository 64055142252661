import React from 'react';
import { Icon, Button, Divider } from 'antd';
// import { inventoryApi } from 'src/services/net';

interface CardExtraProps {
  queryParams: any;
  openQuery: (e: any) => void;
  openModal?: (detailId: string) => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery, openModal } = props;
  const openAddModal = () => {
    openModal!('');
  };

  return (
    <div>
      {/*<a href={inventoryApi.exportStockOutApply({...queryParams})}>*/}
      {/*  <Icon type="export" />导出*/}
      {/*</a>*/}
      {/*<Divider type="vertical" />*/}
      <a onClick={openQuery}>
        <Icon type="search" />查询
      </a>
      <Divider type="vertical" />
      <Button type="primary" onClick={openAddModal}>申请出库</Button>
    </div>
  );
}

export default React.memo(CardExtra);
