/**
 * Created by marvin on 18/8/20.
 */

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { bind } from 'lodash-decorators';
import { RouteComponentProps } from 'react-router-dom';
import { sleep } from '@chipcoo/fe-utils';

import style from './index.module.less';
import { typeMap } from './';
import { SettingType } from './interface';

import { PasswordAffirm } from 'src/containers';

import { ROUTE_NAMES } from 'src/router/normalRouter';
import { RootDispatch } from 'src/store';
import { Setting } from 'src/models/org/interface';
import { lockKey, unlockKey, createKey } from '../delayRemove';
import { NAMESPACE } from 'src/config/const';
import { compilePath } from 'src/components/HoneyRouter';

interface OwnProps {
  type: SettingType;
  id: string;
}

interface StateProps {}
interface DispatchProps {
  dismissOrg: (val: Setting) => void;
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

interface State {
  modalVisible: boolean;
}

class Dismission extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  @bind
  openModal() {
    this.setState({ modalVisible: true });
  }

  @bind
  closeModal() {
    this.setState({ modalVisible: false });
  }

  handleSubmit = async ({ verifyCode }) => {
    const { type, match, history, id, dismissOrg } = this.props;
    const { params } = match;

    let handler;
    const key = createKey(NAMESPACE.ORG, 'dissolve', id);
    lockKey(key);

    try {
      if (type === NAMESPACE.ORG) {
        handler = await dismissOrg({
          type: NAMESPACE.ORG,
          orgId: id,
          code: verifyCode,
        });
      } else {
        // handler = await this.dissolveWorkspace(password);
      }
    } catch (e) {
      console.error(e);
      unlockKey(key);
      return;
    }

    history.replace(compilePath({
      name: ROUTE_NAMES.ORG_LIST,
      params,
    }));

    await sleep(200);
    unlockKey(key);
    message.success('已成功解散' + typeMap[type]);
    handler();
  }

  render () {
    const { type } = this.props;

    const { modalVisible } = this.state;
    return (
      <div>
        <h3>解散{typeMap[type]}</h3>
        <p className={style.warningRed}>如解散{typeMap[type]}，所有{typeMap[type]}数据将被删除，不可恢复。</p>
        <div style={{ display: 'flex' }}>
          <div className={style.content}>
            <p>确定解散本{typeMap[type]}？</p>
            <p>如已确定，点击“解散”后输入6位验证码，并点击“确定”按钮。</p>
          </div>
          <Button type="danger" onClick={this.openModal}>解散</Button>
        </div>

        <PasswordAffirm
          from="dissolve"
          modal={true}
          visible={modalVisible}
          onCloseModal={this.closeModal}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapDispatch = ({ org: { postDissolveOrg } }: RootDispatch) => ({
  dismissOrg: postDissolveOrg,
});

export default connect<StateProps, DispatchProps, OwnProps>(null, mapDispatch)(Dismission);
