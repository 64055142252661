import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Card, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';
import to from 'await-to-js';
import { bind } from 'lodash-decorators';
import { sleep, formatDate } from '@chipcoo/fe-utils';

import styles from './moments.module.less';

import { HocUserCard } from 'src/components';
import { WithWorkspaceId, ActivityByDay } from 'src/models/workspace/interface';
import { RootDispatch, RootState } from 'src/store';
import { activitySelector } from 'src/models/workspace/selectors';
import { ActivityParams } from 'src/services/net/workspace';

interface StateProps {
  workspaceId: string;
  activity: ActivityByDay[];
}

interface DispatchProps {
  fetchActivity: (arg: WithWorkspaceId<ActivityParams>) => any;
}

type Props = StateProps & DispatchProps;

interface State {
  loading: boolean;
  hasMore: boolean;
}

export class Moments extends React.PureComponent<Props, State> {
  state = {
    loading: false,
    hasMore: true,
  };

  async componentDidMount() {
    this.handleLoadMore(0);
  }

  @bind
  async handleLoadMore(page: number = 1) {
    const { loading, hasMore } = this.state;

    if (loading || !hasMore) return;

    this.setState({ loading: true });

    const { activity, fetchActivity, workspaceId } = this.props;
    const params: ActivityParams = { limit: 20 };

    if (activity.length && page) {
      const { list } = activity[activity.length - 1];
      const last = list[list.length - 1];
      params.lastId = last._id;
    }

    let nextHasMore = true;
    const [err, result] = await to(fetchActivity({ workspaceId, ...params }));

    if (!err) {
      nextHasMore = !!(get(result, 'length'));
    }

    await sleep(200);
    this.setState({ loading: false, hasMore: nextHasMore });
  }

  renderEmpty() {
    return <p className={styles.activityEmpty}>暂无动态</p>;
  }

  renderCards() {
    const { loading } = this.state;
    const { activity } = this.props;

    if (!activity.length && !loading) {
      return this.renderEmpty();
    }

    return activity.map(card => {
      return (
        <Card
          className={styles.activityCard}
          bordered={false}
          title={card.day}
          key={card.day}
        >
          {card.list.map(detail => (
            <HocUserCard
              key={detail._id}
              isMessageCard={true}
              className="activity-detail"
              userInfo={detail.user}
              messageTime={formatDate(new Date(detail.updatedAt), 'hh:mm')}
              extra={
                <p className="activity-message">
                  {detail.message}
                </p>
              }
            />
          ))}
        </Card>
      );
    });
  }

  render() {
    const { activity } = this.props;
    const { loading, hasMore } = this.state;
    return (
      <div className={classnames('y-scroll', 'thin-scroll', styles.momentsWrapper)}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.handleLoadMore}
          hasMore={!loading && hasMore}
          useWindow={false}
          threshold={100}
        >
          {this.renderCards()}
          {loading && hasMore && (<div className={styles.activityLoading}><Spin /></div>)}
        </InfiniteScroll>

        {!hasMore && !!activity.length && <p className={styles.noMore}>--已经到底了--</p>}
        <div className="end" />
      </div>
    );
  }
}

const mapState = ({ workspace }: RootState) => ({
  workspaceId: workspace.workspaceId,
  activity: activitySelector(workspace, 'desc'),
});

const mapDispatch = ({ workspace: { fetchActivity } }: RootDispatch) => ({
  fetchActivity,
});

export default connect<StateProps, DispatchProps>(mapState, mapDispatch)(Moments);
