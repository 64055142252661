import React, { FC } from 'react';
import { Row, Col, Input } from 'antd';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  fetchProduct: any;
  status: FormStatus;
}

const TextArea = Input.TextArea;

const MarkingContent: FC<Props> = props => {
  const { fetchProduct, value, onChange, status } = props;

  const markingRule = fetchProduct?.product?.marking.markingRule;

  return (
    <Row style={{ marginTop: 16 }}>
      <Col span={16}>
        <TextArea
          value={value}
          autoSize={{ minRows: 4, maxRows: 4 }}
          placeholder="请参考右侧模板输入打印内容"
          onChange={onChange}
          disabled={status === 'preview'}
        />
      </Col>

      <Col span={8}>
        <TextArea
          disabled={true}
          autoSize={{ minRows: 4, maxRows: 4 }}
          value={markingRule}
        />
      </Col>
    </Row>
  );
};

export default MarkingContent;
