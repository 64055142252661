import React, { FC, useEffect } from 'react';
import { Radio, Icon } from 'antd';
import { FormStatus } from '@chipcoo/hanayo/lib/FormCreator';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  status: FormStatus;
}

const CollectEngineeringData: FC<Props> = props => {
  const { value, onChange, status } = props;
  const newValue = value || false;

  useEffect(
    () => {
      onChange && onChange(newValue);
    },
    []
  );

  return (
    <div>
      <Radio.Group onChange={onChange} value={newValue} disabled={status === 'preview'}>
        <Radio value={false}>否</Radio>
        <Radio value={true}>
          <span>是</span>
        </Radio>
      </Radio.Group>

      {newValue && status !== 'preview' && (
        <span>
            <Icon
              type="exclamation-circle"
              theme="filled"
              style={{ color: '#fa8c16', marginRight: 8 }}
            />

            收集工程数据可能会增加交货时间
          </span>
      )}
    </div>
  );
};

export default CollectEngineeringData;
