/**
 * 所有表情的字典，注意这个字典是跟src/assets/images/emoji里面的图片按名称排列的数据一一对应的，如果要改，必须要一起改
 */

// 这里懒得把这八十多张图片一个个的import进来，因此使用了require.context
// 具体内容可参考 https://webpack.js.org/guides/dependency-management/#require-context
// emojiPics是所有图片的地址的数组，直接用就好了
// 最后，这里需要注意的是这样做emojiPics的顺序就是图片按照名称的顺序，因此需要做好一一对应
const requireContext = require.context('../assets/emoji', true, /^\.\/.*\.png$/);
const emojiPics = requireContext.keys().map(requireContext);
const i18n = [
  '呵呵', // 01
  '嘻嘻', // 02
  '哈哈', // 03
  '爱你', // 04,
  '挖鼻', // 05
  '吃惊', // 06
  '晕', // 07
  '泪', // 08
  '馋嘴', // 09
  '抓狂', // 10
  '哼', // 11
  '可爱', // 12
  '怒', // 13
  '汗', // 14
  '害羞', // 15
  '睡觉', // 16
  '钱', // 17
  '偷笑', // 18
  '笑哭', // 19
  'doge', // 20
  '喵喵', // 21
  '二哈', // 22
  '酷', // 23
  '衰', // 24
  '闭嘴', // 25
  '鄙视', // 26
  '色', // 27
  '鼓掌', // 28
  '悲伤', // 29
  '思考', // 30
  '生病', // 31
  '亲亲', // 32
  '怒骂', // 33
  '太开心', // 34
  '白眼', // 35
  '右哼哼', // 36
  '左哼哼', // 37
  '嘘', // 38
  '委屈', // 39
  '吐', // 40
  '可怜', // 41
  '打哈欠', // 42
  '调皮', // 43
  '失望', // 44
  '砸头', // 45
  '疑问', // 46
  '困', // 47
  '感冒', // 48
  '拜拜', // 49
  '黑线', // 50
  '阴险', // 51
  '啪', // 52
  '傻眼', // 53
  '心', // 54
  '伤心', // 55
  '猪头', // 56
  '熊猫', // 57
  '兔子', // 58
  '握手', // 59
  '作揖', // 60
  '赞', // 61
  '耶', // 62,
  'good', // 63,
  '弱', // 64
  '摇手指', // 65
  'ok', // 66
  'haha', // 67
  '来', // 68
  '握拳', // 69
  '威武', // 70
  '给力', // 71
  '摊手', // 72
  '神兽', // 73
  '喜', // 74
  '蛋糕', // 75
  '鲜花', // 76
  '干杯', // 77
  '礼物', // 78
  '飞机', // 79
  '围观' // 80
];
const emojiDictionary: {[key: string]: any} = {};

i18n.map((ele, index) => {
  if (!emojiPics[index]) {
    console.error('注意下缺emoji图片文件了啊');
    return;
  }
  emojiDictionary[ele] = {
    name: ele,
    src: emojiPics[index]
  };
});

export default emojiDictionary;
