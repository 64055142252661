import React, { ComponentType } from 'react';
import { Button } from 'antd';
// import { isNull } from 'lodash';
import log from 'loglevel';
import { bind } from 'lodash-decorators';
import { BasicModal } from '@chipcoo/hanayo';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';
import { EnumProductModel } from '@chipcoo/constant/lib/product';

import store from 'src/store';
// import { AssemblyProductContent, DpDieContent, OriginDieContent } from 'src/pages/ProductLibrary';
import ProductTable from 'src/pages/Product/ProductTable';
import EnsureOrgMembers from '../EnsureOrgMembers';
import { handleSpwDieDetailData, handleAssemblyProductDetail, handleDppDetail } from 'src/models/product/handle';

interface Props {
  orgId: string;
  productId: string;
  productModel: EnumProductModel;
  messageCard: JSX.Element;
}

interface State {
  notInOrg: boolean;
  isPreview: boolean;
  loadedMembers: boolean;
  loadingProduct: boolean;
  modalVisible: boolean;
}

const noop = () => '';

export class HandleProduct extends EnsureOrgMembers<Props, State> {
  private Component: ComponentType<any>;
  private detail;
  private dispatch;

  constructor(props: Props) {
    super(props);

    this.state = {
      notInOrg: !this.isInOrg(),
      isPreview: false,
      loadedMembers: false,
      loadingProduct: false,
      modalVisible: false,
    };
  }

  openModal = () => this.setState({ modalVisible: true });

  closeModal = () => this.setState({ modalVisible: false });

  @bind
  async tryViewProduct() {
    try {
      await this.viewProduct();
    } catch (e) {
      log.error(`[Loading Product]: error`, e);
      this.setState({ loadingProduct: false });
    }
  }

  @bind
  async viewProduct() {
    const { productId, productModel } = this.props;
    this.setState({ loadingProduct: true });
    // 不管了，检查一遍再说
    await this.checkOrg();

    this.Component = ProductTable;
    this.dispatch = store.dispatch.product;

    const { data } = await this.dispatch.getTableItemDetail(productId);

    switch (productModel) {
      case EnumProductModel.dpProduct:
        this.detail = await handleDppDetail(data);
        break;
      case EnumProductModel.assemblyProduct:
        this.detail = await handleAssemblyProductDetail(data);
        break;
      default:
        this.detail = await handleSpwDieDetailData(data);
        break;
    }

    this.setState({ loadingProduct: false });
    this.openModal();
  }

  @bind
  async getFormData() {
    await this.dispatch.setDetail(this.detail);
    // await store.dispatch({
    //   type: `${this.effectType}/setOrderDetail`,
    //   payload: { type, detail: this.detail }
    // });
    return Promise.resolve();
  }

  @bind
  renderProductContent(modalChildProps: BasicModalChildrenProps) {
    const { productId, productModel } = this.props;

    const { isPreview } = this.state;
    const props = {
      productModel,
      detailSearchId: productId,
      modalUse: isPreview ? 'preview' : 'edit', // TODO 此处
      reloadTable: noop,
      getFormData: this.getFormData,
      ...modalChildProps,
    };

    const { Component } = this;

    return <Component {...props} />;
  }

  @bind
  renderHandleButton() {
    const { notInOrg, loadedMembers, loadingProduct } = this.state;
    if (notInOrg) return null;

    return (
      <Button
        className="noti-btn"
        disabled={!loadedMembers}
        type="primary"
        loading={loadingProduct}
        onClick={this.tryViewProduct}
        children="编辑产品"
      />
    );
  }

  render() {
    const { messageCard } = this.props;
    const { modalVisible, isPreview } = this.state;
    return (
      <div className="notify-detail-content">
        <header className="noti-header flex">
          {messageCard}
          {/*{this.renderHandleButton()}*/}
        </header>

        <BasicModal
          visible={modalVisible}
          closeModal={this.closeModal}
          title="产品详情"
          isShowModalBody={false}
          width={1440}
          className={`form-with-comment-modal ${isPreview ? '' : 'form-status-edit'}`}
        >
          {(childProps: BasicModalChildrenProps) => this.renderProductContent(childProps)}
        </BasicModal>
      </div>
    );
  }
}

export default HandleProduct;
