import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { difference, debounce } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { CacheSwitch } from 'src/components/HoneyRouter';
import { NamedRouteComponents } from 'src/router/normalRouter';
import store, { RootState } from 'src/store';
import { SubscribeResult } from 'src/services/socket';
import { syncSocket } from 'src/socket';
import { handleSyncOrg } from 'src/layouts/GlobalWebPush/handler/org';

interface StateProps {
  orgIds: string[];
}

interface OwnProps extends RouteComponentProps {}
interface State {}

type Props = StateProps & OwnProps;

interface OrgSubscriptions {
  [key: string]: SubscribeResult;
}

function subscribe(ctx: OrgEntry, orgIds: string[]) {
  orgIds.forEach(async (id) => {
    //
    const subscription = await syncSocket.subscribe({
      name: 'organization', id,
    }, handleSyncOrg);

    if (ctx.unmounted) {
      subscription.unsubscribe();
    } else {
      ctx.subscriptions[id] = subscription;
    }
  });
}

function unsubscribe(ctx: OrgEntry, orgIds: string[]) {
  orgIds.forEach(id => {
    const subscription = ctx.subscriptions[id];
    subscription && subscription.unsubscribe();
  });
}

const refreshOrgList = debounce(() => {
  store.dispatch.org.getOrgList({ force: true });
}, 1000);

export class OrgEntry extends PureComponent<Props, State> {
  subscriptions: OrgSubscriptions = {};
  unmounted: boolean = false;
  
  componentDidMount() {
    subscribe(this, this.props.orgIds);
    refreshOrgList();
  }

  componentDidUpdate(prevProps: Props) {
    const addon = difference(this.props.orgIds, prevProps.orgIds);
    const removed = difference(prevProps.orgIds, this.props.orgIds);
    subscribe(this, addon);
    unsubscribe(this, removed);
  }

  componentWillUnmount() {
    this.unmounted = true;
    unsubscribe(this, this.props.orgIds);
  }

  render() {
    return (
      <CacheSwitch>
        {NamedRouteComponents.ORG_LIST()}
        {NamedRouteComponents.ORG_DETAIL_ENTRY()}
      </CacheSwitch>
    );
  }
}

function mapState(state: RootState) {
  return {
    orgIds: state.org.orgList.allIds,
  };
}

export default connect<StateProps, {}, OwnProps>(mapState)(OrgEntry);
