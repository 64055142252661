/**
 * 首先，这里不建议写上https:// 之类的，
 * 具体请看知乎上的这个问题：https://www.zhihu.com/question/67961957 (用 // 代替 http:// 有什么好处？)
 */
import { env, isDev } from './env';

const base = {
  baseUrl: APP_BASE_URL,
};

// 生产环境
let baseConfig = {
  authUrl: '//auth.chipcoo.com',
  chipcooUrl: '//www.chipcoo.com',
  WECHAT_URL: '//auth.chipcoo.com/api/wechat/bind',
  pushSocket: '//push.chipcoo.com/webpush',
  syncSocket: '//push.chipcoo.com/sync',
  staticUrl: '//attachment-public.chipcoo.com',
  tdasUrl: '//tdas.chipcoo.com'
};

if (env === 'local') {
  const { host } = location;
  // 本地开发环境下自行发挥
  baseConfig = {
    authUrl: '//auth.lo.chipwing.tech',
    chipcooUrl: host,
    WECHAT_URL: '//auth.test.chipwing.com/api/wechat/bind',
    pushSocket: `//${host}/webpush`,
    syncSocket: `//${host}/sync`,
    staticUrl: '//attachment-public.test.chipwing.com',
    tdasUrl: '//tdas.lo.chipwing.tech'
  };

  if (!isDev) {
    baseConfig.pushSocket = '//push.lo.chipwing.tech/webpush';
    baseConfig.syncSocket = '//push.lo.chipwing.tech/sync';
  }
}

if (env === 'test') {
  baseConfig = {
    authUrl: '//auth.test.chipwing.tech',
    chipcooUrl: '//test.chipwing.tech',
    WECHAT_URL: '//auth.test.chipwing.tech/api/wechat/bind',
    pushSocket: '//push.test.chipwing.tech/webpush',
    syncSocket: '//push.test.chipwing.tech/sync',
    staticUrl: '//attachment-public.test.chipwing.com',
    tdasUrl: '//tdas.test.chipwing.tech'
  };
}

if (env === 'demo') {
  baseConfig = {
    authUrl: '//auth.demo.chipcoo.com',
    chipcooUrl: '//demo.chipcoo.com',
    WECHAT_URL: '//auth.demo.chipcoo.com/api/wechat/bind',
    pushSocket: '//push.demo.chipcoo.com/webpush',
    syncSocket: '//push.demo.chipcoo.com/sync',
    staticUrl: '//attachment-public.demo.chipcoo.com',
    tdasUrl: '//tdas.demo.chipwing.com'
  };
}

export default Object.assign(base, baseConfig);
