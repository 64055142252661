/**
 * 任务看板最后新建一列stage
 */
import React, { memo } from 'react';
import { Icon } from 'antd';

import styles from './addNewStageList.module.less';

export default memo(() => (
  <div className={styles.wrapper}>
    <div className={styles.contentWrap}>
      <div className={styles.content}>
        <a>
          <Icon type="plus" />
          新建任务列表...
        </a>
      </div>
    </div>
  </div>
));
