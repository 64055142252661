import { createModel } from '@rematch/core';
import { omit, remove } from 'lodash';
import { friendIdToString } from '@chipcoo/fe-utils';

import { getNormalizedData, initialNormalizedData } from 'src/utils';
import {
  InitialState,
  FriendCategory,
  ModifyFriendCategory,
  SetRemarkNameParams,
  FriendDetail,
  PatchFriendApplyParams,
} from './interface';
import { friendApi } from 'src/services/net';
import { RootState } from 'src/store';
import produce from 'immer';
import { isEqualUserId } from 'src/utils/user';

const initialState: InitialState = {
  friends: initialNormalizedData(),
  friendCategory: initialNormalizedData()
};

export * from './selectors';

const {
  getFriends,
  getFriendCategory,
  postFriendCategory,
  patchFriendCategory,
  deleteFriendCategory,
  putApplyFriendByAccount,
  patchFriendRemarkName,
  deleteFriend,
  patchFriendApply
} = friendApi;

export default createModel({
  state: initialState,
  reducers: {
    removeFriend(state: InitialState, userIds: UserId | UserId[]) {
      return produce(state, draft => {
        const { friendCategory, friends } = draft;
        (Array.isArray(userIds) ? userIds : [userIds]).forEach(userId => {
          const { byId: friendsById, allIds: friendsUserIds } = friends;
          if (friendsById[userId]) {
            //
            Reflect.deleteProperty(friendsById, userId);
            remove(friendsUserIds, id => isEqualUserId(userId, id));

            Object.entries(friendCategory.byId).forEach(([_, category]) => {
              remove(category.friends, id => isEqualUserId(userId, id));
            });
          }
        });
      });
    },
    addFriend(state: InitialState, payload: { friendId: string, user: User }) {
      //
      return produce(state, draft => {
        const { user, friendId } = payload;
        const { _id: userId } = user;
        const { byId, allIds } = draft.friends;

        if (!byId[userId]) {
          byId[userId] = {
            user: userId,
            _id: friendId,
          };

          allIds.push(userId);
        }
      });
    },
   },
  effects: (dispatch) => ({
    // 获取所有的好友数据，包含归档分组数据
    async getAllFriends() {
      const [friendsResp, categoryResp] = await Promise.all([getFriends(), getFriendCategory()]);

      // friend的数据做特殊处理，用来建索引的不是friendId，而是user里面的userId：_id

      const normalized: NormalizedData<FriendDetail> = initialNormalizedData();
      const users: User[] = [];
      const online: string[] = [];
      const { byId, allIds } = normalized;

      // 对拿到的 friend 数据进行处理
      // friend 用来建索引的是user的 _id

      friendsResp.data.forEach(friend => {
        const { remarkName, user, _id } = friend;

        const userDetail = { ...omit(user, ['status']), isFriend: true };

        if (remarkName) {
          userDetail.remarkName = remarkName;
        }

        const { _id: userId, status } = user;

        // 用户详情
        users.push(userDetail);

        // 好友详情
        allIds.push(userId);
        byId[userId] = {
          user: userId,
          _id,
        };

        // 用户在线状态
        if (status && (status.isOnline === 'true' || status.isOnline === true)) {
          online.push(userId);
        }
      });

      const categoryData = categoryResp.data.map(item => friendIdToString(item));
      const categories = getNormalizedData(categoryData);

      dispatch.user.setUser(users);
      dispatch.user.setUserOnline(online);

      this.updateState([
        { path: 'friends', data: normalized },
        { path: 'friendCategory', data: categories }
      ]);
    },

    // 新建分组
    async postNewFriendCategory(categoryInfo: Partial<FriendCategory>) {
      const { data } = await postFriendCategory(categoryInfo);
      const { _id } = data;
      const newData = friendIdToString(data);

      this.updateState([
        { method: '$push', path: 'friendCategory.allIds', data: [_id] },
        { path: `friendCategory.byId.${_id}`, data: newData }
      ]);
    },

    // 删除分组
    async deleteFriendCategory(categoryId: string) {
      await deleteFriendCategory(categoryId);

      this.removeNormalizedData({
        removeIds: [categoryId],
        path: 'friendCategory'
      });
    },

    // 编辑分组
    async patchEditFriendCategory(modifyInfo: ModifyFriendCategory) {
      const { _id: categoryId, ...passThoughFormData } = modifyInfo;

      const { data } = await patchFriendCategory(categoryId, { ...passThoughFormData });
      const newData = friendIdToString(data);

      this.updateState({ path: `friendCategory.byId.${categoryId}`, data: newData });
    },

    // 根据账号申请好友
    async putApplyFriendByAccount(accounts: string[]) {
      await putApplyFriendByAccount(accounts);

      return true;
    },

    // 设置某个好友的备注名称
    async patchFriendRemarkName(params: SetRemarkNameParams, rootState: RootState) {
      const { remarkName, userId } = params;

      const friendId = rootState.friend.friends.byId[userId]._id;

      await patchFriendRemarkName(friendId, remarkName);

      dispatch.user.setUser({
        _id: userId,
        remarkName,
      });

      return true;
    },

    // 处理好友申请
    async patchFriendApply(params: PatchFriendApplyParams) {
      const { friendId, action } = params;
      const { data } = await patchFriendApply(friendId, action);
      if (data.status === 'ACCEPT') {
        //
        const { userTo: user, _id } = data;
        this.addFriendDispatch({ user, friendId: _id });
      }

      return data;
    },

    async removeFriendDispatch(userId: string) {
      await dispatch.user.setUser({ _id: userId, remarkName: void 0, isFriend: false });

      this.removeFriend(userId);
    },

    async addFriendDispatch(payload: { friendId: string, user: User }) {
      await dispatch.user.setUser({ ...payload.user, isFriend: true });
      this.addFriend(payload);
    },

    // 删除好友
    async deleteFriend(userId: string, rootState: RootState) {
      const { friend: { friends: { byId: friendsById } } } = rootState;
      const friendId = friendsById[userId]._id;

      await deleteFriend(friendId);

      await this.removeFriendDispatch(userId);

      return true;
    },

    // 点击聊天
    async goChat(userId: string) {
      // 创建一个房间，拿到私聊的房间id
      return await dispatch.chatRoomList.createRoom([userId]);
    },

    // socket推送
    // 添加好友
    async onCreateFriend(data: ISyncSocketData, rootState: RootState) {
      const { payload: { friend: friendId, oppositeUser: user } } = data;

      this.addFriendDispatch({ user, friendId });
    },
    // 移除好友
    async onRemoveFriend(data: ISyncSocketData, rootState: any) {
      const { payload: { friend: { userTo } } } = data;
      const { friend: { friends: { byId } } } = rootState;

      if (byId[userTo]) {
        this.removeFriendDispatch(userTo);
      }
    },
  })
  // tslint:disable-next-line
});
