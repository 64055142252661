/**
 * 客户出库库存列表
 */
import React, { PureComponent } from 'react';
import _, { get, omit } from 'lodash';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { CardTableV2, TableCategory } from '@chipcoo/hanayo';
import { getColumnConfigProps } from '@chipcoo/hanayo/lib/Package/Basic/TableColumnEditor';
import { ICardTableController } from '@chipcoo/hanayo/lib/CardTableV2';
import { GetTableDataParams } from '@chipcoo/hanayo/lib/CardTableV2/Addon/EnhancedTable';
import { EnumProductModel } from '@chipcoo/constant';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import log from 'loglevel';
import { noop, getSelectedRows } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { connectDecorator, RootState, Actions } from 'src/store';
import { State } from 'src/models/operation/inventory/interface';
import { TableCheckboxCtx } from 'src/containers/TableCheckBoxGroup/context';
import CardExtra from './components/CardExtra';
import { getTableColumns } from './components/getTableColumns';
import { filterFormConfig } from '../components/getFilterFormConfig';
import { categoryConfig } from '../formConfig';
import DetailFormModal from './DetailFormModal';
import { getInitialQuery } from 'src/utils';

const { EnhancedTable, QueryTermTags } = CardTableV2;

interface OwnProps extends RouteComponentProps<any> {}

interface StateProps {
  list: any[];
  count: number;
  detail: Obj;
  statistics: State['statistics'];
}
interface DispatchProps {
  getTableList: (params: any) => void;
  postCreate: (value: any) => void;
}
type P = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface S {
  category: EnumProductModel;
  tableLoading: boolean;
  detailModalVisible: boolean;
  detailId: string;
  modalFullWindow: boolean;
  queryFormConfig: FormItemConfig[];
  changeCheck: (val: any) => void;
  isResetDefault: boolean;
  dataSource: any[];
  selectedRows: any[];
}

@connectDecorator(
  ({ stockOut: { list, count, detail, statistics } }: RootState) => ({ list, count, detail, statistics }),
  ({
     stockOut: { getTableList, postCreate }
   }: Actions) => ({
    getTableList, postCreate,
  })
)
class InventoryTable extends PureComponent<P, S> {
  private cardTableController: ICardTableController;
  private tableName = 'STOCK_OUT_APPLY';
  // private readonly tableColumns: ColumnProps<any>[] = [];
  private queryParams;

  constructor(props: P) {
    super(props);

    const { location, list } = props;
    const productModel = get(location?.state, 'productModel') || EnumProductModel.spwDie;

    this.state = {
      category: productModel,
      tableLoading: false,
      detailModalVisible: false,
      detailId: '',
      modalFullWindow: true,
      queryFormConfig: filterFormConfig(productModel, 'stockOutApplication'),
      changeCheck: this.changeCheck,
      isResetDefault: false,
      dataSource: list ?? [],
      selectedRows: [],
    };
  }

  componentDidMount(): void {
    this.getInitialQuery();

    this.getCategoryByLocation();

    this.setState({ dataSource: this.props.list });
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
    const { location, list } = this.props;
    const { id } = this.props.match.params;
    if (prevProps.match.params.id !== id) {
      this.setState({ category: EnumProductModel.spwDie }, () => {
        this.reloadTable();
      });
    }

    if (!_.isEqual(location.state, prevProps.location?.state)) {
      this.getCategoryByLocation();
    }

    if (!_.isEqual(list, prevProps.list)) {
      this.setState({ dataSource: list });
    }
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  getCategoryByLocation = () => {
    const { location, history } = this.props;
    const state = location?.state;
    const productModel = get(state, 'productModel');
    let newState = omit(state, 'productModel');

    history.replace({ ...history.location, state: newState });

    if (productModel) {
      this.setCategory(productModel);
    }
  }

  changeCheck = (val) => {
    const { selectedRows, dataSource } = this.state;
    const data = [...dataSource];
    const { itemType, key } = val;
    const [_id] = key?.split('_');

    // 列表逻辑，个数不会变，只会更改某一项的checked
    const newDataSource = data?.map(d => {
      if (itemType === 'sub' && d.key === _id) {
        const children = d?.children.map(sub => ({
          ...sub,
          checked: sub.key === key ? !sub.checked : sub.checked,
        }));
        // 是否存在没有选中的item
        const noCheckedArray = children.filter(sub => !sub.checked);

        return {
          ...d,
          checked: _.isEmpty(noCheckedArray),
          children
        };
      }

      if (itemType === 'group' && d.key === key) {
        // group的checked判断逻辑
        const dChecked = _.isEmpty(d?.children)
          ? !Boolean(d.checked)
          : (
            _.has(d, 'checked') ? !d.checked : true
          );

        return {
          ...d,
          checked: dChecked,
          children: d?.children
            ? d.children?.map(c => ({
              ...c,
              checked: dChecked
            }))
            : false
        };
      }

      return d;
    });

    const selectedByOtherPage = _.differenceBy([...selectedRows], newDataSource, 'key');
    const selectedData = getSelectedRows(newDataSource, selectedByOtherPage);

    this.setState({ dataSource: newDataSource, selectedRows: selectedData });
  }

  openQuery = () => {
    if (this.cardTableController) {
      this.cardTableController.setQueryVisible(true);
    }
  }

  getCardTableController = controller => {
    this.cardTableController = controller;
  }

  setCategory = (category: S['category']) => {
    this.setState({
      category,
      isResetDefault: true,
      queryFormConfig: filterFormConfig(category, 'stockOutApplication')
    }, () => {
      // 如果是从通知点击过来的，每次点击分类切换后，重置
      if (this.props?.location?.search) {
        this.clearQuery();
      }

      if (this.cardTableController) {
        // TODO: 如果此处不能达到预期的重置筛选/查询的作用，请使用 getInitialQueryParams();

        this.cardTableController.setQueryParams!({
          page: 1,
          formData: {}
        });
      }
    });
  }

  getTableData = async (params: GetTableDataParams) => {
    const { getTableList } = this.props;
    const { category } = this.state;
    const { formData, ...extraParams } = params;
    const _formData = formData && Object.entries(formData).reduce(
      (prev, [key, val]) => {
        const value: any = _.cloneDeep(val);

        switch (key) {
          case 'material':
            prev[key] = value ? value.key : undefined;
            break;
          case 'product':
            prev[key] = value ? value.key : undefined;
            break;
          case 'warehouse':
            prev[key] = value ? value.key : undefined;
            break;
          default:
            prev[key] = value ? value : undefined;

            break;
        }

        return prev;
      },
      {} as any
    );

    this.queryParams = {
      productModel: category,
      ...extraParams,
      ..._formData,
    };

    this.setState({ tableLoading: true });
    let dataSource: any[] = [];

    try {
      await getTableList({...this.queryParams});

      dataSource = this.props.list?.map(it => {
        const selectedItem = this.state.selectedRows?.find(s => s._id === it._id);

        if (selectedItem) {
          if (selectedItem?.children) {
            const childrenKeys = selectedItem?.children?.map(c => c.key);

            const newChildren = it.children.map(c => {
              if (childrenKeys.includes(c.key)) {
                return {
                  ...c,
                  checked: true,
                  // ...selectedItem?.children.find(sc => sc.key === c.key),
                };
              }

              return c;
            });

            return {
              ...selectedItem,
              children: newChildren,
            };
          } else {
            return selectedItem;
          }
        }

        return it;
      });
    } catch (e) {
      console.error(e);
    }

    this.setState({ tableLoading: false, dataSource });
  }

  getTerms = (query: Obj) => {
    const config = this.state.queryFormConfig;

    return config.reduce((result, curCfg) => {
      const dataKey = get(curCfg, 'dataKey');

      if (!dataKey) {
        return result;
      }

      let val = get(query, dataKey);
      if (_.isObject(val)) {
        val = get(val, 'label');
      }

      if (val) {
        const label = get(curCfg, 'label');
        const getDisplay = get(curCfg, 'getDisplay');

        if (getDisplay) {
          val = getDisplay(val);
        }

        result[label] = val;

        if (val && ['string', 'number'].indexOf(typeof val) === -1) {
          log.warn('[getTerms]: 未被正确转换的查询条件展示', label, val, curCfg);
          Reflect.deleteProperty(result, label);
        }
      }

      return result;
    }, {} as any);
  }

  openAddModal = () => {
    const { selectedRows } = this.state;
    if (selectedRows?.length > 0) {

      this.setState({ detailModalVisible: true });
    } else {
      message.info('请先选择要出库的物料!');
    }
  }

  handleSubmit = async (values) => {
    const { postCreate } = this.props;

    try {
      await postCreate(values);
      this.closeModalWithReload();
    } catch (e) {
      console.error(e);
    }
  }

  reloadTable() {
    this.cardTableController && this.cardTableController.reloadTable();
  }

  closeDetailModal = () => this.setState({ detailModalVisible: false });

  closeModalWithReload = () => {
    this.closeDetailModal();

    this.reloadTable();

    this.setState({
      tableLoading: false
    });
  }

  clearQuery = () => {
    const { history } = this.props;

    history.push(this.props.match.url);
  }

  getInitialQuery = () => {
    const initialQuery = getInitialQuery(this.props);
    const category = initialQuery?.category;

    if (category) {
      this.setState({ category });
    }

    return _.omit(initialQuery, 'category');
  }

  render() {
    const { count, statistics } = this.props;
    const {
      tableLoading,
      detailModalVisible,
      modalFullWindow,
      category,
      queryFormConfig,
      isResetDefault,
      dataSource,
      selectedRows,
      ...restState
    } = this.state;

    const tableColumns = getColumnConfigProps(getTableColumns({
      productModel: category
    }), this.tableName);

    return (
      <div className={classNames(styles.stockList, 'wrap-content')}>
        <TableCategory
          defaultCategory={getInitialQuery(this.props)?.category || category}
          title="产品类型"
          statistics={statistics}
          setCategory={this.setCategory}
          categoryConfig={categoryConfig}
          isResetDefault={isResetDefault}
        />
        <TableCheckboxCtx.Provider value={restState}>
          <CardTableV2
            card={{
              title: '可出库列表',
              extra: (
                <CardExtra
                  queryParams={this.queryParams}
                  openQuery={this.openQuery}
                  openModal={this.openAddModal}
                />
              )
            }}
            queryFormConfig={queryFormConfig}
            getCardTableController={this.getCardTableController}
          >
            <QueryTermTags
              getTerms={this.getTerms}
              openQueryPanel={this.openQuery}
              clearQuery={this.clearQuery}
            />

            <EnhancedTable
              total={count}
              dataSource={dataSource}
              tableLoading={tableLoading}
              getTableData={this.getTableData}
              columns={tableColumns}
              clickMention={true}
              size="small"
              tableName={this.tableName}
              // initialQuery={this.getInitialQuery()}
            />
          </CardTableV2>
        </TableCheckboxCtx.Provider>

        <DetailFormModal
          visible={detailModalVisible}
          onOk={this.handleSubmit}
          onCancel={this.closeDetailModal}
          productModel={category}
          selectedRows={selectedRows}
        />
      </div>
    );
  }
}

export default InventoryTable;
// tslint:disable:max-file-line-count
