import { get } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';

interface Options {
  demandQuantity?: number;
  waferCount?: number;
  dieQtyInPackage: number;
  perWaferDieQty: number;
  productModel: EnumProductModel;
}

export const calcCount = (params: Options) => {
  const {
    dieQtyInPackage,
    perWaferDieQty,
    productModel
  } = params;
  let [demandWaferQuantity, demandQuantity]: any[] = [undefined, undefined];

  if (params?.demandQuantity) {
    if (productModel === EnumProductModel.assemblyProduct) {
      // 封装成品，需要算上dieQtyInPackage
      demandWaferQuantity = params?.demandQuantity * dieQtyInPackage / perWaferDieQty;
    } else {
      demandWaferQuantity = params?.demandQuantity / perWaferDieQty;
    }

    return demandWaferQuantity;
  }

  if (params?.waferCount) {
    if (productModel === EnumProductModel.assemblyProduct) {
      // 封装成品，需要算上dieQtyInPackage
      demandQuantity = params?.waferCount * perWaferDieQty / dieQtyInPackage;
    } else {
      demandQuantity = params?.waferCount * perWaferDieQty;
    }

    return demandQuantity;
  }
};

interface DieQtyFuncOption {
  productInfo: any;
  productModel: EnumProductModel;
}

export const getDieQty = (params: DieQtyFuncOption) => {
  const { productInfo, productModel } = params;
  let [dieQtyInPackage, perWaferDieQty] = [0, 0];

  if (productInfo) {
    if (productModel === EnumProductModel.assemblyProduct) {
      if (get(productInfo, 'product.dies.length') === 1) {
        // 单封
        dieQtyInPackage = productInfo?.product?.dies?.[0]?.dieQtyInPackage;
        perWaferDieQty = get(productInfo, 'product.dies.0.die.product.perWaferDieQty');
      } else {
        // 合封
      }
    }

    if (productModel === EnumProductModel.dpProduct) {
      perWaferDieQty = productInfo?.product?.die?.product?.perWaferDieQty;
    }
  }

  return {
    perWaferDieQty,
    dieQtyInPackage,
  };
};
