import React, { PureComponent } from 'react';
import { bind } from 'lodash-decorators';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { noop } from '@chipcoo/fe-utils';

import './style.module.less';
import { OperateProps } from './';

interface Props extends OperateProps {
  title: string;
  index: number;
  count: number;
  identifier: string;
}

interface States {
  menuVisible: boolean;
  loading: boolean;
}

export class GroupTitle extends PureComponent<Props, States> {
  state = {
    menuVisible: false,
    loading: false,
  };

  $el = React.createRef<React.ReactNode>();

  componentWillUnmount() {
    this.setState = noop;
  }

  @bind
  handleClick(e: any) {
    if (this.state.menuVisible) {
      e && e.stopPropagation();
    }
  }

  @bind
  stopPropagation(e: any) {
    e && e.stopPropagation();
  }

  @bind
  handleMenuClick(e: any) {
    console.log(e, 'menu');
    // e && e.stopPropagation();
  }

  @bind
  setVisible(visible: boolean) {
    // Wait a tick for stopPropagation
    setTimeout(() => {
      this.setState({ menuVisible: visible });
    }, 30);
  }

  @bind
  triggerEdit() {
    const { identifier, onEdit } = this.props;
    onEdit(identifier);
    this.close();
  }

  @bind
  async triggerRemove() {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });
    const { onRemove, identifier } = this.props;

    try {
      await onRemove(identifier);
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
    this.close();
  }

  @bind
  close() {
    this.setVisible(false);
  }

  renderMenu() {
    return (
      <Menu>
        <Menu.Item key="edit" onClick={this.triggerEdit}>编辑分组</Menu.Item>
        <Menu.Item key="remove">
          <Popconfirm title="是否删除该分组" onConfirm={this.triggerRemove}>
            <a>删除分组</a>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  }

  renderEditBtn(i: number) {
    const { menuVisible } = this.state;
    return (
      <Dropdown
        visible={menuVisible}
        trigger={['click']}
        overlay={this.renderMenu()}
        onVisibleChange={this.setVisible}
        overlayClassName="member-group-edit-overlay"
      >
        <a className="group-edit-icon" onClick={this.stopPropagation}>
          <i className="c-icon icon-spot-transverse" />
        </a>
      </Dropdown>
    );
  }

  render() {
    const { title, index, count, renderEdit } = this.props;
    const isAll = index === 0;

    return (
      <div className="member-group-title flex" onClick={this.handleClick}>
        <span className="ellipsis cell group-name">{title}</span>
        {(!isAll && !!renderEdit) && this.renderEditBtn(index)}
        <span className="member-count">{count}</span>
      </div>
    );
  }
}

export default GroupTitle;
