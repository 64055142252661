// tslint:disable:max-file-line-count
import React from 'react';
import { get, trim, isEmpty } from 'lodash';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SelectWithDynamicOptionConfig } from '@chipcoo/hanayo';
import { Option as EnhanceOptionProps } from '@chipcoo/hanayo/lib/SelectWithOption';
import {
  FormConfig,
  FormItemConfig,
  DividerFormItemConfig,
  InputFormItemConfig,
  NumberFormItemConfig,
  NumberGroupFormItemConfig,
  SelectFormItemConfig,
  FileFormItemConfig,
  TextAreaFormItemConfig,
  DateFormItemConfig,
  CustomFormItemConfig,
  RepeaterConfig,
  RepeaterRenderParams
} from '@chipcoo/hanayo/lib/FormCreator';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';

import commonStyles from '../../index.module.less';
import { attachmentApi } from 'src/services/net';
import {
  divider,
  principal,
  participants,
  state,
  stateDescription,
  getCustomerName,
} from './common';
import ProdTips0101 from 'src/assets/images/prompt/ProdTips-0101.png';
import ProdTips0102 from 'src/assets/images/prompt/ProdTips-0102.png';
import ProdTips0103 from 'src/assets/images/prompt/ProdTips-0103.png';
import ProdTips0104 from 'src/assets/images/prompt/ProdTips-0104.png';

import { RepeaterCardTitle } from 'src/components';
import VersionFormItemComponent from '../components/Version';
// import { beforeSelectInitialVersionDie } from './FormItemComponent/BeforeSelectInitialVersionDie';

// 当前modal是怎么打开的，新建、编辑、还是被引用
export type ModalScene = ModalMode | 'cited';
// 当前config是被谁引用，[]表明没有任何产品引用
// '被bump芯片引用' | '被封装成品引用' | '被已测芯片引用' | '被磨划芯片引用'
export type DieCitedByType = 'citedByBump' | 'citedByAssembly' | 'citedByCp' | 'citedByDp';
export type DieCitedByTypeArr = DieCitedByType[];

// 被引用时，是去完善还是新建
export type DieModalOption = { citedBy: DieCitedByTypeArr, type: ModalScene, isCitedAddNew?: boolean };

// 选择母版芯片的时候，下拉项里不能有当前填写的这个dieName
const filterDieNameInOption = (dieName?: string) => (options: EnhanceOptionProps[]) => {
  const _dieName = trim(dieName);

  if (!_dieName) return options;

  return options.filter(item => item.value !== _dieName);
};

// function getProcessRequire(formData: any, ) {
//   const { citedBy } = options;
//   let processRequire = get(formData, 'processRequirement');
//
//   // if (citedBy.includes('citedByBump')) {
//   //   processRequire = isArray(processRequire) ? processRequire.push('Bumping') : ['Bumping'];
//   // }
//   //
//   // if (citedBy.includes('citedByCp')) {
//   //   processRequire = isArray(processRequire) ? processRequire.push('中测') : ['中测'];
//   // }
//   //
//   // if (citedBy.includes('citedByDp')) {
//   //   processRequire = isArray(processRequire) ? processRequire.push('磨划') : ['磨划'];
//   // }
//   //
//   // if (citedBy.includes('citedByAssembly')) {
//   //   processRequire = isArray(processRequire) ? processRequire.push('引线类封装', '其他类封装') : ['引线类封装', '其他类封装'];
//   // }
//
//   return processRequire;
// }

// 是否可见
const isVisible = (formData, arr: string[]) => {
  const processRequire = get(formData, 'processRequirement');

  if (!processRequire || isEmpty(processRequire) || processRequire.some(i => arr.includes(i))) {
    return 'preview';
  }

  return 'none';
};

const passivationVisibleArray = ['Bumping'];
const metalVisibleArray = passivationVisibleArray.concat('引线类封装');
const padVisibleArray = metalVisibleArray.concat('中测');
const waferVisibleArray = ['引线类封装', '其他类封装', 'Bumping', '磨划'];

// 流片方案divider
const flowSchemesDivider = (index: number): DividerFormItemConfig => ({
  type: 'divider',
  renderType: (value, initialValues) => {
    const flowState = get(initialValues, `schemes.${index}.state`);

    return flowState ? 'normal' : 'none';
  }
});

// 工厂state divider
export const stateSchemesDivider = (index: number): DividerFormItemConfig => ({
  type: 'divider',
  renderType: (value) => {
    const factory = get(value, `schemes.${index}.factory`) || get(value, `schemes.${index}.factoryName`);

    return factory ? 'normal' : 'none';
  }
});
const bumpingDivider = (): DividerFormItemConfig => ({
  type: 'divider',
  renderType: (value) => isVisible(value, passivationVisibleArray)
});
const padDivider = (): DividerFormItemConfig => ({
  type: 'divider',
  renderType: (value) => isVisible(value, padVisibleArray)
});

// 制版性质,作为搜索项了，如果要调整请注意
// export const maskProperty = (): SelectFormItemConfig => ({
//   label: '制版性质',
//   type: 'select',
//   dataKey: 'mask.maskProperty',
//   optionKey: 'maskProperty',
//   renderType: 'preview',
// });

// 母版芯片名(作为搜索项了，如果要调整请注意)
export const initialVersionDieName = (): CustomFormItemConfig => ({
  label: '母版芯片名',
  type: 'custom',
  dataKey: 'mask.initialVersionDieName',
  renderType: (values) => {
    if (get(values, 'mask.maskProperty') === 'updateVersion') {
      return 'preview';
    }

    return 'none';
  },
  render: (val, globalStatus, form: WrappedFormUtils) => {
    const dieName = val.dieName;

    return (
      <SelectWithDynamicOptionConfig
        status={globalStatus}
        placeholder="请选择（可筛选）"
        optionKey="spwOriginDie"
        addNew={null}
        filterOptionByCustom={filterDieNameInOption(dieName)}
        // beforeSelect={beforeSelectInitialVersionDie(val, form) as any}
      />
    );
  }
});

// 版本号
const reversion = (): CustomFormItemConfig => ({
  label: '版本号',
  type: 'custom',
  dataKey: 'mask.reversion',
  renderType: (values) => {
    if (get(values, 'mask.maskProperty') === 'updateVersion') {
      return 'preview';
    }

    return 'none';
  },
  render: (formData, status: string, form: WrappedFormUtils) =>
    (
      <VersionFormItemComponent
        initialVersionDieName={get(formData, 'mask.initialVersionDieName')}
        resetFields={form.resetFields}
        status={status}
      />
    ),
});

// 制版类型(作为搜索项了，如果要调整请注意)
export const maskType: SelectFormItemConfig = {
  label: '制版类型',
  type: 'select',
  dataKey: 'mask.maskType',
  optionKey: 'maskType',
  renderType: 'preview',
};

// 制版时间(作为搜索项了，如果要调整请注意)
export const maskSetupTime: DateFormItemConfig = {
  label: '制版时间',
  type: 'date',
  dataKey: 'mask.maskSetupTime',
  renderType: 'preview',
};
// 制版说明
const maskDescription: TextAreaFormItemConfig = {
  label: '制版说明',
  type: 'textArea',
  dataKey: 'mask.maskDescription',
  colSpan: 24,
  renderType: 'preview',
  validate: [{ pattern: /^[\d\D]{0,300}$/, message: '请输入最大长度为300的制版说明' }]
};
// 流片厂(作为搜索项了，如果要调整请注意)
export const fabSite: InputFormItemConfig = {
  label: '流片厂',
  type: 'input',
  dataKey: 'factoryName',
  renderType: 'preview',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的流片厂' }]
};
// 流片工艺-后续要做成下拉选择(作为搜索项了，如果要调整请注意)
export const processName: InputFormItemConfig = {
  label: '流片工艺',
  type: 'input',
  dataKey: 'processName',
  renderType: 'preview',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的流片工艺' }]
};
// 流片厂芯片名(作为搜索项了，如果要调整请注意)
export const dieNameInFoundry: InputFormItemConfig = {
  label: '流片厂芯片名',
  type: 'input',
  dataKey: 'dieNameInFoundry',
  renderType: 'preview',
  validate: [{ pattern: /^.{0,30}$/, message: '请输入最大长度为30的流片厂芯片名' }]
};
// Full Mapping文件
const fullMapping: FileFormItemConfig = {
  label: 'Full Mapping文件',
  type: 'file',
  dataKey: 'fullMappingFile',
  hint: '如果该产品后续准备不做中测而直接封装的，Full Mapping 文件可以帮助提升质量管控的效果。',
  props: {
    getFileDetail: fileId => attachmentApi.getDetail(fileId),
    getUrl: fileId => attachmentApi.download({ attachmentId: fileId })
  },
  renderType: 'preview',
};

// 晶圆直径(作为搜索项了，如果要调整请注意)
export const waferDiameter = (): SelectFormItemConfig => ({
  label: '晶圆直径',
  type: 'select',
  dataKey: 'info.waferDiameter',
  optionKey: 'waferDiameter',
  renderType: 'preview',
});
// 工艺节点(作为搜索项了，如果要调整请注意)
export const waferTechnology = (): SelectFormItemConfig => ({
  label: '工艺节点',
  type: 'select',
  dataKey: 'info.waferTechnology',
  hint: 'IC生产工艺可达到的最小导线宽度',
  optionKey: 'waferTechnology',
  renderType: 'preview',
});
// 晶圆厚度
const waferThickness = (): NumberFormItemConfig => ({
  label: '晶圆厚度',
  type: 'number',
  dataKey: 'info.waferThickness',
  unit: 'µm',
  range: '[100, 1000]',
  precision: 0,
  renderType: (formData) => isVisible(formData, waferVisibleArray),
});
// 是否为LOW-K工艺
const isLowKProcess = (): SelectFormItemConfig => ({
  label: '是否为LOW-K工艺',
  type: 'select',
  dataKey: 'info.isLowKProcess',
  hint: (
    <div>
      <p>Low-K是指低介电常数(低于二氧化硅的介电常数3.9)。在金属层之间采用Low-K材料作为介电材料，可减少寄生电容，一般应用于铜互连工艺。</p>
      <p>Low－K晶圆的磨、划工艺与一般硅晶圆不太一样，主要是刀具材质、进刀速度、转速等参数需要优化。</p>
    </div>
  ),
  optionKey: 'whether',
  renderType: (formData) => isVisible(formData, waferVisibleArray),
});
// 芯片尺寸(不含划片道)
const dieSize = (): NumberGroupFormItemConfig => ({
  label: '芯片尺寸(不含划片道)',
  type: 'numberGroup',
  dataKey: 'info.dieSize',
  unit: 'µm',
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0101} />
    </div>
  ),
  renderType: 'preview',
  groupItem: [
    {
      placeholder: '尺寸: L',
      range: '(0, 30000]',
      precision: 3,
      requiredMsg: '请填写芯片尺寸: L',
    },
    {
      placeholder: '尺寸: W',
      range: '(0, 30000]',
      precision: 3,
      requiredMsg: '请填写芯片尺寸: W',
    }
  ]
});
// 最小划片道宽度
const minStreetWidth = (): NumberFormItemConfig => ({
  label: '最小划片道宽度',
  type: 'number',
  dataKey: 'info.minStreetWidth',
  unit: 'µm',
  range: '(0, 200]',
  precision: 0,
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0102} />
    </div>
  ),
  renderType: (formData) => isVisible(formData, waferVisibleArray)
});
// 初始有效管芯数
const perWaferDieQty = (): NumberFormItemConfig => ({
  label: '初始有效管芯数',
  type: 'number',
  dataKey: 'perWaferDieQty',
  precision: 0,
  unit: '颗',
  renderType: (formData) => isVisible(formData, ['Bumping', '中测']),
});
// 最小焊盘尺寸
const minPadSize = (): NumberGroupFormItemConfig => ({
  label: '最小焊盘尺寸',
  type: 'numberGroup',
  dataKey: 'info.minPadSize',
  unit: 'µm',
  precision: 0,
  renderType: (formData) => isVisible(formData, padVisibleArray),
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0103} />
    </div>
  ),
  modalHintProps: { width: 666 },
  groupItem: [
    {
      placeholder: '尺寸: L',
      range: '(0, 200]',
      requiredMsg: '请填写尺寸: L',
    },
    {
      placeholder: '尺寸: W',
      range: '(0, 200]',
      requiredMsg: '请填写尺寸: W'
    }
  ]
});
// 最小焊盘间距
const minPadPitch = (): NumberFormItemConfig => ({
  label: '最小焊盘间距',
  type: 'number',
  dataKey: 'info.minPadPitch',
  unit: 'µm',
  range: '(0, 1000]',
  precision: 0,
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0103} />
    </div>
  ),
  modalHintProps: { width: 666 },
  renderType: (formData) => isVisible(formData, padVisibleArray),
});
// 顶铝厚度
const topMetalThickness = (): NumberFormItemConfig => ({
  label: '顶铝厚度',
  type: 'number',
  dataKey: 'info.topMetalThickness',
  unit: 'KA',
  range: '(0, 200]',
  precision: 1,
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0104} />
    </div>
  ),
  modalHintProps: { width: 1055 },
  renderType: (formData) => isVisible(formData, metalVisibleArray),
});
// 顶铝成分
const topMetalComposition = (): InputFormItemConfig => ({
  label: '顶铝成分',
  type: 'input',
  dataKey: 'info.topMetalComposition',
  hintType: 'modal',
  hint: (
    <div className="text-center">
      <img src={ProdTips0104} />
    </div>
  ),
  modalHintProps: { width: 1055 },
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的顶铝成分' }],
  renderType: (formData) => isVisible(formData, metalVisibleArray),
});
// 焊盘下有无电路
const isCircuitUnderPad = (): SelectFormItemConfig => ({
  label: '焊盘下有无电路',
  type: 'select',
  dataKey: 'info.isCircuitUnderPad',
  optionKey: 'haveOrNot',
  hint: '焊盘下有无电路会影响封装厂设定焊压工序的工艺参数。',
  renderType: (formData) => isVisible(formData, metalVisibleArray),
});
// 焊盘下有无器件
const isDeviceUnderPad = (): SelectFormItemConfig => ({
  label: '焊盘下有无器件',
  type: 'select',
  dataKey: 'info.isDeviceUnderPad',
  optionKey: 'haveOrNot',
  hint: '在芯片封装压焊时，焊盘及其周边区域的瞬间压力很大，很容易造成区域内器件损坏，因此：1、不建议在焊盘及其附近25µm的范围内放置重要的' +
    '器件；2、 仅在金属层较多时允许在此区域放置ESD器件等非关键器件。',
  renderType: (formData) => isVisible(formData, metalVisibleArray),
});
// 焊盘下器件描述
const deviceUnderPadDescription = (): TextAreaFormItemConfig => ({
  label: '焊盘下器件描述',
  type: 'textArea',
  dataKey: 'info.deviceUnderPadDescription',
  colSpan: 24,
  hint: '请描述哪些PAD下有器件，分别在第几层。',
  validate: [{ pattern: /^[\d\D]{0,100}$/, message: '请输入最大长度为100的焊盘下器件描述' }],
  ifRender: (values) => isVisible(values, metalVisibleArray) === 'preview'
    && get(values, 'info.isDeviceUnderPad') === 'true',
  renderType: 'preview',
});
// 晶圆材料
const waferMaterial = (): SelectFormItemConfig => ({
  label: '晶圆材料',
  type: 'select',
  dataKey: 'info.waferMaterial',
  optionKey: 'waferMaterial',
  renderType: (formData) => isVisible(formData, passivationVisibleArray),
});
// 其他晶圆材料
const otherWaferMaterial = (): InputFormItemConfig => ({
  label: '其他晶圆材料',
  type: 'input',
  dataKey: 'info.otherWaferMaterial',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的其他晶圆材料' }],
  ifRender: (values) => isVisible(values, passivationVisibleArray) === 'preview'
    && get(values, 'info.waferMaterial') === 'other',
  renderType: 'preview',
});
// 钝化层成分
const passivationComposition = (): SelectFormItemConfig => ({
  label: '钝化层成分',
  type: 'select',
  dataKey: 'info.passivationComposition',
  optionKey: 'passivationComposition',
  renderType: (formData) => isVisible(formData, passivationVisibleArray),
});
// 钝化层厚度
const passivationThickness = (): NumberFormItemConfig => ({
  label: '钝化层厚度',
  type: 'number',
  dataKey: 'info.passivationThickness',
  unit: 'µm',
  range: '(0, 10]',
  precision: 3,
  renderType: (formData) => isVisible(formData, passivationVisibleArray),
});
// 划片道是否有钝化层
const isPassivationInSawStreet = (): SelectFormItemConfig => ({
  label: '划片道是否有钝化层',
  type: 'select',
  dataKey: 'info.isPassivationInSawStreet',
  optionKey: 'whether',
  renderType: (formData) => isVisible(formData, passivationVisibleArray),
});
// 钝化层开窗形状
const passivationOpeningShape = (): SelectFormItemConfig => ({
  label: '钝化层开窗形状',
  type: 'select',
  dataKey: 'info.passivationOpeningShape',
  optionKey: 'passivationOpeningShape',
  renderType: (formData) => isVisible(formData, passivationVisibleArray),
});
// 钝化层开窗直径
const passivationOpeningDiameter = (): NumberFormItemConfig => ({
  label: '钝化层开窗直径',
  type: 'number',
  dataKey: 'info.passivationOpeningDiameter',
  unit: 'µm',
  precision: 0,
  range: '(0,200]',
  ifRender: (values) => {
    return isVisible(values, passivationVisibleArray) === 'preview' && (
      get(values, 'info.passivationOpeningShape') === 'circular'
      || get(values, 'info.passivationOpeningShape') === 'octagonal'
    );
  },
  renderType: 'preview',
});
// 钝化层开窗尺寸
const passivationOpeningSize = (): NumberGroupFormItemConfig => ({
  label: '钝化层开窗尺寸',
  type: 'numberGroup',
  dataKey: 'info.passivationOpeningSize',
  unit: 'µm',
  precision: 0,
  renderType: 'preview',
  ifRender: (values) => isVisible(values, passivationVisibleArray) === 'preview'
    && get(values, 'info.passivationOpeningShape') === 'rectangular',
  groupItem: [
    {
      placeholder: '尺寸: L',
      range: '(0, 200]',
      requiredMsg: '请填写尺寸: L',
    },
    {
      placeholder: '尺寸: W',
      range: '(0, 200]',
      requiredMsg: '请填写尺寸: W'
    }
  ]
});
// 其他钝化层开窗形状
const otherPassivationOpeningShape = (): InputFormItemConfig => ({
  label: '其他钝化层开窗形状',
  type: 'input',
  dataKey: 'info.otherPassivationOpeningShape',
  ifRender: (values) => isVisible(values, passivationVisibleArray) === 'preview'
    && get(values, 'info.passivationOpeningShape') === 'other',
  validate: [{ pattern: /^.{0,50}$/, message: '请输入最大长度为50的其他钝化层开窗形状' }],
  renderType: 'preview',
});
// 其他钝化层开窗尺寸
const otherPassivationOpeningSize = (): InputFormItemConfig => ({
  label: '其他钝化层开窗尺寸描述',
  type: 'input',
  dataKey: 'info.otherPassivationOpeningSize',
  ifRender: (values) => isVisible(values, passivationVisibleArray) === 'preview'
    && get(values, 'info.passivationOpeningShape') === 'other',
  validate: [{ pattern: /^.{0,100}$/, message: '请输入最大长度为100的其他钝化层开窗尺寸描述' }],
  renderType: 'preview',
});

// 制版信息
export const getMaskInfo = (): FormItemConfig[] => ([
  // maskProperty(),
  initialVersionDieName(),
  reversion(),
  maskType,
  maskSetupTime,
  maskDescription
]);

// 流片方案
export const getFlowSchemes = (index) => ([
  fabSite,
  processName,
  dieNameInFoundry,
  divider,
  fullMapping,
  flowSchemesDivider(index),
  state(index),
  stateDescription(index)
]);
export const getFlowSolution = (): RepeaterConfig => ({
  type: 'repeater',
  dataKey: 'schemes',
  showAddNewBtn: false,
  renderRepeater(params: RepeaterRenderParams) {
    return ({
      card: {
        title: RepeaterCardTitle('流片方案', params.index, params.len),
      },
      groupItemConfig: getFlowSchemes(params.index)
    });
  },
});

// 常用信息
export const getCommonInfo = (): FormItemConfig[] => ([
  waferDiameter(),
  waferTechnology(),
  waferThickness(),
  isLowKProcess(),
  divider,
  dieSize(),
  minStreetWidth(),
  perWaferDieQty(),
  padDivider(),
  minPadSize(),
  minPadPitch(),
  topMetalThickness(),
  topMetalComposition(),
  isCircuitUnderPad(),
  isDeviceUnderPad(),
  deviceUnderPadDescription(),
  bumpingDivider(),
  waferMaterial(),
  otherWaferMaterial(),
  passivationComposition(),
  passivationThickness(),
  isPassivationInSawStreet(),
  passivationOpeningShape(),
  passivationOpeningDiameter(),
  passivationOpeningSize(),
  otherPassivationOpeningShape(),
  otherPassivationOpeningSize(),
]);

export const getSpwDieFormConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: {
        title: '基本信息',
        className: commonStyles.hiddenCardTitle
      },
      groupItemConfig: [
        getCustomerName(),
        principal,
        participants
      ]
    },
    {
      card: {
        title: '制版信息',
      },
      groupItemConfig: getMaskInfo(),
    },
    getFlowSolution(),
    {
      card: {
        title: '后续加工(或测试)所需的芯片信息',
      },
      groupItemConfig: getCommonInfo()
    },
  ],
});
