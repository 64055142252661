import React, { Component } from 'react';

import { get, isFunction } from '../utils/lodash';

import { register } from '../core/manager';
import { CacheComponentProps, CacheComponentState, CacheComponentLifecycle } from '../interface';

const newLifecycles = Number(get((React.version || '').match(/^\d*\.\d*/), '[0]')) >= 16.3;

function getDerivedStateFromProps(
  nextProps: CacheComponentProps, prevState: CacheComponentState
): Partial<CacheComponentState> {
  let { match: nextPropsMatch, when } = nextProps;
  nextProps.match;
  /**
   * Note:
   * Turn computedMatch from CacheSwitch to a real null value if necessary
   *
   * 必要时将 CacheSwitch 计算得到的 computedMatch 值转换为真正的 null
   */
  if (get(nextPropsMatch, '__CacheRoute__computedMatch__null')) {
    nextPropsMatch = null;
  }

  if (!prevState.cached && nextPropsMatch) {
    return {
      cached: true,
      matched: true
    };
  }

  /**
   * Determines whether it needs to cancel the cache based on the next unmatched props action
   *
   * 根据下个未匹配状态动作决定是否需要取消缓存
   */
  if (prevState.matched && !nextPropsMatch) {
    const nextAction = get(nextProps, 'history.action');

    let cancelCache = false;

    switch (when) {
      case 'always':
        break;
      case 'back':
        if (['PUSH', 'REPLACE'].includes(nextAction)) {
          cancelCache = true;
        }

        break;
      case 'forward':
      default:
        if (nextAction === 'POP') {
          cancelCache = true;
        }
    }

    if (cancelCache) {
      return {
        cached: false,
        matched: false
      };
    }
  }

  return {
    matched: !!nextPropsMatch
  };
}

function defaultBehavior(cached: boolean) {
  return cached ? {
    style: {
      display: 'none'
    },
  } : undefined;
}

export default class CacheComponent extends Component<CacheComponentProps, CacheComponentState> {
  static defaultProps: Partial<CacheComponentProps> = {
    when: 'forward',
  };

  /**
   * New lifecycle for replacing the `componentWillReceiveProps` in React 16.3 +
   * React 16.3 + 版本中替代 componentWillReceiveProps 的新生命周期
   */
  static getDerivedStateFromProps = newLifecycles
    ? getDerivedStateFromProps
    : undefined;

  constructor(props: CacheComponentProps) {
    super(props);

    if (props.cacheKey) {
      register(props.cacheKey, this);
    }

    const defaultState = { cached: false, matched: false };

    this.state = { ...defaultState, ...getDerivedStateFromProps(props, defaultState) };
  }

  renderChildren() {
    //
    const { children } = this.props;
    if (isFunction(children)) {
      return children(this.cacheLifecycles) || null;
    } else {
      return children;
    }
  }

  render() {
    const { className: propsClassName = '', behavior = defaultBehavior } = this.props;
    const { className: behaviorClassName = '', ...behaviorProps } = behavior(!this.state.matched) || {};
    const className = `${propsClassName} ${behaviorClassName}`.trim();

    const hasClassName = className !== '';

    return this.state.cached ? (
      <div className={hasClassName ? className : undefined} {...behaviorProps}>
        {this.renderChildren()}
      </div>
    ) : null;
  }

  /**
   * Compatible React 16.3 -
   * 兼容 React 16.3 - 版本
   */
  componentWillReceiveProps = !newLifecycles
    ? nextProps => {
        this.setState({
          ...this.state,
          ...getDerivedStateFromProps(nextProps, this.state)
        });
      }
    : undefined;

  componentDidUpdate(_: any, prevState: CacheComponentState) {
    if (!prevState.cached || !this.state.cached) {
      return;
    }

    if (prevState.matched === true && this.state.matched === false) {
      this.run(this.cacheLifecycles.__listener.didCache);
    }

    if (prevState.matched === false && this.state.matched === true) {
      this.run(this.cacheLifecycles.__listener.didRecover);
    }
  }

  shouldComponentUpdate(nextProps: CacheComponentProps, nextState: CacheComponentState) {
    return (
      this.state.matched ||
      nextState.matched ||
      this.state.cached !== nextState.cached
    );
  }

  run(fn?: Function, ...args: any[]) {
    try {
      fn && fn(...args);
    } catch (e) {
      console.error(e);
    }
  }

  cacheLifecycles = {
    __listener: {} as Partial<CacheComponentLifecycle>,
    didCache: listener => {
      this.cacheLifecycles.__listener.didCache = listener;
    },
    didRecover: listener => {
      this.cacheLifecycles.__listener.didRecover = listener;
    }
  };
}
