import React from 'react';
import { get } from 'lodash';
import {
  FormConfig,
  FormItemConfig,
  InputFormItemConfig,
  SelectFormItemConfig,
  FileFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import { attachmentApi } from 'src/services/net';
import styles from './index.module.less';
import { Verify } from 'src/models/org/enum';

/*************************** 主体信息 ****************************/
const fullName = (orgInfo): InputFormItemConfig => ({
  label: '组织全称',
  type: 'input',
  dataKey: 'info.name',
  placeholder: '请输入组织全称',
  renderType: 'preview',
  render: () => {
    return <span>{get(orgInfo, 'info.name')}</span>;
  }
});

const legalPerson: InputFormItemConfig = {
  label: '法人代表',
  type: 'input',
  required: true,
  hint: '需与营业执照上的法人代表名称完全一致。',
  requiredMsg: '请输入法人代表姓名',
  placeholder: '请输入法人代表',
  dataKey: 'info.legalPerson',
  validate: [{
    pattern: /^.{0,10}$/,
    message: '最长10个字符'
  }]
};

const creditCode: InputFormItemConfig = {
  label: '统一社会信用代码',
  type: 'input',
  placeholder: '请输入统一社会信用代码',
  hint: '请输入18位由大写字母或数字组成的统一社会信用代码!',
  required: true,
  requiredMsg: '请输入统一社会信用代码!',
  dataKey: 'info.creditCode',
  validate: [{
    pattern: /(^[A-Z0-9]{18}$)/,
    message: '请输入18位由大写字母或数字组成的统一社会信用代码!',
  }]
};

const license = (): FileFormItemConfig => ({
  label: '营业执照',
  type: 'file',
  dataKey: 'info.license',
  required: true,
  requiredMsg: '请选择营业执照图片上传',
  fileType: ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'pdf'],
  hint: '复印件务必加盖公章，支持.jpg .jpeg .bmp .gif .png .pdf格式，大小不超过5M。',
  fileSize: 4096000,
  sizeErrMsg: '文件大小不超过5M',
  colClassName: styles.fileBtnPreview,
  props: {
    getFileDetail: fileId => attachmentApi.getDetail(fileId),
    getUrl: (fileId) => attachmentApi.download({ attachmentId: fileId }),
    listType: 'picture',
  },
});

/*********************** 认证申请人信息 ******************************/
const contactName: InputFormItemConfig = {
  label: '认证申请人',
  type: 'input',
  dataKey: 'contact.name',
  required: true,
  requiredMsg: '请填写认证申请人姓名',
  validate: [{
    pattern: /^.{0,20}$/,
    message: '最长20个字符'
  }]
};

const contactPhone: InputFormItemConfig = {
  label: '联系电话',
  type: 'input',
  dataKey: 'contact.phone',
  required: true,
  requiredMsg: '请输入认证人联系电话',
  validate: [{
    pattern: /^[\d-()（）]{1,20}$/,
    message: '请输入正确格式的联系电话，只能包含数字、-、()，最长20个字符',
  }]
};

const contactEmail: InputFormItemConfig = {
  label: '邮箱地址',
  type: 'input',
  dataKey: 'contact.email',
  required: true,
  requiredMsg: '请输入认证人邮箱地址',
  validate: [{
    pattern: /^(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*){1,100}$/,
    message: '请输入正确的邮箱格式!',
  }]
};

const contactAddress: InputFormItemConfig = {
  label: '联系地址',
  type: 'input',
  dataKey: 'contact.address',
  required: true,
  requiredMsg: '请输入认证人联系地址',
  validate: [{
    pattern: /^.{0,100}$/,
    message: '最长100个字符'
  }]
};

/*************************** 开票信息 *******************************/
const invoiceTitle: InputFormItemConfig = {
  label: '发票抬头',
  type: 'input',
  dataKey: 'invoice.name',
  required: true,
  requiredMsg: '请输入发票抬头信息',
  renderType: 'normal',
};

const invoiceType: SelectFormItemConfig = {
  label: '发票类型',
  type: 'select',
  dataKey: 'invoice.type',
  optionKey: 'invoiceType',
  required: true,
  requiredMsg: '请选择发票类型'
};

const taxID: InputFormItemConfig = {
  label: '税务登记号',
  type: 'input',
  dataKey: 'invoice.taxRegistration',
  required: true,
  requiredMsg: '请输入税务登记证号',
};

const bankName: InputFormItemConfig = {
  label: '基本开户银行名称',
  type: 'input',
  dataKey: 'invoice.bankName',
  required: true,
  requiredMsg: '请输入基本开户银行名称',
};

const bankAccount: InputFormItemConfig = {
  label: '基本开户账号',
  type: 'input',
  dataKey: 'invoice.bankAccount',
  required: true,
  requiredMsg: '请输入基本开户账号',
};

const bankAddress: InputFormItemConfig = {
  label: '注册场所地址',
  type: 'input',
  dataKey: 'invoice.address',
  required: true,
  requiredMsg: '请输入注册场所地址',
};

export const mainInfo = (orgInfo): FormItemConfig[] => ([
  fullName(orgInfo),
  legalPerson,
  creditCode,
  license(),
]);

export const invoiceInfo = (): FormItemConfig[] => ([
  invoiceTitle,
  invoiceType,
  taxID,
  bankName,
  bankAccount,
  bankAddress,
]);

export const getFormConfig = (orgInfo): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '主体信息', bordered: true },
      groupItemConfig: mainInfo(orgInfo) as FormItemConfig[]
    },
    {
      card: { title: '认证申请人联系信息', bordered: true },
      groupItemConfig: [
        contactName,
        contactPhone,
        contactEmail,
        contactAddress,
      ] as FormItemConfig[]
    },
    {
      card: { title: '开票信息', bordered: true },
      groupItemConfig: invoiceInfo() as FormItemConfig[],
      ifRender() {
        const { initialFormData } = this;

        return !initialFormData?.status || initialFormData?.status === Verify.EnumStatus.rejected;
      }
    },
  ]
// tslint:disable-next-line: max-file-line-count
});
