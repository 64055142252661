/**
 * 增加一个columnsConf的props，主要用来对antd原生的columns进行简化
 * 如果传入了columnsConf那么以此为准，如果没传入，那么还是以原生的columns为准
 */
import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { TableProps as AntTableProps, ColumnProps } from 'antd/lib/table/interface';
import { cloneDeep, isEmpty, isEqual, flow } from 'lodash';

import TableWidthHorizontalDrag from './TableWidthHorizontalDrag';

import { isFunction } from 'util';

export type ColumnsConf = { [key: string]: Omit<ColumnProps<any>, 'key' | 'dataIndex'> | string };
export interface TableProps extends AntTableProps<any> {
  // 通过这个可以小小的简化下table的columns的配置
  columnsConf?: ColumnsConf;
}
interface TableState {
  columnsConf?: ColumnsConf;
  columns: ColumnProps<any>[];
}

/**
 * 如果有将 table 的配置从 children 中传入，那么这里会进行处理
 * @param originColumnConf
 * @param children
 */
const handleChildrenColumnConf = (originColumnConf: ColumnsConf | undefined, children: any): ColumnsConf => {
  // TODO 忘了去验证children是否可以直接传入一个object，如果可以的话写法会更简单点，就不用传入一个function了
  let stateColumnsConf = cloneDeep(originColumnConf) || {};

  if (!isEmpty(originColumnConf) && children && isFunction(children)) {
    let tableColRenderFunctions = children();

    Object.keys(tableColRenderFunctions).forEach(key => {
      if (!(key in originColumnConf!)) return;

      if (typeof originColumnConf![key] === 'string') {
        stateColumnsConf[key] = {title: originColumnConf![key]};
      }

      (stateColumnsConf[key] as Obj).render = tableColRenderFunctions[key];
    });
  }

  return stateColumnsConf;
};

/**
 * 将传入的配置转换成适用于 antd Table 组件的配置
 * @param columnConfig
 */
const getAntdTableColConf = (columnConfig: ColumnsConf): ColumnProps<any>[] =>
  Object.keys(columnConfig).map(key => {
    const base = { dataIndex: key, key };

    return typeof columnConfig[key] === 'string'
      ? { ...base, title: columnConfig[key] }
      : { ...base, ...columnConfig[key] as Obj };
  });

const handleConf = flow(handleChildrenColumnConf, getAntdTableColConf);

class EnhancedTable extends PureComponent<TableProps, TableState> {
  static getDerivedStateFromProps(nextProps: TableProps, prevState: TableState) {
    const { columnsConf, children, columns } = nextProps;

    // 如果传入了columns，那么以columns为准
    if (!isEmpty(columns)) return { columnsConf, columns };

    if (!isEqual(columnsConf, prevState.columnsConf)) {
      return {
        columnsConf,
        columns: handleConf(columnsConf, children)
      };
    }

    return null;
  }

  constructor(props: TableProps) {
    super(props);

    const { columnsConf, children, columns } = props;

    this.state = {
      columnsConf,
      columns: columns || handleConf(columnsConf, children)
    };
  }

  render() {
    const {
      children,
      columnsConf: newColumns,
      columns: originalColumns,
      ...passThoughProps
    } = this.props as any;
    const { columns } = this.state;
    const scroll = passThoughProps.scroll;

    if (scroll && scroll.x) {
      return <TableWidthHorizontalDrag columns={columns} {...passThoughProps} />;
    }

    return <Table columns={columns} {...passThoughProps} />;
  }
}

export default EnhancedTable;
