import React, { FC, useEffect, useState } from 'react';
import Markdown from 'react-markdown';

import url from 'src/config/url';

interface Props {

}

const FAQ: FC<Props> = props => {
  const [md, setMd] = useState('');

  useEffect(
    () => {
      fetch(`${url.staticUrl}/template/faq.md`, {
        // credentials: 'include',
        mode: 'cors',
      })
        .then(res => res.text())
        .then(text => {
          setMd(text);
        });
    },
    []
  );

  return (
    <Markdown className="markdown-body" source={md} escapeHtml={false} />
  );
};

export default FAQ;
