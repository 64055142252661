import * as React from 'react';
import { find, isEqual } from 'lodash';
import { Skeleton } from 'antd';
import { FormCreator, selectOriginOptionData } from '@chipcoo/hanayo';
// import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';
import { noop } from '@chipcoo/fe-utils';

import { getProductInfo } from './productInfoFormConfig';
import { productApi } from 'src/services/net';

interface P {
  product: string;
  productModel: EnumProductModel;
}
interface S {
  showSkeleton: boolean;
  productInfo: Obj | undefined;
}

class ProductInfo extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      showSkeleton: true,
      productInfo: void 0,
    };
  }

  componentDidMount() {
    this.getProductInfo();
  }

  async componentDidUpdate(prevProps: P) {
    const { product } = this.props;

    !isEqual(prevProps.product, product) && this.getProductInfo();
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  getProductInfo = async () => {
    const { product, productModel } = this.props;
    const cachedOptionRespData = selectOriginOptionData.getCachedData();
    const optionsInfo = cachedOptionRespData.getProductByProductModel;
    let productInfo = find(optionsInfo, it => it._id === product);
    let dieName = '';

    if (optionsInfo && productInfo) {
      if (productModel === EnumProductModel.assemblyProduct) {
        dieName = productInfo?.product?.dies?.[0]?.die?.customerName;
      }

      if (productModel === EnumProductModel.dpProduct) {
        dieName = productInfo?.product?.die?.customerName;
      }
    } else {
      const { data } = await productApi.getProductById(product);

      if (productModel === EnumProductModel.assemblyProduct) {
        dieName = data?.product?.dies?.[0]?.die?.customerName;
      }

      if (productModel === EnumProductModel.dpProduct) {
        dieName = data?.product?.die?.customerName;
      }
    }

    this.setState({
      productInfo: {
        ...productInfo,
        productModel: EnumProductModel,
        dieName,
      },
      showSkeleton: false,
    });
  }

  render () {
    const { showSkeleton, productInfo } = this.state;

    return (
      <Skeleton loading={showSkeleton} active={true}>
        <FormCreator
          formConfig={getProductInfo()}
          globalStatus="preview"
          formData={productInfo}
          formWrapper={false}
          // className={styles.dieInfo}
        />
      </Skeleton>
    );
  }
}

export default ProductInfo;
