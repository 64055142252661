// import { uniq, sortBy } from 'lodash';
import { getMomentTime } from '@chipcoo/fe-utils';

export const handleList = (payload: Obj) => {
  const { data, products } = payload;
  return data.map(it => {
    const {
      createdAt
    } = it;

    return {
      ...it,
      key: it._id,
      createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
      product: products?.find(p => p._id === it.product),
    };
  });
};

export const handleDetail = (payload) => {
  const { createdAt, tests } = payload;

  return {
    ...payload,
    createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
    tests: tests.map(it => ({ ...it, key: Math.random().toString(16) }))
  };
};

export const handleCompareData = (payload) => {
  const { diffs, testGroups, products } = payload;

  const newTestGroups = testGroups.map(it => ({
    ...it,
    createdAt: getMomentTime(it?.createdAt, 'YYYY-MM-DD HH:mm:ss'),
    product: {_id: it.product, customerName: products?.find(p => p._id === it.product)?.customerName }
  }));

  const newDiffs = diffs.map(it => ({ ...it, key: Math.random().toString(16) }));

  return {
    ...payload,
    diffs: newDiffs,
    products,
    testGroups: newTestGroups,
  };
};
