import React, { ComponentType } from 'react';
import { Button } from 'antd';
// import { isNull } from 'lodash';
import { bind } from 'lodash-decorators';
import { BasicModal } from '@chipcoo/hanayo';
import { BasicModalChildrenProps } from '@chipcoo/hanayo/lib/BasicModal';

import './style.less';

import store from 'src/store';
// import {
//   DpOrderContent,
//   CpOrderContent,
//   AssemblyOrderContent
// } from 'src/pages/Operation/OutsourcingProcess';
import { outsourcingProcessApi } from 'src/services/net';
import { Verify } from 'src/models/org/enum';
import EnsureOrgMembers from '../EnsureOrgMembers';

interface Props {
  orgId: string;
  orderId: string;
  orderType: any;
  messageCard: JSX.Element;
  businessType?: BusinessType;
}

interface State {
  notInOrg: boolean;
  isPreview: boolean;
  loadedMembers: boolean;
  loadingOrder: boolean;
  businessType: BusinessType | null;
  modalVisible: boolean;
}

const noop = () => '';

export class HandleOrder extends EnsureOrgMembers<Props, State> {
  private Component: ComponentType<any>;
  private detail;
  private type;
  private effectType;

  constructor(props: Props) {
    super(props);

    this.state = {
      notInOrg: !this.isInOrg(),
      isPreview: false,
      businessType: props.businessType || null,
      loadedMembers: false,
      loadingOrder: false,
      modalVisible: false,
    };
  }

  openModal = () => this.setState({ modalVisible: true });

  closeModal = () => this.setState({ modalVisible: false, isPreview: false });

  @bind
  async tryViewOrder() {
    try {
      await this.viewOrder();
    } catch (e) {
      console.error(e);
      this.setState({ loadingOrder: false });
    }
  }

  @bind
  async viewOrder() {
    const { orderId, orderType: type } = this.props;
    this.setState({ loadingOrder: true });
    // 不管了，检查一遍再说
    await this.checkOrg();
    const { data } = await outsourcingProcessApi.getOrderDetail(orderId);
    const { businessType, status = [], orderType } = data;
    const isPreview = status[0] !== Verify.EnumStatus.rejected;

    const { dispatch } = store;

    this.effectType = 'assemblyOrder';
    this.type = orderType;

    // switch (businessType) {
    //   case BusinessType.cp:
    //     this.Component = CpOrderContent;
    //     this.effectType = 'cpOrder';
    //     break;
    //   case BusinessType.dp:
    //     this.Component = DpOrderContent;
    //     this.effectType = 'dpOrder';
    //     break;
    //   case BusinessType.assembly:
    //     this.Component = AssemblyOrderContent;
    //     break;
    //   default:
    //     this.Component = AssemblyOrderContent;
    // }

    this.detail = await dispatch({ type: `${this.effectType}/handleOrderDetail`, payload: { type, data } });
    this.setState({ businessType, isPreview, loadingOrder: false });
    this.openModal();
  }

  @bind
  async getFormData() {
    const { orderType: type = this.type } = this.props;
    await store.dispatch({
      type: `${this.effectType}/setOrderDetail`,
      payload: { type, detail: this.detail }
    });
    return Promise.resolve();
  }

  @bind
  renderOrderContent(modalChildProps: BasicModalChildrenProps) {
    const { orderId, orderType } = this.props;

    const { isPreview } = this.state;
    const props = {
      orderType,
      detailSearchId: orderId,
      modalUse: isPreview ? 'preview' : 'edit', // TODO 此处
      reloadTable: noop,
      getFormData: this.getFormData,
      ...modalChildProps,
    };

    const { Component } = this;

    return <Component {...props} />;
  }

  @bind
  renderHandleButton() {
    const { notInOrg, loadedMembers, loadingOrder } = this.state;
    if (notInOrg) return null;

    return (
      <Button
        className="noti-btn"
        disabled={!loadedMembers}
        type="primary"
        loading={loadingOrder}
        onClick={this.tryViewOrder}
        children="查看订单"
      />
    );
  }

  render() {
    const { messageCard } = this.props;
    const { modalVisible, isPreview } = this.state;
    return (
      <div className="notify-detail-content">
        <header className="noti-header flex">
          {messageCard}
          {this.renderHandleButton()}
        </header>

        <BasicModal
          visible={modalVisible}
          closeModal={this.closeModal}
          title="订单详情"
          isShowModalBody={false}
          width={1440}
          className={`form-with-comment-modal ${isPreview ? '' : 'form-status-edit'}`}
        >
          {(childProps: BasicModalChildrenProps) => this.renderOrderContent(childProps)}
        </BasicModal>
      </div>
    );
  }
}

export default HandleOrder;
