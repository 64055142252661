import * as React from 'react';
import { Divider, Menu, Dropdown, Icon, Modal } from 'antd';
import { EnumOrderStatus, EnumProductModel } from '@chipcoo/constant';
import { FlexModalManager } from '@chipcoo/hanayo/lib/flex-modal';

import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

const serial = {
  title: '需求订单号',
  dataIndex: 'serial',
  key: 'serial',
  width: 200,
  canCopy: true,
};

const customerName = {
  title: '产品名称',
  dataIndex: 'customerName',
  key: 'customerName',
  width: 200,
};

const packingMethod = {
  title: '包装方式',
  dataIndex: 'packingMethod',
  key: 'packingMethod',
  width: 100,
};

const dieName = {
  title: '芯片名称',
  dataIndex: 'dieName',
  key: 'dieName',
  width: 200,
};

const packageType = {
  title: '封装类型',
  dataIndex: 'packageType',
  key: 'packageType',
  width: 150,
};

const packageTypeLW = {
  title: '封装型号',
  dataIndex: 'packageTypeLW',
  key: 'packageTypeLW',
  width: 150,
};

const quantity = {
  title: '订单数量',
  dataIndex: 'quantity',
  key: 'quantity',
  width: 200,
};

const waferQuantity = {
  title: '订单投料数量',
  dataIndex: 'waferQuantity',
  key: 'waferQuantity',
  width: 200,
};

const createdAt = {
  title: '下单时间',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 200,
};

const expectedDeliveryDate = {
  title: '预计交期',
  dataIndex: 'predictDeliveryTime',
  key: 'predictDeliveryTime',
  width: 200,
};

const statusText = {
  title: '状态',
  dataIndex: 'statusText',
  key: 'statusText',
  width: 100,
};

const productOperate = (option: Option) => ({
  dataIndex: 'operate',
  key: 'operate',
  title: '操作',
  fixed: 'right',
  width: 150,
  render: (text, record) => {
    const { _id, status, productModel } = record;
    const { openModal, deleteLine } = option;
    let reactNode: any = '';

    const getDetailModal = (
      <a
        onClick={() => openModal({
          id: _id,
          mode: 'preview',
          type: 'form',
          classes: record?.classes,
          productModel,
        })}
      >
        查看
      </a>
    );

    function showDeleteConfirm() {
      Modal.confirm({
        title: '是否删除该订单？',
        onOk: () => deleteLine(_id),
        zIndex: FlexModalManager.getNextZIndex()
      });
    }

    const menu = (
      <Menu>
        <Menu.Item key="1">
          <a
            onClick={() => openModal({
              id: _id,
              mode: 'edit',
              classes: record?.classes,
              productModel
            })}
          >
            重新提交
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a onClick={showDeleteConfirm}>删除</a>
        </Menu.Item>
        <Menu.Item key="3">
          {getDetailModal}
        </Menu.Item>
      </Menu>
    );

    if (status === EnumOrderStatus.rejected) {
      reactNode = (
        <Dropdown
          overlay={menu}
          trigger={['click']}
          getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
        >
          <a className="ant-dropdown-link" href="#">
            更多<Icon type="down" />
          </a>
        </Dropdown>
      );
    } else {
      reactNode = getDetailModal;
    }

    return (
      <>
        <a
          onClick={() => openModal({
            id: _id,
            mode: 'preview',
            type: 'pdf',
            classes: record?.classes,
            productModel,
          })}
        >
          预览
        </a>

        <Divider type="vertical" />
        {reactNode}
      </>
    );
  }
});

interface Option {
  productModel: EnumProductModel;
  openModal: (params: any) => void;
  deleteLine: (_id: string) => void;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  const { productModel } = option;

  switch (productModel) {
    case EnumProductModel.assemblyProduct:
      return [
        serial,
        customerName,
        packingMethod,
        dieName,
        packageType,
        packageTypeLW,
        quantity,
        waferQuantity,
        createdAt,
        expectedDeliveryDate,
        statusText,
        productOperate(option)
      ];
    case EnumProductModel.dpProduct:
      return [
        serial,
        customerName,
        packingMethod,
        dieName,
        quantity,
        waferQuantity,
        createdAt,
        expectedDeliveryDate,
        statusText,
        productOperate(option)
      ];
    default:
      return [
        serial,
        customerName,
        packingMethod,
        dieName,
        packageType,
        packageTypeLW,
        quantity,
        waferQuantity,
        createdAt,
        expectedDeliveryDate,
        statusText,
        productOperate(option)
      ];
  }
};
