import { message } from 'antd';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getOrgIdByUrl } from 'src/utils';

export default function(api: AxiosInstance) {
  return {
    listBus(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/mpw/bus', {...config, params});
    },
    listAllFactory(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/mpw/bus/factories', {...config, params});
    },
    createMpwBus(data: any, config: AxiosRequestConfig = {}) {
      return api.post('rnd/mpw/bus/preorder', data, config);
    },
    patchMpwBus(data: any, config: AxiosRequestConfig = {}) {
      return api.patch(`rnd/mpw/bus/preorder/${data?._id}`, data, config);
    },
    listBusPreOrder(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/mpw/bus/preorder/search', { ...config, params });
    },
    cancelBusOrder(orderId: string, config: AxiosRequestConfig = {}) {
      return api.patch(`rnd/mpw/bus/preorder/${orderId}/cancel`, {}, config);
    },

    // 报告查询-WIP列表
    getWipList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/wip/search', {...config, params});
    },
    // Wip详情
    getWipDetailById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/wip/${id}`, config);
    },
    // wip导出
    exportWip: () => {
      const orgId = getOrgIdByUrl();

      return `/api/rnd/wip/export?orgId=${orgId}`;
    },

    getMpwBusPreOrder(orderId: string, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/mpw/bus/preorder/${orderId}`, config);
    },

    // 流片出库记录列表
    getShipmentList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/stock-out-engineer/search', { ...config, params });
    },

    /*************************** nto **********************************/
    listNto(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/nto/search', { ...config, params });
    },

    // 列出改版
    listRevNtoListById(_id: string, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/nto/${_id}/revision`, config);
    },

    createNto(data: any, config: AxiosRequestConfig = {}) {
      return api.post('rnd/nto', data, config);
    },

    getNtoById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/nto/${id}`, config);
    },

    patchNtoStatus(data: any, config: AxiosRequestConfig = {}) {
      return api.patch(`rnd/nto/${data._id}/status`, data, config);
    },

    // 检查名字是否被占用
    checkIsUsedName(params?: any, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/nto/is-name-used`, {
        ...config,
        params: {
          name: params?.name
        }
      });
    },

    getFactoryList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/factory/search', { ...config, params });
    },

    // 启用
    enableNto(_id: string, config: AxiosRequestConfig = {}) {
      return api.patch(`rnd/nto/${_id}/enable`, {}, config);
    },

    // 禁用
    disableNto(_id: string, config: AxiosRequestConfig = {}) {
      return api.patch(`rnd/nto/${_id}/disable`, {}, config);
    },

    /************************* Lot指令 ****************************************/
    // lot指令列表搜索
    getLotList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('rnd/lot-command/search', { ...config, params });
    },

    getLotById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`rnd/lot-command/${id}`, config);
    },

    createLotCommand(data: any, config: AxiosRequestConfig = {}) {
      return api.post('rnd/lot-command', data, {
        ...config,
        handleSpecialError: {
          423: () => {
            message.error('当前Lot指令所使用的NTO产品已被禁用！');
          }
        }
      });
    }
  };
}
