import _ from 'lodash';
import { numberRangeVerify } from '@chipcoo/hanayo';

export function numberRangeValidatorByFormData(data: any, key?: string) {
  const { originAmount } = data;
  let max = 0;

  if (_.isNumber(originAmount)) {
    max = originAmount;
  }

  if (_.isObject(originAmount) && key) {
    max = originAmount?.[key];
  }

  return [{ validator:  numberRangeVerify(`[0, ${max}]`)}];
}
