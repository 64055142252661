/**
 * Created by marvin on 18/8/20.
 */
export { default as Transfer } from './Transfer';
export { default as Dismission } from './Dismission';
export { default as Leave } from './Leave';
export { default as Archive } from './Archive';

export const typeMap = {
  org: '组织',
  workspace: '协作区',
};
