import { createSelector } from 'reselect';
import produce from 'immer';
import { groupBy, get } from 'lodash';

import { ICraftRouteFullRoute, IProductProcessDetail } from 'src/services/net/craftRoute';
import { IProductProcessByProcess, IProductProcessUpdates, ICraftRouteWithProductProcess } from '../interface';
import { EnumProcessStatus } from '@chipcoo/constant';
import { isValidProductProcess, isSameSource } from './is-valid-product-process';

export function createEditGroupSelector() {
  return createSelector(
    (craftRoutes: ICraftRouteFullRoute[], _: IProductProcessDetail[]) => craftRoutes,
    (_: ICraftRouteFullRoute[], productProcess: IProductProcessDetail[]) => productProcess,
    (craftRoutes, productProcess): IProductProcessByProcess[] => {
      return Object.entries(groupBy(craftRoutes, 'process._id')).map(([_, routes]) => {
        const { process } = routes[0];

        return {
          process,
          craftRoutes: routes.map((route) => {
            return {
              ...route,
              productProcesses: productProcess.filter(
                p => get(p, 'craftRoute._id') === route._id && p.process === process._id &&
                  isValidProductProcess(p, route)
              ),
            };
          })
        };
      });
    }
  );
}

export function createProductProcessDataSelector() {
  return createSelector(
    (payload: ICraftRouteWithProductProcess, _: IProductProcessUpdates) => payload._id,
    (payload: ICraftRouteWithProductProcess, _: IProductProcessUpdates) => payload.sources,
    (payload: ICraftRouteWithProductProcess, _: IProductProcessUpdates) => payload.productProcesses,
    (_: ICraftRouteWithProductProcess, changes: IProductProcessUpdates) => changes,
    (craftRouteId, craftRouteSources, productProcesses, changes): IProductProcessDetail[] => {
      const processes = productProcesses.filter(p => !changes.removed.includes(p._id));

      return produce(processes, draft => {
        const { updates, pauseStatus, removed } = changes;

        updates.forEach((item) => {
          const find = draft.find(it => it._id === item._id);
          if (find) {
            Object.assign(find, item);
          } else if (item.craftRoute._id === craftRouteId
              && !removed.includes(item._id)
              && isSameSource(craftRouteSources, item.craftRoute.sources)
            ) {
              draft.push({ ...item });
          }
        });

        pauseStatus.forEach((pauseItem) => {
          const find = draft.find(it => it._id === pauseItem._id);

          if (find) {
            find.status = pauseItem.isPaused ? EnumProcessStatus.paused : EnumProcessStatus.normal;
          }
        });
      });
    }
  );
}

export interface IFinalDataSourceState {
  dataSource: IProductProcessDetail[];
  localData: IProductProcessDetail[];
}

export function createFinalDataSourceSelector() {
  return createSelector(
    (state: IFinalDataSourceState) => state.dataSource,
    (state: IFinalDataSourceState) => state.localData,
    (dataSource, localData): IProductProcessDetail[] => {
      const filtered = localData.filter(it => dataSource.every(data => data._id !== it._id));

      return dataSource.concat(filtered);
    }
  );
}
