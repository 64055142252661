import React, { PureComponent } from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import log from 'loglevel';
import { noop } from '@chipcoo/fe-utils';

import styles from '../index.module.less';
import { getFormConfig } from './formConfig';
import { RootState, RootDispatch } from 'src/store';
import { FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { PatchOrgData, FunctionContactsParams } from 'src/models/org/interface';

interface OwnProps {
  orgId: string;
}

interface StateProps {
  orgData: any;
  currentOrgRole: string;
}

interface DispatchProps {
  patchOrgData: (payload: PatchOrgData) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  visibleModal: boolean;
  contactFormData: any;
}

class FunctionContact extends PureComponent<Props, State> {
  private formController;
  constructor(props: Props) {
    super(props);

    this.state = {
      visibleModal: false,
      contactFormData: props.orgData || {}
    };
  }

  componentWillUnmount(): void {
    this.setState = noop;
  }

  openModal = () => {
    this.setState({
      visibleModal: true,
    });
  }

  closeModal = () => {
    this.setState({
      visibleModal: false,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { validateFields } = this.formController;

    validateFields(async (err, values) => {
      if (!err) {
        const { orgId, patchOrgData } = this.props;
        const { functionContacts } = values;

        let newFunctionContacts: FunctionContactsParams[] = [];

        Object.keys(functionContacts).map(key => {
          const data = functionContacts?.[key];

          if (!isEmpty(data)) {
            const newData = data?.map(it => ({
              ...it,
              function: key,
            })) || [];

            newFunctionContacts = newFunctionContacts.concat(newData);
          }
        });

        try {
          await patchOrgData({
            _id: orgId,
            functionContacts: newFunctionContacts,
          });

          this.setState({ contactFormData: values });

          this.closeModal();
        } catch (e) {
          log.error(e);
        }
      }
    });
  }

  render() {
    const { orgData, currentOrgRole } = this.props;
    const isVerified = get(orgData, 'verify') === 'ACCEPTED';
    const { visibleModal, contactFormData } = this.state;

    const extra = currentOrgRole !== 'MEMBER' &&
      (<i className="c-icon icon-edit" onClick={this.openModal}/>);

    if (!isVerified) {
      return <div />;
    } else {
      return (
        <>
          <FormCreator
            formData={contactFormData ? contactFormData : null}
            formConfig={getFormConfig(status = 'preview', extra)}
            globalStatus={'preview'}
            className={styles.card}
          />
          <FlexModal
            width={950}
            animation="fade"
            visible={visibleModal}
            onCancel={this.closeModal}
            onOk={this.handleSubmit}
            title="编辑联系信息"
            resize={{
              minHeight: 600,
              minWidth: 1024,
            }}
          >
            <FormCreator
              // className="function-contact-form"
              formData={contactFormData ? contactFormData : null}
              formConfig={getFormConfig(status = 'edit')}
              globalStatus={'edit'}
              getFormController={controller => this.formController = controller}
            />
          </FlexModal>

        </>
      );
    }
  }
}

const mapState = ({ org: { orgId, orgList: { byId } } }: RootState) => ({
  orgData: get(byId, orgId),
  currentOrgRole: get(byId[orgId], 'role')!,
});
const mapDispatch = ({ org: { patchOrgData } }: RootDispatch) => ({ patchOrgData });

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(FunctionContact);
