import React, { FC, useState, useEffect } from 'react';
import { isEmpty, sum } from 'lodash';
import { FormCreator } from '@chipcoo/hanayo';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';
import { EnumProductModel } from '@chipcoo/constant';

import { getFormConfig } from './formConfig';
import styles from './index.module.less';

interface IProps {
  visible: boolean;
  onOk?: (values: any) => any;
  onCancel?: () => any;
  selectedRows: any[];
  productModel: EnumProductModel;
}

interface IState {
  modalFullWindow: boolean;
  detailData: any;
}

const DetailFormModal: FC<IProps> = props => {
  const { visible, onOk, onCancel, productModel, selectedRows } = props;
  const [state, setState] = useState<IState>({
    modalFullWindow: true,
    detailData: {},
  });
  const { modalFullWindow, detailData } = state;

  let formController: FormController;
  const getFormController = (controller) => formController = controller;

  useEffect(() => {
    // 处理已选中的数据,传给详情列表
    let selectedData: any[] = [];
    let selectedmaterial: any;
    selectedRows.forEach(data => {
      if (!data.children) {
        selectedData.push(data);
      }

      if (data.children) {
        const filterChildren = data.children;
        if (!isEmpty(filterChildren)) {
          if (productModel === EnumProductModel.assemblyProduct) {
            const amount = sum(filterChildren.map(item => item.amount));
            selectedData.push({
              ...data,
              amount,
              originAmount: amount,
            });
          }
          if (productModel === EnumProductModel.dpProduct) {
            const good = sum(filterChildren.map(item => item.good));
            const bad = sum(filterChildren.map(item => item.bad));
            selectedData.push({
              ...data,
              good,
              bad,
              originAmount: { good, bad, amount: good + bad},
            });
          }

        }
      }
    });
    selectedmaterial = {
      materialDetail: selectedData
    };

    setState({
      ...state,
      detailData: selectedmaterial,
    });
  }, [visible]);

  const onRequestFullWindow = () => {
    setState({
      ...state,
      modalFullWindow: true,
    });
  };

  const onRequestNormalWindow = () => {
    setState({
      ...state,
      modalFullWindow: false,
    });
  };

  const handleSubmit = () => {
    return new Promise((resolve) => {
      formController.validateFieldsAndScroll((err, values) => {
        if (err) {
          return resolve(false);
        }
        if (onOk) {
          resolve(onOk(values));
        } else {
          resolve(true);
        }
      });
    });
  };

  return (
    <FlexModal
      width={950}
      animation="fade"
      className={styles.modal}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      title="添加出库申请"
      fullWindow={modalFullWindow}
      onRequestFullWindow={onRequestFullWindow}
      onRequestNormalWindow={onRequestNormalWindow}
      resize={{
        minHeight: 600,
        minWidth: 1024,
      }}
    >
      <FormCreator
        globalStatus="edit"
        getFormController={getFormController}
        formConfig={getFormConfig()}
        formWrapper={false}
        formData={{ ...detailData, productModel }}
      />
    </FlexModal>
  );
};

export default DetailFormModal;
