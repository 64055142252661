/**
 * 外面包裹的一层Card，当传入查询表单的配置的时候，最右侧会出现一个查询按钮
 */
import React, { PureComponent } from 'react';
import { Dropdown, Card, Icon, Divider } from 'antd';
import { CardProps } from 'antd/lib/card';
import classNames from 'classnames';
import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';

import styles from './index.module.less';

import Ctx from '../createContext';
import QueryPanel from '../QueryPanel';
import QueryTermTags from '../QueryTermTags';

export type QueryFormConfig = {
  formKey: string;
  rowGroupConfig: FormConfig['rowGroupConfig'];
};
export interface StandardTableCardProps extends CardProps {
  queryFormConfig?: QueryFormConfig;
}
interface S {
  queryPanelVisible: boolean;
}

class StandardTableCard extends PureComponent<StandardTableCardProps, S> {
  static contextType = Ctx;

  context!: React.ContextType<typeof Ctx>;

  private formController: any | null;

  constructor(props: StandardTableCardProps) {
    super(props);

    this.state = {
      queryPanelVisible: false
    };
  }

  componentDidMount(): void {
    this.forceUpdate();
  }

  showQueryPanel = () => !this.state.queryPanelVisible && this.setState({ queryPanelVisible: true });

  closeQueryPanel = () => this.state.queryPanelVisible && this.setState({ queryPanelVisible: false });

  getQueryFormController = controller => this.formController = controller;

  get getExtraWithQuery() {
    const { extra } = this.props;

    return (
      <>
        <a onClick={this.showQueryPanel}>
          <Icon type="search" />
          查询
        </a>
        <Divider style={{ visibility: 'hidden' }} type="vertical" />
        {extra}
      </>
    );
  }

  // 重置查询面板的查询条件，注：把查询条件从context中传入FormCreator中不行
  resetQuery = (e, closePanel: boolean, startQuery: boolean = true) => {
    e.stopPropagation();

    const { setQueryCondition } = this.context;
    const { resetFields } = this.formController!;
    resetFields();

    closePanel && this.closeQueryPanel();
    startQuery && setQueryCondition!({ page: 1 });
  }

  getQueryCondition = () => {
    return this.context.queryCondition!;
  }

  renderQueryPanel = () => {
    const { queryFormConfig } = this.props;
    const { queryPanelVisible } = this.state;

    return (
      <Dropdown
        overlay={(
          <QueryPanel
            queryPanelVisible={queryPanelVisible}
            getQueryCondition={this.getQueryCondition}
            getQueryFormController={this.getQueryFormController}
            setQueryCondition={this.context.setQueryCondition!}
            resetQuery={this.resetQuery}
            closePanel={this.closeQueryPanel}
            queryFormConf={queryFormConfig}
          />
        )}
        overlayStyle={{ width: '100%' }}
        overlayClassName={styles.queryOverlayDropdown}
        visible={queryPanelVisible}
        placement="bottomCenter"
        getPopupContainer={() => document.querySelector('.ant-card-head') as HTMLElement}
      >
        {/*Dropdown一定要一个children占位*/}
        <span className={styles.placeholder} />
      </Dropdown>
    );
  }

  render() {
    const { extra, queryFormConfig, children, className, ...restProps } = this.props;
    const newExtra = queryFormConfig ? this.getExtraWithQuery : extra;

    return (
      <>
        {this.renderQueryPanel()}
        <Card
          {...restProps}
          className={classNames(className, styles.cardWrap)}
          bordered={false}
          extra={newExtra}
        >
          <QueryTermTags
            queryFormConfig={queryFormConfig}
            queryCondition={this.getQueryCondition()}
            showQueryPanel={this.showQueryPanel}
            resetQuery={this.resetQuery}
          />

          {children}
        </Card>
      </>

    );
  }
}

export default StandardTableCard;
