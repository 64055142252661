import { InputFormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProcessClass } from '@chipcoo/constant';

// 磨划设备型号
const dPEquipmentModel = (): InputFormItemConfig => ({
  label: '磨划设备型号',
  type: 'input',
  dataKey: 'scheme.dpEquipmentModel',
  renderType() {
    return this.formData.processClass === EnumProcessClass.dp ? 'normal' : 'none';
  },
  validate: [{ pattern: /^.{0,30}$/, message: '请输入最大长度为30的磨划设备型号' }]
});

export const dp = [
  dPEquipmentModel(),
];
