import * as React from 'react';
import { Divider } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ModalMode } from '@chipcoo/hanayo/lib/FormCreator';
import { EnumLotCommandStatus } from '@chipcoo/constant';

interface Option {
  openModal: (id: string, mode: ModalMode) => void;
}

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      key: 'ntoName',
      title: '产品名称',
      dataIndex: 'ntoName',
    },
    {
      key: 'waferAmount',
      title: 'wafer数量',
      dataIndex: 'waferAmount',
    },
    {
      key: 'outputAmount',
      title: '产出数量',
      dataIndex: 'outputAmount',
    },
    {
      key: 'stops',
      title: '暂停站点',
      dataIndex: 'stops',
    },
    {
      key: 'stopsAmount',
      title: '暂停数量',
      dataIndex: 'stopsAmount',
    },
    {
      key: 'statusText',
      title: '状态',
      dataIndex: 'statusText',
    },
    {
      key: 'operate',
      title: '操作',
      dataIndex: 'operate',
      width: 150,
      render: (text, record) => {
        const { openModal } = option;
        const { status, key } = record;

        return (
          <>
            <a onClick={() => openModal(key, 'preview')}>查看</a>
            { status === EnumLotCommandStatus.reject && 
              <>
                <Divider type="vertical" />
                <a onClick={() => openModal(key, 'edit')}>重新提交</a>
              </>
            }
          </>
        );
      }
    }
  ];
};
