import React, { PureComponent } from 'react';

import styles from './list.module.less';
import UploadItem from './UploadItem';
import { UploadFileEnhance, UploadAttachmentProps } from '../interface';

interface Props {
  fileList: UploadFileEnhance[];
  onRemove: UploadAttachmentProps['onRemove'];
}
interface State {}

export class UploadList extends PureComponent<Props, State> {
  render() {
    const { fileList, onRemove } = this.props;
    return (
      <ul className={styles.attachmentListWrapper}>
        {fileList.map((file, i) => (
          <UploadItem
            file={file}
            key={file.uid || i + ''}
            removeFile={onRemove}
          />
        ))}
      </ul>
    );
  }
}

export default UploadList;
