/**
 * Created by marvin on 2019/2/14.
 */
import * as React from 'react';
import { Switch } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.less';
import { RouteMenuItem } from 'src/components/RouteMenu/interface';

import HoneyRoute, { getRouteByName, getRouterProps } from 'src/components/HoneyRouter';
import { RouteMenu } from 'src/components';

interface Props {
  menus: RouteMenuItem[];
  routerProps: any;
}

class SiderMenuRoute extends React.PureComponent<Props> {
  renderSwitchContent = (item) => {
    const routerProps = getRouterProps(this.props);
    const { title, routeName, routeProps } = item;
    const route = getRouteByName(routeName)!;
    return (<HoneyRoute key={title} {...routerProps} {...route} {...routeProps} />);
  }

  render () {
    const { menus, routerProps } = this.props;

    return (
      <div className={styles.outsourceWrapper}>
        {/*todo 结合SiderMenu*/}
        <RouteMenu {...routerProps} menu={menus}/>

        <div className={classnames(styles.outsourceContent, 'thin-scroll', 'y-scroll')}>
          <Switch>
            {
              menus.map((item, index) => {
                if ('children' in item) {
                  return item.children.map(i => this.renderSwitchContent(i));
                } else {
                  return this.renderSwitchContent(item);
                }
              })
            }
          </Switch>
        </div>
      </div>
    );
  }
}

export default SiderMenuRoute;
