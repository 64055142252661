/**
 * Created by marvin on 2019-07-24.
 */
import { AxiosInstance, AxiosRequestConfig } from 'axios';

type ARC = AxiosRequestConfig;

export default function(api: AxiosInstance) {
  return {
    getAddress: (config: ARC = {}) => {
      return api.get('console/address');
    },

    postAddress: (data: any, config: ARC = {}) => {
      return api.post('console/address', data);
    },

    deleteAddress: (id: string, config: ARC = {}) => {
      return api.delete(`console/address/${id}`);
    },

    putAddress: (data: any, config: ARC = {}) => {
      return api.put(`console/address/${data._id}`, data);
    }
  };
}
