import * as React from 'react';
import {
  FormConfig,
  SelectFormItemConfig,
  TextAreaFormItemConfig,
  CustomFormItemConfig,
  InputFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumAbnormalityType } from '@chipcoo/constant';

import styles from './index.module.less';
import OrderSelect from './components/OrderSelect';
import MaterialSelect from './components/MaterialSelect';

import { UploadByPaste } from 'src/containers';

const type: SelectFormItemConfig = {
  label: '异常类型',
  type: 'select',
  dataKey: 'type',
  optionKey: 'abnormalityType',
  required: true,
};

const material: CustomFormItemConfig = {
  label: '来料',
  type: 'custom',
  dataKey: 'material',
  required: true,
  requiredMsg: '请选择来料',
  renderType() {
    return this.formData?.type === EnumAbnormalityType.stockIn ? 'normal' : 'none';
  },
  render() {
    return <MaterialSelect status={this.formStatus} />;
  }
};

const order: CustomFormItemConfig = {
  label: '需求单',
  type: 'custom',
  dataKey: 'order',
  required: true,
  requiredMsg: '请选择需求单',
  renderType() {
    return this.formData?.type === EnumAbnormalityType.process ||
      this.formData?.type === EnumAbnormalityType.complaint
        ? 'normal' : 'none';
  },
  render() {
    return <OrderSelect status={this.formStatus} />;
  }
};

const attachments: CustomFormItemConfig = {
  label: '异常说明',
  type: 'custom',
  dataKey: 'attachments',
  colSpan: 24,
  colClassName: styles.files,
  render() {
    return <UploadByPaste renderType={this.formStatus === 'preview' ? 'preview' : 'normal'} />;
  }
};

const remark: TextAreaFormItemConfig = {
  label: '',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
  colClassName: 'no-label-form-item pre-wrap',
  props: { placeholder: '请输入说明内容' }
};

const result: InputFormItemConfig = {
  label: '处理结果',
  type: 'input',
  dataKey: 'result',
  colSpan: 24,
  required: true,
};

export const getPreviewFormConfig = (): FormConfig => {
  return {
    cardGroupConfig: [
      {
        groupItemConfig: [
          type,
          material,
          order,
          attachments,
          remark,
          result,
        ]
      }
    ]
  };
};
