import React, { PureComponent } from 'react';
import { RegisterGroup } from 'gg-editor';
import { BASE_CRAFT_ROUTE_GROUP, OUTPUT_ANCHOR } from './constant';

const groupConfig = {
  anchor: {
    type: 'circle',
    points: [
      [0, 0.5, { type: OUTPUT_ANCHOR }],
    ],
  },
};

export class RegisterCraftRouteGroups extends PureComponent {
  render() {
    return (
      <>
        <RegisterGroup
          name={BASE_CRAFT_ROUTE_GROUP}
          config={groupConfig}
          extend="flow-base"
        />
      </>
    );
  }
}

export default RegisterCraftRouteGroups;
