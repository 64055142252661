import * as React from 'react';
import { isEqual } from 'lodash';

import store from 'src/store';

interface Props {
  productId: string;
  onChange?: (val: any) => void;
}

class FetchPolicy extends React.PureComponent<Props> {

  async componetDidMount() {
    this.getProductPolicy();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    const { productId } = this.props;

    if (!isEqual(productId, prevProps.productId)) {
      this.getProductPolicy();
    }
  }

  getProductPolicy = async () => {
    const { productId, onChange } = this.props;

    if (productId) {
      try {
        const data = await store.dispatch.demandOrder.getPolicy({
          id: productId,
          name: 'product'
        });

        onChange && onChange(data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  render () {
    return (
      <div />
    );
  }
}

export default FetchPolicy;
