/**
 * 这个插件用ts写this.createDispatcher这里一直会报几个类型为空的校验错误，索性使用js写了。。。
 * 被添加的方法有：
 * 1、reset，重置该某个model的state到初始化状态
 * 2、setState，请参考./reducers.ts文件中的解释
 * 3、removeNormalizedData，同上
 */
import { cloneDeep } from 'lodash';
import defaultReducers from './reducers';
import { get, has } from 'lodash';
import { updateChain } from 'immutability-helper-x';
import warning from 'tiny-warning';
import { env } from 'src/config/env';

const initialRootState = {};

const defaultReducersPlugin = {
  onModel(model) {
    const { state, name } = model;

    initialRootState[name] = cloneDeep(state);

    model.reducers = Object.assign({}, model.reducers, {
      ...defaultReducers,
      // paths: string[] | undefined
      // 如果paths传入undefined那么直接重置全部数据
      // 如果传入一个string[]，那么按照对应的路径一个个的进行重置
      reset: (state, paths) => {
        const initialState = initialRootState[name];

        if (!paths) { return initialState; }

        if (env === 'local') {
          if (!Array.isArray(paths)) {
            console.error('reset的reducers必须传入一个string[]');
          }
        }

        return paths.reduce(
          (newState, path) => {
            const hasPath = has(initialState, path);

            warning(
              hasPath,
              'reset方法传入的路径不对，initialState中没有该属性'
            );

            if (hasPath) return newState.$set(path, get(initialState, path));

            return newState;
          },
          updateChain(state)
        ).value();
      }
    });

    for (const reducerName of Object.keys(model.reducers)) {
      this.dispatch[model.name][reducerName] = this.createDispatcher.apply(
        this,
        [model.name, reducerName]
      );
    }
  }
};

export default defaultReducersPlugin;
