/**
 * 一段文字点击变成可编辑的Input
 */
import * as React from 'react';
import { Tooltip, Icon } from 'antd';
import classnames from 'classnames';
import { trim } from 'lodash';

import styles from './index.module.less';
import InputWrapper from './InputWrapper';

interface P {
  defaultText: string;
  value: string | undefined;
  inputPlaceholder?: string;
  onChange?: (value: string) => void;
  className?: string;
}
interface S {
  value: string | undefined;
  inputVisible: boolean;
}

class EditAfterClick extends React.PureComponent<P, S> {
  private inputValue: string | undefined = void 0;
  private unmounted = false;

  constructor(props: P) {
    super(props);

    this.state = {
      inputVisible: false,
      value: props.value
    };

    this.inputValue = props.value;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleInputChange = (value) => {
    this.inputValue = value;
  }

  handleConfirmInputVal = () => {
    const { value, onChange } = this.props;
    const inputValue = trim(this.inputValue);
    const newValue = inputValue || value;

    if (newValue === value || !newValue) { return; }

    !this.unmounted && this.setState({
      value: newValue,
      inputVisible: false
    });

    onChange && onChange(newValue);
  }

  handleVisibleInput = () => {
    !this.unmounted && this.setState({ inputVisible: true });
  }

  handleInputClose = () => {
    !this.unmounted && this.setState({ inputVisible: false });
  }

  render() {
    const { inputPlaceholder, className, defaultText } = this.props;
    const { inputVisible, value } = this.state;

    if (inputVisible) {
      return (
        <div className={classnames(className, styles.inputWrap)}>
          <InputWrapper
            placeholder={inputPlaceholder}
            value={this.inputValue}
            confirmSelector={`${styles.confirm}`}
            onInputChange={this.handleInputChange}
            onConfirmInputVal={this.handleConfirmInputVal}
            onInputClose={this.handleInputClose}
          />
        </div>
      );
    }

    return (
      <Tooltip mouseEnterDelay={0.5} title="点击可编辑">
        <div className={classnames(className, 'ellipsis', styles.textWrap)} onClick={this.handleVisibleInput}>
          {value || (<>
            <i>{defaultText}</i>
            <Icon type="edit" />
          </>)}
        </div>
      </Tooltip>
    );
  }
}

export default EditAfterClick;
