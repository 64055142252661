/**
 * 水平出现的查询，queryMode为默认的时候显示，只有当查询条件小于等于三个的时候才需要用到该模式
 */
import React, { PureComponent } from 'react';
import { get, find, has, isPlainObject, reduce, map } from 'lodash';
import { Button, Card } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import { FormCreator } from '@chipcoo/hanayo';
import { FormController } from '@chipcoo/hanayo/lib/FormCreator/FormCreator';
import { isDataEmpty } from '@chipcoo/fe-utils';

import { ICtx } from '../types';
import styles from './index.module.less';
import cardStyles from '../Card/index.module.less';
import { QueryFormConfig } from '../Card';
import { EnhancedWrappedFormUtils } from '@chipcoo/hanayo/lib/FormCreator';

interface QueryProps {
  closePanel: () => void;
  queryPanelVisible: boolean;
  setQueryCondition: ICtx['setQueryCondition'];
  getQueryCondition: () => Obj;
  resetQuery: (e: any, closePanel: boolean, startQuery: boolean) => void;
  // 兄弟组件需要用到FormController中的reset方法，暴露出去
  getQueryFormController: (controller: FormController | null) => void;

  queryFormConf?: QueryFormConfig;
  className?: string;
}
interface QueryState {
}

@onClickOutside
class Query extends PureComponent<QueryProps, QueryState> {
  private formController: EnhancedWrappedFormUtils | null;

  componentDidUpdate(prevProps: Readonly<QueryProps>, prevState: Readonly<QueryState>, snapshot?: any): void {
    const { queryPanelVisible, getQueryCondition, queryFormConf } = this.props;

    // 点击清空条件后，会清空查询条件表单中的查询条件，但清空后直接关闭面板，再打开，这个时候还是应该继续显示被清空之前的查询条件，
    // 所以每次打开查询面板之后，都进行一次检查，将对应的表单项给赋值过去
    if (queryPanelVisible !== prevProps.queryPanelVisible && queryPanelVisible) {
      const queryCondition = getQueryCondition();

      if (
        isPlainObject(queryCondition) &&
        !Object.values(queryCondition).every(isDataEmpty) &&
        this.formController
      ) {
        const fieldsValue = reduce(queryCondition, (result, val, key) => {
          const type = this.getQueryType(key);

          if (!type) return result;

          let value = val;

          if (type === 'rangeDate' && !isDataEmpty(val) && Array.isArray(val)) {
            value = val.map(item => moment(item));
          }

          result[key] = value;

          return result;
        }, {} as any);

        // 选择重置以后，添加一个跟之前的查询条件不一样的新条件，再次打开的时候这个条件并不会被重置，因此这里还是需要将所有的field都重置掉
        const undefinedFieldsValue = this.getQueryKeys().reduce((prev, cur) => {
          prev[cur] = undefined;
          return prev;
        }, {} as any);
        const formKey = get(queryFormConf, 'formKey') as string;

        // 未来很可能会去掉formKey，因此这里做个兼容
        const _fieldsValue = formKey
          ? ({
            [formKey]: { ...undefinedFieldsValue, ...fieldsValue }
          })
          : ({ ...undefinedFieldsValue, ...fieldsValue });

        this.formController.setFieldsValue(_fieldsValue);
      }
    }
  }

  // 根据当前拿到的数据的key值，反向查询传入的配置这个key代表的表单项的类型
  getQueryType = (key: string) => {
    const { queryFormConf } = this.props;
    const configArr = get(queryFormConf, 'rowGroupConfig');

    const _conf = find(configArr, ['dataKey', key]);
    const type = get(_conf, 'type');

    // 这行不能去掉，有可能匹配的是limit和page
    if (!type) return;

    return type;
  }

  // 根据传入的query配置，拿到所有的dataKey的集合
  getQueryKeys = () => {
    const { queryFormConf } = this.props;
    const configArr = get(queryFormConf, 'rowGroupConfig');

    return map(configArr as Obj[], 'dataKey');
  }

  submitQuery = (e) => {
    e.stopPropagation();

    const { setQueryCondition, queryFormConf, getQueryCondition } = this.props;
    const { validateFields } = this.formController!;
    const formKey = get(queryFormConf, 'formKey');

    validateFields((err, values) => {
      if (err) return;

      const formData = formKey ? values[formKey] : values;

      // 如果一个查询条件都没填，拿到的数据"可能"是{[key: string]: undefined}，因此需要这么判断下是否一个查询条件都没有填写过
      // 还要判断下之前是不是就没有查询条件，否则清空条件后这里就直接return掉了
      const queryCondition = getQueryCondition();

      if (
        isPlainObject(queryCondition) &&
        Object.values(queryCondition).every(isDataEmpty) &&
        Object.values(formData).every(isDataEmpty)
      ) return;

      this.setState({ queryBtnLoading: true });
      this.closeQueryPanel();

      const queryFormData: Obj = Object.entries(formData).reduce((prev, [key, val]) => {
        if (isDataEmpty(val)) return prev;

        const type = this.getQueryType(key);

        if (!type) return prev;

        // 检查是否是一个rangeDate(得到的数据是一个数组里面含有两个moment对象)
        if (type === 'rangeDate' && Array.isArray(val) && val.every(moment.isMoment)) {
          prev[key] = val.map(item => item.format('YYYY-MM-DD'));

          return prev;
        }

        if (
          (type === 'search' && has(val, 'key')) ||
          // 查询参与者和负责人的时候只能这么搞了，因为写的是自定义组件
          (type === 'custom' && has(val, 'key') && has(val, 'label'))
        ) {
          prev[key] = {
            ...(val as any),
            // @ts-ignore
            label: val.label,
            toJSON() {
              return (val as any).key;
            }
          };

          return prev;
        }

        prev[key] = val;
        return prev;
      }, {} as any);

      // 这里应该执行一个关闭查询面板的动画。。。但这行调用以后更新太大，动画卡顿，考虑到效果，用个200ms延时先跑下动画
      setTimeout(() => setQueryCondition({ ...queryFormData, page: 1 }), 200);
    });
  }

  resetQuery = (e) => {
    const { resetQuery } = this.props;

    resetQuery(e, false, false);
  }

  handleClickOutside = (ev) => {
    const element = document.querySelector(`.${cardStyles.queryOverlayDropdown}`);

    if (element && element.contains(ev.target)) return;

    this.closeQueryPanel();
  }

  closeQueryPanel = () => {
    const { closePanel } = this.props;

    closePanel && closePanel();
  }

  getFormController = (controller) => {
    this.props.getQueryFormController(controller);

    this.formController = controller;
  }

  renderBtn = () => {
    return (
      <div className={styles.btnWrapper}>
        <Button onClick={this.resetQuery}>清空条件</Button>

        <div>
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.submitQuery}
          >
            确定
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={this.closeQueryPanel}>取消</Button>
        </div>
      </div>
    );
  }

  render() {
    const { className, queryFormConf } = this.props;

    return (
      <Card title="查询条件" className={styles.queryPanelWrapper}>
        <FormCreator
          formConfig={queryFormConf!}
          className={classNames(className, styles.formCreator)}
          getFormController={this.getFormController}
        />
        {this.renderBtn()}

        <span className={styles.boxShadow} />
      </Card>
    );
  }
}

export default Query;
