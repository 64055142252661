import { EnumReportContent } from '@chipcoo/constant';
import _, { isEmpty, has, get, flattenDeep, uniq } from 'lodash';
import { spawn, Thread, Worker } from 'threads';

// import { reservingDecimal } from 'src/utils';
import { getBatches, handleStdDistData, handleTopNData, handleCpkData } from './utils';

export const getDataKey = (content) => {
  let dataKey = '';

  if (content === EnumReportContent.bins) dataKey = 'hardBins';
  if (content === EnumReportContent.topN) dataKey = 'topNFail';
  if (content === EnumReportContent.topCPK) dataKey = 'cpk';
  if (content === EnumReportContent.stdDist) dataKey = 'stdDist';
  if (content === EnumReportContent.boxpolt) dataKey = 'boxpolt';
  if (content === EnumReportContent.meanStddev) dataKey = 'meanStddev';
  if (content === EnumReportContent.pfMap) dataKey = 'pfMap';
  if (content === EnumReportContent.binMap) dataKey = 'binMap';
  if (content === EnumReportContent.paramMap) dataKey = 'paramMap';
  if (content === EnumReportContent.stackMap) dataKey = 'stackMap';
  if (content === EnumReportContent.scatter) dataKey = 'scatter';
  if (content === EnumReportContent.xyScatter) dataKey = 'xyScatter';
  if (content === EnumReportContent.pcmCorrelation) dataKey = 'pcmCorrelation';

  return dataKey;
};

export const handleReportDetail = (payload, fromDetailReq = false) => {
  const newPayload: any = fromDetailReq ? Object.entries(payload)?.reduce(
    (prev, [key, value]: [string, any]) => {

      // 把后端的filters清空掉
      if (value?.filters) {
        prev[key] = {
          ...value,
          filters: []
        };
      } else {
        prev[key] = value;
      }

      return prev;
    },
    {} as any
  ) : payload;
  const { topNFail, cpk, stdDist, filters, type, tests, hardBins, softBins } = newPayload;

  // 失效柏拉图
  const newTopNFail = handleTopNData([{ data: topNFail?.data }]);

  // cpk & ppk
  const newCpk = handleCpkData([{ data: cpk?.data }]);

  // 正太分布
  const newStdDistArr = [{ before: tests?.[0] }]?.map(stdDistItem => {
    const { before } = stdDistItem;
    return handleStdDistData(before, {}, null);
  });
  // 散点图
  const newScatter = {
    filters,
    setting: {
      testId: tests?.[0]?.testId,
      calcStd: 6,
    }
  };
  // XY散点图
  const newXyScatter = {
    filters,
    setting: {
      testIds: [tests?.[0]?.testId, tests?.[1]?.testId]
    }
  };

  let detail = {
    ...newPayload,
    topNFail: topNFail && {
      ...topNFail,
      data: newTopNFail,
      xAxisData: topNFail.data.map(it => it.testId),
    },
    cpk: cpk && {
      ...cpk,
      data: newCpk,
      xAxisData: cpk?.xAxisData ?? cpk.data.map(it => it.testId),
    },
    stdDist: {
      ...stdDist,
      setting: {
        testId: tests?.[0]?.testId,
        calcStd: 6,
        group: 500,
      },
      stdDistArr: newStdDistArr
    },
    scatter: newScatter,
    xyScatter: newXyScatter,
    filtersSelection: getBatches({ detail: newPayload, type }),
    hardBins: hardBins && (
      has(hardBins?.data?.[0], 'group') ? hardBins : {
        ...hardBins,
        data: [{
          group: undefined,
          data: hardBins.data
        }]
      }
    ),
    softBins: softBins && (
      has(softBins?.data?.[0], 'group') ? softBins : {
        ...softBins,
        data: [{
          group: undefined,
          data: softBins.data
        }]
      }
    )
  };

  return getBinDetailX(detail);
};

const getBinDetailX = (detail) => {
  let formData = detail;
  const hardBins = detail?.hardBins;
  const softBins = detail?.softBins;

  const getX = (binData) => {
    if (binData) {
      const data: any[] = flattenDeep(
        binData?.data?.map(it => (it?.data || []).filter(b => !b.isPass))
      );
      return uniq(data?.map(it => it?.bin));
    }
    return [];
  };

  return {
    ...formData,
    hardBins: {
      ...hardBins,
      x: getX(hardBins),
    },
    softBins: {
      ...softBins,
      x: getX(softBins),
    }
  };
};

export const handleBinAxis = (binData) => {
  return uniq(binData.filter(b => !b.isPass)?.map(it => it.bin));
};

// const getPreviewList = (payload) => {
//   const orgId = getOrgIdByUrl();
//   const data = payload?.map(it => ({
//     ...it,
//     key: it?._id,
//     attachment: `/api/attachment/chipcoo/${it.attachmentId}/download?orgId=${orgId}`
//   }));
//
//   return data;
// };
export const handlePutReportFilterData = async (payload, detail, filterData) => {
  let formData = { ...detail };
  const { content, filters, setting, groupBy, queryParams, noGroupAxis } = payload;

  if (content === EnumReportContent.bins) {
    if (isEmpty(groupBy)) {
      const hardBinsData = filterData?.data?.hardBins || filterData?.hardBins;
      const softBinsData = filterData?.data?.softBins || filterData?.softBins;
      formData = {
        ...formData,
        hardBins: {
          filters,
          data: [{
            group: undefined,
            data: hardBinsData
          }],
          x: handleBinAxis(hardBinsData),
          queryParams: _.omit(payload, ['noGroupAxis']),
        },
        softBins: {
          filters,
          data: [{
            group: undefined,
            data: softBinsData
          }],
          x: handleBinAxis(softBinsData)
        }
      };
    } else {
      const hardBinsData = filterData?.map(it => ({
        group: it.group,
        data: it.data.hardBins
      }));
      const softBinsData = filterData?.map(it => ({
        group: it.group,
        data: it.data.softBins
      }));

      formData = {
        ...formData,
        hardBins: {
          filters,
          data: hardBinsData,
          x: noGroupAxis ? noGroupAxis?.hardBins : detail?.hardBins?.x,
          queryParams: _.omit(payload, ['noGroupAxis']),
        },
        softBins: {
          filters,
          data: softBinsData,
          x: noGroupAxis ? noGroupAxis?.softBins : detail?.softBins?.x
        },
      };
    }
  }

  if (content === EnumReportContent.topN) {
    const topNData = !groupBy ? [{ data: filterData }] : filterData;
    const newXAxisData = !groupBy ? filterData.map(it => it.testId) : formData.topNFail.xAxisData;

    formData = {
      ...formData,
      topNFail: {
        ...formData.topNFail,
        filters,
        data: handleTopNData(topNData),
        xAxisData: noGroupAxis ? noGroupAxis : newXAxisData,
        queryParams: _.omit(payload, ['noGroupAxis']),
      }
    };
  }

  if (content === EnumReportContent.topCPK) {
    const cpkData = !groupBy ? [{ data: filterData }] : filterData;
    const newXAxisData = !groupBy ? filterData.map(it => it.testId) : formData.cpk?.xAxisData;

    formData = {
      ...formData,
      cpk: {
        ...formData.cpk,
        filters,
        data: handleCpkData(cpkData),
        xAxisData: noGroupAxis ? noGroupAxis : newXAxisData,
        queryParams: _.omit(payload, ['noGroupAxis']),
      }
    };
  }

  if (content === EnumReportContent.stdDist) {
    const qcCalc = await spawn(new Worker('src/worker/qc'));
    const stdDistArr = await qcCalc.qcCalc(filterData, detail, payload);
    await Thread.terminate(qcCalc);

    const newStdDistArr = stdDistArr?.map(stdDistItem => {
      const { before, after, group } = stdDistItem;
      return handleStdDistData(before, after, group);
    });
    formData = {
      ...formData,
      stdDist: {
        ...formData?.stdDist,
        filters,
        setting,
        stdDistArr: newStdDistArr,
        queryParams,
      }
    };
  }

  if (content === EnumReportContent.scatter) {
    const qcCalc = await spawn(new Worker('src/worker/qc'));
    const scatterArr = await qcCalc.qcScatter(filterData, detail, payload);
    await Thread.terminate(qcCalc);

    formData = {
      ...formData,
      scatter: {
        ...formData?.scatter,
        filters,
        setting,
        data: scatterArr,
        queryParams
      }
    };
  }

  if (content === EnumReportContent.boxpolt) {
    const qcCalc = await spawn(new Worker('src/worker/qc'));
    const boxplotArr = await qcCalc.qcBoxpolt(filterData, detail, payload);
    await Thread.terminate(qcCalc);

    formData = {
      ...formData,
      boxpolt: {
        ...formData?.scatter,
        filters,
        setting,
        data: boxplotArr,
        queryParams
      }
    };
  }

  if (content === EnumReportContent.meanStddev) {
    const qcCalc = await spawn(new Worker('src/worker/qc'));
    const meanStdData = await qcCalc.qcMeanStddev(filterData, detail, payload);
    await Thread.terminate(qcCalc);

    formData = {
      ...formData,
      meanStddev: {
        ...formData?.scatter,
        filters,
        setting,
        data: meanStdData.meanStddevArr,
        meanStdCl: meanStdData.meanStdCl,
        queryParams
      }
    };
  }

  if (content === EnumReportContent.xyScatter) {
    const qcCalc = await spawn(new Worker('src/worker/qc'));
    const xyScatterArr = await qcCalc.qcTrendScatter(filterData, payload);
    await Thread.terminate(qcCalc);

    formData = {
      ...formData,
      xyScatter: {
        ...formData?.scatter,
        filters,
        setting,
        xyScatterArr,
        queryParams
      }
    };
  }

  if (content === EnumReportContent.pfMap) {
    formData = {
      ...formData,
      pfMap: {
        ...formData.pfMap,
        // previewList: getPreviewList(filterData),
        setting,
        filters
      }
    };
  }

  if (content === EnumReportContent.binMap) {
    formData = {
      ...formData,
      binMap: {
        ...formData.binMap,
        // previewList: getPreviewList(filterData),
        setting,
        filters
      }
    };
  }

  if (content === EnumReportContent.paramMap) {
    formData = {
      ...formData,
      paramMap: {
        ...formData.paramMap,
        // previewList: getPreviewList(filterData),
        setting,
        filters
      }
    };
  }

  if (content === EnumReportContent.stackMap) {
    formData = {
      ...formData,
      stackMap: {
        ...formData.stackMap,
        mapData: filterData?.[0],
        setting,
        filters
      }
    };
  }

  return formData;
};

// 处理pcm数据，并且将pcm塞入detail
export const handlePcmData = (payload, detail) => {
  return {
    ...detail,
    [EnumReportContent.pcmCorrelation]: payload,
  };
};

export const handleCorrData = (payload, detail) => {
  return {
    ...detail,
    [EnumReportContent.correlation]: payload,
  };
};

// 处理对比分析数据， 并塞入detail里
export const handleCompareData = async (primaryArrayBuffer, secondaryArrayBuffer, payload, detail, queryParams) => {
  const { setting } = payload;
  const primaryFilters: string[] = get(payload, 'setting.compareParams.primaryLot').map(it => {
    const [product, dieNumber, waferId] = it?.split('#');
    return { product, dieNumber, waferId };
  }) ?? [];
  const secondaryFilters: string[] = get(payload, 'setting.compareParams.secondaryLot').map(it => {
    const [product, dieNumber, waferId] = it?.split('#');
    return { product, dieNumber, waferId };
  }) ?? [];
  const primaryPayload = {
    ...payload,
    filters: primaryFilters,
    setting: {
      ...setting,
      calcStd: get(payload, 'setting.compareParams.calcStd'),
      group: get(payload, 'setting.compareParams.group'),
      rangeMin: get(payload, 'setting.compareParams.primaryRange.rangeMin'),
      rangeMax: get(payload, 'setting.compareParams.primaryRange.rangeMax'),
      testId: get(payload, 'setting.compareParams.primaryTestId.label')
    },
  };
  const secondaryPayload = {
    ...payload,
    filters: secondaryFilters,
    setting: {
      ...setting,
      calcStd: get(payload, 'setting.compareParams.calcStd'),
      group: get(payload, 'setting.compareParams.group'),
      rangeMin: get(payload, 'setting.compareParams.secondaryRange.rangeMin'),
      rangeMax: get(payload, 'setting.compareParams.secondaryRange.rangeMax'),
      testId: get(payload, 'setting.compareParams.secondaryTestId.label')
    },
  };

  const qcCalc = await spawn(new Worker('src/worker/qc'));
  const compareArr = await qcCalc.qcCompareCalc(
    primaryArrayBuffer,
    secondaryArrayBuffer,
    detail,
    primaryPayload,
    secondaryPayload
  );
  // let primaryData, secondaryData;
  // if (primaryArrayBuffer) {
  //   primaryData = await qcCalc.qcCalc(primaryArrayBuffer, detail, primaryPayload);
  // } else {
  //   primaryData = [{
  //     after: detail?.[payload?.content].compareData[0]
  //   }];
  // }
  // if (secondaryArrayBuffer) {
  //   secondaryData = await qcCalc.qcCalc(
  //     secondaryArrayBuffer,
  //     {...detail, tests: detail.secondaryTests},
  //     secondaryPayload
  //   );
  // } else {
  //   secondaryData = [{
  //     after: detail?.[payload?.content].compareData[1]
  //   }];
  // }

  await Thread.terminate(qcCalc);

  const compareData = handleStdDistData(compareArr?.[0]?.after, compareArr?.[1]?.after, null);

  return {
    ...detail,
    [EnumReportContent.compare]: {compareData, queryParams}
  };
};
// tslint:disable:max-file-line-count
