/**
 * .ellipsis样式省略是带三个点，这里直接用一层透明遮罩
 */
import * as React from 'react';
import classnames from 'classnames';

import styles from './index.module.less';

interface P {
  className?: string;
  styleName?: string;
  text: string | React.ReactNode;
}
interface S {}

class TransparentEllipsis extends React.PureComponent<P, S> {
  render() {
    const { className, text } = this.props;

    return (
      <div className={classnames(className, styles.wrapper)}>
        <div className="text">{text}</div>
        <span className="mask" />
      </div>
    );
  }
}

export default TransparentEllipsis;
