/**
 * 确认的弹框
 */
import * as React from 'react';
import { Modal } from '@chipcoo/hanayo';

import styles from './index.module.less';

interface P {
  onClose: () => void;
  text: string;
}
interface S {
  isModalOpen: boolean;
}

const closeTimeoutMS = 300;

class ConfirmModal extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      isModalOpen: true
    };
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });

    setTimeout(() => this.props.onClose(), closeTimeoutMS + 50);
  }

  render() {
    const { text } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Modal
        title={null}
        visible={isModalOpen}
        animation="slideDown"
        width={400}
        onOk={this.handleCloseModal}
        onCancel={this.handleCloseModal}
        className={styles.modal}
      >
        <p>{text}</p>
      </Modal>
    );
  }
}

export default ConfirmModal;
