/**
 * 确定和取消按钮的group，其中点击取消的时候会出现一个PopConfirm，里面有一个textArea来填拒绝理由
 */
import React, { PureComponent } from 'react';
import { Button, Popconfirm, Input, message } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { trim } from 'lodash';
import { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';

import styles from './index.module.less';

// TODO 拒绝时候可以配置不写原因
interface P {
  confirmText?: string;
  cancelText?: string;
  popconfirmProps?: Omit<PopconfirmProps, 'title'>;
  onOk?: () => void;
  onCancel?: (reason?: string) => void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  className?: string;
}
interface S {
  textAreaVal: string | undefined;
  confirmBtnLoading: boolean;
  cancelBtnLoading: boolean;
}

const TextArea = Input.TextArea;

class ConfirmRejectBtnGroup extends PureComponent<P, S> {
  static defaultProps = {
    confirmText: '确定',
    cancelText: '取消'
  };

  constructor(props: P) {
    super(props);

    this.state = {
      textAreaVal: void 0,
      confirmBtnLoading: false,
      cancelBtnLoading: false,
    };
  }

  changeTextArea = (e) => {
    this.setState({ textAreaVal: e.target.value });
  }

  get trimedValue(): string | undefined {
    const { textAreaVal } = this.state;

    return trim(trim(textAreaVal), '\n');
  }

  handleConfirmPop = async () => {
    const { onCancel } = this.props;

    if (!this.trimedValue) {
      message.error('请填写详细的拒绝理由');

      return;
    }

    if (onCancel) {
      this.setState({ cancelBtnLoading: true });

      await onCancel(this.trimedValue);

      this.setState({ cancelBtnLoading: false });
    }
  }

  handleClickConfirmBtn = async () => {
    const { onOk } = this.props;

    if (onOk) {
      this.setState({ confirmBtnLoading: true });

      await onOk();

      this.setState({ confirmBtnLoading: false });
    }
  }

  renderPopConfirmTitle = () => {
    const { textAreaVal } = this.state;

    return (
      <>
        <p className={styles.title}>拒绝理由</p>
        <TextArea
          value={textAreaVal}
          autosize={{ minRows: 2, maxRows: 6 }}
          onChange={this.changeTextArea}
        />
      </>
    );
  }

  render() {
    const { confirmBtnLoading, cancelBtnLoading } = this.state;
    const {
      confirmText,
      cancelText,
      className,
      popconfirmProps,
      cancelButtonProps,
      okButtonProps
    } = this.props;

    return (
      <div className={classNames(styles.btnGroup, className)}>
        <Button
          type="primary"
          loading={confirmBtnLoading}
          onClick={this.handleClickConfirmBtn}
          {...okButtonProps}
        >
          {confirmText!}
        </Button>

        <Popconfirm
          okText="是"
          cancelText="否"
          title={this.renderPopConfirmTitle()}
          getPopupContainer={triggerNode => triggerNode!.parentNode as HTMLElement}
          onConfirm={this.handleConfirmPop}
          {...popconfirmProps}
        >
          <Button loading={cancelBtnLoading} {...cancelButtonProps}>{cancelText!}</Button>
        </Popconfirm>
      </div>
    );
  }
}

export default ConfirmRejectBtnGroup;
