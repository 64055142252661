// 一些方法拆出来放这里
import { EnumAnalysisType, EnumReportContent, EnumReportCpFlag } from '@chipcoo/constant';
import _, { toNumber, uniq, isEmpty, isNumber, sortBy, isEqual } from 'lodash';

import { getOrgIdByUrl, reservingDecimal } from 'src/utils';

/**
 *
 * @param attr 当前处理的是什么层级的数据
 * @param dataScope 当前的数据范围，用来限定groupBy的数据范围
 * @param prevBatch 前置的batch，需要拼接
 */
type BatchesOption = {
  attr: 'product' | 'dieNumber' | 'waferId';
  dataScope?: any[];
  prevBatch?: string;
  productsMap?: any[];
  type?: EnumAnalysisType;
};
export const getItems = (option: BatchesOption) => {
  const { attr, dataScope, prevBatch, productsMap, type } = option;
  const groupBy = _.groupBy(dataScope, attr);

  const groupItem = Object.keys(groupBy).map(it => {
    // 通过产品id从产品map里获取产品信息
    const productObj = productsMap?.find(p => p?._id === it);
    // 需要处理的数据范围
    const currentGroupItem = groupBy?.[it];

    // 批次层级
    if (attr === 'dieNumber') {
      const key = prevBatch ? prevBatch + '#' + it : it;
      const waferIds = sortBy(uniq(currentGroupItem?.map(item => item?.waferId)));

      return  {
        key,
        value: key,
        title: it,
        // 类型为ft，没有waferId
        // 类型为cp，有waferId, 需要处理CP层级
        children: (type === 'CP' || type === 'PCM') ? waferIds?.map(wafer => {
          const batch = key + '#' + wafer;
          return {
            key: batch,
            value: batch,
            title: it + '#' + wafer,
          };
        }) : []
      };
    }

    // product层级
    return {
      key: it,
      value: it,
      title: productObj ? `${productObj?.customerName}(${productObj?.chipcooName})` : it,
      children: getItems({ attr: 'dieNumber', dataScope: currentGroupItem, prevBatch: it, type })
    };
  });

  return groupItem;
};

interface FiltersType {
  product: string;
  dieNumber: string;
  waferId?: string;
  site?: string;
}
type batchOptions = {
  detail?: Obj;
  singleContentFilter?: FiltersType[];
  type?: EnumAnalysisType;
};
export const getBatches = (option: batchOptions) => {
  const { detail, singleContentFilter, type } = option;
  let [batches, sites]: any = [[], []];
  const productsMap = detail?.products;
  const filters = detail?.filters || singleContentFilter;

  filters?.forEach(it => {
    const { dieNumber, waferId, site } = it;

    if (type === 'FT') {
      batches.push(dieNumber);
    } else {
      batches.push(dieNumber + '#' + waferId);
    }

    if (isNumber(site)) {
      sites.push(site);
    }
  });

  // xxx#xxx#xxx或xxx#xxx形式的batch，用于全选时的数据传递
  const allBatches = uniq(filters?.map(it => {
    const preBatch = it?.product + '#' + it?.dieNumber;
    const batch = type === 'CP' ? preBatch + '#' + it?.waferId : preBatch;

    return batch;
  }));

  return {
    allBatches: sortBy(allBatches),
    batches: getItems({ attr: 'product', dataScope: filters, type, productsMap }),
    sites: isEmpty(uniq(sites)) ? ['all'] : uniq(sites),
  };
};

// 对提交数据进行处理
export const handleFilterData = (payload: any) => {
  const setting = payload?.setting;

  return {
    ...payload,
    setting: {
      ...setting,
      calcStd: setting?.calcStd && toNumber(setting?.calcStd),
      group: setting?.group && toNumber(setting?.group),
      min: isEmpty(setting?.min) ? null : toNumber(setting?.min),
      max: isEmpty(setting?.max) ? null : toNumber(setting?.max),
      rangeMin: isEmpty(setting?.rangeMin) ? null : toNumber(setting?.rangeMin),
      rangeMax: isEmpty(setting?.rangeMax) ? null : toNumber(setting?.rangeMax),
      rangeMinY: isEmpty(setting?.rangeMinY) ? null : toNumber(setting?.rangeMinY),
      rangeMaxY: isEmpty(setting?.rangeMaxY) ? null : toNumber(setting?.rangeMaxY),
    },
  };
};

// 处理preview数据
export const handlePreviewList = (payload) => {
  const orgId = getOrgIdByUrl();
  const formData = payload?.filter(it => it);

  return formData?.map((it, index) => {
    return {
      ...it,
      key: it?._id,
      attachment: `/api/attachment/chipcoo/${it?.attachmentId}/download?orgId=${orgId}`,
      batch: it?.product + '#' + it?.dieNumber + '#' + it?.waferId
    };
  });
};

export const handleMapData = (payload, detail) => {
  const { content } = payload;

  return {
    ...detail,
    [content]: {
      ...detail?.[content],
      mapData: payload
    },
  };
};

// 处理TopN失效项
export const handleTopNData = (topNFailData: any[]) => {
  return topNFailData.map(item => {
    const { data, group } = item;

    const badAmount = _.sum(data?.map(it => it?.bad));
    const topItem = data?.reduce((prev, cur, idx) => {
      const { percent, bad, min, max, mean, std, cp, pstd, pmean, testId, testNum, name } = cur;
      let badPercent: Number;

      if (idx === 0) {
        badPercent = bad / badAmount * 100;
      } else {
        badPercent = prev[idx - 1]?.badPercent + bad / badAmount * 100;
      }

      const curData = {
        ...cur,
        id: testId ?? `${testNum},${name},${min ?? ''},${max ?? ''}`,
        key: Math.random().toString(16),
        percentText: reservingDecimal(percent * 100, 3) + '%',
        percent,
        badPercent,
        min: reservingDecimal(min, 3),
        max: reservingDecimal(max, 3),
        mean: reservingDecimal(mean, 3),
        pmean: reservingDecimal(pmean, 3),
        std: reservingDecimal(std, 3),
        pstd: reservingDecimal(pstd, 3),
        cp: reservingDecimal(cp, 3),
        cpk: reservingDecimal(cur.cpk, 3),
        ppk: reservingDecimal(cur.ppk, 3),
      };

      prev.push(curData);

      return prev;
    }, []);

    return {
      data: topItem,
      group: isNumber(group?.waferId) ? { ...group, waferName: `${group?.dieNumber}#${group?.waferId}`} : group
    };
  });
};

// 处理cpk数据
export const handleCpkData = (cpkData: any[]) => {
  return cpkData?.map(item => {
    const { data, group } = item;

    const cpkItem = data?.map(it => {
      const { min, max, mean, std, cp, ppk, pstd, pmean, testId, testNum, name } = it;

      return {
        ...it,
        id: testId ?? `${testNum},${name},${min ?? ''},${max ?? ''}`,
        key: Math.random().toString(16),
        percentText: reservingDecimal(it?.percent * 100, 3) + '%',
        min: reservingDecimal(min, 3),
        max: reservingDecimal(max, 3),
        mean: reservingDecimal(mean, 3),
        std: reservingDecimal(std, 3),
        pmean: reservingDecimal(pmean, 3),
        pstd: reservingDecimal(pstd, 3),
        cp: reservingDecimal(cp, 3),
        cpk: reservingDecimal(it.cpk, 3),
        ppk: reservingDecimal(ppk, 3),
      };
    });

    return {
      data: cpkItem,
      group: isNumber(group?.waferId) ? { ...group, waferName: `${group?.dieNumber}#${group?.waferId}`} : group
    };
  });
};

// 处理正态分布数据的函数
export const handleStdDistData = (before, after, group) => {
  return [before, after].map(it => {
    const isCpInfinity: boolean = (it?.cpFlag & EnumReportCpFlag.infinityCp) !== 0;
    const isCpkInfinity: boolean = (it?.cpFlag & EnumReportCpFlag.infinityCpk)  !== 0;
    const isPpkInfinity: boolean = (it?.cpFlag & EnumReportCpFlag.infinityPpk)  !== 0;

    const stdDistCp = isCpInfinity ? '∞' : reservingDecimal(it?.cp, 3);
    const stdDistCpk = isCpkInfinity ? '∞' : reservingDecimal(it?.cpk, 3);
    const stdDistPpk = isPpkInfinity ? '∞' : reservingDecimal(it?.ppk, 3);

    return {
      ...it,
      group: group?.waferId ? { ...group, waferName: `${group?.dieNumber}#${group?.waferId}`} : group,
      pmean: it?.pmean,
      pstd: it?.pstd,
      percentText: it?.percent && reservingDecimal(it?.percent * 1000000, 0) + 'PPM',
      predictFail: it?.predictFail && reservingDecimal(it?.predictFail * 1000000, 0) + 'PPM',
      min: reservingDecimal(it?.min, 3),
      max: reservingDecimal(it?.max, 3),
      mean: reservingDecimal(it?.mean, 3),
      pmeanText: reservingDecimal(it?.pmean, 3),
      std: reservingDecimal(it?.std, 3),
      pstdText: reservingDecimal(it?.pstd, 3),
      cp: stdDistCp,
      cpk: stdDistCpk,
      ppk: stdDistPpk,
    };
  });
};

// 处理对比分析数据的函数
export const handleCompareFormData = (formData: any) => {
  const { setting: { compareParams }, _id } = formData;
  const primaryLot = compareParams?.primaryLot;
  const primarySites = compareParams?.primarySites;
  const primaryTestId = compareParams?.primaryTestId?.label;
  const secondaryLot = compareParams?.secondaryLot;
  const secondarySites = compareParams?.secondarySites;
  const secondaryTestId = compareParams?.secondaryTestId?.label;
  const allPrimarySites = compareParams?.allPrimarySites;
  const allSecondarySites = compareParams?.allSecondarySites;

  let primaryFilters: any[] = [], secondaryFilters: any[] = [];
  primaryLot?.forEach(it => {
    const [product, dieNumber, waferId] = it?.split('#');
    const _sites = isEqual(primarySites, ['all']) ? allPrimarySites : primarySites;

    if (isEmpty(_sites)) {
      primaryFilters.push({ product, dieNumber, waferId });
    } else {
      _sites?.forEach(site => {
        primaryFilters.push({ product, dieNumber, waferId, site });
      });
    }
  });

  secondaryLot?.forEach(it => {
    const [product, dieNumber, waferId] = it?.split('#');
    const _sites = isEqual(secondarySites, ['all']) ? allSecondarySites : secondarySites;

    if (isEmpty(_sites)) {
      secondaryFilters.push({ product, dieNumber, waferId });
    } else {
      _sites?.forEach(site => {
        secondaryFilters.push({ product, dieNumber, waferId, site });
      });
    }
  });

  return {
    primary: {
      _id,
      testId: primaryTestId,
      filters: primaryFilters,
      secondary: false,
    },
    secondary: {
      _id,
      testId: secondaryTestId,
      filters: secondaryFilters,
      secondary: true,
    },
  };
};

export const isMap = content => {
  return content === EnumReportContent.pfMap ||
    content === EnumReportContent.binMap ||
    content === EnumReportContent.paramMap ||
    content === EnumReportContent.stackMap;
};
// tslint:disable-next-line:max-file-line-count
