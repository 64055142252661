import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { get, pick, omit } from 'lodash';
// import { request } from 'src/utils';

import {
  PostMessageParams,
  GetMessageHistoryParams,
  DeleteUserFromRoomParams,
  ModifyChatRoomNameParams,
  PutNewMemberToRoomParams
} from 'src/models/im/chatPanel.type';

export default function(api: AxiosInstance) {
  return {
    // 创建房间(多人群组聊天)
    postCreateRoom(userIds: string[], config?: AxiosRequestConfig) {
      const newUserIds = userIds.map(it => it ? parseInt(it, 10) : 0);

      return api.post('im/room', { userIds: newUserIds }, config);
    },

    // 打开私聊房间
    putPrivateRoom(userId: string, config?: AxiosRequestConfig) {
      return api.put(`im/room/private/${userId}`, config);
    },

    // 获取房间列表
    getRoomList(config?: AxiosRequestConfig) {
      return api.get('im/room/latest', config);
    },

    // 删除某个房间
    deleteRoom(roomId: string, config?: AxiosRequestConfig) {
      return api.delete('im/room/latest', {
        ...config,
        params: { roomId, ...(get(config, 'params') || {}) },
      });
    },

    // 置顶或取消置顶房间
    patchRoomTop(roomId: string, type: 'top' | 'untop', config?: AxiosRequestConfig) {
      return api.patch(`im/room/${roomId}/${type}`, config);
    },

    // 发送消息
    postMessage(params: PostMessageParams, config?: AxiosRequestConfig) {
      return api.post(
        `im/message/room/${params.roomId}`,
        {
          ...params,
          attachments: params.attachments && params.attachments.map(
            attachment => pick(
              attachment,
              ['filename', 'size', 'url', 'mimetype', 'width', 'height']
            )
          ),
        },
        config,
      );
    },

    // 标记房间已读
    patchRoomRead(roomId: string, config?: AxiosRequestConfig) {
      return api.patch(`im/room/${roomId}/markRead`, config);
    },

    // 获取房间信息
    getRoomInfo(roomId: string, config?: AxiosRequestConfig) {
      return api.get(`im/room/${roomId}/info`, config);
    },

    // 获取聊天记录
    getMessageHistory(params: GetMessageHistoryParams, config?: AxiosRequestConfig) {
      return api.get(
        `/im/message/room/${params.roomId}/history`,
        {
          ...config,
          params: {
            ...config,
            ...get(config, 'params'),
            ...omit(params, ['roomId']),
          },
        }
      );
    },

    // 删除某一条聊天记录
    deleteMessage(messageId: string, config?: AxiosRequestConfig) {
      return api.delete(`im/message/${messageId}`, config);
    },

    // 从房间移除用户
    deleteUserFromRoom(params: DeleteUserFromRoomParams, config?: AxiosRequestConfig) {
      return api.delete(`im/room/${params.roomId}/user`, {
        ...config,
        params: {
          ...get(config, 'params'),
          ...omit(params, ['roomId']),
        },
      });
    },

    // 修改房间名称
    patchRoomName(params: ModifyChatRoomNameParams, config?: AxiosRequestConfig) {
      //
      return api.patch(`im/room/${params.roomId}`, {
        ...omit(params, ['roomId']),
      }, config);
    },

    // 离开房间
    // 注：群主为解散房间，普通成员为离开房间
    deleteRoomByLeave(roomId: string, config?: AxiosRequestConfig) {
      return api.delete(`im/room/${roomId}/leave`, config);
    },

    // 获取所有未读的消息的数量
    getUnreadMessageNumber(config?: AxiosRequestConfig) {
      return api.get(`im/room/unread`, config);
    },

    // 添加用户到房间
    putNewMemberToRoom(params: PutNewMemberToRoomParams, config?: AxiosRequestConfig) {
      return api.put(`im/room/${params.roomId}/user`, {
        ...omit(params, ['roomId']),
      }, config);
    }

  };
}
