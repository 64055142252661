/**
 * 这个组件主要使用场景：一个按钮组，确定和取消
 * 如果分别点击确定和取消都会向服务器发送事件，那么当点击了一个按钮以后另外一个按钮需要被禁止点击
 */
import * as React from 'react';
import { Button } from 'antd';

interface P {
  onOk: (e?: any) => void;
  onCancel: (e?: any) => void;
  okText?: string;
  cancelText?: string;
  // 如果请求完成以后整个组件被直接unmount掉了，那就不需要在重置loading状态了
  showOverLoadingStatus?: boolean;
}
interface S {
  okBtnLoading: boolean;
  cancelBtnLoading: boolean;
}

class OkCancelBtnGroup extends React.PureComponent<P, S> {
  static defautProps = {
    okText: '确定',
    cancelText: '取消',
    showOverLoadingStatus: false
  };

  constructor(props: P) {
    super(props);

    this.state = {
      cancelBtnLoading: false,
      okBtnLoading: false
    };
  }

  handleClickOk = async (e) => {
    const { onOk, showOverLoadingStatus } = this.props;

    this.setState({ okBtnLoading: true });

    await onOk(e);

    showOverLoadingStatus && this.setState({ okBtnLoading: false });
  }

  handleClickCancel = async (e) => {
    const { onCancel, showOverLoadingStatus } = this.props;

    this.setState({ cancelBtnLoading: true });

    await onCancel(e);

    showOverLoadingStatus && this.setState({ cancelBtnLoading: false });
  }

  render() {
    const { okText, cancelText } = this.props;
    const { okBtnLoading, cancelBtnLoading } = this.state;

    return (
      <div className="flex-static">
        <Button
          type="primary"
          onClick={this.handleClickOk}
          loading={okBtnLoading}
          disabled={cancelBtnLoading}
        >
          {okText}
        </Button>
        <Button
          onClick={this.handleClickCancel}
          loading={cancelBtnLoading}
          disabled={okBtnLoading}
        >
          {cancelText}
        </Button>
      </div>
    );
  }
}

export default OkCancelBtnGroup;
