/**
 * 写腻歪了三目运算符可以试试这个，参考：https://github.com/hadiab/react-condition
 *
 * 注意ElseIf和Else其实都是If的子组件
 *
 * !!!!! 特别需要注意的是，Else 和 ElseIf组件后面的React.ReactNode节点只能有一个
 *
 * <If condition={false}>
 *  <div>TRUE</div>
 * <ElseIf condition={fruit === 'Orange'} />
 *  <div>Orange</div>
 * <Else />
 *  <div>FALSE</div>
 * </If>
 */
import React, { memo, ReactNode } from 'react';
import { isObject, isFunction } from 'lodash';
import { ReactChild } from 'react';

interface P {
  children?: ReactNode;
  condition?: boolean;
}

const Else = () => null;
const ElseIf = (props: P) => null;
const getDisplayName = (child: ReactChild) => {
  if (!isObject(child) && !isFunction((child as any).type)) return void 0;

  return (child as any).type.displayName;
};

const If = memo((props: P) => {
  const { children, condition } = props;

  let block: null | React.ReactNode = null;
  let stopMap = false;
  let tempCondition = condition;

  React.Children.forEach(children, (child: ReactChild) => {
    if (stopMap) { return; }

    const displayName = getDisplayName(child);
    const tempChild = child as React.ReactElement<P>;

    if (displayName === 'ElseIf') {
      tempCondition = tempChild.props.condition;
    } else if (displayName === 'Else') {
      tempCondition = !tempCondition;
    } else {
      if (tempCondition) {
        block = child;
        stopMap = true;
      }
    }
  });

  return block;
});

export { If, Else, ElseIf };
