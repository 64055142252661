import React, { PureComponent, ReactNode } from 'react';
import { bind } from 'lodash-decorators';
import classnames from 'classnames';
import { Tooltip, Popover, Input, Icon } from 'antd';

import { HocSingleUserItem } from './SingleUserItem';
import { isEqualUserId } from 'src/utils/user';

export interface QuickSelectCommonProps {
  onVisibleChange?: (visible: boolean) => any;
  onToggleSelect?: (id: string) => any;
  users?: User[];
  placeholder?: string;
  autoFocus?: boolean;
  canEdit?: boolean;
  userDisabled?: boolean | ((user: User<any>) => boolean);
}

interface Props extends QuickSelectCommonProps {
  value?: boolean;
  selected: string[] | string;
  getDisplayName: (member: User) => string;
  filterOption: (filterValue: any, record?: User) => boolean;
  getPopupContainer?: () => HTMLElement;
  showInList: (user: User<any>) => boolean;
}

interface States {
  visible: boolean;
  filterValue: string;
}

export class UserPickPopover extends PureComponent<Props, States> {
  static defaultProps = {
    value: false,
    onVisibleChange(visible: boolean) {
      this.setState({ visible });
    },
    users: [],
    selected: [],
    autoFocus: true,
  };

  state = {
    visible: false,
    filterValue: '',
  };

  private inputRef: ReactNode;

  static getDerivedStateFromProps(nextProps: Props) {
    const nextState: Partial<States> = {};

    nextState.visible = nextProps.value;

    return nextState;
  }

  componentDidUpdate(_: any, prevState: States) {
    const { visible } = this.state;
    if (visible && !prevState.visible && this.props.autoFocus) {
      setTimeout(() => this.tryFocus());
    }
  }

  @bind
  bindRef(ref: ReactNode) {
    this.inputRef = ref;
  }

  @bind
  setFilterValue(e: any) {
    this.setState({ filterValue: e.target.value });
  }

  tryFocus() {
    try {
      (this.inputRef as Input).focus();
    } catch (e) {
      console.error(e);
    }
  }

  @bind
  handleVisibleChange(visible: boolean) {
    const { canEdit, onVisibleChange } = this.props;
    canEdit && onVisibleChange && onVisibleChange.call(this, visible);
  }

  @bind
  tryGetPopupContainer() {
    if (this.props.getPopupContainer) {
      return this.props.getPopupContainer();
    }

    return document.body;
  }

  @bind
  toggleSelect(user: User) {
    const { onToggleSelect } = this.props;
    onToggleSelect && onToggleSelect(user._id);
  }

  @bind
  isUserSelected(memberId: UserId) {
    const { selected } = this.props;
    if (Array.isArray(selected)) {
      return selected!.some(id => isEqualUserId(id, memberId));
    }

    return isEqualUserId(memberId, selected!);
  }

  renderSearch() {
    const Search = (<Icon type="search"/>);
    const value = this.state.filterValue;

    return (
      <Input ref={this.bindRef} value={value} onChange={this.setFilterValue} prefix={Search} placeholder="姓名/手机号/邮箱" />
    );
  }

  renderUserList() {
    const { users, getDisplayName, filterOption, userDisabled } = this.props;
    const { filterValue } = this.state;

    return (
      <div className="y-scroll hover-thin-scroll member-select-wrapper">
        <div className="member-select-list">
          {users!.filter(this.props.showInList).reduce((all, userInfo) => {
            if (filterOption(filterValue, userInfo)) {

              all.push(
                <HocSingleUserItem
                  userDisabled={userDisabled}
                  isSelected={this.isUserSelected(userInfo._id)}
                  handleClick={this.toggleSelect}
                  displayName={getDisplayName(userInfo)}
                  userInfo={userInfo}
                  key={userInfo._id}
                />
              );
            }
            return all;
          }, [] as JSX.Element[])}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderUserList()}
      </div>
    );
  }

  render() {
    const { placeholder, getDisplayName, selected, users, canEdit, userDisabled } = this.props;
    const { visible } = this.state;
    const isMultiple = Array.isArray(selected);
    let userInfo;

    if (isMultiple && !canEdit) {
      return null;
    }

    if (!isMultiple) {
      userInfo = users!.find(m => isEqualUserId(m._id, selected as string));
    }

    return (
      <Popover
        getPopupContainer={this.tryGetPopupContainer}
        visible={visible}
        trigger="click"
        placement="topLeft"
        content={this.renderContent()}
        overlayClassName="quick-select-member quick-member-select-overlay"
        onVisibleChange={this.handleVisibleChange}
      >
        <Tooltip title={canEdit ? placeholder : ''} placement="top">
          <div className={classnames(isMultiple ? 'add-btn single-item circled-size' : 'single-mode-selected')}>
            {
              isMultiple ?
              <i className="c-icon icon-plus-thick"/>
              :
              <HocSingleUserItem
                userDisabled={userDisabled}
                isSelected={false}
                displayName={getDisplayName(userInfo)}
                userInfo={userInfo}
              />
            }
          </div>
        </Tooltip>
      </Popover>
    );
  }
}

export default UserPickPopover;
