import { AxiosInstance } from 'axios';

export type BusinessTypeCN = '封装' | '晶圆测试' | '磨划' | '成品测试';

type SearchFactoryParams = {
  name?: string;
  page?: number;
  limit?: number;
  product?: string;
  businessType: BusinessTypeCN;
};

export default function(api: AxiosInstance) {
  return {
    // 根据businessType来筛选加工厂
    searchFactory(params: SearchFactoryParams) {
      return api.get('product/factory/search', { params });
    },

    // // 根据芯片名称进行搜索
    // searchByDieName(params: Obj) {
    //   return api.get('product/die/fast-search', {
    //     params
    //   });
    // },

    // 获取芯片可用的版本
    availableDieVersion(name: string) {
      return api.get('product/die/available-versions', { params: { name } });
    },
  };
}
