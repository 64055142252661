// tslint:disable:max-file-line-count
import React, { PureComponent } from 'react';
import GGEditor, { Flow, PropsAPIHocFix } from 'gg-editor';
import { pick, isEqual, uniqueId } from 'lodash';
import { bind } from 'lodash-decorators';
import PropsAPICtx from 'gg-editor/es/common/context/PropsAPIContext';
import { FlexModalManager, FlexModalEvents, IFlexModalEventsPayload } from '@chipcoo/hanayo/lib/flex-modal';
import { onNextFrame, noop } from '@chipcoo/fe-utils';

import styles from 'src/components/CraftRoute/index.module.less';
import { ICraftRouteFullRoute, ICraftRouteItemLocal } from 'src/services/net/craftRoute';

import Layout from './utils/compact-graph';
import {
  IGraphData,
} from 'src/components/CraftRoute/core';
import { RegisterShapes } from 'src/components/CraftRoute';
import { parseCraftRoute } from './utils/parse-craft-route';
import { CraftRouteManage } from './utils/craft-route-manage';

export { parseCraftRoute };

interface Props {
  modalName?: string;
  craftRoute: ICraftRouteFullRoute | ICraftRouteItemLocal;
  targetMaterial?: string;
  // 暂时忽略合封
  sourceMaterial?: string | string[];
}

interface State {

}

const PropsAPIContext = PropsAPICtx as React.Context<PropsAPIHocFix['propsAPI']>;

interface FinalProps extends Props {
  getContainer: () => HTMLElement;
}

class SingleCraftRouteCore extends PureComponent<FinalProps, State> {
  static contextType = PropsAPIContext;

  context!: React.ContextType<typeof PropsAPIContext>;

  private flowId = uniqueId('flow_');
  private unmounted = false;
  private graphData: IGraphData;
  private craftRouteManage: CraftRouteManage;
  layout: Layout;
  constructor(props: FinalProps) {
    super(props);
    const { craftRoute, targetMaterial, sourceMaterial } = props;
    this.craftRouteManage = new CraftRouteManage(craftRoute, targetMaterial, sourceMaterial);
    this.graphData = this.getGraphData();
    this.layout = new Layout({}, this.craftRouteManage.treeCraftRoute);
  }

  componentDidMount() {
    if (this.props.modalName) {
      FlexModalManager.on(FlexModalEvents.onResize, this.updateGraphSize);
    }

    onNextFrame(() => {
      this.updateGraphSize();
      this.updateGraphGroup();
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
    FlexModalManager.off(FlexModalEvents.onResize, this.updateGraphSize);
  }

  componentDidUpdate(prevProps: FinalProps) {
    const diffPropNames: (keyof Props)[] = ['craftRoute', 'targetMaterial', 'sourceMaterial'];
    if (!isEqual(pick(this.props, diffPropNames), pick(prevProps, diffPropNames))) {
      const { craftRoute, targetMaterial, sourceMaterial } = this.props;
      this.craftRouteManage.updateCraftRoute(craftRoute, targetMaterial, sourceMaterial);
      this.layout.craftRouteLocal = this.craftRouteManage.treeCraftRoute;
      this.graphData = this.getGraphData();

      this.updateGraphData();
    }
  }

  getGraphData() {
    return this.craftRouteManage.getGraphData();
  }

  getGraph() {
    const page = this.context!.editor.getCurrentPage();
    if (page) {
      return page.getGraph();
    }
  }

  @bind
  updateGraphSize(payload?: IFlexModalEventsPayload) {
    if (payload && (payload.modalName !== this.props.modalName || !payload.visible)) {
      return;
    }

    if (this.unmounted) {
      return;
    }
    const { getContainer } = this.props;
    const graph = this.getGraph();
    if (graph) {
      const container = getContainer();
      if (container) {
        const rect = container.getBoundingClientRect();
        graph.changeSize(rect.width, rect.height);
        onNextFrame(() => {
          try {
            graph && graph.setFitView('cc');
          } catch (e) {
            // 通常这个错误不需要被捕获
          }
        });
      }
    }
  }

  @bind
  updateGraphData() {
    const graph = this.getGraph();
    if (graph) {
      graph.read(this.graphData);
      this.updateGraphGroup();
    }
  }

  updateGraphGroup() {
    setTimeout(() => {
      const graph = this.getGraph();
      try {
        graph && graph.getGroups().forEach(group => {
          graph.update(group, { label: '合封' });
        });
      } catch (e) {
        //
      }
    });
  }

  render() {
    return (
      <Flow
        key={this.flowId}
        data={this.graphData}
        align={{
          grid: true,
        }}
        noEndEdge={false}
        className={styles.flow}
        graph={{
          layout: this.layout,
          fitView: 'cc',
          mode: 'readOnly',
          edgeDefaultShape: 'flow-polyline-round',
        }}
      />
    );
  }
}

export class PreviewCraft extends React.PureComponent<Props> {
  mountRef = React.createRef<HTMLDivElement>();

  componentWillUnmount() {
    this.setState = noop;
  }

  getContainer = () => this.mountRef.current!;

  render() {
    return (
      <div className={styles.editor} ref={this.mountRef}>
        <GGEditor className={styles.editor}>
          <RegisterShapes />
          <SingleCraftRouteCore getContainer={this.getContainer} {...this.props} />
        </GGEditor>
      </div>
    );
  }
}

export default PreviewCraft;
