// tslint:disable:max-file-line-count

import { createModel } from '@rematch/core';
import produce from 'immer';
import { remove } from 'lodash';
import { sleep, formatDate } from '@chipcoo/fe-utils';
// import { createSelector } from 'reselect';

import { workspaceApi } from 'src/services/net';

import { isEqualUserId } from 'src/utils/user';
import { getNormalizedData, initialNormalizedData } from 'src/utils';
import {
  Workspace,
  InitialState,
  CooOperatePayload,
  WithWorkspaceId,
  SetMemberParams,
  ActivityItem
} from './interface';
import { CreateParams, MemberGroupEditParams, MemberGroupParams, ActivityParams } from 'src/services/net/workspace';
import { RootState } from 'src/store';

const initialState: InitialState = {
  list: initialNormalizedData({ fetched: false }),
  workspaceId: '',
  activity: {},
};

type SetMemberPayload = WithWorkspaceId<{ members: NormalMember[] }>;

export default createModel({
  state: initialState,
  reducers: {
    setWorkspace(state: InitialState, data: Workspace) {
      return produce(state, (draft) => {
        if (!draft.list.byId[data._id]) {
          draft.list.allIds.push(data._id);
        }

        draft.list.byId[data._id] = data;
      });
    },
    setWorkspaceId(state: InitialState, id: string) {
      if (state.workspaceId === id) return state;

      return produce(state, (draft) => {
        draft.workspaceId = id;

        // 初始化工作区动态。
        if (!draft.activity[id]) {
          draft.activity[id] = initialNormalizedData();
        }
      });
    },
    mergeActivity(state: InitialState, payload: WithWorkspaceId<{ list: ActivityItem[] }>) {
      const { workspaceId, list } = payload;
      return produce(state, draft => {
        const data = draft.activity[workspaceId];

        list.forEach((item) => {
          if (!data.byId[item._id]) {
            data.allIds.push(item._id);
            data.byId[item._id] = {
              ...item,
              day: formatDate(new Date(item.updatedAt), 'yyyy-MM-dd'),
              createAtTimestamp: new Date(item.createdAt).getTime(),
              updateAtTimestamp: new Date(item.updatedAt).getTime(),
            };
          }
        });
      });
    },
    removeMember(state: InitialState, payload: WithWorkspaceId<{ userId: UserId} >) {
      const { workspaceId, userId } = payload;
      return produce(state, (draft) => {
        const workspace = draft.list.byId[workspaceId];
        const eq = (m: Member) => !isEqualUserId(m.user, userId);

        if (workspace) {
          workspace.members = workspace.members.filter(eq);

          workspace.memberGroup.forEach(group => {
            remove(group.users, id => isEqualUserId(id, userId));
          });
        }
      });
    },
    setMember(state: InitialState, payload: SetMemberPayload) {
      return produce(state, (draft) => {
        const { members, workspaceId } = payload;
        const workspace = draft.list.byId[workspaceId];

        if (workspace) {
          const { members: exists } = workspace;

          members.forEach(member => {
            const { user, role } = member;
            const find = exists.find(m => isEqualUserId(user, m.user));
            if (find) {
              find.role = role;
            } else {
              exists.push({
                ...member,
                user: (user as any) * 1,
                role: role || 'MEMBER',
              });
            }
          });
        }
      });
    },
    addMemberGroup(state: InitialState, payload: WithWorkspaceId<{ name: string }>) {
      return produce(state, draft => {
        const workspace = draft.list.byId[payload.workspaceId];

        workspace.memberGroup.push({
          name: payload.name,
          users: [],
        });
      });
    },
    editMemberGroup(state: InitialState, payload: WithWorkspaceId<MemberGroupEditParams>) {
      return produce(state, draft => {
        const workspace = draft.list.byId[payload.workspaceId];
        const find = workspace.memberGroup.find(it => it.name === payload.oldName);

        if (find) {
          find.name = payload.newName || find.name;
          find.users = payload.members || find.users;
        }
      });
    },
    removeMemberGroup(state: InitialState, payload: WithWorkspaceId<{ name: string }>) {
      return produce(state, draft => {
        const workspace = draft.list.byId[payload.workspaceId];

        if (workspace) {
          remove(workspace.memberGroup, group => group.name === payload.name);
        }
      });
    },
    setWorkspaceStar(state: InitialState, payload: WithWorkspaceId<{isStar: boolean}>) {
      return produce(state, draft => {
        const workspace = draft.list.byId[payload.workspaceId];

        if (workspace) {
          workspace.isStar = !!payload.isStar;
        }
        draft.list[payload.workspaceId] = workspace;
      });
    },
  },
  effects: (dispatch) => ({
    removeWorkspace(id: string) {
      return () => this.removeNormalizedData({
        removeIds: [id],
        path: 'list',
      });
    },
    async fetch({ force, params }: DispatchPayload = { force: false }) {
      // if (rootState.workspace.list.fetched && !force) {
      //   logDataCache('Workspace list: hit from cache');
      //   return;
      // }
      const { data }: { data: Workspace[] } = await workspaceApi.list(params);
      this.updateState([
        { path: 'list', data: { ...getNormalizedData<Workspace>(data), fetched: true } },
      ]);
    },
    async create(params: CreateParams) {
      const { data } = await workspaceApi.create(params);
      this.setWorkspace(data);
    },
    async asyncEdit(params: WithWorkspaceId<CreateParams>) {
      const { workspaceId, ...createParams } = params;
      const{ data } = await workspaceApi.edit(workspaceId, createParams);
      this.setWorkspace(data);
    },
    async dissolve({ workspaceId, password }: CooOperatePayload) {
      await workspaceApi.dissolve(workspaceId, { password });

      return this.removeWorkspace(workspaceId);
    },
    async leave({ workspaceId, password }: CooOperatePayload) {
      await workspaceApi.leave(workspaceId, { password });

      return this.removeWorkspace(workspaceId);
    },
    async transfer({ workspaceId, password, userId }: CooOperatePayload, rootState: RootState) {
      const { data } = await workspaceApi.transfer(workspaceId, { password, userId: userId! });
      console.log(data);

      const { profile } = rootState.session;
      const members = [
        { user: userId, role: 'OWNER' },
        { user: profile!._id, role: 'MEMBER' },
      ];
      this.setMember({ workspaceId, members });
    },
    async archive({ workspaceId }: WithWorkspaceId) {
      const { data } = await workspaceApi.archive(workspaceId);
      console.log(data);
    },
    async asyncAddMember({ workspaceId, members }: WithWorkspaceId<{ members: NormalMember[] }> ) {
      await workspaceApi.addMember(workspaceId, { members });

      this.setMember({ workspaceId, members });
    },
    async asyncRemoveMember({ workspaceId, userId }: WithWorkspaceId<{ userId: User['_id'] }>) {
      await workspaceApi.removeMember(workspaceId, { userId });

      this.removeMember({ workspaceId, userId });
    },
    async asyncSetMemberRole(payload: WithWorkspaceId<SetMemberParams>) {
      const { workspaceId, userId, role } = payload;
      await workspaceApi.setMemberRole(workspaceId, userId, { role });
      const members = [{ user: userId, role }];
      this.setMember({ workspaceId, members });
    },
    async asyncAddMemberGroup({ workspaceId, name }: WithWorkspaceId<MemberGroupParams>) {
      const groupName = name.trim();
      await workspaceApi.createMemberGroup(workspaceId, { name: groupName });
      this.addMemberGroup({ workspaceId, name: groupName });
    },
    async asyncRemoveMemberGroup(payload: WithWorkspaceId<MemberGroupParams>) {
      const { workspaceId, name } = payload;
      await workspaceApi.deleteMemberGroup(workspaceId, { name });

      setTimeout(() => {
        this.removeMemberGroup({ workspaceId, name });
      }, 50);
    },
    async asyncEditMemberGroup(payload: WithWorkspaceId<MemberGroupEditParams>) {
      const { workspaceId, ...editParams } = payload;
      await workspaceApi.editMemberGroup(workspaceId, editParams);
      this.editMemberGroup(payload);
    },
    async fetchActivity({ workspaceId, ...params }: WithWorkspaceId<ActivityParams>) {
      const { data } = await workspaceApi.fetchActivity(workspaceId, params);
      this.mergeActivity({ workspaceId, list: data });

      return data;
    },
    async starWorkspace({ workspaceId }: WithWorkspaceId) {
      await workspaceApi.star(workspaceId);
      await sleep(500);
      this.setWorkspaceStar({ workspaceId, isStar: true });
    },
    async unstarWorkspace({ workspaceId }: WithWorkspaceId) {
      await workspaceApi.unstar(workspaceId);
      await sleep(500);
      this.setWorkspaceStar({ workspaceId, isStar: false });
    },
    async handleSyncMember(payload: any, rootState: RootState) {
      // See https://doc.chipwing.com/pages/viewpage.action?pageId=6750209

      const { event, workspaceId, members } = payload;

      switch (event) {
        case 'leave':
          // 离开时给的参数为 UserIdNumber[]
          (members as UserId[]).forEach((userId) => this.removeMember({ userId, workspaceId }));
          break;
        case 'join':
          // 加入时给的参数为 Member[]
          this.setMember({ workspaceId, members: members as Member[] });
          break;
        default:
          //
      }
    },
    async handleSyncWorkspace(payload: any, rootState: RootState) {
      const { event, workspace } = payload;

      switch (event) {
        case 'update':
        case 'create':
          if (rootState.org.orgId === workspace.organization) {
            this.setWorkspace(workspace);
          }
          break;
        case 'dissolve':
          (this.removeWorkspace(workspace._id) as any)();
          break;
        case 'transfer':
          const { members, _id: workspaceId } = workspace;
          this.setMember({ workspaceId, members });
          break;
        case 'archive':
          // TODO: 协作区归档
          break;
        default:
      }
    }
  })
});
