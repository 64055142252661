/**
 * Created by marvin on 18/8/7.
 */

import * as React from 'react';
import { message, Upload } from 'antd';
// import { get } from 'lodash';
import log from 'loglevel';
import ImgCrop from 'antd-img-crop';

import styles from './index.module.less';
import { attachmentApi } from 'src/services/net';

// import AvatarModal from '../AvatarModal';
import { EnhanceAvatar } from 'src/components';

interface Props {
  src: string;
  name: string;
  userId: string;
  modifyAvatar: (url: string) => void;  // 点击确定上传图片，完成修改头像
}
interface State {
  visible: boolean;
  image: any;
  name: string;
}

class UserAvatar extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      visible: false,
      image: '',
      name: ''
    };
  }

  // 打开头像编辑窗口，并将选择的图片传入到编辑窗口中
  // handleNewImage = e => {
  //   const files = e.target.files;
  //
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //
  //     const fileReader = new FileReader();
  //
  //     fileReader.readAsDataURL(file);
  //
  //     fileReader.onload = (result) => {
  //       const dataURL = get(result, 'target.result');
  //
  //       if (dataURL) {
  //         this.setState(
  //           {
  //             image: dataURL
  //           },
  //           () => {
  //             this.setState({ visible: true });
  //           }
  //         );
  //       }
  //     };
  //
  //     // 上传成功清空input[file]的值，使上传同一张图片时正常上传
  //     e.target.value = '';
  //   }
  // }

  // 关闭模态窗
  closeModal = () => this.setState({ visible: false });

  handleSubmit = async (file) => {
    const { userId, modifyAvatar } = this.props;
    // const { image } = this.state;

    try {
      // blob.name = image.name;
      // blob.lastModified = new Date();

      const resp = await attachmentApi.uploadFileToServiceProvider({
        referenceName: 'user',
        referenceId: userId,
        referenceRole: 'avatar',
        file,
      });

      const { url } = resp.data;

      await modifyAvatar(url);

      this.setState({ visible: false }, () => {
        message.success('头像已更换，如未显示新头像请刷新页面');
      });
    } catch (e) {
      log.error(e);
    }
  }

  render () {
    const { src, name } = this.props;
    // const { visible, image } = this.state;

    return (
      <div className={styles.avatar}>
        <EnhanceAvatar src={src} userName={name} />

        <ImgCrop
          grid={true}
          shape="round"
          rotate={true}
          modalTitle="头像上传"
          onModalOk={this.handleSubmit}
        >
          <Upload>
            <a>修改头像</a>
          </Upload>
        </ImgCrop>

        {/*<div className={styles.modify}>*/}
        {/*  <label>修改头像*/}
        {/*    <input*/}
        {/*      type="file"*/}
        {/*      accept="image/bmp,image/gif,image/jpg,image/jpeg,image/png"*/}
        {/*      onChange={this.handleNewImage}*/}
        {/*    />*/}
        {/*  </label>*/}
        {/*</div>*/}

        {/*<AvatarModal*/}
        {/*  visible={visible}*/}
        {/*  image={image}*/}
        {/*  closeModal={this.closeModal}*/}
        {/*  onSubmit={this.handleSubmit}*/}
        {/*/>*/}
      </div>
    );
  }
}

export default UserAvatar;
