import React, { PureComponent } from 'react';
import moment from 'moment';
import { bind } from 'lodash-decorators';
import { humanDate, getUserNameEnhance } from '@chipcoo/fe-utils';

import { CommentMessage } from 'src/models/message.model';
import { isEqualUserId, findNameById } from 'src/utils/user';
import { HocUserMessage, HandledMessageContent, TimeStamp } from 'src/components';
import CommentAttachment from './CommentAttachment';
import { CommentDispatchProps, Resource, UCBasicProps } from './interface';
import { AdminUser } from '.';
import { Tooltip } from 'antd';

interface Props {
  comment: CommentMessage;
  userId: UserId;
  atUserId?: UCBasicProps['atUserId'];
  resource: Resource;
  deleteComment: CommentDispatchProps['deleteComment'];
  userDisabled?: (user: User<any>) => boolean;
  getFullUser: (user: User<any>) => User<any>;
}

interface State {
  showRecall: boolean;
  recalling: boolean;
}

const recallDdl = 3 * 60 * 1000;

function recallRemaining(createdAt: string) {
  return canNotRecallTime(createdAt) - moment().toDate().getTime();
}

function canNotRecallTime(createdAt: string) {
  return moment(createdAt).toDate().getTime() + recallDdl;
}

function isSimpleMessage(comment: CommentMessage) {
  return comment.type === 'MESSAGE' || comment.type === 'COMMENT';
}

function isMyPost(comment: CommentMessage, id: UserId) {
  return isEqualUserId(id, comment.user || comment.admin || '');
}

export class CommentDetail extends PureComponent<Props, State> {
  private hideRecallTimer;
  private unmounted = false;
  constructor(props: Props) {
    super(props);
    const { userId, comment } = props;
    const remaining = recallRemaining(props.comment.createdAt);
    let showRecall = false;

    if (remaining > 0 && isSimpleMessage(comment) && isMyPost(comment, userId)) {
      showRecall = true;
      this.hideRecallTimer = setTimeout(this.hideRecall, remaining);
    }

    this.state = {
      showRecall,
      recalling: false,
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
    clearTimeout(this.hideRecallTimer);
  }

  safeSetState(state: any, cb?: any) {
    if (!this.unmounted) {
      this.setState(state, cb);
    }
  }

  get message() {
    //
    return this.props.comment.message;
  }

  @bind
  hideRecall() {
    this.safeSetState({ showRecall: false });
  }

  @bind
  async recall() {
    if (this.state.recalling) {
      return;
    }

    this.safeSetState({ recalling: true });

    const { comment: { _id }, resource, deleteComment } = this.props;
    await deleteComment({ resource, commentId: _id });

    this.safeSetState({ recalling: false });
  }

  renderRecall() {
    if (!this.state.showRecall) {
      return null;
    }

    return <span onClick={this.recall} className="delete-message-btn message-handle-btn">删除</span>;
  }

  @bind
  isUserDisabled(user: User<any>) {
    if (this.props.userDisabled) {
      return this.props.userDisabled(user);
    }

    return false;
  }

  @bind
  getUserName(userId: UserId) {
    const users = this.props.comment.relatedUsers || [];

    return findNameById([AdminUser].concat(users), userId);
  }

  renderMessage() {
    const { comment, resource, userId, atUserId } = this.props;
    const { message, attachments } = comment;

    const elements: JSX.Element[] = [];
    let i = 0;
    if (attachments!.length === 0 || message !== '[附件]') {
      elements.push(
        <p className="pure-message" key={i++}>
          <span className="pure-message-content">
            <HandledMessageContent
              myUserId={atUserId || userId.toString()}
              findNameFn={this.getUserName}
              content={this.message!}
            />
          </span>
        </p>
      );
    }

    const showImage = attachments!.length > 1;

    attachments!.forEach(id => {
      elements.push(<CommentAttachment resource={resource} attachmentId={id} key={id} imgPreview={!showImage}/>);
    });

    return elements;
  }

  renderSideExtra() {
    const { updatedAt } = this.props.comment;

    return (
      <div className="message-side-extra">
        <span className="message-handler">{this.renderRecall()}</span>
        <Tooltip
          title={moment(updatedAt).format('YYYY-MM-DD HH:mm')}
          placement="topRight"
          overlayStyle={{ whiteSpace: 'nowrap' }}
          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        >
          <span>
            <TimeStamp tag="span" time={updatedAt} format={humanDate} />
          </span>
        </Tooltip>
      </div>
    );
  }

  render() {
    const { relatedUsers, user, admin, type } = this.props.comment;
    let userInfo = (relatedUsers || []).find(u => isEqualUserId(u._id, user!));

    if (admin) {
      userInfo = AdminUser;
    }

    if (type === 'DELETED') {
      const name = getUserNameEnhance(userInfo!);
      return (
        <p className="recalled-message"><span>@{name} 删除了一条消息</span></p>
      );
    }

    const fullUserInfo = this.props.getFullUser(userInfo!);

    return (
      <HocUserMessage
        userInfo={fullUserInfo}
        userDisabled={this.isUserDisabled(fullUserInfo)}
        // messageTime={formatDate(new Date(updatedAt), 'yyyy-MM-dd hh:mm')}
        messageTime={this.renderSideExtra()}
        className={`${(type as string).toLowerCase()}-message`}
      >
        {this.renderMessage()}
      </HocUserMessage>
    );
  }
}

export default CommentDetail;
