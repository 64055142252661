import { uniqueId, get } from 'lodash';
import { ICraftRouteItem, ICraftRouteItemLocal } from 'src/services/net/craftRoute';

export function toTreeGraph(route: ICraftRouteItem | ICraftRouteItemLocal): ICraftRouteItemLocal {
  const isGroupItem = route.sources.length > 1;
  let externalObj = {
    groupId: '',
    siblingLength: route.sources.length,
  };

  if (!isGroupItem) {
    Reflect.deleteProperty(externalObj, 'groupId');
  } else {
    externalObj.groupId = uniqueId('tree_graph_group_');
  }

  const children: ICraftRouteItemLocal['children'] = route.sources.map((source, itemIndex) => {
    if (source.type === 'material') {
      return { ...source, parent: route, itemIndex, };
    }

    const { amount } = source;

    return {
      parent: route,
      itemIndex,
      ...toTreeGraph(source.item),
      amount,
    };
  });

  return {
    ...route,
    amount: get(route, 'amount') || 1,
    children: children.map(item => {
      item.siblings = children;

      return item;
    }),
  };
}
