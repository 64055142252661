/**
 * 出库申请slices列表
 */
import * as React from 'react';
import _, { get } from 'lodash';
import { CardTableV2 } from '@chipcoo/hanayo';
import { ColumnProps } from 'antd/lib/table';

import { getTableColumns } from './getTableColumns';

interface IProps {
  value?: any;
  onChange?: (values: any[]) => void;
}

interface IState {
  modalVisible: boolean;
  dataList: any[];
}

const { EnhancedTable } = CardTableV2;

class SlicesTable extends React.PureComponent<IProps, IState> {
  private readonly tableColumns: ColumnProps<any>[] = [];

  constructor(props: IProps) {
    super(props);

    this.state = {
      modalVisible: false,
      dataList: [],
    };
    this.tableColumns = getTableColumns({});
  }

  render () {
    const { value } = this.props;

    return (
      <>
        <EnhancedTable
          getTableData={() => {
            //
          }}
          total={get(value, 'length')}
          dataSource={value}
          columns={this.tableColumns}
          scroll={{ x: 2050 }}
          size="small"
          className="small-table-no-border"
        />
      </>
    );
  }
}

export default SlicesTable;
