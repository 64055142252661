// import * as React from 'react';
// import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';

export const filterFormConfig: FormItemConfig[] = [
  {
    label: '订单编号',
    type: 'input',
    dataKey: 'serial',
  },
];
