// tslint:disable:max-file-line-count
/**
 * 封装成品:
 * 智能选料那边，计算器里面：颗数=片数*芯片总数/芯片数量。
 * 右侧选料数据/订单信息来说：颗数=所选芯片的良品数/芯片数量
 *
 * 磨划成品:
 * 智能选料那边，计算器里面：颗数=片数*芯片总数
 * 右侧选料数据/订单信息来说：颗数=所选芯片的良品数
 */
import React, { ComponentType } from 'react';
import { isEmpty, find, isInteger, cloneDeep, ceil, floor } from 'lodash';
import { FlexModal } from '@chipcoo/hanayo/lib/flex-modal';
// import { InputNumberWithUnit } from '@chipcoo/hanayo';
import { RouteComponentProps } from 'react-router-dom';
import { selectOriginOptionData } from '@chipcoo/hanayo';
import { EnumProcessClass, EnumProductModel, EnumWarehouseType } from '@chipcoo/constant';

import './index.less';
import { connectDecorator, RootState, Actions } from 'src/store';
import { ISmartSelection } from 'src/services/net/inventoryManage';
import { DemandOrderContext } from '../components/context';
import { getDieQty } from '../util';

import MaterialTable from './MaterialTable';
// import { getFtQuantity } from './util';
import SelectedMaterialTable from './SelectedMaterialTable';
import { SelectedDataInfo } from '../NewOrderModal/CardGroupConfigs/components';

interface OwnProps {
  product: string;
  type: EnumWarehouseType;
  classes?: EnumProcessClass;
  productModel: EnumProductModel;
  visible: boolean;
  onOk: (values: Obj) => void;
  onCancel: () => void;
}
interface StateProps {
  stockList: {
    originData: any[];
    handleData: any[];
  };
}
interface DispatchProps {
  smartSelection: (params: ISmartSelection) => void;
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;

interface State {
  perWaferDieQty: number;
  dieQtyInPackage: number;
  submitTableData?: (val: any[]) => void;
  modalFullWindow: boolean;
  height: number;
  classes: EnumProcessClass;
  // 用于数据外传及左侧section的预览
  selectedData: any[];
  // 用于已选物料列表的展示
  dataSource: any[];
  getSelectedData: Function;
}

@connectDecorator(
  ({
     demandOrder: { stockList }
  }: RootState) => ({ stockList }),
  ({
     demandOrder: { smartSelection }
   }: Actions) => ({
    smartSelection
  })
)
class OrderFlexModal extends React.PureComponent<Props, State> {
  static contextType = DemandOrderContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      perWaferDieQty: 0,
      dieQtyInPackage: 0,
      submitTableData: this.submitTableData,
      modalFullWindow: true,
      height: 0,
      classes: props.classes || EnumProcessClass.assembly,
      selectedData: [],
      getSelectedData: this.getSelectedData,

      dataSource: [],
    };
  }

  componentDidMount(): void {
    this.getProductInfo();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { product } = this.props;

    if (product && product !== prevProps.product) {
      this.getProductInfo();
    }
  }

  // 获取已选物料列表的数据，并设置到state，这个数据在modal submit时会用到
  getSelectedData = (selectedData = []) => {
    this.setState({ selectedData: [...selectedData] });
  }

  getProductInfo = () => {
    const { productModel, product } = this.props;
    const cachedData = selectOriginOptionData.getCachedData();
    let optionKey: string = 'getProductByProductModel';

    const optionsData = cachedData[optionKey];
    const productInfo = find(optionsData, it => it._id === product);
    const dieQty = getDieQty({
      productInfo,
      productModel,
    });

    this.setState({
      perWaferDieQty: dieQty?.perWaferDieQty,
      dieQtyInPackage: dieQty?.dieQtyInPackage
    });
  }

  // 智能选料-物料选择完成后提交数据
  submitTableData = (payload) => {
    this.setState({ dataSource: payload });
  }

  handleSubmit = () => {
    const { onOk } = this.props;
    const { selectedData } = this.state;

    if (!isEmpty(selectedData)) {
      onOk({ selectedData });
    }
  }

  diffOnChange = (value, prevValue) => {
    let deepValue = cloneDeep(value);

    if (!isInteger(value)) {
      if (value - prevValue >= 0) {
        // 表示向上步进，对于value来说，向下取整
        deepValue = floor(value);
      } else {
        // 表示向下步进，对于value来说，向上取整
        deepValue = ceil(value);
      }
    }

    return deepValue;
  }

  handleAmountChange = (value, prevValue, onChange) => {
    const newValue = this.diffOnChange(value, prevValue);

    setTimeout(
      () => {
        onChange(newValue);
      },
      0
    );
  }

  handleWaferAmountChange = (value, prevValue, onChange) => {
    const newValue = this.diffOnChange(value, prevValue);

    setTimeout(
      () => {
        onChange(newValue);
      },
      0
    );
  }

  renderLeftSection = () => {
    const { classes } = this.props;
    const { selectedData } = this.state;

    return (
      <SelectedDataInfo
        classes={classes}
        selectedData={selectedData}
      />
    );
  }

  getTableFormRender = () => {
    const { product, type, productModel, classes } = this.props;
    const { height } = this.state;

    return (
      <MaterialTable
        product={product}
        type={type}
        productModel={productModel}
        height={height}
        classes={classes}
      />
    );
  }

  getSelectedList = () => {
    const { dataSource } = this.state;

    return (
      <SelectedMaterialTable
        dataSource={dataSource}
      />
    );
  }

  setHeight = () => {
    setTimeout(
      () => {
        const chipwingModal: any = document.querySelector('.order-material-form-modal .chipwing-modal');

        this.setState({ height: chipwingModal?.offsetHeight });
      },
      1100
    );
  }

  onRequestFullWindow = () => {
    this.setState({ modalFullWindow: true }, () => {
      this.setHeight();
    });
  }

  onRequestNormalWindow = () => {
    this.setState({ modalFullWindow: false }, () => {
      this.setHeight();
    });
  }

  render () {
    const { visible, onCancel } = this.props;
    const { modalFullWindow, dataSource } = this.state;

    return (
      <DemandOrderContext.Provider value={this.state}>
        <FlexModal
          className="order-material-form-modal"
          width={1024}
          visible={visible}
          title="选择物料"
          onOk={this.handleSubmit}
          okButtonProps={{ disabled: isEmpty(dataSource) }}
          onCancel={onCancel}
          leftSection={this.renderLeftSection()}
          fullWindow={modalFullWindow}
          onRequestFullWindow={this.onRequestFullWindow}
          onRequestNormalWindow={this.onRequestNormalWindow}
        >
          {this.getTableFormRender()}

          {this.getSelectedList()}
        </FlexModal>
      </DemandOrderContext.Provider>
    );
  }
}

export default OrderFlexModal as ComponentType<OwnProps>;
