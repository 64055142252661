import React, { memo } from 'react';
import { get } from 'lodash';
import { Popconfirm } from 'antd';
import { RepeaterRenderParams } from '@chipcoo/hanayo/lib/FormCreator';

import './style.less';

type Props = {
  collapseText: string,
  extraTitleText?: string;
  initialData: any,
  path: string,
  isPreview?: boolean,
};

export const RiskAssessmentHeader = memo(
  function(props: RepeaterRenderParams & Props) {
    const { del, index, collapseText, extraTitleText, initialData, path, isPreview } = props;
    const length = get(initialData, `${path}.length`) || 0;

    let removeEl: React.ReactNode = null;
    const confirm = () => {
      // e.stopPropagation();
      console.log('click confirm');
      del(index);
    };

    if (index >= length) {
      // const data = get(initialData, 'riskEvaluation.risks.' + index);
      // const hasData = data && Object.entries(data).some(([key, v]) => !!v);
      removeEl = (
        <span onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            title="已填写数据，是否删除"
            overlayStyle={{zIndex: 9999}}
            onConfirm={confirm}
          >
            <a className="remove-risk-btn" href="" >删除</a>
          </Popconfirm>
        </span>
      );
    }

    return (
      <>
        <div className="risk-evaluation-header">
          <span>{collapseText}{index + 1}</span>
          {extraTitleText && (<span>{extraTitleText}</span>)}
          {!isPreview && removeEl}
        </div>
      </>
    );
  });

export default RiskAssessmentHeader;
