// tslint:disable:max-file-line-count
import { omit } from 'lodash';
import { SelectOptionConf } from '@chipcoo/hanayo';
import { productApi, inventoryApi, mpwApi } from 'src/services/net';
import { NpiReviewResult, NpiProgressStatusText } from 'src/services/net/npi';
import {
  ProductStateText,
  EnumRequirementOrderStatus,
  PRODUCT_TYPE,
  EnumStockProcessReason,
  EnumStockProcessStatus,
  EnumStockAttributes,
  EnumStockInApplyStatus,
  EnumStockOutApplyStatus,
  EnumInvoiceType,
  EnumStockStatus,
  EnumStockRestrict,
  EnumMpwBusStatus,
  EnumMpwBusPreorderStatus,
  EnumNtoStatus,
  // EnumNtoType,
  EnumLotCommandStatus,
  EnumProductModel,
  EnumAbnormalityType,
  EnumAbnormalityStatus,
  EnumDemandOrderType,
  EnumAnalysisStatus,
  EnumMonitorType
} from '@chipcoo/constant';

export const packingMethodByAssembly = ['料管', '编带', '托盘', '袋装'];

export const packingMethodByDie = ['蓝膜(带Ring)', '蓝膜(不带Ring)', '蓝膜(一分四)', '蓝膜(一分九)', '盒装(挑芯)', '其他'];

export const optionsGroup: SelectOptionConf = {
  // 是否停用
  enable: { options: { true: '正常', false: '停用' } },
  // 有无
  haveOrNot: { options: { true: '有', false: '无' } },
  // 是否
  whether: { options: { true: '是', false: '否' } },
  // yes / no
  yesNo: { options: { true: 'yes', false: 'no' } },
  // 同意与否
  agreeOrNot: { options: { true: '同意', false: '不同意' } },
  // 制版类型
  maskType: { options: { fullMask: '全版(Full Mask)', MLM: '复合版(Multi-layer Mask)' } },
  // 组织成员中的角色
  orgMemberRole: { options: { MEMBER: '普通成员', ADMIN: '管理员' } },
  // 制版性质
  maskProperty: { options: { initialVersion: '首版', updateVersion: '改版' } },
  // 母版芯片，这种只拿首版的芯片
  // initialVersionDie: {
  //   handler: {
  //     getOptionData: (params) => selectFieldApi.searchByDieName({
  //       dieName: params!.value, maskProperty: 'initialVersion', package: 'NONE', page: params!.page
  //     }),
  //     handleRespData: data => data.map(item => ({ value: item.dieName })),
  //   },
  // },
  // 晶圆直径
  waferDiameter: { options: ['12"', '8"', '6"', '5"', '4"', '3"'] },
  // 工艺节点
  waferTechnology: {
    options: [
      '0.5µm', '0.35µm', '0.25µm', '0.18µm', '0.15µm', '0.13µm', '0.11µm', '95nm', '90nm',
      '65nm', '55nm', '40nm', '28nm', '22nm', '20nm', '16nm', '14nm', '10nm', '7nm', '5nm',
    ]
  },
  // 钝化层成分
  passivationComposition: {
    options: { polyimide: 'Polyimide', SiN: 'SiN', SiO2: 'SiO2', oxynitride: 'Oxynitride', other: '其他' }
  },
  // Solder Cap成分
  solderCapComposition: {
    options: {
      'SnAg(Pb-Free)': 'SnAg(Pb-Free)',
      '63Sn/37Pb(Eutectic)': '63Sn/37Pb(Eutectic)',
      'Sn(pure Tin)': 'Sn(pure Tin)',
      other: '其他'
    }
  },
  // 研磨方式
  bgMethod: {
    options: ['粗磨+精磨', '粗磨+精磨+CMP', '粗磨+Poligrind', 'GDP', 'DBG']
  },
  // 切割方式
  sawMethod: {
    options: ['金刚石刀切割', '激光开槽+金刚石刀切割', '激光隐形切割']
  },
  // 磨划包装方式
  dpPackingMethod: {
    options: ['蓝膜(带Ring)', '蓝膜(不带Ring)', '蓝膜(一分四)', '蓝膜(一分九)', '盒装(挑芯)', '其他']
  },
  getProductByProductModel: {
    handler: {
      getOptionData: params => productApi.search({
        ...omit(params, 'value'),
        customerName: params?.value,
      }),
      handleRespData: data => data?.data?.map(it => ({ value: it?._id, label: it?.customerName })),
    },
    shouldCacheOriginRespData: true,
    shouldResetCachedData: true
  },
  // 芯片名(加工前, 单项目晶圆)
  spwOriginDie: {
    handler: {
      getOptionData: (params) => productApi.search({
        ...omit(params, 'value'),
        customerName: params?.value,
        productModel: EnumProductModel.spwDie,
      }),
      handleRespData: ({data}) => data.map(item => ({ value: item._id, label: item.customerName })),
    },
    shouldCacheOriginRespData: true
  },
  dpProductList: {
    handler: {
      getOptionData: params => productApi.search({
        ...omit(params, 'value'),
        customerName: params?.value,
        productModel: EnumProductModel.dpProduct,
      }),
      handleRespData: data => data?.data?.map(it => ({ value: it?._id, label: it?.customerName })),
    },
    shouldCacheOriginRespData: true,
    shouldResetCachedData: true
  },
  // 封装成品名列表
  assemblyProductList: {
    handler: {
      getOptionData: (params) => productApi.search({
        ...omit(params, 'value'),
        customerName: params?.value,
        productModel: EnumProductModel.assemblyProduct
      }),
      handleRespData: data => data?.data?.map(it => ({ value: it?._id, label: it?.customerName })),
    },
    shouldCacheOriginRespData: true,
    shouldResetCachedData: true
  },
  // 封装形式大类
  packageType_1: {
    options: {
      '引线框架': '引线框架',
      '引线基板': '引线基板',
      '倒装': '倒装',
      '晶圆级封装': '晶圆级封装',
      '系统级封装': '系统级封装',
      other:  '其他'
    }
  },
  // 打印方式
  markingMethod: { options: ['正面打印', '背面打印', '不打印'] },
  // 产品状态
  productState: {
    options: ProductStateText,
  },
  // 载板类型
  carrierTypeFramework: { options: { '框架': '框架' } },
  carrierType: {
    options: {
      '基板(BGA)': '基板(BGA)',
      '基板(LGA)': '基板(LGA)',
      other: '其他'
    }
  },
  carrierTypeWaferLevel: { options: { '无(晶圆级封装)': '无(晶圆级封装)' } },
  // 防潮等级
  MSL: { options: ['MSL1', 'MSL2', 'MSL3', 'MSL4', 'MSL5'] },
  // 来料类型
  incomingUnitsType: { options: ['MAP晶圆', '未测晶圆', '墨点晶圆', '蓝膜芯片', '盒装芯片'] },
  // 导热要求
  thermalConductivityRequirement: { options: ['无', '有'] },
  // 环保要求
  environmentalRequirement: { options: ['GREEN', 'RoHS', '无卤', 'REACH'] },
  // 焊丝材料
  wireType: { options: ['镀钯铜线', '铜线', '铝线', '银线', '金线', '合金线'] },
  // 装片胶类型
  dieAttachMaterial: { options: ['Epoxy', 'DAF'] },
  // 装片胶电性
  electronConductivity: { options: ['导电', '绝缘'] },
  // 装片方式
  dieAttachMethod: { options: ['墨点', 'Mapping', '盲封'] },
  // 助焊剂清洗
  fluxClean: { options: ['水洗', '皂化剂'] },
  // 助焊剂类型
  fluxType: { options: ['水洗', '免洗'] },
  // 倒装芯片包封方法
  fcPackageMethod: { options: ['Mold', 'Underfill'] },
  // 晶圆材料
  waferMaterial: {
    options: { si: 'Si', siGe: 'SiGe', sic: 'SiC', gaas: 'GaAs', gan: 'GaN', other: '其他' }
  },
  // 钝化层开窗形状
  passivationOpeningShape: {
    options: {
      circular: 'Circular', octagonal: 'Octagonal', rectangular: 'Rectangular', other: '其他'
    }
  },

  // FT, 成品测试需求
  ftTestRequirement: { options: ['O/S', 'FUNCTION TEST', 'PROGRAM', '分BIN'], },
  // 装片胶
  bomDieAttachMaterial: {
    options: ['DAF膜', '导电胶', '不导电胶', '半烧结银']
  },

  // 加工需求
  processRequirement: { options: { cp: 'CP', bump: 'Bumping', package: '封装', dp: '磨划' } },
  // 温度循环测试
  TC: { options: ['TCB100x', 'TCB200x', 'TCB500x', 'TCB1000x', 'TCC100x', 'TCC200x', 'TCC500x', 'TCC1000x'] },
  // 高加速温湿度实验
  HAST: { options: ['96h', '192h'] },
  // 高温存储实验
  HTS: { options: ['125℃+1000h', '125℃+2000h', '150℃+500h', '150℃+1000h', '150℃+2000h'] },
  // bin 类型
  binType: {
    options: {
      0: '无', ...(new Array(8).fill('BIN').reduce((all, bin, i) => {
        all[i + 1] = `${bin}${i + 1}`;
        return all;
      }, {} as Obj)),
    },
  },
  // 应用领域
  application: { options: ['消费/工业', '汽车', '航空/军工', '其他'] },
  // 测试要求
  testRequirement: { options: ['不测试', 'OS测试', '功能测试'] },
  // 包装形式
  cpPackingMethod: { options: ['墨点', 'Map', '墨点+Map'] },
  assemblyPackingMethod: { options: ['管装', '盘装', '编带', '袋装'] },
  packingMethod: { options: ['管装', '盘装', '编带', '罐装', '袋装', '蓝膜', '挑芯'] },
  // 高风险工序
  highRiskProcess: {
    options: ['测试', '烧录', '研磨', '划片', '上芯', '倒装上芯', '压焊', '塑封', '填胶', '电镀', '打印', '植锡球', '成品切割'],
  },
  // 业务类型
  // businessType: { options: {
  //   [BusinessType.assembly]: '封装',
  //   [BusinessType.cp]: '晶圆测试',
  //   [BusinessType.dp]: '磨划'
  //   }
  // },
  // 客户意见
  // 客户意见/审核意见
  reviewResult: {
    options: {
      [NpiReviewResult.approve]: '同意',
      [NpiReviewResult.reject]: '不同意',
    }
  },
  customerComment: {
    options: {
      [NpiReviewResult.approve]: '同意',
      [NpiReviewResult.reject]: '不同意',
      [NpiReviewResult.takeRisk]: '承担风险',
    }
  },
  // 试生产当前状态
  currentStatus: {
    options: NpiProgressStatusText,
  },
  // 账户币种
  accountCurrency: { options: ['人民币', '美元'] },

  /* 需求订单审核 */
  requirementOrderStatus: {
    options: {
      true: '审核通过',
      false: '审核不通过',
    }
  },

  /* 需求单状态 */
  requirementStatus: {
    options: {
      [EnumRequirementOrderStatus.created]: '待审核',
      [EnumRequirementOrderStatus.accepted]: '待生产',
      [EnumRequirementOrderStatus.rejected]: '已驳回',
      [EnumRequirementOrderStatus.manufacturing]: '生产中',
      [EnumRequirementOrderStatus.delivered]: '已发货',
      [EnumRequirementOrderStatus.finished]: '已完成',
      [EnumRequirementOrderStatus.canceled]: '已取消',
      [EnumRequirementOrderStatus.stockIn]: '已入库',
    }
  },

  // 涉及到的产品类型
  productModel: {
    options: PRODUCT_TYPE,
  },

  /* 库房 */
  warehouse: {
    handler: {
      getOptionData: params => inventoryApi.searchWarehouse({ ...omit(params, 'value') }),
      handleRespData: data => data.data.map(item => ({ value: item._id, label: item.name })),
    },
  },
  /* 物料名称 */
  materialName: {
    handler: {
      getOptionData: (params) => {
        return inventoryApi.searchStockGroup({
          ...omit(params, 'value'),
        });
      },
      handleRespData: resp => resp.data.map(it => ({ value: it._id, label: it.name }))
    },
    shouldResetCachedData: true,
    shouldCacheOriginRespData: true,
  },
  // 是否为蓝膜
  attributes: {
    options: {
      [EnumStockAttributes.blueTape]: '是',
      [EnumStockAttributes.normal]: '否',
    }
  },
  // 客户申请入库订单状态
  stockInApplyStatus: {
    options: {
      [EnumStockInApplyStatus.created]: '待审核',
      [EnumStockInApplyStatus.approved]: '已通过',
      [EnumStockInApplyStatus.rejected]: '已拒绝',
    }
  },
  // 客户申请出库订单状态
  stockOutApplyStatus: {
    options: {
      [EnumStockOutApplyStatus.created]: '待审核',
      [EnumStockOutApplyStatus.approved]: '待发货',
      [EnumStockOutApplyStatus.delivery]: '已发货',
      [EnumStockOutApplyStatus.rejected]: '已拒绝',
    }
  },

  // 库存状态
  stockStatus: {
    options: {
      [EnumStockStatus.manufacture]: '在制',
      [EnumStockStatus.afloat]: '在途',
      [EnumStockStatus.returning]: '退库中',
      [EnumStockStatus.normal]: '正常',
    }
  },

  // 限制类型
  stockRestrictBy: {
    options: {
      [EnumStockRestrict.demand]: '订单锁定',
      '': '无',
    }
  },

  // 工厂搜索
  factoryList: {
    handler: {
      getOptionData: params => productApi.searchFactory({
        name: params?.value.trim(),
        // businessType: ,
        ...omit(params, 'value'),
      }),
      handleRespData: data => {
        return data.data.map(it => ({ value: it?._id, label: it?.name }));
      }
    }
  },

  // 搜索组织内的工厂
  factoryListByUser: {
    handler: {
      getOptionData: params => productApi.searchFactoryByUser({
        organization: params?.organization,
        ...omit(params, 'value'),
      }),
      handleRespData: data => {
        return data.map(it => ({ value: it?._id, label: it?.name }));
      }
    }
  },

  /*************************** 运营管理-加工单查询 **********************************/
  // 订单类型
  demandOrderType: {
    options: {
      [EnumDemandOrderType.engineering]: '工程',
      [EnumDemandOrderType.production]: '量产',
    }
  },
  stockProcessReason: {
    options: {
      [EnumStockProcessReason.demand]: '指令单',
      [EnumStockProcessReason.other]: '其他',
    }
  },

  /* 加工状态 */
  stockProcessStatus: {
    options: {
      [EnumStockProcessStatus.created]: '待加工',
      [EnumStockProcessStatus.processing]: '加工中',
      [EnumStockProcessStatus.retrieving]: '待完成',
      [EnumStockProcessStatus.finished]: '已完成',
    }
  },

  /*************************** 生产异常管理 **********************************/
  // 生产异常类型
  abnormalityType: {
    options: {
      [EnumAbnormalityType.process]: '生产异常',
      [EnumAbnormalityType.stockIn]: '来料异常',
      [EnumAbnormalityType.complaint]: '客诉异常',
    }
  },
  abnormalityStatus: {
    options: {
      [EnumAbnormalityStatus.created]: '开启',
      [EnumAbnormalityStatus.handling]: '处理中',
      [EnumAbnormalityStatus.finished]: '完结',
    }
  },

  /******************************** 组织认证 ************************************/
  /* 发票类型 */
  invoiceType: {
    options: {
      [EnumInvoiceType.valueAddSpecial]: '增值税专用发票',
      [EnumInvoiceType.valueAddRegular]: '增值税普通发票',
      [EnumInvoiceType.regular]: '普通发票'
    }
  },
  /******************************** 研发管理 ************************************/
  mpwBusStatus: {
    options: {
      [EnumMpwBusStatus.created]: '可预订',
      [EnumMpwBusStatus.paused]: '已暂停',
      [EnumMpwBusStatus.expired]: '已过期',
      [EnumMpwBusStatus.canceled]: '已取消',
    }
  },
  mpwBusPreOrderStatus: {
    options: {
      [EnumMpwBusPreorderStatus.created]: '待审核',
      [EnumMpwBusPreorderStatus.design]: '待设计',
      [EnumMpwBusPreorderStatus.submit]: '待提交',
      [EnumMpwBusPreorderStatus.submitted]: '待发车',
      [EnumMpwBusPreorderStatus.departed]: '已发车',
      [EnumMpwBusPreorderStatus.canceled]: '已取消',
      [EnumMpwBusPreorderStatus.rejected]: '已拒绝',
    }
  },
  polyMetal: {
    options: [
      '1P2M', '1P3M', '1P4M', '1P5M', '1P6M', '1P7M', '1P8M', '1P9M', '1P10M',
      '2P2M', '2P3M', '2P4M', '2P5M', '2P6M', '2P7M', '2P8M', '2P9M', '2P10M'
    ],
  },
  totalDieNum: {
    options: ['50', '100', '150', '200', '250+']
  },
  tsmcBump: {
    options: ['None', 'flipchip', 'wirebond']
  },
  // NTO
  ntoStatus: {
    options: {
      [EnumNtoStatus.audit]: '待审核',
      [EnumNtoStatus.submit]: '待提交',
      [EnumNtoStatus.command]: '待指令',
      [EnumNtoStatus.pay]: '待缴费',
      [EnumNtoStatus.paid]: '已缴费',
      [EnumNtoStatus.disabled]: '已禁用',
      [EnumNtoStatus.canceled]: '已取消'
    }
  },
  mpwFactoryList: {
    handler: {
      getOptionData: params => mpwApi.getFactoryList({
        name: params?.value.trim(),
        ...omit(params, 'value'),
      }),
      handleRespData: data => {
        return data.data.map(it => ({ value: it?._id, label: it?.name }));
      }
    },
    shouldResetCachedData: true,
    shouldCacheOriginRespData: true,
  },
  // 根据产品名获取工厂
  factoryListByProductId: {
    handler: {
      getOptionData: (params) => {
        if (params?.productId) return productApi.getProcessById(params?.productId);
        return new Promise(resolve => resolve([]));
      },
      handleRespData: data => {
        return data?.map(item => ({ key: item?.factory?._id, label: item?.factory?.name })) ?? [];
      },
    },
  },
  initialitionNtoList: {
    handler: {
      getOptionData: params => mpwApi.listNto({
        name: params?.value.trim(),
        // type: EnumNtoType.init, // 需要找到所有首版及改版的产品名
        status: [
          EnumNtoStatus.submit,
          EnumNtoStatus.command,
          EnumNtoStatus.pay,
          EnumNtoStatus.paid
        ],
        ...omit(params, 'value'),
      }),
      handleRespData: data => {
        return data.data.map(it => ({ value: it?._id, label: it?.name }));
      }
    },
    shouldResetCachedData: true,
    shouldCacheOriginRespData: true,
  },

  // 用于LOT那里搜索NTO产品，与NTO改版搜索差了一个EnumNtoStatus.submit状态
  initialitionNtoListInLot: {
    handler: {
      getOptionData: params => mpwApi.listNto({
        name: params?.value.trim(),
        status: [
          EnumNtoStatus.command,
          EnumNtoStatus.pay,
          EnumNtoStatus.paid
        ],
        ...omit(params, 'value'),
      }),
      handleRespData: data => {
        return data.data.map(it => ({ value: it?._id, label: it?.name }));
      }
    },
    shouldResetCachedData: true,
    shouldCacheOriginRespData: true,
  },

  // Lot
  lotStatus: {
    options: {
      [EnumLotCommandStatus.normal]: '正常',
      [EnumLotCommandStatus.reject]: '已打回',
      [EnumLotCommandStatus.canceled]: '已取消'
    }
  },

  // QC
  analysisStatus: {
    options: {
      [EnumAnalysisStatus.parsing]: '解析中',
      [EnumAnalysisStatus.calculating]: '计算中',
      [EnumAnalysisStatus.finished]: '分析完成',
      [EnumAnalysisStatus.errored]: '分析错误',
    }
  },

  ftpProtocol: {
    options: {
      'ftp': 'FTP',
      'ftps': 'FTPS',
    }
  },

  // 监测准则
  monitorRuleOptions: {
    options: {
      1: '准则1　有1点位于3倍标准差以外。',
      2: '准则2　连续9点落在中心线的同一侧。',
      3: '准则3　连续6点递增或递减。',
      4: '准则4　连续14点呈上下交替跳动。',
      5: '准则5　连续3点中有2点落在中心线同一侧的2倍标准差之外。',
      6: '准则6　连续5点中有4点落在中心线同一侧的1倍标准差之外。',
      7: '准则7　连续15点落在中心线两侧的1倍标准差之内。',
      8: '准则8　连续8点在中心线两侧，但无任何点落在1倍标准差之内。',
    }
  },
  // 监控规则类型
  monitorType: {
    options: {
      [EnumMonitorType.cl]: '均值-标准差监测',
      [EnumMonitorType.cpk]: 'CPK监测',
      [EnumMonitorType.failRate]: '失效率监测',
      [EnumMonitorType.site]: 'Site间良率差异监测',
      [EnumMonitorType.topFail]: 'TOP失效项变动监测',
      [EnumMonitorType.yield]: '良率监测',
    }
  }
};
