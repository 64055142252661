import * as React from 'react';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { AxiosRequestConfig } from 'axios';
import { message as antdMessage } from 'antd';
import log from 'loglevel';
import { formatDate } from '@chipcoo/fe-utils';

import styles from './index.module.less';
import { RootState, RootDispatch } from 'src/store';

import { HocUserMessage } from 'src/components';
import { HandleInvitation, HandleOrder, HandleProduct } from './Content';
import { mockUser } from '../MsgList/NotifyCard';
import { getCurrentRoute, compilePath } from 'src/components/HoneyRouter';
import { ROUTE_NAMES } from 'src/router/normalRouter';

interface OwnProps {
  notifyId: string;
}
interface StateProps {
  oneNotifyDetail: any;
}
interface DispatchProps {
  getNotifyDetail: (paylaod: WrapAxiosRequest<{notifyId: string}>) => void;
}
type P = OwnProps & StateProps & DispatchProps;
interface S {}

class ContentWrapper extends React.PureComponent<P, S> {
  componentDidMount() {
    this.getDetailData();
  }

  componentDidUpdate(prevProps: P) {
    const { notifyId } = this.props;

    if (prevProps.notifyId !== notifyId) {
      this.getDetailData();
    }
  }

  getDetailData = () => {
    const { getNotifyDetail, notifyId } = this.props;
    const config: AxiosRequestConfig = {
      handleSpecialError: {
        404: () => {
          //
          antdMessage.error('该通知不存在');
          this.routeToNotificationIndex();
        },
        403: () => {
          //
          antdMessage.error('您无权查看该通知');
          this.routeToNotificationIndex();
        },
      }
    };
    getNotifyDetail({ notifyId, config });
  }

  @bind
  routeToNotificationIndex() {
    const { history } = getCurrentRoute()!;
    history.replace(compilePath({ name: ROUTE_NAMES.NOTIFICATION }));
  }

  @bind
  renderFromUserCard(user: User, time: string, message: string) {
    return (
      <HocUserMessage
        userInfo={user}
        className="cell notification-user-card"
        messageTime={formatDate(new Date(time), 'yyyy-MM-dd hh:mm')}
      >
        <p className="message">
          {message}
        </p>
      </HocUserMessage>
    );
  }

  renderContent = () => {
    const { oneNotifyDetail } = this.props;

    if (!oneNotifyDetail) return null;

    let parsedState: any = {};
    const { category, fromUser = mockUser, time, state, message } = oneNotifyDetail;

    // 如果有state, 直接尝试解析state。
    if (state) {
      try {
        parsedState = JSON.parse(state);
      } catch (e) {
        //
        log.error(e);
        log.error(`[Notification]: 'state' is provided but it's not valid json. state:`, state);
      }
    }

    let userCard = this.renderFromUserCard(fromUser || mockUser, time, message);

    const {
      orgId,
      // 订单
      order,
      orderId,
      orderType,
      // 产品
      productId,
      productType,
    } = parsedState;

    // 此处字段可能为orderId， 也可能为order
    const orderID = orderId || order;
    if (orderID) {
      return (
        <HandleOrder messageCard={userCard} {...parsedState} orgId={orgId} orderId={orderID} orderType={orderType} />
      );
    }

    if (productId) {
      return <HandleProduct messageCard={userCard} {...parsedState} productType={productType} />;
    }

    switch (category) {
      // 各种邀请
      case 'invitation':
        return <HandleInvitation detailData={oneNotifyDetail} />;
      // 组织认证
      // case '认证':
      // case 'verify':
      //   return <HandleVerify message={oneNotifyDetail.message} />;
      // 工作区
      case 'workspace':
        userCard = (
          <HocUserMessage
            userInfo={fromUser}
            messageTime={formatDate(new Date(time), 'yyyy-MM-dd hh:mm')}
          >
            <p className="message">
              {oneNotifyDetail.message}
            </p>
          </HocUserMessage>
        );
        break;
      default:
    }

    return (
      <div className="notify-detail-content">
        <header className="noti-header flex align-center">
          {userCard}
        </header>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.msgContentWrapper}>
        {this.renderContent()}
      </div>
    );
  }
}

const mapState = ({ notification }: RootState) => ({
  oneNotifyDetail: notification.oneNotifyDetail
});
const mapDispatch = ({ notification }: RootDispatch) => ({
  getNotifyDetail: notification.getNotifyDetail,
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(ContentWrapper);
