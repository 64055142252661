import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { __RouterContext } from 'react-router';
import { RematchStore } from '@rematch/core';

import HookRoute, { getPreviousRoute, getCurrentRoute, getCurrentMatched } from './HookRoute';
import { HoneyRouteProps } from './interface';

export interface AsyncDataArg<Params extends any = { [key: string]: any }> {
  store: RematchStore;
  // @ts-ignore
  route: RouteComponentProps<Params> & {
    path: string;
  };
  [key: string]: any;
}

import HoneyRouteComponent, {
  routes,
  compilePath,
  getMatchedRoute,
  registerRoute,
  getRoute,
  getRouteByName,
  getRouteByPath,
} from './components/HoneyRouteComponent';

export {
  getPreviousRoute,
  getCurrentRoute,
  getCurrentMatched,
};

export * from './components/HoneyRouteComponent';

const __HoneyRouteComponent = HoneyRouteComponent; // tslint:disable-line

export {
  __HoneyRouteComponent,
};

const { Consumer } = __RouterContext;

export class HoneyRoute extends React.Component<HoneyRouteProps> {
  static get routes() {
    return routes.slice();
  }

  static compilePath = compilePath;
  static getMatchedRoute = getMatchedRoute;
  static registerRoute = registerRoute;
  static getRoute = getRoute;
  static getRouteByName = getRouteByName;
  static getRouteByPath = getRouteByPath;
  static HookRoute = HookRoute;
  static getPreviousRoute = getPreviousRoute;
  static getCurrentRoute = getCurrentRoute;
  static getCurrentMatched = getCurrentMatched;

  refComponent: React.RefObject<HoneyRouteComponent>;

  render() {
    return (
      <Consumer>
        {(context) => <HoneyRouteComponent ref={this.refComponent} {...context} {...this.props}/>}
      </Consumer>
    );
  }
}

export default HoneyRoute;
