/**
 * 磨划芯片slices表格
 */
import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { InputNumber } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { unSortWaferId, sortWaferId, slices2Number } from '@chipcoo/fe-utils';

import { ModalType } from '../index';
import { TableForm } from 'src/components';
import ChoiceStockInWaferIds from './ChoiceStockInWaferIds';

const pieceTableFormConfig = (currentProps) => ({
  waferId: {
    title: '入库片号',
    addonBefore: '#',
    editableRender: (props, tableData) => {
      const commonProps = {
        ...props,
        ...currentProps,
      };

      return (
        <ChoiceStockInWaferIds {...commonProps}/>
      );
    },
    width: 450,
  },
  good: { title: '良品',
    editableRender: (props) => (
      <InputNumber style={{ width: '100%' }} autoComplete="off" placeholder="良品" {...props}/>
    )},
  bad: {
    title: '不良品',
    editableRender: (props) => (
      <InputNumber style={{ width: '100%' }} autoComplete="off" placeholder="不良品" {...props}/>
    )},
  amount: {
    title: '单片总数量',
    editableRender: (props) => (
      <InputNumber style={{ width: '100%' }} autoComplete="off" placeholder="单片总数量" {...props}/>
    ),
    render: (text, record) => {
      const { good, bad } = record;
      const goodCount = parseInt(good, 10) || 0;
      const badCount = parseInt(bad, 10) || 0;
      const amount = goodCount + badCount;

      return <span>{amount}</span>;
    }
  },
});

interface IProps extends Pick<WrappedFormUtils, 'resetFields'> {
  status: ModalType;
  dieNumber: string;
  value?: any;
  onChange?: (value: any) => void;
  setFieldsValue?: (obj: Obj) => void;
  shouldSetFields: boolean;
}

interface IState {
  disabledOptions: number[];
}

const DpDieSlices: FC<IProps> = props => {
  const { status, value, setFieldsValue, onChange, resetFields, dieNumber, shouldSetFields } = props;
  const [state, setState] = useState<IState>({
    disabledOptions: [],
  });
  const { disabledOptions } = state;

  const handleSetFields = (values) => {
    let newValue: any[] = [];

    if (values) {

      newValue = values.map(item => {
        const { waferId } = item;

        return {
          ...item,
          waferId: waferId ? unSortWaferId(waferId) : -1,
        };
      });

      setState({
        disabledOptions: _.flattenDeep(newValue.map(it => it.waferId).filter(it => it !== -1)),
      });

      const { available, unavailable, amount, waferIds } = slices2Number(newValue);
      const newWaferIds = waferIds?.split(',').map(it => unSortWaferId(it));
      const waferAmount = _.get(_.flattenDeep(newWaferIds), 'length');

      if (status !== 'new' && shouldSetFields) {
        setFieldsValue && setFieldsValue({
          available, unavailable, amount, waferAmount, waferIds: sortWaferId(_.flattenDeep(newWaferIds))
        });
      }
    }
  };

  useEffect(() => {
    handleSetFields(value);
  }, [value]);

  const handleChange = (val) => {
    onChange && onChange(val);

    handleSetFields(val);
  };

  const currentProps = {
    status,
    dieNumber,
    resetFields,
    currentDisabledOptions: disabledOptions,
  };

  const readOnly = status === 'preview';
  const tableFormConfig = pieceTableFormConfig(currentProps);

  return (
    <TableForm
      onlyRead={readOnly}
      columnsConfig={tableFormConfig}
      value={value}
      onChange={handleChange}
      showAddBtn={true}
      isSetDisabledByAdd={false}
      size="small"
      isInFlexModal={true}
    />
  );
};

export default DpDieSlices;
