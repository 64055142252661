import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import { fastOrgPath } from '../normalRouter';
import { getCurrentRoute } from 'src/components/HoneyRouter';
// import { createException } from 'src/layouts/ErrorPage';
import urlConfig from 'src/config/url';

import { Exception } from 'src/components';

interface Props extends RouteComponentProps {}
interface State {}

export class Fallback extends PureComponent<Props, State> {
  render() {
    const { location, history } = this.props;
    if (location.pathname === '/') {
      history.push(fastOrgPath());
      return null;
    }

    const route = getCurrentRoute();

    if (route!.match.url !== '/') {
      return null;
    }

    return (
      <Exception
        title="404"
        desc="您访问的页面不存在"
        backText="返回首页"
        redirect={urlConfig.baseUrl || '/'}
      />
    );
  }
}

export default Fallback;
