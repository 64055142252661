/**
 * Created by marvin on 18/8/17.
 */

import * as React from 'react';
import { Tabs } from 'antd';
import { findIndex } from 'lodash';
import { connect } from 'react-redux';
import { bind } from 'lodash-decorators';
import { RouteComponentProps } from 'react-router-dom';

import { RootState } from 'src/store';
import styles from './index.module.less';
import { IOrgDetail } from 'src/models/org/interface';

import { getRouterProps, compilePath } from 'src/components/HoneyRouter';

import OrgInfo from './OrgInfo';
import Members from './Members/New';
// import { Text } from './Members/Test';
import Certification from './Certification';
import Advance from './Advance';
import { ROUTE_NAMES } from 'src/router/normalRouter';

interface OwnProps {}
interface StateProps {
  orgListById: { [id: string]: IOrgDetail };
}
interface DispatchProps {
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>;
interface State {
  orgId: string;
}

interface TabRouteItem {
  tab: string;
  component: React.ComponentType<any>;
  routeName: string;
}

const TabPane = Tabs.TabPane;
let tabList: TabRouteItem[] = [];

function setTabList() {
  tabList = [
    {
      tab: '组织信息',
      component: OrgInfo,
      routeName: ROUTE_NAMES.ORG_SETTINGS_BASIC
    },
    {
      tab: '组织成员',
      component: Members,
      routeName: ROUTE_NAMES.ORG_SETTINGS_MEMBER,
    },
    // {
    //   tab: '组织成员(测试)',
    //   component: MembersTest,
    //   routeName: ROUTE_NAMES.ORG_SETTINGS_MEMBER_TEST,
    // },
    {
      tab: '组织认证',
      component: Certification,
      routeName: ROUTE_NAMES.ORG_SETTINGS_VERIFY,
    },
    {
      tab: '高级设置',
      component: Advance,
      routeName: ROUTE_NAMES.ORG_SETTINGS_ADV,
    },
  ];
}

setTimeout(setTabList);

class OrgSetting extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    if (tabList.length === 0) setTabList();

    const orgId = props.match.params.id;

    this.state = {
      orgId: orgId
    };
  }

  // TODO 跟随路由前进/后退切换。
  @bind
  renderTabPane(item: TabRouteItem) {
    const { orgId } = this.state;
    const routerProps = getRouterProps(this.props);
    const { component: Component, tab, routeName } = item;
    const path = compilePath({
      name: routeName,
      params: routerProps.match.params
    });
    return (
      <TabPane tab={tab} key={path}>
        <Component {...routerProps} orgId={orgId} />
      </TabPane>
    );
  }

  render () {
    const { orgId } = this.state;
    const org = this.props.orgListById[orgId];
    const role = org && org.role;
    const { location: { pathname }, history } = this.props;
    const list = tabList.slice();

    if (role !== 'OWNER') {
      const idx = findIndex(list, item => item.routeName === ROUTE_NAMES.ORG_SETTINGS_VERIFY);
      list.splice(idx, 1);
    }

    return (
      <Tabs
        className={styles.orgSettingTabs}
        tabBarGutter={0}
        defaultActiveKey={pathname}
        onChange={key => history.push(key)}
      >
        {list.map(this.renderTabPane)}
      </Tabs>
    );
  }
}

const mapState = ({ org: { orgList: { byId } } }: RootState) => ({
  orgListById: byId
});

export default connect<StateProps, DispatchProps, OwnProps>(mapState)(OrgSetting);
