import * as React from 'react';
import { IEditableColumnProps } from 'src/containers/TableColumnEditor';

interface Option {
  openModal: (params: any) => void;
}

export const getTableColumns = (option: Option): IEditableColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: '物料名称',
      width: 200,
    },
    {
      dataIndex: 'product',
      key: 'product',
      title: '产品名称',
      width: 200
    },
    {
      dataIndex: 'productModelText',
      key: 'productModelText',
      title: '产品类型',
      width: 100
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      // width: 200
    },
    {
      dataIndex: 'available',
      key: 'available',
      title: '可用库存',
      width: 100
    },
    {
      dataIndex: 'unavailable',
      key: 'unavailable',
      title: '不可用库存',
      width: 120
    },
    // {
    //   dataIndex: 'freeze',
    //   key: 'freeze',
    //   title: '冻结数量',
    //   width: 100
    // },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总库存',
      width: 100,
    },
    {
      dataIndex: 'waferAmount',
      key: 'waferAmount',
      title: '片数',
      width: 100,
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      width: 110,
      fixed: 'right' as 'right',
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record._id);
        };

        return (
          <a onClick={openModal}>
            查看
          </a>
        );
      }
    },
  ];
};
