import _ from 'lodash';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import moment from 'moment';
import qs from 'qs';

import {
  EnumWarehouseType,
  EnumStockInStatus,
} from '@chipcoo/constant';
import { IOrgDetail } from 'src/models/org/interface';
import { messageModal } from 'src/utils/messageModal';
import { getOrgIdByUrl } from 'src/utils';

// 库房搜索参数
export interface IWareHouseListParams {
  page?: number;
  limit?: number;
  name?: string;
  ids?: string[];
  type?: EnumWarehouseType;
}

// 仓库类型
export interface IWareHouse {
  name: string;
  address: string;
  description: string;
  phone: string;
  type: EnumWarehouseType;
  _id?: string;
  key?: string;
  isEnabled?: boolean;
}

// 入库记录data
export interface ISlices {
  waferId: number;
  good: number;
  bad: number;
  amount: number;
  isFrozen?: boolean;
}
export interface IStockIn {
  _id?: string;
  createdAt?: string;
  serial?: string;
  status?: EnumStockInStatus;
  product: string | any;
  material: string | any;
  productModel?: string;
  warehouse: string | any;
  reason: string;
  reasonId?: string | any;
  logisticsNumber: string;
  materialName?: string;
  remark?: string;
  organization?: IOrgDetail;
  customerName?: Obj;
  stock: {
    materialNumber?: string;
    dieNumber: string;
    stampNumber: string;
    markMode: string;
    packingMethod: string;
    slices?: ISlices;
    bins?: number[];
  } | any;
  stockStash?: string;
}

// 智能选料参数
export type ISmartSelection = {
  product: string;
  waferCount: number;
  isBlueTapeFirst: boolean;
  warehouseType: EnumWarehouseType;
};

// 库存枚举enum
export enum EnumStockDistinctField {
  name = 'name',
  product = 'product',
  productModel = 'productModel',
  packingMethod = 'packingMethod',
  warehouse = 'warehouse',
}

export default function(api: AxiosInstance) {
  return {
    /****************************************** 仓库 ********************************************/
    // 获取仓库列表
    searchWarehouse(params: IWareHouseListParams, config: AxiosRequestConfig = {}) {
      return api.get('product/warehouse/search', {
        ...config, params
      });
    },
    // 新建仓库
    addWarehouse(data: IWareHouse, config: AxiosRequestConfig = {}) {
      return api.post('product/warehouse', data, config);
    },
    // 获取仓库详情
    detailWarehouse(_id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/warehouse/${_id}`, { ...config });
    },
    // 修改仓库信息
    modifyWarehouse(val: IWareHouse, config: AxiosRequestConfig = {}) {
      return api.patch(`product/warehouse/${val._id}`, val, config);
    },
    // 禁用仓库
    deleteWarehouse(id: string, config: AxiosRequestConfig = {}) {
      return api.delete(`product/warehouse/${id}`, config);
    },

    /*************************** 物料库存 **********************************/
    searchStock(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/search', { ...config, params: { ...params } });
    },
    searchStockGroup(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/group/material', { ...config, params: { ...params } });
    },
    getStockById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock/material/${id}`,  { ...config, params: {  } });
    },
    getStockByName(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/list-name', { ...config, params: { ...params } });
    },
    // 冻结片号或bin
    patchWaferOrBin(data: any, config: AxiosRequestConfig = {}) {
      return api.patch(`product/stock/${data.id}/freeze`, _.omit(data, 'id'), config);
    },
    listByCraftRoute(id: string, config?: AxiosRequestConfig) {
      return api.get(`product/stock/stat/craft-route/${id}`, config);
    },
    // 根据stock ids获取库存
    getStockByIds(ids: string[], config?: AxiosRequestConfig) {
      return api.get('product/stock', { ...config, params: { ids } });
    },

    /*************************** 入库记录 **********************************/
    // 入库详情列表
    getStockInList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-in/search', { ...config, params });
    },
    // 新建入库单
    postStockIn(data: IStockIn, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-in', data, config);
    },
    // 入库记录详情
    getStockInById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-in/${id}`, config);
    },

    /*************************** 出库记录 **********************************/
    // 出库记录列表
    getStockOutList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-out/search', { ...config, params });
    },
    // 新建出库单
    postStockOut(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-out', data, config);
    },
    // 出库记录详情
    getStockOutById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-out/${id}`, config);
    },

    /*************************** 加工记录 **********************************/
    // 加工记录列表
    getStockProcessList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-process/search', { ...config, params });
    },
    // 新建加工单
    postStockProcess(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-process', data, config);
    },
    // 获取加工记录
    getStockProcessById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-process/${id}`, config);
    },
    // 获取wip
    getWip(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-process/${id}/wip`, config);
    },

    /*************************** 调拨记录 **********************************/
    // 调拨记录列表
    getStockTransferList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-transfer/search', { ...config, params });
    },
    // 新建调拨单
    postStockTransfer(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-transfer', data, config);
    },
    // 获取调拨记录
    getStockTransferById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-transfer/${id}`, config);
    },
    // 关联出库单
    postTransfer2StockOut(data: any, config: AxiosRequestConfig = {}) {
      return api.post(`product/stock-transfer/${data.id}/stock-out`, { stockOut: data.stockOut }, config);
    },
    // 关联入库单
    postTransfer2StockIn(data: any, config: AxiosRequestConfig = {}) {
      return api.post(`product/stock-transfer/${data.id}/stock-in`, { stockIn: data.stockIn }, config);
    },

    /*************************** 物流管理申请列表查询 ***************************** */
    // 入库申请列表
    getStockInApply(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-in-apply/search', {...config, params});
    },
    // 新建入库申请
    postStockInApply(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-in-apply', data, {
        ...config,
        handleSpecialError: {
          409: e => {
            const msg = e?.data?.message;

            message.error(msg);
          }
        }
      });
    },
    // 编辑入库申请
    editStockInApply(data: any, config: AxiosRequestConfig = {}) {
      return api.patch(`product/stock-in-apply/${data.id}`, _.omit(data, 'id'), {
        ...config,
        handleSpecialError: {
          409: (e) => { messageModal({ content: e?.data?.message }); },
        }
      });
    },
    // 获取入库申请详情
    getStockInApplyById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-in-apply/${id}`, config);
    },
    // 导出入库申请
    exportStockInApply(params: any, config: AxiosRequestConfig = {}) {
      const { productModel, material, product, dieNumber, stampNumber, warehouse } = params;
      let filterParams = '';
      if (material) {
        filterParams += `&material=${material}`;
      }
      if (product) {
        filterParams += `&product=${product}`;
      }
      if (dieNumber) {
        filterParams += `&dieNumber=${dieNumber}`;
      }
      if (stampNumber) {
        filterParams += `&stampNumber=${stampNumber}`;
      }
      if (warehouse) {
        filterParams += `&warehouse=${warehouse}`;
      }
      const orgId = getOrgIdByUrl();
      return (
        `/api/product/stock-in-apply/export?productModel=${productModel}&orgId=${orgId}${filterParams}`
      );
    },
    // 入库申请通过芯片批号搜索已存在的片号
    getExistWaferIds(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/used-wafer-ids', { ...config, params });
    },
    // 批量创建入库申请
    postStockInApplyBulk(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-in-apply/bulk', data, {
        ...config,
        handleSpecialError: {
          409: (e) => { message.error(e?.data?.message); },
        }
      });
    },
    // 解析测试MAP文件
    postReadArchice(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-in-apply/read-archive/cp-die', data, config);
    },
    // 出库申请列表
    getStockOutApply(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-out-apply/search', {...config, params});
    },
    // 新建出库申请
    postStockOutApply(data: any, config: AxiosRequestConfig = {}) {
      return api.post('product/stock-out-apply', data, {
        ...config,
        handleSpecialError: {
          409: () => { message.error('请选择产品名称、物料名称、库房、包装方式/打点方式、芯片批号均相同的产品出库'); },
          412: () => { message.error('选中的库存有未被订单表记过的'); },
        }
      });
    },
    // 获取出库申请详情
    getStockOutApplyById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock-out-apply/${id}`, config);
    },
    // 导出出库申请
    exportStockOutApply(params: any, config: AxiosRequestConfig = {}) {
      const { productModel, material, product, dieNumber, stampNumber, warehouse, createdAtAfter, status } = params;
      let filterParams = '';

      if (productModel) {
        filterParams += `&productModel=${productModel}`;
      }
      if (material) {
        filterParams += `&material=${material}`;
      }
      if (product) {
        filterParams += `&product=${product}`;
      }
      if (dieNumber) {
        filterParams += `&dieNumber=${dieNumber}`;
      }
      if (stampNumber) {
        filterParams += `&stampNumber=${stampNumber}`;
      }
      if (warehouse) {
        filterParams += `&warehouse=${warehouse}`;
      }
      if (createdAtAfter) {
        filterParams += `&createdAtAfter=${moment(createdAtAfter)}`;
      }
      if (status) {
        filterParams += `&${qs.stringify({status}, {arrayFormat: 'brackets'})}`;
      }
      const orgId = getOrgIdByUrl();
      return (
        `/api/product/stock-out-apply/export?orgId=${orgId}${filterParams}`
      );
    },

    // 列出可出库列表
    getCanStockOutList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-out-apply/selection', {...config, params});
    },

    /*************************** 智能下单 **********************************/
    // 智能选料-可选物料
    selectionStock(params: any, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock/product/${params?._id}/selection`, { ...config, params });
    },
    // 智能选料
    smartSelection(params: ISmartSelection, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/smart-selection', {
        ...config,
        params,
        handleSpecialError: {
          404: (e) => { messageModal({ content: e?.data?.message }); },
          406: () => { message.error('该产品有多条工艺路线，无法智能选料，需手动选择物料'); },
        }
      });
    },
    // 获取枚举
    stockDistinct(field: EnumStockDistinctField, config: AxiosRequestConfig = {}) {
      return api.get('product/stock/distinct', { ...config, params: { field } });
    },
    productStockSelectById(id: string, config: AxiosRequestConfig = {}) {
      return api.get(`product/stock/product/${id}`, config);
    },

    // 流片出库记录列表
    getShipmentList(params: any, config: AxiosRequestConfig = {}) {
      return api.get('product/stock-out-engineer/search', { ...config, params });
    }
  };
// tslint:disable-next-line: max-file-line-count
}
