import { getRegionText } from '@chipcoo/fe-utils';

export const getAddressByCode = (regionData, regionCode) => {
  const [pCode, cCode, dCode] = regionCode;
  const province = regionData?.['86']?.[pCode];
  const city = regionData?.[pCode]?.[cCode];
  const distinct = regionData?.[cCode]?.[dCode];

  const regionText = getRegionText({ province, city, distinct });

  return regionText;
};
