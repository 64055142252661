import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { EnumCommandType, EnumProductModel, EnumStockOutReason } from '@chipcoo/constant';
import {
  FormConfig,
  FormStatus,
  FormItemConfig,
  RepeaterConfig,
  RepeaterRenderParams,
  InputFormItemConfig,
  SelectFormItemConfig,
  // SearchFormItemConfig,
  TextAreaFormItemConfig,
  DateFormItemConfig,
  CustomFormItemConfig,
  CollapseFormItemProps,
} from '@chipcoo/hanayo/lib/FormCreator';
import { slices2Number, bins2Number } from '@chipcoo/fe-utils';

// import {
//   RepeaterCardTitle,
//   RepeaterDeleter
// } from 'src/pages/ProductManage/components/ProductModal/FormItemComponent/commonFormItemComponent';
import { RepeaterCardTitle } from 'src/components';

import DieName from './components/DieName';
import StockNumberTable from './components/StockNumberTable';
import SelectProductByModel from './components/SelectProductByModel';
import PackingMethodByProduct from './components/PackingMethodByProduct';
// import OutputDieNumber from 'src/pages/OrderManage/components/OutputDieNumber';

// const getPaths = (index: number) => ({
//   available: `inputs[${index}].available`,
//   unavailable: `inputs[${index}].unavailable`,
//   freeze: `inputs[${index}].freeze`,
//   amount: `inputs[${index}].amount`,
//   waferAmount: `inputs[${index}].waferAmount`
// });
type Options = {
  recordType: 'out' | 'process' | 'transfer';
  path: string;
  status?: FormStatus;
  isOrder: boolean;
};
export const stockNumberPanel = (options: Options): CollapseFormItemProps => ({
  type: 'collapse',
  renderType() {
    if (this.formData.reason === EnumStockOutReason.transfer || this.formData.reason === EnumStockOutReason.process) {
      return 'preview';
    }

    if (_.get(this.formData, `${options.path}.bins`) || _.get(this.formData, `${options.path}.waferIds`)) {
      return 'normal';
    }

    return 'none';
  },
  props: {
    defaultActiveKey: ['stock_number_table'],
  },
  panelProps: {
    header: '数量详情',
    forceRender: true,
    key: 'stock_number_table',
  },
  groupItemConfig: [
    {
      type: 'custom',
      colSpan: 24,
      dataKey: options.path ? `${options.path}.stockNumber` : 'stockNumber',
      render() {
        const { status, path, recordType, isOrder } = options;
        const { formData, dataKeyPrefix, initialFormData } = this;
        let formStatus = this.formStatus;
        let productModelPath = 'productModel';
        let isCanEdit = true;
        const stockNumber = _.get(initialFormData, `${dataKeyPrefix}.stockNumber`) ||
          _.get(this.initialFormData, `${options.path}.stockNumber`);

        if (status) {
          formStatus = status;
        }

        if (recordType === 'out') {
          // 调拨或加工出库
          if (
            formData?.reason === EnumStockOutReason.process ||
            formData?.reason === EnumStockOutReason.transfer ||
            formData?.productModel !== EnumProductModel.assemblyProduct
          ) {
            isCanEdit = false;
          }
        }

        if (recordType === 'transfer') {
          if (formData?.productModel !== EnumProductModel.assemblyProduct) {
            isCanEdit = false;
          }
        }

        if (recordType === 'process') {
          productModelPath = `${dataKeyPrefix}.productModel`;
        }

        if (isOrder) {
          productModelPath = `${path}.productModel`;
          const model = _.get(formData, productModelPath);

          if (model === EnumProductModel.assemblyProduct) {
            isCanEdit = true;
          }

          // 对指令单做判断
          if (recordType === 'out') {
            if (model !== EnumProductModel.assemblyProduct) {
              isCanEdit = false;
            }
          }

          if (recordType === 'transfer') {
            if (model !== EnumProductModel.assemblyProduct) {
              isCanEdit = false;
            }
          }
        }

        if (stockNumber) {
          isCanEdit = false;
        }

        return (
          <StockNumberTable
            isCanEdit={isCanEdit}
            formStatus={formStatus}
            productModel={_.get(formData, productModelPath)}
            waferIds={_.get(formData, `${path}.waferIds`)}
            bins={_.get(formData, `${path}.bins`)}
            originMaterialNumber={_.get(formData, `${path}.originMaterialNumber`)}
          />
        );
      }
    }
  ],
});

type CountParams = {
  label: string;
  dataKey: string;
  recordType?: string;
  commandType?: EnumCommandType;
};
export const getInputCount = (options: CountParams): CustomFormItemConfig => ({
  label: options.label,
  type: 'custom',
  dataKey: options.dataKey,
  render() {
    const { formData, dataKeyPrefix } = this;
    const { dataKey: optionsDataKey, recordType, commandType } = options;
    let count = 0;
    let dataKey = optionsDataKey;
    let modelPath = dataKeyPrefix ? `${dataKeyPrefix}.productModel` : 'productModel';
    let stockNumberPath = dataKeyPrefix ? `${dataKeyPrefix}.stockNumber` : 'stockNumber';

    if (recordType === 'stockOut' || recordType === 'stockTransfer') {
      stockNumberPath = 'target.stockNumber';
    }

    if (commandType === EnumCommandType.out) {
      // 出库指令，产品类型直接用计划的产品类型
      stockNumberPath = dataKeyPrefix ? `${dataKeyPrefix}.out.stockNumber` : 'out.stockNumber';
      modelPath = dataKeyPrefix ? `${dataKeyPrefix}.out.productModel` : 'out.productModel';
      // dataKey 是out.xxx形式
      dataKey = dataKey.split('out.')[1];
    }

    if (commandType === EnumCommandType.transfer) {
      stockNumberPath = dataKeyPrefix ? `${dataKeyPrefix}.transfer.stockNumber` : 'transfer.stockNumber';
      modelPath = dataKeyPrefix ? `${dataKeyPrefix}.transfer.productModel` : 'transfer.productModel';
      // dataKey 是transfer.xxx形式
      dataKey = dataKey.split('transfer.')[1];
    }

    if (commandType === EnumCommandType.process) {
      //
    }

    const inputProductModel = _.get(formData, modelPath);
    const stockNumber = _.get(formData, stockNumberPath);

    if (stockNumber) {
      const numberObject = inputProductModel === EnumProductModel.assemblyProduct
        ? bins2Number(stockNumber)
        : slices2Number(stockNumber);

      count = numberObject[dataKey];
    }

    return <span>{count}</span>;
  }
});

// 加工单号
const getSerial = (): InputFormItemConfig => ({
  label: '加工单号',
  type: 'input',
  dataKey: 'serial',
  renderType: 'preview'
});

// 加工类型
const manufactureType: InputFormItemConfig = {
  label: '加工类型',
  type: 'input',
  dataKey: 'manufactureType',
  renderType: 'preview'
};

// 加工操作
const manufactureOperation: InputFormItemConfig = {
  label: '加工操作',
  type: 'input',
  dataKey: 'manufactureOperation',
  renderType: 'preview'
};

// 加工依据
const reason = (): SelectFormItemConfig => ({
  label: '加工依据',
  type: 'select',
  dataKey: 'reason',
  optionKey: 'stockProcessReason',
  renderType: 'preview'
});

// 加工依据单号
const reasonSerial: InputFormItemConfig = {
  label: '加工依据单号',
  type: 'input',
  dataKey: 'reasonSerial',
  renderType: 'preview'
};

// 加工状态
const processStatus: SelectFormItemConfig = {
  label: '加工状态',
  type: 'select',
  dataKey: 'status',
  optionKey: 'stockProcessStatus',
  renderType: (formData, globalStatus) => globalStatus === 'preview' ? 'preview' : 'none'
};

// 要求交期
const demandTime: DateFormItemConfig = {
  label: '要求交期',
  type: 'date',
  dataKey: 'demandTime',
  props: {
    disabledDate: current => {
      // antd升级至^3.12.3时，current类型定义显示该值可能为undefined
      if (!current) return true;

      return current.valueOf() < moment().add(-1, 'day').endOf('day').valueOf();
    }
  },
};

// 加工完成时间
const finishTime: InputFormItemConfig = {
  label: '加工完成时间',
  type: 'input',
  dataKey: 'finishTime',
};

const expectedTime: DateFormItemConfig = {
  label: '预计交期',
  type: 'date',
  dataKey: 'expectedTime',
  renderType() {
    const { formStatus } = this;
    return formStatus === 'preview' ? 'preview' : 'none';
  }
};

// 备注信息
const remark: TextAreaFormItemConfig = {
  label: '备注信息',
  type: 'textArea',
  dataKey: 'remark',
  colSpan: 24,
};

// 加工前产品类型
const productModel: SelectFormItemConfig = {
  label: '加工前产品类型',
  type: 'select',
  dataKey: 'productModel',
  optionKey: 'productModel',
};

// 加工前产品名称
const getProduct = (index: number): CustomFormItemConfig => ({
  label: '加工前产品名称',
  type: 'custom',
  dataKey: 'product',
  render: (formData, globalStatus, form) => (
    <SelectProductByModel
      productModel={_.get(formData, `inputs.${index}.productModel`)}
    />
  )
});

// 加工前物料名称
const getMaterialName = (): InputFormItemConfig => ({
  label: '加工前物料名称',
  type: 'input',
  dataKey: 'material',
});

// 加工前物料批号
const getMaterialNumber = (): InputFormItemConfig => ({
  label: '加工前物料批号',
  type: 'input',
  dataKey: 'materialNumber',
});

// 原始物料批号
const originMaterialNumber = (): InputFormItemConfig => ({
  label: '原始物料批号',
  type: 'input',
  dataKey: 'originMaterialNumber',
});

// 片号
const waferId = (index: number): InputFormItemConfig => ({
  label: '片号',
  type: 'input',
  dataKey: 'waferIds',
  renderType: (formData) => {
    if (
      _.get(formData, `inputs.${index}.productModel`) !== EnumProductModel.assemblyProduct &&
      _.get(formData, `inputs.${index}.originMaterialNumber`)
    ) {
      return 'normal';
    }

    return 'none';
  },
  render() {
    const { formData, dataKeyPrefix } = this;
    const text = _.get(formData, `${dataKeyPrefix}.waferIds`);

    return <span>{text ? '#' + text : '--'}</span>;
  }
});

// Bin类型
const binType = (index: number): InputFormItemConfig => ({
  label: 'Bin类型',
  type: 'input',
  dataKey: 'bins',
  colSpan: 16,
  renderType: (formData) => {
    if (
      _.get(formData, `inputs.${index}.productModel`) === EnumProductModel.assemblyProduct &&
      _.get(formData, `inputs.${index}.originMaterialNumber`)
    ) {
      return 'normal';
    }

    return 'none';
  },
});

// 芯片批号
const dieNumber: InputFormItemConfig = {
  label: '芯片批号',
  type: 'input',
  dataKey: 'dieNumber',
  renderType: 'preview',
};

// 印章批号
const stampNumber: InputFormItemConfig = {
  label: '印章批号',
  type: 'input',
  dataKey: 'stampNumber',
  renderType: 'preview',
};

// 打点方式
const markMode = (path?: string): SelectFormItemConfig => ({
  label: '打点方式',
  type: 'select',
  dataKey: path ? `${path}.markMode` : 'markMode',
  optionKey: 'cpPackingMethod',
  // inputs预览无需必填，output分情况必填
  renderType: () => path === 'output' ? 'normal' : 'preview'
});

// 包装方式
type IPackingMethod = {
  path?: string;
  index?: number;
};
const packingMethod = (params: IPackingMethod): CustomFormItemConfig => ({
  label: '包装方式',
  type: 'custom',
  dataKey: params.path ? `${params.path}.packingMethod` : 'packingMethod',
  // optionKey: 'packingMethod',
  renderType: () => params.path === 'output' ? 'normal' : 'preview',
  render: (formData, globalStatus, form) => {
    const path = params.path
      ? `${params.path}`
      : `inputs[${params.index}`;

    return (
      <PackingMethodByProduct
        status={globalStatus}
        product={_.get(formData, `${path}.product`)}
      />
    );
  }
});

// 芯片名称
const getDieName = (index: number): CustomFormItemConfig => ({
  label: '芯片名称',
  type: 'custom',
  dataKey: 'dieName',
  renderType: (formData) => {
    if (
      _.get(formData, `inputs[${index}].productModel`) &&
      _.get(formData, `inputs[${index}].productModel`) !== EnumProductModel.spwDie &&
      _.get(formData, `inputs[${index}].product`)
    ) {
      return 'preview';
    }

    return 'none';
  },
  render(formData: any) {
    return (
      <DieName
        productModel={_.get(formData, `inputs[${index}].productModel`)}
        product={_.get(formData, `inputs[${index}].product.key`)}
      />
    );
  }
});

// 良品数量
const outputAvailable = (): InputFormItemConfig => ({
  label: '良品数量',
  type: 'input',
  dataKey: 'output.available',
  renderType: (formData, initialData, globalStatus) => {
    return globalStatus === 'preview' ? 'preview' : 'none';
  }
});

// 不良品数量
const outputUnavailable = (): InputFormItemConfig => ({
  label: '不良品数量',
  type: 'input',
  dataKey: 'output.unavailable',
  renderType: (formData, initialData, globalStatus) => {
    return globalStatus === 'preview' ? 'preview' : 'none';
  }
});

// 总数量
const outputAmount = (): InputFormItemConfig => ({
  label: '加工后总数',
  type: 'input',
  dataKey: 'output.amount',
  renderType: (formData, initialData, globalStatus) => {
    return globalStatus === 'preview' ? 'preview' : 'none';
  }
});

// 片数
const waferAmount = (prevPath?: string): InputFormItemConfig => ({
  label: '片数',
  type: 'input',
  dataKey: prevPath ? `${prevPath}.waferAmount` : 'waferAmount',
  renderType: (formData, initialData, globalStatus) => {
    const _productModel = _.get(formData, `${prevPath}.productModel`);
    if (_productModel === EnumProductModel.assemblyProduct) return 'none';

    if (prevPath) {
      return globalStatus === 'preview' ? 'preview' : 'none';
    }

    return 'preview';
  }
});

// 加工后产品类型
const outputProductModel: SelectFormItemConfig = {
  label: '加工后产品类型',
  type: 'select',
  dataKey: 'output.productModel',
  optionKey: 'productModel',
};

// 加工后产品名称
const outputProduct = (): CustomFormItemConfig => ({
  label: '加工后产品名称',
  type: 'custom',
  dataKey: 'output.product',
  render: (formData, globalStatus, form) => (
    <SelectProductByModel
      productModel={_.get(formData, 'output.productModel')}
    />
  )
});

// 加工厂
const factory: InputFormItemConfig = {
  label: '加工厂',
  type: 'input',
  dataKey: 'output.factory',
};

// 加工后物料名称
const outputMaterialName = (): InputFormItemConfig => ({
  label: '加工后物料名称',
  type: 'input',
  dataKey: 'output.material',
});

// 芯片名称
const outputDieName: CustomFormItemConfig = {
  label: '芯片名称',
  type: 'custom',
  dataKey: 'output.dieName',
  renderType: (formData) => {
    if (
      _.get(formData, 'output.productModel') &&
      _.get(formData, 'output.productModel') !== EnumProductModel.spwDie &&
      _.get(formData, 'output.product')
    ) {
      return 'preview';
    }

    return 'none';
  },
  render(formData: any) {
    return (
      <DieName
        productModel={_.get(formData, 'output.productModel')}
        product={formData?.output?.product?._id || formData?.output?.product?.key}
      />
    );
  }
};

// 加工后物料批号
const outputMaterialNumber: InputFormItemConfig = {
  label: '加工后物料批号',
  type: 'input',
  dataKey: 'output.materialNumber',
  renderType: (formData, initialData, globalStatus) => globalStatus === 'preview' ? 'preview' : 'none'
};

// 印章批号
const outputStampNumber: InputFormItemConfig = {
  label: '印章批号',
  type: 'input',
  dataKey: 'output.stampNumber',
};

// 芯片批号
const outputDieNumber = (): InputFormItemConfig => ({
  label: '加工后芯片批号',
  type: 'input',
  dataKey: 'output.dieNumber',
});

export const getMaterialInfo = (): RepeaterConfig => ({
  type: 'repeater',
  dataKey: 'inputs',
  addNewBtnText: '物料信息',
  renderRepeater(params: RepeaterRenderParams) {
    return ({
      card: {
        title: RepeaterCardTitle('加工前物料信息', params.index, params.len),
      },
      groupItemConfig: [
        productModel,
        getProduct(params.index),
        getMaterialName(),
        getMaterialNumber(),
        originMaterialNumber(),
        waferId(params.index),
        binType(params.index),
        stockNumberPanel({ path: `inputs.${params.index}`, recordType: 'process', isOrder: false }),
        dieNumber,
        stampNumber,
        markMode(),
        packingMethod({ index: params.index }),
        getDieName(params.index),
        getInputCount({
          label: '良品数量',
          dataKey: 'available',
          recordType: 'stockProcess'
        }),
        getInputCount({
          label: '不良品数量',
          dataKey: 'unavailable',
          recordType: 'stockProcess'
        }),
        getInputCount({
          label: '加工数量',
          dataKey: 'amount',
          recordType: 'stockProcess'
        }),
        getInputCount({
          label: '片数',
          dataKey: 'waferAmount',
          recordType: 'stockProcess'
        }),
      ] as FormItemConfig[],
    });
  },
});

export const getStockProcessConfig = (): FormConfig => ({
  cardGroupConfig: [
    {
      card: { title: '加工信息' },
      groupItemConfig: [
        getSerial(),
        manufactureType,
        manufactureOperation,
        reason(),
        reasonSerial,
        demandTime,
        processStatus,
        finishTime,
        expectedTime,
        remark,
      ] as FormItemConfig[]
    },
    getMaterialInfo(),
    {
      card: { title: '加工后物料信息' },
      groupItemConfig: [
        outputProductModel,
        outputProduct(),
        factory,
        outputMaterialName(),
        outputMaterialNumber,
        markMode('output'),
        packingMethod({ path: 'output' }),
        outputStampNumber,
        outputDieNumber(),
        outputDieName,
        outputAvailable(),
        outputUnavailable(),
        outputAmount(),
        waferAmount('output'),
      ] as FormItemConfig[],
    }
  ]
});
// tslint:disable:max-file-line-count
