import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getMomentTime, getFactoryName, slices2Number, bins2Number, sortWaferId } from '@chipcoo/fe-utils';

import { optionsGroup } from 'src/config/selectOption';

export const handleProcessList = async (respData) => {

  const handleData = (respData || []).reduce(
    (prev, cur) => {
      const {
        _id,
        reason,
        process,
        factory,
        inputs,
        demandTime,
        createdAt,
        output,
        status,
        ...restData
      } = cur;

      let [
        inputProduct,
        inputMaterial,
        dieNumber,
        stampNumber,
        totalAmount,
        waferAmount
      ]: any[] = [[], [], [], [], 0, 0];

      inputs.forEach(input => {
        const { amount, slices, material, product, stock } = input;

        stock?.dieNumber && dieNumber.push(stock?.dieNumber);
        stock?.stampNumber && stampNumber.push(stock?.stampNumber);
        totalAmount += amount;
        waferAmount = slices ? slices.length : 0;
        inputMaterial.push(material?.materialName);
        inputProduct.push(product?.customerName);
      });

      const curData = {
        ...restData,
        _id,
        key: _id,
        status,
        demandTime: getMomentTime(demandTime),
        createdAt: getMomentTime(createdAt, 'YYYY-MM-DD HH:mm:ss'),
        manufactureType: process?.name,
        manufactureOperation: process?.code,
        reason: optionsGroup.stockProcessReason.options![reason],
        factory: getFactoryName(factory),
        statusText: optionsGroup.stockProcessStatus.options![status],
        output: {
          ...output,
          productName: output?.product?.customerName,
          materialName: output?.material?.materialName,
          packageType: output?.product?.product?.packageType,
        },
        inputs: {
          productName: inputProduct.join(', '),
          materialName: inputMaterial.join(', '),
          dieNumber: dieNumber.join(', '),
          stampNumber: stampNumber.join(', '),
          amount: totalAmount,
          waferAmount,
          packageType: inputs?.[0]?.product?.product?.packageType,
        }
      };

      prev.push(curData);

      return prev;
    },
    [] as any[],
  );

  return handleData;
};

export const handleProcessDetail = (respData) => {

  const handleData = Object.entries(respData).reduce(
    (prev, [key, val]: any) => {
      prev[key] = val;

      if (key === 'process') {
        prev.manufactureType = val?.name;
        prev.manufactureOperation = val?.code;
      }

      if (key === 'demandTime' || key === 'expectedTime') {
        prev[key] = val && moment(val);
      }

      if (key === 'finishTime') {
        prev[key] = getMomentTime(val, 'YYYY-MM-DD HH:mm:ss');
      }

      if (key === 'factory') {
        prev['output.factory'] = getFactoryName(val);
      }

      if (key === 'output') {
        const { product, material, materialNumbers } = val;

        prev[key] = {
          ...val,
          product: { key: product?._id, label: product?.customerName },
          material: material?.materialName,
          materialNumber: materialNumbers[0],
        };
      }

      if (key === 'inputs') {
        const newInputs = (val || []).map(it => {
          const { product, material, slices, bins, stock } = it;
          let submitData: any = {};

          if (slices && !isEmpty(slices)) {
            const { waferIds } = slices2Number(slices);

            submitData = {
              ...submitData,
              stockNumber: slices,
              waferIds
            };
          }

          if (bins && !isEmpty(bins)) {
            const { binType, binsData } = bins2Number(bins);

            submitData = {
              ...submitData,
              bins: binType,
              stockNumber: binsData
            };
          }

          if (stock) {
            const { dieNumber, stampNumber, markMode, packingMethod } = stock;

            submitData = { ...submitData, dieNumber, stampNumber, markMode, packingMethod };
          }

          return {
            ...it,
            ...submitData,
            product: { key: product?._id, label: product?.customerName },
            material: material?.materialName
          };
        });

        prev[key] = newInputs;
      }

      return prev;
    },
    {} as any
  );

  return {
    [respData._id]: handleData
  };
};

export const handleWipData = (formData) => {
  const time = formData?.[0]?.time;
  const wipMode = formData?.[0]?.mode;
  let history = [];

  // 合并历史信息
  const newFormData = formData?.map(it => {
    const newOperations = it?.operations?.map(operation => ({
      ...operation,
      key: uuidv4(),
      waferIds: operation?.waferIds && sortWaferId(operation?.waferIds),
      time: getMomentTime(operation?.startTime, 'YYYY-MM-DD HH:mm:ss')
    }));

    history = history.concat(newOperations);

    return {
      ...it,
      operations: newOperations,
    };
  });

  // 当前信息
  const operations = newFormData?.[0]?.operations?.map(it => ({
    ...it,
    key: uuidv4(),
  }));

  return {
    time,
    wipMode,
    history,
    operations,
  };
};
