import React from 'react';
import { CustomFormItemConfig, FormConfig, FormItemConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { CardItemConfig } from '@chipcoo/hanayo/lib/FormCreator/types/formConfig';

import Manufacture from './components/Manufacture';
import UnStockOut from './components/UnStockOut';
import Order from './components/Order';
import StockOutTable from '../InventoryManage/StockOutTable/StockOutTable';

const type: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'type',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const params: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'params',
  colClassName: 'hidden-form-item',
  render: () => <div />
};

const order: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'order',
  colSpan: 24,
  render() {
    return (
      <Order params={this.formData.params} />
    );
  },
  renderType() {
    return this.formData.type === 'order' ? 'normal' : 'none';
  }
};

const unStockOut: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'unStockOut',
  colSpan: 24,
  render() {
    return <UnStockOut params={this.formData.params} />;
  },
  renderType() {
    return this.formData.type === 'unStockOut' ? 'normal' : 'none';
  }
};

const manufacture: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'manufacture',
  colSpan: 24,
  render() {
    return (
      <Manufacture queryCondition={this.formData?.params} />
    );
  },
  renderType() {
    return this.formData.type === 'manufacture' ? 'normal' : 'none';
  }
};

const stockOutApply: CustomFormItemConfig = {
  type: 'custom',
  dataKey: 'stockOutApply',
  colSpan: 24,
  render() {
    return (
      <StockOutTable
        category="ALL"
        queryCondition={this.formData?.params}
      />
    );
  },
  renderType() {
    return this.formData.type === 'stockOutApply' ? 'normal' : 'none';
  }
};

export const getFormConfig = (): FormConfig => {
  return {
    cardGroupConfig: [
      {
        groupItemConfig: [
          type,
          params,
          order,
          unStockOut,
          manufacture,
          stockOutApply,
        ] as FormItemConfig[],
      }
    ] as CardItemConfig[]
  };
};
