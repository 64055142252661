/**
 * Created by marvin on 18/8/15.
 */

import * as React from 'react';

import styles from './index.module.less';

import { Result } from 'src/components';

export default () => (
  <div className={styles.stepThree}>
    <Result
      type="success"
      title="组织认证申请完成!"
      description="我们将在5个工作日内完成审核，请耐心等待!"
    />
  </div>
);
