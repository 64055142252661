/**
 * Created by marvin on 18/8/4.
 */

import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Divider } from 'antd';

import urlConfig from 'src/config/url';

import styles from './index.module.less';
import dropdownMenuConfig from './popoverConfig';

interface Props {
  userName: string | undefined;
  extraPanel?: React.ReactNode;
  closePopover?: () => void;
}

class AvatarPopoverMenu extends React.PureComponent<Props> {
  onLogout(e: any) {
    e.preventDefault();
    const path = `${location.protocol}//${location.host}${urlConfig.baseUrl}`;
    const href = `${urlConfig.authUrl}/logout?redirect=${path}`;
    location.href = href;
  }

  render () {
    const { extraPanel, closePopover, userName } = this.props;

    return (
      <div className={styles.popover}>
        <ul className={styles.ul}>
          {userName && <>
            <li className={styles.li}>{userName}</li>
            <Divider className={styles.menuDivider} />
          </>}

          {dropdownMenuConfig.map(item => (
            <li key={item.name} className={styles.li} onClick={closePopover}>
              {
                item.isLogout
                  ? (
                    <a onClick={this.onLogout} href={item.path}>
                      <i className={classNames('c-icon', item.icon)} />
                      <span>{item.name}</span>
                    </a>
                  )
                  : (
                    <Link to={item.path}>
                      <i className={classNames('c-icon', item.icon)} />
                      <span>{item.name}</span>
                    </Link>
                  )
              }
            </li>
          ))}
        </ul>
        {extraPanel}
      </div>
    );
  }
}

export default AvatarPopoverMenu;
