/**
 * 用户发送的普通消息左边显示的头像
 */
import * as React from 'react';
import { getUserNameEnhance } from '@chipcoo/fe-utils';

import styles from './index.module.less';

import { EnhanceAvatar, TimeStamp } from 'src/components';
import { ConnectUserInfo } from 'src/pages/IM/containers';
import { getEnhancedAvatar } from 'src/utils/user';

interface P {
  createdAt: string;
  userId: string;
  userInfo?: User;
}
interface S {}

class MessageHeader extends React.PureComponent<P, S> {
  render() {
    const { userInfo, createdAt } = this.props;
    const src = getEnhancedAvatar(userInfo!);
    const userName = getUserNameEnhance(userInfo!);

    return (
      <div className={styles.messageHeader}>
        <EnhanceAvatar src={src} userName={userName} className="avatar" />

        <div className="info">
          <div className="name">
            {userName}
          </div>

          <TimeStamp className="timestamp" time={createdAt} format="HH:mm" />
        </div>
      </div>
    );
  }
}

// Fix me: type define
export default ConnectUserInfo(MessageHeader) as any;
