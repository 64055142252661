import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';

export default function(api: AxiosInstance) {
  return {
    // 获取个人信息
    getProfile(config?: AxiosRequestConfig) {
      return api.get('console/user/profile', config);
    },

    // 设置个人基本资料
    patchBasicInfo(data: Partial<User>, config?: AxiosRequestConfig) {
      return api.patch('console/user/profile', data, config);
    },

    // 获取头像路径
    getAvatar(authId: number, config?: AxiosRequestConfig) {
      return api.get(`console/user/avatar/${authId}`, config);
    },

    // 修改密码
    patchPassword(data: Obj, config?: AxiosRequestConfig) {
      return api.patch('console/user/password', data, {
        ...config,
        handleSpecialError: {
          403: () => { message.error('旧密码错误'); }
        }
      });
    },

    // 极验预处理
    getGeetest: (config: AxiosRequestConfig = {}) => {
      return api.get('ryo/geetest', config);
    },

    // 获取验证码
    sendVerifyCode(data: Obj, config?: AxiosRequestConfig) {
      return api.post('console/user/profile/verify-code', data, {
        ...config,
        handleSpecialError: {
          409: () => false,
        }
      });
    },

    // 修改手机或邮箱
    patchAccount(data: Obj, config?: AxiosRequestConfig) {
      return api.patch('console/user/profile/account', data, {
        ...config,
        handleSpecialError: {
          400: () => false,
          403: () => false,
        }
      });
    },

    // 获取微信等社交信息
    getSocial(config?: AxiosRequestConfig) {
      return api.get('console/social', config);
    },
  };
}
