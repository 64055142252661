import { get } from 'lodash';
import { GGEditorEvent, PropsAPI } from 'gg-editor';
import { DEST_CRAFT_ROUTE_NODE } from './constant';

const NODE_EDGES = {};

/**
 * 将将要被删除的最终节点的边信息保留
 * @param {*} ev
 */
export function storeDestNodeEdges(ev: GGEditorEvent) {
  const { action, item } = ev;
  if (action === 'remove' && get(item, 'model.shape') === DEST_CRAFT_ROUTE_NODE) {
    NODE_EDGES[item.id] = item.getEdges().map(edge => ({ ...edge.model }));
  }
}

/**
 * 阻止删除最终节点(重新添加回来)
 * @param {*} ev
 * @param {*} propsApi
 */
export function preventDeleteDest(ev: GGEditorEvent, propsApi: PropsAPI) {
  const { action, item } = ev;
  if (action === 'remove' && get(item, 'model.shape') === DEST_CRAFT_ROUTE_NODE) {
    const { model } = item;

    propsApi.add!('node', model);

    (NODE_EDGES[item.id] || []).forEach(edgeModel => propsApi.add!('edge', edgeModel));
  }
}

export default preventDeleteDest;
