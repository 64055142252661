import * as React from 'react';

import ContactTable from './ContactTable';

interface Props {
  status: any;
  value?: any;
  onChange?: (val: any) => void;
}

interface State {}

const contacts = [
  { dataKey: 'ORGANIZATION', label: '组织' },
  { dataKey: 'TAPE_OUT', label: 'tapeout' },
  { dataKey: 'ORDER', label: '订单' },
  { dataKey: 'STOCK_OUT', label: '出货' },
  { dataKey: 'FINANCE', label: '对账' },
];

class ContactTableList extends React.PureComponent<Props, State> {

  handleOnChange = (data, dataKey) => {
    const { value, onChange } = this.props;
    onChange && onChange({
      ...value,
      [dataKey]: data
    });
  }

  renderTable = (contact) => {
    const { value } = this.props;
    const { dataKey, label } = contact;

    return (
      <ContactTable
        {...this.props}
        dataKey={dataKey}
        label={label}
        value={value?.[dataKey]}
        onChange={(data) => this.handleOnChange(data, dataKey)}
      />
    );
  }

  render () {
    return (
      <>{contacts.map(it => this.renderTable(it))}</>
    );
  }
}

export default ContactTableList;
