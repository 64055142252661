/**
 * 省市区的级联选择，因为props.options为undefined过不去ts的类型校验
 * 因此需要在传入options的时候传入一个options={[]}，这个props不会起作用，只是为了通过类型校验而写
 */
import * as React from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Cascader, message } from 'antd';
import { CascaderOptionType, CascaderProps } from 'antd/lib/cascader';
import axios from 'axios';
import log from 'loglevel';

/**
 * 根据regionCode去获取详细的省市区的文字地址
 * @param regionData 地址数据
 * @param {string[]} regionCode 表明省市区的regionCode数组
 * @param {string} stateCode 国家区域代码，默认是86
 * @returns {(any)[]}
 */
function getAddressByRegionCode(data: any, regionCode: string[], stateCode: string = '86') {
  let p, c, d;

  if (regionCode && regionCode.length > 0) {
    let [provinceCode, cityCode, distinctCode] = regionCode;

    p = data[86][provinceCode];

    // 如果有区编码
    if (distinctCode) {
      c = data[provinceCode][cityCode];
      d = data[cityCode][distinctCode];
    }

    // 如果只有省市编码
    if (!distinctCode && cityCode) {
      c = data[provinceCode][cityCode];
    }
  }
  return [p, c, d];
}

interface Props extends CascaderProps {
  stateCode?: string;
  getDetailAddress?: (addressText: string[]) => void;
}

interface State {
  options: CascaderOptionType[];
}

export let regionData: Object = {};
// 检查是否有存下来省市区json数据，没有才去服务器取
export const getRegionData = () => {
  if (!isEmpty(regionData)) { return Promise.resolve(regionData); }

  return axios.get('https://static.chipcoo.com/china-area-data.102236jd95i8.json')
    .then(resp => {
      regionData = resp.data;
      return regionData;
    });
};

class AddressCascader extends React.PureComponent<Props, State> {
  static defaultProps = {
    placeholder: '请选择省/市/区',
    stateCode: '86'
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  async componentWillMount() {
    const stateCode = this.props.stateCode as string;

    await getRegionData()
      .then(() => {
        const { defaultValue, value } = this.props;
        const regionCode = value && !isEmpty(value)
          ? value
          : (defaultValue && !isEmpty(defaultValue) ? defaultValue : void 0);

        this.setOptions(stateCode, regionCode);
      }).catch(err => {
        log.error(err);
        message.error('获取省市区地址出错');
      });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value && !isEqual(nextProps.value, this.props.value)) {
      this.setOptions(nextProps.stateCode as string, nextProps.value);
    }
  }

  // 根据props对应的regionCode来确定级联的option的范围
  setOptions = (stateCode: string, value?: string[]) => {
    const optionScope = [stateCode];

    // 如果有初始的value进来，那么需要把对应的级联选择给渲染出来
    if (value) { optionScope.push(value[0], value[1]); }

    const options = this.getCascaderOptions(optionScope);

    if (options) { this.setState({ options }); }
  }

  /**
   * 根据个给定的regionCode数组来的到级联的option的值
   * @param {string[]} regionCode
   * @returns {CascaderOptionType[] | null}
   */
  getCascaderOptions = (regionCode: string[]) => {
    const [nowCode, nextCode] = regionCode;
    const _regionData = regionData[nowCode];

    if (!_regionData) { return null; }

    const regionCodes = Object.keys(_regionData);
    const isLeaf = !regionData[regionCodes[0]];

    return regionCodes.map(code => {
      const option: any = { label: _regionData[code], value: code, isLeaf };

      // 如果判断有children，那么进行递归调用，直到将children全部挂载
      if (code === nextCode && regionCode.length > 1) {
        const children = this.getCascaderOptions(regionCode.slice(1));

        if (children) { option.children = children; }
      }

      return option;
    });
  }

  // 点击了某个省市后，加载下一级的选项
  loadData = (selectedOptions?: any[]) => {
    if (!selectedOptions) { return; }

    const targetOption = selectedOptions[selectedOptions.length - 1];
    const regionOptions = this.getCascaderOptions([targetOption.value]);

    if (regionOptions) {
      targetOption.children = regionOptions;
      this.setState({ options: [...this.state.options] });
    }
  }

  onChange = (value: string[], selectedOptions?: CascaderOptionType[]) => {
    const { onChange, getDetailAddress } = this.props;

    if (onChange) {
      onChange(value, selectedOptions);
    }

    // 暴露一个接口，使用regionCode获取详细地址
    if (getDetailAddress) {
      const detailAddress = getAddressByRegionCode(regionData, value);

      getDetailAddress(detailAddress);
    }
  }

  render() {
    const {
      stateCode,
      onChange,
      options: optionsProps,
      getDetailAddress,
      ...passThoughProps
    } = this.props;
    const { options } = this.state;

    return (
      <Cascader
        {...passThoughProps}
        options={options}
        onChange={this.onChange}
        loadData={this.loadData}
      />
    );
  }
}

export default AddressCascader;
