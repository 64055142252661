export const longArea: string[] = [
  /*************************** 市级编码 **********************************/
  '222400', '419000', '422800', '429000', '433100', '469000', '513200', '513300', '513400', '522300', '522600',
  '522700', '532300', '532500', '532600', '532800', '532900', '533100', '533300', '533400', '622900', '623000',
  '632200', '632300', '632500', '632600', '632700', '632800', '652300', '652700', '652800', '653000', '654000',
  '659000',

  /*************************** 区级编码 **********************************/
  // 130000
  '130271', '130272', '130273', '130274', '130321', '130371', '130471', '130571', '130671', '130771', '130772',
  '130773', '130826', '130827', '130828', '130871', '130930', '130971', '130972', '131028', '131071',
  // 140000
  '140171', '140271', '140671', '140971',
  // 150000
  '150171', '150172', '150223', '150521', '150522', '150571', '150722', '150724', '150926', '150927', '150928',
  '152221', '152222', '152971',
  // 210000
  '210323', '210422', '210423', '210521', '210522', '210624', '210921', '211324',
  // 220000
  '220171', '220173', '220174', '220271', '220272', '220273', '220323', '220623', '220721', '220771', '220871',
  // 230000
  '230208', '230624', '230671', '231071',
  // 320000
  '320371', '320671', '320771', '320871', '320971', '321071', '321371',
  // 330000
  '330371', '331127',
  // 340000
  '340171', '340172', '340271', '340371', '340372', '340871', '341171', '341172', '341271', '341272', '341371',
  '341372', '341871',
  // 370000
  '370171', '370271', '370571', '370572', '370671', '370672', '370772', '370871', '371072', '371073', '371171',
  '371371', '371372', '371373', '371471', '371472', '371771', '371772',
  // 410000
  '410171', '410172', '410371', '410571', '410671', '410771', '410772', '410871', '410971', '410972', '411071',
  '411171', '411271', '411371', '411372', '411471', '411472', '411571', '411671', '411771',
  // 420000
  '420528', '420529', '421071',
  // 430000
  '430372', '430373', '430471', '430473', '430529', '430671', '430771', '430971', '431129', '431171', '431172',
  '431173', '431226', '431227', '431228', '431229', '431230', '431271', '433173',
  // 440000
  '440232', '441825', '441826',
  // 450000
  '450225', '450226', '450328', '450332', '451031', '451123', '451225', '451226', '451227', '451228', '451229',
  '451324',
  // 460000
  '460323', '460400499', '469025', '469026', '469027', '469028', '469029', '469030',
  // 500000
  '500240', '500241', '500242', '500243',
  // 510000
  '510726', '511071', '511132', '511133', '511771', '511971', '513422',
  // 520000
  '520325', '520326', '520423', '520424', '520425', '520526', '520622', '520625', '520627', '520628', '522732',
  // 530000
  '530126', '530128', '530129', '530426', '530427', '530721', '530724', '530821', '530822', '530823', '530824',
  '530826', '530828', '530829', '530926', '530927', '532523', '532530', '532532', '532922', '532926', '532927',
  '533324', '533325', '533423',
  // 540000
  '540171', '540172', '540173', '540174',
  // 620000
  '620525', '620623', '620721', '620923', '620924',
  // 630000
  '630121', '630222', '630223', '630224', '630225', '632221', '632324', '632857',
  // 650000
  '650521', '652328', '652826', '652871', '653131', '654022', '654226'
];

export const longerArea = [
  '131171', '140471', '150271', '220172', '320772', '321271', '340173', '340272', '371071', '410173', '410471',
  '410472', '410773', '430371', '430472', '430972', '530428', '530825', '530827', '530925', '622927'
];
