import React, { PureComponent } from 'react';
import { bind, debounce } from 'lodash-decorators';
import { createSelector } from 'reselect';
import { isEqual } from 'lodash';
import { filterUser } from '@chipcoo/fe-utils';

import Mentions, { OptionProps } from '@chipcoo/hanayo/lib/HoneyMentions/Mentions';
import ChatInputBox, { ChatInputBoxProps, CommentInfo, SuggestObject } from 'src/components/ChatInputBox';
import { MentionPropsInChat } from 'src/components/ChatInputBox/interface';
import SaveDraft, { SaveDraftApi } from 'src/components/SaveDraft';
import MentionUser from './MentionUser';
import { isEqualUserId } from 'src/utils/user';

export interface EnhancedCommentInfo extends CommentInfo {
  mentions: FrontUser[];
}

type OmittedProps = Omit<
  ChatInputBoxProps,
  'onSubmit' | 'value' | 'onChange' | 'saveDraft' | 'suggestionData'
>;

interface Props extends OmittedProps {
  saveDraft: string;
  onSubmit: (value: EnhancedCommentInfo) => any;
  suggestionData: FrontUser[];
}

interface State {
  mentions: FrontUser[];
  value: string;
  suggestionData: SuggestObject[];
}

function getSuggestObject(users: FrontUser[]) {
  return users.map(({ _id: value, nickname: label }) => ({ value, label }));
}

export class ChatInput extends PureComponent<Props, State> {

  saveDraftFn: (val: string | null) => any;
  mentionProps: MentionPropsInChat;

  selectMentionProps = createSelector(
    (props: Props) => props.mentionsProps,
    (mentionProps) => {
      return {
        ...mentionProps,
        onSelect: this.onSelect,
        filterOption: this.filterOption,
        renderSuggestItem: this.renderSuggestItem,
      };
    }
  );

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextState: Partial<State> = {};
    const nextSuggestionData = getSuggestObject(nextProps.suggestionData);

    if (!isEqual(nextSuggestionData, prevState.suggestionData)) {
      nextState.suggestionData = nextSuggestionData;
    }

    return nextState;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      mentions: [] as FrontUser[],
      value: '',
      suggestionData: getSuggestObject(props.suggestionData),
    };
  }

  @bind
  onSelect(option: OptionProps) {
    //
    const { mentions } = this.state;
    const find = mentions.find(mention => isEqualUserId(mention._id, option.optionKey!));
    if (find) {
      find.nickname = option.value;
    } else {
      mentions.push({ _id: option.optionKey!, nickname: option.value });
    }

    this.setState({ mentions: mentions.slice() });
    this.saveDraft();
  }

  @bind
  filterOption(input: string, option: OptionProps) {
    //
    const user = this.getUserByOption(option);

    if (user) {
      return filterUser(input, user);
    }

    // 直接使用展示的值来进行过滤
    return option.value.indexOf(input) !== -1;
  }

  @bind
  renderSuggestItem(suggest: SuggestObject) {
    const user = this.props.suggestionData.find(u => isEqualUserId(suggest.value, u._id));

    if (!user) {
      return null;
    }

    return (
      <Mentions.Option key={suggest.value} optionKey={suggest.value} value={suggest.label}>
        <MentionUser userInfo={user} />
      </Mentions.Option>
    );
  }

  getUserByOption(option: OptionProps) {
    return this.props.suggestionData.find(user => isEqualUserId(user._id, option.optionKey!));
  }

  @bind
  onChange(value: string, resetMention?: boolean) {
    const nextState: Partial<State> = { value };

    if (resetMention) {
      nextState.mentions = [];
    }

    this.setState(nextState as any);
    this.saveDraft();
  }

  @bind
  async onSubmit(value: CommentInfo) {
    await this.props.onSubmit({
      ...value,
      mentions: this.state.mentions,
    });

    this.onChange('', true);
    this.saveDraft(null);
  }

  @bind
  @debounce(1000)
  saveDraft(val?: object | null) {
    const args = [].slice.call(arguments);

    const { value, mentions } = this.state;
    const [ saveVal = JSON.stringify({ value, mentions }) ] = args;

    this.saveDraftFn && this.saveDraftFn(saveVal);
  }

  render() {
    const { saveDraft } = this.props;
    const { suggestionData } = this.state;
    this.mentionProps = this.selectMentionProps(this.props);
    return (
      <SaveDraft autoSaveKey={saveDraft}>
        {(api: SaveDraftApi) => {
          if (!this.saveDraftFn) {
            this.saveDraftFn = api.save;
          }

          return (
            <ChatInputBox
              {...this.props}
              suggestionData={suggestionData}
              value={this.state.value}
              saveDraft={false}
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              mentionsProps={this.mentionProps}
            />
          );
        }}
      </SaveDraft>
    );
  }
}

export default ChatInput;
