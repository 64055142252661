// 已选物料的各种信息展示
import * as React from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { EnumProcessClass } from '@chipcoo/constant';
import { getSelectedSimpleInfo } from '@chipcoo/fe-utils';

import styles from './index.module.less';

import { QuantityText } from '../index';

interface Props {
  classes?: EnumProcessClass;
  selectedData: any;
  isNeedDetail: boolean;
  demandQuantity?: number;
  demandWaferQuantity?: number;
  selectionMode?: string;
}

class SelectedDataInfo extends React.PureComponent<Props> {
  static defaultProps = {
    isNeedDetail: true,
  };

  render () {
    const {
      classes,
      selectedData,
      isNeedDetail,
      demandQuantity = 0,
      demandWaferQuantity = 0,
      selectionMode,
    } = this.props;
    const info = getSelectedSimpleInfo(selectedData);
    let quantityText = '订单数量';

    if (selectionMode === 'terrace') {
      quantityText = '预计产出数量';
    }

    if (_.isEmpty(selectedData) && !isNeedDetail) {
      return (
        <QuantityText
          demandQuantity={demandQuantity}
          waferCount={demandWaferQuantity}
          selectionMode={selectionMode}
        />
      );
    }

    return (
      <Row className={styles.row}>
        <Col>{quantityText}: {info.quantity}颗</Col>

        {
          isNeedDetail && (
            <>
              <Col>晶圆投料数量: {info.waferQuantity}片</Col>
              {classes === EnumProcessClass.assembly && info.assembly.quantity
                ? (
                  <Col>成品投料数量: {info.assembly.quantity}颗</Col>
                )
                : null
              }

              <br/>
              {
                info.spw.quantity
                  ? (
                    <>
                      <Col>已选SPW芯片:</Col>
                      {
                        info?.spw?.list?.map((it, index) => (
                          <Col key={index}>
                            {it.productName}({it?.chipcooName}): {it.waferQuantity}片({it?.amount}颗)
                          </Col>
                        ))
                      }
                    </>
                  )
                  : null
              }

              {
                info.dp.quantity
                  ? (
                    <>
                      <Col>已选磨划芯片:</Col>
                      {
                        info?.dp?.list?.map((it, index) => (
                          <Col key={index}>
                            {it.productName}({it?.chipcooName}): {it.waferQuantity}片({it?.amount}颗)
                          </Col>
                        ))
                      }
                    </>
                  )
                  : null
              }

              {
                info.assembly.quantity
                  ? (
                    <>
                      <Col>已选封装成品:</Col>
                      {
                        info?.assembly?.list?.map((it, index) => (
                          <Col key={index}>
                            {
                              it?.simpleBins?.map((b, idx) => (
                                <p key={idx}>{it.productName}({it?.chipcooName}): {b.name} ({b.amount})颗</p>
                              ))
                            }
                          </Col>
                        ))
                      }
                    </>
                  )
                  : null
              }
            </>
          )
        }
      </Row>
    );
  }
}

export default SelectedDataInfo;
