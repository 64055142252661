/**
 * 芯片信息的form配置中的版本号的formItem组件
 */
import React, { PureComponent } from 'react';
import { isEmpty, get, isEqual } from 'lodash';
import { selectFieldApi } from 'src/services/net';
import { SelectWithOption } from '@chipcoo/hanayo';
import { SelectWithOptionProps } from '@chipcoo/hanayo/lib/SelectWithOption';

type InitialVersionDieName = { key: string, label?: string };

interface P extends Omit<SelectWithOptionProps, 'options'> {
  status: string;
  resetFields: (names?: Array<string>) => void;
  initialVersionDieName?: InitialVersionDieName;
}
interface S {
  options: any[];
}

class Version extends PureComponent<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    this.getSelectFieldData();
  }

  componentDidUpdate(prevProps: P) {
    const { initialVersionDieName: prevInitialVersionDieName } = prevProps;
    const { initialVersionDieName, resetFields } = this.props;

    if (!isEqual(prevInitialVersionDieName, initialVersionDieName)) {
      // 如果新传入的母版芯片名称为空，那么重置所有
      if (!initialVersionDieName) {
        const { options } = this.state;

        if (!isEmpty(options)) this.setState({ options: [] });

        resetFields(['productDieInfo.mask.version']);

      // 如果传入新的母版芯片名称，那么重置版本名称，再取新的数据
      } else {
        resetFields(['productDieInfo.mask.version']);

        this.getSelectFieldData();
      }
    }
  }

  getSelectFieldData = async () => {
    const initialVersionDieName = this.initialVersionDieName;

    if (!initialVersionDieName) return;

    const { data } = await selectFieldApi.availableDieVersion(initialVersionDieName);

    this.setState({
      options: data.map(item => ({ value: item }))
    });
  }

  private get placeholder() {
    return !this.initialVersionDieName ? '请先选择母版芯片名' : '请选择版本号';
  }

  private get initialVersionDieName() {
    const { initialVersionDieName } = this.props;

    return get(initialVersionDieName, 'label') || get(initialVersionDieName, 'key');
  }

  render() {
    const { options } = this.state;
    const { resetFields, initialVersionDieName, status, ...passThoughProps } = this.props;

    if (status === 'preview') return <span>{passThoughProps.value}</span>;

    return (
      <SelectWithOption
        options={options}
        disabled={!this.initialVersionDieName}
        placeholder={this.placeholder}
        {...passThoughProps}
      />
    );
  }
}

export default Version;
