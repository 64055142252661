import { PureComponent } from 'react';
import { get } from 'lodash';
import { noop } from '@chipcoo/fe-utils';

import { changeOrgId } from '../../utils';
import store from 'src/store';

interface Props {
  orgId: string;
}

interface State {
  notInOrg: boolean;
  loadedMembers: boolean;
}

export class EnsureOrgMembers<P extends any = Props, S extends State = State> extends PureComponent<P, S> {
  async componentDidMount() {
    if (this.state.notInOrg) {
      return;
    }

    this.checkOrg();
  }

  componentDidUpdate() {
    this.checkOrg();
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  isInOrg() {
    const orgId = get(this.props, 'orgId');

    return !!store.getState().org.orgList.byId[orgId];
  }

  // 在获取订单详情之前需要正确的设置组织ID以及获取组织成员。
  async checkOrg() {
    const orgId = get(this.props, 'orgId');
    const { changed } = await changeOrgId(orgId);

    this.setState({ loadedMembers: !changed });

    if (!changed) {
      return;
    }

    const { org: { orgMembers: { allIds } } } = store.getState();

    const promise: any[] = [];
    if (changed || allIds.length === 0) {
      // 如果当前更换了组织或者并未获取过组织成员
      promise.push(store.dispatch({ type: 'org/getOrgMembers', payload: { orgId } }));
    }

    await Promise.all(promise);

    this.setState({ loadedMembers: true });
  }
}

export default EnsureOrgMembers;
