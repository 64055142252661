import { get } from 'lodash';
import {
  ICraftMaterial,
  ICraftRouteItemLocal,
} from 'src/services/net/craftRoute';
import { ICraftRouteNode, ICraftRouteGroup, ICraftRouteEdge, AnchorType } from './interface';

import {
  BASE_CRAFT_ROUTE_NODE,
  SOURCE_CRAFT_ROUTE_NODE,
  DEST_CRAFT_ROUTE_NODE,
  BASE_CRAFT_ROUTE_GROUP,
  END_CRAFT_ROUTE
} from './constant';

interface IGraphData {
  nodes: ICraftRouteNode[];
  groups: ICraftRouteGroup[];
  edges: ICraftRouteEdge[];
}

interface ParentMap {
  [key: string]: string;
}

function getId(item: ICraftMaterial | ICraftRouteItemLocal) {
  let nodeId: string;

  if ('type' in item) {
    nodeId = item.id;
  } else {
    nodeId = item._id;
  }

  return nodeId;
}

export function getEdgeId(source: string, target: string) {
  return `Edge_${source}_${target}`;
}

export function getGroupId(sources: ICraftRouteItemLocal['children'], targetId: string) {
  const sourceId = sources.map((source) => getId(source)).join('-');

  return `Group_${sourceId}_to_${targetId}`;
}

export function getAnchorIndex(anchorType: AnchorType, node: ICraftRouteNode): number {
  // 当且仅当入锚点且节点类型是普通工艺路线节点且其没有父组时，返回 1
  if (
    anchorType === AnchorType.INPUT_ANCHOR
    && node.shape === BASE_CRAFT_ROUTE_NODE
    && !node.parent
  ) {
    return 1;
  }
  return 0;
}

function loopItem(
  item: ICraftMaterial | ICraftRouteItemLocal,
  graphData: IGraphData,
  amountRate: number = 1,
  parentMap: ParentMap = {},
) {
  const { amount, isDest } = item;

  let nodeId = getId(item);

  const node: ICraftRouteNode = {
    shape: BASE_CRAFT_ROUTE_NODE,
    id: nodeId,
    amount,
    product: null,
    productModel: null,
    parent: parentMap[nodeId],
  };

  if (isDest) {
    node.shape = DEST_CRAFT_ROUTE_NODE;
  }
  if ('type' in item || item.isEnd) {
    node.shape = SOURCE_CRAFT_ROUTE_NODE;
    if ('type' in item) {
      const { item: material } = item;
      node.productModel = material.productModel;
      node.product = material;
      node.material = material;
      node.label = get(node.material, 'materialName');
    } else {
      const {
        product,
        productModel,
        material,
      } = item;

      node.product = product;
      node.productModel = productModel;
      node.material = material;
      node.label = get(node.material, 'materialName');
    }

    // Set craftRouteId
    node.craftRouteId = END_CRAFT_ROUTE._id;
  } else {
    const {
      _id,
      children,
      process,
      product,
      productModel,
      material,
    } = item;

    node.product = product;
    node.craftRouteId = _id;
    node.productModel = productModel;
    node.material = material;
    node.label = get(node.material, 'materialName');

    const defaultEdgeSource = getId(children![0]);
    const edge: ICraftRouteEdge = {
      id: getEdgeId(defaultEdgeSource, nodeId),
      label: {
        text: process.code,
      },
      process,
      target: nodeId,
      targetAnchor: getAnchorIndex(AnchorType.INPUT_ANCHOR, node),
      source: defaultEdgeSource,
      sourceAnchor: 0, // 源节点永远为 0
    };

    if (children.length > 1) {
      const group: ICraftRouteGroup = {
        shape: BASE_CRAFT_ROUTE_GROUP,
        id: getGroupId(children, nodeId),
      };

      // 将边的源头切到组上
      edge.id = getEdgeId(group.id, edge.target);
      edge.source = group.id;

      // 给每个子元素标记上父节点ID
      children.forEach((child) => {
        parentMap[getId(child)] = group.id;
      });

      graphData.groups.push(group);
    }
    graphData.edges.push(edge);

    children.forEach((child) => {
      // 如果是物料或者非终点
      loopItem(child, graphData, amountRate * amount, parentMap);
    });
  }

  graphData.nodes.push(node);
}

export function craftRouteToGraph(craftRoute: ICraftRouteItemLocal) {
  const graphData: IGraphData = {
    nodes: [],
    groups: [],
    edges: [],
  };

  loopItem({ ...craftRoute, isDest: true }, graphData);

  return graphData;
}
