import * as React from 'react';
import {
  FileFormItemConfig,
  InputFormItemConfig,
  NumberFormItemConfig,
  SelectFormItemConfig,
  TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';
import { EnumProcessClass } from '@chipcoo/constant';

import { attachmentApi } from 'src/services/net';

const fileDefaultProps = {
  getFileDetail: fileId => fileId && attachmentApi.getDetail(fileId),
  getUrl: fileId => fileId && attachmentApi.download({ attachmentId: fileId })
};

const getFtRenderType = (processClass: EnumProcessClass) => {
  return processClass === EnumProcessClass.ft ? 'normal' : 'none';
};

const testRequirement: SelectFormItemConfig = {
  label: '测试需求',
  type: 'select',
  dataKey: 'scheme.testRequirement',
  optionKey: 'ftTestRequirement',
  props: {
    mode: 'multiple'
  },
};

const ftScheme: FileFormItemConfig = {
  label: '成测方案文档',
  type: 'file',
  dataKey: 'scheme.ftScheme',
  hint: (
    <ul>
      <li>• 请将这个产品的测试文档打包成ZIP或RAR格式的压缩文件，并上传；</li>
      <li>• 一般情况下，关于测试卡制作的说明独立一个文档；</li>
      <li>• 可以下载文档模板并参考。</li>
    </ul>
  ),
  hintType: 'modal',
  props: fileDefaultProps,
};

const testerModel: InputFormItemConfig = {
  label: '测试机型号',
  type: 'input',
  dataKey: 'scheme.testerModel',
};

const testerOption: InputFormItemConfig = {
  label: '测试机配置',
  type: 'input',
  dataKey: 'scheme.testerOption',
};

const handlerModel: InputFormItemConfig = {
  label: '分选机型号',
  type: 'input',
  dataKey: 'scheme.handlerModel',
};

const simultaneousSites: NumberFormItemConfig = {
  label: '同测数',
  type: 'number',
  dataKey: 'scheme.simultaneousSites',
  range: '(0, 1024]',
  precision: 0,
};

const testCardName: InputFormItemConfig = {
  label: '测试卡名称',
  type: 'input',
  dataKey: 'scheme.testCardName',
};

const testCardPcb: FileFormItemConfig = {
  label: '测试卡PCB文件',
  type: 'file',
  dataKey: 'scheme.testCardPcb',
  props: fileDefaultProps,
};

const photoOfTestCard: FileFormItemConfig = {
  label: '测试卡照片',
  type: 'file',
  dataKey: 'scheme.photoOfTestCard',
  props: fileDefaultProps,
};

const testCodeName: InputFormItemConfig = {
  label: '测试程序名称',
  type: 'input',
  dataKey: 'scheme.testCodeName',
};

const testCodeFile: FileFormItemConfig = {
  label: '测试程序文件',
  type: 'file',
  dataKey: 'scheme.testCodeFile',
  props: fileDefaultProps,
};

const testCodeCheckSum: InputFormItemConfig = {
  label: '测试程序CheckSum',
  type: 'input',
  dataKey: 'scheme.testCodeCheckSum',
};

const failRelation: FileFormItemConfig = {
  label: '测试项与失效BIN的对应关系',
  type: 'file',
  dataKey: 'scheme.failRelation',
  props: fileDefaultProps,
};

const timeDistributionOfTestItems: FileFormItemConfig = {
  label: '测试项时间分布',
  type: 'file',
  dataKey: 'scheme.timeDistributionOfTestItems',
  props: fileDefaultProps,
};

const uph: NumberFormItemConfig = {
  label: '每小时测试数量',
  type: 'number',
  dataKey: 'scheme.uph',
  unit: '颗',
  positive: true,
  precision: 0,
};

const testTime: NumberFormItemConfig = {
  label: '平均每颗测试时间',
  type: 'number',
  dataKey: 'scheme.testTime',
  unit: '秒',
  precision: 2,
};

const remark: TextAreaFormItemConfig = {
  label: '备注',
  type: 'textArea',
  dataKey: 'scheme.remark',
  colSpan: 24,
};

const ft = [
  testRequirement,
  ftScheme,
  testerModel,
  testerOption,
  handlerModel,
  simultaneousSites,
  testCardName,
  testCardPcb,
  photoOfTestCard,
  testCodeName,
  testCodeFile,
  testCodeCheckSum,
  failRelation,
  timeDistributionOfTestItems,
  uph,
  testTime,
  remark,
];

const ftFormConfig = ft.map(it => ({
  ...it,
  renderType() {
    return getFtRenderType(this.formData.processClass);
  },
}));

export { ftFormConfig };
