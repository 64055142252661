import React, { FC } from 'react';
import { get } from 'lodash';
import { Row, Col, Icon } from 'antd';
import { EnumProductModel, EnumProcessClass } from '@chipcoo/constant';
import { unSortWaferId } from '@chipcoo/fe-utils';

import Mapping from './Mapping';
import Slices from '../../Slices';
import Bin from '../../BinTable';
import DpDieSlices from '../../DpDieSlices';
import styles from './index.module.less';

interface Props {
  materialInfos: any;
  outerFormData: any;
}

interface FormItem {
  label?: string;
  value: any;
  span?: number;
}

const PreviewMaterial: FC<Props> = props => {
  const { materialInfos, outerFormData } = props;

  const getFormConfig = () => {
    let formConfig: FormItem[];
    const { productModel, classes } = outerFormData;
    if (productModel !== EnumProductModel.assemblyProduct) {
      const mapping = get(materialInfos, 'mapping');
      const cpReport = get(materialInfos, 'cpReport');
      const goodBins = get(materialInfos, 'goodBins');
      const waferIds = unSortWaferId(get(materialInfos, 'waferIdArray'));
      const slices = get(materialInfos, 'slices');
      const dpDieSlices  = get(materialInfos, 'dpDieSlices');
      const dieNumber  = get(materialInfos, 'dieNumber');
      const isCpDie = classes === EnumProcessClass.cp ? true : false;

      if (productModel === EnumProductModel.dpProduct) {
        return [
          {
            value: (
              <DpDieSlices
                value={dpDieSlices}
                status={'preview'}
                shouldSetFields={false}
                dieNumber={dieNumber}
                resetFields={() => {
                  //
                }}
              />
            ),
            span: 24,
          }
        ];
      }
      
      formConfig = [
        {
          value: (
            <Slices
              value={slices}
              status={'preview'}
              slices={[]}
              waferIds={waferIds}
              isCpDie={isCpDie}
              shouldSetField={false}
            />
          ),
          span: 24,
        }
      ];
      if (goodBins) {
        formConfig.unshift({
          label: '良品Bin',
          value: goodBins?.map(it => it.charAt(0).toUpperCase() + it.slice(1)).join(',')
        });
      }
      if (cpReport) {
        formConfig.unshift({
          label: 'CP测试报告',
          value: <><Icon type="paper-clip" />{get(cpReport, '[0].name')}</>
        });
      }
      if (mapping) {
        formConfig.unshift({
          label: 'MAP文件',
          value: <Mapping value={mapping} status={'preview'} />
        });
      }
      
    } else {
      const bins = get(materialInfos, 'bins');
      formConfig = [
        {
          value: (
            <Bin value={bins} shouldSetFields={false} status="preview" />
          ),
          span: 24,
        }
      ];
    }
    return formConfig;
  };

  const renderCol = () => {
    return getFormConfig().map(item => {
      const { label, value, span } = item;

      return (
        <Col key={Math.random()} span={span || 8} style={{ padding: '0 12px'}}>
          <div className={styles.formItem}>
            {label && <span className="form-label">{label} : </span>}
            <span className="form-content">{value ? value : '--'}</span>
          </div>
        </Col>
      );
    });
  };

  return (
    <Row>
      {renderCol()}
    </Row>
  );
};

export default PreviewMaterial;
