/**
 * Created by marvin on 2019-06-21.
 */

import React, { useState } from 'react';
import { get } from 'lodash';
// import { Icon } from 'antd';
import classnames from 'classnames';

import styles from './index.module.less';

interface Props {}

function CollapseDieInfo(props: Props) {

  const [isShowChildrenInfo, setVisible] = useState(false);

  function handleClickCollapse(visible: boolean) {
    setVisible(!visible);
  }

  return (
    <div className={styles.collapse}>
      <i
        className={classnames(styles.collapseIconArea, isShowChildrenInfo ? styles.iconUp : styles.iconDown)}
        onClick={() => handleClickCollapse(isShowChildrenInfo)}
      />
      {/*<Icon type="down" className={classnames(styles.icon, !isShowDieInfo && styles.iconDown)} />*/}
      {/*<Icon type="up" className={classnames(styles.icon, isShowDieInfo && styles.iconUp)} />*/}
      {/*</i>*/}

      <div className={classnames(styles.dieInfoAreaTopBorder, isShowChildrenInfo && styles.dieInfoArea)}>
        {isShowChildrenInfo && get(props, 'children')}
      </div>
    </div>
  );
}

export default CollapseDieInfo;
