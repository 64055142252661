import React, { PureComponent } from 'react';
import { getUserNameEnhance } from '@chipcoo/fe-utils';
import { EnhanceAvatar } from 'src/components';
import { HocProfileAvatar } from 'src/hoc';

interface Props {
  userInfo: User<{
    avatarSrc?: string;
  }>;
}

interface State {}

class MentionUserCls extends PureComponent<Props, State> {
  renderAvatar() {
    const { avatar, avatarSrc, name } = this.props.userInfo;
    return <EnhanceAvatar src={avatarSrc || avatar} userName={name} />;
  }

  render() {
    const { account } = this.props.userInfo;
    return (
      <div className="mention-view-item">
        {this.renderAvatar()}
        <div className="mention-user-info">
          <p className="name ellipsis">{getUserNameEnhance(this.props.userInfo)}</p>
          <p className="tips ellipsis">{account}</p>
        </div>
      </div>
    );
  }
}

export const MentionUser = HocProfileAvatar(MentionUserCls);

export default MentionUser;
