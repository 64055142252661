import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { EnumAnalysisCustomScheduleRepeatUnit, EnumAnalysisType, EnumReportContent } from '@chipcoo/constant';
import {
  AlertContact,
  AlertContactEdit,
  AlertContactsListParams,
} from './qcDataMonitor';

type ARC = AxiosRequestConfig;

export interface IMapParams {
  _id: string;
  content: EnumReportContent;
}

interface ICL {
  _id: string;
  testId: string;
}

interface IPutCL extends ICL {
  meanUCL?: number;
  meanLCL?: number;
  meanCL?: number;
  stdDevUCL?: number;
  stdDevLCL?: number;
  stdDevCL?: number;
}

export interface PcmParams {
  reportId: string;
  cpRange?: [number, number];
  cpDieNumber: string;
  pcmDieNumber: string;
  cpMethod: 'average' | 'median' | 'fails';
  cpTest?: string;
  pcmTests?: string[];
}

export interface TemplateParams extends ListParams {
  name?: string;
  recentlyExecutionAt?: Date;
  createdAt?: Date;
}

export interface TemplatePlanData {
  _id: string;
  repeatUnit: EnumAnalysisCustomScheduleRepeatUnit;
  time: string;
  onceDate?: Date;
  weeklyDay?: number[];
  monthlyDay?: number[];
  yearlyDay?: string[];
}

export default function(api: AxiosInstance) {
  return {
    /*************************** 批次分析报告 **********************************/
    getAnalysisList: (params: any, config: ARC = {}) => {
      return api.get('qc/analysis/search', { ...config, params });
    },

    getAnalysisStatusById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/analysis/${_id}/status`, config);
    },

    getAnalysisById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/analysis/${_id}`, config);
    },

    getAnalysisDetail: (params: { product: string, dieNumber: string }, config: ARC = {}) => {
      return api.get(`qc/analysis/lot`, { ...config, params });
    },

    deleteAnalysis: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/analysis/${_id}`, config);
    },

    getReportList: (params: any, config: ARC = {}) => {
      return api.get('qc/report/search', { ...config, params });
    },

    getReportById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/report/${_id}`, config);
    },

    getReportByAnalysisId: (_id: string, config: ARC = {}) => {
      return api.get(`qc/report/analysis/${_id}`, config);
    },

    // 获取解析日志
    getAnalysisLog: (params, config: ARC = {}) => {
      return api.get(`qc/analysis/stdf-parse-log`, { ...config, params });
    },

    // 获取导入列表解析信息
    getImportLog: (params, config: ARC = {}) => {
      return api.get('qc/import-job/search', { ...config, params });
    },

    // 获取解析阶段状态
    getImportDetail: (params, config: ARC = {}) => {
      return api.get(`qc/import-job/${params._id}`, { ...config, params });
    },

    // 获取解析阶段状态
    getImportStatus: (params, config: ARC = {}) => {
      return api.get(`qc/import-job/${params._id}/stage-status`, { ...config, params });
    },

    // 获取解析阶段任务工作
    getImportJob: (params, config: ARC = {}) => {
      return api.get(`qc/import-job/${params?._id}/works`, { ...config, params });
    },

    // 修改报告过滤器
    putReportFilter: (data, config: ARC = {}) => {
      return api.post(`qc/report/${data?._id}/filter`, data, { ...config, timeout: 600e6 });
    },

    // 搜索批次
    getDieNumbers: (params: { productId: string; analysisType: EnumAnalysisType; }, config: ARC = {}) => {
      return api.get(`qc/stdf/search-die-numbers`, { ...config, params });
    },

    // 计算报告pcm相关性
    getPcmCorrCalc: (data: PcmParams, config: ARC = {}) => {
      return api.post(`qc/report/${data.reportId}/pcm-corr-calc`, data, config);
    },

    // 修改whatIf
    postWhatIf: (data, config: ARC = {}) => {
      return api.post(`qc/report/${data?._id}/raw-data`, data, {
        ...config,
        responseType: 'arraybuffer',
        timeout: 120000
      });
    },

    // 获取趋势分析散点图两个测试项原始数据
    postMultiRawData: (data, config: ARC = {}) => {
      return api.post(`qc/report/${data?._id}/multi-raw-data`, data, {
        ...config,
        responseType: 'arraybuffer',
        timeout: 60000
      });
    },

    /*************************** 订阅管理 **********************************/
    // 订阅联系人列表
    getSubscriptionList: (params: AlertContactsListParams, config: ARC = {}) => {
      return api.get('qc/subscription-management/search', { ...config, params });
    },

    // 新建订阅联系人
    postSubscriptionContact: (data: AlertContact, config: ARC = {}) => {
      return api.post('qc/subscription-management/add', data, config);
    },
    // 修改订阅联系人
    putSubscriptionContact: (data: AlertContactEdit, config: ARC = {}) => {
      return api.put(`qc/subscription-management/${data._id}`, data, config);
    },
    // 删除订阅联系人
    deleteSubscriptionContact: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/subscription-management/${_id}`, config);
    },

    /*************************** MAP相关 **********************************/
    // 获取报告MAP预览
    getMapPreview: (params: any, config: ARC = {}) => {
      return api.get(`qc/report/${params?._id}/preview`, { ...config, params, timeout: 60000 });
    },

    // 获取报告Map
    getMapByMapId: (_id: string, config: ARC = {}) => {
      return api.get(`qc/report/map/${_id}`, { ...config, timeout: 60000 });
    },

    // 自动获取报告信息
    getAutoReport: (params: any, config: ARC = {}) => {
      return api.get('qc/report/auto-report', { ...config, params });
    },

    // 分析报告上传
    postUploadAnalysis: (data: any, config: ARC = {}) => {
      return api.post('qc/analysis', data, {
        ...config,
        // headers: {
        //   'Content-Type': 'application/x-www-form-urlencoded',
        // },
        timeout: 600000
      });
    },

    /*************************** 趋势分析 **********************************/
    // 趋势分析报告列表
    getTendencyList: (params: any, config: ARC = {}) => {
      return api.get('qc/tendency/search', { ...config, params, timeout: 60000 });
    },
    // 趋势分析报告详情
    getTendencyById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/tendency/${_id}`, { ...config, timeout: 60000 });
    },
    // 筛选趋势总览数据
    filterTendencySummary: (params: any, config: ARC = {}) => {
      return api.get(`qc/tendency/${params?._id}/calc-summary`, { ...config, params, timeout: 60000 });
    },
    // 筛选TOP 失效Bin的数据
    filterTopBin: (params: any, config: ARC = {}) => {
      return api.get(`qc/tendency/${params?._id}/calc-bins`, { ...config, params, timeout: 60000 });
    },
    // 筛选Top 5数据
    filterTopFive: (params: any, config: ARC = {}) => {
      return api.get(`qc/tendency/${params?._id}/calc-fails`, { ...config, params, timeout: 60000 });
    },
    // 筛选测试项趋势
    getTendencyTest: (params: any, config: ARC = {}) => {
      return api.get(`qc/tendency/${params?._id}/calc-test`, { ...config, params, timeout: 120000 });
    },
    // 搜索测试项
    getTestIds: (params: { _id: string, dates: string[] }, config: ARC = {}) => {
      return api.get(`qc/tendency/${params._id}/search-test`, { ...config, params, timeout: 60000 });
    },
    // 筛选sites
    getSites: (params: { _id: string, dates: string[] }, config: ARC = {}) => {
      return api.get(`qc/tendency/${params._id}/search-site`, { ...config, params });
    },
    // 设置控制线
    putTendencyCl: (data: IPutCL, config: ARC = {}) => {
      return api.put(`qc/tendency/${data._id}/cl`, data, config);
    },

    /*************************** FTP **********************************/
    getFtpList: (params: any, config: ARC = {}) => {
      return api.get('qc/stdf-ftp/search', { ...config, params });
    },
    getFtp: (_id: string, config: ARC = {}) => {
      return api.get(`qc/stdf-ftp/${_id}`, config);
    },
    putFtp: (data: any, config: ARC = {}) => {
      return api.put(`qc/stdf-ftp/${data?._id}`, data, config);
    },
    postFtp: (data: any, config: ARC = {}) => {
      return api.post('qc/stdf-ftp', data, config);
    },
    delFtp: (id: string, config: ARC = {}) => {
      return api.delete(`qc/stdf-ftp/${id}`, config);
    },
    testFtp: (data: any, config: ARC = {}) => {
      return api.post('qc/stdf-ftp/test', data, {
        ...config,
        timeout: 35000,
        handleSpecialError: {
          406: (e) => {
            // return e;
          }
        }
      });
    },

    /*************************** 自定义分析报告 **********************************/
    // 创建自定义分析报告
    postCustomAnalysis: (data: any, config: ARC = {}) => {
      return api.post('qc/analysis/custom', data, config);
    },
    // 混合搜索测试项
    postTestItem: (data: any, config: ARC = {}) => {
      return api.post('qc/stdf/search-tests-mixed', data, config);
    },
    // 根据产品和reportId搜索测试项https://lo.chpwing.tech/api/qc/report/{reportId}/filter-test
    postTestItemByReport: (data: any, config: ARC = {}) => {
      return api.post(`qc/report/${data?.reportId}/filter-test`, data, config);
    },
    // 根据报告搜索测试项
    postTestItemFilter: (data: any, config: ARC = {}) => {
      return api.post('qc/stdf/tests-filter', data, config);
    },
    // 计算自定义分析报告相关性分析
    postReportCorrCalc: (data: any, config: ARC = {}) => {
      return api.post(`qc/report/${data?._id}/corr-calc`, data, {
        ...config,
        // timeout先拉长，坐等后端优化
        timeout: 60000
      });
    },

    /*************************** 自定义模板 **********************************/
    // 获取自定义模板列表
    getTemplateList: (params: TemplateParams, config: ARC = {}) => {
      return api.get(`qc/analysis-custom-template/search`, { ...config, params });
    },
    // 获取自定义模板详情
    getTemplateById: (_id: string, config: ARC = {}) => {
      return api.get(`qc/analysis-custom-template/${_id}`, config);
    },
    // 删除自定义模板
    deleteTemplateById: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/analysis-custom-template/${_id}`, config);
    },
    // 编辑模板计划
    putTemplatePlan: (data: TemplatePlanData, config: ARC = {}) => {
      return api.put(`qc/analysis-custom-template/${data._id}/schedule-data`, data, config);
    },
    // 手动执行一次自定义模板
    postTemplateExecute: (_id: string, config: ARC = {}) => {
      return api.post(`qc/analysis-custom-template/${_id}/execute`, {}, config);
    },
    // 获取计划数据
    getScheduleData: (_id: string, config: ARC = {}) => {
      return api.get(`qc/analysis-custom-template/${_id}/schedule-data`, config);
    },
    /*************************** 测试项管理 **********************************/
    // 获取测试项列表
    getTestGroupList: (params: Obj, config: ARC = {}) => {
      return api.get(`qc/test-group/search`, { ...config, params });
    },
    // 获取测试项详情
    getTestGroupById: (params: Obj, config: ARC = {}) => {
      return api.get(`qc/test-group/${params?.id}`, { ...config, params });
    },
    // 获取测试项对比数据
    getTestGroupCompare: (params: Obj, config: ARC = {}) => {
      return api.get(`qc/test-group/diff`, { ...config, params });
    },
    // 测试项删除
    deleteTestGroupById: (_id: string, config: ARC = {}) => {
      return api.delete(`qc/test-group/${_id}`, {
        ...config,
        handleSpecialError: {
          409: (e) => {
            message.error(e?.data?.message);
          }
        }
      });
    },
  };
}
// tslint:disable:max-file-line-count
