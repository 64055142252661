import * as React from 'react';
import { Icon } from 'antd';

interface CardExtraProps {
  openQuery: (e: any) => void;
  openModal?: () => void;
}

function CardExtra(props: CardExtraProps) {
  const { openQuery } = props;
  // const { openQuery, openModal } = props;

  return (
    <div>
      <a onClick={openQuery}>
        <Icon type="search" />
        查询
      </a>

      {/*<Divider type="vertical" />*/}

      {/*<Button type="primary" onClick={openModal}>添加</Button>*/}
    </div>
  );
}

export default React.memo(CardExtra);
