import React from 'react';
import { get } from 'lodash';
import { EnumProductModel } from '@chipcoo/constant';
import {
  CustomFormItemConfig, DividerFormItemConfig,
  FormStatus,
  InputFormItemConfig,
  SelectFormItemConfig, TextAreaFormItemConfig,
} from '@chipcoo/hanayo/lib/FormCreator';

import store from 'src/store';
import { mapOrgMember } from 'src/models/org';

import Principal, { createRenderParticipants } from 'src/containers/Principal';

// 分割线
export const divider: DividerFormItemConfig = { type: 'divider' };

// 工厂state divider
export const stateSchemesDivider = (index: number): DividerFormItemConfig => ({
  type: 'divider',
  renderType: (value) => {
    const factory = get(value, `schemes.${index}.factory`) || get(value, `schemes.${index}.factoryName`);

    return factory ? 'normal' : 'none';
  }
});

// 状态
export const state = (index): SelectFormItemConfig => ({
  label: '状态',
  type: 'select',
  dataKey: 'state',
  optionKey: 'productState',
  renderType: (value, initialValues) => get(initialValues, `schemes.${index}.state`) ? 'preview' : 'none'
});

// 状态说明
export const stateDescription = (index): InputFormItemConfig => ({
  label: '状态说明',
  type: 'input',
  dataKey: 'stateDescription',
  colSpan: 24,
  renderType: 'preview'
});

export const getCustomerName = (text?: string): InputFormItemConfig => ({
  label: text ? text : '芯片名',
  type: 'input',
  dataKey: 'customerName',
  renderType: 'preview'
});

// 负责人
export const principal: CustomFormItemConfig = {
  label: '负责人',
  type: 'custom',
  dataKey: 'principal',
  colSpan: 8,
  render: ({ principal: p }, status: FormStatus) => {
    const { org: { orgMembers } } = store.getState();
    const members = mapOrgMember(orgMembers);

    return (
      <Principal members={members} principal={p} status={status} />
    );
  },
  fieldDecoratorOptions: {
    normalize(val: any) {
      return val && val * 1;
    }
  },
};

// 参与者
export const participants: CustomFormItemConfig = {
  label: '参与者',
  type: 'custom',
  dataKey: 'participants',
  colSpan: 8,
  render: createRenderParticipants(),
  fieldDecoratorOptions: {
    normalize(val: any) {
      return val && val.map(uId => uId * 1);
    }
  }
};

// 包装方式
export const packingMethod = (productModel: EnumProductModel): SelectFormItemConfig => {
  let optionKey = '';

  if (productModel === EnumProductModel.dpProduct) {
    optionKey = 'dpPackingMethod';
  }

  if (productModel === EnumProductModel.assemblyProduct) {
    optionKey = 'assemblyPackingMethod';
  }

  return ({
    label: '包装方式',
    type: 'select',
    dataKey: 'packingMethod',
    optionKey,
    renderType: 'preview'
  });
};

export const other = (preDataKey?: string): TextAreaFormItemConfig => ({
  label: '其他',
  type: 'textArea',
  dataKey: preDataKey ? `${preDataKey}.other` : 'other',
  colSpan: 24,
  renderType: 'preview'
});
