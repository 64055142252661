import produce from 'immer';
import { createModel } from '@rematch/core';
import { EnumProductModel } from '@chipcoo/constant';

import { State } from './interface.d';
import { TableModelCommonEffects } from 'src/models/type';
import { productApi } from 'src/services/net';
import {
  handleProductList,
  handleSpwDieDetailData,
  handleDppDetail,
  handleAssemblyProductDetail,
  handleOrderTemplateList,
  handleProductProcess,
} from './handle';

const initialState: State = {
  list: [],
  count: 0,
  detail: {},

  orderTemplate: {
    list: [],
    count: 0,
  },
  productProcess: []
};

export default createModel({
  state: initialState,
  selectors: {},
  reducers: {
    setList(state: State, payload: any) {
      return produce(state, draft => {
        draft.list = payload?.data;
        draft.count = payload?.count;
      });
    },
    setDetail(state: State, payload: any) {
      return produce(state, draft => {
        draft.detail[payload?._id] = payload;
      });
    },

    setOrderTemplateList(state: State, payload: any) {
      return produce(state, draft => {
        draft.orderTemplate = {
          list: payload?.data,
          count: payload?.count,
        };
      });
    },
    setProductProcess(state: State, payload: any) {
      return produce(state, draft => {
        draft.productProcess = payload;
      });
    }
  },
  effects: (dispatch): TableModelCommonEffects => ({
    async getTableList(params: any, rootState: any) {
      const { data } = await productApi.search(params);
      const handleData = handleProductList(data?.data, rootState?.org?.orgMembers?.byId);

      this.setList({
        count: data?.count,
        data: handleData,
      });
    },
    async getTableItemDetail(id: string) {
      let handleData;
      // const [materials, { data }] = await Promise.all([
      //   this.fetchProductMaterials(id), productApi.getProductById(id)
      // ]);
      const { data } = await productApi.getProductById(id);

      if (data?.productModel === EnumProductModel.spwDie) {
        handleData = await handleSpwDieDetailData(data);
      }

      if (data?.productModel === EnumProductModel.dpProduct) {
        handleData = await handleDppDetail(data);
      }

      if (data?.productModel === EnumProductModel.assemblyProduct) {
        handleData = await handleAssemblyProductDetail(data);
      }

      // handleData.materials = (materials || []).map(it => it.process);
      // (handleData as any).materialsObj = materials;

      this.setDetail(handleData);
    },
    async postCreate() {
      //
    },
    async patchModify(formData: any) {
      const handleData = {
        _id: formData?._id,
        principal: formData?.principal,
        participants: formData?.participants,
      };

      await productApi.patchProduct(handleData);
    },

    /*************************** 订购名列表 **********************************/
    async getOrderTemplateList(params: any) {
      const { packing, ...restParams } = params;
      const { data } = await productApi.searchOrderTemplate(restParams);
      const handleData = handleOrderTemplateList(data?.data, packing);

      this.setOrderTemplateList({
        data: handleData,
        count: data?.count,
      });
    },

    /*************************** 获取产品制程 **********************************/
    async getProductProcess(productId: string) {
      const { data } = await productApi.getProcessById(productId);
      const handleData = handleProductProcess(data);

      this.setProductProcess(handleData);
    }
  })
});
