/**
 * 选择了芯片名(加工前)以后，渲染芯片信息的详情
 */
import React, { PureComponent } from 'react';
import { Skeleton } from 'antd';
import { isEqual, get } from 'lodash';
import { FormCreator } from '@chipcoo/hanayo';
import { FormConfig } from '@chipcoo/hanayo/lib/FormCreator';
import { noop } from '@chipcoo/fe-utils';

// import styles from './DieInfo.module.less';
import { productApi } from 'src/services/net';
import { handleSpwDieDetailData } from 'src/models/product/handle';
import { divider } from '../formConfig/common';
import { getCommonInfo } from '../formConfig/spw-die';

interface P {
  // 获取到的详情不同，处理方式也不一样
  // fetchDetailType: 'die' | 'dieAndBump';
  die?: { key: string, label?: string };
  formConfig?: FormConfig;
}
interface S {
  showSkeleton: boolean;
  dieInfo: Obj | undefined;
}

export const diePreviewFormConf = () => ({
  formRow: { gutter: 24 },
  rowGroupConfig: [
    ...getCommonInfo(),
  ]
});
// 封装成品选择bump芯片，相当于芯片的加工需求为Bumping的情况
export const bumpDiePreviewFormConf = () => ({
  formRow: { gutter: 24 },
  rowGroupConfig: [
    // originDieName,
    divider,
    ...getCommonInfo(),
    divider,
    // ...getBumpInfo({ citedBy: ['citedByBumpProduct'], type: 'cited' } as BumpDieModalOption),
  ]
});

class DieInfo extends PureComponent<P, S> {
  static defaultProps = {
    dieNameType: 'name',
  };

  constructor(props: P) {
    super(props);

    this.state = {
      showSkeleton: true,
      dieInfo: void 0,
    };
  }

  componentDidMount() {
    const { die } = this.props;

    if (die) {
      this.getDieInfo(die);
    }
  }

  componentWillUnmount() {
    this.setState = noop;
  }

  async componentDidUpdate(prevProps: P) {
    const { die } = this.props;

    if (!die) {
      this.setState({ dieInfo: void 0 });
    } else {
      !isEqual(prevProps.die, die) && this.getDieInfo(die);
    }
  }

  getDieInfo = async (die) => {
    this.setState({ showSkeleton: true });

    const detailId = die?.key;

    let dieInfo;

    const { data } = await productApi.getProductById(detailId);

    dieInfo =  await handleSpwDieDetailData(data);

    this.setState({
      dieInfo,
      showSkeleton: false
    });
  }

  getFormConfig = () => {
    const { formConfig } = this.props;

    if (formConfig) return formConfig;

    const { dieInfo } = this.state;
    const model = get(dieInfo, 'model');

    if (!model || model === 'die') {
      // bump、cp、dp芯片必须传入citedBy，但是封装引用该组件时不传入；
      // 封装成品只有引用芯片才会进入这个判断，所以，没有传入时，此处的citedBy为citedByAssembly
      return diePreviewFormConf();
    }

    return bumpDiePreviewFormConf();
  }

  render() {
    const { die } = this.props;

    if (!die) return null;

    const { dieInfo, showSkeleton } = this.state;

    return (
      <Skeleton loading={showSkeleton} active={true}>
        <FormCreator
          formConfig={this.getFormConfig()}
          globalStatus="preview"
          formData={dieInfo}
          formWrapper={false}
          // className={styles.dieInfo}
        />
      </Skeleton>
    );
  }
}

export default DieInfo;
