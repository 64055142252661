import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';

interface Option {
  openModal: (params: any) => void;
}

export const getTableColumns = (option: Option): ColumnProps<any>[] => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      title: '物料名称',
      width: 250,
    },
    {
      dataIndex: 'product',
      key: 'product',
      title: '产品名称',
      width: 250
    },
    {
      dataIndex: 'productModel',
      key: 'productModel',
      title: '产品类型',
      width: 200
    },
    {
      dataIndex: 'warehouse',
      key: 'warehouse',
      title: '库房',
      // width: 300
    },
    {
      dataIndex: 'available',
      key: 'available',
      title: '良品库存',
      width: 100
    },
    {
      dataIndex: 'unavailable',
      key: 'unavailable',
      title: '不良品库存',
      width: 100
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: '总库存',
      width: 150,
    },
    {
      dataIndex: 'waferAmount',
      key: 'waferAmount',
      title: '片数',
      width: 100,
    },
    {
      dataIndex: 'operate',
      key: 'operate',
      title: '操作',
      width: 140,
      fixed: 'right' as 'right',
      render: (text, record) => {
        const openModal = (e) => {
          option.openModal(record._id);
        };

        return (
          <a onClick={openModal}>
            查看
          </a>
        );
      }
    },
  ];
};
