import log from 'loglevel';
import { isLocalRequest } from './plugin';
import { getResourceKey } from '../resource';

export * from './plugin';

export interface UnsubscribeResult {
  status: 'success' | 'failed';
}

export interface SubscribeResult {
  resourceKey: string;
  unsubscribe(): Promise<UnsubscribeResult>;
}

export interface SubscribeConfig {
  ignoreLocalRequests?: boolean;
}

export interface SocketCbShortcut extends SubscribeResult {
  config: SubscribeConfig;
}
 
export interface SocketCbFunction extends Function {
  __socket_handler?: {
    [resourceKey: string]: SocketCbShortcut;
  };
  (data: ISyncSocketData): any;
}

export interface EnhancedSocked extends SocketIOClient.Socket {
  _events: {
    [key: string]: {
      handler: Function,
      listeners: SocketCbFunction[],
      resource: IResource,
      eventName: string;
      onSubscribeProcess: boolean;
    };
  };
  subscribe(resource: IResource, cb: SocketCbFunction, config?: SubscribeConfig): Promise<SubscribeResult>;
  /**
   * Unsubscribe all events relates to the resource. Meanwhile unbind all local listeners about this resource.
   * 不再订阅与该资源相关的事件，同时本地所有与该事件有关的监听取消。
   */
  unsubscribe(resource: IResource, cb?: SocketCbFunction): Promise<UnsubscribeResult>;
}

function initCb(cb?: SocketCbFunction) {
  if (typeof cb === 'function' && !cb.__socket_handler) {
    cb.__socket_handler = {};
  }
}

function unsubscribe(
  socket: EnhancedSocked, resource: IResource, cb?: SocketCbFunction
): Promise<UnsubscribeResult> {

  const key = getResourceKey(resource);
  const { _events: events } = socket;
  const event = events[key];
  const index = (event.listeners || []).findIndex(fn => fn === cb);
  if (!event || (cb && index === -1)) {
    return Promise.resolve({
      status: 'success',
    });
  }

  const { listeners } = event;

  return new Promise((resolve, reject) => {
    if (listeners.length === 1 || !cb) {
      socket.emit('unsubscribe', resource, (err, eventName) => {
        if (err) {
          return reject(err);
        } 
  
        socket.off(eventName, event.handler);
        event.listeners.forEach((fn) => {
          Reflect.deleteProperty(fn.__socket_handler!, key);
        });
        event.listeners = [];
  
        resolve({ status: 'success' });
      });
      return;
    }

    Reflect.deleteProperty(cb.__socket_handler!, key);
    event.listeners.splice(index, 1);

    resolve({ status: 'success' });
  });
}

function subscribeResource(socket: EnhancedSocked, resource: IResource) {
  const key = getResourceKey(resource);
  socket._events[key].onSubscribeProcess = true;
  
  return new Promise<string>((resolve, reject) => {
    socket.emit('subscribe', resource, (err, eventName) => {
      socket._events[key].onSubscribeProcess = false;

      if (err) {
        return reject(err);
      }

      socket.on(eventName, socket._events[key].handler);
      socket._events[key].eventName = eventName;
      resolve(eventName);
    });
  });
}

const defaultSubscribeConfig: SubscribeConfig = {
  ignoreLocalRequests: true,
};

async function subscribe(
  socket: EnhancedSocked, resource: IResource, cb: SocketCbFunction, config?: SubscribeConfig
): Promise<SubscribeResult>  {
  const key = getResourceKey(resource);

  if (!socket._events[key]) {
    socket._events[key] = {
      listeners: [],
      handler: function() {
        const args = [].slice.call(arguments);
        
        // Delay 200 milliseconds, wait for local requests to complete.
        // 等待本地请求执行完毕再执行回调。200毫秒比较保险。

        setTimeout(() => {
          socket._events[key].listeners.forEach((fn) => {
            const data: ISyncSocketData = args[0];
            const { config: subscribeCfg } = fn.__socket_handler![key]!;

            // If local requests are ignored, do nothing.
            if (subscribeCfg.ignoreLocalRequests && isLocalRequest(data.messageId)) {
              return;
            } 

            fn.apply(null, args);
          });
        }, 200); 
      },
      resource: {
        ...resource,
      },
      onSubscribeProcess: false,
      eventName: '',
    };
  }

  const { listeners, onSubscribeProcess } = socket._events[key]!;

  if (listeners.length === 0 && !onSubscribeProcess) {
    const eventName = await subscribeResource(socket, resource);
    socket._events[key].eventName = eventName;
  }

  if (!cb.__socket_handler![key]) {
    cb.__socket_handler![key] = {
      resourceKey: key,
      unsubscribe() {
        return unsubscribe(socket, resource, cb);
      },
      config: {
        ...defaultSubscribeConfig,
        ...config,
      }
    };
    listeners.push(cb);
  } else {
    //
    log.warn(
      '[SyncSocket]: the callback function had subscribe this resource before, returns previous subscription.'
    );
  }
  
  return cb.__socket_handler![key];
}

export function wrapSocket(socket: EnhancedSocked) {
  if (!socket._events) {
    socket._events = {};
  }

  socket.subscribe = function(resource: IResource, cb: SocketCbFunction, config?: SubscribeConfig) {
    initCb(cb);
    return subscribe(socket, resource, cb, config);
  };

  socket.unsubscribe = function(resource: IResource, cb?: SocketCbFunction) {
    initCb(cb);
    return unsubscribe(socket, resource, cb);
  };

  socket.on('reconnect', function() {
    const events = Object.entries(socket._events);
    socket.emit('subscriptions', (err, eventNames) => {
      if (err) {
        return;
      }

      events.forEach(([_, subscription]) => {
        if (eventNames.indexOf(subscription.eventName) !== -1) {
          return;
        }

        socket.off(subscription.eventName, subscription.handler);
        subscribeResource(socket, subscription.resource);
        log.info('[SyncSocket]', subscription.eventName, 're connected');
      });
    });
  });

  return socket;
}
