/**
 * 系统消息
 */
import * as React from 'react';

import styles from './systemMessage.module.less';

interface P {
  message: string;
}
interface S {}

class SystemMessage extends React.PureComponent<P, S> {
  render() {
    const { message } = this.props;

    return (
      <div className={styles.systemMessageWrapper}>
        <span className={styles.text}>
          {message}
        </span>
      </div>
    );
  }
}

export default SystemMessage;
