import { get } from 'lodash';

import { attachmentApi } from 'src/services/net';

// 获取标准化的可以直接显示在表单里面的附件信息的reduce的处理函数
export const getNormalizedFactoryAttachmentObjReduce = (prev, cur) => {
  const { name, attachment } = cur;
  const attachmentId = get(attachment, '_id');
  const file = get(attachment, 'versions.0');

  attachmentId && file && (prev[name] = {
    ...cur,
    attachment: [{
      uid: attachmentId,
      name: file.filename,
      url: attachmentApi.download({ attachmentId, versionId: file._id })
    }],
  });

  return prev;
};

// 处理从服务器获取到的需要用户确认的图纸相关数据
const blankObj = {};
export const handleNeedConfirmAttachment = (data) => {
  const attachments = get(data, 'riskEvaluation.attachments');

  if (!Array.isArray(attachments)) return blankObj;

  return attachments.reduce(getNormalizedFactoryAttachmentObjReduce, {});
};