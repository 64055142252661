/**
 * 点击确认后直接跳转url
 * 这个组件的使用场景类似如下：
 * 群主将A从聊天群里T除，此时A正在当前群里头在聊天，需要给一个弹框告知他已经被T掉了
 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import ConfirmModal from './ConfirmModal';

const changeUrlAfterConfirm = (text: string, doAfter: () => void) => {
  // 确定弹框的节点
  let modalRoot = document.createElement('div');

  document.body.appendChild(modalRoot);

  const close = () => {
    doAfter();

    const unmountResult = ReactDOM.unmountComponentAtNode(modalRoot);

    if (unmountResult && modalRoot.parentNode) {
      modalRoot.parentNode.removeChild(modalRoot);
    }
  };

  ReactDOM.render(
    <ConfirmModal onClose={close} text={text} />,
    modalRoot
  );
};

export default changeUrlAfterConfirm;
