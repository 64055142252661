export const handleSummaryList = (list: any[] = []) => {

  return list.map(it => {
    const {
      productId,
      orderQuantity,
      unStockOutQuantity,
      manufactureQuantity,
      stockOutApplyQuantity,
      stockOutIn7DQuantity,
      stockOutIn30DQuantity,
      stockOutQuantity,
    } = it;

    return {
      ...it,
      key: productId,
      orderQuantity: orderQuantity ?? 0,
      unStockOutQuantity: unStockOutQuantity ?? 0,
      manufactureQuantity: manufactureQuantity ?? 0,
      stockOutApplyQuantity: stockOutApplyQuantity ?? 0,
      stockOutIn7DQuantity: stockOutIn7DQuantity ?? 0,
      stockOutIn30DQuantity: stockOutIn30DQuantity ?? 0,
      stockOutQuantity: stockOutQuantity ?? 0,
    };
  });
};

export const handleUnStockOutList = (list: any[] = []) => {
  return list.map(it => {
    const { productId, unStockOutQuantity = 0 } = it;

    return {
      ...it,
      key: productId,
      unStockOutQuantity,
    };
  });
};

export const handleManuFacturedList = (list: any[] = []) => {
  return list.map(it => {
    const { productId, manufacturedUnStockOut = 0} = it;
    return {
      ...it,
      key: productId,
      manufacturedUnStockOut,
    };
  });
};
