// import { ROUTE_NAMES } from 'src/router/normalRouter';
// import applicationList from "../../../pages/Organization/OrgDetail/cardGrid";

const cardGrids = [
  {
    key: '0',
    title: '委托加工单',
    description: '在此您可以编辑给我们的委托加工单，委托加工单包含磨划成品和封装成品；',
    target: '',
    route: '', // define only.
    type: 'order',
  },
  {
    key: '1',
    title: '来料入库单',
    description: '在此您可以填写来料的相关信息，来料包含SPW芯片，磨划成品和封装成品；',
    target: '',
    route: '', // define only.
    type: 'stock-in-apply',
  },
  {
    key: '2',
    title: '出货申请单',
    description: '在此您可以对已经加工完成的产品进行出货申请；',
    target: '',
    route: '', // define only.
    type: 'stock-out-apply',
  },
];

export default cardGrids;
